/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as actions from 'modules/Helpers/IntegrationHelpers';
import JourneyLogo from 'modules/Helpers/partnerlogos/journeyLogoFull.png';

export class OptionView extends Component {
  render() {
    const { option, isSelected, verified } = this.props;

    let backgroundStyle = isSelected ? 'bg-primary' : 'bg-gray-lighter';
    backgroundStyle = verified === false ? 'bg-warning' : backgroundStyle;
    const iconStyle = isSelected ? 'text-white-op' : 'text-black-op';

    const icon = actions.iconForOption(option);

    if (option === 'journey') {
      return (
        <a className="block block-bordered block-link-hover3 selectable" style={{ height: '124px', paddingTop: 40, paddingLeft: 12 }}>
          <img src={JourneyLogo} alt="journeyLogo" style={{ width: '100%' }} />
        </a>
      );
    }

    return (
      <a className="block block-bordered block-link-hover3 selectable">
        <table className="block-table text-center">
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <div className="h4 font-w400">{actions.titleForOption(option)}</div>
              </td>
              <td className={backgroundStyle} style={{ width: '50%' }}>
                <div className="push-30 push-30-t">
                  <i className={icon + ' fa-3x ' + iconStyle} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </a>
    );
  }
}

OptionView.propTypes = {
  option: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
};

export default OptionView;
