import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import ScheduleCallModalContainer from '../../containers/ScheduleCallModalContainer';
import CallOutcomeRow from './CallOutcomeRow';
import { NEW_CALL_MODAL, STARTED_TRIAL_MODAL, DEAL_MODAL } from '../../constants';

import ScheduleAppointmentModalContainer from '../../../appointments/containers/ScheduleAppointmentModalContainer';
import ClassAppointmentModalContainer from '../../../appointments/containers/ClassAppointmentModalContainer';
import DealModalContainer from 'modules/components/LeadDetail/containers/DealModalContainer';
import TrialSelectModal from 'modules/components/TrialSelectModal';

import { SCHEDULE_APPOINTMENT_MODAL, CLASS_APPOINTMENT_MODAL } from '../../../appointments/constants';

import * as callHelpers from 'modules/components/LeadDetail/modules/calls/helpers';
import { FormFields, InfoView } from 'modules/components';

import * as callScheduleHelpers from 'modules/Helpers/CallScheduleHelpers';
const { TextArea, Switch } = FormFields;

export class RecordCallModal extends Component {
  state = {
    selectedAction: null,
    note: null,
    surpressEmail: false,
    surpressSms: false,
  };

  modalForSelectedAction = () => {
    const { lead, classIntegrationId } = this.props;
    const { selectedAction } = this.state;

    switch (selectedAction) {
      case callHelpers.BOOKED_APPOINTMENT:
        if (classIntegrationId) {
          return CLASS_APPOINTMENT_MODAL;
        } else {
          return SCHEDULE_APPOINTMENT_MODAL;
        }
      case callHelpers.NO_ANSWER:
      case callHelpers.LEFT_MESSAGE:
      case callHelpers.STILL_THINKING:
        if (lead.call_schedule_step) {
          return null;
        }
        return NEW_CALL_MODAL;
      case callHelpers.SIGNED_UP:
        return DEAL_MODAL;
      case callHelpers.STARTED_TRIAL:
        return STARTED_TRIAL_MODAL;
      default:
        return null;
    }
  };

  handleActionSelect = (type) => {
    this.setState({ ...this.state, selectedAction: type });
  };

  recordCall = () => {
    const { recordCall, lead, call, toggleComplete, listUpdateProtocol } = this.props;
    const { note, selectedAction, surpressSms, surpressEmail } = this.state;

    const modalName = this.modalForSelectedAction();
    if (modalName) {
      this.props.showModal(modalName);
    } else {
      this.props.hideModal();
    }
    if (call) {
      const relevantListUpdateProtocol = modalName ? null : listUpdateProtocol;
      toggleComplete(call, lead, selectedAction, note, surpressSms, surpressEmail, relevantListUpdateProtocol);
    } else {
      recordCall(lead, selectedAction, note);
    }
  };

  trialSelected = (trial, trialStartsAt) => {
    const { lead, hideModal, addLeadToTrial } = this.props;
    addLeadToTrial(lead, trial, trialStartsAt);
    hideModal();
  };

  formIsInvalid = () => {
    const { selectedAction } = this.state;
    if (!selectedAction) return true;
    return false;
  };

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value });
  };

  reset = () => {
    this.setState({
      selectedAction: null,
      note: null,
      surpressEmail: false,
      surpressSms: false,
    });
  };

  render() {
    const { currentModal, hideModal, modalName, lead, call, classIntegrationId, classIntegrationName } = this.props;
    const { selectedAction } = this.state;

    const options = callHelpers.allTypes.map((type, index) => {
      const isSelected = type === selectedAction;
      const trialName = lead.trial ? lead.trial.name : null;
      const disabled = type === callHelpers.STARTED_TRIAL && trialName !== null;
      return (
        <CallOutcomeRow
          key={index}
          type={type}
          isSelected={isSelected}
          onClick={this.handleActionSelect}
          disabled={disabled}
          trialName={trialName}
        />
      );
    });

    const detailText = call ? callScheduleHelpers.callScheduleActiveText(lead, selectedAction) : null;
    const optionsToShow = call ? callScheduleHelpers.optionsToShow(lead, selectedAction) : null;

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal} onExited={this.reset}>
          <div className="block">
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                How did the call go?
              </h3>
            </div>
            <div className="block-content">
              {detailText && (
                <div className="row">
                  <div className="col-xs-12">
                    <InfoView colorThemeClass={'info'} detail={detailText} />
                  </div>
                </div>
              )}
              <OptionsContainer>{options}</OptionsContainer>
              {optionsToShow && (
                <div className="row">
                  {optionsToShow.email && (
                    <Switch
                      name="surpressEmail"
                      label="Stop the automatic email from sending"
                      onChange={this.handleInput}
                      switchStyle="switch-sm switch-primary"
                      value={this.state.surpressEmail}
                    />
                  )}
                  {optionsToShow.sms && (
                    <Switch
                      name="surpressSms"
                      label="Stop the automatic sms from sending"
                      onChange={this.handleInput}
                      switchStyle="switch-sm switch-primary"
                      value={this.state.surpressSms}
                    />
                  )}
                </div>
              )}
              <div className="row push-20">
                <TextArea name="note" label="Call notes" placeholder="Any notes about this call?" onChange={this.handleInput} rows={4} />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.props.hideModal}>
              Cancel
            </button>
            <button className="btn btn-success push-5-r push-10" type="button" disabled={this.formIsInvalid()} onClick={this.recordCall}>
              <i className="si si-call-out" /> Record call
            </button>
          </Modal.Footer>
        </Modal>
        <ScheduleCallModalContainer
          modalName={NEW_CALL_MODAL}
          previousModalName={modalName}
          lead={lead}
          listUpdateProtocol={this.props.listUpdateProtocol}
        />
        <ScheduleAppointmentModalContainer
          modalName={SCHEDULE_APPOINTMENT_MODAL}
          previousModalName={modalName}
          lead={lead}
          listUpdateProtocol={this.props.listUpdateProtocol}
        />
        {classIntegrationId && this.props.currentModal === CLASS_APPOINTMENT_MODAL && (
          <ClassAppointmentModalContainer
            modalName={CLASS_APPOINTMENT_MODAL}
            lead={this.props.lead}
            listUpdateProtocol={this.props.listUpdateProtocol}
            classIntegrationId={classIntegrationId}
            classIntegrationName={classIntegrationName}
          />
        )}
        <DealModalContainer modalName={DEAL_MODAL} listUpdateProtocol={this.props.listUpdateProtocol} lead={lead} />
        <TrialSelectModal.TrialSelectModalContainer onTrialSelect={this.trialSelected} modalName={STARTED_TRIAL_MODAL} />
      </div>
    );
  }
}

RecordCallModal.propTypes = {
  lead: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  salesFunnel: PropTypes.object.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  recordCall: PropTypes.func,
  toggleComplete: PropTypes.func,
  call: PropTypes.object,
  classIntegrationId: PropTypes.number,
  classIntegrationName: PropTypes.string,
  currentModal: PropTypes.string,
  addLeadToTrial: PropTypes.func.isRequired,
};

export default RecordCallModal;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
