import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import notes from "../modules/notes";

import session from "modules/Stores/Session";
import modal from "modules/Stores/Modal";
const { createNote, createNoteForLead, updateNoteForLead } = notes.actions;
const { showModal, hideModal } = modal.actions;

function mapStateToProps(state, ownProps) {
  const { pathToLeadDetail } = ownProps;
  return {
    user: session.selectors.getUser(state),
    notes: notes.selectors.filteredNotes(state, pathToLeadDetail),
    loading: notes.selectors.getLoading(state, pathToLeadDetail),
    modalLoading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNote,
      createNoteForLead,
      updateNoteForLead,
      showModal,
      hideModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(notes.components.LeadNotesView);
