import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScheduleView from "../components/ScheduleView";
import * as actions from '../actions'
import * as reportSelectors from "../../reportsTable/selectors"

function mapStateToProps(state) {
  const selectedReport = reportSelectors.getSelectedAutomaticReport(state)
  return {
    selectedReport: selectedReport,
    selectedReportSchedule: selectedReport.automaticReportSchedule,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateAutomaticReportSchedule: actions.updateAutomaticReportSchedule
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleView);
