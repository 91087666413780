import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectCountryTableView from "./TableView/SelectCountryTableView";

import { ContentBlockView, EmptyView } from "modules/components";

export class MultiCountrySelectView extends Component {
  getCountriesWithPageNumber = pageNumber => {
    const { getCountries } = this.props;
    getCountries(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getCountriesWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getCountries } = this.props;
    getCountries(1, searchTerm);
  };

  render() {
    const { countries, loading, selectedCountryIds } = this.props;

    return (
      <ContentBlockView loading={loading} bordered>
        {countries.length > 0 && (
          <div>
            <SelectCountryTableView
              countries={countries}
              selectedCountryIds={selectedCountryIds}
              selectCountry={this.props.selectCountry}
              deselectCountry={this.props.deselectCountry}
            />
          </div>
        )}
        {countries.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
      </ContentBlockView>
    );
  }
}

MultiCountrySelectView.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectCountry: PropTypes.func.isRequired,
  deselectCountry: PropTypes.func.isRequired
};

export default MultiCountrySelectView;
