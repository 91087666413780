import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectCallScheduleTableRow from './SelectCallScheduleTableRow'

export class SelectCallScheduleTableView extends Component {

  render () {
    const { callSchedules, onCallScheduleSelect, selectedCallSchedule } = this.props
    const rows = callSchedules.map((callSchedule, index) => {
      const selectedId = (selectedCallSchedule) ? selectedCallSchedule.id : null
      return <SelectCallScheduleTableRow
        key={`CS-${callSchedule.id}`}
        callSchedule={callSchedule}
        selected={selectedId === callSchedule.id}
        onCallScheduleSelect={onCallScheduleSelect} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Steps</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SelectCallScheduleTableView.propTypes = {
  callSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCallScheduleSelect: PropTypes.func.isRequired,
  selectedCallSchedule: PropTypes.object
}

export default SelectCallScheduleTableView
