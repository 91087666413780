import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const RESET = NAME + '/RESET'
export const RELOAD_CALL_SCHEDULE = NAME + '/RELOAD_CALL_SCHEDULE'

export const SET_SHOWING = NAME + '/SET_SHOWING'
export const RELOAD_EMAIL_MAP = NAME + '/RELOAD_EMAIL_MAP'
export const UPDATE_FORM_DATA = NAME + '/UPDATE_FORM_DATA'

export const SET_LEAD_SOURCE_IDS = NAME + '/SET_LEAD_SOURCE_IDS'

export const SET_SELECTED_LOCATION_IDS = NAME + '/SET_SELECTED_LOCATION_IDS'

export const LOCATION_SEARCH_TERM_UPDATED = NAME + '/LOCATION_SEARCH_TERM_UPDATED'
export const LOCATION_CURRENT_PAGE_UPDATED = NAME + '/LOCATION_CURRENT_PAGE_UPDATED'
