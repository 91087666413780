import { NAME } from './constants';

export const RESET = NAME + '/RESET';
export const SET_LOADING = NAME + '/SET_LOADING';
export const SET_ERRORS = NAME + '/SET_ERRORS';

export const SET_USER = NAME + '/SET_USER';
export const SET_USERS = NAME + '/SET_USERS';

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED';
