import { isPresent } from 'utils/validation'

export function validatorForOption (option) {
  switch (option) {
    case 'calendly':
      return CalendlyValidator
    default:
      {}
  }
}

const CalendlyValidator = {
  apiKey: [{
    rule: isPresent,
    errorMessage: 'We need your API token for your Calendly account.'
  }],
  name: [{
    rule: isPresent,
    errorMessage: "Give your subscription a name."
  }]
}
