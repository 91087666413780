import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import EditTagModal from '../components/EditTagModal'
import modal from 'modules/Stores/Modal'

import tags from 'modules/components/Tags'

function mapStateToProps (state) {
  return {
    loading: tags.selectors.getLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    errors: tags.selectors.getErrors(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    updateTag: tags.actions.updateTag
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTagModal)
