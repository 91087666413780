/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class StageStepView extends Component {
  stageSelected = () => {
    const { sectionName, fieldName, onSelect, selected } = this.props;
    onSelect(sectionName, fieldName, !selected);
  };

  render() {
    const { color, title, selected, count } = this.props;
    const textColor = selected ? "#fff" : color;
    const itemBackgroundColor = selected ? color : "#fff";
    return (
      <a
        onClick={this.stageSelected}
        className="list-group-item"
        style={{ color: textColor, backgroundColor: itemBackgroundColor }}
      >
        <span className="badge" style={{ backgroundColor: color }}>
          {count}
        </span>
        {title}
      </a>
    );
  }
}

StageStepView.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};
