import { request, GET, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

// FETCH New Leads
export const fetchInRange = (startDate, endDate, pageNumber, location) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setTasks(payload.lead_actions));
      dispatch(loadPageDetails(payload.meta));
    }

    let queryString = 'actions_for_user?start_date=' + startDate + '&end_date=' + endDate + '&completed=false&location_id=' + location.id;

    if (pageNumber) {
      queryString = queryString + '&page[number]=' + pageNumber;
    }
    return request(queryString, GET, null, onSuccess, onFailure);
  };
};

export const completeTask = (task, user) => {
  return (dispatch) => {
    const body = {
      lead_action: {
        completing_user_id: user.id,
        completed: true,
      },
    };
    dispatch(setLoading(true));

    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(removeTaskWithId(task.id));
      notify.info('Task completed.');
    }

    return request('leads/' + task.lead.id + '/lead_actions/' + task.id, PUT, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setTasks(tasks) {
  return {
    type: actionTypes.SET_TASKS,
    tasks,
  };
}

function loadPageDetails(pageDetails, todoType) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
    todoType,
  };
}

function removeTaskWithId(taskId) {
  return {
    type: actionTypes.REMOVE_TASK_WITH_ID,
    taskId,
  };
}
