import styled, { css } from 'styled-components';

import {
  color,
  display,
  layout,
  space,
  overflow,
  flexbox,
  position,
  border,
  shadow,
  typography,
  background,
  grid,
  opacity,
} from 'styled-system';

import { BoxProps } from './Box';

export const Form = styled.form<BoxProps>`
  display: ${(props) => (props.isFlex ? `flex` : props.display)};
  cursor: ${(props) => props.cursor && props.cursor};
  box-sizing: border-box;
  ${display}
  ${opacity}
  ${color}
  ${layout}
  ${space}
  ${overflow}
  ${flexbox}
  ${grid}
  ${border}
  ${shadow}
  ${position}
  ${typography}
  ${background}
  transition: opacity 0.1s ease-in-out;
  ${(props) =>
    props.backgroundShadow &&
    css`
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s ease-in-out;
    `}
  ${(props) =>
    props.centerRow &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `}
  ${(props) =>
    props.w100 &&
    css`
       {
        width: 100%;
      }
    `}
  ${(props) =>
    props.h100 &&
    css`
       {
        height: 100%;
      }
    `}  
  ${(props) =>
    props.s100 &&
    css`
       {
        width: 100%;
        height: 100%;
      }
    `}        
  ${(props) =>
    props.row &&
    css`
       {
        display: flex;
        flex-direction: row;
      }
    `}
  ${(props) =>
    props.column &&
    css`
       {
        flex-direction: column;
      }
    `}    
  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}  
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.25;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    `}
  ${(props) =>
    props.hoverFill &&
    css`
      &:hover {
        background-color: ${props.hoverFill};
      }
    `}
  ${(props) =>
    props.focusFill &&
    css`
      &:focus {
        background-color: ${props.focusFill};
      }
    `}
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}    
  ${(props) =>
    props.aCenter &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.aStart &&
    css`
      align-items: flex-start;
    `}    
  ${(props) =>
    props.aEnd &&
    css`
      align-items: flex-end;
    `}
  ${(props) =>
    props.jStart &&
    css`
      justify-content: flex-start;
    `}        
  ${(props) =>
    props.jCenter &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.jEnd &&
    css`
      justify-content: flex-end;
    `}
  ${(props) =>
    props.jSpaceBtw &&
    css`
      justify-content: space-between;
    `}
  ${(props) =>
    props.jSpaceAround &&
    css`
      justify-content: space-around;
    `}   
  ${(props) =>
    props.doesWrap &&
    css`
      flex-wrap: wrap;
    `}
`;

Form.displayName = 'Form';
