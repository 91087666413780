import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView } from 'modules/components'

export class TurnOnOptInView extends Component {

  turnOnOptIn = () => {
    const { turnOnOptInForLocation, location } = this.props
    turnOnOptInForLocation(location)
  }

  render () {
    const { loading, location } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const infoString = 'Enhanced Privacy lets a location follow best practise ' +
    'and keep up to date with the latest global regulations around data privacy, including the GDPR. ' +
    'Opt in restricts marketing communications only to leads that have expliclty opted in to receiving markeing. ' +
    'and you must keep a lawful basis to store and process a leads data.'
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Enhanced privacy for { location.admin_display_name }</h3>
          </div>
          <div className='block-content push'>
            <EmptyView
              colorThemeClass='primary'
              iconClass='fa fa-warning text-warning'
              title='Enhanced privacy is turned off'
              subtitle={infoString}
              buttonText='Enable Enhanced Privacy'
              buttonSelected={this.turnOnOptIn} />
          </div>
        </div>
      </div>
    )
  }
}

TurnOnOptInView.propTypes = {
  location: PropTypes.object.isRequired,
  turnOnOptInForLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default TurnOnOptInView
