import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validators'

const SECURITY_POLICY_HANDLERS = {
  [actionTypes.PREFILL_FORM_DATA]: (state, action) => {
    return initialStateForSecurityPolicy(action.securityPolicy)
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    const newData = action.data
    return { ...state, data: newData }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForSecurityPolicy()
  }
}

const payloadKeys = {
  passwordComplexityEnabledAt: 'password_complexity_enabled_at',
  minPasswordLength: 'min_password_length',
  minPasswordLetters: 'min_password_letters',
  minPasswordNumbers: 'min_password_numbers',
  minPasswordSpecial: 'min_password_special',
  passwordCanContainUsername: 'password_can_contain_username',
  disableUserAccountAfterDays: 'disable_user_account_after_days',
  lockScreenAfter: 'lock_screen_after',
  maximumNumberOfAttempts: 'maximum_number_of_attemps',
  previousPasswordsToPrevent: 'previous_passwords_to_prevent',
  allowRememberMe: 'allow_remember_me',
  rememberMeLength: 'remember_me_length',
  passwordExpiresAfterMonths: 'password_expires_after_months'
}

function initialStateForSecurityPolicy (securityPolicy) {
  const fields = generateFieldsForKeys(securityPolicy, fieldNames, payloadKeys, validator)
  return {
    loading: false,
    errors: null,
    showing: false,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function reducer (state = initialStateForSecurityPolicy(null), action) {
  const handler = SECURITY_POLICY_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
