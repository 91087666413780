import PropTypes from "prop-types";
import React, { Component } from "react";

import { errorPanelForErrors } from "utils/formHelpers";

import CommunicationPreferencesFormContainer from "../containers/CommunicationPreferencesFormContainer";

export class CommunicationPreferencesView extends Component {
  UNSAFE_componentWillMount() {
    const { getCommunicationPreferences } = this.props;
    getCommunicationPreferences();
  }

  render() {
    const { loading, errors } = this.props;
    const blockStyle = loading ? "block-opt-refresh" : "";
    return (
      <div>
        <div className={"block block-rounded block-bordered " + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Communication Preferences</h3>
          </div>
          <div className="block-content">
            <form className="form-horizontal push-5-t">
              {errorPanelForErrors(errors)}
              <CommunicationPreferencesFormContainer />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CommunicationPreferencesView.propTypes = {
  getCommunicationPreferences: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
};
