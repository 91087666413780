import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { colors } from 'styleguide';
import { Box, Text, Button, GymLeadsLoader } from 'modules/components';
import { IThirdPartyMembership, ITrial, ITrialMembershipMapping } from 'types';
import * as mindbodySelectors from '../../selectors';
import * as mindbodyActions from '../../actions';

interface MembershipsMappingViewProps {
  integrationId: number;
  getTrialsMembershipsMappings: (integrationId: Number) => void;
  getMemberships: (integrationId: Number) => void;
  getTrials: () => void;
  mapTrialToMembership: (integrationdId: number, membershipId: number, trialId: number) => void;
  deleteTrialMembershipMapping: (integrationdId: number, mappingId: number) => void;
  trials: ITrial[];
  memberships: IThirdPartyMembership[];
  trialsMembershipsMappings: ITrialMembershipMapping[];
}

interface IExistingMappings {
  display: string;
  thirdPartyIntegrationId: number;
  mappingId: number;
}

export const MembershipsMappingView: FC<MembershipsMappingViewProps> = (props) => {
  const {
    integrationId,
    getTrialsMembershipsMappings,
    getTrials,
    getMemberships,
    mapTrialToMembership,
    memberships,
    trials,
    trialsMembershipsMappings,
    deleteTrialMembershipMapping,
  } = props;

  const [filteredMemberships, setFilteredMemberships] = useState<IThirdPartyMembership[]>([]);
  const [existingMappings, setExistingMappings] = useState<IExistingMappings[]>([]);
  const [selectedTrial, setSelectedTrial] = useState<ITrial | undefined>();
  const [selectedMembership, setSelectedMembership] = useState<IThirdPartyMembership | undefined>();
  const [membershipsLoading, setMembershipsLoading] = useState(false);
  const [trialsLoading, setTrialsLoading] = useState(false);
  const [mappingLoading, setMappingsLoading] = useState(false);

  useEffect(() => {
    fetchMappings();
    fetchTrials();
    fetchMemberships();
    // eslint-disable-next-line
  }, []);

  const fetchMemberships = async () => {
    setMembershipsLoading(true);
    await getMemberships(integrationId);
    setMembershipsLoading(false);
  };

  const fetchTrials = async () => {
    setTrialsLoading(true);
    await getTrials();
    setTrialsLoading(false);
  };

  const fetchMappings = async () => {
    setMappingsLoading(true);
    await getTrialsMembershipsMappings(integrationId);
    setMappingsLoading(false);
  };

  function handleMembershipClick(membership: IThirdPartyMembership) {
    if (selectedMembership?.id === membership.id) {
      setSelectedMembership(undefined);
      return;
    }

    setSelectedMembership(membership);
  }

  const handleTrialClick = async (trial: ITrial) => {
    if (trial.id === selectedTrial?.id) {
      setSelectedTrial(undefined);
      return;
    }
    setSelectedTrial(trial);
  };

  const saveMappings = async () => {
    if (!selectedMembership || !selectedTrial) {
      return;
    }
    setMappingsLoading(true);
    await mapTrialToMembership(integrationId, selectedMembership.id, selectedTrial.id);
    setMappingsLoading(false);
  };

  const removeTrialMapping = async (mappingId: number) => {
    setMappingsLoading(true);
    deleteTrialMembershipMapping(integrationId, mappingId);
    setMappingsLoading(false);
  };

  useEffect(() => {
    const trialsMap = new Map<number, ITrial>();
    const membershipsMap = new Map<number, IThirdPartyMembership>();
    const mappedMembershipsIds: number[] = [];
    const preparedExistingMappings: IExistingMappings[] = [];

    trials.forEach((trial) => {
      trialsMap.set(Number(trial.id), trial);
    });

    memberships.forEach((membership) => {
      membershipsMap.set(Number(membership.id), membership);
    });

    trialsMembershipsMappings.forEach((trialMembershipMapping) => {
      if (membershipsMap.has(Number(trialMembershipMapping.membership_id)) && trialsMap.has(Number(trialMembershipMapping.trial_id))) {
        mappedMembershipsIds.push(Number(trialMembershipMapping.membership_id));
        preparedExistingMappings.push({
          display: `${membershipsMap.get(trialMembershipMapping.membership_id)?.name} - ${
            trialsMap.get(trialMembershipMapping.trial_id)?.name
          }`,
          thirdPartyIntegrationId: trialMembershipMapping.third_party_integration_id,
          mappingId: trialMembershipMapping.id,
        });
      }
    });

    setExistingMappings(preparedExistingMappings);
    setFilteredMemberships(memberships.filter((membership) => !mappedMembershipsIds.includes(membership.id * 1)));
  }, [trialsMembershipsMappings, trials, memberships]);

  const canAllocateMapping = selectedMembership && selectedTrial;

  return (
    <Box>
      <Text.H3>Trial Membership Mapping:</Text.H3>
      <Text.PS>
        Mapping a MINDBODY subscription to a GymLeads trial lets us know that any client that purchases this memberships should be allocated
        to a trial instead of a deal. Any lead that purcahses these memberships will be allocated to the trial and kept in the sales funnel
        instead of being closed and allocated to a deal.
      </Text.PS>
      <Box row>
        <Box width={[1 / 2]} maxHeight={400} overflowY="scroll">
          <Text.P1>MBO Memberships:</Text.P1>
          <GymLeadsLoader active={membershipsLoading} />
          {filteredMemberships.map((membership) => {
            const isSelected = selectedMembership?.id === membership.id;
            return (
              <ListElement
                key={membership.id}
                onClick={() => handleMembershipClick(membership)}
                selected={isSelected}
                selectable={isSelected}
              >
                {membership.name}
              </ListElement>
            );
          })}
        </Box>
        <Box width={[1 / 2]}>
          <Text.P1>Trials:</Text.P1>
          <GymLeadsLoader active={trialsLoading} />
          {trials.map((trial, index) => (
            <ListElement key={trial.id} onClick={() => handleTrialClick(trial)} selected={selectedTrial?.id === trial.id} selectable>
              {`${index + 1}. ${trial.name}`}
            </ListElement>
          ))}
        </Box>
      </Box>
      <Box row width="100%" justifyContent="center" mt="10px" mb="15px">
        <Button.Secondary width="80%" onClick={() => saveMappings()} disabled={!canAllocateMapping}>
          Allocate MINDBODY membership to GymLeads trial
        </Button.Secondary>
      </Box>
      <hr />
      <Text.P1>Existing Mappings:</Text.P1>
      <GymLeadsLoader active={mappingLoading} />
      <Box>
        {existingMappings.length === 0 && <Text.PS>No Existing Mappings</Text.PS>}
        {existingMappings.length > 0 && (
          <>
            {existingMappings.map((mapping) => (
              <Box row width="90%" key={mapping.mappingId}>
                <Text.PS>{mapping.display}</Text.PS>
                <i
                  className="fa fa-remove pull-right fa-2x selectable"
                  style={{ color: colors.red[2], marginLeft: 'auto' }}
                  onClick={() => removeTrialMapping(mapping.mappingId)}
                />
              </Box>
            ))}
          </>
        )}
      </Box>
      <hr />
    </Box>
  );
};

interface IListElementProps {
  selected?: boolean;
  selectable?: boolean;
}

const ListElement = styled.p<IListElementProps>`
  margin-left: 15px;
  font-weight: ${(props) => (props.selected ? 'bold' : '300')};
  cursor: pointer;
`;

function mapStateToProps(state: MembershipsMappingViewProps) {
  return {
    trials: mindbodySelectors.getTrials(state),
    memberships: mindbodySelectors.getMemberships(state),
    trialsMembershipsMappings: mindbodySelectors.getTrialsMembershipsMappings(state),
  };
}
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      getTrialsMembershipsMappings: mindbodyActions.getTrialsMembershipsMappings,
      getTrials: mindbodyActions.getTrials,
      getMemberships: mindbodyActions.getIntegrationMemberships,
      mapTrialToMembership: mindbodyActions.mapTrialToMembership,
      deleteTrialMembershipMapping: mindbodyActions.removeTrialToMembershipMapping,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsMappingView);
