import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const fetchEmailMapsForPageNumber = (pageNumber, searchTerm) => {
  return dispatch => {
    dispatch(startLoading())
    function onFailure (errors) {
      dispatch(updateFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(stopLoading())
      dispatch(setEmailMaps(payload.email_maps))
      dispatch(loadPageDetails(payload.meta))
    }

    let url = 'email_maps?page[number]=' + pageNumber
    if (searchTerm && searchTerm.length > 0) {
      url = url + '&search=' + searchTerm
    }

    return request(url, GET, null, onSuccess, onFailure)
  }
}

function startLoading () {
  return {
    type: actionTypes.LOADING,
    loading: true
  }
}

function stopLoading () {
  return {
    type: actionTypes.LOAD_SUCCESS,
    loading: false
  }
}

function updateFailed (errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors
  }
}

function setEmailMaps (emailMaps) {
  return {
    type: actionTypes.SET_EMAIL_FORMS,
    emailMaps
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function selectEmailMapId (selectedEmailMapId) {
  return {
    type: actionTypes.SET_SELECTED_EMAIL_FORM_ID,
    selectedEmailMapId
  }
}

// list updates
export function removeEmailMapWithId (emailMapId) {
  return {
    type: actionTypes.DELETE_EMAIL_FORM,
    emailMapId
  }
}

export function addEmailMap (emailMap) {
  return {
    type: actionTypes.ADD_EMAIL_FORM,
    emailMap
  }
}

export function updateEmailMap (emailMap) {
  return {
    type: actionTypes.UPDATE_EMAIL_FORM,
    emailMap
  }
}

export function setSearchTerm (searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  }
}
