/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { TextSearchView, PagesView } from 'modules/components';

export class MigrateLeadSourceModal extends Component {
  state = {
    toId: null,
  };

  getLeadSourcesWithPageNumber = (pageNumber) => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  handleSelect = (value) => {
    this.setState({ ...this.state, toId: value });
  };

  migrateClicked = () => {
    const { leadSource, deleteLeadSource } = this.props;
    const { toId } = this.state;
    deleteLeadSource(leadSource.id, toId);
  };

  cleanUpAndHide = () => {
    this.setState({ toId: null });
    this.props.hideModal();
  };

  render() {
    const { loading, currentModal, modalName, leadSource, leadSources, errors, pageDetails } = this.props;
    const pageCount = pageDetails.pageCount;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanUpAndHide}>
          <div className={blockStyle}>
            <div className="block-header bg-danger">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanUpAndHide}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Migrate Lead Source
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <div className="row">
                  <div className="col-xs-12">
                    {errorPanelForErrors(errors)}
                    <p>This will delete the lead source {leadSource.source_name} and move all leads to the selected source.</p>
                  </div>
                  <div className="row" style={{ marginTop: '-30px' }}>
                    <TextSearchView sectionName={'leadSourceSearch'} onUpdate={this.searchUpdated} />
                  </div>
                </div>
                <div className="row">{leadSourceOptions(leadSources, this.handleSelect, this.state.toId)}</div>
              </div>
            </div>
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageClicked={this.pageClicked} pageDetails={this.props.pageDetails} />
              </div>
            )}
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndHide}>
              Cancel
            </button>
            <button
              className="btn btn-danger push-5-r push-10"
              type="button"
              disabled={this.state.toId === null}
              onClick={this.migrateClicked}
            >
              <i className="fa fa-cross" /> Migrate & Delete Lead Source
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

MigrateLeadSourceModal.propTypes = {
  leadSource: PropTypes.object.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  deleteLeadSource: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default MigrateLeadSourceModal;

const leadSourceOptions = (leadSources, onPress, selectedId) => {
  return leadSources.map((leadSource, index) => {
    const bgStyle = leadSource.id === selectedId ? 'bg-danger-light' : 'bg-danger';
    return (
      <div key={index} className="col-lg-6">
        <a className="block block-link-hover2 selectable" onClick={() => onPress(leadSource.id)}>
          <div className={'block-content block-content-full clearfix ' + bgStyle}>
            <span className="h4 text-white-op">{leadSource.source_name}</span>
          </div>
        </a>
      </div>
    );
  });
};
