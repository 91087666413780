import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import RatesView from '../components/RatesView'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    ratesDataSets: selectors.ratesDataSets(state),
    ratesPieChartData: selectors.ratesPieChartData(state),
    tableData: selectors.ratesTableData(state),
    labels: selectors.dataLabels(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RatesView)
