/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class SubscribeView extends Component {
  render() {
    const calendarLink = 'https://api.gymleads.net/calendars/' + this.props.user.public_uuid + '.ics';
    const mobileconfigLink = 'https://api.gymleads.net/subscription_profile/' + this.props.user.public_uuid + '.mobileconfig';
    return (
      <div>
        <div className="block block-rounded block-bordered">
          <div className="block-header">
            <h3 className="block-title">Subscribe to calendar</h3>
          </div>
          <div className="block-content">
            <p>You can view your appointments and call schedule in any calendar app that supports the ical format.</p>
            <p>Your personalized URL is:</p>
            <a className="text-primary">{calendarLink}</a>
            <p>Refer to our knowledge base for detailed instructions on how to set this up on your phone or computer</p>
            <br />
            {this.props.isMobile && (
              <div className="row">
                <div className="col-xs-6 col-sm-4">
                  <a href={mobileconfigLink} className="block block-link-hover3 text-center block-bordered">
                    <div className="block-content block-content-full">
                      <i className="fa fa-apple fa-4x text-primary-darker" />
                      <div className="font-w600 push-15-t">Install calendar for iPhone/iPad</div>
                    </div>
                  </a>
                </div>
              </div>
            )}
            <br />
          </div>
        </div>
      </div>
    );
  }
}

SubscribeView.propTypes = {
  user: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default SubscribeView;
