import config from 'config';
import { ToolTip } from 'modules/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class LeadSyncStatusView extends Component {
  parseErrorArrayToString(errors) {
    if (!errors) {
      return 'This lead has errors. Try adding more details';
    }
    let string = 'Fix the following errors: \n';
    errors.forEach((errorString) => {
      string = string + errorString;
    });
    return string;
  }

  render() {
    const { leadSyncStatus, syncing } = this.props;
    let color = '';
    let element = <p />;
    if (leadSyncStatus.in_sync) {
      color = '#46c37b';
      element = <i className="fa fa-plug" />;
    } else if (leadSyncStatus.service_id && leadSyncStatus.in_sync === false) {
      color = '#f3b760';
      element = (
        <span>
          {' '}
          <i className="fa fa-plug" />
          Out of sync <ToolTip text={this.parseErrorArrayToString(leadSyncStatus.service_errors)} id="leadSyncStatusErrors" />
        </span>
      );
    } else {
      color = '#d26a5c';
      element = (
        <span>
          {' '}
          <i className="fa fa-plug" />
          Never synced <ToolTip text={this.parseErrorArrayToString(leadSyncStatus.service_errors)} id="leadSyncStatusErrors" />
        </span>
      );
    }

    const getTpiLink = () => {
      if (tpiName === 'mindbody' && tpiLeadId) {
        return `https://clients.mindbodyonline.com/asp/adm/main_info.asp?id=${tpiLeadId}`;
      }
      if (tpiName === 'journey' && tpiLeadId) {
        return `${config.journeyHost}/dashboard/members/${tpiLeadId}/home`;
      }
    };

    const tpiName = leadSyncStatus.third_party_integration_name;
    const tpiLeadId = leadSyncStatus.service_id;
    const tpiLeadLink = getTpiLink();

    return (
      <span>
        {syncing && (
          <span className="label" style={{ backgroundColor: '#989898' }}>
            <span>
              <i className="fa fa-cog fa-spin" /> Syncing
            </span>
          </span>
        )}
        {!syncing && !tpiLeadLink && (
          <span className="label" style={{ backgroundColor: color }}>
            {element}
          </span>
        )}
        {!syncing && tpiLeadId && tpiLeadLink && (
          <a href={tpiLeadLink} target="_blank" rel="noopener noreferrer" className="label" style={{ backgroundColor: color }}>
            {element}
          </a>
        )}
      </span>
    );
  }
}

LeadSyncStatusView.propTypes = {
  syncing: PropTypes.bool.isRequired,
  leadSyncStatus: PropTypes.object.isRequired,
};

export default LeadSyncStatusView;
