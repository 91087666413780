import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getSecurityPolicy = () => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setSecurityPolicy(payload.security_policy))
    }

    return request('security_policies', GET, null, onSuccess, onFailure)
  }
}

export function setSecurityPolicy (securityPolicy) {
  return {
    type: actionTypes.SET_SECURITY_POLICY,
    securityPolicy
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}
