import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import NewScheduleStepModal from "../components/NewScheduleStepModal";
import modal from "modules/Stores/Modal";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { NEW_CALL_SCHEDULE_STEP_MODAL } from "../constants";

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    numberOfSteps: selectors.getSteps(state).length,
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: NEW_CALL_SCHEDULE_STEP_MODAL,
    errors: modal.selectors.getModalErrors(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      createCallScheduleStep: actions.createCallScheduleStep
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewScheduleStepModal);
