import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { InfoView } from 'modules/components';

import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import SweetAlert from 'react-bootstrap-sweetalert';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';

require('./styles.css');
require('react-big-calendar/lib/css/react-big-calendar.css');

const localizer = momentLocalizer(moment);

export class UpdateScheduleModalView extends Component {
  state = {
    showDeleteWarning: false,
  };

  UNSAFE_componentWillMount() {
    const { setScheduleSlots, schedule } = this.props;
    const scheduleSlots = schedule ? schedule.schedule_dates : [];
    setScheduleSlots(scheduleSlots);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentId = this.props.schedule ? this.props.schedule.id : -1;
    const nextId = nextProps.schedule ? nextProps.schedule.id : -1;

    if (currentId !== nextId) {
      const scheduleSlots = nextProps.schedule ? nextProps.schedule.schedule_dates : [];
      nextProps.setScheduleSlots(scheduleSlots);
    }
  }

  showDeleteWarningPopup = () => {
    this.setState({ ...this.state, showDeleteWarning: true });
  };

  deleteSchedule = () => {
    const { schedule, deleteSchedule } = this.props;
    deleteSchedule(schedule.id);
    this.dismissPopups();
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showDeleteWarning: false });
  };

  onSelectEvent = (event) => {
    this.props.removeSlotFromSchedule(event);
  };

  onSelectSlot = (slotInfo) => {
    const utcSlotInfo = {
      start: moment(slotInfo.start).utc().toDate(),
      end: moment(slotInfo.end).utc().toDate(),
    };
    this.props.addSlotToSchedule(utcSlotInfo);
  };

  adjustedScheduleSlots = () => {
    const { scheduleSlots, timezone } = this.props;

    return scheduleSlots.map((scheduleSlot) => {
      const startMoment = moment(scheduleSlot.start);
      const endMoment = moment(scheduleSlot.end);

      const adjustedStartMoment = moment()
        .day(startMoment.day())
        .hour(startMoment.hour())
        .minute(startMoment.minute())
        .second(0)
        .milliseconds(0)
        .tz(timezone);
      const adjustedEndMoment = moment()
        .day(endMoment.day())
        .hour(endMoment.hour())
        .minute(endMoment.minute())
        .second(0)
        .milliseconds(0)
        .tz(timezone);

      return {
        start: adjustedStartMoment.toDate(),
        end: adjustedEndMoment.toDate(),
      };
    });
  };

  updateSchedule = () => {
    const { schedule, scheduleSlots, updateSchedule, user, location } = this.props;
    const scheduleId = schedule ? schedule.id : null;
    updateSchedule(user, location, scheduleSlots, scheduleId);
  };

  render() {
    const { location, user, loading, currentModal, hideModal, modalName } = this.props;

    const CustomToolbar = () => {
      return <div />;
    };

    const startTime = moment().subtract(4, 'week');
    const min = new Date();
    min.setHours(8);
    min.setMinutes(0, 0, 0);

    let formats = {
      dayFormat: 'dddd',
    };

    const infoString = 'Click and drag to select ' + user.given_name + "'s availability for a week. Click on the slot to remove it.";

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal} dialogClassName="modal-lg">
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Update availability for {user.display_name} at {location.admin_display_name}
              </h3>
            </div>
            <div className="block-content">
              <InfoView colorThemeClass={'info'} title={'Set schedule'} detail={infoString} />
              <div className="row">
                <div className="col-xs-12" id="modal-calendar-schedule">
                  <Calendar
                    localizer={localizer}
                    timeslots={2}
                    culture="en"
                    scrollToTime={startTime.toDate()}
                    defaultView="week"
                    events={this.adjustedScheduleSlots()}
                    formats={formats}
                    components={{ toolbar: CustomToolbar }}
                    onSelectEvent={this.onSelectEvent}
                    onSelectSlot={this.onSelectSlot}
                    selectable={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            {this.props.schedule && (
              <button className="btn btn-danger pull-left" onClick={this.showDeleteWarningPopup}>
                <i className="fa fa-times" /> Delete
              </button>
            )}
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn btn-primary push-5-r push-10" type="button" onClick={this.updateSchedule}>
              <i className="fa fa-calendar" /> Update availability
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showDeleteWarning && (
          <div>
            <SweetAlert
              warning
              showCancel
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="warning"
              confirmBtnText="Delete schedule"
              title="Are you Sure?"
              onConfirm={this.deleteSchedule}
              onCancel={this.dismissPopups}
            >
              This user will be shown as always available
            </SweetAlert>
          </div>
        )}
      </div>
    );
  }
}

UpdateScheduleModalView.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  schedule: PropTypes.object,
  setScheduleSlots: PropTypes.func.isRequired,
  addSlotToSchedule: PropTypes.func.isRequired,
  removeSlotFromSchedule: PropTypes.func.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  deleteSchedule: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  scheduleSlots: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
};

export default UpdateScheduleModalView;
