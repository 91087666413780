import { NAME } from './constants'

export const FETCHING_TODOS = NAME + '/FETCHING_TODOS'
export const FETCHING_TODOS_SUCCESS = NAME + '/FETCHING_TODOS_SUCCESS'
export const FETCHING_TODOS_FAILURE = NAME + '/FETCHING_TODOS_FAILURE'

export const UPDATING_TODO = NAME + '/UPDATING_TODO'
export const UPDATING_TODO_SUCCESS = NAME + '/UPDATING_TODO_SUCCESS'
export const UPDATING_TODO_FAILURE = NAME + '/UPDATING_TODO_FAILURE'

export const ADD_TODO = NAME + '/ADD_TODO'
export const TODO_DELETED = NAME + '/TODO_DELETED'
