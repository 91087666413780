import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import selectPaymentMethodModal from '../components/SelectPaymentMethodModal'

import modal from 'modules/Stores/Modal'
import billing from 'modules/Stores/Billing'

import * as actions from '../actions'

function mapStateToProps (state, ownProps) {
  let paymentMethods
  if (ownProps.idValue === 'sms_paychoice_payment_method_id') {
    paymentMethods = billing.selectors.smsPaymentMethods(state)
  } else if (ownProps.idValue === 'subscription_paychoice_payment_method_id') {
    paymentMethods = billing.selectors.subscriptionPaymentMethods(state)
  }
  return {
    loading: state.modal.loading,
    currentModal: modal.selectors.getCurrentModal(state),
    paymentMethods: paymentMethods
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateLocationPaymentMethod: actions.updateLocationPaymentMethod,
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(selectPaymentMethodModal)
