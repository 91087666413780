import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = state => state.reallocations[NAME].loading
export const getErrors = state => state.reallocations[NAME].errors
export const getFromUser = state => state.reallocations[NAME].fromUser
export const getPotentialUsers = state => state.reallocations[NAME].potentialUsers
export const getToUserIds = state => state.reallocations[NAME].toUserIds
export const getPageDetails = state => state.reallocations[NAME].pageDetails

export const isReady = createSelector(
  [ getFromUser, getToUserIds ],
  (fromUser, toUserIds) => {
    if (!fromUser) return false
    if (!toUserIds || toUserIds.length === 0) return false
    return true
  }
)
