import LocalizedStrings from 'react-localization';
import en from './en';
import fr from './fr';

const STRINGS = new LocalizedStrings({
  'en-US': en,
  fr: fr,
  en: en,
});

export default STRINGS;
