import PropTypes from "prop-types";
import React, { Component } from "react";

export class SimpleImageEmptyView extends Component {
  render() {
    const { image, title, subtitle, height } = this.props;
    const heightString = height + "px";
    return (
      <div className="text-center">
        <img
          src={image}
          alt="empty placeholder"
          style={{ height: heightString, marginBottom: "20px" }}
        />
        <h4 style={{ marginBottom: "5px" }}>{title}</h4>
        <p style={{ marginBottom: "15px" }}>{subtitle}</p>
      </div>
    );
  }
}

SimpleImageEmptyView.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  height: PropTypes.number,
};

SimpleImageEmptyView.defaultProps = {
  height: 200,
};

export default SimpleImageEmptyView;
