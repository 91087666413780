/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import selectLocation from "../modules/selectLocation";
import selectUsers from "../modules/selectUsers";
import confirm from "../modules/confirm";

import previousReallocations from "../modules/previousReallocations";

import WizardButtonsView from "./WizardButtonsView";

export class BulkLeadReallocationsView extends Component {
  state = {
    titles: ["1. Select Location", "2. Select Users", "3. Confirm"],
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { tabIndex } = this.props;
    const { titles } = this.state;

    const tabs = titles.map((title, index) => {
      const activeClassName = tabIndex === index ? "active" : "";
      return (
        <li className={activeClassName} key={index}>
          <a style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
            {" "}
            {title}
          </a>
        </li>
      );
    });

    const width = (((tabIndex + 1) * 1.0) / titles.length) * 100;

    const currentContainer = () => {
      switch (tabIndex) {
        case 0:
          return <selectLocation.SelectLocationContainer />;
        case 1:
          return <selectUsers.SelectUsersContainer />;
        case 2:
          return <confirm.ConfirmContainer />;
        default:
          return <div />;
      }
    };

    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="js-wizard-simple block">
              <ul className="nav nav-tabs nav-justified">{tabs}</ul>

              <form
                className="form-horizontal"
                action="base_forms_wizard.html"
                method="post"
              >
                <div className="block-content block-content-mini block-content-full border-b">
                  <div className="wizard-progress progress progress-mini remove-margin-b">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      style={{ width: width + "%" }}
                    />
                  </div>
                </div>

                <div className="block-content tab-content">
                  {currentContainer()}
                </div>
              </form>

              <WizardButtonsView {...this.props} />
            </div>
          </div>
        </div>
        {tabIndex === 0 && (
          <div className="row">
            <div className="col-xs-12">
              <previousReallocations.PreviousImportsContainer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

BulkLeadReallocationsView.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  nextIsReady: PropTypes.bool.isRequired,
  incrementTabIndex: PropTypes.func.isRequired,
  decrementTabIndex: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default BulkLeadReallocationsView;
