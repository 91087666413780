import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapSalespeopleView from '../components/MapSalespeopleView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import selectCSV from '../../selectCSV'

function mapStateToProps (state) {
  const locationId = selectCSV.selectors.getLocationId(state)
  return {
    currentLocationId: locationId,
    gymleadsSalespeople: selectors.getPotentialUsers(state),
    salespeopleToMap: selectors.getSalespeople(state),
    salespeopleMaps: selectors.getSalespeopleMaps(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addSalespersonMap: actions.addSalespersonMap,
    updateSalespersonMap: actions.updateSalespersonMap
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MapSalespeopleView)
