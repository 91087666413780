import React, { FC } from 'react';

import { Box } from 'modules/components';

interface EmptyViewProps {
  onAllLocationsClicked: () => void;
  onSpecificLocationsClicked: () => void;
}

export const EmptyView: FC<EmptyViewProps> = (props) => {
  return (
    <Box isFlex column w100>
      <h2 className="push-10">Get started with Front Desk</h2>
      <p>Setup a branded check in experience for your guests. Collect waiver signatures, track visits and more with Front Desk.</p>
      <Box width={230} column isFlex>
        <button className={'btn btn-minw btn-rounded btn-noborder btn-success push-10'} onClick={props.onAllLocationsClicked}>
          Setup for all locations
        </button>
        <button className={'btn btn-minw btn-rounded btn-noborder btn-success push-10'} onClick={props.onSpecificLocationsClicked}>
          Setup for specific locations
        </button>
      </Box>
    </Box>
  );
};
