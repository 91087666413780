import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getSalesTeam = state => state.dashboard[NAME].salesTeam
export const getLoading = state => state.dashboard[NAME].loading
export const getErrors = state => state.dashboard[NAME].errors
export const getSelectedUserId = state => state.dashboard[NAME].selectedUserId

export const selectedUser = createSelector(
  [ getSalesTeam, getSelectedUserId ],
  (salesTeam, selectedUserId) => {
    if (salesTeam && salesTeam.length > 0) {
      const index = salesTeam.findIndex(
        salesTeamMember => parseInt(salesTeamMember.user.id) === parseInt(selectedUserId)
      )
      return salesTeam[index].user
    }
  }
)
