import PropTypes from "prop-types";
import React, { Component } from "react";

import ThirdPartyIntegrationSelect from "modules/components/ThirdPartyIntegrationSelect";
import FormPostExportContainer from "../containers/FormPostExportContainer";
import { EXPORT_LEAD_MODAL_NAME } from "../constants";

export class ExportLeadView extends Component {
  exportOptionSelected = (exportOption) => {
    const {
      lead,
      exportLeadAsCSV,
      getPostUrlAndData,
      exportLead,
      listUpdateProtocol,
    } = this.props;

    switch (exportOption.name) {
      case "csv":
        exportLeadAsCSV(lead);
        break;
      case "mindbody":
      case "gymmaster":
      case "myma":
      case "one_fit_stop":
      case "booker":
      case "perfect_gym":
      case "glofox":
      case "gymvue":
      case "clubmanager":
        exportLead(null, null, lead, listUpdateProtocol, exportOption);
        break;
      case "snapp_dd":
        getPostUrlAndData(lead, exportOption);
        break;
      default:
        break;
    }
  };

  exportClicked = () => {
    this.props.showModal(EXPORT_LEAD_MODAL_NAME);
  };

  handlePostSubmit = (event) => {};

  render() {
    const { locationId, formPostData } = this.props;
    return (
      <span>
        <button
          type="button"
          className="btn btn-info"
          onClick={this.exportClicked}
        >
          <i className="glyphicon glyphicon-export" /> Export
        </button>
        <ThirdPartyIntegrationSelect.ThirdPartyIntegrationSelectModalContainer
          title={"Select Export Option"}
          modalName={EXPORT_LEAD_MODAL_NAME}
          onThirdPartyIntegrationSelect={this.exportOptionSelected}
          locationId={locationId}
        />
        {formPostData && (
          <FormPostExportContainer formPostData={formPostData} />
        )}
      </span>
    );
  }
}

ExportLeadView.propTypes = {
  lead: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  exportLead: PropTypes.func.isRequired,
  exportLeadAsCSV: PropTypes.func.isRequired,
  getPostUrlAndData: PropTypes.func.isRequired,
  formPostData: PropTypes.object,
};

export default ExportLeadView;
