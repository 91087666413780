/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import onClickOutside from "react-onclickoutside";

export class ExportButton extends Component {
  state = {
    showingOptions: false,
  };

  showExportOptions = () => {
    const showingOptions = !this.state.showingOptions;
    this.setState({ ...this.state, showingOptions: showingOptions });
  };

  exportAsCSVClicked = () => {
    const { getExportUrlWithString, csvDownloadLink } = this.props;
    if (getExportUrlWithString) {
      getExportUrlWithString(csvDownloadLink);
      this.setState({ ...this.state, showingOptions: false });
    }
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  render() {
    const menuStyle = this.state.showingOptions
      ? { display: "block" }
      : { display: "none" };
    return (
      <div className="btn-group pull-right">
        <div className="btn-group">
          <button
            className="btn btn-default dropdown-toggle"
            onClick={this.showExportOptions}
          >
            <span className="caret" />
            <ul className="dropdown-menu" style={menuStyle}>
              <li className="dropdown-header">Download</li>
              <li className="selectable">
                <a
                  className="selectable"
                  tabIndex="-1"
                  onClick={this.exportAsCSVClicked}
                >
                  CSV/Excell
                </a>
              </li>
            </ul>
          </button>
        </div>
        <button
          className="btn btn-default"
          type="button"
          onClick={this.showExportOptions}
        >
          Export
        </button>
      </div>
    );
  }
}

ExportButton.propTypes = {
  getExportUrlWithString: PropTypes.func,
  csvDownloadLink: PropTypes.string,
};

export default onClickOutside(ExportButton);
