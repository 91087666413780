/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TrialSelectModal from 'modules/components/TrialSelectModal';
import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers';
import moment from 'moment';
import EditTrialModal from './EditTrialModal';

export class TrialPanel extends Component {
  showTrialOptions = () => {
    this.props.showModal('select_trial');
  };

  editTrial = () => {
    this.props.showModal('edit_trial');
  };

  trialSelected = (trial, trialStartsAt) => {
    const { lead, hideModal, addLeadToTrial } = this.props;
    addLeadToTrial(lead, trial, trialStartsAt);
    hideModal();
  };

  getTrialCopy = () => {
    const { currentTrial, timezone } = this.props;
    const currentDate = moment().tz(timezone);
    const trialStartDate = moment(currentTrial.start_date).tz(timezone).startOf('day');
    const trialExpiryDate = moment(currentTrial.end_date).tz(timezone).endOf('day');
    if (currentDate.isSame(trialStartDate, 'day') || currentDate > trialStartDate) {
      return ' with ' + pluralizationHelper('days', trialExpiryDate.diff(currentDate.startOf('day'), 'days') + 1) + ' remaining';
    } else {
      return ' starts in ' + pluralizationHelper('days', trialStartDate.diff(currentDate.startOf('day'), 'days'));
    }
  };

  render() {
    const { currentTrial, trialLoading } = this.props;
    const onSelect = currentTrial ? this.editTrial : this.showTrialOptions;
    const blockStyle = trialLoading ? 'block-opt-refresh' : '';
    const trialIcon = currentTrial ? 'text-success' : 'text-warning';

    return (
      <div>
        <a className={'block block-link-hover2 block-bordered selectable ' + blockStyle} onClick={onSelect}>
          <div className="block-content block-content-full clearfix">
            <i className={'si si-check fa-2x text-muted pull-right ' + trialIcon} />
            {!currentTrial && (
              <div>
                <span className="h4 text-muted">Should they start a </span>
                <span className="h4 font-w700">Trial?</span>
              </div>
            )}
            {currentTrial && (
              <div>
                <span className="h4 text-muted">{currentTrial.name} </span>
                <span className="h4 font-w700">{this.getTrialCopy()}</span>
              </div>
            )}
          </div>
        </a>
        <>
          {!currentTrial && <TrialSelectModal.TrialSelectModalContainer onTrialSelect={this.trialSelected} modalName={'select_trial'} />}
          {currentTrial && <EditTrialModal modalName={'edit_trial'} {...this.props} />}
        </>
      </div>
    );
  }
}

TrialPanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TrialPanel;
