import * as ACTIONS from './actionTypes'

const LEAD_HISTORY_ACTION_HANDLERS = {
  [ACTIONS.FETCHING_HISTORY]: (state, action) => {
    return { ...state, loading: true }
  },
  [ACTIONS.FETCHING_HISTORY_SUCCESS]: (state, action) => {
    return { ...state, loading: false, historyItems: action.activityRecords }
  },
  [ACTIONS.FETCHING_HISTORY_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [ACTIONS.ADD_HISTORY_ITEM]: (state, action) => {
    const updatedHistoryItems = [
      action.historyItem,
      ...state.historyItems
    ]
    return { ...state, historyItems: updatedHistoryItems }
  },
  [ACTIONS.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state, pageCount: action.pageDetails.total_pages, currentPage: action.pageDetails.current_page }
  }
}

const initialState = {
  loading: false,
  historyItems: [],
  pageCount: 1,
  currentPage: 1
}

export default function reducer (state = initialState, action) {
  const handler = LEAD_HISTORY_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
