import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectUserTableRowContainer from '../../containers/SelectUserTableRowContainer'

export class SelectUserTableView extends Component {

  render () {
    const { users, onUserSelect, locationId } = this.props
    const rows = users.map((user, index) => {
      return <SelectUserTableRowContainer
        key={index}
        user={user}
        locationId={locationId}
        onUserSelect={onUserSelect} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th style={{ width: '40px' }} />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SelectUserTableView.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUserSelect: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired
}

export default SelectUserTableView
