/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectDOBModal from "./SelectDOBModal";
import "../../LeadMoreDetail.css";
import { DATE_OF_BIRTH_MODAL } from "../../../constants";

import moment from "moment";

export class DateOfBirthPanel extends Component {
  dobSelected = () => {
    this.props.showModal(DATE_OF_BIRTH_MODAL);
  };

  icon = () => {
    if (this.props.lead.date_of_birth) {
      return (
        <i className="si si-check fa-2x text-muted pull-right text-success" />
      );
    }
    return (
      <i className="si si-question fa-2x text-muted pull-right text-warning" />
    );
  };

  text = () => {
    const { lead } = this.props;
    const formattedDateOfBirth = moment(lead.date_of_birth).format(
      "Do MMMM YYYY"
    );
    if (!lead.gender || !lead.date_of_birth) {
      return (
        <div>
          {!lead.date_of_birth && (
            <div>
              <span className="h4 text-muted">
                {"What is " + lead.given_name + "'s "}{" "}
              </span>
              <span className="h4 font-w700">date of birth?</span>
            </div>
          )}
          {lead.date_of_birth && (
            <div>
              <span className="h4 text-muted">
                {lead.given_name + "'s date of birth is"}{" "}
              </span>
              <span className="h4 font-w700">{formattedDateOfBirth}</span>
            </div>
          )}
        </div>
      );
    }
    return (
      <div>
        <span className="h4 text-muted">
          {lead.given_name + "'s birthday is on  "}{" "}
        </span>
        <span className="h4 font-w700">{formattedDateOfBirth}</span>
      </div>
    );
  };

  render() {
    return (
      <div>
        <a
          className="block block-link-hover2 block-bordered selectable"
          onClick={this.dobSelected}
        >
          <div className="block-content block-content-full clearfix">
            {this.icon()}
            {this.text()}
          </div>
        </a>
        <SelectDOBModal {...this.props} modalName={DATE_OF_BIRTH_MODAL} />
      </div>
    );
  }
}

DateOfBirthPanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  formats: PropTypes.PropTypes.object.isRequired,
  currentModal: PropTypes.string,
};
