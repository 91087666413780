import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DealsView from "../components/DealsView";

import deals from "modules/Stores/Deals";
import modal from "modules/Stores/Modal";

import * as localActions from "../actions";
import * as localSelectors from "../selectors";

function mapStateToProps(state) {
  return {
    deals: deals.selectors.getDeals(state),
    selectedDeal: localSelectors.selectedDeal(state),
    selectedDealId: localSelectors.getSelectedDealId(state),
    loading: deals.selectors.getLoading(state),
    errors: deals.selectors.getErrors(state),
    pageDetails: deals.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prefillDataForDeal: localActions.prefillDataForDeal,
      getDeals: deals.actions.getDeals,
      showModal: modal.actions.showModal,
      setSelectedDealId: localActions.setSelectedDealId
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealsView);
