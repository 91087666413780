import PropTypes from 'prop-types'
import React, { Component } from 'react'

import CreateSalesTargetsContainer from '../containers/createSalesTargetsContainer'
import TargetsContainer from '../containers/TargetsContainer'
import LeadTimeOutContainer from '../containers/LeadTimeOutContainer'

export class SalesTargetsView extends Component {

  UNSAFE_componentWillMount () {
    const { fetchTargetRuleSetForLocationUser, user, location } = this.props
    fetchTargetRuleSetForLocationUser(location, user)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.id !== this.props.location.id) {
      nextProps.fetchTargetRuleSetForLocationUser(nextProps.location, nextProps.user)
    }

    if (nextProps.user.id !== this.props.user.id) {
      nextProps.fetchTargetRuleSetForLocationUser(nextProps.location, nextProps.user)
    }
  }

  render () {
    const { location, user, targetRuleSet, loading } = this.props
    return (
      <div>
        {loading &&
          <div className='row text-center'>
            <div className='col-xs-12'>
              <i className='fa fa-3x fa-cog fa-spin' />
              <div className='h3 font-w400 push-10-t push-20'>
                Loading rules
              </div>
            </div>
          </div>
        }
        {!loading &&
          <div>
            {!targetRuleSet &&
              <CreateSalesTargetsContainer location={location} user={user} />
            }
            {targetRuleSet &&
              <div>
                <TargetsContainer location={location} user={user} />
                <LeadTimeOutContainer location={location} user={user} />
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

SalesTargetsView.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchTargetRuleSetForLocationUser: PropTypes.func.isRequired,
  targetRuleSet: PropTypes.object
}

export default SalesTargetsView
