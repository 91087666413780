import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EmptyView } from 'modules/components';

export class ConfirmEmailView extends Component {
  UNSAFE_componentWillMount() {
    const { confirmEmail, token } = this.props;
    confirmEmail(token);
  }

  render() {
    const { loading, errors, user } = this.props;
    const blockStyle = loading ? 'block block-themed animated fadeIn block-opt-refresh' : 'block block-themed animated fadeIn';
    return (
      <div className="content overflow-hidden">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
            <div className={blockStyle}>
              <div className="block-header bg-success">
                {user && <h3 className="block-title">Email Confirmed</h3>}
                {!user && <h3 className="block-title">Confirming Email</h3>}
              </div>
              <div className="block-content block-content-full block-content-narrow">
                {user && (
                  <div>
                    <h1 className="h2 font-w600 push-30-t push-5">Hey {user.given_name}!</h1>
                    <p>
                      Thanks for confirming your email. You can log in when you're ready. (or keep using it if you're already logged in)
                    </p>
                  </div>
                )}
                {errors && (
                  <EmptyView
                    colorThemeClass="danger"
                    iconClass="fa fa-frown-o"
                    title="We could not find this user"
                    subtitle="That confirm link has either been used or is invalid. If you think there
                    has been a mistake, please get in touch and we can help you out"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmEmailView.propTypes = {
  token: PropTypes.string.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  errors: PropTypes.object,
};
