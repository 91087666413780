import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { transformDataToSection } from "modules/components/SelectDateRangeButton/helpers.js";
import { selectFilterComponent, humanReadableFilterName } from "../helpers";
import FilterWrapper from "./FilterWrapper";
import { DATE_RANGE, STATUS_UPDATED_RANGE } from "../constants";

export default class FiltersForm extends Component {
  onUpdate = (sectionData, sectionName) => {
    const { updateFilters, filterData } = this.props;
    const newFilterData = { ...filterData, [sectionName]: sectionData };
    updateFilters(newFilterData);
  };

  onDateSelect = (data, sectionName) => {
    const { updateFilters, filterData } = this.props;
    const newSections = transformDataToSection(data, sectionName, filterData);
    updateFilters(newSections);
  };

  dateRangeFilter(filter) {
    return filter === DATE_RANGE || filter === STATUS_UPDATED_RANGE
      ? true
      : false;
  }

  mappedFilterOptions = () => {
    return this.props.availableFilters.map((filter, index) => {
      const FilterContainer = selectFilterComponent(filter);
      if (!FilterContainer) return undefined;
      return (
        <FilterWrapper
          title={humanReadableFilterName(filter)}
          key={`filter-${filter}-${index}`}
        >
          <FilterContainer
            {...this.props}
            onUpdate={
              this.dateRangeFilter(filter) ? this.onDateSelect : this.onUpdate
            }
          />
        </FilterWrapper>
      );
    });
  };

  render() {
    const filterComponents = this.mappedFilterOptions();
    return <Container>{filterComponents}</Container>;
  }
}

FiltersForm.propTypes = {
  availableFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  calculatingByOptions: PropTypes.arrayOf(PropTypes.string),
  compareOptions: PropTypes.arrayOf(PropTypes.string),
  updateFilters: PropTypes.func.isRequired,
  filterData: PropTypes.object,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
