import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const getLeadsCountWithQuery = (queryString) => {
  return (dispatch) => {
    dispatch(fetchingCounts());
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(fetchLeadsCountSuccess(payload));
    }

    const encodedQueryString = encodeURI(queryString);

    return request('lead_counts?' + encodedQueryString, GET, null, onSuccess, onFailure);
  };
};

function fetchingCounts() {
  return {
    type: actionTypes.FETCHING_COUNTS,
  };
}

function fetchLeadsCountSuccess(counts) {
  return {
    type: actionTypes.FETCH_COUNTS_SUCCESS,
    counts,
  };
}
