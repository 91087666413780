import PropTypes from 'prop-types'
import React, { Component } from 'react'
export class ReportHeaderView extends Component {

  render () {
    return (
      <div className='row items-push'>
        <div className='col-md-12'>
          <h1 className='page-heading push-10'>{this.props.title}</h1>
          <div style={{ borderBottomStyle: 'solid', borderBottomWidth: '2px' }} />
        </div>
      </div>
    )
  }
}

ReportHeaderView.propTypes = {
  title: PropTypes.string.isRequired,
  queued: PropTypes.bool.isRequired,
  compareOption: PropTypes.string.isRequired,
  queryString: PropTypes.string
}

export default ReportHeaderView
