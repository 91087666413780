import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SelectLocationTableRow } from './SelectLocationTableRow'

export class SelectLocationTableView extends Component {

  render () {
    const { locations, selectedLocationIds, selectLocation, deselectLocation } = this.props
    const rows = locations.map((location, index) => {
      const selectedIndex = selectedLocationIds.findIndex(selectedLocationId => location.id === selectedLocationId)
      const selected = (selectedIndex >= 0)
      return <SelectLocationTableRow key={index} location={location} locationSelected={selected}
        selectLocation={selectLocation} deselectLocation={deselectLocation} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th className='text-center'>Postcode</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SelectLocationTableView.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLocation: PropTypes.func.isRequired,
  deselectLocation: PropTypes.func.isRequired
}

export default SelectLocationTableView
