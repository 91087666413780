import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const fetchReportWithQueryString = (queryString) => {
  return (dispatch) => {
    dispatch(startFetching());
    function onFailure(errors) {
      dispatch(fetchFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchSuccess(payload.report));
    }
    let url = 'lead_allocation_report';
    if (queryString) {
      url = url + '?' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function startFetching() {
  return {
    type: actionTypes.FETCHING_REPORT,
  };
}

function fetchSuccess(report) {
  return {
    type: actionTypes.FETCH_REPORT_SUCCESS,
    report,
  };
}

function fetchFailed(errors) {
  return {
    type: actionTypes.FETCH_REPORT_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    data,
  };
}

export function clearAllFilters() {
  return {
    type: actionTypes.CLEAR_ALL_FILTERS,
  };
}

export function sendEmail() {}
