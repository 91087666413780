import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = state => state.salesPerformanceReport[NAME].errors;
export const getLoading = state => state.salesPerformanceReport[NAME].loading;
export const getData = state => state.salesPerformanceReport[NAME].data;

export const dataLabels = createSelector(
  [getData],
  data => {
    return data.filter(x => x).map(dataSet => dataSet.label);
  }
);

export const datasets = createSelector(
  [getData],
  data => {
    const filteredData = data.filter(x => x);
    const firstDataSet = filteredData[0];
    const dataSets = [];
    if (!firstDataSet) return dataSets;
    const dataSetKeys = Object.keys(firstDataSet.data);
    dataSetKeys.forEach((dataSetKey, index) => {
      const label = firstDataSet.data[dataSetKey].label;
      const values = filteredData.map(entryData => {
        return entryData.data[dataSetKey].data.current;
      });
      const colorSet = colorSets[index];
      dataSets.push({
        label: label,
        data: values,
        backgroundColor: colorSet.backgroundColor,
        hoverBackgroundColor: colorSet.hoverBackgroundColor
      });
    });
    return dataSets;
  }
);

const colorSets = [
  {
    backgroundColor: "#7fe3f2",
    hoverBackgroundColor: "#14adc4"
  },
  {
    backgroundColor: "#98b9e3",
    hoverBackgroundColor: "#5c90d2"
  },
  {
    backgroundColor: "#c7b7e4",
    hoverBackgroundColor: "#a48ad4"
  },
  {
    backgroundColor: "#FDC12D",
    hoverBackgroundColor: "#E9B22A"
  },
  {
    backgroundColor: "#83d0c7",
    hoverBackgroundColor: "#44b4a6"
  }
];
