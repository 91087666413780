import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormFields } from 'modules/components';
import { generateNewFormState } from 'utils/formHelpers';
import { NewLeadFormValidator } from '../validators';
import { placeholdersForCountry } from 'modules/Helpers/FormatHelpers';

import TagContainer from '../containers/TagContainer';

import {
  SELECT_LOCATION_VIEW,
  SELECT_USER_VIEW,
  SELECT_LEAD_SOURCE_VIEW,
  SELECT_HEARD_ABOUT_METHOD_VIEW,
  SELECT_LEAD_GOAL_VIEW,
} from '../constants';

const { TextField, SelectField, TextArea, DateTextField } = FormFields;
export class CreateLeadForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(this.props.data, fieldName, value, isValid);
    this.props.updateData(newSectionData);
  };

  handleGoalSelect = (fieldName, value, isValid) => {
    const { goalOptions } = this.props;
    const selectedLeadGoalIndex = goalOptions.findIndex((leadGoal) => parseInt(leadGoal.id) === parseInt(value));
    const selectedLeadGoal = goalOptions[selectedLeadGoalIndex];
    if (selectedLeadGoal) {
      const newSectionData = generateNewFormState(this.props.data, fieldName, selectedLeadGoal.goal_name, true);
      this.props.updateData(newSectionData);
    }
  };

  handleToggle = (fieldName, value) => {
    const newSectionData = generateNewFormState(this.props.data, fieldName, value, true);
    this.props.updateData(newSectionData);
  };

  placeholder = () => {
    const { countries, selectedLocation } = this.props;
    const countryId = selectedLocation ? selectedLocation.country_id : 1;
    return placeholdersForCountry(countryId, countries);
  };

  goalId = () => {
    const { goalOptions, data } = this.props;
    const { fitnessGoal } = data.fields;
    const index = goalOptions.findIndex((goal) => goal.goal_name === fitnessGoal);
    return goalOptions[index];
  };

  render() {
    const { selectedLocation, data, selectedLeadSource, selectedLeadGoal, selectedHeardAboutMethod, selectedUser, user } = this.props;
    const { givenName, surname, email, mobileNumber, zipPostcode, dateOfBirth, gender, workplaceName, notes } = data.fields;
    const placeholder = this.placeholder();
    const locationButtonTitle = selectedLocation ? selectedLocation.admin_display_name : 'Select a location';
    const assignToUserButton = selectedUser ? selectedUser.displayName : 'No one';
    const leadSourceButtonTitle = selectedLeadSource ? selectedLeadSource.source_name : 'Select a lead source';
    const heardAboutMethodTitle = selectedHeardAboutMethod ? selectedHeardAboutMethod.heard_about_title : 'Select how they heard about you';
    const leadGoalButtonTitle = selectedLeadGoal ? selectedLeadGoal.goal_name : 'Select their goal';
    const permission = user.user_permission_rule_set;

    return (
      <form className="form-horizontal">
        <div className="row">
          <div className="col-sm-6">
            <TextField
              name="givenName"
              label="First Name *"
              placeholder="Bob"
              formFieldStyle=""
              initialValue={givenName.value}
              onChange={this.handleInput}
              validator={NewLeadFormValidator}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="surname"
              label="Last Name"
              placeholder="Brown"
              formFieldStyle=""
              initialValue={surname.value}
              onChange={this.handleInput}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TextField
              name="email"
              label="Email"
              placeholder="bob.brown@gmail.com"
              formFieldStyle=""
              onChange={this.handleInput}
              initialValue={email.value}
              validator={NewLeadFormValidator}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="mobileNumber"
              label="Phone Number"
              placeholder={placeholder.phone}
              formFieldStyle=""
              onChange={this.handleInput}
              initialValue={mobileNumber.value}
              validator={NewLeadFormValidator}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TextField
              name="zipPostcode"
              label="Postcode/Zip"
              placeholder="3021"
              formFieldStyle=""
              onChange={this.handleInput}
              initialValue={zipPostcode.value}
              validator={NewLeadFormValidator}
            />
          </div>
          <div className="col-md-6">
            <DateTextField
              name="dateOfBirth"
              label="Date Of Birth"
              onChange={this.handleInput}
              placeholder={placeholder.date}
              locale={placeholder.locale}
              dateFormat={placeholder.date}
              initialValue={dateOfBirth.value}
              validator={NewLeadFormValidator}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <TextField
              name="workplaceName"
              label="Name of workplace"
              placeholder=""
              onChange={this.handleInput}
              initialValue={workplaceName.value}
              needsFormGroup={false}
            />
          </div>
          <div className="col-xs-6">
            <SelectField
              name="gender"
              label="What is their gender?"
              possibleOptions={['Male', 'Female', 'Other']}
              placeHolderText="Select a gender"
              formFieldStyle=""
              initialValue={gender.value}
              onChange={this.handleInput}
            />
          </div>
        </div>
        <hr />
        <div className="row push">
          <div className="col-sm-6">
            <label>Location *</label>
            <br />
            <button className={'btn btn-primary'} type="button" onClick={() => this.props.setCurrentView(SELECT_LOCATION_VIEW)}>
              {locationButtonTitle}
            </button>
          </div>
          {permission.allow_lead_assign && selectedLocation && (
            <div className="col-sm-6">
              <label>Assigned to:</label>
              <br />
              <button className={'btn btn-primary'} type="button" onClick={() => this.props.setCurrentView(SELECT_USER_VIEW)}>
                {assignToUserButton}
              </button>
            </div>
          )}
        </div>
        <div className="row push">
          <div className="col-sm-6">
            <label>Where did the lead come from? *</label>
            <br />
            <button className={'btn btn-primary'} type="button" onClick={() => this.props.setCurrentView(SELECT_LEAD_SOURCE_VIEW)}>
              {leadSourceButtonTitle}
            </button>
          </div>
        </div>
        <div className="row push">
          <div className="col-sm-6">
            <label>What is their goal?</label>
            <br />
            <button className={'btn btn-primary'} type="button" onClick={() => this.props.setCurrentView(SELECT_LEAD_GOAL_VIEW)}>
              {leadGoalButtonTitle}
            </button>
          </div>
          <div className="col-sm-6">
            <label>Where did they hear about you?</label>
            <br />
            <button className={'btn btn-primary'} type="button" onClick={() => this.props.setCurrentView(SELECT_HEARD_ABOUT_METHOD_VIEW)}>
              {heardAboutMethodTitle}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <TextArea
              name="notes"
              label="Any notes about them?"
              placeholder="You can write something helpful here."
              rows={4}
              value={notes.value}
              onChange={this.handleInput}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <TagContainer />
          </div>
        </div>
      </form>
    );
  }
}

CreateLeadForm.propTypes = {
  user: PropTypes.object.isRequired,
  sources: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentView: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
  selectedLeadSource: PropTypes.object,
  selectedUser: PropTypes.object,
};

CreateLeadForm.defaultProps = {
  sources: [],
};

export default CreateLeadForm;
