import PropTypes from "prop-types";
import React, { Component } from "react";

import { ManagerSalesTargetRow } from "./ManagerSalesTargetRow";

import moment from "moment";
import "moment-timezone";

export class SalesTargetsView extends Component {
  UNSAFE_componentWillMount() {
    const { location, fetch } = this.props;
    fetch(location.id);
  }

  titleString = () => {
    const { salesTargetResults, timezone } = this.props;
    switch (salesTargetResults.time_type) {
      case "monthly":
        return moment().tz(timezone).format("MMMM");
      case "weekly":
        return " this week";
      default:
        return "";
    }
  };

  render() {
    const { salesTargetResults, location } = this.props;
    const allKeys = Object.keys(salesTargetResults.data);
    const rows = allKeys.map((key, index) => {
      const targetEntry = salesTargetResults.data[key];
      if (key === "referral_count" && targetEntry.target === 0) {
        return undefined;
      }
      return (
        <ManagerSalesTargetRow key={index} title={key} target={targetEntry} />
      );
    });
    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <h3 className="block-title">
            {location.admin_display_name} sales targets for {this.titleString()}
          </h3>
        </div>
        <div className="block-content block-content-full table-responsive">
          <table className="table table-vcenter">
            <thead>
              <tr className="text-center">
                <th>Goal</th>
                <th className="text-center">Target</th>
                <th className="text-center">Month to date</th>
                <th className="text-center">Remaining</th>
                <th className="text-center">Today's Goal</th>
                <th className="text-center">On Track</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          {salesTargetResults.working_days && (
            <div>
              <p>
                <strong>Elapsed working days:</strong>{" "}
                {salesTargetResults.working_days.elapsed}
              </p>
              <p>
                <strong>Remaining working days:</strong>{" "}
                {salesTargetResults.working_days.remaining}
              </p>
              <p>
                <strong>Target period complete:</strong>{" "}
                {(
                  salesTargetResults.working_days.percentage_of_target * 100
                ).toFixed(0)}
                %
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SalesTargetsView.propTypes = {
  salesTargetResults: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default SalesTargetsView;
