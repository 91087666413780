import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class EmailMapResultRow extends Component {

  render () {
    const { fieldName, required, value } = this.props
    let className = ''
    if (required && !value) {
      className = 'label label-danger'
    } else if (!required && !value) {
      className = 'text-muted'
    }

    const displayValue = value || 'None found'

    return (
      <tr>
        <td className={'font-w600'}>{fieldName}</td>
        <td className={className}>{displayValue}</td>
      </tr>
    )
  }
}

EmailMapResultRow.propTypes = {
  fieldName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string
}

export default EmailMapResultRow
