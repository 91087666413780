import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExportButtonContainer from "modules/components/ExportButton/ExportButtonContainer";
import FilterFormSections from "modules/components/FilterFormSections";
import filters from "../../filters";
import table from "../../table";

function mapStateToProps(state, props) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    filters.selectors.getData
  );
  return {
    reportType: "sales_performance",
    queued: table.selectors.getQueued(state),
    loading: table.selectors.getLoading(state),
    queryString: queryStringSelector(state),
    compareOption: filters.selectors.getCompareOption(state),
    calculatingOption: filters.selectors.getCalculatingOption(state),
    showExcel: true,
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      generateFullReport: table.actions.generateFullReport,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportButtonContainer);
