import PropTypes from "prop-types";
import React, { Component } from "react";

import { EmptyView } from "modules/components";

import RegionRow from "./RegionRow";
import SweetAlert from "react-bootstrap-sweetalert";

export class ListRegionsView extends Component {
  state = {
    showWarning: false,
    regionIdToDelete: null
  };

  UNSAFE_componentWillMount() {
    const { regions, getRegions } = this.props;
    if (regions && regions.length > 0) {
      return;
    }
    getRegions();
  }

  newRegionClicked = () => {
    this.props.showModal(this.props.newRegionModalName);
  };

  dismissWarning = () => {
    this.setState({
      ...this.state,
      showWarning: false,
      regionIdToDelete: null
    });
  };

  deleteRegionClicked = regionId => {
    this.setState({
      ...this.state,
      showWarning: true,
      regionIdToDelete: regionId
    });
  };

  deleteRegion = () => {
    this.props.selectRegionId(null);
    this.props.deleteRegionWithId(this.state.regionIdToDelete);
    this.setState({
      ...this.state,
      showWarning: false,
      regionIdToDelete: null
    });
  };

  regionClicked = regionId => {
    this.props.selectRegionId(regionId);
  };

  render() {
    const { regions, selectedRegionId } = this.props;
    const rows = regions.map((region, index) => {
      const selected = parseInt(region.id) === parseInt(selectedRegionId);
      return (
        <RegionRow
          key={index}
          region={region}
          selected={selected}
          onSelect={this.regionClicked}
          onDelete={this.deleteRegionClicked}
        />
      );
    });
    return (
      <div>
        <div className="block block-rounded block-bordered">
          <div className="block-header">
            <div className="block-options-simple">
              <button
                type="button"
                className="btn btn-xs btn-success"
                onClick={this.newRegionClicked}
              >
                <i className="fa fa-plus" /> Add new region
              </button>
            </div>
            <h3 className="block-title">Your Regions</h3>
          </div>
          <div className="block-content">
            {regions.length === 0 && (
              <div style={{ paddingBottom: "30px" }}>
                <EmptyView
                  colorThemeClass="primary"
                  iconClass="fa fa-map"
                  title="No regions"
                  subtitle="Regions will show here"
                  buttonText="Create a new region"
                  buttonSelected={this.newRegionClicked}
                />
              </div>
            )}
            {regions.length > 0 && (
              <table className="table table-vcenter table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number of locations</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            )}
          </div>
        </div>
        {this.state.showWarning && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="warning"
            title="Are you Sure?"
            confirmBtnText="Delete this region"
            onConfirm={this.deleteRegion}
            onCancel={this.dismissWarning}
          >
            This will delete the region.
          </SweetAlert>
        )}
      </div>
    );
  }
}

ListRegionsView.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRegions: PropTypes.func.isRequired,
  deleteRegionWithId: PropTypes.func.isRequired,
  selectRegionId: PropTypes.func.isRequired,
  newRegionModalName: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  selectedRegionId: PropTypes.number,
  errors: PropTypes.object
};

export default ListRegionsView;
