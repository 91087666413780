import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FilterCheckboxSection from './FilterCheckboxSection'

export default class ActivityTypeSection extends Component {

  state = {
    activityTypes: [{
      value: 'active',
      display: 'Leads Assigned'
    }, {
      value: 'contact call',
      display: 'First Contacts'
    }, {
      value: 'follow_up_call',
      display: 'Follow Up Calls'
    }, {
      value: 'messaged',
      display: 'Messages Sent'
    }, {
      value: 'emailed',
      display: 'Emails Sent'
    }, {
      value: 'appointment',
      display: 'Appointments Secheduled'
    }, {
      value: 'presentation',
      display: 'Presentations Made'
    }, {
      value: 'timed_oud',
      display: 'Timed out'
    }]
  }

  render () {
    return (
      <FilterCheckboxSection title={'Activity Type'} types={this.state.activityTypes} {...this.props} />
    )
  }
}

ActivityTypeSection.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool
}
