import * as actionTypes from "./actionTypes";

const CALL_LIST_ACTION_HANDLERS = {
  [actionTypes.FETCH]: (state, action) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.FETCH_SUCCESS]: (state, action) => {
    return { ...state, loading: false, calls: action.calls };
  },
  [actionTypes.FETCH_FAILED]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.SELECT]: (state, action) => {
    return { ...state, selectedCallId: action.callId };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalCount: action.pageDetails.total_count
    };
  },
  [actionTypes.REMOVE_SELECTED]: state => {
    const index = state.calls.findIndex(
      call => parseInt(call.id) === parseInt(state.selectedCallId)
    );
    const newCalls = [
      ...state.calls.slice(0, index),
      ...state.calls.slice(index + 1)
    ];
    return { ...state, calls: newCalls, selectedCallId: null };
  }
};

const initialState = {
  loading: false,
  selectedCallId: null,
  calls: [],
  pageCount: 1,
  currentPage: 1,
  totalCount: 0
};

export default function reducer(state = initialState, action) {
  const handler = CALL_LIST_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
