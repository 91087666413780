import * as actionTypes from './actionTypes'

const SALES_TEAM_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state, action) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, errors: null, salesTeam: action.salesTeam }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  },
  [actionTypes.INCREMENT_SALES_TEAM_MEMBER_LEAD_COUNT]: (state, action) => {
    const index = indexForSalesTeamMember(state.salesTeam, action.salesTeamMemberId)
    if (index > 0) {
      const salesTeamMemberLeadCounts = state.salesTeam[index].sales_metrics.lead_counts
      const oldFirstStepCount = salesTeamMemberLeadCounts['1']
      const updatedLeadCounts = { ...salesTeamMemberLeadCounts, '1': oldFirstStepCount + 1 }
      const updatedSalesMetrics = { ...state.salesTeam[index].sales_metrics, lead_counts: updatedLeadCounts }
      const updatedSalesTeamMember = { ...state.salesTeam[index], sales_metrics: updatedSalesMetrics }

      const updatedSalesTeamMembers = [
        ...state.salesTeam.slice(0, index),
        updatedSalesTeamMember,
        ...state.salesTeam.slice(index + 1)
      ]
      return { ...state, salesTeam: updatedSalesTeamMembers }
    } else {
      return state
    }
  },
  [actionTypes.USER_SELECTED]: (state, action) => {
    return { ...state, selectedUserId: action.userId }
  }
}

const initialState = {
  salesTeam: [],
  loading: false,
  selectedUserId: null,
  errors: null
}

function indexForSalesTeamMember (salesTeam, salesTeamMemberId) {
  return salesTeam.findIndex(_salesTeamMember => _salesTeamMember.user.id === salesTeamMemberId)
}

export default function reducer (state = initialState, action) {
  const handler = SALES_TEAM_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
