import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import table from '../modules/table'
import graphBreakdown from '../modules/graphBreakdown'

function mapStateToProps (state) {
  const data = graphBreakdown.selectors.getData(state)
  const headers = table.selectors.generateHeadersForData(data)
  return {
    loading: graphBreakdown.selectors.getLoading(state),
    headers: headers,
    data: table.selectors.generateTableDataForData(data),
    isEmpty: (headers.length === 0)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(table.components.TableView)
