import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ScheduleCallModal from '../components/NewCallModal/ScheduleCallModal'

import * as callActions from '../actions'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, ownProps) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    scheduleCall: callActions.scheduleCall
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCallModal)
