import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PhoneOptionsModal from "../components/LeadContactPanel/PhoneOptionsModal";

import modal from "modules/Stores/Modal";

import userData from "modules/Stores/UserData";

import { recordCallForLead, sendTextMessageToLead } from "../actions";
const { showModal, hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    isMobile: state.session.isMobile,
    errors: modal.selectors.getModalErrors(state),
    company: userData.selectors.getCompany(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      recordCallForLead,
      sendTextMessageToLead,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneOptionsModal);
