import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// FETCH New Leads
export const fetchData = (location) => {
  return dispatch => {
    dispatch(fetchingReport())

    function onFailure (errors) {
      dispatch(fetchReportFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(fetchReportSuccessful(payload.report))
    }

    return request('weekly_lead_summary_report?location_id=' + location.id, GET, null, onSuccess, onFailure)
  }
}

function fetchingReport (todoType) {
  return {
    type: actionTypes.FETCHING,
    todoType
  }
}

function fetchReportSuccessful (report) {
  return {
    type: actionTypes.FETCH_SUCCESS,
    report
  }
}

export function fetchReportFailed (errors) {
  return {
    type: actionTypes.FETCH_FAILED,
    errors
  }
}
