import { connect } from "react-redux";
import { withRouter } from "react-router";

import Menu from "../components/Menu/Menu";

import session from "modules/Stores/Session";

const mapStateToProps = (state) => ({
  user: session.selectors.getUser(state),
  currentPath: state.routing.location.pathname,
});

const mapActionCreators = {};

export default withRouter(connect(mapStateToProps, mapActionCreators)(Menu));
