import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AutomaticReportsTableView from "../components/AutomaticReportsTableView";

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    currentPage: selectors.getCurrentPage(state),
    pageCount: selectors.getPageCount(state),
    automaticReports: selectors.getAutomaticReports(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    automaticReportSelected: actions.selectAutomaticReport,
    fetchAutomaticReportsWithPage: actions.fetchAutomaticReportsWithPage
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomaticReportsTableView);
