import { NAME } from "./constants";

export const UPDATE_LEAD_DATA = NAME + "/UPDATE_LEAD_DATA";

export const SELECT_USER = NAME + "/SELECT_USER";
export const SET_CURRENT_VIEW = NAME + "/SET_CURRENT_VIEW";
export const SET_LAST_USED_LOCATION_ID = NAME + "/SET_LAST_USED_LOCATION_ID";

export const SET_EXISTING_LEAD = NAME + "/SET_EXISTING_LEAD";
export const SET_SELECTED_LOCATION = NAME + "/SET_SELECTED_LOCATION";
export const SET_SELECTED_LEAD_SOURCE = NAME + "/SET_SELECTED_LEAD_SOURCE";
export const SET_SELECTED_HEARD_ABOUT_METHOD =
  NAME + "/SET_SELECTED_HEARD_ABOUT_METHOD";
export const SET_SELECTED_LEAD_GOAL = NAME + "/SET_SELECTED_LEAD_GOAL";
export const SET_PAGE_DETAILS = NAME + "/SET_PAGE_DETAILS";

export const RESET = NAME + "/RESET";

export const REMOVE_TAG = NAME + "/REMOVE_TAG";

export const SET_TAGS = NAME + "/SET_TAGS";
export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_USERS = NAME + "/SET_USERS";
export const ADD_TAG = NAME + "/ADD_TAG";
