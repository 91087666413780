import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppointmentItemListContainer from '../containers/AppointmentItemListContainer';
import ScheduleAppointmentModalContainer from '../containers/ScheduleAppointmentModalContainer';
import ClassAppointmentModalContainer from '../containers/ClassAppointmentModalContainer';
import RecordAppointmentModalContainer from '../containers/RecordAppointmentModalContainer';
import AfterAppointmentModalContainer from '../containers/AfterAppointmentModalContainer';

import SweetAlert from 'react-bootstrap-sweetalert';

import { SCHEDULE_APPOINTMENT_MODAL, RECORD_APPOINTMENT_MODAL, FINISHED_APPOINTMENT_MODAL, CLASS_APPOINTMENT_MODAL } from '../constants';

export class AppointmentsView extends Component {
  state = {
    showWarning: false,
    appointmentToAction: null,
  };

  appointmentClicked = (appointment) => {
    const { lead, toggleCompleteForAppointment } = this.props;
    if (!appointment.completed) {
      this.setState({ ...this.state, appointmentToAction: appointment });
      this.props.showModal(FINISHED_APPOINTMENT_MODAL);
    } else {
      toggleCompleteForAppointment(appointment, lead, null);
    }
  };

  scheduleAppointment = () => {
    if (this.props.classIntegrationId) {
      this.props.showModal(CLASS_APPOINTMENT_MODAL);
    } else {
      this.props.showModal(SCHEDULE_APPOINTMENT_MODAL);
    }
  };

  recordAppointment = () => {
    this.props.showModal(RECORD_APPOINTMENT_MODAL);
  };

  deleteAppointment = () => {
    const { lead, deleteAppointmentForLead, classIntegrationName } = this.props;
    const { appointmentToAction } = this.state;
    deleteAppointmentForLead(appointmentToAction, lead, classIntegrationName);
    this.dismissPopups();
  };

  showWarningPopup = (appointmentToAction) => {
    this.setState({
      ...this.state,
      showWarning: true,
      appointmentToAction: appointmentToAction,
    });
  };

  dismissPopups = () => {
    this.setState({
      ...this.state,
      showWarning: false,
      appointmentToAction: null,
    });
  };

  syncWithIntegration = () => {
    const { lead, syncIntegrationAppointments } = this.props;
    syncIntegrationAppointments(lead);
  };

  render() {
    const { loading, lead, classIntegrationId, classIntegrationName, pathToLeadDetail } = this.props;
    const leadInSync = lead.lead_sync_status ? lead.lead_sync_status.in_sync : false;

    return (
      <div className="push-20">
        {loading ? (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h3 className="push-10">Loading Appointments</h3>
              <i className="fa fa-2x fa-sun-o fa-spin" />
            </div>
          </div>
        ) : (
          <div>
            <button className="btn btn-success push-5-r push-10" type="button" onClick={this.scheduleAppointment}>
              <i className="fa fa-plus" /> Schedule Appointment
            </button>
            <button className="btn btn-success push-5-r push-10" type="button" onClick={this.recordAppointment}>
              <i className="fa fa-plus" /> Record presentation
            </button>
            {classIntegrationId && classIntegrationName === 'mindbody' && (
              <button className="btn btn-info push-5-r push-10" type="button" disabled={!leadInSync} onClick={this.syncWithIntegration}>
                <i className="fa fa-refresh" /> Sync with MINDBODY
              </button>
            )}
            <AppointmentItemListContainer
              lead={lead}
              loading={loading}
              appointmentClicked={this.appointmentClicked}
              appointmentDeleted={this.showWarningPopup}
              pathToLeadDetail={pathToLeadDetail}
              classIntegrationName={classIntegrationName}
            />

            {this.props.currentModal === SCHEDULE_APPOINTMENT_MODAL && (
              <ScheduleAppointmentModalContainer
                modalName={SCHEDULE_APPOINTMENT_MODAL}
                lead={this.props.lead}
                listUpdateProtocol={this.props.listUpdateProtocol}
              />
            )}
            <RecordAppointmentModalContainer
              modalName={RECORD_APPOINTMENT_MODAL}
              lead={this.props.lead}
              classIntegrationId={classIntegrationId}
              classIntegrationName={classIntegrationName}
              listUpdateProtocol={this.props.listUpdateProtocol}
            />
            <AfterAppointmentModalContainer
              modalName={FINISHED_APPOINTMENT_MODAL}
              lead={this.props.lead}
              appointment={this.state.appointmentToAction}
              listUpdateProtocol={this.props.listUpdateProtocol}
              classIntegrationId={classIntegrationId}
              classIntegrationName={classIntegrationName}
            />
            {classIntegrationId && this.props.currentModal === CLASS_APPOINTMENT_MODAL && (
              <ClassAppointmentModalContainer
                modalName={CLASS_APPOINTMENT_MODAL}
                lead={this.props.lead}
                listUpdateProtocol={this.props.listUpdateProtocol}
                classIntegrationId={classIntegrationId}
                classIntegrationName={classIntegrationName}
                pathToLeadDetail={pathToLeadDetail}
              />
            )}
            {this.state.showWarning && (
              <SweetAlert
                warning
                showCancel
                cancelBtnBsStyle="default"
                confirmBtnBsStyle="warning"
                confirmBtnText="Delete Appointment"
                title="Are you Sure?"
                onConfirm={this.deleteAppointment}
                onCancel={this.dismissPopups}
              >
                Are you sure you want to delete this appointment?
              </SweetAlert>
            )}
          </div>
        )}
      </div>
    );
  }
}

AppointmentsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  toggleCompleteForAppointment: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  deleteAppointmentForLead: PropTypes.func.isRequired,
  pathToLeadDetail: PropTypes.string.isRequired,
  syncIntegrationAppointments: PropTypes.func.isRequired,
  classIntegrationId: PropTypes.number,
  classIntegrationName: PropTypes.string,
  currentModal: PropTypes.string,
};
