import { request, PUT, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';
import { newPayloadForValue } from 'utils/formHelpers';

export const updateCallSchedule = (formData, leadSourceIds, locationids, listUpdateProtocol) => {
  return (dispatch) => {
    const body = {
      call_schedule: {
        ...generateCallScheduleWithData(formData),
        filters: {
          lead_source_ids: leadSourceIds,
          location_ids: locationids,
        },
      },
    };

    const callScheduleId = formData.fields.id.value;

    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success('Call schedule updated');
      dispatch(listUpdateProtocol.update(payload.call_schedule));
      dispatch(listUpdateProtocol.select(payload.call_schedule.id));
    }

    return request('call_schedules/' + callScheduleId, PUT, body, onSuccess, onFailure);
  };
};

export const deleteCallSchedule = (callScheduleId, listUpdateProtocol) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success('Call schedule deleted');
      dispatch(listUpdateProtocol.select(null));
      dispatch(listUpdateProtocol.remove(callScheduleId));
    }

    return request('call_schedules/' + callScheduleId, DELETE, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function prefilFormForCallSchedule(callSchedule) {
  return {
    type: actionTypes.RELOAD_CALL_SCHEDULE,
    callSchedule,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function generateCallScheduleWithData(formData) {
  let payload = {};
  const fields = formData.fields;
  const fieldKeys = Object.keys(fields);

  fieldKeys.forEach(function (element) {
    const field = fields[element];
    payload = newPayloadForValue(payload, field.value, field.key);
  });

  return payload;
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setLeadSourceIds(leadSourceIds) {
  return {
    type: actionTypes.SET_LEAD_SOURCE_IDS,
    leadSourceIds,
  };
}

// LOCATION SEARCH
export function locationSearchTermUpdated(searchTerm) {
  return {
    type: actionTypes.LOCATION_SEARCH_TERM_UPDATED,
    searchTerm,
  };
}

export function loationUpdateCurrentPage(pageNumer) {
  return {
    type: actionTypes.LOCATION_CURRENT_PAGE_UPDATED,
    pageNumer,
  };
}

export function setLocationIds(locationIds) {
  return {
    type: actionTypes.SET_SELECTED_LOCATION_IDS,
    locationIds,
  };
}
