import React, { Component } from "react";
import styled from "styled-components";
import Reports from "modules/components/Reports";
import PropTypes from "prop-types";
import * as tooltips from '../tooltips'

class QuickInfoBlocksView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString } = this.props;
    fetchWithQueryString(queryString);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      const { fetchWithQueryString, queryString } = nextProps;
      fetchWithQueryString(queryString);
    }
  }

  timeAndSuffixFromSecconds = seconds => {
    let time = seconds;
    let suffix = "seconds";

    if (time > 60) {
      time = time / 60;
      suffix = "minutes";
    } else {
      return { time: time, suffix: suffix };
    }

    if (time > 60) {
      time = time / 60;
      suffix = "hours";
    } else {
      return { time: time, suffix: suffix };
    }

    if (time > 24) {
      time = time / 24;
      suffix = "days";
    } else {
      return { time: time, suffix: suffix };
    }

    if (time > 7) {
      time = time / 7;
      suffix = "weeks";
    }

    return { time: time, suffix: suffix };
  };

  createBlockData = () => {
    const { data } = this.props;
    const assignTimeData = this.timeAndSuffixFromSecconds(data.avg_time_to_assign);
    const firstCallTimeData = this.timeAndSuffixFromSecconds(data.avg_time_til_first_call);
    const assignToInterviewTimeData = this.timeAndSuffixFromSecconds(data.avg_time_from_assign_to_interview);
    const leadCloseTimeData = this.timeAndSuffixFromSecconds(data.avg_close_length);
    const blockData = [
      {
        title: "Average Time to Assign",
        value: assignTimeData.time,
        suffix: assignTimeData.suffix,
        tooltipText: tooltips.averageTimeToAssign(),
        tooltipId: 'averageTimeToAssign'
      },
      {
        title: "Average Time for first call",
        value: firstCallTimeData.time,
        suffix: firstCallTimeData.suffix,
        tooltipText: tooltips.averageTimeForFirstCall(),
        tooltipId: 'averageTimeForFirstCall'
      },
      {
        title: "Average Time from assign to call",
        value: assignToInterviewTimeData.time,
        suffix: assignToInterviewTimeData.suffix,
        tooltipText: tooltips.averageTimefromAssignedToCall(),
        tooltipId: 'averageTimefromAssignedToCall'
      },
      {
        title: "Average close length",
        value: leadCloseTimeData.time,
        suffix: leadCloseTimeData.suffix,
        tooltipText: tooltips.averageTimeToClose(),
        tooltipId: 'averageTimeToClose'
      }
    ];
    return blockData
  };

  render() {
    const { loading } = this.props;
    let blockData = this.createBlockData();
    return (
      <Container>
        {blockData && !loading && (
          <Reports.components.QuickInfoBlocks blockData={blockData} loading={loading} />
        )}
      </Container>
    );
  }
}

export default QuickInfoBlocksView;


QuickInfoBlocksView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.any,
  queryString: PropTypes.string.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired
};

const Container = styled.div``;
