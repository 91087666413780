import { isPresent } from 'utils/validation';

export const EditProfileValidator = {
  name: [
    {
      rule: isPresent,
      errorMessage: 'You must enter a name',
    },
  ],
  backgroundHexColor: [
    {
      rule: isPresent,
      errorMessage: 'You must select a background color',
    },
  ],
  primaryHexColor: [
    {
      rule: isPresent,
      errorMessage: 'You must select a primary color',
    },
  ],
  secondaryHexColor: [
    {
      rule: isPresent,
      errorMessage: 'You must select a secondary color',
    },
  ],
};
