import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommunicationPreferencesView } from "../components/CommunicationPreferencesView";

import { getCommunicationPreferences } from "../actions";
import { getLoading, getErrors } from "../selectors";

function mapStateToProps(state) {
  return {
    loading: getLoading(state),
    errors: getErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCommunicationPreferences,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationPreferencesView);
