import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getWaiverSubmissions = (state) => state.manageLeads.leadDetail[NAME].waiverSubmissions;
export const getSelectedWaiverSubmissionId = (state) => state.manageLeads.leadDetail[NAME].selectedWaiverSubmissionId;
export const getLoading = (state) => state.manageLeads.leadDetail[NAME].loading;
export const getPageDetails = (state) => state.manageLeads.leadDetail[NAME].pageDetails;

export const selectedWaiverSubmission = createSelector(
  [getWaiverSubmissions, getSelectedWaiverSubmissionId],
  (waiverSubmissions, selectedWaiverSubmissionId) => {
    if (waiverSubmissions && waiverSubmissions.length > 0) {
      const index = waiverSubmissions.findIndex(
        (waiverSubmission) => parseInt(waiverSubmission.id) === parseInt(selectedWaiverSubmissionId)
      );
      return waiverSubmissions[index];
    }
  }
);
