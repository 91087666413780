import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CreateLeadForm from "../components/CreateLeadForm";
import session from "modules/Stores/Session";
import userData from "modules/Stores/UserData";
import leadSources from "modules/Stores/LeadSources";

import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    user: session.selectors.getUser(state),
    data: selectors.getData(state),
    countries: userData.selectors.getCountries(state),
    sources: leadSources.selectors.getLeadSources(state),
    selectedLocation: selectors.getSelectedLocation(state),
    selectedLeadSource: selectors.getSelectedLeadSource(state),
    selectedHeardAboutMethod: selectors.getSelectedHeardAboutMethod(state),
    selectedLeadGoal: selectors.getSelectedLeadGoal(state),
    selectedUser: selectors.getSelectedUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentView: actions.setCurrentView,
      updateData: actions.updateData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLeadForm);
