import { request, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';
import regions from 'modules/Stores/Regions';

export const deleteRegionWithId = (regionId) => {
  return (dispatch) => {
    dispatch(regions.actions.setLoading(true));
    function onFailure(errors) {
      dispatch(regions.actions.setLoading(false));
      dispatch(regions.actions.setErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(regions.actions.setLoading(false));
      dispatch(regions.actions.removeRegionWithId(regionId));
      notify.success('Region deleted');
    }

    return request('regions/' + regionId, DELETE, null, onSuccess, onFailure);
  };
};

export function selectRegionId(regionId) {
  return {
    type: actionTypes.SELECT_REGION_ID,
    regionId,
  };
}
