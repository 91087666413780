import { isPresent } from 'utils/validation'

export function validatorForOption (option) {
  switch (option) {
    case 'gymmaster':
      return GymMasterValidator
    default:
      {}
  }
}

const GymMasterValidator = {
  apiKey: [{
    rule: isPresent,
    errorMessage: 'We need your API token for your GymMaster account.'
  }],
  customUrl: [{
    rule: isPresent,
    errorMessage: 'What\'s your GymMaster url?'
  }]
}
