import PropTypes from 'prop-types'
import React, { Component } from 'react'

import UpdateScheduleModalContainer from '../containers/UpdateScheduleModalContainer'

import { SCHEDULE_MODAL_NAME } from '../constants'

export class ScheduleView extends Component {

  showCalendar = () => {
    this.props.showModal(SCHEDULE_MODAL_NAME)
  }

  render () {
    const { loading, user, location, schedule } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Availability for { user.display_name } at {location.admin_display_name}</h3>
          </div>
          <div className='block-content'>
            <div className='row'>
              <div className='col-xs-12'>
                {!schedule &&
                  <p>{user.given_name} is always available.</p>
                }
                {schedule &&
                  <p>{user.given_name} is partially available.</p>
                }
                <button className='btn btn-primary push-5-r push-10'
                  type='button'
                  onClick={this.showCalendar}>
                  <i className='fa fa-calendar' /> Update user availability
                </button>
              </div>
            </div>
            <UpdateScheduleModalContainer user={user} location={location} modalName={SCHEDULE_MODAL_NAME} />
          </div>
        </div>
      </div>
    )
  }
}

ScheduleView.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  schedule: PropTypes.object,
  errors: PropTypes.object
}

export default ScheduleView
