import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AppointmentItemList from '../components/AppointmentItemList/AppointmentItemList'
import appointments from '../../appointments'
import session from 'modules/Stores/Session'

function mapStateToProps (state, ownProps) {
  return {
    appointments: appointments.selectors.getAppointments(state, ownProps.pathToLeadDetail),
    user: session.selectors.getUser(state),
    pageDetails: appointments.selectors.getPageDetails(state, ownProps.pathToLeadDetail),
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchAppointments: appointments.actions.fetchAppointments
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentItemList)
