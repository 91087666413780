import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import { FormFields } from 'modules/components'
import { validatorForOption } from '../validators'
import { fieldsForOption } from '../helpers'

const { TextField, PasswordField } = FormFields

export class ConfigureForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  render () {
    const { username, companyName, customUrl, integrationLocationId, password, apiKey } = this.props.data.fields
    const validator = validatorForOption(this.props.name)
    const fields = fieldsForOption(this.props.name)
    return (
      <div className='row'>
        {fields.username &&
          <div className='col-sm-6'>
            <TextField name='username'
              label={fields.username}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={username.value} />
          </div>
        }
        {fields.companyName &&
          <div className='col-sm-6'>
            <TextField name='companyName'
              label={fields.companyName}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={companyName.value} />
          </div>
        }
        {fields.customUrl &&
          <div className='col-sm-6'>
            <TextField name='customUrl'
              label={fields.customUrl}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={customUrl.value} />
          </div>
        }
        {fields.integrationLocationId &&
          <div className='col-sm-6'>
            <TextField name='integrationLocationId'
              label={fields.integrationLocationId}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              value={integrationLocationId.value} />
          </div>
        }
        {fields.password &&
          <div className='col-sm-6'>
            <PasswordField
              name='password'
              label={fields.password}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={password.value} />
          </div>
        }
        {fields.apiKey &&
          <div className='col-sm-6'>
            <TextField name='apiKey'
              label={fields.apiKey}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={apiKey.value} />
          </div>
        }
      </div>
    )
  }
}

ConfigureForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}

export default ConfigureForm
