import React, { Component } from "react";
import styled from "styled-components";
import { FormFields, InfoView } from "modules/components";
import { validatorForOption } from "../validators";
const { TextField } = FormFields;

export default class CreateSubscriptionView extends Component {
  state = {
    name: "",
    apiKey: ""
  };

  handleInput = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  handleCreateSubscription = async () => {
    const {
      createSubscription,
      selectedIntegration,
      selectedLocation,
      toggleShowSubscription
    } = this.props;
    const { name, apiKey } = this.state;
    const success = await createSubscription(
      selectedLocation.id,
      selectedIntegration.id,
      name,
      apiKey
    );
    if (success) {
      this.setState({ name: "", apiKey: "" });
      toggleShowSubscription()
    }
  };

  render() {
    const { errors } = this.props;
    const { name, apiKey } = this.state;
    const validator = validatorForOption("calendly");
    const createButtonDisabled = name.length === 0 || apiKey.length === 0;
    return (
      <div className="col-md-12">
        <Container className="row">
          <div className="col-sm-5">
            <TextField
              name="name"
              label={"Name"}
              placeholder=""
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={name}
            />
          </div>
          <div className="col-sm-5">
            <TextField
              name="apiKey"
              label={"API Key"}
              placeholder=""
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={apiKey}
            />
          </div>
          <ButtonContainer className="col-sm-2 text-right">
            <button
              className="wizard-prev btn btn-success"
              disabled={createButtonDisabled}
              onClick={this.handleCreateSubscription}
            >
              Create
            </button>
          </ButtonContainer>
        </Container>
        {errors && <InfoView colorThemeClass={'danger'} detail={errors.message} />}
        <Divider />
      </div>
    );
  }
}

const Container = styled.div`
  margin: -20px 0px 20px 0px;
`;

const ButtonContainer = styled.div`
  bottom: -42px;
`;

const Divider = styled.hr``;