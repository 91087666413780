import PropTypes from "prop-types";
import React, { Component } from "react";

import LocationSelect from "modules/components/LocationSelect";
import ListingContainer from "../containers/ListingContainer";

export class ThirdPartyIntegrationsView extends Component {
  render() {
    const { selectedLocation, selectedLocationId, selectLocation } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <LocationSelect.LocationSelectContainer
              onLocationSelect={selectLocation}
              selectedLocationId={selectedLocationId}
            />
          </div>
          {this.props.children}
        </div>
        {selectedLocation && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <ListingContainer />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ThirdPartyIntegrationsView.propTypes = {
  selectLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
  selectedLocationId: PropTypes.number,
  children: PropTypes.element
};

export default ThirdPartyIntegrationsView;
