import React, { Component } from 'react'
import LeadsListHeaderContainer from '../containers/LeadsListHeaderContainer'

import FiltersContainer from '../containers/FiltersContainer'

import FunnelCountContainer from '../containers/FunnelCountContainer'
import StatusCountContainer from '../containers/StatusCountContainer'
import SourceCountContainer from '../containers/SourceCountContainer'
import LeadsTableContainer from '../containers/LeadsTableContainer'

export class LeadsListReportView extends Component {

  render () {
    return (
      <div className='content push-100'>
        <div className='row'>
          <div className='col-xs-12'>
            <LeadsListHeaderContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <FiltersContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <FunnelCountContainer />
          </div>
          <div className='col-md-6'>
            <StatusCountContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <SourceCountContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <LeadsTableContainer />
          </div>
        </div>
      </div>
    )
  }
}

LeadsListReportView.propTypes = {

}
