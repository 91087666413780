import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import { NO_CHANGE } from '../../constants';

export const getTimeTaken = (queryString, selectedCell) => {
  return (dispatch) => {
    if (!selectedCell || selectedCell.status === NO_CHANGE) return;
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {}

    let url = `trial_reports/time_taken?${encodeURI(queryString)}&filter[statuses][]=${selectedCell.status}`;
    url = selectedCell.trialId ? url + `&filter[trial_id]=${selectedCell.trialId}` : url;

    return request(url, GET, null, onSuccess, onFailure);
  };
};

export function websocketSetData(data) {
  return (dispatch) => {
    dispatch(setData(data));
    dispatch(setLoading(false));
  };
}

function setData(data) {
  return {
    type: actionTypes.SET_DATA,
    data,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}
