import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DeleteAutomaticReportButton from "../modules/components/DeleteAutomaticReportButton";
import UsersToEmailContainer from "../modules/containers/UsersToEmailContainer";
import AttributeWrapper from "../modules/components/AttributeWrapper";

import { FormFields } from "modules/components";
const { TextField } = FormFields;

export default class EditReportView extends Component {
  state = {
    reportName: this.props.selectedReport.name,
    userIds: this.props.selectedReport.userIdsToEmail,
    userIdsToEmail: this.props.selectedReport.userIdsToEmail
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedReport !== nextProps.selectedReport
    ) {
      this.setState({
        reportName: nextProps.selectedReport.name,
        userIds: nextProps.selectedReport.userIdsToEmail,
        userIdsToEmail: nextProps.selectedReport.userIdsToEmail,
      });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleUserSelect = (selectedUserIds) => {
    this.setState({...this.state, userIdsToEmail: selectedUserIds})
  };

  addUserId = userId => {
    const updatedUserIds = [...this.state.userIds, userId];
    this.setState({ ...this.state, userIds: updatedUserIds });
  };

  removeUserId = removeUserId => {
    const index = this.state.userIds.findIndex(
      userId => userId === removeUserId
    );
    const newSelectedIds = [
      ...this.state.userIds.slice(0, index),
      ...this.state.userIds.slice(index + 1)
    ];
    this.setState({ ...this.state, userIds: newSelectedIds });
  };

  handleSave = () => {
    const { updateAutomaticReport, selectedReport } = this.props
    const { reportName, userIdsToEmail } = this.state
    updateAutomaticReport(selectedReport.id, reportName, userIdsToEmail)
  }

  render() {
    const { selectedReport, deleteAutomaticReport } = this.props;
    const { reportName, userIds } = this.state;
    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <h3 className="block-title">Edit {selectedReport.name}</h3>
        </div>
        <div className="block-content">
          <div className="row">
            <div className="col-xs-12">
              <AttributeWrapper title="Name">
                <NameContainer className="row">
                  <TextField
                    name="reportName"
                    placeholder=""
                    formFieldStyle=""
                    onChange={this.handleChange}
                    value={reportName}
                  />
                </NameContainer>
              </AttributeWrapper>
            </div>
            <div className="col-xs-12">
              <AttributeWrapper title="Users to Email">
                <UsersToEmailContainer
                  userIds={userIds}
                  addUserId={this.addUserId}
                  removeUserId={this.removeUserId}
                  handleUserSelect={this.handleUserSelect}
                />
              </AttributeWrapper>
            </div>
          </div>
          <ButtonRow className="row">
            <div className="col-xs-12">
              <SaveButton
                className="btn btn-success"
                type="button"
                onClick={this.handleSave}
              >
                Update Report
              </SaveButton>
            </div>
            <div className="col-xs-12">
              <DeleteAutomaticReportButton
                deleteAutomaticReport={deleteAutomaticReport}
                selectedReport={selectedReport}
              />
            </div>
          </ButtonRow>
        </div>
      </div>
    );
  }
}

EditReportView.propTypes = {
  updateAutomaticReport: PropTypes.func.isRequired,
  selectedReport: PropTypes.object.isRequired
};

const SaveButton = styled.button`
  width: 125px;
`;

const NameContainer = styled.div`
  margin: -20px -15px 5px -15px;
`;

const ButtonRow = styled.div`
  padding: 20px 0px 20px 0px;
`;
