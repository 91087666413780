import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, InfoView } from 'modules/components'

export class CreateAutoAssignRuleView extends Component {

  createClicked = () => {
    const { createAutoAssignRuleForLocation, location } = this.props
    createAutoAssignRuleForLocation(location)
  }

  render () {
    const { loading, location } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const infoString = 'Automatically assign incoming leads to a user. If a lead comes in outside of ' +
    'calling hours, we\'ll schedule any automatic calls for the following day.'
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Auto-assign rule for { location.admin_display_name }</h3>
          </div>
          <div className='block-content push'>
            <InfoView colorThemeClass={'info'} detail={infoString} />
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-user-following'
              title='No auto-assign rule'
              subtitle='This club is not using any auto-assign rule.
               Click below to create one'
              buttonText='Create Auto-assign Rule'
              buttonSelected={this.createClicked} />
          </div>
        </div>
      </div>
    )
  }
}

CreateAutoAssignRuleView.propTypes = {
  location: PropTypes.object.isRequired,
  createAutoAssignRuleForLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default CreateAutoAssignRuleView
