import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView } from 'modules/components'

export class CreateSalesTargetView extends Component {

  createClicked = () => {
    const { createSalesTargetForLocation, location } = this.props
    createSalesTargetForLocation(location)
  }

  render () {
    const { loading, location } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Sales Targets for { location.admin_display_name }</h3>
          </div>
          <div className='block-content push'>
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-target'
              title='Using Defaults'
              subtitle='This club is using the default sales targets set by the owners.
               Click below to create your own for salespeople at this club.'
              buttonText='Create Unique Sales Targets'
              buttonSelected={this.createClicked} />
          </div>
        </div>
      </div>
    )
  }
}

CreateSalesTargetView.propTypes = {
  location: PropTypes.object.isRequired,
  createSalesTargetForLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default CreateSalesTargetView
