import * as actionTypes from './actionTypes'

const SELECT_SMS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_SMS_ACCOUNTS]: (state, action) => {
    return { ...state, smsAccounts: action.smsAccounts }
  },
  [actionTypes.SET_SELECTED_SMS_ACCOUNT_ID]: (state, action) => {
    return { ...state, selectedSmsAccountId: action.selectedSmsAccountId }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.UPDATE_SMS_ACCOUNT]: (state, action) => {
    const index = indexForSmsAccount(state.smsAccounts, action.smsAccount.id)
    const newSmsAccounts = [
      ...state.smsAccounts.slice(0, index),
      action.smsAccount,
      ...state.smsAccounts.slice(index + 1)
    ]
    return { ...state, smsAccounts: newSmsAccounts }
  },
  [actionTypes.SET_PAGE_DETAILS]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  loading: false,
  errors: null,
  smsAccounts: [],
  selectedSmsAccountId: null,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = SELECT_SMS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

function indexForSmsAccount (smsAccounts, smsAccountId) {
  return smsAccounts.findIndex(smsAccount => parseInt(smsAccount.id) === parseInt(smsAccountId))
}
