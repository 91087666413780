import React from "react";
import MultiLeadLostReasonSelect from "modules/components/MultiLeadLostReasonSelect";

const LeadLostReasonsSection = ({
  SELECT_LEAD_SOURCES_MODAL,
  clubsFiltersClass,
  leadLostReasonIdsName,
  leadLostReasonIdsData,
  showModal,
  clubTitle,
  onUpdate
}) => {
  function showSelectLeadLostReasonsModal() {
    showModal(SELECT_LEAD_SOURCES_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${clubsFiltersClass}`}
        type="button"
        onClick={showSelectLeadLostReasonsModal}
      >
        {clubTitle}
      </div>
      <MultiLeadLostReasonSelect.MultiLeadLostReasonSelectModalContainer
        modalName={SELECT_LEAD_SOURCES_MODAL}
        sectionName={leadLostReasonIdsName}
        onUpdate={onUpdate}
        data={leadLostReasonIdsData}
      />
    </div>
  );
};

export default LeadLostReasonsSection;
