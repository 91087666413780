import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import RecordAppointmentModal from '../components/NewAppointmentModal/RecordAppointmentModal'
import leadsTrials from '../../leadsTrials'

import modal from 'modules/Stores/Modal'
import UserData from 'modules/Stores/UserData'
import details from 'modules/components/LeadDetail/modules/details'
import * as appointmentActions from '../actions'

const { hideModal, showModal } = modal.actions
const { updateLead } = details.actions

function mapStateToProps (state, ownProps) {
  return {
    currentTrial: leadsTrials.selectors.getCurrentTrial(state),
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    salesFunnel: UserData.selectors.getSalesFunnel(state),
    scheduleCallModalName: 'AFTER_APP_COMPLETE_APPOINTMENT_MODAL',
    rescheduleModalName: 'AFTER_APP_RESCHEDULE_MODAL',
    classAppointmentModalName: 'AFTER_APP_CLASS_APPOINTMENT_MODAL'
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addLeadToTrial: leadsTrials.actions.addLeadToTrial,
    hideModal,
    showModal,
    updateLead,
    toggleComplete: appointmentActions.toggleComplete,
    moveToSecondStep: appointmentActions.moveToSecondStep,
    handleAfterActionForOutcome: appointmentActions.handleAfterActionForOutcome
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordAppointmentModal)
