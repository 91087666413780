import { NAME } from "./constants";
import { createSelector } from "reselect";

export const getErrors = (state) => state.trialsReport[NAME].errors;
export const getLoading = (state) => state.trialsReport[NAME].loading;
export const getTrials = (state) => state.trialsReport[NAME].trials;
export const getTable = (state) => state.trialsReport[NAME].table;
export const getSelectedCell = (state) => state.trialsReport[NAME].selectedCell;

export const getSelectedTrial = createSelector(
  [getSelectedCell, getTrials],
  (selectedCell, trialdata) => {
    if (!selectedCell) {
      return false;
    }
    if (!trialdata) {
      return false;
    }
    return trialdata.find((trial) => trial.id === selectedCell.trialId);
  }
);
