export const days = [
  {
    id: "mon",
    display: "Monday"
  },
  {
    id: "tue",
    display: "Tuesday"
  },
  {
    id: "wed",
    display: "Wednesday"
  },
  {
    id: "thu",
    display: "Thursday"
  },
  {
    id: "fri",
    display: "Friday"
  },
  {
    id: "sat",
    display: "Saturday"
  },
  {
    id: "sun",
    display: "Sunday"
  }
];