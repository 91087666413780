import PropTypes from "prop-types";
import React, { Component } from "react";
import { transformDataToSection } from "modules/components/SelectDateRangeButton/helpers.js";
import { pluralizationHelper } from "modules/Helpers/PluralizationHelpers";
import { ReportFilterFormStyler } from "modules/components/FilterFormSections/components";
import { CLUBS, USERS } from "../constants";

import {
  HeardAboutMethodsSectionContainer,
  LeadSourceSectionContainer,
  TagsSectionContainer,
  UsersContainer,
  LocationsSectionContainer,
  RegionsSectionContainer,
  DateRangeContainer,
  CompareSelectContainer,
  CountriesSectionContainer
} from "modules/components/FilterFormSections/modules/filterOptions";

export class FiltersView extends Component {
  onUpdate = (sectionData, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = { ...sections, [sectionName]: sectionData };
    updateFilters(newSections);
  };

  onDateSelect = (data, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = transformDataToSection(data, sectionName, sections);

    updateFilters(newSections);
  };

  toggleFilters = () => {
    const { showingMore, showFilters, hideFilters } = this.props;
    showingMore === true ? hideFilters() : showFilters();
  };

  render() {
    const { showingMore, activeExtraFiltersCount } = this.props;
    const showMoreFiltersTitle =
      activeExtraFiltersCount > 0
        ? pluralizationHelper("active filters", activeExtraFiltersCount)
        : "Show more filters";
    const moreFiltersTitle = showingMore
      ? "Hide filters"
      : showMoreFiltersTitle;
    const moreFiltersClass =
      activeExtraFiltersCount > 0 ? "btn btn-info" : "btn btn-default";

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-content">
          <div className="row push-20">
            <div className="col-sm-2 text-center">
              <ReportFilterFormStyler title="Date Range" primary={true}>
                <DateRangeContainer
                  onUpdate={this.onDateSelect}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-sm-2 text-center">
              <ReportFilterFormStyler title="Locations" primary={true}>
                <LocationsSectionContainer
                  onUpdate={this.onUpdate}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-sm-2 text-center">
              <ReportFilterFormStyler title="Salespeople" primary={true}>
                <UsersContainer
                  onUpdate={this.onUpdate}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-md-2">
              <ReportFilterFormStyler title="Lead Sources" primary={true}>
                <LeadSourceSectionContainer
                  onUpdate={this.onUpdate}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            {this.props.permissions.multi_user && (
              <div className="col-sm-2">
                <ReportFilterFormStyler title="Compare by" primary={true}>
                  <CompareSelectContainer
                    compareOptions={[USERS, CLUBS]}
                    compareOption={this.props.compareOption}
                    setCompareOption={this.props.setCompareOption}
                  />
                </ReportFilterFormStyler>
              </div>
            )}

            <div className="col-sm-2">
              <br />
              <button
                className={moreFiltersClass + " push-5-r push-10"}
                type="button"
                style={{ marginTop: "5px" }}
                onClick={this.toggleFilters}
              >
                <i className="fa fa-filter pull-left" />
                {moreFiltersTitle}{" "}
              </button>
            </div>
          </div>
          {showingMore === true && (
            <div className="row push-20">
              <div className="col-md-2">
                <ReportFilterFormStyler title="Heard About Methods" primary>
                  <HeardAboutMethodsSectionContainer
                    onUpdate={this.onUpdate}
                    filterData={this.props.data}
                  />
                </ReportFilterFormStyler>
              </div>
              <div className="col-md-2">
                <ReportFilterFormStyler title="Regions" primary>
                  <RegionsSectionContainer
                    onUpdate={this.onUpdate}
                    filterData={this.props.data}
                  />
                </ReportFilterFormStyler>
              </div>
              <div className="col-md-2">
                <ReportFilterFormStyler title="Countries" primary>
                  <CountriesSectionContainer
                    onUpdate={this.onUpdate}
                    filterData={this.props.data}
                  />
                </ReportFilterFormStyler>
              </div>
              <div className="col-md-4">
                <ReportFilterFormStyler title="Tags">
                  <TagsSectionContainer
                    onUpdate={this.onUpdate}
                    filterData={this.props.data}
                  />
                </ReportFilterFormStyler>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

FiltersView.propTypes = {
  showingMore: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  compareOption: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  showFilters: PropTypes.func.isRequired,
  hideFilters: PropTypes.func.isRequired,
  activeExtraFiltersCount: PropTypes.number.isRequired,
  setCompareOption: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired
};

export default FiltersView;
