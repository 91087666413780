import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import session from 'modules/Stores/Session'
import taskList from '../../modules/TaskList'
import dateRangeSelect from '../../modules/DateRangeSelect'

const mapStateToProps = (state) => {
  return {
    loading: taskList.selectors.getLoading(state),
    timezone: session.selectors.getUserTimezone(state),
    tasks: taskList.selectors.getTasks(state),
    pageCount: taskList.selectors.getPageCount(state),
    selectedTask: taskList.selectors.selectedTask(state),
    currentPage: taskList.selectors.getCurrentPage(state),
    totalCount: taskList.selectors.getTotalCount(state),
    startDate: dateRangeSelect.selectors.getStartDate(state),
    endDate: dateRangeSelect.selectors.getEndDate(state),
    user: session.selectors.getUser(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchInRange: taskList.actions.fetchInRange,
    selectTask: taskList.actions.selectTask,
    completeTask: taskList.actions.completeTask
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(taskList.components.TaskListView)
