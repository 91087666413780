import { createSelector } from 'reselect'
import { NAME } from './constants'
import userData from 'modules/Stores/UserData'
import session from 'modules/Stores/Session'

export const selectedLocationId = (state) => state[NAME][NAME].selectedLocationId

export const availableUserRole = createSelector(
  [ userData.selectors.getUserRoles, session.selectors.getUserRole ],
  (allUserRoles, currentUserRole) => {
    switch (currentUserRole.name) {
      case 'Owner':
        return allUserRoles
      case 'Admin':
        return allUserRoles
        .filter(role => ['Franchise Owner', 'Salesmanager', 'Salesperson', 'Reception'].includes(role.name))
      case 'Franchise Owner':
        return allUserRoles
        .filter(role => ['Salesmanager', 'Salesperson', 'Reception'].includes(role.name))
      case 'Salesmanager':
        return allUserRoles
        .filter(role => ['Salesperson', 'Reception'].includes(role.name))
      default:
        return []
    }
  }
)
