import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { MultiLineChartView, PieChart, TableView } from 'modules/components/Data'

export class RatesView extends Component {

  render () {
    const { loading, ratesDataSets, ratesPieChartData, tableData } = this.props
    let blockStyle = 'block block-rounded block-bordered'
    if (loading) { blockStyle = blockStyle + ' block-opt-refresh' }
    return (
      <div className='row'>
        <div className='col-md-6'>
          <div className={blockStyle}>
            <div className='block-header'>
              <h3 className='block-title'>Completion</h3>
            </div>
            <div className='block-content push-50' style={{ height: '310px' }}>
              <MultiLineChartView data={ratesDataSets} labels={this.props.labels} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className={blockStyle}>
            <div className='block-header'>
              <h3 className='block-title'>Completion Totals</h3>
            </div>
            <div className='block-content push-50' style={{ height: '310px' }}>
              <div className='row'>
                <div className='col-xs-4'>
                  <TableView data={tableData} />
                </div>
                <div className='col-xs-8'>
                  <PieChart data={ratesPieChartData} displayLegend={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RatesView.propTypes = {
  loading: PropTypes.bool.isRequired,
  ratesDataSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  ratesPieChartData: PropTypes.object.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default RatesView
