import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SELECT_LOCATIONS_MODAL } from '../constants'
import LocationsSection from '../components/LocationsSection'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  const locationIdsName = "location_ids";
  const locationIdsData = props.filterData && props.filterData[locationIdsName] ? props.filterData[locationIdsName] : { fields: {} }
  const locationsCount = Object.keys(locationIdsData.fields).length;
  const clubsFiltersClass = locationsCount > 0 ? "btn btn-info" : "btn btn-default";
  const clubTitle = locationsCount > 0 ? `${locationsCount} selected` : "All locations";

  return {
    SELECT_LOCATIONS_MODAL: SELECT_LOCATIONS_MODAL,
    locationIdsName: locationIdsName,
    clubTitle: clubTitle,
    clubsFiltersClass: clubsFiltersClass,
    locationIdsData: locationIdsData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsSection)
