import { isEmail, isPresent } from 'utils/validation'

export const InviteTeamMemberValidator = {
  'givenName': [{
    rule: isPresent,
    errorMessage: 'What is their name?'
  }],
  'email': [{
    rule: isEmail,
    errorMessage: 'That doesn\'t seem to be a valid email'
  }],
  'role': [{
    rule: isPresent,
    errorMessage: 'What should their role be?'
  }]
}
