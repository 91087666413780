import React, { Component } from "react";
import PropTypes from "prop-types";
import ReportHeaderContainer from "../modules/header/containers/ReportHeaderContainer"
import FiltersContainer from "../containers/FiltersContainer";
import TrialsTableContainer from "../modules/trialsTable/containers/TrialsTableContainer";
import LeadsTableContainer from '../modules/leadsTable/containers/LeadsTableContainer'
import TimeTakenTableContainer from '../modules/timeTakenTable/containers/TimeTakenTableContainer'

export class TrialsReportView extends Component {
  componentDidMount() {
    const { subscribeToTrialsReport, user } = this.props
    subscribeToTrialsReport(user)
  }
  render() {
    return (
      <div className="content push-100">
        <div className="row">
          <div className="col-xs-12">
            <ReportHeaderContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FiltersContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TrialsTableContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TimeTakenTableContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <LeadsTableContainer />
          </div>
        </div>
      </div>
    );
  }
}

TrialsReportView.propTypes = {
  queryString: PropTypes.string
};
