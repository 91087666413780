import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InboxEmpty from 'images/TemplatesEmpty.jpg';
import NotificationItem from './NotificationItem';
import { history } from 'store/store';

import { ContentBlockView, ImageEmptyView, PagesView } from 'modules/components';

export class SharedInboxView extends Component {
  UNSAFE_componentWillMount() {
    this.getNotificationsWithPageNumber(1);
  }

  getNotificationsWithPageNumber = (pageNumber) => {
    const { getSharedInboxNotifications, location, selectedUserIds } = this.props;
    getSharedInboxNotifications(pageNumber, location.id, selectedUserIds);
  };

  pageClicked = (pageNumber) => {
    this.getNotificationsWithPageNumber(pageNumber);
  };

  onNotificationSelect = (notification) => {
    if (!notification) {
      return;
    }
    const owner = notification.owner;
    if (!owner) {
      return;
    }

    const lead = owner.notification_lead;
    history.push('/secure/manageLeads?lead_id=' + lead.id);
  };

  render() {
    const { notifications, pageDetails, loading } = this.props;
    const pageCount = pageDetails.pageCount;

    const headerView = () => (
      <thead>
        <tr>
          <th className="text-center" style={{ width: '12px' }}>
            <i className="si si-user" />
          </th>
          <th>User</th>
          <th style={{ width: '25%' }}>Lead</th>
          <th style={{ width: '25%' }}>Date</th>
          <th />
          <th />
        </tr>
      </thead>
    );

    const notificationItems = notifications
      .filter((notification) => {
        if (!notification.owner) {
          return false;
        }
        if (!notification.owner.notification_lead) {
          return false;
        }
        return true;
      })
      .map((notification, index) => {
        return <NotificationItem notification={notification} onNotificationSelect={this.onNotificationSelect} key={index} />;
      });

    const blockOptions = () => {
      return (
        <ul className="block-options">
          <li>
            <button type="button" onClick={() => this.getNotificationsWithPageNumber(1)}>
              <i className="si si-refresh" />
            </button>
          </li>
        </ul>
      );
    };

    return (
      <ContentBlockView title={'Shared Inbox'} loading={loading} bordered blockOptions={blockOptions()}>
        {notificationItems.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-vcenter">
              {headerView()}
              <tbody>{notificationItems}</tbody>
            </table>
          </div>
        )}
        {notificationItems.length === 0 && (
          <ImageEmptyView
            image={InboxEmpty}
            title={'No Shared Replies'}
            subtitle="Incoming messages from your teammates leads will show here"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

SharedInboxView.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  loading: PropTypes.bool,
  selectedUserIds: PropTypes.arrayOf(PropTypes.number),
  pageDetails: PropTypes.object,
  getSharedInboxNotifications: PropTypes.func,
};

export default SharedInboxView;
