import { NAME } from './constants'

export const LOADING = NAME + '/LOADING'
export const LOAD_SUCCESS = NAME + '/LOAD_SUCCESS'
export const LOAD_FAILED = NAME + '/LOAD_FAILED'

export const SET_EMAIL_FORMS = NAME + '/SET_EMAIL_FORMS'
export const SET_SELECTED_EMAIL_FORM_ID = NAME + '/SET_SELECTED_EMAIL_FORM_ID'

export const ADD_EMAIL_FORM = NAME + '/ADD_EMAIL_FORMS'
export const UPDATE_EMAIL_FORM = NAME + '/UPDATE_EMAIL_FORM'
export const DELETE_EMAIL_FORM = NAME + '/DELETE_EMAIL_FORM'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_SEARCH_TERM = NAME + '/SET_SEARCH_TERM'
