import * as actionTypes from './actionTypes'

export function csvWasSelected (file) {
  const errors = fileErrors(file)
  if (errors.length === 0) {
    return {
      type: actionTypes.CSV_WAS_SELECTED,
      file
    }
  } else {
    return {
      type: actionTypes.FILE_ERROR,
      errorString: errors[0]
    }
  }
}

export function selectLocation (location) {
  return {
    type: actionTypes.LOCATION_SELECTED,
    location
  }
}

function fileErrors (file) {
  let errors = []
  const sizeOk = file.size < 5000000
  const fileNameOk = (file.name.includes('.csv'))
  if (!sizeOk) {
    errors.push('CSV must be under 5 MB')
  }
  if (!fileNameOk) {
    errors.push('You must select a csv file')
  }

  return errors
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setEnhancedPrivacy (enhancedPrivacy) {
  return {
    type: actionTypes.SET_ENHANCED_PRIVACY,
    enhancedPrivacy
  }
}
