import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MigrateLeadSourceModal from "../components/MigrateLeadSourceModal";

import * as actions from "../actions";
import leadSources from "modules/Stores/LeadSources";
import * as leadSourceApiActions from "modules/Stores/LeadSources/apiActions";

import modal from "modules/Stores/Modal";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    leadSources: leadSources.selectors.getLeadSources(state),
    errors: modal.selectors.getModalErrors(state),
    pageDetails: leadSources.selectors.getPageDetails(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      getLeadSources: leadSourceApiActions.getLeadSources,
      deleteLeadSource: actions.deleteLeadSource
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MigrateLeadSourceModal);
