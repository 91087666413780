import { generateStore } from "utils/reduxHelpers";
import moment from "moment";

const name = "dateTimeSelect";

const initialState = {
  date: moment(),
  time: null,
  length: "30"
};

export default generateStore(name, initialState);
