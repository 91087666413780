import { combineReducers } from "redux";
import calendly from "./modules/calendly";
import mindbody from "./modules/mindbody";
import facebook from "./modules/facebook";
import perfectGym from "./modules/perfectGym";
import gymMaster from "./modules/gymmaster";
import booker from "./modules/booker";
import glofox from "./modules/glofox";
import defaultConfig from "./modules/defaultConfig";

export const reducer = combineReducers({
  [calendly.constants.NAME]: calendly.reducer,
  [mindbody.constants.NAME]: mindbody.reducer,
  [facebook.constants.NAME]: facebook.reducer,
  [perfectGym.constants.NAME]: perfectGym.reducer,
  [gymMaster.constants.NAME]: gymMaster.reducer,
  [defaultConfig.constants.NAME]: defaultConfig.reducer,
  [booker.constants.NAME]: booker.reducer,
  [glofox.constants.NAME]: glofox.reducer
});
