import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert'

export class ButtonsView extends Component {

  state = {
    showDanger: false
  }

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false })
  }

  deleteIntegration = () => {
    this.dismissPopups()
    const { deleteIntegration, integration, location } = this.props
    deleteIntegration(integration, location)
  }

  render () {
    return (
      <div className='row'>
        <div className='col-xs-6 text-left'>
          <button className='wizard-prev btn btn-danger' onClick={this.showDangerPopup}>
            Delete
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          <button className='wizard-next btn btn-default'
            onClick={this.props.hideModal}>
            Done
          </button>
        </div>
        {this.state.showDanger &&
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='danger'
            confirmBtnText='Delete Integration'
            title='Are you Sure?'
            onConfirm={this.deleteIntegration}
            onCancel={this.dismissPopups}>
            You will no longer be able to import leads from Facebook messenger.{' '}
            You must log out and in before changes take effect.
          </SweetAlert>
        }
      </div>
    )
  }
}

ButtonsView.propTypes = {
  integration: PropTypes.object.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
}

export default ButtonsView
