import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import moment from "moment";
import DateSelect from "./DateSelect";
import dateTimeSelect from "modules/Stores/DateTimeSelect";

import { FormFields } from "modules/components";
const { SelectField } = FormFields;

class DateTimeSelectView extends Component {
  componentDidMount() {
    this.props.setLength(this.props.recomendedLength || "30");
  }

  componentWillUnmount() {
    this.props.reset();
  }

  currentDate = () => {
    let currentDate = this.props.dateTime ? this.props.dateTime : moment();
    return this.props.date ? this.props.date : currentDate;
  };

  currentTime = () => {
    return this.props.dateTime ? this.props.dateTime.format() : this.props.time;
  };

  generateAndReturnDate = (date, time, length) => {
    if (!date || !time) {
      return;
    }
    const timeMoment = moment(time);

    let dateTime = date.clone();
    dateTime.set("hour", timeMoment.hour());
    dateTime.set("minute", timeMoment.minute());

    if (this.props.onUpdate) {
      this.props.onUpdate(dateTime.format(), length);
    }
  };

  generateTimeOptions() {
    let currentTime = moment()
      .startOf("day")
      .add(2, "h")
      .add(45, "m");
    let options = [];

    for (let i = 0; i < 80; i++) {
      currentTime.add(15, "minutes");
      let display = this.format(currentTime);
      options.push({ id: currentTime.format(), value: display });
    }
    return options;
  }

  handleTimeSelect = time => {
    this.props.setTime(time);
    this.generateAndReturnDate(this.props.date, time, this.props.length);
  };

  handleLengthSelect = length => {
    this.props.setLength(length);
    this.generateAndReturnDate(this.props.date, this.props.time, length);
  };

  handleDateSelect = date => {
    this.props.setDate(date);
    if (!this.props.time) {
      if (this.props.onDaySelect) {
        this.props.onDaySelect(date);
      }
    } else {
      this.generateAndReturnDate(date, this.props.time, this.props.length);
    }
  };

  format(time) {
    let h = time.hour();
    let m = this.pad(time.minute());
    let AmPm = h < 12 ? "AM" : "PM";

    // convert to 12 hour clock
    h = h % 12 || 12;

    // pad with a 0
    h = this.pad(h);

    return `${h}:${m} ${AmPm}`;
  }

  pad(value) {
    return value > 9 ? value : "0" + value;
  }

  render() {
    return (
      <div>
        <DateSelect
          style={this.props.style}
          onUpdate={this.handleDateSelect}
          date={this.currentDate()}
        />
        <div className="row">
          <div className="col-xs-4" style={{ paddingRight: "0px" }}>
            <SelectField
              name="time"
              possibleOptions={this.generateTimeOptions()}
              displayFieldName={"value"}
              label="Starting at"
              formFieldStyle=""
              onChange={(name, value, isValid) => this.handleTimeSelect(value)}
              disabled={false}
              value={this.currentTime()}
            />
          </div>
          <div
            className="col-xs-4"
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              marginLeft: "-20px"
            }}
          >
            <SelectField
              name="time"
              possibleOptions={["5", "10", "15", "30", "45", "60"]}
              formFieldStyle=""
              label="Going for"
              onChange={(name, value, isValid) =>
                this.handleLengthSelect(value)
              }
              disabled={false}
              initialValue={this.props.length}
            />
          </div>
          <div className="col-xs-4" style={{ paddingLeft: "0px" }}>
            <p style={{ marginTop: "30px" }}>Minutes</p>
          </div>
        </div>
      </div>
    );
  }
}

DateTimeSelectView.propTypes = {
  style: PropTypes.string,
  onUpdate: PropTypes.func,
  onDaySelect: PropTypes.func,
  dateTime: PropTypes.object,
  recomendedLength: PropTypes.string
};

function mapStateToProps(state) {
  return {
    date: dateTimeSelect.selectors.getDate(state),
    time: dateTimeSelect.selectors.getTime(state),
    length: dateTimeSelect.selectors.getLength(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      reset: dateTimeSelect.actions.reset,
      setDate: dateTimeSelect.actions.setDate,
      setTime: dateTimeSelect.actions.setTime,
      setLength: dateTimeSelect.actions.setLength
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeSelectView);
