import { isMinLength, isPresent } from 'utils/validation'

export const MainLocationValidator = {
  addressLineOne: [{
    rule: isPresent,
    errorMessage: 'Address Line One is required'
  }],
  city: [{
    rule: isPresent,
    errorMessage: 'City is required'
  }],
  state: [{
    rule: isPresent,
    errorMessage: 'State is required'
  }],
  countryId: [{
    rule: isPresent,
    errorMessage: 'Country is required'
  }],
  timezone: [{
    rule: isPresent,
    errorMessage: 'Timezone is required'
  }],
  zipPostcode: [{
    rule: isMinLength(3),
    errorMessage: 'Zip or Postcode must be more than 3 characters'
  }]
}
