export function titleForType (type) {
  switch (type) {
    case 'signed_up':
      return 'Presented: Closed lead'
    case 'wants_to_think_about_it':
      return 'Presented: They want to think about it'
    case 'no_show':
      return 'No Show'
    case 'not_interested':
      return 'Presented: couldn\'t close'
    case 'other':
      return 'Presented'
    case 'rescheduled':
      return 'Rescheduled'
    default:
      return 'Called'
  }
}

export function optionTitle (type) {
  switch (type) {
    case 'signed_up':
      return 'They signed up'
    case 'wants_to_think_about_it':
      return 'Will think about it'
    case 'no_show':
      return 'Didn\'t show up'
    case 'not_interested':
      return 'Won\'t be joining'
    case 'other':
      return 'Something else'
    case 'started_trial':
      return "Started a trial"
    case 'rescheduled':
      return "They rescheduled"
    default:
      return 'Called'
  }
}

export function optionIcon (type) {
  switch (type) {
    case 'signed_up':
      return 'si si-check'
    case 'wants_to_think_about_it':
      return 'si si-directions'
    case 'no_show':
      return 'si si-ban'
    case 'not_interested':
      return 'fa fa-thumbs-down'
    case 'other':
      return 'si si-question'
    case 'started_trial':
      return 'si si-clock'
    case 'rescheduled':
      return 'si si-action-redo'
    default:
      return ''
  }
}

export const allTypes = ['signed_up', 'wants_to_think_about_it', 'no_show', 'not_interested', 'other', 'started_trial', 'rescheduled']

export const SIGNED_UP = 'signed_up'
export const STARTED_TRIAL = 'started_trial'
export const WANTS_TO_THINK_ABOUT_IT = 'wants_to_think_about_it'
export const NO_SHOW = 'no_show'
export const NOT_INTERESTED = 'not_interested'
export const RESCHEDULED = 'rescheduled'
export const OTHER = 'other'

