import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class ManagerSalesTargetRow extends Component {

  titleString = () => {
    const { title } = this.props
    switch (title) {
      case 'new_leads_count':
        return 'New leads'
      case 'initial_call_count':
        return 'Contacts'
      case 'appointment_count':
        return 'Appointments'
      case 'presentation_count':
        return 'Presentations'
      case 'total_sales':
        return 'Sales'
      case 'referral_count':
        return 'Referrals'
      default:
        return ''
    }
  }

  contextColorClassName = () => {
    const { target } = this.props
    if (!target || target.percentage_on_track === null || target.percentage_on_track === undefined) {
      return ''
    }

    const percentageOnTrack = target.percentage_on_track

    if (percentageOnTrack >= 1.1) { return 'success' }

    if (percentageOnTrack >= 0.98 && percentageOnTrack <= 1.02) { return '' }

    if (percentageOnTrack <= 0.97 && percentageOnTrack >= 0.9) { return 'warning' }

    if (percentageOnTrack <= 0.89) { return 'danger' }
  }

  render () {
    const { target } = this.props

    return (
      <tr className={this.contextColorClassName()}>
        <td>{this.titleString()}</td>
        <td className='text-center'>
          {target.target}
        </td>
        <td className='text-center'>
          { target.target_to_date }
        </td>
        <td className='text-center'>
          {target.balance}
        </td>
        <td className='text-center'>
          { target.accumulated_goal_count }
        </td>
        <td className='text-center'>
          { (target.percentage_on_track * 100).toFixed(0) }%
        </td>
      </tr>
    )
  }
}

ManagerSalesTargetRow.propTypes = {
  target: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}
