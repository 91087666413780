import { NAME } from "./constants";

export const FETCHING_LEADS = NAME + "/FETCHING_LEADS";
export const FETCH_LEADS_SUCCESS = NAME + "/FETCH_LEADS_SUCCESS";
export const FETCH_LEADS_FAILED = NAME + "/FETCH_LEADS_FAILED";
export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const SELECT_LEAD = NAME + "/SELECT_LEAD";
export const DESELECT_LEAD = NAME + "/DESELECT_LEAD";
export const ADD_LEAD = NAME + "/ADD_LEAD";
export const REMOVE_LEAD = NAME + "/REMOVE_LEAD";
export const UPDATE_LEAD = NAME + "/UPDATE_LEAD";
export const UPDATE_SALESPERSON_FOR_LEAD =
  NAME + "/UPDATE_SALESPERSON_FOR_LEAD";

export const UPDATE_LEAD_SALES_FUNNEL_STEP =
  NAME + "/UPDATE_LEAD_SALES_FUNNEL_STEP";

export const CREATE_TAG = NAME + "/CREATE_TAG";
export const DELETE_TAG = NAME + "/DELETE_TAG";

export const DELETE = NAME + "/DELETE";

export const SET_SHOWING_QUICK_LEAD_VIEW =
  NAME + "/SET_SHOWING_QUICK_LEAD_VIEW";
