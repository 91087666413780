import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView, EmptyView, TextSearchView, PagesView } from 'modules/components'

import SelectCallScheduleTableView from './TableView/SelectCallScheduleTableView'

export class CallScheduleSelectView extends Component {

  UNSAFE_componentWillMount () {
    this.getCallSchedulesWithPageNumber(1)
  }

  getCallSchedulesWithPageNumber = (pageNumber) => {
    const { getCallSchedules, leadId } = this.props
    getCallSchedules(pageNumber, null, leadId)
  }

  pageClicked = (pageNumber) => {
    this.getCallSchedulesWithPageNumber(pageNumber)
  }

  searchUpdated = (searchText) => {
    const { getCallSchedules, leadId } = this.props
    const pageNumber = 1
    getCallSchedules(pageNumber, searchText, leadId)
  }

  render () {
    const { loading, pageDetails, callSchedules, selectedCallSchedule, onCallScheduleSelect } = this.props

    return (
      <ContentBlockView
        loading={loading}
        bordered>
        <div className='row' style={{ marginTop: '-20px' }}>
          <TextSearchView
            sectionName={'callScheduleSearch'}
            onUpdate={this.searchUpdated} />
        </div>
        {callSchedules.length > 0 &&
          <div>
            <SelectCallScheduleTableView
              callSchedules={callSchedules}
              selectedCallSchedule={selectedCallSchedule}
              onCallScheduleSelect={onCallScheduleSelect} />
          </div>
        }
        {callSchedules.length === 0 &&
          <EmptyView
            colorThemeClass='primary'
            iconClass='si si-magnifier'
            title='No results'
            subtitle='Check the search field' />
        }
        {pageDetails.pageCount > 1 &&
          <div className='text-center push'>
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked} />
          </div>
        }
      </ContentBlockView>
    )
  }
}

CallScheduleSelectView.propTypes = {
  pageDetails: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getCallSchedules: PropTypes.func.isRequired,
  onCallScheduleSelect: PropTypes.func.isRequired,
  setSelectedCallSchedule: PropTypes.func.isRequired,
  callSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCallSchedule: PropTypes.object
}

export default CallScheduleSelectView
