import React, { Component } from 'react'
import styled from 'styled-components';
import ToolTip from 'react-portal-tooltip'

class RichToolTip extends Component {

  state = {
    tooltipShowing: false
  }

  showToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: true })
  }

  hideToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: false })
  }

  render() {
    const { tooltipShowing } = this.state
    const { text, toptipId } = this.props
    return (
      <ToolTipIcon className='fa fa-question-circle selectable'
        id={toptipId}
        onMouseEnter={this.showToolTip}
        onMouseLeave={this.hideToolTip}>
      <ToolTip active={tooltipShowing} parent={`#${toptipId}`} position={'top'} arrow={'center'}>
        <Container>
          {text}
        </Container>
      </ToolTip>
    </ToolTipIcon>
    )
  }
}

export default RichToolTip

const ToolTipIcon = styled.i`
  margin-left: 2px;
`;

const Container = styled.div`
  padding: 10px 10px 0px 10px;
  max-width: 400px;
`;