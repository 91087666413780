import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getPotentialUsersForLocationId = (locationId, pageNumber) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setPotentialUsers(payload.users))
      dispatch(setPageDetails(payload.meta))
    }

    const url = 'users?location_ids[]=' + locationId + '&page[number]=' + pageNumber + '&detail=true'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setPotentialUsers (potentialUsers) {
  return {
    type: actionTypes.SET_POTENTIAL_USERS,
    potentialUsers
  }
}

export function setFromUser (fromUser) {
  return {
    type: actionTypes.SET_FROM_USER,
    fromUser: fromUser
  }
}

export function addToUserId (toUserId) {
  return {
    type: actionTypes.ADD_TO_USER_ID,
    toUserId: parseInt(toUserId)
  }
}

export function removeFromUserId (toUserId) {
  return {
    type: actionTypes.REMOVE_TO_USER_ID,
    toUserId: parseInt(toUserId)
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

// Utility
function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
