import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormFields, InfoView } from "modules/components";
import { generateNewFormState } from "utils/formHelpers";

import { SELECT_LOCATIONS_MODAL, SELECT_LEAD_SOURCE_MODAL } from "../constants";
import MultiLocationSelect from "modules/components/MultiLocationSelect";
import MultiLeadSourceSelect from "modules/components/MultiLeadSourceSelect";

const { TextField, Switch } = FormFields;

export class EditCallScheduleForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onInput(newData);
  };

  updateLocationIds = sectionData => {
    const locationIds = Object.keys(sectionData.fields).map(locationId => {
      return parseInt(locationId);
    });
    this.props.setLocationIds(locationIds);
  };

  updateLeadSourceIds = sectionData => {
    const leadSourceIds = Object.keys(sectionData.fields).map(leadSourceId => {
      return parseInt(leadSourceId);
    });
    this.props.setLeadSourceIds(leadSourceIds);
  };

  render() {
    const { name, isDefault } = this.props.data.fields;
    const { locationIdsData, leadSourceIdsData } = this.props;

    const switchLabel = "Make this the default schedule.";
    const warningString =
      "This is the default schedule. All leads that " +
      "don't match another schedule will be placed into this one.";

    const locationIdsTitle = "location_ids";
    const leadSourceIdsTitle = "lead_source_ids";
    const locationsCount = Object.keys(locationIdsData.fields).length;
    const leadSourcesCount = Object.keys(leadSourceIdsData.fields).length;
    const clubTitle =
      locationsCount > 0 ? locationsCount + " selected" : "All locations";
    const leadSourceTitle =
      leadSourcesCount > 0
        ? leadSourcesCount + " selected"
        : "All lead sources";
    return (
      <div>
        {isDefault.value === true && (
          <div className="row">
            <div className="col-sm-12">
              <InfoView colorThemeClass={"warning"} detail={warningString} />
            </div>
          </div>
        )}
        <div
          className="row"
          style={{
            marginLeft: "-30px",
            marginRight: "-30px",
            marginTop: "-15px"
          }}
        >
          <div className="col-sm-12 col-md-6">
            <TextField
              name="name"
              label="Call schedule name *"
              placeholder="Web form call schedule"
              formFieldStyle=""
              onChange={this.handleInput}
              value={name.value}
            />
          </div>
          <div className="col-sm-12">
            <Switch
              name="isDefault"
              label={switchLabel}
              onChange={this.handleInput}
              closeOnSelect={false}
              switchStyle="switch-sm switch-primary"
              value={isDefault.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 push-5-t">
            <label htmlFor="leadSourceSelect">Which clubs can use it?:</label>{" "}
            <button
              className="btn btn-default push-5-r push-10"
              style={{ display: "inline-block" }}
              type="button"
              onClick={() => this.props.showModal(SELECT_LOCATIONS_MODAL)}
              disabled={isDefault.value}
            >
              {clubTitle}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 push-5-t">
            <label htmlFor="leadSourceSelect">Lead Sources:</label>{" "}
            <button
              className="btn btn-default push-5-r push-10"
              style={{ display: "inline-block" }}
              type="button"
              onClick={() => this.props.showModal(SELECT_LEAD_SOURCE_MODAL)}
              disabled={isDefault.value}
            >
              {leadSourceTitle}
            </button>
          </div>
        </div>
        <MultiLocationSelect.MultiLocationSelectModalContainer
          modalName={SELECT_LOCATIONS_MODAL}
          sectionName={locationIdsTitle}
          onUpdate={this.updateLocationIds}
          data={locationIdsData}
        />
        <MultiLeadSourceSelect.MultiLeadSourceSelectModalContainer
          modalName={SELECT_LEAD_SOURCE_MODAL}
          sectionName={leadSourceIdsTitle}
          onUpdate={this.updateLeadSourceIds}
          data={leadSourceIdsData}
        />
      </div>
    );
  }
}

EditCallScheduleForm.propTypes = {
  data: PropTypes.object.isRequired,
  onInput: PropTypes.func.isRequired,
  setLeadSourceIds: PropTypes.func.isRequired,
  locationIdsData: PropTypes.object.isRequired,
  leadSourceIdsData: PropTypes.object.isRequired,
  locationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  showModal: PropTypes.func.isRequired,
  setLocationIds: PropTypes.func.isRequired
};

export default EditCallScheduleForm;
