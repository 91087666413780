import { request, POST, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export const fetchValidateToken = (token) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = {
      token: token,
    };

    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(tokenValidated(payload.validate_token));
      dispatch(loadSuccess());
    }
    return request(
      "validate_password_reset_request",
      POST,
      body,
      onSuccess,
      onFailure
    );
  };
};

function tokenValidated(validateToken) {
  return {
    type: actionTypes.TOKEN_VALIDATED,
    validateToken,
  };
}

export const resetPassword = (
  validateToken,
  newPassword,
  newPasswordConfirmation
) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = {
      reset_password_request: {
        validate_token: validateToken,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation,
      },
    };

    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccess());
      dispatch(resetPasswordSucceess());
    }

    return request("reset_password", POST, body, onSuccess, onFailure);
  };
};

function resetPasswordSucceess() {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
}

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}

// Whitelabel profile
export const fetchWhitelabelProfileLogo = (productName) => {
  return (dispatch) => {
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(setWhitelabelProfileLogo(payload));
    }

    const url = `whitelabel_profile_logo?client=${productName}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setWhitelabelProfileLogo(whitelabelProfileLogo) {
  return {
    type: actionTypes.WHITELABEL_PROFILE,
    whitelabelProfileLogo,
  };
}
