import { isPresent, isMinLength } from 'utils/validation'

const passwordMinLength = 8

const isStrongPassword = (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value);

export const UpdatePasswordFormValidator = {
  currentPassword: [{
    rule: isPresent,
    errorMessage: 'What is your current password?'
  }],
  newPassword: [{
    rule: isMinLength(passwordMinLength),
    errorMessage: 'Your new password needs to be longer than ' + passwordMinLength + ' characters'
  }, {
    rule: isStrongPassword,
    errorMessage: 'Your new password needs to include at least one uppercase letter, one lowercase letter, one number and one special character',
  }, {
    rule: isPresent,
    errorMessage: 'You need to enter a new password'
  }],
  newPasswordConfirmation: [{
    rule: isMinLength(passwordMinLength),
    errorMessage: 'Your new password needs to be longer than ' + passwordMinLength + ' characters'
  }, {
    rule: isStrongPassword,
    errorMessage: 'Your new password needs to include at least one uppercase letter, one lowercase letter, one number and one special character',
  }, {
    rule: isPresent,
    errorMessage: 'You need to enter a new password'
  }]
}
