import { GET, request } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const getUsersForLocationId = (locationId, pageNumber) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setUsers(payload.users));
      dispatch(setPageDetails(payload.meta));
    }

    const url = 'users?location_ids[]=' + locationId + '&page[number]=' + pageNumber + '&detail=true';
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setUsers(users) {
  return {
    type: actionTypes.SET_USERS,
    users,
  };
}

export function setUser(user) {
  return {
    type: actionTypes.SET_USER,
    user,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

// Utility
function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
