import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'
import header from '../modules/header'

function mapStateToProps (state) {
  const useStatusDate = filters.selectors.getUseStatusDate(state)
  let queryString = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)(state)
  if (useStatusDate === false) {
    queryString = filters.selectors.convertUpdatedQueryStringToCreated(queryString)
  }

  return {
    title: 'Leads',
    queued: header.selectors.getQueued(state),
    queryString: queryString
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    generateFullReport: header.actions.generateFullReport
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(header.components.ReportHeaderView)
