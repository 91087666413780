/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import { errorPanelForErrors } from "utils/formHelpers";

import OptionsView from "./OptionsView";

import ConfigurationModalContainer from "../../../containers/ConfigurationModalContainer";
import MindBodyConfigurationModalContainer from "../../../containers/MindBodyConfigurationModalContainer";
import GlofoxConfigurationModalContainer from "../../../containers/GlofoxConfigurationModalContainer";
import PerfectGymConfigurationModalContainer from "../../../containers/PerfectGymConfigurationModalContainer";
import GymmasterConfigurationModalContainer from "../../../containers/GymmasterConfigurationModalContainer";
import FacebookConfigurationModalContainer from "../../../containers/FacebookConfigurationModalContainer";
import CalendlyConfigurationModalContainer from "../../../containers/CalendlyConfigurationModalContainer";
import BookerConfigurationModalContainer from "../../../containers/BookerConfigurationModalContainer";

import { MODAL_NAME } from "../constants";

export class ListingView extends Component {
  state = {
    selectedKey: "outgoing",
  };

  UNSAFE_componentWillMount() {
    const { getAllOptions, getIntegrationsForLocation, location } = this.props;
    getAllOptions();
    getIntegrationsForLocation(location);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.id === this.props.location.id) {
      return;
    }
    const { getAllOptions, getIntegrationsForLocation, location } = nextProps;
    getAllOptions();
    getIntegrationsForLocation(location);
    this.props.setSelectedOption(null);
  }

  indexClicked = (key) => {
    this.setState({ ...this.state, selectedKey: key });
  };

  selectedModalContainer = () => {
    const { selectedOption } = this.props;
    switch (selectedOption) {
      case "mindbody":
        return (
          <MindBodyConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      case "glofox":
        return (
          <GlofoxConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      case "facebook":
        return (
          <FacebookConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      case "calendly":
        return (
          <CalendlyConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      case "perfect_gym":
        return (
          <PerfectGymConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      case "gymmaster":
        return (
          <GymmasterConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      case "booker":
        return (
          <BookerConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
      default:
        return (
          <ConfigurationModalContainer
            modalName={MODAL_NAME}
            name={selectedOption}
            direction={this.state.selectedKey}
            selectedIntegration={this.selectedIntegration()}
          />
        );
    }
  };

  selectedOptions = () => {
    const { options, configuredIntegrations } = this.props;
    const selectedOptions = options[this.state.selectedKey];

    return (
      <OptionsView
        options={selectedOptions}
        configuredIntegrations={configuredIntegrations}
        onClick={this.optionClicked}
      />
    );
  };

  selectedIntegration = () => {
    const integerationIndex = this.props.configuredIntegrations.findIndex(
      (integration) => integration.name === this.props.selectedOption
    );
    return this.props.configuredIntegrations[integerationIndex];
  };

  optionClicked = (option) => {
    this.props.setSelectedOption(option);
    this.props.showModal(MODAL_NAME);
  };

  render() {
    const { loading, errors, options } = this.props;
    const blockStyle = loading ? "block-opt-refresh" : "";
    const sections = Object.keys(options);

    const tabs = sections.map((optionName, index) => {
      const activeClassName =
        optionName === this.state.selectedKey
          ? "selectable active"
          : "selectable";
      return (
        <li
          key={index}
          className={activeClassName}
          onClick={() => this.indexClicked(optionName)}
        >
          <a>{optionName.charAt(0).toUpperCase() + optionName.slice(1)}</a>
        </li>
      );
    });

    return (
      <div>
        <div className={"block block-rounded block-bordered " + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Integration Options</h3>
          </div>
          <ul className="nav nav-tabs nav-tabs-alt">{tabs}</ul>
          <div className="block-content tab-content">
            {errorPanelForErrors(errors)}
            {this.selectedOptions()}
          </div>
        </div>
        {this.props.selectedOption && this.selectedModalContainer()}
      </div>
    );
  }
}

ListingView.propTypes = {
  getAllOptions: PropTypes.func.isRequired,
  getIntegrationsForLocation: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  configuredIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  errors: PropTypes.object,
};

export default ListingView;
