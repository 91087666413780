import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ThirdPartyIntegrationSelectView from '../components/ThirdPartyIntegrationSelectView'
import thirdPartyIntegrations from 'modules/Stores/ThirdPartyIntegrations'

function mapStateToProps (state) {
  return {
    thirdPartyIntegrations: thirdPartyIntegrations.selectors.getThirdPartyIntegrations(state),
    loading: thirdPartyIntegrations.selectors.getLoading(state),
    errors: thirdPartyIntegrations.selectors.getErrors(state),
    lastSelectedLocationId: thirdPartyIntegrations.selectors.getLastFetchedLocationId(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getThirdPartyIntegrations: thirdPartyIntegrations.actions.getThirdPartyIntegrations
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyIntegrationSelectView)
