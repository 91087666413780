import { NAME } from './constants'

export const UPDATE_INVITE_DATA = NAME + '/UPDATE_INVITE_DATA'
export const RESET_FORM_DATA = NAME + '/RESET_FORM_DATA'

export const SENDING_INVITE = NAME + '/SENDING_INVITE'
export const SENDING_INVITE_SUCCESS = NAME + '/SENDING_INVITE_SUCCESS'
export const SENDING_INVITE_FAILURE = NAME + '/SENDING_INVITE_FAILURE'

export const GET_PENDING_INVITES = NAME + '/GET_PENDING_INVITES'
export const GET_PENDING_INVITES_SUCCESS = NAME + '/GET_PENDING_INVITES_SUCCESS'
export const GET_PENDING_INVITES_FAILURE = NAME + '/GET_PENDING_INVITES_FAILURE'

export const RESENDING_INVITE = NAME + '/RESEND_INVITE'
export const RESEND_INVITE_SUCCESS = NAME + '/RESEND_INVITE_SUCCESS'
export const RESEND_INVITE_FAILRE = NAME + '/RESEND_INVITE_FAILRE'

export const REVOKING_INVITE = NAME + '/REVOKING_INVITE'
export const REVOKE_INVITE_SUCCESS = NAME + '/REVOKE_INVITE_SUCCESS'
export const REVOKE_INVITE_FAILRE = NAME + '/REVOKE_INVITE_FAILRE'
