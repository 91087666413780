import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getData = state => state[NAME].data;
export const getLoading = state => state[NAME].loading;
export const getUsers = state => state[NAME].users;
export const getExistingLead = state => state[NAME].existingLead;
export const getSelectedUser = state => state[NAME].assignToUser;
export const getTags = state => state[NAME].data.fields.tags;
export const getCurrentView = state => state[NAME].currentView;
export const getPageDetails = state => state[NAME].pageDetails;
export const getSelectedLocation = state => state[NAME].selectedLocation;
export const getSelectedLeadSource = state => state[NAME].selectedLeadSource;
export const getSelectedHeardAboutMethod = state =>
  state[NAME].selectedHeardAboutMethod;
export const getSelectedLeadGoal = state => state[NAME].selectedLeadGoal;
export const getLastUsedLocationId = state => state[NAME].lastUsedLocationId;

export function fetchOnLoadSelector(locationId) {
  return createSelector(
    [getLastUsedLocationId],
    lastUsedLocationId => {
      if (lastUsedLocationId === null) {
        return true;
      }
      return !(lastUsedLocationId === locationId);
    }
  );
}
