import PropTypes from "prop-types";
import React, { Component } from "react";

import LeadTableRow from "./LeadTableRow";

export default class TableView extends Component {
  render() {
    const { leads, timezone } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Gender</th>
              <th>Status</th>
              <th>Location</th>
              <th>Salesperson</th>
              <th>Source</th>
              <th>Heard About Method</th>
              <th>Last Contact</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Date Of Birth</th>
              <th>Address Line One</th>
              <th>Address Line Two</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
              <th>Postcode</th>
              <th>Created On</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, index) => {
              return <LeadTableRow lead={lead} key={index} timezone={timezone}/>;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

TableView.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
};
