import { apiClient } from "utils/apiClient";

export async function updateOpeningHours(locationId, openingHoursId, state) {
  const body = {
    opening_hours: {
      weekday: state.weekday,
      weekend: state.weekend,
    }
  }
  try {
    const response = await apiClient().put(`locations/${locationId}/opening_hours/${openingHoursId}`, body);
    return response.data;
  } catch (e) {
    const response = e.response;
    const error = createOpeningHoursError(response.data.errors)
    throw error
  }
}

function createOpeningHoursError(error) {
  const openingHoursError = new Error('openingHoursError');
  openingHoursError.message = { status: error.status, type: error.type, message: error.message }
  return openingHoursError;
}