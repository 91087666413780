import { request, PUT, POST } from 'utils/apiUtils';
import { isValidFileType, uploadFileToS3 } from 'utils/imageUpload';
import * as actionTypes from './actionTypes';

import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';
import userData from 'modules/Stores/UserData';

export const saveCompanyWithFormData = (company, salesFunnelData) => {
  return (dispatch) => {
    const body = seralizeCompanyFromFormData(salesFunnelData);

    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.company));
      dispatch(userData.actions.getUserCompanySuccess(payload.company));
      notify.success('Company updated');
    }

    return request('companies/' + company.id, PUT, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.UPDATING,
  };
}

function updateSuccessful() {
  return {
    type: actionTypes.UPDATE_SUCCESS,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function prefillForm(company) {
  return {
    type: actionTypes.PREFILL_FORM,
    company,
  };
}

// Company Logo
export const updateCompanyLogo = (file, company) => {
  return (dispatch) => {
    if (!isValidFileType(file)) {
      dispatch(updateFailed('Invalid file uploaded. Please choose either .jpeg or .png file.'));
      notify.error('Invalid file uploaded. Please choose either .jpeg or .png file.');
      return;
    }

    dispatch(startUpdating());

    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(uploadUrl, contentType) {
      dispatch(createCompanyLogo(uploadUrl, contentType, company));
    }

    function onProgress(progress) {}

    return uploadFileToS3(file, onSuccess, onProgress, onFailure, 'image');
  };
};

const createCompanyLogo = (s3URL, contentType, user) => {
  return (dispatch) => {
    const body = {
      image: {
        direct_upload_url: s3URL,
        image_content_type: contentType,
      },
    };
    dispatch(startUpdating());

    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(data) {
      dispatch(updateSuccessful(user));
      notify.success('Company Logo updated');
    }

    return request('companies/' + user.id + '/company_logos', POST, body, onSuccess, onFailure);
  };
};

export function avatarWasSelected(file) {
  return {
    type: actionTypes.LOGO_WAS_SELECTED,
    file,
  };
}

function seralizeCompanyFromFormData(formData) {
  const { title, url, userDisplayName } = formData.fields;

  let body = {};

  body = newPayloadForFormValue(body, title, 'title');
  body = newPayloadForFormValue(body, userDisplayName, 'user_display_name');
  body = newPayloadForFormValue(body, url, 'url');

  return body;
}
