import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getNotes = (state, prefix) => state[prefix]['leadDetail'][NAME].notes
export const getLoading = (state, prefix) => state[prefix]['leadDetail'][NAME].loading

export const filteredNotes = createSelector(
  [ getNotes ],
  (notes) => {
    return notes.filter(note => note)
  }
)
