import * as actionTypes from './actionTypes'

const BILLING_HISTORY_ACTION_HANDLERS = {
  [actionTypes.FORM_UPDATED]: (state, action) => {
    const newData = action.data
    return { ...state, data: newData }
  },
  [actionTypes.RESET_FORM]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    data: {
      fields: {
        number: {
          value: '',
          isValid: false
        },
        name: {
          value: '',
          isValid: false
        },
        expiry: {
          value: '',
          isValid: false
        },
        cvc: {
          value: '',
          isValid: false
        }
      }
    }
  }
}

export default function reducer (state = initialState(), action) {
  const handler = BILLING_HISTORY_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
