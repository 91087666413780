import { request, GET, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';

export const fetchToDosForLead = (lead) => {
  return (dispatch) => {
    dispatch(fetchingTodos());

    function onFailure(errors) {
      dispatch(fetchingTodosFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchingTodosSuccess(payload.lead_actions));
    }

    return request('leads/' + lead.id + '/lead_actions', GET, null, onSuccess, onFailure);
  };
};

function fetchingTodos() {
  return {
    type: actionTypes.FETCHING_TODOS,
  };
}

function fetchingTodosSuccess(todos) {
  return {
    type: actionTypes.FETCHING_TODOS_SUCCESS,
    todos,
  };
}

function fetchingTodosFailed(errors) {
  return {
    type: actionTypes.FETCHING_TODOS_FAILURE,
    errors,
  };
}

export const toggleCompleteTodoForLead = (action, lead, user) => {
  return (dispatch) => {
    const body = {
      lead_action: {
        completing_user_id: user.id,
        completed: !action.completed,
      },
    };
    dispatch(updatingTodo(action.id));

    function onFailure(errors) {
      dispatch(updatingTodoFailed(errors, action.id));
    }

    function onSuccess(payload) {
      dispatch(completingTodoSuccess(payload.lead_action));
    }

    return request('leads/' + lead.id + '/lead_actions/' + action.id, PUT, body, onSuccess, onFailure);
  };
};

function completingTodoSuccess(todo) {
  return {
    type: actionTypes.UPDATING_TODO_SUCCESS,
    todo,
  };
}

export const deleteTodoForLead = (action, lead) => {
  return (dispatch) => {
    dispatch(updatingTodo(action.id));

    function onFailure(errors) {
      dispatch(updatingTodoFailed(errors, action.id));
    }

    function onSuccess(payload) {
      dispatch(todoDeleted(action.id));
    }

    return request('leads/' + lead.id + '/lead_actions/' + action.id, DELETE, null, onSuccess, onFailure);
  };
};

function todoDeleted(todoId) {
  return {
    type: actionTypes.TODO_DELETED,
    todoId,
  };
}

function updatingTodo(todoId) {
  return {
    type: actionTypes.UPDATING_TODO,
    todoId,
  };
}

function updatingTodoFailed(errors, todoId) {
  return {
    type: actionTypes.UPDATING_TODO_FAILURE,
    errors,
    todoId,
  };
}

export const createTodoForLead = (lead, title, detail, type, dueDate, onComplete) => {
  return (dispatch) => {
    const body = {
      lead_action: {
        title: title,
        detail: detail,
        completed: false,
        action_type: 'custom',
        due_date: dueDate,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(addTodo(payload.lead_action));
      if (onComplete) {
        dispatch(onComplete());
      }
    }

    return request('leads/' + lead.id + '/lead_actions', POST, body, onSuccess, onFailure);
  };
};

function addTodo(todo) {
  return {
    type: actionTypes.ADD_TODO,
    todo,
  };
}
