import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProductName } from "modules/Stores/UserData/selectors";

import FormView from "../components/Confirm/FormView";

import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state, props) {
  return {
    integration: selectors.getIntegration(state),
    permissionSet: selectors.getIntegrationPermissionSet(state),
    productName: getProductName(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPermissionForSet: actions.setPermissionForSet,
      updateFacebookPageIntegration: actions.updateFacebookPageIntegration
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormView);
