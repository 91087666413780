import * as actionTypes from './actionTypes'

import { SELECT_USER_VIEW } from './constants'

const ASSIGN_LEAD_ACTION_HANDLERS = {
  [actionTypes.RESET]: (state, action) => {
    return {
      ...state,
      selectedLocation: null,
      selectedUser: null,
      loading: false,
      currentView: SELECT_USER_VIEW,
      pageDetails: {
        currentPage: 1,
        pageCount: 0,
        total: 0
      }
    }
  },
  [actionTypes.SET_CURRENT_VIEW]: (state, action) => {
    return { ...state, currentView: action.currentView }
  },
  [actionTypes.SET_SELECTED_USER]: (state, action) => {
    return { ...state, selectedUser: action.selectedUser }
  },
  [actionTypes.SET_USERS]: (state, action) => {
    return { ...state, users: action.users }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_SELECTED_LOCATION]: (state, action) => {
    return { ...state, selectedLocation: action.selectedLocation }
  },
  [actionTypes.SET_LAST_USED_LOCATION_ID]: (state, action) => {
    return { ...state, lastUsedLocationId: action.lastUsedLocationId }
  },
  [actionTypes.SET_PAGE_DETAILS]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  selectedLocation: null,
  lastUsedLocationId: null,
  selectedUser: null,
  users: [],
  loading: false,
  currentView: SELECT_USER_VIEW,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = ASSIGN_LEAD_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
