import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const RESET = NAME + '/RESET'

export const SET_STEPS = NAME + '/SET_STEPS'
export const ADD_STEP = NAME + '/ADD_STEP'
export const UPDATE_STEP = NAME + '/UPDATE_STEP'

export const SELECT_STEP_ID = NAME + '/SELECT_STEP_ID'
