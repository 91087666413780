import PropTypes from "prop-types";
import React, { Component } from "react";

export class HeardAboutMethodTableRow extends Component {
  render() {
    const { heardAboutMethod, heardAboutMethodSelected, selected } = this.props;
    const backgroundClass = selected ? "bg-primary" : "";
    const textClass = selected ? "text-white" : "";
    return (
      <tr
        className={textClass + " selectable " + backgroundClass}
        onClick={() => heardAboutMethodSelected(heardAboutMethod)}
      >
        <td>{heardAboutMethod.heard_about_title}</td>
      </tr>
    );
  }
}

HeardAboutMethodTableRow.propTypes = {
  heardAboutMethod: PropTypes.object.isRequired,
  heardAboutMethodSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

HeardAboutMethodTableRow.defaultProps = {
  showSaleDetails: true
};
