import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import StepNumberView from 'modules/components/StepNumberView/StepNumberView';
import { toolTipSelector } from '../tooltips';
export class AppointmentsView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, calculatingOption } = this.props;
    fetchWithQueryString(queryString, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString || nextProps.calculatingOption !== this.props.calculatingOption) {
      const { fetchWithQueryString, queryString, calculatingOption } = nextProps;
      fetchWithQueryString(queryString, calculatingOption);
    }
  }

  render() {
    const { data, loading, calculatingOption } = this.props;
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh' : 'block block-rounded block-bordered';
    const tooltips = toolTipSelector(calculatingOption);

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Appointments booked</h3>
        </div>
        <BlockContent className="block-content">
          <div className="row">
            <div className="col-sm-3">
              <StepNumberView
                title="A1 appointments"
                number={data.a1_appointments.current}
                percentage={data.a1_appointments.change}
                tooltipId={'a1-appointments'}
                tooltipText={tooltips.a1Description}
              />
            </div>
            <div className="col-sm-3">
              <StepNumberView
                title="A2 appointments"
                number={data.a2_appointments.current}
                percentage={data.a2_appointments.change}
                tooltipId={'a2-appointments'}
                tooltipText={tooltips.a2Description}
              />
            </div>
            <div className="col-sm-3">
              <StepNumberView
                title="A3 appointments"
                number={data.a3_appointments.current}
                percentage={data.a3_appointments.change}
                tooltipId={'a3-appointments'}
                tooltipText={tooltips.a3Description}
              />
            </div>
            <div className="col-sm-3">
              <StepNumberView
                title="Total appointments booked"
                number={data.total_appointments_booked.current}
                percentage={data.total_appointments_booked.change}
                tooltipId={'total-appointments-booked'}
                tooltipText={tooltips.totalAppointmentsBookedDescription}
              />
            </div>
          </div>
        </BlockContent>
      </div>
    );
  }
}

AppointmentsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string,
};

export default AppointmentsView;

const BlockContent = styled.div`
  padding-bottom: 40px;
`;
