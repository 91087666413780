import PropTypes from "prop-types";
import React, { Component } from "react";
import { LeadSourceTableRow } from "./LeadSourceTableRow";

export class LeadSourceTableView extends Component {
  render() {
    const {
      leadSources,
      leadSourceSelected,
      selectedLeadSourceId,
      tableHead,
      renderRow
    } = this.props;
    const rows = leadSources.map((leadSource, index) => {
      const selected = leadSource.id === selectedLeadSourceId;
      if (renderRow) {
        return renderRow(leadSource, selected);
      }
      return (
        <LeadSourceTableRow
          key={index}
          leadSource={leadSource}
          leadSourceSelected={leadSourceSelected}
          selected={selected}
        />
      );
    });

    return (
      <div style={{ marginTop: "-20px" }}>
        <table className="table table-vcenter table-hover">
          {tableHead && tableHead}
          {!tableHead && (
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
          )}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

LeadSourceTableView.propTypes = {
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func,
  tableHead: PropTypes.element,
  leadSourceSelected: PropTypes.func.isRequired,
  selectedLeadSourceId: PropTypes.number
};

LeadSourceTableRow.defaultProps = {
  showSaleDetails: true
};

export default LeadSourceTableView;
