import { NAME } from './constants'

export const getLoading = (state) => state.emailMapsSettings[NAME].loading
export const getErrors = (state) => state.emailMapsSettings[NAME].errors

export function resultForEmailMap (emailMap) {
  if (emailMap && emailMap.latest_email_lead_submission) {
    return emailMap.latest_email_lead_submission.result
  }
}

export function leadForEmailMap (emailMap) {
  if (emailMap.latest_email_lead_submission) {
    return emailMap.latest_email_lead_submission.lead
  }
}
