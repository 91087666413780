import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const fetchWithQueryString = (queryString, compareOption) => {
  return (dispatch) => {
    dispatch(startFetching());
    function onFailure(errors) {
      dispatch(fetchFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchSuccess(payload.breakdown.data));
    }
    let url = 'lost_leads_reports/breakdown?comparing=' + compareOption;
    if (queryString) {
      url = url + '&' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function startFetching() {
  return {
    type: actionTypes.LOADING,
  };
}

function fetchSuccess(data) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    data,
  };
}

function fetchFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}
