import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PageSelection, FormFields } from 'modules/components';
import { MindbodyStaffTableView } from './MindbodyStaffTableView';
import * as actions from 'modules/Helpers/IntegrationHelpers';

import Modal from 'react-bootstrap-modal';

const { SelectField } = FormFields;

export class AddFromMindbodyModalView extends Component {
  componentDidMount() {
    this.pageClicked(1);
  }

  sendInvites = () => {
    const { inviteMindBodyUsers, selectedUsers, location, selectedUserRoleId } = this.props;
    inviteMindBodyUsers(selectedUsers, location, selectedUserRoleId);
  };

  pageClicked = (pageNumber) => {
    const { getMindbodyUsersForPage, location, staffIntegration } = this.props;
    getMindbodyUsersForPage(location, staffIntegration.id, pageNumber);
  };

  hideAndReset = () => {
    const { hideModal, resetForm } = this.props;
    hideModal();
    resetForm();
  };

  handleUserRoleSelect = (fieldName, value, isValid) => {
    this.props.setUserRoleId(value);
  };

  render() {
    const { currentModal, modalName, loading, selectedUserIds } = this.props;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const logo = actions.logoForOption('mindbody');
    return (
      <Modal show={currentModal === modalName} onHide={this.hideAndReset}>
        <div className={blockStyle}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideAndReset}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Send {selectedUserIds.length} invites
            </h3>
          </div>
          <div className="block-content">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h4 push-10">Invite MINDBODY staff</h2>
                <p className="text-gray-dark">
                  Easily setup your sales team by inviting staff members in MINDBODY. Users must have an email and not already be part of{' '}
                  {this.props.productName}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <SelectField
                  name="role"
                  label="Role"
                  possibleOptions={this.props.userRoles}
                  displayFieldName={'name'}
                  formFieldStyle=""
                  placeHolderText="Select a role"
                  onChange={this.handleUserRoleSelect}
                  value={this.props.selectedUserRoleId}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <MindbodyStaffTableView
                  users={this.props.users}
                  selectedUserIds={this.props.selectedUserIds}
                  selectUserId={this.props.selectUserId}
                  deselectUserId={this.props.deselectUserId}
                />
                {this.props.pageCount > 1 && (
                  <div className="text-center push" style={{ marginBottom: '-20px' }}>
                    <PageSelection pageCount={this.props.pageCount} currentPage={this.props.currentPage} pageClicked={this.pageClicked} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <img alt="" src={logo} className="pull-left" style={{ width: '100px' }} />
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideAndReset}>
            Cancel
          </button>
          <button
            className="btn btn-primary push-5-r push-10"
            disabled={selectedUserIds.length === 0 || !this.props.selectedUserRoleId}
            type="button"
            onClick={this.sendInvites}
          >
            <i className="fa fa-envelope" /> Send Invites
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddFromMindbodyModalView.propTypes = {
  selectedUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  deselectUserId: PropTypes.func.isRequired,
  selectUserId: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  getMindbodyUsersForPage: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  setUserRoleId: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  staffIntegration: PropTypes.object.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  inviteMindBodyUsers: PropTypes.func.isRequired,
  selectedUserRoleId: PropTypes.string,
  productName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default AddFromMindbodyModalView;
