import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MigrateTagModal from '../components/MigrateTagModal';

import tags from 'modules/components/Tags';
import modal from 'modules/Stores/Modal';

function mapStateToProps(state) {
  return {
    loading: tags.selectors.getLoading(state),
    currentModal: state.modal.currentModal,
    tags: tags.selectors.getPopularTags(state),
    errors: tags.selectors.getErrors(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      deleteTag: tags.actions.deleteTag,
      migrateAndDeleteTag: tags.actions.migrateAndDeleteTag,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MigrateTagModal);
