import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import { FormFields } from "modules/components";
import { MainLocationValidator } from "../validators";
import { placeholdersForCountry } from "modules/Helpers/FormatHelpers";

import moment from "moment";
import "moment-timezone";

import {
  cardTypeForNumber,
  lastFourDigitsOfNumber,
} from "modules/Stores/Billing/ccUtils";
import CreditCardFormContainer from "modules/components/CreditCardForm/containers/CreditCardFormContainer";

const { TextField, SelectField } = FormFields;
export class CreateLocationForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onInput(newData);
  };

  phonePlaceholder = () => {
    const { data, countries } = this.props;
    const countryId = data.fields.countryId;
    return placeholdersForCountry(countryId.value, countries).phone;
  };

  render() {
    const {
      name,
      addressLineOne,
      addressLineTwo,
      city,
      timezone,
      state,
      countryId,
      zipPostcode,
      phoneNumber,
      paychoicePaymentMethodId,
    } = this.props.data.fields;
    const { countries } = this.props;
    return (
      <div>
        <TextField
          name="name"
          label="Club name"
          placeholder="Sydney Central"
          formFieldStyle=""
          onChange={this.handleInput}
          initialValue={name.value}
        />
        <TextField
          name="addressLineOne"
          label="Address Line One"
          placeholder="Level 5"
          formFieldStyle=""
          onChange={this.handleInput}
          validator={MainLocationValidator}
          initialValue={addressLineOne.value}
        />
        <TextField
          name="addressLineTwo"
          label="Address Line Two"
          placeholder="12 Fitness Cresent"
          formFieldStyle=""
          onChange={this.handleInput}
          initialValue={addressLineTwo.value}
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          placeholder={this.phonePlaceholder()}
          formFieldStyle=""
          onChange={this.handleInput}
          value={phoneNumber.value}
        />
        <div className="row">
          <div className="col-sm-6">
            <TextField
              name="city"
              label="City"
              placeholder="Glen Iris"
              formFieldStyle=""
              onChange={this.handleInput}
              validator={MainLocationValidator}
              initialValue={city.value}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="state"
              label="State"
              placeholder="VIC"
              formFieldStyle=""
              onChange={this.handleInput}
              validator={MainLocationValidator}
              initialValue={state.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectField
              name="countryId"
              label="Country"
              possibleOptions={countries}
              placeHolderText="Select a country"
              formFieldStyle=""
              onChange={this.handleInput}
              validator={MainLocationValidator}
              displayFieldName={"name"}
              initialValue={countryId.value}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="zipPostcode"
              label="ZIP/Postcode"
              placeholder="3000"
              formFieldStyle=""
              onChange={this.handleInput}
              validator={MainLocationValidator}
              initialValue={zipPostcode.value}
            />
          </div>
          <div className="col-sm-6">
            <SelectField
              name="timezone"
              label="Time Zone"
              possibleOptions={moment.tz.names()}
              placeHolderText="Select the timezone this location operates in"
              formFieldStyle=""
              onChange={this.handleInput}
              validator={MainLocationValidator}
              initialValue={timezone.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectField
              name="paychoicePaymentMethodId"
              label="Select a payment method"
              possibleOptions={this.props.paymentMethods}
              displayFieldFunction={(method) =>
                cardTypeForNumber(method.masked_number) +
                " ending in " +
                lastFourDigitsOfNumber(method.masked_number)
              }
              placeHolderText="Add a new payment method"
              value={paychoicePaymentMethodId.value}
              formFieldStyle=""
              onChange={this.handleInput}
            />
          </div>
        </div>
        {!paychoicePaymentMethodId.value && (
          <div>
            <CreditCardFormContainer
              showInstructions={false}
              onInput={this.props.onPaymentInput}
              data={this.props.paymentData}
            />
          </div>
        )}
      </div>
    );
  }
}

CreateLocationForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  onPaymentInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  paymentData: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CreateLocationForm;
