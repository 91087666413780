import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import sourceCount from '../modules/sourceCount'
import filters from '../modules/filters'
import FilterFormSections from 'modules/components/FilterFormSections'

function mapStateToProps (state) {
  const useStatusDate = filters.selectors.getUseStatusDate(state)
  let queryString = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)(state)
  if (useStatusDate === false) {
    queryString = filters.selectors.convertUpdatedQueryStringToCreated(queryString)
  }
  return {
    chartData: sourceCount.selectors.chartData(state),
    data: sourceCount.selectors.sortedData(state),
    loading: sourceCount.selectors.getLoading(state),
    queryString: queryString,
    reportType: sourceCount.selectors.getReportType(state),
    reportUrl: sourceCount.selectors.reportUrl(state),
    reportTitle: sourceCount.selectors.reportTitle(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: sourceCount.actions.fetchWithQueryString,
    setReportType: sourceCount.actions.setReportType
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(sourceCount.components.SourceCountView)
