import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const getLeadsWithPages = (queryString, selectedCell, pageNumber) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(resetLeads());

    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setLeads(payload.trial_reports));
      dispatch(loadPageDetails(payload.meta));
    }

    let url = `trial_reports/leads?${encodeURI(queryString)}&filter[statuses][]=${selectedCell.status}&page=${pageNumber}`;
    url = selectedCell.trialId ? url + `&filter[trial_id]=${selectedCell.trialId}` : url;

    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setLeads(leads) {
  return {
    type: actionTypes.SET_LEADS,
    leads,
  };
}

function resetLeads() {
  return {
    type: actionTypes.RESET_LEADS,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
