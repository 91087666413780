import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const fetchEventsForEmail = (lead, email, pageNumber = 1) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (errors) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setMailEvents(payload.mail_events))
      dispatch(loadPageDetails(payload.meta))
    }

    let emailParam = 'lead_email'

    switch (email.email_type) {
      case 'Lead Email':
        emailParam = 'lead_emails'
        break
      case 'Mass Email':
        emailParam = 'leads_mass_lead_emails'
        break
      case 'Automatic Funnel Email':
        emailParam = 'automation_emails'
        break
      default:
        break
    }

    const url = 'leads/' + lead.id + '/' +
    emailParam + '/' + email.id +
    '/mail_events?page[number]=' + pageNumber
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setMailEvents (mailEvents) {
  return {
    type: actionTypes.SET_MAIL_EVENTS,
    mailEvents
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.EMAILS_PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
