import { NAME } from './constants'

export const SET_SELECTED_USER_IDS = NAME + '/SET_SELECTED_USER_IDS'

export const SET_NOTIFICATIONS = NAME + '/SET_NOTIFICATIONS'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_LOADING = NAME + '/SET_LOADING'

export const RESET = NAME + '/RESET'
