import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class CsvValueCell extends Component {

  render () {
    const { row, isValid } = this.props

    const className = (isValid) ? 'text-success' : 'text-muted'

    return (
      <div className={className}>{row.value}</div>
    )
  }
}

CsvValueCell.propTypes = {
  row: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired
}

export default CsvValueCell
