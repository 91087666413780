import { humanReadableStatus } from "../trialsTable/helpers";
import { NO_CHANGE } from "../../constants"

export function humanReadableTitleTimeTaken(timeTakenData, selectedCell) {
  if (!selectedCell) { return ''}
  if (selectedCell.status === NO_CHANGE) { return ''}
  if (!timeTakenData) { return `No leads marked as ${humanReadableStatus(selectedCell.status)} during this period`}
  const lastItemInArray = timeTakenData.slice(-1)[0]
  if (!lastItemInArray) { return `No leads marked as ${humanReadableStatus(selectedCell.status)} during this period`}
  return `Timeline of leads marked as ${humanReadableStatus(selectedCell.status)}`;
}
