import * as actionTypes from './actionTypes'

const TRIALS_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_LEADS]: (state, action) => {
    return { ...state, leads: action.leads }
  },
  [actionTypes.RESET_LEADS]: (state, action) => {
    return { ...state,
      leads: [],
      pageCount: 0,
      currentPage: 0,
      totalLeads: 0
    }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalLeads: action.pageDetails.total_count
    }
  }
}

const initialState = {
  errors: null,
  loading: false,
  leads: [],
  pageCount: 0,
  currentPage: 0,
  totalLeads: 0
}

export default function reducer (state = initialState, action) {
  const handler = TRIALS_LEADS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
