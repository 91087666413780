import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import locations from 'modules/Stores/Locations';

import notify from 'modules/Notifications';

export const createSalesTargetForLocation = (location) => {
  return (dispatch) => {
    const body = {
      target_rule_set: defaultSalesTargetValues(),
      location_id: location.id,
    };
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful());
      dispatch(locations.actions.editLocation(mergeLocationAndTarget(location, payload.target_rule_set)));
      notify.success('Sales target for ' + location.admin_display_name + ' created');
    }

    return request('create_for_location', POST, body, onSuccess, onFailure);
  };
};

function mergeLocationAndTarget(location, target) {
  return { ...location, target_rule_set: target };
}

function startUpdating() {
  return {
    type: actionTypes.UPDATING,
  };
}

function updateSuccessful() {
  return {
    type: actionTypes.UPDATE_SUCCESS,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_FAILED,
    errors,
  };
}

function defaultSalesTargetValues() {
  return {
    time_type: 'monthly',
    initial_call_time: 300,
    new_leads_count: 40,
    initial_call_count: 30,
    appointment_count: 20,
    presentation_count: 19,
    total_sales: 12,
    lead_time_out_enabled: false,
    lead_time_out_minutes: 10,
    default: false,
  };
}
