import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClassDayRow from './ClassDayRow';
import DateTimeSelect from './DateTimeSelect';

import ClassesEmpty from 'images/ClassesEmpty.jpg';
import { errorPanelForErrors } from 'utils/formHelpers';

import { ImageEmptyView } from 'modules/components';

export class ClassesView extends Component {
  state = {
    startDateTime: null,
    endDateTime: null,
  };

  componentWillUnmount() {
    this.setState({
      startDateTime: null,
      endDateTime: null,
    });
    this.props.setClasses([]);
  }

  dateChanged = (dateStrings) => {
    const { getClassesForIntegration, classIntegrationId } = this.props;
    this.setState({
      startDateTime: dateStrings.startDateTimeString,
      endDateTime: dateStrings.endDateTimeString,
    });

    getClassesForIntegration(classIntegrationId, dateStrings.startDateTimeString, dateStrings.endDateTimeString);
  };

  ignoreCacheSelected = () => {
    const { getClassesForIntegration, classIntegrationId } = this.props;
    getClassesForIntegration(classIntegrationId, this.state.startDateTime, this.state.endDateTime, true);
  };

  render() {
    const { classes, selectClass, selectedClass, loading, cached, timezone, classIntegrationName, errors } = this.props;

    return (
      <div>
        <DateTimeSelect timezone={timezone} dateChanged={this.dateChanged} classIntegrationName={classIntegrationName} />
        <div style={{ height: '600px' }}>
          <div style={{ overflowY: 'auto', width: 'auto', height: '600px' }}>
            {errorPanelForErrors(errors)}
            {!errors && !loading && classes.length === 0 && (
              <div style={{ marginTop: '180px' }}>
                <ImageEmptyView
                  image={ClassesEmpty}
                  title={"We couldn't load any classes"}
                  subtitle={
                    'Have you finished setting up your integration? ' +
                    "Double check your settings to make sure it's complete. You " +
                    'can always set your own date and time'
                  }
                />
              </div>
            )}
            {cached && (
              <div className="alert alert-warning alert-dismissable selectable" onClick={this.ignoreCacheSelected}>
                <p>
                  <button type="button" className="close" aria-hidden="true">
                    <i className="si si-reload" />
                  </button>
                  These results have been cached to save time. If you need to load the latest data, click here.
                </p>
              </div>
            )}
            {classes.length > 0 && (
              <ClassDayRow
                classes={classes}
                selectClass={selectClass}
                selectedClass={selectedClass}
                timezone={timezone}
                classIntegrationName={classIntegrationName}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ClassesView.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  cached: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selectClass: PropTypes.func.isRequired,
  getClassesForIntegration: PropTypes.func.isRequired,
  setClasses: PropTypes.func.isRequired,
  classIntegrationId: PropTypes.number.isRequired,
  classIntegrationName: PropTypes.string.isRequired,
  selectedClass: PropTypes.object,
};

export default ClassesView;
