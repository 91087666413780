import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ExportButton from './ExportButton'
import modal from "modules/Stores/Modal";

function mapStateToProps (state) {
  return {
    currentModal: modal.selectors.getCurrentModal(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton)
