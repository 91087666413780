import * as actionTypes from "./actionTypes";

import {
  safeRawPropertyForObject,
  safeBooleanPropertyForObject,
} from "utils/formHelpers";

const COMMUNICATION_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM]: (state, action) => {
    return initialStateForPreferences(action.communicationPreferences);
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    const newData = action.data;
    return { ...state, data: newData };
  },
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  },
};

function initialStateForPreferences(communicationPreferences) {
  const dailyOwnerSummaryEmailId = "daily_owner_summary_email";
  const dailyManagerSummaryEmailId = "daily_manager_summary_email";
  const newLeadId = "new_lead";
  const leadAssignedId = "lead_assigned";
  const leadTimedOut = "lead_timed_out";
  const newLeadTextMessage = "lead_new_text_message";
  const paymentNotificationsId = "payment_notifications";

  return {
    errors: null,
    loading: false,
    data: {
      id: safeRawPropertyForObject(communicationPreferences, "id"),
      fields: {
        dailyOwnerSummaryEmail: {
          id: dailyOwnerSummaryEmailId,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            dailyOwnerSummaryEmailId
          ),
          isValid: true,
        },
        dailyManagerSummaryEmail: {
          id: dailyManagerSummaryEmailId,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            dailyManagerSummaryEmailId
          ),
          isValid: true,
        },
        newLead: {
          id: newLeadId,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            newLeadId
          ),
          isValid: true,
        },
        leadAssigned: {
          id: leadAssignedId,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            leadAssignedId
          ),
          isValid: true,
        },
        leadTimedOut: {
          id: leadTimedOut,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            leadTimedOut
          ),
          isValid: true,
        },
        newLeadTextMessage: {
          id: newLeadTextMessage,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            newLeadTextMessage
          ),
          isValid: true,
        },
        paymentNotifications: {
          id: paymentNotificationsId,
          value: safeBooleanPropertyForObject(
            communicationPreferences,
            paymentNotificationsId
          ),
          isValid: true,
        },
      },
    },
  };
}

export default function reducer(
  state = initialStateForPreferences({}),
  action
) {
  const handler = COMMUNICATION_PREFERENCES_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
