import { combineReducers } from 'redux';
import salesPerformanceFunnel from './modules/salesPerformanceFunnel';
import contacts from './modules/contacts';
import filters from './modules/filters';
import sales from './modules/sales';
import appointments from './modules/appointments';
import presentations from './modules/presentations';
import table from './modules/table';
import walkIns from './modules/walkIns';
import userLeaderboard from './modules/userLeaderboard';

export default combineReducers({
  [filters.constants.NAME]: filters.reducer,
  [userLeaderboard.constants.NAME]: userLeaderboard.reducer,
  [salesPerformanceFunnel.constants.NAME]: salesPerformanceFunnel.reducer,
  [contacts.constants.NAME]: contacts.reducer,
  [sales.constants.NAME]: sales.reducer,
  [appointments.constants.NAME]: appointments.reducer,
  [table.constants.NAME]: table.reducer,
  [presentations.constants.NAME]: presentations.reducer,
  [walkIns.constants.NAME]: walkIns.reducer,
});
