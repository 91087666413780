export function iconForNotification (notification) {
  const owner = notification.owner
  if (!owner) { return 'si si-bell bg-info' }

  switch (owner.notification_type) {
    case 'facebook message':
      return 'fa fa-facebook bg-default'
    case 'lead email':
      return 'si si-envelope bg-info'
    case 'lead text message':
      return 'si si-bubbles bg-info'
    default:
      return 'si si-bell bg-info'
  }
}
