import { isPresent } from "utils/validation";

export function validatorForOption(option) {
  switch (option) {
    case "myma":
      return MyMaValidator;
    case "snapp_dd":
      return SnappValidator;
    case "clubmanager":
      return ClubmanagerValidator;
    case "gymvue":
      return GymVueValidator;
    default: {
    }
  }
}

const SnappValidator = {
  customUrl: [
    {
      rule: isPresent,
      errorMessage: "We need to know the URL to pre-populate.",
    },
  ],
};

const MyMaValidator = {
  apiKey: [
    {
      rule: isPresent,
      errorMessage: "We need your API token for your myMa account.",
    },
  ],
  companyName: [
    {
      rule: isPresent,
      errorMessage: "What's this clubs client ID?",
    },
  ],
};

const ClubmanagerValidator = {
  username: [
    {
      rule: isPresent,
      errorMessage: "Please enter your ClubManager username",
    },
  ],
  password: [
    {
      rule: isPresent,
      errorMessage: "Please enter your ClubManager password",
    },
  ],
};

const GymVueValidator = {
  apiKey: [
    {
      rule: isPresent,
      errorMessage: "Please enter your GymVue API key",
    },
  ],
};
