import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export const generateFullReport = (queryString, compareOption) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success("Your report is being generated. We'll email it to you when it's ready.");
    }

    let url = `lost_leads_reports/full_report?comparing=${compareOption}`;
    if (queryString) {
      url = url + '&' + queryString;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}
