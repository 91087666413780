import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// FETCH New Leads
export const fetchUpdates = () => {
  return dispatch => {
    dispatch(fetchingTargets())

    function onFailure (errors) {
      dispatch(fetchTargetsFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(fetchTargetsSuccessful(payload.lead_activity_records))
    }

    return request('all_lead_activity_records', GET, null, onSuccess, onFailure)
  }
}

function fetchingTargets () {
  return {
    type: actionTypes.FETCHING
  }
}

function fetchTargetsSuccessful (historyItems) {
  return {
    type: actionTypes.FETCH_SUCCESS,
    historyItems
  }
}

export function fetchTargetsFailed (errors) {
  return {
    type: actionTypes.FETCH_FAILED,
    errors
  }
}
