import PropTypes from "prop-types";
import React, { Component } from "react";

import CallScheduleSelectContainer from "../containers/CallScheduleSelectContainer";
import EditCallScheduleContainer from "../containers/EditCallScheduleContainer";
import CallScheduleStepsContainer from "../containers/CallScheduleStepsContainer";

import { InfoView } from "modules/components";

export class CallScheduleView extends Component {
  render() {
    const { selectedCallSchedule } = this.props;
    const infoString = (
      <span>
        Call schedules allow you to automate when and how leads should be called{" "}
        and followed up. Leads will automatically be added to the call schedule{" "}
        depending on their lead source and will automatically become inactive if
        they reach the end without being contacted. Visit{" "}
        <strong>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://support.gymleads.net/how-it-works/call-schedules/managing-call-schedules"
          >
            the knowledge base
          </a>
        </strong>{" "}
        For more details.
      </span>
    );
    return (
      <div>
        <InfoView
          colorThemeClass={"info"}
          title={"Call Schedules"}
          detail={infoString}
        />
        <div className="row">
          <div className="col-md-12">
            <CallScheduleSelectContainer />
          </div>
        </div>
        {selectedCallSchedule && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <EditCallScheduleContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <CallScheduleStepsContainer />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CallScheduleView.propTypes = {
  selectedCallSchedule: PropTypes.object,
};

export default CallScheduleView;
