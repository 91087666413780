import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TrialSelectModal from '../components/TrialSelectModal'
import userData from 'modules/Stores/UserData'

import trials from 'modules/Stores/Trials'
import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'

function mapStateToProps (state) {
  return {
    trials: trials.selectors.getTrials(state),
    loading: trials.selectors.getLoading(state),
    errors: trials.selectors.getErrors(state),
    pageDetails: trials.selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state),
    formats: userData.selectors.defaultFormats(state),
    timezone: session.selectors.getUserTimezone(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getTrials: trials.actions.getTrials,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialSelectModal)
