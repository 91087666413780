import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from 'styled-components';
import { HorizontalBar } from "react-chartjs-2";

export default class ChartView extends Component {
  
  generateToolTips = () => {
    const suffix = this.props.yScaleTitle;
    if (suffix) {
      return {
        callbacks: {
          label: function(tooltipItem, data) {
            return Number(tooltipItem.xLabel).toFixed(2) + " " + suffix;
          }
        }
      };
    } else {
      return {};
    }
  };

  render() {
    const { labels, data, dataSetTitle, yScaleTitle } = this.props;

    const chartData = {
      type: "horizontalBar",
      labels: labels,
      datasets: [
        {
          label: dataSetTitle,
          backgroundColor: "#ccdcf1",
          borderColor: "#98b9e3",
          borderWidth: 1,
          hoverBackgroundColor: "#98b9e3",
          hoverBorderColor: "#5c90d2",
          data: data
        }
      ]
    };
    const tooltips = this.generateToolTips()

    const chartHeight = `${(20 * labels.length) + 200}px`

    return (
      <Container chartHeight={chartHeight}>
        <HorizontalBar
          data={chartData}
          redraw={true}
          width={4}
          height={1}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            tooltips: tooltips,
            hover :{
              animationDuration:0
            },
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  fontSize: 12,
                  display: true
                },
                scaleLabel:{
                  display: true,
                  labelString: yScaleTitle
                },
                stacked: true
              }],
              yAxes: [{
                scaleLabel: {
                  display: false,
                },
                barPercentage: 0.2,
                gridLines: {
                  display:false,
                  color: '#fff',
                  zeroLineColor: '#fff',
                  zeroLineWidth: 0
                },
                ticks: {
                  fontSize:11
                },
                stacked: true
              }]
            },
            legend:{
              display: false
            }
          }}
        />
      </Container>
    );
  }
}

ChartView.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataSetTitle: PropTypes.string.isRequired,
  yScaleTitle:PropTypes.string.isRequired,
};

const Container = styled.div`
  height: ${props => props.chartHeight};
`;