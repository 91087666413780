import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { QueuedLeadsView } from '../components/QueuedLeadsView';

import { fetchLeadsWithPageNumber, selectLead, setLocationId, remove } from '../actions';
import locationSelect from '../../LocationSelect';
import * as selectors from '../selectors';
import modal from 'modules/Stores/Modal';
import salesTeam from '../../SalesTeam';

const { showModal } = modal.actions;

const listUpdateProtocol = {
  updateLeadAssignentForLead: remove,
  incrementSalesTeamMemberLeadCount: salesTeam.actions.incrementSalesTeamMemberLeadCount,
};

function mapStateToProps(state) {
  return {
    loading: state.dashboard.QueuedLeads.loading,
    errors: state.dashboard.QueuedLeads.errors,
    leads: state.dashboard.QueuedLeads.leads,
    location: locationSelect.selectors.getSelectedLocation(state),
    pageCount: selectors.getPageCount(state),
    currentPage: selectors.getCurrentPage(state),
    selectedLead: selectors.selectedLead(state),
    listUpdateProtocol: listUpdateProtocol,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLocationId,
      fetchLeadsWithPageNumber,
      selectLead,
      showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(QueuedLeadsView);
