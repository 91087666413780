import { ILead, IMeta, ActivityRecord } from 'types';
import { apiClient } from 'utils/apiClient';
import * as URLs from './URLs';

type getLeadResponse = {
  lead: ILead;
};

type getActivityRecordsResponse = {
  lead_activity_records: ActivityRecord[];
  meta: IMeta;
};

export async function fetchLeadDetails(leadId: number) {
  const response = await apiClient().get(URLs.LEAD(leadId));

  return response.data as Promise<getLeadResponse>;
}

export async function fetchActivityRecords(leadId: number, pageNumber?: number) {
  const response = await apiClient().get(URLs.LEAD_ACTIVITY_RECORDS(leadId, pageNumber));
  return response.data as Promise<getActivityRecordsResponse>;
}
