import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, PageSelection } from 'modules/components'

import { InvoiceRow } from './InvoiceRow'
import InvoiceModalViewContainer from '../containers/InvoiceModalViewContainer'

const INVOICE_MODAL = 'billingHistory/INVOICE_MODAL'

export class BillingHistoryView extends Component {

  UNSAFE_componentWillMount () {
    const { fetch, billingPlan, currentPage } = this.props
    fetch(billingPlan.id, currentPage)
  }

  pageClicked = (pageNumber) => {
    const { billingPlan, fetch } = this.props
    fetch(billingPlan.id, pageNumber)
  }

  invoiceSelected = (invoice) => {
    this.props.selectInvoiceId(invoice.id)
    this.props.showModal(INVOICE_MODAL)
  }

  render () {
    const { invoices, loading, timezone, selectedInvoice } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Billing History</h3>
          </div>
          <div className='block-content'>
            {invoices.length > 0 &&
              <table className='table table-borderless table-striped table-vcenter table-hover'>
                <thead>
                  <tr>
                    <th style={{ width: '100px' }}>INV Num</th>
                    <th>Date</th>
                    <th>Subtotal</th>
                    <th>Tax</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice, index) => {
                    return <InvoiceRow invoice={invoice} key={index}
                      onSelect={this.invoiceSelected} timezone={timezone} />
                  })}
                </tbody>
              </table>
            }
            {invoices.length === 0 &&
              <EmptyView
                colorThemeClass={'primary'}
                iconClass={'fa fa-history'}
                title={'No Billing History'}
                subtitle={'New bills will appear here'} />
            }
            {this.props.pageCount > 1 &&
              <div className='text-center push'>
                <PageSelection
                  pageCount={this.props.pageCount}
                  currentPage={this.props.currentPage}
                  pageClicked={this.pageClicked} />
              </div>
            }
          </div>
        </div>
        {selectedInvoice &&
          <InvoiceModalViewContainer modalName={INVOICE_MODAL} />
        }
      </div>
    )
  }
}

BillingHistoryView.propTypes = {
  billingPlan: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  selectInvoiceId: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  selectedInvoice: PropTypes.object,
  errors: PropTypes.object
}

export default BillingHistoryView
