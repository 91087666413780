/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import FacebookMessagesContainer from "../containers/FacebookMessagesContainer";
import TextMessagesContainer from "../containers/TextMessagesContainer";

export class MessagesView extends Component {
  state = {
    options: ["SMS", "Facebook"],
    selectedIndex: 0,
  };

  indexClicked = (selectedIndex) => {
    this.setState({ ...this.state, selectedIndex: selectedIndex });
  };

  selectedComponent = () => {
    const { lead, facebookMessengerUser } = this.props;
    switch (this.state.selectedIndex) {
      case 0:
        return <TextMessagesContainer lead={lead} />;
      case 1:
        if (facebookMessengerUser) {
          return (
            <FacebookMessagesContainer
              lead={lead}
              facebookMessengerUser={facebookMessengerUser}
              setFacebookMessengerUser={this.props.setFacebookMessengerUser}
            />
          );
        } else {
          return <TextMessagesContainer lead={lead} />;
        }
      default:
        return <div />;
    }
  };

  render() {
    const { options, selectedIndex } = this.state;
    const tabs = options.map((option, index) => {
      const activeClassName =
        index === selectedIndex ? "selectable active" : "selectable";
      return (
        <li
          key={index}
          className={activeClassName}
          onClick={() => this.indexClicked(index)}
        >
          <a id={index}>{option}</a>
        </li>
      );
    });

    return (
      <div
        className="block"
        style={{
          paddingBottom: "0px",
          marginTop: "-20px",
          marginLeft: "-20px",
          marginRight: "-20px",
          marginBottom: "0px",
        }}
      >
        {this.props.facebookMessengerUser && (
          <ul className="nav nav-tabs">{tabs}</ul>
        )}
        <div
          className="block-content tab-content"
          style={{ paddingBottom: "0px" }}
        >
          {this.selectedComponent()}
        </div>
      </div>
    );
  }
}

MessagesView.propTypes = {
  lead: PropTypes.object.isRequired,
  setFacebookMessengerUser: PropTypes.func.isRequired,
  facebookMessengerUser: PropTypes.object,
};
