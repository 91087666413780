/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export class StatisticNumber extends Component {
  render() {
    const { number, title, type } = this.props;
    const displayNumber = number ? (number * 100).toFixed(2) : 0;
    return (
      <a className="block block-bordered block-link-hover3 text-center">
        <div className={"block-content block-content-full " + type}>
          <div className="h1 font-w700 text-white">
            {displayNumber}
            <span className="h2 text-white">%</span>
          </div>
          <div className="h5 text-white text-uppercase push-5-t">{title}</div>
        </div>
      </a>
    );
  }
}

StatisticNumber.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

StatisticNumber.isRequired = {
  type: "bg-gray-lighter border-br",
  number: 0,
};

export default StatisticNumber;
