import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LawfulProcessingBasisView from '../components/LawfulProcessingBasisView'

import editLawfulProcessingBasis from '../modules/editLawfulProcessingBasis'

function mapStateToProps (state) {
  return {
    editLawfulProcessingBasisShowing: editLawfulProcessingBasis.selectors.getShowing(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LawfulProcessingBasisView)
