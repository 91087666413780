import * as actionTypes from './actionTypes'

const SALES_PERFORMANCE_CONTACTS_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, data: action.data }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

const initialState = {
  errors: null,
  loading: false,
  data: {
    scheduled_contact_calls: { current: 0, change: 0 },
    contact_attempts: { current: 0, change: 0 },
    successful_contact_calls: { current: 0, change: 0 },
    success_ratio: { current: 0, change: 0 },
    contact_to_appointment_ratio: { current: 0, change: 0 },
    contact_to_appointment_count: { current: 0, change: 0 },
  }
}

export default function reducer (state = initialState, action) {
  const handler = SALES_PERFORMANCE_CONTACTS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
