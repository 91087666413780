import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import locations from 'modules/Stores/Locations'
import modal from 'modules/Stores/Modal'

import facebook from '../modules/configuration/modules/facebook'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    loading: modal.selectors.getModalLoading(state),
    tabIndex: facebook.selectors.getTabIndex(state),
    location: selectedLocation
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    resetForm: facebook.actions.reset,
    prefillFormForIntegration: facebook.actions.prefillFormForIntegration
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(facebook.components.FacebookConfigurationModalView)
