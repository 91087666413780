import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Bar } from 'react-chartjs-2'

import { ChartBox } from 'modules/components'

export default class BarChartView extends Component {

  generateYAxis = () => {
    const { yScaleTitle } = this.props
    let yAxes = {
      ticks: {
        beginAtZero: true,
        userCallback: function (label, index, labels) {
          if (Math.floor(label) === label) {
            return label
          }
        }
      }
    }

    if (this.props.yScaleTitle) {
      const scaleLabel = {
        display: true,
        labelString: yScaleTitle
      }
      yAxes = { ...yAxes, scaleLabel: scaleLabel }
    }

    return yAxes
  }

  generateToolTips = () => {
    const suffix = this.props.yScaleTitle
    if (suffix) {
      return {
        callbacks: {
          label: function (tooltipItem, data) {
            return Number(tooltipItem.yLabel).toFixed(2) + ' ' + suffix
          }
        }
      }
    } else {
      return {}
    }
  }

  render () {
    const { title, labels, data, loading, isEmpty, dataSetTitle } = this.props

    const shouldReDraw = this.props.redraw || false
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: dataSetTitle,
          backgroundColor: '#ccdcf1',
          borderColor: '#98b9e3',
          borderWidth: 1,
          hoverBackgroundColor: '#98b9e3',
          hoverBorderColor: '#5c90d2',
          data: data
        }
      ]
    }
    
    return (
      <ChartBox
        title={title}
        loading={loading}
        isEmpty={isEmpty}
        chartIcon='fa fa-bar-chart'>
        <Bar
          data={chartData}
          redraw={shouldReDraw}
          options={{
            scales: {
              yAxes: [this.generateYAxis()]
            },
            tooltips: this.generateToolTips()
          }} />
      </ChartBox>
    )
  }
}

BarChartView.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  dataSetTitle: PropTypes.string.isRequired,
  redraw: PropTypes.bool,
  yScaleTitle: PropTypes.string
}
