import PropTypes from "prop-types";
import React, { Component } from "react";

import DealRow from "./DealRow";
import PagesView from "modules/components/PageSelection/PagesView";
import {
  ContentBlockView,
  EmptyView,
  TextSearchView
} from "modules/components";
import NewDealModalContainer from "../containers/NewDealModalContainer";
import DealDetailContainer from "../containers/DealDetailContainer";

import { NEW_DEAL_MODAL } from "../constants";

export class DealsView extends Component {
  UNSAFE_componentWillMount() {
    const { deals } = this.props;
    if (deals.length > 0) {
      return;
    }
    this.getDealsWithPageNumber(1);
  }

  getDealsWithPageNumber = pageNumber => {
    const { getDeals } = this.props;
    getDeals(pageNumber);
  };

  pageClicked = pageNumber => {
    this.props.setSelectedDealId(null);
    this.getDealsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getDeals } = this.props;
    getDeals(1, searchTerm);
  };

  dealSelected = emailDeal => {
    this.props.prefillDataForDeal(emailDeal);
  };

  showCreateModal = () => {
    const { showModal } = this.props;
    showModal(NEW_DEAL_MODAL);
  };

  render() {
    const { deals, loading, selectedDealId, selectedDeal } = this.props;
    const dealRows = deals.map((deal, index) => {
      const selected = deal.id === selectedDealId;
      return (
        <DealRow
          deal={deal}
          key={index}
          selected={selected}
          onSelect={this.dealSelected}
        />
      );
    });

    const addButton = (
      <button
        type="button"
        className="pull-right btn btn-xs btn-success"
        onClick={this.showCreateModal}
      >
        <i className="fa fa-plus" /> Create New Deal
      </button>
    );

    const pageCount = this.props.pageDetails.pageCount;
    return (
      <div>
        <ContentBlockView
          title={"Deals"}
          loading={loading}
          blockOptions={addButton}
          bordered
        >
          <div className="row" style={{ marginTop: "-30px" }}>
            <TextSearchView
              sectionName={"dealSearch"}
              onUpdate={this.searchUpdated}
            />
          </div>
          <div className="list-group selectable push">
            {dealRows}
            {deals.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="fa fa-dollar"
                title="No Deals"
                buttonText="Create first deal"
                buttonSelected={this.showCreateModal}
                subtitle="Add a new deal to start tracking your close types."
              />
            )}
          </div>
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageClicked={this.pageClicked}
                pageDetails={this.props.pageDetails}
              />
            </div>
          )}
        </ContentBlockView>
        {selectedDealId && selectedDeal && <DealDetailContainer />}
        <NewDealModalContainer />
      </div>
    );
  }
}

DealsView.propTypes = {
  prefillDataForDeal: PropTypes.func.isRequired,
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getDeals: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setSelectedDealId: PropTypes.func.isRequired,
  selectedDeal: PropTypes.object,
  selectedDealId: PropTypes.number,
  pageDetails: PropTypes.object
};

export default DealsView;
