import * as actionTypes from './actionTypes'
import { safePropertyForObject, safeBooleanPropertyForObject } from 'utils/formHelpers'

const FACEBOOK_SETUP_ACTION_HANDLERS = {
  [actionTypes.RESET]: (state) => {
    return initialStateForIntegration({})
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    return initialStateForIntegration(action.integration)
  },
  [actionTypes.SET_PERMISSION_SET]: (state, action) => {
    return { ...state, permissionSet: action.permissionSet }
  },
  [actionTypes.SET_TAB_INDEX]: (state, action) => {
    return { ...state, tabIndex: action.tabIndex }
  },
  [actionTypes.SET_FACEBOOK_USER]: (state, action) => {
    return { ...state, facebookUser: action.facebookUser }
  },
  [actionTypes.SET_FACEBOOK_PAGES]: (state, action) => {
    return { ...state, facebookPages: action.facebookPages }
  },
  [actionTypes.SET_SELECTED_PAGE_ID]: (state, action) => {
    return { ...state, selectedPageId: action.selectedPageId }
  },
  [actionTypes.SET_VERIFIED]: (state, action) => {
    return { ...state, verified: action.verified }
  },
  [actionTypes.SET_INTEGRATION]: (state, action) => {
    return { ...state, integration: action.integration }
  },
  [actionTypes.ADD_LINKED_FACEBOOK_PAGE]: (state, action) => {
    const newLinkedPages = [
      ...state.integration.third_party_integrations_facebook_pages,
      action.linkedPage
    ]
    const updatedIntegration = { ...state.integration, third_party_integrations_facebook_pages: newLinkedPages }
    return { ...state, integration: updatedIntegration }
  },
  [actionTypes.DELETE_LINKED_FACEBOOK_PAGE]: (state, action) => {
    const index = state.integration.third_party_integrations_facebook_pages
    .findIndex(linkedPage => parseInt(linkedPage.id) === parseInt(action.linkedPageId))
    const newLinkedPages = [
      ...state.integration.third_party_integrations_facebook_pages.slice(0, index),
      ...state.integration.third_party_integrations_facebook_pages.slice(index + 1)
    ]
    const updatedIntegration = { ...state.integration, third_party_integrations_facebook_pages: newLinkedPages }
    return { ...state, integration: updatedIntegration }
  },
  [actionTypes.UPDATE_LINKED_FACEBOOK_PAGE]: (state, action) => {
    const index = state.integration.third_party_integrations_facebook_pages
    .findIndex(linkedPage => parseInt(linkedPage.id) === parseInt(action.linkedPage.id))
    const newLinkedPages = [
      ...state.integration.third_party_integrations_facebook_pages.slice(0, index),
      action.linkedPage,
      ...state.integration.third_party_integrations_facebook_pages.slice(index + 1)
    ]
    const updatedIntegration = { ...state.integration, third_party_integrations_facebook_pages: newLinkedPages }
    return { ...state, integration: updatedIntegration }
  }
}

function initialStateForIntegration (option) {
  const id = safePropertyForObject(option, 'id')
  const pageId = safePropertyForObject(option, 'company_name')
  const verified = safeBooleanPropertyForObject(option, 'verified')
  let tabIndex = (id && !pageId) ? 1 : 0
  tabIndex = (id && verified === true) ? 2 : tabIndex
  return {
    facebookUser: null,
    permissionSet: option.integration_permission_set,
    facebookPages: [],
    verified: false,
    tabIndex: tabIndex,
    selectedPageId: null,
    integration: option
  }
}

export default function reducer (state = initialStateForIntegration({}), action) {
  const handler = FACEBOOK_SETUP_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
