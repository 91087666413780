import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TableRow from "./TableRow";

const TableView = ({ automaticReports, selectAutomaticReport, selectedId }) => {
  return (
    <Container>
      <table className="table table-vcenter table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-center">Type</th>
          </tr>
        </thead>
        <tbody>
          {automaticReports.map((automaticReport, index) => (
            <TableRow
              key={`report-row-${index}`}
              automaticReport={automaticReport}
              selected={selectedId === automaticReport.id}
              selectAutomaticReport={selectAutomaticReport}
            />
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default TableView;

TableRow.propTypes = {
  automaticReports: PropTypes.arrayOf(PropTypes.object),
  selectAutomaticReport: PropTypes.func.isRequired,
  selectedId: PropTypes.number
};

const Container = styled.div`
  margin: -20px 0px 0px 0px;
`;
