import * as actionTypes from './actionTypes'

const CONFIRM_EMAIL_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return ({ ...state, loading: true, errors: null })
  },
  [actionTypes.LOADING_SUCCESS]: (state, action) => {
    return ({ ...state, loading: false, user: action.user })
  },
  [actionTypes.LOADING_FAILURE]: (state, action) => {
    return ({ ...state, loading: false, errors: action.errors })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function confirmEmailReducer (state = initialState, action) {
  const handler = CONFIRM_EMAIL_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

const initialState = {
  loading: false,
  errors: null,
  user: null
}
