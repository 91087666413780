import React from "react";
import MultiUserSelect from "modules/components/MultiUserSelect";

const SETTINGS_USER_TO_EMAIL_MODAL = "SETTINGS_USER_TO_EMAIL_MODAL";

const UsersToEmail = ({
  userIds,
  handleUserSelect,
  addUserId,
  removeUserId,
  showModal,
  buttonTitle,
  usersFiltersClass
}) => {
  function showSelectUsersModal() {
    showModal(SETTINGS_USER_TO_EMAIL_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${usersFiltersClass}`}
        type="button"
        onClick={showSelectUsersModal}
      >
        {buttonTitle}
      </div>
      <MultiUserSelect.MultiUserSelectModalContainer
        selectedUserIds={userIds}
        buttonTitle="Add team members"
        modalName={SETTINGS_USER_TO_EMAIL_MODAL}
        onUpdate={handleUserSelect}
        addUserId={addUserId}
        removeUserId={removeUserId}
        allUsersButton={false}
      />
    </div>
  );
};

export default UsersToEmail;
