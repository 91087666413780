import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import NewDealModal from '../components/NewDealModal'
import modal from 'modules/Stores/Modal'
import { NEW_DEAL_MODAL } from '../constants'

import deals from 'modules/Stores/Deals'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: NEW_DEAL_MODAL
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    createDeal: deals.actions.createDeal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDealModal)
