import { connect } from 'react-redux';

import { CalendarLayoutView } from '../components/CalendarLayoutView';
import session from 'modules/Stores/Session';
import calendar from 'modules/components/Calendar';
import locationSelect from '../modules/locationSelect';

function mapStateToProps(state) {
  return {
    user: session.selectors.getUser(state),
    loading: calendar.selectors.getLoading(state),
    selectedLocationId: locationSelect.selectors.getSelectedLocationId(state),
  };
}

export default connect(mapStateToProps)(CalendarLayoutView);
