import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ReportsView } from '../components/ReportsView'

const mapStateToProps = state => ({
})

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsView)
