export const NAME = "createLead";

export const CREATE_LEAD_MODAL_NAME = "CREATE_LEAD_MODAL_NAME";

export const SELECT_LOCATION_VIEW = NAME + "/SELECT_LOCATION_VIEW";
export const SELECT_USER_VIEW = NAME + "/SELECT_USER_VIEW";
export const SELECT_LEAD_SOURCE_VIEW = NAME + "/SELECT_LEAD_SOURCE_VIEW";
export const SELECT_HEARD_ABOUT_METHOD_VIEW =
  NAME + "/SELECT_HEARD_ABOUT_METHOD_VIEW";
export const SELECT_LEAD_GOAL_VIEW = NAME + "/SELECT_LEAD_GOAL_VIEW";
export const CREATE_LEAD_VIEW = NAME + "/CREATE_LEAD_VIEW";
