import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FiltersForm from '../components/FiltersForm';
import { ACTIVE, CLOSED, NOT_ASSIGNED, INACTIVE, LOST } from 'containers/Reports/Reports/SalesPerformance/modules/filters/constants';
import { leadsReportFilters } from '../constants';

function mapStateToProps(state) {
  return {
    statuses: [ACTIVE, CLOSED, NOT_ASSIGNED, INACTIVE, LOST],
    availableFilters: leadsReportFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersForm);
