import * as actionTypes from './actionTypes'

const BILLING_HISTORY_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, invoices: action.invoices }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state, pageCount: action.pageDetails.total_pages, currentPage: action.pageDetails.current_page }
  },
  [actionTypes.INVOICE_SELECTED]: (state, action) => {
    return { ...state, loading: false, selectedInvoiceId: action.invoiceId }
  }
}

const initialState = {
  loading: false,
  errors: null,
  invoices: [],
  selectedInvoiceId: null,
  pageCount: 1,
  currentPage: 1
}

export default function reducer (state = initialState, action) {
  const handler = BILLING_HISTORY_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
