import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ExportButton from './ExportButton'

export class ReportHeaderView extends Component {

  render () {
    return (
      <div className='row items-push'>
        <div className='col-md-12'>
          {this.props.getExportUrlWithString &&
            <ExportButton
              getExportUrlWithString={this.props.getExportUrlWithString}
              csvDownloadLink={this.props.csvDownloadLink} />
          }
          <h1 className='page-heading push-10'>{this.props.title}</h1>
          <div style={{ borderBottomStyle: 'solid', borderBottomWidth: '2px' }} />
        </div>
      </div>
    )
  }
}

ReportHeaderView.propTypes = {
  title: PropTypes.string.isRequired,
  getExportUrlWithString: PropTypes.func,
  csvDownloadLink: PropTypes.string
}

export default ReportHeaderView
