import * as actionTypes from './actionTypes'

export function selectLawfulProcessingBasisId (selectedLawfulProcessingBasisId) {
  return {
    type: actionTypes.SET_SELECTED_LAWFUL_PROCESSING_BASIS_ID,
    selectedLawfulProcessingBasisId
  }
}

export function setSearchTerm (searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  }
}
