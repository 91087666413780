import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class MindbodyLocationRow extends Component {

  render () {
    const { mindbodyLocation, locationSelected, selected } = this.props

    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''
    return (
      <tr className={textClass + ' selectable ' + backgroundClass} onClick={() => locationSelected(mindbodyLocation)}>
        <td>{mindbodyLocation.name}</td>
        <td>{mindbodyLocation.city}</td>
        <td>{mindbodyLocation.postal_code}</td>
      </tr>
    )
  }
}

MindbodyLocationRow.propTypes = {
  mindbodyLocation: PropTypes.object.isRequired,
  locationSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
}
