import * as actionTypes from './actionTypes'
import moment from 'moment'
import 'moment-timezone'

const LEADS_REPORT_FILTER_ACTION_HANDLERS = {
  [actionTypes.CLEAR_ALL_FILTERS]: (state) => {
    return { ...state, data: defaultLeadFormState() }
  },
  [actionTypes.UPDATE_FILTER_DATA]: (state, action) => {
    return { ...state, data: action.filterData }
  },
  [actionTypes.HIDE_FILTERS]: (state, action) => {
    return { ...state, showingMore: false }
  },
  [actionTypes.SHOW_FILTERS]: (state, action) => {
    return { ...state, showingMore: true }
  },
  [actionTypes.SET_COMPARE_VALUE]: (state, action) => {
    return { ...state, compareOption: action.compareOption }
  },
  [actionTypes.SET_USE_STATUS_DATE]: (state, action) => {
    return { ...state, useStatusDate: action.useStatusDate }
  }
}

const initialState = {
  showingMore: false,
  useStatusDate: false,
  data: defaultLeadFormState()
}

function defaultLeadFormState () {
  return {
    statusUpdatedBetween: {
      fields: {
        status_updated_after: { value: moment().startOf('day').utc().format(), isValid: true },
        status_updated_before: { value: moment().endOf('day').utc().format(), isValid: true }
      }
    }
  }
}

export default function reducer (state = initialState, action) {
  const handler = LEADS_REPORT_FILTER_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
