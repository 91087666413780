import PropTypes from "prop-types";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { errorPanelForErrors } from "utils/formHelpers";

import TopUpForm from "./TopUpForm";

export class TopUpView extends Component {
  state = {
    showWarning: false
  };

  showWarning = () => {
    this.setState({ ...this.state, showWarning: true });
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showWarning: false });
  };

  toggleAutoRecharge = () => {
    const { updateSMSAccount, smsAccount } = this.props;
    const newValue = !smsAccount.auto_recharge;
    updateSMSAccount(smsAccount, newValue);
    this.dismissPopups();
  };

  render() {
    const { smsAccount, loading } = this.props;

    const blockStyle = loading ? "block-opt-refresh" : "";

    const rechargeIconClass = smsAccount.auto_recharge
      ? "si si-check text-success"
      : "si si-close text-danger";
    const rechargeIconWord = smsAccount.auto_recharge ? "on" : "off";
    const warningMessage = smsAccount.auto_recharge
      ? "Turning off auto recharge means you will need to top-up your account manually. Your sms's " +
        "will not send if your balance reaches 0."
      : "By turning on auto recharge you are allowing us to automatically charge your card for the amount " +
        "specified when your balance drops below $5.00. Are you sure you want to enable Auto Recharge?";

    const warningButtonTitle = smsAccount.auto_recharge
      ? "Turn off Auto Recharge"
      : "Turn on Auto Recharge";

    const rechargeButtonTitle = smsAccount.auto_recharge
      ? "Turn auto recharge off"
      : "Turn auto recharge on";
    const rechargeButtonClass = smsAccount.auto_recharge
      ? "btn btn-block btn-danger push-10"
      : "btn btn-block btn-success push-10";

    return (
      <div>
        <div className={"block block-rounded block-bordered " + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Balance & top up</h3>
          </div>
          <div className="block-content">
            {errorPanelForErrors(this.props.errors)}
            {smsAccount && (
              <div className="row">
                <div className="col-md-4">
                  <div className="block block-bordered text-center">
                    <div className="block-content block-content-full bg-gray-lighter border-b">
                      <div className="h1 font-w700">
                        <span className="h2 text-muted">$ </span>
                        {parseFloat(
                          Math.round(smsAccount.balance * 100) / 100
                        ).toFixed(2)}
                      </div>
                      <div className="h5 text-muted text-uppercase push-5-t">
                        Credit Remaining
                      </div>
                    </div>
                    <div className="block-content block-content-full block-content-mini">
                      <i className={rechargeIconClass} /> Auto Recharge is{" "}
                      {rechargeIconWord}
                    </div>
                  </div>
                  <button
                    className={rechargeButtonClass}
                    type="button"
                    onClick={this.showWarning}
                  >
                    {rechargeButtonTitle}
                  </button>
                  <p
                    className="text-muted text-center"
                    style={{ fontSize: "11px" }}
                  >
                    Auto recharge will automatically top-up your account with
                    $20.00 if it gets below $5.00
                  </p>
                </div>
                <div className="col-md-8">
                  <TopUpForm
                    smsAccount={smsAccount}
                    topUpAccount={this.props.topUpAccount}
                    updateSMSAccount={this.props.updateSMSAccount}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.showWarning && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="warning"
            title="Are you Sure?"
            confirmBtnText={warningButtonTitle}
            onConfirm={this.toggleAutoRecharge}
            onCancel={this.dismissPopups}
          >
            {warningMessage}
          </SweetAlert>
        )}
      </div>
    );
  }
}

TopUpView.propTypes = {
  updateSMSAccount: PropTypes.func.isRequired,
  topUpAccount: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  smsAccount: PropTypes.object,
  errors: PropTypes.object
};

export default TopUpView;
