import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView } from 'modules/components'
import SweetAlert from 'react-bootstrap-sweetalert'

export class TurnOffOptInView extends Component {

  state = {
    showDisableWarning: false
  }

  turnOffOptIn = () => {
    const { turnOffOptInForLocation, location } = this.props
    turnOffOptInForLocation(location)
  }

  showPopups = () => {
    this.setState({ ...this.state, showDisableWarning: true })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showDisableWarning: false })
  }

  render () {
    const { loading, location } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const infoString = 'You\'re using enhanced privacy for this location. Leads must opt in before they ' +
    'can recieve any marketing materials from this club and you must keep track of a lawful basis to keep ' +
     'and work with your leads.'
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Enhanced privacy for { location.admin_display_name }</h3>
          </div>
          <div className='block-content push'>
            <EmptyView
              colorThemeClass='danger'
              iconClass='fa fa-check-circle text-success'
              title='Enhanced privacy is turned on'
              subtitle={infoString}
              buttonText='Disable Enhanced Privacy'
              buttonSelected={this.showPopups} />
          </div>
        </div>
        {this.state.showDisableWarning &&
          <div>
            <SweetAlert
              danger
              showCancel
              cancelBtnBsStyle='default'
              confirmBtnBsStyle='danger'
              confirmBtnText='Disable Enhanced Privacy'
              title={'Are you sure?'}
              onConfirm={this.turnOffOptIn}
              onCancel={this.dismissPopups}>
              Disabling enhanced privacy may put you in danger of violating the privacy{' '}
              laws in your country. Are you{' '}
              sure you want to disable enhanced privacy?
            </SweetAlert>
          </div>
        }
      </div>
    )
  }
}

TurnOffOptInView.propTypes = {
  location: PropTypes.object.isRequired,
  turnOffOptInForLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default TurnOffOptInView
