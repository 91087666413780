/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export class EmptyView extends Component {
  onClick = (event) => {
    if (this.props.buttonSelected) {
      this.props.buttonSelected();
    }
  };

  render() {
    const {
      colorThemeClass,
      iconClass,
      title,
      subtitle,
      buttonText,
    } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3 text-center">
            <i className={iconClass + " fa-3x"} />
            <p className="lead" style={{ marginBottom: "0px" }}>
              {title}
            </p>
            <p style={{ marginBottom: "15px" }}>{subtitle}</p>
          </div>
        </div>
        <div className="row">
          {buttonText && (
            <div
              className={
                "col-sm-6 col-sm-offset-3 text-center text-" + colorThemeClass
              }
            >
              <a
                className={
                  "btn btn-minw btn-rounded btn-noborder btn-" +
                  colorThemeClass +
                  " push-5"
                }
                onClick={this.onClick}
              >
                {buttonText}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

EmptyView.propTypes = {
  colorThemeClass: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonSelected: PropTypes.func,
};
