import { connect } from 'react-redux'
import TextDetailsView from './TextDetailsView'
import { getProductName } from 'modules/Stores/UserData/selectors'

function mapStateToProps (state) {
  return {
    productName: getProductName(state)
  }
}

export default connect(mapStateToProps)(TextDetailsView)
