import React, { Component } from "react";
import { errorPanelForErrors } from "utils/formHelpers";

import { FormFields } from "modules/components";

import { calculatedTimeTypeSelection } from "./CallScheduleStepHelpers";

import {
  SELECT_EMAIL_TEMPLATE_VIEW,
  SELECT_SMS_TEMPLATE_VIEW
} from "./NewScheduleStepModal";

const { TextField, SelectField } = FormFields;

export class NewScheduleEditStepView extends Component {
  handleChange = (fieldName, value) => {
    this.props.updateValue(fieldName, value);
  };

  render() {
    const {
      errors,
      numberOfSteps,
      timeToWait,
      selectedTimeType,
      setSelectedView,
      selectedEmailTemplate,
      selectedTextMessageTemplate,
      footerComponent,
      updateValue
    } = this.props;
    return (
      <div>
        <div className="block-content">
          <div className="push-30">
            <div
              className="row"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <div className="col-sm-12">
                <p>
                  Set a step in your call schedule. Set how long after the last
                  contact it should take, and which follow up templates to use.
                </p>
                {errorPanelForErrors(errors, false)}
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-3"
                style={{ paddingRight: "0px", marginRight: "-10px" }}
              >
                <TextField
                  name="timeToWait"
                  label="Time to wait"
                  placeholder="6"
                  formFieldStyle=""
                  onChange={this.handleChange}
                  value={timeToWait}
                />
              </div>
              <div
                className="col-sm-3"
                style={{
                  marginTop: "43px",
                  paddingLeft: "0px",
                  marginLeft: "-15px"
                }}
              >
                <SelectField
                  name="selectedTimeType"
                  formFieldStyle=""
                  possibleOptions={calculatedTimeTypeSelection(timeToWait)}
                  displayFieldName={"display"}
                  onChange={this.handleChange}
                  initialValue={selectedTimeType}
                />
              </div>
              <div
                className="col-sm-6"
                style={{
                  marginTop: "50px",
                  paddingLeft: "0px",
                  marginLeft: "-15px"
                }}
              >
                <p>
                  {numberOfSteps === 0 && "after initial enquiry"}
                  {numberOfSteps > 0 && "after last call"}
                </p>
              </div>
              <div className="row push">
                <div
                  className="col-xs-12"
                  style={{
                    marginLeft: "30px"
                  }}
                >
                  Email:{" "}
                  <button
                    className="btn btn-default"
                    onClick={() => setSelectedView(SELECT_EMAIL_TEMPLATE_VIEW)}
                  >
                    {selectedEmailTemplate
                      ? selectedEmailTemplate.title
                      : "Select template"}
                  </button>
                  {selectedEmailTemplate && (
                    <button
                      className="btn btn-default"
                      onClick={() => updateValue("selectedEmailTemplate", null)}
                      style={{ marginLeft: "4px" }}
                    >
                      Clear
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xs-12"
                  style={{
                    marginLeft: "30px"
                  }}
                >
                  SMS:{" "}
                  <button
                    className="btn btn-default"
                    onClick={() => setSelectedView(SELECT_SMS_TEMPLATE_VIEW)}
                  >
                    {selectedTextMessageTemplate
                      ? selectedTextMessageTemplate.title
                      : "Select template"}
                  </button>
                  {selectedTextMessageTemplate && (
                    <button
                      className="btn btn-default"
                      onClick={() =>
                        updateValue("selectedTextMessageTemplate", null)
                      }
                      style={{ marginLeft: "4px" }}
                    >
                      Clear
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {footerComponent}
      </div>
    );
  }
}

export default NewScheduleEditStepView;
