import PropTypes from 'prop-types'
import React, { Component } from 'react'

import UpdatePasswordForm from './UpdatePasswordForm'
import { errorPanelForErrors } from 'utils/formHelpers'

export class UpdatePasswordView extends Component {

  render () {
    const { sendData, loading, data, errors, updateData } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Update Password</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors, false)}
              <UpdatePasswordForm
                onUpdate={updateData}
                sendData={sendData}
                data={data} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

UpdatePasswordView.propTypes = {
  sendData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

export default UpdatePasswordView
