import PropTypes from "prop-types";
import React, { Component } from "react";
import "./UserRoleTag.css";

export class UserRoleTag extends Component {
  render() {
    const { userRole } = this.props;
    let style = "danger";
    switch (userRole.name) {
      case "Owner":
      case "Admin":
        style = "info";
        break;
      case "Salesmanager":
        style = "warning";
        break;
      case "Salesperson":
        style = "success";
        break;
      case "Reception":
        style = "default";
        break;
      default:
        break;
    }
    return (
      <div className="user-role-tag">
        <span className={"label label-" + style}>{userRole.name}</span>
      </div>
    );
  }
}

UserRoleTag.propTypes = {
  userRole: PropTypes.object.isRequired,
};
