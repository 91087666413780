import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Avatar from 'react-avatar'

export class StaffTableRow extends Component {

  rowSelected = (event) => {
    if (event.type !== 'click') { return }
    const { user, selected, selectUserId, deselectUserId } = this.props
    if (!user.email) { return }
    if (selected) {
      deselectUserId(user.id)
    } else {
      selectUserId(user.id)
    }
  }

  render () {
    const { user, selected } = this.props
    let className = (selected) ? 'selectable active' : 'selectable'
    className = (user.email) ? className : className + ' text-muted'
    return (
      <tr className={className} onClick={this.rowSelected}>
        <td className='text-center'>
          {user.email &&
            <label className='css-input css-checkbox css-checkbox-primary'>
              <input type='checkbox' disabled checked={selected} readOnly /><span />
            </label>
          }
        </td>
        <td>
          <Avatar name={user.name} email={user.email} size={32} src={user.image_url} round={true} />
        </td>
        <td>{user.name}</td>
        <td>{user.email}</td>
      </tr>
    )
  }
}

StaffTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectUserId: PropTypes.func.isRequired,
  deselectUserId: PropTypes.func.isRequired
}
