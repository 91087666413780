import { combineReducers } from 'redux';
import reportsTable from './modules/reportsTable';
import filters from './modules/filters';
import schedule from './modules/schedule';
import editReport from './modules/editReport';

export default combineReducers({
  [reportsTable.constants.NAME]: reportsTable.reducer,
  [filters.constants.NAME]: filters.reducer,
  [schedule.constants.NAME]: schedule.reducer,
  [editReport.constants.NAME]: editReport.reducer,
});
