import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, PageSelection } from 'modules/components'
import TextSearchView from './TextSearchView'

import EmailMapRow from './EmailMapRow'

export class ListEmailMapsView extends Component {

  state = {
    showingSearch: false
  }

  componentDidMount () {
    this.pageClicked(1)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.searchTerm !== this.props.searchTerm)) {
      const { fetchEmailMapsForPageNumber, selectEmailMapId, changeShowing, searchTerm } = nextProps
      fetchEmailMapsForPageNumber(1, searchTerm)
      selectEmailMapId(null)
      changeShowing(false)
    }
  }

  pageClicked = (pageNumber) => {
    const { fetchEmailMapsForPageNumber, searchTerm } = this.props
    fetchEmailMapsForPageNumber(pageNumber, searchTerm)
  }

  newEmailMapClicked = () => {
    this.props.selectEmailMapId(null)
    this.props.changeShowing(true)
  }

  emailMapClicked = (emailMapId) => {
    this.props.selectEmailMapId(emailMapId)
    this.props.changeShowing(true)
  }

  toggleSearch = () => {
    if (this.state.showingSearch === true) {
      this.props.setSearchTerm('')
    }
    this.setState({ ...this.state, showingSearch: !this.state.showingSearch })
  }

  render () {
    const { emailMaps, selectedEmailMapId, loading, setSearchTerm, searchTerm } = this.props
    const rows = emailMaps.map((emailMap, index) => {
      const selected = (parseInt(emailMap.id) === parseInt(selectedEmailMapId))
      return <EmailMapRow
        key={index}
        emailMap={emailMap}
        selected={selected}
        onSelect={this.emailMapClicked} />
    })
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <div className='block-options-simple'>
              <button type='button' className='btn btn-xs btn-success push-10-r' onClick={this.newEmailMapClicked}>
                <i className='fa fa-plus' />{' '}Create new email map
              </button>
              <button type='button' className='pull-right btn btn-xs btn-primary'
                onClick={this.toggleSearch}>
                <i className='fa fa-search' />
              </button>
            </div>
            <h3 className='block-title'>Your Email Maps</h3>
          </div>
          {this.state.showingSearch &&
            <div>
              <TextSearchView onUpdate={setSearchTerm} searchText={searchTerm} sectionName={'search'} />
            </div>
          }
          <div className='block-content'>
            {emailMaps.length === 0 &&
              <div style={{ paddingBottom: '30px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='fa fa-wpforms'
                  title='No email maps'
                  subtitle='Email maps will show here'
                  buttonText='Create a new email map'
                  buttonSelected={this.newEmailMapClicked} />
              </div>
            }
            {emailMaps.length > 0 &&
              <table className='table table-vcenter table-hover'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Default club</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            }
            {this.props.pageCount > 1 &&
              <div className='text-center push'>
                <PageSelection
                  pageCount={this.props.pageCount}
                  currentPage={this.props.currentPage}
                  pageClicked={this.pageClicked} />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

ListEmailMapsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchEmailMapsForPageNumber: PropTypes.func.isRequired,
  changeShowing: PropTypes.func.isRequired,
  emailMaps: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectEmailMapId: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  selectedEmailMapId: PropTypes.number,
  errors: PropTypes.object
}

export default ListEmailMapsView
