import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PagesView from 'modules/components/PageSelection/PagesView';
import { FormFields, TextSearchView } from 'modules/components';

import { EDIT_LEAD_SOURCE_MODAL, MIGRATE_LEAD_SOURCE_MODAL } from '../constants';
import EditLeadSourceModalContainer from '../containers/EditLeadSourceModalContainer';
import MigrateLeadSourceModalContainer from '../containers/MigrateLeadSourceModalContainer';
import SourceItem from './SourceItem';
const { InlineTextField } = FormFields;

export class LeadSourcesView extends Component {
  state = {
    leadSourceToDelete: null,
    newLeadSourceText: null,
    selectedLeadSource: null,
  };

  UNSAFE_componentWillMount() {
    const { leadSources } = this.props;
    if (leadSources.length > 0) {
      return;
    }
    this.getLeadSourcesWithPageNumber(1);
  }

  getLeadSourcesWithPageNumber = (pageNumber) => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  handleInput = (fieldName, textValue) => {
    this.setState({ ...this.state, newLeadSourceText: textValue });
  };

  addLeadSourceSelected = () => {
    const newLeadSourceText = this.state.newLeadSourceText;
    this.props.createLeadSource(newLeadSourceText);
    this.setState({ ...this.state, newLeadSourceText: null });
  };

  sourceClicked = (source) => {
    this.setState({ ...this.state, selectedLeadSource: source });
    this.props.showModal(EDIT_LEAD_SOURCE_MODAL);
  };

  showMigrateModal = (source) => {
    this.setState({ ...this.state, leadSourceToDelete: source });
    this.props.showModal(MIGRATE_LEAD_SOURCE_MODAL);
  };

  render() {
    const { canEdit } = this.props;
    const sources = this.props.leadSources.map((leadSource, index) => {
      return (
        <SourceItem key={index} leadSource={leadSource} onDelete={this.showMigrateModal} onSelect={this.sourceClicked} canEdit={canEdit} />
      );
    });

    const pageCount = this.props.pageDetails.pageCount;

    const blockStyle = this.props.loading ? 'block-opt-refresh' : '';

    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Lead Sources</h3>
          </div>
          <div className="block-content bg-gray-lighter">
            <div className="row" style={{ marginTop: '-30px' }}>
              <TextSearchView sectionName={'leadSourceSearch'} onUpdate={this.searchUpdated} />
            </div>
          </div>
          <div className="block-content">
            <div className="row">{sources}</div>
            <div className="row">
              {canEdit && (
                <>
                  <hr />
                  <div className="col-md-8">
                    <InlineTextField
                      name="newLeadSourceText"
                      label="New source"
                      placeholder="Summer deal landing page"
                      disabled={false}
                      value={this.state.newLeadSourceText}
                      formFieldStyle=""
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-success push-5-r push-10"
                      type="button"
                      disabled={!this.state.newLeadSourceText || this.state.newLeadSourceText.length === 0}
                      onClick={this.addLeadSourceSelected}
                    >
                      <i className="fa fa-plus" /> Add source
                    </button>
                  </div>
                </>
              )}
            </div>
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageClicked={this.pageClicked} pageDetails={this.props.pageDetails} />
              </div>
            )}
          </div>
        </div>
        {this.state.leadSourceToDelete && (
          <MigrateLeadSourceModalContainer modalName={MIGRATE_LEAD_SOURCE_MODAL} leadSource={this.state.leadSourceToDelete} />
        )}
        {this.state.selectedLeadSource && (
          <EditLeadSourceModalContainer modalName={EDIT_LEAD_SOURCE_MODAL} leadSource={this.state.selectedLeadSource} />
        )}
      </div>
    );
  }
}

LeadSourcesView.propTypes = {
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  createLeadSource: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default LeadSourcesView;
