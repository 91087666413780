import React from "react";
import styled from "styled-components";

export const toolTipSelector = calculating => {
  switch (calculating) {
    case "lead_created":
      return leadCreatedTooltips();
    case "event_occured":
      return eventOccuredTooltips();
    default:
      return eventOccuredTooltips();
  }
};

const leadCreatedTooltips = () => {
  const scheduledCallsDescription = (
    <Container>
      <p>
        For leads created during the date range, the{" "}
        <strong>total number</strong> of calls scheduled to occur (including
        outside the selected date range).
      </p>

      <p>
        For example, a new lead is created and a call is scheduled for them.
        This call can be scheduled at any time in the future, including outside
        the selected date range.
      </p>

      <p>Eg: John has scheduled a call for a new lead.</p>
    </Container>
  );

  const callAttemptsDescription = (
    <Container>
      <p>
        For leads created during the selected date range the total number of
        calls completed with an outcome.
      </p>

      <p>A call attempt can be both a <strong>successful or unsuccessful</strong>.</p>

      <p>
        Eg: Sally has a call scheduled and recorded the outcome of "left
        message.
      </p>
    </Container>
  );

  const successCallsDescription = (
    <Container>
      <p>
        For leads created in the time period, any call that was marked as
        complete where the lead answered the phone.
      </p>

      <p>
        A successful call is any call that was due and marked as complete that
        has the outcome of:
      </p>
      <p>
        • Booked an appointment
        <br />
        • Said they are still thinking
        <br />
        • Something Else
        <br />• Not Interested
      </p>
    </Container>
  );

  const contactSuccessRatio = (
    <Container>
      <p>
        The success ratio is calculated from the{" "}
        <strong>successful calls/call attempts and x100</strong>.
      </p>

      <p>*successful calls/call attempts x100 = %</p>
    </Container>
  );

  const toAppointmentDescription = (
    <Container>
      <p>
        For leads created in the time period that had an appointment booked as
        the result of a successful call .
      </p>

      <p>Eg: John called his lead and booked an appointment from that call.</p>
    </Container>
  );

  const toAppointmentRatioDescription = (
    <Container>
      <p>
        This is calculated from all the{" "}
        <strong>calls that were completed successfully</strong> for leads
        created in the date range and how many of those calls had the{" "}
        <strong>outcome of 'booked an appointment'</strong>.
      </p>

      <p>*Successful calls to appointments/successful calls x100 = %</p>
    </Container>
  );

  const tooltips = {
    scheduledCallsDescription,
    callAttemptsDescription,
    successCallsDescription,
    contactSuccessRatio,
    toAppointmentDescription,
    toAppointmentRatioDescription
  };
  return tooltips;
};

const eventOccuredTooltips = () => {
  const scheduledCallsDescription = (
    <Container>
      <p>
        These are all the calls that have been{" "}
        <strong>scheduled and are due to occur</strong> within your selected
        time period.
      </p>

      <p>
        For example, if you have someone that has a scheduled call on their
        profile but the due date falls outside of your select time period, it
        will not be recorded in this figure.
      </p>
    </Container>
  );

  const callAttemptsDescription = (
    <Container>
      <p>
        These are all the calls that have been{" "}
        <strong>completed with an outcome</strong> within your select time
        frame.
      </p>

      <p>A call attempt can be both a successful and unsuccessful call.</p>

      <p>
        Eg: Sally has a call scheduled within 7 days and marked it as complete.
      </p>
    </Container>
  );

  const successCallsDescription = (
    <Container>
      <p>
        A successful call is any call that was{" "}
        <strong>due and marked as complete</strong> within your time period that
        has the outcome of:
      </p>

      <p>
        • Booked an appointment
        <br />
        • Said they are still thinking
        <br />
        • Something Else
        <br />• Not Interested
      </p>
    </Container>
  );

  const contactSuccessRatio = (
    <Container>
      <p>
        The success ratio is calculated from the{" "}
        <strong>successful calls/call attempts and x100.</strong>
      </p>

      <p>*successful calls/call attempts x100 = %</p>
    </Container>
  );

  const toAppointmentDescription = (
    <Container>
      <p>
        This is calculated from all of the{" "}
        <strong>
          successful calls that had an outcome of 'Booked an appointment'
        </strong>{" "}
        that are due to occur within your select time period.
      </p>

      <p>
        Eg: John called his lead within the last 7 days and booked an
        appointment from that call
      </p>
    </Container>
  );

  const toAppointmentRatioDescription = (
    <Container>
      <p>
        This is calculated from all the{" "}
        <strong>calls that were completed successfully</strong>
        within your selected time period and how many of those calls{" "}
        <strong>had the outcome of 'booked an appointment'</strong>.
      </p>

      <p>*Successful calls to appointments/successful calls x100 = %</p>
    </Container>
  );

  const tooltips = {
    scheduledCallsDescription,
    callAttemptsDescription,
    successCallsDescription,
    contactSuccessRatio,
    toAppointmentDescription,
    toAppointmentRatioDescription
  };

  return tooltips;
};

const Container = styled.span``;
