import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectToUserRow from './SelectToUserRow'
import { PagesView } from 'modules/components'

export class SelectToUsersForm extends Component {

  pageClicked = (pageNumber) => {
    this.getUsersWithPageNumber(pageNumber)
  }

  getUsersWithPageNumber = (pageNumber) => {
    const { getUsers, currentLocationId } = this.props
    getUsers(currentLocationId, pageNumber)
  }

  render () {
    const { potentialUsers, toUserIds, addToUserId, removeFromUserId, fromUser, pageDetails } = this.props
    const rows = potentialUsers.filter(user => user.id !== fromUser.id)
    .map((user, index) => {
      const selectedIndex = toUserIds.findIndex(selectedUserId => user.id === selectedUserId)
      const isSelected = (selectedIndex >= 0)
      return <SelectToUserRow key={index} user={user} userSelected={isSelected}
        selectUser={addToUserId} deselectUser={removeFromUserId} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th />
              <th style={{ width: '40%' }}>Name</th>
              <th style={{ width: '40%' }}>Role</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        {pageDetails.pageCount > 1 &&
          <div className='text-center push'>
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked} />
          </div>
        }
      </div>
    )
  }
}

SelectToUsersForm.propTypes = {
  currentLocationId: PropTypes.number.isRequired,
  getUsers: PropTypes.func.isRequired,
  toUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  potentialUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  addToUserId: PropTypes.func.isRequired,
  removeFromUserId: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  fromUser: PropTypes.object.isRequired
}

export default SelectToUsersForm
