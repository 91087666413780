import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MultiDealSelectModal from "../components/MultiDealSelectModal";

import deals from "modules/Stores/Deals";
import modal from "modules/Stores/Modal";

function mapStateToProps(state) {
  return {
    deals: deals.selectors.getDeals(state),
    loading: deals.selectors.getLoading(state),
    errors: deals.selectors.getErrors(state),
    pageDetails: deals.selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDeals: deals.actions.getDeals,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiDealSelectModal);
