import { NAME } from './constants'

export const FETCHING = NAME + '/FETCHING'
export const FETCH_SUCCESS = NAME + '/FETCH_SUCCESS'
export const FETCH_FAILURE = NAME + '/FETCH_FAILURE'

export const SET_LEAD = NAME + '/SET_LEAD'
export const UPDATE_SALES_FUNNEL_STEP = NAME + '/UPDATE_SALES_FUNNEL_STEP'
export const SET_FACEBOOK_MESSENGER_USER = NAME + '/SET_FACEBOOK_MESSENGER_USER'
export const UPDATE_SALESPERSON_FOR_LEAD = NAME + '/UPDATE_SALESPERSON_FOR_LEAD'

export const SET_LEAD_SYNC_STATUS = NAME + '/SET_LEAD_SYNC_STATUS'

export const UPDATE_LEAD_SALES_FUNNEL_STEP = NAME + '/UPDATE_LEAD_SALES_FUNNEL_STEP'

export const CREATE_TAG = NAME + '/CREATE_TAG'
export const DELETE_TAG = NAME + '/DELETE_TAG'

export const SET_LOCATION = NAME + '/SET_LOCATION'
export const UPDATE_CALL_SCHEDULE_STEP = NAME + '/UPDATE_CALL_SCHEDULE_STEP'
