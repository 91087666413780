import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
const { SelectField } = FormFields;

export class MigrateDealModal extends Component {
  state = {
    toId: null,
  };

  handleSelect = (name, value) => {
    this.setState({ ...this.state, [name]: value });
  };

  migrateClicked = () => {
    const { deal, deleteDeal } = this.props;
    const { toId } = this.state;
    deleteDeal(deal.id, toId);
  };

  cleanUpAndHide = () => {
    this.setState({ toId: null });
    this.props.hideModal();
  };

  render() {
    const { loading, currentModal, modalName, deal, deals, errors } = this.props;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanUpAndHide}>
          <div className={blockStyle}>
            <div className="block-header bg-danger">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanUpAndHide}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Migrate Deal
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <div className="row">
                  <div className="col-xs-12">
                    {errorPanelForErrors(errors)}
                    <p>This will delete the deal {deal.title} and move all leads to the deal.</p>
                  </div>
                  <div className="col-xs-12" style={{ marginLeft: '-20px', marginRight: '20px' }}>
                    <SelectField
                      name="toId"
                      label="Which deal should this migrate to?"
                      possibleOptions={deals}
                      displayFieldName={'title'}
                      placeHolderText="Select a deal to move to"
                      formFieldStyle="form-material form-material-danger"
                      onChange={this.handleSelect}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndHide}>
              Cancel
            </button>
            <button
              className="btn btn-danger push-5-r push-10"
              type="button"
              disabled={this.state.toId === null}
              onClick={this.migrateClicked}
            >
              <i className="fa fa-cross" /> Migrate & Delete Deal
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

MigrateDealModal.propTypes = {
  deal: PropTypes.object.isRequired,
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  deleteDeal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default MigrateDealModal;
