import { isPresent } from "utils/validation";

export function validatorForOption(option) {
  switch (option) {
    case "glofox":
      return GlofoxValidator;
    default: {
    }
  }
}

const GlofoxValidator = {
  apiKey: [
    {
      rule: isPresent,
      errorMessage: "We need your Branch ID for your Glofox account."
    }
  ],
  companyName: [
    {
      rule: isPresent,
      errorMessage: "We need your Namespace for your Glofox account."
    }
  ]
};
