import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-bootstrap-modal'
import EmailHistoryItem from './EmailHistoryItem'

export class EmailHistoryView extends Component {

  componentDidMount () {
    const { fetchEventsForEmail, email, lead } = this.props
    if (email) {
      fetchEventsForEmail(lead, email)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.email.id !== this.props.email.id) {
      nextProps.reset()
      nextProps.fetchEventsForEmail(nextProps.lead, nextProps.email)
    }
  }

  dismissModal = () => {
    const { hideModal } = this.props
    hideModal()
  }

  render () {
    const { modalName, currentModal, loading, mailEvents } = this.props
    const emailItemsViews = mailEvents.map((mailEvent, index) => {
      return (
        <EmailHistoryItem key={index} mailEventItem={mailEvent} />
      )
    })

    const blockStyle = loading ? 'block block-opt-refresh' : 'block'
    return (
      <Modal
        show={currentModal === modalName}
        onHide={this.dismissModal}
        dialogClassName='modal'>
        <div className={blockStyle}>
          <div className='block-header bg-success'>
            <ul className='block-options'>
              <li>
                <button data-dismiss='modal' type='button' onClick={this.dismissModal} >
                  <i className='si si-close' style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className='block-title' style={{ color: 'white' }}>Email History</h3>
          </div>
          <div className='block-content'>
            <ul className='list list-timeline pull-t'>
              {emailItemsViews}
            </ul>
          </div>
        </div>
        <Modal.Footer>
          <button className='btn btn-default push-5-r push-10' type='button' onClick={this.dismissModal}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

EmailHistoryView.propTypes = {
  lead: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  mailEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object,
  fetchEventsForEmail: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  currentModal: PropTypes.string
}

export default EmailHistoryView
