import { request, POST } from 'utils/apiUtils';
import emails from './modules/emails';

import modal from 'modules/Stores/Modal';

import notify from 'modules/Notifications';
import { trackEvent } from 'modules/Helpers/Analytics';
const { startLoading, stopLoading, hideModal, showErrors } = modal.actions;

export const sendLegacyEmailToLead = (lead, subject, bodyTemplate) => {
  return (dispatch) => {
    const body = {
      lead_email: {
        subject: subject,
        body_template: bodyTemplate,
      },
    };
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(emails.actions.add(payload.lead_email));
      dispatch(hideModal());
      notify.success('Email sent');
    }

    trackEvent('Email Sent');

    return request('leads/' + lead.id + '/lead_emails', POST, body, onSuccess, onFailure);
  };
};

export const sendQuickEmailToLead = (lead, subject, text) => {
  return (dispatch) => {
    const body = {
      lead_email: {
        subject: subject,
        text: text,
      },
      quick_send: true,
    };
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(emails.actions.add(payload.lead_email));
      dispatch(hideModal());
      notify.success('Email sent');
    }

    trackEvent('Email Sent');

    return request('leads/' + lead.id + '/lead_emails', POST, body, onSuccess, onFailure);
  };
};

export const sendHtmlEmailToLead = (lead, subject, html) => {
  return (dispatch) => {
    const body = {
      lead_email: {
        subject: subject,
        html: html,
      },
    };
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(emails.actions.add(payload.lead_email));
      dispatch(hideModal());
      notify.success('Email sent');
    }

    trackEvent('Email Sent');

    return request('leads/' + lead.id + '/lead_emails', POST, body, onSuccess, onFailure);
  };
};
