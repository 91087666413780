import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserSelectView from '../components/UserSelectView'

import modal from 'modules/Stores/Modal'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    users: selectors.getPotentialUsers(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    selectedUser: selectors.getUserId(state),
    locationId: selectors.getLocationId(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getUsers: actions.getPotentialUsersForLocationId,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectView)
