import { connect } from 'react-redux'
import EditArticleView from '../components/EditArticleView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  }
}

const mapActionCreators = {
  updateArticle: actions.updateArticle
}

export default connect(mapStateToProps, mapActionCreators)(EditArticleView)
