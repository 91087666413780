import * as actionTypes from './actionTypes'

const CALL_SCHEDULE_SELECT_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_CALL_SCHEDULES]: (state, action) => {
    return { ...state, callSchedules: action.callSchedules }
  },
  [actionTypes.SET_SELECTED_CALL_SCHEDULE]: (state, action) => {
    return { ...state, selectedCallSchedule: action.callSchedule }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

function initialState () {
  return {
    loading: false,
    errors: {},
    callSchedules: [],
    selectedCallSchedule: null,
    pageDetails: {
      pageCount: 0,
      currentPage: 1,
      total: 0
    }
  }
}

export default function reducer (state = initialState(), action) {
  const handler = CALL_SCHEDULE_SELECT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
