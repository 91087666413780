import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SendEmailModal from "modules/components/SendEmailModal/SendEmailModal";

import modal from "modules/Stores/Modal";
import session from "modules/Stores/Session";
import mergeTags from "modules/Stores/MergeTags";

import * as emailActions from "../emailActions";
const { showModal, hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    isMobile: state.session.isMobile,
    user: session.selectors.getUser(state),
    errors: modal.selectors.getModalErrors(state),
    canSendEmail: session.selectors.canSendEmail(state),
    emailBlocked: session.selectors.emailBlocked(state),
    mergeTags: mergeTags.selectors.getMergeTags(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      sendQuickEmailToLead: emailActions.sendQuickEmailToLead,
      sendHtmlEmailToLead: emailActions.sendHtmlEmailToLead,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailModal);
