import { NAME } from './constants';

export const getWhitelabelProfile = (state) => state[NAME].whitelabelProfile;
export const getProductName = () => queryParams.client;

// Creates a hash with the URL Query params
var queryParams = window.location.search
  .substr(1)
  .split('&')
  .reduce(function (q, query) {
    var chunks = query.split('=');
    var key = chunks[0];
    var value = chunks[1];
    // eslint-disable-next-line
    return (q[key] = value), q;
  }, {});
