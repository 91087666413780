import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateNewFormState } from 'utils/formHelpers';

import { ContentBlockView, FormFields, Text } from 'modules/components';
import { validator } from '../validator';

import { MIGRATE_DEAL_MODAL } from '../constants';
import MigrateDealModalContainer from '../containers/MigrateDealModalContainer';
const { TextField } = FormFields;

export class DealsView extends Component {
  state = {
    dealToDelete: null,
  };

  saveDealClicked = () => {
    const { selectedDeal, updateDeal, data } = this.props;
    updateDeal(selectedDeal, data);
  };

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid);
    this.props.updateData(newData);
  };

  migrateDealClicked = () => {
    this.props.showModal(MIGRATE_DEAL_MODAL);
  };

  render() {
    const { selectedDeal, loading, data } = this.props;
    const { title } = data.fields;

    const journeyManaged = selectedDeal.journey_id !== null;
    const deleteButton = journeyManaged ? undefined : (
      <button type="button" className="pull-right btn btn-xs btn-danger" onClick={this.migrateDealClicked}>
        <i className="fa fa-cross" /> Delete Deal
      </button>
    );

    return (
      <div>
        <ContentBlockView title={selectedDeal.title} loading={loading} blockOptions={deleteButton} bordered>
          {journeyManaged && <Text.P2>This deal is managed in Journey.</Text.P2>}
          <div className="row push-20">
            <TextField
              name="title"
              label="Deal Title"
              placeholder="What should this Deal be called?"
              formFieldStyle=""
              onChange={this.handleInput}
              value={title.value}
              validator={validator}
              disabled={journeyManaged}
            />
          </div>
          {journeyManaged === false && (
            <div className="row">
              <div className="col-xs-4 push-20">
                <button className="btn btn-primary" onClick={this.saveDealClicked}>
                  <i className="fa fa-save" /> Save deal details
                </button>
              </div>
            </div>
          )}
        </ContentBlockView>
        <MigrateDealModalContainer modalName={MIGRATE_DEAL_MODAL} deal={selectedDeal} />
      </div>
    );
  }
}

DealsView.propTypes = {
  selectedDeal: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  deleteDeal: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default DealsView;
