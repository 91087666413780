import PropTypes from 'prop-types'
import React, { Component } from 'react'
import HeaderView from '../modules/header/components/HeaderView'
import FiltersContainer from '../containers/FiltersContainer'
import SummaryContainer from '../containers/SummaryContainer'
import GraphBreakdownContainer from '../containers/GraphBreakdownContainer'
import GraphSummaryContainer from '../containers/GraphSummaryContainer'
import TableContainer from '../containers/TableContainer'
import StepsContainer from '../containers/StepsContainer'

export class LostLeadsReportView extends Component {

  render () {
    return (
      <div className='content push-100'>
        <div className='row'>
          <div className='col-md-12'>
            <HeaderView />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <FiltersContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <SummaryContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <GraphBreakdownContainer />
          </div>
          <div className='col-md-6'>
            <GraphSummaryContainer />
          </div>
        </div>
        <div className='row'>
          <StepsContainer />
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <TableContainer />
          </div>
        </div>
      </div>
    )
  }
}

LostLeadsReportView.propTypes = {
  queryString: PropTypes.string
}

export default LostLeadsReportView
