import { isPresent } from 'utils/validation'

export function validatorForOption (option) {
  switch (option) {
    case 'mindbody':
      return MindbodyValidator
    default:
      {}
  }
}

const MindbodyValidator = {
  companyName: [{
    rule: isPresent,
    errorMessage: 'Whats this locations Studio ID?'
  }]
}
