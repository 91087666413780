import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectCountryTableRow } from "./SelectCountryTableRow";

export class SelectCountryTableView extends Component {
  render() {
    const {
      countries,
      selectedCountryIds,
      selectCountry,
      deselectCountry
    } = this.props;
    const rows = countries.map((country, index) => {
      const selectedIndex = selectedCountryIds.findIndex(
        selectedCountryId => country.id === selectedCountryId
      );
      const selected = selectedIndex >= 0;
      return (
        <SelectCountryTableRow
          key={index}
          country={country}
          countrySelected={selected}
          selectCountry={selectCountry}
          deselectCountry={deselectCountry}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectCountryTableView.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectCountry: PropTypes.func.isRequired,
  deselectCountry: PropTypes.func.isRequired
};

export default SelectCountryTableView;
