import PropTypes from "prop-types";
import React, { Component } from "react";
import TrialPerformanceView from "./TrialPerformanceView";
import TrialListView from "./TrialListView";

export class TrialsTableView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString } = this.props;
    fetchWithQueryString(queryString);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      const { fetchWithQueryString, queryString } = nextProps;
      fetchWithQueryString(queryString);
    }
  }

  render() {
    const { trialsData, loading, setSelectedCell, selectedCell } = this.props;

    return (
      <div className="row">
        <div className="col-md-4">
          <TrialListView trialsData={trialsData} loading={loading} />
        </div>
        <div className="col-md-8">
          <TrialPerformanceView
            trialsData={trialsData}
            setSelectedCell={setSelectedCell}
            selectedCell={selectedCell}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

TrialsTableView.propTypes = {
  loading: PropTypes.bool.isRequired,
  trialsData: PropTypes.arrayOf(PropTypes.object),
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string
};

export default TrialsTableView;
