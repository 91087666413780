import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ThirdPartyIntegrationsView } from '../components/ThirdPartyIntegrationsView'

import locations from 'modules/Stores/Locations'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    selectedLocationId: selectedLocationId,
    selectedLocation: selectedLocation
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    selectLocation: actions.selectLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyIntegrationsView)
