import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'
import appointmentList from '../../modules/AppointmentList'
import dateRangeSelect from '../../modules/DateRangeSelect'

const mapStateToProps = (state) => {
  return {
    loading: appointmentList.selectors.getLoading(state),
    timezone: session.selectors.getUserTimezone(state),
    appointments: appointmentList.selectors.getAppointments(state),
    pageCount: appointmentList.selectors.getPageCount(state),
    selectedAppointment: appointmentList.selectors.selectedAppointment(state),
    currentPage: appointmentList.selectors.getCurrentPage(state),
    totalCount: appointmentList.selectors.getTotalCount(state),
    startDate: dateRangeSelect.selectors.getStartDate(state),
    endDate: dateRangeSelect.selectors.getEndDate(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchInRange: appointmentList.actions.fetchInRange,
    selectAppointment: appointmentList.actions.selectAppointment,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(appointmentList.components.AppointmentListView)
