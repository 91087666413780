import { request, GET, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';

import notify from 'modules/Notifications';
const { startLoading, stopLoading, hideModal } = modal.actions;

// Fetch Notes
export const fetchNotesForLead = (lead) => {
  return (dispatch) => {
    dispatch(fetchingNotes());

    function onFailure(errors) {
      dispatch(fetchNotesFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchNotesSuccess(payload.lead_notes));
    }

    return request('leads/' + lead.id + '/lead_notes', GET, null, onSuccess, onFailure);
  };
};

function fetchingNotes() {
  return {
    type: actionTypes.FETCHING_NOTES,
  };
}

function fetchNotesSuccess(notes) {
  return {
    type: actionTypes.FETCHING_NOTES_SUCCESS,
    notes,
  };
}

function fetchNotesFailed(errors) {
  return {
    type: actionTypes.FETCHING_NOTES_FAILURE,
    errors,
  };
}

// Create Note
export const createNoteForLead = (lead, detail) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = {
      lead_note: {
        detail: detail,
      },
    };

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(hideModal());
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(saveNoteSuccess(payload.lead_note_version));
      dispatch(hideModal());
      notify.success('Note created');
    }

    return request('leads/' + lead.id + '/lead_notes', POST, body, onSuccess, onFailure);
  };
};

function saveNoteSuccess(note) {
  return {
    type: actionTypes.SAVE_NOTE_SUCCESS,
    note,
  };
}

// Update Note
export const updateNoteForLead = (lead, note, updatedDetail) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = {
      lead_note: {
        detail: updatedDetail,
      },
    };

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(hideModal());
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(updateNoteSuccess(payload.lead_note_version));
      dispatch(hideModal());
      notify.success('Note updated');
    }

    return request('leads/' + lead.id + '/lead_notes/' + note.lead_note_id, PUT, body, onSuccess, onFailure);
  };
};

function updateNoteSuccess(note) {
  return {
    type: actionTypes.UPDATE_NOTE_SUCCESS,
    note,
  };
}
