import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MultiLeadLostReasonSelectModal from "../components/MultiLeadLostReasonSelectModal";

import * as leadLostReasonApiActions from "modules/Stores/LeadLostReasons/apiActions";
import leadLostReasons from "modules/Stores/LeadLostReasons";
import modal from "modules/Stores/Modal";

function mapStateToProps(state) {
  return {
    leadLostReasons: leadLostReasons.selectors.getLeadLostReasons(state),
    loading: leadLostReasons.selectors.getLoading(state),
    errors: leadLostReasons.selectors.getErrors(state),
    pageDetails: leadLostReasons.selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadLostReasons: leadLostReasonApiActions.getLeadLostReasons,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiLeadLostReasonSelectModal);
