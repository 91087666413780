import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeSelect from "../components/TimeSelect";
import session from 'modules/Stores/Session'

function mapStateToProps(state) {
  return {
    timezone: session.selectors.getUserTimezone(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSelect);
