import * as actionTypes from "./actionTypes";

const DEALS_ACTION_HANDLERS = {
  [actionTypes.SET_DEALS]: (state, action) => {
    return { ...state, deals: action.deals };
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.DEAL_SAVED]: (state, action) => {
    const oldDeals = state.deals;
    const index = indexForDeal(oldDeals, action.deal.id);
    let newDeals = [];
    if (index < 0) {
      newDeals = [...oldDeals, action.deal];
    } else {
      newDeals = [
        ...oldDeals.slice(0, index),
        action.deal,
        ...oldDeals.slice(index + 1)
      ];
    }

    return { ...state, deals: newDeals };
  },
  [actionTypes.DELETE_DEAL_WITH_ID]: (state, action) => {
    const oldDeals = state.deals;
    const index = indexForDeal(oldDeals, action.dealId);
    const newDeals = [
      ...state.deals.slice(0, index),
      ...state.deals.slice(index + 1)
    ];
    return { ...state, deals: newDeals };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  deals: [],
  errors: null,
  loading: false,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
};

export default function reducer(state = initialState, action) {
  const handler = DEALS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

function indexForDeal(deals, dealId) {
  return deals.findIndex(deal => parseInt(deal.id) === parseInt(dealId));
}
