import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectLocationFormContainer from '../containers/SelectLocationFormContainer'

export class SelectLocationView extends Component {

  render () {
    const { isActive } = this.props
    const activeClassName = (isActive) ? 'active in' : ''
    return (
      <div className={'tab-pane fade fade-up push-50 ' + activeClassName}>
        <div className='row push-20'>
          <div className='col-md-12'>
            <h4>Reallocate leads from a user</h4>
            <p>
              Move leads from one of your team members to other users on your team. Leads will be distributed{' '}
              evenly across selected users
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <SelectLocationFormContainer />
          </div>
        </div>
      </div>
    )
  }
}

SelectLocationView.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default SelectLocationView
