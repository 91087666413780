import { NAME } from './constants'

export const getErrors = (state) => state.leadsListReport[NAME].errors
export const getLoading = (state) => state.leadsListReport[NAME].loading
export const getLeads = (state) => state.leadsListReport[NAME].leads
export const getPageCount = state => state.leadsListReport[NAME].pageCount
export const getCurrentPage = state => state.leadsListReport[NAME].currentPage

export function convertUpdatedQueryStringToCreated (queryString) {
  return queryString
  .replace(/status_updated_before/g, 'created_before')
  .replace(/status_updated_after/g, 'created_after')
}
