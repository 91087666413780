import * as actionTypes from './actionTypes'

const LEAD_DETAILS_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state) => {
    return { ...state, loading: true, fullDetail: false }
  },
  [actionTypes.FETCH_SUCCESS]: (state) => {
    return { ...state, loading: false }
  },
  [actionTypes.FETCH_FAILURE]: (state) => {
    return { ...state, loading: false }
  },
  [actionTypes.SET_LEAD]: (state, action) => {
    return { ...state, lead: action.lead, fullDetail: true }
  },
  [actionTypes.SET_FACEBOOK_MESSENGER_USER]: (state, action) => {
    if (!state.lead) { return state }
    const updatedLead = {
      ...state.lead,
      facebook_messenger_user: action.facebookMessengerUser
    }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.UPDATE_SALES_FUNNEL_STEP]: (state, action) => {
    const newLead = {
      ...state.lead,
      sales_funnel_step: action.salesFunnelStep
    }
    return { ...state, lead: newLead }
  },
  [actionTypes.UPDATE_LEAD_SALES_FUNNEL_STEP]: (state, action) => {
    const lead = state.lead
    const updatedLead = { ...lead, sales_funnel_step: action.salesFunnelStep }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.UPDATE_SALESPERSON_FOR_LEAD]: (state, action) => {
    const lead = state.lead
    const updatedLead = { ...lead, salesperson: action.salesperson }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.DELETE_TAG]: (state, action) => {
    const lead = state.lead
    const tagIndex = indexForTag(lead.lead_tags, action.tag)
    const updatedTags = [
      ...lead.lead_tags.slice(0, tagIndex),
      ...lead.lead_tags.slice(tagIndex + 1)
    ]
    const updatedLead = { ...lead, lead_tags: updatedTags }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.CREATE_TAG]: (state, action) => {
    const lead = state.lead
    const updatedTags = [
      ...lead.lead_tags,
      action.tag
    ]
    const updatedLead = { ...lead, lead_tags: updatedTags }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.SET_LEAD_SYNC_STATUS]: (state, action) => {
    const lead = state.lead
    const updatedLead = { ...lead, lead_sync_status: action.leadSyncStatus, syncing: false }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.SET_LOCATION]: (state, action) => {
    const location = action.location
    const updatedLead = { ...state.lead, location_id: location.id, location_name: location.admin_display_name }
    return { ...state, lead: updatedLead }
  },
  [actionTypes.UPDATE_CALL_SCHEDULE_STEP]: (state, action) => {
    const oldStep = state.lead.call_schedule_step
    if (oldStep) {
      const newStep = { ...action.callScheduleStep, schedule_name: oldStep.schedule_name }
      const updatedLead = { ...state.lead, call_schedule_step: newStep }
      return { ...state, lead: updatedLead }
    } else {
      return state
    }
  }
}

const initialState = {
  loading: false,
  fullDetail: false,
  lead: null
}

function indexForTag (tags, tag) {
  return tags.findIndex(_tag => _tag.name === tag)
}

export default function reducer (state = initialState, action) {
  const handler = LEAD_DETAILS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
