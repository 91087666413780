import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MigrateLeadLostReasonModal from "../components/MigrateLeadLostReasonModal";

import * as actions from "../actions";
import leadLostReasons from "modules/Stores/LeadLostReasons";
import * as leadLostReasonsApiActions from "modules/Stores/LeadLostReasons/apiActions";

import modal from "modules/Stores/Modal";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    leadLostReasons: leadLostReasons.selectors.getLeadLostReasons(state),
    pageDetails: leadLostReasons.selectors.getPageDetails(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      getLeadLostReasons: leadLostReasonsApiActions.getLeadLostReasons,
      deleteLeadLostReason: actions.deleteLeadLostReason
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MigrateLeadLostReasonModal);
