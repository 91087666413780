/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { cardTypeForNumber, lastFourDigitsOfNumber } from 'modules/Stores/Billing/ccUtils';
import moment from 'moment';
import CreditCardModalContainer from '../containers/CreditCardModalContainer';
import { ADD_CC_MODAL } from '../constants';

export class SelectPaymentMethodModal extends Component {
  paymentMethodClicked = (selectedPaymentMethod) => {
    const { location, updateLocationPaymentMethod, idValue } = this.props;
    updateLocationPaymentMethod(location, selectedPaymentMethod.id, idValue);
  };

  showAddCardModal = () => {
    this.props.showModal(this.addCCModalName());
  };

  addCCModalName = () => {
    return this.props.modalName + ADD_CC_MODAL;
  };

  render() {
    const { loading, currentModal, hideModal, modalName, location, idValue } = this.props;

    const paymentMethods = this.props.paymentMethods.map((paymentMethod, index) => {
      const iconStyle = paymentMethod.id === location[idValue] ? 'fa fa-check-circle text-success push-10-l' : '';
      return (
        <div key={index} className="col-lg-12">
          <a className="block selectable" onClick={() => this.paymentMethodClicked(paymentMethod)}>
            <div className={'block-content block-content-full clearfix'}>
              <i className="si si-credit-card push-10-r" />
              {cardTypeForNumber(paymentMethod.masked_number)} ending in {lastFourDigitsOfNumber(paymentMethod.masked_number)} added{' '}
              {moment(paymentMethod.created_at).format('MMMM Do YYYY')}
              <i className={iconStyle} />
            </div>
          </a>
        </div>
      );
    });

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Select a payment method
              </h3>
            </div>
            <div className="block-content">
              <div className="row">{paymentMethods}</div>
              <button className={'btn btn-success push-5-r push-10'} type="button" onClick={this.showAddCardModal}>
                <i className="fa fa-plus" /> Use new card
              </button>
            </div>
          </div>
        </Modal>
        <CreditCardModalContainer locationId={location.id} idValue={idValue} modalName={this.addCCModalName()} />
      </div>
    );
  }
}

SelectPaymentMethodModal.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLocationPaymentMethod: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  idValue: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default SelectPaymentMethodModal;
