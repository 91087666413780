import * as actionTypes from './actionTypes'

const LOCATION_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_INITIAL_LOAD_COMPLETE]: (state, action) => {
    return { ...state, initialLoadComplete: action.initialLoadComplete }
  },
  [actionTypes.SET_IS_MULTI_LOCATION_ACCOUNT]: (state, action) => {
    return { ...state, isMultiLocationAccount: action.isMultiLocationAccount }
  },
  [actionTypes.SET_DEFAULT_LOCATION]: (state, action) => {
    return { ...state, defaultLocation: action.defaultLocation }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_LOCATIONS]: (state, action) => {
    return { ...state, locations: action.locations }
  },
  [actionTypes.ADD_LOCATION]: (state, action) => {
    const index = indexForLocation(state.locations, action.location.id)
    if (index !== -1) { return state }
    const newLocations = [
      ...state.locations,
      action.location
    ]
    return { ...state, locations: newLocations }
  },
  [actionTypes.REMOVE_LOCATION]: (state, action) => {
    const index = indexForLocation(state.locations, action.locationId)

    const newLocations = [
      ...state.locations.slice(0, index),
      ...state.locations.slice(index + 1)
    ]

    return { ...state, locations: newLocations }
  },
  [actionTypes.EDIT_LOCATION]: (state, action) => {
    const index = indexForLocation(state.locations, action.location.id)

    const newLocations = [
      ...state.locations.slice(0, index),
      action.location,
      ...state.locations.slice(index + 1)
    ]

    return { ...state, locations: newLocations }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

function indexForLocation (locations, locationId) {
  return locations.findIndex(location => location.id === locationId)
}

const initialState = {
  loading: false,
  errors: null,
  initialLoadComplete: false,
  locations: [],
  isMultiLocationAccount: false,
  defaultLocation: null,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = LOCATION_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
