import * as LeadActions from './actions'
import details from 'modules/components/LeadDetail/modules/details'

export const listUpdateProtocol = {
  deselect: LeadActions.deselect,
  add: LeadActions.add,
  remove: LeadActions.remove,
  addTag: details.actions.addTag,
  removeTag: details.actions.removeTag,
  updateLeadAssignentForLead: LeadActions.updateLeadAssignentForLead,
  updateLeadDetails: LeadActions.updateLeadDetails,
  updateSalesFunnelStep: LeadActions.updateSalesFunnelStep
}
