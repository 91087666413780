import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class PageHeader extends Component {

  render () {
    const { title, subtitle, image, imageYPositionStyle } = this.props
    const yPosition = (imageYPositionStyle) || '50%'
    const divStyle = {
      backgroundImage: 'url(' + image + ')',
      backgroundPositionY: yPosition,
      overflow: 'visible'
    }

    return (
      <div className='bg-image img-rounded overflow-hidden push' style={divStyle}>
        <div className='bg-black-op'>
          <div className='content'>
            {this.props.headerChildren}
            <div className='block block-transparent block-themed text-center'>
              <div className='block-content'>
                <h1 className='h1 font-w700 text-white animated fadeInDown push-5'>{title}</h1>
                <h2 className='h4 font-w400 text-white-op animated fadeInUp'>
                  {subtitle}
                </h2>
              </div>
            </div>
          </div>
          {this.props.headerLowerChildren}
        </div>
      </div>
    )
  }
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  headerChildren: PropTypes.element,
  headerLowerChildren: PropTypes.element,
  imageYPositionStyle: PropTypes.string

}
