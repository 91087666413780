import * as actionTypes from "./actionTypes";

import { safePropertyForObject } from "utils/formHelpers";

const GLOFOX_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.ON_INPUT]: (state, action) => {
    return { ...state, data: action.data };
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    return initialStateForIntegration(action.integration);
  },
  [actionTypes.RESET]: state => {
    return initialStateForIntegration({});
  },
  [actionTypes.SET_PERMISSION_SET]: (state, action) => {
    return { ...state, permissionSet: action.permissionSet };
  }
};

function initialStateForIntegration(option) {
  return {
    permissionSet: option.integration_permission_set,
    data: {
      fields: {
        id: {
          value: safePropertyForObject(option, "id"),
          isValid: true
        },
        companyName: {
          value: safePropertyForObject(option, "company_name"),
          isValid: true
        },
        apiKey: {
          value: safePropertyForObject(option, "api_key"),
          isValid: true
        }
      }
    }
  };
}

export default function reducer(
  state = initialStateForIntegration({}),
  action
) {
  const handler = GLOFOX_PREFERENCES_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
