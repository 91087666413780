import { NAME } from './constants'

export const FETCH = NAME + '/FETCH'
export const FETCH_SUCCESS = NAME + '/FETCH_SUCCESS'
export const FETCH_FAILED = NAME + '/FETCH_FAILED'

export const SELECT = NAME + '/SELECT'
export const REMOVE_SELECTED = NAME + '/REMOVE_SELECTED'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
