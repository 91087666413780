import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getShowingMore = (state) => state.manageLeads[NAME].showingMore
export const getData = (state) => state.manageLeads[NAME].data
export const getLocationSearchTerm = (state) => state.manageLeads[NAME].locationSearchTerm
export const getLocationCurrentPage = (state) => state.manageLeads[NAME].locationCurrentPage
export const getCountLoading = (state) => state.manageLeads[NAME].countLoading
export const getCounts = (state) => state.manageLeads[NAME].counts
export const getHasPrefilledUser = (state) => state.manageLeads[NAME].hasPrefilledUser

export const activeExtraFiltersCount = createSelector(
  [ getData ],
  (data) => {
    const nameContainsData = (data['contains']) || { fields: {} }
    const createdBetweenData = (data['statusUpdatedBetween']) || { fields: {} }
    const locationIdsData = (data['location_ids']) || { fields: {} }
    const leadSourceIdsData = (data['lead_source_ids']) || { fields: {} }
    const heardAboutMethodIdsData = (data['heard_about_method_ids']) || { fields: {} }
    const tagData = (data.tags) || { fields: {} }
    const userIdData = (data['user_ids']) || { fields: {} }
    const lastContactedData = (data['lastContacted']) || { fields: {} }
    const trialData = (data['trials']) || { fields: {} }
    const noActionsData = (data['no_due_actions']) || { fields: {} }

    const activeTagKeys = Object.keys(tagData.fields)
      .filter((key) => { return tagData.fields[key].value === true })

    const nameContainsFields = Object.keys(nameContainsData.fields)
      .filter((key) => { return nameContainsData.fields[key].value })

    const lastContactedFields = Object.keys(lastContactedData.fields)
      .filter((key) => { return lastContactedData.fields[key].value })

    const trialFields = Object.keys(trialData.fields)
      .filter((key) => { return trialData.fields[key].value })

    let createdBetweenFields = Object.keys(createdBetweenData.fields)
      .filter((key) => { return createdBetweenData.fields[key].value })
    if (createdBetweenFields.length === 2) {
      createdBetweenFields = createdBetweenFields.slice(-1)
    }

    const activeLocationIdFields = Object.keys(locationIdsData.fields)
      .filter((key) => { return locationIdsData.fields[key].value === true })

    const activeLeadSourceFields = Object.keys(leadSourceIdsData.fields)
      .filter((key) => { return leadSourceIdsData.fields[key].value === true })

    const activeHeardAboutMethodFields = Object.keys(heardAboutMethodIdsData.fields)
      .filter((key) => { return heardAboutMethodIdsData.fields[key].value === true })

    const activeUerIds = Object.keys(userIdData.fields)
      .filter((key) => { return userIdData.fields[key].value === true })

    const activeNoActionsData = Object.keys(noActionsData.fields)
      .filter((key) => { return noActionsData.fields[key].value === true })

    return [
      ...activeLeadSourceFields,
      ...activeHeardAboutMethodFields,
      ...lastContactedFields,
      ...trialFields,
      ...nameContainsFields,
      ...createdBetweenFields,
      ...activeTagKeys,
      ...activeLocationIdFields,
      ...activeUerIds,
      ...activeNoActionsData
    ].length
  }
)

export function leadShouldAddFactory (lead) {
  return createSelector(
    [ getData ],
    (data) => {
      return false
    }
  )
}
