import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PlanDetailsPanel from './PlanDetailsPanel'
import PaymentMethodsContainer from '../containers/PaymentMethodsContainer'
import NextPaymentDueSection from './NextPaymentDueSection'

export class SubscriptionView extends Component {

  UNSAFE_componentWillMount () {
    const { getSubscriptionDetails } = this.props
    getSubscriptionDetails()
  }

  render () {
    const { loading, billingPlan, timezone } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Subscription Details</h3>
          </div>
          <div className='block-content'>
            <div className='row'>
              <div className='col-sm-8'>
                <PaymentMethodsContainer />
                {billingPlan.next_charge_date &&
                  <NextPaymentDueSection dueDate={billingPlan.next_charge_date} timezone={timezone} />
                }
              </div>
              <div className='col-sm-4'>
                {billingPlan.membership &&
                  <PlanDetailsPanel
                    membership={billingPlan.membership}
                    totalCost={billingPlan.total_cost}
                    membershipPermissions={this.props.membershipPermissions} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SubscriptionView.propTypes = {
  getSubscriptionDetails: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  billingPlan: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  membershipPermissions: PropTypes.object.isRequired
}

export default SubscriptionView
