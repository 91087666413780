import styled from 'styled-components';
import { InputHTMLAttributes } from 'react';

import {
  color,
  space,
  SpaceProps,
  size,
  layout,
  overflow,
  OverflowProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  typography,
  TextStyleProps,
  background,
  BackgroundColorProps,
  BackgroundProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
} from 'styled-system';

export interface InputProps
  extends SpaceProps,
    InputHTMLAttributes<HTMLInputElement>,
    FlexboxProps,
    OverflowProps,
    PositionProps,
    BorderProps,
    ShadowProps,
    TextStyleProps,
    BackgroundColorProps,
    FontFamilyProps,
    FontSizeProps,
    FontStyleProps,
    FontFamilyProps,
    FontWeightProps,
    LetterSpacingProps,
    LineHeightProps,
    BackgroundProps {
  id?: string;
}

export const Input = styled.input<InputProps>`
  ${space}
  ${size}
  ${layout}
  ${overflow}
  ${color}
  ${flexbox}
  ${border}
  ${shadow}
  ${position}
  ${typography}
  ${background}
  ${fontStyle}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${letterSpacing}
  ${lineHeight}
`;
