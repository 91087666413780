import * as actionTypes from './actionTypes'

const LEADS_LIST_ACTION_HANDLERS = {
  [actionTypes.FETCHING_LEADS]: (state) => {
    return ({ ...state, loading: true, errors: null })
  },
  [actionTypes.FETCH_LEADS_SUCCESS]: (state, action) => {
    return ({ ...state, loading: false, leads: action.leads })
  },
  [actionTypes.FETCH_LEADS_FAILED]: (state, action) => {
    return ({ ...state, loading: false, errors: action.errors })
  },
  [actionTypes.SELECT_LEAD]: (state, action) => {
    return ({ ...state, selectedLeadId: action.leadId })
  },
  [actionTypes.DESELECT_LEAD]: (state) => {
    return { ...state, selectedLeadId: null }
  },
  [actionTypes.ADD_LEAD]: (state, action) => {
    const updatedleads = [
      action.lead,
      ...state.leads
    ]
    return ({ ...state, leads: updatedleads })
  },
  [actionTypes.REMOVE_LEAD]: (state, action) => {
    const index = indexForLead(state.leads, action.lead)
    const leads = [
      ...state.leads.slice(0, index),
      ...state.leads.slice(index + 1)
    ]
    return { ...state, loading: false, leads: leads, selectedLeadId: null }
  },
  [actionTypes.UPDATE_SALESPERSON_FOR_LEAD]: (state, action) => {
    const index = indexForLead(state.leads, action.lead)
    const lead = { ...state.leads[index], salesperson: action.salesperson }
    const leads = [
      ...state.leads.slice(0, index),
      lead,
      ...state.leads.slice(index + 1)
    ]
    return { ...state, leads: leads }
  },
  [actionTypes.UPDATE_LEAD]: (state, action) => {
    const index = indexForLead(state.leads, action.lead)
    const leads = [
      ...state.leads.slice(0, index),
      action.lead,
      ...state.leads.slice(index + 1)
    ]
    return { ...state, leads: leads }
  },
  [actionTypes.DELETE_TAG]: (state, action) => {
    const leadIndex = indexForLead(state.leads, action.lead)
    const lead = state.leads[leadIndex]
    const tagIndex = indexForTag(lead.lead_tags, action.tag)
    const updatedTags = [
      ...lead.lead_tags.slice(0, tagIndex),
      ...lead.lead_tags.slice(tagIndex + 1)
    ]
    const updatedLead = { ...lead, lead_tags: updatedTags }

    const leads = [
      ...state.leads.slice(0, leadIndex),
      updatedLead,
      ...state.leads.slice(leadIndex + 1)
    ]
    return { ...state, leads: leads }
  },
  [actionTypes.CREATE_TAG]: (state, action) => {
    const leadIndex = indexForLead(state.leads, action.lead)
    const lead = state.leads[leadIndex]
    const updatedTags = [
      ...lead.lead_tags,
      action.tag
    ]
    const updatedLead = { ...lead, lead_tags: updatedTags }
    const leads = [
      ...state.leads.slice(0, leadIndex),
      updatedLead,
      ...state.leads.slice(leadIndex + 1)
    ]
    return { ...state, leads: leads }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalLeads: action.pageDetails.total_count
    }
  },
  [actionTypes.UPDATE_LEAD_SALES_FUNNEL_STEP]: (state, action) => {
    const leadIndex = indexForLead(state.leads, action.lead)
    const lead = state.leads[leadIndex]
    const updatedLead = { ...lead, sales_funnel_step: action.salesFunnelStep }
    const leads = [
      ...state.leads.slice(0, leadIndex),
      updatedLead,
      ...state.leads.slice(leadIndex + 1)
    ]
    return { ...state, leads: leads }
  },
  [actionTypes.SET_SHOWING_QUICK_LEAD_VIEW]: (state, action) => {
    return { ...state, showingQuickLeadView: action.showingQuickLeadView }
  }
}

function indexForLead (leads, lead) {
  return leads.findIndex(_lead => _lead.id === lead.id)
}

function indexForTag (tags, tag) {
  return tags.findIndex(_tag => _tag.name === tag.text)
}

const initialState = {
  leads: [],
  errors: null,
  loading: false,
  pageCount: 1,
  currentPage: 1,
  totalLeads: 0,
  showingQuickLeadView: false,
  selectedLeadId: null
}

export default function reducer (state = initialState, action) {
  const handler = LEADS_LIST_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
