import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectLeadSourceTableRow } from "./SelectLeadSourceTableRow";

export class SelectLeadSourceTableView extends Component {
  render() {
    const { leadSources, selectedLeadSource, onLeadSourceSelect } = this.props;
    const rows = leadSources.map((leadSource, index) => {
      const selectedId = selectedLeadSource ? selectedLeadSource.id : null;
      const selected = selectedId === leadSource.id;
      return (
        <SelectLeadSourceTableRow
          key={index}
          leadSource={leadSource}
          leadSourceSelected={selected}
          onLeadSourceSelect={onLeadSourceSelect}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectLeadSourceTableView.propTypes = {
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLeadSource: PropTypes.object,
  onLeadSourceSelect: PropTypes.func.isRequired
};

export default SelectLeadSourceTableView;
