import { combineReducers } from 'redux';
import filters from './modules/filters';
import trialsTable from './modules/trialsTable';
import leadsTable from './modules/leadsTable';
import timeTakenTable from './modules/timeTakenTable';

export default combineReducers({
  [filters.constants.NAME]: filters.reducer,
  [trialsTable.constants.NAME]: trialsTable.reducer,
  [leadsTable.constants.NAME]: leadsTable.reducer,
  [timeTakenTable.constants.NAME]: timeTakenTable.reducer,
});
