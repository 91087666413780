import moment from 'moment'
import 'moment-timezone'
import * as actionTypes from './actionTypes'

const CREATE_LOCATION_ACTION_HANDLERS = {
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.UPDATE_FORM_PAYMENT_DATA]: (state, action) => {
    return { ...state, paymentData: action.paymentData }
  },
  [actionTypes.RESET_FORM]: (state) => {
    return initialStateForLocation(null)
  }
}

function initialStateForLocation (location) {
  const timezone = moment.tz.guess()
  return {
    data: {
      fields: {
        name: {
          value: null,
          isValid: true
        },
        addressLineOne: {
          value: null,
          isValid: false
        },
        addressLineTwo: {
          value: null,
          isValid: true
        },
        phoneNumber: {
          value: null,
          isValid: true
        },
        city: {
          value: null,
          isValid: false
        },
        state: {
          value: null,
          isValid: false
        },
        countryId: {
          value: null,
          isValid: false
        },
        timezone: {
          value: timezone,
          isValid: (timezone !== null || timezone !== undefined)
        },
        zipPostcode: {
          value: null,
          isValid: false
        },
        paychoicePaymentMethodId: {
          value: null,
          isValid: true
        }
      }
    },
    paymentData: {
      isValid: false,
      fields: {
        number: {
          value: '',
          isValid: false
        },
        name: {
          value: '',
          isValid: false
        },
        expiry: {
          value: '',
          isValid: false
        },
        cvc: {
          value: '',
          isValid: false
        }
      }
    }
  }
}

export default function reducer (state = initialStateForLocation(null), action) {
  const handler = CREATE_LOCATION_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
