import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ReportSummaryPanel extends Component {
  render() {
    const { title, body, icon, linkTo, bgColor } = this.props;
    return (
      <Link to={linkTo} className="block block-link-hover3 selectable">
        <div
          className={"block-content block-content-full text-center " + bgColor}
        >
          <div className="item item-2x item-circle bg-crystal-op push-20-t push-20">
            <i className={"text-white-op " + icon} />
          </div>
        </div>
        <div
          className="block-content block-content-full"
          style={{ height: "140px" }}
        >
          <h3 className="h4 push-10">{title}</h3>
          <p className="text-gray-dark">{body}</p>
        </div>
      </Link>
    );
  }
}

ReportSummaryPanel.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};
