import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  MOVE_LEAD_MODAL,
  LEAD_LOST_REASON_MODAL,
  DEAL_MODAL,
} from "../constants";

import MoveLeadModalContainer from "../containers/MoveLeadModalContainer";
import LeadLostReasonModalContainer from "../containers/LeadLostReasonModalContainer";
import DealModalContainer from "../containers/DealModalContainer";

import SweetAlert from "react-bootstrap-sweetalert";

import "./LeadDetail.css";

export class ActiveLeadButtons extends Component {
  state = {
    showLostWarning: false,
    showInactiveWarning: false,
  };

  showLostWarningPopup = () => {
    this.setState({ ...this.state, showLostWarning: true });
  };

  showInactiveWarningPopup = () => {
    this.setState({ ...this.state, showInactiveWarning: true });
  };

  dismissPopups = () => {
    this.setState({
      ...this.state,
      showLostWarning: false,
      showInactiveWarning: false,
    });
  };

  setLeadToInactive = () => {
    const { updateLeadToStatus, lead, listUpdateProtocol } = this.props;
    this.setState({
      ...this.state,
      showLostWarning: false,
      showInactiveWarning: false,
    });
    updateLeadToStatus(
      lead,
      "inactive",
      lead.sales_funnel_step,
      null,
      listUpdateProtocol
    );
  };

  showLeadLostReasons = () => {
    this.setState({
      ...this.state,
      showLostWarning: false,
      showInactiveWarning: false,
    });
    this.props.showModal(LEAD_LOST_REASON_MODAL);
  };

  closeClicked = () => {
    this.props.showModal(DEAL_MODAL);
  };

  moveLeadSelected = () => {
    this.props.showModal(MOVE_LEAD_MODAL);
  };

  canLeadBeClosed = () => {
    const { lead } = this.props;
    return (
      lead &&
      lead.sales_funnel_step &&
      lead.sales_funnel_step.allows_close === true
    );
  };

  render() {
    const { lead, listUpdateProtocol } = this.props;
    const { showLostWarning, showInactiveWarning } = this.state;
    return (
      <div>
        <div>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.moveLeadSelected}
            style={{ marginRight: "5px" }}
          >
            <i className="fa fa-exchange" style={{ marginRight: "5px" }} />
            Move to step ...
          </button>
          <button
            type="button"
            className="btn btn-warning"
            onClick={this.showInactiveWarningPopup}
            style={{ marginRight: "5px" }}
          >
            <i className="fa fa-warning" style={{ marginRight: "5px" }} />
            Inactive
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.showLostWarningPopup}
            style={{ marginRight: "5px" }}
          >
            <i className="fa fa-close" style={{ marginRight: "5px" }} />
            Lost
          </button>
          {this.canLeadBeClosed() && (
            <button
              type="button"
              className="btn btn-success"
              onClick={this.closeClicked}
            >
              <i className="fa fa-check" style={{ marginRight: "5px" }} />
              Close
            </button>
          )}
        </div>
        <MoveLeadModalContainer
          modalName={MOVE_LEAD_MODAL}
          lead={lead}
          listUpdateProtocol={listUpdateProtocol}
        />
        <LeadLostReasonModalContainer
          modalName={LEAD_LOST_REASON_MODAL}
          lead={lead}
          listUpdateProtocol={listUpdateProtocol}
        />
        <DealModalContainer
          modalName={DEAL_MODAL}
          lead={lead}
          listUpdateProtocol={listUpdateProtocol}
        />
        {showInactiveWarning && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="warning"
            title="Are you Sure?"
            confirmBtnText="Change to Inactive"
            onConfirm={this.setLeadToInactive}
            onCancel={this.dismissPopups}
          >
            This lead will no longer be in the sales funnel
          </SweetAlert>
        )}
        {showLostWarning && (
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="danger"
            confirmBtnText="Mark as Lost"
            title="Are you Sure?"
            onConfirm={this.showLeadLostReasons}
            onCancel={this.dismissPopups}
          >
            This Lead will no longer be in the sales funnel and will be removed
            from all communication channels
          </SweetAlert>
        )}
      </div>
    );
  }
}

ActiveLeadButtons.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  updateLeadToStatus: PropTypes.func.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object,
};

export default ActiveLeadButtons;
