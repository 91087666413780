import * as actionTypes from './actionTypes'

const SOCKETS_ACTION_HANDLERS = {
  [actionTypes.SET_CONNECTED]: (state, action) => {
    return { ...state, connected: action.connected }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.ADD_CHANNEL]: (state, action) => {
    const newChannels = [
      ...state.channels,
      action.channelName
    ]
    return { ...state, channels: newChannels }
  },
  [actionTypes.REMOVE_CHANNEL]: (state, action) => {
    const index = indexForChannel(state.channels, action.channelName)
    const newChannels = [
      ...state.channels.slice(0, index),
      ...state.channels.slice(index + 1)
    ]
    return { ...state, channels: newChannels }
  }
}

function indexForChannel (channels, channelName) {
  return channels.findIndex(channel => channel === channelName)
}

const initialState = {
  errors: null,
  connected: false,
  channels: []
}

export default function reducer (state = initialState, action) {
  const handler = SOCKETS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
