import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { history } from 'store/store';

import { LeadStatusTag } from 'modules/components';
import 'moment-timezone';
import moment from 'moment';
import styled from 'styled-components';

export default class LeadTableRow extends Component {
  viewProfileSelected = () => {
    const { lead } = this.props;
    history.push('/secure/manageLeads?lead_id=' + lead.id);
  };

  render() {
    const { lead, timezone } = this.props;
    const headAbout = lead.heard_about_method ? lead.heard_about_method.heard_about_title : '';
    const format = 'DD/MM/YYYY, h:mm a';

    return (
      <TableRow>
        <td>
          <button className="btn btn-sm btn-primary" onClick={this.viewProfileSelected} type="button">
            <i className="fa fa-user" />
          </button>
        </td>
        <td>{lead.display_name}</td>
        <td>{lead.gender}</td>
        <td>
          <LeadStatusTag leadStatus={lead.status} />
        </td>
        <td>{lead.location_name}</td>
        <td>{lead.salesperson_name}</td>
        <td>{lead.source}</td>
        <td>{headAbout}</td>
        <td>{lead.last_contacted && moment(lead.last_contacted).tz(timezone).format(format)}</td>
        <td>{lead.mobile_number}</td>
        <td>{lead.email}</td>
        <td>{lead.date_of_birth}</td>
        <td>{lead.address_line_one}</td>
        <td>{lead.address_line_two}</td>
        <td>{lead.city}</td>
        <td>{lead.state}</td>
        <td>{lead.country}</td>
        <td>{lead.zip_postcode}</td>
        <td>{moment(lead.created_at).tz(timezone).format(format)}</td>
      </TableRow>
    );
  }
}

LeadTableRow.propTypes = {
  lead: PropTypes.object.isRequired,
};

const TableRow = styled.tr``;
