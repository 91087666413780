import { combineReducers } from 'redux';
import filters from './modules/filters';
import leadsList from './modules/leadsList';
import leadDetail from 'modules/components/LeadDetail';

export default combineReducers({
  [filters.constants.NAME]: filters.reducer,
  [leadsList.constants.NAME]: leadsList.reducer,
  [leadDetail.constants.NAME]: leadDetail.reducer,
});
