import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-bootstrap-modal'
import SelectUserTableView from './TableView/SelectUserTableView'

import { ContentBlockView, EmptyView, TextSearchView, PagesView } from 'modules/components'
import SweetAlert from 'react-bootstrap-sweetalert'

import { SELECT_LOCATION_VIEW } from '../constants'

export class UserSelectView extends Component {

  state = {
    showAssignWarning: false
  }

  UNSAFE_componentWillMount () {
    const { setLastUsedLocationId, fetchOnLoad, locationId } = this.props
    setLastUsedLocationId(locationId)
    if (fetchOnLoad !== true) { return }
    this.getUsersWithPageNumber(1)
  }

  getUsersWithPageNumber = (pageNumber) => {
    const { getUsers, locationId } = this.props
    getUsers(pageNumber, null, locationId)
  }

  pageClicked = (pageNumber) => {
    this.getUsersWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getUsers, locationId } = this.props
    getUsers(1, searchTerm, locationId)
  }

  userClicked = (user, isAvailable) => {
    if (isAvailable) {
      this.assignUser(user)
      return
    } else {
      this.props.setSelectedUser(user)
      this.setState({ ...this.state, showAssignWarning: true })
    }
  }

  assignUser = (user) => {
    const { lead, assignLeadToUser, listUpdateProtocol, isFirstAssignment, locationId } = this.props
    assignLeadToUser(lead, user, listUpdateProtocol, locationId, isFirstAssignment)
    this.setState({ ...this.state, showAssignWarning: false })
  }

  confirmAssignUser = () => {
    const user = this.props.selectedUser
    this.assignUser(user)
  }

  showUserSelection = () => {
    this.props.setCurrentView(SELECT_LOCATION_VIEW)
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showAssignWarning: false })
  }

  render () {
    const { locationId, loading, pageDetails, users } = this.props

    return (
      <div>
        <ContentBlockView
          loading={loading}
          bordered>
          <div className='row' style={{ marginTop: '-20px' }}>
            <TextSearchView
              sectionName={'userSearch'}
              onUpdate={this.searchUpdated} />
          </div>
          {users.length > 0 &&
            <div>
              <SelectUserTableView
                users={users}
                locationId={locationId}
                onUserSelect={this.userClicked} />
            </div>
          }
          {users.length === 0 &&
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-magnifier'
              title='No results'
              subtitle='Check the search field' />
          }
          {pageDetails.pageCount > 1 &&
            <div className='text-center push'>
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked} />
            </div>
          }
        </ContentBlockView>
        <Modal.Footer>
          <button
            className='btn btn-default push-5-r push-10 pull-left'
            type='button'
            onClick={this.showUserSelection}>
            Change Location
          </button>
        </Modal.Footer>
        {this.state.showAssignWarning &&
          <div>
            <SweetAlert
              warning
              showCancel
              cancelBtnBsStyle='default'
              confirmBtnBsStyle='warning'
              confirmBtnText='Assign Lead'
              title='Are you Sure?'
              onConfirm={this.confirmAssignUser}
              onCancel={this.dismissPopups}>
              This salesperson isn't working right now. Do you still want to assign to them?
            </SweetAlert>
          </div>
        }
      </div>
    )
  }
}

UserSelectView.propTypes = {
  getUsers: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedUser: PropTypes.object,
  listUpdateProtocol: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  assignLeadToUser: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  isFirstAssignment: PropTypes.bool.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  fetchOnLoad: PropTypes.bool.isRequired,
  setLastUsedLocationId: PropTypes.func.isRequired
}

export default UserSelectView
