import * as actionTypes from './actionTypes'

const SELF_CALENDAR_ACTION_HANDLERS = {
  [actionTypes.SET_EVENTS]: (state, action) => {
    return { ...state, events: action.events }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.EVENT_SELECTED]: (state, action) => {
    return { ...state, selectedEventId: action.eventId }
  },
  [actionTypes.SET_DATE]: (state, action) => {
    return { ...state, date: action.date }
  },
  [actionTypes.SET_VIEW]: (state, action) => {
    return { ...state, view: action.view }
  },
  [actionTypes.ADD_EVENTS]: (state, action) => {
    const newEvents = [
      ...state.events,
      ...action.events
    ]
    return { ...state, events: newEvents }
  },
  [actionTypes.DELETE_EVENT]: (state, action) => {
    const index = state.events
    .findIndex(event => event.id === action.eventId)
    const newEvents = [
      ...state.events.slice(0, index),
      ...state.events.slice(index + 1)
    ]
    return { ...state, events: newEvents }
  },
  [actionTypes.UPDATE_EVENT]: (state, action) => {
    const index = state.events
    .findIndex(event => event.id === action.event.id)
    const newEvents = [
      ...state.events.slice(0, index),
      action.event,
      ...state.events.slice(index + 1)
    ]
    return { ...state, events: newEvents }
  }
}

const initialState = {
  events: [],
  date: new Date(),
  view: 'week',
  loading: false,
  selectedEventId: null
}

export default function reducer (state = initialState, action) {
  const handler = SELF_CALENDAR_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
