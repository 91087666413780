import { connect } from 'react-redux'

import SelectColorValue from '../components/SelectColorValue'
import Calendar from 'modules/components/Calendar'

import session from 'modules/Stores/Session'
import * as selectors from '../selectors'

function mapStateToProps (state, ownProps) {
  const { value } = ownProps
  const selectedUsers = selectors.getSelectedUsers(state)
  const usersToUse = (selectedUsers.length > 0) ? selectedUsers : [session.selectors.getUser(state)]
  const colors = Calendar.colors
  const sortedUserIds = usersToUse.map(user => user.id).sort()
  const index = sortedUserIds.findIndex(id => id === value.id)
  return {
    color: colors[index]
  }
}

export default connect(mapStateToProps)(SelectColorValue)
