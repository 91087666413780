import * as actionTypes from './actionTypes'
import { safePropertyForObject, safeRawPropertyForObject } from 'utils/formHelpers'

const EDIT_CALL_SCHEDULE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RELOAD_CALL_SCHEDULE]: (state, action) => {
    const callSchedule = action.callSchedule
    const data = formDataForCallSchedule(callSchedule)
    const locationIds = (callSchedule.filters.location_ids)
    ? callSchedule.filters.location_ids : []
    return {
      ...state,
      data: data,
      leadSourceIds: action.callSchedule.filters.lead_source_ids,
      selectedLocationIds: locationIds,
      errors: null
    }
  },
  [actionTypes.SET_LEAD_SOURCE_IDS]: (state, action) => {
    return { ...state, leadSourceIds: action.leadSourceIds }
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_SELECTED_LOCATION_IDS]: (state, action) => {
    return { ...state, selectedLocationIds: action.locationIds }
  },
  [actionTypes.LOCATION_SEARCH_TERM_UPDATED]: (state, action) => {
    return { ...state, locationSearchTerm: action.searchTerm, locationCurrentPage: 1 }
  },
  [actionTypes.LOCATION_CURRENT_PAGE_UPDATED]: (state, action) => {
    return { ...state, locationCurrentPage: action.pageNumer }
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForCallSchedule()
  }
}

function initialStateForCallSchedule (callSchedule) {
  const locationIds = (callSchedule && callSchedule.filters) ? callSchedule.filters.location_ids : []
  return {
    loading: false,
    errors: null,
    data: formDataForCallSchedule(callSchedule),
    leadSourceIds: [],
    selectedLocationIds: locationIds,
    locationSearchTerm: '',
    locationCurrentPage: 1
  }
}

function formDataForCallSchedule (callSchedule) {
  const idKey = 'id'
  const nameKey = 'name'
  const defaultKey = 'default'
  return {
    fields: {
      id: {
        value: safePropertyForObject(callSchedule, idKey),
        key: idKey,
        isValid: true
      },
      name: {
        value: safePropertyForObject(callSchedule, nameKey),
        key: nameKey,
        isValid: (safePropertyForObject(callSchedule, nameKey)) != null
      },
      isDefault: {
        value: safeRawPropertyForObject(callSchedule, defaultKey),
        key: defaultKey,
        isValid: true
      }
    }
  }
}

export default function reducer (state = initialStateForCallSchedule(), action) {
  const handler = EDIT_CALL_SCHEDULE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
