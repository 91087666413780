import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ConfigureForm } from './ConfigureForm';
import * as actions from 'modules/Helpers/IntegrationHelpers';
import { errorPanelForErrors } from 'utils/formHelpers';

import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-bootstrap-modal';

export class ConfigureModalView extends Component {
  state = {
    showDanger: false,
  };

  UNSAFE_componentWillMount() {
    if (this.props.selectedIntegration) {
      this.props.prefillFormForIntegration(this.props.selectedIntegration);
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedIntegration } = this.props;
    if (
      (!selectedIntegration && nextProps.selectedIntegration) ||
      (nextProps.selectedIntegration && selectedIntegration && selectedIntegration.id !== nextProps.selectedIntegration.id)
    ) {
      nextProps.prefillFormForIntegration(nextProps.selectedIntegration);
    }
    if (selectedIntegration && !nextProps.selectedIntegration) {
      nextProps.resetForm();
    }
  }

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true });
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false });
  };

  deleteIntegration = () => {
    this.dismissPopups();
    const { deleteIntegration, data, location } = this.props;
    deleteIntegration(data, location);
  };

  setUpIntegration = () => {
    const { addOrUpdateOptionWithData, data, location, name, direction } = this.props;
    addOrUpdateOptionWithData(data, location, name, direction);
  };

  hideModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const { loading, currentModal, modalName, errors, name, location } = this.props;

    const logo = actions.logoForOption(name);

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Let's setup {actions.titleForOption(name)}
              </h3>
            </div>
            <div className="block-content">
              <div className="form-horizontal push-5-t">
                {errorPanelForErrors(errors)}
                <ConfigureForm onInput={this.props.onInput} data={this.props.data} name={this.props.name} />
              </div>
              <div className="alert alert-warning alert-dismissable">
                <h4 className="font-w300 push-15">About {actions.titleForOption(name)}</h4>
                <p>{actions.detailForOption(name, location)}</p>
              </div>
              {logo && <img alt="" src={logo} style={{ width: '100px' }} />}
            </div>
          </div>
          <Modal.Footer>
            {this.props.data.fields.id.value && (
              <button className="btn btn-danger push-5-r push-10 pull-left" type="button" onClick={this.showDangerPopup}>
                Delete
              </button>
            )}
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModal}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r push-10"
              type="button"
              disabled={this.props.data.isValid !== true}
              onClick={this.setUpIntegration}
            >
              <i className="fa fa-plug" /> Set Up Integration
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showDanger && (
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="danger"
            confirmBtnText="Delete Integration"
            title="Are you Sure?"
            onConfirm={this.deleteIntegration}
            onCancel={this.dismissPopups}
          >
            This integration will no longer work
          </SweetAlert>
        )}
      </div>
    );
  }
}

ConfigureModalView.propTypes = {
  loading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  addOrUpdateOptionWithData: PropTypes.func.isRequired,
  prefillFormForIntegration: PropTypes.func.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  selectedIntegration: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentModal: PropTypes.string,
};

export default ConfigureModalView;
