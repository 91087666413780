import { request, GET, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';
import * as listingActions from '../../../listing/actions';

import notify from 'modules/Notifications';
import { trackEvent } from 'modules/Helpers/Analytics';

import modal from 'modules/Stores/Modal';

export const addOrUpdateOptionWithData = (formData, location, toTabIndex) => {
  return (dispatch) => {
    const body = {
      third_party_integration: seralizeOptionFromFormData(formData, 'perfect_gym', 'outgoing'),
    };
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setTabIndex(toTabIndex));

      if (formData.fields.id.value) {
        dispatch(listingActions.updateIntegration(payload.third_party_integration));
      } else {
        dispatch(listingActions.addIntegration(payload.third_party_integration));
      }
      dispatch(prefillFormForIntegration(payload.third_party_integration));
    }

    if (formData.fields.id.value) {
      return request('locations/' + location.id + '/third_party_integrations/' + formData.fields.id.value, PUT, body, onSuccess, onFailure);
    } else {
      return request('locations/' + location.id + '/third_party_integrations', POST, body, onSuccess, onFailure);
    }
  };
};

export const authenticateIntegration = (location, integrationId, username, password) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      username: username,
      password: password,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      const integration = payload.third_party_integration;
      if (integration.verified === true) {
        dispatch(setTabIndex(2));
        dispatch(listingActions.updateIntegration(integration));
        dispatch(prefillFormForIntegration(integration));
      } else {
        const errors = { errors: { not_verified: 'Username or password is incorrect' } };
        dispatch(modal.actions.showErrors(errors));
      }
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + integrationId + '/authenticate',
      POST,
      body,
      onSuccess,
      onFailure
    );
  };
};

export const deleteIntegration = (formData, location) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(listingActions.deleteIntegration(formData.fields.id.value));
      dispatch(reset());
      notify.success('Integration Removed');
      trackEvent('Integration Removed');
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + formData.fields.id.value,
      DELETE,
      null,
      onSuccess,
      onFailure
    );
  };
};

export const setPermissionForSet = (locationId, integrationId, permissionSetId, field, value) => {
  return (dispatch) => {
    const body = {
      integration_permission_set: {
        [field]: value,
      },
    };

    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setPermissionSet(payload.integration_permission_set));
    }
    const url =
      'locations/' + locationId + '/third_party_integrations/' + integrationId + '/integration_permission_sets/' + permissionSetId;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setPermissionSet(permissionSet) {
  return {
    type: actionTypes.SET_PERMISSION_SET,
    permissionSet,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.ON_INPUT,
    data,
  };
}

export function prefillFormForIntegration(integration) {
  return {
    type: actionTypes.PREFIL_FORM,
    integration,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setTabIndex(tabIndex) {
  return {
    type: actionTypes.SET_TAB_INDEX,
    tabIndex,
  };
}

export const getLocationsForPage = (location, integrationId, pageNumber) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setSiteLocations(payload.locations));
      dispatch(loadPageDetails(payload.meta));
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + integrationId + '/locations?page[number]=' + pageNumber,
      GET,
      null,
      onSuccess,
      onFailure
    );
  };
};

function setSiteLocations(siteLocations) {
  return {
    type: actionTypes.SET_SITE_LOCATIONS,
    siteLocations,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function seralizeOptionFromFormData(formData, name, direction) {
  const { customUrl, integrationLocationId } = formData.fields;

  let payload = { name: 'perfect_gym', direction: 'outgoing' };
  payload = newPayloadForFormValue(payload, integrationLocationId, 'integration_location_id');
  payload = newPayloadForFormValue(payload, customUrl, 'custom_url');

  return payload;
}
