import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CompareBySection from "../components/CompareBySection";

function mapStateToProps(state, props) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareBySection);
