import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SelectLocationTableRow } from './SelectLocationTableRow'

export class SelectLocationTableView extends Component {

  render () {
    const { locations, selectedLocation, onLocationSelect } = this.props
    const rows = locations.map((location, index) => {
      const selectedId = (selectedLocation) ? selectedLocation.id : null
      const selected = (selectedId === location.id)
      return <SelectLocationTableRow
        key={index}
        location={location}
        locationSelected={selected}
        onLocationSelect={onLocationSelect} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th>Name</th>
              <th className='text-center'>Postcode</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SelectLocationTableView.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLocation: PropTypes.object,
  onLocationSelect: PropTypes.func.isRequired
}

export default SelectLocationTableView
