import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import TableView from "./TableView";
import { EmptyView, PageSelection } from "modules/components";
import * as tooltips from "../tooltips";
import { RichToolTip } from "modules/components";
import { NO_CHANGE } from "../../../constants";

export class LeadsTableView extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.selectedCell) {
      return;
    } else if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.selectedCell !== this.props.selectedCell
    ) {
      const { getLeadsWithPages, queryString, selectedCell } = nextProps;
      getLeadsWithPages(queryString, selectedCell, 1);
    }
  }
  pageClicked = pageNumber => {
    const { getLeadsWithPages, queryString, selectedCell } = this.props;
    getLeadsWithPages(queryString, selectedCell, pageNumber);
  };

  render() {
    const {
      loading,
      leads,
      currentPage,
      pageCount,
      selectedCell,
      formattedTitle,
      timezone
    } = this.props;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">
            {formattedTitle}
            {selectedCell && selectedCell.status !== NO_CHANGE && (
              <TooltipContainer>
                <RichToolTip
                  text={tooltips.leadsTable(selectedCell.status)}
                  toptipId={`trial-lead-table-tooltip`}
                />
              </TooltipContainer>
            )}
          </h3>
        </div>
        <div className="block-content">
          {!selectedCell && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="fa fa-users"
              title="Select table cell to display leads"
            />
          )}
          {selectedCell && leads && leads.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="fa fa-users"
              title="No lead data for this period."
            />
          )}
          {selectedCell && leads && leads.length > 0 && (
            <TableView leads={leads} timezone={timezone} />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PageSelection
                pageCount={pageCount}
                currentPage={currentPage}
                pageClicked={this.pageClicked}
                pageLimit={20}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

LeadsTableView.propTypes = {
  queryString: PropTypes.string.isRequired,
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  selectedCell: PropTypes.object,
  getLeadsWithPages: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default LeadsTableView;

const TooltipContainer = styled.span`
  position: relative;
  top: -4px !important;
  font-size: 10px;
  margin-left: 2px;
`;
