import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CallScheduleView from '../components/CallScheduleView'

import callScheduleSelect from '../modules/callScheduleSelect'

function mapStateToProps (state) {
  return {
    selectedCallSchedule:  callScheduleSelect.selectors.selectedCallSchedule(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CallScheduleView)
