import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ChartView from './ChartView'
import { EmptyView } from 'modules/components'

export class GraphBreakdownView extends Component {

  UNSAFE_componentWillMount () {
    const { fetchWithQueryString, queryString, compareOption } = this.props
    fetchWithQueryString(queryString, compareOption)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.queryString !== this.props.queryString) ||
    (nextProps.compareOption !== this.props.compareOption)) {
      const { fetchWithQueryString, queryString, compareOption } = nextProps
      fetchWithQueryString(queryString, compareOption)
    }
  }

  render () {
    const { isEmpty, data, loading, labels } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <div className='block-header'>
          <h3 className='block-title'>Lost Reasons Breakdown</h3>
        </div>
        {isEmpty &&
          <div style={{ paddingTop: '60px' }}>
            <EmptyView
              colorThemeClass='primary'
              iconClass={'fa fa-bar-chart'}
              title='No data for these settings'
              subtitle='Update your filters or come back later.' />
          </div>
        }
        {!isEmpty &&
          <div className='block-content push-50' style={{ minHeight: '300px', maxHeight: '600px' }}>
            <ChartView data={data} labels={labels} />
          </div>
        }
      </div>
    )
  }
}

GraphBreakdownView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  compareOption: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  queryString: PropTypes.string
}

export default GraphBreakdownView
