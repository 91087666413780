import PropTypes from "prop-types";
import React, { Component } from "react";
import { isToday } from "modules/Helpers/DateHelpers";

import moment from "moment";

import * as helpers from "modules/Helpers/HistoryHelpers";

export default class HistoryViewItem extends Component {
  render() {
    const { historyItem, timezone } = this.props;
    const iconStyle =
      helpers.iconStyle(historyItem.record_type) +
      " list-timeline-icon " +
      helpers.bgStyle(historyItem.record_type);

    const createdAtMomentFromNow = moment(historyItem.created_at).fromNow();
    const createdAtMoment = moment(historyItem.created_at).tz(timezone);

    let date;
    let time;

    if (timezone) {
      date = createdAtMoment ? createdAtMoment.format("DD-MM-YY") : "";
      time = createdAtMoment ? createdAtMoment.format("h:mm a") : "";
    }

    return (
      <li>
        <div className="list-timeline-time">
          <div>{createdAtMomentFromNow}</div>
          {timezone && (
            <p>
              {time}
              {!isToday(createdAtMoment) && (
                <span>
                  <br />
                  {date}
                </span>
              )}
            </p>
          )}
        </div>
        <i className={iconStyle} />
        <div className="list-timeline-content">
          <p className="font-w600">{historyItem.title}</p>
          <p className="font-s13">{historyItem.detail}</p>
        </div>
      </li>
    );
  }
}

HistoryViewItem.propTypes = {
  historyItem: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
};
