import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HeaderImage from 'images/dashboard@2x.jpg';
import moment from 'moment';
import { PageHeader, QuickInfoBlock } from 'modules/components';

import WeeklyLeadSummaryReportContainer from '../containers/ComponentContainers/WeeklyLeadSummaryReportContainer';
import ManagerSalesTeamContainer from '../containers/ComponentContainers/ManagerSalesTeamContainer';
import QueuedLeads from '../modules/QueuedLeads';

export class ReceptionDashboardView extends Component {
  UNSAFE_componentWillMount() {
    const { location, fetchMetricsForLocation } = this.props;

    const startOfDay = moment().startOf('Day').utc().format();
    const endOfDay = moment().endOf('Day').utc().format();

    fetchMetricsForLocation(location, startOfDay, endOfDay);
  }

  render() {
    const { company, location, locationMetrics } = this.props;
    const title = 'Dashboard';
    const subtitle = "Here's the latest stats for " + company.title + ' ' + location.admin_display_name + '!';
    const leadCount = locationMetrics.new_leads_count ? locationMetrics.new_leads_count : 0;
    const unassignedLeads = locationMetrics.unassigned_leads_count ? locationMetrics.unassigned_leads_count : 0;
    const assignedLeads = locationMetrics.active_leads_count ? locationMetrics.active_leads_count : 0;
    const closedLeads = locationMetrics.closed_leads_count ? locationMetrics.closed_leads_count : 0;

    return (
      <div className="content content-boxed overflow-hidden">
        <PageHeader title={title} subtitle={subtitle} image={HeaderImage} />
        {location && (
          <div>
            <div className="row">
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock title={'NEW LEADS'} number={leadCount} icon={'si si-calendar'} iconTitle={'TODAY'} />
              </div>
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock title={'UNASSIGNED LEADS'} number={unassignedLeads} icon={'si si-calendar'} iconTitle={'TODAY'} />
              </div>
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock title={'ACTIVE LEADS'} number={assignedLeads} icon={'si si-calendar'} iconTitle={'TODAY'} />
              </div>
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock title={'CLOSED LEADS'} number={closedLeads} icon={'si si-calendar'} iconTitle={'TODAY'} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <WeeklyLeadSummaryReportContainer />
              </div>
              <div className="col-md-6">
                <QueuedLeads.Container />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <ManagerSalesTeamContainer />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ReceptionDashboardView.propTypes = {
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  locationMetrics: PropTypes.object.isRequired,
  fetchMetricsForLocation: PropTypes.func.isRequired,
  selectedUserId: PropTypes.number,
};
