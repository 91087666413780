import * as actionTypes from './actionTypes'

const SALES_PERFORMANCE_FUNNEL_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, data: action.data }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

const initialState = {
  errors: null,
  loading: false,
  data: {
    leads_created: { current: 0, change: 0 },
    leads_assigned: { current: 0, change: 0 },
    lead_assignments: { current: 0, change: 0 },
    leads_contacted: { current: 0, change: 0 },
    appointments_booked: { current: 0, change: 0 },
    presentations_made: { current: 0, change: 0 },
    leads_closed: { current: 0, change: 0 }
  }
}

export default function reducer (state = initialState, action) {
  const handler = SALES_PERFORMANCE_FUNNEL_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
