import PropTypes from "prop-types";
import React, { Component } from "react";
import { ToolTip } from "modules/components";

import session from "modules/Stores/Session";

import moment from "moment";
import "moment-timezone";

export class ImportRow extends Component {
  reverseSelected = () => {
    const { reverseImport, csvImport } = this.props;
    reverseImport(csvImport.id);
  };

  canReverse = () => {
    const { user, csvImport } = this.props;
    if (csvImport.status !== "complete") {
      return false;
    }
    if (
      csvImport.user.id !== user.id ||
      session.helpers.userRoleHasFranchiseOwnerPermissions(user.user_role) ===
        false
    ) {
      return false;
    }
    return true;
  };

  leadResult = () => {
    const { csvImport } = this.props;
    const complete = csvImport.status === "complete";

    if (complete && csvImport.update_leads) {
      return (
        <span>
          {csvImport.lead_count} new
          <br />
          {csvImport.updated_lead_count} updated
        </span>
      );
    } else if (complete && !csvImport.update_leads) {
      return <span>{csvImport.lead_count} new</span>;
    }
  };

  render() {
    const { csvImport, timezone } = this.props;
    const location = csvImport.location;

    let statusColor = "text-info";
    switch (csvImport.status) {
      case "pending":
        statusColor = "text-info";
        break;
      case "processing":
      case "reversing":
        statusColor = "text-primary";
        break;
      case "reversed":
        statusColor = "text-warning";
        break;
      case "failed":
        statusColor = "text-danger";
        break;
      case "complete":
        statusColor = "text-success";
        break;
      default:
        break;
    }

    return (
      <tr>
        <td>
          {moment(csvImport.created_at).tz(timezone).format("h:mm a")}
          <br />
          {moment(csvImport.created_at).tz(timezone).format("Do MMM YYYY")}{" "}
          {csvImport.csv_file_name && (
            <ToolTip
              text={csvImport.csv_file_name}
              id={`csvImportName-${csvImport.id}`}
            />
          )}
        </td>
        {location && <td>{location.admin_display_name}</td>}
        <td>{csvImport.user ? csvImport.user.display_name : "Unknown user"}</td>
        <td>
          <i className={"fa fa-circle " + statusColor} /> {csvImport.status}
        </td>
        <td>{this.leadResult()}</td>
        {csvImport.status === "reversed" && (
          <td>
            Reversed by {csvImport.reverse_user_name} at{" "}
            {moment(csvImport.reversed_at)
              .tz(timezone)
              .format("h:mm a, Do MMM YYYY")}
          </td>
        )}
        {this.canReverse() && (
          <td>
            {csvImport.update_leads && (
              <button
                className="btn btn-sm btn-danger"
                onClick={this.reverseSelected}
                disabled={csvImport.update_leads}
                title="Imports which update leads can not be reversed."
                type="button"
              >
                <i className="fa fa-cross" /> Reverse
              </button>
            )}
            {!csvImport.update_leads && (
              <button
                className="btn btn-sm btn-danger"
                onClick={this.reverseSelected}
                type="button"
              >
                <i className="fa fa-cross" /> Reverse
              </button>
            )}
          </td>
        )}
      </tr>
    );
  }
}

ImportRow.propTypes = {
  user: PropTypes.object.isRequired,
  csvImport: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  reverseImport: PropTypes.func.isRequired,
};

export default ImportRow;
