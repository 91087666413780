import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import FormView from '../components/SelectLocation/FormView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    data: selectors.getData(state),
    tabIndex: selectors.getTabIndex(state),
    bookerLocations: selectors.getSiteLocations(state),
    pageCount: selectors.getPageCount(state),
    currentPage: selectors.getCurrentPage(state),
    total: selectors.getTotal(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: actions.onInput,
    getLocationsForPage: actions.getLocationsForPage
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
