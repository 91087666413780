import React, { FC } from 'react';
import styled from 'styled-components';
import { IIntegrationPermissionSet, ILocation, IThirdPartyIntegration } from 'types';
import { Box, FormFields, ToolTip } from 'modules/components';
import * as strings from './constants';

const { Switch } = FormFields;

interface IPermissionsViewProps {
  data: any;
  setPermissionForSet: (id: number, integrationId: number, permissionSetId: number, fieldName: string | undefined, value: boolean) => void;
  location: ILocation;
  permissionSet?: IIntegrationPermissionSet;
  selectedIntegration: IThirdPartyIntegration;
  addOrUpdateOptionWithData: (formData: any, location: ILocation, toTabIndex: number | undefined, body: any) => void;
}

export const PermissionsView: FC<IPermissionsViewProps> = (props) => {
  const { selectedIntegration, permissionSet, data, setPermissionForSet, addOrUpdateOptionWithData, location } = props;

  const handleToggle = (fieldName: string, value: boolean) => {
    if (!permissionSet) return;
    const integrationId = data.fields.id.value;

    setPermissionForSet(location.id, integrationId, permissionSet.id, fieldName, value);
  };

  const handleIntegrationToggle = (fieldName: string, value: boolean) => {
    const body = {
      [fieldName]: value,
    };
    addOrUpdateOptionWithData({ fields: { id: { value: selectedIntegration.id } } }, location, undefined, body);
  };

  return (
    <div>
      <div className="row">
        <ToggleGroupHeadline>Integration permissions:</ToggleGroupHeadline>
        <Box className="col-md-6">
          <Switch
            name="export_lead"
            label={<SwitchLabel text="Export Lead" toolTipId="exportLead" toolTipText={strings.EXPORT_TOOLTIP} />}
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.export_lead}
          />
          <Switch
            name="sync_lead"
            label={<SwitchLabel text="Sync Lead" toolTipId="syncLead" toolTipText={strings.SYNCLEAD_TOOLTIP} />}
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.sync_lead}
          />
          <Switch
            name="staff_account"
            label={<SwitchLabel text="Staff Accounts" toolTipId="staffAccount" toolTipText={strings.STAFF_TOOLTIP} />}
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.staff_account}
          />
          {permissionSet?.staff_account && (
            <Switch
              name="contact_log_sync"
              label={<SwitchLabel text="Contact Log Sync" toolTipId="contactLogSync" toolTipText={strings.CONTACT_LOGSYNC} />}
              onChange={handleToggle}
              switchStyle="switch-sm switch-primary"
              value={permissionSet.contact_log_sync}
            />
          )}
          <Switch
            name="appointments_from_classes"
            label={
              <SwitchLabel text="Appointments from class timetable" toolTipId="appointments" toolTipText={strings.APPOINTMENTS_TOOLTIP} />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.appointments_from_classes}
          />
          <Switch
            name="manual_lead_import"
            label={<SwitchLabel text="Check for new leads" toolTipId="manualLeadSync" toolTipText={strings.MANUAL_LEAD_IMPORT} />}
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.manual_lead_import}
          />
          <Switch
            name="manual_lead_import_prospect_check"
            label={
              <SwitchLabel
                text="Import prospects only"
                toolTipId="manualLeadImportProspectCheck"
                toolTipText={strings.MANUAL_LEAD_IMPORT_PROSPECT_CHECK}
              />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.manual_lead_import_prospect_check}
          />
          {permissionSet?.appointments_from_classes && (
            <Switch
              name="classes_require_booking"
              label={
                <SwitchLabel
                  text="Class attendance requires a booking"
                  toolTipId="requireBooking"
                  toolTipText={strings.CLASSES_REQUIRE_BOOKING}
                />
              }
              onChange={handleToggle}
              switchStyle="switch-sm switch-primary"
              value={permissionSet.classes_require_booking}
            />
          )}
          <Switch
            name="lead_is_prospect"
            label={<SwitchLabel text="Flag lead as prospect" toolTipId="leadIsProspect" toolTipText={strings.LEAD_IS_PROSPECT} />}
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.lead_is_prospect}
          />
          <Switch
            name="ignore_location_id"
            label={
              <SwitchLabel
                text="Ignore MINDBODY home location ID"
                toolTipId="ignoreLocationId"
                toolTipText={strings.IGNORE_HOME_LOCATION}
              />
            }
            onChange={handleIntegrationToggle}
            switchStyle="switch-sm switch-primary"
            value={selectedIntegration?.ignore_location_id}
          />
        </Box>

        <Box className="col-md-6">
          <Switch
            name="collect_visits"
            label={<SwitchLabel text="Collect Visits" toolTipId="collectVisits" toolTipText={strings.COLLECT_VISITS_TOOLTIP} />}
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.collect_visits}
          />
          <Switch
            name="membership_cancellations"
            label={
              <SwitchLabel
                text="Membership Cancellations"
                toolTipId="membershipCancellation"
                toolTipText={strings.MEMBERSHIP_CANCELLATION_TOOLTIP}
              />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.membership_cancellations}
          />

          <Switch
            name="appointment_created"
            label={
              <SwitchLabel text="Appointment Created" toolTipId="appointmentCreated" toolTipText={strings.APPOINTMENT_CREATED_TOOLTOP} />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.appointment_created}
          />
          <Switch
            name="appointment_updated"
            label={
              <SwitchLabel text="Appointment Updated" toolTipId="appointmentUpdated" toolTipText={strings.APPOINTED_UPDATED_TOOLTIP} />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.appointment_updated}
          />
          <Switch
            name="appointment_cancelled"
            label={
              <SwitchLabel
                text=" Appointment Cancelled"
                toolTipId="appointmentCancelled"
                toolTipText={strings.APPOINTMENT_CANCELLED_TOOLTIP}
              />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.appointment_cancelled}
          />
          <Switch
            name="lead_membership_assigned"
            label={
              <SwitchLabel
                text="Membership Assigned"
                toolTipId="leadMembershipAssigned"
                toolTipText={strings.LEAD_MEMBERSHIP_ASSIGNED_TOOLTIP}
              />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.lead_membership_assigned}
          />
          <Switch
            name="sync_lead_from_class_event"
            label={
              <SwitchLabel
                text="Sync lead from bookings"
                toolTipId="syncLeadFromClassEvent"
                toolTipText={strings.SYNC_LEAD_FROM_CLASS_EVENT}
              />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.sync_lead_from_class_event}
          />
          <Switch
            name="update_lead_from_integration"
            label={
              <SwitchLabel
                text="Update lead details from MINDBODY"
                toolTipId="updateLeadFromIntegration"
                toolTipText={strings.UPDATE_LEAD_FROM_INTEGRATION}
              />
            }
            onChange={handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSet?.update_lead_from_integration}
          />
        </Box>
      </div>
    </div>
  );
};

interface ISwitchLabelProps {
  text: string;
  toolTipId: string;
  toolTipText: string;
}

const SwitchLabel: FC<ISwitchLabelProps> = (props) => {
  return (
    <span>
      {`${props.text} `}
      <ToolTip text={props.toolTipText} id={props.toolTipId} />
    </span>
  );
};

const ToggleGroupHeadline = styled.h5`
  margin-bottom: 15px;
  margin-left: 15px;
`;
