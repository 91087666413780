import PropTypes from "prop-types";
import React, { Component } from "react";

import { errorPanelForErrors } from "utils/formHelpers";

import { FormFields } from "modules/components";

import * as sessionHelpers from "modules/Stores/Session/helpers";
import TextDetailsContainer from "./TextDetailsContainer";
import { getPricingDetailsFromText } from "modules/Helpers/SmsHelpers";

import Modal from "react-bootstrap-modal";

import { SELECT_TEMPLATE_VIEW } from "./SendTextMessageModal";
const { SelectField } = FormFields;

const defaultState = {
  cursorPosition: 0,
  text: "",
  maxLength: null
};

export class SendTextMessageModal extends Component {
  state = defaultState;

  isDisabled = () => {
    const { text } = this.state;
    if (!text || text.length === 0) {
      return true;
    }
    return false;
  };

  sendTextMessage = () => {
    const { lead, sendTextMessageToLead } = this.props;
    sendTextMessageToLead(lead, null, this.state.text);
    this.setState(defaultState);
  };

  cleanAndHideModal = () => {
    this.props.hideModal();
  };

  pricingDetails = text => {
    const pricingDetails = getPricingDetailsFromText(text, this.props.smsCost);
    return pricingDetails;
  };

  updateText = event => {
    const textValue = event.target.value;
    const pricingDetails = this.pricingDetails(textValue);
    this.setState({
      text: textValue,
      maxLength: pricingDetails.maxLength
    });
  };

  onSelect = (name, value) => {
    const { text } = this.state;
    const { cursorPosition } = this.state;
    const newText =
      text.slice(0, cursorPosition) + value + text.slice(cursorPosition);
    this.setState({ text: newText });
  };

  onInputSelect = event => {
    this.setState({
      ...this.state,
      cursorPosition: event.target.selectionStart
    });
  };

  setTemplate = templateText => {
    this.setState({
      text: templateText
    });
  };

  render() {
    const { user, mergeTags, smsCost } = this.props;
    const { text } = this.state;
    const userCantEdit = !sessionHelpers.userHasOpenComms(user);
    const copy = userCantEdit
      ? "Select a template from the dropdown and press send."
      : "Enter your text message text in the box to the right. Select a merge tag to make it personalized." +
        "Leads feel more valued when you say their name in the message! You can also select a premade template.";

    return (
      <div className="block-content">
        <div className="row" style={{display: 'flex', flexWrap: 'wrap'}}>
          <div className="col-md-4 border-r">
            <p className="lead">
              Send {this.props.lead.given_name} a text message
            </p>
            <p className="text-muted">{copy}</p>
            <button
              className="btn btn-primary push-10 pull-left"
              onClick={() => this.props.setSelectedView(SELECT_TEMPLATE_VIEW)}
            >
              Select a template
            </button>
          </div>
          <div className="col-md-8 border-l">
            {errorPanelForErrors(this.props.errors)}
            <div>
              <div className="row push-10">
                <SelectField
                  id="personalization"
                  placeHolderText="Insert merge tag"
                  onChange={this.onSelect}
                  name="personalization"
                  initialValue={null}
                  formFieldStyle={"input-group"}
                  icon={"fa fa-user"}
                  possibleOptions={mergeTags}
                  disabled={userCantEdit}
                  displayFieldName="title"
                />
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <textarea
                    className="form-control"
                    id="message"
                    maxLength={this.state.maxLength}
                    onChange={this.updateText}
                    rows={6}
                    onSelect={this.onInputSelect}
                    disabled={userCantEdit}
                    value={text}
                  />
                </div>
              </div>
            </div>
            <TextDetailsContainer
              messageCost={smsCost}
              pricingDetails={this.pricingDetails(text)}
            />
          </div>
        </div>
        <Modal.Footer>
          <button
            className="btn btn-default push-5-r push-10"
            type="button"
            onClick={this.cleanAndHideModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-success push-5-r push-10"
            type="button"
            disabled={this.isDisabled()}
            onClick={this.sendTextMessage}
          >
            <i className="fa fa-comment-o" /> Send Text Message
          </button>
        </Modal.Footer>
      </div>
    );
  }
}

SendTextMessageModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  smsCost: PropTypes.number.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  sendTextMessageToLead: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentModal: PropTypes.string
};

export default SendTextMessageModal;
