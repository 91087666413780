import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap-modal';

import { StepView } from './StepView';

export class MoveLeadModal extends Component {
  stepSelected = (step) => {
    const { moveLeadToStep, lead, listUpdateProtocol } = this.props;
    moveLeadToStep(lead, lead.sales_funnel_step, step, listUpdateProtocol);
  };

  render() {
    const { lead, loading, currentModal, hideModal, modalName, salesFunnelSteps } = this.props;

    const stepsView = salesFunnelSteps.map((step, index) => {
      const isDisabled = (lead.sales_funnel_step && lead.sales_funnel_step.id === step.id) || false;
      return (
        <div key={index} className="col-sm-3">
          <StepView salesFunnelStep={step} onSelect={this.stepSelected} disabled={isDisabled} />
        </div>
      );
    });

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Which step should they be moved to?
              </h3>
            </div>
            <div className="block-content">
              <StepsContainer className="row">{stepsView}</StepsContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const StepsContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

MoveLeadModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  moveLeadToStep: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default MoveLeadModal;
