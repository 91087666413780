/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import EnterDetailsBodyContainer from "../containers/EnterDetailsBodyContainer";
import AuthenticateBodyContainer from "../containers/AuthenticateBodyContainer";
import SelectLocationBodyContainer from "../containers/SelectLocationBodyContainer";
import ConfirmBodyContainer from "../containers/ConfirmBodyContainer";
import { errorPanelForErrors } from "utils/formHelpers";

export class WizardView extends Component {
  state = {
    titles: [
      "1. Enter domain",
      "2. Log In",
      "3. Select location",
      "4. Confirm",
    ],
  };

  currentContainer = () => {
    const { tabIndex, location } = this.props;
    switch (tabIndex) {
      case 0:
        return <EnterDetailsBodyContainer />;
      case 1:
        return <AuthenticateBodyContainer />;
      case 2:
        return <SelectLocationBodyContainer location={location} />;
      case 3:
        return <ConfirmBodyContainer location={location} />;
      default:
        return <div />;
    }
  };

  render() {
    const { tabIndex, errors } = this.props;

    const tabs = this.state.titles.map((title, index) => {
      const activeClassName = tabIndex === index ? "active" : "";
      return (
        <li className={activeClassName} key={index}>
          <a style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
            {" "}
            {title}
          </a>
        </li>
      );
    });

    const width = (((tabIndex + 1) * 1.0) / 4) * 100;

    return (
      <div
        className="js-wizard-simple block"
        style={{
          marginTop: "-20px",
          marginLeft: "-20px",
          marginRight: "-20px",
        }}
      >
        <ul className="nav nav-tabs nav-justified">{tabs}</ul>

        <div
          className="form-horizontal"
          action="base_forms_wizard.html"
          method="post"
        >
          <div className="block-content block-content-mini block-content-full border-b">
            <div className="wizard-progress progress progress-mini remove-margin-b">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                style={{ width: width + "%" }}
              />
            </div>
          </div>

          <div className="block-content tab-content">
            {errorPanelForErrors(errors)}
            {this.currentContainer()}
          </div>
        </div>
      </div>
    );
  }
}

WizardView.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default WizardView;
