import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SELECT_LEAD_LOST_REASONS_MODAL } from "../constants";
import LeadLostReasonsSection from "../components/LeadLostReasonsSection";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  const leadLostReasonIdsName = "lead_lost_reason_ids";
  const leadLostReasonIdsData =
    props.filterData && props.filterData[leadLostReasonIdsName]
      ? props.filterData[leadLostReasonIdsName]
      : { fields: {} };
  const leadLostReasonsCount = Object.keys(leadLostReasonIdsData.fields).length;
  const clubsFiltersClass =
    leadLostReasonsCount > 0 ? "btn btn-info" : "btn btn-default";
  const clubTitle =
    leadLostReasonsCount > 0
      ? `${leadLostReasonsCount} selected`
      : "All lost reasons";

  return {
    SELECT_LEAD_SOURCES_MODAL: SELECT_LEAD_LOST_REASONS_MODAL,
    leadLostReasonIdsName: leadLostReasonIdsName,
    clubTitle: clubTitle,
    clubsFiltersClass: clubsFiltersClass,
    leadLostReasonIdsData: leadLostReasonIdsData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadLostReasonsSection);
