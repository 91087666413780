import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PageSelection } from 'modules/components';

import { TaskRow } from './TaskRow';
import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers';

export class TaskListView extends Component {
  UNSAFE_componentWillMount() {
    this.fetchTasksForPage(1);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.startDate !== this.props.startDate || nextProps.endDate !== this.props.endDate) {
      this.props.fetchInRange(nextProps.startDate, nextProps.endDate, nextProps.currentPage, nextProps.location);
    }
  }

  pageClicked = (pageNumber) => {
    this.fetchTasksForPage(pageNumber);
  };

  fetchTasksForPage = (pageNumber) => {
    const { fetchInRange, startDate, endDate, location } = this.props;
    fetchInRange(startDate, endDate, pageNumber, location);
  };

  markCompleteSelected = (task) => {
    const { completeTask, user } = this.props;
    completeTask(task, user);
  };

  render() {
    const { tasks, loading, totalCount, timezone, openLeadModal } = this.props;
    const { pageCount, currentPage } = this.props;
    const blockStyle = loading ? 'block block-bordered block-opt-refresh' : 'block block-bordered';
    const taskList = tasks.map((task, index) => {
      return (
        <TaskRow
          key={index}
          task={task}
          markCompleteSelected={this.markCompleteSelected}
          timezone={timezone}
          openLeadModal={openLeadModal}
        />
      );
    });

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">{pluralizationHelper('Tasks', totalCount)}</h3>
        </div>
        <div className="block-content">
          <div className="list-group">
            {taskList.length > 0 && <ul className="nav-users push">{taskList}</ul>}
            {taskList.length === 0 && (
              <div className="alert alert-success alert-dismissable">
                <p>
                  <i className="fa fa-check" /> No tasks
                </p>
              </div>
            )}
            {pageCount && currentPage && pageCount > 1 && (
              <div className="text-center push">
                <PageSelection pageCount={pageCount} currentPage={currentPage} pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TaskListView.propTypes = {
  loading: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  fetchInRange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  completeTask: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
};

export default TaskListView;
