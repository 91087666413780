import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';
import * as tableActions from '../table/actions';

export function updateFilters(filterData) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    filterData,
  };
}

export function clearAllFilters() {
  return {
    type: actionTypes.CLEAR_ALL_FILTERS,
  };
}

export function showFilters() {
  return {
    type: actionTypes.SHOW_FILTERS,
  };
}

export function hideFilters() {
  return {
    type: actionTypes.HIDE_FILTERS,
  };
}

export function setCompareOption(compareOption) {
  return {
    type: actionTypes.SET_COMPARE_VALUE,
    compareOption,
  };
}

export function changeCompareOption(compareOption) {
  return (dispatch) => {
    dispatch(tableActions.setQueued(false));
    dispatch(setCompareOption(compareOption));
  };
}

export function setCalculatingOption(calculatingOption) {
  return {
    type: actionTypes.SET_CALCULATING_VALUE,
    calculatingOption,
  };
}

export function showNoUserError(displayName) {
  return (dispatch) => {
    notify.error(displayName + ' is not a user in the selected clubs');
  };
}
