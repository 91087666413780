import * as actionTypes from './actionTypes'

const SCHEDULE_TASK_ACTION_HANDLERS = {
  [actionTypes.SET_TASK_DATE]: (state, action) => {
    return { ...state, startTime: action.startTime }
  },
  [actionTypes.SET_TASK_LENGTH]: (state, action) => {
    return { ...state, length: action.length }
  },
  [actionTypes.SET_TASK_TYPE]: (state, action) => {
    return { ...state, taskType: action.taskType }
  },
  [actionTypes.UPDATE_FILTER_DATA]: (state, action) => {
    return { ...state, data: action.filterData }
  },
  [actionTypes.SET_LEADS]: (state, action) => {
    return { ...state, leads: action.leads }
  },
  [actionTypes.SET_SELECTED_LEAD_ID]: (state, action) => {
    return { ...state, selectedLeadId: action.selectedLeadId }
  }
}

const initialState = {
  startTime: null,
  length: null,
  data: defaultLeadFormState(),
  selectedLeadId: null,
  leads: []
}

function defaultLeadFormState () {
  return {
    statuses: {
      fields: {
        active: { value: true, isValid: true }
      }
    }
  }
}

export default function reducer (state = initialState, action) {
  const handler = SCHEDULE_TASK_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
