import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { SELECT_LOCATION_MODAL } from '../constants'
import LocationSelect from 'modules/components/LocationSelect'

export class SelectLocationForm extends Component {

  locationSelected = (location) => {
    const { selectLocationId } = this.props
    selectLocationId(location)
    this.props.hideModal()
  }

  showSelectLocationsModal = () => {
    this.props.showModal(SELECT_LOCATION_MODAL)
  }

  render () {
    const { selectedLocationName, selectedLocationId } = this.props
    const buttonString = selectedLocationName || 'Select Location'
    return (
      <div className='row'>
        <div className='col-sm-12'>
          <h3 className='push-15'
            style={{ display: 'inline-block', fontSize: '13px' }}>
            Select A location to reallocate leads in *</h3><br />
          <button className='btn btn-default push-5-r push-10'
            style={{ display: 'inline-block' }}
            type='button'
            onClick={this.showSelectLocationsModal}>
            {buttonString}
          </button>
          <LocationSelect.LocationSelectModalContainer
            modalName={SELECT_LOCATION_MODAL}
            selectedLocationId={selectedLocationId}
            onLocationSelect={this.locationSelected} />
        </div>
      </div>
    )
  }
}

SelectLocationForm.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  selectLocationId: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
  selectedLocationName: PropTypes.string
}

export default SelectLocationForm
