import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getLawfulProcessingBases = () => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setLawfulProcessingBases(payload.lawful_processing_bases))
    }

    return request('lawful_processing_bases', GET, null, onSuccess, onFailure)
  }
}

function setLawfulProcessingBases (lawfulProcessingBases) {
  return {
    type: actionTypes.SET_LAWFUL_PROCESSING_BASES,
    lawfulProcessingBases
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function saveLawfulProcessingBasis (lawfulProcessingBasis) {
  return {
    type: actionTypes.LAWFUL_PROCESSING_BASIS_SAVED,
    lawfulProcessingBasis
  }
}

export function deleteLawfulProcessingBasisWithId (lawfulProcessingBasisId) {
  return {
    type: actionTypes.DELETE_LAWFUL_PROCESSING_BASIS_WITH_ID,
    lawfulProcessingBasisId
  }
}
