import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'

import SelectUsersView from '../components/SelectUsersView'
import { getTabIndex } from '../../../selectors'

import selectLocation from '../../selectLocation'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const activeTab = getTabIndex(state)
  const locationName = selectLocation.selectors.getLocationName(state)
  return {
    loading: selectors.getLoading(state),
    isActive: (activeTab === 1),
    fromUser: selectors.getFromUser(state),
    currentLocationName: locationName
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectUsersView)
