import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadStatusSection from 'modules/components/FilterFormSections/components/LeadStatusSection'

function mapStateToProps(state, props) {
  const leadStatusName = 'statuses';
  const leadStatusData = props.filterData && props.filterData[leadStatusName] ? props.filterData[leadStatusName] : { fields: {} };
  return {
    sectionName: leadStatusName,
    data: leadStatusData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadStatusSection);
