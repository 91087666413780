import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';
import userData from 'modules/Stores/UserData';

export const updateSalesFunnelWithData = (salesFunnel, salesFunnelData) => {
  return (dispatch) => {
    const salesFunnelJSON = seralizeSalesFunnelFromFormData(salesFunnelData);
    const body = {
      sales_funnel: salesFunnelJSON,
    };
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.sales_funnel));
      dispatch(userData.actions.getSalesFunnelSuccess(payload.sales_funnel));
      notify.success('Sales Funnel updated');
    }

    return request('sales_funnels/' + salesFunnel.id, PUT, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.UPDATING,
  };
}

function updateSuccessful() {
  return {
    type: actionTypes.UPDATE_SUCCESS,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function prefillFormForSalesFunnelSteps(salesFunnelSteps) {
  return {
    type: actionTypes.PREFILL_FORM_FOR_SALES_FUNNEL_STEPS,
    salesFunnelSteps,
  };
}

function seralizeSalesFunnelFromFormData(formData) {
  const { stepOne, stepTwo, stepThree, stepFour } = formData.fields;
  const salesFunnelJSON = {
    sales_funnel_steps_attributes: [
      salesFunnelStepJSONForData(stepOne),
      salesFunnelStepJSONForData(stepTwo),
      salesFunnelStepJSONForData(stepThree),
      salesFunnelStepJSONForData(stepFour),
    ],
  };
  return salesFunnelJSON;
}

function salesFunnelStepJSONForData(salesFunnelStepData) {
  const salesFunnelStepJSON = {};

  if (salesFunnelStepData.value) {
    salesFunnelStepJSON.name = salesFunnelStepData.value;
  }
  if (salesFunnelStepData.id) {
    salesFunnelStepJSON.id = salesFunnelStepData.id;
  }
  if (salesFunnelStepData.allowsClose !== undefined || salesFunnelStepData.allowsClose !== null) {
    salesFunnelStepJSON.allows_close = salesFunnelStepData.allowsClose;
  }

  return salesFunnelStepJSON;
}
