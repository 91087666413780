import { combineReducers } from 'redux';
import history from './modules/history';
import details from './modules/details';
import leadsTrials from './modules/leadsTrials';
import calls from './modules/calls';
import appointments from './modules/appointments';
import todos from './modules/todos';
import messages from './modules/messages';
import emails from './modules/emails';
import notes from './modules/notes';
import checkIns from './modules/checkIns';
import exportLead from './modules/exportLead';

export default combineReducers({
  [details.constants.NAME]: details.reducer,
  [history.constants.NAME]: history.reducer,
  [todos.constants.NAME]: todos.reducer,
  [messages.constants.NAME]: messages.reducer,
  [emails.constants.NAME]: emails.reducer,
  [notes.constants.NAME]: notes.reducer,
  [calls.constants.NAME]: calls.reducer,
  [appointments.constants.NAME]: appointments.reducer,
  [checkIns.constants.NAME]: checkIns.reducer,
  [exportLead.constants.NAME]: exportLead.reducer,
  [leadsTrials.constants.NAME]: leadsTrials.reducer,
});
