import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SelectSMSTemplateView from "./SelectSMSTemplateView";
import textMessageTemplates from "modules/Stores/TextMessageTemplates";
import * as apiActions from "modules/Stores/TextMessageTemplates/apiActions";

function mapStateToProps(state) {
  return {
    textMessageTemplates: textMessageTemplates.selectors.getTextMessageTemplates(
      state
    ),
    loading: textMessageTemplates.selectors.getLoading(state),
    errors: textMessageTemplates.selectors.getErrors(state),
    pageDetails: textMessageTemplates.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTextMessageTemplates: apiActions.getTextMessageTemplates
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSMSTemplateView);
