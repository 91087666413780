import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import NewCallScheduleModal from "../components/NewCallScheduleModal";
import modal from "modules/Stores/Modal";
import leadSources from "modules/Stores/LeadSources";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { NEW_CALL_SCHEDULE_MODAL } from "../constants";
import * as leadSourceApiActions from "modules/Stores/LeadSources/apiActions";

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: NEW_CALL_SCHEDULE_MODAL,
    leadSources: leadSources.selectors.getLeadSources(state),
    pageDetails: leadSources.selectors.getPageDetails(state),
    errors: selectors.getErrors(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadSources: leadSourceApiActions.getLeadSources,
      hideModal: modal.actions.hideModal,
      createCallSchedule: actions.createCallSchedule
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCallScheduleModal);
