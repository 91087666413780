import PropTypes from "prop-types";
import React, { Component } from "react";
import Table from "./Table";

export default class TableBreakdownView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, compareOption } = this.props;
    fetchWithQueryString(queryString, compareOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.compareOption !== this.props.compareOption
    ) {
      const { fetchWithQueryString, queryString, compareOption } = nextProps;
      fetchWithQueryString(queryString, compareOption);
    }
  }

  render() {
    const { data, loading } = this.props;
    const dataPresent = Object.keys(data).length > 0

    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Time Taken Breakdown</h3>
        </div>
        <div className="block-content block-content-full">
          {!loading && dataPresent && (
            <Table data={data} />
          )}
        </div>
      </div>
    );
  }
}

TableBreakdownView.propTypes = {
  fetchWithQueryString: PropTypes.func.isRequired,
  compareOption: PropTypes.string.isRequired,
  queryString: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.any,
  data: PropTypes.object.isRequired
};
