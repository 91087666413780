import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CallScheduleSelectModal from '../components/CallScheduleSelectModal'

import * as actions from '../actions'
import * as selectors from '../selectors'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  return {
    loading: selectors.getLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    selectedCallSchedule: selectors.getSelectedCallSchedule(state)
  }
}

function mapDispatchToProps (dispatch, props) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    reset: actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CallScheduleSelectModal)
