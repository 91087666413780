import { request, buildIndexUrl, GET, DELETE, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

import { formDataFromData } from 'utils/formHelpers';

export const getCustomMailMergeTags = (pageNumber, searchTerm = null) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setCustomMailMergeTags(payload.custom_mail_merge_tags));
      dispatch(setPageDetails(payload.meta));
    }

    const url = buildIndexUrl('custom_mail_merge_tags', pageNumber, null, searchTerm);
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const deleteCustomMailMergeTag = (customMailMergeTagId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(deleteCustomMailMergeTagWithId(customMailMergeTagId));
      notify.success('Custom Mail Merge Tag deleted');
    }

    const deleteString = 'custom_mail_merge_tags/' + customMailMergeTagId;
    return request(deleteString, DELETE, null, onSuccess, onFailure);
  };
};

export const createCustomMailMergeTag = (label, value, afterSuccess) => {
  return (dispatch) => {
    const body = {
      custom_mail_merge_tag: {
        label,
        value,
      },
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(saveCustomMailMergeTag(payload.custom_mail_merge_tag));
      if (afterSuccess) {
        afterSuccess();
      }
      notify.success('Custom Mail Merge Tag Created');
    }

    return request('custom_mail_merge_tags/', POST, body, onSuccess, onFailure);
  };
};

const fieldNames = ['label', 'value'];
export const updateCustomMailMergeTag = (customMailMergeTag, formData) => {
  return (dispatch) => {
    const body = {
      custom_mail_merge_tag: formDataFromData(formData, fieldNames),
    };

    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(saveCustomMailMergeTag(payload.custom_mail_merge_tag));
      notify.info('Custom Mail Merge Tag Updated');
    }
    const url = 'custom_mail_merge_tags/' + customMailMergeTag.id;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setCustomMailMergeTags(customMailMergeTags) {
  return {
    type: actionTypes.SET_CUSTOM_MAIL_MERGE_TAGS,
    customMailMergeTags,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function saveCustomMailMergeTag(customMailMergeTag) {
  return {
    type: actionTypes.CUSTOM_MAIL_MERGE_TAG_SAVED,
    customMailMergeTag,
  };
}

export function deleteCustomMailMergeTagWithId(customMailMergeTagId) {
  return {
    type: actionTypes.DELETE_CUSTOM_MAIL_MERGE_TAG_WITH_ID,
    customMailMergeTagId,
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
