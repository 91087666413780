import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getStartDate = state => state.dashboard[NAME].startDate
export const getEndDate = state => state.dashboard[NAME].endDate

export const getData = createSelector(
  [ getStartDate, getEndDate ],
  (startDate, endDate) => {
    return {
      fields: {
        created_after: { value: startDate, valid: true },
        created_before: { value: endDate, valid: true }
      }
    }
  }
)
