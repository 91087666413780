import { request, POST } from 'utils/apiUtils';
import { uploadFileToS3 } from 'utils/imageUpload';
import * as actionTypes from './actionTypes';

export const uploadCsvFile = (file, locationId, headers, salespersonMaps, statusMaps, lawfulProcessingBasesMaps, importOptions) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setSuccess(false));
      dispatch(setErrors(payload));
      dispatch(setLoading(false));
    }

    function onSuccess(uploadUrl, contentType) {
      dispatch(
        createCsvUpload(uploadUrl, contentType, locationId, headers, salespersonMaps, statusMaps, lawfulProcessingBasesMaps, importOptions)
      );
    }

    function onProgress(progress) {}

    return uploadFileToS3(file, onSuccess, onProgress, onFailure, 'import');
  };
};

const createCsvUpload = (
  s3URL,
  contentType,
  locationId,
  headers,
  salespersonMaps,
  statusMaps,
  lawfulProcessingBasesMaps,
  importOptions
) => {
  return (dispatch) => {
    const body = {
      csv_import: {
        headers: headers,
        location_id: locationId,
        salesperson_maps: salespersonMaps,
        status_maps: statusMaps,
        direct_upload_url: s3URL,
        csv_content_type: contentType,
        lawful_processing_bases_maps: lawfulProcessingBasesMaps,
        import_options: {
          update_leads: importOptions.updateLeads,
          trigger_automations: importOptions.enterAutomationFunnel,
          enter_matching_call_schedule: importOptions.enterMatchingCallSchedule,
        },
      },
    };

    function onFailure(payload) {
      dispatch(setSuccess(false));
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(data) {
      dispatch(setLoading(false));
      dispatch(setSuccess(true));
    }

    return request('csv_imports/', POST, body, onSuccess, onFailure);
  };
};

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setSuccess(success) {
  return {
    type: actionTypes.SET_SUCCESS,
    success,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
