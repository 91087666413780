import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { EmptyView, LeadCapsule } from 'modules/components';
import EmailMapResultRow from './EmailMapResultRow';

import moment from 'moment';
import 'moment-timezone';

export class EmailMapResultsView extends Component {
  render() {
    const { loading, emailMapSubmission, defaultLocation, lead, timezone } = this.props;
    const blockStyle = loading ? 'block-opt-refresh' : '';

    const emptySubtitle = "We'll show the latest email map results here. Check back once you've set this address up and sent a test";

    let result = emailMapSubmission ? emailMapSubmission.result : null;

    return (
      <div className={'block block-rounded block-bordered ' + blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Email Map Result</h3>
        </div>
        <div className="block-content">
          <div className="row">
            <div className="col-sm-12">
              {!emailMapSubmission && (
                <div style={{ paddingBottom: '30px' }}>
                  <EmptyView colorThemeClass="primary" iconClass="si si-drawer" title="No emails recieved" subtitle={emptySubtitle} />
                </div>
              )}
              {emailMapSubmission && (
                <div>
                  <h4 style={{ marginBottom: '10px' }}>Here is the latest email</h4>
                  <div className="row">
                    <div className="col-sm-12">
                      <p>
                        <span className={'font-w600'}>Sender</span>: {emailMapSubmission.sender}
                      </p>
                      <p>
                        <span className={'font-w600'}>Subject</span>: {emailMapSubmission.subject}
                      </p>
                      <p>
                        <span className={'font-w600'}>Recieved at</span>:{' '}
                        {moment(emailMapSubmission.created_at).tz(timezone).format('dddd MMMM Do, h:mm a')}
                      </p>
                      <p>
                        <span className={'font-w600'}>Email Body: </span>
                      </p>
                      <p>{emailMapSubmission.body}</p>
                    </div>
                  </div>
                  <h4 style={{ marginBottom: '10px', marginTop: '20px' }}>Here is what we could find:</h4>
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Field Name</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <EmailMapResultRow fieldName={'Given Name'} value={result.given_name} required />
                          <EmailMapResultRow fieldName={'Surname'} value={result.surname} />
                          <EmailMapResultRow fieldName={'Mobile Number'} value={result.mobile_number} />
                          <EmailMapResultRow fieldName={'Email'} value={result.email} />
                          <EmailMapResultRow fieldName={'Address'} value={result.address_line_one} />
                          <EmailMapResultRow fieldName={'City'} value={result.city} />
                          <EmailMapResultRow fieldName={'State'} value={result.state} />
                          <EmailMapResultRow fieldName={'Zip/Postcode'} value={result.zip_postcode} />
                          <EmailMapResultRow fieldName={'Gender'} value={result.gender} />
                          <EmailMapResultRow fieldName={'Notes'} value={result.notes} />
                          <EmailMapResultRow fieldName={'Appointment Time'} value={result.appointment_time} />
                          <tr>
                            <td className={'font-w600'}>Club</td>
                            {defaultLocation && <td>{defaultLocation.admin_display_name}</td>}
                            {!defaultLocation && <td className={'text-muted'}>None found - Used Default</td>}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {lead && (
                    <div className="row">
                      <div className="col-xs-6">
                        <LeadCapsule lead={lead} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailMapResultsView.propTypes = {
  getResultsForEmailMap: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  emailMapSubmission: PropTypes.object,
  defaultLocation: PropTypes.object,
  lead: PropTypes.object,
  errors: PropTypes.object,
};

export default EmailMapResultsView;
