import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SELECT_LEAD_SOURCES_MODAL } from "../constants";
import LeadSourcesSection from "../components/LeadSourcesSection";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  const leadSourceIdsName = "lead_source_ids";
  const leadSourceIdsData =
    props.filterData && props.filterData[leadSourceIdsName]
      ? props.filterData[leadSourceIdsName]
      : { fields: {} };
  const leadSourcesCount = Object.keys(leadSourceIdsData.fields).length;
  const clubsFiltersClass =
    leadSourcesCount > 0 ? "btn btn-info" : "btn btn-default";
  const clubTitle =
    leadSourcesCount > 0 ? `${leadSourcesCount} selected` : "All lead sources";

  return {
    SELECT_LEAD_SOURCES_MODAL: SELECT_LEAD_SOURCES_MODAL,
    leadSourceIdsName: leadSourceIdsName,
    clubTitle: clubTitle,
    clubsFiltersClass: clubsFiltersClass,
    leadSourceIdsData: leadSourceIdsData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadSourcesSection);
