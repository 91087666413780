import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MultiUserSelectDataModal from '../components/MultiUserSelectDataModal'

import * as actions from '../actions'
import * as selectors from '../selectors'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  return {
    users: selectors.getUsers(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch, props) {
  return bindActionCreators({
    getUsers: actions.get,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiUserSelectDataModal)
