import * as actionTypes from './actionTypes'

const SALES_PERFORMANCE_TABLE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_QUEUED]: (state, action) => {
    return { ...state, queued: action.queued }
  }
}

const initialState = {
  loading: false,
  queued: false
}

export default function reducer (state = initialState, action) {
  const handler = SALES_PERFORMANCE_TABLE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
