import * as actionTypes from './actionTypes'

const EDIT_SCHEDULE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    loading: false,
    errors: null,
  }
}

export default function (state = initialState(), action) {
  const handler = EDIT_SCHEDULE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
