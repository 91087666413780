import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

import { NAME } from './constants';

import teamMembers from './modules/teamMembers';
import invite from './modules/invite';
import permissions from './modules/permissions';
import schedule from './modules/schedule';
import salesTargets from './modules/salesTargets';
import addFromMindbody from './modules/addFromMindbody';

const SALES_TEAMS_ACTION_HANDELRS = {
  [actionTypes.LOCATION_SELECTED]: (state, action) => {
    return { ...state, selectedLocationId: action.location.id };
  },
};

const initialState = {
  selectedLocationId: null,
};

function reducer(state = initialState, action) {
  const handler = SALES_TEAMS_ACTION_HANDELRS[action.type];

  return handler ? handler(state, action) : state;
}

export default combineReducers({
  [NAME]: reducer,
  [teamMembers.constants.NAME]: teamMembers.reducer,
  [schedule.constants.NAME]: schedule.reducer,
  [invite.constants.NAME]: invite.reducer,
  [permissions.constants.NAME]: permissions.reducer,
  [addFromMindbody.constants.NAME]: addFromMindbody.reducer,
  [salesTargets.constants.NAME]: salesTargets.reducer,
});
