import * as actionTypes from './actionTypes'

const SELECT_CSV_ACTION_HANDLERS = {
  [actionTypes.CSV_WAS_SELECTED]: (state, action) => {
    const updatedCSV = { ...state.csv, file: action.file }
    return { ...state, csv: updatedCSV, errorString: null }
  },
  [actionTypes.LOCATION_SELECTED]: (state, action) => {
    return { ...state, locationId: action.location.id, locationName: action.location.admin_display_name }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  },
  [actionTypes.FILE_ERROR]: (state, action) => {
    return { ...state, errorString: action.errorString }
  },
  [actionTypes.SET_ENHANCED_PRIVACY]: (state, action) => {
    return { ...state, enhancedPrivacy: action.enhancedPrivacy }
  }
}

function initialState () {
  return {
    csv: {
      loading: false,
      file: null,
      progress: null
    },
    locationId: null,
    locationName: null,
    enhancedPrivacy: false,
    errorString: null
  }
}

export default function reducer (state = initialState(), action) {
  const handler = SELECT_CSV_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
