import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormFields } from "modules/components";
import { generateNewFormState } from "utils/formHelpers";
import { validator } from "../validators";
import moment from "moment";

const { TextField, Switch } = FormFields;

export class SecurityPolicyFormView extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onInput(newData);
  };

  handleHourInput = (fieldName, value, isValid) => {
    const convertedValue = value * 60 * 60;
    this.handleInput(fieldName, convertedValue, isValid);
  };

  secondToHours(seconds) {
    return seconds / 60 / 60;
  }

  handleTimeToggle = (fieldName, value) => {
    const dateValue = value ? moment() : null;
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      dateValue,
      true
    );
    this.props.onInput(newData);
  };

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      true
    );
    this.props.onInput(newData);
  };

  passwordComplexityEnabled = () => {
    const { passwordComplexityEnabledAt } = this.props.data.fields;
    return passwordComplexityEnabledAt.value !== null;
  };

  render() {
    const {
      minPasswordLength,
      minPasswordLetters,
      minPasswordNumbers,
      minPasswordSpecial,
      passwordCanContainUsername,
      disableUserAccountAfterDays,
      lockScreenAfter,
      maximumNumberOfAttempts,
      previousPasswordsToPrevent,
      allowRememberMe,
      rememberMeLength,
      passwordExpiresAfterMonths,
    } = this.props.data.fields;
    const passwordComplexityEnabled = this.passwordComplexityEnabled();
    return (
      <div>
        <h3 className="block-title">Password Complexity</h3>
        <Switch
          name="passwordComplexityEnabledAt"
          label="Enforce password complexity rules for your users passwords."
          onChange={this.handleTimeToggle}
          switchStyle="switch-sm switch-primary"
          value={passwordComplexityEnabled}
        />
        <div className="row">
          <div className="col-md-6">
            <TextField
              name="minPasswordLength"
              label="Minimum Password Length"
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={minPasswordLength.value}
              disabled={!passwordComplexityEnabled}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="minPasswordLetters"
              label="Minimum Password Letters"
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={minPasswordLetters.value}
              disabled={!passwordComplexityEnabled}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="minPasswordNumbers"
              label="Minimum Password Numbers"
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={minPasswordNumbers.value}
              disabled={!passwordComplexityEnabled}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="minPasswordSpecial"
              label="Minimum Password Special Characters"
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={minPasswordSpecial.value}
              disabled={!passwordComplexityEnabled}
            />
          </div>
          <div className="col-md-6">
            <Switch
              name="passwordCanContainUsername"
              label="Allow username in password"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={passwordCanContainUsername.value}
              disabled={!passwordComplexityEnabled}
            />
          </div>
        </div>
        <h3 className="block-title">Account Security</h3>
        <div className="row">
          <div className="col-md-6">
            <TextField
              name="disableUserAccountAfterDays"
              label="Disable accounts after..."
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={disableUserAccountAfterDays.value}
              formGroupIconElement={"Days"}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="lockScreenAfter"
              label="Lock screen after..."
              placeholder="15"
              onChange={this.handleInput}
              validator={validator}
              value={lockScreenAfter.value}
              formGroupIconElement={"Minutes"}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="maximumNumberOfAttempts"
              label="Lock account after incorrect login attempts"
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={maximumNumberOfAttempts.value}
              formGroupIconElement={"Failed Attempts"}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="previousPasswordsToPrevent"
              label="Disallow x previous passwords"
              placeholder="7"
              onChange={this.handleInput}
              validator={validator}
              value={previousPasswordsToPrevent.value}
              formGroupIconElement={"Previous Passwords"}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="passwordExpiresAfterMonths"
              label="Password expires after"
              placeholder="3"
              onChange={this.handleInput}
              validator={validator}
              value={passwordExpiresAfterMonths.value}
              formGroupIconElement={"Months"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Switch
              name="allowRememberMe"
              label="Enable remember me"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowRememberMe.value}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="rememberMeLength"
              label="Max amount of time to remember user"
              placeholder="7"
              onChange={this.handleHourInput}
              validator={validator}
              value={this.secondToHours(rememberMeLength.value)}
              formGroupIconElement={"Hours"}
              disabled={!allowRememberMe.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9">
            <button
              className="btn btn-primary push-5-r push-10"
              type="button"
              onClick={this.props.onSaveSelected}
            >
              <i className="fa fa-lock" /> Update Security Policy
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SecurityPolicyFormView.propTypes = {
  onSaveSelected: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default SecurityPolicyFormView;
