import { ISetActivityRecords, ISetLead } from 'types';
import { IClear } from 'types/interfaces/models';
import * as actionTypes from './actionTypes';
import { ILeadDetailsModalState, LeadDetailsReducerActionTypes } from './types';

const LEAD_DETAILS_MODAL_ACTION_HANDLERS = {
  [actionTypes.SET_LEAD]: (state: ILeadDetailsModalState, action: ISetLead) => {
    return { ...state, lead: action.lead };
  },
  [actionTypes.SET_ACTIVITY_RECORDS]: (state: ILeadDetailsModalState, action: ISetActivityRecords) => {
    return { ...state, activityRecords: action.activityRecords };
  },
  [actionTypes.RESET_STATE]: (state: ILeadDetailsModalState, action: IClear) => {
    return { ...initialState };
  },
};

const initialState: ILeadDetailsModalState = {
  lead: undefined,
  activityRecords: [],
};

export default function reducer(state = initialState, action: LeadDetailsReducerActionTypes) {
  const handler = LEAD_DETAILS_MODAL_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
