import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

import Modal from 'react-bootstrap-modal';

export class PersonalTaskModalView extends Component {
  state = {
    showDeleteWarning: false,
  };

  showDeleteWarningPopup = () => {
    this.setState({ ...this.state, showDeleteWarning: true });
  };

  deleteTask = () => {
    this.dismissPopups();
    this.props.deleteEvent(this.props.todo);
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showDeleteWarning: false });
  };

  render() {
    const { todo, currentModal, hideModal, modalName, loading } = this.props;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    return (
      <div>
        {todo && (
          <Modal show={currentModal === modalName} onHide={hideModal}>
            <div className={blockStyle}>
              <div className="block-header bg-primary">
                <ul className="block-options">
                  <li>
                    <button data-dismiss="modal" type="button" onClick={hideModal}>
                      <i className="si si-close" style={{ color: 'white' }} />
                    </button>
                  </li>
                </ul>
                <h3 className="block-title" style={{ color: 'white' }}>
                  Event details
                </h3>
              </div>
              <div className="block-content">
                <h3 className="font-w300 text-black">{todo.title}</h3>
                <h5>{todo.user.display_name}</h5>
                <p>
                  On <strong>{moment(todo.start || todo.due_date).format('dddd, MMMM Do YYYY')}</strong> at{' '}
                  <strong>{moment(todo.start || todo.due_date).format('h:mm a')}</strong>
                </p>
              </div>
            </div>
            <Modal.Footer>
              <button className="btn btn-danger pull-left" onClick={this.showDeleteWarningPopup}>
                <i className="fa fa-times" /> Delete
              </button>
              <button className="btn btn-primary push-5-r push-10" type="button" onClick={hideModal}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showDeleteWarning && (
          <div>
            <SweetAlert
              danger
              showCancel
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="danger"
              confirmBtnText="Delete event"
              title="Are you Sure?"
              onConfirm={this.deleteTask}
              onCancel={this.dismissPopups}
            >
              This event will be cleared from the calendar
            </SweetAlert>
          </div>
        )}
      </div>
    );
  }
}

PersonalTaskModalView.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  todo: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default PersonalTaskModalView;
