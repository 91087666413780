import PropTypes from 'prop-types';
import React, { Component } from 'react';

import 'moment-timezone';
import moment from 'moment';

import DateTimeSelect from 'modules/components/DateTimeSelect/DateTimeSelect';
import ScheduleCalendarContainer from 'modules/components/DateTimeSelect/containers/ScheduleCalendarContainer';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import STRINGS from 'strings';

export default class ScheduleCallModal extends Component {
  state = {
    dateTimeString: null,
    day: null,
    length: '5',
  };

  resetState = () => {
    this.setState({ dateTimeString: null, day: null, length: '5' });
  };

  hideModalAndCleanUp = () => {
    this.resetState();
    this.props.hideModal();
  };

  dateUpdated = (newDateTimeString, length) => {
    this.setState({
      ...this.state,
      dateTimeString: newDateTimeString,
      length: length,
    });
  };

  createCallReminder = () => {
    const { scheduleCall, lead, listUpdateProtocol } = this.props;
    const { dateTimeString } = this.state;
    const dateTimeMoment = moment(dateTimeString).utc();

    const utcDateTimeAsString = dateTimeMoment.utc().format();

    scheduleCall(lead, utcDateTimeAsString, listUpdateProtocol, this.state.length);
  };

  onDaySelect = (date) => {
    this.setState({ ...this.state, day: date.format() });
  };

  render() {
    const Strings = STRINGS.DateTime;
    const { currentModal, modalName, lead } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp} dialogClassName="modal-lg">
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              {Strings.whenDoYouWantToScheduleCall}
            </h3>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <DateTimeSelect onUpdate={this.dateUpdated} onDaySelect={this.onDaySelect} recomendedLength={this.state.length} />
            </div>
            <div
              className="col-md-4 hidden-xs hidden-sm"
              style={{
                padding: '0px',
                marginLeft: '-7px',
                height: '600px',
                width: '300px',
                overflow: 'scroll',
              }}
            >
              <ScheduleCalendarContainer
                onUpdate={this.dateUpdated}
                locationId={lead.location_id}
                date={this.state.day}
                user={lead.cached_salesperson}
              />
            </div>
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
            {STRINGS.Global.cancel}
          </button>
          <button
            className="btn btn-success push-5-r push-10"
            type="button"
            disabled={!this.state.dateTimeString}
            onClick={this.createCallReminder}
          >
            <i className="si si-call-out" /> {Strings.createFollowUpCall}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ScheduleCallModal.propTypes = {
  lead: PropTypes.object.isRequired,
  modalName: PropTypes.string.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  currentModal: PropTypes.string,
  //below props used in calendar container, accessed via owenProps
  eventTypes: PropTypes.array,
  usersIds: PropTypes.array,
  timezone: PropTypes.string,
};

ScheduleCallModal.defaultProps = {
  listUpdateProtocol: {},
};
