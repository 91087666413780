/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class CustomMailMergeTagRow extends Component {
  rowSelected = () => {
    const { customMailMergeTag, onSelect } = this.props;
    onSelect(customMailMergeTag);
  };

  render() {
    const { customMailMergeTag, selected } = this.props;
    const style = selected ? 'selected' : 'selectable';
    const customMailMergeTagStyle = selected ? 'text-white' : '';
    return (
      <a onClick={this.rowSelected} className={'list-group-item ' + style}>
        <span className={customMailMergeTagStyle}>{customMailMergeTag.label}</span>:{' '}
        <span className={customMailMergeTagStyle}>{customMailMergeTag.value}</span>
      </a>
    );
  }
}

CustomMailMergeTagRow.propTypes = {
  customMailMergeTag: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default CustomMailMergeTagRow;
