import PropTypes from "prop-types";
import React from "react";
import ExportButtonContainer from "modules/components/ExportButton/ExportButtonContainer";
import styled from "styled-components";

const ReportHeaderView = ({
  generateFullReport,
  queryString,
  queued,
  title,
}) => {
  return (
    <div className="row items-push">
      <div className="col-md-12">
        <ExportButtonContainer
          generateFullReport={generateFullReport}
          queryString={queryString}
          queued={queued}
          reportType={"leads_report"}
          showExcel
        />
        <h1 className="page-heading push-10">{title}</h1>
        <BottomBorder />
      </div>
    </div>
  );
};

export default ReportHeaderView;

ReportHeaderView.propTypes = {
  title: PropTypes.string.isRequired,
  queued: PropTypes.bool.isRequired,
  generateFullReport: PropTypes.func.isRequired,
  queryString: PropTypes.string,
};

const BottomBorder = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 2px;
`;
