import PropTypes from 'prop-types'
import React, { Component } from 'react'
import HistoryViewItem from './HistoryViewItem'

import { PageSelection } from 'modules/components'

export class HistoryView extends Component {

  render () {
    const { historyItems, pageCount, currentPage, pageClicked, timezone } = this.props

    const historyItemsView = historyItems.map((historyItem, index) => {
      return (
        <HistoryViewItem key={index} historyItem={historyItem} timezone={timezone} />
      )
    })

    return (
      <div>
        <ul className='list list-timeline pull-t'>
          {historyItemsView}
        </ul>
        {pageCount && currentPage && pageClicked && pageCount > 1 &&
          <div className='text-center push'>
            <PageSelection
              pageCount={pageCount}
              currentPage={currentPage}
              pageClicked={pageClicked} />
          </div>
        }
      </div>
    )
  }
}

HistoryView.propTypes = {
  historyItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageClicked: PropTypes.func,
  timezone: PropTypes.string.isRequired
}

export default HistoryView
