import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SELECT_USERS_MODAL } from '../constants'
import UsersSection from '../components/UsersSection'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  const userIdsName = "user_ids";  
  const userIdData = props.filterData && props.filterData[userIdsName] ? props.filterData[userIdsName] : { fields: {} };
  const usersCount = Object.keys(userIdData.fields).length;
  const usersTitle = usersCount > 0 ? `${usersCount} selected` : "All users";
  const usersFiltersClass = usersCount > 0 ? "btn btn-info" : "btn btn-default";

  const locationIdsName = "location_ids";
  const locationIdsData = props.filterData && props.filterData[locationIdsName] ? props.filterData[locationIdsName] : { fields: {} }
  const locationIds = Object.keys(locationIdsData.fields).map(id => parseInt(id))

  return {
    SELECT_USERS_MODAL: SELECT_USERS_MODAL,
    userIdsName: userIdsName,
    usersCount: usersCount,
    usersTitle: usersTitle,
    usersFiltersClass: usersFiltersClass,
    userIdData: userIdData,
    locationIds: locationIds
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersSection)
