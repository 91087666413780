import { combineReducers } from 'redux';

import selectSms from './modules/selectSms';
import account from './modules/account';
import charges from './modules/charges';
import topUp from './modules/topUp';

export default combineReducers({
  [selectSms.constants.NAME]: selectSms.reducer,
  [topUp.constants.NAME]: topUp.reducer,
  [account.constants.NAME]: account.reducer,
  [charges.constants.NAME]: charges.reducer,
});
