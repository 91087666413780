import { connect } from 'react-redux'
import EmailHistoryView from '../components/EmailHistoryView'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import modal from 'modules/Stores/Modal'
import { MODAL_NAME } from '../constants'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    mailEvents: selectors.getMailEvents(state),
    pageDetails: selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: MODAL_NAME
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchEventsForEmail: actions.fetchEventsForEmail,
    hideModal: modal.actions.hideModal,
    reset: actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailHistoryView))
