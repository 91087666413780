import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import creditCardModal from '../../creditCardModal'

function mapStateToProps (state) {
  return {
    errors: modal.selectors.getModalErrors(state),
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    data: creditCardModal.selectors.getData(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    resetForm: creditCardModal.actions.resetForm,
    createCreditCardRecord: creditCardModal.actions.createCreditCardRecord,
    onInput: creditCardModal.actions.onInput
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(creditCardModal.components.CreditCardModalView)
