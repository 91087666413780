import React, { Component } from 'react';
import styled from 'styled-components';

export class SubscriptionsTableView extends Component {
  subscriptionRows = () => {
    const { subscriptions, selectedSubscription, selectSubscription } = this.props;
    const selectedSubscriptionId = selectedSubscription ? selectedSubscription.id : null;

    return subscriptions.map((subscription, index) => {
      const selected = subscription.id === selectedSubscriptionId;
      return <SubscriptionRow key={index} subscription={subscription} selected={selected} selectSubscription={selectSubscription} />;
    });
  };

  render() {
    const { subscriptions } = this.props;

    return (
      <Container className="row">
        <Container className="col-sm-12">
          <Headline>Calendly Subscriptions:</Headline>
          {subscriptions && subscriptions.length > 0 && (
            <table className="table table-vcenter table-hover">
              <tbody>{this.subscriptionRows()}</tbody>
            </table>
          )}
        </Container>
      </Container>
    );
  }
}

export default SubscriptionsTableView;

const SubscriptionRow = ({ subscription, selected, selectSubscription }) => {
  const backgroundClass = selected ? 'bg-primary' : '';
  const textClass = selected ? 'text-white' : '';
  return (
    <tr className={textClass + ' selectable ' + backgroundClass} onClick={() => selectSubscription(subscription)}>
      <td>{subscription.name}</td>
      <td>
        {subscription.verified ? (
          <Label className={'label label-success'}>Verified</Label>
        ) : (
          <Label className={'label label-danger'}>Invalid</Label>
        )}
      </td>
      <td>{subscription.version}</td>
    </tr>
  );
};

const Container = styled.div``;
const Label = styled.span``;

const Headline = styled.h5`
  margin-bottom: 15px;
`;
