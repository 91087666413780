import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLocationId = state => state.reallocations[NAME].locationId
export const getLocationName = state => state.reallocations[NAME].locationName

export const isReady = createSelector(
  [ getLocationId ],
  (locationId) => {
    if (!locationId) return false
    return true
  }
)
