import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const fetchHistoryForLeadAndPageNumber = (lead, pageNumber) => {
  return (dispatch) => {
    dispatch(fetchingHistory())

    function onFailure (errors) {
      dispatch(fetchHistoryFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(fetchHistorySuccess(payload.lead_activity_records))
      dispatch(loadPageDetails(payload.meta))
    }

    const pageNumberQuery = '?page[number]=' + pageNumber
    return request('leads/' + lead.id + '/lead_activity_records' + pageNumberQuery, GET, null, onSuccess, onFailure)
  }
}

function fetchingHistory () {
  return {
    type: actionTypes.FETCHING_HISTORY
  }
}

function fetchHistorySuccess (activityRecords) {
  return {
    type: actionTypes.FETCHING_HISTORY_SUCCESS,
    activityRecords
  }
}

function fetchHistoryFailed (errors) {
  return {
    type: actionTypes.FETCHING_HISTORY_FAILURE,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function addHistoryItem (historyItem) {
  return {
    type: actionTypes.ADD_HISTORY_ITEM,
    historyItem
  }
}
