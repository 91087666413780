import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function SubscriptionAddOnTableRow ({ subscriptionAddOn }) {
  return (
    <TableRow>
      <TableData>{subscriptionAddOn.location.display_name}</TableData>
      <TableData>{subscriptionAddOn.title}</TableData>
      <TableData>${subscriptionAddOn.amount}</TableData>
    </TableRow>
  );
}

SubscriptionAddOnTableRow.propTypes = {
  subscriptionAddOn: PropTypes.object.isRequired
};

export default SubscriptionAddOnTableRow;

const TableRow = styled.tr``;
const TableData = styled.td``;
