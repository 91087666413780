import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectCSVFormContainer from '../containers/SelectCSVFormContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

export class SelectCSVView extends Component {

  state = {
    showLocationWarning: false
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.enhancedPrivacy === true && nextProps.enhancedPrivacy !== this.props.enhancedPrivacy) {
      this.showPopup()
    }
  }

  showPopup = () => {
    this.setState({ ...this.state, showLocationWarning: true })
  }

  dismissPopup = () => {
    this.setState({ ...this.state, showLocationWarning: false })
  }

  render () {
    const { isActive, errorString } = this.props
    const activeClassName = (isActive) ? 'active in' : ''
    return (
      <div>
        <div className={'tab-pane fade fade-up push-50 ' + activeClassName}>
          <div className='row'>
            <div className='col-md-12'>
              <h4>Import Leads via CSV</h4>
              <p>Import your existing leads into {this.props.productName} by selecting a CSV file.<br />
              After selecting the file and clicking next, you will be able to configure the import settings</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              {errorString &&
                <p className='text-danger'>
                  {errorString}
                </p>
              }
              <SelectCSVFormContainer />
            </div>
          </div>
        </div>
        {this.state.showLocationWarning &&
          <div>
            <SweetAlert
              warning
              cancelBtnBsStyle='default'
              confirmBtnBsStyle='warning'
              confirmBtnText='I Understand'
              title={'Enhanced Privacy Requirements'}
              onConfirm={this.dismissPopup}>
              You are importing leads into a location with enhanced privacy. Your import{' '}
              will need to contain a column that shows the lawful reason to process their data.{' '}
              You should also include an opted in date if they have previously opted in to recieve{' '}
              marketing materials, however you must have a record of proof of their opt in.
            </SweetAlert>
          </div>
        }
      </div>
    )
  }
}

SelectCSVView.propTypes = {
  isActive: PropTypes.bool.isRequired,
  enhancedPrivacy: PropTypes.bool,
  errorString: PropTypes.string
}

export default SelectCSVView
