import PropTypes from "prop-types";
import React, { Component } from "react";
import "./UserRoleTag.css";

export class EditableUserRoleTag extends Component {
  roleSelected = (event) => {
    const { onChange, userRoles } = this.props;
    const id = event.target.value;
    const index = userRoles.findIndex(
      (role) => parseInt(role.id) === parseInt(id)
    );
    onChange(userRoles[index]);
  };

  styleForRoleName(roleName) {
    switch (roleName) {
      case "Owner":
      case "Admin":
        return "info";
      case "Salesmanager":
        return "warning";
      case "Salesperson":
        return "success";
      case "Reception":
        return "default";
      default:
        return "info";
    }
  }

  render() {
    const { userRole, userRoles } = this.props;

    const roles = userRoles.map((userRole, index) => {
      return (
        <option key={index} value={userRole.id}>
          {userRole.name}
        </option>
      );
    });

    return (
      <div className="user-role-tag">
        {/* <span className={'label label-' + this.styleForRoleName(userRole.name)} onClick={this.showOptions}>
          {userRole.name + ' '} <i className='si si-pencil' />
        </span> */}
        <select
          className="form-control"
          id={"userRoleSelect"}
          value={userRole.id}
          name={"userRole"}
          size={roles.count}
          onChange={this.roleSelected}
        >
          {roles}
        </select>
      </div>
    );
  }
}

EditableUserRoleTag.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRole: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditableUserRoleTag;
