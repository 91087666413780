import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TrialsSelectSection from "modules/components/FilterFormSections/components/TrialsSelectSection";
import trials from 'modules/Stores/Trials'


function mapStateToProps(state, props) {
  return {
    trials: trials.selectors.getTrials(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrialsSelectSection);
