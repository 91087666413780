import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextMessageItem } from './TextMessageItem'
import { SEND_TEXT_MODAL } from '../../../../../constants'
import { EmptyView } from 'modules/components'
import PagesContainer from '../containers/PagesContainer'

export class TextMessagesView extends Component {

  componentDidMount () {
    this.getTextMessagesWithPageNumber(1)
  }

  pageClicked = (pageNumber) => {
    this.getTextMessagesWithPageNumber(pageNumber)
  }

  getTextMessagesWithPageNumber = (pageNumber) => {
    const { lead, fetchForLead } = this.props
    fetchForLead(lead, pageNumber)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { lead } = this.props
    if (lead.id !== nextProps.lead.id) {
      nextProps.fetchForLead(nextProps.lead, 1)
    }
  }

  addTextMessage = () => {
    this.props.showModal(SEND_TEXT_MODAL)
  }

  render () {
    const { textMessages, lead, loading } = this.props

    const messagesList = textMessages.map((textMessage, index) => {
      return (
        <TextMessageItem key={index} textMessage={textMessage} lead={lead} />
      )
    })

    return (
      <div className='push-20'>
        {loading &&
          <div className='row'>
            <div className='col-sm-6 col-sm-offset-3 text-center'>
              <h3 className='push-10'>Loading Text Messages</h3>
              <i className='fa fa-2x fa-sun-o fa-spin' />
            </div>
          </div>
        }
        {!loading &&
          <div>
            {textMessages.length > 0 &&
              <div>
                <button className='btn btn-success push-5-r push-10' type='button' onClick={this.addTextMessage} >
                  <i className='fa fa-plus' /> Send text message
                </button>
                <ul className='list list-simple'>
                  {messagesList}
                </ul>
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            }
            {textMessages.length === 0 &&
              <EmptyView
                colorThemeClass='primary'
                iconClass='si si-speech'
                title='No text messages yet'
                subtitle={(lead.mobile_number) ? null : 'Add a mobile number to send a text message'}
                buttonText={(lead.mobile_number) ? 'Send a text message' : null}
                buttonSelected={(lead.mobile_number) ? this.addTextMessage : null} />
            }
          </div>
          }
      </div>
    )
  }
}

TextMessagesView.propTypes = {
  textMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchForLead: PropTypes.func.isRequired
}
