import { isURL, isPresent } from 'utils/validation'

export const GymDetailsFormValidator = {
  'title': [{
    rule: isPresent,
    errorMessage: 'A company name is required'
  }],
  'url': [{
    rule: isURL,
    errorMessage: 'That doesn\'t seem to be a URL'
  }]
}
