import React, { FC } from "react"

type ModalHeaderProps = {
    hideModal: () => void
}

const ModalHeader: FC<ModalHeaderProps> = (props) => {
    return (
        <div className="block-header bg-primary">
            <ul className="block-options">
                <li>
                    <button data-dismiss="modal" type="button" onClick={props.hideModal}>
                        <i className="si si-close" style={{ color: 'white' }} />
                    </button>
                </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
                Event details
            </h3>
        </div>
    )
}


export default ModalHeader
