import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import appointments from '../modules/appointments'
import modal from 'modules/Stores/Modal'
import classes from 'modules/Stores/Classes'

function mapStateToProps (state, ownProps) {
  const { pathToLeadDetail } = ownProps
  const { classIntegrationId, classIntegrationName } = classes.selectors.locationUsesClassesFactory(ownProps.lead.location_id)(state)
  return {
    loading: appointments.selectors.getLoading(state, pathToLeadDetail),
    classIntegrationId: classIntegrationId,
    classIntegrationName: classIntegrationName,
    currentModal: modal.selectors.getCurrentModal(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleCompleteForAppointment: appointments.actions.toggleComplete,
    deleteAppointmentForLead: appointments.actions.deleteAppointment,
    syncIntegrationAppointments: appointments.actions.syncIntegrationAppointments,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(appointments.components.AppointmentsView)
