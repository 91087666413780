import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import locations from 'modules/Stores/Locations'
import * as locationSelectors from '../../../selectors'
import * as selectors from '../selectors'
import * as actions from '../actions'

import OpeningHoursView from '../components/OpeningHoursView'

function mapStateToProps(state) {
  const selectedLocationId = locationSelectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    errors: selectors.getErrors(state),
    loading: selectors.getLoading(state),
    location: selectedLocation
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateOpeningHours: actions.updateOpeningHours
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OpeningHoursView)
