export function iconForNotification (notification) {
  const owner = notification.owner
  if (!owner) { return 'si si-bell bg-info' }

  switch (owner.notification_type) {
    case 'lead assignment':
      return 'si si-user-follow bg-success'
    case 'facebook message':
      return 'fa fa-facebook bg-default'
    case 'lead email':
      return 'si si-envelope bg-info'
    case 'lead text message':
      return 'si si-bubbles bg-info'
    case 'lead timeout':
      return 'si si-user-unfollow bg-danger'
    case 'mindbody user':
      return 'si si-lock bg-danger'
    default:
      return 'si si-bell bg-info'
  }
}
