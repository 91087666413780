import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LocationTableRow } from './LocationTableRow'

export class LocationTableView extends Component {

  render () {
    const { locations, locationSelected, selectedLocationId, tableHead,
      showSaleDetails, renderRow } = this.props
    const rows = locations.map((location, index) => {
      const selected = (location.id === selectedLocationId)
      if (renderRow) {
        return renderRow(location, selected)
      }
      return <LocationTableRow
        key={index}
        showSaleDetails={showSaleDetails}
        location={location}
        locationSelected={locationSelected}
        selected={selected} />
    })

    return (
      <div style={{ marginTop: '-20px' }}>
        <table className='table table-vcenter table-hover'>
          {tableHead &&
            tableHead
          }
          {!tableHead &&
            <thead>
              <tr>
                <th>Name</th>
                {showSaleDetails &&
                  <th className='text-center'>Active Leads</th>
                }
              </tr>
            </thead>
          }
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

LocationTableView.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func,
  tableHead: PropTypes.element,
  locationSelected: PropTypes.func.isRequired,
  showSaleDetails: PropTypes.bool,
  selectedLocationId: PropTypes.number
}

LocationTableRow.defaultProps = {
  showSaleDetails: true
}

export default LocationTableView
