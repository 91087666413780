import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import FiltersView from '../components/FiltersView'
import FilterFormSections from 'modules/components/FilterFormSections'
import * as tableSelectors from '../../reportsTable/selectors'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(selectors.getData)
  return {
    automaticReport: tableSelectors.getSelectedAutomaticReport(state),
    queryString: queryStringSelector(state),
    filterData: selectors.getData(state),
    compareOption: selectors.getCompareOption(state),
    calculatingOption: selectors.getCalculatingOption(state),
    loading: selectors.getLoading(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateFilters: actions.updateFilters,
    setFilters: actions.setFilters,
    setCompareOption: actions.setCompareOption,
    setCalculatingOption: actions.setCalculatingOption,
    updateAutomaticReportFilters: actions.updateAutomaticReportFilters
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersView)