import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getErrors = (state) => state[NAME].errors;
export const getUser = (state) => state[NAME].user;
export const getInvite = (state) => state[NAME].invite;
export const getLoading = (state) => state[NAME].loading;
export const getToken = () => queryParams.token;

export const inviteIsInvalid = createSelector([getErrors], (errors) => {
  if (!errors || errors.length === 0) {
    return false;
  }
  if (!errors.errors || errors.errors.length === 0) {
    return false;
  }
  const keys = Object.keys(errors.errors);
  let isInvalid = keys.includes('not_found');
  isInvalid = isInvalid || keys.includes('revoked');
  return isInvalid;
});

var queryParams = window.location.search
  .substr(1)
  .split('&')
  .reduce(function (q, query) {
    var chunks = query.split('=');
    var key = chunks[0];
    var value = chunks[1];
    // eslint-disable-next-line
    return (q[key] = value), q;
  }, {});
