import { request, POST, GET } from "utils/apiUtils";
import { startSession } from "modules/Stores/Session/actions";

// ------------------------------------
// Constants
// ------------------------------------
export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const WHITELABEL_PROFILE = "WHITELABEL_PROFILE";

// ------------------------------------
// Actions
// ------------------------------------
export const login = (username, password, rememberMe) => {
  return (dispatch) => {
    dispatch(loggingIn());
    const body = {
      auth: {
        username: username,
        password: password,
        remember_me: rememberMe,
      },
    };

    function onFailure(errors) {
      dispatch(loginFailure(errors));
    }

    function onSuccess(payload) {
      dispatch(startSession(payload));
      dispatch(loginSuccess());
    }

    return request("sessions", POST, body, onSuccess, onFailure);
  };
};

function loggingIn() {
  return {
    type: LOGGING_IN,
  };
}

function loginSuccess() {
  return {
    type: LOGIN_SUCCESS,
  };
}

function loginFailure(errors) {
  return {
    type: LOGIN_FAILURE,
    errors,
  };
}

export const actions = {
  login,
};

export const fetchWhitelabelProfileLogo = (productName) => {
  return (dispatch) => {
    function onFailure(payload) {
      // dispatch(setErrors(payload.errors))
    }

    function onSuccess(payload) {
      dispatch(setWhitelabelProfileLogo(payload));
    }

    const url = `whitelabel_profile_logo?client=${productName}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setWhitelabelProfileLogo(whitelabelProfileLogo) {
  return {
    type: WHITELABEL_PROFILE,
    whitelabelProfileLogo,
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const LOGIN_ACTION_HANDLERS = {
  [LOGGING_IN]: (state) => {
    return { ...state, loading: true, errors: null };
  },
  [LOGIN_SUCCESS]: (state, action) => {
    return { ...state, loading: false, success: true, errors: null };
  },
  [LOGIN_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      success: false,
      errors: action.errors.errors,
    };
  },
  [WHITELABEL_PROFILE]: (state, action) => {
    return {
      ...state,
      whitelabelProfile: action.whitelabelProfileLogo.whitelabel_profile,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  success: null,
  errors: null,
  whitelabelProfile: null,
};
export default function loginReducer(state = initialState, action) {
  const handler = LOGIN_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
