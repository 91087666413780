import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectDateRangeButton from 'modules/components/SelectDateRangeButton/SelectDateRangeButton'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'

function mapStateToProps (state) {
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    timezone: session.selectors.getUserTimezone(state),
    updatedAfterTitle: 'status_updated_after',
    updatedBeforeTitle: 'status_updated_before'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDateRangeButton)
