import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { errorPanelForErrors } from 'utils/formHelpers'

import EditLawfulProcessingBasisFormContainer from '../containers/EditLawfulProcessingBasisFormContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

export class EditLawfulProcessingBasisView extends Component {

  state = {
    showWarning: false
  }

  componentDidMount () {
    const { lawfulProcessingBasis, prefilFormData } = this.props
    if (lawfulProcessingBasis) {
      prefilFormData(lawfulProcessingBasis)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentLawfulProcessingBasisId = (this.props.lawfulProcessingBasis)
    ? this.props.lawfulProcessingBasis.id : null
    const nextLawfulProcessingBasisId = (nextProps.lawfulProcessingBasis)
    ? nextProps.lawfulProcessingBasis.id : null
    if (nextLawfulProcessingBasisId !== currentLawfulProcessingBasisId) {
      nextProps.prefilFormData(nextProps.lawfulProcessingBasis)
    }
  }

  saveLawfulProcessingBasis = () => {
    const { saveLawfulProcessingBasis, data, lawfulProcessingBasis } = this.props
    const id = (lawfulProcessingBasis) ? lawfulProcessingBasis.id : null
    saveLawfulProcessingBasis(id, data)
  }

  deleteSelected = () => {
    this.setState({ ...this.state, showWarning: true })
  }

  dismissWarning = () => {
    this.setState({ ...this.state, showWarning: false })
  }

  deleteLawfulProcessingBasis = () => {
    const { deleteLawfulProcessingBasis, lawfulProcessingBasis, changeShowing, reset } = this.props

    deleteLawfulProcessingBasis(lawfulProcessingBasis.id)
    changeShowing(false)
    reset()
    this.setState({ ...this.state, showWarning: false })
  }

  render () {
    const { loading, lawfulProcessingBasis, errors } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const title = (lawfulProcessingBasis) ? 'Update existing lawful processing basis'
    : 'Create new lawful processing basis'

    return (
      <div className={'block block-rounded block-bordered ' + blockStyle} >
        <div className='block-header'>
          {this.props.lawfulProcessingBasis && !lawfulProcessingBasis.locked &&
            <div className='block-options-simple'>
              <button type='button' className='btn btn-xs btn-danger' onClick={this.deleteSelected}>
                <i className='fa fa-times' />{' '}Delete lawful processing basis
              </button>
            </div>
          }
          <h3 className='block-title'>{title}</h3>
        </div>
        <div className='block-content'>
          {errorPanelForErrors(errors)}
          <div className='row'>
            <div className='col-sm-12'>
              <EditLawfulProcessingBasisFormContainer
                lawfulProcessingBasis={lawfulProcessingBasis} />
            </div>
          </div>
          <div className='push-20 push-10-t row'>
            <div className='col-md-12'>
              <button type='button'
                className='btn btn-primary'
                onClick={this.saveLawfulProcessingBasis}
                disabled={(this.props.data.isValid !== true)}>
                <i className='fa fa-save' /> Save Lawful Processing Basis
              </button>
            </div>
          </div>
        </div>
        {this.state.showWarning &&
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='danger'
            title='Are you Sure?'
            confirmBtnText='Delete this lawful processing basis'
            onConfirm={this.deleteLawfulProcessingBasis}
            onCancel={this.dismissWarning}>
            This lawful processing basis will be deleted. You won't be able to track it anymore
          </SweetAlert>
        }
      </div>
    )
  }
}

EditLawfulProcessingBasisView.propTypes = {
  saveLawfulProcessingBasis: PropTypes.func.isRequired,
  prefilFormData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  lawfulProcessingBasis: PropTypes.object,
  deleteLawfulProcessingBasis: PropTypes.func.isRequired,
  changeShowing: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default EditLawfulProcessingBasisView
