/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectGenderModal from "./SelectGenderModal";
import "../../LeadMoreDetail.css";
import { GENDER_MODAL } from "../../../constants";

export class GenderPanel extends Component {
  genderSelected = () => {
    this.props.showModal(GENDER_MODAL);
  };

  icon = () => {
    if (this.props.lead.gender) {
      return (
        <i className="si si-check fa-2x text-muted pull-right text-success" />
      );
    }
    return (
      <i className="si si-question fa-2x text-muted pull-right text-warning" />
    );
  };

  genderText = () => {
    const { lead } = this.props;
    if (!lead.gender) {
      return (
        <div>
          <span className="h4 text-muted">
            {"Set " + lead.given_name + "'s "}{" "}
          </span>
          <span className="h4 font-w700">gender</span>
        </div>
      );
    }
    return (
      <div>
        <span className="h4 text-muted">{lead.given_name + " is "} </span>
        <span className="h4 font-w700">{lead.gender}</span>
      </div>
    );
  };

  render() {
    return (
      <div>
        <a
          className="block block-link-hover2 block-bordered selectable"
          onClick={this.genderSelected}
        >
          <div className="block-content block-content-full clearfix">
            {this.icon()}
            {this.genderText()}
          </div>
        </a>
        <SelectGenderModal {...this.props} modalName={GENDER_MODAL} />
      </div>
    );
  }
}

GenderPanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
};
