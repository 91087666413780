import * as actionTypes from "./actionTypes";

import {
  safeRawPropertyForObject,
  safeBooleanPropertyForObject,
} from "utils/formHelpers";

const COMMUNICATION_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM]: (state, action) => {
    return initialStateForUser(action.user);
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    const newData = action.data;
    return { ...state, data: newData };
  },
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  },
};

function initialStateForUser(user) {
  const showOwnDataByDefaultId = "show_own_data_by_default";

  return {
    errors: null,
    loading: false,
    data: {
      id: safeRawPropertyForObject(user, "id"),
      fields: {
        showOwnDataByDefault: {
          id: showOwnDataByDefaultId,
          value: safeBooleanPropertyForObject(user, showOwnDataByDefaultId),
          isValid: true,
        },
      },
    },
  };
}

export default function reducer(state = initialStateForUser({}), action) {
  const handler = COMMUNICATION_PREFERENCES_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
