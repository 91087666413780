import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Reports from 'modules/components/Reports'
import UserData from 'modules/Stores/UserData'

import { getReportSummaryData, getLoading } from '../selectors'

function mapStateToProps (state) {
  const sortedFlatHeadersAndData = Reports.selectors.sortedFlatHeadersAndDataFactory(getReportSummaryData)
  const flatSortedData = sortedFlatHeadersAndData(state)

  const dataIsEmpty = Reports.selectors.dataIsEmptyFactory(getReportSummaryData)
  return {
    title: 'Allocation Summary',
    data: flatSortedData.counts,
    loading: getLoading(state),
    labels: flatSortedData.headers,
    isEmpty: dataIsEmpty(state),
    dataSetTitle: UserData.selectors.companyName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports.components.BarChartView)
