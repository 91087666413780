import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TagsSection2 from 'modules/components/FilterFormSections/components/TagsSection2'
import Tags from 'modules/components/Tags'

function mapStateToProps (state, props) {
  return {
    suggestions: Tags.selectors.getPopularTags(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsSection2)
