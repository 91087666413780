import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components';
import StepNumberView from 'modules/components/StepNumberView/StepNumberView'
import { toolTipSelector } from "../tooltips";


export class WalkInsView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, calculatingOption } = this.props;
    fetchWithQueryString(queryString, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.queryString !== this.props.queryString) ||
    (nextProps.calculatingOption !== this.props.calculatingOption)) {
      const { fetchWithQueryString, queryString, calculatingOption } = nextProps;
      fetchWithQueryString(queryString, calculatingOption);
    }
  }

  render () {
    const { data, loading, calculatingOption } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    const tooltips = toolTipSelector(calculatingOption)
    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Walk Ins</h3>
        </div>
        <BlockContent className='block-content'>
          <div className='row'>
            <div className='col-sm-4'>
              <StepNumberView title='Walk ins'
                number={data.walk_in_count.current}
                percentage={data.walk_in_count.change}
                tooltipId={'walkinCount'}
                tooltipText={tooltips.walkinCount} />
            </div>
            <div className='col-sm-4'>
              <StepNumberView title='Walk in sales'
                number={data.walk_in_sales.current}
                percentage={data.walk_in_sales.change}
                tooltipId={'walkinSales'}
                tooltipText={tooltips.walkinSales} />
            </div>
            <div className='col-sm-4'>
              <StepNumberView title='Walk in close ratio'
                number={data.walk_in_close_rate.current * 100}
                suffix={'%'}
                percentage={data.walk_in_close_rate.change}
                tooltipId={'walkinClose'}
                tooltipText={tooltips.walkinClose} />
            </div>
          </div>
        </BlockContent>
      </div>
    )
  }
}

WalkInsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string
}

export default WalkInsView

const BlockContent = styled.div`
  padding-bottom: 40px;
`;