import * as actionTypes from './actionTypes'

export function selectLocation (location) {
  return dispatch => Promise.all([
    dispatch(locationSelected(location))
  ])
}

export function locationSelected (location) {
  return {
    type: actionTypes.LOCATION_SELECTED,
    location
  }
}
