import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import salesPerformanceFunnel from '../modules/salesPerformanceFunnel'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    loading: salesPerformanceFunnel.selectors.getLoading(state),
    data: salesPerformanceFunnel.selectors.getData(state),
    errors: salesPerformanceFunnel.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    calculatingOption: filters.selectors.getCalculatingOption(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: salesPerformanceFunnel.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
  salesPerformanceFunnel.components.SalesPerformanceFunnelView)
