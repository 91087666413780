import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import contacts from '../modules/contacts'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    loading: contacts.selectors.getLoading(state),
    data: contacts.selectors.getData(state),
    errors: contacts.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    calculatingOption: filters.selectors.getCalculatingOption(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: contacts.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(contacts.components.ContactsView)
