import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from 'styled-components';
import {
  idForSalesFunnelStepLink,
  displayNameForSalesFunnelStepLink
} from "../helpers";
import Select from "react-select";
import "react-select/dist/react-select.css";

export default class StepTransitionSections2 extends Component {
  fieldPrefix() {
    return this.props.sectionName + "/";
  }

  handleUpdate = event => {
    const { onUpdate, sectionName } = this.props
    var newTags = {};
    event.forEach(tag => (newTags[tag.value] = tag));
    const newData = { fields: newTags };
    onUpdate(newData, sectionName);
  };

  generateStepTransitionOptions = () => {
    const { salesFunnelStepLinks } = this.props
    return salesFunnelStepLinks.map(salesFunnelStepLink => {
      return {
        value: idForSalesFunnelStepLink(salesFunnelStepLink),
        label: displayNameForSalesFunnelStepLink(salesFunnelStepLink)
      };
    });
  };

  searchTagFromData = stepTransitionOptions => {
    const { data } = this.props;
    const ids = Object.keys(data.fields);
    return stepTransitionOptions.filter(link => ids.includes(link.value));
  };

  render() {
    const stepTransitionOptions = this.generateStepTransitionOptions();
    const tags = this.searchTagFromData(stepTransitionOptions);

    return (
      <Container>
        <Select
        multi={true}
        options={stepTransitionOptions}
        value={tags}
        onChange={event => this.handleUpdate(event)}
      />
      </Container>

    );
  }
}

StepTransitionSections2.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  salesFunnelStepLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Container = styled.div`
  text-align: left;
`;