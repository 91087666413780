import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { HistoryView } from 'modules/components'

export class LeadHistoryView extends Component {

  pageClicked = (pageNumber) => {
    const { fetchHistoryForLeadAndPageNumber, lead } = this.props
    fetchHistoryForLeadAndPageNumber(lead, pageNumber)
  }

  render () {
    const { loading, timezone, history, pageCount, currentPage } = this.props
    return (
      <div className='push-20'>
        {loading &&
          <div className='row'>
            <div className='col-sm-6 col-sm-offset-3 text-center'>
              <h3 className='push-10'>Loading History</h3>
              <i className='fa fa-2x fa-sun-o fa-spin' />
            </div>
          </div>
        }
        {!loading &&
          <HistoryView
            pageClicked={this.pageClicked}
            historyItems={history}
            pageCount={pageCount}
            currentPage={currentPage}
            timezone={timezone} />
        }
      </div>
    )
  }
}

LeadHistoryView.propTypes = {
  history: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  lead: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  fetchHistoryForLeadAndPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired
}
