import React, { useEffect } from 'react';
import { userCanAccessBilling } from 'modules/Stores/Session/helpers';
import IdleTimer from './IdleTimer';

import FloatingAddButton from './FloatingAddButton';

import 'react-select/dist/react-select.css';

import { PrivateRouteContainer } from 'modules/components';

//TODO: Re-impllement analytic actions
import * as analyticActions from 'modules/Helpers/Analytics';

import Dashboard from 'containers/Dashboard';
import ManageLeads from 'containers/ManageLeads';
import Calendar from 'containers/Calendar';
import Settings from 'containers/Settings';

import ReportListing from 'containers/Reports/Listing';
import * as reports from 'containers/Reports/Reports';

const reportKeys = Object.keys(reports);

export const SecureView = (props) => {
  const {
    user,
    company,
    intercomHash,
    loadingComplete,
    showModal,
    currentModal,
    sessionLoading,
    timeout,
    switchingAppName,
    goToSignup,
  } = props;
  const switchingApp = switchingAppName !== null;
  const showSecureView = loadingComplete === true && sessionLoading === false;
  const startTimerOnLoad = timeout !== 0;

  useEffect(() => {
    if (user.registration_status === 'setting_up') {
      goToSignup();
      return;
    }

    props.loadInitialStateForCurrentUser();
    if (userCanAccessBilling(user)) {
      props.loadBillingForCurrentUser();
    }
    props.loadDefaultSmsCost();
    props.getTagOptions();
    props.clearAttemptedUrl();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((user, company, intercomHash)) {
      analyticActions.startUserSession(user, company, intercomHash);
    }
  }, [user, company, intercomHash]);

  const lockScreen = () => {
    props.logout('Timed out due to inactivity');
  };

  return (
    <div>
      {switchingApp && <div id="page-loader" style={{ display: 'block' }} />}
      {showSecureView && startTimerOnLoad && (
        <IdleTimer startOnLoad={true} timeout={timeout * 60 * 1000} idleAction={lockScreen}>
          <Routes showModal={showModal} currentModal={currentModal} />
        </IdleTimer>
      )}
      {showSecureView && !startTimerOnLoad && <Routes showModal={showModal} currentModal={currentModal} />}

      {!showSecureView && <div id="page-loader" style={{ display: 'block' }} />}
    </div>
  );
};

const Routes = (props) => {
  const { showModal, currentModal } = props;
  return (
    <div style={{ marginBottom: '100px' }}>
      <PrivateRouteContainer exact path="/secure/dashboard" component={Dashboard.Container} />
      <PrivateRouteContainer exact path="/secure/manageLeads" component={ManageLeads.Container} />
      <PrivateRouteContainer exact path="/secure/calendar" component={Calendar.Container} />
      <PrivateRouteContainer exact path="/secure/reports" component={ReportListing.Container} />

      {reportKeys.map((key) => {
        const reportRoute = reports[key];
        return (
          <PrivateRouteContainer key={key} exact path={`/secure/reports/${reportRoute.constants.NAME}`} component={reportRoute.Container} />
        );
      })}

      <PrivateRouteContainer path="/secure/settings" component={Settings.SettingsContainer} />
      <FloatingAddButton showModal={showModal} currentModal={currentModal} />
    </div>
  );
};
