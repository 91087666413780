import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { QueuedLeadRow } from './QueuedLeadRow'
import { EmptyView, PageSelection } from 'modules/components'

import AssignLead from 'modules/components/AssignLead'

import { ASSIGN_MODAL_NAME } from '../constants'

export class QueuedLeadsView extends Component {

  leadRowSelected = (lead) => {
    this.props.selectLead(lead)
    this.props.showModal(ASSIGN_MODAL_NAME)
  }

  componentDidMount () {
    this.pageClicked(1)
  }

  pageClicked = (pageNumber) => {
    const { fetchLeadsWithPageNumber, location } = this.props
    fetchLeadsWithPageNumber(location, pageNumber)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const location = this.props.location
    const nextLocation = nextProps.location

    if (location.id !== nextLocation.id) {
      nextProps.setLocationId(nextLocation.id)
    }
  }

  render () {
    const { loading, pageCount, leads, selectedLead, listUpdateProtocol } = this.props

    const blockStyle = loading ? 'block block-opt-refresh'
    : 'block block-rounded block-bordered'

    const leadsView = leads.map((lead, index) => {
      return (
        <QueuedLeadRow
          key={index}
          lead={lead}
          leadSelected={this.leadRowSelected} />
      )
    })

    const leadsHeaderView = () =>
      <thead>
        <tr>
          <th className='text-center' style={{ width: '12px' }}>
            <i className='si si-user' />
          </th>
          <th>Name</th>
          <th style={{ width: '25%' }}>Time Inquired</th>
          <th style={{ width: '25%' }}>Inquiry source</th>
          <th />
          <th />
        </tr>
      </thead>

    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Unassigned Leads</h3>
        </div>
        <div style={{ height: '316px' }}>
          <div style={{ height: '316px', overflowY: 'auto', width: 'auto', overflowX: 'hidden' }}>
            {leads.length > 0 &&
              <div className='table-responsive'>
                <table className='table table-hover table-vcenter'>
                  {leadsHeaderView()}
                  <tbody>
                    {leadsView}
                  </tbody>
                </table>
                {selectedLead &&
                  <AssignLead.AssignLeadModalContainer
                    modalName={ASSIGN_MODAL_NAME}
                    listUpdateProtocol={listUpdateProtocol}
                    lead={selectedLead} />
                }
              </div>
             }
            {leads.length === 0 &&
              <div style={{ paddingTop: '80px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='si si-user-unfollow'
                  title='No unassigned leads'
                  subtitle='New leads that need to be assigned will show here.' />
              </div>
            }
            {pageCount > 1 &&
              <div className='text-center push'>
                <div className='block block-rounded block-bordered'>
                  <PageSelection
                    pageCount={this.props.pageCount}
                    currentPage={this.props.currentPage}
                    pageClicked={this.pageClicked}
                    pageLimit={10} />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

QueuedLeadsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchLeadsWithPageNumber: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectLead: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setLocationId: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  selectedLead: PropTypes.object
}
