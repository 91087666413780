import { request, buildIndexUrl, GET, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';

import notify from 'modules/Notifications';

export const getTeamForLocation = (location, page, search) => {
  return (dispatch) => {
    dispatch(startLoading());
    function onFailure(errors) {
      loadingFailure(errors);
    }

    function onSuccess(payload) {
      dispatch(loadingSuccess());
      dispatch(updateTeam(payload.users));
      dispatch(loadPageDetails(payload.meta));
    }

    let url = buildIndexUrl('users', page, null, search);
    url = url + '&detail=true&location_ids[]=' + location.id;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function updateTeam(team) {
  return {
    type: actionTypes.TEAM_UPDATED,
    team,
  };
}

export const addTeamMembersToLocation = (location, userIds) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      user_ids: userIds,
    };
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success('Users added');
      if (payload.location.users) {
        dispatch(updateTeam(payload.location.users));
      }
    }

    return request('locations/' + location.id + '/add_users', POST, body, onSuccess, onFailure);
  };
};

export const deleteTeamMember = (teamMember, location) => {
  return (dispatch) => {
    dispatch(startLoading());
    function onFailure(payload) {
      dispatch(setLoading(false));
      const error =
        payload?.errors?.user ||
        'This user still has leads assigned to them at this location. Use lead reallocations to reassign their leads to someone else.';

      notify.error(error);
    }

    function onSuccess(payload) {
      dispatch(loadingSuccess());
      dispatch(removeUser(teamMember));
      notify.success('Team member removed');
    }

    return request('users/' + teamMember.id + '?location_id=' + location.id, DELETE, null, onSuccess, onFailure);
  };
};

export const updateTeamMemberUserRole = (user, userRole) => {
  return (dispatch) => {
    const body = {
      user: {
        user_role_id: userRole.id,
      },
    };

    dispatch(startLoading());
    function onFailure(errors) {
      loadingFailure(errors);
    }

    function onSuccess(payload) {
      dispatch(loadingSuccess());
      dispatch(updateUser(payload.user));
      notify.success('User role updated');
    }

    return request('users/' + user.id, PUT, body, onSuccess, onFailure);
  };
};

function removeUser(user) {
  return {
    type: actionTypes.TEAM_MEMBER_REMOVED,
    user,
  };
}

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadingSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadingFailure(errors) {
  return {
    type: actionTypes.LOAD_FAILURE,
    errors,
  };
}

export function selectUserId(userId) {
  return {
    type: actionTypes.TEAM_MEMBER_SELECTED,
    userId,
  };
}

export function updateUserPermissions(userId, userPermissionRuleSet) {
  return {
    type: actionTypes.TEAM_MEMBER_PERMISSIONS_UPDATED,
    userId,
    userPermissionRuleSet,
  };
}

function updateUser(user) {
  return {
    type: actionTypes.TEAM_MEMBER_UPDATED,
    user,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
