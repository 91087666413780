import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'

import ButtonsView from '../components/Confirm/ButtonsView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    integration: selectors.getIntegration(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    deleteIntegration: actions.deleteIntegration,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsView)
