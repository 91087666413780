import { observable, runInAction } from 'mobx';
import { capitalizeFirstLetter } from './stringUtils';

export const FORM_FIELDS_KEY = 'formFields';
export const GETTER_FIELD_PREFIX = 'get';

export interface DefaultStoreActions {
  reset: () => void;
  setLoading: (loading: boolean) => void;
  isEmpty: (value: any) => boolean;
}

export function generateMobxStore(defaultState: any, listKey?: any): any {
  const state = generateSimpleState(defaultState);
  return {
    state: state,
    actions: generateStateActions(defaultState, state, listKey),
  };
}

export function generateMobxSuite(defaultState: any, listKey?: any) {
  const state = generateSimpleState(defaultState);
  return {
    state: state,
    actions: generateStateActions(defaultState, state, listKey),
  };
}

export function generateSimpleState(defaultState: any) {
  return observable.object(defaultState);
}

export function generateStateActions(defaultState: any, state: any, listKey?: string) {
  const actions: any = {};
  const stateKeys = Object.keys(defaultState);
  stateKeys.forEach((key) => {
    if (key !== FORM_FIELDS_KEY) {
      const functionName = `set${capitalizeFirstLetter(key)}`;
      actions[functionName] = (value: any) => {
        runInAction(() => {
          state[key] = value;
        });
      };
    }
  });

  if (defaultState[FORM_FIELDS_KEY]) {
    const formFieldKeys = Object.keys(defaultState[FORM_FIELDS_KEY]);
    formFieldKeys.forEach((key) => {
      const functionName = `set${capitalizeFirstLetter(key)}`;
      actions[functionName] = (value: any) => {
        runInAction(() => {
          state[FORM_FIELDS_KEY][key] = value;
        });
      };
    });
  }

  if (listKey) {
    // add list item
    actions[`add${capitalizeFirstLetter(listKey)}ListItem`] = (newItem: any) => {
      if (!newItem) return;
      const existingItems = state[listKey] || [];
      const newItems = [...existingItems, newItem];
      runInAction(() => {
        state[listKey] = newItems;
      });
    };

    // update list item
    actions[`update${capitalizeFirstLetter(listKey)}ListItem`] = (updatedItem: any, idKey: string = 'id') => {
      const existingItems = state[listKey];
      if (!updatedItem || !existingItems) return;
      const newItems = existingItems.map((item: any) => {
        if (item[idKey].toString() === updatedItem[idKey].toString()) return updatedItem;
        return item;
      });
      runInAction(() => {
        state[listKey] = newItems;
      });
    };

    // remove list item
    actions[`remove${capitalizeFirstLetter(listKey)}ListItem`] = (itemId: string | number | undefined, idKey: string = 'id') => {
      const existingItems = state[listKey];
      if (!itemId || !existingItems) return;
      const newItems = existingItems.filter((item: any) => item[idKey] !== itemId);
      runInAction(() => {
        state[listKey] = newItems;
      });
    };
  }

  actions['reset'] = () => {
    const newState = Object.assign(defaultState, {});

    runInAction(() => {
      Object.assign(state, newState);
    });
  };

  actions['isEmpty'] = (key: string) => {
    if (!state[key]) {
      return true;
    }
    if (state[key].length < 1) {
      return true;
    }
    return false;
  };

  return actions;
}
