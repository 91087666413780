import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import messages from '../modules/messages'
import details from '../modules/details'

function mapStateToProps (state, ownProps) {
  const lead = ownProps.lead
  return {
    facebookMessengerUser: lead.facebook_messenger_user
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setFacebookMessengerUser: details.actions.setFacebookMessengerUser
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(messages.components.MessagesView)
