import config from "config";
import Bugsnag from "@bugsnag/js";

// START USER SESSIONS
export function startUserSession(user, company, intercomHash) {
  if (intercomHash) {
    intercomHash = intercomHash.replace(/['"]+/g, "");
  }

  if (config.debug) {
    console.log("ANALYTIC START", user, company, intercomHash);
    return;
  }

  const options = {
    app_id: "f8f1vqq3",
    email: user.email,
    user_id: user.id,
    username: user.username,
    name: user.display_name,
    role: user.user_role.name,
    created_at: user.created_at,
    user_hash: intercomHash,
    app: "Sales",
    app_version: config.version,
  };

  Bugsnag.addMetadata("user", {
    id: user.id,
    name: user.display_name,
  });

  if (company && company.id) {
    Bugsnag.addMetadata("company", {
      name: company.title,
      id: company.id,
    });
  }

  try {
    if (company && company.id) {
      options["company"] = {
        id: company.id,
        name: company.title,
        website: company.url,
        created_at: company.created_at,
      };
    }

    if (window.Intercom) {
      window.Intercom("boot", options);
    }
  } catch (exception) {
    Bugsnag.notify(exception);
  }
}

// STOP USER SESSIONS
export function stopUserSession() {
  if (config.debug) {
    console.log("ANALYTIC STOP");
    return;
  }
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
  Bugsnag.user = null;
}

// EVENT TRACKING
export function trackEvent(event, data) {
  if (config.debug) {
    console.log("ANALYTIC EVENT", event, data);
    return;
  }
  sendMixpanelEvent(event, data);
  sendIntercomEvent(event, data);
}

function sendMixpanelEvent(event, data) {
  // if (!mixpanel) { return }
  // if (data) {
  //   mixpanel.track(event, data)
  // } else {
  //   mixpanel.track(event)
  // }
}

function sendIntercomEvent(event, data) {
  if (!window.Intercom) {
    return;
  }
  if (data) {
    window.Intercom("trackEvent", event, data);
  } else {
    window.Intercom("trackEvent", event);
  }
}
