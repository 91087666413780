import * as actionTypes from './actionTypes'

const UPDATES_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.FETCH_SUCCESS]: (state, action) => {
    return { ...state, loading: false, errors: null, historyItems: action.historyItems }
  },
  [actionTypes.FETCH_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  }
}

const initialState = {
  historyItems: [],
  loading: false,
  errors: null
}

export default function reducer (state = initialState, action) {
  const handler = UPDATES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
