import { apiClient } from 'utils/apiClient';
import { ILocationMergeOverrideTag } from 'types';

async function getLocationMergeOverrideTags(locationId: number) {
  const url = `location_merge_override_tags?location_id=${locationId}`;

  const response = await apiClient().get(url);

  return response.data as {
    location_merge_override_tags: ILocationMergeOverrideTag[];
  };
}

async function createLocationMergeOverrideTag(locationId: number, customMailMergeTagId: number, value: string) {
  const url = `location_merge_override_tags`;

  const body = {
    location_id: locationId,
    custom_mail_merge_tag_id: customMailMergeTagId,
    location_merge_override_tag: {
      value,
    },
  };

  const response = await apiClient().post(url, body);

  return response.data as {
    location_merge_override_tag: ILocationMergeOverrideTag;
  };
}

async function updateLocationMergeOverrideTag(locationId: number, locationMergeOverrideTagId: number, value: string) {
  const url = `location_merge_override_tags/${locationMergeOverrideTagId}`;

  const body = {
    location_id: locationId,
    location_merge_override_tag_id: locationMergeOverrideTagId,
    location_merge_override_tag: {
      value,
    },
  };

  const response = await apiClient().put(url, body);

  return response.data as {
    location_merge_override_tag: ILocationMergeOverrideTag;
  };
}

async function deleteLocationMergeOverrideTag(locationMergeOverrideTagId: number) {
  const url = `location_merge_override_tags/${locationMergeOverrideTagId}`;

  const response = await apiClient().delete(url);
  return response.data;
}

export { getLocationMergeOverrideTags, createLocationMergeOverrideTag, updateLocationMergeOverrideTag, deleteLocationMergeOverrideTag };
