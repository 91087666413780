import React, { FC } from 'react';
import styled from 'styled-components';
import { EVENT_COLORS } from 'modules/components/Calendar/constants';

import { Box, Text } from 'modules/components';
import { space } from 'styleguide';

type StyledPorps = {
  mt?: string;
  ml?: string;
  mb?: string;
  height?: string;
  color?: string;
};

const LegendColor = styled.div<StyledPorps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  background: ${(props) => props.color};
  align-self: flex-start;
`;

export const CalenderLegend: FC = () => {
  return (
    <Box>
      <Box row height="20px">
        <LegendColor color={EVENT_COLORS.NOT_COMPLETED} />
        <Text.P2 ml={space.S}>Upcoming </Text.P2>
      </Box>
      <Box row height="20px">
        <LegendColor color={EVENT_COLORS.SUCCESSFUL} />
        <Text.P2 ml={space.S}>Successful </Text.P2>
      </Box>
      <Box row height="20px">
        <LegendColor color={EVENT_COLORS.UNSUCCESSFUL} />
        <Text.P2 ml={space.S}>Unsuccessful </Text.P2>
      </Box>
      <Box row height="20px">
        <LegendColor color={EVENT_COLORS.MISSED} />
        <Text.P2 ml={space.S}>Missed </Text.P2>
      </Box>
    </Box>
  );
};
