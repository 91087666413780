import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TagView from '../components/TagView'
import Tags from 'modules/components/Tags'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    suggestions: Tags.selectors.getPopularTags(state),
    tags: selectors.getTags(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setTags: actions.setTags
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TagView)
