import PropTypes from "prop-types";
import React, { Component } from "react";
import { PieChart } from "modules/components/Data";
import { TableView } from "./TableView";

import { EmptyView } from "modules/components";

import { LEAD_SOURCE_TYPE, LEAD_HEARD_ABOUT_METHOD } from "../constants";

export class SourceCountView extends Component {
  componentDidMount() {
    const { fetchWithQueryString, queryString, reportUrl } = this.props;
    fetchWithQueryString(queryString, reportUrl);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.reportType !== this.props.reportType
    ) {
      const { fetchWithQueryString, queryString, reportUrl } = nextProps;
      fetchWithQueryString(queryString, reportUrl);
    }
  }

  switchReportSelected = () => {
    const { reportType, setReportType } = this.props;
    switch (reportType) {
      case LEAD_SOURCE_TYPE:
        setReportType(LEAD_HEARD_ABOUT_METHOD);
        break;
      case LEAD_HEARD_ABOUT_METHOD:
        setReportType(LEAD_SOURCE_TYPE);
        break;
      default:
        break;
    }
  };

  heardAboutButtonTitle = () => {
    switch (this.props.reportType) {
      case LEAD_SOURCE_TYPE:
        return "See Heard About Methods";
      case LEAD_HEARD_ABOUT_METHOD:
        return "See Lead Sources";
      default:
        return "";
    }
  };

  render() {
    const { loading, data, chartData } = this.props;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <div className="block-options-simple">
            <button
              className="btn btn-xs btn-primary"
              onClick={this.switchReportSelected}
            >
              <i className="fa fa-pie-chart" /> {this.heardAboutButtonTitle()}
            </button>
          </div>
          <h3 className="block-title">{this.props.reportTitle}</h3>
        </div>
        <div className="block-content push-50" style={{ minHeight: "409px" }}>
          {data.length > 0 && (
            <div className="row">
              <div className="col-md-4">
                <TableView data={data} />
              </div>
              <div className="col-md-8">
                <PieChart data={chartData} />
              </div>
            </div>
          )}
          {data.length === 0 && (
            <div className="row">
              <div
                className="col-sm-6 col-sm-offset-3"
                style={{ marginTop: "150px" }}
              >
                <EmptyView
                  colorThemeClass="primary"
                  iconClass="fa fa-pie-chart fa-3x"
                  title="No data for these filters"
                  subtitle="Try selecting a different time period."
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SourceCountView.propTypes = {
  queryString: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  reportTitle: PropTypes.string.isRequired,
  reportUrl: PropTypes.string.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  chartData: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setReportType: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SourceCountView;
