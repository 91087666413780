import PropTypes from "prop-types";
import React, { Component } from "react";
import { EmptyView } from "modules/components";
import { HorizontalBar } from "react-chartjs-2";

export default class MultiLineChartView extends Component {
  generateOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true
      },
      hover: {
        animationDuration: 0
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontSize: 11,
              display: false
            },
            scaleLabel: {
              display: false
            },
            gridLines: {},
            stacked: true
          }
        ],
        yAxes: [
          {
            barThickness: 25,
            gridLines: {
              display: false,
              color: "#fff",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
            },
            ticks: {
              fontSize: 11
            },
            stacked: true
          }
        ]
      },
      legend: {
        display: true
      }
    };
  }

  render() {
    const { labels, data, width, height } = this.props;
    const chartData = {
      type: "horizontalBar",
      labels: labels,
      datasets: data
    };

    if (!labels || labels.length < 1) {
      return (
        <EmptyView
          colorThemeClass="primary"
          iconClass="si si-graph"
          title="No results"
          subtitle="Check your filters and try again"
        />
      );
    }

    return (
      <HorizontalBar
        data={chartData}
        redraw={false}
        width={width}
        height={height}
        options={this.generateOptions()}
      />
    );
  }
}

MultiLineChartView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.number,
  height: PropTypes.number
};

MultiLineChartView.defaultProps = {
  width: 100,
  height: 25
};
