import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import { VIEW_SIGNED_WAIVER_MODAL } from '../constants';

export class WaiverSubmissionItem extends Component {
  showSignedWaiver = () => {
    const { waiverSubmission, showModal, selectWaiverSubmissionId } = this.props;
    selectWaiverSubmissionId(waiverSubmission.id);
    showModal(VIEW_SIGNED_WAIVER_MODAL);
  };

  render() {
    const { waiverSubmission } = this.props;
    const createdAtMoment = moment(waiverSubmission.created_at).format('dddd, MMMM Do YYYY h:mm a');
    return (
      <div className="block block-transparent pull-r-l">
        <div className="block-header bg-gray-lighter">
          <ul className="block-options">
            <li>
              <span>
                <em className="text-muted">{createdAtMoment}</em>
              </span>
            </li>
          </ul>
          <h3 className="block-title">{waiverSubmission.waiver_form_name}</h3>
        </div>
        <div className="block-content">
          {waiverSubmission.emergency_contact_name && (
            <p className="font-s13">
              <strong>Emergency contact:</strong> {waiverSubmission.emergency_contact_name}
            </p>
          )}
          {waiverSubmission.emergency_contact_number && (
            <p className="font-s13">
              <strong>Emergency contact number:</strong>
              {waiverSubmission.emergency_contact_number}
            </p>
          )}
          {waiverSubmission.notes && <p className="font-s13">{waiverSubmission.notes}</p>}
          {waiverSubmission.has_signature && (
            <button className="btn btn-primary" type="button" onClick={this.showSignedWaiver}>
              View signed waiver
            </button>
          )}
        </div>
      </div>
    );
  }
}

WaiverSubmissionItem.propTypes = {
  waiverSubmission: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  selectWaiverSubmissionId: PropTypes.func.isRequired,
};
