import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditLawfulProcessingBasisForm from '../components/EditLawfulProcessingBasisForm'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: actions.onInput
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLawfulProcessingBasisForm)
