import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import email from '../modules/emails'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    emails: email.selectors.getEmails(state),
    loading: email.selectors.getLoading(state),
    selectedEmailForHistory: email.selectors.selectedEmailForHistory(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    getEmails: email.actions.fetchForLead,
    setShowHistoryEmailId: email.actions.setShowHistoryEmailId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(email.components.EmailsView)
