import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import ScheduleCallModalContainer from 'modules/components/LeadDetail/modules/calls/containers/ScheduleCallModalContainer';
import AppointmentOutcomeRow from 'modules/components/LeadDetail/modules/appointments/components/NewAppointmentModal/AppointmentOutcomeRow';
import LeadLostReasonModalContainer from 'modules/components/LeadDetail/containers/LeadLostReasonModalContainer';
import DealModalContainer from 'modules/components/LeadDetail/containers/DealModalContainer';
import TrialSelectModal from 'modules/components/TrialSelectModal';
import ScheduleAppointmentModalContainer from '../../containers/ScheduleAppointmentModalContainer';
import ClassAppointmentModalContainer from '../../containers/ClassAppointmentModalContainer';
import * as appointmentHelpers from 'modules/components/LeadDetail/modules/appointments/helpers';

import { FormFields } from 'modules/components';
const { TextArea, Switch } = FormFields;
const STARTED_TRIAL_MODAL = 'STARTED_TRIAL_MODAL';
const DEAL_MODAL = 'DEAL_MODAL_AFTER_PRESENTATION';
const LOST_REASON_MODAL = 'LOST_REASON_MODAL_AFTER_PRESENTATION';

export class RecordAppointmentModal extends Component {
  state = {
    selectedAction: null,
    note: null,
    visitType: this.props.appointment ? this.props.appointment.visit_type : 'first_visit',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const appointment = this.props.appointment;
    const nextAppointment = nextProps.appointment;

    const currentId = appointment ? appointment.id : null;
    const nextId = nextAppointment ? nextAppointment.id : null;

    if (currentId === null && nextId === null) {
      const visitType = nextAppointment ? nextAppointment.visit_type : 'first_visit';
      this.setState({
        ...this.state,
        selectedAction: null,
        note: null,
        visitType: visitType,
      });
      return;
    }

    if (currentId !== nextId) {
      const visitType = nextAppointment ? nextAppointment.visit_type : 'first_visit';
      this.setState({
        ...this.state,
        selectedAction: null,
        note: null,
        visitType: visitType,
      });
    }
  }

  modalForActionType(actionType) {
    const { classIntegrationId, classAppointmentModalName, rescheduleModalName } = this.props;
    switch (actionType) {
      case appointmentHelpers.RESCHEDULED:
        return classIntegrationId ? classAppointmentModalName : rescheduleModalName;
      case appointmentHelpers.WANTS_TO_THINK_ABOUT_IT:
      case appointmentHelpers.NO_SHOW:
        return this.props.scheduleCallModalName;
      case appointmentHelpers.NOT_INTERESTED:
        return LOST_REASON_MODAL;
      case appointmentHelpers.SIGNED_UP:
        return DEAL_MODAL;
      case appointmentHelpers.STARTED_TRIAL:
        return STARTED_TRIAL_MODAL;
      default:
        return null;
    }
  }

  handleActionSelect = (type) => {
    this.setState({ ...this.state, selectedAction: type });
  };

  recordAppointment = () => {
    const {
      lead,
      appointment,
      toggleComplete,
      recordAppointment,
      salesFunnel,
      listUpdateProtocol,
      handleAfterActionForOutcome,
    } = this.props;
    const { note, visitType, selectedAction } = this.state;

    this.setState({ ...this.state, selectedAction: selectedAction });
    const modalName = this.modalForActionType(selectedAction);
    if (modalName) {
      this.props.showModal(modalName);
    } else {
      this.props.hideModal();
    }

    if (appointment) {
      toggleComplete(appointment, lead, selectedAction, note, visitType);
    } else {
      recordAppointment(lead, selectedAction, note, visitType);
    }
    handleAfterActionForOutcome(selectedAction, lead, salesFunnel, listUpdateProtocol);
  };

  formIsInvalid = () => {
    const { selectedAction } = this.state;
    if (!selectedAction) return true;
    return false;
  };

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleToggle = (fieldName, value) => {
    const visitType = value === true ? 'no_sale_return' : 'first_visit';
    this.setState({ ...this.state, visitType: visitType });
  };

  switchValue = () => {
    const { visitType } = this.state;
    return visitType === 'no_sale_return';
  };

  trialSelected = (trial, trialStartsAt) => {
    const { lead, hideModal, addLeadToTrial } = this.props;
    addLeadToTrial(lead, trial, trialStartsAt);
    hideModal();
  };

  optionSelected = (type) => {
    this.setState({ selectedAction: type });
  };

  render() {
    const { currentModal, hideModal, modalName, lead, currentTrial, classIntegrationId, classIntegrationName } = this.props;
    const { selectedAction } = this.state;

    const options = appointmentHelpers.allTypes.map((type, index) => {
      const isSelected = type === selectedAction;
      const trialName = currentTrial ? currentTrial.name : null;
      const disabled = type === appointmentHelpers.STARTED_TRIAL && trialName !== null;
      return (
        <AppointmentOutcomeRow
          key={index}
          type={type}
          isSelected={isSelected}
          onClick={this.optionSelected}
          disabled={disabled}
          trial_name={trialName}
        />
      );
    });

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className="block">
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                How did the appointment go?
              </h3>
            </div>
            <div className="block-content">
              <div className="row">{options}</div>
              <div className="row">
                <Switch
                  name="isNoSaleReturn"
                  label={'Is ' + lead.given_name + ' returning after a non sale appointment? (NSR)'}
                  onChange={this.handleToggle}
                  switchStyle="switch-sm switch-primary"
                  value={this.switchValue()}
                />
              </div>
              <div className="row push-20">
                <TextArea
                  name="note"
                  label="Appointment notes"
                  placeholder="Any notes about this appointment?"
                  onChange={this.handleInput}
                  rows={4}
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.props.hideModal}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r push-10"
              type="button"
              disabled={this.formIsInvalid()}
              onClick={this.recordAppointment}
            >
              <i className="si si-calendar" /> Record Appointment
            </button>
          </Modal.Footer>
        </Modal>
        <ScheduleCallModalContainer
          modalName={this.props.scheduleCallModalName}
          lead={lead}
          appointmentOutcome={this.state.selectedAction}
          listUpdateProtocol={this.props.listUpdateProtocol}
        />
        <ScheduleAppointmentModalContainer
          modalName={this.props.rescheduleModalName}
          lead={lead}
          listUpdateProtocol={this.props.listUpdateProtocol}
        />
        {classIntegrationId && (
          <ClassAppointmentModalContainer
            modalName={this.props.classAppointmentModalName}
            lead={this.props.lead}
            listUpdateProtocol={this.props.listUpdateProtocol}
            classIntegrationId={classIntegrationId}
            classIntegrationName={classIntegrationName}
            pathToLeadDetail={this.props.pathToLeadDetail}
          />
        )}
        <LeadLostReasonModalContainer modalName={LOST_REASON_MODAL} listUpdateProtocol={this.props.listUpdateProtocol} lead={lead} />
        <DealModalContainer modalName={DEAL_MODAL} listUpdateProtocol={this.props.listUpdateProtocol} lead={lead} />
        <TrialSelectModal.TrialSelectModalContainer onTrialSelect={this.trialSelected} modalName={STARTED_TRIAL_MODAL} />
      </div>
    );
  }
}

RecordAppointmentModal.propTypes = {
  updateLead: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  salesFunnel: PropTypes.object.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  handleAfterActionForOutcome: PropTypes.func.isRequired,
  scheduleCallModalName: PropTypes.string.isRequired,
  recordAppointment: PropTypes.func,
  toggleComplete: PropTypes.func,
  appointment: PropTypes.object,
  currentModal: PropTypes.string,
};

export default RecordAppointmentModal;
