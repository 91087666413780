import { combineReducers } from 'redux';

import emailMapSelect from './modules/emailMapSelect';
import editEmailMap from './modules/editEmailMap';
import emailMapResults from './modules/emailMapResults';

export default combineReducers({
  [emailMapSelect.constants.NAME]: emailMapSelect.reducer,
  [editEmailMap.constants.NAME]: editEmailMap.reducer,
  [emailMapResults.constants.NAME]: emailMapResults.reducer,
});
