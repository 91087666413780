import { createSelector } from 'reselect'
import { NAME } from './constants'
import userData from 'modules/Stores/UserData'

export const getErrors = (state) => state.lostLeadsReport[NAME].errors
export const getLoading = (state) => state.lostLeadsReport[NAME].loading
export const getLostSteps = (state) => state.lostLeadsReport[NAME].lostSteps
export const getInactiveSteps = (state) => state.lostLeadsReport[NAME].inactiveSteps

export const dataLabels = createSelector(
  [ userData.selectors.getSalesFunnelSteps ],
  (salesFunnelSteps) => {
    return salesFunnelSteps.map((salesFunnelStep) => salesFunnelStep.name)
  }
)

export const lostDataSet = createSelector(
  [ getLostSteps, userData.selectors.getSalesFunnelSteps ],
  (lostSteps, salesFunnelSteps) => {
    const data = salesFunnelSteps.map((salesFunnelStep, index) => {
      return (lostSteps[salesFunnelStep.id]) ? lostSteps[salesFunnelStep.id] : 0
    })
    return [{
      label: 'Steps Lost From',
      data: data,
      backgroundColor: '#d26a5c',
      hoverBackgroundColor: '#d26a5c',
      borderWidth: 1,
      borderColor: '#AF4B40'
    }]
  }
)

export const inactiveDataSet = createSelector(
  [ getInactiveSteps, userData.selectors.getSalesFunnelSteps ],
  (inactiveSteps, salesFunnelSteps) => {
    const data = salesFunnelSteps.map((salesFunnelStep, index) => {
      return (inactiveSteps[salesFunnelStep.id]) ? inactiveSteps[salesFunnelStep.id] : 0
    })
    return [{
      label: 'Steps Made Inactive From',
      data: data,
      backgroundColor: '#f3b760',
      hoverBackgroundColor: '#f3b760',
      borderWidth: 1,
      borderColor: '#E29B41'
    }]
  }
)
