import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TopUpView from '../components/TopUpView'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSMSAccount: actions.updateSMSAccount,
    topUpAccount: actions.topUpAccount
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TopUpView)
