import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LocationTableView from './LocationTableView/LocationTableView'
import PagesContainer from '../containers/PagesContainer'

import { ContentBlockView, EmptyView, TextSearchView } from 'modules/components'

export class LocationSelectView extends Component {

  UNSAFE_componentWillMount () {
    const { locations } = this.props
    if (locations.length > 0) { return }
    this.getLocationsWithPageNumber(1)
  }

  getLocationsWithPageNumber = (pageNumber) => {
    const { getLocations } = this.props
    getLocations(pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getLocationsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getLocations } = this.props
    getLocations(1, searchTerm)
  }

  render () {
    const { locations, onLocationSelect, renderRow, showSaleDetails, tableHead,
      selectedLocationId, minHeight, pageDetails, loading, title } = this.props
    const pageCount = pageDetails.pageCount

    return (
      <ContentBlockView
        minHeight={minHeight}
        title={title}
        loading={loading}
        bordered>
        <div className='row' style={{ marginTop: '-20px' }}>
          <TextSearchView
            sectionName={'locationSearch'}
            onUpdate={this.searchUpdated} />
        </div>
        <div>
          {locations.length > 0 &&
            <LocationTableView
              renderRow={renderRow}
              tableHead={tableHead}
              showSaleDetails={showSaleDetails}
              locations={locations}
              locationSelected={onLocationSelect}
              selectedLocationId={selectedLocationId} />
          }
          {locations.length === 0 &&
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-magnifier'
              title='No results'
              subtitle='Check the search field' />
          }
          {pageCount > 1 &&
            <div className='text-center push'>
              <PagesContainer pageClicked={this.pageClicked} />
            </div>
          }
        </div>
      </ContentBlockView>
    )
  }
}

LocationSelectView.propTypes = {
  title: PropTypes.string,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedLocationId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
  showSaleDetails: PropTypes.bool
}

LocationSelectView.defaultProps = {
  showSaleDetails: false,
  title: 'Locations'
}

export default LocationSelectView
