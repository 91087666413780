import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';
import * as arrayUtils from 'utils/arrayUtils';

import MutliHeardAboutMethodSelectView from './MultiHeardAboutMethodSelectView';

export class MultiHeardAboutMethodSelectModal extends Component {
  state = {
    selectedHeardAboutMethodIds: Object.keys(this.props.data.fields).map((string) => parseInt(string)),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldData = this.props.data;
    const newData = nextProps.data;

    if (!oldData || !newData) {
      return;
    }
    const oldIds = Object.keys(oldData.fields) || [];
    const newIds = Object.keys(newData.fields) || [];

    if (arrayUtils.arraysEqual(oldIds, newIds)) {
      return;
    }
    this.setState({
      selectedHeardAboutMethodIds: newIds.map((string) => parseInt(string)),
    });
  }

  selectHeardAboutMethod = (heardAboutMethod) => {
    const newSelectedHeardAboutMethodIds = [...this.state.selectedHeardAboutMethodIds, heardAboutMethod.id];
    this.setState({
      ...this.state,
      selectedHeardAboutMethodIds: newSelectedHeardAboutMethodIds,
    });
  };

  deselectHeardAboutMethod = (heardAboutMethod) => {
    const index = this.state.selectedHeardAboutMethodIds.findIndex(
      (heardAboutMethodId) => parseInt(heardAboutMethodId) === parseInt(heardAboutMethod.id)
    );
    const newSelectedHeardAboutMethodIds = [
      ...this.state.selectedHeardAboutMethodIds.slice(0, index),
      ...this.state.selectedHeardAboutMethodIds.slice(index + 1),
    ];
    this.setState({
      ...this.state,
      selectedHeardAboutMethodIds: newSelectedHeardAboutMethodIds,
    });
  };

  useAllHeardAboutMethods = () => {
    this.setState({ ...this.state, selectedHeardAboutMethodIds: [] });
    const newSectionData = { fields: {} };
    this.props.onUpdate(newSectionData, this.props.sectionName);
    this.cleanAndHide();
  };

  updateClubIds = () => {
    let fields = {};
    this.state.selectedHeardAboutMethodIds.forEach((heardAboutMethodId) => {
      const object = { value: true, isValid: true };
      fields = { ...fields, [heardAboutMethodId]: object };
    });
    const newSectionData = { fields: fields, isValid: true };
    this.props.onUpdate(newSectionData, this.props.sectionName);
    this.cleanAndHide();
  };

  cleanAndHide = () => {
    this.setState({ selectedHeardAboutMethodIds: [] });
    this.props.hideModal();
  };

  render() {
    const { currentModal, modalName, hideModal } = this.props;
    const disabled = this.state.selectedHeardAboutMethodIds.length === 0;
    const buttonTitle = this.props.buttonTitle || 'Filter by selected';

    return (
      <Modal show={currentModal === modalName} onHide={this.cleanAndHide}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.cleanAndHide}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select Heard About Methods
            </h3>
          </div>
          <div className="block-content">
            <MutliHeardAboutMethodSelectView
              {...this.props}
              selectedHeardAboutMethodIds={this.state.selectedHeardAboutMethodIds}
              selectHeardAboutMethod={this.selectHeardAboutMethod}
              deselectHeardAboutMethod={this.deselectHeardAboutMethod}
            />
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-primary pull-left" onClick={this.useAllHeardAboutMethods}>
            All Lead Sources
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={disabled} onClick={this.updateClubIds}>
            <i className="fa fa-building-o" /> {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MultiHeardAboutMethodSelectModal.propTypes = {
  getHeardAboutMethods: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  currentModal: PropTypes.string,
};

MultiHeardAboutMethodSelectModal.defaultProps = {
  buttonTitle: 'Filter by selected',
};

export default MultiHeardAboutMethodSelectModal;
