import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

// Fetch Notes
export const fetchForLead = (lead, pageNumber = 1) => {
  return (dispatch) => {
    dispatch(fetchingWaivers());

    function onFailure(errors) {
      dispatch(fetchWaiversFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchWaiversSuccess(payload.waiver_submissions));
      dispatch(loadPageDetails(payload.meta));
    }

    const url = `waiver_submissions_for_lead/${lead.id}?page[number]=${pageNumber}`;

    return request(url, GET, null, onSuccess, onFailure);
  };
};

function fetchingWaivers() {
  return {
    type: actionTypes.FETCHING_WAIVERS,
  };
}

function fetchWaiversSuccess(waiverSubmissions) {
  return {
    type: actionTypes.FETCHING_WAIVERS_SUCCESS,
    waiverSubmissions,
  };
}

function fetchWaiversFailed(errors) {
  return {
    type: actionTypes.FETCHING_WAIVERS_FAILURE,
    errors,
  };
}

export function selectWaiverSubmissionId(selectedWaiverSubmissionId) {
  return {
    type: actionTypes.SELECT_WAIVER_SUBMISSION_ID,
    selectedWaiverSubmissionId,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
