import * as actionTypes from './actionTypes'

const TURN_ON_OPT_IN_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

function initialState (salesTargets) {
  return {
    loading: false,
    errors: null
  }
}

export default function reducer (state = initialState(null), action) {
  const handler = TURN_ON_OPT_IN_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
