import { request, buildIndexUrl, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getLocations = (pageNumber, searchTerm = null) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setLocations(payload.locations))
      dispatch(loadPageDetails(payload.meta))
    }

    const url = buildIndexUrl('locations', pageNumber, null, searchTerm)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const loadInitialLocations = () => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      const locations = payload.locations
      const isMultiLocationAccount = (locations.length > 1)
      dispatch(setLocations(locations))
      dispatch(setDefaultLocation(locations[0]))
      dispatch(setIsMultiLocationAccount(isMultiLocationAccount))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setInitialLoadComplete(true))
    }

    const url = buildIndexUrl('locations', 1)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function addLocation (location) {
  return {
    type: actionTypes.ADD_LOCATION,
    location
  }
}

export function editLocation (location, oldValue) {
  return {
    type: actionTypes.EDIT_LOCATION,
    location,
    oldValue
  }
}

function setLocations (locations) {
  return {
    type: actionTypes.SET_LOCATIONS,
    locations
  }
}

// Utility
function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setIsMultiLocationAccount (isMultiLocationAccount) {
  return {
    type: actionTypes.SET_IS_MULTI_LOCATION_ACCOUNT,
    isMultiLocationAccount
  }
}

function setDefaultLocation (defaultLocation) {
  return {
    type: actionTypes.SET_DEFAULT_LOCATION,
    defaultLocation
  }
}

function setInitialLoadComplete (initialLoadComplete) {
  return {
    type: actionTypes.SET_INITIAL_LOAD_COMPLETE,
    initialLoadComplete
  }
}
