import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LocationSelectView from '../components/LocationSelectView'
import locations from 'modules/Stores/Locations'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    locations: locations.selectors.getLocations(state),
    loading: locations.selectors.getLoading(state),
    errors: locations.selectors.getErrors(state),
    pageDetails: locations.selectors.getPageDetails(state),
    selectedLocation: selectors.getSelectedLocation(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setCurrentView: actions.setCurrentView,
    selectLocation: actions.selectLocation,
    getLocations: locations.actions.getLocations
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectView)
