import * as actionTypes from './actionTypes'

const REDEEM_ACTION_HANDLERS = {
  [actionTypes.FETCHING_INVITE]: (state) => {
    return ({ ...state, loading: true, errors: null })
  },
  [actionTypes.FETCHING_INVITE_SUCCESS]: (state, action) => {
    return ({ ...state, loading: false, errors: null, invite: action.invite })
  },
  [actionTypes.FETCHING_INVITE_FAILURE]: (state, action) => {
    return ({ ...state, loading: false, errors: action.errors })
  },
  [actionTypes.REDEEMING_INVITE]: (state) => {
    return ({ ...state, loading: true, errors: null })
  },
  [actionTypes.REDEEMING_INVITE_SUCCESS]: (state) => {
    return ({ ...state, loading: false, errors: null })
  },
  [actionTypes.REDEEMING_INVITE_FAILED]: (state, action) => {
    return ({ ...state, loading: false, errors: action.errors.errors })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function redeemReducer (state = initialState, action) {
  const handler = REDEEM_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

const initialState = {
  loading: false,
  errors: null,
  invite: null
}
