import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import ThirdPartyIntegrationSelectView from 'modules/components/ThirdPartyIntegrationSelect/components/ThirdPartyIntegrationSelectView';

export class ThirdPartyIntegrationSelectModal extends Component {
  render() {
    const { currentModal, modalName, hideModal, title, errors } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              {title}
            </h3>
          </div>
          {errorPanelForErrors(errors)}
          <div className="block-content">
            <ThirdPartyIntegrationSelectView {...this.props} />
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ThirdPartyIntegrationSelectModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  lastSelectedLocationId: PropTypes.number,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  title: PropTypes.string,
  getThirdPartyIntegrations: PropTypes.func.isRequired,
  thirdPartyIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onThirdPartyIntegrationSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedThirdPartyIntegrationId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
};

export default ThirdPartyIntegrationSelectModal;
