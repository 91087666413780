import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import password from '../modules/password'

const { sendData, updateData } = password.actions
const { getLoading, getData, getErrors } = password.selectors

function mapStateToProps (state) {
  return {
    loading: getLoading(state),
    data: getData(state),
    errors: getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    sendData,
    updateData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(password.components.UpdatePasswordView)
