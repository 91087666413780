import { NAME } from './constants';

export const SET_LOADING = NAME + '/SET_LOADING';
export const RESET = NAME + '/RESET';

export const SESSION_CREATE = NAME + '/SESSION_CREATE';
export const SESSION_END = NAME + '/SESSION_END';

export const SET_SESSION_EXPIRY = NAME + '/SET_SESSION_EXPIRY';
export const SET_REALTIME_AUTH = NAME + '/SET_REALTIME_AUTH';
export const SET_SESSION_END_REASON = NAME + '/SET_SESSION_END_REASON';
export const SET_INTERCOM_HASH = NAME + '/SET_INTERCOM_HASH';

export const UPDATE_USER = NAME + '/UPDATE_USER';
export const SWITCHING_APP_NAME = NAME + '/SWITCHING_APP_NAME';

export const ATTEMPED_URL = NAME + '/ATTEMPED_URL';
export const SET_APP_VERSION = NAME + '/SET_APP_VERSION';
