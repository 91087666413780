import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomMailMergeTagDetailView from '../components/CustomMailMergeTagDetailView';

import customMailMergeTags from 'modules/Stores/CustomMailMergeTags';
import modal from 'modules/Stores/Modal';

import * as localSelectors from '../selectors';
import * as localActions from '../actions';

function mapStateToProps(state) {
  return {
    selectedCustomMailMergeTag: localSelectors.selectedCustomMailMergeTag(state),
    loading: customMailMergeTags.selectors.getLoading(state),
    data: localSelectors.getData(state),
    errors: customMailMergeTags.selectors.getErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateData: localActions.updateData,
      deleteCustomMailMergeTag: customMailMergeTags.actions.deleteCustomMailMergeTag,
      updateCustomMailMergeTag: customMailMergeTags.actions.updateCustomMailMergeTag,
      prefillDataForCustomMailMergeTag: localActions.prefillDataForCustomMailMergeTag,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomMailMergeTagDetailView);
