import PropTypes from "prop-types";
import React, { Component } from "react";
import { LeadGoalTableRow } from "./LeadGoalTableRow";

export class LeadGoalTableView extends Component {
  render() {
    const {
      leadGoals,
      leadGoalSelected,
      selectedLeadGoalId,
      tableHead,
      renderRow
    } = this.props;
    const rows = leadGoals.map((leadGoal, index) => {
      const selected = leadGoal.id === selectedLeadGoalId;
      if (renderRow) {
        return renderRow(leadGoal, selected);
      }
      return (
        <LeadGoalTableRow
          key={index}
          leadGoal={leadGoal}
          leadGoalSelected={leadGoalSelected}
          selected={selected}
        />
      );
    });

    return (
      <div style={{ marginTop: "-20px" }}>
        <table className="table table-vcenter table-hover">
          {tableHead && tableHead}
          {!tableHead && (
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
          )}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

LeadGoalTableView.propTypes = {
  leadGoals: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func,
  tableHead: PropTypes.element,
  leadGoalSelected: PropTypes.func.isRequired,
  selectedLeadGoalId: PropTypes.number
};

LeadGoalTableRow.defaultProps = {
  showSaleDetails: true
};

export default LeadGoalTableView;
