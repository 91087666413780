import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import subscription from '../modules/subscription'
import modal from 'modules/Stores/Modal'
import userData from 'modules/Stores/UserData'
import session from 'modules/Stores/Session'
const { getLoading, getErrors, getBillingPlan } = subscription.selectors

function mapStateToProps (state) {
  return {
    billingPlan: getBillingPlan(state),
    loading: getLoading(state),
    errors: getErrors(state),
    timezone: session.selectors.getUserTimezone(state),
    membershipPermissions: userData.selectors.getMembershipPermissions(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getSubscriptionDetails: subscription.actions.getSubscriptionDetails,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(subscription.components.SubscriptionView)
