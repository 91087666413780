import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TrialPanel from './TrialPanel'

import * as leadsTrialsActions from '../../../../leadsTrials/actions'
import * as leadsTrialsSelectors from '../../../../leadsTrials/selectors'

function mapStateToProps (state, ownProps) {
  return {
    trialErrors: leadsTrialsSelectors.getErrors(state),
    trialLoading: leadsTrialsSelectors.getLoading(state),
    currentTrial: leadsTrialsSelectors.getCurrentTrial(state),
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    removeLeadFromTrial: leadsTrialsActions.removeLeadFromTrial,
    updateLeadsTrial: leadsTrialsActions.updateLeadsTrial,
    addLeadToTrial: leadsTrialsActions.addLeadToTrial,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialPanel)
