import React, { Component } from "react";
import styled from "styled-components";
import FormView from "./FormView";

export default class OpeningHoursView extends Component {
  state = {
    weekday: "",
    weekend: "",
  };

  componentDidMount() {
    const { opening_hours } = this.props.location;
    this.setState({
      weekday: opening_hours.weekday,
      weekend: opening_hours.weekend,
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  updateOpeningHours = () => {
    const { updateOpeningHours, location } = this.props;
    updateOpeningHours(location, location.opening_hours.id, this.state);
  };

  render() {
    const { loading, location } = this.props;
    const blockStyle = loading ? "block-opt-refresh" : "";

    return (
      <BlockContainer
        className={`block block-rounded block-bordered ${blockStyle}`}
      >
        <BlockHeader className="block-header">
          <BlockTitle className="block-title">
            Opening Hours for {location.name}
          </BlockTitle>
        </BlockHeader>
        <BlockContent className="block-content">
          <FormView
            handleChange={this.handleChange}
            updateOpeningHours={this.updateOpeningHours}
            state={this.state}
          />
        </BlockContent>
      </BlockContainer>
    );
  }
}

const BlockContainer = styled.div``;
const BlockHeader = styled.div``;
const BlockTitle = styled.h3``;
const BlockContent = styled.div``;
