import * as actionTypes from "./actionTypes";

const SUBSCRIPTION_ADD_ONS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_SUBSCRIPTION_ADD_ONS]: (state, action) => {
    return { ...state, subscriptionAddOns: action.subscriptionAddOns };
  },
  [actionTypes.RESET]: () => {
    return { initialState };
  },
  [actionTypes.SET_PAGE_DETAILS]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  subscriptionAddOns: null,
  loading: false,
  errors: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function reducer(state = initialState, action) {
  const handler = SUBSCRIPTION_ADD_ONS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
