import React from "react";
import MultiUserSelect from "modules/components/MultiUserSelect";

const UsersSection = ({
  onUpdate,
  SELECT_USERS_MODAL,
  showModal,
  usersTitle,
  usersFiltersClass,
  userIdData,
  userIdsName,
  locationIds
}) => {

  function showSelectUsersModal() {
    showModal(SELECT_USERS_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${usersFiltersClass}`}
        type="button"
        onClick={showSelectUsersModal}
      >
        {usersTitle}
      </div>
      <MultiUserSelect.MultiUserSelectDataModalContainer
        data={userIdData}
        modalName={SELECT_USERS_MODAL}
        sectionName={userIdsName}
        onUpdate={onUpdate}
        locationIds={locationIds}
      />
    </div>
  );
};

export default UsersSection;