import { createSelector } from 'reselect';
import { NAME } from './constants';

import customMailMergeTags from 'modules/Stores/CustomMailMergeTags';

export const getSelectedCustomMailMergeTagId = (state) => state[NAME].selectedCustomMailMergeTagId;
export const getData = (state) => state[NAME].data;

export const selectedCustomMailMergeTag = createSelector(
  [customMailMergeTags.selectors.getCustomMailMergeTags, getSelectedCustomMailMergeTagId],
  (customMailMergeTags, selectedCustomMailMergeTagId) => {
    if (customMailMergeTags && customMailMergeTags.length > 0) {
      const index = customMailMergeTags.findIndex(
        (customMailMergeTag) => parseInt(customMailMergeTag.id) === parseInt(selectedCustomMailMergeTagId)
      );
      return customMailMergeTags[index];
    } else {
      return null;
    }
  }
);
