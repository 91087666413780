import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as actions from 'modules/Helpers/IntegrationHelpers';

import FacebookWizardView from './FacebookWizardView';
import LoginButtonsContainer from '../containers/LoginButtonsContainer';
import SelectPageButtonsContainer from '../containers/SelectPageButtonsContainer';
import ConfirmButtonsContainer from '../containers/ConfirmButtonsContainer';
import AdditionalPageButtonsContainer from '../containers/AdditionalPageButtonsContainer';

import Modal from 'react-bootstrap-modal';

export class FacebookConfigurationModalView extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.selectedIntegration) {
      this.props.prefillFormForIntegration(this.props.selectedIntegration);
    } else {
      this.props.resetForm();
    }
  }

  hideModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const { currentModal, modalName, name, tabIndex, location, selectedIntegration, loading } = this.props;

    const buttonsContainer = () => {
      switch (tabIndex) {
        case 0:
          return <LoginButtonsContainer />;
        case 1:
          return <SelectPageButtonsContainer location={location} />;
        case 2:
          if (selectedIntegration && selectedIntegration.third_party_integrations_facebook_pages) {
            return <ConfirmButtonsContainer location={location} />;
          } else {
            return <div />;
          }
        case 3:
          return <AdditionalPageButtonsContainer />;
        default:
          return <div />;
      }
    };

    const logo = actions.logoForOption(name);

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Let's setup {actions.titleForOption(name)}
              </h3>
            </div>
            <div className="block-content">
              <FacebookWizardView {...this.props} />
              {logo && <img alt="" src={logo} style={{ width: '25px' }} />}
            </div>
          </div>
          <Modal.Footer>{buttonsContainer()}</Modal.Footer>
        </Modal>
      </div>
    );
  }
}

FacebookConfigurationModalView.propTypes = {
  loading: PropTypes.bool.isRequired,
  prefillFormForIntegration: PropTypes.func.isRequired,
  selectedIntegration: PropTypes.object,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default FacebookConfigurationModalView;
