import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import Datetime from "react-datetime";
import "styles/react-datetime.css";

export class DateTimeSelect extends Component {
  state = {
    date: moment().tz(this.props.timezone),
    showingDatePicker: false,
  };

  componentDidMount() {
    this.thisDaySelected();
  }

  setStartDate = (selectedDay) => {
    const { classIntegrationName } = this.props;
    const startDateTime = selectedDay.clone().startOf("day");
    const endDateTime = selectedDay.clone().endOf("day");
    let dateStrings = {};

    if (classIntegrationName === "glofox") {
      // time in UTC accounting for timezone.
      dateStrings = {
        startDateTimeString: startDateTime.utc().format(),
        endDateTimeString: endDateTime.utc().format(),
      };
    } else {
      // mindbody
      dateStrings = {
        startDateTimeString: startDateTime
          .clone()
          .format("YYYY-MM-DDTHH:mm:ss"),
        endDateTimeString: endDateTime.clone().format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    this.props.dateChanged(dateStrings);
    this.setState({
      ...this.state,
      date: selectedDay,
      showingDatePicker: false,
    });
  };

  thisDaySelected = () => {
    const newDate = moment().tz(this.props.timezone);
    this.setStartDate(newDate);
  };

  previousSelected = () => {
    const newDate = this.state.date.subtract(1, "day");
    this.setStartDate(newDate);
  };

  nextSelected = () => {
    const newDate = this.state.date.add(1, "day");
    this.setStartDate(newDate);
  };

  selectDate = (date) => {
    this.setStartDate(date);
  };

  toggleDateView = () => {
    this.setState({
      ...this.state,
      showingDatePicker: !this.state.showingDatePicker,
    });
  };

  dateString = () => {
    return this.state.date.format("dddd Do MMM");
  };

  render() {
    return (
      <Container className="row">
        <div className="col-md-6">
          <h4>{this.dateString()}</h4>
        </div>
        <div className="col-md-6">
          <div className="btn-group pull-right push-10">
            <PreviousDayButton
              className="btn btn-default"
              type="button"
              onClick={this.previousSelected}
            >
              Previous Day
            </PreviousDayButton>
            <SelectDayButton
              className="btn btn-default"
              type="button"
              onClick={this.toggleDateView}
            >
              Select Day
            </SelectDayButton>
            <NextDayButton
              className="btn btn-default"
              type="button"
              onClick={this.nextSelected}
            >
              Next Day
            </NextDayButton>
          </div>
          {this.state.showingDatePicker && (
            <Datetime
              input={false}
              onChange={this.selectDate}
              viewMode={"days"}
              timeFormat={false}
            />
          )}
        </div>
      </Container>
    );
  }
}

DateTimeSelect.propTypes = {
  timezone: PropTypes.string.isRequired,
  dateChanged: PropTypes.func.isRequired,
  classIntegrationName: PropTypes.string.isRequired,
};

export default DateTimeSelect;

const PreviousDayButton = styled.button``;
const NextDayButton = styled.button``;
const SelectDayButton = styled.button``;
const Container = styled.div``;
