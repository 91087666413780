import React from "react";
import MultiLeadSourceSelect from "modules/components/MultiLeadSourceSelect";

const LeadSourcesSection = ({
  SELECT_LEAD_SOURCES_MODAL,
  clubsFiltersClass,
  leadSourceIdsName,
  leadSourceIdsData,
  showModal,
  clubTitle,
  onUpdate
}) => {
  function showSelectLeadSourcesModal() {
    showModal(SELECT_LEAD_SOURCES_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${clubsFiltersClass}`}
        type="button"
        onClick={showSelectLeadSourcesModal}
      >
        {clubTitle}
      </div>
      <MultiLeadSourceSelect.MultiLeadSourceSelectModalContainer
        modalName={SELECT_LEAD_SOURCES_MODAL}
        sectionName={leadSourceIdsName}
        onUpdate={onUpdate}
        data={leadSourceIdsData}
      />
    </div>
  );
};

export default LeadSourcesSection;
