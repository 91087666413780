/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectHeardAboutMethodModalContainer from "./SelectHeardAboutMethodModalContainer";
import { HEARD_ABOUT_METHOD_MODAL } from "../../../constants";
import "../../LeadMoreDetail.css";

export class HeardAboutPanel extends Component {
  methodSelected = () => {
    this.props.showModal(HEARD_ABOUT_METHOD_MODAL);
  };

  icon = () => {
    if (this.props.lead.heard_about_method) {
      return (
        <i className="si si-check fa-2x text-muted pull-right text-success" />
      );
    }
    return (
      <i className="si si-question fa-2x text-muted pull-right text-warning" />
    );
  };

  methodText = () => {
    const { lead } = this.props;
    return (
      <div>
        {!lead.heard_about_method && (
          <div>
            <span className="h4 text-muted">
              {"Where did " + lead.given_name}{" "}
            </span>
            <span className="h4 font-w700">hear about us?</span>
          </div>
        )}
        {lead.heard_about_method && (
          <div>
            <span className="h4 text-muted">Heard about from: </span>
            <span className="h4 font-w700">
              {lead.heard_about_method.heard_about_title}
            </span>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <a
          className="block block-link-hover2 block-bordered selectable"
          onClick={this.methodSelected}
        >
          <div className="block-content block-content-full clearfix">
            {this.icon()}
            {this.methodText()}
          </div>
        </a>
        <SelectHeardAboutMethodModalContainer
          {...this.props}
          modalName={HEARD_ABOUT_METHOD_MODAL}
        />
      </div>
    );
  }
}

HeardAboutPanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
};
