import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

export class EditNoteModal extends Component {
  state = {
    oldDetail: this.props.note.detail,
    detail: this.props.note.detail,
  };

  handleChange = (event) => {
    const textValue = event.target.value;
    this.setState({ ...this.state, detail: textValue });
  };

  updateNote = () => {
    const { note, lead, updateNoteForLead } = this.props;
    const { detail } = this.state;
    updateNoteForLead(lead, note, detail);
  };

  addNoteDisabled = () => {
    const { detail, oldDetail } = this.state;

    if (detail === oldDetail) {
      return true;
    }
    return !(detail && detail.length > 1);
  };

  render() {
    const { lead, loading, currentModal, hideModal, modalName } = this.props;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal} backdrop="static">
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Update note For {lead.given_name}
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <textarea
                  className="form-control"
                  name="note-detail"
                  rows="8"
                  placeholder="What is your note?"
                  value={this.state.detail}
                  onChange={this.handleChange}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn btn-primary push-5-r push-10" type="button" disabled={this.addNoteDisabled()} onClick={this.updateNote}>
              <i className="fa fa-save" /> Update Note
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

EditNoteModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  note: PropTypes.object.isRequired,
  updateNoteForLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default EditNoteModal;
