import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SELECT_DEALS_MODAL } from "../constants";
import DealsSection from "../components/DealsSection";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  const dealIdsName = "deal_ids";
  const dealIdsData =
    props.filterData && props.filterData[dealIdsName]
      ? props.filterData[dealIdsName]
      : { fields: {} };
  const dealsCount = Object.keys(dealIdsData.fields).length;
  const clubsFiltersClass = dealsCount > 0 ? "btn btn-info" : "btn btn-default";
  const clubTitle = dealsCount > 0 ? `${dealsCount} selected` : "All deals";

  return {
    SELECT_DEALS_MODAL: SELECT_DEALS_MODAL,
    dealIdsName: dealIdsName,
    clubTitle: clubTitle,
    clubsFiltersClass: clubsFiltersClass,
    dealIdsData: dealIdsData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealsSection);
