import React, { FC } from 'react';
import 'styles/loaders.min.css';

interface GymLeadsLoaderProps {
  active?: boolean;
  text?: string;
  center?: boolean;
  color?: string;
}

const GymLeadsLoader: FC<GymLeadsLoaderProps> = (props) => {
  const { text, active = false, center = true, color = '#5c90d2' } = props;
  const visibility = !active ? 'hidden' : 'visible';
  const height = active ? '100%' : '0px';
  const display = center ? 'table' : 'auto';
  return (
    <div style={{ width: '100%', visibility: visibility, height: height }}>
      <div className="loader-inner ball-pulse" style={{ display: display, margin: '0 auto' }}>
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
      </div>
      {text && <p className="text-center">{text}</p>}
    </div>
  );
};

export default GymLeadsLoader;
