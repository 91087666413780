/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import RawToolTip from "modules/components/ToolTip/RawToolTip";

export class UserEmailView extends Component {
  componentDidMount() {
    const { userEmail, getUserEmail } = this.props;
    if (!userEmail) {
      getUserEmail();
    }
  }

  render() {
    const { userEmail } = this.props;
    const emailTextStyle = userEmail && userEmail.usable ? "" : "text-muted";
    return (
      <div>
        <div className="block block-rounded block-bordered">
          <div className="block-header">
            <h3 className="block-title">
              Your {this.props.productName} Email address
            </h3>
          </div>
          <div className="block-content">
            <div className="row">
              <div className="col-md-10">
                {!userEmail && (
                  <p>
                    You don't seem to have an email address setup for your
                    profile. Send us a support message and we'll get one setup
                    for you
                  </p>
                )}
                {userEmail && (
                  <div className="list-group">
                    <a className="list-group-item selectable">
                      <i className="si si-envelope push-5-r" />
                      <span className={emailTextStyle}>
                        {userEmail.full_address}
                      </span>
                      {userEmail.usable === false && (
                        <span>
                          {" "}
                          <i
                            className="fa fa-warning push-5-r text-warning"
                            data-tip
                            data-for={"email-warning"}
                          />
                          <RawToolTip
                            id={"email-warning"}
                            text={"We're still still setting this email up."}
                          />
                        </span>
                      )}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="row push-10">
              <div className="col-xs-12">
                {/* eslint-disable-next-line no-mixed-operators */}
                {!userEmail ||
                  (userEmail.usable === false && (
                    <p>
                      We're still setting this email address up for you. If it
                      isn't ready in 24 hours, let us know and we'll see what we
                      can do
                    </p>
                  ))}
                <p className="text-muted">
                  This is your {this.props.productName} email address. Any
                  emails you send from the system will come from this address.
                  Any emails that are sent to this address from a lead will be
                  stored in their profile
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserEmailView.propTypes = {
  userEmail: PropTypes.object,
  getUserEmail: PropTypes.func.isRequired,
};

export default UserEmailView;
