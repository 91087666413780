import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SELECT_COUNTRIES_MODAL } from "../constants";
import CountriesSection from "../components/CountriesSection";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  const countryIdsName = "country_ids";
  const countryIdsData =
    props.filterData && props.filterData[countryIdsName]
      ? props.filterData[countryIdsName]
      : { fields: {} };
  const countriesCount = Object.keys(countryIdsData.fields).length;
  const clubsFiltersClass =
    countriesCount > 0 ? "btn btn-info" : "btn btn-default";
  const clubTitle =
    countriesCount > 0 ? `${countriesCount} selected` : "All countries";

  return {
    SELECT_COUNTRIES_MODAL: SELECT_COUNTRIES_MODAL,
    countryIdsName: countryIdsName,
    clubTitle: clubTitle,
    clubsFiltersClass: clubsFiltersClass,
    countryIdsData: countryIdsData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesSection);
