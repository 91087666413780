import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import PrivateRoute from './PrivateRoute';

import session from 'modules/Stores/Session';

const mapStateToProps = (state) => {
  return {
    user: session.selectors.getUser(state),
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAttemptedUrl: session.actions.setAttemptedUrl,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
