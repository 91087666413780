import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LinkedFacebookPageRowContainer from '../../containers/LinkedFacebookPageRowContainer';
import { FormFields, ToolTip } from 'modules/components';

import { SELECT_LEAD_SOURCE_VIEW, SELECT_LEAD_GOAL_VIEW } from './FormView';
const { Switch } = FormFields;

export class FormConfigureIntegrationView extends Component {
  handleToggle = (fieldName, value) => {
    const { location, integration, permissionSet, setPermissionForSet } = this.props;
    const permissionSetId = permissionSet.id;
    const locationId = location.id;
    const integrationId = integration.id;
    setPermissionForSet(locationId, integrationId, permissionSetId, fieldName, value);
  };

  showSelectLeadSourceView = (linkedFacebookPage) => {
    this.props.setCurrentView(SELECT_LEAD_SOURCE_VIEW, linkedFacebookPage);
  };

  showSelectLeadGoalView = (linkedFacebookPage) => {
    this.props.setCurrentView(SELECT_LEAD_GOAL_VIEW, linkedFacebookPage);
  };

  render() {
    const { integration, permissionSet } = this.props;

    const rows = integration.third_party_integrations_facebook_pages.map((linkedFacebookPage, index) => {
      return (
        <LinkedFacebookPageRowContainer
          canDelete={integration.third_party_integrations_facebook_pages.length > 1}
          key={index}
          linkedFacebookPage={linkedFacebookPage}
          showSelectLeadSourceView={this.showSelectLeadSourceView}
          showSelectLeadGoalView={this.showSelectLeadGoalView}
        />
      );
    });

    // Integration Permission Set Attributes
    const chatbotValue = permissionSet?.chatbot || false;
    const leadSubmissionValue = permissionSet?.lead_submission || false;
    const leadAdAutoGenerateHeardAboutMethods = permissionSet?.facebook_lead_ad_auto_generate_heard_about_method || false;
    const leadAdAutoGenerateTags = permissionSet?.facebook_lead_ad_auto_generate_tags || false;

    return (
      <div>
        <p>Facebook integration is complete for this club</p>
        <p>
          {this.props.productName} can help qualify leads using the chatbot and bring their details into your account, as well as pull in
          lead ad submissions.
        </p>
        <p>You can also change the lead source or goals for leads that come from the page.</p>
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-vcenter">
              <thead>
                <tr>
                  <th style={{ width: '60px' }} />
                  <th>Page Name</th>
                  <th>Lead Source</th>
                  <th>Lead Goal</th>
                  <th style={{ width: '60px' }} />
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h5 style={{ marginBottom: '15px' }}>Integration permissions:</h5>
            <Switch
              name="chatbot"
              label={
                <span>
                  Chatbot <ToolTip text={'Activates the Facebook Chatbot on your Facebook page.'} id="chatbot" />
                </span>
              }
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={chatbotValue}
            />
            <Switch
              name="lead_submission"
              label={
                <span>
                  Facebook LeadAd{' '}
                  <ToolTip text={'Activates the Facebook LeadAd Submission functionality for your Facebook page.'} id="lead_submission" />
                </span>
              }
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={leadSubmissionValue}
            />
            <>
              <Switch
                name="facebook_lead_ad_auto_generate_tags"
                label={
                  <span>
                    Lead Ad: Auto Generate Tags{' '}
                    <ToolTip
                      text={'If this is enabled, tags that identify the lead ad will automatically be add to the lead'}
                      id="facebook_lead_ad_auto_generate_tags"
                    />
                  </span>
                }
                onChange={this.handleToggle}
                switchStyle="switch-sm switch-primary"
                value={leadAdAutoGenerateTags}
              />
              <Switch
                name="facebook_lead_ad_auto_generate_heard_about_method"
                label={
                  <span>
                    Lead Ad: Auto Generate Heard About methods{' '}
                    <ToolTip
                      text={'If this is enabled, a heard about method will automatically be generated for this lead'}
                      id="facebook_lead_ad_auto_generate_heard_about_method"
                    />
                  </span>
                }
                onChange={this.handleToggle}
                switchStyle="switch-sm switch-primary"
                value={leadAdAutoGenerateHeardAboutMethods}
              />
            </>
          </div>
        </div>
      </div>
    );
  }
}

FormConfigureIntegrationView.propTypes = {
  integration: PropTypes.object.isRequired,
  setPermissionForSet: PropTypes.func.isRequired,
  permissionSet: PropTypes.object,
  location: PropTypes.object.isRequired,
  setCurrentView: PropTypes.func.isRequired,
};

export default FormConfigureIntegrationView;
