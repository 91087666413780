import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'images/logo.png';
import { EmptyView } from 'modules/components';

import ResetPasswordForm from './ResetPasswordForm';

export class ResetPasswordView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchValidateToken, token, fetchWhitelabelProfileLogo, productName } = this.props;

    if (productName) {
      fetchWhitelabelProfileLogo(productName);
    }
    if (token) {
      fetchValidateToken(token);
    }
  }

  setLogo() {
    const { whitelabelProfile, productName } = this.props;
    if (!productName) {
      return logo;
    } else if (whitelabelProfile && whitelabelProfile.logo_url) {
      return whitelabelProfile.logo_url;
    } else if (whitelabelProfile && whitelabelProfile.name === 'Gymleads') {
      return logo;
    }
  }

  getLoginUrl() {
    const { productName } = this.props;
    if (productName) {
      return '/login?client=' + productName;
    } else {
      return '/login';
    }
  }

  setCopyright() {
    if (this.props.productName) {
      return (
        <div>
          <span className="js-year-copy">2016-2022</span> © Powered by GymLeads
        </div>
      );
    } else {
      return (
        <div>
          <span className="js-year-copy">2016-2022</span> © GymLeads
        </div>
      );
    }
  }

  render() {
    const { resetPassword, validateToken, success, loading, errors } = this.props;
    return (
      <div>
        <div className="bg-white pulldown">
          <div className="content context-boxed overflow-hidden">
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <div className="push-30-t push-50 animated fadeIn">
                  <div className="text-center">
                    <img src={this.setLogo()} alt="logo" style={{ height: '80px', paddingBottom: '10px' }} />
                    {validateToken && !success && (
                      <div>
                        <p>Enter and confirm your new password below. For your security this reset session will expire in 30 minutes.</p>
                        <ResetPasswordForm
                          loading={loading}
                          errors={errors}
                          success={success}
                          validateToken={validateToken}
                          onSubmit={resetPassword}
                        />
                      </div>
                    )}
                    {!validateToken && errors && (
                      <div>
                        <EmptyView
                          colorThemeClass="danger"
                          iconClass="fa fa-frown-o"
                          title="Invalid Reset Session"
                          subtitle="This reset link has probably been used or timed out. Try sending another
                           reset email or, if you keep running into trouble, get in touch with us and we can
                           help you out"
                        />
                        <p className="push-50 text-center">
                          <Link to={this.getLoginUrl()}> Login</Link>
                        </p>
                      </div>
                    )}
                    {!validateToken && !errors && !success && (
                      <div>
                        <h3 className="push-20 push-20-t">Validating Session</h3>
                        <i className="fa fa-2x fa-sun-o fa-spin" />
                      </div>
                    )}
                    {success && (
                      <div>
                        <p>Your password has been reset. You can now log in using your new password</p>
                        <p className="push-50 text-center">
                          <Link to={this.getLoginUrl()}> Login</Link>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pulldown push-30-t text-center animated fadeInUp">
          <small className="text-muted">{this.setCopyright()}</small>
        </div>
      </div>
    );
  }
}

ResetPasswordView.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  fetchValidateToken: PropTypes.func.isRequired,
  token: PropTypes.string,
  validateToken: PropTypes.string,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  errors: PropTypes.object,
};
