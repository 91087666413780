import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'
import { ContentBlockView } from 'modules/components'

import EditLocationFormContainer from '../containers/EditLocationFormContainer'

import { userCanAccessBilling } from 'modules/Stores/Session/helpers'
import { cardTypeForNumber, lastFourDigitsOfNumber } from 'modules/Stores/Billing/ccUtils'
import moment from 'moment'

import { SELECT_SUBSCRIPTION_PAYMENT_METHOD_MODAL, SELECT_SMS_PAYMENT_METHOD_MODAL } from '../constants'
import SelectPaymentMethodModalContainer from '../containers/SelectPaymentMethodModalContainer'

export class EditLocationView extends Component {

  UNSAFE_componentWillMount () {
    const { location, prefillForm } = this.props
    prefillForm(location)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.id !== this.props.location.id) {
      const { location, prefillForm } = nextProps
      prefillForm(location)
    }
  }

  saveClicked = () => {
    const { saveWithData, location, data } = this.props
    saveWithData(location, data)
  }

  selectSubscriptionPaymentMethod = () => {
    this.props.showModal(SELECT_SUBSCRIPTION_PAYMENT_METHOD_MODAL)
  }

  selectSmsPaymentMethod = () => {
    this.props.showModal(SELECT_SMS_PAYMENT_METHOD_MODAL)
  }

  render () {
    const { onInput, loading, data, location, errors, user,
      subscriptionPaymentMethod, smsPaymentMethod } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>{location.admin_display_name} Details</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <EditLocationFormContainer
                data={data}
                countries={this.props.countries}
                onSaveSelected={this.saveClicked}
                onInput={onInput} />
            </form>
          </div>
        </div>
        <div className='row'>
          {userCanAccessBilling(user) && subscriptionPaymentMethod &&
            <div className='col-md-6'>
              <ContentBlockView
                title={location.admin_display_name + ' subscription payment method'}
                bordered>
                <p>
                  <i className='si si-credit-card push-10-r' />
                  Using {cardTypeForNumber(subscriptionPaymentMethod.masked_number)}{' '}
                  ending in {lastFourDigitsOfNumber(subscriptionPaymentMethod.masked_number)}{' '}
                  added {moment(subscriptionPaymentMethod.created_at).format('MMMM Do YYYY')}
                </p>
                <button className='btn btn-default push-20' onClick={this.selectSubscriptionPaymentMethod}>
                  Update subscription payment method
                </button>
              </ContentBlockView>
              <SelectPaymentMethodModalContainer
                idValue={'subscription_paychoice_payment_method_id'}
                modalName={SELECT_SUBSCRIPTION_PAYMENT_METHOD_MODAL}
                location={location} />
            </div>
          }
          {userCanAccessBilling(user) &&
            <div className='col-md-6'>
              <ContentBlockView
                title={location.admin_display_name + ' sms payment method'}
                bordered>
                {smsPaymentMethod &&
                  <p>
                    <i className='si si-credit-card push-10-r' />
                    Using {cardTypeForNumber(smsPaymentMethod.masked_number)}{' '}
                    ending in {lastFourDigitsOfNumber(smsPaymentMethod.masked_number)}{' '}
                    added {moment(smsPaymentMethod.created_at).format('MMMM Do YYYY')}
                  </p>
                }
                {!smsPaymentMethod &&
                  <div>
                    <p>No SMS payment method. <br /> Add a payment method to send SMS</p>
                  </div>
                }
                <button className='btn btn-default push-20' onClick={this.selectSmsPaymentMethod}>
                  Update SMS payment method
                </button>
                <SelectPaymentMethodModalContainer
                  idValue={'sms_paychoice_payment_method_id'}
                  modalName={SELECT_SMS_PAYMENT_METHOD_MODAL}
                  location={location} />
              </ContentBlockView>
            </div>
          }
        </div>
      </div>
    )
  }
}

EditLocationView.propTypes = {
  user: PropTypes.object.isRequired,
  saveWithData: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  prefillForm: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  subscriptionPaymentMethod: PropTypes.object,
  smsPaymentMethod: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.object
}

export default EditLocationView
