import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const getLeadsWithQueryAndPageNumber = (queryString, pageNumber) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setLeads(payload.leads));
      dispatch(loadPageDetails(payload.meta));
    }

    return request('leads?page[number]=' + pageNumber + '&' + encodeURI(queryString), GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setLeads(leads) {
  return {
    type: actionTypes.SET_LEADS,
    leads,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
