import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import { FormFields } from 'modules/components'
import { validatorForOption } from '../../validators'
import { fieldsForOption } from '../../helpers'

const { TextField } = FormFields

export class FormView extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  render () {
    const { companyName } = this.props.data.fields
    const validator = validatorForOption('mindbody')
    const fields = fieldsForOption('mindbody')
    return (
      <div>
        <div className='row push'>
          <div className='col-md-12'>
            <h2 className='h4 push-10'>Integrate MINDBODY</h2>
            <p className='text-gray-dark'>Connect {this.props.productName} with your MINDBODY account.</p>
            <p className='text-gray-dark'>Start by entering your club/studio ID.{' '}
            You can find this in the bottom right hand corner of your website, in the footer.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <TextField name='companyName'
              label={fields.companyName}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={companyName.value} />
          </div>
        </div>
      </div>
    )
  }
}

FormView.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default FormView
