import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import graphBreakdown from '../modules/graphBreakdown'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  const dataSets = graphBreakdown.selectors.datasets(state)
  return {
    loading: graphBreakdown.selectors.getLoading(state),
    data: dataSets,
    labels: graphBreakdown.selectors.dataLabels(state),
    errors: graphBreakdown.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    isEmpty: dataSets.length === 0,
    compareOption: filters.selectors.getCompareOption(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: graphBreakdown.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(graphBreakdown.components.GraphBreakdownView)
