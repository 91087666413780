import { request, GET, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import details from '../details';
import notify from 'modules/Notifications';

export const getStepsForCallSchedule = (callScheduleId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setSteps(payload.call_schedule_steps));
    }

    const url = 'call_schedules/' + callScheduleId + '/call_schedule_steps';
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setSteps(steps) {
  return {
    type: actionTypes.SET_STEPS,
    steps,
  };
}

export const removeFromSchedule = (lead) => {
  return (dispatch) => {
    const body = {
      lead: {
        call_schedule_step_id: null,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(details.actions.setLead(payload.lead));
      notify.success('Removed from call schedule');
    }

    return request('leads/' + lead.id, PUT, body, onSuccess, onFailure);
  };
};
