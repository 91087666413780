import { StyledSystemProps } from './DynamicStyledSystemComponent';

export const fontFamilies: { sourceSans: string } = {
  sourceSans: "'Source Sans Pro', sans-serif;",
};

interface TypographyStyles {
  H1: StyledSystemProps;
  H2: StyledSystemProps;
  H3: StyledSystemProps;
  H4: StyledSystemProps;
  H5: StyledSystemProps;
  TableHeader: StyledSystemProps;
  P1: StyledSystemProps;
  P2: StyledSystemProps;
  PS: StyledSystemProps;
  Link: StyledSystemProps;
}

export const typographyStyles: TypographyStyles = {
  H1: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 600,
    fontSize: 46,
    lineHeight: '52px',
    color: 'navy.0',
  },
  H2: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 600,
    fontSize: 36,
    lineHeight: '42px',
    color: 'navy.0',
  },
  H3: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '32px',
    color: 'navy.0',
  },
  H4: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '28px',
    color: 'navy.0',
  },
  H5: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    color: 'navy.0',
  },
  TableHeader: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: 'navy.0',
  },
  P1: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
    color: 'navy.0',
  },
  P2: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    color: 'navy.0',
  },
  PS: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: 'navy.0',
  },
  Link: {
    fontFamily: fontFamilies.sourceSans,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: 'navy.0',
  },
};
