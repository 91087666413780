import { combineReducers } from 'redux';
import header from './modules/header';
import filters from './modules/filters';
import contactCalls from './modules/contactCalls';
import appointments from './modules/appointments';
import tasks from './modules/tasks';

export default combineReducers({
  [header.constants.NAME]: header.reducer,
  [filters.constants.NAME]: filters.reducer,
  [contactCalls.constants.NAME]: contactCalls.reducer,
  [appointments.constants.NAME]: appointments.reducer,
  [tasks.constants.NAME]: tasks.reducer,
});
