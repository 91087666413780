import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  IIntegrationPermissionSet,
  ILocation,
  IThirdPartyIntegration,
  IThirdPartyMembership,
  ITrial,
  ITrialMembershipMapping,
} from 'types';
import { Box, Button } from 'modules/components';
import { PermissionsView } from './PermissionsView';
import TrialMappingContainer from './TrialMappingView';

enum FormViewType {
  PermissionsView = 1,
  TrialMapping = 2,
}

interface IFormViewProps {
  data: any;
  getTrialsMembershipsMappings: (integrationId: Number) => void;
  getMemberships: (integrationId: Number) => void;
  getTrials: () => void;
  mapTrialToMembership: (integrationdId: number, membershipId: number, trialId: number) => void;
  deleteTrialMembershipMapping: (integrationdId: number, mappingId: number) => void;
  trials: ITrial[];
  memberships: IThirdPartyMembership[];
  trialsMembershipsMappings: ITrialMembershipMapping[];
  setPermissionForSet: (id: number, integrationId: number, permissionSetId: number, fieldName: string | undefined, value: boolean) => void;
  location: ILocation;
  permissionSet?: IIntegrationPermissionSet;
  selectedIntegration: IThirdPartyIntegration;
  addOrUpdateOptionWithData: (formData: any, location: ILocation, toTabIndex: number | undefined, body: any) => void;
  showModal: (modalName: string) => void;
}

const FormView: FC<IFormViewProps> = (props) => {
  const [currentView, setCurrentView] = useState(FormViewType.PermissionsView);
  const { data, selectedIntegration } = props;
  const { companyName, username } = data.fields;

  const renderCurrentView = () => {
    switch (currentView) {
      case FormViewType.PermissionsView:
        return (
          <PermissionsView
            data={props.data}
            setPermissionForSet={props.setPermissionForSet}
            location={props.location}
            permissionSet={props.permissionSet}
            selectedIntegration={selectedIntegration}
            addOrUpdateOptionWithData={props.addOrUpdateOptionWithData}
          />
        );
      case FormViewType.TrialMapping:
        return <TrialMappingContainer integrationId={selectedIntegration.id} />;
    }
  };

  const renderViewButtons = () => {
    switch (currentView) {
      case FormViewType.PermissionsView:
        return <Button.Primary1 onClick={() => setCurrentView(FormViewType.TrialMapping)}>Manage Trial Mappings</Button.Primary1>;
      case FormViewType.TrialMapping:
        return <Button.Primary1 onClick={() => setCurrentView(FormViewType.PermissionsView)}>Manage Permissions</Button.Primary1>;
    }
  };

  return (
    <div>
      <div className="row push">
        <div className="col-md-12">
          <ModalHeadline>This club is now integrated with MINDBODY</ModalHeadline>
          <p>
            <strong>Site/Club ID:</strong> {companyName.value}
            <br />
            <strong>MINDBODY Location ID:</strong> {username.value}
          </p>
          <hr />
        </div>
      </div>
      {renderCurrentView()}
      <Box row>{renderViewButtons()}</Box>
    </div>
  );
};

const ModalHeadline = styled.h4`
  font-weight: bold;
  margin-bottom: 15px;
`;

export default FormView;
