import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectToUsersForm from '../components/SelectToUsersForm'

import selectLocation from '../../selectLocation'
import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  const locationId = selectLocation.selectors.getLocationId(state)
  return {
    loading: selectors.getLoading(state),
    currentLocationId: locationId,
    potentialUsers: selectors.getPotentialUsers(state),
    toUserIds: selectors.getToUserIds(state),
    pageDetails: selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getUsers: actions.getPotentialUsersForLocationId,
    addToUserId: actions.addToUserId,
    removeFromUserId: actions.removeFromUserId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectToUsersForm)
