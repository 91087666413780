import session from 'modules/Stores/Session';
import STRINGS from 'strings';

export function linksForUser(user) {
  const Strings = STRINGS.Menu;
  const standardLinks = [
    {
      to: '/secure/dashboard',
      style: 'fa-dashboard',
      title: Strings.dashboard,
    },
    {
      to: '/secure/manageLeads',
      style: 'fa-users',
      title: Strings.manageLeads,
    },
  ];

  standardLinks.push({
    to: '/secure/calendar',
    style: 'fa-calendar',
    title: Strings.calendar,
  });

  if (session.helpers.canAccessReports(user)) {
    standardLinks.push({
      to: '/secure/reports',
      style: 'fa-table',
      title: Strings.reports,
    });
  }

  standardLinks.push({
    to: '/secure/settings',
    style: 'fa-gear',
    title: Strings.settings,
  });

  return standardLinks;
}
