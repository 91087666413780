import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.dashboard[NAME].errors
export const getLoading = state => state.dashboard[NAME].loading
export const getAppointments = state => state.dashboard[NAME].appointments
export const getSelectedAppointmentId = state => state.dashboard[NAME].selectedAppointmentId
export const getPageCount = state => state.dashboard[NAME].pageCount
export const getCurrentPage = state => state.dashboard[NAME].currentPage
export const getTotalCount = state => state.dashboard[NAME].totalCount

export const selectedAppointment = createSelector(
  [ getAppointments, getSelectedAppointmentId ],
  (appointments, appointmentId) => {
    const index = appointments.findIndex(appointment => parseInt(appointment.id) === parseInt(appointmentId))
    return appointments[index]
  }
)
