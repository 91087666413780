import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, PageSelection } from 'modules/components'

import ImportRowContainer from '../containers/ImportRowContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

export class PreviousImportsView extends Component {

  state = {
    idToDelete: false
  }

  UNSAFE_componentWillMount () {
    this.pageClicked(1)
  }

  showDeleteWarningPopup = (id) => {
    this.setState({ ...this.state, idToDelete: id })
  }

  startImportReverse = () => {
    const { reverseImportWithId } = this.props
    this.dismissPopups()
    reverseImportWithId(this.state.idToDelete)
    this.setState({ ...this.state, idToDelete: null })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, idToDelete: null })
  }

  pageClicked = (pageNumber) => {
    const { getImportsWithPageNumber } = this.props
    getImportsWithPageNumber(pageNumber)
  }

  currentImport = () => {
    const { imports } = this.props
    const index = imports
    .findIndex(csvImport => parseInt(csvImport.id, 10) === parseInt(this.state.idToDelete, 10))
    return imports[index]
  }

  render () {
    const { imports, pageCount, loading, currentPage } = this.props

    const importRows = imports.map((csvImport, index) => {
      return (
        <ImportRowContainer
          key={index}
          csvImport={csvImport}
          reverseImport={this.showDeleteWarningPopup} />
      )
    })
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Previous imports</h3>
          </div>
          <div className='block-content'>
            {imports.length === 0 &&
              <div style={{ paddingBottom: '30px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='glyphicon glyphicon-import'
                  title='No imports'
                  subtitle='Previous imports will be shown here' />
              </div>
            }
            {imports.length > 0 &&
              <table className='table table-vcenter'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Location</th>
                    <th>User</th>
                    <th>Status</th>
                    <th>Leads</th>
                    <th style={{ width: '15%' }} />
                  </tr>
                </thead>
                <tbody>
                  {importRows}
                </tbody>
              </table>
            }
            {pageCount > 1 &&
              <div className='text-center push'>
                <PageSelection
                  pageCount={pageCount}
                  currentPage={currentPage}
                  pageClicked={this.pageClicked} />
              </div>
            }
            {this.state.idToDelete &&
              <div>
                <SweetAlert
                  danger
                  showCancel
                  cancelBtnBsStyle='default'
                  confirmBtnBsStyle='danger'
                  confirmBtnText='Delete all of these leads'
                  title='Are you (really) sure?'
                  onConfirm={this.startImportReverse}
                  onCancel={this.dismissPopups}>
                  This import will be reversed. All {this.currentImport().lead_count}{' '}
                  leads will be deleted, along with all of their history. This cannot be undone.
                </SweetAlert>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

PreviousImportsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  imports: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  getImportsWithPageNumber: PropTypes.func.isRequired,
  reverseImportWithId: PropTypes.func.isRequired
}

export default PreviousImportsView
