import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EmailDomainStatsView from '../components/EmailDomainStatsView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    domain: selectors.selectedDomain(state),
    statistics: selectors.getStatistics(state),
    rates: selectors.getRates(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getDomainStatistics: actions.getDomainStatistics
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailDomainStatsView)
