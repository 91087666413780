import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

import locations from 'modules/Stores/Locations';
import modal from 'modules/Stores/Modal';

export const updateLocationWithData = (location, formData) => {
  return (dispatch) => {
    const body = seralizeLocationFromFormData(formData);
    dispatch(startUpdating());
    function onFailure(payload) {
      dispatch(updateFailed(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.location));
      dispatch(locations.actions.editLocation(payload.location));
      notify.success('Location updated');
    }

    return request('locations/' + location.id, PUT, body, onSuccess, onFailure);
  };
};

export const updateLocationPaymentMethod = (location, paymetMethodId, idValue) => {
  return (dispatch) => {
    const body = {
      location: {
        [idValue]: paymetMethodId,
      },
    };
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
      dispatch(modal.actions.stopLoading());
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.location));
      dispatch(locations.actions.editLocation(payload.location));
      dispatch(modal.actions.stopLoading());
      notify.success('Payment method updated');
      dispatch(modal.actions.hideModal());
    }

    return request('locations/' + location.id, PUT, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.UPDATING,
  };
}

function updateSuccessful(user) {
  return {
    type: actionTypes.UPDATE_SUCCESS,
    user,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function prefillForm(location) {
  return {
    type: actionTypes.PREFILL_FORM,
    location,
  };
}

function seralizeLocationFromFormData(formData) {
  const { addressLineOne, addressLineTwo, city, state, countryId, timezone, zipPostcode, name, adminName, phoneNumber } = formData.fields;

  let payload = {};

  payload = newPayloadForFormValue(payload, name, 'name');
  payload = newPayloadForFormValue(payload, adminName, 'admin_name');
  payload = newPayloadForFormValue(payload, addressLineOne, 'address_line_one');
  payload = newPayloadForFormValue(payload, addressLineTwo, 'address_line_two');
  payload = newPayloadForFormValue(payload, phoneNumber, 'phone_number');
  payload = newPayloadForFormValue(payload, city, 'city');
  payload = newPayloadForFormValue(payload, timezone, 'timezone');
  payload = newPayloadForFormValue(payload, state, 'state');
  payload = newPayloadForFormValue(payload, countryId, 'country_id');
  payload = newPayloadForFormValue(payload, zipPostcode, 'zip_postcode');
  payload = newPayloadForFormValue(payload, zipPostcode, 'zip_postcode');

  return payload;
}
