import { request, buildIndexUrl, GET } from "utils/apiUtils";

import store from "./index";

export const getTextMessageTemplates = (pageNumber, searchTerm = null) => {
  return dispatch => {
    dispatch(store.actions.setLoading(true));
    function onFailure(payload) {
      dispatch(store.actions.setLoading(false));
      dispatch(store.actions.setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(store.actions.setLoading(false));
      dispatch(
        store.actions.setTextMessageTemplates(payload.text_message_templates)
      );
      dispatch(store.actions.setPageDetails(payload.meta));
    }

    const url = buildIndexUrl(
      "text_message_template/templates_to_use",
      pageNumber,
      null,
      searchTerm
    );
    return request(url, GET, null, onSuccess, onFailure);
  };
};
