import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PagesView from 'modules/components/PageSelection/PagesView';
import { FormFields, TextSearchView } from 'modules/components';

import { MIGRATE_LEAD_GOAL_MODAL } from '../constants';
import MigrateLeadGoalModalContainer from '../containers/MigrateLeadGoalModalContainer';
const { InlineTextField } = FormFields;

export class LeadGoalsView extends Component {
  state = {
    goalToDelete: null,
    newLeadGoalText: null,
  };

  UNSAFE_componentWillMount() {
    const { leadGoals } = this.props;
    if (leadGoals.length > 0) {
      return;
    }
    this.getLeadGoalsWithPageNumber(1);
  }

  getLeadGoalsWithPageNumber = (pageNumber) => {
    const { getLeadGoals } = this.props;
    getLeadGoals(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadGoalsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadGoals } = this.props;
    getLeadGoals(1, searchTerm);
  };

  handleInput = (fieldName, textValue) => {
    this.setState({ ...this.state, newLeadGoalText: textValue });
  };

  addLeadGoalSelected = () => {
    const leadGoalText = this.state.newLeadGoalText;
    this.setState({ ...this.state, newLeadGoalText: null });
    this.props.createLeadGoal(leadGoalText);
  };

  migrateLeadGoalClicked = (leadGoal) => {
    this.setState({ ...this.state, goalToDelete: leadGoal });
    this.props.showModal(MIGRATE_LEAD_GOAL_MODAL);
  };

  render() {
    const { canEdit } = this.props;
    const goals = this.props.leadGoals.map((goal, index) => {
      return (
        <div key={index} className="col-lg-4">
          <div
            className="block block-bordered"
            style={{
              paddingTop: '5px',
              paddingLeft: '5px',
              paddingBottom: '5px',
              paddingRight: '5px',
            }}
          >
            {goal.goal_name}
            {canEdit && (
              <button className="btn btn-xs btn-default pull-right" type="button" onClick={() => this.migrateLeadGoalClicked(goal)}>
                <i className="fa fa-times" />
              </button>
            )}
          </div>
        </div>
      );
    });

    const blockStyle = this.props.loading ? 'block-opt-refresh' : '';
    const pageCount = this.props.pageDetails.pageCount;

    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Lead Goals</h3>
          </div>
          <div className="block-content bg-gray-lighter">
            <div className="row" style={{ marginTop: '-30px' }}>
              <TextSearchView sectionName={'leadGoalSearch'} onUpdate={this.searchUpdated} />
            </div>
          </div>
          <div className="block-content">
            <div className="row">{goals}</div>
            <div className="row">
              {canEdit && (
                <>
                  <hr />
                  <div className="col-md-8">
                    <InlineTextField
                      name="newLeadGoalText"
                      label="New Lead Goal"
                      placeholder="CrossFit"
                      formFieldStyle=""
                      value={this.state.newLeadGoalText}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-success push-5-r push-10"
                      type="button"
                      disabled={!this.state.newLeadGoalText || this.state.newLeadGoalText.length === 0}
                      onClick={this.addLeadGoalSelected}
                    >
                      <i className="fa fa-plus" /> Add lead goal
                    </button>
                  </div>
                </>
              )}
            </div>
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageClicked={this.pageClicked} pageDetails={this.props.pageDetails} />
              </div>
            )}
          </div>
        </div>
        {this.state.goalToDelete && (
          <MigrateLeadGoalModalContainer modalName={MIGRATE_LEAD_GOAL_MODAL} leadGoal={this.state.goalToDelete} />
        )}
      </div>
    );
  }
}

LeadGoalsView.propTypes = {
  leadGoals: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  createLeadGoal: PropTypes.func.isRequired,
  deleteLeadGoal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default LeadGoalsView;
