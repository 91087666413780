import * as actionTypes from './actionTypes'

import { safeBooleanPropertyForObject, safePropertyForObject } from 'utils/formHelpers'

const LEAD_TIME_OUT_ACTION_HANDLERS = {
  [actionTypes.PREFIL_TARGET_METRICS]: (state, action) => {
    return initialStateForSalesTargets(action.salesTargets)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    const newData = action.data
    return { ...state, data: newData }
  },
  [actionTypes.UPDATING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

function initialStateForSalesTargets (salesTargets) {
  return {
    loading: false,
    errors: null,
    data: {
      isValid: true,
      fields: {
        leadTimeOutEnabled: {
          value: safeBooleanPropertyForObject(salesTargets, 'lead_time_out_enabled'),
          isValid: true
        },
        leadTimeOutMinutes: {
          value: safePropertyForObject(salesTargets, 'lead_time_out_minutes'),
          isValid: true
        }
      }
    }
  }
}

export default function reducer (state = initialStateForSalesTargets(null), action) {
  const handler = LEAD_TIME_OUT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
