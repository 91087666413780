import PropTypes from 'prop-types'
import React, { Component } from 'react'
import UserAvatar from 'modules/components/Avatars/UserAvatar'

export class SelectUserTableRow extends Component {

  rowSelected = (event) => {
    const { user, onUserSelect } = this.props
    onUserSelect(user)
  }

  render () {
    const { user, selected } = this.props
    const imageStyle = {
      width: '40px',
      height: '40px',
      borderRadius: '20px'
    }
    const style = (selected) ? 'selected' : 'selectable'

    return (
      <tr className={style}
        onClick={this.rowSelected}>
        <td className='text-center'>
          <UserAvatar avatarImage={user.user_avatar} type={'thumb'} style={imageStyle} />
        </td>
        <td>{user.display_name}</td>
      </tr>
    )
  }
}

SelectUserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onUserSelect: PropTypes.func.isRequired
}

export default SelectUserTableRow
