import React from 'react';
import { push } from 'react-router-redux';
import { request, buildIndexUrl, GET, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import queuedLeads from 'containers/Dashboard/modules/QueuedLeads';
import leadsList from 'containers/ManageLeads/modules/leadsList';

import { seralizeLeadFromData } from './helpers';
import notify, { ToastButtonView } from 'modules/Notifications';

import modal from 'modules/Stores/Modal';

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_LEAD_DATA,
    data,
  };
}

export const getUsers = (page, search, locationId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setUsers(payload.users));
      dispatch(setPageDetails(payload.meta));
      dispatch(setLoading(false));
    }
    let url = buildIndexUrl('users', page, null, search);
    url = url + '&location_ids[]=' + locationId;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const create = (leadData, locationId, assignToId, leadSourceId, leadGoal, heardAboutMethodId, queryBody) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setExistingLead(null));
    var body = seralizeLeadFromData(leadData, locationId, assignToId, leadSourceId, leadGoal, heardAboutMethodId);

    if (queryBody) {
      body = { ...body, should_show_params: queryBody };
    }

    function onFailure(payload) {
      dispatch(setLoading(false));
      if (payload.meta) {
        dispatch(setExistingLead(payload.meta.existing_lead));
      } else {
        dispatch(modal.actions.showErrors(payload));
      }
    }

    function onSuccess(payload) {
      const lead = payload.lead;
      const meta = payload.meta;

      dispatch(reset());
      dispatch(setLoading(false));
      dispatch(modal.actions.hideModal());

      if (meta.result) {
        dispatch(handleCreateAlreadyExists(lead, meta));
      } else {
        dispatch(handleCreateSuccess(leadData, assignToId, lead, meta.should_show));
      }
    }

    if (leadData.id) {
      return request('leads/' + leadData.id, PUT, body, onSuccess, onFailure);
    } else {
      return request('leads', POST, body, onSuccess, onFailure);
    }
  };
};

const handleCreateSuccess = (leadData, assignToId, lead, shouldShow) => {
  return (dispatch) => {
    if (!leadData.id && !assignToId) {
      dispatch(queuedLeads.actions.add(lead));
    }
    if (shouldShow) {
      dispatch(leadsList.actions.add(lead));
    }
    notify.success('Lead created');
  };
};

const handleCreateAlreadyExists = (lead, meta) => {
  const message = `${lead.display_name} already exists with that email or mobile. Their details have been updated`;
  return (dispatch) => {
    const handleOnClick = () => {
      dispatch(push(`/secure/manageLeads?lead_id=${lead.id}`));
    };
    notify.info(<ToastButtonView title={message} buttonTitle={'View lead'} onClick={handleOnClick} />);
    if (lead.status === 'not_assigned') {
      dispatch(queuedLeads.actions.add(lead));
    }
  };
};

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setUsers(users) {
  return {
    type: actionTypes.SET_USERS,
    users,
  };
}

export function setSelectedUser(user) {
  return {
    type: actionTypes.SELECT_USER,
    user: user,
  };
}

export function setExistingLead(lead) {
  return {
    type: actionTypes.SET_EXISTING_LEAD,
    lead,
  };
}

export function setCurrentView(currentView) {
  return {
    type: actionTypes.SET_CURRENT_VIEW,
    currentView,
  };
}

export function selectLocation(selectedLocation) {
  return {
    type: actionTypes.SET_SELECTED_LOCATION,
    selectedLocation,
  };
}

export function selectLeadSource(selectedLeadSource) {
  return {
    type: actionTypes.SET_SELECTED_LEAD_SOURCE,
    selectedLeadSource,
  };
}

export function selectLeadGoal(selectedLeadGoal) {
  return {
    type: actionTypes.SET_SELECTED_LEAD_GOAL,
    selectedLeadGoal,
  };
}

export function selectHeardAboutMethod(selectedHeardAboutMethod) {
  return {
    type: actionTypes.SET_SELECTED_HEARD_ABOUT_METHOD,
    selectedHeardAboutMethod,
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

export function setPageDetails(pageDetails) {
  return {
    type: actionTypes.SET_PAGE_DETAILS,
    pageDetails,
  };
}

// Tags
export function setTags(tags) {
  return {
    type: actionTypes.SET_TAGS,
    tags,
  };
}

export function setLastUsedLocationId(lastUsedLocationId) {
  return {
    type: actionTypes.SET_LAST_USED_LOCATION_ID,
    lastUsedLocationId,
  };
}
