import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import onClickOutside from "react-onclickoutside";
import NumericInput from "react-numeric-input";

import moment from "moment";
import "moment-timezone";

const NONE = "No filter";
const IN_TRIAL = "In Trial";
const TRIAL_EXPIRES_AT = "Trial ends in";
const TRIAL_STARTS_AT = "Trial starts in";

export class TrialsSection extends Component {
  state = {
    showingOptions: false,
    selectedIndex: 0,
    showingDayEntryExpires: false,
    showingDayEntryStarts: false,
    options: [NONE, IN_TRIAL, TRIAL_EXPIRES_AT, TRIAL_STARTS_AT],
  };

  indexClicked = (index) => {
    const option = this.state.options[index];
    switch (option) {
      case NONE:
        this.hideDayEntry(index);
        this.resetData();
        break;
      case IN_TRIAL:
        this.hideDayEntry(index);
        this.setInTrial();
        break;
      case TRIAL_EXPIRES_AT:
        this.showDayEntryExpires(index);
        break;
      case TRIAL_STARTS_AT:
        this.showDayEntryStarts(index);
        break;
      default:
        break;
    }
  };

  showDayEntryStarts = (index) => {
    this.setState({
      ...this.state,
      showingDayEntryExpires: false,
      showingDayEntryStarts: true,
      showingOptions: false,
      selectedIndex: index,
    });
  };

  showDayEntryExpires = (index) => {
    this.setState({
      ...this.state,
      showingDayEntryExpires: true,
      showingDayEntryStarts: false,
      showingOptions: false,
      selectedIndex: index,
    });
  };

  hideDayEntry = (index) => {
    this.setState({
      ...this.state,
      showingDayEntryExpires: false,
      showingDayEntryStarts: false,
      showingOptions: false,
      selectedIndex: index,
    });
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  selectDate(date, fieldName) {
    const utcDate = date.format();
    this.handleInput(fieldName, utcDate, true);
  }

  clearData = () => {
    this.handleInput("trial_starts_at", null, true);
    this.handleInput("trial_ends_before", null, true);
  };

  resetData = () => {
    const data = {
      fields: {
        trial_starts_at: { value: null, isValid: true },
        trial_ends_before: { value: null, isValid: true },
        on_a_trial: { value: false, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  setInTrial = () => {
    const data = {
      fields: {
        trial_starts_at: { value: null, isValid: true },
        trial_ends_before: { value: null, isValid: true },
        on_a_trial: { value: true, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  setTrialStartsAtDate = (days) => {
    const date = moment()
      .add(days + 1, "day")
      .format();
    const data = {
      fields: {
        trial_starts_at: { value: date, isValid: true },
        trial_ends_before: { value: null, isValid: true },
        on_a_trial: { value: false, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  setTrialExpiresAtDate = (days) => {
    const date = moment()
      .add(days + 1, "day")
      .format();
    const data = {
      fields: {
        trial_starts_at: { value: null, isValid: true },
        trial_ends_before: { value: date, isValid: true },
        on_a_trial: { value: false, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  currentSelection = () => {
    const { options, selectedIndex } = this.state;
    return options[selectedIndex];
  };

  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onUpdate(newSectionData, this.props.sectionName);
  };

  render() {
    const {
      showingDayEntryExpires,
      showingDayEntryStarts,
      selectedIndex,
      showingOptions,
    } = this.state;
    const options = this.state.options.map((option, index) => {
      const className = index === selectedIndex ? "selected" : "selectable";
      return (
        <li key={index} className={className}>
          <div className="DropDown" onClick={() => this.indexClicked(index)}>
            {option}
          </div>
        </li>
      );
    });
    let selectedOption = this.currentSelection();
    const btnColorStyle = selectedOption === NONE ? "btn-default" : "btn-info";
    return (
      <div>
        <button
          className={"btn " + btnColorStyle + " push-5-r push-10"}
          type="button"
          onClick={this.toggleDropdown}
        >
          {selectedOption} <i className="fa fa-caret-down" />
        </button>
        {showingDayEntryExpires && (
          <span>
            <NumericInput
              min={0}
              max={100}
              onChange={this.setTrialExpiresAtDate}
              style={{
                input: {
                  width: "60px",
                  height: "34px",
                },
              }}
            />{" "}
            Days
          </span>
        )}
        {showingDayEntryStarts && (
          <span>
            <NumericInput
              min={0}
              max={100}
              onChange={this.setTrialStartsAtDate}
              style={{
                input: {
                  width: "60px",
                  height: "34px",
                },
              }}
            />{" "}
            Days
          </span>
        )}
        {showingOptions && (
          <ul className="dropdown-menu" style={{ display: "block" }}>
            {options}
          </ul>
        )}
      </div>
    );
  }
}

TrialsSection.propTypes = {
  timezone: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default onClickOutside(TrialsSection);
