import { createSelector } from 'reselect';

export const getUser = (state) => state.session.user;
export const getUserTimezone = (state) => state.session.user.timezone;
export const getUserRole = (state) => state.session.user.user_role;
export const getAttemptedUrl = (state) => state.session.attemptedUrl;
export const getIsMobile = (state) => state.session.isMobile;
export const getUserPermissions = (state) => state.session.user.user_permission_rule_set;
export const getCurrentAppVersion = (state) => state.session.currentAppVersion;
export const getRealtimeAuth = (state) => state.session.realtimeAuth;
export const getSessionExpiry = (state) => state.session.sessionExpiry;
export const getSessionEndReason = (state) => state.session.sessionEndReason;
export const getSwitchingAppName = (state) => state.session.switchingAppName;
export const getIntercomHash = (state) => state.session.intercomHash;
export const getLoading = (state) => state.session.loading;

export const isSalesperson = createSelector([getUserRole], (userRole) => {
  return userRole.name === 'Salesperson';
});

export const canSendEmail = createSelector([getUser], (user) => {
  return user.transactional_email && user.transactional_email.usable === true;
});

export const emailBlocked = createSelector([getUser], (user) => {
  return user.transactional_email && user.transactional_email.blocked === true;
});

export const emailRestricted = createSelector([getUser], (user) => {
  return user.transactional_email && user.transactional_email.restricted === true;
});
