import * as actionTypes from './actionTypes'

const SALES_REPORT_FILTER_ACTION_HANDLERS = {
  [actionTypes.CLEAR_ALL_FILTERS]: (state) => {
    return { ...state, data: defaultLeadFormState() }
  },
  [actionTypes.UPDATE_FILTER_DATA]: (state, action) => {
    return { ...state, data: action.filterData }
  },
  [actionTypes.HIDE_FILTERS]: (state, action) => {
    return { ...state, showingMore: false }
  },
  [actionTypes.SHOW_FILTERS]: (state, action) => {
    return { ...state, showingMore: true }
  },
  [actionTypes.SET_SELECTED_LOCATION_IDS]: (state, action) => {
    return { ...state, selectedLocationIds: action.locationIds }
  },
  [actionTypes.LOCATION_SEARCH_TERM_UPDATED]: (state, action) => {
    return { ...state, locationSearchTerm: action.searchTerm, locationCurrentPage: 1 }
  },
  [actionTypes.LOCATION_CURRENT_PAGE_UPDATED]: (state, action) => {
    return { ...state, locationCurrentPage: action.pageNumer }
  },
  [actionTypes.FETCHING_COUNTS]: (state, action) => {
    return { ...state, countLoading: true }
  },
  [actionTypes.FETCH_COUNTS_SUCCESS]: (state, action) => {
    return { ...state, counts: action.counts, countLoading: false }
  },
  [actionTypes.SET_HAS_PREFILLED_USER] : (state, action) => {
    return { ...state, hasPrefilledUser: action.hasPrefilledUser }
  }
}

const initialState = {
  showingMore: false,
  hasPrefilledUser: false,
  selectedLocationIds: null,
  locationSearchTerm: '',
  locationCurrentPage: 1,
  countLoading: false,
  data: defaultLeadFormState(),
  counts: {}
}

function defaultLeadFormState () {
  return {
    statuses: {
      fields: {
        active: { value: true, isValid: true }
      }
    }
  }
}

export default function reducer (state = initialState, action) {
  const handler = SALES_REPORT_FILTER_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
