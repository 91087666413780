import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { WaiverSubmissionItem } from './WaiverSubmissionItem';
import { EmptyView, PagesView } from 'modules/components';
import ViewSignedWaiverModalViewProps from '../containers/ViewSignedWaiverModalViewProps';

import { VIEW_SIGNED_WAIVER_MODAL } from '../constants';

export class LeadWaiversView extends Component {
  componentDidMount() {
    this.loadCheckInsForPage(1);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.lead.id !== this.props.lead.id) {
      this.loadCheckInsForPage(1);
    }
  }

  loadCheckInsForPage = (pageNumber) => {
    const { fetchForLead, lead } = this.props;
    fetchForLead(lead, pageNumber);
  };

  render() {
    const { waiverSubmissions, lead, loading, selectedWaiverSubmission, pageDetails, showModal, selectWaiverSubmissionId } = this.props;

    const waiverSubmissionsList = waiverSubmissions.map((waiverSubmission, index) => {
      return (
        <WaiverSubmissionItem
          waiverSubmission={waiverSubmission}
          key={index}
          showModal={showModal}
          selectWaiverSubmissionId={selectWaiverSubmissionId}
        />
      );
    });

    const pageCount = pageDetails.pageCount;

    return (
      <div className="push-20">
        {loading && (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h3 className="push-10">Loading sign ins</h3>
              <i className="fa fa-2x fa-sun-o fa-spin" />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            {waiverSubmissions.length > 0 && (
              <div>
                <ul className="list list-simple">{waiverSubmissionsList}</ul>
              </div>
            )}
            {waiverSubmissions.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="fa fa-wpforms"
                title="No sign ins yet"
                subtitle="Sign ins from this lead created will show here"
              />
            )}
            {selectedWaiverSubmission && <ViewSignedWaiverModalViewProps lead={lead} modalName={VIEW_SIGNED_WAIVER_MODAL} />}
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageDetails={pageDetails} pageClicked={this.loadCheckInsForPage} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

LeadWaiversView.propTypes = {
  lead: PropTypes.object.isRequired,
  waiverSubmissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  selectWaiverSubmissionId: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  selectedWaiverSubmission: PropTypes.object,
};
