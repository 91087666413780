import config from 'config';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FacebookLogin from 'utils/facebook';
import { Button } from 'modules/components';

export class FormView extends Component {
  responseFacebook = (response) => {
    const { setFacebookUser } = this.props;
    if (response.status && response.status === 'unknown') {
      return;
    }
    setFacebookUser(response);
  };

  render() {
    const { facebookUser } = this.props;
    return (
      <div>
        <div className="row push">
          <div className="col-md-12">
            <h2 className="h4 push-10">Integrate Facebook</h2>
            <p className="text-gray-dark">Connect {this.props.productName} with your gym's Facebook page. Connecting Facebook will:</p>
            <ul className="text-gray-dark">
              <li>
                Connect the {this.props.productName} chatbot to your page chat account. It will automatically flag leads and take them
                through a simple qualification flow.
              </li>
              <li>Any leads that connect through facebook messenger will be pulled into {this.props.productName}.</li>
              <li>Salespeople can view the entire messenger conversation and respond manually through the lead detail view.</li>
              <li>Pull any facebook lead ad submissions linked to the page into {this.props.productName}.</li>
            </ul>
            <p className="text-gray-dark">Log into Facebook and click next.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            {facebookUser && <h4>Logged in as {facebookUser.name}</h4>}
            {!facebookUser && (
              <FacebookLogin
                appId={config.facebookAppId}
                autoLoad={false}
                scope={
                  'leads_retrieval, ads_management, pages_read_engagement, pages_manage_ads, pages_manage_metadata, pages_show_list, business_management, pages_messaging, pages_messaging_subscriptions, public_profile, pages_manage_posts, pages_manage_engagement'
                }
                onClick={this.loginClicked}
                callback={this.responseFacebook}
                version={'10.0'}
                render={(renderProps) => <Button.Primary1 onClick={renderProps.onClick}>Log in via Facebook</Button.Primary1>}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

FormView.propTypes = {
  setFacebookUser: PropTypes.func.isRequired,
  facebookUser: PropTypes.object,
};

export default FormView;
