import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LostReasonModal from "../components/LostReasonModal/LostReasonModal";
import { updateLeadToStatus } from "../actions";

import modal from "modules/Stores/Modal";
import leadLostReasons from "modules/Stores/LeadLostReasons";
import * as leadLostReasonsApiActions from "modules/Stores/LeadLostReasons/apiActions";

function mapStateToProps(state) {
  return {
    leadLostReasons: leadLostReasons.selectors.getLeadLostReasons(state),
    loading: leadLostReasons.selectors.getLoading(state),
    errors: leadLostReasons.selectors.getErrors(state),
    pageDetails: leadLostReasons.selectors.getPageDetails(state),
    currentModal: state.modal.currentModal
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      updateLeadToStatus,
      getLeadLostReasons: leadLostReasonsApiActions.getLeadLostReasons
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostReasonModal);
