import * as actionTypes from './actionTypes'

const SELECT_LOCATION_ACTION_HANDLERS = {
  [actionTypes.LOCATION_SELECTED]: (state, action) => {
    return { ...state, locationId: action.location.id, locationName: action.location.name }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    locationId: null,
    locationName: null
  }
}

export default function reducer (state = initialState(), action) {
  const handler = SELECT_LOCATION_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
