import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import callScheduleSelect from '../modules/callScheduleSelect'
import callScheduleSteps from '../modules/callScheduleSteps'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  const selectedCallSchedule = callScheduleSelect.selectors.selectedCallSchedule(state)
  return {
    callScheduleId: selectedCallSchedule.id,
    selectedCallScheduleStep: callScheduleSteps.selectors.getSelectedStep(state),
    loading: callScheduleSteps.selectors.getLoading(state),
    errors:  callScheduleSteps.selectors.getErrors(state),
    steps: callScheduleSteps.selectors.getSteps(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchCallScheduleSteps: callScheduleSteps.actions.fetchCallScheduleSteps,
    selectCallScheduleStepId: callScheduleSteps.actions.selectCallScheduleStepId,
    showModal: modal.actions.showModal,
    reset: callScheduleSteps.actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(callScheduleSteps.components.CallScheduleStepsView)
