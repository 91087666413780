import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import history from '../modules/history'
import session from 'modules/Stores/Session'

const { fetchHistoryForLeadAndPageNumber } = history.actions

function mapStateToProps (state, ownProps) {
  const { pathToLeadDetail } = ownProps
  return {
    history: history.selectors.getHistoryItems(state, pathToLeadDetail),
    loading: history.selectors.getLoading(state, pathToLeadDetail),
    pageCount: history.selectors.getPageCount(state, pathToLeadDetail),
    currentPage: history.selectors.getCurrentPage(state, pathToLeadDetail),
    timezone: session.selectors.getUserTimezone(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchHistoryForLeadAndPageNumber
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(history.components.LeadHistoryView)
