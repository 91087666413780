import PropTypes from "prop-types";
import React, { Component } from "react";

import { CONFIGURE_INTEGRATION_VIEW } from "./FormView";
import LeadSourceSelectContainer from "modules/components/LeadSourceSelect/containers/LeadSourceSelectContainer";

export class FormSelectLeadSourceView extends Component {
  handleSourceChange = value => {
    const { linkedFacebookPage, updateFacebookPageIntegration } = this.props;
    const body = { lead_source_id: value.id };
    updateFacebookPageIntegration(linkedFacebookPage.id, body);
    this.props.setCurrentView(CONFIGURE_INTEGRATION_VIEW, null);
  };

  render() {
    const { linkedFacebookPage } = this.props;
    return (
      <div>
        <LeadSourceSelectContainer
          selectedLeadSourceId={linkedFacebookPage.lead_source.id}
          onLeadSourceSelect={this.handleSourceChange}
        />
      </div>
    );
  }
}

FormSelectLeadSourceView.propTypes = {
  integration: PropTypes.object.isRequired,
  updateFacebookPageIntegration: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
};

export default FormSelectLeadSourceView;
