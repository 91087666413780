import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FilterFormSections from 'modules/components/FilterFormSections'
import ExportButtonContainer from 'modules/components/ExportButton/ExportButtonContainer'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    queryString: queryStringSelector(state),
    compareOption: filters.selectors.getCompareOption(state),
    queued: false
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButtonContainer)
