import * as actionTypes from './actionTypes'

const CALL_SCHEDULE_STEPS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_STEPS]: (state, action) => {
    return { ...state, steps: action.steps }
  },
  [actionTypes.ADD_STEP]: (state, action) => {
    const newSteps = [
      ...state.steps,
      action.step
    ]
    return { ...state, steps: newSteps }
  },
  [actionTypes.UPDATE_STEP]: (state, action) => {
    const index = state.steps.findIndex(step => parseInt(step.id) === parseInt(action.step.id))
    const newSteps = [
      ...state.steps.slice(0, index),
      action.step,
      ...state.steps.slice(index + 1)
    ]
    return { ...state, steps: newSteps }
  },
  [actionTypes.SELECT_STEP_ID]: (state, action) => {
    return { ...state, selectedStepId: action.stepId }
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForCallScheduleSteps()
  }
}

function initialStateForCallScheduleSteps (callScheduleSteps) {
  return {
    loading: false,
    errors: null,
    steps: [],
    selectedStepId: null
  }
}

export default function reducer (state = initialStateForCallScheduleSteps(), action) {
  const handler = CALL_SCHEDULE_STEPS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
