import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UsersToEmail from "../components/UsersToEmail";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  const usersCount = props.userIds ? props.userIds.length : 0;
  const buttonTitle = usersCount > 0 ? `${usersCount} selected` : "No users";
  const usersFiltersClass = usersCount > 0 ? "btn btn-info" : "btn btn-default";

  return {
    buttonTitle: buttonTitle,
    usersFiltersClass: usersFiltersClass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersToEmail);
