import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { RegionsView } from '../components/RegionsView'
import regions from '../modules/regions'

function mapStateToProps (state) {
  return {
    selectedRegionId: regions.selectors.getSelectedRegionId(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionsView)
