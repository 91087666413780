import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MultiRegionSelectModal from "../components/MultiRegionSelectModal";

import modal from "modules/Stores/Modal";
import regionStore from "modules/Stores/Regions";
import * as regionApiActions from "modules/Stores/Regions/apiActions";
import * as actions from "../actions";
import * as selectors from "../selectors";

function mapStateToProps(state) {
  const regions = regionStore.selectors.getRegions(state);
  const filterRegionFunction = selectors.filteredRegions(regions);
  const filteredRegions = filterRegionFunction(state);
  const pagedRegions = selectors.regionsAtCurrentPage(filteredRegions, 10);
  const pageCountFunction = selectors.pageCountForRegions(filteredRegions, 10);
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    regions: pagedRegions(state),
    pageCount: pageCountFunction(state),
    searchTerm: selectors.getRegionSearchTerm(state),
    currentPage: selectors.getRegionCurrentPage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRegions: regionApiActions.getRegions,
      updateCurrentPage: actions.updateRegionCurrentPage,
      searchTermUpdated: actions.regionSearchTermUpdated,
      setRegionIds: actions.setRegionIds,
      hideModal: modal.actions.hideModal,
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiRegionSelectModal);
