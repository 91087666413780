import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import createSalesTarget from '../modules/createSalesTarget'
import currentTarget from '../modules/currentTarget'

function mapStateToProps (state) {
  return {
    loading: createSalesTarget.selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createSalesTargetForLocationUser: createSalesTarget.actions.createSalesTargetForLocationUser,
    setSalesTarget: currentTarget.actions.setSalesTarget
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(createSalesTarget.components.CreateSalesTargetView)
