const initialState = {
  loading: false,
  errors: null,
  selectedSubscription: null,
  subscriptions: null,
  newSubscription: {
    name: null,
    apiKey: null,
  }
}

export default initialState