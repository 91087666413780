import { isPresent, isEmail } from "utils/validation";

export const EditWaiverValidator = {
  name: [
    {
      rule: isPresent,
      errorMessage: "You must enter a name",
    },
  ],
  ignoreReopenForwardAddress: [
    {
      rule: isEmail,
      errorMessage: "You must enter a valid email",
    },
  ],
  locationid: [
    {
      rule: isPresent,
      errorMessage: "You must select a default location",
    },
  ],
};
