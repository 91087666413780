import PropTypes from 'prop-types'
import React, { Component } from 'react'

import config from 'config'

export class IntercomWrapper extends Component {

  componentDidMount () {
    if (config.debug) { return }

    const options = { app_id: 'f8f1vqq3', app: 'Marketing', app_version: config.version }
    if (window.Intercom) {
      window.Intercom('boot', options)
    }
  }

  render () {
    return (
      <div />
    )
  }
}

IntercomWrapper.propTypes = {
  user : PropTypes.object
}

export default IntercomWrapper
