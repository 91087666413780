import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Avatar from 'react-avatar'
import moment from 'moment'

import { LeadStatusTag, LeadSalesFunnelSteps } from 'modules/components'
import { daysAgoString } from 'modules/Helpers/DateHelpers'

export class LeadListRow extends Component {

  rowSelected = () => {
    const { lead, onSelect } = this.props
    onSelect(lead.id)
  }

  dealDays = () => {
    const { lead } = this.props
    const eventdate = moment(lead.trial.end_date)
    return eventdate.diff(moment(), 'days')
  }

  render () {
    const { lead, selected } = this.props
    const leadStepNumber = (lead.sales_funnel_step) ? lead.sales_funnel_step.step_number : 0
    const selectedClass = (selected) ? 'selected' : ''
    const mutedColor = (selected) ? 'text-white' : 'text-muted'
    const assignedToString = lead.salesperson_name || 'no one'
    return (
      <div className={'lead-list-row block block-rounded block-bordered selectable ' + selectedClass}
        style={{ marginBottom: '4px' }}
        onClick={this.rowSelected}>
        <div className='block-content'>
          <h3 className='h4' style={{ marginBottom: '8px' }}>
            <span style={{ paddingTop: '2px', paddingRight: '5px' }}>{lead.display_name}</span>
            <span style={{ position: 'absolute', top: '12px', marginLeft: '3px' }}>
              <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={32} round={true} />
            </span>
            <span style={{ fontSize: '14px', position: 'absolute', top: '19px', marginLeft: '43px' }}>
              <LeadStatusTag leadStatus={lead.status} />
            </span>
          </h3>
          <p className={mutedColor} style={{ fontSize: '12px' }}> Assigned to {assignedToString}
            <span className='label-outline' style={{ marginLeft: '5px', fontSize: '10px' }}>
              {lead.location_name}
            </span>
            <i className='push-5-l fa fa-calendar' /> {daysAgoString(lead, true)}
            {lead.trial &&
              <span>
                <i className='push-5-l fa fa-clock-o' /> {this.dealDays()}
              </span>
            }
          </p>
          <div className='progress-container push-20'>
            {!this.props.shortMode && leadStepNumber > 0 &&
              <LeadSalesFunnelSteps lead={lead} salesFunnelSteps={this.props.salesFunnelSteps} />
            }
          </div>
        </div>
      </div>
    )
  }
}

LeadListRow.propTypes = {
  lead: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.bool.isRequired,
  shortMode: PropTypes.bool.isRequired
}

export default LeadListRow
