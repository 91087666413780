import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class ConfirmView extends Component {

  createAndStart = (event) => {
    event && event.preventDefault()
    const { createLeadReallocation, fromUser, touserIds, currentLocationId } = this.props
    createLeadReallocation(currentLocationId, fromUser.id, touserIds)
  }

  render () {
    const { isActive, success, fromUser, touserIds, loading, currentLocationName } = this.props
    const activeClassName = (isActive) ? 'active in' : ''

    return (
      <div className={'tab-pane fade fade-up push-30-t push-50 ' + activeClassName}>
        {success === false && loading === false &&
          <div>
            <h3 style={{ paddingBottom: '5px' }}>Confirm and save</h3>
            <p>
              All leads assigned to {fromUser.display_name} in {currentLocationName} will be{' '}
              reallocated evenly across {touserIds.length} user(s):
            </p>
            <div className='col-xs-12 col-sm-6 push'>
              <button className='btn btn-block btn-primary' onClick={this.createAndStart}>
                Start Transfer
              </button>
            </div>
          </div>
        }
        {success === false && loading &&
          <div className='text-center'>
            <h3 style={{ paddingBottom: '5px' }}>Starting Reallocation</h3>
            <i className='fa fa-3x fa-cog fa-spin' />
          </div>
        }
        {success && loading === false &&
          <div className='text-center'>
            <h3 style={{ paddingBottom: '5px' }}>Bulk Reallocation Started</h3>
            <i className='fa fa-5x fa-check-circle text-success' style={{ paddingBottom: '5px' }} />
            <p>Your bulk reallocation has started.</p>
          </div>
        }
      </div>
    )
  }
}

ConfirmView.propTypes = {
  isActive: PropTypes.bool.isRequired,
  currentLocationId: PropTypes.number.isRequired,
  currentLocationName: PropTypes.string.isRequired,
  fromUser: PropTypes.object.isRequired,
  touserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool.isRequired,
  createLeadReallocation: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired
}

export default ConfirmView
