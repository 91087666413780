import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'images/logo.png';
import ForgotPasswordForm from './ForgotPasswordForm';

export class ForgotPasswordView extends Component {
  componentWillUnmount() {
    this.props.reset();
  }

  componentDidMount() {
    const { fetchWhitelabelProfileLogo, productName } = this.props;
    if (productName) {
      fetchWhitelabelProfileLogo(productName);
    }
  }

  setLogo() {
    const { whitelabelProfile, productName } = this.props;
    if (!productName) {
      return logo;
    } else if (whitelabelProfile && whitelabelProfile.logo_url) {
      return whitelabelProfile.logo_url;
    } else if (whitelabelProfile && whitelabelProfile.name === 'Gymleads') {
      return logo;
    }
  }

  getLoginUrl() {
    const { productName } = this.props;
    if (productName) {
      return '/login?client=' + productName;
    } else {
      return '/login';
    }
  }

  setCopyright() {
    if (this.props.productName) {
      return (
        <div>
          <span className="js-year-copy">2016-2022</span> © Powered by GymLeads
        </div>
      );
    } else {
      return (
        <div>
          <span className="js-year-copy">2016-2022</span> © GymLeads
        </div>
      );
    }
  }

  render() {
    const { sendEmail, loading, errors, success } = this.props;
    const message = !success
      ? "Having some trouble? Enter your username below and we'll send you a link to reset your password."
      : "We've sent an email to the address linked with that username with further instructions.\n Please " +
        "confirm if the provided information is correct or get in touch with support if you're still " +
        "having trouble or haven't received the email in the next 10 minutes.";
    return (
      <div>
        <div className="bg-white pulldown">
          <div className="content context-boxed overflow-hidden">
            <div className="row">
              <div className="row">
                <div className="col-md-4 col-md-offset-4 text-center push-30-t">
                  <img src={this.setLogo()} alt="logo" style={{ height: '80px', paddingBottom: '10px' }} />
                  <p className="animated fadeIn" style={{ margin: '10px' }}>
                    {message}
                  </p>
                </div>
              </div>
              <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <div className="push-30-t push-50 animated fadeIn">
                  <div className="text-center">
                    {!success && <ForgotPasswordForm onSubmit={sendEmail} loading={loading} errors={errors} />}
                  </div>
                  <p className="push-50 text-center">
                    <Link to={this.getLoginUrl()}> Log In</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pulldown push-30-t text-center animated fadeInUp">
          <small className="text-muted">{this.setCopyright()}</small>
        </div>
      </div>
    );
  }
}

ForgotPasswordView.propTypes = {
  loading: PropTypes.bool.isRequired,
  sendEmail: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  success: PropTypes.bool,
};
