import React from "react";
import styled from "styled-components";
import ExportCsvModalContainer from "../containers/ExportCsvModalContainer";

function HeaderView() {
  return (
    <Container>
      <HeaderContainer className="row items-push">
        <div className="col-md-12">
          <div className="btn-group pull-right">
            <div className="btn-group">
              <ExportCsvModalContainer />
            </div>
          </div>
          <h1 className="page-heading push-10">Time Taken Report</h1>
          <BottomBorder />
        </div>
      </HeaderContainer>
      <InfoAlert className="alert alert-info alert-dismissable">
        <p>
          Please note when pulling this report; that this report calculates
          averages for events that occurred on the selected dates and reflects
          how long it took for the leads sales funnel steps/status to move to
          the relevant step. This report does not factor in when the lead was
          created.
        </p>
      </InfoAlert>
    </Container>
  );
}

HeaderView.propTypes = {};

export default HeaderView;

const HeaderContainer = styled.div``;
const Container = styled.div``;
const InfoAlert = styled.div``;
const BottomBorder = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 2px;
`;
