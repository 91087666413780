import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class Switch extends Component {

  state = {
    errorMessage: null,
    style: '',
    value: false
  }

  handleChange = (event) => {
    const switchValue = event.target.checked
    let style = ''
    let errorMessage = null

    this.setState({ ...this.state, errorMessage: errorMessage, style: style, value: switchValue })
    this.props.onChange(this.props.name, switchValue)
  }

  render () {
    const { name, disabled, switchStyle, label } = this.props
    const { style } = this.state
    const isDisabled = disabled === true
    const isOn = (this.props.value !== null | this.props.value !== undefined) ? this.props.value : this.state.value
    return (
      <div className={'form-group ' + style}>
        <div className='col-xs-12'>
          <label htmlFor={name} className={'css-input switch ' + switchStyle} >
            <input
              type='checkbox'
              id={name}
              name={name}
              checked={isOn}
              onChange={this.handleChange}
              disabled={isDisabled} />
            <span /> {label}
          </label>
        </div>
      </div>
    )
  }
}

Switch.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  name: PropTypes.string.isRequired,
  switchStyle: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.bool
}
