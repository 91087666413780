import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserSelectView from '../components/UserSelectView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  const selectedLocation = selectors.getSelectedLocation(state)
  const locationId = (selectedLocation) ? selectedLocation.id : props.lead.location_id
  return {
    users: selectors.getUsers(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    selectedUser: selectors.getSelectedUser(state),
    locationId: locationId,
    locationName: (selectedLocation) ? selectedLocation.admin_display_name : props.lead.location_name,
    isFirstAssignment: false,
    fetchOnLoad: selectors.fetchOnLoadSelector(locationId)(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    assignLeadToUser: actions.assignLeadToUser,
    getUsers: actions.getUsers,
    setSelectedUser: actions.setSelectedUser,
    setCurrentView: actions.setCurrentView,
    setLastUsedLocationId: actions.setLastUsedLocationId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectView)
