import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TasksView from '../components/TasksView'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../../filters'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    errors: selectors.getErrors(state),
    loading: selectors.getLoading(state),
    queryString: queryStringSelector(state),
    lastQueryString: selectors.getLastQueryString(state),
    compareOption: filters.selectors.getCompareOption(state),
    isEmpty: selectors.isEmpty(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksView)
