import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectLeadSourceTableRow extends Component {
  rowSelected = () => {
    const { leadSource, onLeadSourceSelect } = this.props;
    onLeadSourceSelect(leadSource);
  };

  render() {
    const { leadSource, leadSourceSelected } = this.props;
    const className = leadSourceSelected ? "selected" : "selectable";
    return (
      <tr className={className} onClick={() => this.rowSelected(leadSource)}>
        <td>{leadSource.source_name}</td>
      </tr>
    );
  }
}

SelectLeadSourceTableRow.propTypes = {
  leadSource: PropTypes.object.isRequired,
  leadSourceSelected: PropTypes.bool.isRequired,
  onLeadSourceSelect: PropTypes.func.isRequired
};
