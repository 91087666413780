export const NAME = "filters";

// Filter Types
export const COMPARE_BY = "compare_by";
export const CALCULATE_BY = "calculate_by";
export const USERS = "users";
export const LOCATIONS = "locations";
export const REGIONS = "regions";
export const LEAD_SOURCES = "lead_sources";
export const HEARD_ABOUT_METHODS = "heard_about_methods";
export const TAGS = "tags";
export const TRIALS = "trials";
export const DATE_RANGE = "date_range";
export const CLUBS = "locations";
export const LEAD_CREATED = "lead_created";
export const EVENT_OCCURED = "event_occured";
export const LEAD_STATUS = "statuses";
export const STATUS_UPDATED_RANGE = "statusUpdatedBetween";
export const ASSIGNED_TO = "assigned_to";
export const FUNNEL_STEPS = "funnel_steps";

export const GROUPED_USERS = "grouped_users";

// Lead Statuses
export const ACTIVE = "active";
export const CLOSED = "closed";
export const NOT_ASSIGNED = "not_assigned";
export const INACTIVE = "inactive";
export const LOST = "lost";

// Modals
export const SELECT_LOCATIONS_MODAL = "SELECT_LOCATIONS_MODAL";
export const SELECT_USERS_MODAL = "SELECT_USERS_MODAL";
export const SELECT_REGIONS_MODAL = "SELECT_REGIONS_MODAL";
export const SELECT_LEAD_SOURCES_MODAL = "SELECT_LEAD_SOURCES_MODAL";
export const SELECT_LEAD_LOST_REASONS_MODAL = "SELECT_LEAD_LOST_REASONS_MODAL";
export const SELECT_HEARD_ABOUT_METHODS_MODAL =
  "SELECT_HEARD_ABOUT_METHODS_MODAL";
export const SELECT_DEALS_MODAL = "SELECT_DEALS_MODAL";
export const SELECT_COUNTRIES_MODAL = "SELECT_COUNTRIES_MODAL";
