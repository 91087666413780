const SUBSCRIBE_ACTION_HANDLERS = {

}

function initialState () {
  return {
  }
}

export default function reducer (state = initialState(), action) {
  const handler = SUBSCRIBE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
