import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableView from './TableView'

import { EmptyView, PageSelection } from 'modules/components'

export class LeadsListView extends Component {

  componentDidMount () {
    const { getLeadsWithQueryAndPageNumber, queryString, loading } = this.props
    if (loading) { return }
    getLeadsWithQueryAndPageNumber(queryString, 1)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.queryString !== this.props.queryString)) {
      const { getLeadsWithQueryAndPageNumber, queryString } = nextProps
      getLeadsWithQueryAndPageNumber(queryString, 1)
    }
  }

  pageClicked = (pageNumber) => {
    const { getLeadsWithQueryAndPageNumber, queryString } = this.props
    getLeadsWithQueryAndPageNumber(queryString, pageNumber)
  }

  render () {
    const { loading, leads, currentPage, pageCount, timezone } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'

    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Leads created in time period</h3>
        </div>
        <div className='block-content push-50' style={{ minHeight: '409px' }}>
          {leads.length === 0 &&
            <div style={{ marginTop: '125px' }}>
              <EmptyView
                colorThemeClass='primary'
                iconClass='fa fa-users'
                title='No Leads!'
                subtitle='Check your filters or add a new lead.' />
            </div>
          }
          {leads.length > 0 &&
            <div>
              <TableView leads={leads} timezone={timezone} />
            </div>
           }
          {pageCount > 1 &&
            <div className='text-center push'>
              <PageSelection
                pageCount={pageCount}
                currentPage={currentPage}
                pageClicked={this.pageClicked}
                pageLimit={20} />
            </div>
          }
        </div>
      </div>
    )
  }
}

LeadsListView.propTypes = {
  queryString: PropTypes.string.isRequired,
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  getLeadsWithQueryAndPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired
}

export default LeadsListView
