import { combineReducers } from 'redux';
import billingHistory from './modules/billingHistory';
import subscription from './modules/subscription';
import creditCardModal from './modules/creditCardModal';
import subscriptionAddOn from './modules/subscriptionAddOn';

export default combineReducers({
  [billingHistory.constants.NAME]: billingHistory.reducer,
  [subscription.constants.NAME]: subscription.reducer,
  [creditCardModal.constants.NAME]: creditCardModal.reducer,
  [subscriptionAddOn.constants.NAME]: subscriptionAddOn.reducer,
});
