import React, { Component } from 'react'
import LocationSelectDashboardContainer from '../containers/RootContainers/LocationSelectDashboardContainer'

export class DashboardView extends Component {

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    return <LocationSelectDashboardContainer />
  }
}
