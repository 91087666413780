import { request, GET } from 'utils/apiUtils'

export const isValidFileType = (file) => {
  const allowedTypes = ['image/jpeg', 'image/png'];
  return allowedTypes.includes(file.type);
}

export const uploadFileToS3 = (file, success, progress, failure, type) => {
  getSignedUploadUrl(file, success, progress, failure, type)
}

const getSignedUploadUrl = (file, success, progress, failure, type) => {
  function onFailure (errors) {
    failure(errors)
  }

  function onSuccess (data) {
    uploadFileToS3WithUploadData(file, data.upload, success, progress, failure)
  }

  request('signed_upload_url?type=' + type + '&filename=' + file.name, GET, null, onSuccess, onFailure)
}

const uploadFileToS3WithUploadData = (file, uploadData, success, progress, failure) => {
  const { scheme, host, path, query } = uploadData.url

  const uploadUrl = scheme + '://' + host + path + '?' + query
  const downloadloadUrl = scheme + '://' + host + path

  function onFailure (errors) {
    failure(errors)
  }

  function onSuccess (data) {
    success(downloadloadUrl, uploadData.content_type)
  }

  function onProgress (progressEvent) {
    if (progressEvent.lengthComputable) {
      const percentComplete = progressEvent.loaded / progressEvent.total
      progress(percentComplete)
    }
  }

  const xhr = new XMLHttpRequest()

  xhr.open('PUT', uploadUrl, true)
  xhr.setRequestHeader('Content-Type', uploadData.content_type)

  xhr.addEventListener('progress', onProgress)
  xhr.addEventListener('load', onSuccess)
  xhr.addEventListener('error', onFailure)

  xhr.send(file)
}
