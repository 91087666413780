import { isPresent } from "utils/validation";

export const OpeningHoursValidator = {
  weekday: [
    {
      rule: isPresent,
      errorMessage: "Field is required"
    }
  ],
  weekend: [
    {
      rule: isPresent,
      errorMessage: "Field is required"
    }
  ]
};
