import { NAME } from './constants'

export const SET_SELECTED_USER_IDS = NAME + '/SET_SELECTED_USER_IDS'

export const SET_LAST_USED_LOCATION_IDS = NAME + '/SET_LAST_USED_LOCATION_IDS'

export const ADD_USER_ID = NAME + '/ADD_USER_ID'
export const REMOVE_USER_ID = NAME + '/REMOVE_USER_ID'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_USERS = NAME + '/SET_USERS'

export const RESET = NAME + '/RESET'
