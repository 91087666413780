/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";
import SetupScheduleModalContainer from "./modules/setupScheduleModal/containers/SetupScheduleModalContainer";
import { SETUP_AUTOMATIC_REPORT_MODAL } from "./constants";

export class ExportButton extends Component {
  state = {
    showingOptions: false,
  };

  showExportOptions = () => {
    this.setState({ showingOptions: !this.state.showingOptions });
  };

  exportAsCSVClicked = () => {
    const {
      generateFullReport,
      queryString,
      compareOption,
      calculatingOption,
    } = this.props;
    generateFullReport(queryString, compareOption, calculatingOption, "csv");
    this.setState({ showingOptions: false });
  };

  exportAsExcelClicked = () => {
    const {
      generateFullReport,
      queryString,
      compareOption,
      calculatingOption,
    } = this.props;
    generateFullReport(queryString, compareOption, calculatingOption, "excel");
    this.setState({ showingOptions: false });
  };

  handleClickOutside = (event) => {
    this.setState({ showingOptions: false });
  };

  setupAutomaticReportModal = () => {
    const { showModal } = this.props;
    showModal(SETUP_AUTOMATIC_REPORT_MODAL);
  };

  render() {
    const {
      queued,
      queryString,
      reportType,
      compareOption,
      calculatingOption,
      showExcel = false,
    } = this.props;
    const menuStyle = this.state.showingOptions
      ? { display: "block" }
      : { display: "none" };
    return (
      <div className="btn-group pull-right">
        <div className="btn-group">
          <button
            className="btn btn-default dropdown-toggle"
            onClick={this.showExportOptions}
          >
            <span className="caret" />
          </button>
          <ul className="dropdown-menu" style={menuStyle}>
            <DropdownButton className="selectable">
              <a
                className="selectable"
                tabIndex="-1"
                onClick={this.setupAutomaticReportModal}
              >
                Setup Schedule
              </a>
            </DropdownButton>
            <li className="dropdown-header">Email as</li>
            {queued ? (
              <li className="text-muted">
                <MutedOption>Report emailed</MutedOption>
              </li>
            ) : (
              <DropdownButton className="selectable">
                <a
                  className="selectable"
                  tabIndex="-1"
                  onClick={this.exportAsCSVClicked}
                >
                  CSV
                </a>
                {showExcel && (
                  <a
                    className="selectable"
                    tabIndex="-1"
                    onClick={this.exportAsExcelClicked}
                  >
                    Excel
                  </a>
                )}
              </DropdownButton>
            )}
          </ul>
        </div>
        <SetupScheduleModalContainer
          queryString={queryString}
          reportType={reportType}
          compareOption={compareOption}
          calculatingOption={calculatingOption}
        />
        <button
          className="btn btn-default"
          type="button"
          onClick={this.showExportOptions}
        >
          Export
        </button>
      </div>
    );
  }
}

ExportButton.propTypes = {
  queued: PropTypes.bool,
  generateFullReport: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  compareOption: PropTypes.string,
  calculatingOption: PropTypes.string,
  queryString: PropTypes.string,
};

export default onClickOutside(ExportButton);

const DropdownButton = styled.li``;
const MutedOption = styled.div`
  padding-left: 15px;
`;
