import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class WizardButtonsView extends Component {

  render () {
    const { tabIndex, nextIsReady, incrementTabIndex, decrementTabIndex, reset } = this.props

    const showPrevious = (tabIndex !== 0)
    const nextDisabledStyle = (nextIsReady) ? '' : 'disabled'

    return (
      <div className='block-content block-content-mini block-content-full border-t'>
        {tabIndex < 2 &&
          <div>
            <div className='row'>
              <div className='col-xs-6'>
                {showPrevious &&
                  <button className='wizard-prev btn btn-default' onClick={decrementTabIndex}>
                    <i className='fa fa-arrow-left' /> Previous
                  </button>
                }
              </div>
              <div className='col-xs-6 text-right'>
                <button className={'wizard-next btn btn-default ' + nextDisabledStyle}
                  disabled={!nextIsReady}
                  onClick={incrementTabIndex}>
                  Next <i className='fa fa-arrow-right' />
                </button>
              </div>
            </div>
          </div>
        }
        {tabIndex === 2 &&
          <div>
            <div className='row'>
              <div className='col-xs-6' />
              <div className='col-xs-6 text-right'>
                <button className={'wizard-next btn btn-default ' + nextDisabledStyle}
                  disabled={!nextIsReady}
                  onClick={reset}>
                  Done <i className='fa fa-check' />
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

WizardButtonsView.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  nextIsReady: PropTypes.bool.isRequired,
  incrementTabIndex: PropTypes.func.isRequired,
  decrementTabIndex: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
}

export default WizardButtonsView
