import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExportButtonContainer from 'modules/components/ExportButton/ExportButtonContainer'
import FilterFormSections from 'modules/components/FilterFormSections'
import * as filterSelectors from "../../filters/selectors"
import * as actions from '../actions'

function mapStateToProps(state, props) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filterSelectors.getData)
  return {
    reportType: 'trials_report',
    // queued: table.selectors.getQueued(state),
    // loading: table.selectors.getLoading(state),
    queryString: queryStringSelector(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      generateFullReport: actions.generateFullReport,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportButtonContainer);
