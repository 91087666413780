import * as actionTypes from './actionTypes'

const THIRD_PARTY_INTEGRATIONS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_THIRD_PARTY_INTEGRATIONS]: (state, action) => {
    return { ...state, thirdPartyIntegrations: action.thirdPartyIntegrations }
  },
  [actionTypes.SET_LAST_FETCHED_LOCATION_ID]: (state, action) => {
    return { ...state, lastFetchedLocationId: action.lastFetchedLocationId }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

const initialState = {
  thirdPartyIntegrations: [],
  lastFetchedLocationId: null,
  loading: false,
  errors: null
}

export default function reducer (state = initialState, action) {
  const handler = THIRD_PARTY_INTEGRATIONS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
