import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NewCustomMailMergeTagModal from '../components/NewCustomMailMergeTagModal';
import modal from 'modules/Stores/Modal';
import { NEW_CUSTOM_MAIL_MERGE_TAG_MODAL } from '../constants';

import customMailMergeTags from 'modules/Stores/CustomMailMergeTags';

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: NEW_CUSTOM_MAIL_MERGE_TAG_MODAL,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      createCustomMailMergeTag: customMailMergeTags.actions.createCustomMailMergeTag,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomMailMergeTagModal);
