import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectSms from '../modules/selectSms'
import Charges from '../modules/charges'
import Account from '../modules/account'
import TopUp from '../modules/topUp'

export class SMSRechargeView extends Component {

  render () {
    const { selectedSmsAccount } = this.props
    return (
      <div>
        <SelectSms.SelectSmsContainer />
        {selectedSmsAccount &&
          <div>
            <TopUp.TopUpContainer smsAccount={selectedSmsAccount} />
            <Account.AccountContainer smsAccount={selectedSmsAccount} />
            <Charges.ChargesContainer smsAccount={selectedSmsAccount} />
          </div>
        }
      </div>
    )
  }
}

SMSRechargeView.propTypes = {
  selectedSmsAccount: PropTypes.object
}

export default SMSRechargeView
