import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MigrateHeardAboutMethodModal from "../components/MigrateHeardAboutMethodModal";

import * as actions from "../actions";
import heardAboutMethods from "modules/Stores/HeardAboutMethods";
import * as heardAboutMethodApiActions from "modules/Stores/HeardAboutMethods/apiActions";

import modal from "modules/Stores/Modal";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    heardAboutMethods: heardAboutMethods.selectors.getHeardAboutMethods(state),
    errors: modal.selectors.getModalErrors(state),
    pageDetails: heardAboutMethods.selectors.getPageDetails(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      getHeardAboutMethods: heardAboutMethodApiActions.getHeardAboutMethods,
      deleteHeardAboutMethod: actions.deleteHeardAboutMethod
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MigrateHeardAboutMethodModal);
