import * as actionTypes from './actionTypes'

const SALES_FUNNEL_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_TAGS]: (state, action) => {
    return { ...state, tags: action.tags }
  },
  [actionTypes.FETCHING_POPULAR_TAGS_SUCCESS]: (state, action) => {
    return { ...state, popularTags: action.popularTags }
  },
  [actionTypes.ADD_TAG]: (state, action) => {
    const popularIndex = indexForTag(state.popularTags, action.tag.name)
    if (popularIndex !== -1) { return state }
    const newPopularTags = [
      ...state.popularTags,
      action.tag.name
    ]
    const newTags = [
      ...state.tags,
      action.tag
    ]
    return { ...state, popularTags: newPopularTags, tags: newTags }
  },
  [actionTypes.REMOVE_TAG]: (state, action) => {
    const popularIndex = indexForTag(state.popularTags, action.tag.name)
    const normalIndex = state.tags.findIndex(tag => tag.id === action.tag.id)

    let newTags = state.tags
    let newPopularTags = state.popularTags

    if (normalIndex !== -1) {
      newTags = [
        ...state.tags.slice(0, normalIndex),
        ...state.tags.slice(normalIndex + 1)
      ]
    }

    if (popularIndex !== -1) {
      newPopularTags = [
        ...state.popularTags.slice(0, popularIndex),
        ...state.popularTags.slice(popularIndex + 1)
      ]
    }
    return { ...state, popularTags: newPopularTags, tags: newTags }
  },
  [actionTypes.EDIT_TAG]: (state, action) => {
    const popularIndex = indexForTag(state.popularTags, action.oldValue)
    const normalIndex = state.tags.findIndex(tag => tag.id === action.tag.id)

    let newTags = state.tags
    let newPopularTags = state.popularTags

    if (normalIndex !== -1) {
      newTags = [
        ...state.tags.slice(0, normalIndex),
        action.tag,
        ...state.tags.slice(normalIndex + 1)
      ]
    }

    if (popularIndex !== -1) {
      newPopularTags = [
        ...state.popularTags.slice(0, popularIndex),
        action.tag.name,
        ...state.popularTags.slice(popularIndex + 1)
      ]
    }

    return { ...state, popularTags: newPopularTags, tags: newTags }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

function indexForTag (tags, tagName) {
  return tags.findIndex(tag => tag === tagName)
}

const initialState = {
  loading: false,
  errors: null,
  popularTags: [],
  tags: [],
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = SALES_FUNNEL_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
