import * as actionTypes from './actionTypes'

const PREVIOUS_IMPORTS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_IMPORTS]: (state, action) => {
    return { ...state, imports: action.imports }
  },
  [actionTypes.SET_CSV_IMPORT]: (state, action) => {
    if (!action.csvImport) { return }
    const newCsvImport = action.csvImport
    const oldImports = state.imports
    const index = indexForCsvImport(oldImports, newCsvImport.id)
    const newImports = [
      ...oldImports.slice(0, index),
      newCsvImport,
      ...oldImports.slice(index + 1)
    ]
    return { ...state, imports: newImports }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalCount: action.pageDetails.total_count
    }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    imports: [],
    loading: false,
    pageCount: 1,
    currentPage: 1,
    totalCount: 0,
    errors: null
  }
}

export default function reducer (state = initialState(), action) {
  const handler = PREVIOUS_IMPORTS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

function indexForCsvImport (csvImports, csvImportId) {
  return csvImports.findIndex(csvImport => csvImport.id === csvImportId)
}
