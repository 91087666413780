import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';
import TrialSelectView from './TrialSelectView';

import 'styles/react-datetime.css';
import moment from 'moment';
import Datetime from 'react-datetime';

import styled from 'styled-components';

const ValidationErrorDescription = styled.div`
  color: #d26a5c;
  font-style: italic;
  margin-top: 5px;
`;

export class TrialSelectModal extends Component {
  state = {
    date: Datetime.moment().tz(this.props.timezone),
  };

  cleanAndHide = () => {
    this.props.hideModal();
  };

  render() {
    const { currentModal, modalName, hideModal, formats } = this.props;
    const isValidDate = this.state.date.isValid();
    const defaultDateValue = this.state.date.format(formats.date);

    return (
      <Modal show={currentModal === modalName} onHide={this.cleanAndHide}>
        <div className="block">
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.cleanAndHide}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select a trial
            </h3>
          </div>

          <div className="block-content">
            <TrialSelectView {...this.props} trialStartsAt={this.state.date} />
          </div>
          <div className="block-content">
            <label className="control-label">Trial start date</label>
            <Datetime
              defaultValue={defaultDateValue}
              timeFormat={false}
              strictParsing={true}
              locale={formats.locale}
              dateFormat={formats.date}
              inputProps={{
                placeholder: formats.date,
              }}
              utc={false}
              timezone={this.props.timezone}
              viewMode={'days'}
              onChange={(date) => this.setState({ ...this.state, date: moment(date) })}
            />
            {!isValidDate && <ValidationErrorDescription>Invalid date</ValidationErrorDescription>}
          </div>
        </div>

        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

TrialSelectModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  getTrials: PropTypes.func.isRequired,
  onTrialSelect: PropTypes.func.isRequired,
  selectedTrialId: PropTypes.number,
  trials: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  formats: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default TrialSelectModal;
