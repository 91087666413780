import { createSelector } from 'reselect'
import * as arrayUtils from 'utils/arrayUtils'
import { NAME } from './constants'

export const getUsers = (state) => state[NAME].users
export const getSelectedUserIds = (state) => state[NAME].selectedUserIds
export const getPageDetails = state => state[NAME].pageDetails
export const getLoading = state => state[NAME].loading
export const getLastUsedLocationIds = state => state[NAME].lastUsedLocationIds

export function fetchOnLoadSelector (locationIds) {
  return createSelector(
    [ getLastUsedLocationIds ],
    (lastUsedLocationIds) => {
      if (lastUsedLocationIds === null) { return true }
      return !arrayUtils.arraysEqual(lastUsedLocationIds, locationIds)
    }
  )
}
