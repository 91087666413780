import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import lawfulProcessingBasisSelect from '../modules/lawfulProcessingBasisSelect'
import editLawfulProcessingBasis from '../modules/editLawfulProcessingBasis'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    lawfulProcessingBasis: lawfulProcessingBasisSelect.selectors.selectedLawfulProcessingBasis(state),
    loading: editLawfulProcessingBasis.selectors.getLoading(state),
    data: editLawfulProcessingBasis.selectors.getData(state),
    errors:  editLawfulProcessingBasis.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    saveLawfulProcessingBasis: editLawfulProcessingBasis.actions.saveLawfulProcessingBasis,
    prefilFormData: editLawfulProcessingBasis.actions.prefilFormData,
    onInput: editLawfulProcessingBasis.actions.onInput,
    deleteLawfulProcessingBasis: editLawfulProcessingBasis.actions.deleteLawfulProcessingBasis,
    changeShowing: editLawfulProcessingBasis.actions.changeShowing,
    showModal: modal.actions.showModal,
    reset: editLawfulProcessingBasis.actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(editLawfulProcessingBasis
  .components.EditLawfulProcessingBasisView)
