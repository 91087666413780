import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";
import locations from "modules/Stores/Locations";

import glofox from "../modules/configuration/modules/glofox";
import * as userDataSelectors from "modules/Stores/UserData/selectors";
import * as selectors from "../selectors";

function mapStateToProps(state, props) {
  const selectedLocationId = selectors.getSelectedLocationId(state);
  const selectedLocation = locations.selectors.locationForIdFactory(
    selectedLocationId
  )(state);
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    data: glofox.selectors.getData(state),
    location: selectedLocation,
    company: userDataSelectors.getCompany(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      resetForm: glofox.actions.reset,
      addOrUpdateOptionWithData: glofox.actions.addOrUpdateOptionWithData,
      deleteIntegration: glofox.actions.deleteIntegration,
      prefillFormForIntegration: glofox.actions.prefillFormForIntegration,
      onInput: glofox.actions.onInput
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(glofox.components.GlofoxConfigureModalView);
