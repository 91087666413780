import * as actionTypes from './actionTypes'

const INVITE_USERS_ACTION_HANDLERS = {
  [actionTypes.UPDATE_INVITE_DATA]: (state, action) => {
    const formState = action.data
    return { ...state, data: formState }
  },
  [actionTypes.RESET_FORM_DATA]: (state, action) => {
    const formState = initialFormData()
    return { ...state, data: formState }
  },
  [actionTypes.SENDING_INVITE_SUCCESS]: (state, action) => {
    const invites = [
      ...state.pendingInvites,
      action.invite ]
    return { ...state, pendingInvites: invites }
  },
  [actionTypes.GET_PENDING_INVITES]: (state, action) => {
    return { ...state, pendingInvitesLoading: true }
  },
  [actionTypes.GET_PENDING_INVITES_SUCCESS]: (state, action) => {
    return { ...state, pendingInvitesLoading: false, pendingInvites: action.pendingInvites }
  },
  [actionTypes.GET_PENDING_INVITES_FAILURE]: (state, action) => {
    return { ...state, pendingInvitesLoading: false }
  },
  [actionTypes.RESENDING_INVITE]: (state, action) => {
    return { ...state, pendingInvitesLoading: true }
  },
  [actionTypes.RESEND_INVITE_SUCCESS]: (state, action) => {
    return { ...state, pendingInvitesLoading: false }
  },
  [actionTypes.RESEND_INVITE_FAILRE]: (state, action) => {
    return { ...state, pendingInvitesLoading: false }
  },
  [actionTypes.REVOKING_INVITE]: (state, action) => {
    return { ...state, pendingInvitesLoading: true }
  },
  [actionTypes.REVOKE_INVITE_SUCCESS]: (state, action) => {
    const indexToRemove = action.index
    const invites = [
      ...state.pendingInvites.slice(0, indexToRemove),
      ...state.pendingInvites.slice(indexToRemove + 1)
    ]
    return { ...state, pendingInvitesLoading: false, pendingInvites: invites }
  },
  [actionTypes.REVOKE_INVITE_FAILRE]: (state, action) => {
    return { ...state, pendingInvitesLoading: false }
  }
}

export default function reducer (state = initialState, action) {
  const handler = INVITE_USERS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

const initialState = {
  data : initialFormData(),
  pendingInvitesLoading: false,
  pendingInvites: []
}

function initialFormData () {
  return {
    isValid: false,
    hasSent: false,
    loading: false,
    errors: null,
    fields: {
      givenName: {
        value: null,
        isValid: false
      },
      email: {
        value: null,
        isValid: false
      },
      role: {
        value: null,
        isValid: false
      }
    }
  }
}
