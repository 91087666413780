import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';
import * as arrayUtils from 'utils/arrayUtils';

import MutliLocationSelectView from './MultiLocationSelectView';

export class MultiLocationSelectModal extends Component {
  state = {
    selectedLocationIds: Object.keys(this.props.data.fields).map((string) => parseInt(string)),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldData = this.props.data;
    const newData = nextProps.data;

    if (!oldData || !newData) {
      return;
    }
    const oldIds = Object.keys(oldData.fields) || [];
    const newIds = Object.keys(newData.fields) || [];

    if (arrayUtils.arraysEqual(oldIds, newIds)) {
      return;
    }
    this.setState({
      selectedLocationIds: newIds.map((string) => parseInt(string)),
    });
  }

  selectLocation = (location) => {
    const newSelectedLocationIds = [...this.state.selectedLocationIds, location.id];
    this.setState({
      ...this.state,
      selectedLocationIds: newSelectedLocationIds,
    });
  };

  deselectLocation = (location) => {
    const index = this.state.selectedLocationIds.findIndex((locationId) => parseInt(locationId) === parseInt(location.id));
    const newSelectedLocationIds = [...this.state.selectedLocationIds.slice(0, index), ...this.state.selectedLocationIds.slice(index + 1)];
    this.setState({
      ...this.state,
      selectedLocationIds: newSelectedLocationIds,
    });
  };

  useAllLocations = () => {
    this.setState({ ...this.state, selectedLocationIds: [] });
    const newSectionData = { fields: {} };
    this.props.onUpdate(newSectionData, this.props.sectionName, []);
    this.cleanAndHide();
  };

  updateClubIds = () => {
    let fields = {};
    this.state.selectedLocationIds.forEach((locationId) => {
      const object = { value: true, isValid: true };
      fields = { ...fields, [locationId]: object };
    });
    const newSectionData = { fields: fields, isValid: true };
    this.props.onUpdate(newSectionData, this.props.sectionName, this.state.selectedLocationIds);
    this.cleanAndHide();
  };

  cleanAndHide = () => {
    this.setState({ selectedLocationIds: [] });
    this.props.hideModal();
  };

  render() {
    const { currentModal, modalName, hideModal, showAllLocationsButton } = this.props;
    const disabled = this.state.selectedLocationIds.length === 0;
    const buttonTitle = this.props.buttonTitle || 'Filter by selected';

    return (
      <Modal show={currentModal === modalName} onHide={this.cleanAndHide}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.cleanAndHide}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select locations
            </h3>
          </div>
          <div className="block-content">
            <MutliLocationSelectView
              {...this.props}
              selectedLocationIds={this.state.selectedLocationIds}
              selectLocation={this.selectLocation}
              deselectLocation={this.deselectLocation}
            />
          </div>
        </div>
        <Modal.Footer>
          {showAllLocationsButton && (
            <button className="btn btn-primary pull-left" onClick={this.useAllLocations}>
              All Locations
            </button>
          )}
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={disabled} onClick={this.updateClubIds}>
            <i className="fa fa-building-o" /> {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MultiLocationSelectModal.propTypes = {
  getLocations: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  currentModal: PropTypes.string,
  showAllLocationsButton: PropTypes.bool,
};

MultiLocationSelectModal.defaultProps = {
  buttonTitle: 'Filter by selected',
  showAllLocationsButton: true,
};

export default MultiLocationSelectModal;
