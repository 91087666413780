import UserAvatar from './Avatars/UserAvatar';
import ChartBox from './ChartBox/ChartBox';
import ContentBlockView from './ContentBlockView/ContentBlockView';
import ConversionChevronView from './ConversionChevronView/ConversionChevronView';
import { EmptyView } from './EmptyView/EmptyView';
import ExportButton from './ExportButton/ExportButtonContainer';
import * as FormFields from './FormFields';
import GymLeadsLoader from './GymleadsLoader';
import HeaderContainer from './header';
import HistoryView from './HistoryView/HistoryView';
import ImageEmptyView from './ImageEmptyView/ImageEmptyView';
import { InfoView } from './InfoView/InfoView';
import LeadCapsule from './LeadCapsule/LeadCapsule';
import LeadSalesFunnelSteps from './LeadSalesFunnelSteps/LeadSalesFunnelSteps';
import { LeadStatusTag } from './LeadStatusTag/LeadStatusTag';
import LocationSelect from './LocationSelect';
import NewEmailMessageEditor from './MessageEditors/NewEmailMessageEditor';
import NavLink from './NavLink/NavLink';
import PageHeader from './PageHeader/PageHeader';
import PageSelection from './PageSelection/PageSelection';
import PagesView from './PageSelection/PagesView';
import PrivateRoute from './privateRoute/PrivateRoute';
import PrivateRouteContainer from './privateRoute/PrivateRouteContainer';
import { QuickInfoBlock } from './QuickInfoBlock/QuickInfoBlock';
import SelectDateRangeButton from './SelectDateRangeButton/SelectDateRangeButton';
import SimpleImageEmptyView from './SimpleImageEmptyView/SimpleImageEmptyView';
import StepNumberView from './StepNumberView/StepNumberView';
import TextSearchView from './TextSearchView/TextSearchView';
import TimeSelectView from './TimeSelect/TimeSelectView';
import RawToolTip from './ToolTip/RawToolTip';
import RichToolTip from './ToolTip/RichToolTip';
import ToolTip from './ToolTip/ToolTip';
import EditableUserRoleTag from './UserRoleTag/EditableUserRoleTag';
import { UserRoleTag } from './UserRoleTag/UserRoleTag';

import AssignLead from './AssignLead';
import CalendarEvents from './Calendar';
import CallScheduleSelect from './CallScheduleSelect';
import CreateLead from './CreateLead';
import EmailHistory from './EmailHistory';
import HeaderNotifications from './HeaderNotifications';
import LeadDetail from './LeadDetail';
import MultiRegionSelect from './MultiRegionSelect';
import MultiUserSelect from './MultiUserSelect';
import SelectUserModal from './SelectUserModal';
import Tags from './Tags';

export { default as Button } from './Button';
export { ColorPicker, Switch, TextArea, TextField } from './FormFields';
export * from './Foundation';
export { TR } from './Table/TR';
export {
  FormFields,
  HeaderContainer,
  PrivateRoute,
  PrivateRouteContainer,
  EmptyView,
  InfoView,
  UserRoleTag,
  EditableUserRoleTag,
  PageSelection,
  PagesView,
  SelectDateRangeButton,
  PageHeader,
  QuickInfoBlock,
  NavLink,
  UserAvatar,
  RichToolTip,
  ToolTip,
  ConversionChevronView,
  RawToolTip,
  ChartBox,
  HistoryView,
  StepNumberView,
  LeadStatusTag,
  Tags,
  LeadSalesFunnelSteps,
  NewEmailMessageEditor,
  LeadCapsule,
  LocationSelect,
  ContentBlockView,
  ImageEmptyView,
  SimpleImageEmptyView,
  EmailHistory,
  TextSearchView,
  GymLeadsLoader,
  TimeSelectView,
  ExportButton,
};

export const componentStores = {
  Tags,
  MultiUserSelect,
  SelectUserModal,
  MultiRegionSelect,
  LeadDetail,
  HeaderNotifications,
  EmailHistory,
  CreateLead,
  CalendarEvents,
  CallScheduleSelect,
  AssignLead,
};
