import { createSelector } from 'reselect'

export const getLeads = state => state.dashboard.QueuedLeads.leads
export const getPageCount = state => state.dashboard.QueuedLeads.pageCount
export const getCurrentPage = state => state.dashboard.QueuedLeads.currentPage
const getSelectedLeadId = state => state.dashboard.QueuedLeads.selectedLeadId

export const selectedLead = createSelector(
  [ getLeads, getSelectedLeadId ],
  (leads, selectedLeadId) => {
    const index = leads.findIndex(lead => lead.id === selectedLeadId)
    return leads[index]
  }
)
