import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { generateNewFormState } from 'utils/formHelpers'
import { TopUpFormValidator } from '../validator'

import SweetAlert from 'react-bootstrap-sweetalert'

import { FormFields } from 'modules/components'
const { TextField } = FormFields

export class TopUpForm extends Component {

  state = this.defaultState()

  defaultState () {
    return {
      showWarning: false,
      data: {
        isValid: false,
        fields: {
          amount: {
            value: null,
            isValid: false
          }
        }
      }
    }
  }

  handleInput = (name, value, isValid) => {
    const newData = generateNewFormState(this.state.data, name, value, isValid)
    this.setState({ ...this.state, data: newData })
  }

  showWarning = (e) => {
    if (e) { e.preventDefault() }
    this.setState({ ...this.state, showWarning: true })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showWarning: false })
  }

  topUpAccount = () => {
    const { topUpAccount, smsAccount } = this.props
    const { amount } = this.state.data.fields

    topUpAccount(smsAccount, amount.value)
    this.setState(this.defaultState())
  }

  render () {
    const { isValid } = this.state.data
    const { amount } = this.state.data.fields
    const dispayAmount = (amount.value) ? parseFloat(amount.value).toFixed(2) : ''
    return (
      <div className='block block-bordered'>
        <div className='block-content block-content-full border-b'>
          <h3>Top-up account</h3>
          <form className='form-horizontal push-30-t'>
            <TextField
              name='amount'
              label='Amount'
              placeholder='20'
              onChange={this.handleInput}
              initialValue={amount.value}
              validator={TopUpFormValidator} />
            <div className='form-group'>
              <div className='col-sm-6'>
                <button
                  type='button'
                  className='btn btn-block btn-success'
                  style={{ marginTop: '8px' }}
                  disabled={!isValid}
                  onClick={this.showWarning}>
                  <i className='fa fa-dollar' /> Top-up account
                </button>
              </div>
            </div>
          </form>
        </div>
        {this.state.showWarning &&
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='warning'
            title='Are you Sure?'
            confirmBtnText='Top-up account'
            onConfirm={this.topUpAccount}
            onCancel={this.dismissPopups}>
            This will charge your credit credit card for ${dispayAmount}.
            Is this the right amount?
          </SweetAlert>
        }
      </div>
    )
  }
}

TopUpForm.propTypes = {
  smsAccount: PropTypes.object.isRequired,
  topUpAccount: PropTypes.func.isRequired
}

export default TopUpForm
