import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';

export class ClassRow extends Component {
  rowClicked = () => {
    const { classRecord, selectClass } = this.props;
    if (classRecord.is_canceled === true || !classRecord.is_available) {
      return;
    }
    selectClass(classRecord);
  };

  render() {
    const { classRecord, selected, timezone, classIntegrationName } = this.props;
    let startTimeString, endTimeString;

    if (['glofox', 'journey'].includes(classIntegrationName)) {
      // convert to user's timezone for glofox and Journey class times
      startTimeString = moment(classRecord.start_date_time).tz(timezone).format('hh:mm');
      endTimeString = moment(classRecord.end_date_time).tz(timezone).format('hh:mm A');
    } else {
      startTimeString = moment(classRecord.start_date_time).utc().format('hh:mm');
      endTimeString = moment(classRecord.end_date_time).utc().format('hh:mm A');
    }

    const unBookableClass = classRecord.is_canceled || !classRecord.is_available;
    const selectedStyle = selected ? 'selected' : '';
    const textStyle = unBookableClass ? 'text-muted' : 'selectable';
    return (
      <tr className={selectedStyle + ' ' + textStyle} onClick={this.rowClicked}>
        <td>
          {startTimeString} - {endTimeString}
        </td>
        <td>{classRecord.name}</td>
        {classRecord.capacity && (
          <td>
            {classRecord.is_canceled && <span className="text-danger">Cancelled</span>}
            {!classRecord.is_canceled &&
              classRecord.total_booked + '/' + classRecord.capacity + ' (' + classRecord.leads_booked + ' leads)'}
          </td>
        )}
        <td>{classRecord.staff_name}</td>
      </tr>
    );
  }
}

ClassRow.propTypes = {
  classRecord: PropTypes.object.isRequired,
  selectClass: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ClassRow;
