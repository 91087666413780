import { newPayloadForFormValue, newPayloadForValue } from "utils/formHelpers";

export function seralizeLeadFromData(
  leadData,
  locationId,
  assignToId,
  leadSourceId,
  heardAboutMethodId,
  leadGoal
) {
  const {
    givenName,
    surname,
    email,
    mobileNumber,
    source,
    workplaceName,
    notes,
    gender,
    dateOfBirth,
    zipPostcode,
    tags
  } = leadData.fields;

  let body = {
    location_id: locationId,
    lead_source_id: leadSourceId,
    assign_to_user_id: assignToId,
    heard_about_method_id: heardAboutMethodId,
    reason_for_interest: leadGoal
  };
  body = newPayloadForFormValue(body, givenName, "given_name");
  body = newPayloadForFormValue(body, surname, "surname");
  body = newPayloadForFormValue(body, email, "email");
  body = newPayloadForFormValue(body, mobileNumber, "mobile_number");
  body = newPayloadForFormValue(body, workplaceName, "workplace_name");
  body = newPayloadForFormValue(body, source, "source");
  body = newPayloadForFormValue(body, zipPostcode, "zip_postcode");
  body = newPayloadForFormValue(body, gender, "gender");
  body = newPayloadForFormValue(body, dateOfBirth, "date_of_birth");
  body = newPayloadForFormValue(body, notes, "note");
  body = newPayloadForValue(body, tags, "tags");

  return body;
}

export function seralizeLeadForLocation(leadData, locationId) {
  const {
    givenName,
    surname,
    email,
    mobileNumber,
    leadSourceId,
    lookingFor,
    source,
    fitnessGoal,
    heardAboutMethod,
    tags,
    workplaceName
  } = leadData.fields;

  let body = { location_id: locationId };
  body = newPayloadForFormValue(body, givenName, "given_name");
  body = newPayloadForFormValue(body, surname, "surname");
  body = newPayloadForFormValue(body, email, "email");
  body = newPayloadForFormValue(body, mobileNumber, "mobile_number");
  body = newPayloadForFormValue(body, workplaceName, "workplace_name");
  body = newPayloadForFormValue(body, fitnessGoal, "reason_for_interest");
  body = newPayloadForFormValue(body, lookingFor, "looking_for");
  body = newPayloadForFormValue(
    body,
    heardAboutMethod,
    "heard_about_method_id"
  );
  body = newPayloadForFormValue(body, leadSourceId, "lead_source_id");
  body = newPayloadForFormValue(body, source, "source");
  body = newPayloadForValue(body, tags, "tags");

  return body;
}
