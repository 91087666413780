import { isEmail, isMinLength, isPresent, isValidDate } from "utils/validation";

export const NewLeadFormValidator = {
  givenName: [
    {
      rule: isPresent,
      errorMessage: "We need to know their name!",
    },
  ],
  location: [
    {
      rule: isPresent,
      errorMessage: "Which location are they interested in?",
    },
  ],
  email: [
    {
      rule: isEmail,
      errorMessage: "That doesn't look like an email",
    },
  ],
  mobileNumber: [
    {
      rule: isMinLength(8),
      errorMessage: "Mobile numbers are at least 8 characters",
    },
  ],
  dateOfBirth: [
    {
      rule: isValidDate,
      errorMessage: "That does not look like a valid date",
    },
  ],
  zipPostcode: [
    {
      rule: isMinLength(3),
      errorMessage: "Zip or Postcode must be more than 4 characters",
    },
  ],
};
