export function userRoleHasManagerPermissions(userRole) {
  return ["Admin", "Owner", "Salesmanager", "Franchise Owner"].includes(
    userRole.name
  );
}

export function userRoleHasOwnerPermissions(userRole) {
  return ["Admin", "Owner"].includes(userRole.name);
}

export function userRoleHasFranchiseOwnerPermissions(userRole) {
  return ["Admin", "Owner", "Franchise Owner"].includes(userRole.name);
}

export function userRoleHasReceptionPermissions(userRole) {
  return ["Reception"].includes(userRole.name);
}

export function userCanAssign(user) {
  return user.user_permission_rule_set.allow_lead_assign === true;
}

export function userCanViewOthersLeads(user) {
  return user.user_permission_rule_set.allow_view_others_leads === true;
}

export function userCanReAssign(user) {
  return user.user_permission_rule_set.allow_lead_reassign === true;
}

export function userCanSeeAllCalenders(user) {
  return user.user_permission_rule_set.allow_other_calendar === true;
}

export function userHasOpenComms(user) {
  return user.user_permission_rule_set.allow_open_comms === true;
}

export function userCanUseMarketing(user) {
  return user.user_permission_rule_set.allow_marketing === true;
}

export function userCanAccessBilling(user) {
  switch (user.user_role.name) {
    case "Owner":
    case "Admin":
    case "Franchise Owner":
      return true;
    default:
      return false;
  }
}

export function canSwitchDashboard(user, permissions) {
  const hasManagerPermissions = userRoleHasManagerPermissions(user.user_role);
  const multiUser = permissions.multi_user;
  return hasManagerPermissions && multiUser;
}

export function canAccessReports(user) {
  if (!user) return false;
  const hasManagerPermissions = userRoleHasManagerPermissions(user.user_role);
  if (hasManagerPermissions) return true;
  return user.user_permission_rule_set.allow_view_reports;
}
