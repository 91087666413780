import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView, EmptyView, TextSearchView } from 'modules/components'
import SelectUserTableView from './TableView/SelectUserTableView'

import PagesContainer from '../containers/PagesContainer'

export class MultiUserSelectView extends Component {

  componentDidMount () {
    const { fetchOnLoad, setLastUsedLocationIds, locationIds } = this.props
    setLastUsedLocationIds(locationIds)
    if (fetchOnLoad !== true) { return }
    this.getUsersWithPageNumber(1)
  }

  getUsersWithPageNumber = (pageNumber) => {
    const { get, locationIds } = this.props
    get(pageNumber, null, locationIds)
  }

  pageClicked = (pageNumber) => {
    this.getUsersWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm, fieldName) => {
    const { get, locationIds } = this.props
    get(1, searchTerm, locationIds)
  }

  render () {
    const { users, selectUser, deselectUser, loading, selectedUserIds, pageDetails } = this.props
    const pageCount = pageDetails.pageCount

    return (
      <ContentBlockView
        loading={loading}
        bordered>
        <div className='row' style={{ marginTop: '-20px' }}>
          <TextSearchView
            sectionName={'userSearch'}
            onUpdate={this.searchUpdated} />
        </div>
        {!loading && users.length > 0 &&
          <SelectUserTableView
            users={users}
            selectedUserIds={selectedUserIds}
            selectUser={selectUser}
            filterFunction={this.filterExistingUsers}
            deselectUser={deselectUser} />
        }
        {!loading && users.length === 0 &&
          <EmptyView
            colorThemeClass='primary'
            iconClass='si si-magnifier'
            title='No results'
            subtitle='Clear the search field and try again' />
        }
        {pageCount > 1 &&
          <div className='text-center push'>
            <PagesContainer pageClicked={this.pageClicked} />
          </div>
        }
      </ContentBlockView>
    )
  }
}

MultiUserSelectView.propTypes = {
  get: PropTypes.func.isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectUser: PropTypes.func.isRequired,
  deselectUser: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setLastUsedLocationIds: PropTypes.func.isRequired,
  fetchOnLoad: PropTypes.bool.isRequired,
  locationIds: PropTypes.arrayOf(PropTypes.number)
}

export default MultiUserSelectView
