import React from "react";
import PropTypes from "prop-types";
import { EmptyView, PagesView } from "modules/components";
import CallItem from "./CallItem";
import styled from "styled-components";

const CallItemList = ({
  lead,
  user,
  calls,
  callClicked,
  callDeleted,
  fetchCalls,
  pageDetails,
  loading
}) => {
  const callList = calls.map((call, index) => {
    return (
      <CallItem
        key={index}
        call={call}
        callClicked={callClicked}
        timezone={user.timezone}
        callDeleted={callDeleted}
        loading={loading}
      />
    );
  });

  function pageClicked(pageNumber) {
    fetchCalls(lead, pageNumber);
  }

  return (
    <Container>
      <div className="col-xs-12">
        {calls.length > 0 ? (
          <CallItemsDiv>{callList}</CallItemsDiv>
        ) : (
          <EmptyView
            colorThemeClass="primary"
            iconClass="fa fa-tasks"
            title="No calls for this lead"
          />
        )}
      </div>
      {pageDetails.pageCount > 1 && (
        <div className="col-xs-12">
          <div className="text-center push">
            <PagesView pageDetails={pageDetails} pageClicked={pageClicked} />
          </div>
        </div>
      )}
    </Container>
  );
};

export default CallItemList;

CallItemList.propTypes = {
  calls: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  callDeleted: PropTypes.func.isRequired,
  callClicked: PropTypes.func.isRequired,
  fetchCalls: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const CallItemsDiv = styled.div``;
const Container = styled.div``;
