import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Select from 'react-select'
import 'react-select/dist/react-select.css'

export class TagView extends Component {

  handleUpdate = (tags) => {
    const tagStrings = tags.map(tag => tag.value)
    this.props.setTags(tagStrings)
  }

  generateValues = () => {
    return this.props.tags.map((tagString) => {
      return {
        value: tagString,
        label: tagString
      }
    })
  }

  generateSuggestions = () => {
    return this.props.suggestions.map((tag) => {
      return {
        value: tag,
        label: tag
      }
    })
  }

  render () {
    return (
      <div className='form-material form-material-primary select-up'>
        <Select.Creatable
          multi={true}
          promptTextCreator={(label) => 'Add new tag called:' + label}
          placeholder={'Select or type a new tag'}
          value={this.generateValues()}
          options={this.generateSuggestions()}
          onChange={this.handleUpdate}
        />
        <label>Tags</label>
      </div>
    )
  }
}

TagView.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired
}

export default TagView
