import { request, GET, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export const getImportsWithPageNumber = (pageNumber) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setImports(payload.csv_imports));
      dispatch(loadPageDetails(payload.meta));
    }

    return request('csv_imports?page[number]=' + pageNumber, GET, null, onSuccess, onFailure);
  };
};

export const reverseImportWithId = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
      notify.error('Imports can only be reversed by the import creator or an admin');
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success('Import reversing');
      dispatch(setCsvImport(payload.csv_import));
    }

    return request('csv_imports/' + id, DELETE, null, onSuccess, onFailure);
  };
};

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setCsvImport(csvImport) {
  return {
    type: actionTypes.SET_CSV_IMPORT,
    csvImport,
  };
}

function setImports(imports) {
  return {
    type: actionTypes.SET_IMPORTS,
    imports,
  };
}
