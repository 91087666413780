import { newPayloadForFormValue } from 'utils/formHelpers';
import { trackEvent } from 'modules/Helpers/Analytics';
import * as integrationApi from 'modules/api/thirdPartyIntegration';
import * as actionTypes from './actionTypes';
import * as listingActions from '../../../listing/actions';
import notify from 'modules/Notifications';
import modal from 'modules/Stores/Modal';

export const addOrUpdateOptionWithData = (formData, location, toTabIndex) => {
  return async (dispatch) => {
    dispatch(modal.actions.startLoading());
    try {
      const body = {
        third_party_integration: seralizeOptionFromFormData(formData, 'booker', 'outgoing'),
      };
      const thirdPartyIntegrationId = formData.fields.id.value;
      let response;
      if (thirdPartyIntegrationId) {
        // If a integration ID exists, then we are updating the integration
        response = await integrationApi.updateIntegration(location.id, thirdPartyIntegrationId, body);
        dispatch(modal.actions.stopLoading());
        dispatch(listingActions.addIntegration(response.third_party_integration));
      } else {
        // If there is no integration Id, we are creating a new integration
        response = await integrationApi.createIntegration(location.id, body);
        dispatch(modal.actions.stopLoading());
        dispatch(listingActions.updateIntegration(response.third_party_integration));
      }
      dispatch(prefillFormForIntegration(response.third_party_integration));
      dispatch(setTabIndex(toTabIndex));
    } catch (errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors.message.message));
    }
  };
};

export const deleteIntegration = (formData, location) => {
  return async (dispatch) => {
    dispatch(modal.actions.startLoading());
    try {
      const thirdPartyIntegrationId = formData.fields.id.value;
      await integrationApi.deleteIntegration(location.id, thirdPartyIntegrationId);
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(listingActions.deleteIntegration(formData.fields.id.value));
      dispatch(reset());
      notify.success('Integration Removed');
      trackEvent('Integration Removed');
    } catch (errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors({ errors: errors.message.message }));
    }
  };
};

export const getLocationsForPage = (location, thirdPartyIntegrationId, pageNumber) => {
  return async (dispatch) => {
    dispatch(modal.actions.startLoading());
    try {
      const response = await integrationApi.getLocationsForPage(location.id, thirdPartyIntegrationId, pageNumber);
      dispatch(modal.actions.stopLoading());
      dispatch(setSiteLocations(response.locations));
      dispatch(loadPageDetails(response.meta));
    } catch (errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors({ errors: errors.message.message }));
    }
  };
};

export function onInput(data) {
  return {
    type: actionTypes.ON_INPUT,
    data,
  };
}

export function prefillFormForIntegration(integration) {
  return {
    type: actionTypes.PREFIL_FORM,
    integration,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setTabIndex(tabIndex) {
  return {
    type: actionTypes.SET_TAB_INDEX,
    tabIndex,
  };
}

function setSiteLocations(siteLocations) {
  return {
    type: actionTypes.SET_SITE_LOCATIONS,
    siteLocations,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function seralizeOptionFromFormData(formData, name, direction) {
  const { companyName, integrationLocationId, apiKey } = formData.fields;

  let payload = { name: 'booker', direction: 'outgoing' };
  payload = newPayloadForFormValue(payload, integrationLocationId, 'integration_location_id');

  if (companyName.value.length > 0) {
    payload = newPayloadForFormValue(payload, companyName, 'company_name');
  }
  if (apiKey.value.length > 0) {
    payload = newPayloadForFormValue(payload, apiKey, 'api_key');
  }

  return payload;
}
