import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CallItemList from '../components/CallItemList/CallItemList'
import calls from '../../calls'
import session from 'modules/Stores/Session'

function mapStateToProps (state, ownProps) {
  return {
    calls: calls.selectors.getCalls(state, ownProps.pathToLeadDetail),
    loading: calls.selectors.getLoading(state, ownProps.pathToLeadDetail),
    user: session.selectors.getUser(state),
    pageDetails: calls.selectors.getPageDetails(state, ownProps.pathToLeadDetail)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchCalls: calls.actions.fetchCalls
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CallItemList)
