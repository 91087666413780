import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateNewFormState } from 'utils/formHelpers';

import { FormFields } from 'modules/components';

const { Switch } = FormFields;

export class PermissionsForm extends Component {
  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true);
    this.props.onInput(newData);
  };

  render() {
    const {
      allowLeadAssign,
      allowLeadReassign,
      allowOtherCalendar,
      allowOpenComms,
      allowMarketing,
      allowViewOthersLeads,
      showSharedInbox,
      viewAllMarketingAssets,
      allowViewReports,
      allowLeadSettings,
    } = this.props.data.fields;
    const { isAdmin } = this.props;
    const allowOpenCommsText = 'Allow this user to send free text emails and sms? (If this is off they will only be able to use templates)';
    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <h3 className="block-title">Assign Leads</h3>
            <Switch
              name="allowLeadAssign"
              label="Allow this user to assign new leads to anyone at that location"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowLeadAssign.value}
            />
            <h3 className="block-title">Reassign Leads</h3>
            <Switch
              name="allowLeadReassign"
              label="Allow this user to reassign any lead during the assigning process"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowLeadReassign.value}
            />
            <h3 className="block-title">View other users leads</h3>
            <Switch
              name="allowViewOthersLeads"
              label="Allow this user to view other users leads."
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowViewOthersLeads.value}
            />
            <h3 className="block-title">Share Inbox</h3>
            <Switch
              name="showSharedInbox"
              label="Allow incoming emails, SMS and Facebook messages to show in the shared inbox widget"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={showSharedInbox.value}
            />
            <h3 className="block-title">View others schedules</h3>
            <Switch
              name="allowOtherCalendar"
              label="Allow this user to view other peoples schedules"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowOtherCalendar.value}
            />
            <h3 className="block-title">Allow open communication</h3>
            <Switch
              name="allowOpenComms"
              label={allowOpenCommsText}
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowOpenComms.value}
            />
            <h3 className="block-title">Allow marketing</h3>
            <Switch
              name="allowMarketing"
              label="Can this user use the marketing module?"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowMarketing.value}
            />
            <h3 className="block-title">View reports</h3>
            <Switch
              name="allowViewReports"
              label="Can this user view reports? (Only applicable to salespeople)"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={allowViewReports.value}
            />
            {isAdmin && (
              <div>
                <h3 className="block-title">View other peoples Marketing Assets?</h3>
                <Switch
                  name="viewAllMarketingAssets"
                  label="Can this user view and edit other peoples marketing assets?"
                  onChange={this.handleToggle}
                  switchStyle="switch-sm switch-primary"
                  value={viewAllMarketingAssets.value}
                />
                <h3 className="block-title">Update Lead Settings?</h3>
                <Switch
                  name="allowLeadSettings"
                  label="Can this user update company wide Lead settings (lead sources, heard about methods, etc)"
                  onChange={this.handleToggle}
                  switchStyle="switch-sm switch-primary"
                  value={allowLeadSettings.value}
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <button className="btn btn-primary push-5-r push-10" type="button" onClick={this.props.onSave}>
              <i className="si si-user" /> Update User Permissions
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PermissionsForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
};
