import * as actionTypes from './actionTypes'

const BILLING_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_PAYMENT_METHODS]: (state, action) => {
    return { ...state, paymentMethods: action.paymentMethods }
  },
  [actionTypes.SET_DEFAULT_SMS_COST]: (state, action) => {
    return { ...state, defaultSmsCost: action.defaultSmsCost }
  },
  [actionTypes.ADD_PAYMENT_METHOD]: (state, action) => {
    const newPaymentMethods = [
      ...state.paymentMethods,
      action.paymentMethod
    ]
    return { ...state, paymentMethods: newPaymentMethods }
  },
  [actionTypes.REMOVE_PAYMENT_METHOD]: (state, action) => {
    const index = indexForPaymentMethod(state.paymentMethods, action.paymentMethodId)
    const newPaymentMethods = [
      ...state.paymentMethods.slice(0, index),
      ...state.paymentMethods.slice(index + 1)
    ]
    return { ...state, paymentMethods: newPaymentMethods }
  },
  [actionTypes.UPDATE_PAYMENT_METHOD]: (state, action) => {
    const index = indexForPaymentMethod(state.paymentMethods, action.paymentMethod.id)
    const newPaymentMethods = [
      ...state.paymentMethods.slice(0, index),
      action.paymentMethod,
      ...state.paymentMethods.slice(index + 1)
    ]
    return { ...state, paymentMethods: newPaymentMethods }
  }
}

const initialState = {
  loading: false,
  defaultSmsCost: 0.10,
  paymentMethods: []
}

function indexForPaymentMethod (paymentMethods, paymentMethodId) {
  return paymentMethods.findIndex(paymentMethods => parseInt(paymentMethods.id) === parseInt(paymentMethodId))
}

export default function reducer (state = initialState, action) {
  const handler = BILLING_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
