import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import locations from 'modules/Stores/Locations'
import salesTargets from '../modules/salesTargets'
import currentTarget from '../modules/salesTargets/modules/currentTarget'
import teamMembers from '../modules/teamMembers'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.selectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    location: selectedLocation,
    user: teamMembers.selectors.selectedUser(state),
    targetRuleSet: currentTarget.selectors.getSalesTarget(state),
    loading: currentTarget.selectors.getLoading(state),
    selectedLocationId: selectedLocationId
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchTargetRuleSetForLocationUser: currentTarget.actions.getCurrentSalesTarget
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(salesTargets.components.SalesTargetsView)
