import { request, buildIndexUrl, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getSharedInboxNotifications = (page, locationId, userIds, search) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setNotifications(payload.notifications))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setLoading(false))
    }
    let url = 'locations/' + locationId + '/shared_inbox'
    url = buildIndexUrl(url, page, null, search)
    if (userIds && userIds.length > 0) {
      url = url + '&user_ids[]=' + userIds.join('&user_ids[]=')
    }
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setNotifications (notifications) {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    notifications
  }
}

export function setSelectedUserIds (selectedUserIds) {
  return {
    type: actionTypes.SET_SELECTED_USER_IDS,
    selectedUserIds
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
