import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class UploadCsvView extends Component {

  UNSAFE_componentWillMount () {
    const { headers, locationId, csvFile, statusMaps, lawfulProcessingBasesMap,
      salespeopleMaps, uploadCsvFile, importOptions } = this.props
    uploadCsvFile(csvFile, locationId, headers, salespeopleMaps, statusMaps, lawfulProcessingBasesMap, importOptions)
  }

  render () {
    const { isActive, loading } = this.props
    const activeClassName = (isActive) ? 'active in' : ''
    return (
      <div className={'tab-pane fade fade-up push-30-t push-50 ' + activeClassName}>
        {loading &&
          <div className='text-center'>
            <h3 style={{ paddingBottom: '5px' }}>Uploading CSV</h3>
            <i className='fa fa-3x fa-cog fa-spin' />
          </div>
        }
        {!loading &&
          <div className='text-center'>
            <h3 style={{ paddingBottom: '5px' }}>Finished uploading</h3>
            <i className='fa fa-5x fa-check-circle text-success' style={{ paddingBottom: '5px' }} />
            <p>Your leads will being importing soon. We'll send you an email when it's done</p>
          </div>
        }
      </div>
    )
  }
}

UploadCsvView.propTypes = {
  importOptions: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  locationId: PropTypes.number.isRequired,
  statusMaps: PropTypes.arrayOf(PropTypes.object),
  salespeopleMaps: PropTypes.arrayOf(PropTypes.object),
  lawfulProcessingBasesMap: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  csvFile: PropTypes.object.isRequired,
  uploadCsvFile: PropTypes.func.isRequired
}

export default UploadCsvView
