import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapStatusesView from '../components/MapStatusesView'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    statusesToMap: selectors.getStatuses(state),
    gymleadsStatuses: ['not_assigned', 'active', 'inactive', 'lost', 'closed'],
    statusMaps: selectors.getStatusMaps(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addStatusMap: actions.addStatusMap,
    updateStatusMap: actions.updateStatusMap
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MapStatusesView)
