import { request, POST, GET } from 'utils/apiUtils';

import * as actionTypes from './actionTypes';
import { startSession } from 'modules/Stores/Session/actions';

import moment from 'moment';
import 'moment-timezone';

// Fetch
export const fetchInvite = (token) => {
  return (dispatch) => {
    dispatch(fetchingInvite());

    function onFailure(errors) {
      dispatch(fetchInviteFailure(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchInviteSuccess(payload.invite));
    }

    return request('invites/' + token, GET, null, onSuccess, onFailure);
  };
};

function fetchingInvite() {
  return {
    type: actionTypes.FETCHING_INVITE,
  };
}

function fetchInviteSuccess(invite) {
  return {
    type: actionTypes.FETCHING_INVITE_SUCCESS,
    invite,
  };
}

function fetchInviteFailure(errors) {
  return {
    type: actionTypes.FETCHING_INVITE_FAILURE,
    errors,
  };
}

// Redeem
export const redeemInvite = (inviteToken, givenName, username, password) => {
  return (dispatch) => {
    dispatch(signingIn());
    const body = {
      token: inviteToken,
      password: password,
      username: username,
      timezone: moment.tz.guess(),
    };
    if (givenName) {
      body.given_name = givenName;
    }

    function onFailure(errors) {
      dispatch(signupFailure(errors));
    }

    function onSuccess(payload) {
      dispatch(startSession(payload));
      dispatch(signupSuccess());
    }
    return request('invites/redeem', POST, body, onSuccess, onFailure);
  };
};

function signingIn() {
  return {
    type: actionTypes.REDEEMING_INVITE,
  };
}

function signupSuccess() {
  return {
    type: actionTypes.REDEEMING_INVITE_SUCCESS,
  };
}

function signupFailure(errors) {
  return {
    type: actionTypes.REDEEMING_INVITE_FAILED,
    errors,
  };
}
