import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import walkIns from '../modules/walkIns'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    loading: walkIns.selectors.getLoading(state),
    data: walkIns.selectors.getData(state),
    errors: walkIns.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    calculatingOption: filters.selectors.getCalculatingOption(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: walkIns.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(walkIns.components.WalkInsView)
