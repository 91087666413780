import { isPresent, isInteger } from "utils/validation";

export const validator = {
  name: [
    {
      rule: isPresent,
      errorMessage: "The trial name needs a name",
    },
  ],
  dayLength: [
    {
      rule: isInteger,
      errorMessage: "Day length should be a round number.",
    },
    {
      rule: isPresent,
      errorMessage:
        "We need to know how long the trial goes for (enter 0 for ongoing)",
    },
  ],
};
