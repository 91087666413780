import * as actionTypes from './actionTypes'
import { request, GET } from 'utils/apiUtils'

// GET classLocationIds

export const getClassLocations = () => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setClassLocationIds(payload.location_ids))
    }

    let url = 'third_party_integrations/class_location_ids'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

// GET CLASSES
export const getClassesForIntegration = (integrationId, startDateTime, endDateTime, ignoreCache) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    dispatch(setErrors(null))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setCached(payload.cached))
      dispatch(setClasses(payload.classes))
    }

    let url = 'third_party_integrations/' + integrationId +
     '/classes?start_date_time=' + startDateTime + '&end_date_time=' + endDateTime

    if (ignoreCache === true) {
      url = url + '&ignore_cache=true'
    }

    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function setClasses (classes) {
  return {
    type: actionTypes.SET_CLASSES,
    classes
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setCached (cached) {
  return {
    type: actionTypes.SET_CACHED,
    cached
  }
}

function setClassLocationIds (classLocationIds) {
  return {
    type: actionTypes.SET_CLASS_LOCATION_IDS,
    classLocationIds
  }
}
