import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SelectTrialTableRow } from './SelectTrialTableRow'

export class SelectTrialTableView extends Component {

  render () {
    const { trials, onTrialSelect, selectedTrialId, trialStartsAt } = this.props
    const rows = trials.map((trial, index) => {
      const isSelected = (trial.id === selectedTrialId)
      return <SelectTrialTableRow
        key={index}
        trial={trial}
        isSelected={isSelected}
        onTrialSelect={onTrialSelect}
        trialStartsAt={trialStartsAt} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th>Name</th>
              <th className='text-center'>Days</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SelectTrialTableView.propTypes = {
  trials: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTrialSelect: PropTypes.func.isRequired,
  selectedTrialId: PropTypes.number
}

export default SelectTrialTableView
