import { apiClient } from "utils/apiClient";

export async function fetchSubscriptions(locationId, integrationId) {
  const url = `locations/${locationId}/third_party_integrations/${integrationId}/calendly_webhook_subscriptions`
  try {
    const response = await apiClient().get(url);
    return response.data;
  } catch (e) {
    const response = e.response;
    const error = createCalendlyError(response.data.errors)
    throw error
  }
}

export async function createSubscription(locationId, integrationId, name, apiKey) {
  const url = `locations/${locationId}/third_party_integrations/${integrationId}/calendly_webhook_subscriptions`
  const body = {
    calendly_webhook_subscription: {
      name: name,
      api_key: apiKey
    }
  }
  try {
    const response = await apiClient().post(url, body);
    return response.data;
  } catch (e) {
    const response = e.response;
    const error = createCalendlyError(response.data.errors)
    throw error
  }
}

export async function updateSubscription(locationId, integrationId, subscriptionId, subscriptionParams) {
  const url = `locations/${locationId}/third_party_integrations/${integrationId}/calendly_webhook_subscriptions/${subscriptionId}`
  const body = { calendly_webhook_subscription: subscriptionParams }
  try {
    const response = await apiClient().put(url, body);
    return response.data;
  } catch (e) {
    const response = e.response;
    const error = createCalendlyError(response.data.errors)
    throw error
  }
}

export async function deleteSubscription(locationId, integrationId, subscriptionId) {
  const url = `locations/${locationId}/third_party_integrations/${integrationId}/calendly_webhook_subscriptions/${subscriptionId}`
  try {
    const response = await apiClient().delete(url);
    return response.data;
  } catch (e) {
    const response = e.response;
    const error = createCalendlyError(response.data.errors)
    throw error
  }
}

function createCalendlyError(error) {
  const calendlyError = new Error('calendlyError');
  calendlyError.message = { status: error.status, type: error.type, message: error.message }
  return calendlyError;
}