import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectCountryTableRow extends Component {
  rowSelected = event => {
    const {
      country,
      countrySelected,
      selectCountry,
      deselectCountry
    } = this.props;
    if (countrySelected) {
      deselectCountry(country);
    } else {
      selectCountry(country);
    }
  };

  render() {
    const { country, countrySelected } = this.props;
    return (
      <tr className="selectable" onClick={() => this.rowSelected(country)}>
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input
              type="checkbox"
              disabled
              checked={countrySelected}
              readOnly
            />
            <span />
          </label>
        </td>
        <td>{country.name}</td>
      </tr>
    );
  }
}

SelectCountryTableRow.propTypes = {
  country: PropTypes.object.isRequired,
  countrySelected: PropTypes.bool.isRequired,
  selectCountry: PropTypes.func.isRequired,
  deselectCountry: PropTypes.func.isRequired
};
