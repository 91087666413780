import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SettingsView } from '../components/SettingsView';

import session from 'modules/Stores/Session';
import userData from 'modules/Stores/UserData';
import { linksForUserRoleFactory } from '../selectors';

function mapStateToProps(state) {
  const linksForUser = linksForUserRoleFactory(session.selectors.getUserRole, userData.selectors.getMembershipPermissions);
  return {
    links: linksForUser(state),
    currentPath: state.routing.location.pathname,
    company: userData.selectors.getCompany(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
