import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import teamMembers from '../teamMembers';

import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

export const updateUserPermissions = (user, formData) => {
  return (dispatch) => {
    const permissionsJSON = seralizeFormData(formData);
    const body = {
      user_permission_rule_set: permissionsJSON,
    };
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      dispatch(teamMembers.actions.updateUserPermissions(user.id, payload.user_permission_rule_set));
      notify.success('Permissions updated');
    }

    return request('users/' + user.id + '/user_permission_rule_sets/' + formData.id, PUT, body, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccessful() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function prefilForm(userPermissions) {
  return {
    type: actionTypes.PREFILL_FORM,
    userPermissions,
  };
}

function seralizeFormData(formData) {
  const {
    allowLeadAssign,
    allowLeadReassign,
    allowOtherCalendar,
    allowOpenComms,
    allowMarketing,
    allowViewOthersLeads,
    viewAllMarketingAssets,
    showSharedInbox,
    allowViewReports,
    allowLeadSettings,
  } = formData.fields;

  let payload = {};
  payload = newPayloadForFormValue(payload, allowLeadAssign, 'allow_lead_assign');
  payload = newPayloadForFormValue(payload, allowLeadReassign, 'allow_lead_reassign');
  payload = newPayloadForFormValue(payload, allowOtherCalendar, 'allow_other_calendar');
  payload = newPayloadForFormValue(payload, allowOpenComms, 'allow_open_comms');
  payload = newPayloadForFormValue(payload, allowMarketing, 'allow_marketing');
  payload = newPayloadForFormValue(payload, allowViewReports, 'allow_view_reports');
  payload = newPayloadForFormValue(payload, allowViewOthersLeads, 'allow_view_others_leads');
  payload = newPayloadForFormValue(payload, showSharedInbox, 'show_shared_inbox');
  payload = newPayloadForFormValue(payload, viewAllMarketingAssets, 'view_all_marketing_assets');
  payload = newPayloadForFormValue(payload, allowLeadSettings, 'allow_lead_settings');

  return payload;
}
