import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import locations from 'modules/Stores/Locations';

import notify from 'modules/Notifications';

export const turnOffOptInForLocation = (location) => {
  return (dispatch) => {
    const body = {
      location: {
        uses_opt_in: false,
      },
    };

    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(locations.actions.editLocation(payload.location));
      notify.success('Opt In Turned Off for ' + location.admin_display_name);
    }

    const url = 'locations/' + location.id;

    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
