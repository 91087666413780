import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
const { TextField } = FormFields;

export class EditRegionModalView extends Component {
  state = {
    name: null,
    postcodes: [],
  };

  componentDidMount = () => {
    this.hydrateState(this.props.region);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.region.id !== this.props.region.id) {
      this.hydrateState(nextProps.region);
    }
  }

  hydrateState = (region) => {
    const postcodes = region.region_postcodes.map((postcode) => postcode.postcode);
    this.setState({ ...this.state, name: region.name, postcodes: postcodes });
  };

  updateRegion = () => {
    const { region, updateRegion, updateRegionInList } = this.props;
    const { name, postcodes } = this.state;
    updateRegion(region.id, name, postcodes, updateRegionInList);
  };

  handleInput = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  updatePostcode = (event) => {
    const textValue = event.target.value;
    const index = parseInt(event.target.id);
    const oldPostcodes = this.state.postcodes;
    const newPostcodes = [...oldPostcodes.slice(0, index), textValue, ...oldPostcodes.slice(index + 1)];
    this.setState({ ...this.state, postcodes: newPostcodes });
  };

  addPostcode = () => {
    const oldPostcodes = this.state.postcodes;
    const newPostcodes = [...oldPostcodes, ''];
    this.setState({ ...this.state, postcodes: newPostcodes });
  };

  deletePostcodeIndex = (index) => {
    const oldPostcodes = this.state.postcodes;
    const newPostcodes = [...oldPostcodes.slice(0, index), ...oldPostcodes.slice(index + 1)];
    this.setState({ ...this.state, postcodes: newPostcodes });
  };

  cleanAndHideModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const { loading, currentModal, modalName, errors } = this.props;
    const { name, postcodes } = this.state;

    const addDisabled = !(name && name.length > 1 && postcodes && postcodes.length > 0);

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    const postcodesDivs = postcodes.map((postcode, index) => {
      return (
        <div className="col-xs-4 push-10" key={index}>
          <div style={{ border: '1px solid #e6e6e6', padding: '10px' }}>
            <input
              type="text"
              className="form-control h4 font-w700"
              style={{ border: 'none', padding: '0px', width: '80%' }}
              id={index}
              placeholder="postcode"
              value={postcode}
              onChange={this.updatePostcode}
            />
            <span className="pull-right selectable" style={{ marginTop: '-30px' }} onClick={() => this.deletePostcodeIndex(index)}>
              <i className="fa fa-times fa-2x" />
            </span>
          </div>
        </div>
      );
    });
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanAndHideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanAndHideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Create region
              </h3>
            </div>
            <div className="block-content">
              <div className="row push">
                {errorPanelForErrors(errors)}
                <div className="form-material">
                  <TextField
                    name="name"
                    label="Region name"
                    placeholder=""
                    value={this.state.name}
                    formFieldStyle=""
                    onChange={this.handleInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <p style={{ fontSize: '13px', fontWeight: '600' }}>Region Postcodes</p>
                </div>
              </div>
              <div className="row">
                {postcodesDivs}
                <div className="col-xs-4">
                  <div
                    className="selectable"
                    style={{
                      color: '#999',
                      border: '1px dashed #e6e6e6',
                      padding: '10px',
                      paddingTop: '15px',
                      paddingBottom: '15px',
                    }}
                    onClick={this.addPostcode}
                  >
                    <span className="h4 font-w700">Add</span>
                    <span className="pull-right" style={{ marginTop: '-2px' }}>
                      <i className="fa fa-plus fa-2x" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanAndHideModal}>
              Cancel
            </button>
            <button className="btn btn-primary push-5-r push-10" type="button" disabled={addDisabled} onClick={this.updateRegion}>
              <i className="si si-plus" /> Update region
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

EditRegionModalView.propTypes = {
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  region: PropTypes.object.isRequired,
  updateRegion: PropTypes.func.isRequired,
  updateRegionInList: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default EditRegionModalView;
