import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserSelectView from '../components/UserSelectView';

import modal from 'modules/Stores/Modal';

import * as actions from '../actions';
import * as selectors from '../selectors';

function mapStateToProps(state, props) {
  return {
    users: selectors.getUsers(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    locationId: props.locationId,
    selectedUserId: props.selectedUserId,
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getUsers: actions.getUsersForLocationId,
      hideModal: modal.actions.hideModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectView);
