import React, { Component } from 'react'

import LeadGoalsContainer from '../containers/LeadGoalsContainer'
import HeardAboutMethodsContainer from '../containers/HeardAboutMethodsContainer'
import LeadLostReasonsContainer from '../containers/LeadLostReasonsContainer'
import LeadSourcesContainer from '../containers/LeadSourcesContainer'

export class LeadSettingsView extends Component {

  render () {
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <LeadGoalsContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <HeardAboutMethodsContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <LeadLostReasonsContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <LeadSourcesContainer />
          </div>
        </div>
      </div>
    )
  }
}

LeadSettingsView.propTypes = {

}

export default LeadSettingsView
