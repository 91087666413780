import * as calendlyApi from 'modules/api/calendlyWebhookSubscription';
import * as actionTypes from './actionTypes';
import * as listingActions from '../../../listing/actions';
import notify from 'modules/Notifications';
import { request, POST, DELETE } from 'utils/apiUtils';
import { trackEvent } from 'modules/Helpers/Analytics';
import modal from 'modules/Stores/Modal';

export function fetchSubscriptions(locationId, integrationId) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await calendlyApi.fetchSubscriptions(locationId, integrationId);
      if (response) {
        dispatch(setSubscriptions(response.calendly_webhook_subscriptions));
        dispatch(setLoading(false));
        return true;
      }
    } catch (e) {
      dispatch(setErrors(e.message));
      dispatch(setLoading(false));
      return false;
    }
  };
}

export function createSubscription(locationId, integrationId, name, apiKey) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await calendlyApi.createSubscription(locationId, integrationId, name, apiKey);
      if (response) {
        dispatch(setErrors(null));
        dispatch(setNewSubscription(response.calendly_webhook_subscription));
        dispatch(setSelectedSubscription(response.calendly_webhook_subscription));
        notify.success('Created Calendly subscription');
        dispatch(setLoading(false));
        return true;
      }
    } catch (e) {
      dispatch(setErrors(e.message));
      dispatch(setLoading(false));
      return false;
    }
  };
}

export function updateSubscription(locationId, integrationId, subscriptionId, subscriptionParams) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await calendlyApi.updateSubscription(locationId, integrationId, subscriptionId, subscriptionParams);
      if (response) {
        dispatch(setUpdatedSubscription(response.calendly_webhook_subscription));
        dispatch(setSelectedSubscription(response.calendly_webhook_subscription));
        notify.success('Updated Calendly subscription');
        dispatch(setLoading(false));
        return true;
      }
    } catch (e) {
      dispatch(setErrors(e.message));
      dispatch(setLoading(false));
      return false;
    }
  };
}

export function deleteSubscription(locationId, integrationId, subscriptionId) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await calendlyApi.deleteSubscription(locationId, integrationId, subscriptionId);
      if (response) {
        dispatch(removeSubscription(subscriptionId));
        dispatch(setSelectedSubscription(null));
        notify.success('Deleted Calendly subscription');
        dispatch(setLoading(false));
        return true;
      }
    } catch (e) {
      dispatch(setErrors(e.message));
      dispatch(setLoading(false));
      return false;
    }
  };
}

export function createIntegration(locationId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    const body = {
      third_party_integration: {
        direction: 'incoming',
        name: 'calendly',
      },
    };

    function onFailure(errors) {
      dispatch(setLoading(false));
      dispatch(setErrors(errors.message));
    }
    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success('Created Calendly integration');
      dispatch(listingActions.addIntegration(payload.third_party_integration));
    }

    return request('locations/' + locationId + '/third_party_integrations', POST, body, onSuccess, onFailure);
  };
}

export const deleteIntegration = (locationId, integrationId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(listingActions.deleteIntegration(integrationId));
      dispatch(resetSubscriptionModal());
      notify.success('Integration Removed');
      trackEvent('Integration Removed');
    }

    return request('locations/' + locationId + '/third_party_integrations/' + integrationId, DELETE, null, onSuccess, onFailure);
  };
};

export function setSelectedSubscription(subscription) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_SELECTED_SUBSCRIPTION, subscription });
  };
}

export function resetSubscriptionModal() {
  return (dispatch) => {
    dispatch(setErrors(null));
    dispatch(setSelectedSubscription());
  };
}

export function setSubscriptions(subscriptions) {
  return {
    type: actionTypes.SET_SUBSCRIPTIONS,
    subscriptions,
  };
}

function setNewSubscription(calendly_webhook_subscription) {
  return {
    type: actionTypes.SET_NEW_SUBSCRIPTION,
    calendly_webhook_subscription,
  };
}

function setUpdatedSubscription(subscription) {
  return {
    type: actionTypes.SET_UPDATED_SUBSCRIPTION,
    subscription,
  };
}

function removeSubscription(subscriptionId) {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION,
    subscriptionId,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
