import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Modal from 'react-bootstrap-modal'
import { FormFields } from 'modules/components'

const { TextField } = FormFields

export class NewDealModal extends Component {
  state = {
    name: null
  }

  handleChange = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value })
  }

  createDealSelected = () => {
    const { createDeal } = this.props
    const { name } = this.state
    createDeal(name, this.onSubmitSuccess)
  }

  onSubmitSuccess = () => {
    this.setState({ name: null })
    this.props.hideModal()
  }

  formIsValid = () => {
    const { name } = this.state
    if (!name || name.length < 1) { return false }
    return true
  }

  render () {
    const { currentModal, hideModal, modalName, loading } = this.props
    const { callName } = this.state
    const blockStyle = loading ? 'block block-opt-refresh' : 'block'

    return (
      <div>
        <Modal
          show={currentModal === modalName}
          onHide={hideModal} >
          <div className={blockStyle}>
            <div className='block-header bg-success'>
              <ul className='block-options'>
                <li>
                  <button data-dismiss='modal' type='button' onClick={hideModal} >
                    <i className='si si-close' style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className='block-title' style={{ color: 'white' }}>Let's create a deal</h3>
            </div>
            <div className='block-content'>
              <div className='push-30'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <TextField
                      name='name'
                      label='Deal title *'
                      placeholder='Standard Membership'
                      formFieldStyle=''
                      onChange={this.handleChange}
                      value={callName} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className='btn btn-default pull-left' onClick={hideModal}>
              Cancel
            </button>
            <button className='btn btn-success push-5-r'
              type='button'
              disabled={!this.formIsValid()}
              onClick={this.createDealSelected}>
              <i className='fa fa-plus' />{' '}
              Create deal
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

NewDealModal.propTypes = {
  createDeal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  currentModal: PropTypes.string
}

export default NewDealModal
