import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PersonalTaskModalView from "../components/PersonalTaskModalView";

import modal from "modules/Stores/Modal";

import { selectedEvent } from "../selectors";
import * as actions from "../actions";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    currentModal: state.modal.currentModal,
    todo: selectedEvent(state),
    loading: modal.selectors.getModalLoading(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      deleteEvent: actions.deleteEvent,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalTaskModalView);
