import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import { FormFields } from 'modules/components'
import { validatorForOption } from '../../validators'

const { TextField } = FormFields

export class FormView extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  render () {
    const { customUrl, apiKey } = this.props.data.fields
    const validator = validatorForOption('gymaster')
    return (
      <div>
        <div className='row push'>
          <div className='col-md-12'>
            <h2 className='h4 push-10'>Integrate GymMaster</h2>
            <p>Connect {this.props.productName} with your GymMaster account. Start by entering your GymMaster domain and API key.</p>
            <ul className='text-gray-dark'>
              <li>You will need to request your API key from GymMaster support.{' '}
              Make sure you have this before setting up the integration.</li>
              <li>Export your closed leads to the GymMaster member management{' '}
                system. You can get an API token from from GymMaster support. Your{' '}
                 GymMaster URL will be something like https://yourgymname.gymmasteronline.com/</li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <TextField
              name='customUrl'
              label='Your GymMaster URL'
              placeholder='https://yourgymname.gymmasteronline.com'
              formFieldStyle=''
              onChange={this.handleInput}
              validator={validator}
              value={customUrl.value} />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <TextField name='apiKey'
              label='Your API Key'
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={apiKey.value} />
          </div>
        </div>
      </div>
    )
  }
}

FormView.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default FormView
