import { apiClient } from 'utils/apiClient';
import { IPaginationMeta, ISubscriptionAddOn, SubscriptionAddOnTemplate } from 'types';

type GetSubscriptionAddOnsResponse = {
  subscription_add_ons: ISubscriptionAddOn[];
  meta: IPaginationMeta;
};

export const getSubscriptionAddOns = async (template: SubscriptionAddOnTemplate, pageNumber = 1) => {
  let url = `subscription_add_ons?page[number]=${pageNumber}`;
  if (template) {
    url = `${url}&template=${template}`;
  }
  const response = await apiClient().get(url);
  return response.data as GetSubscriptionAddOnsResponse;
};

type CreateSubscriptionForLocationIdResponse = {
  subscription_add_on: ISubscriptionAddOn;
};

export const createSubscriptionForLocationId = async (template: SubscriptionAddOnTemplate, locationId: number) => {
  const url = `subscription_add_ons`;
  const body = {
    subscription_add_on: {
      location_id: locationId,
      template,
    },
  };
  const response = await apiClient().post(url, body);
  return response.data as CreateSubscriptionForLocationIdResponse;
};

export const createSubscriptionForAllLocations = async (template: SubscriptionAddOnTemplate) => {
  const url = `create_subscription_add_ons`;
  const body = {
    subscription_add_on: {
      template,
    },
  };
  const response = await apiClient().post(url, body);
  const data: { success: boolean } = response.data;
  return data.success;
};

export const createSubscriptionForSpecificLocations = async (template: SubscriptionAddOnTemplate, locationIds: number[]) => {
  const url = `create_subscription_add_ons`;
  const body = {
    subscription_add_on: {
      template,
      location_ids: locationIds,
    },
  };
  const response = await apiClient().post(url, body);
  const data: { success: boolean } = response.data;
  return data.success;
};

export const removeSubscriptionForLocationId = async (subscription_add_on_id: number) => {
  const url = `subscription_add_ons/${subscription_add_on_id}`;

  const response = await apiClient().delete(url);
  return response.data;
};
