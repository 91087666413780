import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';
import { NAME } from './constants';

import listing from './modules/listing';
import configuration from './modules/configuration';

const THIRD_PARTY_INTEGRATION_ACTION_HANDELRS = {
  [actionTypes.LOCATION_SELECTED]: (state, action) => {
    return { ...state, selectedLocationId: action.location.id };
  },
};

const initialState = {
  selectedLocationId: null,
};

function reducer(state = initialState, action) {
  const handler = THIRD_PARTY_INTEGRATION_ACTION_HANDELRS[action.type];

  return handler ? handler(state, action) : state;
}

export default combineReducers({
  [NAME]: reducer,
  [listing.constants.NAME]: listing.reducer,
  [configuration.constants.NAME]: configuration.reducer,
});
