import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import session from 'modules/Stores/Session';
import NotificationMenu from '../components/NotificationMenu';
import * as selectors from '../selectors';
import * as actions from '../actions';

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    notifications: selectors.getNotifications(state),
    currentPage: selectors.getCurrentPage(state),
    pageCount: selectors.getPageCount(state),
    timezone: session.selectors.getUserTimezone(state),
    notificationCount: selectors.getNotificationCount(state),
    user: session.selectors.getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNotificationsForPage: actions.getNotificationsForPage,
      addNotificationsForPage: actions.addNotificationsForPage,
      getNotificationCount: actions.getNotificationCount,
      setAllNotificationsRead: actions.setAllNotificationsRead,
      setNotificationAsRead: actions.setNotificationAsRead,
      subscribeToChannelsForUser: actions.subscribeToChannelsForUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);
