import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectLocationForm from '../components/SelectLocationForm'
import modal from 'modules/Stores/Modal'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    selectedLocationId: selectors.getLocationId(state),
    selectedLocationName: selectors.getLocationName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal,
    selectLocationId: actions.selectLocationId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationForm)
