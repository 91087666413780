import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userData from 'modules/Stores/UserData'
import locations from 'modules/Stores/Locations'
import modal from 'modules/Stores/Modal'
import schedule from '../modules/schedule'

import teamMembers from '../modules/teamMembers'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.selectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)

  const user = teamMembers.selectors.selectedUser(state)

  const userId = user.id
  const getSchedule = userData.selectors.scheduleForUserLocationFactory(userId, selectedLocationId)
  return {
    location: selectedLocation,
    user: user,
    schedule: getSchedule(state),
    loading: schedule.selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSchedule: schedule.actions.updateSchedule,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(schedule.components.ScheduleView)
