import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getLoading = state => state.automaticReports[NAME].loading
export const getErrors = state => state.automaticReports[NAME].errors
export const getPageDetails = state => state.automaticReports[NAME].pageDetails
export const getPageCount = state => state.automaticReports[NAME].pageDetails.pageCount
export const getCurrentPage = state => state.automaticReports[NAME].pageDetails.currentPage
export const getAutomaticReports = state => state.automaticReports[NAME].automaticReports
export const getSelectedAutomaticReportId = state => state.automaticReports[NAME].selectedAutomaticReportId

export const getSelectedAutomaticReport = createSelector(
  [ getSelectedAutomaticReportId, getAutomaticReports ],
  (selectedId, automaticReports) => {
    if (selectedId === null) { return null }
    return automaticReports.find(report => report.id === selectedId)
  }
)