export interface Space {
  XS: string;
  S: string;
  M: string;
  L: string;
  XL: string;
  XXL: string;
}

export const spaceArray = ['0.125rem', '0.25rem', '0.5rem', '0.9rem', '2rem', '4rem'];

export const space: Space = {
  XS: spaceArray[1],
  S: spaceArray[2],
  M: spaceArray[3],
  L: spaceArray[4],
  XL: spaceArray[5],
  XXL: spaceArray[6],
};
