import { request, buildIndexUrl, GET, DELETE, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

import { formDataFromData } from 'utils/formHelpers';

export const getDeals = (pageNumber, searchTerm = null) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setDeals(payload.deals));
      dispatch(setPageDetails(payload.meta));
    }

    const url = buildIndexUrl('deals', pageNumber, null, searchTerm);
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const deleteDeal = (dealId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(deleteDealWithId(dealId));
      notify.success('Deal deleted');
    }

    const deleteString = 'deals/' + dealId;
    return request(deleteString, DELETE, null, onSuccess, onFailure);
  };
};

export const createDeal = (title, afterSuccess) => {
  return (dispatch) => {
    const body = {
      deal: {
        title: title,
      },
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(saveDeal(payload.deal));
      if (afterSuccess) {
        afterSuccess();
      }
      notify.success('Deal Created');
    }

    return request('deals/', POST, body, onSuccess, onFailure);
  };
};

const fieldNames = ['title'];
export const updateDeal = (deal, formData) => {
  return (dispatch) => {
    const body = {
      deal: formDataFromData(formData, fieldNames),
    };

    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(saveDeal(payload.deal));
      notify.info('Deal Updated');
    }
    const url = 'deals/' + deal.id;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setDeals(deals) {
  return {
    type: actionTypes.SET_DEALS,
    deals,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function saveDeal(deal) {
  return {
    type: actionTypes.DEAL_SAVED,
    deal,
  };
}

export function deleteDealWithId(dealId) {
  return {
    type: actionTypes.DELETE_DEAL_WITH_ID,
    dealId,
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
