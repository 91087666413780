import PropTypes from 'prop-types'
import React, { Component } from 'react'

import moment from 'moment'
import 'moment-timezone'

export class InvoiceRow extends Component {

  rowSelected = () => {
    const { invoice, onSelect } = this.props
    onSelect(invoice)
  }

  render () {
    const { invoice, timezone } = this.props
    return (
      <tr className='selectable' onClick={this.rowSelected}>
        <td className='font-w600'>{invoice.invoice_number }</td>
        <td className='font-w600'>
          { moment(invoice.created_at).tz(timezone).format('MMMM Do YYYY') }
        </td>
        <td >${(invoice.subtotal).toFixed(2) }</td>
        <td >${(invoice.tax_subtotal).toFixed(2) }</td>
        <td >${(invoice.total).toFixed(2) }</td>
      </tr>
    )
  }
}

InvoiceRow.propTypes = {
  invoice: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
}
