import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Reports from 'modules/components/Reports'

import TableContainer from './TableContainer'
import FilterFormContainer from './FilterFormContainer'
import ChartContainer from './ChartContainer'
import QuickInfoBlocksContainer from './QuickInfoBlocksContainer'

import { fetchReportWithQueryString, sendEmail } from '../actions'

import FilterFormSections from 'modules/components/FilterFormSections'
import { getFilterData, getCSVDownloadLink } from '../selectors'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(getFilterData)
  const headerContainer = <Reports.components.ReportHeaderView
    title={'Lead Allocation'}
    emailReportClicked={sendEmail}
    csvDownloadLink={getCSVDownloadLink(state)} />

  return {
    queryString: queryStringSelector(state),
    tableContainer: <TableContainer />,
    chartContainer: <ChartContainer />,
    filterFormContainer: <FilterFormContainer />,
    quickInfoBlockContainer: <QuickInfoBlocksContainer />,
    headerContainer: headerContainer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchReportWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports.components.ReportView)
