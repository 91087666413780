import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectUserTableView from './TableView/SelectUserTableView';

import { ContentBlockView, EmptyView, PagesView, TextSearchView } from 'modules/components';

export class UserSelectView extends Component {
  UNSAFE_componentWillMount() {
    this.getUsersWithPageNumber(1);
  }

  getUsersWithPageNumber = (pageNumber) => {
    const { getUsers, locationId } = this.props;
    getUsers(locationId, pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getUsersWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getUsers, locationId } = this.props;
    getUsers(1, searchTerm, locationId);
  };

  render() {
    const { loading, pageDetails, users, selectedUserId, onUserSelect } = this.props;

    return (
      <ContentBlockView loading={loading} bordered>
        <div className="row" style={{ marginTop: '-20px' }}>
          <TextSearchView sectionName={'userSearch'} onUpdate={this.searchUpdated} />
        </div>
        {users.length > 0 && (
          <div>
            <SelectUserTableView users={users} selectedUserId={selectedUserId} onUserSelect={onUserSelect} />
          </div>
        )}
        {users.length === 0 && (
          <EmptyView colorThemeClass="primary" iconClass="si si-magnifier" title="No results" subtitle="Check the search field" />
        )}
        {pageDetails.pageCount > 1 && (
          <div className="text-center push">
            <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

UserSelectView.propTypes = {
  getUsers: PropTypes.func.isRequired,
  locationId: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  selectedUserId: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func.isRequired,
};

export default UserSelectView;
