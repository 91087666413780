import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";
import * as selectors from "../selectors";
import * as actions from "../actions";

import FiltersView from "../components/FiltersView";

function mapStateToProps(state) {
  return {
    showingMore: selectors.getShowingMore(state),
    data: selectors.getData(state),
    compareOption: selectors.getCompareOption(state),
    activeExtraFiltersCount: selectors.activeExtraFiltersCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFilters: actions.updateFilters,
      showFilters: actions.showFilters,
      showModal: modal.actions.showModal,
      setCompareOption: actions.setCompareOption,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersView);
