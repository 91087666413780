import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ManageLeadsView from '../components/ManageLeadsView';
import leadsList from '../modules/leadsList';
import filters from '../modules/filters';
import session from 'modules/Stores/Session';

const mapStateToProps = (state) => {
  const _queryParams = queryParams(state);
  return {
    showingLead: leadsList.selectors.selectedLead(state) !== null && leadsList.selectors.selectedLead(state) !== undefined,
    leadIdToShow: _queryParams.lead_id,
    quickAction: _queryParams.quick_action,
    locationIdToShow: parseInt(_queryParams.locationId),
    user: session.selectors.getUser(state),
    hasPrefilledUser: filters.selectors.getHasPrefilledUser(state),
    data: filters.selectors.getData(state),
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadsWithQueryAndPageNumber: leadsList.actions.getLeadsWithQueryAndPageNumber,
      selectLead: leadsList.actions.select,
      showingQuickLeadView: leadsList.actions.showingQuickLeadView,
      updateFilters: filters.actions.updateFilters,
      presetUserInFilters: filters.actions.presetUserInFilters,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLeadsView);

var queryParams = (state) =>
  state.routing.location.search
    .substr(1)
    .split('&')
    .reduce(function (q, query) {
      var chunks = query.split('=');
      var key = chunks[0];
      var value = chunks[1];
      // eslint-disable-next-line
      return (q[key] = value), q;
    }, {});
