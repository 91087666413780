import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserSelectModal from '../components/UserSelectModal';

import modal from 'modules/Stores/Modal';
import * as selectors from '../selectors';

function mapStateToProps(state, props) {
  return {
    loading: selectors.getLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    locationId: props.locationId,
    selectedUserId: props.selectedUserId,
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectModal);
