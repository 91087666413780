import * as actionTypes from './actionTypes';
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers';
import { fieldNames } from './constants';
import { validator } from './validator';

const CUSTOM_MAIL_MERGE_TAG_ACTION_HANDLERS = {
  [actionTypes.PREFIL_DATA_FOR_CUSTOM_MAIL_MERGE_TAG]: (state, action) => {
    return initialStateForCustomMailMergeTag(action.customMailMergeTag);
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data };
  },
  [actionTypes.SET_LOADING]: (state, actions) => {
    return { ...state, loading: actions.loading };
  },
  [actionTypes.SET_ERRORS]: (state, actions) => {
    return { ...state, errors: actions.errors };
  },
  [actionTypes.SET_SELECTED_CUSTOM_MAIL_MERGE_TAG_ID]: (state, action) => {
    return { ...state, selectedCustomMailMergeTagId: action.selectedCustomMailMergeTagId };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialStateForCustomMailMergeTag(null);
  },
};

const payloadKeys = {
  label: 'label',
  value: 'value',
};

function initialStateForCustomMailMergeTag(customMailMergeTag) {
  const fields = generateFieldsForKeys(customMailMergeTag, fieldNames, payloadKeys, validator);
  const customMailMergeTagId = customMailMergeTag ? customMailMergeTag.id : null;
  return {
    loading: false,
    errors: null,
    selectedCustomMailMergeTagId: customMailMergeTagId,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields),
    },
  };
}

export default function reducer(state = initialStateForCustomMailMergeTag(null), action) {
  const handler = CUSTOM_MAIL_MERGE_TAG_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
