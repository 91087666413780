import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LocationSelect from 'modules/components/LocationSelect';
import EditLocationContainer from '../containers/EditLocationContainer';
import AutoAssignRuleContainer from '../containers/AutoAssignRuleContainer';
import SalesTargetsContainer from '../containers/SalesTargetsContainer';
import NewClubModalContainer from '../containers/NewClubModalContainer';
import ActionAutomationRulesContainer from '../containers/ActionAutomationRulesContainer';
import OptInContainer from '../containers/OptInContainer';
import OpeningHoursContainer from '../modules/openingHours/containers/OpeningHoursContainer';
import LocationMergeOverrideTags from '../modules/locationMergeOverrideTags';
import { InfoView } from 'modules/components';

import { NEW_CLUB_MODAL_NAME } from '../modules/createLocation/constants';

export class LocationsView extends Component {
  showNewClubModal = () => {
    this.props.showModal(NEW_CLUB_MODAL_NAME);
  };

  render() {
    const { currentLocation, canCreateNewLocations, selectLocation, selectedLocationId } = this.props;
    const infoString = 'Manage your location contact details and set specific sales targets for your individual clubs.';
    return (
      <div>
        <InfoView colorThemeClass={'info'} title={'Locations'} detail={infoString} />
        {canCreateNewLocations && (
          <div className="row push">
            <div className="col-xs-6 col-xs-offset-6">
              <button className="btn btn-primary pull-right" type="button" onClick={this.showNewClubModal}>
                <i className="fa fa-plus" /> New Location
              </button>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <LocationSelect.LocationSelectContainer selectedLocationId={selectedLocationId} onLocationSelect={selectLocation} />
          </div>
        </div>
        {currentLocation && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <EditLocationContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AutoAssignRuleContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <SalesTargetsContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <OptInContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <OpeningHoursContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ActionAutomationRulesContainer />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <LocationMergeOverrideTags location={currentLocation} />
              </div>
            </div>
          </div>
        )}
        <NewClubModalContainer />
      </div>
    );
  }
}

LocationsView.propTypes = {
  canCreateNewLocations: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
  selectLocation: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
};

export default LocationsView;
