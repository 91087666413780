import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SecureView } from '../components/SecureView';

import userData from 'modules/Stores/UserData';
import billing from 'modules/Stores/Billing';
import session from 'modules/Stores/Session';
import modal from 'modules/Stores/Modal';
import tags from 'modules/components/Tags';
import securityPolicy from 'modules/Stores/SecurityPolicy';
import { goToSignup } from '../actions';

function mapStateToProps(state) {
  const policy = securityPolicy.selectors.getSecurityPolicy(state);
  const timeout = policy ? policy.lock_screen_after : 0;
  return {
    user: session.selectors.getUser(state),
    company: userData.selectors.getCompany(state),
    intercomHash: session.selectors.getIntercomHash(state),
    loadingComplete: userData.selectors.loadingComplete(state),
    currentModal: modal.selectors.getCurrentModal(state),
    sessionLoading: session.selectors.getLoading(state),
    switchingAppName: session.selectors.getSwitchingAppName(state),
    timeout: timeout,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadInitialStateForCurrentUser: userData.actions.loadInitialStateForCurrentUser,
      loadBillingForCurrentUser: billing.actions.loadBillingDataForUser,
      loadDefaultSmsCost: billing.actions.getDefaultSmsCost,
      getTagOptions: tags.actions.getTagOptions,
      clearAttemptedUrl: session.actions.clearAttemptedUrl,
      showModal: modal.actions.showModal,
      logout: session.actions.logout,
      goToSignup: goToSignup,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SecureView);
