import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import FiltersForm from '../components/FiltersForm'
import { leadAllocationFilters } from '../constants'

function mapStateToProps (state) {
  return {
    availableFilters: leadAllocationFilters,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersForm)