import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EditArticleFormValidator } from '../validators'
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers'

import { FormFields } from 'modules/components'
const { TextField, PasswordField } = FormFields

export class EditArticleForm extends Component {
  state = {
    isValid: false,
    fields: {
      email: {
        value: '',
        isValid: false
      },
      oldPassword: {
        value: '',
        isValid: false
      },
      newPassword: {
        value: false,
        isValid: false
      },
      newPasswordConfirmation: {
        value: '',
        isValid: false
      },
      totp: {
        value: '',
        isValid: false
      }
    }
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { fields } = this.state
    const email = fields.email.value
    const oldPassword = fields.oldPassword.value
    const newPassword = fields.newPassword.value
    const newPasswordConfirmation = fields.newPasswordConfirmation.value
    const totp = fields.totp.value
    if (newPassword !== newPasswordConfirmation) { return }
    this.props.onSubmit(email, oldPassword, newPassword, newPasswordConfirmation, totp)
  }

  handleInput = (name, value, isValid) => {
    this.setState(generateNewFormState(this.state, name, value, isValid))
  }

  render () {
    const { loading, errors } = this.props
    const { isValid } = this.state
    const buttonTitle = loading ? 'Updating' : 'Update'
    const loginSymbol = loading ? 'fa fa-circle-o-notch fa-spin pull-right' : 'si si-login pull-right'
    const buttonEnabled = isValid && !loading

    const renderButton = () =>
      <div className='form-group'>
        <div className='col-xs-12 col-sm-6 col-sm-offset-3'>
          <button type='submit'
            className='btn btn-block btn-primary'
            disabled={!buttonEnabled}
            onClick={this.handleSubmit}>
            <i className={loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>
    return (
      <div>
        {errorPanelForErrors(errors)}
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}>
          <TextField name='email'
            label='Email'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={EditArticleFormValidator}
            value={this.state.fields.email.value} />
          <PasswordField name='oldPassword'
            label='Old Password'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={EditArticleFormValidator} />
          <PasswordField name='newPassword'
            label='New Password'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={EditArticleFormValidator} />
          <PasswordField name='newPasswordConfirmation'
            label='Confirm New Password'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={EditArticleFormValidator} />
          <TextField name='totp'
            label='TOTP'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={EditArticleFormValidator} />
          {renderButton()}
        </form>
      </div>
    )
  }
}

EditArticleForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default EditArticleForm
