import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

export class ColorPicker extends Component {
  state = {
    isShowing: false,
    value: null,
  };

  handleChange = (color) => {
    const { name, onChange } = this.props;
    const hexValue = color.hex;
    this.setState({ ...this.state, value: hexValue });
    onChange(name, hexValue, true);
  };

  togglePicker = () => {
    const { isShowing } = this.state;
    this.setState({ ...this.state, isShowing: !isShowing });
  };

  render() {
    const color = this.props.value || this.state.value;
    const nonNullColor = color || '#fff';
    const { name, label, useBootStrapLayout = true } = this.props;
    const layoutClassName = useBootStrapLayout ? 'col-xs-12 push-10-t' : undefined;
    return (
      <div className={layoutClassName}>
        <label className="colorLabel" htmlFor={name}>
          {label}
        </label>
        <span className="colorSquare selectable" onClick={this.togglePicker} style={{ backgroundColor: nonNullColor }} />
        {this.state.isShowing && (
          <div>
            <SketchPicker color={nonNullColor} onChangeComplete={this.handleChange} />
            <button type="button" className="btn btn-primary" onClick={this.togglePicker}>
              {' '}
              Done
            </button>
          </div>
        )}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  useBootStrapLayout: PropTypes.bool,
};

export default ColorPicker;
