/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { GENDER_MALE, GENDER_FEMALE, GENDER_OTHER } from '../../../constants';

export class SelectGenderModal extends Component {
  state = {
    genders: [
      {
        name: GENDER_FEMALE,
        icon: 'si si-symbol-female',
      },
      {
        name: GENDER_MALE,
        icon: 'si si-symbol-male',
      },
      {
        name: GENDER_OTHER,
        icon: 'fa fa-transgender',
      },
    ],
  };

  indexClicked = (index) => {
    const { lead, updateLead, listUpdateProtocol } = this.props;
    const selectedGender = this.state.genders[index];
    const gender = { gender: selectedGender.name };
    updateLead(lead, gender, listUpdateProtocol.updateLeadDetails);
  };

  render() {
    const { lead, loading, currentModal, hideModal, modalName } = this.props;
    const genders = this.state.genders.map((gender, index) => {
      const bgStyle = gender.name === lead.gender ? 'bg-primary-light' : 'bg-primary';
      return (
        <div key={index} className="col-xs-6 col-sm-4">
          <a className="block block-bordered block-link-hover1 text-center selectable" onClick={() => this.indexClicked(index)}>
            <div className={'block-content block-content-full ' + bgStyle}>
              <i className={gender.icon + ' fa-5x text-white'} />
            </div>
            <div className="block-content block-content-full block-content-mini">{gender.name}</div>
          </a>
        </div>
      );
    });

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                What is {lead.given_name}'s gender?
              </h3>
            </div>
            <div className="block-content">
              <div className="row">{genders}</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SelectGenderModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default SelectGenderModal;
