import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { StaffTableRow } from './StaffTableRow'

export class MindbodyStaffTableView extends Component {

  render () {
    const { users, selectedUserIds, selectUserId, deselectUserId } = this.props
    const rows = users.map((user, index) => {
      const selectedIndex = selectedUserIds.findIndex(selectedUserId => user.id === selectedUserId)
      const selected = (selectedIndex >= 0)
      return <StaffTableRow
        key={index}
        user={user}
        selected={selected}
        selectUserId={selectUserId}
        deselectUserId={deselectUserId} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th />
              <th />
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

MindbodyStaffTableView.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectUserId: PropTypes.func.isRequired,
  deselectUserId: PropTypes.func.isRequired
}
