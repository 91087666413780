import PropTypes from 'prop-types'
import React, { Component } from 'react'
import UserAvatar from 'modules/components/Avatars/UserAvatar'

export class SelectUserTableRow extends Component {

  rowSelected = (event) => {
    const { user, userSelected, selectUser, deselectUser } = this.props
    if (userSelected) {
      deselectUser(user)
    } else {
      selectUser(user)
    }
  }

  render () {
    const { user, userSelected } = this.props
    const imageStyle = {
      width: '40px',
      height: '40px',
      borderRadius: '20px'
    }
    return (
      <tr className='selectable' onClick={() => this.rowSelected(user)}>
        <td className='text-center'>
          <label className='css-input css-checkbox css-checkbox-primary'>
            <input type='checkbox' disabled checked={userSelected} readOnly /><span />
          </label>
        </td>
        <td className='text-center'>
          <UserAvatar avatarImage={user.user_avatar} type={'thumb'} style={imageStyle} />
        </td>
        <td>{user.display_name}</td>
      </tr>
    )
  }
}

SelectUserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  userSelected: PropTypes.bool.isRequired,
  selectUser: PropTypes.func.isRequired,
  deselectUser: PropTypes.func.isRequired
}
