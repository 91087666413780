export const MINUTES = "Minutes";
export const HOURS = "Hours";
export const DAYS = "Days";
export const WEEKS = "Weeks";
export const timeTypes = [MINUTES, HOURS, DAYS, WEEKS];

export function secondsFormattedForTimeType(state) {
  const { timeToWait, selectedTimeType } = state;
  switch (selectedTimeType) {
    case MINUTES:
      return timeToWait * 60;
    case HOURS:
      return timeToWait * 60 * 60;
    case DAYS:
      return timeToWait * 60 * 60 * 24;
    case WEEKS:
      return timeToWait * 60 * 60 * 24 * 7;
    default:
      return timeToWait;
  }
}

export function formIsInvalid(timeToWait) {
  if (!timeToWait || timeToWait < 1) {
    return true;
  }
  return false;
}

export function calculatedTimeTypeSelection(timeToWait) {
  const usePlural = timeToWait > 1;
  return timeTypes.map(timeType => {
    return {
      id: timeType,
      display: usePlural ? timeType : timeType.slice(0, -1)
    };
  });
}

export function secondsToTimeObject(seconds) {
  if (seconds % 604800 === 0) {
    return { timeToWait: seconds / 604800, selectedTimeType: WEEKS };
  } else if (seconds % 86400 === 0) {
    return { timeToWait: seconds / 86400, selectedTimeType: DAYS };
  } else if (seconds % 3600 === 0) {
    return { timeToWait: seconds / 3600, selectedTimeType: HOURS };
  } else if (seconds % 60 === 0) {
    return { timeToWait: seconds / 60, selectedTimeType: MINUTES };
  }
}

export function selectedTemplate(templates, id) {
  if (!id) {
    return null;
  }
  const index = templates.findIndex(
    template => parseInt(template.id) === parseInt(id)
  );
  return templates[index];
}
