import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export const fetchWithQueryString = (queryString, compareOption) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setLastQueryString(queryString));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setData(payload.data));
    }
    let url = 'activity_reports/appointments?comparing=' + compareOption;
    if (queryString) {
      url = url + '&' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const generateFullReport = (queryString, compareOption) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess() {
      dispatch(setLoading(false));
      dispatch(setQueued(true));
      notify.success("Your report is being generated. We'll email it to you when it's ready.");
    }

    let url = 'activity_reports/appointments_full_report?comparing=' + compareOption;
    if (queryString) {
      url = url + '&' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setData(data) {
  return {
    type: actionTypes.SET_DATA,
    data,
  };
}

function setLastQueryString(lastQueryString) {
  return {
    type: actionTypes.SET_LAST_QUERY_STRING,
    lastQueryString,
  };
}

function setQueued(queued) {
  return {
    type: actionTypes.SET_QUEUED,
    queued,
  };
}
