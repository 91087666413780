import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import leadGoalsStore from 'modules/Stores/LeadGoals';
import * as leadGoalApiActions from 'modules/Stores/LeadGoals/apiActions';
import leadGoals from '../modules/leadGoals';
import modal from 'modules/Stores/Modal';
import session from 'modules/Stores/Session';

function mapStateToProps(state) {
  const permissionSet = session.selectors.getUserPermissions(state);
  return {
    leadGoals: leadGoalsStore.selectors.getLeadGoals(state),
    loading: leadGoalsStore.selectors.getLoading(state),
    pageDetails: leadGoalsStore.selectors.getPageDetails(state),
    canEdit: permissionSet.allow_lead_settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadGoals: leadGoalApiActions.getLeadGoals,
      createLeadGoal: leadGoals.actions.createLeadGoal,
      deleteLeadGoal: leadGoals.actions.deleteLeadGoal,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(leadGoals.components.LeadGoalsView);
