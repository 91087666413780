import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
const { SelectField } = FormFields;

export class MigrateTrialModal extends Component {
  state = {
    toId: null,
  };

  handleSelect = (name, value) => {
    this.setState({ ...this.state, [name]: value });
  };

  migrateClicked = () => {
    const { trial, deleteTrial } = this.props;
    const { toId } = this.state;
    deleteTrial(trial.id, toId);
    this.cleanUpAndHide();
  };

  cleanUpAndHide = () => {
    this.setState({ toId: null });
    this.props.hideModal();
  };

  render() {
    const { loading, currentModal, modalName, trial, trials, errors } = this.props;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const canMigrateTrial = trials.length > 1; // A company can not delete their last trial.
    const filteredTrials = trials.filter((t) => t.id !== trial.id);
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanUpAndHide}>
          <div className={blockStyle}>
            <div className="block-header bg-danger">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanUpAndHide}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Migrate Trial
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <div className="row">
                  <div className="col-xs-12">
                    {errorPanelForErrors(errors)}
                    {canMigrateTrial && <p>This will delete the trial {trial.name} and move all leads to the trial.</p>}
                    {!canMigrateTrial && <p>This trial cannot be deleted as it is the last trial there may be leads currently in it.</p>}
                  </div>
                  {canMigrateTrial && (
                    <div className="col-xs-12" style={{ marginLeft: '-20px', marginRight: '20px' }}>
                      <SelectField
                        name="toId"
                        label="Which trial should this migrate to?"
                        possibleOptions={filteredTrials}
                        displayFieldName={'name'}
                        placeHolderText="Select a trial to move to"
                        formFieldStyle="form-material form-material-danger"
                        onChange={this.handleSelect}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndHide}>
              Cancel
            </button>
            <button
              className="btn btn-danger push-5-r push-10"
              type="button"
              disabled={this.state.toId === null}
              onClick={this.migrateClicked}
            >
              <i className="fa fa-cross" /> Migrate & Delete Trial
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

MigrateTrialModal.propTypes = {
  trial: PropTypes.object.isRequired,
  trials: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  deleteTrial: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default MigrateTrialModal;
