import React from "react";
import styled from "styled-components";
import moment from "moment";
import { RichToolTip } from 'modules/components'
import * as tooltips from '../tooltips'
require("moment-duration-format");

function calculateTime(seconds) {
  const time =
    seconds > 0
      ? moment.duration(seconds, "seconds").format("d [days], h [hrs], m [min]")
      : "N/A";
  return time;
}

const Table = ({ data }) => {
  const headerKeys = Object.keys(data[0].result);

  const headerRows = headerKeys.map((headerKey, index) => {
    const label = data[0].result[headerKey].label
    const tooltip = tooltips.averageTimeToStep(label)
    return (
      <th key={`${headerKey}-${index}`}>{label}
      <RichToolTip text={tooltip} toptipId={`tooltip-${index}`} />
      </th>
    );
  });

  const rows = data.map((item, index) => {
    return (
      <tr key={`${item.name.value}-${index}`}>
        <td>{item.name.value}</td>
        {headerKeys.map((headerKey, index) => {
          return (
            <td key={`${headerKey}-${index}`}>
              {calculateTime(item.result[headerKey].value)}
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <Container className="table-responsive">
      <table className="table table-vcenter table-hover">
        <thead>
          <tr>
            <th>Name</th>
            {headerRows}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Container>
  );
};

export default Table;

const Container = styled.div``;
