import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = state => state[NAME].loading
export const getDefaultSmsCost = state => state[NAME].defaultSmsCost
export const getPaymentMethods = state => state[NAME].paymentMethods

export const subscriptionPaymentMethods = createSelector(
  [ getPaymentMethods ],
  (paymentMethods) => {
    return paymentMethods.filter((method) => method.allow_subscription === true)
  }
)

export const smsPaymentMethods = createSelector(
  [ getPaymentMethods ],
  (paymentMethods) => {
    return paymentMethods.filter((method) => method.allow_sms === true)
  }
)

export function subscriptionPaymentMethodForLocationFactory (location) {
  return createSelector(
    [ getPaymentMethods ],
    (paymentMethods) => {
      const index = paymentMethods
      .findIndex(paymentMethod =>
        parseInt(paymentMethod.id) === parseInt(location.subscription_paychoice_payment_method_id))
      return paymentMethods[index]
    }
  )
}

export function smsPaymentMethodForLocationFactory (location) {
  return createSelector(
    [ getPaymentMethods ],
    (paymentMethods) => {
      const index = paymentMethods
      .findIndex(paymentMethod =>
        parseInt(paymentMethod.id) === parseInt(location.sms_paychoice_payment_method_id))
      return paymentMethods[index]
    }
  )
}
