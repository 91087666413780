import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import lawfulProcessingBases from 'modules/Stores/LawfulProcessingBases'
import lawfulProcessingBasisSelect from '../modules/lawfulProcessingBasisSelect'
import editLawfulProcessingBasis from '../modules/editLawfulProcessingBasis'

function mapStateToProps (state) {
  return {
    lawfulProcessingBases: lawfulProcessingBases.selectors.getLawfulProcessingBases(state),
    selectedLawfulProcessingBasisId: lawfulProcessingBasisSelect.selectors.getSelectedLawfulProcessingBasisId(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    selectLawfulProcessingBasisId: lawfulProcessingBasisSelect.actions.selectLawfulProcessingBasisId,
    changeShowing: editLawfulProcessingBasis.actions.changeShowing
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(lawfulProcessingBasisSelect
  .components.ListLawfulProcessingBasesView)
