import * as actionTypes from './actionTypes';

const CONFIGURE_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_USERS]: (state, action) => {
    return { ...state, users: action.users };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState();
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count,
      },
    };
  },
};

function initialState() {
  return {
    loading: false,
    users: [],
    pageDetails: {
      pageCount: 0,
      currentPage: 1,
      total: 0,
    },
  };
}

export default function reducer(state = initialState(), action) {
  const handler = CONFIGURE_LEADS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
