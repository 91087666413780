import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getLoading = (state) => state.emailMapsSettings[NAME].loading;
export const getErrors = (state) => state.emailMapsSettings[NAME].errors;
export const getShowing = (state) => state.emailMapsSettings[NAME].showing;
export const getData = (state) => state.emailMapsSettings[NAME].data;
export const getTags = (state) => state.emailMapsSettings[NAME].tags;

export const getLocationSearchTerm = (state) => state.emailMapsSettings[NAME].locationSearchTerm;
export const getLocationCurrentPage = (state) => state.emailMapsSettings[NAME].locationCurrentPage;

export const getUserSearchTerm = (state) => state.emailMapsSettings[NAME].userSearchTerm;
export const getUserCurrentPage = (state) => state.emailMapsSettings[NAME].userCurrentPage;

// Locations
export function filteredLocations(locations) {
  return createSelector([getLocationSearchTerm], (searchTerm) => {
    if (searchTerm && searchTerm.length > 0) {
      const uppercaseSearchTerm = searchTerm.toUpperCase();
      return locations.filter((location) => {
        return location.admin_display_name.toUpperCase().includes(uppercaseSearchTerm);
      });
    } else {
      return locations;
    }
  });
}

export function pageCountForLocations(locations, resultsPerPage) {
  return createSelector([getLocationCurrentPage], (currentPage) => {
    const pageCount = locations.length / resultsPerPage;
    if (pageCount < 1) {
      return 1;
    }
    return pageCount;
  });
}

export function locationsAtCurrentPage(locations, resultsPerPage) {
  return createSelector([getLocationCurrentPage], (currentPage) => {
    const startIndex = resultsPerPage * (currentPage - 1);
    const endIndex = startIndex + resultsPerPage;

    return locations.slice(startIndex, endIndex);
  });
}

// Users
export function filteredUsers(users) {
  return createSelector([getUserSearchTerm], (searchTerm) => {
    if (searchTerm && searchTerm.length > 0) {
      const uppercaseSearchTerm = searchTerm.toUpperCase();
      return users.filter((location) => {
        return location.admin_display_name.toUpperCase().includes(uppercaseSearchTerm);
      });
    } else {
      return users;
    }
  });
}

export function pageCountForUsers(users, resultsPerPage) {
  return createSelector([getUserCurrentPage], (currentPage) => {
    const pageCount = users.length / resultsPerPage;
    if (pageCount < 1) {
      return 1;
    }
    return pageCount;
  });
}

export function usersAtCurrentPage(users, resultsPerPage) {
  return createSelector([getUserCurrentPage], (currentPage) => {
    const startIndex = resultsPerPage * (currentPage - 1);
    const endIndex = startIndex + resultsPerPage;

    return users.slice(startIndex, endIndex);
  });
}
