import * as actionTypes from "./actionTypes";

const LISTING_ACTION_HANDLERS = {
  [actionTypes.INTEGRATIONS_LIST_LOADED]: (state, action) => {
    return { ...state, options: action.options };
  },
  [actionTypes.CONFIGURED_INTEGRATIONS_LOADED]: (state, action) => {
    return { ...state, configuredIntegrations: action.configuredIntegrations };
  },
  [actionTypes.LOADING]: state => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  },
  [actionTypes.SET_SELECTED_OPTION]: (state, action) => {
    return { ...state, selectedOption: action.selectedOption };
  },
  [actionTypes.UPDATE_INTEGRATION]: (state, action) => {
    const newIntegration = action.integration;
    const oldIntegrations = state.configuredIntegrations;
    const index = indexForIntegration(oldIntegrations, newIntegration.id);
    const newConfiguredIntegrations = [
      ...oldIntegrations.slice(0, index),
      newIntegration,
      ...oldIntegrations.slice(index + 1)
    ];
    return { ...state, configuredIntegrations: newConfiguredIntegrations };
  },
  [actionTypes.ADD_INTEGRATION]: (state, action) => {
    const newIntegration = action.integration;
    const oldIntegrations = state.configuredIntegrations;

    const newIntegrations = [...oldIntegrations, newIntegration];

    return { ...state, configuredIntegrations: newIntegrations };
  },
  [actionTypes.DELETE_INTEGRATION]: (state, action) => {
    const deletedIntegrationID = action.integrationId;
    const oldIntegrations = state.configuredIntegrations;

    const index = indexForIntegration(oldIntegrations, deletedIntegrationID);

    const newConfiguredIntegrations = [
      ...oldIntegrations.slice(0, index),
      ...oldIntegrations.slice(index + 1)
    ];
    return { ...state, configuredIntegrations: newConfiguredIntegrations };
  }
};

function indexForIntegration(integrations, integrationId) {
  return integrations.findIndex(
    integration => parseInt(integration.id) === parseInt(integrationId)
  );
}

const initialState = {
  errors: null,
  loading: false,
  selectedOption: null,
  options: {
    outgoing: [],
    incoming: []
  },
  configuredIntegrations: []
};

export default function reducer(state = initialState, action) {
  const handler = LISTING_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
