import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import Papa from "papaparse";

export const getPotentialUsersForLocationId = (locationId, pageNumber) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setPotentialUsers(payload.users));
    }

    const url =
      "users?location_ids[]=" +
      locationId +
      "&page[number]=" +
      pageNumber +
      "&detail=true";
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setPotentialUsers(potentialUsers) {
  return {
    type: actionTypes.SET_POTENTIAL_USERS,
    potentialUsers,
  };
}

export function findSalespeopleAndStatusStrings(
  csvFile,
  statusKeyIndex,
  salespersonKeyIndex,
  lawfulProcessingBasesKeyIndex
) {
  return (dispatch) => {
    dispatch(setLoading(true));
    let statuses = new Set();
    let salespeople = new Set();
    let lawfulProcessingBases = new Set();
    Papa.parse(csvFile, {
      skipEmptyLines: true,
      complete: function (results) {
        dispatch(setLoading(false));
        for (const row of results.data.slice(1)) {
          if (statusKeyIndex !== null || statusKeyIndex !== undefined) {
            statuses.add(row[statusKeyIndex]);
          }
          if (
            salespersonKeyIndex !== null ||
            salespersonKeyIndex !== undefined
          ) {
            salespeople.add(row[salespersonKeyIndex]);
          }
          if (
            lawfulProcessingBasesKeyIndex !== null ||
            lawfulProcessingBasesKeyIndex !== undefined
          ) {
            lawfulProcessingBases.add(row[lawfulProcessingBasesKeyIndex]);
          }
        }
        if (
          statusKeyIndex !== null ||
          (statusKeyIndex !== undefined && statuses.length > 0)
        ) {
          dispatch(
            setStatuses([...statuses].filter((entry) => entry.trim() !== ""))
          );
        }
        if (
          salespersonKeyIndex !== null ||
          (salespersonKeyIndex !== undefined && salespeople.length > 0)
        ) {
          dispatch(
            setSalespeople(
              [...salespeople].filter((entry) => entry.trim() !== "")
            )
          );
        }
        if (
          lawfulProcessingBasesKeyIndex !== null ||
          (lawfulProcessingBasesKeyIndex !== undefined &&
            lawfulProcessingBases.length > 0)
        ) {
          dispatch(
            setLawfulProcessingBases(
              [...lawfulProcessingBases].filter((entry) => entry.trim() !== "")
            )
          );
        }
      },
    });
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setSalespeople(salespeople) {
  return {
    type: actionTypes.SET_SALESPEOPLE,
    salespeople,
  };
}

function setStatuses(statuses) {
  return {
    type: actionTypes.SET_STATUSES,
    statuses,
  };
}

function setLawfulProcessingBases(lawfulProcessingBases) {
  return {
    type: actionTypes.SET_LAWFUL_PROCESSING_BASES,
    lawfulProcessingBases,
  };
}

export function updateSalespersonMap(salespersonMap) {
  return {
    type: actionTypes.UPDATE_SALESPERSON_MAP,
    salespersonMap,
  };
}

export function updateStatusMap(statusMap) {
  return {
    type: actionTypes.UPDATE_STATUS_MAP,
    statusMap,
  };
}

export function updateLawfulProcessingBasesMap(lawfulProcessingBasesMap) {
  return {
    type: actionTypes.UPDATE_LAWFUL_PROCESSING_BASES_MAP,
    lawfulProcessingBasesMap,
  };
}

export function addSalespersonMap(salespersonMap) {
  return {
    type: actionTypes.ADD_SALESPERSON_MAP,
    salespersonMap,
  };
}

export function addStatusMap(statusMap) {
  return {
    type: actionTypes.ADD_STATUS_MAP,
    statusMap,
  };
}

export function addLawfulProcessingBasesMap(lawfulProcessingBasesMap) {
  return {
    type: actionTypes.ADD_LAWFUL_PROCESSING_BASES_MAP,
    lawfulProcessingBasesMap,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
