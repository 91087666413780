import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import createAutoAssignRule from '../modules/createAutoAssignRule'

function mapStateToProps (state) {
  return {
    loading: createAutoAssignRule.selectors.getLoading(state),
    errors: createAutoAssignRule.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createAutoAssignRuleForLocation: createAutoAssignRule.actions.createAutoAssignRuleForLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(createAutoAssignRule.components.CreateAutoAssignRuleView)
