import * as actionTypes from './actionTypes'

const ACTIVITY_TASKS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_LAST_QUERY_STRING]: (state, action) => {
    return { ...state, lastQueryString: action.lastQueryString }
  },
  [actionTypes.SET_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_DATA]: (state, action) => {
    return { ...state, data: action.data }
  }
}

const initialState = {
  errors: null,
  loading: false,
  lastQueryString: null,
  queued: false,
  data: []
}

export default function reducer (state = initialState, action) {
  const handler = ACTIVITY_TASKS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
