import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class PerfectGymLocationRow extends Component {

  render () {
    const { perfectGymLocation, locationSelected, selected } = this.props

    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''
    return (
      <tr className={textClass + ' selectable ' + backgroundClass}
        onClick={() => locationSelected(perfectGymLocation)}>
        <td>{perfectGymLocation.name}</td>
        <td>{perfectGymLocation.address.city}</td>
        <td>{perfectGymLocation.address.postalCode}</td>
      </tr>
    )
  }
}

PerfectGymLocationRow.propTypes = {
  perfectGymLocation: PropTypes.object.isRequired,
  locationSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
}
