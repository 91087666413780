import PropTypes from "prop-types";
import React, { Component } from "react";

import LawfulProcessingBasisSelectContainer from "../containers/LawfulProcessingBasisSelectContainer";
import EditLawfulProcessingBasisContainer from "../containers/EditLawfulProcessingBasisContainer";
import { InfoView } from "modules/components";

export class LawfulProcessingBasisView extends Component {
  render() {
    const { editLawfulProcessingBasisShowing } = this.props;
    const infoString = (
      <span>
        Lawful Processing Bases allow you to record and keep track of the
        different reasons you are processing a leads data. This is used in
        enhanced privacy mode and is required under several privacy regulations,
        including the GDPR. Visit{" "}
        <strong>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://support.gymleads.net/pricing-privacy-and-terms/marketing-compliance/enhanced-privacy"
          >
            the knowledge base
          </a>
        </strong>{" "}
        For more details.
      </span>
    );
    return (
      <div>
        <InfoView
          colorThemeClass={"info"}
          title={"Lawful Processing Bases"}
          detail={infoString}
        />
        <div className="row">
          <div className="col-md-12">
            <LawfulProcessingBasisSelectContainer />
          </div>
        </div>
        {editLawfulProcessingBasisShowing && (
          <div className="row">
            <div className="col-md-12">
              <EditLawfulProcessingBasisContainer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

LawfulProcessingBasisView.propTypes = {
  editLawfulProcessingBasisShowing: PropTypes.bool.isRequired,
};

export default LawfulProcessingBasisView;
