import { NAME } from './constants'
import { createSelector } from 'reselect'

import session from 'modules/Stores/Session'
import moment from 'moment'
import 'moment-timezone'

export const getReport = state => state.dashboard[NAME].report
export const getLoading = state => state.dashboard[NAME].loading

export const getSortedReportData = createSelector(
  [ getReport, session.selectors.getUserTimezone ],
  (report, timeZone) => {
    let headers = []
    let counts = []
    report.data.forEach((dataRecord) => {
      const serverDate = moment(dataRecord.date)
      const localizedDate = serverDate.tz(timeZone)
      const dayName = localizedDate.format('dddd')

      headers.push(dayName)
      counts.push(dataRecord.value)
    })
    return {
      headers: headers.reverse(),
      counts: counts.reverse()
    }
  }
)

export const getIsEmpty = createSelector(
  [ getReport ],
  (report) => {
    const nonEmptyValues = report.data.map((record) => record.value)
    .filter((v) => v > 0)

    return (nonEmptyValues.length === 0)
  }
)
