import { buildIndexUrl } from 'utils/apiUtils'
import { apiClient } from "utils/apiClient";
import * as actionTypes from './actionTypes'

export const getCallSchedules = (page, search, leadId) => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      let url = buildIndexUrl('call_schedules', page, null, search)
      const response = await apiClient().get(`${url}&lead_id=${leadId}`)
      dispatch(setLoading(false))
      dispatch(setCallSchedules(response.data.call_schedules))
      dispatch(setPageDetails(response.data.meta))
    } catch (e) {
      dispatch(setLoading(false))
      dispatch(setErrors(e.data))
    }
  }
}

function setCallSchedules (callSchedules) {
  return {
    type: actionTypes.SET_CALL_SCHEDULES,
    callSchedules
  }
}

export function setSelectedCallSchedule (callSchedule) {
  return {
    type: actionTypes.SET_SELECTED_CALL_SCHEDULES,
    callSchedule
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

// Utility
function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
