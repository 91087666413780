/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectLeadGoalModalContainer from "./SelectLeadGoalModalContainer";
import { GOAL_MODAL, GENDER_MALE, GENDER_FEMALE } from "../../../constants";
import "../../LeadMoreDetail.css";

export class FitnessGoalsPanel extends Component {
  goalSelected = () => {
    this.props.showModal(GOAL_MODAL);
  };

  icon = () => {
    if (this.props.lead.reason_for_interest) {
      return (
        <i className="si si-check fa-2x text-muted pull-right text-success" />
      );
    }
    return (
      <i className="si si-question fa-2x text-muted pull-right text-warning" />
    );
  };

  genderAddressString(gender) {
    switch (gender) {
      case GENDER_MALE:
        return "He";
      case GENDER_FEMALE:
        return "She";
      default:
        return "They";
    }
  }

  goalText = () => {
    const { lead } = this.props;
    return (
      <div>
        {lead.reason_for_interest && (
          <div>
            <span className="h4 text-muted">Interested in: </span>
            <span className="h4 font-w700">{lead.reason_for_interest}</span>
          </div>
        )}
        {!lead.reason_for_interest && (
          <div>
            <span className="h4 text-muted">What is their </span>
            <span className="h4 font-w700">goal?</span>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <a
          className="block block-link-hover2 block-bordered selectable"
          onClick={this.goalSelected}
        >
          <div className="block-content block-content-full clearfix">
            {this.icon()}
            {this.goalText()}
          </div>
        </a>
        <SelectLeadGoalModalContainer {...this.props} modalName={GOAL_MODAL} />
      </div>
    );
  }
}

FitnessGoalsPanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
};
