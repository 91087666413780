import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import * as reportsTableActions from '../reportsTable/actions';
import notify from 'modules/Notifications';

export const updateAutomaticReportSchedule = (automaticReportId, scheduleId, selectedDays, sendTime) => {
  const body = {
    days_to_send: selectedDays.join(','),
    send_time: sendTime,
  };

  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(reportsTableActions.updateAutomaticReportSchedule(payload.automatic_report_schedule));
      dispatch(setLoading(false));
      notify.success('Successfully updated report schedule');
    }

    const url = `automatic_reports/${automaticReportId}/automatic_report_schedules/${scheduleId}`;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}
