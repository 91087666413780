import * as actionTypes from "./actionTypes";

const SALES_PERFORMANCE_LEADERBOARD_ACTION_HANDLERS = {
  [actionTypes.LOADING]: state => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, data: action.data };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  }
};

const initialState = {
  errors: null,
  loading: false,
  data: []
};

export default function reducer(state = initialState, action) {
  const handler = SALES_PERFORMANCE_LEADERBOARD_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
