import { generateMobxSuite } from 'utils/mobxHelpers';

import { IWaiverForm } from 'types';

interface FrontDeskStoreActions {
  setWaiverForms: (waiverForms: IWaiverForm[]) => void;
  setSelectedWaiverFormId: (selectedWaiverFormId?: number) => void;
  addWaiverFormsListItem: (WaiverForm: IWaiverForm) => void;
  updateWaiverFormsListItem: (WaiverForm: IWaiverForm) => void;
  removeWaiverFormsListItem: (WaiverFormId: number) => void;
  setCreatingNew: (creatingNew: boolean) => void;
}

type FrontDeskState = {
  waiverForms: IWaiverForm[];
  selectedWaiverFormId?: number;
  selectedWaiverForm?: IWaiverForm;
  creatingNew: boolean;
};

type FrontDeskStore = {
  state: FrontDeskState;
  actions: FrontDeskStoreActions;
};

const defaultState: FrontDeskState = {
  waiverForms: [],
  selectedWaiverFormId: undefined,
  creatingNew: false,
  get selectedWaiverForm() {
    if (!this.selectedWaiverFormId) return undefined;
    return this.waiverForms.filter((waiverForm) => waiverForm.id === this.selectedWaiverFormId)?.[0];
  },
};

const generatedStore = generateMobxSuite(defaultState, 'waiverForms');

export const store: FrontDeskStore = {
  state: generatedStore.state,
  actions: generatedStore.actions,
};
