import PropTypes from "prop-types";
import React, { Component } from "react";
import "./composeView.css";
import autosize from "autosize";

const MAX_LENGTH = 640;

export class FacebookComposeView extends Component {
  state = {
    message: "",
  };

  componentDidMount() {
    const {
      createChatRoomForMessengerUser,
      facebookMessengerUser,
    } = this.props;
    autosize(document.querySelectorAll("textarea"));
    if (!facebookMessengerUser) {
      return;
    }
    createChatRoomForMessengerUser(facebookMessengerUser);
  }

  componentWillUnmount() {
    const {
      unsubscribeForMessengerChatRoom,
      facebookMessengerChatRoom,
    } = this.props;
    autosize.destroy(document.querySelectorAll("textarea"));
    if (unsubscribeForMessengerChatRoom) {
      unsubscribeForMessengerChatRoom(facebookMessengerChatRoom);
    }
  }

  sendMessage = (event) => {
    event && event.preventDefault();
    const { createNewFacebookMessage, facebookMessengerUser } = this.props;
    createNewFacebookMessage(facebookMessengerUser, this.state.message);
    this.setState({ ...this.state, message: "" });
  };

  handleSwitchChange = (event) => {
    const switchValue = !event.target.checked;
    const {
      setFacebookUserHumanOnlyStatus,
      facebookMessengerUser,
      setFacebookMessengerUser,
    } = this.props;
    setFacebookUserHumanOnlyStatus(
      facebookMessengerUser,
      switchValue,
      setFacebookMessengerUser
    );
  };

  handleChange = (event) => {
    const textValue = event.target.value;
    this.setState({ ...this.state, message: textValue });
  };

  render() {
    const { sending, facebookMessengerUser, updating } = this.props;
    const { message } = this.state;

    const renderButton = () => {
      if (sending) {
        return (
          <button className="btn btn-success btn-block" disabled={false}>
            <i className="fa fa-circle-o-notch fa-spin" />{" "}
          </button>
        );
      } else {
        return (
          <button
            className="btn btn-success btn-block"
            onClick={this.sendMessage}
            disabled={!message}
          >
            <i className="fa fa-comment-o" /> Send
          </button>
        );
      }
    };
    const botDisabledTitle = facebookMessengerUser.talk_to_human_only
      ? "Chat bot disabled "
      : "Chat bot enabled ";
    return (
      <div
        className="compose-container"
        style={{ marginLeft: "-20px", marginRight: "-20px" }}
      >
        <div className="row">
          <div className="col-xs-10">
            <form onSubmit={this.sendMessage}>
              <textarea
                rows={1}
                maxLength={MAX_LENGTH}
                className="form-control"
                placeholder="Type something ..."
                value={this.state.message}
                style={{ lineHeight: "20px" }}
                onChange={this.handleChange}
              />
            </form>
          </div>
          <div className="col-xs-2">{renderButton()}</div>
        </div>
        <div className="row push-10-t">
          <div className="col-xs-4">
            <label className="css-input switch switch-warning">
              <input
                type="checkbox"
                onChange={this.handleSwitchChange}
                checked={!facebookMessengerUser.talk_to_human_only}
                disabled={updating}
              />
              <span /> {botDisabledTitle}
              {updating && <i className="fa fa-circle-o-notch fa-spin" />}
            </label>
          </div>
          <div className="col-xs-3 col-xs-offset-5">
            <p className="text-muted">
              {MAX_LENGTH - this.state.message.length} characters remaining
            </p>
          </div>
        </div>
      </div>
    );
  }
}

FacebookComposeView.propTypes = {
  facebookMessengerUser: PropTypes.object.isRequired,
  facebookMessengerChatRoom: PropTypes.object,
  sending: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  createNewFacebookMessage: PropTypes.func.isRequired,
  setFacebookUserHumanOnlyStatus: PropTypes.func.isRequired,
  setFacebookMessengerUser: PropTypes.func.isRequired,
  createChatRoomForMessengerUser: PropTypes.func.isRequired,
  unsubscribeForMessengerChatRoom: PropTypes.func.isRequired,
};

export default FacebookComposeView;
