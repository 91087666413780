import PropTypes from 'prop-types'
import React, { Component } from 'react'

import OptionView from './OptionView'

export class OptionsView extends Component {

  indexClicked = (index) => {
    const { onClick, options } = this.props
    const option = options[index]
    onClick(option)
  }

  render () {
    const { options, configuredIntegrations } = this.props

    const integrationOptions = options.map((option, index) => {
      const integerationIndex = configuredIntegrations
      .findIndex(integration => integration.name.toString() === option.toString())
      const selectedIntegration = configuredIntegrations[integerationIndex]
      const isSelected = (selectedIntegration !== null && selectedIntegration !== undefined)
      const verified = (selectedIntegration) ? selectedIntegration.verified : null
      return (
        <div key={index} className='col-sm-6 col-lg-4' onClick={() => this.indexClicked(index)} >
          <OptionView
            option={option}
            verified={verified}
            isSelected={isSelected} />
        </div>
      )
    })

    return (
      <div className='row'>
        {integrationOptions}
      </div>
    )
  }
}

OptionsView.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  configuredIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired
}

export default OptionsView
