import PropTypes from "prop-types";
import React, { Component } from "react";
import { HeardAboutMethodTableRow } from "./HeardAboutMethodTableRow";

export class HeardAboutMethodTableView extends Component {
  render() {
    const {
      heardAboutMethods,
      heardAboutMethodSelected,
      selectedHeardAboutMethodId,
      tableHead,
      renderRow
    } = this.props;
    const rows = heardAboutMethods.map((heardAboutMethod, index) => {
      const selected = heardAboutMethod.id === selectedHeardAboutMethodId;
      if (renderRow) {
        return renderRow(heardAboutMethod, selected);
      }
      return (
        <HeardAboutMethodTableRow
          key={index}
          heardAboutMethod={heardAboutMethod}
          heardAboutMethodSelected={heardAboutMethodSelected}
          selected={selected}
        />
      );
    });

    return (
      <div style={{ marginTop: "-20px" }}>
        <table className="table table-vcenter table-hover">
          {tableHead && tableHead}
          {!tableHead && (
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
          )}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

HeardAboutMethodTableView.propTypes = {
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func,
  tableHead: PropTypes.element,
  heardAboutMethodSelected: PropTypes.func.isRequired,
  selectedHeardAboutMethodId: PropTypes.number
};

HeardAboutMethodTableRow.defaultProps = {
  showSaleDetails: true
};

export default HeardAboutMethodTableView;
