import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getLoading = state => state.callScheduleSettings[NAME].loading;
export const getErrors = state => state.callScheduleSettings[NAME].errors;
export const getLeadSourceIds = state =>
  state.callScheduleSettings[NAME].leadSourceIds;
export const getData = state => state.callScheduleSettings[NAME].data;

export const getSelectedLocationIds = state =>
  state.callScheduleSettings[NAME].selectedLocationIds;
export const getLocationSearchTerm = state =>
  state.callScheduleSettings[NAME].locationSearchTerm;
export const getLocationCurrentPage = state =>
  state.callScheduleSettings[NAME].locationCurrentPage;

// Locations
export function filteredLocations(locations) {
  return createSelector(
    [getLocationSearchTerm],
    searchTerm => {
      if (searchTerm && searchTerm.length > 0) {
        const uppercaseSearchTerm = searchTerm.toUpperCase();
        return locations.filter(location => {
          return location.admin_display_name
            .toUpperCase()
            .includes(uppercaseSearchTerm);
        });
      } else {
        return locations;
      }
    }
  );
}

export function pageCountForLocations(locations, resultsPerPage) {
  return createSelector(
    [getLocationCurrentPage],
    currentPage => {
      const pageCount = locations.length / resultsPerPage;
      if (pageCount < 1) {
        return 1;
      }
      return pageCount;
    }
  );
}

export function locationsAtCurrentPage(locations, resultsPerPage) {
  return createSelector(
    [getLocationCurrentPage],
    currentPage => {
      const startIndex = resultsPerPage * (currentPage - 1);
      const endIndex = startIndex + resultsPerPage;

      return locations.slice(startIndex, endIndex);
    }
  );
}

export const locationIdsData = createSelector(
  [getSelectedLocationIds],
  selectedLocationIds => {
    let fields = {};
    selectedLocationIds.forEach(locationId => {
      fields = { ...fields, [locationId]: { isValid: true, value: true } };
    });
    return { fields: fields, isValid: true };
  }
);

export const leadSourceIdsData = createSelector(
  [getLeadSourceIds],
  selectedLeadSourceIds => {
    let fields = {};
    selectedLeadSourceIds.forEach(leadSourceId => {
      fields = { ...fields, [leadSourceId]: { isValid: true, value: true } };
    });
    return { fields: fields, isValid: true };
  }
);
