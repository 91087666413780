import { ReactText } from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

const defaultOptions: ToastOptions = {
  draggable: false,
  autoClose: 2500,
};

const notify = {
  success: (content: ToastContent, options = defaultOptions): ReactText => {
    return toast.success(content, options);
  },

  info: (content: ToastContent, options = defaultOptions): ReactText => {
    return toast.info(content, options);
  },

  error: (content: ToastContent, options = defaultOptions): ReactText => {
    return toast.error(content, options);
  },

  warning: (content: ToastContent, options = defaultOptions): ReactText => {
    return toast.warning(content, options);
  },
};

export default notify;
export { ToastButtonView } from './ToastButtonView';
