import PropTypes from 'prop-types';
import moment from 'moment';
import React, { Component } from 'react';
import { PageHeader } from 'modules/components';
import HeaderImage from '../assets/leads@2x.jpg';

import FilterConatiner from '../containers/FiltersContainer';
import LeadsListContainer from '../containers/LeadsListContainer';
import LeadDetailContainer from '../containers/LeadDetailContainer';

import MediaQuery from 'react-responsive';

import * as constants from '../constants';

export class ManageLeadsView extends Component {
  UNSAFE_componentWillMount() {
    const { presetUserInFilters, user, data, hasPrefilledUser } = this.props;
    if (!hasPrefilledUser) {
      presetUserInFilters(user, data);
    }
    this.fetchleadDetails(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentLeadIdToShow = this.props.leadIdToShow ? this.props.leadIdToShow : null;
    const nextLeadIdToShow = nextProps.leadIdToShow ? nextProps.leadIdToShow : null;
    if (currentLeadIdToShow !== nextLeadIdToShow) {
      this.fetchleadDetails(nextProps);
    }
  }

  fetchleadDetails = (props) => {
    const { leadIdToShow, getLeadsWithQueryAndPageNumber, selectLead, showingQuickLeadView, quickAction } = props;
    if (leadIdToShow) {
      const query = 'filter[ids][]=' + leadIdToShow;
      getLeadsWithQueryAndPageNumber(query, 1);
      selectLead(leadIdToShow);
      showingQuickLeadView(true);
    } else if (quickAction) {
      this.setQueryForQuickAction();
    }
  };

  setQueryForQuickAction = () => {
    const { updateFilters, selectLead, showingQuickLeadView, quickAction, locationIdToShow } = this.props;
    let data = {
      location_ids: {
        fields: {
          [locationIdToShow]: { value: true, isValid: true },
        },
      },
    };
    let statusUpdatedBetween = {
      fields: {
        status_updated_after: {
          value: moment().startOf('day').utc().format(),
          isValid: true,
        },
        status_updated_before: {
          value: moment().endOf('day').utc().format(),
          isValid: true,
        },
      },
    };
    let statuses = { fields: {}, isValid: true };
    switch (quickAction) {
      case constants.QUICK_ACTION_NEW_LEADS:
        statuses = {
          fields: {
            active: { value: true, isValid: true },
            closed: { value: true, isValid: true },
            inactive: { value: true, isValid: true },
            lost: { value: true, isValid: true },
            not_assigned: { value: true, isValid: true },
          },
          isValid: true,
        };
        break;
      case constants.QUICK_ACTION_UNASSIGNED_LEADS:
        statuses = {
          fields: {
            not_assigned: { value: true, isValid: true },
          },
          isValid: true,
        };
        statusUpdatedBetween = { fields: {} };
        break;
      case constants.QUICK_ACTION_ACTIVE_LEADS:
        statuses = {
          fields: {
            active: { value: true, isValid: true },
          },
          isValid: true,
        };
        statusUpdatedBetween = { fields: {} };
        break;
      case constants.QUICK_ACTION_CLOSED_LEADS:
        statuses = {
          fields: {
            closed: { value: true, isValid: true },
          },
          isValid: true,
        };
        break;
      default:
        break;
    }
    data = { ...data, statuses: statuses, statusUpdatedBetween: statusUpdatedBetween };
    updateFilters(data);
    selectLead(null);
    showingQuickLeadView(false);
  };

  render() {
    const title = 'Manage leads';
    const subtitle = 'Manage and close leads through your sales funnel';

    const leftColSize = this.props.showingLead ? 'col-md-4' : 'col-md-8';
    const rightColSize = this.props.showingLead ? 'col-md-8' : 'col-md-4';

    const filterStyle = this.props.showingLead ? { display: 'none' } : {};
    const detailStyle = this.props.showingLead ? {} : { display: 'none' };

    return (
      <div className="content content-boxed overflow-hidden">
        <PageHeader title={title} subtitle={subtitle} image={HeaderImage} imageYPositionStyle={'82%'} />
        <div className="row">
          <MediaQuery query="(min-width: 992px)">
            <div className={leftColSize}>
              <LeadsListContainer />
            </div>
            <div className={rightColSize}>
              <div style={detailStyle}>
                <LeadDetailContainer />
              </div>
              <div style={filterStyle}>
                <FilterConatiner />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width: 992px)">
            <div className={rightColSize}>
              <div style={detailStyle}>
                <LeadDetailContainer />
              </div>
              <div style={filterStyle}>
                <FilterConatiner />
              </div>
            </div>
            <div className={leftColSize}>
              <LeadsListContainer />
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

ManageLeadsView.propTypes = {
  getLeadsWithQueryAndPageNumber: PropTypes.func.isRequired,
  selectLead: PropTypes.func.isRequired,
  showingLead: PropTypes.bool.isRequired,
  showingQuickLeadView: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  presetUserInFilters: PropTypes.func.isRequired,
  hasPrefilledUser: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  leadIdToShow: PropTypes.string,
  locationIdToShow: PropTypes.number,
  quickAction: PropTypes.string,
};

export default ManageLeadsView;
