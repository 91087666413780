import { request, GET, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';
import * as listingActions from '../../../listing/actions';

import notify from 'modules/Notifications';
import { trackEvent } from 'modules/Helpers/Analytics';
import { fetchTrials } from 'modules/api/trials';
import * as ThirdPartyIntegrationsAPI from 'modules/api/thirdPartyIntegration';
import modal from 'modules/Stores/Modal';

export const addOrUpdateOptionWithData = (formData, location, toTabIndex, _body) => {
  return (dispatch) => {
    const body = _body || {
      third_party_integration: seralizeOptionFromFormData(formData, 'mindbody', 'outgoing'),
    };
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      if (toTabIndex) dispatch(setTabIndex(toTabIndex));

      if (formData.fields.id.value) {
        dispatch(listingActions.updateIntegration(payload.third_party_integration));
      } else {
        dispatch(listingActions.addIntegration(payload.third_party_integration));
      }
      dispatch(prefillFormForIntegration(payload.third_party_integration));
    }

    if (formData.fields.id.value) {
      return request('locations/' + location.id + '/third_party_integrations/' + formData.fields.id.value, PUT, body, onSuccess, onFailure);
    } else {
      return request('locations/' + location.id + '/third_party_integrations', POST, body, onSuccess, onFailure);
    }
  };
};

export const verifiyIntegration = (location, integrationId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      const integration = payload.third_party_integration;
      if (integration.verified === true) {
        dispatch(setTabIndex(2));
        dispatch(listingActions.updateIntegration(integration));
        dispatch(prefillFormForIntegration(integration));
      } else {
        const errors = { errors: { not_verified: 'Club not yet verified' } };
        dispatch(modal.actions.showErrors(errors));
      }
    }

    return request('locations/' + location.id + '/third_party_integrations/' + integrationId + '/verify', GET, null, onSuccess, onFailure);
  };
};

export const deleteIntegration = (formData, location) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(listingActions.deleteIntegration(formData.fields.id.value));
      dispatch(reset());
      notify.success('Integration Removed');
      trackEvent('Integration Removed');
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + formData.fields.id.value,
      DELETE,
      null,
      onSuccess,
      onFailure
    );
  };
};

export const setPermissionForSet = (locationId, integrationId, permissionSetId, field, value) => {
  return (dispatch) => {
    const body = {
      integration_permission_set: {
        [field]: value,
      },
    };

    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setPermissionSet(payload.integration_permission_set));
    }
    const url =
      'locations/' + locationId + '/third_party_integrations/' + integrationId + '/integration_permission_sets/' + permissionSetId;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setPermissionSet(permissionSet) {
  return {
    type: actionTypes.SET_PERMISSION_SET,
    permissionSet,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.ON_INPUT,
    data,
  };
}

export function prefillFormForIntegration(integration) {
  return {
    type: actionTypes.PREFIL_FORM,
    integration,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setTabIndex(tabIndex) {
  return {
    type: actionTypes.SET_TAB_INDEX,
    tabIndex,
  };
}

export const getMindbodyLocationsForPage = (location, integrationId, pageNumber) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setSiteLocations(payload.locations));
      dispatch(loadPageDetails(payload.meta));
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + integrationId + '/locations?page[number]=' + pageNumber,
      GET,
      null,
      onSuccess,
      onFailure
    );
  };
};

export function getTrials() {
  return async (dispatch) => {
    try {
      const trialsResponse = await fetchTrials();
      console.log({ trials: trialsResponse.trials });
      dispatch(setTrials(trialsResponse.trials));
    } catch (error) {
      console.log(error);
      dispatch(setTrials([]));
    }
  };
}

export function getIntegrationMemberships(integrationId) {
  return async (dispatch) => {
    try {
      const membershipsResponse = await ThirdPartyIntegrationsAPI.fetchThirdPartyIntegrationMemberships(integrationId);
      dispatch(setMemberships(membershipsResponse.memberships));
    } catch (error) {
      console.log(error);
      dispatch(setMemberships([]));
    }
  };
}

export function getTrialsMembershipsMappings(integrationId) {
  return async (dispatch) => {
    try {
      const mappings = await ThirdPartyIntegrationsAPI.fetchTrialsMembershipsMappings(integrationId);
      console.log({ mappings: mappings.trial_membership_mappings });
      dispatch(setTrialsMembershipsMappings(mappings.trial_membership_mappings));
    } catch (error) {
      console.log(error);
      dispatch(setTrialsMembershipsMappings([]));
    }
  };
}

export function mapTrialToMembership(integrationId, membershipId, trialId) {
  return async (dispatch) => {
    try {
      const mapping = await ThirdPartyIntegrationsAPI.mapTrialToMembership(integrationId, membershipId, trialId);
      console.log({ mapping });
      dispatch(addTrialMembershipMapping(mapping.trial_to_membership_mapping));
    } catch (error) {
      console.log(error);
    }
  };
}

export function removeTrialToMembershipMapping(integrationId, mappingId) {
  return async (dispatch) => {
    try {
      await ThirdPartyIntegrationsAPI.deleteTrialToMembershipMapping(integrationId, mappingId);
      dispatch(removeTrialMembershipMapping(mappingId));
    } catch (error) {
      console.log(error);
    }
  };
}

function setSiteLocations(siteLocations) {
  return {
    type: actionTypes.SET_SITE_LOCATIONS,
    siteLocations,
  };
}

function setTrialsMembershipsMappings(mappings) {
  return {
    type: actionTypes.SET_TRIALS_MEMBERSHIPS_MAPPINGS,
    trialsMembershipsMappings: mappings,
  };
}
function setMemberships(memberships) {
  return {
    type: actionTypes.SET_MEMBERSHIPS,
    memberships,
  };
}
function setTrials(trials) {
  return {
    type: actionTypes.SET_TRIALS,
    trials,
  };
}

function addTrialMembershipMapping(mapping) {
  return {
    type: actionTypes.ADD_TRIAL_MEMBERSHIP_MAPPING,
    mapping,
  };
}

function removeTrialMembershipMapping(mappingId) {
  return {
    type: actionTypes.REMOVE_TRIAL_MEMBERSHIP_MAPPING,
    mappingId,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function seralizeOptionFromFormData(formData, name, direction) {
  const { companyName, username } = formData.fields;

  let payload = { name: 'mindbody', direction: 'outgoing' };
  payload = newPayloadForFormValue(payload, companyName, 'company_name');
  payload = newPayloadForFormValue(payload, username, 'username');

  return payload;
}
