import { request, GET, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';
import regions from 'modules/Stores/Regions';

export const updateRegion = (id, name, postcodes) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      region: {
        name: name,
        region_postcodes_attributes: postcodeAttributesForPostcodes(postcodes),
      },
    };
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(regions.actions.replaceRegionWithId(payload.region, id));

      notify.success('Region updated');
      dispatch(modal.actions.hideModal());
    }

    return request('regions/' + id, PUT, body, onSuccess, onFailure);
  };
};

function postcodeAttributesForPostcodes(postcodes) {
  return postcodes.map((postcodeString) => {
    return { postcode: postcodeString };
  });
}

export const getLocationsForRegionId = (regionId) => {
  return (dispatch) => {
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(setLocations(payload.locations));
    }

    return request('locations_for_region/' + regionId, GET, null, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
    loading: true,
  };
}

function stopLoading() {
  return {
    type: actionTypes.LOADING,
    loading: false,
  };
}

function showErrors(errors) {
  return {
    type: actionTypes.SHOW_ERRORS,
    errors,
  };
}

function setLocations(locations) {
  return {
    type: actionTypes.SET_LOCATIONS,
    locations,
  };
}
