import React from "react";
import styled from "styled-components";

export const toolTipSelector = calculating => {
  switch (calculating) {
    case "lead_created":
      return leadCreatedTooltips();
    case "event_occured":
      return eventOccuredTooltips();
    default:
      return eventOccuredTooltips();
  }
};

const leadCreatedTooltips = () => {
  const walkinCount = (
    <Container>
      <p>
        For all leads that are{" "}
        <strong>created and assigned with a source of 'Walk in'</strong> within
        your selected time period.
      </p>
      <p>
        Eg: John has a lead walk in, creates their profile, assigns them to
        himself and marks their source as 'Walk in'
      </p>
    </Container>
  );

  const walkinSales = (
    <Container>
      <p>
        For all leads that were created in the selected time period that have a{" "}
        <strong>
          source of 'walk in' that were changed to a status of 'Closed'
        </strong>
        .
      </p>
      <p>
        Eg: Sally had a lead walk in, created their profile, assigned them to
        herself, marked their source as 'walk in' and changes their status to
        'Closed'.
      </p>
    </Container>
  );

  const walkinClose = (
    <Container>
      <p>
        This ratio is comparing all <strong>walk-ins created</strong> within
        your selected time period that also{" "}
        <strong>have a status of 'Closed'</strong>.
      </p>
      <p>*walk in sales/walk ins x 100 = %</p>
    </Container>
  );

  const tooltips = {
    walkinCount,
    walkinSales,
    walkinClose
  };
  return tooltips;
};

const eventOccuredTooltips = () => {
  const walkinCount = (
    <Container>
      <p>
        All leads that are{" "}
        <strong>created and assigned with a source of 'Walk in'</strong> within
        your selected time period.
      </p>
      <p>
        Eg: John has a lead walk in, creates their account, assigns them to
        himself and marks their source as 'Walk in'
      </p>
    </Container>
  );

  const walkinSales = (
    <Container>
      <p>
        All leads that have a{" "}
        <strong>
          source of 'walk in' that were changed to a status of 'Closed'
        </strong>{" "}
        within your selected time period.
      </p>
      <p>
        Eg: Sally had a lead walk in, created their account, assigned them to
        herself, marked their source as 'walk in' and changes their status to
        'Closed'.
      </p>
    </Container>
  );

  const walkinClose = (
    <Container>
      <p>
        This ratio is comparing all <strong>walk ins created</strong> within
        your selected time period that also{" "}
        <strong>have a status of 'Closed'.</strong>
      </p>
      <p>*walk in sales/walk ins x 100 = %</p>
    </Container>
  );

  const tooltips = {
    walkinCount,
    walkinSales,
    walkinClose
  };

  return tooltips;
};

const Container = styled.span``;
