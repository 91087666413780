import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import { INDIVIDUAL_TYPE, LOCATION_TYPE } from './constants'

// FETCH New Leads
export const fetchSalesTargetResultsForLocationId = (locationId) => {
  return dispatch => {
    dispatch(fetchingTargets(LOCATION_TYPE))

    function onFailure (errors) {
      dispatch(fetchTargetsFailed(errors, LOCATION_TYPE))
    }

    function onSuccess (payload) {
      dispatch(fetchTargetsSuccessful(payload.target_rule_set_result, LOCATION_TYPE))
    }

    return request('location_target_rule_set_results?location_id=' + locationId, GET, null, onSuccess, onFailure)
  }
}

export const fetchSalesTargetResultsForUserId = (userId, locationId) => {
  return dispatch => {
    dispatch(fetchingTargets(INDIVIDUAL_TYPE))

    function onFailure (errors) {
      dispatch(fetchTargetsFailed(errors, INDIVIDUAL_TYPE))
    }

    function onSuccess (payload) {
      dispatch(fetchTargetsSuccessful(payload.target_rule_set_result, INDIVIDUAL_TYPE))
    }
    const query = 'user_target_rule_set_results?user_id=' + userId + '&location_id=' + locationId
    return request(query, GET, null, onSuccess, onFailure)
  }
}

function fetchingTargets (targetType) {
  return {
    type: actionTypes.FETCHING,
    targetType
  }
}

function fetchTargetsSuccessful (salesTargetResults, targetType) {
  return {
    type: actionTypes.FETCH_SUCCESS,
    salesTargetResults,
    targetType
  }
}

export function fetchTargetsFailed (errors, targetType) {
  return {
    type: actionTypes.FETCH_FAILED,
    errors,
    targetType
  }
}
