import * as actionTypes from './actionTypes'
import moment from 'moment'

const SCHEDULE_ACTION_HANDLERS = {
  [actionTypes.SET_SCHEDULE_SLOTS]: (state, action) => {
    return { ...state, scheduleSlots: action.scheduleSlots }
  },
  [actionTypes.ADD_SLOT_TO_SCHEDULE]: (state, action) => {
    const updatedScheduleSlots = [
      ...state.scheduleSlots,
      action.slot
    ]
    return { ...state, scheduleSlots: updatedScheduleSlots }
  },
  [actionTypes.REMOVE_SLOT_FROM_SCHEDULE]: (state, action) => {
    const index = indexForSlot(state.scheduleSlots, action.slot)
    const scheduleSlots = state.scheduleSlots
    if (index > -1) {
      const updatedScheduleSlots = [
        ...scheduleSlots.slice(0, index),
        ...scheduleSlots.slice(index + 1)
      ]
      return { ...state, scheduleSlots: updatedScheduleSlots }
    }
    return state
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

function initialStateForPermissions (userPermissions) {
  return {
    errors: null,
    loading: false,
    scheduleSlots: []
  }
}

function indexForSlot (scheduleSlots, slot) {
  const compareStartMoment = moment(slot.start)
  const compareEndMoment = moment(slot.end)

  return scheduleSlots.findIndex((_slot) => {
    const startMoment = moment(_slot.start)
    const endMoment = moment(_slot.end)

    if (startMoment.day() !== compareStartMoment.day() ||
     startMoment.hour() !== compareStartMoment.hour() ||
     startMoment.minute() !== compareStartMoment.minute()) {
      return false
    }

    if (endMoment.day() !== compareEndMoment.day() ||
     endMoment.hour() !== compareEndMoment.hour() ||
     endMoment.minute() !== compareEndMoment.minute()) {
      return false
    }

    return true
  })
}

export default function reducer (state = initialStateForPermissions({}), action) {
  const handler = SCHEDULE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
