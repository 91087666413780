import * as actionTypes from './actionTypes'

export function selectLocation (location) {
  return {
    type: actionTypes.LOCATION_SELECTED,
    location
  }
}

export function setShowCompletedOption (showCompletedOption) {
  return {
    type: actionTypes.SET_SHOW_COMPLETED_OPTION,
    showCompletedOption
  }
}
