import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import CallScheduleSelectContainer from '../containers/CallScheduleSelectContainer';

export class CallScheduleSelectModal extends Component {
  onCallScheduleSelect = (callSchedule) => {
    const { onUpdate, hideModal, reset } = this.props;
    if (onUpdate) {
      onUpdate(callSchedule.id);
    }
    hideModal();
    reset();
  };

  render() {
    const { currentModal, hideModal, modalName, leadId } = this.props;

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select a Call Schedule
            </h3>
          </div>
          <div className="block-content">
            <CallScheduleSelectContainer onCallScheduleSelect={this.onCallScheduleSelect} leadId={leadId} />
          </div>
        </div>

        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CallScheduleSelectModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  onUpdate: PropTypes.func,
  identifer: PropTypes.string,
  modalName: PropTypes.string.isRequired,
};

export default CallScheduleSelectModal;
