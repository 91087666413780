import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'
import { SalesFunnelValidator } from '../validators'

const { InlineTextField, Switch } = FormFields

export class SalesFunnelForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.updateData(newData)
  }

  handleToggle = (fieldName, value, isValid) => {
    const { data, updateData } = this.props
    const fields = data.fields
    const name = fieldName.replace('-', '')
    fields[name] = { ...fields[name], allowsClose: value }
    const newData = { ...data, fields: fields }
    updateData(newData)
  }

  render () {
    const { stepOne, stepTwo, stepThree, stepFour } = this.props.data.fields
    return (
      <div>
        <div>
          <div className='row'>
            <div className='col-md-9'>
              <InlineTextField name='stepOne'
                label='Step One'
                placeholder='Inquired'
                formFieldStyle=''
                onChange={this.handleInput}
                disabled={false}
                validator={SalesFunnelValidator}
                value={stepOne.value} />
            </div>
            <div className='col-md-3'>
              <Switch
                name='stepOne-'
                label='Allow close'
                onChange={this.handleToggle}
                switchStyle='switch-sm switch-primary'
                value={stepOne.allowsClose} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-9'>
              <InlineTextField name='stepTwo'
                label='Step Two'
                placeholder='Interviewed'
                formFieldStyle=''
                onChange={this.handleInput}
                disabled={false}
                validator={SalesFunnelValidator}
                value={stepTwo.value} />
            </div>
            <div className='col-md-3'>
              <Switch
                name='stepTwo-'
                label='Allow close'
                onChange={this.handleToggle}
                switchStyle='switch-sm switch-primary'
                value={stepTwo.allowsClose} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-9'>
              <InlineTextField name='stepThree'
                label='Step Three'
                placeholder='Visited'
                formFieldStyle=''
                onChange={this.handleInput}
                disabled={false}
                validator={SalesFunnelValidator}
                value={stepThree.value} />
            </div>
            <div className='col-md-3'>
              <Switch
                name='stepThree-'
                label='Allow close'
                onChange={this.handleToggle}
                switchStyle='switch-sm switch-primary'
                value={stepThree.allowsClose} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-9'>
              <InlineTextField name='stepFour'
                label='Step Four'
                placeholder='Closed'
                formFieldStyle=''
                onChange={this.handleInput}
                disabled={false}
                validator={SalesFunnelValidator}
                value={stepFour.value} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8 col-md-offset-2'>
              <button className='btn btn-primary push-5-r push-10'
                type='button'
                onClick={this.props.onSaveSelected}>
                <i className='fa fa-filter' /> Update Sales Funnel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SalesFunnelForm.propTypes = {
  onSaveSelected: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default SalesFunnelForm
