/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { EmptyView, TextSearchView, PagesView, GymLeadsLoader } from 'modules/components';

export const SelectQuickSendTemplateView = (props) => {
  const [search, setSearch] = useState(undefined);
  const { emailTemplates, onTemplateSelect, pageDetails, loading, getEmailTemplates } = props;

  const pageCount = pageDetails.pageCount;

  useEffect(() => {
    getEmailTemplates('quick_send', 1);
  }, [getEmailTemplates]);

  const updateSearch = (newSearchTerm) => {
    setSearch(newSearchTerm);
    getEmailTemplates('quick_send', 1, newSearchTerm);
  };

  const handlePageClicked = (newPageNumber) => {
    getEmailTemplates('quick_send', newPageNumber, search);
  };

  const rows = emailTemplates.map((template) => {
    return <TemplateRow emailTemplate={template} key={template.id} onSelect={onTemplateSelect} />;
  });

  return (
    <div style={{ marginLeft: '12px', marginRight: '12px' }}>
      <div className="row">
        <div className="col-xs-12">
          <TextSearchView sectionName={'emailTemplateSearch'} onUpdate={updateSearch} />
        </div>
      </div>
      <div className="list-group selectable push">
        <GymLeadsLoader active={loading} text="Getting Quick Send Templates" />
        {!loading && rows}
        {!loading && emailTemplates.length === 0 && (
          <EmptyView colorThemeClass="primary" iconClass="fa fa-envelope" title="No Quick Send Templates" />
        )}
      </div>
      {pageCount > 1 && (
        <div className="text-center push">
          <PagesView pageClicked={handlePageClicked} pageDetails={props.pageDetails} />
        </div>
      )}
      <div className="modal-footer">
        <button className="btn btn-primary push-10 pull-left" onClick={props.backSelected}>
          <i className="fa fa-angle-left" /> Back
        </button>
        <button className="btn btn-default push-5-r push-10" type="button" onClick={props.cancelSelected}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SelectQuickSendTemplateView;

const TemplateRow = (props) => {
  const { emailTemplate, onSelect } = props;

  const rowSelected = () => {
    onSelect(emailTemplate);
  };

  return (
    <a onClick={rowSelected} className="list-group-item selectable">
      <span>{emailTemplate.title}</span>
    </a>
  );
};
