import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import StepTransitionsContainer from "modules/components/FilterFormSections/containers/StepTransitionsContainer";

function mapStateToProps (state, props) {
  const funnelStepsName = "sales_funnel_step_links";
  const funnelStepsData = props.filterData && props.filterData[funnelStepsName] ? props.filterData[funnelStepsName] : { fields: {} };

  return {
    sectionName: funnelStepsName,
    data: funnelStepsData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepTransitionsContainer)
