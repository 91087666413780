import * as actionTypes from "./actionTypes";

const USER_SELECT_ACTION_HANDLERS = {
  [actionTypes.SET_USERS]: (state, action) => {
    return { ...state, selectedUsers: action.users };
  },
  [actionTypes.SET_TYPES]: (state, action) => {
    return { ...state, types: action.types };
  },
};

const initialState = {
  selectedUsers: [],
  types: ["appointments"],
};

export default function reducer(state = initialState, action) {
  const handler = USER_SELECT_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
