import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class ContentBlockView extends Component {
  render() {
    const { title, children, header, loading, bordered, minHeight, blockOptions, colorStyle } = this.props;
    const blockStyle = bordered ? `block block-bordered` : `block`;
    const className = loading ? blockStyle + ' block-opt-refresh' : blockStyle;
    const style = minHeight ? { minHeight: minHeight } : {};
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={className} style={style}>
            {header && header}
            {title && (
              <div className={`block-header ${colorStyle}`}>
                {blockOptions && blockOptions}
                <h3 className="block-title pull-left">{title}</h3>
              </div>
            )}
            <div className="block-content">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

ContentBlockView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  blockOptions: PropTypes.element,
  title: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.element,
  bordered: PropTypes.bool,
  minHeight: PropTypes.number,
  colorStyle: PropTypes.string,
};

ContentBlockView.defaultProps = {
  loading: false,
  bordered: false,
};

export default ContentBlockView;
