import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../components/Header';
import session from 'modules/Stores/Session';
import * as selectors from '../selectors';

const mapStateToProps = (state) => {
  return {
    user: session.selectors.getUser(state),
    whitelabelProfile: selectors.getWhitelabelProfile(state),
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: session.actions.logout,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
