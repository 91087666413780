import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'
import header from '../modules/header'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    title: 'Activity',
    queued: false,
    queryString: queryStringSelector(state),
    compareOption: filters.selectors.getCompareOption(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(header.components.ReportHeaderView)
