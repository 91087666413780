import { combineReducers } from 'redux';
import activityBreakdown from './modules/activityBreakdown';
import tableBreakdown from './modules/tableBreakdown';
import quickInfoBlocks from './modules/quickInfoBlocks';
import filters from './modules/filters';
import header from './modules/header';

export default combineReducers({
  [header.constants.NAME]: header.reducer,
  [filters.constants.NAME]: filters.reducer,
  [activityBreakdown.constants.NAME]: activityBreakdown.reducer,
  [tableBreakdown.constants.NAME]: tableBreakdown.reducer,
  [quickInfoBlocks.constants.NAME]: quickInfoBlocks.reducer,
});
