import * as actionTypes from './actionTypes'
import { CONTACT_CALL_REPORT } from './constants'

const ACTIVITY_HEADER_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_SELECTED_REPORT]: (state, action) => {
    return { ...state, selectedReport: action.selectedReport }
  }
}

const initialState = {
  selectedReport: CONTACT_CALL_REPORT,
  loading: false
}

export default function reducer (state = initialState, action) {
  const handler = ACTIVITY_HEADER_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
