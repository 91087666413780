import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class FilterSection extends Component {

  render () {
    const { children, title, showBottomBorder, blockStyle } = this.props
    const blockClassName = (blockStyle) || 'block'
    return (
      <div className={blockClassName} style={{ marginBottom: '0px' }}>
        <div className='block-header' style={{ paddingBottom: '0px' }}>
          <h3 className='block-title'>{title}</h3>
        </div>
        <div className='block-content' style={{ paddingTop: '0px' }}>
          <div className='row'>
            {children}
          </div>
          {showBottomBorder !== false &&
            <hr style={{ borderColor: '#BABABA', marginTop: '20px', marginBottom: '0px' }} />
          }
        </div>
      </div>
    )
  }
}

FilterSection.propTypes = {
  children : PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool,
  blockStyle: PropTypes.string
}
