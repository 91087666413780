import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectLeadLostReasonTableRow extends Component {
  rowSelected = event => {
    const {
      leadLostReason,
      leadLostReasonSelected,
      selectLeadLostReason,
      deselectLeadLostReason
    } = this.props;
    if (leadLostReasonSelected) {
      deselectLeadLostReason(leadLostReason);
    } else {
      selectLeadLostReason(leadLostReason);
    }
  };

  render() {
    const { leadLostReason, leadLostReasonSelected } = this.props;
    return (
      <tr
        className="selectable"
        onClick={() => this.rowSelected(leadLostReason)}
      >
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input
              type="checkbox"
              disabled
              checked={leadLostReasonSelected}
              readOnly
            />
            <span />
          </label>
        </td>
        <td>{leadLostReason.lost_reason_title}</td>
      </tr>
    );
  }
}

SelectLeadLostReasonTableRow.propTypes = {
  leadLostReason: PropTypes.object.isRequired,
  leadLostReasonSelected: PropTypes.bool.isRequired,
  selectLeadLostReason: PropTypes.func.isRequired,
  deselectLeadLostReason: PropTypes.func.isRequired
};
