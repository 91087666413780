import * as components from './components';
import * as constants from './constants';
import * as actions from './actions';
import reducer from './reducer';

import history from './modules/history';
import details from './modules/details';
import appointments from './modules/appointments';
import calls from './modules/calls';
import todos from './modules/todos';
import notes from './modules/notes';
import messages from './modules/messages';
import emails from './modules/emails';
import checkIns from './modules/checkIns';
import leadsTrials from './modules/leadsTrials';

export default {
  constants,
  calls,
  details,
  appointments,
  components,
  actions,
  reducer,
  history,
  todos,
  notes,
  messages,
  emails,
  checkIns,
  leadsTrials,
};
