import * as actionTypes from './actionTypes'

const USER_DATA_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state, action) => {
    return { ...state, loading: true, errors: null, selectedTeamMemberId: null }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, errors: null }
  },
  [actionTypes.LOAD_FAILURE]: (state, action) => {
    return { ...state, team: null, errors: action.errors.errors, loading: false }
  },
  [actionTypes.TEAM_UPDATED]: (state, action) => {
    return { ...state, team: action.team }
  },
  [actionTypes.TEAM_MEMBER_REMOVED]: (state, action) => {
    const index = indexForSalesTeamMember(state.team, action.user.id)
    const team = [
      ...state.team.slice(0, index),
      ...state.team.slice(index + 1)
    ]
    return { ...state, team: team }
  },
  [actionTypes.TEAM_MEMBER_SELECTED]: (state, action) => {
    return { ...state, selectedTeamMemberId: action.userId }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        total: action.pageDetails.total_count
      }
    }
  },
  [actionTypes.TEAM_MEMBER_UPDATED]: (state, action) => {
    const index = indexForSalesTeamMember(state.team, action.user.id)
    const team = [
      ...state.team.slice(0, index),
      action.user,
      ...state.team.slice(index + 1)
    ]
    return { ...state, team: team }
  },
  [actionTypes.TEAM_MEMBER_PERMISSIONS_UPDATED]: (state, action) => {
    const index = indexForSalesTeamMember(state.team, action.userId)
    const oldTeamMember = state.team[index]
    const updatedTeamMember = { ...oldTeamMember, user_permission_rule_set: action.userPermissionRuleSet }
    const team = [
      ...state.team.slice(0, index),
      updatedTeamMember,
      ...state.team.slice(index + 1)
    ]
    return { ...state, team: team }
  }
}

function indexForSalesTeamMember (salesTeam, salesTeamMemberId) {
  return salesTeam.findIndex(_salesTeamMember => parseInt(_salesTeamMember.id) === parseInt(salesTeamMemberId))
}

const initialState = {
  loading: false,
  errors: null,
  selectedTeamMemberId: null,
  team: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = USER_DATA_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
