import { request, buildIndexUrl, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getSmsAccounts = (pageNumber, searchTerm = null) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setSmsAccounts(payload.sms_accounts))
      dispatch(setPageDetails(payload.meta))
    }

    const url = buildIndexUrl('sms_accounts', pageNumber, null, searchTerm)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setSmsAccounts (smsAccounts) {
  return {
    type: actionTypes.SET_SMS_ACCOUNTS,
    smsAccounts
  }
}

export function setSelectedSmsAccount (selectedSmsAccountId) {
  return {
    type: actionTypes.SET_SELECTED_SMS_ACCOUNT_ID,
    selectedSmsAccountId
  }
}

export function updateSmsAccount (smsAccount) {
  return {
    type: actionTypes.UPDATE_SMS_ACCOUNT,
    smsAccount
  }
}

// Utility
function setPageDetails (pageDetails) {
  return {
    type: actionTypes.SET_PAGE_DETAILS,
    pageDetails
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}
