/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export class DealRow extends Component {
  rowSelected = () => {
    const { deal, onSelect } = this.props;
    onSelect(deal);
  };

  render() {
    const { deal, selected } = this.props;
    const style = selected ? "selected" : "selectable";
    const dealStyle = selected ? "text-white" : "";
    return (
      <a onClick={this.rowSelected} className={"list-group-item " + style}>
        <span className={dealStyle}>{deal.title}</span>
      </a>
    );
  }
}

DealRow.propTypes = {
  deal: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default DealRow;
