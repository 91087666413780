import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import callScheduleSelect from '../modules/callScheduleSelect'
import editCallSchedule from '../modules/editCallSchedule'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  const selectedCallSchedule = callScheduleSelect.selectors.selectedCallSchedule(state)
  return {
    callSchedule: selectedCallSchedule,
    loading: editCallSchedule.selectors.getLoading(state),
    data: editCallSchedule.selectors.getData(state),
    errors:  editCallSchedule.selectors.getErrors(state),
    listUpdateProtocol: callScheduleSelect.listUpdateProtocol,
    selectedLeadSourcesIds: editCallSchedule.selectors.getLeadSourceIds(state),
    selectedLocationIds: editCallSchedule.selectors.getSelectedLocationIds(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateCallSchedule: editCallSchedule.actions.updateCallSchedule,
    prefilFormForCallSchedule: editCallSchedule.actions.prefilFormForCallSchedule,
    onInput: editCallSchedule.actions.onInput,
    deleteCallSchedule: editCallSchedule.actions.deleteCallSchedule,
    showModal: modal.actions.showModal,
    reset: editCallSchedule.actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(editCallSchedule.components.EditCallScheduleView)
