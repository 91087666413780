import { apiClient, buildIndexUrl } from 'utils/apiClient';
import { DELETE, GET, POST, PUT, request } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';
import modal from 'modules/Stores/Modal';
import moment from 'moment';
import * as leadActions from '../../actions';
import details from '../details';
import * as noteActions from '../notes/actions';
import * as appointmentHelpers from './helpers';

export function fetchAppointments(lead, pageNumber) {
  return async (dispatch) => {
    dispatch(fetching());
    const page = pageNumber ? pageNumber : 1;
    const url = buildIndexUrl(`leads/${lead.id}/appointments`, page);
    try {
      const response = await apiClient().get(url);
      dispatch(fetchSuccess(response.data.appointments));
      dispatch(setPageDetails(response.data.meta));
    } catch (e) {
      dispatch(fetchFailed(e.data));
    }
  };
}

export function setPageDetails(pageDetails) {
  return {
    type: actionTypes.SET_PAGE_DETAILS,
    pageDetails,
  };
}

function fetching() {
  return {
    type: actionTypes.FETCHING,
  };
}

function fetchSuccess(appointments) {
  return {
    type: actionTypes.FETCHING_SUCCESS,
    appointments,
  };
}

function fetchFailed(errors) {
  return {
    type: actionTypes.FETCHING_FAILURE,
    errors,
  };
}

export const toggleComplete = (appointment, lead, outcome, note, visitType) => {
  return (dispatch) => {
    const body = {
      appointment: {
        outcome: outcome,
        note: note,
        visit_type: visitType,
      },
    };
    dispatch(updating(appointment.id));

    function onFailure(errors) {
      dispatch(updatingFailed(errors, appointment.id));
    }

    function onSuccess(payload) {
      dispatch(completingSuccess(payload.appointment));
      notify.success('Appointment updated');
    }

    return request('leads/' + lead.id + '/appointments/' + appointment.id, PUT, body, onSuccess, onFailure);
  };
};

function completingSuccess(appointment) {
  return {
    type: actionTypes.UPDATING_SUCCESS,
    appointment,
  };
}

export const deleteAppointment = (appointment, lead, integrationName) => {
  return (dispatch) => {
    let formattedIntegrationName = '';
    if (integrationName === 'mindbody') formattedIntegrationName = 'MINDBODY';
    if (integrationName === 'glofox') formattedIntegrationName = 'Glofox';

    dispatch(updating(appointment.id));

    function onFailure(errors) {
      dispatch(updatingFailed(errors, appointment.id));
    }

    function onSuccess(payload) {
      if (payload.meta && payload.meta.syncing_class === true) {
        dispatch(setSyncing(appointment.id, true));
        notify.info(`Removing from ${formattedIntegrationName} class and deleting appointment`);
      } else {
        dispatch(deleted(appointment.id));
        notify.success('Appointment deleted');
      }
    }

    return request('leads/' + lead.id + '/appointments/' + appointment.id, DELETE, null, onSuccess, onFailure);
  };
};

function deleted(appointmentId) {
  return {
    type: actionTypes.DELETE,
    appointmentId,
  };
}

function updating(appointmentId) {
  return {
    type: actionTypes.UPDATING,
    appointmentId,
  };
}

function updatingFailed(errors, appointmentId) {
  return {
    type: actionTypes.UPDATING_FAILURE,
    errors,
    appointmentId,
  };
}

export const scheduleAppointment = (
  lead,
  dueDate,
  listUpdateProtocol,
  serviceClassId,
  serviceString,
  visitType,
  length,
  integrationName
) => {
  return (dispatch) => {
    let formattedIntegrationName = '';
    if (integrationName === 'mindbody') formattedIntegrationName = 'MINDBODY';
    if (integrationName === 'glofox') formattedIntegrationName = 'Glofox';

    const body = {
      appointment: {
        due_date: dueDate,
        service_class_id: serviceClassId,
        service_class_detail: serviceString,
        visit_type: visitType,
        length: length,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(add(payload.appointment));
      if (listUpdateProtocol.cleanUp) {
        dispatch(listUpdateProtocol.cleanUp(lead));
      }
      notify.success('Appointment scheduled');
      if (payload.meta && payload.meta.syncing_class === true) {
        dispatch(setSyncing(payload.appointment.id, true));
        notify.info(`Booking into ${formattedIntegrationName} class`);
      }
      dispatch(details.actions.setSalesFunnelStep(payload.appointment.lead.sales_funnel_step));
    }

    return request('leads/' + lead.id + '/appointments', POST, body, onSuccess, onFailure);
  };
};

export const recordAppointment = (lead, outcome, note, visitType) => {
  return (dispatch) => {
    const now = moment().format();
    const body = {
      appointment: {
        due_date: now,
        outcome: outcome,
        note: note,
        visit_type: visitType,
      },
    };

    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(add(payload.appointment));
      notify.success('Appointment recorded');
      dispatch(details.actions.setSalesFunnelStep(payload.appointment.lead.sales_funnel_step));
    }

    return request('leads/' + lead.id + '/appointments', POST, body, onSuccess, onFailure);
  };
};

function add(appointment) {
  return {
    type: actionTypes.ADD,
    appointment,
  };
}

// AFTER actions
export function moveToSecondStep(lead, salesFunnel, listUpdateProtocol) {
  return (dispatch) => {
    const currentStep = lead.sales_funnel_step;
    const nextStep = getCalledStep(lead, salesFunnel);

    if (lead.sales_funnel_step && lead.sales_funnel_step.step_number > 1) {
      return;
    }
    dispatch(leadActions.moveLeadToStep(lead, currentStep, nextStep, listUpdateProtocol));
  };
}

function getCalledStep(lead, salesFunnel) {
  const salesFunnelSteps = salesFunnel.steps;
  return salesFunnelSteps[1];
}

export function handleAfterActionForOutcome(outcome, lead, salesFunnel, listUpdateProtocol) {
  return (dispatch) => {
    switch (outcome) {
      case appointmentHelpers.OTHER:
        if (listUpdateProtocol.cleanUp) {
          dispatch(listUpdateProtocol.cleanUp(lead));
        }
        break;
      default:
        break;
    }
  };
}

export function closeLead(lead, salesFunnel, listUpdateProtocol) {
  return (dispatch) => {
    const currentStep = lead.sales_funnel_step;

    const detail = 'Showed them the club and they want to join.';
    dispatch(noteActions.createNoteForLead(lead, detail));
    dispatch(leadActions.updateLeadToStatus(lead, 'closed', currentStep, null, listUpdateProtocol));
  };
}

export function handleAsyncAppointmentUpdate(body, type) {
  return (dispatch) => {
    let integration_name;
    if (type === 'mbo_appointment') integration_name = 'MINDBODY';
    if (type === 'glofox_appointment') integration_name = 'Glofox';
    if (type === 'journey_appointment') integration_name = 'Journey';

    let success = true;
    // for now, only handle error responses for glofox
    if (type === 'glofox_appointment') success = body.success;

    let message;
    if (body.action === 'added' && !success) {
      const errorMessage = body.errors && body.errors.length > 0 ? body.errors[0] : '';
      message = `Booking into ${integration_name} class failed. ${errorMessage}`;
    }

    if (body.action === 'deleted' && !success) {
      const errorMessage = body.errors && body.errors.length > 0 ? body.errors[0] : '';
      message = `Removing lead from ${integration_name} class failed. ${errorMessage}`;
    }

    switch (body.action) {
      case 'added':
        dispatch(setSyncing(body.id, false));

        success && notify.success(`Booked into ${integration_name} class successfully`);

        !success && notify.error(message);
        break;
      case 'deleted':
        dispatch(deleted(body.id));

        success && notify.success(`Removed from ${integration_name} class and deleted`);

        !success && notify.error(message);
        break;
      default:
        break;
    }
  };
}

function setSyncing(appointmentId, syncing) {
  return {
    type: actionTypes.SET_APPOINTMENT_SYNCING,
    appointmentId,
    syncing,
  };
}

export const syncIntegrationAppointments = (lead) => {
  return (dispatch) => {
    function onFailure(errors) {
      dispatch(fetchFailed(errors));
    }

    function onSuccess(payload) {
      notify.success('Syncing Appointments. New appointments will show up in a few moments');
    }

    return request('leads/' + lead.id + '/sync_with_integration', GET, null, onSuccess, onFailure);
  };
};
