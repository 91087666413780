import React from "react";
import styled from "styled-components";

export const toolTipSelector = calculating => {
  switch (calculating) {
    case "lead_created":
      return leadCreatedTooltips();
    case "event_occured":
      return eventOccuredTooltips();
    default:
      return eventOccuredTooltips();
  }
};

const leadCreatedTooltips = () => {
  const totalNewLeadsDescription = (
    <Container>
      <p>
        All leads <strong>created and assigned</strong> within your selected
        time period.
      </p>
    </Container>
  );

  const totalSalesDescription = (
    <Container>
      <p>
        For all leads that were created in the selected time period, that have
        had their <strong>status updated to 'Closed'</strong>.
      </p>
    </Container>
  );

  const closeRatioDescription = (
    <Container>
      <p>
        Close ratio is comparing all <strong>leads to total sales</strong>.
      </p>

      <p>*total sales/total new leads x100 = %</p>
    </Container>
  );

  const refferalsFromPOSDescription = (
    <Container>
      <p>
        For all leads <strong>created and assigned</strong> within your selected
        time period that have a{" "}
        <strong>source of 'Point of sale referral'</strong>.
      </p>
    </Container>
  );

  const refferalsPerSaleDescription = (
    <Container>
      <p>
        For all leads <strong>created and assigned</strong> within your selected
        time period that have a <strong>source of 'Sale referrall'</strong>.
      </p>
    </Container>
  );

  const tooltips = {
    totalNewLeadsDescription,
    totalSalesDescription,
    closeRatioDescription,
    refferalsFromPOSDescription,
    refferalsPerSaleDescription
  };
  return tooltips;
};

const eventOccuredTooltips = () => {
  const totalNewLeadsDescription = (
    <Container>
      <p>
        All leads <strong>created and assigned</strong> within your selected
        time period.
      </p>
    </Container>
  );

  const totalSalesDescription = (
    <Container>
      <p>
        All leads that have had their{" "}
        <strong>status updated to 'Closed'</strong> within your selected time
        period.{" "}
      </p>
    </Container>
  );

  const closeRatioDescription = (
    <Container>
      <p>
        Close ratio is comparing all <strong>leads to total sales.</strong>
      </p>

      <p>*total sales/total new leads x100 = %</p>
    </Container>
  );

  const refferalsFromPOSDescription = (
    <Container>
      <p>
        All leads <strong>created and assigned</strong> within your selected
        time period that has a source of{" "}
        <strong>'Point of sale referral'.</strong>
      </p>
    </Container>
  );

  const refferalsPerSaleDescription = (
    <Container>
      <p>
        All leads <strong>created and assigned</strong> within your selected
        time period that has a <strong>source of 'Sale referral'.</strong>
      </p>
    </Container>
  );

  const tooltips = {
    totalNewLeadsDescription,
    totalSalesDescription,
    closeRatioDescription,
    refferalsFromPOSDescription,
    refferalsPerSaleDescription
  };

  return tooltips;
};

const Container = styled.div``;
