import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import exportLead from '../modules/exportLead'
import modal from 'modules/Stores/Modal'
import userData from 'modules/Stores/UserData'

import * as leadActions from '../actions'

function mapStateToProps (state, props) {
  const lead = props.lead
  let shouldClose = false
  shouldClose = (lead.status === 'active' && lead.sales_funnel_step && lead.sales_funnel_step.step_number === 4)
  return {
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state),
    shouldClose: shouldClose
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    updateLeadToStatus: leadActions.updateLeadToStatus,
    reopenLead: leadActions.reopenLead
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(exportLead.components.ExportOptionView)
