import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers'

import { InfoView } from 'modules/components'

export class TextDetailsView extends Component {

  render () {
    const { pricingDetails, messageCost , productName} = this.props

    return (
      <div>
        <p>
          <span className='text-muted'>{pricingDetails.remainingCharsLength} characters remaining{' '}
            until it's a new message</span><br />
          {pricingDetails.totalCharsRemaining} total characters remaining<br />
          {pluralizationHelper('messages', pricingDetails.messageCount)} {' '} is ${parseFloat(pricingDetails.currentCost).toFixed(2)} in total,  {' '}
          each SMS is charged at ${parseFloat(messageCost).toFixed(2)}

        </p>
        {pricingDetails.emojiDetected &&
          <InfoView colorThemeClass='info'>
            <p>
              It looks like you're using emojis! <span role='img' aria-label='smile'>😀</span> <br />{' '}
              Emoijs are a great way to make your message stand out,{' '}
              but they do make the message more expensive because of the way carriers need to,{' '}
              enocde the message.{' '}See our {' '}
              <a style={{ fontWeight: 'bold' }}
                href='http://support.gymleads.net/how-it-works/communication/sending-an-sms'
                target='__blank'> support article</a> for more details
            </p>
          </InfoView>
        }
        <p className='text-muted'>
          *The above is an estimate only. Actual numbers may differ based on the actual{' '}
          value of merge fields.<br />
          {productName} also adds an opt out message and link at the end of every message to{' '}
          identify you as the sender and help them opt out if they wish.{' '}
          This is required by law in all countries {productName} operates in.
        </p>
      </div>
    )
  }
}

TextDetailsView.propTypes = {
  messageCost: PropTypes.number.isRequired,
  productName: PropTypes.string,
  pricingDetails: PropTypes.object
}

export default TextDetailsView
