import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";

import details from "../modules/details";

import userData from "modules/Stores/UserData";
import session from "modules/Stores/Session";

import AssignLead from "modules/components/AssignLead";
const { showModal, hideModal } = modal.actions;
const { updateLead } = details.actions;

function mapStateToProps(state, ownProps) {
  const lead = details.selectors.getLead(state);
  let formats = {};
  if (lead) {
    formats = userData.selectors.formatsForLocationId(lead.location_id)(state);
  }

  return {
    lead: lead,
    loading: state.modal.loading,
    detailsLoading: details.selectors.getLoading(state),
    currentModal: state.modal.currentModal,
    user: state.session.user,
    company: userData.selectors.getCompany(state),
    tagSuggestions: state.tags.suggestedOptions,
    timezone: session.selectors.getUserTimezone(state),
    formats: formats,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      updateLead,
      assignLeadToUser: AssignLead.actions.assignLeadToUser,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(details.components.LeadMoreDetailsView);
