import React, { Component } from 'react'

import HeaderContainer from '../modules/header/containers/HeaderContainer'
import FiltersContainer from '../modules/filters/containers/FiltersContainer'
import QuickInfoBlocksContainer from '../modules/quickInfoBlocks/containers/QuickInfoBlocksContainer'
import ActivityBreakdownContainer from '../modules/activityBreakdown/containers/ActivityBreakdownContainer'
import TableBreakdownContainer from '../modules/tableBreakdown/containers/TableBreakdownContainer'

class TimeTakenReportView extends Component {

  render () {
    return (
      <div className='content push-100'>
        <div className='row'>
          <div className='col-xs-12'>
            <HeaderContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <FiltersContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <QuickInfoBlocksContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <ActivityBreakdownContainer />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <TableBreakdownContainer />
          </div>
        </div>
      </div>
    )
  }
}

export default TimeTakenReportView

TimeTakenReportView.propTypes = {
}
