import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, PageSelection } from 'modules/components'

import ChargeItemRow from './ChargeItemRow'

export class ChargesView extends Component {

  UNSAFE_componentWillMount () {
    const { getTextMessageChargesForSmsAccountAndPage, smsAccount } = this.props
    getTextMessageChargesForSmsAccountAndPage(smsAccount)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.smsAccount.id !== this.props.smsAccount.id) {
      const { getTextMessageChargesForSmsAccountAndPage, smsAccount } = nextProps
      getTextMessageChargesForSmsAccountAndPage(smsAccount)
    }
  }

  pageClicked = (pageNumber) => {
    const { getTextMessageChargesForSmsAccountAndPage, smsAccount } = this.props
    getTextMessageChargesForSmsAccountAndPage(smsAccount, pageNumber)
  }

  render () {
    const { charges, loading, pageCount, currentPage, smsAccount } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''

    const rows = charges.map((charge, index) => {
      return <ChargeItemRow key={index} charge={charge} timezone={this.props.timezone} />
    })
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>SMS Charge History for {smsAccount.location_name}</h3>
          </div>
          <div className='block-content'>
            {charges.length > 0 &&
              <div>
                <div className='push'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Cost</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                  {pageCount > 1 &&
                    <PageSelection
                      pageCount={pageCount}
                      currentPage={currentPage}
                      pageClicked={this.pageClicked} />
                  }
                </div>
              </div>
            }
            {charges.length === 0 &&
              <EmptyView
                colorThemeClass={'primary'}
                iconClass={'fa fa-history'}
                title={'No Charges Yet'}
                subtitle={'SMS records will appear here'} />
            }
          </div>
        </div>
      </div>
    )
  }
}

ChargesView.propTypes = {
  smsAccount: PropTypes.object.isRequired,
  getTextMessageChargesForSmsAccountAndPage: PropTypes.func.isRequired,
  charges: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  errors: PropTypes.object
}

export default ChargesView
