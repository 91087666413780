import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_SENDING = NAME + '/SET_SENDING'
export const SET_UPDATING = NAME + '/SET_UPDATING'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const ADD_FACEBOOK_MESSAGE = NAME + '/ADD_FACEBOOK_MESSAGE'
export const SET_FACEBOOK_MESSAGES = NAME + '/SET_FACEBOOK_MESSAGES'
export const ADD_FACEBOOK_MESSAGES = NAME + '/ADD_FACEBOOK_MESSAGES'
export const SET_CHAT_ROOM = NAME + '/SET_CHAT_ROOM'
