import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validator'

const TRIAL_ACTION_HANDLERS = {
  [actionTypes.PREFIL_DATA_FOR_TRIAL]: (state, action) => {
    return initialStateForTrial(action.trial)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, actions) => {
    return { ...state, loading: actions.loading }
  },
  [actionTypes.SET_ERRORS]: (state, actions) => {
    return { ...state, errors: actions.errors }
  },
  [actionTypes.SET_SELECTED_TRIAL_ID]: (state, action) => {
    return { ...state, selectedTrialId: action.selectedTrialId }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialStateForTrial(null)
  }
}

const payloadKeys = {
  name: 'name',
  dayLength: 'day_length'
}

function initialStateForTrial (trial) {
  const fields = generateFieldsForKeys(trial, fieldNames, payloadKeys, validator)
  const trialId = (trial) ? trial.id : null
  return {
    loading: false,
    errors: null,
    selectedTrialId: trialId,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function reducer (state = initialStateForTrial(null), action) {
  const handler = TRIAL_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
