import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CommunicationPreferencesForm } from '../components/CommunicationPreferencesForm'

import session from 'modules/Stores/Session'

import { onInput, updateCommunicationPreferences } from '../actions'
import { getData } from '../selectors'

function mapStateToProps (state) {
  return {
    userRole: session.selectors.getUserRole(state),
    data: getData(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput,
    updateCommunicationPreferences
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferencesForm)
