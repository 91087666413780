import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SubscriptionAddOnView from '../components/SubscriptionAddOnView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    subscriptionAddOns: selectors.getSubscriptionAddOns(state),
    pageDetails: selectors.getPageDetails(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchSubscriptionAddOns: actions.fetchSubscriptionAddOns
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAddOnView)
