import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { store } from './store';

import { ProfileSelect } from './ProfileSelect';
import { EditProfile } from './EditProfile';

const Profiles: FC = observer(() => {
  const { selectedWaiverForm, creatingNew } = store.state;

  return (
    <>
      <ProfileSelect />
      {(selectedWaiverForm || creatingNew) && <EditProfile />}
    </>
  );
});

export default Profiles;
