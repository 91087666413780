import { connect } from 'react-redux'

import userData from 'modules/Stores/UserData'
import funnelCount from '../modules/funnelCount'
import countsLoader from '../modules/countsLoader'

function mapStateToProps (state) {
  const salesFunnelSteps = userData.selectors.getSalesFunnelSteps(state)
  const counts = countsLoader.selectors.getCounts(state)
  const dataSets = funnelCount.selectors.funnelStepCountsAsDataSet(counts, salesFunnelSteps)
  return {
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state),
    dataSets: dataSets,
    loading: countsLoader.selectors.getCountLoading(state),
    isEmpty: funnelCount.selectors.isEmpty(dataSets)
  }
}

export default connect(mapStateToProps)(funnelCount.components.FunnelCountView)
