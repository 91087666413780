import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TrialDetailView from '../components/TrialDetailView'

import trials from 'modules/Stores/Trials'
import modal from 'modules/Stores/Modal'

import * as localSelectors from '../selectors'
import * as localActions from '../actions'

function mapStateToProps (state) {
  return {
    selectedTrial: localSelectors.selectedTrial(state),
    loading: trials.selectors.getLoading(state),
    data: localSelectors.getData(state),
    errors: trials.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: localActions.updateData,
    deleteTrial: trials.actions.deleteTrial,
    updateTrial: trials.actions.updateTrial,
    prefillDataForTrial: localActions.prefillDataForTrial,
    showModal: modal.actions.showModal
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TrialDetailView)
