import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectLocationView from '../components/SelectLocationView'
import { getTabIndex } from '../../../selectors'

function mapStateToProps (state) {
  const activeTab = getTabIndex(state)
  return {
    isActive: (activeTab === 0)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationView)
