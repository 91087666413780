import * as actionTypes from './actionTypes'

const SELECT_CSV_ACTION_HANDLERS = {
  [actionTypes.SET_POTENTIAL_USERS]: (state, action) => {
    return { ...state, potentialUsers: action.potentialUsers }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_STATUSES]: (state, action) => {
    return { ...state, statuses: action.statuses }
  },
  [actionTypes.SET_SALESPEOPLE]: (state, action) => {
    return { ...state, salespeople: action.salespeople }
  },
  [actionTypes.SET_LAWFUL_PROCESSING_BASES]: (state, action) => {
    return { ...state, lawfulProcessingBases: action.lawfulProcessingBases }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.ADD_SALESPERSON_MAP]: (state, action) => {
    const newSalespeopleMaps = [
      ...state.salespeopleMaps,
      action.salespersonMap
    ]
    return { ...state, salespeopleMaps: newSalespeopleMaps }
  },
  [actionTypes.ADD_STATUS_MAP]: (state, action) => {
    const newStatusMaps = [
      ...state.statusMaps,
      action.statusMap
    ]
    return { ...state, statusMaps: newStatusMaps }
  },
  [actionTypes.ADD_LAWFUL_PROCESSING_BASES_MAP]: (state, action) => {
    const newLawfulProcessingBasesMaps = [
      ...state.lawfulProcessingBasesMaps,
      action.lawfulProcessingBasesMap
    ]
    return { ...state, lawfulProcessingBasesMaps: newLawfulProcessingBasesMaps }
  },
  [actionTypes.UPDATE_SALESPERSON_MAP]: (state, action) => {
    const index = state.salespeopleMaps.findIndex(map => map.old === action.salespersonMap.old)
    const newSalespeople = [
      ...state.salespeopleMaps.slice(0, index),
      action.salespersonMap,
      ...state.salespeopleMaps.slice(index + 1)
    ]
    return { ...state, salespeopleMaps: newSalespeople }
  },
  [actionTypes.UPDATE_STATUS_MAP]: (state, action) => {
    const index = state.statusMaps.findIndex(map => map.old === action.statusMap.old)
    const newStatusMaps = [
      ...state.statusMaps.slice(0, index),
      action.statusMap,
      ...state.statusMaps.slice(index + 1)
    ]
    return { ...state, statusMaps: newStatusMaps }
  },
  [actionTypes.UPDATE_LAWFUL_PROCESSING_BASES_MAP]: (state, action) => {
    const index = state.lawfulProcessingBasisMaps.findIndex(map => map.old === action.lawfulProcessingBasisMap.old)
    const newLawfulProcessingBasisMaps = [
      ...state.lawfulProcessingBasisMaps.slice(0, index),
      action.lawfulProcessingBasisMap,
      ...state.lawfulProcessingBasisMaps.slice(index + 1)
    ]
    return { ...state, lawfulProcessingBasesMaps: newLawfulProcessingBasisMaps }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    loading: false,
    potentialUsers: [],
    statuses: [],
    salespeople: [],
    lawfulProcessingBases: [],
    statusMaps: [],
    salespeopleMaps: [],
    lawfulProcessingBasesMaps: []
  }
}

export default function reducer (state = initialState(), action) {
  const handler = SELECT_CSV_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
