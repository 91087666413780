import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FormView from '../components/Confirm/FormView'
import session from 'modules/Stores/Session'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state, props) {
  return {
    data: selectors.getData(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setPermissionForSet: actions.setPermissionForSet,
    authenticateIntegration: actions.authenticateIntegration
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
