import * as actionTypes from './actionTypes';

const SESSION_ACTION_HANDLERS = {
  [actionTypes.RESET]: () => {
    return initialState;
  },
  [actionTypes.SESSION_CREATE]: (state, action) => {
    return { ...state, user: action.user, isMobile: action.isMobile };
  },
  [actionTypes.SESSION_END]: (state, action) => {
    return { ...state, user: null, sessionEndReason: action.sessionEndReason };
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_SESSION_EXPIRY]: (state, action) => {
    return { ...state, sessionExpiry: action.sessionExpiry };
  },
  [actionTypes.SET_SESSION_END_REASON]: (state, action) => {
    return { ...state, sessionEndReason: action.sessionEndReason };
  },
  [actionTypes.ATTEMPED_URL]: (state, action) => {
    return { ...state, attemptedUrl: action.attemptedUrl };
  },
  [actionTypes.SET_APP_VERSION]: (state, action) => {
    return { ...state, currentAppVersion: action.appVersion };
  },
  [actionTypes.SET_INTERCOM_HASH]: (state, action) => {
    return { ...state, intercomHash: action.intercomHash };
  },
  [actionTypes.UPDATE_USER]: (state, action) => {
    return { ...state, user: action.user };
  },
  [actionTypes.SWITCHING_APP_NAME]: (state, action) => {
    return { ...state, switchingAppName: action.switchingAppName };
  },
  [actionTypes.SET_REALTIME_AUTH]: (state, action) => {
    return { ...state, realtimeAuth: action.realtimeAuth };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  user: null,
  switchingAppName: null,
  loading: false,
  isMobile: false,
  realtimeAuth: null,
  sessionExpiry: null,
  isRefreshingSession: false,
  sessionEndReason: null,
  attemptedUrl: null,
  currentAppVersion: null,
  intercomHash: null,
};

export default function reducer(state = initialState, action) {
  const handler = SESSION_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
