import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DaySelect from "../components/DaySelect";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DaySelect);
