import { request, GET, POST, PUT, DELETE } from 'utils/apiUtils';
import modal from 'modules/Stores/Modal';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export const fetchCallScheduleSteps = (callScheduleId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setCallScheduleSteps(payload.call_schedule_steps));
    }

    const url = 'call_schedules/' + callScheduleId + '/call_schedule_steps';
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const createCallScheduleStep = (callScheduleId, secondsToWait, emailTemplateId, smsTemplateId, afterSuccess) => {
  return (dispatch) => {
    const body = {
      call_schedule_step: {
        seconds_until_next_call: secondsToWait,
        email_template_id: emailTemplateId,
        text_message_template_id: smsTemplateId,
      },
    };

    dispatch(modal.actions.startLoading());

    function onFailure(payload) {
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      notify.success('Call schedule step created');
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(addCallScheduleStep(payload.call_schedule_step));
      afterSuccess();
    }

    const url = 'call_schedules/' + callScheduleId + '/call_schedule_steps';
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const updateCallScheduleStep = (callScheduleId, callScheduleStepId, secondsToWait, emailTemplateId, smsTemplateId, afterSuccess) => {
  return (dispatch) => {
    const body = {
      call_schedule_step: {
        seconds_until_next_call: secondsToWait,
        email_template_id: emailTemplateId,
        text_message_template_id: smsTemplateId,
      },
    };

    dispatch(modal.actions.startLoading());

    function onFailure(payload) {
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      notify.success('Call schedule step created');
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(updateCallScheduleStepInReducer(payload.call_schedule_step));
      afterSuccess();
    }

    const url = 'call_schedules/' + callScheduleId + '/call_schedule_steps/' + callScheduleStepId;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

export const deleteCallScheduleStep = (callScheduleId, callScheduleStepId, afterSuccess) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(payload) {
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      notify.success('Call schedule step removed');
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(setCallScheduleSteps(payload.call_schedule_steps));
      afterSuccess();
    }

    const url = 'call_schedules/' + callScheduleId + '/call_schedule_steps/' + callScheduleStepId;
    return request(url, DELETE, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setCallScheduleSteps(steps) {
  return {
    type: actionTypes.SET_STEPS,
    steps,
  };
}

function addCallScheduleStep(step) {
  return {
    type: actionTypes.ADD_STEP,
    step,
  };
}

export function updateCallScheduleStepInReducer(step) {
  return {
    type: actionTypes.UPDATE_STEP,
    step,
  };
}

export function selectCallScheduleStepId(stepId) {
  return {
    type: actionTypes.SELECT_STEP_ID,
    stepId,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
