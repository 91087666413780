import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export const getAllOptions = () => {
  return dispatch => {
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      dispatch(allOptionsLoaded(payload));
    }

    return request("integration_list", GET, null, onSuccess, onFailure);
  };
};

function allOptionsLoaded(options) {
  return {
    type: actionTypes.INTEGRATIONS_LIST_LOADED,
    options
  };
}

export const getIntegrationsForLocation = location => {
  return dispatch => {
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      dispatch(configuredIntergrationsLoaded(payload.third_party_integrations));
    }

    return request(
      "locations/" + location.id + "/third_party_integrations",
      GET,
      null,
      onSuccess,
      onFailure
    );
  };
};

export const setSelectedOption = selectedOption => {
  return {
    type: actionTypes.SET_SELECTED_OPTION,
    selectedOption
  };
};

function configuredIntergrationsLoaded(configuredIntegrations) {
  return {
    type: actionTypes.CONFIGURED_INTEGRATIONS_LOADED,
    configuredIntegrations
  };
}

function startLoading() {
  return {
    type: actionTypes.LOADING
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors
  };
}

function loadSuccessful() {
  return {
    type: actionTypes.LOAD_SUCCESS
  };
}

export function addIntegration(integration) {
  return {
    type: actionTypes.ADD_INTEGRATION,
    integration
  };
}

export function updateIntegration(integration) {
  return {
    type: actionTypes.UPDATE_INTEGRATION,
    integration
  };
}

export function deleteIntegration(integrationId) {
  return {
    type: actionTypes.DELETE_INTEGRATION,
    integrationId
  };
}
