import { request, POST, PUT, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import userData from 'modules/Stores/UserData';
import modal from 'modules/Stores/Modal';

import notify from 'modules/Notifications';

export const updateSchedule = (user, location, scheduleDates, scheduleId) => {
  return (dispatch) => {
    const body = {
      user_location_schedule: {
        schedule_dates: scheduleDates,
        user_id: user.id,
        location_id: location.id,
      },
    };
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
      dispatch(setErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      if (scheduleId) {
        dispatch(userData.actions.updateSchedule(payload.user_location_schedule));
      } else {
        dispatch(userData.actions.addSchedule(payload.user_location_schedule));
      }
      notify.success('Schedule updated');
      dispatch(modal.actions.hideModal());
    }

    let endpoint = 'user_location_schedules';

    if (scheduleId) {
      let endpoint = 'user_location_schedules/' + scheduleId;
      return request(endpoint, PUT, body, onSuccess, onFailure);
    } else {
      return request(endpoint, POST, body, onSuccess, onFailure);
    }
  };
};

export const deleteSchedule = (scheduleId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
      dispatch(setErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(userData.actions.removeScheduleWithId(scheduleId));
      notify.success('Schedule deleted');
      dispatch(modal.actions.hideModal());
    }

    let endpoint = 'user_location_schedules/' + scheduleId;
    return request(endpoint, DELETE, null, onSuccess, onFailure);
  };
};

export function setScheduleSlots(scheduleSlots) {
  return {
    type: actionTypes.SET_SCHEDULE_SLOTS,
    scheduleSlots,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

export function addSlotToSchedule(slot) {
  return {
    type: actionTypes.ADD_SLOT_TO_SCHEDULE,
    slot,
  };
}

export function removeSlotFromSchedule(slot) {
  return {
    type: actionTypes.REMOVE_SLOT_FROM_SCHEDULE,
    slot,
  };
}
