import * as actionTypes from './actionTypes'

const LEADS_REPORT_COUNT_LOADER_ACTION_HANDLERS = {
  [actionTypes.FETCHING_COUNTS]: (state, action) => {
    return { ...state, countLoading: true }
  },
  [actionTypes.FETCH_COUNTS_SUCCESS]: (state, action) => {
    return { ...state, counts: action.counts, countLoading: false }
  }
}

const initialState = {
  countLoading: false,
  counts: {}
}

export default function reducer (state = initialState, action) {
  const handler = LEADS_REPORT_COUNT_LOADER_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
