import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormFields, ToolTip } from "modules/components";
import styled from "styled-components";

const { Switch } = FormFields;

const ToggleGroupHeadline = styled.h5`
  margin-bottom: 15px;
`;

export class IntegrationOptions extends Component {
  handleToggle = (fieldName, value) => {
    const { data, setPermissionForSet, permissionSet, location } = this.props;
    const integrationId = data.fields.id.value;

    setPermissionForSet(
      location.id,
      integrationId,
      permissionSet.id,
      fieldName,
      value
    );
  };

  render() {
    const permissionSet = this.props.permissionSet;

    const exportTooltipText = "Export a closed lead to Glofox as a new member";
    const exportLabel = (
      <span>
        Export Lead <ToolTip text={exportTooltipText} id="exportLead" />
      </span>
    );

    const syncLeadTooltipText =
      "Keep a lead synced with Glofox as a member throughout the whole lifecycle";
    const syncLeadLabel = (
      <span>
        Sync Lead <ToolTip text={syncLeadTooltipText} id="syncLead" />
      </span>
    );

    const appointmentsTooltipText =
      "When scheduling a new appointment, choose from a scheduled class in Glofox";
    const appointmentsLabel = (
      <span>
        Appointments from class timetable{" "}
        <ToolTip text={appointmentsTooltipText} id="appointments" />
      </span>
    );

    const classesRequireBookingText =
      "Tick if your club requires a booking for class attendance";
    const classesRequireBookingLabel = (
      <span>
        Class attendance requires a booking{" "}
        <ToolTip text={classesRequireBookingText} id="requireBooking" />
      </span>
    );

    const permissionSetValue = permissionSet
      ? permissionSet.export_lead
      : false;
    const syncLeadValue = permissionSet ? permissionSet.sync_lead : false;
    const appointmentsFromClassesValue = permissionSet
      ? permissionSet.appointments_from_classes
      : false;
    const classesRequireBookingValue = permissionSet
      ? permissionSet.classes_require_booking
      : false;

    return (
      <div className="row">
        <div className="col-md-12">
          <ToggleGroupHeadline>Integration permissions:</ToggleGroupHeadline>
          <Switch
            name="export_lead"
            label={exportLabel}
            onChange={this.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={permissionSetValue}
          />
          <Switch
            name="sync_lead"
            label={syncLeadLabel}
            onChange={this.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={syncLeadValue}
          />
          <Switch
            name="appointments_from_classes"
            label={appointmentsLabel}
            onChange={this.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={appointmentsFromClassesValue}
          />
          {appointmentsFromClassesValue === true && (
            <Switch
              name="classes_require_booking"
              label={classesRequireBookingLabel}
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={classesRequireBookingValue}
            />
          )}
        </div>
      </div>
    );
  }
}

IntegrationOptions.propTypes = {
  data: PropTypes.object.isRequired,
  setPermissionForSet: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  permissionSet: PropTypes.object
};

export default IntegrationOptions;
