import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SalespersonDashboardView } from '../../components/SalespersonDashboardView';

import dateRangeSelect from '../../modules/DateRangeSelect';
import userData from 'modules/Stores/UserData';
import locations from 'modules/Stores/Locations';
import locationSelect from '../../modules/LocationSelect';
import session from 'modules/Stores/Session';
import modal from 'modules/Stores/Modal';

const mapStateToProps = (state) => ({
  user: state.session.user,
  location: locationSelect.selectors.getSelectedLocation(state),
  company: userData.selectors.getCompany(state),
  permissions: userData.selectors.getMembershipPermissions(state),
  selectedDateType: state.dashboard.DateRange.selectedType,
  isMultiLocationAccount: locations.selectors.getIsMultiLocationAccount(state),
  timezone: session.selectors.getUserTimezone(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectDateRange: dateRangeSelect.actions.select,
      clearCurrentLocation: locationSelect.actions.clearLocation,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SalespersonDashboardView);
