import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CustomTodoModal from '../components/NewTodoModal/CustomTodoModal'
import { createTodoForLead } from '../actions'

import modal from 'modules/Stores/Modal'
const { showModal, hideModal } = modal.actions

function mapStateToProps (state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal,
    hideModal,
    createTodoForLead
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTodoModal)
