import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { space } from 'styleguide';

import { ContentBlockView, GymLeadsLoader, PagesView, TR, EmptyView, Box } from 'modules/components';
import { IWaiverForm, IPaginationMeta } from 'types';
import { fetchWaiverForms, deleteWaiverForm } from 'modules/api/waiverForms';
import notify from 'modules/Notifications';

import { store } from './store';

export const ProfileSelect: FC = observer(() => {
  const [loading, setLoading] = useState(false);
  const [pageMeta, setPageMeta] = useState<IPaginationMeta>({ current_page: 1, total_pages: 0, total_count: 0 });

  const { selectedWaiverFormId, waiverForms } = store.state;
  const { setSelectedWaiverFormId, setWaiverForms, removeWaiverFormsListItem } = store.actions;

  useEffect(() => {
    fetchWaiverFormsForPage(1);
    // eslint-disable-next-line
  }, []);

  const handleRowClick = (waiverForm: IWaiverForm) => {
    setSelectedWaiverFormId(waiverForm.id);
    store.actions.setCreatingNew(false);
  };

  const fetchWaiverFormsForPage = async (pageNumber: number) => {
    setLoading(true);
    const response = await fetchWaiverForms(pageNumber);
    setWaiverForms(response.waiver_forms);
    setPageMeta(response.meta);
    setLoading(false);
  };

  const deleteProfile = async (waiverFormId: number) => {
    setLoading(true);
    await deleteWaiverForm(waiverFormId);
    removeWaiverFormsListItem(waiverFormId);
    notify.warning('Profile deleted');
    setLoading(false);
  };

  const handleCreateNewClicked = () => {
    store.actions.setSelectedWaiverFormId(undefined);
    store.actions.setCreatingNew(true);
  };

  const isEmpty = waiverForms.length === 0;

  return (
    <ContentBlockView
      title="Select a profile"
      bordered
      loading={loading}
      blockOptions={
        <button type="button" className="pull-right btn btn-xs btn-success" onClick={handleCreateNewClicked}>
          <i className="fa fa-plus" /> Create new
        </button>
      }
    >
      {isEmpty && (
        <Box mb={space.M}>
          <EmptyView
            colorThemeClass="primary"
            iconClass="fa fa-wpforms"
            title="No Front Desk Profiles"
            subtitle="Front desk profiles forms will show here"
            buttonText="Create a new front desk profile"
            buttonSelected={handleCreateNewClicked}
          />
        </Box>
      )}
      {!isEmpty && (
        <>
          <GymLeadsLoader active={loading} />
          <table className="table table-vcenter table-hover">
            <thead>
              <tr>
                <th>Profile name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {waiverForms.map((waiverForm) => {
                const selected = waiverForm.id === selectedWaiverFormId;
                return (
                  <TR key={`profile-${waiverForm.id}`} selectable selected={selected}>
                    <td onClick={() => handleRowClick(waiverForm)}>{waiverForm.name}</td>
                    <td>
                      <button type="button" className="btn btn-xs btn-danger" onClick={() => deleteProfile(waiverForm.id)}>
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </TR>
                );
              })}
            </tbody>
          </table>
          <PagesView pageDetails={pageMeta} pageClicked={fetchWaiverFormsForPage} />
        </>
      )}
    </ContentBlockView>
  );
});
