import * as actionTypes from './actionTypes';

const AUTOMATIC_REPORT_VIEW_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.UPDATE_FILTER_DATA]: (state, action) => {
    return { ...state, data: action.filterData };
  },
  [actionTypes.SET_FILTER_DATA]: (state, action) => {
    const report = action.automaticReport;
    if (report) {
      return {
        ...state,
        data: report.data,
        compareOption: report.compareOption,
        calculatingOption: report.calculatingOption,
      };
    }
  },
  [actionTypes.SET_COMPARE_VALUE]: (state, action) => {
    return { ...state, compareOption: action.compareOption };
  },
  [actionTypes.SET_CALCULATING_VALUE]: (state, action) => {
    return { ...state, calculatingOption: action.calculatingOption };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState;
  },
};

const initialState = {
  loading: false,
  compareOption: null,
  calculatingOption: null,
  data: null,
};

export default function reducer(state = initialState, action) {
  const handler = AUTOMATIC_REPORT_VIEW_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
