import PropTypes from "prop-types";
import React, { Component } from "react";

import { CONFIGURE_INTEGRATION_VIEW } from "./FormView";
import LeadGoalSelectContainer from "modules/components/LeadGoalSelect/containers/LeadGoalSelectContainer";

export class FormSelectLeadGoalView extends Component {
  handleGoalChange = value => {
    const { linkedFacebookPage, updateFacebookPageIntegration } = this.props;
    const body = { lead_goal_id: value.id };
    updateFacebookPageIntegration(linkedFacebookPage.id, body);
    this.props.setCurrentView(CONFIGURE_INTEGRATION_VIEW, null);
  };

  render() {
    const { linkedFacebookPage } = this.props;
    const selectedLeadGoalId = linkedFacebookPage.lead_goal
      ? linkedFacebookPage.lead_goal.id
      : null;
    return (
      <div>
        <LeadGoalSelectContainer
          selectedLeadGoalId={selectedLeadGoalId}
          onLeadGoalSelect={this.handleGoalChange}
        />
      </div>
    );
  }
}

FormSelectLeadGoalView.propTypes = {
  integration: PropTypes.object.isRequired,
  updateFacebookPageIntegration: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
};

export default FormSelectLeadGoalView;
