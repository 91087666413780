import React from "react";
import MultiDealSelect from "modules/components/MultiDealSelect";

const DealsSection = ({
  SELECT_DEALS_MODAL,
  clubsFiltersClass,
  dealIdsName,
  dealIdsData,
  showModal,
  clubTitle,
  onUpdate
}) => {
  function showSelectDealsModal() {
    showModal(SELECT_DEALS_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${clubsFiltersClass}`}
        type="button"
        onClick={showSelectDealsModal}
      >
        {clubTitle}
      </div>
      <MultiDealSelect.MultiDealSelectModalContainer
        modalName={SELECT_DEALS_MODAL}
        sectionName={dealIdsName}
        onUpdate={onUpdate}
        data={dealIdsData}
      />
    </div>
  );
};

export default DealsSection;
