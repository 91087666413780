import PropTypes from 'prop-types'
import React, { Component } from 'react'
import RawToolTip from './RawToolTip'

export class ToolTip extends Component {

  render () {
    const { id } = this.props
    return (
      <span>
        <i className='fa fa-info-circle selectable' data-tip data-for={id} />
        <RawToolTip {...this.props} />
      </span>
    )
  }
}

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  place: PropTypes.string, // [Top, Bottom, Left, Right]
  effect: PropTypes.string, // [Float, Solid]
  type: PropTypes.string // [Dark, Success, Warning, Error, Info, Light]
}

export default ToolTip
