import React from 'react';
import { hot } from 'react-hot-loader/root';
import { PrivateRouteContainer, HeaderContainer } from 'modules/components';
import MenuContainer from '../containers/MenuContainer';
import './CoreLayout.css';
import 'styles/core.css';
import 'styles/oneui.css';
import 'styles/cells.css';
import IntercomWrapper from './IntercomWrapper';

import { Switch, Route } from 'react-router-dom';
import Login from 'containers/Login';
import Articles from 'containers/Articles';
import EditArticle from 'containers/EditArticle';
import ResetPassword from 'containers/ResetPassword';
import ForgotPassword from 'containers/ForgotPassword';
import ConfirmEmail from 'containers/ConfirmEmail';
import Redeem from 'containers/Redeem';
import NotFound from 'containers/NotFound';
import Sso from 'containers/Sso';
import Secure from 'containers/Secure';

const AppView = (props) => {
  const { user, shouldShowMenu = true } = props;

  return (
    <div id="page-container" className="header-navbar-fixed">
      {user && <HeaderContainer />}
      <main id="main-container" style={{ minHeight: '921px' }}>
        {user && shouldShowMenu && <MenuContainer />}
        <Switch>
          <Route exact path="/" component={Login.LoginContainer} />
          <Route exact path="/login" component={Login.LoginContainer} />
          <Route exact path={'/' + Articles.constants.NAME} component={Articles.ArticlesContainer} />
          <Route exact path={'/' + EditArticle.constants.NAME} component={EditArticle.EditArticleContainer} />
          <Route exact path={'/sso'} component={Sso.SsoContainer} />
          <Route exact path={'/reset_password'} component={ResetPassword.ResetPasswordContainer} />
          <Route exact path={'/forgotPassword'} component={ForgotPassword.ForgotPasswordContanier} />
          <Route exact path={'/confirm_email'} component={ConfirmEmail.ConfirmEmailContainer} />
          <Route exact path={'/redeem'} component={Redeem.RedeemContainer} />
          <PrivateRouteContainer path="/secure" component={Secure.SecureContainer} />
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </main>
      <IntercomWrapper />
    </div>
  );
};

export const App = hot(AppView);
