import PropTypes from "prop-types";
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

const NONE = "No filter";
const NO_CALLS = "No Calls";
const NO_APPOINTMENTS = "No appointments";
const NO_CALLS_AND_APPOINTMENTS = "No calls and appointments";

export class MissingActionsSection extends Component {
  state = {
    showingOptions: false,
    options: [NONE, NO_CALLS, NO_APPOINTMENTS, NO_CALLS_AND_APPOINTMENTS],
  };

  indexClicked = (index) => {
    const option = this.state.options[index];
    const fields = {};
    switch (option) {
      case NONE:
        break;
      case NO_CALLS:
        fields["contact_calls"] = { value: true, isValid: true };
        break;
      case NO_APPOINTMENTS:
        fields["appointments"] = { value: true, isValid: true };
        break;
      case NO_CALLS_AND_APPOINTMENTS:
        fields["contact_calls"] = { value: true, isValid: true };
        fields["appointments"] = { value: true, isValid: true };
        break;
      default:
        break;
    }

    const data = { fields: fields };
    this.props.onUpdate(data, this.props.sectionName);
    this.setState({ ...this.state, showingOptions: false });
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  currentSelection = () => {
    const { data } = this.props;
    const keys = Object.keys(data.fields);
    if (keys.length === 0) {
      return NONE;
    }
    if (keys.length === 1 && keys.includes("contact_calls")) {
      return NO_CALLS;
    }
    if (keys.length === 1 && keys.includes("appointments")) {
      return NO_APPOINTMENTS;
    }

    return NO_CALLS_AND_APPOINTMENTS;
  };

  render() {
    const options = this.state.options.map((option, index) => {
      const className =
        index === this.state.selectedIndex ? "selected" : "selectable";
      return (
        <li key={index} className={className}>
          <div className="DropDown" onClick={() => this.indexClicked(index)}>
            {option}
          </div>
        </li>
      );
    });
    let selectedOption = this.currentSelection();
    const btnColorStyle = selectedOption === NONE ? "btn-default" : "btn-info";
    return (
      <div>
        <button
          className={"btn " + btnColorStyle + " push-5-r push-10"}
          type="button"
          onClick={this.toggleDropdown}
        >
          {selectedOption} <i className="fa fa-caret-down" />
        </button>
        {this.state.showingOptions && (
          <ul className="dropdown-menu" style={{ display: "block" }}>
            {options}
          </ul>
        )}
      </div>
    );
  }
}

MissingActionsSection.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default onClickOutside(MissingActionsSection);
