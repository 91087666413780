import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class LawfulProcessingBasisRow extends Component {

  lawfulProcessingBasisClicked = (event) => {
    const { lawfulProcessingBasis, onSelect } = this.props
    onSelect(lawfulProcessingBasis.id)
  }

  render () {
    const { lawfulProcessingBasis, selected } = this.props

    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''

    return (
      <tr className={textClass + ' selectable ' + backgroundClass} onClick={this.lawfulProcessingBasisClicked}>
        <td className='font-w600'>
          {lawfulProcessingBasis.title}
        </td>
        <td className='font-w600'>
          {lawfulProcessingBasis.new_default &&
            'Yes'
          }
        </td>
        <td className='font-w600'>
          {lawfulProcessingBasis.closed_lead_default &&
            'Yes'
          }
        </td>
      </tr>
    )
  }
}

LawfulProcessingBasisRow.propTypes = {
  lawfulProcessingBasis: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default LawfulProcessingBasisRow
