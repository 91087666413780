import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MoveLeadModal from "../components/MoveLeadModal/MoveLeadModal";
import { moveLeadToStep } from "../actions";

import modal from "modules/Stores/Modal";

import userData from "modules/Stores/UserData";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      moveLeadToStep,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveLeadModal);
