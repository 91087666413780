import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SelectLocationTableRow extends Component {

  rowSelected = (event) => {
    const { location, onLocationSelect } = this.props
    onLocationSelect(location)
  }

  render () {
    const { location, locationSelected } = this.props
    const className = (locationSelected) ? 'selected' : 'selectable'
    return (
      <tr className={className} onClick={() => this.rowSelected(location)}>
        <td>{location.admin_display_name}</td>
        <td className='text-center'>
          {location.zip_postcode}
        </td>
      </tr>
    )
  }
}

SelectLocationTableRow.propTypes = {
  location: PropTypes.object.isRequired,
  locationSelected: PropTypes.bool.isRequired,
  onLocationSelect: PropTypes.func.isRequired
}
