import * as actionTypes from './actionTypes';

const CONFIGURE_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_IMPORT_OPTIONS_VALUE]: (state, action) => {
    return { ...state, importOptions: { ...state.importOptions, [action.fieldName]: action.value } };
  },
  [actionTypes.SET_NUMBER_OF_RECORDS]: (state, action) => {
    return { ...state, numberOfRecords: action.numberOfRecords };
  },
  [actionTypes.SET_LEADS]: (state, action) => {
    return { ...state, leads: action.leads };
  },
  [actionTypes.SET_HEADERS]: (state, action) => {
    return { ...state, headers: action.headers };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.REPLACE_HEADER_VALUE]: (state, action) => {
    const oldValue = action.oldValue;
    const newValue = action.newValue;
    const index = state.headers.findIndex((header) => header === oldValue);
    const newHeaders = [...state.headers.slice(0, index), newValue, ...state.headers.slice(index + 1)];
    return { ...state, headers: newHeaders };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState();
  },
};

function initialState() {
  return {
    headers: [],
    leads: [],
    errors: null,
    progress: null,
    numberOfRecords: 0,
    loading: false,
    selectedFields: [],
    importOptions: {
      updateLeads: false,
    },
  };
}

export default function reducer(state = initialState(), action) {
  const handler = CONFIGURE_LEADS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
