import * as ACTIONS from './actionTypes'

const EXPORT_LEAD_ACTION_HANDLERS = {
  [ACTIONS.SET_FORM_POST_DATA]: (state, action) => {
    return { ...state, formPostData: action.formPostData }
  },
  [ACTIONS.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  formPostData: null
}

export default function reducer (state = initialState, action) {
  const handler = EXPORT_LEAD_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
