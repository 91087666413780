export function fieldsForOption(option) {
  switch (option) {
    case 'myma':
      return { companyName: 'Client ID', apiKey: 'API Key' };
    case 'mindbody':
      return { companyName: 'Studio ID' };
    case 'facebook_lead_adds':
      return { username: 'Username', companyName: 'Company/Client ID' };
    case 'one_fit_stop':
      return { apiKey: 'API Key' };
    case 'glofox':
      return { apiKey: 'Branch ID', companyName: 'Namespace' };
    case 'gymvue':
      return { apiKey: 'API key' };
    case 'journey':
      return {};
    case 'snapp_dd':
      return {
        customUrl: 'Signup URL*',
        integrationLocationId: 'Your location id (optional)',
      };
    case 'clubmanager':
      return {
        username: 'Your ClubManager username',
        password: 'Your ClubManager password',
      };
    case 'email':
      return {};
    default:
      return {};
  }
}
