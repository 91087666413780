import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import moment from 'moment-timezone'
import { TimeSelectView } from 'modules/components'

const TimeSelect = ({ sendTime, timezone, handleTimeChange }) => {
  const selectedTimeMoment = (sendTime) ? moment(sendTime).tz(timezone) : null
  const selectedTimeObject = timeObjectFromMoment(selectedTimeMoment)

  function changeTime(timeObject) {
    const formattedTimeObject = generateDateTimeWithTimeObject(timeObject, timezone)
    handleTimeChange(formattedTimeObject)
  }

  return (
    <div className="row">
      <div className="col-xs-12">
        <h5>What time should the report send?</h5>
        <Container>
          <TimeSelectView time={selectedTimeObject} onTimeChange={changeTime}/>
        </Container>
      </div>
    </div>
  );
};

export default TimeSelect;

TimeSelect.propTypes = {
  sendTime: PropTypes.string,
  handleTimeChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

const Container = styled.table`
  margin: 15px 0px 0px 0px;
`;

function generateDateTimeWithTimeObject(timeObject, timezone) {
  if (!timeObject) { return null }
  const { adjustedHour, minute } = timeObject

  return moment().set({'hour': adjustedHour, 'minute': minute}).tz(timezone).utc()
}

function timeObjectFromMoment (momentValue) {
  if (!momentValue) {
    return {
      hour: '1',
      adjustedHour: '13',
      minute: '00',
      period: 'pm'
    }
  }
  const adjustedHour = momentValue.hour()
  const hourValue = (adjustedHour < 13) ? adjustedHour : adjustedHour - 12
  const periodValue = (adjustedHour < 13) ? 'am' : 'pm'
  const minuteValue = momentValue.minute()
  return {
    hour: hourValue,
    adjustedHour: adjustedHour,
    minute: minuteValue,
    period: periodValue
  }
}