import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class LocationRow extends Component {

  render () {
    const { location } = this.props
    return (
      <tr className='selectable' onClick={this.templateClicked}>
        <td className='font-w600'>
          {location.admin_display_name}
        </td>
        <td className='font-w600'>
          {location.city}
        </td>
        <td className='font-w600'>
          {location.zip_postcode}
        </td>
      </tr>
    )
  }
}

LocationRow.propTypes = {
  location: PropTypes.object.isRequired
}

export default LocationRow
