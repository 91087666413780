import React from "react";

export const leadStatus = (status) => (
  <p>
    <strong>Count:</strong> The total number of leads who were in a trial during the date range and had their status changed to <strong>{status}</strong> after starting the trial within that range.
    <br />
    <br />
    <strong>Average:</strong> The average number of days it took for the lead to have their status marked as <strong>{status}</strong> after starting a trial.
  </p>
);

export const leadNoChangeStatus = () => (
  <p>
    <strong>Count:</strong> The total number of leads whose status did not change after they started a trial during the date range.
    <br />
    <br />
    <strong>Average:</strong> A leads status is required to have changed after starting a trial for an average to be calculated during this date range.
  </p>
);


export const trialLeadsCount = () => (
  <p>
     The total number of leads who were in the trial <strong>during the date range</strong>.
  </p>
);