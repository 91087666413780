import React, { Component } from "react";

import { InfoView } from "modules/components";
import EditProfileContainer from "../containers/EditProfileContainer";
import UserEmailContainer from "../containers/UserEmailContainer";
import EditPasswordContainer from "../containers/EditPasswordContainer";
import PersonalizationContainer from "../containers/PersonalizationContainer";

export class ProfileView extends Component {
  render() {
    const infoString =
      "Personalise your account so leads know they are talking to a real person";
    return (
      <div>
        <InfoView
          colorThemeClass={"info"}
          title={"Your Profile"}
          detail={infoString}
        />
        <EditProfileContainer />
        <UserEmailContainer />
        <EditPasswordContainer />
        <PersonalizationContainer />
      </div>
    );
  }
}

ProfileView.propTypes = {};

export default ProfileView;
