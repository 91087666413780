import { NAME } from './constants'

export const FETCHING = NAME + '/FETCHING'
export const FETCHING_SUCCESS = NAME + '/FETCHING_SUCCESS'
export const FETCHING_FAILURE = NAME + '/FETCHING_FAILURE'

export const UPDATING = NAME + '/UPDATING'
export const UPDATING_SUCCESS = NAME + '/UPDATING_SUCCESS'
export const UPDATING_FAILURE = NAME + '/UPDATING_FAILURE'
export const SET_PAGE_DETAILS = NAME + '/SET_PAGE_DETAILS'

export const ADD = NAME + '/ADD'
export const DELETE = NAME + '/DELETE'

export const SET_STEPS = NAME + '/SET_STEPS'
