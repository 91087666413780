import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ImportLeadsView } from "../components/ImportLeadsView";
import { getTabIndex } from "../selectors";
import * as actions from "../actions";

import selectCSV from "../modules/selectCSV";
import configureImport from "../modules/configureImport";
import fieldMapping from "../modules/fieldMapping";
import uploadCSV from "../modules/uploadCSV";

function mapStateToProps(state) {
  const tabIndex = getTabIndex(state);
  let nextIsReady = false;
  switch (tabIndex) {
    case 0:
      nextIsReady = selectCSV.selectors.isReady(state);
      break;
    case 1:
      nextIsReady = configureImport.selectors.isReady(state);
      break;
    case 2:
      nextIsReady = fieldMapping.selectors.isReady(state);
      break;
    case 3:
      nextIsReady = uploadCSV.selectors.isReady(state);
      break;
    default:
      break;
  }

  return {
    tabIndex: tabIndex,
    nextIsReady: nextIsReady,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      incrementTabIndex: actions.incrementTabIndex,
      decrementTabIndex: actions.decrementTabIndex,
      reset: actions.reset,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportLeadsView);
