import * as actionTypes from './actionTypes'

const GRAPH_STEPS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_LOST_STEPS]: (state, action) => {
    return { ...state, lostSteps: action.lostSteps }
  },
  [actionTypes.SET_INACTIVE_STEPS]: (state, action) => {
    return { ...state, inactiveSteps: action.inactiveSteps }
  }
}

const initialState = {
  errors: null,
  loading: false,
  lostSteps: [],
  inactiveSteps: []
}

export default function reducer (state = initialState, action) {
  const handler = GRAPH_STEPS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
