import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import ScheduleAppointmentModal from 'modules/components/LeadDetail/modules/appointments/components/NewAppointmentModal/ScheduleAppointmentModal';
import ScheduleAppointmentModal from '../components/ScheduleAppointmentModal';

import * as appointmentActions from '../actions';

import modal from 'modules/Stores/Modal';

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      scheduleAppointment: appointmentActions.scheduleAppointment,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAppointmentModal);
