import * as ACTIONS from './actionTypes';

import moment from 'moment';

const DATE_RANGE_ACTION_HANDLERS = {
  [ACTIONS.DATE_RANGE_SELECTED]: (state, action) => {
    return { ...state, startDate: action.startDate, endDate: action.endDate, selectedType: action.selectedType };
  },
};

const initialState = {
  startDate: moment().startOf('month').utc().format(),
  endDate: moment().endOf('day').utc().format(),
};

export default function reducer(state = initialState, action) {
  const handler = DATE_RANGE_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
