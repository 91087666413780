import * as ACTIONS from './actionTypes'

const EXPORT_LEAD_ACTION_HANDLERS = {
  [ACTIONS.SET_LEADS_TRIALS]: (state, action) => {
    let trials = [...state.leadsTrials, action.leads_trial]
    return { ...state, leadsTrials: trials }
  },
  [ACTIONS.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [ACTIONS.SET_CURRENT_TRIAL]: (state, action) => {
    return { ...state, currentTrial: action.trial }
  },
  [ACTIONS.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [ACTIONS.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  errors: null,
  loading: false, 
  currentTrial: null,
  leadsTrials: [],
}

export default function reducer (state = initialState, action) {
  const handler = EXPORT_LEAD_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
