import PropTypes from "prop-types";
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import styled from "styled-components";
import FilterSection from "modules/components/FilterFormSections/components/FilterSection";

export class CompareBySection extends Component {
  state = {
    showingOptions: false,
    options: Object.keys(this.props.compareByOptions),
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  indexClicked = (index) => {
    const { setCompareOption } = this.props;
    const newCompareOption = this.state.options[index];
    this.setState({ ...this.state, showingOptions: false });
    setCompareOption(newCompareOption);
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  render() {
    const { compareOption, compareByOptions } = this.props;
    const { showingOptions, options } = this.state;
    const mappedOptions = options.map((option, index) => {
      const className =
        index === this.state.selectedIndex ? "selected" : "selectable";
      return (
        <li key={index} className={className}>
          <div className="DropDown" onClick={() => this.indexClicked(index)}>
            {compareByOptions[option]}
          </div>
        </li>
      );
    });
    return (
      <FilterSection
        title="Compare by"
        showBottomBorder={false}
        blockStyle={"block"}
      >
        <div className="col-sm-12">
          <div className="form-material">
            <OptionsButton
              className="btn btn-default push-5-r push-10"
              type="button"
              onClick={this.toggleDropdown}
            >
              {compareByOptions[compareOption]}{" "}
              <DownIcon className="fa fa-caret-down" />
            </OptionsButton>
            {showingOptions && (
              <DropdownList className="dropdown-menu">
                {mappedOptions}
              </DropdownList>
            )}
          </div>
        </div>
      </FilterSection>
    );
  }
}

CompareBySection.propTypes = {
  compareOption: PropTypes.string.isRequired,
  compareByOptions: PropTypes.object.isRequired,
  setCompareOption: PropTypes.func.isRequired,
};

export default onClickOutside(CompareBySection);

const OptionsButton = styled.button`
  margin: 5px 0px 0px 0px;
`;

const DropdownList = styled.ul`
  display: block;
`;

const DownIcon = styled.i``;
