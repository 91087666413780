import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RecordCallModal from "modules/components/LeadDetail/modules/calls/components/NewCallModal/RecordCallModal";

import modal from "modules/Stores/Modal";
import classes from "modules/Stores/Classes";
import userData from "modules/Stores/UserData";
import * as callActions from "modules/components/LeadDetail/modules/calls/actions";
import leadsTrials from "modules/components/LeadDetail/modules/leadsTrials";

import * as callListActions from "../actions";
const { hideModal, showModal } = modal.actions;

const NEW_CALL_AFTER_COMPLETED_MODAL = "NEW_CALL_AFTER_COMPLETED_MODAL";
const NEW_APPOINTMENT_AFTER_COMPLETED_MODAL =
  "NEW_APPOINTMENT_AFTER_COMPLETED_MODAL";
const NEW_CLASS_APPOINTMENT_AFTER_COMPLETED_MODAL =
  "NEW_CLASS_APPOINTMENT_AFTER_COMPLETED_MODAL";

function mapStateToProps(state, ownProps) {
  const location = ownProps.location;
  const {
    classIntegrationId,
    classIntegrationName,
  } = classes.selectors.locationUsesClassesFactory(location.id)(state);
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    salesFunnel: userData.selectors.getSalesFunnel(state),
    classIntegrationId: classIntegrationId,
    classIntegrationName: classIntegrationName,
    scheduleCallModalName: NEW_CALL_AFTER_COMPLETED_MODAL,
    scheduleAppointmentModalName: NEW_APPOINTMENT_AFTER_COMPLETED_MODAL,
    scheduleClassAppointmentModalName: NEW_CLASS_APPOINTMENT_AFTER_COMPLETED_MODAL,
    listUpdateProtocol: {
      cleanUp: callListActions.removeSelected,
    },
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      showModal,
      toggleComplete: callActions.toggleComplete,
      onSelect: callListActions.removeSelected,
      addLeadToTrial: leadsTrials.actions.addLeadToTrial,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordCallModal);
