import { buildIndexUrl, apiClient } from 'utils/apiClient';
import { IWaiverForm, IPaginationMeta } from 'types';

type FetchWaiverFormsResponse = {
  waiver_forms: IWaiverForm[];
  meta: IPaginationMeta;
};

export const fetchWaiverForms = async (page = 1) => {
  const url = buildIndexUrl('waiver_forms', page);
  const response = await apiClient().get(url);
  return response.data as FetchWaiverFormsResponse;
};

type CreateWaiverFormInput = {
  name: string;
  completeText: string | null;
  disclaimerText: string | null;
  backgroundHexColor: string;
  primaryHexColor: string;
  secondaryHexColor: string;
  buttonTextColor: string | null;
  introductionText: string | null;
  membershipInquryFinishedText: string | null;
  membershipQuestionText: string | null;
  locationIds?: string[];
  allowMobileCheckIn: boolean;
  allowTabletCheckIn: boolean;
};

export const createWaiverForm = async (input: CreateWaiverFormInput) => {
  const params = {
    waiver_form: {
      name: input.name,
      complete_text: input.completeText,
      disclaimer_text: input.disclaimerText,
      shows_emergency_contact_details: false,
      show_lead_sources: false,
      background_hex_color: input.backgroundHexColor,
      primary_hex_color: input.primaryHexColor,
      secondary_hex_color: input.secondaryHexColor,
      introduction_text: input.introductionText,
      button_text_color: input.buttonTextColor,
      membership_question_text: input.membershipQuestionText,
      membership_inqury_finished_text: input.membershipInquryFinishedText,
      location_ids: input.locationIds,
      allow_mobile_check_in: input.allowMobileCheckIn,
      allow_tablet_check_in: input.allowTabletCheckIn,
    },
  };

  const url = 'waiver_forms';
  const response = await apiClient().post(url, params);
  return response.data as { waiver_form: IWaiverForm };
};

type UpdateWaiverFormInput = {
  waiverFormId: number;
  name: string;
  completeText: string | null;
  disclaimerText: string | null;
  backgroundHexColor: string;
  primaryHexColor: string;
  secondaryHexColor: string;
  buttonTextColor: string | null;
  introductionText: string | null;
  membershipInquryFinishedText: string | null;
  membershipQuestionText: string | null;
  locationIds?: string[];
  allowMobileCheckIn: boolean;
  allowTabletCheckIn: boolean;
};

export const updateWaiverForm = async (input: UpdateWaiverFormInput) => {
  const params = {
    waiver_form: {
      name: input.name,
      complete_text: input.completeText,
      disclaimer_text: input.disclaimerText,
      shows_emergency_contact_details: false,
      show_lead_sources: false,
      background_hex_color: input.backgroundHexColor,
      primary_hex_color: input.primaryHexColor,
      secondary_hex_color: input.secondaryHexColor,
      introduction_text: input.introductionText,
      button_text_color: input.buttonTextColor,
      membership_question_text: input.membershipQuestionText,
      membership_inqury_finished_text: input.membershipInquryFinishedText,
      location_ids: input.locationIds,
      allow_mobile_check_in: input.allowMobileCheckIn,
      allow_tablet_check_in: input.allowTabletCheckIn,
    },
  };

  const url = `waiver_forms/${input.waiverFormId}`;
  const response = await apiClient().put(url, params);
  return response.data as { waiver_form: IWaiverForm };
};

export const deleteWaiverForm = async (waiverFormId: number) => {
  const url = `waiver_forms/${waiverFormId}`;
  const response = await apiClient().delete(url);
  return response.data;
};
