/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import CountTo from "react-count-to";
import { RichToolTip } from "modules/components";

export class QuickInfoBlock extends Component {
  handleSelect = (event) => {
    event.preventDefault();
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      title,
      number,
      icon,
      iconTitle,
      suffix,
      onClick,
      tooltipText,
      tooltipId,
    } = this.props;
    const countSpeed = 1000;
    const loading = this.props.loading || false;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";
    const aStyle = onClick ? "selectable" : "";

    return (
      <div className={blockStyle}>
        <div className="block-content block-content-full">
          <div className="text-muted">
            <small>
              {icon && <i className={icon} />}
              {icon && iconTitle && " "}
              {iconTitle && iconTitle}
            </small>
          </div>
          <div className="font-s12 font-w700">
            {title}
            {tooltipText && (
              <RichToolTip text={tooltipText} toptipId={tooltipId} />
            )}
          </div>

          <a
            className={"h2 font-w300 text-primary " + aStyle}
            onClick={this.handleSelect}
          >
            <CountTo to={number} speed={countSpeed} />
            {suffix && " " + suffix}
          </a>
        </div>
      </div>
    );
  }
}

QuickInfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  iconTitle: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  suffix: PropTypes.string,
  tooltipText: PropTypes.object,
  tooltipId: PropTypes.string,
};
