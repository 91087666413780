import { createSelector } from 'reselect'
import { NAME } from './constants'

import deals from 'modules/Stores/Deals'

export const getSelectedDealId = (state) => state[NAME].selectedDealId
export const getData = (state) => state[NAME].data

export const selectedDeal = createSelector(
  [ deals.selectors.getDeals, getSelectedDealId ],
  (deals, selectedDealId) => {
    if (deals && deals.length > 0) {
      const index = deals.findIndex(deal => parseInt(deal.id) === parseInt(selectedDealId))
      return deals[index]
    } else {
      return null
    }
  }
)
