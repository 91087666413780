import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Tags from 'modules/components/Tags'

function mapStateToProps (state) {
  return {
    suggestions: Tags.selectors.getPopularTags(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addTagForLead: Tags.actions.createTagForLeadWithName,
    deleteTagForLead: Tags.actions.deleteTagForLead
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags.LeadTagView)
