/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { pluralizationHelper } from "modules/Helpers/PluralizationHelpers";

export class TrialRow extends Component {
  rowSelected = () => {
    const { trial, onSelect } = this.props;
    onSelect(trial);
  };

  render() {
    const { trial, selected } = this.props;
    const style = selected ? "selected" : "selectable";
    const trialStyle = selected ? "text-white" : "";
    return (
      <a onClick={this.rowSelected} className={"list-group-item " + style}>
        <span className={trialStyle}>
          {trial.name} - {pluralizationHelper("days", trial.day_length)}
        </span>
      </a>
    );
  }
}

TrialRow.propTypes = {
  trial: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default TrialRow;
