import { generateNewFormState } from 'utils/formHelpers'

export function transformDataToSection (data, sectionName, sections) {
  const keys = Object.keys(data)
  const afterKey = keys[0]
  const afterValue = data[afterKey]

  const beforeKey = keys[1]
  const beforeValue = data[beforeKey]

  const relevantSections = sections[sectionName]

  let newSectionData = generateNewFormState(relevantSections, afterKey, afterValue, true)
  newSectionData = generateNewFormState(newSectionData, beforeKey, beforeValue, true)

  return { ...sections, [sectionName]: newSectionData }
}
