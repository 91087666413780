import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { errorPanelForErrors } from 'utils/formHelpers';

import { PermissionsForm } from './PermissionsForm';

export class PermissionsView extends Component {
  UNSAFE_componentWillMount() {
    const { user, prefilForm } = this.props;
    prefilForm(user.user_permission_rule_set);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.id !== this.props.user.id) {
      nextProps.prefilForm(nextProps.user.user_permission_rule_set);
    }
  }

  saveClicked = () => {
    const { user, data, updateUserPermissions } = this.props;
    updateUserPermissions(user, data);
  };

  render() {
    const { loading, errors, user, onInput, data, isAdmin } = this.props;
    const blockStyle = loading ? 'block-opt-refresh' : '';
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Permissions for {user.display_name}</h3>
          </div>
          <div className="block-content">
            <form className="form-horizontal push-5-t">
              {errorPanelForErrors(errors)}
              <PermissionsForm data={data} isAdmin={isAdmin} onInput={onInput} onSave={this.saveClicked} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

PermissionsView.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  prefilForm: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  updateUserPermissions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  errors: PropTypes.object,
};

export default PermissionsView;
