/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RedeemFormValidator } from './validators';
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers';
import { FormFields } from 'modules/components';

const PRIVACY_MODAL = 'PRIVACY_MODAL';
const TERMS_MDOAL = 'TERMS_MODAL';

const { TextField, PasswordField, Checkbox } = FormFields;

export class RedeemInviteForm extends Component {
  state = {
    isValid: false,
    fields: {
      givenName: {
        value: this.props.name,
        isValid: this.props.name,
      },
      username: {
        value: null,
        isValid: false,
      },
      password: {
        value: null,
        isValid: false,
      },
      agreeToTerms: {
        value: false,
        isValid: false,
      },
    },
  };

  handleSubmit = (event) => {
    event && event.preventDefault();
    const { givenName, password, username } = this.state.fields;
    this.props.onSubmit(givenName.value, password.value, username.value);
  };

  handleInput = (name, value, isValid) => {
    this.setState(generateNewFormState(this.state, name, value, isValid));
  };

  handleToggle = (name, value) => {
    this.setState(generateNewFormState(this.state, name, value, value));
  };

  termsClicked = () => {
    this.props.showModal(TERMS_MDOAL);
  };

  privacyCicked = () => {
    this.props.showModal(PRIVACY_MODAL);
  };

  render() {
    const { loading, errors } = this.props;
    const { isValid, fields } = this.state;
    const { username, givenName, agreeToTerms } = fields;
    const loginSymbol = loading ? 'fa-circle-o-notch fa-spin pull-right' : 'fa fa-plus pull-right';
    const buttonTitle = loading ? 'Signing up' : 'Sign up';
    const buttonEnabled = isValid && !loading;

    const renderButton = () => (
      <div className="form-group">
        <div className="col-xs-12 col-sm-6 col-md-5">
          <button type="submit" className="btn btn-block btn-success" disabled={!buttonEnabled} onClick={this.handleSubmit}>
            <i className={'fa ' + loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>
    );

    return (
      <div>
        {errorPanelForErrors(errors, false)}
        <form className="form-horizontal push-30-t push-50" onSubmit={this.handleSubmit}>
          <TextField
            name="username"
            label="Username (use this to log in)"
            placeholder="What do you want your username to be?"
            formFieldStyle="form-material form-material-success"
            onChange={this.handleInput}
            disabled={loading}
            validator={RedeemFormValidator}
            value={username.value}
          />
          <TextField
            name="givenName"
            label="Your name"
            placeholder="What is your name?"
            formFieldStyle="form-material form-material-success"
            onChange={this.handleInput}
            disabled={loading}
            validator={RedeemFormValidator}
            value={givenName.value}
          />
          <PasswordField
            name="password"
            label="Password"
            placeholder="Choose a strong password.."
            formFieldStyle="form-material form-material-success"
            onChange={this.handleInput}
            disabled={loading}
            validator={RedeemFormValidator}
          />
          <div className="row">
            <div className="col-xs-1">
              <Checkbox
                name="agreeToTerms"
                label=""
                onChange={this.handleToggle}
                switchStyle="success"
                checked={agreeToTerms.value}
                disabled={loading}
              />
            </div>
            <div className="col-xs-11">
              <p className="push-10-l" style={{ marginTop: '5px' }}>
                I agree with the{' '}
                <a className="selectable" href="https://www.gymleads.net/termsofuse" target="_blank" rel="noopener noreferrer">
                  Terms And Conditions
                </a>{' '}
                and{' '}
                <a className="selectable" href="https://www.gymleads.net/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
          <div>{renderButton()}</div>
        </form>
      </div>
    );
  }
}

RedeemInviteForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string,
  showModal: PropTypes.func.isRequired,
};

export default RedeemInviteForm;
