import PropTypes from 'prop-types'
import React, { Component } from 'react'

import LocationRow from './LocationRow'

import { EmptyView } from 'modules/components'

import { MODAL_NAME } from '../constants'

import EditRegionModalContainer from '../containers/EditRegionModalContainer'

export class EditRegionView extends Component {

  componentDidMount = () => {
    const { region, getLocationsForRegionId } = this.props
    getLocationsForRegionId(region.id)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.region.id !== this.props.region.id) {
      const { region, getLocationsForRegionId } = nextProps
      getLocationsForRegionId(region.id)
    }
  }

  editRegionClicked = () => {
    this.props.showModal(MODAL_NAME)
  }

  render () {
    const { region, locations } = this.props
    const blockStyle = (this.props.loading) ? 'block-opt-refresh' : ''
    const rows = locations.map((location, index) => {
      return <LocationRow
        key={index}
        location={location} />
    })
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <div className='block-options-simple'>
              <button type='button' className='btn btn-xs btn-primary' onClick={this.editRegionClicked}>
                <i className='fa fa-plus' />{' '}Edit region
              </button>
            </div>
            <h3 className='block-title'>{region.name}</h3>
          </div>
          <div className='block-content'>
            {locations.length === 0 &&
              <div style={{ paddingBottom: '30px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='fa fa-building-o'
                  title='No locations'
                  subtitle='No locations match this regions postcodes.' />
              </div>
            }
            {locations.length > 0 &&
              <table className='table table-vcenter table-hover'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>City</th>
                    <th>Postcode</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
             }
          </div>
          <EditRegionModalContainer region={region} modalName={MODAL_NAME} />
        </div>
      </div>
    )
  }
}

EditRegionView.propTypes = {
  loading: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  region: PropTypes.object.isRequired,
  getLocationsForRegionId: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default EditRegionView
