import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getSelectedTeamMemberId = state => state.salesTeams[NAME].selectedTeamMemberId
export const getTeam = state => state.salesTeams[NAME].team
export const getLoading = state => state.salesTeams[NAME].loading
export const getPageDetails = state => state.salesTeams[NAME].pageDetails

export const selectedUser = createSelector(
  [ getTeam, getSelectedTeamMemberId ],
  (team, selectedTeamMemberId) => {
    if (selectedTeamMemberId && team.length > 0) {
      const index = team.findIndex(teamMember => parseInt(teamMember.id) === parseInt(selectedTeamMemberId))
      return team[index]
    }
  }
)
