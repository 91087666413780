import * as actionTypes from "./actionTypes";

const TRIALS_TIME_TAKEN_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_DATA]: (state, action) => {
    return { ...state, data: action.data };
  }
};

const initialState = {
  errors: null,
  loading: false,
  data: null
};

export default function reducer(state = initialState, action) {
  const handler = TRIALS_TIME_TAKEN_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
