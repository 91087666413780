import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SMSRechargeView } from '../components/SMSRechargeView'

import selectSms from '../modules/selectSms'

function mapStateToProps (state) {
  return {
    selectedSmsAccount: selectSms.selectors.getSelectedSmsAccount(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSRechargeView)
