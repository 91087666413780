import { connect } from 'react-redux'

import statusCount from '../modules/statusCount'
import countsLoader from '../modules/countsLoader'

function mapStateToProps (state) {
  const counts = countsLoader.selectors.getCounts(state)
  const dataSets = statusCount.selectors.countsAsDataSet(counts)
  return {
    dataSets: dataSets,
    labels: statusCount.selectors.labels(),
    loading: countsLoader.selectors.getCountLoading(state),
    isEmpty: statusCount.selectors.isEmpty(dataSets)
  }
}

export default connect(mapStateToProps)(statusCount.components.StatusCountView)
