import { NAME } from './constants';

export const RESET = NAME + '/RESET';
export const LOADING_STARTED = NAME + '/INITIAL_USER_DATA_STARTED_LOADING';
export const LOADING_STOPPED = NAME + '/INITIAL_USER_DATA_STOPED_LOADING';

export const SET_COUNTRIES = NAME + '/SET_COUNTRIES';

export const COMPANY_DATA_FETCH_SUCESS = NAME + '/COMPANY_DATA_FETCH_SUCESS';
export const USER_ROLES_FETCH_SUCCESS = NAME + '/USER_ROLES_FETCH_SUCCESS';
export const SALES_FUNNEL_FETCH_SUCCESS = NAME + '/SALES_FUNNEL_FETCH_SUCCESS';

export const FETCHING_LOCATION_METRICS = NAME + '/FETCHING_LOCATION_METRICS';
export const FETCH_LOCATION_METRICS_SUCCESS = NAME + '/FETCH_LOCATION_METRICS_SUCCESS';
export const FETCH_LOCATION_METRICS_FAILED = NAME + '/FETCH_LOCATION_METRICS_FAILED';

export const FETCHING = NAME + '/FETCHING';

export const SET_SALES_TARGETS = NAME + '/SET_SALES_TARGETS';

export const SET_REGIONS = NAME + '/SET_REGIONS';
export const ADD_REGION = NAME + '/ADD_REGION';
export const UPDATE_REGION = NAME + '/UPDATE_REGION';
export const REMOVE_REGION = NAME + '/REMOVE_REGION';

export const ADD_LOCATION = NAME + '/ADD_LOCATION';
export const UPDATE_LOCATION = NAME + '/UPDATE_LOCATION';
export const UPDATE_USER = NAME + '/UPDATE_USER';

export const SET_SCHEDULES = NAME + '/SET_SCHEDULES';
export const ADD_SCHEDULE = NAME + '/ADD_SCHEDULE';
export const UPDATE_SCHEDULE = NAME + '/UPDATE_SCHEDULE';
export const REMOVE_SCHEDULE = NAME + '/REMOVE_SCHEDULE';

export const ADD_THIRD_PARTY_INTEGRATION = NAME + '/ADD_THIRD_PARTY_INTEGRATION';
export const DELETE_THIRD_PARTY_INTEGRATION_WITH_ID = NAME + '/DELETE_THIRD_PARTY_INTEGRATION_WITH_ID';
export const UPDATE_THIRD_PARTY_INTEGRATION = NAME + '/UPDATE_THIRD_PARTY_INTEGRATION';
