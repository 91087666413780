import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LocationSelectDashboardView } from '../../components/LocationSelectDashboardView'

import userData from 'modules/Stores/UserData'
import session from 'modules/Stores/Session'
import structure from '../../modules/Structure'
import locationSelect from '../../modules/LocationSelect'

function mapStateToProps (state, ownProps) {
  return {
    user: session.selectors.getUser(state),
    selectedLocation: locationSelect.selectors.getSelectedLocation(state),
    company: userData.selectors.getCompany(state),
    permissions: userData.selectors.getMembershipPermissions(state),
    dashboardType: structure.selectors.getDashoardType(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setCurrentLocation: userData.actions.setCurrentLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectDashboardView)
