import { isEmail, isPresent, isMinLength, hasWhiteSpace } from 'utils/validation'

export const ProfileFormValidator = {
  username: [{
    rule: isMinLength(4),
    errorMessage: 'Your username needs to be longer than ' + 4 + ' characters'
  }, {
    rule: hasWhiteSpace,
    errorMessage: 'Usernames should not have any spaces'
  }, {
    rule: isPresent,
    errorMessage: 'Looks like your username is missing'
  }],
  givenName: [{
    rule: isPresent,
    errorMessage: 'We need to know what to call you!'
  }],
  email: [{
    rule: isEmail,
    errorMessage: 'That doesn\'t seem to be an email address'
  }, {
    rule: isPresent,
    errorMessage: 'We need an email address so we can contact you'
  }],
  signatureEmail: [{
    rule: isEmail,
    errorMessage: 'That doesn\'t seem to be an email address'
  }]
}
