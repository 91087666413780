import PropTypes from "prop-types";
import React, { Component } from "react";

import Select from "react-select";
import "react-select/dist/react-select.css";
import "./LeadTagView.css";

export class LeadTagView extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  handleUpdate = (newTags) => {
    const {
      lead,
      addTagForLead,
      deleteTagForLead,
      listUpdateProtocol,
    } = this.props;
    const oldTags = this.props.leadTags;
    if (newTags.length > oldTags.length) {
      const newTag = newTags.slice(-1)[0];
      addTagForLead(lead, newTag.label, listUpdateProtocol.addTag);
    } else {
      const removedTag = oldTags.filter((oldTag) => {
        return (
          newTags.findIndex((newTag) => newTag.label === oldTag.text) === -1
        );
      })[0];
      if (removedTag) {
        deleteTagForLead(lead, removedTag.text, listUpdateProtocol.removeTag);
      }
    }
  };

  generateValues = () => {
    return this.props.leadTags.map((tag, index) => {
      return {
        value: tag.text,
        label: tag.text,
      };
    });
  };

  generateSuggestions = () => {
    return this.props.suggestions.map((tag, index) => {
      return {
        value: tag,
        label: tag,
      };
    });
  };

  render() {
    return (
      <div className="form-material form-material-primary select-up">
        <Select.Creatable
          multi={true}
          promptTextCreator={(label) => "Add new tag called: " + label}
          placeholder={"Select or type a new tag"}
          value={this.generateValues()}
          options={this.generateSuggestions()}
          onChange={this.handleUpdate}
        />
        <label>Tags</label>
      </div>
    );
  }
}

LeadTagView.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  leadTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  addTagForLead: PropTypes.func.isRequired,
  deleteTagForLead: PropTypes.func.isRequired,
};

export default LeadTagView;
