import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MultiCountrySelectModal from "../components/MultiCountrySelectModal";

import userData from "modules/Stores/UserData";
import modal from "modules/Stores/Modal";

function mapStateToProps(state) {
  return {
    countries: userData.selectors.getCountries(state),
    currentModal: modal.selectors.getCurrentModal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiCountrySelectModal);
