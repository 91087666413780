import React from "react";

export const averageTimeToAssign = () => (
  <p>The average time taken for the lead to be assigned to a salesperson. </p>
);

export const averageTimeForFirstCall = () => (
  <p>The average time for a lead to receive their first call.</p>
);

export const averageTimefromAssignedToCall = () => (
  <p>
    The average time it takes for a call to occur from when a lead was assigned.
  </p>
);

export const averageTimeToClose = () => (
  <p>The average amount of time it takes for a lead to be marked as closed.</p>
);
