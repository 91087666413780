import { connect } from 'react-redux'
import { EditableUserRoleTag } from 'modules/components'

import * as selectors from '../../../selectors'

function mapStateToProps (state) {
  return {
    userRoles: selectors.availableUserRole(state)
  }
}

export default connect(mapStateToProps)(EditableUserRoleTag)
