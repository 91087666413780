import { request, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';
import selectSms from '../selectSms';

export const updateSMSAccount = (smsAccount, autoRecharge) => {
  return (dispatch) => {
    dispatch(startUpdating());
    const body = {
      sms_account: { auto_recharge: autoRecharge },
    };

    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(selectSms.actions.updateSmsAccount(payload.sms_account));
      dispatch(loadSuccessful());
    }

    return request('sms_accounts/' + smsAccount.id, PUT, body, onSuccess, onFailure);
  };
};

export const topUpAccount = (smsAccount, amount) => {
  return (dispatch) => {
    dispatch(startUpdating());
    const body = {
      sms_account_recharge: {
        amount: amount,
      },
    };
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      dispatch(selectSms.actions.updateSmsAccount(payload.sms_account));
      notify.success('Top-up successfull');
    }

    return request('sms_accounts/' + smsAccount.id + '/sms_account_recharges', POST, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccessful() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}
