import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import salesTargets from '../modules/salesTargets'
import currentTarget from '../modules/currentTarget'

function mapStateToProps (state, nextProps) {
  return {
    salesTargets: currentTarget.selectors.getSalesTarget(state),
    loading: salesTargets.selectors.getLoading(state),
    data: salesTargets.selectors.getData(state),
    errors: salesTargets.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: salesTargets.actions.updateData,
    deleteSalesTarget: salesTargets.actions.deleteSalesTarget,
    updateSalesTargetWithData: salesTargets.actions.updateSalesTargetWithData,
    prefilSalesTargetsData: salesTargets.actions.prefilSalesTargetsData,
    setSalesTarget: currentTarget.actions.setSalesTarget
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(salesTargets.components.SalesTargetsView)
