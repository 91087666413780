import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import SelectHeardAboutMethodTableView from "./TableView/SelectHeardAboutMethodTableView";

import { CREATE_LEAD_VIEW } from "../constants";
import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class HeardAboutMethodSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { heardAboutMethods } = this.props;
    if (heardAboutMethods.length > 0) {
      return;
    }
    this.getHeardAboutMethodsWithPageNumber(1);
  }

  getHeardAboutMethodsWithPageNumber = pageNumber => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getHeardAboutMethodsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(1, searchTerm);
  };

  showCreateLead = () => {
    this.props.setCurrentView(CREATE_LEAD_VIEW);
  };

  onHeardAboutMethodSelect = heardAboutMethod => {
    const { setCurrentView, selectHeardAboutMethod } = this.props;
    selectHeardAboutMethod(heardAboutMethod);
    setCurrentView(CREATE_LEAD_VIEW);
  };

  render() {
    const {
      heardAboutMethods,
      pageDetails,
      loading,
      selectedHeardAboutMethod
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <div>
        <ContentBlockView loading={loading} bordered>
          <div className="row" style={{ marginTop: "-20px" }}>
            <TextSearchView
              sectionName={"heardAboutMethodSearch"}
              onUpdate={this.searchUpdated}
            />
          </div>
          {heardAboutMethods.length > 0 && (
            <div>
              <SelectHeardAboutMethodTableView
                heardAboutMethods={heardAboutMethods}
                selectedHeardAboutMethod={selectedHeardAboutMethod}
                onHeardAboutMethodSelect={this.onHeardAboutMethodSelect}
              />
            </div>
          )}
          {heardAboutMethods.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
        </ContentBlockView>
        <Modal.Footer>
          <button
            className="btn btn-default push-5-r push-10"
            type="button"
            onClick={this.showCreateLead}
          >
            Go Back
          </button>
        </Modal.Footer>
      </div>
    );
  }
}

HeardAboutMethodSelectView.propTypes = {
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectedHeardAboutMethod: PropTypes.object,
  getHeardAboutMethods: PropTypes.func.isRequired,
  selectHeardAboutMethod: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
};

export default HeardAboutMethodSelectView;
