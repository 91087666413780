import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateNewFormState } from 'utils/formHelpers';

import { ContentBlockView, FormFields, Text } from 'modules/components';
import { validator } from '../validator';

import { MIGRATE_TRIAL_MODAL } from '../constants';
import MigrateTrialModalContainer from '../containers/MigrateTrialModalContainer';

const { TextField } = FormFields;
export class TrialsView extends Component {
  state = {
    trialToDelete: null,
  };

  saveTrialClicked = () => {
    const { selectedTrial, updateTrial, data } = this.props;
    updateTrial(selectedTrial, data);
  };

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid);
    this.props.updateData(newData);
  };

  migrateTrialClicked = () => {
    this.props.showModal(MIGRATE_TRIAL_MODAL);
  };

  render() {
    const { selectedTrial, loading, data } = this.props;
    const { name, dayLength } = data.fields;

    const deleteButton = (
      <button type="button" className="pull-right btn btn-xs btn-danger" onClick={this.migrateTrialClicked}>
        <i className="fa fa-cross" /> Delete Trial
      </button>
    );

    const journeyManaged = selectedTrial.journey_id !== null;

    return (
      <div>
        <ContentBlockView title={selectedTrial.name} loading={loading} blockOptions={!journeyManaged ? deleteButton : undefined} bordered>
          {journeyManaged && <Text.P2>This trial is managed in Journey.</Text.P2>}
          <div className="row push-20">
            <TextField
              name="name"
              label="Trial Name *"
              placeholder="What should this Trial be called?"
              formFieldStyle=""
              onChange={this.handleInput}
              value={name.value}
              validator={validator}
              disabled={journeyManaged}
            />
          </div>
          <div className="row push-20">
            <TextField
              name="dayLength"
              label="Length (In Days) *"
              placeholder="30"
              formFieldStyle=""
              onChange={this.handleInput}
              value={dayLength.value}
              validator={validator}
              disabled={journeyManaged}
            />
          </div>
          {journeyManaged === false && (
            <div className="row">
              <div className="col-xs-4 push-20">
                <button className="btn btn-primary" onClick={this.saveTrialClicked}>
                  <i className="fa fa-save" /> Save trial details
                </button>
              </div>
            </div>
          )}
        </ContentBlockView>
        <MigrateTrialModalContainer modalName={MIGRATE_TRIAL_MODAL} trial={selectedTrial} />
      </div>
    );
  }
}

TrialsView.propTypes = {
  selectedTrial: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  deleteTrial: PropTypes.func.isRequired,
  updateTrial: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default TrialsView;
