import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';

import deals from 'modules/Stores/Deals';

export function setSelectedDealId(selectedDealId) {
  return {
    type: actionTypes.SET_SELECTED_DEAL_ID,
    selectedDealId,
  };
}

export function prefillDataForDeal(deal) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_DEAL,
    deal,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export const deleteDeal = (dealId, toDealId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toDealId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess(payload) {
      dispatch(setSelectedDealId(null));
      dispatch(prefillDataForDeal(null));
      dispatch(modal.actions.stopLoading());
      dispatch(deals.actions.deleteDealWithId(dealId));
      notify.success('Deal Migrated');
    }
    const url = 'deals/' + dealId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};
