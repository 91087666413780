import React from 'react';
import NumberFormat from 'react-number-format';

export function readableMobileNumber(number) {
  if (number === null || number === undefined || number.length === 0) {
    return number;
  }
  if (number.startsWith('+61')) {
    const formattedNumber = number.replace('+61', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="#### ### ###" displayType={'text'} />;
  }

  if (number.startsWith('+60')) {
    const formattedNumber = number.replace('+60', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="#### ### ####" displayType={'text'} />;
  }

  if (number.startsWith('+31')) {
    const formattedNumber = number.replace('+31', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="#### ### ###" displayType={'text'} />;
  }

  if (number.startsWith('+1')) {
    const formattedNumber = number.replace('+1', '').replace('(', '').replace('(', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="(###) ###-####" displayType={'text'} />;
  }

  if (number.startsWith('+63')) {
    const formattedNumber = number.replace('+63', '0');
    return <NumberFormat value={formattedNumber} format="#-###-#######" displayType={'text'} />;
  }

  if (number.startsWith('+65')) {
    const formattedNumber = number.replace('+65', '');
    return <NumberFormat value={formattedNumber} format="#### ####" displayType={'text'} />;
  }

  if (number.startsWith('+353')) {
    const formattedNumber = number.replace('+353', '0').replace('(', '').replace('(', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="(##) #### ####" displayType={'text'} />;
  }

  if (number.startsWith('+44')) {
    const formattedNumber = number.replace('+44', '0').replace('(', '').replace('(', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="##### ### ###" displayType={'text'} />;
  }

  if (number.startsWith('+64')) {
    const formattedNumber = number.replace('+64', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="#### ### ####" displayType={'text'} />;
  }

  if (number.startsWith('+27')) {
    const formattedNumber = number.replace('+27', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="### ### ####" displayType={'text'} />;
  }

  if (number.startsWith('+66')) {
    const formattedNumber = number.replace('+66', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="##-####-####" displayType={'text'} />;
  }

  if (number.startsWith('+852')) {
    const formattedNumber = number.replace('+852', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="####-####" displayType={'text'} />;
  }

  if (number.startsWith('+81')) {
    const formattedNumber = number.replace('+81', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="### #### ####" displayType={'text'} />;
  }

  if (number.startsWith('+91')) {
    const formattedNumber = number.replace('+91', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="####-######" displayType={'text'} />;
  }

  if (number.startsWith('+84')) {
    const formattedNumber = number.replace('+84', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="###-#######" displayType={'text'} />;
  }

  if (number.startsWith('+86')) {
    const formattedNumber = number.replace('+86', '').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="### #### ####" displayType={'text'} />;
  }

  if (number.startsWith('+62')) {
    const formattedNumber = number.replace('+62', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="###-####-####" displayType={'text'} />;
  }

  if (number.startsWith('+971')) {
    const formattedNumber = number.replace('+971', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="###-####-####" displayType={'text'} />;
  }

  if (number.startsWith('+82')) {
    const formattedNumber = number.replace('+82', '0').replace(' ', '');
    return <NumberFormat value={formattedNumber} format="###-###-####" displayType={'text'} />;
  }

  return number;
}

export function placeholdersForCountry(countryId, countries) {
  const index = countries.findIndex((country) => parseInt(country.id) === parseInt(countryId));
  const country = countries[index];
  if (!country) {
    return defaultPlaceholders;
  }
  switch (country.code) {
    case 'AU':
      return defaultPlaceholders;
    case 'MY':
      return myPlaceholders;
    case 'PH':
      return phPlaceholders;
    case 'US':
      return usPlaceholders;
    case 'CA':
      return caPlaceholders;
    case 'NZ':
      return defaultPlaceholders;
    case 'UK':
      return ukPlaceholders;
    case 'IE':
      return iePlaceholders;
    case 'NL':
      return nlPlaceholders;
    case 'SG':
      return sgPlaceholders;
    case 'ZA':
      return zaPlaceholders;
    case 'TH':
      return thPlaceholders;
    case 'HK':
      return hkPlaceholders;
    case 'CN':
      return cnPlaceholders;
    case 'IN':
      return inPlaceholders;
    case 'JP':
      return jpPlaceholders;
    case 'VN':
      return vnPlaceholders;
    case 'ID':
      return idPlaceholders;
    case 'AE':
      return aePlaceholders;
    case 'KR':
      return krPlaceholders;
    default:
      return defaultPlaceholders;
  }
}

const defaultPlaceholders = {
  phone: '#### ### ###',
  date: 'DD/MM/YYYY',
  locale: 'en-AU',
};

const usPlaceholders = {
  phone: '(###) ###-####',
  date: 'MM/DD/YYYY',
  locale: 'en-US',
};

const caPlaceholders = {
  phone: '(###) ###-####',
  date: 'DD/MM/YYYY',
  locale: 'en-CA',
};

const ukPlaceholders = {
  phone: '##### ### ###',
  date: 'DD/MM/YYYY',
  locale: 'en_GB',
};

const iePlaceholders = {
  phone: '(##) ### ####',
  date: 'DD/MM/YYYY',
  locale: 'en_IE',
};

const sgPlaceholders = {
  phone: '+65 #### ####',
  date: 'DD/MM/YYYY',
  locale: 'en_EG',
};

const nlPlaceholders = {
  phone: '#### ### ###',
  date: 'DD/MM/YYYY',
  locale: 'en_EG',
};

const myPlaceholders = {
  phone: '#### ### ###',
  date: 'DD/MM/YYYY',
  locale: 'en_MY',
};

const phPlaceholders = {
  phone: '#-###-#######',
  date: 'MM/DD/YYYY',
  locale: 'en_PH',
};

const zaPlaceholders = {
  phone: '###-###-#####',
  date: 'DD/MM/YYYY',
  locale: 'en_ZA',
};

const thPlaceholders = {
  phone: '##-####-####',
  date: 'DD/MM/YYYY',
  locale: 'en_TH',
};

const hkPlaceholders = {
  phone: '####-####',
  date: 'DD.MM.YYYY',
  locale: 'en_HK',
};

const cnPlaceholders = {
  phone: '### #### ####',
  date: 'yyyy-mm-dd',
  locale: 'en_CN',
};

const jpPlaceholders = {
  phone: '### #### ####',
  date: 'yyyy年mm月dd日',
  locale: 'en_JP',
};

const inPlaceholders = {
  phone: '####-######',
  date: 'DD-MM-YY',
  locale: 'en_IN',
};

const vnPlaceholders = {
  phone: '###-#######',
  date: 'YYYY-MM-DD',
  locale: 'en_VN',
};

const idPlaceholders = {
  phone: '###-####-####',
  date: 'DD/MM/YYYY',
  locale: 'en_ID',
};

const aePlaceholders = {
  phone: '###-####-####',
  date: 'DD/MM/YYYY',
  locale: 'en_AE',
};

const krPlaceholders = {
  phone: '###-###-####',
  date: 'YYY/MM/DD',
  locale: 'en-US',
};
