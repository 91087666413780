import PropTypes from "prop-types";
import React, { Component } from "react";
import ThirdPartyIntegrationTableView from "./TableView/ThirdPartyIntegrationTableView";

import { ContentBlockView } from "modules/components";

export class ThirdPartyIntegrationSelectView extends Component {
  UNSAFE_componentWillMount() {
    const {
      thirdPartyIntegrations,
      lastSelectedLocationId,
      locationId,
    } = this.props;
    if (
      thirdPartyIntegrations.length > 0 &&
      lastSelectedLocationId === locationId
    ) {
      return;
    }
    this.getThirdPartyIntegration();
  }

  getThirdPartyIntegration = () => {
    const { getThirdPartyIntegrations, locationId } = this.props;
    getThirdPartyIntegrations(locationId);
  };

  render() {
    const {
      thirdPartyIntegrations,
      onThirdPartyIntegrationSelect,
      renderRow,
      tableHead,
      selectedThirdPartyIntegrationId,
      minHeight,
      loading,
      title,
    } = this.props;

    return (
      <ContentBlockView
        minHeight={minHeight}
        title={title}
        loading={loading}
        bordered
      >
        <ThirdPartyIntegrationTableView
          renderRow={renderRow}
          tableHead={tableHead}
          thirdPartyIntegrations={thirdPartyIntegrations}
          thirdPartyIntegrationSelected={onThirdPartyIntegrationSelect}
          selectedThirdPartyIntegrationId={selectedThirdPartyIntegrationId}
        />
      </ContentBlockView>
    );
  }
}

ThirdPartyIntegrationSelectView.propTypes = {
  title: PropTypes.string,
  locationId: PropTypes.number.isRequired,
  lastSelectedLocationId: PropTypes.number,
  getThirdPartyIntegrations: PropTypes.func.isRequired,
  thirdPartyIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onThirdPartyIntegrationSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedThirdPartyIntegrationId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
};

ThirdPartyIntegrationSelectView.defaultProps = {
  title: "ThirdPartyIntegrations",
};

export default ThirdPartyIntegrationSelectView;
