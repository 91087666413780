export function fieldsForOption(option) {
  switch (option) {
    case "mindbody":
      return { companyName: "Studio ID", username: "Location ID" };
    default:
      return {};
  }
}

export const mindbodyPremiumFeatures = [
  "collect_visits",
  "membership_cancellations",
  "appointment_created",
  "appointment_updated",
  "appointment_cancelled",
  "lead_membership_assigned",
  "lead_update"
];
