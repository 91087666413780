import notify from 'modules/Notifications';
import * as actionTypes from './actionTypes';

export function showNoUserError(displayName) {
  return (dispatch) => {
    notify.error(displayName + ' is not a user in the selected clubs');
  };
}

export function regionSearchTermUpdated(searchTerm) {
  return {
    type: actionTypes.REGION_SEARCH_TERM_UPDATED,
    searchTerm,
  };
}

export function updateRegionCurrentPage(pageNumer) {
  return {
    type: actionTypes.REGION_CURRENT_PAGE_UPDATED,
    pageNumer,
  };
}

export function setRegionIds(regionIds) {
  return {
    type: actionTypes.SET_SELECTED_REGION_IDS,
    regionIds,
  };
}
