import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LocationSelectView from '../components/LocationSelectView'
import locations from 'modules/Stores/Locations'
import session from 'modules/Stores/Session'

function mapStateToProps (state) {
  const userRole = session.selectors.getUserRole(state)
  return {
    locations: locations.selectors.getLocations(state),
    loading: locations.selectors.getLoading(state),
    errors: locations.selectors.getErrors(state),
    pageDetails: locations.selectors.getPageDetails(state),
    showSaleDetails: session.helpers.userRoleHasManagerPermissions(userRole)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getLocations: locations.actions.getLocations
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectView)
