/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  cardTypeForNumber,
  lastFourDigitsOfNumber,
} from "modules/Stores/Billing/ccUtils";
import moment from "moment";

export class PaymentMethodRow extends Component {
  replaceSelected = () => {
    const { paymentMethod, updateClicked } = this.props;
    updateClicked(paymentMethod);
  };

  deleteSelected = () => {
    const { paymentMethod, deleteClicked } = this.props;
    deleteClicked(paymentMethod);
  };

  render() {
    const { paymentMethod } = this.props;
    return (
      <div className="push-10">
        <i className="si si-credit-card push-10-r" />
        {cardTypeForNumber(paymentMethod.masked_number)} ending in{" "}
        {lastFourDigitsOfNumber(paymentMethod.masked_number)} added{" "}
        {moment(paymentMethod.created_at).format("MMMM Do YYYY")}
        <a
          className="push-10-r push-10-l selectable"
          onClick={this.replaceSelected}
        >
          <i className="fa fa-edit" /> Replace
        </a>
        {paymentMethod.in_use === false && (
          <a
            className="push-10-r selectable text-danger"
            onClick={this.deleteSelected}
          >
            <i className="fa fa-times" /> Delete
          </a>
        )}
      </div>
    );
  }
}

PaymentMethodRow.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  updateClicked: PropTypes.func.isRequired,
  deleteClicked: PropTypes.func.isRequired,
};

export default PaymentMethodRow;
