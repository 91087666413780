import React, { Component } from 'react'

import TargetsContainer from '../containers/TargetsContainer'
import LeadTimeOutContainer from '../containers/LeadTimeOutContainer'
import { InfoView } from 'modules/components'

export class SalesTargetsView extends Component {

  render () {
    const infoString = 'These are the default sales targets for all of your' +
    ' salespeople across all clubs. Managers can set their own targets for specific clubs in the Locations menu.'
    return (
      <div>
        <InfoView colorThemeClass={'info'} title={'Default sales targets'} detail={infoString} />
        <TargetsContainer />
        <LeadTimeOutContainer />
      </div>
    )
  }
}

SalesTargetsView.propTypes = {

}

export default SalesTargetsView
