import { NAME } from './constants'

export const REMOVE_LEAD = NAME + '/REMOVE_LEAD'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'

export const QUEUED_INQUIRIES_FETCHING_LEADS = NAME + '/QUEUED_INQUIRIES_FETCHING_LEADS'
export const QUEUED_INQUIRIES_FETCHING_LEADS_FAILED = NAME + '/QUEUED_INQUIRIES_FETCHING_LEADS_FAILED'
export const QUEUED_INQUIRIES_FETCHING_LEADS_SUCCEEDED = NAME + '/QUEUED_INQUIRIES_FETCHING_LEADS_SUCCEEDED'

export const QUEUED_INQUIRIES_ADD_LEAD = NAME + '/QUEUED_INQUIRIES_ADD_LEAD'
export const SELECT_LEAD = NAME + '/SELECT_LEAD'

export const SET_LOCATION_ID = NAME + '/SET_LOCATION_ID'
