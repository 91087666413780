import { combineReducers } from 'redux';
import filters from './modules/filters';
import summary from './modules/summary';
import graphBreakdown from './modules/graphBreakdown';
import header from './modules/header';

export default combineReducers({
  [summary.constants.NAME]: summary.reducer,
  [filters.constants.NAME]: filters.reducer,
  [graphBreakdown.constants.NAME]: graphBreakdown.reducer,
  [header.constants.NAME]: header.reducer,
});
