import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HeardAboutMethodTableView from './HeardAboutMethodTableView/HeardAboutMethodTableView';
import PagesContainer from '../containers/PagesContainer';

import { ContentBlockView, EmptyView, TextSearchView } from 'modules/components';

export class HeardAboutMethodSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { heardAboutMethods } = this.props;
    if (heardAboutMethods.length > 0) {
      return;
    }
    this.getHeardAboutMethodsWithPageNumber(1);
  }

  getHeardAboutMethodsWithPageNumber = (pageNumber) => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getHeardAboutMethodsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(1, searchTerm);
  };

  render() {
    const {
      heardAboutMethods,
      onHeardAboutMethodSelect,
      renderRow,
      tableHead,
      selectedHeardAboutMethodId,
      minHeight,
      pageDetails,
      loading,
      title,
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView minHeight={minHeight} title={title} loading={loading} bordered>
        <div className="row" style={{ marginTop: '-20px' }}>
          <TextSearchView sectionName={'heardAboutMethodSearch'} onUpdate={this.searchUpdated} />
        </div>
        <div>
          {heardAboutMethods.length > 0 && (
            <HeardAboutMethodTableView
              renderRow={renderRow}
              tableHead={tableHead}
              heardAboutMethods={heardAboutMethods}
              heardAboutMethodSelected={onHeardAboutMethodSelect}
              selectedHeardAboutMethodId={selectedHeardAboutMethodId}
            />
          )}
          {heardAboutMethods.length === 0 && (
            <EmptyView colorThemeClass="primary" iconClass="si si-magnifier" title="No results" subtitle="Check the search field" />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesContainer pageClicked={this.pageClicked} />
            </div>
          )}
        </div>
      </ContentBlockView>
    );
  }
}

HeardAboutMethodSelectView.propTypes = {
  title: PropTypes.string,
  getHeardAboutMethods: PropTypes.func.isRequired,
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHeardAboutMethodSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedHeardAboutMethodId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
};

HeardAboutMethodSelectView.defaultProps = {
  showSaleDetails: false,
  title: 'Heard About Methods',
};

export default HeardAboutMethodSelectView;
