import { isPresent, isEmail } from "utils/validation";

export const EditArticleFormValidator = {
  email: [
    {
      rule: isPresent,
      errorMessage: "What is your email?",
    },
    {
      rule: isEmail,
      errorMessage: "What is your email?",
    },
  ],
  oldPassword: [
    {
      rule: isPresent,
      errorMessage: "Looks like the password is missing",
    },
  ],
  newPassword: [
    {
      rule: isPresent,
      errorMessage: "Looks like the password is missing",
    },
  ],
  newPasswordConfirmation: [
    {
      rule: isPresent,
      errorMessage: "Looks like the password is missing",
    },
  ],
  totp: [
    {
      rule: isPresent,
      errorMessage: "Looks like the totp is missing",
    },
  ],
};
