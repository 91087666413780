export function generateHeadersForData (data) {
  const allLostReasons = getAllLostReasonsFromData(data)
  if (allLostReasons.length === 0) {
    return []
  }

  return allLostReasons.map(reason => reason.label)
}

export function generateTableDataForData (data) {
  const allLostReasons = getAllLostReasonsFromData(data)
  if (allLostReasons.length === 0) {
    return []
  }

  let rows = []

  data.forEach((entity, index) => {
    const label = entity.label
    const entityData = entity.data
    let rowValues = [label]
    let total = 0
    allLostReasons.forEach((lostReason) => {
      const id = lostReason.id
      const index = entityData.findIndex(entityDataItem => entityDataItem.id === id)
      const count = (index > -1) ? entityData[index].count : 0
      total = total + count
      rowValues = [...rowValues, count]
    })
    rowValues = [...rowValues, total]
    rows.push(rowValues)
  })

  return rows
}

function getAllLostReasonsFromData (data) {
  let allLostReasons = data.map(entity => entity.data)
  allLostReasons = [].concat.apply([], allLostReasons)
  allLostReasons = allLostReasons.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) === pos
  })
  return allLostReasons.sort((a, b) => b.id - a.id)
}
