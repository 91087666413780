import PropTypes from "prop-types";
import React, { Component } from "react";

import Modal from "react-bootstrap-modal";
import { FormFields } from "modules/components";

import { validator } from "../validator";

const { TextField } = FormFields;

export class NewTrialModal extends Component {
  state = {
    name: null,
    dayLength: 30,
  };

  handleChange = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  createTrialSelected = () => {
    const { createTrial } = this.props;
    const { name, dayLength } = this.state;
    createTrial(name, dayLength, this.onSubmitSuccess);
  };

  onSubmitSuccess = () => {
    this.setState({ name: null, dayLength: 30 });
    this.props.hideModal();
  };

  formIsValid = () => {
    const { name, dayLength } = this.state;
    if (!name || name.length < 1) {
      return false;
    }
    if (!Number.isInteger(Number(dayLength))) {
      return false;
    }
    return true;
  };

  render() {
    const { currentModal, hideModal, modalName, loading } = this.props;
    const { name, dayLength } = this.state;
    const blockStyle = loading ? "block block-opt-refresh" : "block";

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                Let's create a trial
              </h3>
            </div>
            <div className="block-content">
              <div className="push-30">
                <div className="row">
                  <div className="col-sm-12">
                    <TextField
                      name="name"
                      label="Trial Name *"
                      placeholder="30 Day Free Trial"
                      formFieldStyle=""
                      onChange={this.handleChange}
                      value={name}
                      validator={validator}
                    />
                  </div>
                  <div className="col-sm-12">
                    <TextField
                      name="dayLength"
                      label="Length (In Days) *"
                      placeholder="30"
                      formFieldStyle=""
                      onChange={this.handleChange}
                      value={dayLength}
                      validator={validator}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default pull-left" onClick={hideModal}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r"
              type="button"
              disabled={!this.formIsValid()}
              onClick={this.createTrialSelected}
            >
              <i className="fa fa-plus" /> Create trial
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

NewTrialModal.propTypes = {
  createTrial: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  currentModal: PropTypes.string,
};

export default NewTrialModal;
