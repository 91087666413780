import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import leadDetail from 'modules/components/LeadDetail';

import modal from 'modules/Stores/Modal';
import session from 'modules/Stores/Session';
import userData from 'modules/Stores/UserData';
import leadList from '../modules/leadsList';

function mapStateToProps(state) {
  const shallowLead = leadList.selectors.selectedLead(state);
  const leadSelector = leadDetail.details.selectors.getLatestLead(shallowLead);
  return {
    lead: leadSelector(state),
    company: userData.selectors.getCompany(state),
    detailsLoading: leadDetail.details.selectors.getLoading(state),
    listUpdateProtocol: leadList.listUpdateProtocol,
    pathToLeadDetail: 'manageLeads',
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state),
    timezone: session.selectors.getUserTimezone(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchHistoryForLeadAndPageNumber: leadDetail.history.actions.fetchHistoryForLeadAndPageNumber,
      fetchDetailForLead: leadDetail.details.actions.fetch,
      fetchCallsForLead: leadDetail.calls.actions.fetch,
      fetchAppointmentsForLead: leadDetail.appointments.actions.fetchAppointments,
      fetchToDosForLead: leadDetail.todos.actions.fetchToDosForLead,
      fetchNotesForLead: leadDetail.notes.actions.fetchNotesForLead,
      fetchEmailsForLead: leadDetail.emails.actions.fetchForLead,
      fetchCurrentTrialForLead: leadDetail.leadsTrials.actions.fetchCurrentTrial,
      updateLeadToStatus: leadDetail.actions.updateLeadToStatus,
      dismissLead: clearLead,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(leadDetail.components.LeadDetailView);

export const clearLead = (queryString) => {
  return (dispatch) => {
    dispatch(leadList.actions.deselect());
    dispatch(leadList.actions.showingQuickLeadView(false));
  };
};
