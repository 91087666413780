import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class LocationTableRow extends Component {

  render () {
    const { location, locationSelected, selected, showSaleDetails } = this.props
    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''
    return (
      <tr className={textClass + ' selectable ' + backgroundClass} onClick={() => locationSelected(location)}>
        <td>{location.admin_display_name}</td>
        <td className='text-center'>
          {showSaleDetails &&
            <span>
              <i className='si si-users push-5-r' />
              {location.active_lead_count}
            </span>
          }
        </td>
      </tr>
    )
  }
}

LocationTableRow.propTypes = {
  location: PropTypes.object.isRequired,
  locationSelected: PropTypes.func.isRequired,
  showSaleDetails: PropTypes.bool,
  selected: PropTypes.bool
}

LocationTableRow.defaultProps = {
  showSaleDetails: true
}
