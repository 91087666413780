/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export class StepView extends Component {
  handleSelect = (event) => {
    event.preventDefault();
    const { onSelect, salesFunnelStep, disabled } = this.props;
    if (disabled) return;
    onSelect(salesFunnelStep);
  };

  render() {
    const { salesFunnelStep, disabled } = this.props;
    const style = disabled ? "bg-gray" : "bg-primary selectable";
    return (
      <a
        className="block block-rounded block-link-hover3 text-center"
        onClick={this.handleSelect}
      >
        <div
          className={"block-content block-content-full text-center " + style}
        >
          {salesFunnelStep.name}
        </div>
      </a>
    );
  }
}

StepView.propTypes = {
  salesFunnelStep: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
