import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  secondsToTimeObject
} from './helpers'

import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers'

export default class CallScheduleStepView extends Component {

  actionString = () => {
    const { callScheduleStep } = this.props
    const smsId = callScheduleStep.text_message_template_id
    const emailId = callScheduleStep.email_template_id
    if (!smsId && !emailId) {
      return 'Call'
    }

    if (smsId && !emailId) {
      return 'Call & SMS'
    }

    if (!smsId && emailId) {
      return 'Call & email'
    }

    if (smsId && emailId) {
      return 'Call, SMS and email'
    }
  }

  render () {
    const { callScheduleStep, isCurrent, hasOccured } = this.props
    const timeObject = secondsToTimeObject(callScheduleStep.seconds_until_next_call)
    let style = (isCurrent) ? 'si si-call-out bg-success' : 'si si-call-end bg-default-light'
    style = (hasOccured) ? 'si si-call-end bg-gray' : style
    const badgeColor = (hasOccured) ? 'gray' : 'primary'
    const textDecoration = (hasOccured) ? 'line-through' : ''
    const currentString = (isCurrent) ? '(Current)' : ''
    return (
      <li>
        <div className='list-timeline-time'>
          <span className={'badge badge-' + badgeColor} style={{ marginTop: '-5px' }}>
            Step {callScheduleStep.step_number + 1} <br />
            {currentString}
          </span>
        </div>
        <i className={'list-timeline-icon ' + style} />
        <div className='list-timeline-content' style={{ textDecoration: textDecoration }}>
          <p className='font-w600'>{this.actionString()}</p>
          <p className='font-s13'>
            {
              pluralizationHelper(timeObject.selectedTimeType, timeObject.timeToWait)
            } later
          </p>
        </div>
      </li>
    )
  }
}

CallScheduleStepView.propTypes = {
  callScheduleStep: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  hasOccured: PropTypes.bool.isRequired
}
