import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";
import regions from "modules/Stores/Regions";
import * as regionApiActions from "modules/Stores/Regions/apiActions";
import regionList from "../modules/regions";
import newRegion from "../modules/newRegion";

function mapStateToProps(state) {
  return {
    loading: regions.selectors.getLoading(state),
    regions: regions.selectors.getRegions(state),
    currentModal: modal.selectors.getCurrentModal(state),
    newRegionModalName: newRegion.constants.MODAL_NAME,
    selectedRegionId: regionList.selectors.getSelectedRegionId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal,
      getRegions: regionApiActions.getRegions,
      deleteRegionWithId: regionList.actions.deleteRegionWithId,
      selectRegionId: regionList.actions.selectRegionId
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(regionList.components.ListRegionsView);
