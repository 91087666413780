import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import locations from 'modules/Stores/Locations'

import perfectGym from '../modules/configuration/modules/perfectGym'

import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    loading: modal.selectors.getModalLoading(state),
    tabIndex: perfectGym.selectors.getTabIndex(state),
    location: selectedLocation,
    errors: modal.selectors.getModalErrors(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    resetForm: perfectGym.actions.reset,
    prefillFormForIntegration: perfectGym.actions.prefillFormForIntegration
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(perfectGym.components.ConfigurationModalView)
