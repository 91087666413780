import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validator'

const DEAL_ACTION_HANDLERS = {
  [actionTypes.PREFIL_DATA_FOR_DEAL]: (state, action) => {
    return initialStateForDeal(action.deal)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, actions) => {
    return { ...state, loading: actions.loading }
  },
  [actionTypes.SET_ERRORS]: (state, actions) => {
    return { ...state, errors: actions.errors }
  },
  [actionTypes.SET_SELECTED_DEAL_ID]: (state, action) => {
    return { ...state, selectedDealId: action.selectedDealId }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialStateForDeal(null)
  }
}

const payloadKeys = {
  title: 'title'
}

function initialStateForDeal (deal) {
  const fields = generateFieldsForKeys(deal, fieldNames, payloadKeys, validator)
  const dealId = (deal) ? deal.id : null
  return {
    loading: false,
    errors: null,
    selectedDealId: dealId,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function reducer (state = initialStateForDeal(null), action) {
  const handler = DEAL_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
