import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import CallScheduleStepView from './CallScheduleStepView';

import SweetAlert from 'react-bootstrap-sweetalert';

class CallScheduleStepsModal extends Component {
  state = {
    showDeleteWarning: false,
  };

  showRemoveWarningPopup = () => {
    this.setState({ ...this.state, showDeleteWarning: true });
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showDeleteWarning: false });
  };

  removeFromSchedule = () => {
    const { removeFromSchedule, lead } = this.props;
    removeFromSchedule(lead);
    this.dismissPopups();
  };

  loadSteps = () => {
    const { fetchCallScheduleSteps, callSchedule } = this.props;
    fetchCallScheduleSteps(callSchedule.call_schedule_id);
  };

  render() {
    const { currentModal, modalName, callSchedule, hideModal, callScheduleSteps, loading } = this.props;

    const stepViews = callScheduleSteps.map((step, index) => {
      const isCurrent = step.id === callSchedule.id;
      const hasOccured = step.step_number < callSchedule.step_number;
      return <CallScheduleStepView key={index} callScheduleStep={step} isCurrent={isCurrent} hasOccured={hasOccured} />;
    });

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal} onEnter={this.loadSteps}>
          <div className={blockStyle} style={{ marginBottom: '0px' }}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                {callSchedule.schedule_name}
              </h3>
            </div>
            <div className="row">
              <ul className="list list-timeline pull-t">
                <li>
                  <i className="si si-cloud-download list-timeline-icon bg-gray" />
                  <div className="list-timeline-content">
                    <p className="font-w600" style={{ textDecoration: 'line-through' }}>
                      Enquires at club
                    </p>
                  </div>
                </li>
                {stepViews}
                <li>
                  <i className="list-timeline-icon bg-warning fa fa-warning" />
                  <div className="list-timeline-content">
                    <p className="font-w600">Becomes inactive</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-warning pull-left" onClick={this.showRemoveWarningPopup}>
              Remove from schedule
            </button>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Done
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showDeleteWarning && (
          <div>
            <SweetAlert
              warning
              showCancel
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="warning"
              confirmBtnText="Remove from schedule"
              title="Are you Sure?"
              onConfirm={this.removeFromSchedule}
              onCancel={this.dismissPopups}
            >
              This lead will be removed from the call schedule. Follow up calls will no longer be automated.
            </SweetAlert>
          </div>
        )}
      </div>
    );
  }
}

CallScheduleStepsModal.propTypes = {
  fetchCallScheduleSteps: PropTypes.func.isRequired,
  callSchedule: PropTypes.object.isRequired,
  callScheduleSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  removeFromSchedule: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default CallScheduleStepsModal;
