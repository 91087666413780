import styled, { css } from 'styled-components';

import {
  color,
  DisplayProps,
  display,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  overflow,
  OverflowProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  typography,
  TextStyleProps,
  background,
  BackgroundColorProps,
  BackgroundProps,
  grid,
  GridProps,
  OpacityProps,
  opacity,
} from 'styled-system';

export interface BoxProps
  extends SpaceProps,
    FlexboxProps,
    DisplayProps,
    LayoutProps,
    OverflowProps,
    PositionProps,
    BorderProps,
    ShadowProps,
    TextStyleProps,
    BackgroundColorProps,
    BackgroundProps,
    OpacityProps,
    GridProps {
  centerRow?: boolean;
  isFlex?: boolean;
  cursor?: string;
  border?: string;
  backgroundShadow?: string;
  w100?: boolean;
  h100?: boolean;
  s100?: boolean;
  row?: boolean;
  column?: boolean;
  clickable?: boolean;
  disabled?: boolean;
  hoverFill?: string;
  focusFill?: string;
  center?: boolean;
  aCenter?: boolean;
  aStart?: boolean;
  aEnd?: boolean;
  jStart?: boolean;
  jCenter?: boolean;
  jEnd?: boolean;
  jSpaceBtw?: boolean;
  jSpaceAround?: boolean;
  doesWrap?: boolean;
  color?: string;
  onClick?: () => void;
  isLoading?: boolean;
  readOnly?: boolean;
}

export const Box = styled.div<BoxProps>`
  display: ${(props) => (props.isFlex ? `flex` : props.display)};
  cursor: ${(props) => props.cursor && props.cursor};
  box-sizing: border-box;
  ${display}
  ${opacity}
  ${color}
  ${layout}
  ${space}
  ${overflow}
  ${flexbox}
  ${grid}
  ${border}
  ${shadow}
  ${position}
  ${typography}
  ${background}
  transition: opacity 0.1s ease-in-out;
  ${(props) =>
    props.backgroundShadow &&
    css`
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s ease-in-out;
    `}
  ${(props) =>
    props.centerRow &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `}
  ${(props) =>
    props.w100 &&
    css`
       {
        width: 100%;
      }
    `}
  ${(props) =>
    props.h100 &&
    css`
       {
        height: 100%;
      }
    `}  
  ${(props) =>
    props.s100 &&
    css`
       {
        width: 100%;
        height: 100%;
      }
    `}        
  ${(props) =>
    props.row &&
    css`
       {
        display: flex;
        flex-direction: row;
      }
    `}
  ${(props) =>
    props.column &&
    css`
       {
        flex-direction: column;
      }
    `}    
  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}  
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.25;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    `}
  ${(props) =>
    props.readOnly &&
    css`
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    `}    
  ${(props) =>
    props.hoverFill &&
    css`
      &:hover {
        background-color: ${props.hoverFill};
      }
    `}
  ${(props) =>
    props.focusFill &&
    css`
      &:focus {
        background-color: ${props.focusFill};
      }
    `}
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}    
  ${(props) =>
    props.aCenter &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.aStart &&
    css`
      align-items: flex-start;
    `}    
  ${(props) =>
    props.aEnd &&
    css`
      align-items: flex-end;
    `}
  ${(props) =>
    props.jStart &&
    css`
      justify-content: flex-start;
    `}        
  ${(props) =>
    props.jCenter &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.jEnd &&
    css`
      justify-content: flex-end;
    `}
  ${(props) =>
    props.jSpaceBtw &&
    css`
      justify-content: space-between;
    `}
  ${(props) =>
    props.jSpaceAround &&
    css`
      justify-content: space-around;
    `}   
  ${(props) =>
    props.doesWrap &&
    css`
      flex-wrap: wrap;
    `}
`;

Box.displayName = 'Box';
