import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getProductName } from 'modules/Stores/UserData/selectors'

import FormView from '../components/VerifyLink/FormView'

import * as mindbodySelectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    data: mindbodySelectors.getData(state),
    productName: getProductName(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
