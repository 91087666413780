import PropTypes from 'prop-types'
import React, { Component } from 'react'
import OwnerLocationDashboardContainer from '../containers/RootContainers/OwnerLocationDashboardContainer'
import ClubsManagementContainer from '../containers/ClubsManagementContainer'

import SalespersonDashboardContainer from '../containers/RootContainers/SalesPersonDashboardContainer'
import ReceptionDashboardContainer from '../containers/RootContainers/ReceptionDashboardContainer'

import * as dashboardTypes from '../modules/Structure/constants'

export class LocationSelectDashboardView extends Component {

  render () {
    const { user, selectedLocation, permissions, dashboardType } = this.props
    const dashboardForUser = (user) => {
      switch (user.user_role.name) {
        case 'Owner':
        case 'Salesmanager':
        case 'Franchise Owner':
        case 'Admin':
          if (permissions.multi_user) {
            if (dashboardType === dashboardTypes.DASHBOARD_MANAGER_TYPE) {
              return <OwnerLocationDashboardContainer />
            } else if (dashboardType === dashboardTypes.DASHBOARD_SALES_TYPE) {
              return <SalespersonDashboardContainer />
            }
          }
          return <SalespersonDashboardContainer />
        case 'Salesperson':
          return <SalespersonDashboardContainer />
        case 'Reception':
          return <ReceptionDashboardContainer />
        default:
          break
      }
    }

    return (
      <div>
        {selectedLocation &&
          dashboardForUser(user)
        }
        {!selectedLocation &&
          <ClubsManagementContainer />
        }
      </div>
    )
  }
}

LocationSelectDashboardView.propTypes = {
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  dashboardType: PropTypes.string.isRequired,
  selectedLocation: PropTypes.object
}
