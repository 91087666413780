import PropTypes from "prop-types";
import React, { Component } from "react";
import { selectReportContainer } from "../helpers";
import styled from 'styled-components';

export class FiltersView extends Component {

  updateAutomaticReport = () => {
    const { updateAutomaticReportFilters, queryString, automaticReport, compareOption, calculatingOption } = this.props
    updateAutomaticReportFilters(queryString, automaticReport.id, calculatingOption, compareOption)
  }

  render() {
    const { automaticReport, loading } = this.props;
    const ReportFilterContainer = selectReportContainer(automaticReport.type);
    
    return ReportFilterContainer ? (
        <div className={`block block-rounded block-bordered ${loading ? "block-opt-refresh" : ''}`}>
          <div className="block-header">
            <h3 className="block-title">Filters</h3>
          </div>
          <div className="block-content push-20">
            <ReportFilterContainer {...this.props} />
            <SaveButton className='btn btn-primary'
              type='button'
              onClick={this.updateAutomaticReport}>Update Filters
            </SaveButton>
          </div>
        </div>
    ) : (
      <div />
    );
  }
}

FiltersView.propTypes = {
  automaticReport: PropTypes.object,
  updateAutomaticReportFilters: PropTypes.func.isRequired,
  queryString: PropTypes.string,
  compareOption: PropTypes.string,
  calculatingOption: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

export default FiltersView;


const SaveButton = styled.button`
  margin: 15px 0px 20px 0px;
`;