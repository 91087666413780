import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export function updateFilters(filterData) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    filterData,
  };
}

export function clearAllFilters() {
  return {
    type: actionTypes.CLEAR_ALL_FILTERS,
  };
}

export function showFilters() {
  return {
    type: actionTypes.SHOW_FILTERS,
  };
}

export function hideFilters() {
  return {
    type: actionTypes.HIDE_FILTERS,
  };
}

export function searchTermUpdated(searchTerm) {
  return {
    type: actionTypes.LOCATION_SEARCH_TERM_UPDATED,
    searchTerm,
  };
}

export function updateCurrentPage(pageNumer) {
  return {
    type: actionTypes.LOCATION_CURRENT_PAGE_UPDATED,
    pageNumer,
  };
}

export function setLocationIds(locationIds) {
  return {
    type: actionTypes.SET_SELECTED_LOCATION_IDS,
    locationIds,
  };
}

export function showNoUserError(displayName) {
  return () => {
    notify.error(displayName + ' is not a user in the selected clubs');
  };
}

export const getLeadsCountWithQueryAndPageNumber = (queryString) => {
  return (dispatch) => {
    dispatch(fetchingCounts());
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(fetchLeadsCountSuccess(payload));
    }

    const encodedQueryString = encodeURI(queryString);

    return request('lead_counts?' + encodedQueryString, GET, null, onSuccess, onFailure);
  };
};

function fetchingCounts() {
  return {
    type: actionTypes.FETCHING_COUNTS,
  };
}

function fetchLeadsCountSuccess(counts) {
  return {
    type: actionTypes.FETCH_COUNTS_SUCCESS,
    counts,
  };
}

export function presetUserInFilters(user, data) {
  return (dispatch) => {
    if (!user.show_own_data_by_default) {
      return;
    }
    const currentUserHash = { value: true, display: user.display_name, isValid: true };
    const userIdData = { fields: { [user.id]: currentUserHash } };
    const newSections = { ...data, assigned_to_ids: userIdData };
    dispatch(updateFilters(newSections));
    dispatch(setHasPrefilledUser(true));
  };
}

function setHasPrefilledUser(hasPrefilledUser) {
  return {
    type: actionTypes.SET_HAS_PREFILLED_USER,
    hasPrefilledUser,
  };
}
