import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import previousImports from '../modules/previousImports'

function mapStateToProps (state) {
  return {
    imports: previousImports.selectors.getImports(state),
    loading: previousImports.selectors.getLoading(state),
    pageCount: previousImports.selectors.getPageCount(state),
    totalCount: previousImports.selectors.getTotalCount(state),
    currentPage: previousImports.selectors.getCurrentPage(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    reverseImportWithId: previousImports.actions.reverseImportWithId,
    getImportsWithPageNumber: previousImports.actions.getImportsWithPageNumber
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(previousImports.components.PreviousImportsView)
