import React, { Component } from "react";
import * as integrationApi from "modules/api/thirdPartyIntegration";

export class FormView extends Component {
  state = {
    loading: false
  };

  startLogin = async () => {
    //create integration
    const {
      updateIntegration,
      prefillFormForIntegration,
      location
    } = this.props;

    this.setState({ loading: true });
    const body = { name: "booker", direction: "outgoing" };
    const response = await integrationApi.createIntegration(location.id, body);

    updateIntegration(response.third_party_integration);
    prefillFormForIntegration(response.third_party_integration);
    const authUrl = response.third_party_integration.auth_url;
    this.setState({ loading: false });

    window.open(authUrl, "_self");
  };

  render() {
    const { loading } = this.state;
    const buttonTitle = loading ? "Please wait" : "Log into Booker";
    const loginSymbol = loading
      ? "fa fa-circle-o-notch fa-spin pull-right"
      : "si si-login pull-right";
    return (
      <div>
        <div className="row push">
          <div className="col-md-12">
            <h2 className="h4 push-10">Integrate Booker</h2>
            <p>
              Connect GymLeads with your booker account. Start by logging into
              Booker by clicking the Log In button below
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-sm-offset-3">
          <button
            type="button"
            className="btn btn-block btn-primary"
            disabled={loading}
            onClick={this.startLogin}
          >
            <i className={loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>
    );
  }
}

export default FormView;
