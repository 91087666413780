import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

class NavLink extends React.Component {
  isActive = () => {
    const { to, currentPath } = this.props;
    return to.indexOf(currentPath) >= 0;
  };

  render() {
    const { to, listItemClassName } = this.props;
    const isActive = this.isActive();
    const activeClassName = isActive ? 'active' : '';
    const listClassName = listItemClassName || '';

    return (
      <li className={activeClassName + listClassName}>
        <Link to={to}>{this.props.children}</Link>
      </li>
    );
  }
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  listItemClassName: PropTypes.string,
  currentPath: PropTypes.string.isRequired,
};

export default NavLink;
