import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import session from 'modules/Stores/Session'
import emailMapSelect from '../modules/emailMapSelect'
import emailMapResults from '../modules/emailMapResults'

function mapStateToProps (state) {
  const emailMap = emailMapSelect.selectors.selectedEmailMap(state)
  const location = (emailMap) ? emailMap.default_location : null
  return {
    emailMapSubmission: emailMap.latest_email_lead_submission,
    timezone: session.selectors.getUserTimezone(state),
    lead: emailMapResults.selectors.leadForEmailMap(emailMap),
    defaultLocation: location,
    loading: emailMapResults.selectors.getLoading(state),
    errors:  emailMapResults.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getResultsForEmailMap: emailMapResults.actions.getResultsForEmailMap
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(emailMapResults.components.EmailMapResultsView)
