import * as actionTypes from './actionTypes'

const CLUB_MANAGEMENT_ACTION_HANDLERS = {
  [actionTypes.SET_SELECTED_LOCATION]: (state, action) => {
    return { ...state, selectedLocation: action.selectedLocation }
  }
}

const initialState = {
  selectedLocation: null
}

export default function reducer (state = initialState, action) {
  const handler = CLUB_MANAGEMENT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
