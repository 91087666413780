/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-bootstrap-modal";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView,
} from "modules/components";

export class SelectLeadGoalModal extends Component {
  UNSAFE_componentWillMount() {
    const { leadGoals } = this.props;
    if (leadGoals.length > 0) {
      return;
    }
    this.getLeadGoalsWithPageNumber(1);
  }

  getLeadGoalsWithPageNumber = (pageNumber) => {
    const { getLeadGoals } = this.props;
    getLeadGoals(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadGoalsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadGoals } = this.props;
    getLeadGoals(1, searchTerm);
  };

  indexClicked = (selectedGoal) => {
    const { lead, updateLead, listUpdateProtocol } = this.props;
    const goal = { reason_for_interest: selectedGoal.goal_name };
    updateLead(lead, goal, listUpdateProtocol.updateLeadDetails);
  };

  render() {
    const {
      lead,
      loading,
      currentModal,
      hideModal,
      modalName,
      leadGoals,
      pageDetails,
    } = this.props;

    const pageCount = pageDetails.pageCount;

    const goals = leadGoals.map((goal, index) => {
      const bgStyle =
        goal.goal_name === lead.reason_for_interest
          ? "bg-primary-light"
          : "bg-primary";
      return (
        <div key={index} className="col-lg-6">
          <a
            className="block block-link-hover2 selectable"
            onClick={() => this.indexClicked(goal)}
          >
            <div
              className={"block-content block-content-full clearfix " + bgStyle}
            >
              <span className="h4 text-white-op">{goal.goal_name}</span>
            </div>
          </a>
        </div>
      );
    });

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={"block"}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                What is {lead.given_name}'s main goal?
              </h3>
            </div>
            <ContentBlockView loading={loading} bordered>
              <div className="row" style={{ marginTop: "-20px" }}>
                <TextSearchView
                  sectionName={"leadGoalSearch"}
                  onUpdate={this.searchUpdated}
                />
              </div>
              {leadGoals.length > 0 && (
                <div>
                  <div className="row">{goals}</div>
                </div>
              )}
              {leadGoals.length === 0 && (
                <EmptyView
                  colorThemeClass="primary"
                  iconClass="si si-magnifier"
                  title="No results"
                  subtitle="Check the search field"
                />
              )}
              {pageCount > 1 && (
                <div className="text-center push">
                  <PagesView
                    pageDetails={pageDetails}
                    pageClicked={this.pageClicked}
                  />
                </div>
              )}
            </ContentBlockView>
          </div>
        </Modal>
      </div>
    );
  }
}

SelectLeadGoalModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  leadGoals: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default SelectLeadGoalModal;
