import { isInteger, isPresent } from "utils/validation";

export const validator = {
  minPasswordLength: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  minPasswordLetters: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  minPasswordNumbers: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  minPasswordSpecial: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  passwordCanContainUsername: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  disableUserAccountAfterDays: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  lockScreenAfter: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  maximumNumberOfAttempts: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  previousPasswordsToPrevent: [
    {
      rule: isPresent,
      errorMessage:
        "There should be a value here (enter 0 to ignore this rule)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  rememberMeLength: [
    {
      rule: isPresent,
      errorMessage: "There should be a value here (enter 0 to use the default)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
  passwordExpiresAfterMonths: [
    {
      rule: isPresent,
      errorMessage: "There should be a value here (enter 0 to use the default)",
    },
    {
      rule: isInteger,
      errorMessage: "This should be a valid number",
    },
  ],
};
