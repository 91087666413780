import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import NewTrialModal from '../components/NewTrialModal'
import modal from 'modules/Stores/Modal'
import { NEW_TRIAL_MODAL } from '../constants'

import trials from 'modules/Stores/Trials'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: NEW_TRIAL_MODAL
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    createTrial: trials.actions.createTrial
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTrialModal)
