import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export function updateFilters(filterData) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    filterData,
  };
}
export function showFilters(showingMore) {
  return {
    type: actionTypes.SHOW_FILTERS,
    showingMore,
  };
}

export function setCompareOption(compareOption) {
  return {
    type: actionTypes.SET_COMPARE_VALUE,
    compareOption,
  };
}

export function showNoUserError(displayName) {
  notify.error(`${displayName} is not a user in the selected clubs`);
}
