import PropTypes from "prop-types";
import React, { Component } from "react";

export class LeadSourceTableRow extends Component {
  render() {
    const { leadSource, leadSourceSelected, selected } = this.props;
    const backgroundClass = selected ? "bg-primary" : "";
    const textClass = selected ? "text-white" : "";
    return (
      <tr
        className={textClass + " selectable " + backgroundClass}
        onClick={() => leadSourceSelected(leadSource)}
      >
        <td>{leadSource.source_name}</td>
      </tr>
    );
  }
}

LeadSourceTableRow.propTypes = {
  leadSource: PropTypes.object.isRequired,
  leadSourceSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

LeadSourceTableRow.defaultProps = {
  showSaleDetails: true
};
