import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import FormView from '../components/SelectPage/FormView'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state, props) {
  const facebookUser = selectors.getFacebookUser(state) || null
  const accessToken = facebookUser ? facebookUser.accessToken : null
  return {
    errors: modal.selectors.getModalErrors(state),
    facebookUserToken: accessToken,
    facebookPages: selectors.getFacebookPages(state),
    selectedPageId: selectors.getSelectedPageId(state),
    tabIndex: selectors.getTabIndex(state),
    facebookUser: selectors.getFacebookUser(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchFacebookPages: actions.fetchFacebookPages,
    setFacebookPages: actions.setFacebookPages,
    setSelectedPageId: actions.setSelectedPageId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
