import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import userData from 'modules/Stores/UserData'
import * as editRegionActions from '../actions'
import EditRegionModalView from '../components/EditRegionModalView'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    updateRegion: editRegionActions.updateRegion,
    updateRegionInList: userData.actions.updateRegion
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRegionModalView)
