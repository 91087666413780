import React from "react";
import PropTypes from "prop-types";
import { humanReadableReportName, reportTagColor } from "../../filters/helpers";

const TableRow = ({ automaticReport, selectAutomaticReport, selected }) => {
  const bgClass = selected ? "bg-primary" : "";
  const textClass = selected ? "text-white" : "";
  const bgColor = reportTagColor(automaticReport.type);
  const labelText = humanReadableReportName(automaticReport.type);

  return (
    <tr
      className={textClass + " selectable " + bgClass}
      onClick={() => selectAutomaticReport(automaticReport)}
    >
      <td>{automaticReport.name}</td>
      <td className="text-center">
        <span className="label" style={{ backgroundColor: bgColor }}>
          {labelText}
        </span>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  automaticReport: PropTypes.object.isRequired,
  selectAutomaticReport: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

export default TableRow;
