import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const fetchWithQueryString = (queryString) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setLostSteps(payload.steps.lost_from_steps));
      dispatch(setInactiveSteps(payload.steps.inactive_from_steps));
    }
    let url = 'lost_leads_reports/steps';
    if (queryString) {
      url = url + '?' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setLostSteps(lostSteps) {
  return {
    type: actionTypes.SET_LOST_STEPS,
    lostSteps,
  };
}

function setInactiveSteps(inactiveSteps) {
  return {
    type: actionTypes.SET_INACTIVE_STEPS,
    inactiveSteps,
  };
}
