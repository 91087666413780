import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import * as filterActions from '../filters/actions'

export const fetchAutomaticReportsWithPage = (pageNumber, search) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    dispatch(reset())

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }
    
    function onSuccess (payload) {
      dispatch(setAutomaticReports(payload.automatic_reports))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setLoading(false))
    }
    
    let url = `automatic_reports`
    url = pageNumber ? `${url}?page[number]=${pageNumber}` : `${url}?page[number]=1`
    if (search) { url = `${url}&search=${search}`}
    return request(url, GET, null, onSuccess, onFailure)
  }
}


export function selectAutomaticReport (automaticReport) {
  return dispatch => {
    dispatch(filterActions.setFilters(automaticReport))
    dispatch({
      type: actionTypes.SELECT_AUTOMATIC_REPORT,
      automaticReport
    })
  }
}

function setAutomaticReports(automaticReports) {
  return {
    type: actionTypes.SET_AUTOMATIC_REPORTS,
    automaticReports
  };
}

export function updateAutomaticReport(automaticReport) {
  return {
    type: actionTypes.UPDATE_AUTOMATIC_REPORT,
    automaticReport
  };
}

export function updateAutomaticReportSchedule(automaticReportSchedule) {
  return {
    type: actionTypes.UPDATE_AUTOMATIC_REPORT_SCHEDULE,
    automaticReportSchedule
  };
}

export function deleteAutomaticReport(automaticReportId) {
  return {
    type: actionTypes.DELETE_AUTOMATIC_REPORT,
    automaticReportId
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}
