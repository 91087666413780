import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import * as arrayUtils from 'utils/arrayUtils';

import MultiUserSelectViewContainer from '../containers/MultiUserSelectViewContainer';

export class MultiUserSelectModal extends Component {
  state = {
    selectedUserIds: Object.keys(this.props.data.fields).map((string) => parseInt(string)),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldData = this.props.data;
    const newData = nextProps.data;

    if (!oldData || !newData) {
      return;
    }
    const oldIds = Object.keys(oldData.fields) || [];
    const newIds = Object.keys(newData.fields) || [];

    if (arrayUtils.arraysEqual(oldIds, newIds)) {
      return;
    }
    this.setState({
      selectedUserIds: newIds.map((string) => parseInt(string)),
    });
  }

  selectUser = (user) => {
    const newSelectedUserIds = [...this.state.selectedUserIds, user.id];
    this.setState({ ...this.state, selectedUserIds: newSelectedUserIds });
  };

  deselectUser = (user) => {
    const index = this.state.selectedUserIds.findIndex((userId) => parseInt(userId) === parseInt(user.id));
    const newSelectedUserIds = [...this.state.selectedUserIds.slice(0, index), ...this.state.selectedUserIds.slice(index + 1)];
    this.setState({ ...this.state, selectedUserIds: newSelectedUserIds });
  };

  useAllUsers = () => {
    this.setState({ ...this.state, selectedUserIds: [] });
    const newSectionData = { fields: {} };
    this.props.onUpdate(newSectionData, this.props.sectionName);
    this.cleanAndHide();
  };

  updateUserIds = () => {
    let fields = {};
    this.state.selectedUserIds.forEach((userId) => {
      const object = { value: true, isValid: true };
      fields = { ...fields, [userId]: object };
    });
    const newSectionData = { fields: fields, isValid: true };
    this.props.onUpdate(newSectionData, this.props.sectionName);
    this.cleanAndHide();
  };

  cleanAndHide = () => {
    this.setState({ selectedUserIds: [] });
    this.props.hideModal();
  };

  render() {
    const { currentModal, hideModal, buttonTitle, modalName } = this.props;
    const disabled = this.state.selectedUserIds.length === 0;

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select users
            </h3>
          </div>
          <div className="block-content">
            <MultiUserSelectViewContainer
              get={this.props.getUsers}
              {...this.props}
              selectedUserIds={this.state.selectedUserIds}
              selectUser={this.selectUser}
              deselectUser={this.deselectUser}
            />
          </div>
        </div>

        <Modal.Footer>
          <button className="btn btn-primary pull-left" onClick={this.useAllUsers}>
            All Users
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.props.hideModal}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={disabled} onClick={this.updateUserIds}>
            <i className="fa fa-building-o" /> {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MultiUserSelectModal.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  buttonTitle: PropTypes.string,
  currentModal: PropTypes.string,
  singleSelect: PropTypes.bool.isRequired,
  filterFunction: PropTypes.func,
  modalName: PropTypes.string.isRequired,
};

MultiUserSelectModal.defaultProps = {
  buttonTitle: 'Filter by selected',
  singleSelect: false,
};

export default MultiUserSelectModal;
