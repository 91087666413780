import { createSelector } from "reselect";
import { placeholdersForCountry } from "modules/Helpers/FormatHelpers";
import securityPolicy from "../SecurityPolicy";
import locationsStore from "modules/Stores/Locations";
import moment from "moment";

export const getCountries = (state) => state.userData.countries;

// Sales Funnel
export const getSalesFunnelSteps = (state) => state.userData.salesFunnel.steps;

export const firstStep = createSelector(
  [getSalesFunnelSteps],
  (salesFunnelSteps) => {
    return salesFunnelSteps[0];
  }
);

// regions
export const getRegions = (state) => state.userData.regions;

export function regionForIdFactory(regionId) {
  return createSelector([getRegions], (regions) => {
    if (regions && regions.length > 0) {
      return regionForId(regions, regionId);
    }
  });
}

function regionForId(regions, regionId) {
  const index = regions.findIndex(
    (region) => parseInt(region.id) === parseInt(regionId)
  );
  return regions[index];
}

export function integrationWithAppointmentsForLocation(location) {
  if (location === null || location === undefined) {
    return null;
  }
  if (
    location.third_party_integrations === null ||
    location.third_party_integrations === undefined
  ) {
    return null;
  }
  const integrations = location.third_party_integrations
    .filter((integration) => integration.direction === "outgoing")
    .filter((integration) => integration.integration_permission_set)
    .filter(
      (integration) =>
        integration.integration_permission_set.appointments_from_classes ===
        true
    );
  return integrations[0];
}

// loading is complete

export const getSalesFunnel = (state) => state.userData.salesFunnel;
export const getCompany = (state) => state.userData.company;
export const getCompanyTargetMetrics = (state) =>
  state.userData.company.default_target_rule_set;
export const getMembership = (state) =>
  state.userData.company.default_membership;
export const getMembershipPermissions = (state) =>
  state.userData.company.default_membership.membership_permission;
export const getUserRoles = (state) => state.userData.userRoles;
export const getLocationMetrics = (state) => state.userData.locationMetrics;
export const getWhitelabelProfile = (state) =>
  state.userData.company.whitelabel_profile;
export const getWhitelabelProfileLogo = (state) =>
  state.userData.company.whitelabel_profile.whitelabel_profile_logo;

export const getProductName = createSelector(
  [getWhitelabelProfile],
  (whitelabelProfile) => {
    if (whitelabelProfile && whitelabelProfile.name) {
      return whitelabelProfile.name;
    } else {
      return "Gymleads";
    }
  }
);

export const loadingComplete = createSelector(
  [
    locationsStore.selectors.getInitialLoadComplete,
    getCompany,
    getSalesFunnel,
    getUserRoles,
    securityPolicy.selectors.getSecurityPolicy,
  ],
  (locationsAreLoaded, company, salesFunnel, userRoles, securityPolicy) => {
    if (locationsAreLoaded !== true) return false;
    if (company === {}) return false;
    if (!salesFunnel.steps) return false;
    if (userRoles.length === 0) return false;
    if (!securityPolicy) return false;
    return true;
  }
);

export const locationLoadComplete = createSelector(
  [
    locationsStore.selectors.getInitialLoadComplete,
    getCompany,
    getSalesFunnel,
    getUserRoles,
    getLocationMetrics,
  ],
  (locationsAreLoaded, company, salesFunnel, userRoles, locationMetrics) => {
    if (locationsAreLoaded !== true) return false;
    if (company === {}) return false;
    if (salesFunnel === {}) return false;
    if (userRoles.length === 0) return false;
    if (locationMetrics === {}) return false;
    return true;
  }
);

export function userRoleForIdSelector(userRoleId) {
  return createSelector([getUserRoles], (userRoles) => {
    const index = userRoles.findIndex(
      (userRole) => parseInt(userRole.id) === parseInt(userRoleId)
    );
    return userRoles[index];
  });
}

export const allSalesFunnelStepLinks = createSelector(
  [getSalesFunnelSteps],
  (salesFunnelSteps) => {
    const links = [];
    salesFunnelSteps.forEach((step) => {
      links.push({
        toStep: step,
      });
    });

    salesFunnelSteps.forEach((step, index) => {
      if (index === 0) {
        return;
      }
      const previousStep = salesFunnelSteps[index - 1];
      links.push({
        fromStep: previousStep,
        toStep: step,
      });
    });

    return links;
  }
);

export const companyName = createSelector([getCompany], (company) => {
  return company.title;
});

// schedules

export const getSchedules = (state) => state.userData.schedules;

export function scheduleForUserLocationFactory(userId, locationId) {
  return createSelector([getSchedules], (schedules) => {
    if (schedules && schedules.length > 0) {
      const index = schedules.findIndex((schedule) => {
        if (
          schedule.location_id === locationId &&
          schedule.user_id === userId
        ) {
          return true;
        }
        return false;
      });
      return schedules[index];
    }
  });
}

export function isAvailableForScheduleInTimezone(schedule) {
  if (!schedule) {
    return true;
  }
  if (!schedule.schedule_dates.length === 0) {
    return true;
  }

  const now = moment().utc();
  const length = schedule.schedule_dates.length;

  for (let i = 0; i < length; i++) {
    const slot = schedule.schedule_dates[i];
    const slotStartMoment = moment(slot.start);
    if (slotStartMoment.day() !== now.day()) {
      continue;
    }
    const nowInWeekMoment = moment()
      .set({
        year: slotStartMoment.year(),
        month: slotStartMoment.month(),
        date: slotStartMoment.date(),
      })
      .utc();
    if (nowInWeekMoment.isBetween(slot.start, slot.end)) {
      return true;
    }
  }
  return false;
}

export function formatsForLocationId(locationId) {
  return createSelector(
    [locationsStore.selectors.getLocations, getCountries],
    (locations, countries) => {
      const location = locationForId(locations, locationId);
      const countryId = location ? location.country_id : 1;
      return placeholdersForCountry(countryId, countries);
    }
  );
}

export const defaultFormats = createSelector(
  [locationsStore.selectors.getDefaultLocation, getCountries],
  (defaultLocation, countries) => {
    const countryId = defaultLocation ? defaultLocation.country_id : 1;
    return placeholdersForCountry(countryId, countries);
  }
);

function locationForId(locations, locationId) {
  const index = locations.findIndex(
    (location) => parseInt(location.id) === parseInt(locationId)
  );
  return locations[index];
}
