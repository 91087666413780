import { NAME } from './constants'

export const LOADING = NAME + '/LOADING'
export const LOAD_SUCCESS = NAME + '/LOAD_SUCCESS'
export const LOAD_FAILED = NAME + '/LOAD_FAILED'

export const RESET = NAME + '/RESET'

export const SET_SHOWING = NAME + '/SET_SHOWING'
export const SET_TAGS = NAME + '/SET_TAGS'
export const RELOAD_EMAIL_MAP = NAME + '/RELOAD_EMAIL_MAP'
export const UPDATE_FORM_DATA = NAME + '/UPDATE_FORM_DATA'
