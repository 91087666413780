import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField } from '../FormFields'

const FIELD_NAME = 'search'

export default class TextSearchView extends Component {

  state = {
    searchTerm: '',
    searchTermApplied: true,
    previousSearchTerm: ''
  }

  handleInput = (fieldName, value, isValid) => {
    const searchTermApplied = !(value !== this.state.previousSearchTerm)
    let searchTerm = (value) || ''
    this.setState({ ...this.state, searchTerm: searchTerm, searchTermApplied: searchTermApplied })
  }

  shouldApplySearchTerm = () => {
    const { previousSearchTerm, searchTerm } = this.state
    if (!searchTerm && !previousSearchTerm) {
      return false
    }
    return true
  }

  clear = () => {
    this.setState({
      searchTerm: '',
      searchTermApplied: true,
      previousSearchTerm: ''
    })
  }

  applySearchTerm = (event) => {
    event && event.preventDefault()
    const { searchTerm } = this.state
    const shouldApplySearchTerm = this.shouldApplySearchTerm()
    if (shouldApplySearchTerm === false) { return }

    this.setState({ ...this.state, previousSearchTerm: searchTerm, searchTermApplied: true })
    this.props.onUpdate(searchTerm, this.props.sectionName)
  }

  clearSearch = (event) => {
    event && event.preventDefault()
    this.setState({ ...this.state, previousSearchTerm: '', searchTermApplied: true, searchTerm: '' })
    this.props.onUpdate('', this.props.sectionName)
  }

  render () {
    let groupIconElement = <i className='fa fa-search' />
    let showingButton = false

    if (this.state.searchTermApplied === false) {
      showingButton = true
      groupIconElement = <button className='btn btn-primary' type='submit' onClick={this.applySearchTerm}>
        <i className='fa fa-search' /> Apply
      </button>
    } else if (this.state.searchTerm && this.state.searchTerm.length > 0) {
      showingButton = true
      groupIconElement = <button className='btn btn-primary' type='button' onClick={this.clearSearch}>
        <i className='fa fa-times-circle' /> Clear
      </button>
    }

    const groupIconClass = (showingButton) ? 'input-group-btn' : 'input-group-addon'

    return (
      <form onSubmit={this.applySearchTerm}>
        <TextField
          name={FIELD_NAME}
          value={this.state.searchTerm}
          formGroupIconElementClass={groupIconClass}
          placeholder='Search by name'
          onChange={this.handleInput}
          formGroupIconElement={groupIconElement} />
      </form>
    )
  }
}

TextSearchView.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired
}
