import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SelectLocationTableView from './TableView/SelectLocationTableView'
import PagesContainer from '../containers/PagesContainer'

import { ContentBlockView, EmptyView, TextSearchView } from 'modules/components'

export class MultiLocationSelectView extends Component {

  UNSAFE_componentWillMount () {
    const { locations } = this.props
    if (locations.length > 0) { return }
    this.getLocationsWithPageNumber(1)
  }

  getLocationsWithPageNumber = (pageNumber) => {
    const { getLocations } = this.props
    getLocations(pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getLocationsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getLocations } = this.props
    getLocations(1, searchTerm)
  }

  render () {
    const { locations, pageDetails, loading, selectedLocationIds } = this.props
    const pageCount = pageDetails.pageCount

    return (
      <ContentBlockView
        loading={loading}
        bordered>
        <div className='row' style={{ marginTop: '-20px' }}>
          <TextSearchView
            sectionName={'locationSearch'}
            onUpdate={this.searchUpdated} />
        </div>
        {locations.length > 0 &&
          <div>
            <SelectLocationTableView locations={locations} selectedLocationIds={selectedLocationIds}
              selectLocation={this.props.selectLocation} deselectLocation={this.props.deselectLocation} />
          </div>
        }
        {locations.length === 0 &&
          <EmptyView
            colorThemeClass='primary'
            iconClass='si si-magnifier'
            title='No results'
            subtitle='Check the search field' />
        }
        {pageCount > 1 &&
          <div className='text-center push'>
            <PagesContainer pageClicked={this.pageClicked} />
          </div>
        }
      </ContentBlockView>
    )
  }
}

MultiLocationSelectView.propTypes = {
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLocation: PropTypes.func.isRequired,
  deselectLocation: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired
}

export default MultiLocationSelectView
