import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = (props) => {
  const { component, user, setAttemptedUrl, location } = props;

  useEffect(() => {
    if (!user) {
      setAttemptedUrl(`${location?.pathname}${location?.search}`);
    }
    // eslint-disable-next-line
  }, []);

  if (user) {
    return <Route {...props} component={component} />;
  } else {
    return <Redirect to={{ pathname: '/login' }} />;
  }
};

export default PrivateRoute;
