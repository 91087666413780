import PropTypes from "prop-types";
import React, { Component } from "react";
import "./LeadNotes.css";
import moment from "moment";

export class LeadNoteItem extends Component {
  editClicked = () => {
    const { editClicked, note } = this.props;
    editClicked(note);
  };

  render() {
    const { note, canEdit } = this.props;
    const noteUserName = note.user ? note.user.given_name : "Deleted User";
    const createdAtMoment = moment(note.created_at).fromNow();
    return (
      <div className="block block-transparent pull-r-l">
        <div className="block-header bg-gray-lighter">
          <ul className="block-options">
            <li>
              <span>
                <em className="text-muted">{createdAtMoment}</em>
              </span>
            </li>
            {canEdit && (
              <li>
                <button type="button" onClick={this.editClicked}>
                  <i className="si si-pencil" />
                </button>
              </li>
            )}
          </ul>
          <h3 className="block-title">{noteUserName}</h3>
        </div>
        <div className="block-content">
          <p className="font-s13">{note.detail}</p>
        </div>
      </div>
    );
  }
}

LeadNoteItem.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  editClicked: PropTypes.func.isRequired,
  note: PropTypes.object.isRequired,
};
