import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading: loading,
  };
}

export function loadBillingDataForUser() {
  return (dispatch) =>
    Promise.all([dispatch(setLoading(true)), dispatch(getPaymentMethods())]).then(() => {
      dispatch(setLoading(true));
    });
}

// GET
export const getDefaultSmsCost = () => {
  return (dispatch) => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(setDefaultSmsCost(payload));
    }

    return request('default_sms_cost', GET, null, onSuccess, onFailure);
  };
};

const getPaymentMethods = () => {
  return (dispatch) => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(setPaymentMethods(payload.paychoice_payment_methods));
    }

    return request('paychoice_payment_methods', GET, null, onSuccess, onFailure);
  };
};

// SET
function setPaymentMethods(paymentMethods) {
  return {
    type: actionTypes.SET_PAYMENT_METHODS,
    paymentMethods,
  };
}

// UPDATE
function setDefaultSmsCost(defaultSmsCost) {
  return {
    type: actionTypes.SET_DEFAULT_SMS_COST,
    defaultSmsCost,
  };
}

export function addPaymentMethod(paymentMethod) {
  return {
    type: actionTypes.ADD_PAYMENT_METHOD,
    paymentMethod,
  };
}

export function updatePaymentMethod(paymentMethod) {
  return {
    type: actionTypes.UPDATE_PAYMENT_METHOD,
    paymentMethod,
  };
}

export function removePaymentMethodId(paymentMethodId) {
  return {
    type: actionTypes.REMOVE_PAYMENT_METHOD,
    paymentMethodId,
  };
}
