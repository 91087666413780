import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LeadSourceSelectModal from "../components/LeadSourceSelectModal";
import leadSources from "modules/Stores/LeadSources";
import * as leadSourceApiActions from "modules/Stores/LeadSources/apiActions";
import modal from "modules/Stores/Modal";

function mapStateToProps(state) {
  return {
    leadSources: leadSources.selectors.getLeadSources(state),
    loading: leadSources.selectors.getLoading(state),
    errors: leadSources.selectors.getErrors(state),
    pageDetails: leadSources.selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadSources: leadSourceApiActions.getLeadSources,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadSourceSelectModal);
