import { createWebsocketConsumer } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const subscribeToChannelName = (channelName, eventName, received) => {
  return (dispatch) => {
    dispatch(addChannel(channelName));
    const wsConsumer = createWebsocketConsumer();
    const channel = wsConsumer.subscribe(channelName);
    channel.bind(eventName, function (data) {
      received(data);
    });
  };
};

function addChannel(channelName) {
  return {
    type: actionTypes.ADD_CHANNEL,
    channelName,
  };
}
