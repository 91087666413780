import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import Datetime from 'react-datetime';
import 'styles/react-datetime.css';
import { generateNewFormState } from 'utils/formHelpers';

import FilterSection from 'modules/components/FilterFormSections/components/FilterSection';

import moment from 'moment';

export class DateSelectionModal extends Component {
  state = {
    [this.props.updatedAfterTitle]: null,
    [this.props.updatedBeforeTitle]: null,
    fromDateValid: true,
    toDateValid: true,
  };

  hideModalAndCleanUp = () => {
    const { updatedAfterTitle, updatedBeforeTitle } = this.props;
    this.setState({ [updatedAfterTitle]: null, [updatedBeforeTitle]: null });
    this.props.hideModal();
  };

  hideModalAndCleanUp = () => {
    const { updatedAfterTitle, updatedBeforeTitle } = this.props;
    this.setState({ [updatedAfterTitle]: null, [updatedBeforeTitle]: null });
    this.props.hideModal();
  };

  setDateRange = () => {
    const { updatedAfterTitle, updatedBeforeTitle } = this.props;
    const data = {
      [updatedAfterTitle]: this.state[updatedAfterTitle].clone().startOf('day').utc().format(),
      [updatedBeforeTitle]: this.state[updatedBeforeTitle].clone().endOf('day').utc().format(),
    };

    this.props.onUpdate(data, this.props.sectionName);
    this.props.hideModal();
  };

  selectDate(date, fieldName) {
    const utcDate = date.format();
    this.handleInput(fieldName, utcDate, true);
  }

  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(this.props.data, fieldName, value, isValid);
    this.props.onUpdate(newSectionData, this.props.sectionName);
  };

  calculateAfterDate = () => {
    const { updatedAfterTitle } = this.props;
    if (this.state[updatedAfterTitle]) {
      return this.state[updatedAfterTitle];
    }

    const fields = this.props.data.fields;
    const createdAfterDate = fields[updatedAfterTitle] ? fields[updatedAfterTitle].value : null;
    return moment(createdAfterDate);
  };

  calculateBeforeDate = () => {
    const { updatedBeforeTitle } = this.props;
    if (this.state[updatedBeforeTitle]) {
      return this.state[updatedBeforeTitle];
    }

    const fields = this.props.data.fields;
    const createdBeforeDate = fields[updatedBeforeTitle] ? fields[updatedBeforeTitle].value : null;
    return moment(createdBeforeDate);
  };

  handleFromDateSelection = (date) => {
    const { updatedAfterTitle } = this.props;
    if (!date) {
      return;
    }
    if (moment(date).isValid() === false) {
      this.setState({ ...this.state, fromDateValid: false });
      return;
    }
    this.setState({
      ...this.state,
      [updatedAfterTitle]: date.clone(),
      fromDateValid: true,
    });
  };

  handleToDateSelection = (date) => {
    const { updatedBeforeTitle } = this.props;
    if (!date) {
      return;
    }
    if (moment(date).isValid() === false) {
      this.setState({ ...this.state, toDateValid: false });
      return;
    }
    this.setState({
      ...this.state,
      [updatedBeforeTitle]: date.clone(),
      toDateValid: true,
    });
  };

  render() {
    const { currentModal, modalName, updatedBeforeTitle, updatedAfterTitle, formats } = this.props;
    const { fromDateValid, toDateValid } = this.state;
    const invalidDate = !fromDateValid || !toDateValid;
    const disabled = !this.state[updatedBeforeTitle] || !this.state[updatedAfterTitle] || invalidDate;
    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select a date range
            </h3>
          </div>
          <div className="block-content">
            <FilterSection title={'Date'} showBottomBorder={false}>
              <div className="input-daterange input-group">
                <Datetime
                  value={this.calculateAfterDate()}
                  defaultValue={null}
                  dateFormat={formats.date}
                  locale={formats.locale}
                  inputProps={{ placeholder: 'From' }}
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={this.handleFromDateSelection}
                />
                <span className="input-group-addon">To</span>
                <Datetime
                  value={this.calculateBeforeDate()}
                  defaultValue={null}
                  inputProps={{ placeholder: 'Until' }}
                  dateFormat={formats.date}
                  locale={formats.locale}
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={this.handleToDateSelection}
                />
              </div>
            </FilterSection>
            {invalidDate && (
              <i>
                <span style={{ color: '#d26a5c' }}>Chosen date is invalid.</span>
              </i>
            )}
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={disabled} onClick={this.setDateRange}>
            <i className="fa fa-calendar" /> Set Date range
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DateSelectionModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updatedAfterTitle: PropTypes.string.isRequired,
  updatedBeforeTitle: PropTypes.string.isRequired,
  formats: PropTypes.object.isRequired,
  currentModal: PropTypes.string,
};

export default DateSelectionModal;
