import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userData from 'modules/Stores/UserData'
import locations from 'modules/Stores/Locations'
import billing from 'modules/Stores/Billing'
import session from 'modules/Stores/Session'
import modal from 'modules/Stores/Modal'
import editLocation from '../modules/editLocation'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const location = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  const getSubscriptionPaymentMethodSelector =
    billing.selectors.subscriptionPaymentMethodForLocationFactory(location)
  const getSmsPaymentMethodSelector =
    billing.selectors.smsPaymentMethodForLocationFactory(location)
  return {
    user: session.selectors.getUser(state),
    location: location,
    data: editLocation.selectors.getData(state),
    errors: editLocation.selectors.getErrors(state),
    loading: editLocation.selectors.getLoading(state),
    countries: userData.selectors.getCountries(state),
    subscriptionPaymentMethod: getSubscriptionPaymentMethodSelector(state),
    smsPaymentMethod: getSmsPaymentMethodSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    saveWithData: editLocation.actions.updateLocationWithData,
    onInput: editLocation.actions.updateData,
    prefillForm: editLocation.actions.prefillForm,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(editLocation.components.EditLocationView)
