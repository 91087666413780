import { createSelector } from 'reselect';
import { NAME } from './constants';

import { availableFields } from './importFields';
import selectCSV from '../selectCSV';

export const getLeads = (state) => state.importLeads[NAME].leads;
export const getImportOptions = (state) => state.importLeads[NAME].importOptions;
export const getUpdateLeadsValue = (state) => state.importLeads[NAME].importOptions.updateLeads;
export const getEnterAutomationFunnelValue = (state) => state.importLeads[NAME].importOptions.enterAutomationFunnel;
export const getEnterMatchingCallScheduleValue = (state) => state.importLeads[NAME].importOptions.enterMatchingCallSchedule;
export const getLoading = (state) => state.importLeads[NAME].loading;
export const getEditingLeadAtIndex = (state) => state.importLeads[NAME].editingLeadAtIndex;
export const getHeaders = (state) => state.importLeads[NAME].headers;
export const getErrors = (state) => state.importLeads[NAME].errors;
export const getNumberOfRecords = (state) => state.importLeads[NAME].numberOfRecords;

const recomendedKeys = ['surname', 'email', 'mobile_number', 'salesperson_id', 'opted_in_at'];

export const isReady = createSelector(
  [getLeads, getEditingLeadAtIndex, getHeaders, getNumberOfRecords, selectCSV.selectors.getEnhancedPrivacy],
  (leads, editingLeadAtIndex, headers, numberOfRecords, enhancedPrivacy) => {
    if (!leads) return false;
    if (leads.length === 0) return false;
    if (editingLeadAtIndex) return false;
    const selectedFields = getSelectedFieldsForHeaders(headers);
    let requiredKeys = requiredKeysForNumberOfRecords(numberOfRecords, enhancedPrivacy);
    if (missingFieldsForKeys(selectedFields, requiredKeys).count > 0) return false;
    return true;
  }
);

export function leadAtIndexFactory(index) {
  return createSelector([getLeads], (leads) => {
    return leads[index];
  });
}

export const statusColumnIndex = createSelector([getHeaders], (headers) => {
  const index = headers.findIndex((header) => header === 'status');
  if (index > -1) {
    return index;
  }
  return null;
});

export const salespersonColumnIndex = createSelector([getHeaders], (headers) => {
  const index = headers.findIndex((header) => header === 'salesperson_id');
  if (index > -1) {
    return index;
  }
  return null;
});

export const lawfulProcessingBasesColumnIndex = createSelector([getHeaders], (headers) => {
  const index = headers.findIndex((header) => header === 'lawful_processing_basis');
  if (index > -1) {
    return index;
  }
  return null;
});

export const getAvailableFields = createSelector([getHeaders], (headers) => {
  return availableFields.map((field) => {
    var inUse = headers.findIndex((header) => header === field.key) > -1;
    return { ...field, inUse };
  });
});

export const getSelectedFields = createSelector([getHeaders], (headers) => {
  return getSelectedFieldsForHeaders(headers);
});

function getSelectedFieldsForHeaders(headers) {
  return availableFields.filter((field) => {
    return headers.findIndex((header) => header === field.key) > -1;
  });
}

export function selectedFieldsAreValid(selectedFields) {
  return selectedFields.findIndex((field) => field.key === 'given_name') > -1;
}

export function requiredFieldsMissing(selectedFields, numberOfRecords, enhancedPrivacy) {
  let requiredKeys = requiredKeysForNumberOfRecords(numberOfRecords, enhancedPrivacy);
  return missingFieldsForKeys(selectedFields, requiredKeys);
}

export function recomendedFieldsMissing(selectedFields) {
  return missingFieldsForKeys(selectedFields, recomendedKeys);
}

function requiredKeysForNumberOfRecords(numberOfRecords, enhancedPrivacy) {
  let requiredKeys = ['given_name'];
  if (enhancedPrivacy === true) {
    requiredKeys.push('lawful_processing_basis');
  }
  if (numberOfRecords > 10) {
    requiredKeys.push('salesperson_id');
  }
  return requiredKeys;
}

function missingFieldsForKeys(selectedFields, keys) {
  const missingKeys = keys.filter((key) => {
    return selectedFields.findIndex((selectedField) => selectedField.key === key) === -1;
  });
  return missingKeys.map((key) => {
    const index = availableFields.findIndex((availableField) => availableField.key === key);
    return availableFields[index];
  });
}

export const generateHeaderStates = createSelector([getHeaders], (headers) => {
  return headers.map((headerString) => {
    const key = headerString;
    var displayName = null;
    for (var i = 0; i < availableFields.length; i++) {
      if (availableFields[i].key === headerString) {
        displayName = availableFields[i].displayName;
        break;
      }
    }
    return { displayName: displayName, key: key };
  });
});
