import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getCurrentView = state => state[NAME].currentView
export const getSelectedLocation = state => state[NAME].selectedLocation
export const getSelectedUser = state => state[NAME].selectedUser
export const getUsers = state => state[NAME].users
export const getPageDetails = state => state[NAME].pageDetails
export const getLoading = state => state[NAME].loading
export const getLastUsedLocationId = state => state[NAME].lastUsedLocationId

export function fetchOnLoadSelector (locationId) {
  return createSelector(
    [ getLastUsedLocationId ],
    (lastUsedLocationId) => {
      if (lastUsedLocationId === null) { return true }
      return !(lastUsedLocationId === locationId)
    }
  )
}
