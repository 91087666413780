import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddFromMindbodyModalView from "../components/AddFromMindbodyModalView";
import { getProductName } from "modules/Stores/UserData/selectors";

import modal from "modules/Stores/Modal";
import userData from "modules/Stores/UserData";

import * as actions from "../actions";
import * as selectors from "../selectors";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    currentModal: modal.selectors.getCurrentModal(state),
    users: selectors.getUsers(state),
    selectedUserIds: selectors.getSelectedUserIds(state),
    selectedUsers: selectors.selectedUsers(state),
    pageCount: selectors.getPageCount(state),
    currentPage: selectors.getCurrentPage(state),
    selectedUserRoleId: selectors.getSelectedUserRoleId(state),
    userRoles: userData.selectors.getUserRoles(state),
    total: selectors.getTotal(state),
    productName: getProductName(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMindbodyUsersForPage: actions.getMindbodyUsersForPage,
      selectUserId: actions.selectUserId,
      deselectUserId: actions.deselectUserId,
      resetForm: actions.resetForm,
      setUserRoleId: actions.setUserRoleId,
      inviteMindBodyUsers: actions.inviteMindBodyUsers,
      hideModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFromMindbodyModalView);
