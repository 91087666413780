import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'
const { TextField, PasswordField } = FormFields

export class FormView extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  render () {
    const { username, password } = this.props.data.fields
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <h2 className='h4 push-10'>Authorize {this.props.productName}</h2>
            <p className='text-gray-dark'>Enter the username and password for your Perfect Gym API user{' '}
              to authorize {this.props.productName} to access your data.{' '}
              Perfect Gym authorizations expire after a few months, so you'll need to come back and renew it from {' '}
              time to time (don't worry, we'll remind you when it's coming up).</p>
            <p className='text-gray-dark push'>Enter your API username and password</p>
          </div>
        </div>
        <div className='row push'>
          <div className='col-sm-6'>
            <TextField
              name='username'
              label={'API username'}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              value={username.value} />
          </div>
          <div className='col-sm-6'>
            <PasswordField
              name='password'
              disabled={false}
              label={'API user password'}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              value={password.value} />
          </div>
        </div>
      </div>
    )
  }
}

FormView.propTypes = {
  data: PropTypes.object.isRequired,
  onInput: PropTypes.func.isRequired
}

export default FormView
