import React, { Component } from "react";
import { InfoView } from "modules/components";
import AutomaticReportsTableContainer from "../modules/reportsTable/containers/AutomaticReportsTableContainer";
import FiltersContainer from "../modules/filters/containers/FiltersContainer";
import ScheduleContainer from "../modules/schedule/containers/ScheduleContainer";
import EditReportContainer from "../modules/editReport/containers/EditReportContainer";

export class AutomaticReportsView extends Component {

  componentWillUnmount() {
    const { reset } = this.props
    reset()
  }
  
  render() {
    const { selectedAutomaticReport } = this.props;
    return (
      <div>
        <InfoView
          colorThemeClass="info"
          title="Automatic Report"
          detail="Manage your scheduled reports"
        />
        <AutomaticReportsTableContainer />
        {selectedAutomaticReport && (
          <div>
            <EditReportContainer />
            <FiltersContainer />
            <ScheduleContainer />
          </div>
        )}
      </div>
    );
  }
}

export default AutomaticReportsView;
