import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = (state) => state.callScheduleSettings[NAME].loading
export const getErrors = (state) => state.callScheduleSettings[NAME].errors
export const getSteps = (state) => state.callScheduleSettings[NAME].steps
export const getSelectedStepId = (state) => state.callScheduleSettings[NAME].selectedStepId

export const getSelectedStep = createSelector(
  [ getSteps, getSelectedStepId ],
  (steps, selectedStepId) => {
    const index = steps.findIndex(step => parseInt(step.id) === parseInt(selectedStepId))
    return steps[index]
  }
)
