/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Avatar from 'react-avatar';
import { isToday, colorForDate } from 'modules/Helpers/DateHelpers';
import moment from 'moment';

export class TaskRow extends Component {
  viewProfileSelected = () => {
    const { task } = this.props;
    this.props.openLeadModal(task.lead.id);
  };

  markCompleteSelected = () => {
    const { markCompleteSelected, task } = this.props;
    if (markCompleteSelected) {
      markCompleteSelected(task);
    }
  };

  render() {
    const { task, timezone } = this.props;
    const dueMoment = moment(task.due_date).tz(timezone);
    const format = isToday(dueMoment) ? 'h:mm a' : 'dddd MMMM Do, h:mm a';
    const dueDateString = dueMoment.format(format);
    const timeString = isToday(dueMoment) ? 'Today' : '';
    const lead = task.lead;
    const timeColorClass = colorForDate(dueMoment, timezone) || 'text-muted';
    return (
      <li>
        <a onClick={this.onSelect} style={{ paddingLeft: '10px' }}>
          <div className="row">
            <div className="col-xs-2">
              <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={42} round={true} />
            </div>
            <div className="col-xs-7">
              {lead.display_name}
              <br />
              {task.title}
              <br />
              <span className={timeColorClass}>
                {timeString} {dueDateString}
              </span>
            </div>
            <div className="col-xs-3">
              <button className="btn btn-sm btn-primary push-5-r push-10" onClick={this.viewProfileSelected} type="button">
                <i className="fa fa-user" />
              </button>
              <button className="btn btn-sm btn-success push-5-r push-10" onClick={this.markCompleteSelected} type="button">
                <i className="fa fa-check" />
              </button>
            </div>
          </div>
        </a>
      </li>
    );
  }
}

TaskRow.propTypes = {
  task: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  markCompleteSelected: PropTypes.func,
};
