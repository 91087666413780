/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import CallScheduleStepsModalContainer from "../../containers/CallScheduleStepsModalContainer";

import { CALL_SCHEDULE_MODAL } from "../../constants";

class CallScheduleView extends Component {
  showDetails = () => {
    this.props.showModal(CALL_SCHEDULE_MODAL);
  };

  render() {
    const { callScheduleStep, pathToLeadDetail, lead } = this.props;
    return (
      <div>
        <a
          className="block block-link-hover3 borderd selectable"
          onClick={this.showDetails}
        >
          <div className="block-content block-content-full clearfix">
            <span className="pull-left" style={{ paddingRight: "10px" }}>
              <h5 className="push-10-t">{callScheduleStep.schedule_name}</h5>
              <span
                className="badge badge-primary"
                style={{ marginTop: "-5px" }}
              >
                Step {callScheduleStep.step_number + 1}
              </span>
            </span>
            <div className="item item-rounded pull-right push-10-r bg-info">
              <i className="si si-call-out text-white-op" />
            </div>
          </div>
        </a>
        <CallScheduleStepsModalContainer
          callSchedule={callScheduleStep}
          pathToLeadDetail={pathToLeadDetail}
          lead={lead}
        />
      </div>
    );
  }
}

CallScheduleView.propTypes = {
  callScheduleStep: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  pathToLeadDetail: PropTypes.string.isRequired,
};

export default CallScheduleView;
