import * as actionTypes from './actionTypes'

const CONTACT_CALLS_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.SET_PAGE_DETAILS]: (state, action) => {
    const newMeta = action.pageDetails
    return { ...state, loading: false, pageDetails: {
      currentPage: newMeta.current_page,
      pageCount: newMeta.total_pages,
      total: newMeta.total_count
    }}
  },
  [actionTypes.FETCHING_SUCCESS]: (state, action) => {
    return { ...state, loading: false, calls: action.calls }
  },
  [actionTypes.FETCHING_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATING]: (state, action) => {
    const index = indexForCall(state.calls, action.callId)
    const updatedCall = { ...state.calls[index], loading: true }
    const updatedCalls = [
      ...state.calls.slice(0, index),
      updatedCall,
      ...state.calls.slice(index + 1)
    ]
    return { ...state, calls: updatedCalls }
  },
  [actionTypes.ADD]: (state, action) => {
    const updatedCalls = [
      action.call,
      ...state.calls
    ]
    return { ...state, calls: updatedCalls }
  },
  [actionTypes.UPDATING_SUCCESS]: (state, action) => {
    const updatedCall = action.call
    const index = indexForCall(state.calls, updatedCall.id)
    const updatedCalls = [
      ...state.calls.slice(0, index),
      updatedCall,
      ...state.calls.slice(index + 1)
    ]
    return { ...state, loading: false, calls: updatedCalls }
  },
  [actionTypes.UPDATING_FAILURE]: (state, action) => {
    const index = indexForCall(state.calls, action.callId)
    const updatedCall = { ...state.calls[index], loading: false }
    const updatedCalls = [
      ...state.calls.slice(0, index),
      updatedCall,
      ...state.calls.slice(index + 1)
    ]
    return { ...state, loading: false, calls: updatedCalls }
  },
  [actionTypes.DELETE]: (state, action) => {
    const index = indexForCall(state.calls, action.callId)
    const updatedCalls = [
      ...state.calls.slice(0, index),
      ...state.calls.slice(index + 1)
    ]
    return { ...state, loading: false, calls: updatedCalls }
  },
  [actionTypes.SET_STEPS]: (state, action) => {
    return { ...state, steps: action.steps }
  }
}

const initialState = {
  loading: false,
  calls: [],
  steps: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

function indexForCall (calls, callId) {
  return calls.findIndex(_call => _call.id === callId)
}

export default function reducer (state = initialState, action) {
  const handler = CONTACT_CALLS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
