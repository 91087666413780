
import * as actionTypes from './actionTypes'

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setSelectedReport (selectedReport) {
  return {
    type: actionTypes.SET_SELECTED_REPORT,
    selectedReport
  }
}

