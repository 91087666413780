import { request, POST } from 'utils/apiUtils';

import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';
import regions from 'modules/Stores/Regions';

export const createRegion = (name, postcodes) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      region: {
        name: name,
        region_postcodes_attributes: postcodeAttributesForPostcodes(postcodes),
      },
    };
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(regions.actions.addRegion(payload.region));
      notify.success('Region created');
      dispatch(modal.actions.hideModal());
    }

    return request('regions', POST, body, onSuccess, onFailure);
  };
};

function postcodeAttributesForPostcodes(postcodes) {
  return postcodes.map((postcodeString) => {
    return { postcode: postcodeString };
  });
}
