import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getData = state => state.locationSettings[NAME].data
export const getPaymentData = state => state.locationSettings[NAME].paymentData

export const isValid = createSelector(
  [ getData, getPaymentData ],
  (data, paymentData) => {
    const useExistingCard = (data.fields.paychoicePaymentMethodId.value)
    if (useExistingCard) {
      return data.isValid
    } else {
      if (!data.isValid) { return false }
      if (!paymentData.isValid) { return false }
      return true
    }
  }
)
