import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'

import ButtonsView from '../components/EnterClubId/ButtonsView'

import * as mindbodyActions from '../actions'
import * as mindbodySelectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    nextIsReady: mindbodySelectors.currentIndexIsReady(state),
    data: mindbodySelectors.getData(state),
    tabIndex: mindbodySelectors.getTabIndex(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addOrUpdateOptionWithData: mindbodyActions.addOrUpdateOptionWithData,
    reset: mindbodyActions.reset,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsView)
