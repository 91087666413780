import React, { FC, useState, useEffect } from 'react';
import { errorPanelForErrors } from 'utils/formHelpers';
import { observer } from 'mobx-react';
import { space } from 'styleguide';
import notify from 'modules/Notifications';

import { ContentBlockView, Box, TextField, ColorPicker, Button, Text, TextArea, FormFields } from 'modules/components';

import { updateWaiverForm, createWaiverForm } from 'modules/api/waiverForms';

import { store } from './store';
import { EditProfileValidator } from './validators';

const { Checkbox } = FormFields;

export const EditProfile: FC = observer((props) => {
  const [loading, setLoading] = useState(false);
  const { selectedWaiverForm } = store.state;
  const { updateWaiverFormsListItem, addWaiverFormsListItem, setSelectedWaiverFormId } = store.actions;

  const [profileName, setProfileName] = useState(selectedWaiverForm?.name || '');
  const [backgroundHexColor, setBackgroundHexColor] = useState(selectedWaiverForm?.background_hex_color || '');
  const [primaryHexColor, setPrimaryHexColor] = useState(selectedWaiverForm?.primary_hex_color || '');
  const [secondaryHexColor, setSecondaryHexColor] = useState(selectedWaiverForm?.secondary_hex_color || '');
  const [buttonTextColor, setButtonTextColor] = useState(selectedWaiverForm?.button_text_color || '#fff');

  const [introductionText, setIntroductionText] = useState(selectedWaiverForm?.introduction_text || null);
  const [membershipQuestionText, setMembershipQuestionText] = useState(selectedWaiverForm?.membership_question_text || null);
  const [membershipInquryFinishedText, setMembershipInquryFinishedText] = useState(
    selectedWaiverForm?.membership_inqury_finished_text || null
  );
  const [disclaimerText, setDisclaimerText] = useState(selectedWaiverForm?.disclaimer_text || null);
  const [completeText, setCompleteText] = useState(selectedWaiverForm?.complete_text || null);
  const [submitErrors, setSubmitErrors] = useState<any>();

  const [allowMobileCheckIn, setAllowMobileCheckIn] = useState(
    selectedWaiverForm?.allow_mobile_check_in !== undefined ? selectedWaiverForm?.allow_mobile_check_in : true
  );
  const [allowTabletCheckIn, setAllowTabletCheckIn] = useState(
    selectedWaiverForm?.allow_tablet_check_in !== undefined ? selectedWaiverForm?.allow_tablet_check_in : true
  );

  useEffect(() => {
    setProfileName(selectedWaiverForm?.name || '');
    setBackgroundHexColor(selectedWaiverForm?.background_hex_color || '#fff');
    setPrimaryHexColor(selectedWaiverForm?.primary_hex_color || '#000');
    setSecondaryHexColor(selectedWaiverForm?.secondary_hex_color || '#4A90E2');
    setButtonTextColor(selectedWaiverForm?.button_text_color || '#fff');
    setIntroductionText(selectedWaiverForm?.introduction_text || null);
    setMembershipInquryFinishedText(selectedWaiverForm?.membership_inqury_finished_text || null);
    setMembershipQuestionText(selectedWaiverForm?.membership_question_text || null);
    setDisclaimerText(selectedWaiverForm?.disclaimer_text || null);
    setCompleteText(selectedWaiverForm?.complete_text || null);
    setAllowMobileCheckIn(selectedWaiverForm?.allow_mobile_check_in !== undefined ? selectedWaiverForm?.allow_mobile_check_in : true);
    setAllowTabletCheckIn(selectedWaiverForm?.allow_tablet_check_in !== undefined ? selectedWaiverForm?.allow_tablet_check_in : true);
  }, [selectedWaiverForm]);

  const updateProfile = async () => {
    if (!selectedWaiverForm) {
      return;
    }
    setLoading(true);

    const response = await updateWaiverForm({
      waiverFormId: selectedWaiverForm.id,
      name: profileName,
      backgroundHexColor: backgroundHexColor,
      primaryHexColor: primaryHexColor,
      secondaryHexColor: secondaryHexColor,
      buttonTextColor,
      introductionText,
      membershipInquryFinishedText,
      membershipQuestionText,
      disclaimerText,
      completeText,
      locationIds: undefined,
      allowMobileCheckIn,
      allowTabletCheckIn,
    });

    updateWaiverFormsListItem(response.waiver_form);
    notify.success('Profile Updated');

    setLoading(false);
  };

  const createProfile = async () => {
    setLoading(true);
    try {
      const response = await createWaiverForm({
        name: profileName,
        backgroundHexColor: backgroundHexColor,
        primaryHexColor: primaryHexColor,
        secondaryHexColor: secondaryHexColor,
        buttonTextColor,
        introductionText,
        membershipInquryFinishedText,
        membershipQuestionText,
        disclaimerText,
        completeText,
        locationIds: undefined,
        allowMobileCheckIn,
        allowTabletCheckIn,
      });
      addWaiverFormsListItem(response.waiver_form);
      setSelectedWaiverFormId(response.waiver_form.id);
      setSubmitErrors(undefined);
      notify.success('Profile Created');
    } catch (e) {
      setSubmitErrors(e?.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentBlockView title={selectedWaiverForm?.name || 'New Profile'} bordered loading={loading}>
      {errorPanelForErrors(submitErrors, true)}
      <Box column isFlex>
        <TextField
          needsFormGroup={false}
          name="name"
          label="Profile name *"
          placeholder="Casual Pass Profile"
          formFieldStyle=""
          onChange={(_: string, value: string) => setProfileName(value)}
          value={profileName}
          validator={EditProfileValidator}
        />
        <Box isFlex row>
          <Checkbox
            name="allowMobileCheckIn"
            label={'Allow mobile check in'}
            onChange={() => setAllowMobileCheckIn(!allowMobileCheckIn)}
            switchStyle="primary"
            checked={allowMobileCheckIn}
          />
          <Box mr={space.M} />
          <Checkbox
            name="allowTabletCheckIn"
            label={'Allow tablet check in'}
            onChange={() => setAllowTabletCheckIn(!allowTabletCheckIn)}
            switchStyle="primary"
            checked={allowTabletCheckIn}
          />
        </Box>
        <Box bg="black" w100 height={1} my={space.L} />
        <Text.H3>Personalize colors</Text.H3>
        <Box isFlex row mb={space.M} jSpaceBtw>
          <Box isFlex column>
            <ColorPicker
              useBootStrapLayout={false}
              label="Background color"
              name="backgroundHexColor"
              onChange={(_: string, value: string) => setBackgroundHexColor(value)}
              value={backgroundHexColor}
            />
            <ColorPicker
              useBootStrapLayout={false}
              label="Text color"
              name="primaryHexColor"
              onChange={(_: string, value: string) => setPrimaryHexColor(value)}
              value={primaryHexColor}
            />
            <ColorPicker
              useBootStrapLayout={false}
              label="Button color"
              name="secondaryHexColor"
              onChange={(_: string, value: string) => setSecondaryHexColor(value)}
              value={secondaryHexColor}
            />
            <ColorPicker
              useBootStrapLayout={false}
              label="Button text color"
              name="buttonTextColor"
              onChange={(_: string, value: string) => setButtonTextColor(value)}
              value={buttonTextColor || '#fff'}
            />
          </Box>
          <PreviewView
            backgroundColor={backgroundHexColor}
            buttonColor={secondaryHexColor}
            textColor={primaryHexColor}
            buttonTextColor={buttonTextColor}
            introductionText={introductionText}
          />
        </Box>
        <Box bg="black" w100 height={1} my={space.L} />
        <Text.H3>Personalize copy</Text.H3>
        <TextField
          needsFormGroup={false}
          name="introductionText"
          label="Welcome text (shown on the home screen, the first thing leads will see)"
          placeholder="Sign in here"
          formFieldStyle=""
          onChange={(_: string, value: string) => setIntroductionText(value)}
          value={introductionText}
        />
        <TextField
          needsFormGroup={false}
          name="membershipInquryFinishedText"
          label="Standard flow ending text * (shown when the lead has finished signing in)"
          placeholder="We're looking forward to showing you around!"
          formFieldStyle=""
          onChange={(_: string, value: string) => setCompleteText(value)}
          value={completeText}
        />
        <TextField
          needsFormGroup={false}
          name="membershipQuestionText"
          label="Membership question text (when asking if they are interested in a membership)"
          placeholder="Are you interested in a membership?"
          formFieldStyle=""
          onChange={(_: string, value: string) => setMembershipQuestionText(value)}
          value={membershipQuestionText}
        />
        <TextField
          needsFormGroup={false}
          name="membershipInquryFinishedText"
          label="Membership flow ending text (shown when the lead has ended the flow after being interested in a membership)"
          placeholder="We're looking forward to showing you around!"
          formFieldStyle=""
          onChange={(_: string, value: string) => setMembershipInquryFinishedText(value)}
          value={membershipInquryFinishedText}
        />
        <TextArea
          name="disclaimerText"
          label="Disclaimer text"
          needsFormGroup={false}
          onChange={(_: string, value: string) => setDisclaimerText(value)}
          rows={10}
          value={disclaimerText}
        />
      </Box>
      <Box row jSpaceBtw>
        {selectedWaiverForm && (
          <Button.Primary1 onClick={updateProfile} my={space.L}>
            Save Front Desk Profile
          </Button.Primary1>
        )}
        {!selectedWaiverForm && (
          <Button.Primary1 onClick={createProfile} my={space.L}>
            Create Front Desk Profile
          </Button.Primary1>
        )}
      </Box>
    </ContentBlockView>
  );
});

interface PreviewViewProps {
  backgroundColor: string;
  buttonColor: string;
  textColor: string;
  introductionText: string | null;
  buttonTextColor: string | null;
}

const PreviewView: FC<PreviewViewProps> = (props) => {
  const { backgroundColor, buttonColor, textColor, buttonTextColor, introductionText } = props;

  return (
    <Box border="2px solid black" borderRadius={2} isFlex column height={200} width={400}>
      <Box bg="white" height={44} w100 borderBottom="1px solid black">
        <Box />
      </Box>
      <Box isFlex column flexGrow={1} bg={backgroundColor} w100 center>
        <Text.H3 color={textColor}>{introductionText || 'Sign in here'}</Text.H3>
        <Box width={120} bg={buttonColor} borderRadius={4} isFlex center>
          <p style={{ color: buttonTextColor || '#fff', marginBottom: 4 }}>Tap to begin</p>
        </Box>
      </Box>
    </Box>
  );
};
