import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import facebookMessages from '../modules/facebookMessages'

function mapStateToProps (state) {
  return {
    facebookMessages: facebookMessages.selectors.getFacebookMessages(state),
    currentPage: facebookMessages.selectors.getCurrentPage(state),
    pageCount: facebookMessages.selectors.getPageCount(state),
    loading: facebookMessages.selectors.getLoading(state),
    errors: facebookMessages.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchForFacebookMessengerUser: facebookMessages.actions.fetchForFacebookMessengerUser,
    addFacebookMessengesForPage: facebookMessages.actions.addFacebookMessengesForPage
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(facebookMessages.components.FacebookMessagesView)
