import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectDealTableRow extends Component {
  rowSelected = event => {
    const { deal, dealSelected, selectDeal, deselectDeal } = this.props;
    if (dealSelected) {
      deselectDeal(deal);
    } else {
      selectDeal(deal);
    }
  };

  render() {
    const { deal, dealSelected } = this.props;
    return (
      <tr className="selectable" onClick={() => this.rowSelected(deal)}>
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input type="checkbox" disabled checked={dealSelected} readOnly />
            <span />
          </label>
        </td>
        <td>{deal.title}</td>
      </tr>
    );
  }
}

SelectDealTableRow.propTypes = {
  deal: PropTypes.object.isRequired,
  dealSelected: PropTypes.bool.isRequired,
  selectDeal: PropTypes.func.isRequired,
  deselectDeal: PropTypes.func.isRequired
};
