import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LocationSelectModal from '../components/LocationSelectModal'
import locations from 'modules/Stores/Locations'
import session from 'modules/Stores/Session'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  const userRole = session.selectors.getUserRole(state)
  return {
    locations: locations.selectors.getLocations(state),
    loading: locations.selectors.getLoading(state),
    errors: locations.selectors.getErrors(state),
    pageDetails: locations.selectors.getPageDetails(state),
    showSaleDetails: session.helpers.userRoleHasManagerPermissions(userRole),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getLocations: locations.actions.getLocations,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectModal)
