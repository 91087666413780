import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../../filters'
import ReportHeaderView from '../components/ReportHeaderView'
import modal from "modules/Stores/Modal";

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    title: 'Trials Report',
    queryString: queryStringSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,    
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeaderView)
