import React from "react";
import styled from "styled-components";

export const toolTipSelector = calculating => {
  switch (calculating) {
    case "lead_created":
      return leadCreatedTooltips();
    case "event_occured":
      return eventOccuredTooltips();
    default:
      return eventOccuredTooltips();
  }
};

const leadCreatedTooltips = () => {
  const funnelLeadsCreated = (
    <Container>
      <p>
        <strong>Total leads created</strong> (assigned and unassigned) within
        your selected time period.
      </p>
    </Container>
  );

  const funnelLeadsAssigned = (
    <Container>
      <p>
        All leads that have been{" "}
        <strong>created and assigned within your selected time period</strong>.
      </p>
      <p>
        *Please note, we have tracked the reassigned leads below. These are all
        leads that have been reassigned within your selected time period.
      </p>
    </Container>
  );

  const funnelLeadsContacted = (
    <Container>
      <p>
        From all the leads that have been{" "}
        <strong>
          created in the selected date range that have been assigned and
          contacted
        </strong>
        .
      </p>
    </Container>
  );

  const funnelAppointmentsBooked = (
    <Container>
      <p>
        Total number of appointments booked for leads who were created within
        the date range.
      </p>
    </Container>
  );

  const funnelPresentationsmade = (
    <Container>
      <p>
        For the leads that have been <strong>created</strong> during the
        selected date range and who have been assigned, the total number of{" "}
        <strong>successful appointments that have been recorded</strong>.
      </p>
    </Container>
  );

  const funnelLeadsClosed = (
    <Container>
      <p>
        For the leads that have been <strong>created and assigned</strong>, how
        many have had their <strong>status changed to 'Closed'</strong>.
      </p>
    </Container>
  );

  const tooltips = {
    funnelLeadsCreated,
    funnelLeadsAssigned,
    funnelLeadsContacted,
    funnelAppointmentsBooked,
    funnelPresentationsmade,
    funnelLeadsClosed
  };
  return tooltips;
};

const eventOccuredTooltips = () => {
  const funnelLeadsCreated = (
    <Container>
      <p>
        <strong>Total leads created</strong> (assigned and unassigned) within
        your selected time period.
      </p>
    </Container>
  );

  const funnelLeadsAssigned = (
    <Container>
      <p>
        All leads that have been{" "}
        <strong>created and assigned within your selected time period</strong>.
      </p>
      <p>
        *Please note, we have tracked the reassigned leads below. These are all
        leads that have been reassigned within your selected time period.
      </p>
    </Container>
  );

  const funnelLeadsContacted = (
    <Container>
      <p>
        From all the leads that have been <strong>created and assigned,</strong>{" "}
        how many have been{" "}
        <strong>contacted within your selected time period.</strong>
      </p>
    </Container>
  );

  const funnelAppointmentsBooked = (
    <Container>
      <p>
        From the leads that have been <strong>created and assigned</strong>, how
        many leads have <strong>created an appointment</strong> within your
        selected time period.
      </p>
    </Container>
  );

  const funnelPresentationsmade = (
    <Container>
      <p>
        From the leads that have been <strong>created and assigned,</strong> how
        many <strong>successful appointments have been recorded</strong> within
        your selected time period.
      </p>
    </Container>
  );

  const funnelLeadsClosed = (
    <Container>
      <p>
        From the leads that have been <strong>created and assigned,</strong> how
        many have had their <strong>status changed to 'Closed'</strong> within
        your selected time period.
      </p>
    </Container>
  );

  const tooltips = {
    funnelLeadsCreated,
    funnelLeadsAssigned,
    funnelLeadsContacted,
    funnelAppointmentsBooked,
    funnelPresentationsmade,
    funnelLeadsClosed
  };

  return tooltips;
};

const Container = styled.span``;
