import { request, GET, PUT, POST, DELETE } from 'utils/apiUtils';
import { isValidFileType, uploadFileToS3 } from 'utils/imageUpload';
import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';
import { trackEvent } from 'modules/Helpers/Analytics';

import session from 'modules/Stores/Session';
import modal from 'modules/Stores/Modal';
const { updateUser } = session.actions;

export const updateProfileWithData = (formData, user) => {
  return (dispatch) => {
    const body = seralizeUserFromFormData(formData);
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.user));
      dispatch(updateUser(payload.user));
      notify.success('Profile updated');
      trackEvent('Profile updated successfully', {
        userId: user.id,
      });
    }

    return request('users/' + user.id, PUT, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.UPDATING_PROFILE,
  };
}

function updateSuccessful(user) {
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    user,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_PROFILE_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function prefillFormForUser(user) {
  return {
    type: actionTypes.PREFIL_FORM_FOR_USER,
    user,
  };
}

// UPLOAD AVATAR
export const updateUserAvatar = (file, user) => {
  return (dispatch) => {
    if (!isValidFileType(file)) {
      dispatch(updateFailed('Invalid file uploaded. Please choose either .jpeg or .png file.'));
      notify.error('Invalid file uploaded. Please choose either .jpeg or .png file.');
      return;
    }

    dispatch(startUpdating());

    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(uploadUrl, contentType) {
      dispatch(createUserAvatar(uploadUrl, contentType, user));
    }

    function onProgress(progress) {}

    return uploadFileToS3(file, onSuccess, onProgress, onFailure, 'image');
  };
};

const createUserAvatar = (s3URL, contentType, user) => {
  return (dispatch) => {
    const body = {
      image: {
        direct_upload_url: s3URL,
        image_content_type: contentType,
      },
    };
    dispatch(startUpdating());

    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(data) {
      dispatch(updateSuccessful(user));
      notify.success('Avatar updated');
      trackEvent('Profile picture set', {
        userId: user.id,
      });
    }

    return request('users/' + user.id + '/user_avatars', POST, body, onSuccess, onFailure);
  };
};

export function avatarWasSelected(file) {
  return {
    type: actionTypes.AVATAR_WAS_SELECTED,
    file,
  };
}

function seralizeUserFromFormData(formData) {
  const {
    username,
    givenName,
    surname,
    email,
    mobileNumber,
    timeZone,
    managementServiceUserId,
    positionTitle,
    signatureEmail,
    signatureMobile,
  } = formData.fields;

  let payload = {};
  payload = newPayloadForFormValue(payload, givenName, 'given_name');
  payload = newPayloadForFormValue(payload, username, 'username');
  payload = newPayloadForFormValue(payload, surname, 'surname');
  payload = newPayloadForFormValue(payload, email, 'email');
  payload = newPayloadForFormValue(payload, positionTitle, 'position_title');
  payload = newPayloadForFormValue(payload, mobileNumber, 'mobile_number');
  payload = newPayloadForFormValue(payload, signatureEmail, 'signature_email');
  payload = newPayloadForFormValue(payload, signatureMobile, 'signature_phone_number');
  payload = newPayloadForFormValue(payload, timeZone, 'timezone');
  payload = newPayloadForFormValue(payload, managementServiceUserId, 'management_service_user_id');

  return payload;
}

// MINDBODY user
export const createMindbodyUser = (username, password, siteId) => {
  return (dispatch) => {
    const body = {
      username: username,
      password: password,
      site_id: siteId,
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(session.actions.addMindbodyUser(payload.mindbody_user));
      notify.success('MINDBODY login added');
    }

    return request('mindbody_users', POST, body, onSuccess, onFailure);
  };
};

export const destroyMindbodyUser = (mindbodyUserId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(data) {
      dispatch(modal.actions.stopLoading());
      dispatch(session.actions.removeMindbodyUserWithId(mindbodyUserId));
      notify.success('MINDBODY login removed');
    }

    return request('mindbody_users/' + mindbodyUserId, DELETE, null, onSuccess, onFailure);
  };
};

export const getAvailableSiteIds = () => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(data) {
      dispatch(modal.actions.stopLoading());
      dispatch(setAvailableSiteIds(data.available_site_ids));
    }

    return request('available_site_ids/', GET, null, onSuccess, onFailure);
  };
};

function setAvailableSiteIds(availableSiteIds) {
  return {
    type: actionTypes.SET_AVAILABLE_SITE_IDS,
    availableSiteIds,
  };
}
