import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import locations from "modules/Stores/Locations";
import session from "modules/Stores/Session";
import userSelect from "../modules/userSelect";
import locationSelect from "../modules/locationSelect";

function mapStateToProps(state) {
  const selectedLocationId = locationSelect.selectors.getSelectedLocationId(
    state
  );
  const selectedLocation = locations.selectors.locationForIdFactory(
    selectedLocationId
  )(state);
  const selectedUsers = userSelect.selectors.getSelectedUsers(state);
  const usersToUse =
    selectedUsers.length > 0
      ? selectedUsers
      : [session.selectors.getUser(state)];
  const eventTypes = userSelect.selectors.getTypes(state);
  return {
    selectedUsers: usersToUse,
    users: selectedLocation ? selectedLocation.users : [],
    allowMultiSelect: true,
    eventTypes,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectUsers: userSelect.actions.selectUsers,
      setTypes: userSelect.actions.setTypes,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userSelect.components.UserSelectView);
