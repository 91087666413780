const SETTINGS_ACTION_HANDLERS = {
}

const initialState = {
}

export default function reducer (state = initialState, action) {
  const handler = SETTINGS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
