import * as actionTypes from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.SET_LOADING:
        return { ...state, loading: action.loading };
      case actionTypes.SET_ERRORS:
        return { ...state, errors: action.errors };
      case actionTypes.SET_SUBSCRIPTIONS:
        return { ...state, subscriptions: action.subscriptions };
      case actionTypes.SET_SELECTED_SUBSCRIPTION:
        return { ...state, selectedSubscription: action.subscription };
      case actionTypes.SET_NEW_SUBSCRIPTION:
        return { ...state, subscriptions: [ ...state.subscriptions, action.calendly_webhook_subscription] };
      case actionTypes.SET_UPDATED_SUBSCRIPTION:
        const updatedIndex = state.subscriptions.findIndex(subscription => subscription.id === action.subscription.id)
        const updatedSubscriptions = [ ...state.subscriptions.slice(0, updatedIndex), action.subscription, ...state.subscriptions.slice(updatedIndex + 1) ]
        return { ...state, subscriptions: updatedSubscriptions }
      case actionTypes.DELETE_SUBSCRIPTION:
        const deleteIndex = state.subscriptions.findIndex(subscription => subscription.id === action.subscriptionId)
        const remainingSubscriptions = [ ...state.subscriptions.slice(0, deleteIndex), ...state.subscriptions.slice(deleteIndex + 1) ]
        return { ...state, subscriptions: remainingSubscriptions }
      default:
        return state;
    }
  }
  return state;
};
export default reducer;
