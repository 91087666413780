import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const fetchWithQueryString = (queryString, reportUrl) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setData(payload.data));
    }

    let url = reportUrl;

    if (queryString) {
      url = url + '?' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setData(data) {
  return {
    type: actionTypes.SET_DATA,
    data,
  };
}

export function setReportType(reportType) {
  return {
    type: actionTypes.SET_REPORT_TYPE,
    reportType,
  };
}
