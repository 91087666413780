import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormView from '../components/Confirm/FormView';

import * as userDataSelectors from 'modules/Stores/UserData/selectors';
import modal from 'modules/Stores/Modal';

import * as mindbodySelectors from '../selectors';
import * as mindbodyActions from '../actions';

function mapStateToProps(state, props) {
  return {
    data: mindbodySelectors.getData(state),
    permissionSet: mindbodySelectors.getIntegrationPermissionSet(state),
    company: userDataSelectors.getCompany(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPermissionForSet: mindbodyActions.setPermissionForSet,
      addOrUpdateOptionWithData: mindbodyActions.addOrUpdateOptionWithData,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView);
