import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import salesTargets from '../salesTargets'
import leadTimeOut from '../leadTimeOut'

export const getCurrentSalesTarget = (location, user) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (errors) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(salesTargets.actions.prefilSalesTargetsData(payload.target_rule_set))
      dispatch(leadTimeOut.actions.prefilSalesTargetsData(payload.target_rule_set))
      dispatch(setSalesTarget(payload.target_rule_set))
    }
    const query = 'sales_target_for_user_location?location_id=' + location.id + '&user_id=' + user.id
    return request(query, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setSalesTarget (salesTarget) {
  return {
    type: actionTypes.SET_SALES_TARGET,
    salesTarget
  }
}
