import { connect } from 'react-redux'
import { ResetPasswordView } from '../components/ResetPasswordView'

import { fetchValidateToken, resetPassword, fetchWhitelabelProfileLogo } from '../actions'
import { getToken, getProductName, getLoading, getErrors, getSuccess, getValidateToken, getWhitelabelProfile } from '../selectors'

const mapStateToProps = state => ({
  token: getToken(state),
  productName: getProductName(state),
  loading: getLoading(state),
  errors: getErrors(state),
  success: getSuccess(state),
  validateToken: getValidateToken(state),
  whitelabelProfile: getWhitelabelProfile(state)
})

const mapActionCreators = {
  fetchWhitelabelProfileLogo,
  fetchValidateToken,
  resetPassword
}

export default connect(mapStateToProps, mapActionCreators)(ResetPasswordView)
