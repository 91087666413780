import { combineReducers } from 'redux'
import currentTarget from './modules/currentTarget'
import salesTargets from './modules/salesTargets'
import leadTimeOut from './modules/leadTimeOut'
import createSalesTarget from './modules/createSalesTarget'

export const reducer = combineReducers({
  [currentTarget.constants.NAME]: currentTarget.reducer,
  [salesTargets.constants.NAME]: salesTargets.reducer,
  [leadTimeOut.constants.NAME]: leadTimeOut.reducer,
  [createSalesTarget.constants.NAME]: createSalesTarget.reducer
})
