import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EmptyView } from 'modules/components'

import AddFromMindbodyModalContainer from '../modules/addFromMindbody/containers/AddFromMindbodyModalContainer'
import { ADD_FROM_MINDBODY_MODAL } from '../modules/addFromMindbody/constants'

import { INVITE_MODAL_NAME } from '../constants'

export class PendingInvitesList extends Component {

  showInviteTeamModal = () => {
    this.props.showModal(INVITE_MODAL_NAME)
  }

  resendInviteClicked = (index) => {
    const { pendingInvites, resendInvite } = this.props
    const pendingInvite = pendingInvites[index]
    if (pendingInvite) {
      resendInvite(pendingInvite)
    }
  }

  revokeInviteClicked = (index) => {
    const { pendingInvites, revokeInvite } = this.props
    const pendingInvite = pendingInvites[index]
    if (pendingInvite) {
      revokeInvite(pendingInvite, index)
    }
  }

  showMindbodyStaffListModal = () => {
    this.props.showModal(ADD_FROM_MINDBODY_MODAL)
  }

  render () {
    const { pendingInvites, loading, staffIntegration, location } = this.props
    const rows = pendingInvites.map((pendingInvite, index) => {
      return (
        <tr key={pendingInvite.id} id={index} onClick={this.salesPersonClicked}>
          <td className='font-w600'>{pendingInvite.invite_name}</td>
          <td className='font-w600'>{pendingInvite.invite_email}</td>
          <td className='font-w600'>{pendingInvite.user_role.name}</td>
          <td className='text-right'>
            <div className='btn-group'>
              <button className='btn btn-xs btn-default' type='button' onClick={() => this.resendInviteClicked(index)}>
                <i id={index} className='si si-reload' />
              </button>
              <button className='btn btn-xs btn-default' type='button' onClick={() => this.revokeInviteClicked(index)}>
                <i id={index} className='fa fa-times' />
              </button>
            </div>
          </td>
        </tr>
      )
    })

    const headerView = () =>
      <thead>
        <tr>
          <th style={{ width: '25%' }}>Name</th>
          <th style={{ width: '25%' }}>Email</th>
          <th style={{ width: '25%' }}>Role</th>
          <th className='text-right' style={{ width: '25%' }}>Actions</th>
        </tr>
      </thead>
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <div className='block-options-simple'>
            <button type='button' className='btn btn-xs btn-success' onClick={this.showInviteTeamModal}>
              <i className='fa fa-plus' />{' '}Invite new user
            </button>{' '}
            {staffIntegration &&
              <button type='button' className='btn btn-xs btn-success' onClick={this.showMindbodyStaffListModal}>
                <i className='fa fa-plus' />{' '}Invite MINDBODY users
              </button>
            }
          </div>
          <h3 className='block-title'>{this.props.location.admin_display_name} Pending Invites</h3>
        </div>
        <div className='block-content block-content-full'>
          {pendingInvites.length > 0 &&
            <div className='table-responsive'>
              <table className='table table-vcenter'>
                {headerView()}
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
          }
          {pendingInvites.length === 0 &&
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-envelope'
              title='No pending invites'
              subtitle='Everyone has accepted their invites (or none have been sent)'
              buttonText='Invite Team Member'
              buttonSelected={this.showInviteTeamModal} />
          }
          {staffIntegration && this.props.currentModal === ADD_FROM_MINDBODY_MODAL &&
            <AddFromMindbodyModalContainer
              modalName={ADD_FROM_MINDBODY_MODAL}
              location={location}
              staffIntegration={this.props.staffIntegration} />
          }
        </div>
      </div>
    )
  }
}

PendingInvitesList.propTypes = {
  pendingInvites: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  resendInvite: PropTypes.func.isRequired,
  revokeInvite: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  staffIntegration: PropTypes.object,
  currentModal: PropTypes.string
}

export default PendingInvitesList
