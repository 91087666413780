import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HeardAboutMethodSelectView from "../components/HeardAboutMethodSelectView";
import heardAboutMethods from "modules/Stores/HeardAboutMethods";
import * as heardAboutMethodApiActions from "modules/Stores/HeardAboutMethods/apiActions";

import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    heardAboutMethods: heardAboutMethods.selectors.getHeardAboutMethods(state),
    loading: heardAboutMethods.selectors.getLoading(state),
    errors: heardAboutMethods.selectors.getErrors(state),
    pageDetails: heardAboutMethods.selectors.getPageDetails(state),
    selectedHeardAboutMethod: selectors.getSelectedHeardAboutMethod(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentView: actions.setCurrentView,
      selectHeardAboutMethod: actions.selectHeardAboutMethod,
      getHeardAboutMethods: heardAboutMethodApiActions.getHeardAboutMethods
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeardAboutMethodSelectView);
