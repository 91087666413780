import * as actionTypes from "./actionTypes";

export function selectUsers(users) {
  return {
    type: actionTypes.SET_USERS,
    users,
  };
}

export function setTypes(types) {
  return {
    type: actionTypes.SET_TYPES,
    types,
  };
}
