import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditLeadModal from "../components/EditLeadModal/EditLeadModal";
import details from "../modules/details";
import * as actions from "../actions";

import modal from "modules/Stores/Modal";
import userData from "modules/Stores/UserData";
import session from "modules/Stores/Session";
import lawfulProcessingBases from "modules/Stores/LawfulProcessingBases";
const { updateLead, deleteLead } = details.actions;
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    countries: userData.selectors.getCountries(state),
    fullDetail: details.selectors.getFullDetail(state),
    lawfulProcessingBases: lawfulProcessingBases.selectors.getLawfulProcessingBases(
      state
    ),
    timezone: session.selectors.getUserTimezone(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      deleteLead,
      updateLead,
      dataPortabilityExport: actions.dataPortabilityExport,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadModal);
