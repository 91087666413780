import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import StagesView from '../components/StagesView'
import FilterFormSections from 'modules/components/FilterFormSections'
import * as filterActions from '../actions'
import * as filterSelectors from '../selectors'

import userData from 'modules/Stores/UserData'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactoryWithoutStatuses(filterSelectors.getData)
  return {
    data: filterSelectors.getData(state),
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state),
    counts: filterSelectors.getCounts(state),
    queryString: queryStringSelector(state),
    countLoading: filterSelectors.getCountLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateFilters: filterActions.updateFilters,
    getLeadsCountWithQueryAndPageNumber: filterActions.getLeadsCountWithQueryAndPageNumber
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StagesView)
