import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'
import Datetime from 'react-datetime'
import UserSelectModalContainer from '../containers/UserSelectModalContainer'
import { SELECT_USER_MODAL } from '../constants'

const { Switch } = FormFields

export class AutoAssignRuleForm extends Component {

  handleDataUpdate = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true)
    this.props.onInput(newData)
  }

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true)
    this.props.onInput(newData)
  }

  saveEnabled = () => {
    const { data } = this.props
    const { endTime, startTime } = data.fields
    const endIsNull = endTime.value === null
    const startIsNull = startTime.value === null
    return endIsNull === startIsNull
  }

  onSelectUser = () => {
    this.props.showModal(SELECT_USER_MODAL)
  }

  render () {
    const { data } = this.props
    const { enabled, endTime, startTime, user } = data.fields

    const userDisplayName = () => {
      if (user.value && user.value.display_name) {
        return user.value.display_name
      } else {
        return 'Assign User'
      }
    }

    return (
      <div>
        <div className='row'>
          <div className='col-md-8'>
            <Switch
              name='enabled'
              label='Use auto-assign?'
              onChange={this.handleToggle}
              switchStyle='switch-sm switch-primary'
              value={enabled.value} />
          </div>
        </div>

        <div className='row push-10'>
          <div className='col-md-2'>
            <label className='control-label pull-left'>Call start time</label>
          </div>
          <div className='col-md-2'>
            <Datetime
              value={startTime.value}
              inputProps={{
                placeholder: '6:00 AM'
              }}
              dateFormat={false}
              timeConstraints={{ hours: { min: 1, max: 12 }, minutes: { step: 5 } }}
              viewMode={'time'}
              onChange={(date) => this.handleDataUpdate('startTime', date)} />
          </div>
          {startTime.value &&
            <i className='selectable si si-close fa-2x' onClick={() => this.handleDataUpdate('startTime', null)}
              style={{ marginLeft: '-6px' }} />
          }
        </div>

        <div className='row push-10'>
          <div className='col-md-2'>
            <label className='control-label pull-left'>Call end time</label>
          </div>
          <div className='col-md-2'>
            <Datetime
              value={endTime.value}
              inputProps={{
                placeholder: '9:00 PM'
              }}
              timeConstraints={{ hours: { min: 13, max: 23 }, minutes: { step: 5 } }}
              dateFormat={false}
              viewMode={'time'}
              onChange={(date) => this.handleDataUpdate('endTime', date)} />
          </div>
          {endTime.value &&
            <i className='selectable si si-close fa-2x' onClick={() => this.handleDataUpdate('endTime', null)} 
            style={{ marginLeft: '-6px' }} />
          }
        </div>

        <div className='row push-10'>
          <div className='col-md-2'>
            <label className='control-label pull-left'>Assign leads to*</label>
          </div>
          <div className='col-md-2'>
            <UserSelectModalContainer modalName={SELECT_USER_MODAL} handleDataUpdate={this.handleDataUpdate}/>
            <button className='btn btn-primary push-5-r push-10'
              type='button'
              onClick={this.onSelectUser}>
              <i className='selectable si si-user push-5-r' />
              {userDisplayName()}
            </button>
          </div>
        </div>
        
        <div className='row'>
          <div className='col-md-8'>
            <button className='btn btn-primary push-5-r push-10'
              style={{width: '130px'}}
              type='button'
              onClick={this.props.onSaveSelected}
              disabled={!this.saveEnabled()}>
              <i className='si si-target' /> Update Rule
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-8'>
            <button className='btn btn-danger push-5-r push-10'
              style={{width: '130px'}}
              type='button'
              onClick={this.props.onDeleteSelected}>
              <i className='fa fa-times' /> Delete Rule
            </button>
          </div>
        </div>
      </div>
    )
  }
}

AutoAssignRuleForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSaveSelected: PropTypes.func.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
}

export default AutoAssignRuleForm
