import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PendingInvitesList from './PendingInvitesList'
import InviteTeamMemberModalContainer from '../containers/InviteTeamMemberModalContainer'
import TeamMembersContainer from '../containers/TeamMembersContainer'
import ScheduleContainer from '../containers/ScheduleContainer'
import PermissionsContainer from '../containers/PermissionsContainer'
import SalesTargetsContainer from '../containers/SalesTargetsContainer'
import { InfoView } from 'modules/components'

import LocationSelect from 'modules/components/LocationSelect'

import { INVITE_MODAL_NAME } from '../constants'

export class SalesTeamsView extends Component {

  render () {
    const { loadingInvites, selectLocation, selectedLocationId, pendingInvites, resendInvite,
      revokeInvite, selectedUser, selectedLocation } = this.props
    const infoString = 'Manage your sales teams for each location. ' +
    'Select a team member to set their KPI\'s and permissions'
    return (
      <div>
        <InfoView colorThemeClass={'info'} title={'Sales Teams'} detail={infoString} />
        <div className='row'>
          <div className='col-md-12'>
            <LocationSelect.LocationSelectContainer
              onLocationSelect={selectLocation}
              selectedLocationId={selectedLocationId} />
          </div>
        </div>
        {selectedLocation &&
          <div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-12'>
                    <PendingInvitesList
                      pendingInvites={pendingInvites}
                      currentModal={this.props.currentModal}
                      loading={loadingInvites}
                      resendInvite={resendInvite}
                      revokeInvite={revokeInvite}
                      showModal={this.props.showModal}
                      location={selectedLocation}
                      staffIntegration={this.props.staffIntegration} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <TeamMembersContainer />
                  </div>
                </div>
                {selectedUser &&
                  <div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ScheduleContainer />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <PermissionsContainer />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SalesTargetsContainer />
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <InviteTeamMemberModalContainer modalName={INVITE_MODAL_NAME} />
          </div>
        }
      </div>
    )
  }
}

SalesTeamsView.propTypes = {
  pendingInvites: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
  loadingInvites: PropTypes.bool.isRequired,
  resendInvite: PropTypes.func.isRequired,
  revokeInvite: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteTeamMember: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  selectedLocation: PropTypes.object,
  staffIntegration: PropTypes.object,
  currentModal: PropTypes.string
}

export default SalesTeamsView
