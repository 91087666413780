import PropTypes from 'prop-types'
import React, { Component } from 'react'
import EmailItem from './EmailItem'
import { SEND_EMAIL_MODAL } from '../../../constants'
import { EmptyView } from 'modules/components'
import PagesContainer from '../containers/PagesContainer'
import emailHistory from 'modules/components/EmailHistory'

export class EmailsView extends Component {

  componentDidMount () {
    this.getEmailsWithPageNumber(1)
  }

  pageClicked = (pageNumber) => {
    this.getEmailsWithPageNumber(pageNumber)
  }

  getEmailsWithPageNumber = (pageNumber) => {
    const { lead, getEmails } = this.props
    getEmails(lead, pageNumber)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { lead } = this.props
    if (lead.id !== nextProps.lead.id) {
      nextProps.getEmails(nextProps.lead, 1)
    }
  }

  addEmail = () => {
    this.props.showModal(SEND_EMAIL_MODAL)
  }

  showEmailHistorySelected = (email) => {
    this.props.setShowHistoryEmailId(email.id)
    this.props.showModal(emailHistory.constants.MODAL_NAME)
  }

  render () {
    const { emails, lead, loading, selectedEmailForHistory } = this.props

    const messagesList = emails.map((email, index) => {
      return (
        <EmailItem key={index} email={email} lead={lead} onShowHistory={this.showEmailHistorySelected} />
      )
    })

    return (
      <div className='push-20'>
        {loading &&
          <div className='row'>
            <div className='col-sm-6 col-sm-offset-3 text-center'>
              <h3 className='push-10'>Loading Emails</h3>
              <i className='fa fa-2x fa-sun-o fa-spin' />
            </div>
          </div>
        }
        {!loading &&
          <div>
            {emails.length > 0 &&
              <div>
                <button className='btn btn-success push-5-r push-10' type='button' onClick={this.addEmail} >
                  <i className='fa fa-plus' /> Send email
                </button>
                <ul className='list list-simple'>
                  {messagesList}
                </ul>
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            }
            {emails.length === 0 &&
              <EmptyView
                colorThemeClass='primary'
                iconClass='si si-envelope'
                title='No emails yet'
                subtitle={(lead.mobile_number) ? null : 'Give this lead an email address to send an email'}
                buttonText={(lead.email) ? 'Send an email' : null}
                buttonSelected={(lead.email) ? this.addEmail : null} />
            }
          </div>
        }
        {selectedEmailForHistory &&
          <emailHistory.EmailHistoryContainer
            lead={lead}
            email={selectedEmailForHistory} />
        }
      </div>
    )
  }
}

EmailsView.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEmails: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedEmailForHistory: PropTypes.object,
  setShowHistoryEmailId: PropTypes.func.isRequired
}
