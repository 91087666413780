import PropTypes from "prop-types";
import React, { Component } from "react";
import { UserAvatar } from "modules/components/Avatars";
import { UserRoleTag } from "modules/components";

import EditableUserRoleTag from "../containers/EditUserRoleTagContainer";

export class TeamMemberRow extends Component {
  deleteClicked = () => {
    const { teamMember, onDelete } = this.props;
    onDelete(teamMember);
  };

  salesPersonClicked = () => {
    const { teamMember, onSelect } = this.props;
    onSelect(teamMember.id);
  };

  canSeeDelete = () => {
    const { user, teamMember, isCurrentUser } = this.props;
    if (isCurrentUser) return false;
    const userRoleName = user.user_role.name;
    const teamMemberRoleName = teamMember.user_role.name;
    switch (userRoleName) {
      case "Owner":
        return true;
      case "Admin":
        return [
          "Franchise Owner",
          "Salesmanager",
          "Salesperson",
          "Reception",
        ].includes(teamMemberRoleName);
      case "Franchise Owner":
        return ["Salesmanager", "Salesperson", "Reception"].includes(
          teamMemberRoleName
        );
      case "Salesmanager":
        return ["Salesperson", "Reception"].includes(teamMemberRoleName);
      case "Salesperson":
        return false;
      default:
        return undefined;
    }
  };

  onRoleChange = (userRole) => {
    const { updateTeamMemberUserRole, teamMember } = this.props;
    updateTeamMemberUserRole(teamMember, userRole);
  };

  render() {
    const { teamMember, isCurrentUser } = this.props;

    const lastRowStyle = isCurrentUser
      ? "ribbon ribbon-modern ribbon-primary"
      : "";
    const backgroundClassName = this.props.selected ? "bg-gray-lighter" : "";
    return (
      <tr
        className={"selectable " + backgroundClassName}
        key={teamMember.id}
        onClick={this.salesPersonClicked}
      >
        <td className="text-center">
          <UserAvatar
            avatarImage={teamMember.user_avatar}
            className="img-avatar48"
          />
        </td>
        <td className="font-w600">{teamMember.display_name}</td>
        <td>{teamMember.username || teamMember.email}</td>
        <td>
          {this.canSeeDelete() && (
            <EditableUserRoleTag
              userRole={teamMember.user_role}
              onChange={this.onRoleChange}
            />
          )}
          {!this.canSeeDelete() && (
            <UserRoleTag userRole={teamMember.user_role} />
          )}
        </td>
        {isCurrentUser && (
          <td className={"font-w600 " + lastRowStyle}>
            <div className="ribbon-box font-w200">You</div>
          </td>
        )}
        {this.canSeeDelete() && (
          <td className="text-center">
            <div className="btn-group">
              <button
                className="btn btn-xs btn-default"
                type="button"
                onClick={this.deleteClicked}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </td>
        )}
      </tr>
    );
  }
}

TeamMemberRow.propTypes = {
  updateTeamMemberUserRole: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  teamMember: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default TeamMemberRow;
