import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as helpers from 'modules/Helpers/IntegrationHelpers'

export class ThirdPartyIntegrationTableRow extends Component {

  render () {
    const { thirdPartyIntegration, thirdPartyIntegrationSelected, selected } = this.props
    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''
    return (
      <tr className={textClass + ' selectable ' + backgroundClass}
        onClick={() => thirdPartyIntegrationSelected(thirdPartyIntegration)}>
        <td>
          {helpers.titleForOption(thirdPartyIntegration.name)}
        </td>
      </tr>
    )
  }
}

ThirdPartyIntegrationTableRow.propTypes = {
  thirdPartyIntegration: PropTypes.object.isRequired,
  thirdPartyIntegrationSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
}
