import { NAME } from "./constants";

export const UPDATE_FILTER_DATA = NAME + "/UPDATE_FILTER_DATA";
export const CLEAR_ALL_FILTERS = NAME + "/CLEAR_ALL_FILTERS";

export const SET_TASK_DATE = NAME + "/SET_TASK_DATE";
export const SET_TASK_LENGTH = NAME + "/SET_TASK_LENGTH";

export const SET_LEADS = NAME + "/SET_LEADS";
export const SET_SELECTED_LEAD_ID = NAME + "/SET_SELECTED_LEAD_ID";
export const SET_TASK_TYPE = NAME + "/SET_TASK_TYPE";
