import PropTypes from "prop-types";
import React, { Component } from "react";
import TextInputView from "./TextInputView";

import "./editorStyles.css";

export class NewEmailMessageEditor extends Component {
  state = {
    charCount: 0,
    text: "",
    subject: "",
  };

  handleSubjectChange = (event) => {
    const textValue = event.target.value;
    this.setState({ subject: textValue });
    this.props.onSubjectChange(textValue);
  };

  handleTextChange = (value) => {
    this.setState({ text: value });
    this.props.onBodyChange(value);
  };

  setTemplate = (text, subject) => {
    this.setState({
      text: text,
      subject: subject,
    });
  };

  render() {
    const { user, mergeTags } = this.props;
    const readOnly = this.props.readOnly || false;
    const { subject, text } = this.state;

    const subjectValue =
      subject !== null && subject !== undefined ? subject : "";
    return (
      <div className="email-editor">
        <div className="email-editor-header">
          <ul>
            <li className="email-editor-header-btn email-editor-header-btn-close" />
            <li className="email-editor-header-btn email-editor-header-btn-minimize" />
            <li className="email-editor-header-btn email-editor-header-btn-expand" />
          </ul>
        </div>
        <input
          className="email-editor-subject"
          type="text"
          id="emailSubject"
          placeholder="Enter your email subject"
          disabled={readOnly}
          onChange={this.handleSubjectChange}
          autoComplete="off"
          value={subjectValue}
        />
        <div className="email-editor-body">
          <div className="row">
            <div className="col-lg-10 col-lg-offset-1 col-md-12">
              <div className="email-editor-body-content">
                <TextInputView
                  readOnly={readOnly}
                  mergeTags={mergeTags}
                  text={text}
                  setText={this.handleTextChange}
                />
                <div className="push-20-t">
                  <div>
                    <p style={{ marginBottom: "0px" }}>{user.display_name}</p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.position_title || "Customer Service team"}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {"{{Club phone number}}"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="email-editor-body-club-footer">
                <p style={{ marginBottom: "0px" }}>
                  {"<<"}Club address{">>"}
                </p>
                <p>
                  We don't want to be annoying. If you don't want us to email
                  you anymore,{" "}
                  <span style={{ textDecoration: "underline" }}>
                    click here
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewEmailMessageEditor.propTypes = {
  user: PropTypes.object.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool,
};

export default NewEmailMessageEditor;
