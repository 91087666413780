import { request, buildIndexUrl, GET, POST, PUT, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export const getTags = (pageNumber, searchTerm = null) => {
  const encodedSearchTerm = searchTerm ? encodeURIComponent(searchTerm) : null;

  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setTags(payload.lead_tags));
      dispatch(loadPageDetails(payload.meta));
    }

    const url = buildIndexUrl('lead_tags', pageNumber, null, encodedSearchTerm);
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

export const getTagOptions = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(fetchingPopularTagsSuccess(payload.lead_tags));
    }

    return request('common_tags', GET, null, onSuccess, onFailure);
  };
};

function fetchingPopularTagsSuccess(popularTags) {
  return {
    type: actionTypes.FETCHING_POPULAR_TAGS_SUCCESS,
    popularTags,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

// CREATE TAG
export const createTag = (tagName, afterSuccess) => {
  return (dispatch) => {
    const body = {
      lead_tag: {
        name: tagName,
      },
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      dispatch(addTag(payload.lead_tag));
      if (afterSuccess) {
        afterSuccess();
      }
      notify.success('Tag Created');
    }

    return request('lead_tags', POST, body, onSuccess, onFailure);
  };
};

export const updateTag = (tagId, tagName, oldValue, afterSuccess) => {
  return (dispatch) => {
    const body = {
      lead_tag: {
        name: tagName,
      },
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      if (afterSuccess) {
        afterSuccess();
      }
      dispatch(editTag(payload.lead_tag, oldValue));
      notify.success('Tag Updated');
    }

    return request('lead_tags/' + tagId, PUT, body, onSuccess, onFailure);
  };
};

export const createTagForLeadWithName = (lead, tagName, updateUI) => {
  return (dispatch) => {
    const newTag = { name: tagName };
    dispatch(updateUI(newTag));
    const body = {
      lead_tag: {
        name: tagName,
      },
    };
    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(addTag(payload.lead_tag));
    }

    return request('leads/' + lead.id + '/lead_tags', POST, body, onSuccess, onFailure);
  };
};

function addTag(tag) {
  return {
    type: actionTypes.ADD_TAG,
    tag,
  };
}

function editTag(tag, oldValue) {
  return {
    type: actionTypes.EDIT_TAG,
    tag,
    oldValue,
  };
}

function setTags(tags) {
  return {
    type: actionTypes.SET_TAGS,
    tags,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

// DELETE TAG
export const deleteTagForLead = (lead, tagName, updateUI) => {
  return (dispatch) => {
    dispatch(updateUI(tagName));
    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {}

    return request('leads/' + lead.id + '/lead_tags/' + encodeURIComponent(tagName), DELETE, null, onSuccess, onFailure);
  };
};

export const deleteTag = (tag, afterSuccess) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(removeTag(tag));
      if (afterSuccess) {
        afterSuccess();
      }
      notify.success('Tag Deleted');
    }

    return request('lead_tags/' + tag.id, DELETE, null, onSuccess, onFailure);
  };
};

export const migrateAndDeleteTag = (tag, toTagId, afterSuccess) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const body = {
      to_id: toTagId,
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      if (afterSuccess) {
        afterSuccess();
      }
      dispatch(removeTag(tag));
      notify.success('Tag Migrated');
    }
    const url = 'lead_tags/' + tag.id + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};

function removeTag(tag) {
  return {
    type: actionTypes.REMOVE_TAG,
    tag,
  };
}
