import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CallScheduleSelectView from "../components/CallScheduleSelectView";
import modal from "modules/Stores/Modal";

import * as actions from "../actions";
import * as selectors from "../selectors";

function mapStateToProps(state, props) {
  return {
    callSchedules: selectors.getCallSchedules(state),
    errors: selectors.getErrors(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    selectedCallSchedule: selectors.getSelectedCallSchedule(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCallSchedules: actions.getCallSchedules,
      setSelectedCallSchedule: actions.setSelectedCallSchedule,
      hideModal: modal.actions.hideModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallScheduleSelectView);
