import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

export class NewNoteModal extends Component {
  state = {
    detail: null,
  };

  handleChange = (event) => {
    const textValue = event.target.value;
    this.setState({ ...this.state, detail: textValue });
  };

  createNote = () => {
    const { lead, createNoteForLead } = this.props;
    const { detail } = this.state;
    createNoteForLead(lead, detail);
  };

  render() {
    const { lead, loading, currentModal, hideModal, modalName } = this.props;
    const { detail } = this.state;

    const addNoteDisabled = !(detail && detail.length > 1);

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal} backdrop="static">
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                New Note For {lead.given_name}
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <textarea
                  className="form-control"
                  name="note-detail"
                  rows="8"
                  placeholder="What is your note?"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn btn-success push-5-r push-10" type="button" disabled={addNoteDisabled} onClick={this.createNote}>
              <i className="fa fa-plus" /> Add Note
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

NewNoteModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  createNoteForLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default NewNoteModal;
