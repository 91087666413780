import { request, GET, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export const createCallSchedule = (name, leadSourceId, isDefault, afterSuccess) => {
  return (dispatch) => {
    const body = {
      call_schedule: {
        name: name,
        default: isDefault,
        filters: {
          lead_source_ids: leadSourceId,
        },
      },
    };

    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success('Call schedule created');

      dispatch(addCallSchedule(payload.call_schedule));
      dispatch(selectCallScheduleId(payload.call_schedule.id));
      if (afterSuccess) {
        afterSuccess();
      }
    }

    return request('call_schedules', POST, body, onSuccess, onFailure);
  };
};

export const fetchCallSchedulesForPageNumber = (pageNumber) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setCallSchedules(payload.call_schedules));
      dispatch(loadPageDetails(payload.meta));
    }

    return request('call_schedules?page[number]=' + pageNumber, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setCallSchedules(callSchedules) {
  return {
    type: actionTypes.SET_CALL_SCHEDULES,
    callSchedules,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

export function selectCallScheduleId(selectedCallScheduleId) {
  return {
    type: actionTypes.SET_SELECTED_CALL_SCHEDULE_ID,
    selectedCallScheduleId,
  };
}

// list updates
export function removeCallScheduleWithId(callScheduleId) {
  return {
    type: actionTypes.DELETE_CALL_SCHEDULE,
    callScheduleId,
  };
}

export function addCallSchedule(callSchedule) {
  return {
    type: actionTypes.ADD_CALL_SCHEDULE,
    callSchedule,
  };
}

export function updateCallSchedule(callSchedule) {
  return {
    type: actionTypes.UPDATE_CALL_SCHEDULE,
    callSchedule,
  };
}
