import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CalculatingSelectSection } from "modules/components/FilterFormSections/components";
import { stringForCalculatingOption } from "../helpers";

function mapStateToProps(state, props) {
  return {
    stringForCalculatingOption: stringForCalculatingOption,
    calculatingOptions: props.calculatingOptions,
    calculatingOption: props.calculatingOption,
    setCalculatingOption: props.setCalculatingOption
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatingSelectSection);
