import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView } from 'modules/components'

export class TableView extends Component {

  rows = () => {
    return this.props.data.map((userRow, index) => {
      const cells = userRow.map((data, index) => {
        return (
          <td key={index.toString() + data.toString()}>{data}</td>
        )
      })

      return (
        <tr key={index} >
          {cells}
        </tr>
      )
    })
  }

  headerRows = () => {
    return this.props.headers.map((header, index) => {
      return (
        <th key={index}>{header}</th>
      )
    })
  }

  render () {
    const { loading, isEmpty } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Full report</h3>
        </div>
        <div className='block-content block-content-full'>
          {isEmpty &&
            <EmptyView
              colorThemeClass='primary'
              iconClass={'fa fa-table'}
              title='No data for these settings'
              subtitle='Update your filters or come back later.' />
          }
          {!isEmpty &&
            <div className='table-responsive'>
              <table className='table table-vcenter'>
                <thead>
                  <tr>
                    <th>Name</th>
                    {this.headerRows()}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.rows()}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    )
  }
}

TableView.propTypes = {
  loading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired
}

export default TableView
