import React from 'react'
import { ToolTip } from "modules/components";

export const enabledLabel = (
  <span>
    Enable{" "}
    <ToolTip text={"Enable or disable this subscription"} id="enabledToggle" />
  </span>
);

export const createAppointmentLabel = (
  <span>
    Create Appointment{" "}
    <ToolTip text={"Creates the appointment in GymLeads"} id="createAppointmentToggle" />
  </span>
);

export const cancelAppointmentLabel = (
  <span>
    Cancel Appointment{" "}
    <ToolTip text={"Updates appointments if leads cancel"} id="cancelAppointmentToggle" />
  </span>
);

export const createLeadLabel = (
  <span>
    Create Lead{" "}
    <ToolTip text={"Creates a profile for any new leads"} id="createLeadToggle" />
  </span>
);

export const updateLeadLabel = (
  <span>
    Update Lead{" "}
    <ToolTip text={"Updates the lead profile for any changes"} id="updateLeadToggle" />
  </span>
);
