import { request, POST } from 'utils/apiUtils';
import notify from 'modules/Notifications';

export const createAutomaticSchedule = (
  queryString,
  compareByOption,
  calculatingOption,
  sendTime,
  selectedDays,
  reportName,
  reportType
) => {
  const body = {
    report_type: reportType,
    name: reportName,
    comparing: compareByOption,
    calculating: calculatingOption,
    schedule: {
      send_time: sendTime,
      days_to_send: selectedDays.join(','),
    },
  };

  return (dispatch) => {
    function onFailure(payload) {
      notify.error('Failed to create automatic report');
    }

    function onSuccess(payload) {
      notify.success('Successfully created automatic report');
    }

    let url = `automatic_reports`;
    if (queryString) {
      url = `${url}?${queryString}`;
    }
    return request(url, POST, body, onSuccess, onFailure);
  };
};
