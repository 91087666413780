import * as actionTypes from './actionTypes'

const RESET_PASSWORD_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return ({ ...state, loading: true, errors: null })
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return ({ ...state, loading: false, success: true })
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return ({ ...state, loading: false, success: false, errors: action.errors.errors })
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return ({ ...state, loading: false, errors: null })
  },
  [actionTypes.TOKEN_VALIDATED]: (state, action) => {
    return ({ ...state, validateToken: action.validateToken })
  },
  [actionTypes.RESET_PASSWORD_SUCCESS]: (state, action) => {
    return ({ ...state, success: true })
  },
  [actionTypes.WHITELABEL_PROFILE]: (state, action) => {
    return ({ ...state, whitelabelProfile: action.whitelabelProfileLogo.whitelabel_profile })
  }
}

const initialState = {
  loading: false,
  success: null,
  validateToken: null,
  errors: null,
  whitelabelProfile: null
}
export default function reducer (state = initialState, action) {
  const handler = RESET_PASSWORD_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
