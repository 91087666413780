import React, { Component } from "react";
import styled from "styled-components";
import {
  isCellSelected,
  humanReadableStatus,
  calculateTotalSum,
  calculateTotalAverage
} from "../helpers";
import {
  INACTIVE_STATUS,
  LOST_STATUS,
  CLOSED_STATUS,
  NO_CHANGE
} from "../../../constants";
import { RichToolTip } from "modules/components";
import * as tooltips from "../tooltips";

export default class TrialPerformanceView extends Component {
  render() {
    const { trialsData, setSelectedCell, selectedCell, loading } = this.props;

    const statusesArray = [
      CLOSED_STATUS,
      INACTIVE_STATUS,
      LOST_STATUS,
      NO_CHANGE
    ];
    const blockLoading = loading ? "block-opt-refresh" : "";

    return (
      <div className={`block block-rounded block-bordered ${blockLoading}`}>
        <div className="block-header">
          <h3 className="block-title">Trial Performance</h3>
        </div>
        <div className="block-content push-50">
          {trialsData && trialsData.length > 0 && (
            <TableContainer>
              {statusesArray.map((status, index) => (
                <div
                  className="table-responsive"
                  key={`trialStatusTable-${index}`}
                >
                  <HeaderContainer>
                    <StatusHeader>{humanReadableStatus(status)}</StatusHeader>
                    <TooltipContainer>
                      {status === NO_CHANGE ? (
                        <RichToolTip
                          text={tooltips.leadNoChangeStatus()}
                          toptipId={`tooltip-${index}`}
                        />
                      ) : (
                        <RichToolTip
                          text={tooltips.leadStatus(
                            humanReadableStatus(status)
                          )}
                          toptipId={`tooltip-${index}`}
                        />
                      )}
                    </TooltipContainer>
                  </HeaderContainer>
                  <StyledTable className="table table-vcenter">
                    <thead>
                      <tr>
                        <TableHeader>Count</TableHeader>
                        {status !== NO_CHANGE && (
                          <TableHeader>Average</TableHeader>
                        )}
                      </tr>
                    </thead>
                    <TBody>
                      {trialsData.map((trial, index) => {
                        return (
                          <TableRow key={`performanceRow-${index}-${status}`}>
                            <StyledCell
                              selected={isCellSelected(
                                trial,
                                status,
                                selectedCell
                              )}
                              onClick={() => setSelectedCell(trial.id, status)}
                            >
                              {trial.data[status].value}
                            </StyledCell>
                            {status !== NO_CHANGE && (
                              <StyledCell
                                selected={isCellSelected(
                                  trial,
                                  status,
                                  selectedCell
                                )}
                                onClick={() =>
                                  setSelectedCell(trial.id, status)
                                }
                              >
                                {trial.data[status].average}
                              </StyledCell>
                            )}
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <StyledCell
                          selected={isCellSelected(null, status, selectedCell)}
                          onClick={() =>
                            setSelectedCell(null, status, selectedCell)
                          }
                        >
                          <TotalSubheader>
                            {calculateTotalSum(trialsData, status)} leads
                          </TotalSubheader>
                        </StyledCell>
                        {status !== NO_CHANGE && (
                          <StyledCell
                            selected={isCellSelected(
                              null,
                              status,
                              selectedCell
                            )}
                            onClick={() => setSelectedCell(null, status)}
                          >
                            <TotalSubheader>
                              {Math.round(
                                calculateTotalAverage(trialsData, status)
                              )}{" "}
                              days
                            </TotalSubheader>
                          </StyledCell>
                        )}
                      </TableRow>
                    </TBody>
                  </StyledTable>
                </div>
              ))}
            </TableContainer>
          )}
        </div>
      </div>
    );
  }
}

const TotalSubheader = styled.span`
  font-weight: 700;
  font-size: 14px;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTable = styled.table`
  /* tr td:hover {
    background-color: #ccdcf1;
    cursor: pointer;
  } */
`;

const TBody = styled.tbody`
  tr:hover {
    background-color: #ccdcf1 !important;
    cursor: pointer;
  }
`;

const StyledCell = styled.td`
  :hover {
    background-color: ${props =>
      props.selected ? "rgba(0,126,255,.08)" : "#ccdcf1 !important"};
  }
  background-color: ${props =>
    props.selected ? "rgba(0,126,255,.08)" : "inherit"};
  color: "#646464";
`;

const TableHeader = styled.th`
  text-align: left;
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
`;

const TableRow = styled.tr`
  /* text-align: center; */
`;

const HeaderContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const TooltipContainer = styled.span`
  position: relative;
  top: -4px !important;
  font-size: 10px;
  margin-left: 2px;
`;

const StatusHeader = styled.span`
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  text-transform: uppercase;
`;
