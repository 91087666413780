import * as actionTypes from './actionTypes'

const PREVIOUS_REALLOCATIONS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_REALLOCATIONS]: (state, action) => {
    return { ...state, reallocations: action.reallocations }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalCount: action.pageDetails.total_count
    }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    reallocations: [],
    loading: false,
    pageCount: 1,
    currentPage: 1,
    totalCount: 0,
    errors: null
  }
}

export default function reducer (state = initialState(), action) {
  const handler = PREVIOUS_REALLOCATIONS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
