import * as actionTypes from './actionTypes'

const SUBSCRIPTION_DETALS_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state) => {
    return { ...state, loading: false }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  },
  [actionTypes.BILLING_PLAN_UPDATED]: (state, action) => {
    return { ...state, billingPlan: action.billingPlan }
  }
}

const initialState = {
  loading: false,
  errors: null,
  billingPlan: {}
}

export default function reducer (state = initialState, action) {
  const handler = SUBSCRIPTION_DETALS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
