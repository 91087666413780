import PropTypes from "prop-types";
import React, { Component } from "react";

export class LeadGoalTableRow extends Component {
  render() {
    const { leadGoal, leadGoalSelected, selected } = this.props;
    const backgroundClass = selected ? "bg-primary" : "";
    const textClass = selected ? "text-white" : "";
    return (
      <tr
        className={textClass + " selectable " + backgroundClass}
        onClick={() => leadGoalSelected(leadGoal)}
      >
        <td>{leadGoal.goal_name}</td>
      </tr>
    );
  }
}

LeadGoalTableRow.propTypes = {
  leadGoal: PropTypes.object.isRequired,
  leadGoalSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

LeadGoalTableRow.defaultProps = {
  showSaleDetails: true
};
