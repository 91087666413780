import { NAME } from './constants'

export const LOADING = NAME + '/LOADING'
export const SET_LOADING = NAME + '/SET_LOADING'
export const LOAD_SUCCESS = NAME + '/LOAD_SUCESS'
export const LOAD_FAILURE = NAME + '/LOAD_FAILURE'

export const TEAM_MEMBER_SELECTED = NAME + '/TEAM_MEMBER_SELECTED'
export const TEAM_MEMBER_PERMISSIONS_UPDATED = NAME + '/TEAM_MEMBER_PERMISSIONS_UPDATED'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'

export const TEAM_UPDATED = NAME + '/TEAM_UPDATED'
export const TEAM_MEMBER_REMOVED = NAME + '/TEAM_MEMBER_REMOVED'
export const TEAM_MEMBER_UPDATED = NAME + '/TEAM_MEMBER_UPDATED'
