import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LoginFormValidator } from '../modules/validators';
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
import STRINGS from 'strings';
const { TextField, PasswordField, Checkbox } = FormFields;

export class LoginForm extends Component {
  state = {
    isValid: false,
    fields: {
      username: {
        value: null,
        isValid: false,
      },
      password: {
        value: null,
        isValid: false,
      },
      rememberMe: {
        value: false,
        isValid: true,
      },
    },
  };

  handleSubmit = (event) => {
    event && event.preventDefault();
    const { fields } = this.state;
    const username = fields.username.value;
    const password = fields.password.value;
    const rememberMe = fields.rememberMe.value;
    this.props.onSubmit(username, password, rememberMe);
  };

  handleInput = (name, value, isValid) => {
    this.setState(generateNewFormState(this.state, name, value, isValid));
  };

  handleToggle = (name, value) => {
    this.setState(generateNewFormState(this.state, name, value, true));
  };

  render() {
    const Strings = STRINGS.Login;
    const { loading, errors } = this.props;
    const { isValid } = this.state;
    const buttonTitle = loading ? Strings.loggingIn : Strings.logIn;
    const loginSymbol = loading ? 'fa fa-circle-o-notch fa-spin pull-right' : 'si si-login pull-right';
    const buttonEnabled = isValid && !loading;

    const renderButton = () => (
      <div className="form-group">
        <div className="col-xs-12 col-sm-6 col-sm-offset-3">
          <button
            id="log-in-button"
            type="submit"
            className="btn btn-block btn-primary"
            disabled={!buttonEnabled}
            onClick={this.handleSubmit}
          >
            <i className={loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>
    );
    return (
      <div>
        {errorPanelForErrors(errors)}
        <form className="form-horizontal push-30-t" onSubmit={this.handleSubmit}>
          <TextField
            name="username"
            label={Strings.username}
            formFieldStyle="form-material form-material-primary"
            onChange={this.handleInput}
            disabled={loading}
            validator={LoginFormValidator}
            value={this.state.fields.username.value}
          />
          <PasswordField
            name="password"
            label={Strings.password}
            formFieldStyle="form-material form-material-primary"
            onChange={this.handleInput}
            disabled={loading}
            validator={LoginFormValidator}
          />
          <Checkbox
            name="rememberMe"
            label={Strings.rememberMe}
            onChange={this.handleToggle}
            switchStyle="primary"
            checked={this.state.fields.rememberMe.value}
            disabled={loading}
          />
          {renderButton()}
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default LoginForm;
