import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import { iconForNotification } from "../helpers";

export class NotificationItem extends Component {
  notificationClicked = () => {
    const { notification, onNotificationSelect } = this.props;
    onNotificationSelect(notification);
  };

  lead = () => {
    const { notification } = this.props;
    const owner = notification.owner;
    if (!owner) {
      return;
    }

    return owner.notification_lead;
  };

  user = () => {
    const { notification } = this.props;
    return notification.user;
  };

  render() {
    const { notification } = this.props;
    const completedMoment = moment(notification.created_at).fromNow();
    const user = this.user();
    const lead = this.lead();
    return (
      <tr className="selectable" onClick={this.notificationClicked}>
        <td>
          <i
            className={"notification-icon " + iconForNotification(notification)}
          />
        </td>
        <td>
          {user && user.display_name}
          {!user && "Unkown User"}
        </td>
        <td>
          {lead && lead.display_name}
          {!lead && "Unkown lead"}
        </td>
        <td>{completedMoment}</td>
      </tr>
    );
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onNotificationSelect: PropTypes.func.isRequired,
};

export default NotificationItem;
