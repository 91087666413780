import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import billing from 'modules/Stores/Billing'
import modal from 'modules/Stores/Modal'

import * as actions from '../actions'

import PaymentMethodsView from '../components/PaymentMethodsView'

function mapStateToProps (state) {
  return {
    paymentMethods: billing.selectors.getPaymentMethods(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    deletePaymentMethodId: actions.deletePaymentMethodId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsView)
