import React from 'react';

import { Text, Box } from '../Foundation';
import GymleadsLoader from '../GymleadsLoader';
import { BoxProps } from '../Foundation/Box';

import { colors } from 'styleguide/colors';
import { space } from 'styleguide/space';

interface ButtonComponentProps {
  Primary1: React.FC<BoxProps>;
  Primary2: React.FC<BoxProps>;
  Secondary: React.FC<BoxProps>;
  Link: React.FC<BoxProps>;
}

const createButton: (buttonStyle: BoxProps, displayName: string) => React.FC<BoxProps> = (boxStyle, displayName) => {
  const component: React.FC<BoxProps> = (props) => {
    const { isLoading } = props;
    return (
      <Box {...boxStyle} {...props} as="button" disabled={isLoading || props.disabled}>
        {!isLoading && (
          <Text.P1 textAlign="center" color={boxStyle.color} fontSize={14} marginBottom={0}>
            {props.children}
          </Text.P1>
        )}
        {isLoading && <GymleadsLoader color={boxStyle.color} />}
      </Box>
    );
  };

  component.displayName = displayName;
  return component;
};

const DefaultStyles = {
  border: 'none',
  borderRadius: 2,
  bg: 'white',
  py: space.S,
  px: space.M,
  height: 36,
  alignItems: 'center',
  cursor: 'pointer',
};

const buttonStyles = {
  Primary1: {
    ...DefaultStyles,
    bg: colors.blue[1],
    focusFill: colors.blue[0],
    hoverFill: colors.blue[0],
    color: 'white',
    boxShadow: '0px 8px 10px rgba(22, 39, 83, 0.15)',
  },
  Primary2: {
    ...DefaultStyles,
    bg: 'white',
    color: colors.blue[0],
    boxShadow: '0px 8px 10px rgba(22, 39, 83, 0.15)',
    hoverFill: colors.grey[2],
  },
  Secondary: {
    ...DefaultStyles,
    px: space.L,
    color: colors.blue[0],
    border: `1px solid ${colors.blue[0]};`,
  },
  Link: {
    ...DefaultStyles,
    color: colors.blue[0],
    alignItems: 'flex-start',
  },
};

export const Button: ButtonComponentProps = {
  Primary1: createButton(buttonStyles.Primary1, 'Primary1'),
  Primary2: createButton(buttonStyles.Primary2, 'Primary2'),
  Secondary: createButton(buttonStyles.Secondary, 'Secondary'),
  Link: createButton(buttonStyles.Link, 'Link'),
};

export default Button;
