import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HeardAboutMethodSelectModal from "../components/HeardAboutMethodSelectModal";
import heardAboutMethods from "modules/Stores/HeardAboutMethods";
import * as heardAboutMethodApiActions from "modules/Stores/HeardAboutMethods/apiActions";
import modal from "modules/Stores/Modal";

function mapStateToProps(state) {
  return {
    heardAboutMethods: heardAboutMethods.selectors.getHeardAboutMethods(state),
    loading: heardAboutMethods.selectors.getLoading(state),
    errors: heardAboutMethods.selectors.getErrors(state),
    pageDetails: heardAboutMethods.selectors.getPageDetails(state),
    currentModal: modal.selectors.getCurrentModal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getHeardAboutMethods: heardAboutMethodApiActions.getHeardAboutMethods,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeardAboutMethodSelectModal);
