import * as actionTypes from './actionTypes'

export function selectLocationId (location) {
  return {
    type: actionTypes.LOCATION_SELECTED,
    location
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
