import PropTypes from "prop-types";
import React, { Component } from "react";

import FilterSection from "./FilterSection";
import Select from "react-select";
import "react-select/dist/react-select.css";

export default class ContainsSection extends Component {
  handleUpdate = (event) => {
    var newTags = {};
    event.forEach((tag) => {
      newTags[tag.value] = {
        value: true,
        id: tag.value,
        isValid: true,
      };
    });

    const newData = {
      fields: newTags,
    };

    this.props.onUpdate(newData, this.props.sectionName);
  };

  tagsFromData = () => {
    const tags = this.props.data.fields;
    const keys = Object.keys(tags);
    return keys.map((key) => {
      const tag = tags[key];
      return {
        label: key,
        value: tag.id,
      };
    });
  };

  generateSuggestions = () => {
    return this.props.suggestions.map((tag) => {
      return {
        value: tag,
        label: tag.toString(),
      };
    });
  };

  render() {
    const title = this.props.title || "Tags";
    return (
      <FilterSection
        title={title}
        showBottomBorder={this.props.showBottomBorder}
        blockStyle={this.props.blockStyle}
      >
        <div className="col-sm-12">
          <div className="form-material">
            <Select
              multi={true}
              options={this.generateSuggestions()}
              value={this.tagsFromData()}
              onChange={(newValue) => this.handleUpdate(newValue)}
            />
          </div>
        </div>
      </FilterSection>
    );
  }
}

ContainsSection.propTypes = {
  data: PropTypes.object.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool,
  title: PropTypes.string,
  blockStyle: PropTypes.string,
};
