import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'

import { InfoView } from 'modules/components'

import AutoAssignRuleFormContainer from '../containers/AutoAssignRuleFormContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

export class CurrentAutoAssignRuleView extends Component {

  state = {
    showDanger: false
  }

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true })
  }

  UNSAFE_componentWillMount () {
    const { autoAssignRule, prefilFormData } = this.props
    prefilFormData(autoAssignRule)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.autoAssignRule.id !== this.props.autoAssignRule.id) {
      nextProps.prefilFormData(nextProps.autoAssignRule)
    }
  }

  saveClicked = () => {
    const { updateAutoAssignRuleWithData, autoAssignRule, data, location } = this.props
    updateAutoAssignRuleWithData(location, autoAssignRule, data)
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false })
  }

  deleteAutoAssignRule = () => {
    const { deleteAutoAssignRule, autoAssignRule, location } = this.props
    deleteAutoAssignRule(location, autoAssignRule)
    this.dismissPopups()
  }

  render () {
    const { loading, data, errors, onInput } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const infoString = 'Automatically assign incoming leads to a user. If a lead comes in outside of ' +
    'calling hours, we\'ll schedule any automatic calls for the following day.'
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Auto-assign Rule for { this.props.location.admin_display_name }</h3>
          </div>
          <div className='block-content'>
            <InfoView colorThemeClass={'info'} detail={infoString} />
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <AutoAssignRuleFormContainer
                data={data}
                onSaveSelected={this.saveClicked}
                onDeleteSelected={this.showDangerPopup}
                onInput={onInput} />
            </form>
          </div>
          {this.state.showDanger &&
            <SweetAlert
              danger
              showCancel
              cancelBtnBsStyle='default'
              confirmBtnBsStyle='danger'
              confirmBtnText='Delete auto-assign rule'
              title='Are you sure?'
              onConfirm={this.deleteAutoAssignRule}
              onCancel={this.dismissPopups}>
              This will delete your auto-assign rule. Are you sure?
            </SweetAlert>
          }
        </div>
      </div>
    )
  }
}

CurrentAutoAssignRuleView.propTypes = {
  location: PropTypes.object.isRequired,
  prefilFormData: PropTypes.func.isRequired,
  updateAutoAssignRuleWithData: PropTypes.func.isRequired,
  deleteAutoAssignRule: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  autoAssignRule: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default CurrentAutoAssignRuleView
