import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectLeadGoalTableRow extends Component {
  rowSelected = () => {
    const { leadGoal, onLeadGoalSelect } = this.props;
    onLeadGoalSelect(leadGoal);
  };

  render() {
    const { leadGoal, leadGoalSelected } = this.props;
    const className = leadGoalSelected ? "selected" : "selectable";
    return (
      <tr className={className} onClick={() => this.rowSelected(leadGoal)}>
        <td>{leadGoal.goal_name}</td>
      </tr>
    );
  }
}

SelectLeadGoalTableRow.propTypes = {
  leadGoal: PropTypes.object.isRequired,
  leadGoalSelected: PropTypes.bool.isRequired,
  onLeadGoalSelect: PropTypes.func.isRequired
};
