import {
  INACTIVE_STATUS,
  LOST_STATUS,
  CLOSED_STATUS,
  NO_CHANGE,
} from "../../constants";

export function calculateTotalSum(data, status) {
  const totalArray = data.map((trial) => trial.data[status].value);
  const totalSum = totalArray.reduce((a, b) => a + b, 0);
  return totalSum;
}

export function calculateTotalAverage(data, status) {
  const totalArray = data.map((trial) => {
    const value = trial.data[status].value;
    const average = trial.data[status].average;
    return value > 0 ? average : null;
  });
  const totalArrayFiltered = totalArray.filter((num) => num !== null);
  const totalSum = totalArrayFiltered.reduce((a, b) => a + b, 0);
  return totalSum !== 0 ? totalSum / totalArrayFiltered.length : 0;
}

export function isCellSelected(trial, status, selectedCell) {
  if (!selectedCell) {
    return false;
  }
  if (!trial && selectedCell.status === status && !selectedCell.trialId) {
    return true;
  } else if (
    trial &&
    selectedCell &&
    selectedCell.trialId === trial.id &&
    selectedCell.status === status
  ) {
    return true;
  } else {
    return false;
  }
}

export function humanReadableStatus(status) {
  switch (status) {
    case CLOSED_STATUS:
      return "Closed";
    case LOST_STATUS:
      return "Lost";
    case INACTIVE_STATUS:
      return "Inactive";
    case NO_CHANGE:
      return "No Change";
    default:
      return "";
  }
}
