import PropTypes from "prop-types";
import React, { Component } from "react";
import "./Header.css";
import { linksForUser } from "./linksForUser";
import { NavLink } from "modules/components";
import STRINGS from "strings/";

export class Menu extends Component {
  state = {
    collapsed: false,
  };

  toggleVisability = () => {
    const { collapsed } = this.state;
    this.setState({ ...this.state, collapsed: !collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const { currentPath, user } = this.props;
    const buttonCollapsedStyle = collapsed ? "collapsed" : "";
    const menuCollapsedStyleIn = collapsed ? "in" : "";

    const navLinks = () => {
      const links = linksForUser(user).map((link, index) => {
        return (
          <NavLink
            key={index}
            to={link.to}
            onClick={this.toggleVisability}
            currentPath={currentPath}
          >
            <i className={"fa " + link.style + " push-5-r"} />
            {link.title}
          </NavLink>
        );
      });

      return (
        <div className="content-mini content-boxed">
          <ul className="nav nav-pills-dark nav-pills nav-sub-header push">
            {links}
            <li>
              <a
                href="http://support.gymleads.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-question-circle push-5-r" />
                {STRINGS.Menu.support}
              </a>
            </li>
          </ul>
        </div>
      );
    };

    return (
      <div>
        <div className="bg-gray-lighter visible-xs">
          <div className="content-mini content-boxed">
            <button
              className={
                "btn btn-block btn-default visible-xs push " +
                buttonCollapsedStyle
              }
              onClick={this.toggleVisability}
            >
              <i className="fa fa-navicon push-5-r" />
              {STRINGS.Menu.menu}
            </button>
          </div>
          <div
            className={
              "bg-primary-lighter navbar-collapse secure-navbar-collapse remove-padding " +
              menuCollapsedStyleIn
            }
            id="sub-header-nav"
          >
            {navLinks()}
          </div>
        </div>
        <div className="bg-primary-lighter remove-padding hidden-xs">
          {navLinks()}
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  user: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default Menu;
