import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";
import userData from "modules/Stores/UserData";
import FilterFormSections from "modules/components/FilterFormSections";

import filters from "../modules/filters";
import countsLoader from "../modules/countsLoader";

function mapStateToProps(state) {
  const useStatusDate = filters.selectors.getUseStatusDate(state);
  let queryString = FilterFormSections.selectors.queryStringFactory(
    filters.selectors.getData
  )(state);
  if (useStatusDate === false) {
    queryString = filters.selectors.convertUpdatedQueryStringToCreated(
      queryString
    );
  }
  return {
    useStatusDate: useStatusDate,
    showingMore: filters.selectors.getShowingMore(state),
    data: filters.selectors.getData(state),
    activeExtraFiltersCount: filters.selectors.activeExtraFiltersCount(state),
    permissions: userData.selectors.getMembershipPermissions(state),
    queryString: queryString
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUseStatusDate: filters.actions.setUseStatusDate,
      updateFilters: filters.actions.updateFilters,
      clearAllFilters: filters.actions.clearAllFilters,
      showFilters: filters.actions.showFilters,
      hideFilters: filters.actions.hideFilters,
      showModal: modal.actions.showModal,
      getLeadsCountWithQuery: countsLoader.actions.getLeadsCountWithQuery
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(filters.components.FiltersView);
