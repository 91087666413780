export const availableFields = [
  { displayName: 'External system id', key: 'export_id' },
  { displayName: 'First name', key: 'given_name' },
  { displayName: 'Last name', key: 'surname' },
  { displayName: 'Email', key: 'email' },
  { displayName: 'Workplace Name', key: 'workplace_name' },
  { displayName: 'Date of Birth', key: 'date_of_birth' },
  { displayName: 'Mobile number', key: 'mobile_number' },
  { displayName: 'Source ', key: 'lead_source' },
  { displayName: 'Lead lost reason', key: 'lead_lost_reason' },
  { displayName: 'Heard about method', key: 'heard_about_method' },
  { displayName: 'Gender', key: 'gender' },
  { displayName: 'Address', key: 'address_line_one' },
  { displayName: 'City', key: 'city' },
  { displayName: 'State', key: 'state' },
  { displayName: 'Zip/postcode', key: 'zip_postcode' },
  { displayName: 'Creation date', key: 'created_at' },
  { displayName: 'First contacted date', key: 'first_contact' },
  { displayName: 'Closed date', key: 'sale_at' },
  { displayName: 'Lost date', key: 'lost_at' },
  { displayName: 'Tags', key: 'tags' },
  { displayName: 'opted out', key: 'opted_out' },
  { displayName: 'Status', key: 'status' },
  { displayName: 'MINDBODY id', key: 'mbo_id' },
  { displayName: 'Notes', key: 'note' },
  { displayName: 'Salesperson', key: 'salesperson_id' },
  { displayName: 'Opted In Date', key: 'opted_in_at' },
  { displayName: 'Lawful basis', key: 'lawful_processing_basis' }
]

export const possibleFieldMappings = {
  given_name: ['given_name', 'first_name', 'name', 'first name', 'given name', 'name'],
  surname: ['surname', 'last_name', 'last name'],
  address_line_one: ['address'],
  date_of_birth: ['date_of_birth', 'birthday', 'date of birth'],
  email: ['email'],
  mobile_number: ['mobile_number', 'number', 'phone_number',
    'phone_mobile', 'mobile', 'mobile number', 'phone number', 'phone', 'phone mobile', 'mobile_phone'],
  lead_source: ['source', 'lead source', 'lead_source'],
  gender: ['gender', 'sex'],
  opted_out: ['opted_out', 'opt out', 'opted_out_of_sms'],
  zip_postcode: ['postcode', 'zip', 'zip/postcode'],
  first_contact: ['first_contact', 'first call'],
  salesperson_id: ['salesperson']
}

export function guessedHeaderMappings (headers) {
  const possibleRemapKeys = Object.keys(possibleFieldMappings)
  let remappedHeaders = headers.slice()
  for (const key of possibleRemapKeys) {
    const possibleFields = possibleFieldMappings[key]
    for (const possibleField of possibleFields) {
      const index = headers.findIndex(header => header.toLowerCase() === possibleField.toLowerCase())
      if (index > -1) {
        remappedHeaders = [...remappedHeaders.slice(0, index), key, ...remappedHeaders.slice(index + 1)]
        break
      }
    }
  }

  return remappedHeaders
}
