import * as actionTypes from './actionTypes';

const APPOINTMENT_LIST_ACTION_HANDLERS = {
  [actionTypes.FETCH]: (state, action) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.FETCH_SUCCESS]: (state, action) => {
    return { ...state, loading: false, appointments: action.appointments };
  },
  [actionTypes.FETCH_FAILED]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.SELECT]: (state, action) => {
    return { ...state, selectedAppointmentId: action.appointmentId };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalCount: action.pageDetails.total_count,
    };
  },
  [actionTypes.REMOVE_SELECTED]: (state, action) => {
    const index = state.appointments.findIndex((appointment) => parseInt(appointment.id) === parseInt(state.selectedAppointmentId));
    const newappointments = [...state.appointments.slice(0, index), ...state.appointments.slice(index + 1)];
    return { ...state, appointments: newappointments, selectedappointmentId: null };
  },
};

const initialState = {
  loading: false,
  selectedAppointmentId: null,
  appointments: [],
  pageCount: 1,
  currentPage: 1,
  totalCount: 0,
};

export default function reducer(state = initialState, action) {
  const handler = APPOINTMENT_LIST_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
