import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SalesTeamsView from "../components/SalesTeamsView";

import locations from "modules/Stores/Locations";

import invites from "../modules/invite";

import modal from "modules/Stores/Modal";

import { deleteTeamMember } from "../modules/teamMembers/actions";

import teamMembers from "../modules/teamMembers";

import * as actions from "../actions";
import * as selectors from "../selectors";
const { showModal } = modal.actions;
const { resendInvite, revokeInvite } = invites.actions;

function mapStateToProps(state) {
  const selectedLocationId = selectors.selectedLocationId(state);
  const selectedLocation = locations.selectors.locationForIdFactory(
    selectedLocationId
  )(state);
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    user: state.session.user,
    teamMembers: state.salesTeams.teamMembers.team,
    loadingTeam: state.salesTeams.teamMembers.loading,
    pendingInvites: state.salesTeams.invite.pendingInvites,
    loadingInvites: state.salesTeams.invite.pendingInvitesLoading,
    selectedLocation: selectedLocation,
    selectedLocationId: selectedLocationId,
    selectedUser: teamMembers.selectors.selectedUser(state),
    staffIntegration: locations.selectors.integrationWithStaffAccountForLocation(
      selectedLocation
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resendInvite,
      revokeInvite,
      showModal,
      deleteTeamMember,
      selectLocation: actions.selectLocation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTeamsView);
