import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_SMS_ACCOUNTS = NAME + '/SET_SMS_ACCOUNTS'
export const SET_PAYMENT_METHODS = NAME + '/SET_PAYMENT_METHODS'

export const ADD_PAYMENT_METHOD = NAME + '/ADD_PAYMENT_METHOD'
export const UPDATE_PAYMENT_METHOD = NAME + '/UPDATE_PAYMENT_METHOD'
export const REMOVE_PAYMENT_METHOD = NAME + '/REMOVE_PAYMENT_METHOD'
export const SET_DEFAULT_SMS_COST = NAME + '/SET_DEFAULT_SMS_COST'

export const ADD_SMS_ACCOUNT = NAME + '/ADD_SMS_ACCOUNT'
export const REMOVE_SMS_ACCOUNT = NAME + '/REMOVE_SMS_ACCOUNT'
export const UPDATE_SMS_ACCOUNT = NAME + '/UPDATE_SMS_ACCOUNT'
export const UPDATE_SMS_ACCOUNT_BALANCE = NAME + '/UPDATE_SMS_ACCOUNT_BALANCE'
