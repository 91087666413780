import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HeaderImage from 'images/dashboard@2x.jpg';
import moment from 'moment';
import { PageHeader, QuickInfoBlock } from 'modules/components';
import { history } from 'store/store';

import WeeklyLeadSummaryReportContainer from '../containers/ComponentContainers/WeeklyLeadSummaryReportContainer';
import LocationSalesTargetsContainer from '../containers/ComponentContainers/LocationSalesTargetsContainer';
import ManagerSalesTeamContainer from '../containers/ComponentContainers/ManagerSalesTeamContainer';
import UserTargetsViewForManagerContainer from '../containers/ComponentContainers/UserTargetsViewForManagerContainer';
import SetDashboardTypeButtonContainer from '../containers/ComponentContainers/SetDashboardTypeButtonContainer';

import * as manageLeadsConstants from 'containers/ManageLeads/constants';
import QueuedLeads from '../modules/QueuedLeads';

export class ManagerLocationDashboardView extends Component {
  UNSAFE_componentWillMount() {
    const { location, fetchMetricsForLocation } = this.props;

    const startOfDay = moment().startOf('Day').utc().format();
    const endOfDay = moment().endOf('Day').utc().format();

    fetchMetricsForLocation(location, startOfDay, endOfDay);
  }

  backSelected = () => {
    this.props.clearCurrentLocation();
  };

  quickInfoBlockSelectedWithAction(action) {
    const { location } = this.props;
    history.push('/secure/manageLeads?quick_action=' + action + '&locationId=' + location.id);
  }

  render() {
    const { company, location, locationMetrics, isMultiLocationAccount } = this.props;
    const title = location.admin_display_name + ' dashboard';
    const subtitle = 'Here are the latest stats for ' + company.title + ' ' + location.admin_display_name + '!';
    const leadCount = locationMetrics.new_leads_count ? locationMetrics.new_leads_count : 0;
    const unassignedLeads = locationMetrics.unassigned_leads_count ? locationMetrics.unassigned_leads_count : 0;
    const assignedLeads = locationMetrics.active_leads_count ? locationMetrics.active_leads_count : 0;
    const closedLeads = locationMetrics.closed_leads_count ? locationMetrics.closed_leads_count : 0;

    let pageHeaderChildren = <div />;
    if (isMultiLocationAccount) {
      pageHeaderChildren = <i className="si si-arrow-left fa-2x pull-left text-white selectable" onClick={this.backSelected} />;
    }

    let sub = (
      <div className="row">
        <div className="col-sm-3 col-sm-offset-9 push-10">
          <SetDashboardTypeButtonContainer />
        </div>
      </div>
    );

    return (
      <div className="content content-boxed overflow-hidden">
        <PageHeader
          title={title}
          subtitle={subtitle}
          image={HeaderImage}
          headerChildren={pageHeaderChildren}
          headerLowerChildren={sub}
          imageYPositionStyle={'19%'}
        />
        {location && (
          <div>
            <div className="row">
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock
                  title={'NEW LEADS'}
                  number={leadCount}
                  icon={'si si-calendar'}
                  iconTitle={'TODAY'}
                  onClick={() => this.quickInfoBlockSelectedWithAction(manageLeadsConstants.QUICK_ACTION_NEW_LEADS)}
                />
              </div>
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock
                  title={'UNASSIGNED LEADS'}
                  number={unassignedLeads}
                  icon={'si si-calendar'}
                  iconTitle={'TODAY'}
                  onClick={() => this.quickInfoBlockSelectedWithAction(manageLeadsConstants.QUICK_ACTION_UNASSIGNED_LEADS)}
                />
              </div>
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock
                  title={'ACTIVE LEADS'}
                  number={assignedLeads}
                  icon={'si si-calendar'}
                  iconTitle={'TODAY'}
                  onClick={() => this.quickInfoBlockSelectedWithAction(manageLeadsConstants.QUICK_ACTION_ACTIVE_LEADS)}
                />
              </div>
              <div className="col-xs-6 col-sm-3">
                <QuickInfoBlock
                  title={'CLOSED LEADS'}
                  number={closedLeads}
                  icon={'si si-calendar'}
                  iconTitle={'TODAY'}
                  onClick={() => this.quickInfoBlockSelectedWithAction(manageLeadsConstants.QUICK_ACTION_CLOSED_LEADS)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <WeeklyLeadSummaryReportContainer />
              </div>
              <div className="col-md-6">
                <QueuedLeads.Container />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="row">
                  <div className="col-xs-12">
                    <LocationSalesTargetsContainer />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <ManagerSalesTeamContainer />
                  </div>
                </div>
                {this.props.selectedUserId && (
                  <div className="row">
                    <div className="col-md-12">
                      <UserTargetsViewForManagerContainer location={this.props.location} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ManagerLocationDashboardView.propTypes = {
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  locationMetrics: PropTypes.object.isRequired,
  clearCurrentLocation: PropTypes.func.isRequired,
  fetchMetricsForLocation: PropTypes.func.isRequired,
  isMultiLocationAccount: PropTypes.bool.isRequired,
  selectedUserId: PropTypes.number,
};
