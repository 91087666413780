import { connect } from 'react-redux'
import SelectUserTableRow from '../components/TableView/SelectUserTableRow'

import userData from 'modules/Stores/UserData'

function mapStateToProps (state, ownProps) {
  const getSchedule = userData.selectors.scheduleForUserLocationFactory(ownProps.user.id, ownProps.locationId)
  const schedule = getSchedule(state)
  return {
    isAvailable: userData.selectors.isAvailableForScheduleInTimezone(schedule)
  }
}

export default connect(mapStateToProps)(SelectUserTableRow)
