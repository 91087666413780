import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TagRow from './TagRow'
import { ContentBlockView, EmptyView, TextSearchView } from 'modules/components'
import NewTagModalContainer from '../containers/NewTagModalContainer'
import EditTagModalContainer from '../containers/EditTagModalContainer'
import MigrateTagModalContainer from '../containers/MigrateTagModalContainer'
import PagesContainer from '../containers/PagesContainer'

import { NEW_TAG_MODAL, EDIT_TAG_MODAL, MIGRATE_TAG_MODAL } from '../constants'

export class TagsView extends Component {

  state = {
    selectedTag: null
  }

  UNSAFE_componentWillMount () {
    this.getTagsWithPageNumber(1)
  }

  getTagsWithPageNumber = (pageNumber) => {
    const { getTags } = this.props
    getTags(pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getTagsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getTags } = this.props
    getTags(1, searchTerm)
  }

  tagSelected = (tag) => {
    this.setState({ ...this.state, selectedTag: tag })
    this.props.showModal(EDIT_TAG_MODAL)
  }

  tagDeleted = (tag) => {
    this.setState({ ...this.state, selectedTag: tag })
    this.props.showModal(MIGRATE_TAG_MODAL)
  }

  showCreateModal = () => {
    this.setState({ ...this.state, selectedTag: null })
    this.props.showModal(NEW_TAG_MODAL)
  }

  render () {
    const { tags, loading, pageDetails } = this.props
    const { selectedTag } = this.state
    const pageCount = pageDetails.pageCount
    const tagRows = tags.map((tag, index) => {
      return <TagRow
        tag={tag}
        key={index}
        onSelect={this.tagSelected}
        onDelete={this.tagDeleted} />
    })

    const addButton = (
      <button type='button' className='pull-right btn btn-xs btn-success'
        onClick={this.showCreateModal}>
        <i className='fa fa-plus' /> Create New Tag
      </button>
    )

    return (
      <div>
        <ContentBlockView title={'Tags'}
          blockOptions={addButton}
          loading={loading}
          bordered>
          <div className='row' style={{ marginTop: '-20px' }}>
            <TextSearchView
              sectionName={'tagSearch'}
              onUpdate={this.searchUpdated} />
          </div>
          <div className='list-group selectable push'>
            {tagRows}
            {tags.length === 0 &&
              <EmptyView
                colorThemeClass='primary'
                iconClass='fa fa-tags'
                title='No Tags'
                buttonText='Create a new tag'
                buttonSelected={this.showCreateModal}
                subtitle={'We didn\'t find any tags. Narrow your search or create a new one.'} />
            }
          </div>
          {pageCount > 1 &&
            <div className='text-center push'>
              <PagesContainer pageClicked={this.pageClicked} />
            </div>
          }
        </ContentBlockView>
        <NewTagModalContainer modalName={NEW_TAG_MODAL} />
        {selectedTag &&
          <div>
            <EditTagModalContainer modalName={EDIT_TAG_MODAL} tag={selectedTag} />
            <MigrateTagModalContainer modalName={MIGRATE_TAG_MODAL} tag={selectedTag} />
          </div>
        }
      </div>
    )
  }
}

TagsView.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getTags: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired
}

export default TagsView
