import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SelectCallScheduleTableRow extends Component {

  rowSelected = (event) => {
    const { callSchedule, onCallScheduleSelect } = this.props
    onCallScheduleSelect(callSchedule)
  }

  render () {
    const { callSchedule, selected } = this.props
    const style = (selected) ? 'selected' : 'selectable'

    return (
      <tr className={style} onClick={this.rowSelected}>
        <td>{callSchedule.name}</td>
        <td>{callSchedule.number_of_steps}</td>
        <td>{callSchedule.default && 'Default' }
        </td>
      </tr>
    )
  }
}

SelectCallScheduleTableRow.propTypes = {
  callSchedule: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onCallScheduleSelect: PropTypes.func.isRequired
}

export default SelectCallScheduleTableRow
