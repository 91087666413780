import PropTypes from "prop-types";
import React, { Component } from "react";
import { isToday } from "modules/Helpers/DateHelpers";
import moment from "moment";

export class FacebookMessageItem extends Component {
  messageCheck = () => {
    const { facebookMessage } = this.props;
    if (facebookMessage.read) {
      return (
        <i
          className="fa fa-check"
          style={{ fontSize: "10px", color: "#34C84A" }}
        />
      );
    } else if (facebookMessage.delivered) {
      return (
        <i
          className="fa fa-check"
          style={{ fontSize: "10px", color: "#666666" }}
        />
      );
    }
  };

  render() {
    const { facebookMessage } = this.props;
    const fromClassName =
      facebookMessage.message_direction === "from_user"
        ? "from-them"
        : "from-me";
    const metaFromClassName =
      facebookMessage.message_direction === "from_user"
        ? "meta-from-them"
        : "meta-from-me";
    const dayString = isToday(moment(facebookMessage.created_at))
      ? "Today"
      : moment(facebookMessage.created_at).format("ddd");
    const timeStamp = moment(facebookMessage.created_at).format("h:mm A");
    return (
      <div className="facebook-message-container">
        <div className={"facebook-message " + fromClassName}>
          <div className="message-bubble">
            <p>{facebookMessage.text}</p>
          </div>
          <div className={"message-meta " + metaFromClassName}>
            <p className="facebook-message-timestamp">
              {dayString + ", " + timeStamp} {this.messageCheck()}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

FacebookMessageItem.propTypes = {
  facebookMessage: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
};

export default FacebookMessageItem;
