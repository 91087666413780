import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HeaderImage from 'images/dashboard@2x.jpg';
import LocationSelect from 'modules/components/LocationSelect';

import { PageHeader } from 'modules/components';

export class LocationSelectView extends Component {
  componentDidMount() {
    const { locationSelected, isMultiLocationAccount, defaultLocation } = this.props;
    if (isMultiLocationAccount) {
      return;
    }
    locationSelected(defaultLocation, defaultLocation);
  }

  render() {
    const { locationSelected } = this.props;
    return (
      <div className="content content-boxed overflow-hidden">
        <PageHeader title={'Dashboard'} subtitle={'Select a club to view more detail'} image={HeaderImage} imageYPositionStyle={'19%'} />
        <div className="row">
          <div className="col-xs-12">
            <LocationSelect.LocationSelectContainer onLocationSelect={locationSelected} />
          </div>
        </div>
      </div>
    );
  }
}

LocationSelectView.propTypes = {
  user: PropTypes.object.isRequired,
  locationSelected: PropTypes.func.isRequired,
  isMultiLocationAccount: PropTypes.bool.isRequired,
  defaultLocation: PropTypes.object.isRequired,
};

export default LocationSelectView;
