import PropTypes from "prop-types";
import React, { Component } from "react";
import Script from "react-load-script";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 800px;
`;

const Designer = styled.div`
  flex: 1;
  display: flex;
  > iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: "800px" !important;
    display: flex;
    border: 0px;
  }
`;
export class EmailEditor extends Component {
  state = {
    loading: true
  };

  unrollReady = () => {
    const { template, mergeTags } = this.props;
    window.unlayer.init({
      displayMode: "email",
      id: "editor",
      projectId: 256,
      mergeTags: mergeTags
    });
    window.unlayer.loadDesign(template.design_json);
    this.setState({ ...this.state, loading: false });
  };

  render() {
    const { loading } = this.state;
    const { readOnly } = this.props;
    const style = readOnly ? { pointerEvents: "none" } : {};
    return (
      <div style={style}>
        <Script
          id="editor"
          url="https://editor.unlayer.com/embed.js"
          onLoad={this.unrollReady}
        />
        {loading && (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h2 className="push">Loading editor</h2>
              <i className="fa fa-4x fa-cog fa-spin text-warning" />
            </div>
          </div>
        )}
        <Wrapper>
          <Designer id="editor" minHeight={800} />
        </Wrapper>
      </div>
    );
  }
}

EmailEditor.propTypes = {
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired,
  template: PropTypes.object.isRequired
};

export default EmailEditor;
