import { request, POST, GET, DELETE, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const fetchCurrentTrial = (lead) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setCurrentTrial(payload.leads_trial))
      dispatch(setLoading(false))
    }
    const url = `leads/${lead.id}/leads_trials/leads_current_trial`
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const addLeadToTrial = (lead, trial, trialStartDate, trialEndDate) => {
  const body = {
    trial_id: trial.id,
    start_date: trialStartDate,
    end_date: trialEndDate,
  }
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setCurrentTrial(payload.leads_trial))
    }
    const url = `leads/${lead.id}/leads_trials`
    return request(url, POST, body, onSuccess, onFailure)
  }
}

export const removeLeadFromTrial = (lead, leadsTrial) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setCurrentTrial(null))
      dispatch(setLoading(false))
    }
    const url = `leads/${lead.id}/leads_trials/${leadsTrial.id}`
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}

export const updateLeadsTrial = (lead, leadsTrial, startDate, endDate) => {
  const body = {
    start_date: startDate,
    end_date: endDate
  }
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setCurrentTrial(payload.leads_trial))
      dispatch(setLoading(false))
    }
    const url = `leads/${lead.id}/leads_trials/${leadsTrial.id}`
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

export function setCurrentTrial (trial) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CURRENT_TRIAL,
      trial
    })
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}