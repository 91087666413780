import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getResultsForEmailMap = (emailMap) => {
  return dispatch => {
    dispatch(startLoading())

    function onFailure (errors) {
      dispatch(loadFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(loadSuccessful())
      dispatch(setResults.payload)
    }

    return request('email_map_results/' + emailMap.id, GET, null, onSuccess, onFailure)
  }
}

function startLoading () {
  return {
    type: actionTypes.LOADING
  }
}

function loadSuccessful () {
  return {
    type: actionTypes.LOAD_SUCCESS
  }
}

function loadFailed (errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors
  }
}

export function setResults (results) {
  return {
    type: actionTypes.SET_RESULTS,
    results
  }
}
