import React, { Component } from 'react';
import ReportSummaryPanel from './ReportSummaryPanel';

import { PageHeader } from 'modules/components';

import * as reports from '../../Reports';
import HeaderImage from 'images/reports@2x.jpg';

export class ReportsView extends Component {
  state = {
    reportOptions: [
      {
        title: 'Leads Report',
        linkTo: `/secure/reports/${reports.LeadsList.constants.NAME}`,
        icon: 'fa fa-users',
        body: 'Get a list of your leads and numbers across your categories.',
        bgColor: 'bg-amethyst',
      },
      {
        title: 'Sales Performance',
        linkTo: `/secure/reports/${reports.SalesPerformance.constants.NAME}`,
        icon: 'fa fa-line-chart',
        body: "See how well your leads are being closed and if you're on track to meet your goals.",
        bgColor: 'bg-flat',
      },
      {
        title: 'Lead Allocation',
        linkTo: `/secure/reports/${reports.LeadAllocation.constants.NAME}`,
        icon: 'fa fa-inbox',
        body: "Learn how leads are allocated to your team, and how they're actioned.",
        bgColor: 'bg-modern',
      },
      {
        title: 'Time Taken',
        linkTo: `/secure/reports/${reports.TimeTaken.constants.NAME}`,
        icon: 'si si-clock',
        body: 'Learn how long critical tasks are taking, and compare how they match up to your goals.',
        bgColor: 'bg-city',
      },
      {
        title: 'Activity Report',
        linkTo: `/secure/reports/${reports.Activity.constants.NAME}`,
        icon: 'fa fa-pie-chart',
        body: 'See how your sales team are completing appointments, calls and tasks.',
        bgColor: 'bg-modern',
      },
      {
        title: 'Lost Leads',
        linkTo: `/secure/reports/${reports.LostLeads.constants.NAME}`,
        icon: 'fa fa-user-times',
        body: "Learn what's stopping your leads from signing up.",
        bgColor: 'bg-danger',
      },
      {
        title: 'Deals Report',
        linkTo: `/secure/reports/${reports.Deals.constants.NAME}`,
        icon: 'fa fa-dollar',
        body: 'Learn which deals are most popular.',
        bgColor: 'bg-success',
      },
      {
        title: 'Trials Report',
        linkTo: `/secure/reports/${reports.Trials.constants.NAME}`,
        icon: 'fa fa-clock-o',
        body: 'Learn what trials are popular and whose in them.',
        bgColor: 'bg-smooth',
      },
    ],
  };

  render() {
    const reportOptionPanels = this.state.reportOptions.map((reportOption, index) => {
      return (
        <div key={index} className="cold-xs-6 col-md-4">
          <ReportSummaryPanel
            title={reportOption.title}
            body={reportOption.body}
            linkTo={reportOption.linkTo}
            bgColor={reportOption.bgColor}
            icon={reportOption.icon}
          />
        </div>
      );
    });

    return (
      <div className="content content-boxed">
        <PageHeader title={'Reports'} subtitle={'Discover insights about your team'} image={HeaderImage} />
        <div className="row">{reportOptionPanels}</div>
      </div>
    );
  }
}

ReportsView.propTypes = {};
