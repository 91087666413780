import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ListRegionsContainer from '../containers/ListRegionsContainer'
import NewRegionModalContainer from '../containers/NewRegionModalContainer'
import EditRegionContainer from '../containers/EditRegionContainer'
import { InfoView } from 'modules/components'

export class RegionsView extends Component {

  render () {
    const infoString = 'Set up regions based on postcodes for easier reporting.'
    return (
      <div>
        <InfoView colorThemeClass={'info'} title={'Regions'} detail={infoString} />
        <ListRegionsContainer />
        <NewRegionModalContainer />
        {this.props.selectedRegionId &&
          <EditRegionContainer />
        }
      </div>
    )
  }
}

RegionsView.propTypes = {
  selectedRegionId: PropTypes.number
}

export default RegionsView
