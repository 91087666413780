import React from "react";

export function generateNewFormState(oldState, name, value, isValid) {
  const fields = oldState ? oldState.fields : { fields: {} };
  fields[name] = { ...fields[name], value: value, isValid: isValid };
  const newIsValidValue = fieldsAreValid(fields);
  return { ...oldState, fields: fields, isValid: newIsValidValue };
}

export function fieldsAreValid(fields) {
  let newIsValidValue = true;
  Object.keys(fields).forEach(function (key) {
    const fieldIsValid = fields[key].isValid;
    if (fieldIsValid === false) {
      newIsValidValue = false;
    }
  });
  return newIsValidValue;
}

export function safePropertyForObject(
  object,
  propertyName,
  defaultValue = null
) {
  return object && object[propertyName]
    ? object[propertyName].toString()
    : defaultValue;
}

export function safeRawPropertyForObject(
  object,
  propertyName,
  transformPropertyFunc,
  defaultValue = null
) {
  let value = defaultValue;
  if (
    object &&
    object[propertyName] !== null &&
    object[propertyName] !== undefined
  ) {
    value = object[propertyName];
  }

  if (value && transformPropertyFunc) {
    return transformPropertyFunc(value);
  }

  return value;
}

export function safeBooleanPropertyForObject(
  object,
  propertyName,
  defaultValue = false
) {
  const rawObject = safeRawPropertyForObject(object, propertyName);
  if (rawObject === undefined || rawObject === null) {
    return defaultValue;
  }
  return rawObject;
}

export function newPayloadForFormValue(payload, formField, key) {
  if (formField && formField.value) {
    return { ...payload, [key]: formField.value };
  } else if (formField && formField.value === false) {
    return { ...payload, [key]: formField.value };
  } else if (formField && formField.value === "") {
    return { ...payload, [key]: null };
  } else {
    return payload;
  }
}

export function newPayloadForValue(payload, value, key) {
  if (value !== null && value !== undefined && value !== "") {
    return { ...payload, [key]: value };
  } else {
    return payload;
  }
}

export const errorPanelForErrors = (errors, showKey) => {
  if (!errors) {
    return null;
  }
  const errorType = typeof errors;
  let errorRows = <div />;
  switch (errorType) {
    case "string":
      errorRows = errorsForString(errors);
      break;
    case "object":
      if (Array.isArray(errors)) {
        errorRows = errorsForArray(errors, showKey);
      } else {
        errorRows = errorsForObject(errors, showKey);
      }
      break;
    default:
      break;
  }
  return (
    <div className="alert alert-danger" role="alert" hidden={!errors}>
      <ul>{errorRows}</ul>
    </div>
  );
};

function errorsForObject(errorObject, showKey) {
  return Object.keys(errorObject).map(function (key) {
    if (key === "param") {
      return undefined;
    }
    const stringValue = Array.isArray(errorObject[key])
      ? errorObject[key][0]
      : errorObject[key];
    const errorString = showKey ? key + " " + stringValue : stringValue;
    return (
      <li key={key}>
        {errorString.charAt(0).toUpperCase() + errorString.slice(1)}
      </li>
    );
  });
}

function errorsForArray(errorArray, showKey = false) {
  return errorArray.map(function (errorRow, index) {
    const rowType = typeof errorRow;
    switch (rowType) {
      case "string":
        return (
          <li key={index}>
            {errorRow.charAt(0).toUpperCase() + errorRow.slice(1)}
          </li>
        );
      case "object":
        return errorsForObject(errorRow, showKey);
      default:
        break;
    }
    return undefined;
  });
}

function errorsForString(errorString) {
  return <li>{errorString.charAt(0).toUpperCase() + errorString.slice(1)}</li>;
}

export function generateFieldsForKeys(object, keys, payloadKeys, validator) {
  let fields = {};
  keys.forEach((key) => {
    const propertyName = payloadKeys[key];
    const objectType = object ? typeof object[propertyName] : null;
    let value;
    switch (objectType) {
      case "boolean":
        value = safeBooleanPropertyForObject(object, payloadKeys[key]);
        break;
      default:
        value = safePropertyForObject(object, payloadKeys[key]);
        break;
    }
    let isValid = true;
    if (validator && validator[key]) {
      const validations = validator[key];
      validations.forEach(function (validation) {
        isValid = validation.rule(value);
      });
    }
    const field = {
      value: value,
      isValid: isValid,
      key: payloadKeys[key],
    };
    fields = { ...fields, [key]: field };
  });

  return fields;
}

export function formDataFromData(data, fieldNames, defaultValue = null) {
  const fields = data.fields;

  let formData = {};
  fieldNames.forEach((fieldName) => {
    const field = fields[fieldName];
    const key = field.key;
    const value = field.value || defaultValue;
    formData = { ...formData, [key]: value };
  });

  return formData;
}
