import { NAME } from "./constants";

export const UPDATE_FILTER_DATA = NAME + "/UPDATE_FILTER_DATA";
export const CLEAR_ALL_FILTERS = NAME + "/CLEAR_ALL_FILTERS";

export const HIDE_FILTERS = NAME + "/HIDE_FILTERS";
export const SHOW_FILTERS = NAME + "/SHOW_FILTERS";

export const SET_SELECTED_LOCATION_IDS = NAME + "/SET_SELECTED_LOCATION_IDS";

export const LOCATION_SEARCH_TERM_UPDATED =
  NAME + "/LOCATION_SEARCH_TERM_UPDATED";
export const LOCATION_CURRENT_PAGE_UPDATED =
  NAME + "/LOCATION_CURRENT_PAGE_UPDATED";

export const SET_USE_STATUS_DATE = NAME + "/SET_USE_STATUS_DATE";
export const SET_COMPARE_VALUE = NAME + "/SET_COMPARE_VALUE";
