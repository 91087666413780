import { request, buildIndexUrl, GET } from "utils/apiUtils";

import locationStore from "./index";

export const getHeardAboutMethods = (pageNumber, searchTerm = null) => {
  return dispatch => {
    dispatch(locationStore.actions.setLoading(true));
    function onFailure(payload) {
      dispatch(locationStore.actions.setLoading(false));
      dispatch(locationStore.actions.setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(locationStore.actions.setLoading(false));
      dispatch(
        locationStore.actions.setHeardAboutMethods(payload.heard_about_methods)
      );
      dispatch(locationStore.actions.setPageDetails(payload.meta));
    }

    const url = buildIndexUrl(
      "heard_about_methods",
      pageNumber,
      null,
      searchTerm
    );
    return request(url, GET, null, onSuccess, onFailure);
  };
};
