export const NAME = 'securityPolicySettings'
export const fieldNames = [
  'passwordComplexityEnabledAt',
  'minPasswordLength',
  'minPasswordLetters',
  'minPasswordNumbers',
  'minPasswordSpecial',
  'passwordCanContainUsername',
  'disableUserAccountAfterDays',
  'lockScreenAfter',
  'maximumNumberOfAttempts',
  'previousPasswordsToPrevent',
  'allowRememberMe',
  'rememberMeLength',
  'passwordExpiresAfterMonths'
]
