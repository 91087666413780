import PropTypes from "prop-types";
import React, { Component } from "react";
import StepNumberView from "modules/components/StepNumberView/StepNumberView";
import styled from "styled-components";
import { toolTipSelector } from "../tooltips";

export class PresentationsView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, calculatingOption } = this.props;
    fetchWithQueryString(queryString, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.calculatingOption !== this.props.calculatingOption
    ) {
      const {
        fetchWithQueryString,
        queryString,
        calculatingOption,
      } = nextProps;
      fetchWithQueryString(queryString, calculatingOption);
    }
  }

  render() {
    const { data, loading, calculatingOption } = this.props;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";
    const tooltips = toolTipSelector(calculatingOption);

    return (
      <Container className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Presentations made</h3>
        </div>
        <BlockContent className="block-content">
          <ContentRow className="row">
            <Grouped className="col-sm-6">
              <div className="col-sm-4">
                <StepNumberView
                  title="Appointments due"
                  number={data.presentations_due_count.current}
                  percentage={data.presentations_due_count.change}
                  tooltipId={"presentationsDueDescription"}
                  tooltipText={tooltips.presentationsDueDescription}
                />
              </div>
              <div className="col-sm-4">
                <StepNumberView
                  title="Presentations occured"
                  number={data.presentations_count.current}
                  percentage={data.presentations_count.change}
                  tooltipId={"presentationsOccuredDescription"}
                  tooltipText={tooltips.presentationsOccuredDescription}
                />
              </div>
              <div className="col-sm-4">
                <StepNumberView
                  title="Appointment to presentation ratio"
                  number={data.appointment_to_presentation_ratio.current * 100}
                  suffix={"%"}
                  percentage={data.appointment_to_presentation_ratio.change}
                  tooltipId={"appointmentToPresentationRatioDescription"}
                  tooltipText={
                    tooltips.appointmentToPresentationRatioDescription
                  }
                />
              </div>
            </Grouped>
            <div className="col-sm-2">
              <StepNumberView
                title="Presentation sales"
                number={data.presentation_sales_count.current}
                percentage={data.presentation_sales_count.change}
                tooltipId={"presentationSalesDescription"}
                tooltipText={tooltips.presentationSalesDescription}
              />
            </div>
            <div className="col-sm-2">
              <StepNumberView
                title="Presentation close ratio"
                number={data.presentation_close_ratio.current * 100}
                suffix={"%"}
                percentage={data.presentation_close_ratio.change}
                tooltipId={"presentationCloseRatioDescription"}
                tooltipText={tooltips.presentationCloseRatioDescription}
              />
            </div>
          </ContentRow>
          <ContentRow className="row">
            <Grouped className="col-sm-6">
              <div className="col-sm-4">
                <StepNumberView
                  title="A1 close rate"
                  number={data.a1_close_rate.current * 100}
                  percentage={data.a1_close_rate.change}
                  suffix={"%"}
                />
              </div>
              <div className="col-sm-4">
                <StepNumberView
                  title="A2 close rate"
                  number={data.a2_close_rate.current * 100}
                  percentage={data.a2_close_rate.change}
                  suffix={"%"}
                />
              </div>
              <div className="col-sm-4">
                <StepNumberView
                  title="A3 close rate"
                  number={data.a3_close_rate.current * 100}
                  percentage={data.a3_close_rate.change}
                  suffix={"%"}
                />
              </div>
            </Grouped>
            <div className="col-sm-2" />
            <div className="col-sm-2" />
          </ContentRow>
        </BlockContent>
      </Container>
    );
  }
}

PresentationsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string,
};

export default PresentationsView;

const Container = styled.div``;

const Grouped = styled.div`
  border-right: 1px solid #e9e9e9;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const BlockContent = styled.div`
  padding-bottom: 40px;
  min-height: 140px;
`;
