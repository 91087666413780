import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ConfirmView from '../components/ConfirmView'

import { getTabIndex } from '../../../selectors'

import selectLocation from '../../selectLocation'
import selectUsers from '../../selectUsers'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const activeTab = getTabIndex(state)
  const locationId = selectLocation.selectors.getLocationId(state)
  const locationName = selectLocation.selectors.getLocationName(state)
  return {
    isActive: (activeTab === 2),
    currentLocationId: locationId,
    currentLocationName: locationName,
    loading: selectors.getLoading(state),
    success: selectors.getSuccess(state),
    fromUser: selectUsers.selectors.getFromUser(state),
    touserIds: selectUsers.selectors.getToUserIds(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createLeadReallocation: actions.createLeadReallocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmView)
