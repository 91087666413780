import * as actionTypes from './actionTypes'

const CALL_SCHEDULE_SELECT_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_CALL_SCHEDULES]: (state, action) => {
    return { ...state, callSchedules: action.callSchedules }
  },
  [actionTypes.SET_SELECTED_CALL_SCHEDULE_ID]: (state, action) => {
    return { ...state, selectedCallScheduleId: action.selectedCallScheduleId }
  },
  [actionTypes.ADD_CALL_SCHEDULE]: (state, action) => {
    const newCallSchedule = action.callSchedule
    const oldCallSchedules = state.callSchedules

    const newCallSchedules = [
      ...oldCallSchedules,
      newCallSchedule
    ]
    return { ...state, callSchedules: newCallSchedules }
  },
  [actionTypes.UPDATE_CALL_SCHEDULE]: (state, action) => {
    const newCallSchedule = action.callSchedule
    const oldCallSchedules = state.callSchedules
    const index = indexForCallSchedule(oldCallSchedules, newCallSchedule.id)
    const newCallSchedules = [
      ...oldCallSchedules.slice(0, index),
      newCallSchedule,
      ...oldCallSchedules.slice(index + 1)
    ]
    return { ...state, callSchedules: newCallSchedules }
  },
  [actionTypes.DELETE_CALL_SCHEDULE]: (state, action) => {
    const deletedCallScheduleId = action.callScheduleId
    const oldCallSchedules = state.callSchedules
    const index = indexForCallSchedule(oldCallSchedules, deletedCallScheduleId)
    const newCallSchedules = [
      ...oldCallSchedules.slice(0, index),
      ...oldCallSchedules.slice(index + 1)
    ]
    return { ...state, callSchedules: newCallSchedules }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      total: action.pageDetails.total_count
    }
  }
}

const initialState = {
  loading: false,
  errors: null,
  selectedCallScheduleId: null,
  pageCount: 1,
  currentPage: 1,
  total: 0,
  callSchedules: []
}

function indexForCallSchedule (callSchedules, callScheduleId) {
  return callSchedules.findIndex(callSchedule => parseInt(callSchedule.id) === parseInt(callScheduleId))
}

export default function reducer (state = initialState, action) {
  const handler = CALL_SCHEDULE_SELECT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
