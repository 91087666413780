import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FilterCheckboxSection from './FilterCheckboxSection'

export default class GenderSection extends Component {

  state = {
    genders: [{
      value: 'Male',
      display: 'Male'
    }, {
      value: 'Female',
      display: 'Female'
    }, {
      value: 'Other',
      display: 'Other'
    }]
  }

  render () {
    return (
      <FilterCheckboxSection title={'Gender'} types={this.state.genders} {...this.props} />
    )
  }
}

GenderSection.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool
}
