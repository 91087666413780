import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MultiUserSelectView from '../components/MultiUserSelectView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    fetchOnLoad: selectors.fetchOnLoadSelector(props.locationIds)(state)
  }
}

function mapDispatchToProps (dispatch, props) {
  return bindActionCreators({
    setLastUsedLocationIds: actions.setLastUsedLocationIds
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiUserSelectView)
