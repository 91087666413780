import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'

import { FormFields } from 'modules/components'

import FilterSection from './FilterSection'

export default class FilterCheckboxSection extends Component {

  fieldPrefix () {
    return this.props.sectionName + '/'
  }

  handleToggle = (fieldName, value) => {
    const truncatedFieldName = fieldName.replace(this.fieldPrefix(), '')
    const newSectionData = generateNewFormState(this.props.data, truncatedFieldName, value, true)
    this.props.onUpdate(newSectionData, this.props.sectionName)
  }

  render () {
    const { types, title, showBottomBorder, blockStyle } = this.props
    const checkboxes = types.map((type, index) => {
      const field = this.props.data.fields[type.value]
      const checked = (field && field.value === true) || false
      return (
        <div key={index} className={columnWidthClass}>
          <FormFields.Checkbox
            label={type.display}
            name={this.fieldPrefix() + type.value.toString()}
            onChange={this.handleToggle}
            switchStyle={'primary'}
            disabled={false}
            checked={checked}
            />
        </div>
      )
    })

    return (
      <FilterSection title={title} showBottomBorder={showBottomBorder} blockStyle={blockStyle}>
        <div> {checkboxes} </div>
      </FilterSection>
    )
  }
}

FilterCheckboxSection.propTypes = {
  title: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool,
  blockStyle: PropTypes.string
}

export const columnWidthClass = 'col-sm-6'
