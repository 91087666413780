import PropTypes from "prop-types";
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import styled from "styled-components";

export class CompareSelectSection extends Component {
  state = {
    showingOptions: false,
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  indexClicked = (index) => {
    const { setCompareOption, compareOptions } = this.props;
    const newCompareOption = compareOptions[index];
    this.setState({ ...this.state, showingOptions: false });
    setCompareOption(newCompareOption);
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  createOptions = () => {
    const { selectedIndex } = this.state;
    const { stringForCompareOption, compareOptions } = this.props;
    return compareOptions.map((option, index) => (
      <li
        key={index}
        className={index === selectedIndex ? "selected" : "selectable"}
      >
        <div className="DropDown" onClick={() => this.indexClicked(index)}>
          {stringForCompareOption(option)}
        </div>
      </li>
    ));
  };

  render() {
    const { compareOption, stringForCompareOption } = this.props;
    const { showingOptions } = this.state;
    const optionsList = this.createOptions();

    return (
      <Container>
        <OptionsButton
          className="btn btn-default"
          type="button"
          onClick={this.toggleDropdown}
        >
          {stringForCompareOption(compareOption)}{" "}
          <DownIcon className="fa fa-caret-down" />
        </OptionsButton>
        {showingOptions && (
          <DropdownList className="dropdown-menu">{optionsList}</DropdownList>
        )}
      </Container>
    );
  }
}

CompareSelectSection.propTypes = {
  stringForCompareOption: PropTypes.func.isRequired,
  compareOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  compareOption: PropTypes.string,
  setCompareOption: PropTypes.func.isRequired,
};

export default onClickOutside(CompareSelectSection);

const DropdownList = styled.ul`
  display: block;
  top: unset !important;
  left: unset !important;
`;

const OptionsButton = styled.button``;
const DownIcon = styled.i``;
const Container = styled.div``;
