import { request, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';
import { newPayloadForValue } from 'utils/formHelpers';

export const saveEmailMap = (formData, tags, listUpdateProtocol) => {
  return (dispatch) => {
    const body = {
      email_map: generateEmailMapWithData(formData, tags),
    };

    const emailMapId = formData.fields.id.value;

    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      notify.success('Email Map Saved');
      if (emailMapId) {
        dispatch(listUpdateProtocol.update(payload.email_map));
      } else {
        dispatch(listUpdateProtocol.add(payload.email_map));
      }
      dispatch(listUpdateProtocol.select(payload.email_map.id));
    }

    const url = 'email_maps';

    if (emailMapId) {
      return request(url + '/' + emailMapId, PUT, body, onSuccess, onFailure);
    } else {
      return request(url, POST, body, onSuccess, onFailure);
    }
  };
};

export const deleteEmailMap = (emailMapId, listUpdateProtocol) => {
  return (dispatch) => {
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      notify.success('Email Map Deleted');
      dispatch(listUpdateProtocol.select(null));
      dispatch(listUpdateProtocol.remove(emailMapId));
    }

    return request('email_maps/' + emailMapId, DELETE, null, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccessful() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}

export function changeShowing(showing) {
  return {
    type: actionTypes.SET_SHOWING,
    showing,
  };
}

export function prefilFormForEmailMap(emailMap) {
  return {
    type: actionTypes.RELOAD_EMAIL_MAP,
    emailMap,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

function generateEmailMapWithData(formData, tags) {
  let payload = {};
  const fields = formData.fields;
  const fieldKeys = Object.keys(fields);

  fieldKeys.forEach(function (element) {
    const field = fields[element];
    payload = newPayloadForValue(payload, field.value, field.key);
  });

  payload['lead_tags'] = tags;

  return payload;
}

export function setTags(tags) {
  return {
    type: actionTypes.SET_TAGS,
    tags,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
