export function iconStyle (recordType) {
  switch (recordType) {
    case 'inquired':
      return 'si si-cloud-download'
    case 'timed_out':
      return 'si si-clock'
    case 'active':
      return 'si si-user-follow'
    case 'closed':
      return 'si si-like'
    case 'lost':
      return 'fa fa-frown-o'
    case 'edited':
      return 'si si-pencil'
    case 'moved':
      return 'si si-arrow-right'
    case 'contact call':
    case 'follow_up_call':
    case 'called':
      return 'si si-call-out'
    case 'messaged':
      return 'fa fa-comments-o'
    case 'emailed':
      return 'si si-envelope'
    case 'action_completed':
    case 'presentation':
      return 'fa fa-check'
    case 'appointment':
      return 'si si-calendar'
    case 'opted_out':
      return 'fa fa-ban'
    case 'exported':
      return 'glyphicon glyphicon-export'
    case 'note':
      return 'si si-note'
    default:
      return ''
  }
}

export function bgStyle (recordType) {
  switch (recordType) {
    case 'inquired':
      return 'bg-default-light'
    case 'moved':
      return 'bg-default-dark'
    case 'closed':
    case 'follow_up_call':
      return 'bg-success'
    case 'appointment':
    case 'exported':
      return 'bg-amethyst'
    case 'action_completed':
    case 'presentation':
      return 'bg-flat'
    case 'active':
      return 'bg-modern'
    case 'edited':
    case 'note':
      return 'bg-primary-dark'
    case 'contact call':
    case 'emailed':
    case 'messaged':
    case 'called':
      return 'bg-default'
    case 'opted_out':
    case 'lost':
    case 'timed_out':
      return 'bg-danger'
    default:
      return 'bg-default'
  }
}

export function bgStyleColor (recordType) {
  switch (recordType) {
    case 'inquired':
    case 'contact call':
      return '#98b9e3'
    case 'moved':
      return '#70b9eb'
    case 'closed':
    case 'follow_up_call':
    case 'called':
      return '#46c37b'
    case 'appointment':
    case 'exported':
      return '#a48ad4'
    case 'action_completed':
    case 'presentation':
      return '#44b4a6'
    case 'active':
      return 'bg-modern'
    case 'edited':
    case 'note':
      return '#14adc4'
    case 'emailed':
    case 'messaged':
      return '#5c90d2'
    case 'opted_out':
    case 'lost':
    case 'timed_out':
      return '#d26a5c'
    default:
      return '#5c90d2'
  }
}

export function calendarEventColor (record) {
  switch (record.type) {
    case 'contact call':
      return (record.completed) ? '#93B3DC' : '#98b9e3'
    case 'appointment':
      return (record.completed) ? '#9C83CA' : '#a48ad4'
    case 'personal task':
      return '#2C343F'
    default:
      return (record.completed) ? '#3E618E' : '#5c90d2'
  }
}

export const allTypes = ['inquired', 'active',
  'closed', 'lost', 'edited', 'moved', 'contact call', 'follow_up_call',
  'messaged', 'emailed', 'action_completed',
  'appointment', 'presentation', 'opted_out']
