/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './LeadDetail.css';
import LeadToDosContainer from '../containers/LeadToDosContainer';
import LeadCallsContainer from '../containers/LeadCallsContainer';
import LeadAppointmentsContainer from '../containers/LeadAppointmentsContainer';
import LeadHistoryContainer from '../containers/LeadHistoryContainer';
import LeadNotesContainer from '../containers/LeadNotesContainer';
import LeadMessagesContainer from '../containers/LeadMessagesContainer';
import LeadEmailsContainer from '../containers/LeadEmailsContainer';
import LeadMoreDetailsContainer from '../containers/LeadMoreDetailsContainer';
import LeadWaiversContainer from '../containers/LeadWaiversContainer';

import STRINGS from 'strings';

export default class LeadOptionToggleView extends Component {
  state = {
    options: [
      STRINGS.LeadDetail.details,
      STRINGS.LeadDetail.calls,
      STRINGS.LeadDetail.appointments,
      STRINGS.LeadDetail.notes,
      STRINGS.LeadDetail.messages,
      STRINGS.LeadDetail.emails,
      STRINGS.LeadDetail.tasks,
      STRINGS.LeadDetail.checkIns,
      STRINGS.LeadDetail.history,
    ],
    selectedIndex: 0,
  };

  indexClicked = (selectedIndex) => {
    this.setState({ ...this.state, selectedIndex: selectedIndex });
  };

  selectedComponent = () => {
    const { lead, listUpdateProtocol } = this.props;
    switch (this.state.selectedIndex) {
      case 0:
        return <LeadMoreDetailsContainer listUpdateProtocol={listUpdateProtocol} />;
      case 1:
        return <LeadCallsContainer lead={lead} pathToLeadDetail={this.props.pathToLeadDetail} listUpdateProtocol={listUpdateProtocol} />;
      case 2:
        return (
          <LeadAppointmentsContainer lead={lead} pathToLeadDetail={this.props.pathToLeadDetail} listUpdateProtocol={listUpdateProtocol} />
        );
      case 3:
        return <LeadNotesContainer pathToLeadDetail={this.props.pathToLeadDetail} lead={lead} />;
      case 4:
        return <LeadMessagesContainer pathToLeadDetail={this.props.pathToLeadDetail} lead={lead} />;
      case 5:
        return <LeadEmailsContainer pathToLeadDetail={this.props.pathToLeadDetail} lead={lead} />;
      case 6:
        return <LeadToDosContainer pathToLeadDetail={this.props.pathToLeadDetail} lead={lead} listUpdateProtocol={listUpdateProtocol} />;
      case 7:
        return <LeadWaiversContainer lead={lead} />;
      case 8:
        return <LeadHistoryContainer pathToLeadDetail={this.props.pathToLeadDetail} lead={lead} />;
      default:
        return <div />;
    }
  };
  render() {
    const { options, selectedIndex } = this.state;
    const lead = this.props.lead;

    const tabs = options.map((option, index) => {
      const activeClassName = index === selectedIndex ? 'selectable active' : 'selectable';
      return (
        <li key={index} className={activeClassName} onClick={() => this.indexClicked(index)}>
          {index === 1 && lead && lead.pending_contact_calls_count > 0 && (
            <i className="fa fa-circle text-danger" style={{ position: 'absolute', top: 5, right: 5 }} />
          )}
          {index === 2 && lead && lead.pending_appointments_count > 0 && (
            <i className="fa fa-circle text-danger" style={{ position: 'absolute', top: 5, right: 5 }} />
          )}
          {index === 6 && lead && lead.pending_lead_actions_count > 0 && (
            <i className="fa fa-circle text-danger" style={{ position: 'absolute', top: 5, right: 5 }} />
          )}
          <a id={index}>{option}</a>
        </li>
      );
    });

    return (
      <div className="block" style={{ marginTop: '6px', marginLeft: '-5px', marginRight: '-5px' }}>
        <ul className="nav nav-tabs nav-tabs-alt">{tabs}</ul>
        <div className="block-content tab-content" style={{ paddingBottom: '0px', marginBottom: '-20px' }}>
          {this.selectedComponent()}
        </div>
      </div>
    );
  }
}

LeadOptionToggleView.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  pathToLeadDetail: PropTypes.string.isRequired,
  lead: PropTypes.object,
};
