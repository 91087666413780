import React from "react";
import styled from "styled-components";

export const toolTipSelector = calculating => {
  switch (calculating) {
    case "lead_created":
      return leadCreatedTooltips();
    case "event_occured":
      return eventOccuredTooltips();
    default:
      return eventOccuredTooltips();
  }
};

const leadCreatedTooltips = () => {
  const presentationsDueDescription = (
    <Container>
      <p>
        For leads created during the date range, the{" "}
        <strong>total number</strong> of appointments scheduled to occur
        (including outside the selected date range).
      </p>
      <p>Eg: John has booked an appointment for a lead.</p>
    </Container>
  );

  const presentationsOccuredDescription = (
    <Container>
      <p>
        For leads created during the time period, the number of leads that
        showed to their appointment.
      </p>

      <p>
        No shows and rescheduled appointments are not included in these figures.
      </p>

      <p>
        Eg: Sally had an appointment with a lead and that appointment showed and
        was marked as successful straight away.
      </p>
    </Container>
  );

  const appointmentToPresentationRatioDescription = (
    <Container>
      <p>
        This ratio is calculated from all{" "}
        <strong>
          appointments that were due and marked as a successful presentation
        </strong>
        .
      </p>

      <p>No shows and rescheduled appointments will not be included.</p>

      <p>*Presentations occurred/presentations due x100 = %</p>
    </Container>
  );

  const presentationSalesDescription = (
    <Container>
      <p>
        For leads created in the time period selected, any lead that has an{" "}
        <strong>appointment occur</strong> where the{" "}
        <strong>
          outcome is they signed up and lead's status changed to closed
        </strong>
        .
      </p>
      <p>
        Eg: John has an appointment due, they showed and signed up and were
        marked as 'Closed' on the day.
      </p>
    </Container>
  );

  const presentationCloseRatioDescription = (
    <Container>
      <p>
        This ratio is calculated for leads created in the time period; from any
        lead that has had a{" "}
        <strong>
          successful presentation and moved to the status of 'Closed'
        </strong>
        .
      </p>

      <p>
        A presentation is considered successful if they have the following
        outcome:
      </p>

      <p>
        • They signed up <br />
        • They want to think about it <br />
        • Something else
        <br />
        • Started a trial <br />
      </p>
      <p>
        *Presentation sales/presentations occured x 100 = %<br />
      </p>
    </Container>
  );

  const tooltips = {
    presentationsDueDescription,
    presentationsOccuredDescription,
    appointmentToPresentationRatioDescription,
    presentationSalesDescription,
    presentationCloseRatioDescription
  };
  return tooltips;
};

const eventOccuredTooltips = () => {
  const presentationsDueDescription = (
    <Container>
      <p>
        These are all <strong>appointments that are due to occur</strong> within
        your select time period.
      </p>

      <p>
        If you have booked an appointment but it is not due to occur within your
        selected time period, it will not be included in these numbers.
      </p>

      <p>
        Eg: John has booked an appointment for a lead that is due within 7 days
      </p>
    </Container>
  );

  const presentationsOccuredDescription = (
    <Container>
      <p>
        These are all appointments that were{" "}
        <strong>due to occur and marked as completed</strong> within your
        selected time period.
      </p>

      <p>
        No shows and rescheduled appointments are not included in these figures.
      </p>

      <p>
        Eg: Sally had an appointment with a lead that was due within 7 days and
        that appointment showed and was marked as successful straight away
      </p>
    </Container>
  );

  const appointmentToPresentationRatioDescription = (
    <Container>
      <p>
        This ratio is calculated from all{" "}
        <strong>
          appointments that were due and marked as a successful presentation
        </strong>{" "}
        within your selected time period.
      </p>

      <p>No shows and rescheduled appointments will not be included. </p>

      <p>*Presentations occurred/presentations due x100 = %</p>
    </Container>
  );

  const presentationSalesDescription = (
    <Container>
      <p>
        Presentation sales are calculated from any lead that has had a{" "}
        <strong>successful appointment due and completed</strong> within your
        selected time period that has also had their
        <strong>status moved to 'Closed'.</strong>{" "}
      </p>
      <p>
        Eg: John has an appointment due within the next 7 days, they showed and
        signed up and were marked as 'Closed' on the day
      </p>
    </Container>
  );

  const presentationCloseRatioDescription = (
    <Container>
      <p>
        This ratio is calculated from any lead that has had a{" "}
        <strong>
          successful presentation and moved to the status of 'Closed'
        </strong>{" "}
        within your select time period.
      </p>

      <p>
        A presentation is considered successful if they have the following
        outcome:
      </p>

      <p>
        • They signed up <br />
        • They want to think about it <br />
        • Something else
        <br />
        • Started a trial <br />
      </p>
      <p>
        *Presentation sales/presentations occured x 100 = %<br />
      </p>
    </Container>
  );

  const tooltips = {
    presentationsDueDescription,
    presentationsOccuredDescription,
    appointmentToPresentationRatioDescription,
    presentationSalesDescription,
    presentationCloseRatioDescription
  };

  return tooltips;
};

const Container = styled.span``;
