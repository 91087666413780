import * as actionTypes from './actionTypes'

const GET_AUTO_ASSIGN_RULE_ACTION_HANDLERS = {
  [actionTypes.SET_AUTO_ASSIGN_RULE]: (state, action) => {
    return { ...state, autoAssignRule: action.autoAssignRule }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

function initialState (salesTargets) {
  return {
    autoAssignRule: null,
    loading: false,
    errors: null
  }
}

export default function reducer (state = initialState(null), action) {
  const handler = GET_AUTO_ASSIGN_RULE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
