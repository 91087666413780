import PropTypes from "prop-types";
import React, { Component } from "react";

import QuickSendView from "./NewEmailViews/QuickSendView";
import HtmlTemplateSelectContainer from "./HtmlTemplateSelectContainer";
import EditHtmlView from "./NewEmailViews/EditHtmlView";
import SelectQuickSendTemplateContainer from "./SelectQuickSendTemplateContainer";

import Modal from "react-bootstrap-modal";
import * as helpers from "modules/Stores/Session/helpers";

export const QUICK_SEND_VIEW = "QuickSend";
export const SELECT_QUICK_SEND_TEMPLATE = "SELECT_QUICK_SEND_TEMPLATE";
export const HTML_TEMPLATE_SELECT_VIEW = "HTML_TEMPLATE_SELECT_VIEW";
export const EDIT_HTML_VIEW = "EDIT_HTML_VIEW";

const defaultState = {
  view: QUICK_SEND_VIEW,
  html: {
    selectedTemplate: null,
  },
};

export class SendEmailModal extends Component {
  state = defaultState;

  currentTemplateView = () => {
    switch (this.state.view) {
      case HTML_TEMPLATE_SELECT_VIEW:
        return (
          <HtmlTemplateSelectContainer
            cancelSelected={this.cleanAndHideModal}
            backSelected={() =>
              this.setState({ ...this.state, view: QUICK_SEND_VIEW })
            }
            onTemplateSelect={this.selectTemplate}
          />
        );
      case SELECT_QUICK_SEND_TEMPLATE:
        return (
          <SelectQuickSendTemplateContainer
            cleanUpParent={this.cleanAndHideModal}
            onTemplateSelect={this.onQuickSendTemplateSelected}
            cancelSelected={this.cleanAndHideModal}
            backSelected={() =>
              this.setState({ ...this.state, view: QUICK_SEND_VIEW })
            }
          />
        );
      default:
        return <div />;
    }
  };

  selectTemplate = (template) => {
    this.setState({
      ...this.state,
      html: { selectedTemplate: template },
      view: EDIT_HTML_VIEW,
    });
  };

  onQuickSendTemplateSelected = (quickSendTemplate) => {
    this.setState({
      view: QUICK_SEND_VIEW,
    });
    this.composeView.setTemplate(
      quickSendTemplate.text,
      quickSendTemplate.subject
    );
  };

  cleanAndHideModal = () => {
    this.setState(defaultState);
    this.props.hideModal();
  };

  render() {
    const {
      loading,
      modalName,
      currentModal,
      lead,
      user,
      errors,
      canSendEmail,
      sendQuickEmailToLead,
      sendHtmlEmailToLead,
      emailBlocked,
      mergeTags,
    } = this.props;

    const { view } = this.state;

    const blockStyle = loading ? "block block-opt-refresh" : "block";
    const sizeIsLarge = this.state.view === EDIT_HTML_VIEW;
    const style = sizeIsLarge ? { width: "1124px", marginLeft: "-100px" } : {};

    const readOnly = !helpers.userHasOpenComms(user) && canSendEmail;

    return (
      <div>
        <Modal
          large
          show={currentModal === modalName}
          onHide={this.cleanAndHideModal}
          backdrop="static"
        >
          <div className={blockStyle} style={style}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={this.cleanAndHideModal}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                Send An Email
              </h3>
            </div>
            <div
              style={{
                display: view === QUICK_SEND_VIEW ? "" : "none",
              }}
            >
              <QuickSendView
                lead={lead}
                user={user}
                readOnly={readOnly}
                sendQuickEmailToLead={sendQuickEmailToLead}
                cleanUpParent={this.cleanAndHideModal}
                setCurrentView={(view) =>
                  this.setState({ ...this.state, view: view })
                }
                cancelSelected={this.cleanAndHideModal}
                canSendEmail={canSendEmail}
                errors={errors}
                mergeTags={mergeTags}
                emailBlocked={emailBlocked}
                ref={(instance) => {
                  if (instance) {
                    this.composeView = instance;
                  }
                }}
              />
            </div>
            {this.state.html.selectedTemplate && (
              <div
                style={{
                  display: view === EDIT_HTML_VIEW ? "" : "none",
                }}
              >
                <EditHtmlView
                  lead={lead}
                  readOnly={readOnly}
                  template={this.state.html.selectedTemplate}
                  mergeTags={mergeTags}
                  sendHtmlEmailToLead={sendHtmlEmailToLead}
                  cleanUpParent={this.cleanAndHideModal}
                  cancelSelected={this.cleanAndHideModal}
                  canSendEmail={canSendEmail}
                  backSelected={() =>
                    this.setState({
                      ...this.state,
                      view: HTML_TEMPLATE_SELECT_VIEW,
                    })
                  }
                  user={user}
                  errors={errors}
                />
              </div>
            )}
            {this.currentTemplateView()}
          </div>
        </Modal>
      </div>
    );
  }
}

SendEmailModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  sendQuickEmailToLead: PropTypes.func.isRequired,
  sendHtmlEmailToLead: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  canSendEmail: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentModal: PropTypes.string,
  emailBlocked: PropTypes.bool.isRequired,
};

export default SendEmailModal;
