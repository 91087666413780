import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView } from 'modules/components'

import LawfulProcessingBasisRow from './LawfulProcessingBasisRow'

export class ListLawfulProcessingBasesView extends Component {

  state = {
    showingSearch: false
  }

  newLawfulProcessingBasisClicked = () => {
    this.props.selectLawfulProcessingBasisId(null)
    this.props.changeShowing(true)
  }

  lawfulProcessingBasisClicked = (lawfulProcessingBasisId) => {
    this.props.selectLawfulProcessingBasisId(lawfulProcessingBasisId)
    this.props.changeShowing(true)
  }

  render () {
    const { lawfulProcessingBases, selectedLawfulProcessingBasisId } = this.props
    const rows = lawfulProcessingBases.map((lawfulProcessingBasis, index) => {
      const selected = (parseInt(lawfulProcessingBasis.id) === parseInt(selectedLawfulProcessingBasisId))
      return <LawfulProcessingBasisRow
        key={index}
        lawfulProcessingBasis={lawfulProcessingBasis}
        selected={selected}
        onSelect={this.lawfulProcessingBasisClicked} />
    })
    return (
      <div>
        <div className={'block block-rounded block-bordered'} >
          <div className='block-header'>
            <div className='block-options-simple'>
              <button type='button'
                className='btn btn-xs btn-success push-10-r'
                onClick={this.newLawfulProcessingBasisClicked}>
                <i className='fa fa-plus' />{' '}Create new lawful processing basis
              </button>
            </div>
            <h3 className='block-title'>Your Lawful Processing Bases</h3>
          </div>
          <div className='block-content'>
            {lawfulProcessingBases.length === 0 &&
              <div style={{ paddingBottom: '30px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='fa fa-legal'
                  title='No lawful processing bases'
                  subtitle='Lawful processing bases will show here'
                  buttonText='Create a new lawful processing basis'
                  buttonSelected={this.newLawfulProcessingBasisClicked} />
              </div>
            }
            {lawfulProcessingBases.length > 0 &&
              <table className='table table-vcenter table-hover'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>New Lead Default</th>
                    <th>Closed Lead Default</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    )
  }
}

ListLawfulProcessingBasesView.propTypes = {
  changeShowing: PropTypes.func.isRequired,
  lawfulProcessingBases: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectLawfulProcessingBasisId: PropTypes.func.isRequired,
  selectedLawfulProcessingBasisId: PropTypes.number
}

export default ListLawfulProcessingBasesView
