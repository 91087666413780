import { createSelector } from "reselect";
import { newPayloadForValue } from "utils/formHelpers";
import { salesFunnelStepLinkJSONForId } from "./helpers";

export function flatFilterParamsFactory(filterDataSelector) {
  return createSelector([filterDataSelector], (filterData) => {
    return flattenFilterData(filterData);
  });
}

function flattenFilterData(filterData) {
  const sections = Object.keys(filterData);
  return sections.map((sectionName) => {
    const section = filterData[sectionName];
    const flatSectionData = Object.keys(section.fields)
      .map((fieldName) => {
        const field = section.fields[fieldName];
        if (field.value && field.value !== false) {
          return {
            name: fieldName,
            value: field.value,
          };
        }
        return undefined;
      })
      .filter((value) => value !== undefined);
    return {
      name: sectionName,
      filters: flatSectionData,
    };
  });
}

export function queryStringFactoryWithoutStatuses(filterDataSelector) {
  return createSelector([filterDataSelector], (filterData) => {
    const flatFilterParams = flattenFilterData(filterData);
    let queryString = "";
    flatFilterParams.forEach((section) => {
      switch (section.name) {
        case "assigned_to_ids":
        case "lead_source_ids":
        case "heard_about_method_ids":
        case "lead_lost_reason_ids":
        case "deal_ids":
        case "country_ids":
        case "genders":
        case "record_types":
        case "user_ids":
        case "location_ids":
        case "sales_funnel_step_ids":
        case "tags":
        case "no_due_actions":
          queryString =
            queryString + constructStatusFilterStringWithSection(section);
          break;
        case "contains":
        case "createdBetween":
        case "statusUpdatedBetween":
          queryString =
            queryString + constructStringFilterStringWithSection(section);
          break;
        case "sales_funnel_step_links":
          queryString =
            queryString + constructLinkKeyFilterStringWithSection(section);
          break;
        default:
          break;
      }
    });

    if (queryString.length > 1) {
      return queryString.slice(0, -1);
    } else {
      return "";
    }
  });
}

export function queryStringFactory(filterDataSelector) {
  return createSelector([filterDataSelector], (filterData) => {
    const flatFilterParams = flattenFilterData(filterData);
    let queryString = "";
    flatFilterParams.forEach((section) => {
      switch (section.name) {
        case "statuses":
        case "current_funnel_step_ids":
        case "assigned_to_ids":
        case "lead_source_ids":
        case "heard_about_method_ids":
        case "lead_lost_reason_ids":
        case "deal_ids":
        case "country_ids":
        case "genders":
        case "record_types":
        case "user_ids":
        case "location_ids":
        case "region_ids":
        case "trial_ids":
        case "sales_funnel_step_ids":
        case "tags":
        case "no_due_actions":
          queryString =
            queryString + constructStatusFilterStringWithSection(section);
          break;
        case "contains":
        case "createdBetween":
        case "statusUpdatedBetween":
        case "lastContacted":
        case "trials":
          queryString =
            queryString + constructStringFilterStringWithSection(section);
          break;
        case "sales_funnel_step_links":
          queryString =
            queryString + constructLinkKeyFilterStringWithSection(section);
          break;
        default:
          break;
      }
    });

    if (queryString.length > 1) {
      return queryString.slice(0, -1);
    } else {
      return "";
    }
  });
}

function constructStatusFilterStringWithSection(section) {
  let string = "";
  section.filters.forEach((filter) => {
    string = string + "filter[" + section.name + "][]=" + filter.name + "&";
  });
  return string;
}

function constructStringFilterStringWithSection(section) {
  let string = "";
  section.filters.forEach((filter) => {
    string = string + "filter[" + filter.name + "]=" + filter.value + "&";
  });
  return string;
}

function constructLinkKeyFilterStringWithSection(section) {
  let string = "";
  section.filters.forEach((filter) => {
    const linkJson = salesFunnelStepLinkJSONForId(filter.name);
    string =
      string +
      "filter[" +
      section.name +
      "][][to_step_id]=" +
      linkJson.to_step_id +
      "&" +
      "filter[" +
      section.name +
      "][][type]=" +
      linkJson.type +
      "&";
    if (linkJson.from_step_id) {
      string =
        string +
        "filter[" +
        section.name +
        "][][from_step_id]=" +
        linkJson.from_step_id +
        "&";
    }
  });
  return string;
}

export function queryBodyFactory(filterDataSelector) {
  return createSelector([filterDataSelector], (filterData) => {
    const flatFilterParams = flattenFilterData(filterData);
    let queryBody = {};
    flatFilterParams.forEach((section) => {
      switch (section.name) {
        case "statuses":
        case "current_funnel_step_ids":
        case "assigned_to_ids":
        case "lead_source_ids":
        case "heard_about_method_ids":
        case "lead_lost_reason_ids":
        case "deal_ids":
        case "country_ids":
        case "genders":
        case "record_types":
        case "user_ids":
        case "location_ids":
        case "region_ids":
        case "sales_funnel_step_ids":
        case "tags":
        case "no_due_actions":
          queryBody = newPayloadForValue(
            queryBody,
            constructStatusFilterArrayWithSection(section),
            section.name
          );
          break;
        case "contains":
        case "createdBetween":
        case "statusUpdatedBetween":
          queryBody = constructStringFilterBodyWithSection(section, queryBody);
          break;
        case "sales_funnel_step_links":
          queryBody = constructLinkKeyFilterBodyWithSection(section, queryBody);
          break;
        default:
          break;
      }
    });

    return queryBody;
  });
}

function constructStatusFilterArrayWithSection(section) {
  let array = [];
  section.filters.forEach((filter) => {
    array.push(filter.name);
  });
  return array;
}

function constructStringFilterBodyWithSection(section, queryBody) {
  section.filters.forEach((filter) => {
    queryBody = newPayloadForValue(queryBody, filter.value, filter.name);
  });
  return queryBody;
}

function constructLinkKeyFilterBodyWithSection(section, queryBody) {
  let links = [];
  section.filters.forEach((filter) => {
    const linkJson = salesFunnelStepLinkJSONForId(filter.name);
    let link = {};
    link = newPayloadForValue(link, linkJson.to_step_id, "to_step_id");
    link = newPayloadForValue(link, linkJson.type, "type");

    if (linkJson.from_step_id) {
      link = newPayloadForValue(link, linkJson.from_step_id, "from_step_id");
    }
    links.push(link);
  });
  queryBody = newPayloadForValue(queryBody, links, "sales_funnel_step_links");
}
