export const NAME = 'leadDetail'

export const EDIT_LEAD_MODAL = NAME + '/EDIT_LEAD_MODAL'
export const LEAD_PHONE_MODAL = NAME + '/LEAD_PHONE_MODAL'
export const SEND_EMAIL_MODAL = NAME + '/SEND_EMAIL_MODAL'

export const RECORD_CALL_MODAL = 'RECORD_CALL_MODAL'
export const SEND_TEXT_MODAL = 'SEND_TEXT_MODAL'

export const MOVE_LEAD_MODAL = 'MOVE_LEAD_MODAL'
export const LEAD_LOST_REASON_MODAL = NAME + '/LEAD_LOST_REASON_MODAL'
export const DEAL_MODAL = 'DEAL_MODAL'
