import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectDateRangeButton from 'modules/components/SelectDateRangeButton/SelectDateRangeButton'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'

function mapStateToProps (state, props) {
  const createdBetween = "createdBetween";
  const createdBetweenData = props.filterData && props.filterData[createdBetween] ? props.filterData[createdBetween] : { fields: {} };
  return {
    sectionName: createdBetween,
    data: createdBetweenData,
    currentModal: modal.selectors.getCurrentModal(state),
    timezone: session.selectors.getUserTimezone(state),
    updatedAfterTitle: 'created_after',
    updatedBeforeTitle: 'created_before'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDateRangeButton)
