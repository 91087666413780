import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { FormFields } from 'modules/components';
const { TextField, SelectField } = FormFields;

export class EditLeadSourceModal extends Component {
  state = {
    title: this.props.leadSource.source_name,
    lawfulProcessingBasisId: this.props.leadSource.lawful_processing_basis_id,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.leadSource.id !== nextProps.leadSource.id) {
      this.setState({
        ...this.state,
        title: nextProps.leadSource.source_name,
        lawfulProcessingBasisId: nextProps.leadSource.lawful_processing_basis_id,
      });
    }
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, title: value });
  };

  handleSelect = (name, value) => {
    this.setState({ ...this.state, lawfulProcessingBasisId: value });
  };

  updateClicked = () => {
    const { leadSource, updateLeadSource } = this.props;
    const { title, lawfulProcessingBasisId } = this.state;
    updateLeadSource(leadSource, title, lawfulProcessingBasisId);
  };

  render() {
    const { loading, currentModal, hideModal, modalName, leadSource, lawfulProcessingBases } = this.props;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Update source
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <div className="row">
                  <div className="col-xs-12">
                    <TextField
                      name="title"
                      label="Heard about name"
                      placeholder="eg. Summer deal langing page"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      needsFormGroup={false}
                      initialValue={leadSource.source_name}
                      disabled={leadSource.locked}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12" style={{ marginLeft: '-20px', marginRight: '20px' }}>
                    <SelectField
                      name="lawfulProcessingBasisId"
                      label="What is the default Lawful Processing Basis?"
                      possibleOptions={lawfulProcessingBases}
                      displayFieldName={'title'}
                      placeHolderText="Select a lawful processing basis"
                      formFieldStyle="form-material form-material-primary"
                      initialValue={this.state.lawfulProcessingBasisId}
                      onChange={this.handleSelect}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn btn-primary push-5-r push-10" type="button" onClick={this.updateClicked}>
              <i className="fa fa-save" /> Update lead source
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

EditLeadSourceModal.propTypes = {
  leadSource: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  updateLeadSource: PropTypes.func.isRequired,
  lawfulProcessingBases: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default EditLeadSourceModal;
