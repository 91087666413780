import React from "react";
import styled from "styled-components";

export const toolTipSelector = calculating => {
  switch (calculating) {
    case "lead_created":
      return leadCreatedTooltips();
    case "event_occured":
      return eventOccuredTooltips();
    default:
      return eventOccuredTooltips();
  }
};

const leadCreatedTooltips = () => {
  const a1Description = (
    <Container>
      <p>
        These are all appointments that are{" "}
        <strong>due to occur with 24 hours</strong> after speaking with someone.{" "}
      </p>
      <p>
        Eg: You have made a contact call and booked an appointment with them for
        today
      </p>
    </Container>
  );

  const a2Description = (
    <Container>
      <p>
        These are all appointments that are{" "}
        <strong>due to occur within 24 - 48 hours</strong> after speaking with
        someone.
      </p>
      <p>
        Eg: You have made a contact call today and booked an appointment for 2
        days time
      </p>
    </Container>
  );

  const a3Description = (
    <Container>
      <p>
        These are all appointments that are{" "}
        <strong>due to occur within 48+ hours</strong> after speaking with
        someone.
      </p>
      <p>
        Eg: You have made a contact call today and booked an appointment for 3+
        days time
      </p>
    </Container>
  );

  const totalAppointmentsBookedDescription = (
    <Container>
      <p>
        These are <strong>all appointments that are due to occur</strong> within
        your selected time period.
      </p>
      <p>*A1 + A2 + A3 = Total appointments booked.</p>
    </Container>
  );

  const tooltips = {
    a1Description,
    a2Description,
    a3Description,
    totalAppointmentsBookedDescription
  };
  return tooltips;
};

const eventOccuredTooltips = () => {
  const a1Description = (
    <Container>
      <p>
        These are all appointments that are{" "}
        <strong>due to occur with 24 hours</strong> after speaking with someone.{" "}
      </p>
      <p>
        Eg: You have made a contact call and booked an appointment with them for
        today
      </p>
    </Container>
  );

  const a2Description = (
    <Container>
      <p>
        These are all appointments that are{" "}
        <strong>due to occur within 24 - 48 hours</strong> after speaking with
        someone.
      </p>
      <p>
        Eg: You have made a contact call today and booked an appointment for 2
        days time
      </p>
    </Container>
  );

  const a3Description = (
    <Container>
      <p>
        These are all appointments that are{" "}
        <strong>due to occur within 48+ hours</strong> after speaking with
        someone.
      </p>
      <p>
        Eg: You have made a contact call today and booked an appointment for 3+
        days time
      </p>
    </Container>
  );

  const totalAppointmentsBookedDescription = (
    <Container>
      <p>
        These are <strong>all appointments that are due to occur</strong> within
        your selected time period.
      </p>
      <p>*A1 + A2 + A3 = Total appointments booked.</p>
    </Container>
  );

  const tooltips = {
    a1Description,
    a2Description,
    a3Description,
    totalAppointmentsBookedDescription
  };
  return tooltips;
};

const Container = styled.div``;
