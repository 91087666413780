import PropTypes from "prop-types";
import React, { Component } from "react";
import SalesPerformanceHeaderContainer from "../containers/SalesPerformanceHeaderContainer";

import FiltersContainer from "../containers/FiltersContainer";
import SalesPerformanceFunnelContainer from "../containers/SalesPerformanceFunnelContainer";
import ContactsContainer from "../containers/ContactsContainer";
import AppointmentsContainer from "../containers/AppointmentsContainer";
import PresentationsContainer from "../containers/PresentationsContainer";
import WalkInsContainer from "../containers/WalkInsContainer";
import SalesContainer from "../containers/SalesContainer";
import UserLeaderboardContainer from "../containers/UserLeaderboardContainer";

export class SalesPerformanceReportView extends Component {
  render() {
    return (
      <div className="content push-100">
        <div className="row">
          <div className="col-xs-12">
            <SalesPerformanceHeaderContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FiltersContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SalesPerformanceFunnelContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <UserLeaderboardContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ContactsContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <AppointmentsContainer />
          </div>
          <div className="col-md-6">
            <WalkInsContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <PresentationsContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <SalesContainer />
          </div>
        </div>
      </div>
    );
  }
}

SalesPerformanceReportView.propTypes = {
  queryString: PropTypes.string
};
