import PropTypes from "prop-types";
import React, { Component } from "react";
import { MultiLineChartView } from "modules/components/Data";
import styled from "styled-components";

export class UserLeaderboardView extends Component {
  UNSAFE_componentWillMount() {
    const {
      fetchWithQueryString,
      queryString,
      compareOption,
      calculatingOption
    } = this.props;
    fetchWithQueryString(queryString, compareOption, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.compareOption !== this.props.compareOption ||
      nextProps.calculatingOption !== this.props.calculatingOption
    ) {
      const {
        fetchWithQueryString,
        queryString,
        compareOption,
        calculatingOption
      } = nextProps;
      fetchWithQueryString(queryString, compareOption, calculatingOption);
    }
  }

  render() {
    const { labels, data, loading } = this.props;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";

    const chartHeight = 20 * labels.length + 200;

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Funnel performance leaderboard</h3>
        </div>
        <div className="block-content push-50">
          <ScrollableContainer chartHeight={chartHeight}>
            <Container chartHeight={chartHeight}>
              <MultiLineChartView data={data} labels={labels} />
            </Container>
          </ScrollableContainer>
        </div>
      </div>
    );
  }
}

UserLeaderboardView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  compareOption: PropTypes.string.isRequired,
  queryString: PropTypes.string
};

export default UserLeaderboardView;

const ScrollableContainer = styled.div`
  overflow-y: ${props => (props.chartHeight > 450 ? "scroll" : "")};
  max-height: 475px;
`;

const Container = styled.div`
  height: ${props => props.chartHeight}px;
`;
