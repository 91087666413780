import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import checkIns from '../modules/checkIns';
import modal from 'modules/Stores/Modal';

function mapStateToProps(state) {
  return {
    waiverSubmissions: checkIns.selectors.getWaiverSubmissions(state),
    loading: checkIns.selectors.getLoading(state),
    selectedWaiverSubmission: checkIns.selectors.selectedWaiverSubmission(state),
    pageDetails: checkIns.selectors.getPageDetails(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectWaiverSubmissionId: checkIns.actions.selectWaiverSubmissionId,
      showModal: modal.actions.showModal,
      fetchForLead: checkIns.actions.fetchForLead,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(checkIns.components.LeadWaiversView);
