import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-bootstrap-modal";

import ComposeTextMessageView from "./ComposeTextMessageView";
import SelectSMSTemplateContainer from "./SelectSMSTemplateContainer";

export const COMPOSE_VIEW = "SMS/COMPOSE_VIEW";
export const SELECT_TEMPLATE_VIEW = "SMS/SELECT_TEMPLATE_VIEW";

const defaultState = {
  selectedView: COMPOSE_VIEW
};

export class SendTextMessageModal extends Component {
  state = defaultState;

  setSelectedView = selectedView => {
    this.setState({ selectedView });
  };

  cleanAndHideModal = () => {
    this.setState(defaultState);
    this.props.hideModal();
  };

  templateSelected = template => {
    this.composeView.setTemplate(template.text);
    this.setState({
      selectedView: COMPOSE_VIEW
    });
  };

  titleForModal = () => {
    switch (this.state.selectedView) {
      case COMPOSE_VIEW:
        return "Send a Text Message";
      case SELECT_TEMPLATE_VIEW:
        return "Select a template to use";
      default:
        break;
    }
  };

  render() {
    const { loading, modalName, currentModal } = this.props;
    const { selectedView } = this.state;

    const blockStyle = loading ? "block block-opt-refresh" : "block";

    return (
      <div>
        <Modal
          large
          show={currentModal === modalName}
          onHide={this.cleanAndHideModal}
          backdrop="static"
        >
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button
                  data-dismiss="modal"
                  type="button"
                  onClick={this.cleanAndHideModal}
                >
                  <i className="si si-close" style={{ color: "white" }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: "white" }}>
              {this.titleForModal()}
            </h3>
          </div>
          <div className={blockStyle}>
            <div
              style={{ display: selectedView === COMPOSE_VIEW ? "" : "none" }}
            >
              <ComposeTextMessageView
                {...this.props}
                text={this.state.text}
                setSelectedView={this.setSelectedView}
                ref={instance => {
                  if (instance) {
                    this.composeView = instance;
                  }
                }}
              />
            </div>
            {selectedView === SELECT_TEMPLATE_VIEW && (
              <SelectSMSTemplateContainer
                {...this.props}
                goBackView={COMPOSE_VIEW}
                setSelectedView={this.setSelectedView}
                onTemplateSelect={this.templateSelected}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

SendTextMessageModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  smsCost: PropTypes.number.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  sendTextMessageToLead: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentModal: PropTypes.string
};

export default SendTextMessageModal;
