import * as actionTypes from './actionTypes';
import * as subscriptoinAddOnApi from 'modules/api/subscriptionAddOn';

export const fetchSubscriptionAddOns = (page = 1, sort = null, search = null) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await subscriptoinAddOnApi.getSubscriptionAddOns(undefined, page);
      dispatch(setLoading(false));
      dispatch(setSubscriptionAddOns(response.subscription_add_ons));
      dispatch(setPageDetails(response.meta));
    } catch (errors) {
      dispatch(setLoading(false));
      dispatch(setErrors(errors.data));
    }
  };
};

function setSubscriptionAddOns(subscriptionAddOns) {
  return {
    type: actionTypes.SET_SUBSCRIPTION_ADD_ONS,
    subscriptionAddOns,
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.SET_PAGE_DETAILS,
    pageDetails,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
