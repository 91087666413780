import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SelectLocationTableRow extends Component {

  rowSelected = (event) => {
    const { location, locationSelected, selectLocation, deselectLocation } = this.props
    if (locationSelected) {
      deselectLocation(location)
    } else {
      selectLocation(location)
    }
  }

  render () {
    const { location, locationSelected } = this.props
    return (
      <tr className='selectable' onClick={() => this.rowSelected(location)}>
        <td className='text-center'>
          <label className='css-input css-checkbox css-checkbox-primary'>
            <input type='checkbox' disabled checked={locationSelected} readOnly /><span />
          </label>
        </td>
        <td>{location.admin_display_name}</td>
        <td className='text-center'>
          {location.zip_postcode}
        </td>
      </tr>
    )
  }
}

SelectLocationTableRow.propTypes = {
  location: PropTypes.object.isRequired,
  locationSelected: PropTypes.bool.isRequired,
  selectLocation: PropTypes.func.isRequired,
  deselectLocation: PropTypes.func.isRequired
}
