import * as callHelpers from 'modules/components/LeadDetail/modules/calls/helpers'

export function callScheduleActiveText (lead, selectedAction) {
  const callScheduleStep = lead.call_schedule_step
  if (!callScheduleStep) { return null }

  if (selectedAction === callHelpers.NO_ANSWER ||
  selectedAction === callHelpers.LEFT_MESSAGE) {
    return lead.given_name + ' is in a call schedule. With the selected option ' +
    'the next follow up call will be scheduled automatically' + actionStringForCallScheduleStep(callScheduleStep)
  }
  if (selectedAction === callHelpers.STILL_THINKING) {
    return lead.given_name + ' is in a call schedule. With the selected option ' +
    'the next follow up call will be scheduled automatically.'
  }

  return null
}

function actionStringForCallScheduleStep (callScheduleStep) {
  const smsId = callScheduleStep.text_message_template_id
  const emailId = callScheduleStep.email_template_id
  if (!smsId && !emailId) {
    return '.'
  }

  if (smsId && !emailId) {
    return ' and an SMS will be sent.'
  }

  if (!smsId && emailId) {
    return ' and an email will be sent.'
  }

  if (smsId && emailId) {
    return ' and an SMS and email will be sent.'
  }
}

export function optionsToShow (lead, selectedAction) {
  const callScheduleStep = lead.call_schedule_step
  if (!callScheduleStep) { return null }

  if (selectedAction === callHelpers.NO_ANSWER ||
  selectedAction === callHelpers.LEFT_MESSAGE) {
    return {
      sms: (callScheduleStep.text_message_template_id !== null),
      email: (callScheduleStep.email_template_id !== null)
    }
  }

  return null
}
