import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";

import { FormFields } from "modules/components";

import FilterSection from "./FilterSection";
const { TextField } = FormFields;

export default class ContainsSection extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onUpdate(newSectionData, this.props.sectionName);
  };

  state = {
    containOptions: [
      {
        display: "Name Contains",
        id: "name_contains",
        eg: "eg. Janet",
        value: null,
      },
      {
        display: "Email Contains",
        id: "email_contains",
        eg: "eg. @gmail.com",
        value: null,
      },
      {
        display: "Phone number",
        id: "mobile_number_contains",
        eg: "(include the country & area code)",
        value: null,
      },
      {
        display: "Postcode",
        id: "postcodes",
        eg: "eg. 3000, 3010",
        value: null,
      },
    ],
  };

  render() {
    const options = this.state.containOptions.map((containOption, index) => {
      const field = this.props.data.fields[containOption.id];
      const value = (field && field.value) || null;
      return (
        <div key={index} className="col-sm-6">
          <TextField
            name={containOption.id}
            label={containOption.display}
            placeholder={containOption.eg}
            initialValue={value}
            onChange={this.handleInput}
            needsFormGroup={false}
          />
        </div>
      );
    });

    return (
      <FilterSection
        title={"Text Search"}
        showBottomBorder={this.props.showBottomBorder}
      >
        <div> {options} </div>
      </FilterSection>
    );
  }
}

ContainsSection.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool,
};
