import { colorForDate, isThisYear, isToday } from 'modules/Helpers/DateHelpers';
import moment from 'moment';
import 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import * as appointmentHelpers from '../../helpers';

export class AppointmentItem extends Component {
  onSelect = (event) => {
    event.preventDefault();
    const { appointment, appointmentClicked } = this.props;
    appointmentClicked(appointment);
  };

  onDelete = () => {
    const { appointment, appointmentDeleted } = this.props;
    appointmentDeleted(appointment);
  };

  render() {
    const { appointment, timezone, loading, classIntegrationName } = this.props;

    const creatingUserName = appointment.creating_user ? `booked by ${appointment?.creating_user?.display_name}` : '';
    const completingUserName = appointment.completing_user ? appointment.completing_user.display_name : 'Deleted User';

    const appointmentTitleString = appointment.service_class_detail || 'Appointment';

    let integrationName = '';
    if (classIntegrationName === 'mindbody') integrationName = 'MINDBODY';
    if (classIntegrationName === 'glofox') integrationName = 'Glofox';
    if (classIntegrationName === 'journey') integrationName = 'Journey';

    return (
      <BorderedContainer className={`block block-link-hover3 ${loading && 'block-opt-refresh'}`}>
        <div className="block-content block-content-full clearfix">
          <div className="row">
            <div className="col-xs-1">
              <label className="css-input css-checkbox css-checkbox-lg css-checkbox-primary">
                <input type="checkbox" checked={appointment.completed} onChange={this.onSelect} />
                <span />{' '}
              </label>
            </div>
            <div className="col-xs-9">
              {appointment.outcome && (
                <h4>
                  {completingUserName} {appointmentHelpers.titleForType(appointment.outcome)} for {appointmentTitleString}
                  {appointment.visit_type === 'no_sale_return' && (
                    <span
                      style={{
                        marginLeft: '5px',
                        fontSize: '10px',
                        bottom: '3px',
                        position: 'relative',
                      }}
                      className="label label-warning"
                    >
                      NSR
                    </span>
                  )}
                </h4>
              )}
              {!appointment.outcome && (
                <h4>
                  {`${appointmentTitleString} ${creatingUserName}`}
                  {appointment.visit_type === 'no_sale_return' && (
                    <span
                      style={{
                        marginLeft: '5px',
                        fontSize: '10px',
                        bottom: '3px',
                        position: 'relative',
                      }}
                      className="label label-warning"
                    >
                      NSR
                    </span>
                  )}
                </h4>
              )}
              {appointment.syncing && (
                <span className="label" style={{ backgroundColor: '#989898' }}>
                  <span>
                    <i className="fa fa-cog fa-spin" />
                    {`Syncing with ${integrationName} class`}
                  </span>
                </span>
              )}
              {appointment.note && (
                <p className="text-muted">
                  <i className="si si-note" /> {appointment.note}
                </p>
              )}
            </div>
            <div className="col-xs-2">
              <i className="fa fa-remove pull-right fa-2x text-muted selectable" onClick={this.onDelete} />
            </div>
          </div>
          {appointment.checked_in && (
            <div className="row">
              <div className="col-xs-11 col-xs-offset-1">Lead has checked in</div>
            </div>
          )}
          <div className="row">
            <div className="col-xs-11 col-xs-offset-1">
              <AppointmentItemTime appointment={appointment} timezone={timezone} />
            </div>
          </div>
        </div>
      </BorderedContainer>
    );
  }
}

AppointmentItem.propTypes = {
  appointment: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  appointmentClicked: PropTypes.func.isRequired,
  appointmentDeleted: PropTypes.func.isRequired,
};

const BorderedContainer = styled.a`
  border-color: #eaeaea;
  border-width: 0.3px;
  border-style: solid;
`;

function getDateTimeFormat(momentDate) {
  if (isToday(momentDate)) return 'h:mm a';
  return isThisYear(momentDate) ? 'dddd MMMM Do, h:mm a' : 'dddd MMMM Do YYYY, h:mm a';
}

const AppointmentItemTime = ({ appointment, timezone }) => {
  const dueMoment = moment(appointment.due_date).tz(timezone);
  const completedMoment = moment(appointment.completed_on).tz(timezone).fromNow();
  const format = getDateTimeFormat(dueMoment);
  const dueDateString = dueMoment.format(format);
  const timeString = isToday(dueMoment) ? 'today at' : 'on';
  const timeColorClass = colorForDate(dueMoment, timezone) || 'text-muted';
  return (
    <div>
      {appointment.completed && (
        <div>
          <span className="text-muted">Completed {completedMoment}</span>
        </div>
      )}
      {!appointment.completed && appointment.due_date && (
        <div>
          <span className={timeColorClass}>
            Arriving {timeString} {dueDateString} for a {appointment.length} minute session
          </span>
        </div>
      )}
    </div>
  );
};

AppointmentItemTime.propTypes = {
  appointment: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
};
