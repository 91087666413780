import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import steps from '../modules/steps'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  const lostDataSet = steps.selectors.lostDataSet(state)
  const inactiveDataSet = steps.selectors.inactiveDataSet(state)
  return {
    loading: steps.selectors.getLoading(state),
    lostDataSet: lostDataSet,
    inactiveDataSet: inactiveDataSet,
    labels: steps.selectors.dataLabels(state),
    errors: steps.selectors.getErrors(state),
    queryString: queryStringSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: steps.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(steps.components.StepsView)
