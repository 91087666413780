import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import StageStepView from './StageStepView'

const statuses = 'statuses'
const funnelStepIds = 'current_funnel_step_ids'

export default class StagesView extends Component {

  UNSAFE_componentWillMount () {
    const { queryString, getLeadsCountWithQueryAndPageNumber } = this.props
    getLeadsCountWithQueryAndPageNumber(queryString)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.queryString !== this.props.queryString)) {
      const { getLeadsCountWithQueryAndPageNumber, queryString } = nextProps
      getLeadsCountWithQueryAndPageNumber(queryString)
    }
  }

  onUpdate = (sectionName, fieldName, selected) => {
    const data = (this.props.data[sectionName]) || { fields: {} }
    const newSectionData = generateNewFormState(data, fieldName, selected, true)
    const { updateFilters } = this.props
    const sections = this.props.data
    const newSections = { ...sections, [sectionName]: newSectionData }
    updateFilters(newSections)
  }

  isSelected = (sectionName, fieldName) => {
    const section = (this.props.data[sectionName]) || { fields: {} }
    const fieldData = (section.fields[fieldName]) || { value: false }
    return fieldData.value
  }

  render () {
    const { counts, countLoading } = this.props
    const steps = this.props.salesFunnelSteps.map((step, index) => {
      const fieldName = step.id.toString()
      const count = (counts[step.name]) ? counts[step.name] : 0
      const selected = this.isSelected(funnelStepIds, fieldName)
      return <StageStepView color={'#5c90d2'} title={step.name} key={index}
        sectionName={funnelStepIds} fieldName={fieldName} selected={selected}
        count={count} onSelect={this.onUpdate} />
    })

    const activeCount = (counts['active']) ? counts['active'] : 0
    const inactiveCount = (counts['inactive']) ? counts['inactive'] : 0
    const lostCount = (counts['lost']) ? counts['lost'] : 0
    const closedCount = (counts['closed']) ? counts['closed'] : 0
    const notAssignedCount = (counts['not_assigned']) ? counts['not_assigned'] : 0

    return (
      <div className='block'>
        {countLoading &&
          <p className='text-muted pull-right' style={{ marginBottom: '0px' }}>
            Updating numbers{' '}
            <i className='fa fa-cog fa-spin' />
          </p>
        }
        <h3 className='block-title push-15' >filter by stage:</h3>
        <div className='list-group selectable'>
          <StageStepView color={'#70b9eb'} title={'Active'}
            sectionName={statuses} fieldName={'active'} selected={this.isSelected(statuses, 'active')}
            count={activeCount} onSelect={this.onUpdate} />
          {steps}
          <StageStepView color={'#46c37b'} title={'Closed'}
            sectionName={statuses} fieldName={'closed'} selected={this.isSelected(statuses, 'closed')}
            count={closedCount} onSelect={this.onUpdate} />
          <StageStepView color={'#f3b760'} title={'Inactive'}
            sectionName={statuses} fieldName={'inactive'} selected={this.isSelected(statuses, 'inactive')}
            count={inactiveCount} onSelect={this.onUpdate} />
          <StageStepView color={'#d26a5c'} title={'Lost'}
            sectionName={statuses} fieldName={'lost'} selected={this.isSelected(statuses, 'lost')}
            count={lostCount} onSelect={this.onUpdate} />
          <StageStepView color={'#ff8f8f'} title={'Not Assigned'}
            sectionName={statuses} fieldName={'not_assigned'} selected={this.isSelected(statuses, 'not_assigned')}
            count={notAssignedCount} onSelect={this.onUpdate} />
        </div>
      </div>
    )
  }
}

StagesView.propTypes = {
  data: PropTypes.object.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  countLoading: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  counts: PropTypes.objectOf(PropTypes.number).isRequired,
  queryString: PropTypes.string.isRequired,
  getLeadsCountWithQueryAndPageNumber: PropTypes.func.isRequired
}
