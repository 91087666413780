/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import Avatar from 'react-avatar';

import TextSearchView from 'containers/ManageLeads/modules/filters/components/TextSearchView';
import { FormFields } from 'modules/components';

import { isToday } from 'modules/Helpers/DateHelpers';
import moment from 'moment';
import Calendar from 'modules/components/Calendar';

const { TextField, SelectField } = FormFields;
export class CreateTaskModal extends Component {
  state = {
    taskTypes: [
      {
        title: 'Contact call',
        type: 'contact_call',
        icon: 'si si-call-end',
      },
      {
        title: 'Appointment',
        type: 'appointment',
        icon: 'si si-calendar',
      },
      {
        title: 'Block availability',
        type: 'personal_task',
        icon: 'si si-close',
      },
    ],
    selectedTask: null,
    taskName: null,
    selectedUserId: null,
  };

  handleOptionSelect = (task) => {
    this.setState({ ...this.state, selectedTask: task });
    if (task.type === 'personal_task') {
      this.props.setSelectedLeadId(null);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      const { searchForLeadsWithQueryString, queryString } = nextProps;
      if (this.state.selectedTask && this.state.selectedTask.type !== 'personal_task') {
        searchForLeadsWithQueryString(queryString);
      }
    }
  }

  handleInput = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  handleUserSelection = (fieldName, value, isValid) => {
    const { presetUserIdsInFilters, locationId, data } = this.props;

    this.setState({ ...this.state, [fieldName]: value });
    presetUserIdsInFilters(value, locationId, data);
  };

  onUpdate = (sectionData, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = { ...sections, [sectionName]: sectionData };
    updateFilters(newSections);
  };

  userColor = () => {
    const { selectedUserId } = this.state;
    if (!selectedUserId) {
      return null;
    }
    const colors = Calendar.colors;
    const sortedUserIds = this.props.users.map((user) => user.id).sort();
    const index = sortedUserIds.findIndex((id) => parseInt(id) === parseInt(selectedUserId));
    return colors[index];
  };

  selectedUser = () => {
    const { selectedUserId } = this.state;
    if (!selectedUserId) {
      return null;
    }

    const { users } = this.props;

    const index = users.findIndex((user) => parseInt(user.id) === parseInt(selectedUserId));
    return users[index];
  };

  saveClicked = () => {
    if (!this.state.selectedTask) return;
    const { scheduleTask, startTime, length, timezone, locationId, scheduleAppointment, scheduleCall, selectedLead } = this.props;
    const { taskName, selectedTask, selectedUserId } = this.state;
    switch (selectedTask.type) {
      case 'personal_task':
        scheduleTask(startTime, length, selectedUserId, locationId, taskName, timezone, this.selectedUser(), this.userColor());
        break;
      case 'contact_call':
        scheduleCall(selectedLead, startTime, length, timezone, this.selectedUser(), this.userColor());
        break;
      case 'appointment':
        scheduleAppointment(selectedLead, startTime, length, timezone, this.selectedUser(), this.userColor());
        break;
      default:
        break;
    }
  };

  formIsInvalid = () => {
    if (!this.state.selectedTask) return true;
    if (!this.state.selectedUserId) return true;
    switch (this.state.selectedTask.type) {
      case 'personal_task':
        return !this.state.taskName;
      case 'contact_call':
      case 'appointment':
        return !this.props.selectedLead;
      default:
        return true;
    }
  };

  reset = () => {
    this.setState({
      ...this.state,
      selectedTask: null,
      taskName: null,
      selectedUserId: null,
    });
  };

  render() {
    const { currentModal, hideModal, modalName, timezone, startTime, length, loading, selectedLead } = this.props;
    const { selectedTask, taskTypes, selectedUserId } = this.state;

    const contains = 'contains';
    const nameContainsData = this.props.data[contains] || { fields: {} };
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    const options = taskTypes.map((type, index) => {
      const bgStyle = type === selectedTask ? 'bg-success-light' : 'bg-success';
      return (
        <div key={index} className="col-xs-6 col-sm-4">
          <a className="block block-bordered block-link-hover1 text-center selectable" onClick={() => this.handleOptionSelect(type)}>
            <div className={'block-content block-content-full ' + bgStyle}>
              <i className={type.icon + ' fa-5x text-white'} />
            </div>
            <div className="block-content block-content-full block-content-mini">{type.title}</div>
          </a>
        </div>
      );
    });

    const dueMoment = moment(startTime).tz(timezone);
    const format = isToday(dueMoment) ? 'h:mm a' : 'dddd MMMM Do, h:mm a';
    const dueDateString = dueMoment.format(format);
    const timeString = isToday(dueMoment) ? 'today at' : '';

    return (
      <div>
        <Modal show={currentModal === modalName} onExited={this.reset} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                What do you want to schedule?
              </h3>
            </div>
            <div className="block-content">
              <div className="row">{options}</div>
              {startTime && (
                <p>
                  For {timeString} {dueDateString} for {length} minutes
                </p>
              )}
              <div
                style={{
                  marginLeft: '-15px',
                  marginRight: '-15px',
                }}
              >
                <SelectField
                  name="selectedUserId"
                  label="Who is it for?"
                  possibleOptions={this.props.users}
                  placeHolderText={'Select a user'}
                  displayFieldName={'display_name'}
                  formFieldStyle=""
                  onChange={this.handleUserSelection}
                />
              </div>
              {selectedTask && selectedUserId && selectedTask.type !== 'personal_task' && (
                <div>
                  <p style={{ marginBottom: '0px' }}>Search for leads</p>
                  <div className="row push-10">
                    <div className="col-md-12" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                      <TextSearchView
                        ref={(input) => {
                          this.textSearch = input;
                        }}
                        data={nameContainsData}
                        onUpdate={this.onUpdate}
                        sectionName={contains}
                      />
                    </div>
                  </div>
                  {this.props.leads.length > 0 && (
                    <div className="row push-10">
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <div>
                            <table className="table table-vcenter">
                              <tbody>
                                {this.props.leads.map((lead, index) => {
                                  const className = selectedLead && selectedLead.id === lead.id ? 'selected' : 'selectable';
                                  return (
                                    <tr key={index} className={className} onClick={() => this.props.setSelectedLeadId(lead.id)}>
                                      <td width="15%">
                                        <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={22} round={true} />
                                      </td>
                                      <td>{lead.display_name}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {selectedTask && selectedTask.type === 'personal_task' && (
                <div className="row push-10">
                  <div className="col-md-12" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <TextField
                      name={'taskName'}
                      initialValue={''}
                      label={'What are you blocking off?'}
                      placeholder="eg. Lunch break"
                      onChange={this.handleInput}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.props.hideModal}>
              Cancel
            </button>
            <button className="btn btn-success push-5-r push-10" type="button" disabled={this.formIsInvalid()} onClick={this.saveClicked}>
              <i className="fa fa-plus" /> Create
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

CreateTaskModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  presetUserIdsInFilters: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scheduleTask: PropTypes.func.isRequired,
  searchForLeadsWithQueryString: PropTypes.func.isRequired,
  length: PropTypes.string,
  startTime: PropTypes.string,
  queryString: PropTypes.string,
  currentModal: PropTypes.string,
  selectedLead: PropTypes.object,
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedLeadId: PropTypes.func.isRequired,
  scheduleAppointment: PropTypes.func.isRequired,
  scheduleCall: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default CreateTaskModal;
