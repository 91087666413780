import { request, GET, POST, PUT } from 'utils/apiUtils';
import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

import modal from 'modules/Stores/Modal';

import * as actionTypes from './actionTypes';
export const sendInvite = (inviteFormData, location) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = seralizeInviteFromFormFields(inviteFormData, location);

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(resetForm());
      dispatch(sendInviteSuccess(payload.invite));
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success('Invite sent');
    }

    return request('invites', POST, body, onSuccess, onFailure);
  };
};

function seralizeInviteFromFormFields(formData, location) {
  const { email, role, givenName } = formData.fields;

  let payload = { location_id: location.id };
  payload = newPayloadForFormValue(payload, email, 'invite_email');
  payload = newPayloadForFormValue(payload, givenName, 'invite_name');
  payload = newPayloadForFormValue(payload, role, 'user_role_id');

  return payload;
}

export function updateInviteData(data) {
  return {
    type: actionTypes.UPDATE_INVITE_DATA,
    data,
  };
}

function sendInviteSuccess(invite) {
  return {
    type: actionTypes.SENDING_INVITE_SUCCESS,
    invite,
  };
}

// GET pending invites
export const getPendingInvitesForLocation = (location) => {
  return (dispatch) => {
    dispatch(startFetchingPendingInvites());
    function onFailure(errors) {
      dispatch(getPendingInvitesFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(getPendingInvitesSuccess(payload.invites));
    }

    return request('pending_invites?location_id=' + location.id, GET, null, onSuccess, onFailure);
  };
};

function startFetchingPendingInvites(invites) {
  return {
    type: actionTypes.GET_PENDING_INVITES,
    invites,
  };
}

function getPendingInvitesSuccess(pendingInvites) {
  return {
    type: actionTypes.GET_PENDING_INVITES_SUCCESS,
    pendingInvites,
  };
}

function getPendingInvitesFailed(errors) {
  return {
    type: actionTypes.GET_PENDING_INVITES_FAILURE,
    errors,
  };
}

// Resend
export const resendInvite = (invite) => {
  return (dispatch) => {
    const body = {
      id: invite.id,
    };
    dispatch(startResendingInvite());
    function onFailure(errors) {
      dispatch(resendInviteFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(resendInviteSuccess(payload.invites));
    }

    return request('resend_invite', POST, body, onSuccess, onFailure);
  };
};

function startResendingInvite(invites) {
  return {
    type: actionTypes.RESENDING_INVITE,
  };
}

function resendInviteSuccess(pendingInvites) {
  return {
    type: actionTypes.RESEND_INVITE_SUCCESS,
    pendingInvites,
  };
}

function resendInviteFailed(errors) {
  return {
    type: actionTypes.RESEND_INVITE_FAILRE,
    errors,
  };
}

// Revoke
export const revokeInvite = (invite, index) => {
  return (dispatch) => {
    dispatch(startRevokingInvite());
    const body = {
      revoked: true,
    };
    function onFailure(errors) {
      dispatch(revokeInviteFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(revokeInviteSuccess(index));
    }

    return request('invites/' + invite.id, PUT, body, onSuccess, onFailure);
  };
};

function startRevokingInvite(invites) {
  return {
    type: actionTypes.REVOKING_INVITE,
  };
}

function revokeInviteSuccess(index) {
  return {
    type: actionTypes.REVOKE_INVITE_SUCCESS,
    index,
  };
}

function revokeInviteFailed(errors) {
  return {
    type: actionTypes.REVOKE_INVITE_FAILRE,
    errors,
  };
}

export function resetForm() {
  return {
    type: actionTypes.RESET_FORM_DATA,
  };
}
