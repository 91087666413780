import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CreateLeadModal from '../components/CreateLeadModal'
import modal from 'modules/Stores/Modal'
import locations from 'modules/Stores/Locations'
import session from 'modules/Stores/Session'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    currentView: selectors.getCurrentView(state),
    defaultLocation: locations.selectors.getDefaultLocation(state),
    isMultiLocationAccount: locations.selectors.getIsMultiLocationAccount(state),
    selectedLocation: selectors.getSelectedLocation(state),
    user: session.selectors.getUser(state),
    selectedUser: selectors.getSelectedUser(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    selectLocation: actions.selectLocation,
    setSelectedUser: actions.setSelectedUser,
    resetForm: actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLeadModal)
