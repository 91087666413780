import { NAME } from './constants'

export const getLoading = state => state.locationSettings.autoAssignRule[NAME].loading
export const getData = state => state.locationSettings.autoAssignRule[NAME].data
export const getErrors = state => state.locationSettings.autoAssignRule[NAME].errors

// Set User Container
export const getPotentialUsers = state => state.locationSettings.autoAssignRule[NAME].potentialUsers
export const getPageDetails = state => state.locationSettings.autoAssignRule[NAME].pageDetails
export const getUserId = state => state.locationSettings.autoAssignRule[NAME].data.fields.user

export const getLocationId = state => state.locationSettings.autoAssignRule.getAutoAssignRule.autoAssignRule.location_id
