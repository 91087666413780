/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView, SimpleImageEmptyView } from "modules/components";

export class ImageEmptyView extends Component {
  onClick = (event) => {
    if (this.props.buttonSelected) {
      this.props.buttonSelected();
    }
  };

  render() {
    const {
      colorThemeClass,
      image,
      title,
      subtitle,
      buttonText,
      height,
      loading,
    } = this.props;
    return (
      <ContentBlockView loading={loading}>
        <SimpleImageEmptyView
          image={image}
          title={title}
          subtitle={subtitle}
          height={height}
        />
        <div className="row">
          {buttonText && (
            <div
              className={
                "col-sm-6 col-sm-offset-3 text-center text-" + colorThemeClass
              }
              style={{ marginBottom: "20px" }}
            >
              <a
                className={
                  "btn btn-minw btn-rounded btn-noborder btn-" +
                  colorThemeClass +
                  " push-5"
                }
                onClick={this.onClick}
              >
                {buttonText}
              </a>
            </div>
          )}
        </div>
      </ContentBlockView>
    );
  }
}

ImageEmptyView.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  colorThemeClass: PropTypes.string,
  height: PropTypes.number,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonSelected: PropTypes.func,
  loading: PropTypes.bool,
};

ImageEmptyView.defaultProps = {
  colorThemeClass: "primary",
  loading: false,
};

export default ImageEmptyView;
