import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'

import ButtonsView from '../components/SelectPage/ButtonsView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    selectedFacebookPage: selectors.selectedFacebookPage(state),
    nextIsReady: selectors.currentIndexIsReady(state),
    tabIndex: selectors.getTabIndex(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createFacebookIntegration: actions.createFacebookIntegration,
    setTabIndex: actions.setTabIndex,
    reset: actions.reset,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsView)
