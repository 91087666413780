import { request, GET } from 'utils/apiUtils';

import * as actionTypes from './actionTypes';

// Fetch
export const confirmEmail = (token) => {
  return (dispatch) => {
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(loadingFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadingSuccess(payload.user));
    }
    return request('confirm_email?token=' + token, GET, null, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadingFailed(errors) {
  return {
    type: actionTypes.LOADING_FAILURE,
    errors,
  };
}

function loadingSuccess(user) {
  return {
    type: actionTypes.LOADING_SUCCESS,
    user,
  };
}
