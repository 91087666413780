import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ExportDropdownContainer from "../containers/ExportDropdownContainer";

export class ReportHeaderView extends Component {
  render() {
    return (
      <Container className="row items-push">
        <div className="col-md-12">
          <div className="btn-group pull-right">
            <div className="btn-group">
              <ExportDropdownContainer />
            </div>
          </div>
          <h1 className="page-heading push-10">{this.props.title}</h1>
          <BottomBorder />
        </div>
      </Container>
    );
  }
}

ReportHeaderView.propTypes = {
  showModal: PropTypes.func.isRequired
};

export default ReportHeaderView;

const Container = styled.div``;
const BottomBorder = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 2px;
`;
