export enum FilterCondition {
  ANY = "any",
  ALL = "all"
}

export enum RuleEntryTypes {
  Text,
  Numeric,
  YesNo,
  Location,
  LeadSource,
  User,
  Gender,
  Tags,
  Days,
  Status,
  Trial,
  TrialTime,
  SalesFunnelStep,
  HeardAboutMethod,
  MissingActions
}

export interface IFilter {
  condition: FilterCondition;
  rules: IRule[];
}

export interface IRule {
  id: string;
  label: string;
  icon: string;
  entry: RuleEntryTypes;
  data?: {
    value: string | number | number[] | null | undefined;
    option: string | undefined;
  };
}

export enum FilterTypes {
  COMPARE_BY = "compare_by",
  CALCULATE_BY = "calculate_by",
  USERS = "users",
  LOCATIONS = "locations",
  REGIONS = "regions",
  LEAD_SOURCES = "lead_sources",
  HEARD_ABOUT_METHODS = "heard_about_methods",
  TAGS = "tags",
  TRIALS = "trials",
  DATE_RANGE = "date_range",
  LEAD_CREATED = "lead_created",
  EVENT_OCCURED = "event_occured",
  LEAD_STATUS = "statuses",
  STATUS_UPDATED_RANGE = "status_updated_between",
  ASSIGNED_TO = "assigned_to",
  FUNNEL_STEPS = "funnel_steps"
}
