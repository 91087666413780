import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

export class FormPostExportView extends Component {
  hideAndReset = () => {
    const { hideModal, reset } = this.props;
    hideModal();
    reset();
  };

  render() {
    const { currentModal, modalName, title, formPostData } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={this.hideAndReset}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideAndReset}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              {title}
            </h3>
          </div>
          <div className="block-content">
            <p>Let's sign them up! Click below to open your sign up form and prefil their details.</p>
            <form className="form-horizontal push-30-t" method="POST" action={formPostData.url} target="_blank">
              {formPostData.form_data.map((field, index) => {
                return <input type="hidden" value={field.value || undefined} id={field.id} name={field.id} key={index} />;
              })}
              <div className="form-group">
                <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                  <button type="submit" className="btn btn-block btn-primary" onClick={this.handlePostSubmit}>
                    Open in Snapp DD
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideAndReset}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

FormPostExportView.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  formPostData: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
};

export default FormPostExportView;
