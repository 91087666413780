import { createSelector } from 'reselect'
import lawfulProcessingBases from 'modules/Stores/LawfulProcessingBases'
import { NAME } from './constants'

export const getSelectedLawfulProcessingBasisId = state => state
.lawfulProcessingBasis[NAME].selectedLawfulProcessingBasisId
export const getSearchTerm = state => state.lawfulProcessingBasis[NAME].searchTerm

export const selectedLawfulProcessingBasis = createSelector(
  [ lawfulProcessingBases.selectors.getLawfulProcessingBases, getSelectedLawfulProcessingBasisId ],
  (lawfulProcessingBases, selectedLawfulProcessingBasisId) => {
    if (lawfulProcessingBases && lawfulProcessingBases.length > 0) {
      const index = lawfulProcessingBases
      .findIndex(lawfulProcessingBasis =>
         parseInt(lawfulProcessingBasis.id) === parseInt(selectedLawfulProcessingBasisId)
       )
      return lawfulProcessingBases[index]
    }
  }
)
