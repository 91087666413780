import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import emailMapSelect from '../modules/emailMapSelect'
import editEmailMap from '../modules/editEmailMap'

function mapStateToProps (state) {
  return {
    loading: emailMapSelect.selectors.getLoading(state),
    emailMaps: emailMapSelect.selectors.getEmailMaps(state),
    pageCount: emailMapSelect.selectors.getPageCount(state),
    currentPage: emailMapSelect.selectors.getCurrentPage(state),
    selectedEmailMapId: emailMapSelect.selectors.getSelectedEmailMapId(state),
    searchTerm: emailMapSelect.selectors.getSearchTerm(state),
    errors:  emailMapSelect.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchEmailMapsForPageNumber: emailMapSelect.actions.fetchEmailMapsForPageNumber,
    selectEmailMapId: emailMapSelect.actions.selectEmailMapId,
    changeShowing: editEmailMap.actions.changeShowing,
    setSearchTerm: emailMapSelect.actions.setSearchTerm
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(emailMapSelect.components.ListEmailMapsView)
