import { request, GET, PUT, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';

import colors from './colors';

import 'moment-timezone';
import moment from 'moment';
import { completeCall, createContactCallForLead } from 'modules/api/calls';
import { completeAppointment, createAppointment } from 'modules/api/appointments';

export function selectEventId(eventId) {
  return {
    type: actionTypes.EVENT_SELECTED,
    eventId: eventId,
  };
}

export function setDate(date) {
  return {
    type: actionTypes.SET_DATE,
    date,
  };
}

export function setView(view) {
  return {
    type: actionTypes.SET_VIEW,
    view,
  };
}

export function loadAllEventsForUsersInDateRange(startDate, endDate, timezone, users, locationIds, showCompleted, types) {
  const userIds = users.map((user) => user.id).sort();
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      const events = convertToEvents(payload.calendars, timezone, userIds);
      dispatch(setEvents(events));
    }

    var query =
      'calendar_for_users?start_date=' +
      startDate +
      '&end_date=' +
      endDate +
      '&' +
      paramStringWithName('location_ids', locationIds) +
      paramStringWithName('user_ids', userIds);

    if (types) {
      query = query + paramStringWithName('types', types);
    }
    if (showCompleted !== null) {
      query = query + '&is_completed=' + showCompleted;
    }
    return request(query, GET, null, onSuccess, onFailure);
  };
}

function paramStringWithName(name, values) {
  let string = '';
  values.forEach((value) => {
    string = string + name + '[]=' + value + '&';
  });
  return string;
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setEvents(events) {
  return {
    type: actionTypes.SET_EVENTS,
    events,
  };
}

// contact calls
export function convertToEvents(events, timezone, userIds) {
  return events.map((event) => {
    const startDate = moment(event.calendar_start).tz(timezone);

    const endDate = moment(event.calendar_end).tz(timezone);
    const index = userIds.findIndex((id) => id === event.calendar_user.id);

    return {
      id: event.calendar_id,
      lead: event.calendar_lead,
      serverid: event.calendar_class_id,
      start: startDate.toDate(),
      end: endDate.toDate(),
      title: event.calendar_title,
      completed: event.calendar_completed,
      type: event.calendar_type,
      user: event.calendar_user,
      userColor: colors[index],
      outcome: event.calendar_outcome,
      successful: event.is_successful,
    };
  });
}

export function addEvents(events) {
  return {
    type: actionTypes.ADD_EVENTS,
    events,
  };
}

export function updateEvent(event) {
  return {
    type: actionTypes.UPDATE_EVENT,
    event,
  };
}

export function removeEventWithId(eventId) {
  return {
    type: actionTypes.DELETE_EVENT,
    eventId,
  };
}

// UPDATE/DELETE
export const deleteEvent = (event) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.hideModal());
      dispatch(modal.actions.stopLoading());
      dispatch(removeEventWithId(event.id));
      notify.success('Event deleted');
    }

    return request(generateQueryUrlForTask(event), DELETE, null, onSuccess, onFailure);
  };
};

export const updateEventTime = (task, newStartTime, timezone) => {
  return (dispatch) => {
    function onFailure(payload) {
      notify.error('Event failed to update');
    }

    function onSuccess(payload) {
      const newTask = generateUpdatedEventForTask(task, newStartTime, payload, timezone);
      dispatch(updateEvent(newTask));
      notify.success('Event updated');
    }

    return request(generateQueryUrlForTask(task), PUT, generateBodyForTask(task, newStartTime), onSuccess, onFailure);
  };
};

function generateQueryUrlForTask(task) {
  switch (task.type) {
    case 'personal task':
      return 'personal_tasks/' + task.serverid;
    case 'appointment':
      return 'leads/' + task.lead.id + '/appointments/' + task.serverid;
    case 'contact call':
      return 'leads/' + task.lead.id + '/contact_calls/' + task.serverid;
    default:
      return 'leads/' + task.lead.id + '/lead_actions/' + task.serverid;
  }
}

function generateBodyForTask(task, newStartTime) {
  let modelName = '';
  let timeName = '';
  switch (task.type) {
    case 'personal task':
      modelName = 'personal_task';
      timeName = 'start_time';
      break;
    case 'appointment':
      modelName = 'appointment';
      timeName = 'due_date';
      break;
    case 'contact call':
      modelName = 'contact_call';
      timeName = 'due_date';
      break;
    default:
      modelName = 'lead_action';
      timeName = 'due_date';
      break;
  }
  return {
    [modelName]: {
      [timeName]: newStartTime,
    },
  };
}

function generateUpdatedEventForTask(task, newStartTime, payload, timezone) {
  let payloadTask;
  switch (task.type) {
    case 'personal task':
      payloadTask = payload.personal_task;
      break;
    case 'appointment':
      payloadTask = payload.appointment;
      break;
    case 'contact call':
      payloadTask = payload.contact_call;
      break;
    default:
      payloadTask = payload.lead_action;
      break;
  }

  const startDate = moment(newStartTime).tz(timezone);
  const endDate = startDate.clone();
  endDate.add(payloadTask.length, 'minutes');
  return { ...task, start: startDate.toDate(), end: endDate.toDate() };
}

export const toggleCallComplete = (event, outcome, note) => {
  return async (dispatch) => {
    const completedOn = event.completed_on ? null : moment().format();
    try {
      const { contact_call } = await completeCall(event.lead.id, event.serverid, outcome, completedOn, note);

      const updatedEvent = {
        ...event,
        completed: contact_call.completed,
        outcome: contact_call.outcome,
        successful: contact_call.is_successful
      };
      dispatch(updateEvent(updatedEvent));
      notify.success('Call updated');
    } catch (err) {
      notify.error('Call failed to update');
    }
  };
};

export const toggleAppointmentComplete = (event, outcome, note, visitType) => {
  return async (dispatch) => {
    try {
      const { appointment } = await completeAppointment(event.lead.id, event.serverid, outcome, visitType, note);
      const updatedEvent = {
        ...event,
        completed: appointment.completed,
        outcome: appointment.outcome,
        successful: appointment.is_successful
      };
      dispatch(updateEvent(updatedEvent));
      notify.success('Appointment updated');
    } catch (error) {
      notify.error('Appointment failed to update');
    }
  };
};

export function scheduleCall(eventTypes, usersIds, timezone, lead, dueDate, listUpdateProtocol, length) {
  return async (dispatch) => {
    dispatch(modal.actions.startLoading());

    try {
      const { calenderable } = await createContactCallForLead(lead.id, dueDate, length, true);

      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());

      if (eventTypes.includes('contact_calls')) {
        const events = convertToEvents([calenderable], timezone, usersIds);
        dispatch(addEvents(events));
      }
      notify.success('Call scheduled!');
    } catch (errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }
  };
}

export const scheduleAppointment = (
  eventTypes,
  timezone,
  usersIds,
  lead,
  dueDate,
  serviceClassId,
  serviceString,
  visitType,
  length,
  integrationName
) => {
  return async (dispatch) => {
    let formattedIntegrationName = '';
    if (integrationName === 'mindbody') formattedIntegrationName = 'MINDBODY';
    if (integrationName === 'glofox') formattedIntegrationName = 'Glofox';

    dispatch(modal.actions.startLoading());

    try {
      const { calenderable, meta } = await createAppointment(lead.id, dueDate, serviceClassId, serviceString, visitType, length, true);
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      if (eventTypes.includes('appointments')) {
        const events = convertToEvents([calenderable], timezone, usersIds);
        if (!events) throw new Error('Can not get events');
        dispatch(addEvents(events));
        notify.success('Appointment scheduled');
        if (meta?.syncing_class === true) {
          notify.info(`Booking into ${formattedIntegrationName} class`);
        }
      }
    } catch (error) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors([error]));
    }
  };
};
