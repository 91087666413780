import PropTypes from "prop-types";
import React, { Component } from "react";
import FunnelStepsWithoutLabel from "./FunnelStepsWithoutLabel";
import FunnelStepsWithLabel from "./FunnelStepsWithLabel";

export class LeadSalesFunnelSteps extends Component {
  stepSelected = step => {
    const { moveLeadToStep, lead, listUpdateProtocol } = this.props;
    if (!moveLeadToStep) {
      return;
    }
    moveLeadToStep(lead, lead.sales_funnel_step, step, listUpdateProtocol);
  };

  render() {
    const { salesFunnelSteps, lead } = this.props;
    const currentStepNumber = lead.sales_funnel_step.step_number;
    const showTitles = this.props.showTitles || false;

    return showTitles ? (
      <FunnelStepsWithLabel
        salesFunnelSteps={salesFunnelSteps}
        currentStepNumber={currentStepNumber}
        onStepSelected={this.stepSelected}
      />
    ) : (
      <FunnelStepsWithoutLabel
        salesFunnelSteps={salesFunnelSteps}
        currentStepNumber={currentStepNumber}
      />
    );
  }
}

LeadSalesFunnelSteps.propTypes = {
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  listUpdateProtocol: PropTypes.object,
  moveLeadToStep: PropTypes.func,
  showTitles: PropTypes.bool
};

export default LeadSalesFunnelSteps;
