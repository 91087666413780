import React, { FC } from 'react';

import { Box, Text, Button } from 'modules/components';

interface ToastButtonViewProps {
  title: string;
  buttonTitle: string;
  onClick: () => void;
}

export const ToastButtonView: FC<ToastButtonViewProps> = (props) => {
  const { title, buttonTitle, onClick } = props;
  return (
    <Box>
      <Text.P2>{title}</Text.P2>
      <Button.Primary1 onClick={onClick}>{buttonTitle}</Button.Primary1>
    </Box>
  );
};
