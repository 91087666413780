import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import appointments from '../modules/appointments'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    loading: appointments.selectors.getLoading(state),
    data: appointments.selectors.getData(state),
    errors: appointments.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    calculatingOption: filters.selectors.getCalculatingOption(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: appointments.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(appointments.components.AppointmentsView)
