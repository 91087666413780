import React, { Component } from 'react'

import SubscribeContainer from '../containers/SubscribeContainer'

import { InfoView } from 'modules/components'

export class CalendarSetingsView extends Component {

  render () {
    const infoString = 'Set up your calendar on your mobile, tablet or computer'
    return (
      <div>
        <InfoView colorThemeClass={'info'} title={'Your Calendar'} detail={infoString} />
        <SubscribeContainer />
      </div>
    )
  }
}

CalendarSetingsView.propTypes = {

}

export default CalendarSetingsView
