/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { QuickInfoBlock } from "modules/components";

export class SummaryView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString } = this.props;
    fetchWithQueryString(queryString);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      const { fetchWithQueryString, queryString } = nextProps;
      fetchWithQueryString(queryString);
    }
  }

  render() {
    const { loading, data } = this.props;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";
    return (
      <div className="row">
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={"Leads assigned"}
            number={data.leads_assigned.current}
            loading={loading}
          />
        </div>
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={"Leads closed"}
            number={data.leads_closed.current}
            loading={loading}
          />
        </div>
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={"Close rate"}
            number={data.close_rate.current * 100}
            loading={loading}
            suffix={"%"}
          />
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className={blockStyle}>
            <div className="block-content block-content-full">
              <div className="font-s12 font-w700">Top deal</div>
              <a
                className="h2 font-w300 text-primary"
                onClick={this.handleSelect}
              >
                {data.top_deal}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SummaryView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string,
};

export default SummaryView;
