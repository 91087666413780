import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectHeardAboutMethodTableRow extends Component {
  rowSelected = () => {
    const { heardAboutMethod, onHeardAboutMethodSelect } = this.props;
    onHeardAboutMethodSelect(heardAboutMethod);
  };

  render() {
    const { heardAboutMethod, heardAboutMethodSelected } = this.props;
    const className = heardAboutMethodSelected ? "selected" : "selectable";
    return (
      <tr
        className={className}
        onClick={() => this.rowSelected(heardAboutMethod)}
      >
        <td>{heardAboutMethod.heard_about_title}</td>
      </tr>
    );
  }
}

SelectHeardAboutMethodTableRow.propTypes = {
  heardAboutMethod: PropTypes.object.isRequired,
  heardAboutMethodSelected: PropTypes.bool.isRequired,
  onHeardAboutMethodSelect: PropTypes.func.isRequired
};
