import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PaymentMethodRowContainer from '../containers/PaymentMethodRowContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

import CreditCardModalContainer from '../../../containers/CreditCardModalContainer'
import { PAYMENT_METHOD_MODAL_NAME } from '../../creditCardModal/constants'

export class PaymentMethodsView extends Component {

  state = {
    cardIdToReplace: null,
    cardIdToDelete: null,
    showWarning: false
  }

  updateCard = (card) => {
    this.setState({ ...this.state, cardIdToReplace: card.id })
    this.props.showModal(PAYMENT_METHOD_MODAL_NAME)
  }

  deleteClicked = (card) => {
    this.setState({ ...this.state, showWarning: true, cardToDelete: card.id })
  }

  deletePaymentMethod = () => {
    this.props.deletePaymentMethodId(this.state.cardToDelete)
    this.dismissPopups()
  }

  addCreditCard = () => {
    this.setState({ ...this.state, cardIdToReplace: null })
    this.props.showModal(PAYMENT_METHOD_MODAL_NAME)
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showWarning: false })
  }

  render () {
    const paymentMethods = this.props.paymentMethods.map((paymentMethod, index) => {
      return <PaymentMethodRowContainer
        key={index}
        paymentMethod={paymentMethod}
        updateClicked={this.updateCard}
        deleteClicked={this.deleteClicked}
        canDelete={false} />
    })

    return (
      <div>
        {paymentMethods}
        <div className='push-10-t'>
          <button className='btn btn-default' onClick={this.addCreditCard}>Add new card</button>
        </div>
        <hr />
        <CreditCardModalContainer modalName={PAYMENT_METHOD_MODAL_NAME} cardIdToReplace={this.state.cardIdToReplace} />
        {this.state.showWarning &&
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='warning'
            title='Are you Sure?'
            confirmBtnText='Delete payment method'
            onConfirm={this.deletePaymentMethod}
            onCancel={this.dismissPopups}>
            Are you sure you want to delete this payment method?
          </SweetAlert>
        }
      </div>
    )
  }
}

PaymentMethodsView.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  deletePaymentMethodId: PropTypes.func.isRequired
}

export default PaymentMethodsView
