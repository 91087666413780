export function titleForEvent(event) {
  switch (event.event) {
    case 'accept':
      return 'Email accepted by ESP';
    case 'delivery':
      return 'Email successfully delivered to the inbox';
    case 'bounce':
      return 'Email bounced, email address could not be found';
    case 'drop':
      return 'The email was dropped, likely due to a full inbox';
    case 'open':
      return 'The email was opened';
    case 'click':
      return 'A link in the email was clicked';
    case 'complaint':
      return 'The lead complained that this email is spam';
    case 'unsubscribe':
      return 'The lead opened the email and unsubscribed';
    default:
      return 'An unkown event occured';
  }
}

export function iconStyle(event) {
  switch (event) {
    case 'accept':
      return 'si si-envelope';
    case 'delivery':
      return 'fa fa-check';
    case 'bounce':
      return 'fa fa-times';
    case 'drop':
      return 'fa fa-times';
    case 'open':
      return 'si si-eyeglasses';
    case 'click':
      return 'fa fa-hand-pointer-o';
    case 'complaint':
      return 'fa fa-warning';
    case 'unsubscribe':
      return 'si si-close';
    default:
      return 'si si-envelope';
  }
}

export function bgStyle(event) {
  switch (event) {
    case 'accept':
      return 'bg-default-light';
    case 'delivery':
      return 'bg-default-dark';
    case 'bounce':
      return 'bg-danger';
    case 'drop':
      return 'bg-danger';
    case 'open':
      return 'bg-success';
    case 'click':
      return 'bg-success';
    case 'complaint':
      return 'bg-danger';
    case 'unsubscribe':
      return 'bg-danger';
    default:
      return 'bg-default';
  }
}
