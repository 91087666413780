import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import LocationSelectView from 'modules/components/LocationSelect/components/LocationSelectView';

export class LocationSelectModal extends Component {
  render() {
    const { currentModal, modalName, hideModal } = this.props;

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select clubs
            </h3>
          </div>
          <div className="block-content">
            <LocationSelectView {...this.props} />
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

LocationSelectModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  title: PropTypes.string,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedLocationId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
  showSaleDetails: PropTypes.bool,
};

export default LocationSelectModal;
