import { connect } from 'react-redux'
import { BillingView } from '../components/BillingView'

import subscription from '../modules/subscription'

function mapStateToProps (state) {
  return {
    billingPlan: subscription.selectors.getBillingPlan(state)
  }
}

export default connect(mapStateToProps)(BillingView)
