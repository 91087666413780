import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ChargesView from '../components/ChargesView'

import session from 'modules/Stores/Session'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    charges: selectors.getCharges(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    pageCount: selectors.getPageCount(state),
    currentPage: selectors.getCurrentPage(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getTextMessageChargesForSmsAccountAndPage: actions.getTextMessageChargesForSmsAccountAndPage
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargesView)
