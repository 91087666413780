import { connect } from 'react-redux'
import { SalesPerformanceReportView } from '../components/SalesPerformanceReportView'

function mapStateToProps (state) {
  return {
    queryString: null
  }
}

export default connect(mapStateToProps)(SalesPerformanceReportView)
