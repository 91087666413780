import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.dashboard[NAME].errors
export const getLoading = state => state.dashboard[NAME].loading
export const getCalls = state => state.dashboard[NAME].calls
export const getSelectedCallId = state => state.dashboard[NAME].selectedCallId
export const getPageCount = state => state.dashboard[NAME].pageCount
export const getCurrentPage = state => state.dashboard[NAME].currentPage
export const getTotalCount = state => state.dashboard[NAME].totalCount

export const selectedCall = createSelector(
  [ getCalls, getSelectedCallId ],
  (calls, callId) => {
    const index = calls.findIndex(call => parseInt(call.id) === parseInt(callId))
    return calls[index]
  }
)
