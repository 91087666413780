import * as actionTypes from './actionTypes'
import { SHOW_ALL } from './constants'

const LOCATION_SELECT_ACTION_HANDLERS = {
  [actionTypes.LOCATION_SELECTED]: (state, action) => {
    return {
      ...state,
      selectedLocationId: action.location.id,
      selectedLocationName: action.location.admin_display_name
    }
  },
  [actionTypes.SET_SHOW_COMPLETED_OPTION]: (state, action) => {
    return { ...state, showCompletedOption: action.showCompletedOption }
  }
}

const initialState = {
  selectedLocationId: null,
  selectedLocationName: null,
  showCompletedOption: SHOW_ALL
}

export default function reducer (state = initialState, action) {
  const handler = LOCATION_SELECT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
