import { generateStore } from "utils/reduxHelpers";

const name = "leadSources";

const initialState = {
  leadSources: [],
  loading: false,
  errors: null,
  pageDetails: {}
};

export default generateStore(name, initialState);
