import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LocationSelectView from '../modules/LocationSelect/components/LocationSelectView'

import session from 'modules/Stores/Session'
import userData from 'modules/Stores/UserData'
import locations from 'modules/Stores/Locations'
import locationSelect from '../modules/LocationSelect'

function mapStateToProps (state, ownProps) {
  return {
    company: userData.selectors.getCompany(state),
    user: session.selectors.getUser(state),
    isMultiLocationAccount: locations.selectors.getIsMultiLocationAccount(state),
    defaultLocation: locations.selectors.getDefaultLocation(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    locationSelected: locationSelect.actions.selectLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectView)
