import { combineReducers } from 'redux'
import getAutoAssignRule from './modules/getAutoAssignRule'
import currentAutoAssignRule from './modules/currentAutoAssignRule'
import createAutoAssignRule from './modules/createAutoAssignRule'

export const reducer = combineReducers({
  [getAutoAssignRule.constants.NAME]: getAutoAssignRule.reducer,
  [currentAutoAssignRule.constants.NAME]: currentAutoAssignRule.reducer,
  [createAutoAssignRule.constants.NAME]: createAutoAssignRule.reducer
})
