import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';

import customMailMergeTags from 'modules/Stores/CustomMailMergeTags';

export function setSelectedCustomMailMergeTagId(selectedCustomMailMergeTagId) {
  return {
    type: actionTypes.SET_SELECTED_CUSTOM_MAIL_MERGE_TAG_ID,
    selectedCustomMailMergeTagId,
  };
}

export function prefillDataForCustomMailMergeTag(customMailMergeTag) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_CUSTOM_MAIL_MERGE_TAG,
    customMailMergeTag,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export const deleteCustomMailMergeTag = (customMailMergeTagId, toCustomMailMergeTagId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toCustomMailMergeTagId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess(payload) {
      dispatch(setSelectedCustomMailMergeTagId(null));
      dispatch(prefillDataForCustomMailMergeTag(null));
      dispatch(modal.actions.stopLoading());
      dispatch(customMailMergeTags.actions.deleteCustomMailMergeTagWithId(customMailMergeTagId));
      notify.success('CustomMailMergeTag Migrated');
    }
    const url = 'customMailMergeTags/' + customMailMergeTagId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};
