import { request, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';
import * as listingActions from '../../../listing/actions';

import notify from 'modules/Notifications';
import { trackEvent } from 'modules/Helpers/Analytics';

import modal from 'modules/Stores/Modal';

export const addOrUpdateOptionWithData = (formData, location, name, direction) => {
  return (dispatch) => {
    const body = {
      third_party_integration: seralizeOptionFromFormData(formData, name, direction),
    };
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(reset());

      if (formData.fields.id.value) {
        dispatch(listingActions.updateIntegration(payload.third_party_integration));
        notify.success('Integration Updated');
      } else {
        dispatch(listingActions.addIntegration(payload.third_party_integration));
        notify.success('Integration Set Up');
        trackEvent('Integration Set Up', {
          integration: payload.third_party_integration.name,
        });
      }
    }

    if (formData.fields.id.value) {
      return request('locations/' + location.id + '/third_party_integrations/' + formData.fields.id.value, PUT, body, onSuccess, onFailure);
    } else {
      return request('locations/' + location.id + '/third_party_integrations', POST, body, onSuccess, onFailure);
    }
  };
};

export const deleteIntegration = (formData, location) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(listingActions.deleteIntegration(formData.fields.id.value));
      dispatch(reset());
      notify.success('Integration Removed');
      trackEvent('Integration Removed');
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + formData.fields.id.value,
      DELETE,
      null,
      onSuccess,
      onFailure
    );
  };
};

export function onInput(data) {
  return {
    type: actionTypes.ON_INPUT,
    data,
  };
}

export function prefillFormForIntegration(integration) {
  return {
    type: actionTypes.PREFIL_FORM,
    integration,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

function seralizeOptionFromFormData(formData, name, direction) {
  const { username, companyName, customUrl, password, apiKey, integrationLocationId } = formData.fields;

  let payload = { name: name, direction: direction };
  payload = newPayloadForFormValue(payload, username, 'username');
  payload = newPayloadForFormValue(payload, password, 'password');
  payload = newPayloadForFormValue(payload, companyName, 'company_name');
  payload = newPayloadForFormValue(payload, customUrl, 'custom_url');
  payload = newPayloadForFormValue(payload, integrationLocationId, 'integration_location_id');
  payload = newPayloadForFormValue(payload, apiKey, 'api_key');

  return payload;
}
