import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { errorPanelForErrors } from 'utils/formHelpers';

import CreateLocationForm from './CreateLocationForm';

import { InfoView } from 'modules/components';

import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-bootstrap-modal';

export class CreateLocationModalView extends Component {
  state = {
    showWarning: false,
  };

  createLocation = () => {
    const { createLocationForCompany, data, paymentData } = this.props;
    createLocationForCompany(data, paymentData);
    this.dismissPopups();
  };

  cleanUpAndClose = () => {
    const { resetForm, hideModal } = this.props;
    this.dismissPopups();
    hideModal();
    resetForm();
  };

  showWarningPopup = () => {
    this.setState({ ...this.state, showWarning: true });
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showWarning: false });
  };

  render() {
    const { company, loading, currentModal, modalName, errors } = this.props;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const warningString = (
      <span>
        Adding a club will increase your monthly bill. Visit{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://support.gymleads.net/pricing-privacy-and-terms/how-does-the-pricing-work-for-gymleads"
        >
          this link
        </a>{' '}
        For more details
      </span>
    );
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanUpAndClose}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanUpAndClose}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                New Location For {company.title}
              </h3>
            </div>
            <div className="block-content">
              <InfoView colorThemeClass={'warning'} title={'This will cost you money'} detail={warningString} />
              <form className="form-horizontal push-5-t">
                {errorPanelForErrors(errors)}
                <CreateLocationForm
                  onInput={this.props.onInput}
                  data={this.props.data}
                  onPaymentInput={this.props.onPaymentInput}
                  paymentData={this.props.paymentData}
                  countries={this.props.countries}
                  paymentMethods={this.props.paymentMethods}
                />
              </form>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndClose}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r push-10"
              type="button"
              disabled={this.props.isValid !== true}
              onClick={this.showWarningPopup}
            >
              <i className="fa fa-plus" /> Create Location
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showWarning && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="warning"
            confirmBtnText="Add new club"
            title="Are you Sure?"
            onConfirm={this.createLocation}
            onCancel={this.dismissPopups}
          >
            This will add a new location to your account and increase your monthly bill. Are you sure?
          </SweetAlert>
        )}
      </div>
    );
  }
}

CreateLocationModalView.propTypes = {
  company: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  createLocationForCompany: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPaymentInput: PropTypes.func.isRequired,
  paymentData: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentModal: PropTypes.string,
};

export default CreateLocationModalView;
