import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SalesFunnelView } from '../components/SalesFunnelView'

import * as actions from '../actions'

import userData from 'modules/Stores/UserData'

import { getLoading, getData, getErrors } from '../selectors'

function mapStateToProps (state) {
  return {
    salesFunnel: userData.selectors.getSalesFunnel(state),
    loading: getLoading(state),
    data: getData(state),
    errors: getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSalesFunnelWithData: actions.updateSalesFunnelWithData,
    updateData: actions.updateData,
    prefillFormForSalesFunnelSteps: actions.prefillFormForSalesFunnelSteps
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesFunnelView)
