import PropTypes from "prop-types";
import React, { Component } from "react";

import { UserRoleTag } from "modules/components";

export class SelectToUserRow extends Component {
  rowSelected = () => {
    const { user, userSelected, selectUser, deselectUser } = this.props;
    if (userSelected) {
      deselectUser(user.id);
    } else {
      selectUser(user.id);
    }
  };

  render() {
    const { user, userSelected } = this.props;
    return (
      <tr className="selectable" onClick={() => this.rowSelected()}>
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input type="checkbox" disabled checked={userSelected} readOnly />
            <span />
          </label>
        </td>
        <td>{user.display_name}</td>
        <td>
          <UserRoleTag userRole={user.user_role} />
        </td>
      </tr>
    );
  }
}

SelectToUserRow.propTypes = {
  user: PropTypes.object.isRequired,
  userSelected: PropTypes.bool.isRequired,
  selectUser: PropTypes.func.isRequired,
  deselectUser: PropTypes.func.isRequired,
};

export default SelectToUserRow;
