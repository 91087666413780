/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import "./../LeadDetail.css";
import PhoneOptionsModalContainer from "../../containers/PhoneOptionsModalContainer";
import SendLeadEmailModalContainer from "../../containers/SendLeadEmailModalContainer";
import { LEAD_PHONE_MODAL, SEND_EMAIL_MODAL } from "../../constants";
import { readableMobileNumber } from "modules/Helpers/FormatHelpers";

import RawToolTip from "modules/components/ToolTip/RawToolTip";

export default class LeadContactPanel extends Component {
  callLead = (event) => {
    if (this.props.lead.mobile_number) {
      this.props.showModal(LEAD_PHONE_MODAL);
    }
  };

  emailLead = (event) => {
    if (this.props.lead.email) {
      this.props.showModal(SEND_EMAIL_MODAL);
    }
  };

  render() {
    const { lead } = this.props;
    const numberTextStyle = lead.mobile_number ? "" : "text-muted";
    const emailTextStyle = lead.email ? "" : "text-muted";
    const numberText =
      readableMobileNumber(lead.mobile_number) || "Ask for a phone number";
    const emailText = lead.email || "Ask for an email";
    return (
      <div className="list-group">
        <a className="list-group-item selectable" onClick={this.callLead}>
          <i className="si si-call-out push-5-r" />
          <span className={numberTextStyle}>{numberText}</span>
          {lead.sms_bounced && (
            <span>
              {" "}
              <i
                className="si si-close push-5-r text-danger"
                data-tip
                data-for={"sms-warning"}
              />
              <RawToolTip
                id={"sms-warning"}
                text={
                  "This number has bounced or dropped when we tried to send an SMS"
                }
              />
            </span>
          )}
        </a>
        <a className="list-group-item selectable" onClick={this.emailLead}>
          <i className="si si-envelope push-5-r" />
          <span className={emailTextStyle}>{emailText}</span>
          {lead.email_bounced && (
            <span>
              {" "}
              <i
                className="si si-close push-5-r text-danger"
                data-tip
                data-for={"email-warning"}
              />
              <RawToolTip
                id={"email-warning"}
                text={"This email has bounced or dropped"}
              />
            </span>
          )}
        </a>
        <PhoneOptionsModalContainer modalName={LEAD_PHONE_MODAL} lead={lead} />
        <SendLeadEmailModalContainer modalName={SEND_EMAIL_MODAL} lead={lead} />
      </div>
    );
  }
}

LeadContactPanel.propTypes = {
  lead: PropTypes.object,
  company: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
};
