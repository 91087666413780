import { connect } from 'react-redux'

import ReallocationRow from '../components/ReallocationRow'

import session from 'modules/Stores/Session'

function mapStateToProps (state, props) {
  return {
    timezone: session.selectors.getUserTimezone(state)
  }
}

export default connect(mapStateToProps)(ReallocationRow)
