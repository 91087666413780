import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const fetchWithQueryString = (queryString) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setErrors(errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setData(payload));
      dispatch(setLoading(false));
    }
    let url = `time_taken_reports/activity_breakdown`;
    if (queryString) {
      url = `${url}?${encodeURI(queryString)}`;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setData(data) {
  return {
    type: actionTypes.SET_DATA,
    data,
  };
}
