import PropTypes from "prop-types";
import React, { Component } from "react";
import header from "../modules/header";
import { ReportSelectView } from "./ReportSelectView";

import ActivityReportHeaderContainer from "../containers/ActivityReportHeaderContainer";
import FiltersContainer from "../containers/FiltersContainer";
import contactCalls from "../modules/contactCalls";
import appointments from "../modules/appointments";
import tasks from "../modules/tasks";

const {
  CONTACT_CALL_REPORT,
  APPOINTMENT_REPORT,
  TASK_REPORT,
} = header.constants;
export class ActivityReportView extends Component {
  selectedReportElement() {
    switch (this.props.selectedReport) {
      case CONTACT_CALL_REPORT:
        return <contactCalls.ContactCallsContainer />;
      case APPOINTMENT_REPORT:
        return <appointments.AppointmentsContainer />;
      case TASK_REPORT:
        return <tasks.TasksContainer />;
      default:
        return <div />;
    }
  }

  render() {
    const {
      selectedReport,
      onReportChange,
      exportContactCalls,
      exportAppointment,
      exportTasks,
    } = this.props;
    return (
      <div className="content push-100">
        <div className="row">
          <div className="col-xs-12">
            <ActivityReportHeaderContainer />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FiltersContainer />
          </div>
        </div>
        <ReportSelectView
          selectedReport={selectedReport}
          onReportChange={onReportChange}
          exportContactCalls={exportContactCalls}
          exportAppointment={exportAppointment}
          exportTasks={exportTasks}
        />
        <div className="row">
          <div className="col-xs-12">{this.selectedReportElement()}</div>
        </div>
      </div>
    );
  }
}

ActivityReportView.propTypes = {
  selectedReport: PropTypes.string.isRequired,
  onReportChange: PropTypes.func,
  exportContactCalls: PropTypes.func.isRequired,
  exportAppointment: PropTypes.func.isRequired,
  exportTasks: PropTypes.func.isRequired,
};

ActivityReportView.defaultProps = {
  selectedReport: CONTACT_CALL_REPORT,
};
