import * as actionTypes from './actionTypes'

const LOST_LEADS_SUMMARY_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, data: action.data }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  }
}

const initialState = {
  errors: null,
  loading: false,
  data: {
    leads_assigned: { current: 0, change: 0 },
    leads_lost: { current: 0, change: 0 },
    lead_lost_rate: { current: 0, change: 0 },
    top_reason: 'Loading'
  }
}

export default function reducer (state = initialState, action) {
  const handler = LOST_LEADS_SUMMARY_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
