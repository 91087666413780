import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MultiUserSelectModal from '../components/MultiUserSelectModal'

import * as actions from '../actions'
import * as selectors from '../selectors'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  const userIds = (props.selectedUserIds) || selectors.getSelectedUserIds(state)
  return {
    selectedUserIds: userIds,
    users: selectors.getUsers(state),
    pageDetails: selectors.getPageDetails(state),
    loading: selectors.getLoading(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch, props) {
  const setUserIds = (props.setSelectedUserIds) || actions.setSelectedUserIds
  const addUserId = (props.addUserId) || actions.addUserId
  const removeUserId = (props.removeUserId) || actions.removeUserId
  return bindActionCreators({
    get: actions.get,
    hideModal: modal.actions.hideModal,
    setSelectedUserIds: setUserIds,
    addUserId: addUserId,
    removeUserId: removeUserId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiUserSelectModal)
