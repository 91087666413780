import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';

import trials from 'modules/Stores/Trials';

export function setSelectedTrialId(selectedTrialId) {
  return {
    type: actionTypes.SET_SELECTED_TRIAL_ID,
    selectedTrialId,
  };
}

export function prefillDataForTrial(trial) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_TRIAL,
    trial,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export const deleteTrial = (trialId, toTrialId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toTrialId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess(payload) {
      dispatch(setSelectedTrialId(null));
      dispatch(prefillDataForTrial(null));
      dispatch(modal.actions.stopLoading());
      dispatch(trials.actions.deleteTrialWithId(trialId));
      notify.success('Trial Migrated');
    }
    const url = 'trials/' + trialId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};
