import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TrialsView from '../components/TrialsView'

import trials from 'modules/Stores/Trials'
import modal from 'modules/Stores/Modal'

import * as localActions from '../actions'
import * as localSelectors from '../selectors'

function mapStateToProps (state) {
  return {
    trials: trials.selectors.getTrials(state),
    selectedTrial: localSelectors.selectedTrial(state),
    selectedTrialId: localSelectors.getSelectedTrialId(state),
    loading: trials.selectors.getLoading(state),
    errors: trials.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    prefillDataForTrial: localActions.prefillDataForTrial,
    getTrials: trials.actions.getTrials,
    showModal: modal.actions.showModal,
    setSelectedTrialId: localActions.setSelectedTrialId
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TrialsView)
