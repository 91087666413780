/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export class PageSelection extends Component {
  previousPageClicked = (event) => {
    event.preventDefault();
    const { currentPage } = this.props;
    if (currentPage === 1) return;

    const previousPage = currentPage - 1;
    this.pageClicked(previousPage);
  };

  nextPageClicked = (event) => {
    event.preventDefault();
    const { pageCount, currentPage } = this.props;
    if (currentPage === pageCount) return;

    const nextPage = currentPage + 1;
    this.pageClicked(nextPage);
  };

  pageClicked = (pageNumber) => {
    const { currentPage, pageClicked } = this.props;
    if (pageNumber === currentPage) return;

    pageClicked(pageNumber);
  };

  generatePageLinks = (firstPage, lastPage) => {
    const { currentPage } = this.props;
    const pages = [];
    for (let page = firstPage; page <= lastPage; page++) {
      const className = page === currentPage ? "active" : "selectable";
      pages.push(
        <li key={page} className={className}>
          <a onClick={() => this.pageClicked(page)}>{page}</a>
        </li>
      );
    }
    return pages;
  };

  generatePageLink = (page) => {
    const { currentPage } = this.props;
    const className = page === currentPage ? "active" : "selectable";
    return (
      <li key={page} className={className}>
        <a onClick={() => this.pageClicked(page)}>{page}</a>
      </li>
    );
  };

  pageLinks = () => {
    // The logic in this function was adapted from the react-paginate package
    // https://github.com/AdeleD/react-paginate

    const pages = [];
    const { currentPage, pageLimit, pageCount } = this.props;

    const marginPagesDisplayed = 1;
    let pageRangeDisplayed = pageLimit - marginPagesDisplayed * 2;
    pageRangeDisplayed = pageRangeDisplayed > 0 ? pageRangeDisplayed : 1;

    if (pageCount <= pageRangeDisplayed) {
      return this.generatePageLinks(1, pageCount);
    }

    let leftSide = pageRangeDisplayed / 2;
    let rightSide = pageRangeDisplayed - leftSide;

    if (currentPage > pageCount - pageRangeDisplayed / 2) {
      rightSide = pageCount - currentPage;
      leftSide = pageRangeDisplayed - rightSide;
    } else if (currentPage < pageRangeDisplayed / 2) {
      leftSide = currentPage;
      rightSide = pageRangeDisplayed - leftSide;
    }

    let page;
    let breakView;
    let createPageView = (page) => this.generatePageLink(page);

    for (page = 1; page <= pageCount; page++) {
      if (page <= marginPagesDisplayed) {
        pages.push(createPageView(page));
        continue;
      }

      if (page > pageCount - marginPagesDisplayed) {
        pages.push(createPageView(page));
        continue;
      }

      if (page >= currentPage - leftSide && page <= currentPage + rightSide) {
        pages.push(createPageView(page));
        continue;
      }

      if (pages[pages.length - 1] !== breakView) {
        let pageLink;
        if (currentPage < page) {
          const forwardJump = currentPage + pageRangeDisplayed;
          pageLink = forwardJump >= pageCount ? pageCount - 1 : forwardJump;
        } else {
          const backwardJump = currentPage - pageRangeDisplayed;
          pageLink = backwardJump < 1 ? 1 : backwardJump;
        }
        breakView = (
          <li
            key={page}
            className="selectable"
            onClick={() => this.pageClicked(pageLink)}
          >
            <span>...</span>
          </li>
        );
        pages.push(breakView);
      }
    }
    return pages;
  };

  render() {
    const { pageCount, currentPage } = this.props;

    const renderNextLink = () => {
      const className = currentPage === pageCount ? "disabled" : "selectable";
      return (
        <li className={className}>
          <a onClick={this.nextPageClicked}>
            <i className="fa fa-angle-double-right" />
          </a>
        </li>
      );
    };

    const renderPreviousLink = () => {
      const className = currentPage === 1 ? "disabled" : "selectable";
      return (
        <li className={className}>
          <a onClick={this.previousPageClicked}>
            <i className="fa fa-angle-double-left" />
          </a>
        </li>
      );
    };

    return (
      <nav>
        <ul className="pagination">
          {renderPreviousLink()}
          {this.pageLinks()}
          {renderNextLink()}
        </ul>
      </nav>
    );
  }
}

PageSelection.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageClicked: PropTypes.func.isRequired,
  pageLimit: PropTypes.number.isRequired,
};

PageSelection.defaultProps = {
  pageLimit: 10,
};

export default PageSelection;
