import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PagesView from 'modules/components/PageSelection/PagesView';
import { FormFields, TextSearchView } from 'modules/components';

import { EDIT_LEAD_LOST_REASON_MODAL, MIGRATE_LEAD_LOST_REASON_MODAL } from '../constants';
import EditLeadLostReasonModalContainer from '../containers/EditLeadLostReasonModalContainer';
import MigrateLeadLostReasonModalContainer from '../containers/MigrateLeadLostReasonModalContainer';
const { InlineTextField } = FormFields;

export class LeadLostReasons extends Component {
  state = {
    leadLostReasonToDelete: null,
    leadLostReasonText: null,
    selectedLeadLostReason: null,
  };

  UNSAFE_componentWillMount() {
    const { leadLostReasons } = this.props;
    if (leadLostReasons.length > 0) {
      return;
    }
    this.getLeadLostReasonsWithPageNumber(1);
  }

  getLeadLostReasonsWithPageNumber = (pageNumber) => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadLostReasonsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(1, searchTerm);
  };

  handleInput = (fieldName, textValue) => {
    this.setState({ ...this.state, leadLostReasonText: textValue });
  };

  addLeadLostReasonSelected = () => {
    const newLeadLostReasonText = this.state.leadLostReasonText;
    this.props.createLeadLostReason(newLeadLostReasonText);
    this.setState({ ...this.state, leadLostReasonText: null });
  };

  reasonClicked = (reason) => {
    this.setState({ ...this.state, selectedLeadLostReason: reason });
    this.props.showModal(EDIT_LEAD_LOST_REASON_MODAL);
  };

  migrateReasonClicked = (reason) => {
    this.setState({ ...this.state, leadLostReasonToDelete: reason });
    this.props.showModal(MIGRATE_LEAD_LOST_REASON_MODAL);
  };

  render() {
    const { canEdit } = this.props;
    const pageCount = this.props.pageDetails.pageCount;
    const reasons = this.props.leadLostReasons.map((leadLostReason, index) => {
      return (
        <div key={index} className="col-lg-4">
          <div
            className="block block-bordered selectable"
            style={{
              paddingTop: '5px',
              paddingLeft: '5px',
              paddingBottom: '5px',
              paddingRight: '5px',
            }}
          >
            <span onClick={() => this.reasonClicked(leadLostReason)}>{leadLostReason.lost_reason_title}</span>
            {canEdit && (
              <button className="btn btn-xs btn-default pull-right" type="button" onClick={() => this.migrateReasonClicked(leadLostReason)}>
                <i className="fa fa-times" />
              </button>
            )}
          </div>
        </div>
      );
    });

    const blockStyle = this.props.loading ? 'block-opt-refresh' : '';

    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Lost reasons</h3>
          </div>
          <div className="block-content bg-gray-lighter">
            <div className="row" style={{ marginTop: '-30px' }}>
              <TextSearchView sectionName={'leadLostReasonSearch'} onUpdate={this.searchUpdated} />
            </div>
          </div>
          <div className="block-content">
            <div className="row">{reasons}</div>
            <div className="row">
              {canEdit && (
                <>
                  <hr />
                  <div className="col-md-8">
                    <InlineTextField
                      name="leadLostReasonText"
                      label="New reason"
                      placeholder="Too expensive"
                      disabled={false}
                      value={this.state.leadLostReasonText}
                      formFieldStyle=""
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-success push-5-r push-10"
                      type="button"
                      disabled={!this.state.leadLostReasonText || this.state.leadLostReasonText.length === 0}
                      onClick={this.addLeadLostReasonSelected}
                    >
                      <i className="fa fa-plus" /> Add reason
                    </button>
                  </div>
                </>
              )}
            </div>
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageClicked={this.pageClicked} pageDetails={this.props.pageDetails} />
              </div>
            )}
          </div>
        </div>
        {this.state.selectedLeadLostReason && (
          <EditLeadLostReasonModalContainer modalName={EDIT_LEAD_LOST_REASON_MODAL} leadLostReason={this.state.selectedLeadLostReason} />
        )}
        {this.state.leadLostReasonToDelete && (
          <MigrateLeadLostReasonModalContainer
            modalName={MIGRATE_LEAD_LOST_REASON_MODAL}
            leadLostReason={this.state.leadLostReasonToDelete}
          />
        )}
      </div>
    );
  }
}

LeadLostReasons.propTypes = {
  leadLostReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  createLeadLostReason: PropTypes.func.isRequired,
  deleteLeadLostReason: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default LeadLostReasons;
