import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getProductName } from 'modules/Stores/UserData/selectors'

import locations from 'modules/Stores/Locations'
import session from 'modules/Stores/Session'
import modal from 'modules/Stores/Modal'

import teamMembers from '../modules/teamMembers'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.selectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    user: session.selectors.getUser(state),
    teamMembers: teamMembers.selectors.getTeam(state),
    loading: teamMembers.selectors.getLoading(state),
    location: selectedLocation,
    selectedUser: teamMembers.selectors.selectedUser(state),
    productName: getProductName(state),
    pageDetails: teamMembers.selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    selectUserId: teamMembers.actions.selectUserId,
    deleteTeamMember: teamMembers.actions.deleteTeamMember,
    updateTeamMemberUserRole: teamMembers.actions.updateTeamMemberUserRole,
    addTeamMembersToLocation: teamMembers.actions.addTeamMembersToLocation,
    getUsers: teamMembers.actions.getTeamForLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(teamMembers.components.TeamMembersView)
