import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class InvoiceLineItemRow extends Component {

  render () {
    const { invoiceLineItem, index } = this.props
    return (
      <tr>
        <td className='text-center'>{index}</td>
        <td>
          <p className='font-w600 push-10'>{invoiceLineItem.title}</p>
          {invoiceLineItem.detail &&
            <div className='text-muted'>{invoiceLineItem.detail}</div>
          }
        </td>
        <td className='text-center'><span className='badge badge-primary'>{invoiceLineItem.quantity}</span></td>
        <td className='text-right'>$ {invoiceLineItem.unit_amount.toFixed(2)}</td>
        <td className='text-right'>$ {invoiceLineItem.amount.toFixed(2)}</td>
      </tr>
    )
  }
}

InvoiceLineItemRow.propTypes = {
  invoiceLineItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
