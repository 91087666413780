import { request, PUT, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import { newPayloadForFormValue, newPayloadForValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

export const updateSalesTargetWithData = (location, salesTarget, SalesTargetData) => {
  return (dispatch) => {
    const salesTargetJSON = seralizeSalesTargetsFromFormData(SalesTargetData);
    const body = {
      target_rule_set: salesTargetJSON,
    };
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.target_rule_set));
      notify.success('Sales targets updated');
    }

    return request('target_rule_sets/' + salesTarget.id, PUT, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.UPDATING,
  };
}

function updateSuccessful(salesTarget) {
  return {
    type: actionTypes.UPDATE_SUCCESS,
    salesTarget,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function prefilSalesTargetsData(salesTargets) {
  return {
    type: actionTypes.PREFIL_TARGET_METRICS,
    salesTargets,
  };
}

export const deleteSalesTarget = (location, salesTarget, setSalesTarget) => {
  return (dispatch) => {
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.target_rule_set));
      dispatch(setSalesTarget(null));
      notify.success('Sales target deleted');
    }

    return request('target_rule_sets/' + salesTarget.id, DELETE, null, onSuccess, onFailure);
  };
};

function seralizeSalesTargetsFromFormData(formData) {
  const {
    timeType,
    workingDaysOverride,
    closeRate,
    initialCallCount,
    appointmentTime,
    appointmentCount,
    presentationCount,
    followUpCallTime,
    followUpCallCount,
    totalSales,
    referralCount,
    initialCallTime,
    newLeadsCount,
  } = formData.fields;

  let payload = {};
  payload = newPayloadForFormValue(payload, newLeadsCount, 'new_leads_count');
  payload = newPayloadForFormValue(payload, timeType, 'time_type');
  payload = newPayloadForFormValue(payload, workingDaysOverride, 'working_days_override_value');
  payload = newPayloadForFormValue(payload, closeRate, 'close_rate');
  payload = newPayloadForFormValue(payload, totalSales, 'total_sales');

  payload = newPayloadForFormValue(payload, initialCallCount, 'initial_call_count');

  if (appointmentTime.value) {
    payload = newPayloadForValue(payload, appointmentTime.value * 60 * 60 * 24, 'appointment_time');
  }
  if (initialCallTime.value) {
    payload = newPayloadForValue(payload, initialCallTime.value * 60, 'initial_call_time');
  }

  payload = newPayloadForFormValue(payload, referralCount, 'referral_count');
  payload = newPayloadForFormValue(payload, appointmentCount, 'appointment_count');
  payload = newPayloadForFormValue(payload, presentationCount, 'presentation_count');
  payload = newPayloadForFormValue(payload, followUpCallTime, 'follow_up_call_time');
  payload = newPayloadForFormValue(payload, followUpCallCount, 'follow_up_call_count');

  return payload;
}
