import { request, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';
import { trackEvent } from 'modules/Helpers/Analytics';

import leadLostReasons from 'modules/Stores/LeadLostReasons';
import modal from 'modules/Stores/Modal';

export const create = (title) => {
  return (dispatch) => {
    const body = {
      lead_lost_reason: {
        lost_reason_title: title,
      },
    };

    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccess());
      dispatch(leadLostReasons.actions.addLeadLostReason(payload.lead_lost_reason));
      notify.success('Lead lost reason created');
    }

    trackEvent('Added lead lost reason', {
      reasonName: title,
    });

    return request('lead_lost_reasons', POST, body, onSuccess, onFailure);
  };
};

export const deleteLeadLostReason = (leadLostReasonId, toLeadLostReasonId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toLeadLostReasonId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess() {
      dispatch(modal.actions.stopLoading());
      dispatch(leadLostReasons.actions.removeLeadLostReasonWithId(leadLostReasonId));
      notify.success('Lead lost reason deleted');
      dispatch(modal.actions.hideModal());
    }
    const url = 'lead_lost_reasons/' + leadLostReasonId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const update = (leadLostReason, title) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    const body = {
      lead_lost_reason: {
        lost_reason_title: title,
      },
    };

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(leadLostReasons.actions.replaceLeadLostReasonWithId(payload.lead_lost_reason));
      dispatch(modal.actions.hideModal());
      notify.success('Lead lost reason updated');
    }

    return request('lead_lost_reasons/' + leadLostReason.id, PUT, body, onSuccess, onFailure);
  };
};

export function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

export function loadSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

export function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
  };
}
