import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import UserSelectContainer from '../containers/UserSelectContainer';

export class UserSelectModal extends Component {
  render() {
    const { currentModal, hideModal, modalName, handleDataUpdate } = this.props;

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select a user to assign from
            </h3>
          </div>
          <div className="block-content">
            <UserSelectContainer handleDataUpdate={handleDataUpdate} />
          </div>
        </div>

        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.props.hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UserSelectModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  identifer: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  handleDataUpdate: PropTypes.func.isRequired,
};

UserSelectModal.defaultProps = {
  buttonTitle: 'Filter by selected',
  singleSelect: false,
};

export default UserSelectModal;
