import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const ReportFilterFormStyler = ({ children, title, primary }) => {
  return (
    <Container>
      <Header>
        <Title primary={primary}>{title}</Title>
      </Header>
      <Content>
        <div className="row">
          <div className="col-sm-12">
            <ChildContainer primary={primary}>{children}</ChildContainer>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default ReportFilterFormStyler;

ReportFilterFormStyler.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  primary: PropTypes.bool
};

ReportFilterFormStyler.defaultProps = {
  primary: false
};

const Container = styled.div``;
const Header = styled.div``;
const Title = styled.div`
  font-size: 14px;
  font-weight: ${props => (props.primary ? "400" : "700")};
  text-transform: ${props => (props.primary ? "" : "uppercase")};
  text-align: ${props => (props.primary ? "center" : "left")};
`;

const ChildContainer = styled.div`
  margin: 5px 0px 15px 0px;
  text-align: ${props => (props.primary ? "center" : "left")};
`;

const Content = styled.div``;
