import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

export default function FunnelStepsWithLabel({
  salesFunnelSteps,
  currentStepNumber,
  onStepSelected
}) {
  const Steps = salesFunnelSteps.map((step, index) => {
    const complete = currentStepNumber >= step.step_number;
    return (
      <StepContainer key={index}>
        {step.step_number > 1 && <ProgressBar complete={complete} />}
        <FunnelStep onClick={() => onStepSelected(step)} complete={complete}>
          {step.name}
        </FunnelStep>
      </StepContainer>
    );
  });
  return <SalesFunnelContainer>{Steps}</SalesFunnelContainer>;
}

FunnelStepsWithLabel.propTypes = {
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStepNumber: PropTypes.number.isRequired,
  onStepSelected: PropTypes.func.isRequired
};

const SalesFunnelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FunnelStep = styled.div`
  background-color: #f5f5f5;
  color: #999;
  height: 20px;
  font-size: 75%;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 5px;
  margin: 5px 0;
  border-radius: 0.25em;

  ${props =>
    props.complete &&
    css`
      color: white;
      background-color: #5c90d2;
    `}

  &:hover {
    cursor: pointer;
  }
`;

const ProgressBar = styled.div`
  width: 40px;
  height: 5px;
  background-color: ${props => (props.complete ? "#98b9e3" : "#f5f5f5")};
`;
