import * as actionTypes from './actionTypes'

const ACCOUNT_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state) => {
    return { ...state, loading: false }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

const initialState = {
  loading: false,
  errors: null
}

export default function reducer (state = initialState, action) {
  const handler = ACCOUNT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
