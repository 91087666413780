import PropTypes from "prop-types";
import React, { Component } from "react";

export class SsoView extends Component {
  componentDidMount() {
    const { loadSsoSession, token, user } = this.props;
    if (user) {
      this.props.history.push("/secure/dashboard");
    } else if (token) {
      loadSsoSession(token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.props.history.push("/secure/dashboard");
    }
  }

  render() {
    return (
      <div id="page-container" className="header-navbar-fixed">
        <div id="page-loader" style={{ display: "block" }} />
      </div>
    );
  }
}

SsoView.propTypes = {
  user: PropTypes.object,
  loadSsoSession: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default SsoView;
