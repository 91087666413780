import { createSelector } from 'reselect'

export function sortedFlatHeadersAndDataFactory (getReportSummaryDataSeletor) {
  return createSelector(
    [ getReportSummaryDataSeletor ],
    (summaryData) => {
      return generateSortedHeadersAndCounts(summaryData)
    }
  )
}

export function headerNamesAndKeysFactory (getReportSummaryDataSelector) {
  return createSelector(
   [ getReportSummaryDataSelector ],
   (summaryData) => {
     return Object.keys(summaryData).map((summaryDataKey) => {
       const summaryDataEntry = summaryData[summaryDataKey]
       return {
         key: summaryDataKey,
         display_name: summaryDataEntry.display_name
       }
     }).filter((record) => record.display_name)
   }
  )
}

function generateSortedHeadersAndCounts (summaryData) {
  let headers = []
  let counts = []
  Object.keys(summaryData).forEach((summaryDataKey) => {
    const summaryDataEntry = summaryData[summaryDataKey]
    if (summaryDataEntry.display_name) {
      headers.push(summaryDataEntry.display_name)
      counts.push(summaryDataEntry.count)
    }
  })
  return {
    headers: headers,
    counts: counts
  }
}

export function dataIsEmptyFactory (getReportSummaryDataSelector) {
  return createSelector(
    [ getReportSummaryDataSelector ],
    (summaryData) => {
      const nonEmptyValues = generateSortedHeadersAndCounts(summaryData)
      .counts
      .filter((v) => v > 0)

      return (nonEmptyValues.length === 0)
    }
  )
}
