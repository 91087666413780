import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import editActionAutomationRule from '../modules/editActionAutomationRule'
import { getProductName } from 'modules/Stores/UserData/selectors'


function mapStateToProps (state, nextProps) {
  return {
    loading: editActionAutomationRule.selectors.getLoading(state),
    data: editActionAutomationRule.selectors.getData(state),
    errors: editActionAutomationRule.selectors.getErrors(state),
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: editActionAutomationRule.actions.updateData,
    update: editActionAutomationRule.actions.update,
    prefilFormData: editActionAutomationRule.actions.prefilFormData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(editActionAutomationRule
  .components.CurrentActionAutomationRuleView)
