import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ChartBox } from 'modules/components'
import { Pie } from 'react-chartjs-2'

export default class PieChartView extends Component {

  render () {
    const { title, labels, data, loading, isEmpty, dataSetTitle } = this.props
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: dataSetTitle,
          backgroundColor: [
            '#98b9e3',
            '#c7b7e4',
            '#FDC12D',
            '#83d0c7',
            '#7fe3f2',
            '#ff90b5'
          ],
          hoverBackgroundColor: [
            '#5c90d2',
            '#a48ad4',
            '#E9B22A',
            '#44b4a6',
            '#14adc4',
            '#ff6c9d'
          ],
          data: data
        }
      ]
    }

    return (
      <ChartBox
        title={title}
        loading={loading}
        isEmpty={isEmpty}
        chartIcon='fa fa-pie-chart'>
        <Pie data={chartData} />
      </ChartBox>
    )
  }
}

PieChartView.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  dataSetTitle: PropTypes.string.isRequired
}
