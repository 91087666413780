import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'

import * as helpers from '../helpers'

export default class MailEventItem extends Component {

  render () {
    const { mailEventItem } = this.props
    const iconStyle = helpers.iconStyle(mailEventItem.event) +
    ' list-timeline-icon ' +
    helpers.bgStyle(mailEventItem.event)
    const createdAtMoment = moment(mailEventItem.created_at).fromNow()
    return (
      <li>
        <div className='list-timeline-time'>
          {createdAtMoment}
        </div>
        <i className={iconStyle} />
        <div className='list-timeline-content'>
          <p className='font-w600'>{helpers.titleForEvent(mailEventItem)}</p>
          <p>{moment(mailEventItem.created_at).format('dddd, MMMM Do YYYY, h:mm a')}</p>
          {mailEventItem.link &&
            <p>{mailEventItem.link.long_url_string}</p>
          }
        </div>
      </li>
    )
  }
}

MailEventItem.propTypes = {
  mailEventItem: PropTypes.object.isRequired
}
