import { isPresent } from 'utils/validation'

export const validator = {
  title: [{
    rule: isPresent,
    errorMessage: 'You must enter a title'
  }],
  leadFacingDescription: [{
    rule: isPresent,
    errorMessage: 'You must a description that explains the reason to the lead'
  }]
}
