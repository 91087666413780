import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectLeadLostReasonTableRow } from "./SelectLeadLostReasonTableRow";

export class SelectLeadLostReasonTableView extends Component {
  render() {
    const {
      leadLostReasons,
      selectedLeadLostReasonIds,
      selectLeadLostReason,
      deselectLeadLostReason
    } = this.props;
    const rows = leadLostReasons.map((leadLostReason, index) => {
      const selectedIndex = selectedLeadLostReasonIds.findIndex(
        selectedLeadLostReasonId =>
          leadLostReason.id === selectedLeadLostReasonId
      );
      const selected = selectedIndex >= 0;
      return (
        <SelectLeadLostReasonTableRow
          key={index}
          leadLostReason={leadLostReason}
          leadLostReasonSelected={selected}
          selectLeadLostReason={selectLeadLostReason}
          deselectLeadLostReason={deselectLeadLostReason}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectLeadLostReasonTableView.propTypes = {
  leadLostReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLeadLostReasonIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLeadLostReason: PropTypes.func.isRequired,
  deselectLeadLostReason: PropTypes.func.isRequired
};

export default SelectLeadLostReasonTableView;
