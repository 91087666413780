import PropTypes from "prop-types";
import React, { Component } from "react";

import NewScheduleEditStepView from "./NewScheduleEditStepView";

import SelectSMSTemplateContainer from "modules/components/SendTextMessageModal/SelectSMSTemplateContainer";
import SelectQuickSendTemplateContainer from "modules/components/SendEmailModal/SelectQuickSendTemplateContainer";

import Modal from "react-bootstrap-modal";
import {
  DAYS,
  secondsFormattedForTimeType,
  formIsInvalid,
} from "./CallScheduleStepHelpers";

export const STEP_VIEW = "NEW_CALL_SCHEDULE/STEP_VIEW";
export const SELECT_EMAIL_TEMPLATE_VIEW =
  "NEW_CALL_SCHEDULE/SELECT_EMAIL_TEMPLATE_VIEW";
export const SELECT_SMS_TEMPLATE_VIEW =
  "NEW_CALL_SCHEDULE/SELECT_SMS_TEMPLATE_VIEW";

const defaultState = {
  selectedTimeType: DAYS,
  timeToWait: 1,
  selectedEmailTemplate: null,
  selectedTextMessageTemplate: null,
  selectedView: STEP_VIEW,
};

export class NewCallScheduleStepModal extends Component {
  state = defaultState;

  handleChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  createScheduleStepSelected = () => {
    const { createCallScheduleStep, callScheduleId } = this.props;
    const { selectedEmailTemplate, selectedTextMessageTemplate } = this.state;
    const selectedEmailTemplateId = selectedEmailTemplate
      ? selectedEmailTemplate.id
      : null;
    const selectedTextMessageTemplateId = selectedTextMessageTemplate
      ? selectedTextMessageTemplate.id
      : null;
    createCallScheduleStep(
      callScheduleId,
      secondsFormattedForTimeType(this.state),
      selectedEmailTemplateId,
      selectedTextMessageTemplateId,
      this.hideAndReset
    );
  };

  hideAndReset = () => {
    this.setState(defaultState);
    this.props.hideModal();
  };

  titleForView = () => {
    switch (this.state.selectedView) {
      case STEP_VIEW:
        return "Add a new step";
      case SELECT_EMAIL_TEMPLATE_VIEW:
        return "Select quick send email template";
      case SELECT_SMS_TEMPLATE_VIEW:
        return "Select SMS template";
      default:
        return "";
    }
  };

  setSelectedView = (view) => {
    this.setState({ selectedView: view });
  };

  currentView = () => {
    switch (this.state.selectedView) {
      case STEP_VIEW:
        return (
          <NewScheduleEditStepView
            errors={this.props.errors}
            updateValue={this.handleChange}
            numberOfSteps={this.props.numberOfSteps}
            timeToWait={this.state.timeToWait}
            selectedTimeType={this.state.selectedTimeType}
            setSelectedView={this.setSelectedView}
            selectedEmailTemplate={this.state.selectedEmailTemplate}
            selectedTextMessageTemplate={this.state.selectedTextMessageTemplate}
            footerComponent={
              <NewFooter
                onCancel={this.hideAndReset}
                actionPressed={this.createScheduleStepSelected}
                actionDisabled={() => formIsInvalid(this.state.timeToWait)}
              />
            }
          />
        );
      case SELECT_EMAIL_TEMPLATE_VIEW:
        return (
          <SelectQuickSendTemplateContainer
            {...this.props}
            backSelected={() => this.setState({ selectedView: STEP_VIEW })}
            setSelectedView={this.setSelectedView}
            onTemplateSelect={(template) =>
              this.setState({
                selectedEmailTemplate: template,
                selectedView: STEP_VIEW,
              })
            }
          />
        );
      case SELECT_SMS_TEMPLATE_VIEW:
        return (
          <SelectSMSTemplateContainer
            {...this.props}
            goBackView={STEP_VIEW}
            setSelectedView={this.setSelectedView}
            onTemplateSelect={(template) =>
              this.setState({
                selectedTextMessageTemplate: template,
                selectedView: STEP_VIEW,
              })
            }
          />
        );
      default:
        return "";
    }
  };

  render() {
    const { currentModal, modalName, loading } = this.props;
    const blockStyle = loading ? "block block-opt-refresh" : "block";

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideAndReset}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={this.hideAndReset}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                {this.titleForView()}
              </h3>
            </div>
            {this.currentView()}
          </div>
        </Modal>
      </div>
    );
  }
}

NewCallScheduleStepModal.propTypes = {
  createCallScheduleStep: PropTypes.func.isRequired,
  callScheduleId: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  currentModal: PropTypes.string,
};

export default NewCallScheduleStepModal;

const NewFooter = ({ onCancel, actionPressed, actionDisabled }) => {
  return (
    <Modal.Footer>
      <button className="btn btn-default pull-left" onClick={onCancel}>
        Cancel
      </button>
      <button
        className="btn btn-success push-5-r"
        type="button"
        disabled={actionDisabled()}
        onClick={actionPressed}
      >
        <i className="fa fa-plus" /> Add step
      </button>
    </Modal.Footer>
  );
};
