/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import LoginFormContainer from "../containers/LoginFormContainer";
import SelectPageFormContainer from "../containers/SelectPageFormContainer";
import ConfirmFormContainer from "../containers/ConfirmFormContainer";

export class FacebookWizardView extends Component {
  state = {
    titles: ["1. Login", "2. Select Facebook Page", "3. Confirm"],
  };

  render() {
    const { tabIndex, selectedIntegration } = this.props;

    const tabs = this.state.titles.map((title, index) => {
      const activeClassName = tabIndex === index ? "active" : "";
      return (
        <li className={activeClassName} key={index}>
          <a style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
            {" "}
            {title}
          </a>
        </li>
      );
    });

    const width = (((tabIndex + 1) * 1.0) / 3) * 100;

    const currentContainer = () => {
      switch (tabIndex) {
        case 0:
          return <LoginFormContainer />;
        case 1:
          return <SelectPageFormContainer />;
        case 2:
          if (
            selectedIntegration &&
            selectedIntegration.third_party_integrations_facebook_pages
          ) {
            return <ConfirmFormContainer location={this.props.location} />;
          } else {
            return <div />;
          }
        default:
          return undefined;
      }
    };

    return (
      <div
        className="js-wizard-simple block"
        style={{
          marginTop: "-20px",
          marginLeft: "-20px",
          marginRight: "-20px",
        }}
      >
        <ul className="nav nav-tabs nav-justified">{tabs}</ul>

        <div
          className="form-horizontal"
          action="base_forms_wizard.html"
          method="post"
        >
          <div className="block-content block-content-mini block-content-full border-b">
            <div className="wizard-progress progress progress-mini remove-margin-b">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                style={{ width: width + "%" }}
              />
            </div>
          </div>

          <div className="block-content tab-content">{currentContainer()}</div>
        </div>
      </div>
    );
  }
}

FacebookWizardView.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  selectedIntegration: PropTypes.object,
};

export default FacebookWizardView;
