import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";

import moment from "moment";
import "moment-timezone";

import Datetime from "react-datetime";
import "styles/react-datetime.css";

import FilterSection from "./FilterSection";

const TODAY = "Today";
const YESTERDAY = "Yesterday";
const THIS_WEEK = "This Week";
const LAST_WEEK = "Last Week";
const THIS_MONTH = "This Month";
const LAST_MONTH = "Last Month";
const LAST_7_DAYS = "Last 7 days";
const LAST_30_DAYS = "Last 30 Days";

export default class CreatedBetweenSection extends Component {
  state = {
    showingOptions: false,
    selectedIndex: null,
    dateRanges: [
      TODAY,
      YESTERDAY,
      THIS_WEEK,
      LAST_WEEK,
      THIS_MONTH,
      LAST_MONTH,
      LAST_7_DAYS,
      LAST_30_DAYS,
    ],
  };

  indexClicked = (index) => {
    this.setState({
      ...this.state,
      showingOptions: false,
      selectedIndex: index,
    });
    this.generateDateAndReturnForIndex(index);
  };

  generateDateAndReturnForIndex = (index) => {
    const option = this.state.dateRanges[index];
    let startDate = moment();
    let endDate = moment();
    switch (option) {
      case TODAY:
        startDate = moment().startOf("day").utc();
        endDate = moment().endOf("day").utc();
        break;
      case YESTERDAY:
        startDate = moment().subtract(1, "day").startOf("day").utc();
        endDate = moment().subtract(1, "day").endOf("day").utc();
        break;
      case THIS_WEEK:
        startDate = moment().startOf("week").utc();
        endDate = moment().endOf("week").utc();
        break;
      case LAST_WEEK:
        startDate = moment().subtract(1, "week").startOf("week").utc();
        endDate = moment().subtract(1, "week").endOf("week").utc();
        break;
      case THIS_MONTH:
        startDate = moment().startOf("month").utc();
        endDate = moment().endOf("month").utc();
        break;
      case LAST_MONTH:
        startDate = moment().subtract(1, "month").startOf("month").utc();
        endDate = moment().subtract(1, "month").endOf("month").utc();
        break;
      case LAST_7_DAYS:
        startDate = moment().subtract(7, "days").startOf("day").utc();
        endDate = moment().endOf("day").utc();
        break;
      case LAST_30_DAYS:
        startDate = moment().subtract(30, "days").startOf("day").utc();
        endDate = moment().endOf("day").utc();
        break;
      default:
        break;
    }

    this.selectDate(startDate, "created_after");
    this.selectDate(endDate, "created_before");
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  selectDate(date, fieldName) {
    const utcDate = date.format();
    this.handleInput(fieldName, utcDate, true);
  }

  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onUpdate(newSectionData, this.props.sectionName);
  };

  calculateAfterDate = () => {
    const fields = this.props.data.fields;
    const createdAfterDate = fields.created_after
      ? fields.created_after.value
      : null;
    return moment(createdAfterDate);
  };

  calculateBeforeDate = () => {
    const fields = this.props.data.fields;
    const createdBeforeDate = fields.created_before
      ? fields.created_before.value
      : null;
    return moment(createdBeforeDate);
  };

  render() {
    const options = this.state.dateRanges.map((dateRange, index) => {
      const className =
        index === this.state.selectedIndex ? "selected" : "selectable";
      return (
        <li key={index} className={className}>
          <div className="DropDown" onClick={() => this.indexClicked(index)}>
            {dateRange}
          </div>
        </li>
      );
    });

    return (
      <FilterSection
        title={"Date"}
        showBottomBorder={this.props.showBottomBorder}
      >
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-1">
              <button
                className="btn btn-default"
                onClick={this.toggleDropdown}
                type="button"
              >
                <span className="fa fa-calendar" />
              </button>
              {this.state.showingOptions && (
                <ul className="dropdown-menu" style={{ display: "block" }}>
                  {options}
                </ul>
              )}
            </div>
            <div className="col-sm-11">
              <div className="input-daterange input-group">
                <Datetime
                  value={this.calculateAfterDate()}
                  defaultValue={null}
                  dateFormat="DD/MM/YYYY"
                  inputProps={{ placeholder: "From" }}
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(date) =>
                    this.selectDate(
                      moment(date).startOf("day").utc(),
                      "created_after"
                    )
                  }
                />
                <span className="input-group-addon">To</span>
                <Datetime
                  value={this.calculateBeforeDate()}
                  defaultValue={null}
                  inputProps={{ placeholder: "Until" }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(date) =>
                    this.selectDate(
                      moment(date).endOf("day").utc(),
                      "created_before"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </FilterSection>
    );
  }
}

CreatedBetweenSection.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool,
};
