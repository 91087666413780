import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RedeemView } from '../components/RedeemView'

import { fetchInvite, redeemInvite } from '../actions'
import session from 'modules/Stores/Session'

import * as selectors from '../selectors'
import modal from 'modules/Stores/Modal'

const mapStateToProps = state => ({
  token: selectors.getToken(state),
  invite: selectors.getInvite(state),
  loading: selectors.getLoading(state),
  errors: selectors.getErrors(state),
  isInvalid: selectors.inviteIsInvalid(state),
  user: session.selectors.getUser(state)
})

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchInvite,
    redeemInvite,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RedeemView)
