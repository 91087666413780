import { isInteger, isMinLength, isMaxLength, isPresent } from 'utils/validation'

export const CreditCardValidator = {
  number: [{
    rule: isMinLength(14),
    errorMessage: 'A Credit Card number must be at least 16 numbers (no spaces please)'
  }, {
    rule: isMaxLength(19),
    errorMessage: 'The Credit Card must be at most 19 characters'
  }, {
    rule: isInteger,
    errorMessage: 'Credit Card numbers should only be numbers (no spaces please)'
  }],
  name: [{
    rule: isPresent,
    errorMessage: 'What is the name on the card?'
  }],
  expiry: [{
    rule: isPresent,
    errorMessage: 'When does the card expire?'
  }],
  cvc: [{
    rule: isMinLength(3),
    errorMessage: 'The CVC must be at least 3 numbers'
  }, {
    rule: isMaxLength(4),
    errorMessage: 'The CVC must be at most 4 characters'
  }, {
    rule: isInteger,
    errorMessage: 'CVC\'s can only be numbers'
  }]
}
