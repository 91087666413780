import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DashboardView } from '../components/DashboardView'

const mapStateToProps = state => ({
})

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView)
