import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import callScheduleSelect from '../modules/callScheduleSelect'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    loading: callScheduleSelect.selectors.getLoading(state),
    callSchedules: callScheduleSelect.selectors.getCallSchedules(state),
    pageCount: callScheduleSelect.selectors.getPageCount(state),
    currentPage: callScheduleSelect.selectors.getCurrentPage(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchCallSchedulesForPageNumber: callScheduleSelect.actions.fetchCallSchedulesForPageNumber,
    selectCallScheduleId: callScheduleSelect.actions.selectCallScheduleId,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(callScheduleSelect.components.ListCallSchedulesView)
