import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RecordCallModal from "../components/NewCallModal/RecordCallModal";

import modal from "modules/Stores/Modal";
import classes from "modules/Stores/Classes";
import userData from "modules/Stores/UserData";
import * as callActions from "../actions";
import leadsTrials from "../../leadsTrials";
const { hideModal, showModal } = modal.actions;

function mapStateToProps(state, ownProps) {
  const {
    classIntegrationId,
    classIntegrationName,
  } = classes.selectors.locationUsesClassesFactory(ownProps.lead.location_id)(
    state
  );
  return {
    currentTrial: leadsTrials.selectors.getCurrentTrial(state),
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    salesFunnel: userData.selectors.getSalesFunnel(state),
    classIntegrationId: classIntegrationId,
    classIntegrationName: classIntegrationName,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addLeadToTrial: leadsTrials.actions.addLeadToTrial,
      hideModal,
      showModal,
      recordCall: callActions.recordCall,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordCallModal);
