import * as actionTypes from './actionTypes'

import { LEAD_SOURCE_TYPE } from './constants'

const SOURCE_COUNT_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_REPORT_TYPE]: (state, action) => {
    return { ...state, reportType: action.reportType }
  }
}

const initialState = {
  errors: null,
  loading: false,
  reportType: LEAD_SOURCE_TYPE,
  data: []
}

export default function reducer (state = initialState, action) {
  const handler = SOURCE_COUNT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
