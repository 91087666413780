import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import session from 'modules/Stores/Session'
import modal from 'modules/Stores/Modal'
import profile from '../modules/profile'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    loading: profile.selectors.getLoading(state),
    data: profile.selectors.getData(state),
    errors: profile.selectors.getErrors(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    prefillFormForUser: profile.actions.prefillFormForUser,
    updateProfileWithData: profile.actions.updateProfileWithData,
    updateData: profile.actions.updateData,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(profile.components.EditProfileView)
