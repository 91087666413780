import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class EmailMapRow extends Component {

  emailMapClicked = (event) => {
    const { emailMap, onSelect } = this.props
    onSelect(emailMap.id)
  }

  render () {
    const { emailMap, selected } = this.props
    const location = emailMap.default_location

    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''

    return (
      <tr className={textClass + ' selectable ' + backgroundClass} onClick={this.emailMapClicked}>
        <td className='font-w600'>
          {emailMap.name}
        </td>
        <td className='font-w600'>
          {emailMap.email}
        </td>
        {location &&
          <td className='font-w600'>
            {location.display_name}
          </td>
        }
      </tr>
    )
  }
}

EmailMapRow.propTypes = {
  emailMap: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default EmailMapRow
