import { request, POST } from 'utils/apiUtils';
import session from 'modules/Stores/Session';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';

export const getArticles = (articleId, name, totp, userId) => {
  return (dispatch) => {
    const body = {
      articles: {
        id: articleId,
        name: name,
        totp: totp,
        user_id: userId,
      },
    };

    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      const user = payload.user;
      const message = `You have logged in as ${user.display_name}. Your session will end in 30 minutes`;
      notify.success(message);

      dispatch(setLoading(false));
      dispatch(session.actions.startSession(payload));
    }

    return request('articles', POST, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
