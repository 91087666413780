import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Datetime from 'react-datetime';
import 'styles/react-datetime.css';

export class DateTextField extends Component {
  state = {
    errorMessage: null,
    style: '',
    valid: this.props.validator !== null,
    value: '',
  };

  UNSAFE_componentWillMount() {
    this.setState({ value: this.props.initialValue || '' });
  }

  handleChange = (date) => {
    let style = '';
    const errorMessage = this.errorMessageForValue(date);
    const newIsValidValue = errorMessage === null;

    if (!newIsValidValue) {
      style = 'has-error';
    }

    this.setState({
      errorMessage: errorMessage,
      style: style,
      valid: newIsValidValue,
      value: date,
    });
    this.props.onChange(this.props.name, date, newIsValidValue);
  };

  handleOnFocus = (event) => {
    const { onFocus, name } = this.props;
    if (onFocus) {
      onFocus(name);
    }
  };

  handleOnBlur = (event) => {
    const { onBlur, name } = this.props;
    if (onBlur) {
      onBlur(name);
    }
  };

  errorMessageForValue = (value) => {
    const { name, validator } = this.props;
    if (!validator) return null;

    let errorMessage = null;
    const validations = validator[name];
    validations.forEach(function (validation) {
      const isValid = validation.rule(value);
      if (!isValid) {
        errorMessage = validation.errorMessage;
      }
    });
    return errorMessage;
  };

  render() {
    const { name, disabled, formFieldStyle, label, needsFormGroup, formGroupIconElement, locale, placeholder, dateFormat } = this.props;
    const { style, errorMessage } = this.state;
    const showFormGroup = needsFormGroup !== false;
    const isDisabled = disabled === true;
    const formStyle = formFieldStyle || '';
    const propsValue = this.props.value === null ? '' : this.props.value;
    const displayValue = propsValue !== undefined ? propsValue : this.state.value;

    const textField = () => {
      return (
        <div className={formStyle}>
          <label htmlFor={name}>{label}</label>
          {formGroupIconElement && (
            <div className="input-group">
              <Datetime
                utc={true}
                disabled={isDisabled}
                locale={locale}
                dateFormat={dateFormat}
                inputProps={{
                  disabled: isDisabled,
                  placeholder: placeholder,
                }}
                viewMode={'days'}
                timeFormat={false}
                value={displayValue}
                onChange={this.handleChange}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
              />
              <span className="input-group-addon">{formGroupIconElement}</span>
            </div>
          )}
          {!formGroupIconElement && (
            <Datetime
              inputProps={{
                disabled: isDisabled,
                placeholder: placeholder,
              }}
              utc={true}
              timeFormat={false}
              locale={locale}
              dateFormat={dateFormat}
              viewMode={'days'}
              value={displayValue}
              onChange={this.handleChange}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
            />
          )}
          <span id={name + '-error-message'} className="help-block">
            {errorMessage}
          </span>
        </div>
      );
    };
    return (
      <div>
        {showFormGroup && (
          <div className={'form-group ' + style}>
            <div className="col-xs-12">{textField()}</div>
          </div>
        )}
        {showFormGroup === false && textField()}
      </div>
    );
  }
}

DateTextField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  formFieldStyle: PropTypes.string,
  disabled: PropTypes.bool,
  validator: PropTypes.object,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  needsFormGroup: PropTypes.bool,
  onFocus: PropTypes.func,
  formGroupIconElement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
};

export default DateTextField;
