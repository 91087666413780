import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardTrialSelectModal from "../components/DashboardTrialSelectModal";

import modal from "modules/Stores/Modal";
import UserData from "modules/Stores/UserData";
import * as appointmentListActions from "../actions";
import * as constants from "../constants";
import leadsTrials from 'modules/components/LeadDetail/modules/leadsTrials'

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    modalName: constants.DASHBOARD_TRIAL_SELECT_MODAL,
    salesFunnel: UserData.selectors.getSalesFunnel(state),
    listUpdateProtocol: {
      cleanUp: appointmentListActions.removeSelected
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addLeadToTrial: leadsTrials.actions.addLeadToTrial,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTrialSelectModal);
