import React, { Component } from "react";
import styled from 'styled-components';
import PropTypes from "prop-types";
import DaySelectContainer from '../containers/DaySelectContainer'
import TimeSelectContainer from '../containers/TimeSelectContainer'

export default class ScheduleView extends Component {
  state = {
    selectedDays: this.props.selectedReportSchedule.daysToSend,
    sendTime: this.props.selectedReportSchedule.sendTime,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedReportSchedule !== nextProps.selectedReportSchedule) {
      this.setState({
        selectedDays: nextProps.selectedReportSchedule.daysToSend,
        sendTime: nextProps.selectedReportSchedule.sendTime,
      })
    }
  }
  
  handleDayChange = (event) => {
    const { selectedDays } = this.state
    const value = event.target.checked
    const dayName = event.target.id
    let newSelectedDays = [...selectedDays]

    if (value === true) {
      if (selectedDays.indexOf(dayName) >= 0) { return }
      newSelectedDays = [...newSelectedDays, dayName]
    } else if (value === false) {
      if (selectedDays.indexOf(dayName) === -1) { return }
      newSelectedDays = newSelectedDays.filter(day => day!== dayName)
    }
    this.setState({...this.state, selectedDays: newSelectedDays})
  }

  handleTimeChange = (newTimeMoment) => {
    this.setState({...this.state, sendTime: newTimeMoment.format()})
  }

  updateAutomaticReport = () => {
    const { selectedReport, updateAutomaticReportSchedule, selectedReportSchedule } = this.props
    const { selectedDays, sendTime } = this.state
    updateAutomaticReportSchedule(selectedReport.id, selectedReportSchedule.id, selectedDays, sendTime)
  }

  render() {
    const { selectedDays, sendTime } = this.state
    return (
      <div>
        <div className="block block-rounded block-bordered">
          <div className="block-header">
            <h3 className="block-title">Schedule</h3>
          </div>
          <div className="block-content">
            <DaySelectContainer selectedDays={selectedDays} handleDayChange={this.handleDayChange}/>
            <TimeSelectContainer sendTime={sendTime} handleTimeChange={this.handleTimeChange}/>
            <SaveButton className='btn btn-success'
              type='button'
              onClick={this.updateAutomaticReport}>Update Schedule
            </SaveButton>
          </div>
        </div>
      </div>
    );
  }
}

ScheduleView.propTypes = {
  updateAutomaticReportSchedule: PropTypes.func.isRequired,
  selectedReportSchedule: PropTypes.object.isRequired,
  selectedReport: PropTypes.object,
};

const SaveButton = styled.button`
  width: 144px;
  margin: 0px 0px 20px 0px;
`;