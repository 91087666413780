import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import userLeaderboard from "../modules/userLeaderboard";
import FilterFormSections from "modules/components/FilterFormSections";
import filters from "../modules/filters";

function mapStateToProps(state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    filters.selectors.getData
  );
  return {
    loading: userLeaderboard.selectors.getLoading(state),
    data: userLeaderboard.selectors.datasets(state),
    labels: userLeaderboard.selectors.dataLabels(state),
    errors: userLeaderboard.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    compareOption: filters.selectors.getCompareOption(state),
    calculatingOption: filters.selectors.getCalculatingOption(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWithQueryString: userLeaderboard.actions.fetchWithQueryString
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userLeaderboard.components.UserLeaderboardView);
