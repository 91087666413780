import * as actionTypes from './actionTypes'

const CONFIGURE_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_FROM_USER]: (state, action) => {
    return { ...state, fromUser: action.fromUser, toUserIds: [] }
  },
  [actionTypes.SET_POTENTIAL_USERS]: (state, action) => {
    return { ...state, potentialUsers: action.potentialUsers }
  },
  [actionTypes.ADD_TO_USER_ID]: (state, action) => {
    return { ...state,
      toUserIds: [
        ...state.toUserIds,
        action.toUserId
      ] }
  },
  [actionTypes.REMOVE_TO_USER_ID]: (state, action) => {
    const oldUserIds = state.toUserIds
    const index = oldUserIds.findIndex(userId => parseInt(action.toUserId) === parseInt(userId))
    return { ...state,
      toUserIds: [
        ...oldUserIds.slice(0, index),
        ...oldUserIds.slice(index + 1)
      ] }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

function initialState () {
  return {
    loading: false,
    fromUser: null,
    potentialUsers: [],
    toUserIds: [],
    pageDetails: {
      pageCount: 0,
      currentPage: 1,
      total: 0
    }
  }
}

export default function reducer (state = initialState(), action) {
  const handler = CONFIGURE_LEADS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
