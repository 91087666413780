import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AssignLeadModal from '../components/AssignLeadModal'

import modal from 'modules/Stores/Modal'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state, props) {
  const selectedLocation = selectors.getSelectedLocation(state)
  return {
    locationName: (selectedLocation) ? selectedLocation.admin_display_name : props.lead.location_name,
    currentView: selectors.getCurrentView(state),
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    reset: actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignLeadModal)
