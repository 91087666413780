import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getRegionSearchTerm = state => state[NAME].regionSearchTerm;
export const getRegionCurrentPage = state => state[NAME].regionCurrentPage;

// Regions
export function filteredRegions(regions) {
  return createSelector(
    [getRegionSearchTerm],
    searchTerm => {
      if (searchTerm && searchTerm.length > 0) {
        const uppercaseSearchTerm = searchTerm.toUpperCase();
        return regions.filter(region => {
          return region.name.toUpperCase().includes(uppercaseSearchTerm);
        });
      } else {
        return regions;
      }
    }
  );
}

export function pageCountForRegions(regions, resultsPerPage) {
  return createSelector(
    [getRegionCurrentPage],
    currentPage => {
      const pageCount = regions.length / resultsPerPage;
      if (pageCount < 1) {
        return 1;
      }
      return pageCount;
    }
  );
}

export function regionsAtCurrentPage(regions, resultsPerPage) {
  return createSelector(
    [getRegionCurrentPage],
    currentPage => {
      const startIndex = resultsPerPage * (currentPage - 1);
      const endIndex = startIndex + resultsPerPage;

      return regions.slice(startIndex, endIndex);
    }
  );
}
