import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const fetchWithQueryString = (queryString) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(errors) {
      dispatch(setErrors(errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {}
    let url = `trial_reports/breakdown`;
    if (queryString) {
      url = url + '?' + encodeURI(queryString);
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const setSelectedCell = (trialId, statusName) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_CELL,
      selectedCell: { trialId: trialId, status: statusName },
    });
  };
};

export function websocketSetTrials(trials) {
  return (dispatch) => {
    dispatch(setTrials(trials));
    dispatch(setLoading(false));
  };
}

function setTrials(trials) {
  return {
    type: actionTypes.SET_TRIALS,
    trials,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}
