import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getMailDomains = () => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      const domains = [
        payload.transactional_domain,
        payload.marketing_domain
      ]
      dispatch(setDomains(domains))
    }

    return request('mail_domains', GET, null, onSuccess, onFailure)
  }
}

export const getDomainStatistics = (domain, startTime, endTime) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setRates(payload.rates))
      dispatch(setStatistics(payload.statistics))
    }

    const url = 'domain_statistics?id=' + domain.id + '&start_date=' + startTime + '&end_date=' + endTime
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setDomains (domains) {
  return {
    type: actionTypes.SET_DOMAINS,
    domains
  }
}

export function setSelectedDomainId (selectedDomainId) {
  return {
    type: actionTypes.SET_SELECTED_DOMAIN_ID,
    selectedDomainId
  }
}

function setRates (rates) {
  return {
    type: actionTypes.SET_RATES,
    rates
  }
}

function setStatistics (statistics) {
  return {
    type: actionTypes.SET_STATISTICS,
    statistics
  }
}
