import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import locations from 'modules/Stores/Locations'

import autoAssign from '../modules/autoAssign'
import getAutoAssignRule from '../modules/autoAssign/modules/getAutoAssignRule'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const location = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    location: location,
    loading: getAutoAssignRule.selectors.getLoading(state),
    autoAssignRule: getAutoAssignRule.selectors.getAutoAssignRule(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getAutoAssignRuleForLocation: getAutoAssignRule.actions.getAutoAssignRuleForLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(autoAssign.components.AutoAssignRuleView)
