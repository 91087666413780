import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BarChartView from 'modules/components/Reports/components/BarChartView'

export class WeeklyLeadSummaryView extends Component {

  UNSAFE_componentWillMount () {
    const { fetchData, location } = this.props
    fetchData(location)
  }

  render () {
    return (
      <div style={{ minHeight: '316px' }}>
        <BarChartView {...this.props} dataSetTitle={''} />
      </div>
    )
  }
}

WeeklyLeadSummaryView.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired
}

export default WeeklyLeadSummaryView
