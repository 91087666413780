import { request, PUT } from 'utils/apiUtils';
import notify from 'modules/Notifications';

import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';

export const sendData = (formData) => {
  return (dispatch) => {
    const body = {
      user_password: seralizePasswordFromFormData(formData),
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      dispatch(resetForm());
      notify.success('Password updated');
    }

    return request('update_password', PUT, body, onSuccess, onFailure);
  };
};

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function resetForm() {
  return {
    type: actionTypes.RESET_FORM_DATA,
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function seralizePasswordFromFormData(formData) {
  const { currentPassword, newPassword, newPasswordConfirmation } = formData.fields;

  let payload = {};
  payload = newPayloadForFormValue(payload, currentPassword, 'current_password');
  payload = newPayloadForFormValue(payload, newPassword, 'new_password');
  payload = newPayloadForFormValue(payload, newPasswordConfirmation, 'new_password_confirmation');

  return payload;
}
