/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import SelectCSVFileContainer from "../containers/SelectCSVFileContainer";
import ConfigureLeadsContainer from "../containers/ConfigureLeadsContainer";
import FieldMappingContainer from "../containers/FieldMappingContainer";
import UploadCsvContainer from "../containers/UploadCsvContainer";

import PreviousImportsContainer from "../containers/PreviousImportsContainer";

import WizardButtonsView from "./WizardButtonsView";

export class ImportLeadsView extends Component {
  state = {
    titles: [
      "1. Select CSV",
      "2. Configure Leads",
      "3. Map values ",
      "3. Upload CSV",
    ],
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { tabIndex } = this.props;
    const { titles } = this.state;

    const tabs = titles.map((title, index) => {
      const activeClassName = tabIndex === index ? "active" : "";
      return (
        <li className={activeClassName} key={index}>
          <a style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
            {" "}
            {title}
          </a>
        </li>
      );
    });

    const width = (((tabIndex + 1) * 1.0) / titles.length) * 100;

    const currentContainer = () => {
      switch (tabIndex) {
        case 0:
          return <SelectCSVFileContainer />;
        case 1:
          return <ConfigureLeadsContainer />;
        case 2:
          return <FieldMappingContainer />;
        case 3:
          return <UploadCsvContainer />;
        default:
          return <div />;
      }
    };

    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="js-wizard-simple block">
              <ul className="nav nav-tabs nav-justified">{tabs}</ul>

              <form
                className="form-horizontal"
                action="base_forms_wizard.html"
                method="post"
              >
                <div className="block-content block-content-mini block-content-full border-b">
                  <div className="wizard-progress progress progress-mini remove-margin-b">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      style={{ width: width + "%" }}
                    />
                  </div>
                </div>

                <div className="block-content tab-content">
                  {currentContainer()}
                </div>
              </form>

              <WizardButtonsView {...this.props} />
            </div>
          </div>
        </div>
        {tabIndex === 0 && (
          <div className="row">
            <div className="col-xs-12">
              <PreviousImportsContainer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ImportLeadsView.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  nextIsReady: PropTypes.bool.isRequired,
  incrementTabIndex: PropTypes.func.isRequired,
  decrementTabIndex: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default ImportLeadsView;
