import * as actionTypes from './actionTypes'

const NEW_SALES_TARGET_ACTION_HANDLERS = {
  [actionTypes.UPDATING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

function initialStateForSalesTargets (salesTargets) {
  return {
    loading: false
  }
}

export default function reducer (state = initialStateForSalesTargets(null), action) {
  const handler = NEW_SALES_TARGET_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
