import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { push } from 'react-router-redux';
import session from 'modules/Stores/Session';
import locations from 'modules/Stores/Locations';
import classes from 'modules/Stores/Classes';
import mergeTags from 'modules/Stores/MergeTags';
import lawfulProcessingBases from 'modules/Stores/LawfulProcessingBases';
import securityPolicy from 'modules/Stores/SecurityPolicy';
import trials from 'modules/Stores/Trials';

import notify from 'modules/Notifications';

export function resetUserData() {
  return {
    type: actionTypes.RESET,
  };
}

function startLoading() {
  return {
    type: actionTypes.LOADING_STARTED,
  };
}

function stopLoading() {
  return {
    type: actionTypes.LOADING_STOPPED,
  };
}

export function getCountries() {
  return (dispatch) => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(setCountries(payload.countries));
    }

    return request('countries', GET, null, onSuccess, onFailure);
  };
}

export function setCountries(countries) {
  return {
    type: actionTypes.SET_COUNTRIES,
    countries,
  };
}

export function loadInitialStateForCurrentUser() {
  return (dispatch) =>
    Promise.all([
      dispatch(startLoading()),
      dispatch(locations.actions.loadInitialLocations()),
      dispatch(classes.actions.getClassLocations()),
      dispatch(getCountries()),
      dispatch(getUserCompanyDetails()),
      dispatch(getUserRoles()),
      dispatch(getSchedules()),
    ]).then(() => {
      dispatch(stopLoading());
    });
}

// GET USER COMPANY
const getUserCompanyDetails = () => {
  return (dispatch) => {
    function onFailure(payload) {
      if (payload.error === 'unauthorized') {
        notify.warning('Your session has been expired. Please log in again.');
        dispatch(session.actions.endSession());
        dispatch(push('/login'));
      }
    }

    function onSuccess(payload) {
      if (payload.company) {
        const company = payload.company;
        dispatch(getUserCompanySuccess(company));
        dispatch(mergeTags.actions.fetchMergeTags());
        dispatch(getSalesFunnelSuccess(company.current_sales_funnel));
        dispatch(trials.actions.getTrials());
        dispatch(lawfulProcessingBases.actions.getLawfulProcessingBases());
        dispatch(securityPolicy.actions.getSecurityPolicy());
      }
    }

    return request('user_company', GET, null, onSuccess, onFailure);
  };
};

export function getUserCompanySuccess(company) {
  return {
    type: actionTypes.COMPANY_DATA_FETCH_SUCESS,
    company,
  };
}

// GET SALES Funnel
export function getSalesFunnelSuccess(salesFunnel) {
  return {
    type: actionTypes.SALES_FUNNEL_FETCH_SUCCESS,
    salesFunnel,
  };
}

// GET User Roles Funnel
const getUserRoles = () => {
  return (dispatch) => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(getUserRolesSuccess(payload.user_roles));
    }

    return request('user_roles', GET, null, onSuccess, onFailure);
  };
};

function getUserRolesSuccess(userRoles) {
  return {
    type: actionTypes.USER_ROLES_FETCH_SUCCESS,
    userRoles,
  };
}

export const fetchMetricsForLocation = (location, startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchingLocationMetrics());

    function onFailure(errors) {
      dispatch(fetchMetricsFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchMetricsSuccess(payload.location_metrics));
    }
    if (startDate && endDate) {
      return request(
        'locations/' + location.id + ' /metrics?start_date=' + startDate + '&end_date=' + endDate,
        GET,
        null,
        onSuccess,
        onFailure
      );
    } else {
      return request('locations/' + location.id + ' /metrics', GET, null, onSuccess, onFailure);
    }
  };
};

function fetchingLocationMetrics() {
  return {
    type: actionTypes.FETCHING_LOCATION_METRICS,
  };
}

function fetchMetricsSuccess(metrics) {
  return {
    type: actionTypes.FETCH_LOCATION_METRICS_SUCCESS,
    metrics,
  };
}

function fetchMetricsFailed(errors) {
  return {
    type: actionTypes.FETCH_LOCATION_METRICS_FAILED,
    errors,
  };
}

export function updateSalesTargets(salesTargets) {
  return {
    type: actionTypes.SET_SALES_TARGETS,
    salesTargets,
  };
}

export function updateLocation(location) {
  return {
    type: actionTypes.UPDATE_LOCATION,
    location,
  };
}

export function addLocation(location) {
  return {
    type: actionTypes.ADD_LOCATION,
    location,
  };
}

export function removeRegionWithId(regionId) {
  return {
    type: actionTypes.REMOVE_REGION,
    regionId,
  };
}

export function addRegion(region) {
  return {
    type: actionTypes.ADD_REGION,
    region,
  };
}

export function updateRegion(region) {
  return {
    type: actionTypes.UPDATE_REGION,
    region,
  };
}

// SCHEDULES
export const getSchedules = () => {
  return (dispatch) => {
    dispatch(startLoading());

    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(setSchedules(payload.user_location_schedules));
    }

    return request('user_location_schedules', GET, null, onSuccess, onFailure);
  };
};

function setSchedules(schedules) {
  return {
    type: actionTypes.SET_SCHEDULES,
    schedules,
  };
}

export function removeScheduleWithId(scheduleId) {
  return {
    type: actionTypes.REMOVE_SCHEDULE,
    scheduleId,
  };
}

export function addSchedule(schedule) {
  return {
    type: actionTypes.ADD_SCHEDULE,
    schedule,
  };
}

export function updateSchedule(schedule) {
  return {
    type: actionTypes.UPDATE_SCHEDULE,
    schedule,
  };
}

export function updateUser(user) {
  return {
    type: actionTypes.UPDATE_USER,
    user,
  };
}

// THIRD PARTY INTEGRATIONS
export function addThirdPartyIntegration(locationId, thirdPartyIntegration) {
  return {
    type: actionTypes.ADD_THIRD_PARTY_INTEGRATION,
    locationId,
    thirdPartyIntegration,
  };
}

export function deleteThirdPartyIntegrationWithId(locationId, thirdPartyIntegrationId) {
  return {
    type: actionTypes.DELETE_THIRD_PARTY_INTEGRATION_WITH_ID,
    locationId,
    thirdPartyIntegrationId,
  };
}

export function updateThirdPartyIntegration(locationId, thirdPartyIntegration) {
  return {
    type: actionTypes.UPDATE_THIRD_PARTY_INTEGRATION,
    locationId,
    thirdPartyIntegration,
  };
}
