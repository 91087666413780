import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';
import moment from 'moment';
import getAutoAssignRule from '../getAutoAssignRule';

export const createAutoAssignRuleForLocation = (location) => {
  return (dispatch) => {
    const body = {
      auto_assign_rule: {
        enabled_at: moment().format(),
      },
      location_id: location.id,
    };

    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(getAutoAssignRule.actions.setAutoAssignRule(payload.auto_assign_rule));
      notify.success('auto-assign rule for ' + location.admin_display_name + ' created');
    }

    const url = 'locations/' + location.id + '/auto_assign_rules';

    return request(url, POST, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
