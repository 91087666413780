import React, { Component } from 'react';
import styled from 'styled-components';
import SubscriptionsTableView from './SubscriptionsTableView';
import EditSubscriptionContainer from '../containers/EditSubscriptionContainer';

export class CalendlySetupView extends Component {
  componentDidMount() {
    const { fetchSubscriptions, selectedIntegration, subscriptions, selectedLocation } = this.props;
    if (selectedIntegration && subscriptions === null) {
      fetchSubscriptions(selectedLocation.id, selectedIntegration.id);
    }
  }

  componentWillUnmount() {
    const { setSubscriptions } = this.props;
    setSubscriptions(null);
  }

  selectSubscription = (subscription) => {
    const { setSelectedSubscription } = this.props;
    setSelectedSubscription(subscription);
  };

  render() {
    const { selectedSubscription, subscriptions, selectedIntegration, selectedLocation } = this.props;

    return (
      <Container>
        {!selectedSubscription && (
          <SubscriptionsTableView
            subscriptions={subscriptions}
            selectedSubscription={selectedSubscription}
            selectedIntegration={selectedIntegration}
            selectedLocation={selectedLocation}
            selectSubscription={this.selectSubscription}
          />
        )}
        {selectedSubscription && <EditSubscriptionContainer locationId={selectedLocation.id} integrationId={selectedIntegration.id} />}
      </Container>
    );
  }
}

export default CalendlySetupView;

const Container = styled.div``;
