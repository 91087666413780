import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NewEmailMessageEditor, InfoView } from 'modules/components';

import { errorPanelForErrors } from 'utils/formHelpers';

import { mergeTagsForSelect } from '../templateHelpers';
import { HTML_TEMPLATE_SELECT_VIEW, SELECT_QUICK_SEND_TEMPLATE } from '../SendEmailModal';
export class QuickSendView extends Component {
  state = {
    text: '',
    subject: '',
  };

  sendEmail = () => {
    const { sendQuickEmailToLead, lead } = this.props;
    const { text, subject } = this.state;
    sendQuickEmailToLead(lead, subject, text);
  };

  warningString = () => {
    const { canSendEmail, emailBlocked } = this.props;
    if (emailBlocked) {
      return (
        <span>
          Your email domain has been disabled due to a poor bounce or complaint rate. Unfortunately you won't be able to send emails until
          we can work to resolve it. Visit{' '}
          <a target="_blank" rel="noopener noreferrer" href=" http://support.gymleads.net/faq-and-troubleshooting/why-is-my-email-disabled">
            this link
          </a>{' '}
          For more details
        </span>
      );
    }
    if (!canSendEmail) {
      return (
        <span>
          We're still setting up your GymLeads email address. This is so we can bring replies from your leads into the GymLeads system. It
          shouldn't be too much longer. Visit{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://support.gymleads.net/how-it-works/communication/your-gymleads-email-address"
          >
            this link
          </a>{' '}
          For more details
        </span>
      );
    }
  };

  isFormInvalid = () => {
    const { canSendEmail } = this.props;
    const { text, subject } = this.state;
    if (!canSendEmail) {
      return true;
    }

    if (!subject || subject.trim().length === 0) {
      return true;
    }
    if (!text || text.length === 0) {
      return true;
    }
    return false;
  };

  setTemplate = (text, subject) => {
    this.setState({
      text: text,
      subject: subject,
    });
    this.editorView.setTemplate(text, subject);
  };

  render() {
    const { user, canSendEmail, cancelSelected, mergeTags, lead, errors, readOnly } = this.props;

    const selectMergeTags = mergeTagsForSelect(mergeTags);

    const copy = readOnly
      ? 'Select a template from the dropdown and press send.'
      : 'Enter your email text in the box to the right. You can insert merge fields by selecting one from the ' +
        "dropdown. If you want more powerful editing options, you can select the 'full editor' button below ";

    return (
      <div>
        <div className="block-content">
          {!canSendEmail && (
            <InfoView colorThemeClass={'warning'} title={"You can't send emails right now"} detail={this.warningString()} />
          )}
          {canSendEmail && (
            <div className="row">
              <div className="col-md-4 border-r">
                <p className="lead">Send {lead.given_name} an email</p>
                <p className="text-muted">{copy}</p>
                <button
                  className="btn btn-primary push"
                  onClick={() => this.props.setCurrentView(SELECT_QUICK_SEND_TEMPLATE)}
                  type="button"
                >
                  Select a Quick Send Template
                </button>
                <button className="btn btn-primary" onClick={() => this.props.setCurrentView(HTML_TEMPLATE_SELECT_VIEW)} type="button">
                  Select a HTML template
                </button>
              </div>
              <div className="col-md-8 border-l">
                {errorPanelForErrors(errors)}
                <NewEmailMessageEditor
                  user={user}
                  onSubjectChange={(text) => this.setState({ subject: text })}
                  onBodyChange={(text) => this.setState({ text: text })}
                  readOnly={readOnly}
                  onChange={this.props.onTextChange}
                  mergeTags={selectMergeTags}
                  ref={(instance) => {
                    if (instance) {
                      this.editorView = instance;
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button className="btn btn-default push-5-r push-10" type="button" onClick={cancelSelected}>
            Cancel
          </button>
          <button className="btn btn-success push-5-r push-10" type="button" disabled={this.isFormInvalid()} onClick={this.sendEmail}>
            <i className="si si-envelope" /> Send Email
          </button>
        </div>
      </div>
    );
  }
}

QuickSendView.propTypes = {
  lead: PropTypes.object.isRequired,
  sendQuickEmailToLead: PropTypes.func.isRequired,
  cleanUpParent: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  canSendEmail: PropTypes.bool.isRequired,
  cancelSelected: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  emailBlocked: PropTypes.bool.isRequired,
};

export default QuickSendView;
