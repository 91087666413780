import PropTypes from 'prop-types';
import React, { Component } from 'react';
import logo from 'images/logo.png';
import ArticlesForm from './ArticlesForm';

export class ArticlesView extends Component {
  render() {
    const { getArticles, loading, errors } = this.props;

    return (
      <div>
        <div className="bg-white pulldown">
          <div className="content context-boxed overflow-hidden">
            <div className="row">
              <div className="row">
                <div className="col-md-12 text-center push-30-t">
                  <img src={logo} alt="logo" style={{ height: '60px', paddingBottom: '10px' }} />
                </div>
              </div>
              <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <div className="push-30-t push-50 animated fadeIn">
                  <ArticlesForm onSubmit={getArticles} loading={loading} errors={errors} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pulldown push-30-t text-center animated fadeInUp">
          For use by GymLeads staff only. Your IP will be logged for every activity on this page. Unauthorized attempts wil be investigated.
        </div>
      </div>
    );
  }
}

ArticlesView.propTypes = {
  loading: PropTypes.bool.isRequired,
  getArticles: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ArticlesView;
