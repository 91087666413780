import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import turnOnOptIn from '../modules/turnOnOptIn'

function mapStateToProps (state) {
  return {
    loading: turnOnOptIn.selectors.getLoading(state),
    errors: turnOnOptIn.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    turnOnOptInForLocation: turnOnOptIn.actions.turnOnOptInForLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(turnOnOptIn.components.TurnOnOptInView)
