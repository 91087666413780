import * as constants from "./constants";

export function stringForCompareOption(option) {
  switch (option) {
    case constants.CLUBS:
      return "Clubs";
    case constants.USERS:
      return "Users";
    case constants.LEAD_SOURCES:
      return "Lead Sources";
    case constants.GROUPED_USERS:
      return "Grouped Users";
    default:
      return "";
  }
}

export function stringForCalculatingOption(option) {
  switch (option) {
    case constants.LEAD_CREATED:
      return "Lead Created";
    case constants.EVENT_OCCURED:
      return "Event Occured";
    default:
      return "";
  }
}

export function humanReadableFilterName(filter) {
  switch (filter) {
    case constants.COMPARE_BY:
      return "Compare By";
    case constants.CALCULATE_BY:
      return "Calculate By";
    case constants.LEAD_SOURCES:
      return "Lead Sources";
    case constants.HEARD_ABOUT_METHODS:
      return "Heard About Methods";
    case constants.TAGS:
      return "Tags";
    case constants.DATE_RANGE:
      return "Date Range";
    case constants.STATUS_UPDATED_RANGE:
      return "Status Updated Range";
    case constants.USERS:
      return "Users";
    case constants.ASSIGNED_TO:
      return "Assigned To";
    case constants.LOCATIONS:
      return "Locations";
    case constants.REGIONS:
      return "Regions";
    case constants.LEAD_STATUS:
      return "Lead Status";
    case constants.FUNNEL_STEPS:
      return "Funnel Steps";
    case constants.TRIALS:
      return "Trials";
    default:
      return null;
  }
}
