import { isPresent } from 'utils/validation'

export const LoginFormValidator = {
  username: [{
    rule: isPresent,
    errorMessage: 'What is your username?'
  }],
  password: [{
    rule: isPresent,
    errorMessage: 'Looks like the password is missing'
  }]
}
