import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import SelectLeadGoalTableView from "./TableView/SelectLeadGoalTableView";

import { CREATE_LEAD_VIEW } from "../constants";
import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class LeadGoalSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { leadGoals } = this.props;
    if (leadGoals.length > 0) {
      return;
    }
    this.getLeadGoalsWithPageNumber(1);
  }

  getLeadGoalsWithPageNumber = pageNumber => {
    const { getLeadGoals } = this.props;
    getLeadGoals(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadGoalsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadGoals } = this.props;
    getLeadGoals(1, searchTerm);
  };

  showCreateLead = () => {
    this.props.setCurrentView(CREATE_LEAD_VIEW);
  };

  onLeadGoalSelect = leadGoal => {
    const { setCurrentView, selectLeadGoal } = this.props;
    selectLeadGoal(leadGoal);
    setCurrentView(CREATE_LEAD_VIEW);
  };

  render() {
    const { leadGoals, pageDetails, loading, selectedLeadGoal } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <div>
        <ContentBlockView loading={loading} bordered>
          <div className="row" style={{ marginTop: "-20px" }}>
            <TextSearchView
              sectionName={"leadGoalSearch"}
              onUpdate={this.searchUpdated}
            />
          </div>
          {leadGoals.length > 0 && (
            <div>
              <SelectLeadGoalTableView
                leadGoals={leadGoals}
                selectedLeadGoal={selectedLeadGoal}
                onLeadGoalSelect={this.onLeadGoalSelect}
              />
            </div>
          )}
          {leadGoals.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
        </ContentBlockView>
        <Modal.Footer>
          <button
            className="btn btn-default push-5-r push-10"
            type="button"
            onClick={this.showCreateLead}
          >
            Go Back
          </button>
        </Modal.Footer>
      </div>
    );
  }
}

LeadGoalSelectView.propTypes = {
  leadGoals: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectedLeadGoal: PropTypes.object,
  getLeadGoals: PropTypes.func.isRequired,
  selectLeadGoal: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
};

export default LeadGoalSelectView;
