import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ExportLeadContainer from '../containers/ExportLeadContainer'
import SweetAlert from 'react-bootstrap-sweetalert'
import MoveLeadModalContainer from '../../../containers/MoveLeadModalContainer'

export class ExportOptionView extends Component {

  state = {
    showWarning: false
  }

  showWarningPopup = () => {
    this.setState({ ...this.state, showWarning: true })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showWarning: false })
  }

  reopenClicked = () => {
    const { lead, reopenLead, salesFunnelSteps, listUpdateProtocol } = this.props
    this.dismissPopups()
    reopenLead(lead, salesFunnelSteps, listUpdateProtocol)
  }

  closeClicked = () => {
    const { updateLeadToStatus, lead, listUpdateProtocol } = this.props
    updateLeadToStatus(lead, 'closed', lead.sales_funnel_step, null, listUpdateProtocol)
  }

  moveLeadSelected = () => {
    this.props.showModal(this.props.moveLeadModalName)
  }

  render () {
    const { lead, listUpdateProtocol } = this.props
    return (
      <div>
        {lead.status === 'active' &&
          <button type='button' className='btn btn-success' onClick={this.moveLeadSelected}>
            <i className='fa fa-exchange' />{' '}
            Move to step ...
          </button>
        }
        {' '}
        {lead.status === 'active' &&
          <button type='button' className='btn btn-success' onClick={this.closeClicked}>
            <i className='fa fa-check' />{' '}
            Close
          </button>
        }
        {lead.status !== 'active' && lead.status !== 'not_assigned' &&
          <button type='button' className='btn btn-warning' onClick={this.showWarningPopup}>
            <i className='si si-loop' />{' '}
            Reopen
          </button>
        }
        {' '}
        <ExportLeadContainer {...this.props} />
        <MoveLeadModalContainer
          modalName={this.props.moveLeadModalName}
          lead={lead}
          listUpdateProtocol={listUpdateProtocol} />
        {this.state.showWarning &&
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='warning'
            title='Are you Sure?'
            confirmBtnText='Reopen lead'
            onConfirm={this.reopenClicked}
            onCancel={this.dismissPopups}>
            The lead will be reopened and moved to the start of the sales funnel
          </SweetAlert>
        }
      </div>
    )
  }
}

ExportOptionView.propTypes = {
  lead: PropTypes.object.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  updateLeadToStatus: PropTypes.func.isRequired,
  reopenLead: PropTypes.func.isRequired,
  moveLeadModalName: PropTypes.string.isRequired
}

export default ExportOptionView
