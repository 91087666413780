export const NAME = 'details'

export const GENDER_MALE = 'Male'
export const GENDER_FEMALE = 'Female'
export const GENDER_OTHER = 'Other'
export const GENDER_MODAL = 'gender'

export const GOAL_INCREASE_ENDURANCE = 'Increase Endurance'
export const GOAL_BUILD_MUSCLE = 'Build Muscle'
export const GOAL_LOOSE_WEIGHT = 'Lose Weight'
export const GOAL_OTHER = 'Other'
export const GOAL_MODAL = 'fitnessGoals'

export const HEARD_ABOUT_METHOD_MODAL = NAME + '/HEARD_ABOUT_METHOD_MODAL'

export const LOOKING_FOR_LOCATION = 'Convenient Location'
export const LOOKING_FOR_24_7 = '24/7 Access'
export const LOOKING_FOR_PRICING = 'Competitive pricing'
export const LOOKING_FOR_EQUIPMENT = 'Quality and vairety of equipment '

export const FITNESS_LEVEL_NEW = 'New to Fitness'
export const FITNESS_LEVEL_LESS_LEVEL_ONE = 'Less 6 months'
export const FITNESS_LEVEL_LESS_LEVEL_TWO = '1-2 years'
export const FITNESS_LEVEL_LESS_LEVEL_THREE = 'Over 2 years'
export const FITNESS_MODAL = 'fitnessLevel'

export const DATE_OF_BIRTH_MODAL = 'DATE_OF_BIRTH_MODAL'
