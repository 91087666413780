import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SelectField extends Component {

  state = {
    errorMessage: null,
    style: '',
    valid: (this.props.validator !== null)
  }

  handleChange = (event) => {
    const textValue = event.target.value
    let style = ''
    const errorMessage = this.errorMessageForValue(textValue)
    const newIsValidValue = (errorMessage === null)

    if (!newIsValidValue) {
      style = 'has-error'
    }

    this.setState({ ...this.state, errorMessage: errorMessage, 'style': style, 'valid': newIsValidValue })
    this.props.onChange(this.props.name, textValue, newIsValidValue)
  }

  errorMessageForValue = (value) => {
    const { name, validator } = this.props
    if (!validator) return null

    let errorMessage = null
    const validations = validator[name]
    validations.forEach(function (validation) {
      const isValid = validation.rule(value)
      if (!isValid) {
        errorMessage = validation.errorMessage
      }
    })
    return errorMessage
  }

  render () {
    const {
      name,
      disabled,
      formFieldStyle,
      label,
      possibleOptions,
      initialValue,
      displayFieldName,
      placeHolderText,
      icon,
      displayFieldFunction
    } = this.props

    const { style, errorMessage } = this.state
    const input = (initialValue !== null) ? initialValue : ''
    const isDisabled = disabled === true
    const options = possibleOptions.map((option, index) => {
      if (displayFieldName && option.id && option[displayFieldName]) {
        return <option key={index} value={option.id}>{option[displayFieldName]}</option>
      } else if (displayFieldFunction && option.id) {
        return <option key={index} value={option.id}>{displayFieldFunction(option)}</option>
      } else {
        return <option key={index} value={option}>{option}</option>
      }
    })
    return (
      <div className={'form-group ' + style}>
        <div className='col-xs-12'>
          <div className={formFieldStyle}>
            {label &&
              <label htmlFor={name}>{label}</label>
            }
            {icon &&
              <span className='input-group-addon'><i className='fa fa-user' /></span>
            }
            <select className='form-control'
              value={input}
              id={name}
              name={name}
              size={possibleOptions.count}
              onChange={this.handleChange}
              disabled={isDisabled}>
              { placeHolderText &&
                <option value=''>{placeHolderText}</option>
              }
              {options}
            </select>
            <span id={name + '-error-message'} className='help-block'>{errorMessage}</span>
          </div>
        </div>
      </div>
    )
  }
}

SelectField.propTypes = {
  formFieldStyle: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  possibleOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
  displayFieldName: PropTypes.string,
  displayFieldFunction: PropTypes.func,
  placeHolderText: PropTypes.string,
  validator: PropTypes.object,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SelectField
