import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { LeadCapsule } from 'modules/components';

import CreateLeadFormContainer from '../containers/CreateLeadFormContainer';
import { errorPanelForErrors } from 'utils/formHelpers';

import SweetAlert from 'react-bootstrap-sweetalert';

export class CreateLeadView extends Component {
  state = {
    showAssignWarning: false,
  };

  saveClicked = () => {
    if (this.shouldShowWarning()) {
      this.setState({ ...this.state, showAssignWarning: true });
    } else {
      this.createLead();
    }
  };

  dismissModal = () => {
    const { resetForm, hideModal } = this.props;
    hideModal();
    resetForm();
  };

  shouldShowWarning = () => {
    const { selectedUser, user } = this.props;
    const permission = user.user_permission_rule_set;
    if (selectedUser && selectedUser.id !== user.id && permission.allow_lead_assign === false) {
      return true;
    }
    return false;
  };

  createLead = () => {
    const {
      create,
      selectedLocation,
      data,
      selectedUser,
      selectedLeadSource,
      queryBody,
      selectedHeardAboutMethod,
      selectedLeadGoal,
    } = this.props;
    const leadGoalName = selectedLeadGoal ? selectedLeadGoal.goal_name : undefined;
    const heardAboutMethodId = selectedHeardAboutMethod ? selectedHeardAboutMethod.id : undefined;
    const selectUserId = selectedUser ? selectedUser.id : null;
    create(data, selectedLocation.id, selectUserId, selectedLeadSource.id, heardAboutMethodId, leadGoalName, queryBody);
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showAssignWarning: false });
  };

  readyToSubmit = () => {
    const { data, selectedLocation, selectedLeadSource } = this.props;
    const { isValid } = data;
    if (!selectedLocation || !selectedLeadSource) return false;
    if (isValid !== true) return false;
    return true;
  };

  render() {
    const { existingLead } = this.props;
    return (
      <div>
        {existingLead && (
          <div className="block-content warning">
            <div className="row">
              <div className="col-sm-6">
                <div className="alert alert-warning">
                  <p>
                    {existingLead.display_name} already exists with that email or mobile number. If you need to use the same contact details
                    for a new lead, you can create them without the mobile number/email and set it after they have been created.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <LeadCapsule lead={existingLead} />
              </div>
            </div>
          </div>
        )}
        <div className="block-content">
          {errorPanelForErrors(this.props.errors)}
          <CreateLeadFormContainer />
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.dismissModal}>
            Cancel
          </button>
          <button className="btn btn-success push-5-r push-10" type="button" disabled={!this.readyToSubmit()} onClick={this.saveClicked}>
            <i className="fa fa-user-plus" /> Create
          </button>
        </Modal.Footer>
        {this.state.showAssignWarning && (
          <div>
            <SweetAlert
              warning
              showCancel
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="warning"
              confirmBtnText="Create lead without assigning"
              title="Are you Sure?"
              onConfirm={this.createLead}
              onCancel={this.dismissPopups}
            >
              This lead isn't assigned to you, so you won't be able to action them. Are you sure you want to continue? (If you want to
              action this lead, assign it to yourself before clicking create)
            </SweetAlert>
          </div>
        )}
      </div>
    );
  }
}

CreateLeadView.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object,
  selectedLeadSource: PropTypes.object,
  errors: PropTypes.object,
  existingLead: PropTypes.object,
  create: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  queryBody: PropTypes.object,
  selectedUser: PropTypes.object,
};

export default CreateLeadView;
