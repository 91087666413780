import { request, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import notify from 'modules/Notifications';

import heardAboutMethod from 'modules/Stores/HeardAboutMethods';
import modal from 'modules/Stores/Modal';

export const create = (heardAboutTitle) => {
  return (dispatch) => {
    const body = {
      heard_about_method: {
        heard_about_title: heardAboutTitle,
      },
    };

    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccess());
      dispatch(heardAboutMethod.actions.addHeardAboutMethod(payload.heard_about_method));
      notify.success('Heard about method created');
    }

    return request('heard_about_methods', POST, body, onSuccess, onFailure);
  };
};

export const deleteHeardAboutMethod = (heardAboutMethodId, toHeardAboutMethodId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toHeardAboutMethodId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess() {
      dispatch(modal.actions.stopLoading());
      dispatch(heardAboutMethod.actions.removeHeardAboutMethodWithId(heardAboutMethodId));
      notify.success('Heard about method migrated');
      dispatch(modal.actions.hideModal());
    }
    const url = 'heard_about_methods/' + heardAboutMethodId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const update = (newHeardAboutMethod, title) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    const body = {
      heard_about_method: {
        heard_about_title: title,
      },
    };

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(heardAboutMethod.actions.replaceHeardAboutMethodWithId(payload.heard_about_method));
      dispatch(modal.actions.hideModal());
      notify.success('Heard about method updated');
    }

    return request('heard_about_methods/' + newHeardAboutMethod.id, PUT, body, onSuccess, onFailure);
  };
};

export function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

export function loadSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

export function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
  };
}
