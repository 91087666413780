import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import { newPayloadForFormValue } from 'utils/formHelpers';

import locations from 'modules/Stores/Locations';

import notify from 'modules/Notifications';

export const updateSalesTargetWithData = (location, salesTarget, SalesTargetData) => {
  return (dispatch) => {
    const salesTargetJSON = seralizeSalesTargetsFromFormData(SalesTargetData);
    const body = {
      target_rule_set: salesTargetJSON,
    };
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(updateFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateSuccessful(payload.target_rule_set));
      dispatch(locations.actions.editLocation(mergeLocationAndTarget(location, payload.target_rule_set)));
      notify.success('Lead time out settings updated');
    }

    return request('target_rule_sets/' + salesTarget.id, PUT, body, onSuccess, onFailure);
  };
};

function startUpdating() {
  return {
    type: actionTypes.UPDATING,
  };
}

function mergeLocationAndTarget(location, target) {
  return { ...location, target_rule_set: target };
}

function updateSuccessful(salesTarget) {
  return {
    type: actionTypes.UPDATE_SUCCESS,
    salesTarget,
  };
}

function updateFailed(errors) {
  return {
    type: actionTypes.UPDATE_FAILED,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function prefilSalesTargetsData(salesTargets) {
  return {
    type: actionTypes.PREFIL_TARGET_METRICS,
    salesTargets,
  };
}

function seralizeSalesTargetsFromFormData(formData) {
  const { leadTimeOutMinutes, leadTimeOutEnabled } = formData.fields;

  let payload = {};

  payload = newPayloadForFormValue(payload, leadTimeOutMinutes, 'lead_time_out_minutes');
  payload = newPayloadForFormValue(payload, leadTimeOutEnabled, 'lead_time_out_enabled');

  return payload;
}
