import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ScheduleCalendarView from "../ScheduleCalendarView";

import calendar from "modules/components/Calendar";

import session from "modules/Stores/Session";
import dateTimeSelect from "modules/Stores/DateTimeSelect";

function mapStateToProps(state) {
  return {
    timezone: session.selectors.getUserTimezone(state),
    events: calendar.selectors.getEvents(state),
    loading: calendar.selectors.getLoading(state),
    date: dateTimeSelect.selectors.getDate(state),
    time: dateTimeSelect.selectors.getTime(state),
    length: dateTimeSelect.selectors.getLength(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadAllEventsForUsersInDateRange:
        calendar.actions.loadAllEventsForUsersInDateRange,
      setDate: dateTimeSelect.actions.setDate,
      setTime: dateTimeSelect.actions.setTime,
      setLength: dateTimeSelect.actions.setLength
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCalendarView);
