export const NAME = "salesPerformanceReportFilters";

export const CLUBS = "locations";
export const USERS = "users";
export const LEAD_SOURCES = "lead_sources";
export const GROUPED_USERS = "grouped_users";

export const LEAD_CREATED = "lead_created";
export const EVENT_OCCURED = "event_occured";

export const SELECT_LOCATIONS_MODAL = NAME + "/SELECT_LOCATIONS_MODAL";
export const SELECT_USERS_MODAL = NAME + "/SELECT_USERS_MODAL";

export const ACTIVE = "active";
export const CLOSED = "closed";
export const NOT_ASSIGNED = "not_assigned";
export const INACTIVE = "inactive";
export const LOST = "lost";
