import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EmptyView } from 'modules/components'
import ChartView from './ChartView'

export class StepsView extends Component {

  UNSAFE_componentWillMount () {
    const { fetchWithQueryString, queryString } = this.props
    fetchWithQueryString(queryString)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.queryString !== this.props.queryString)) {
      const { fetchWithQueryString, queryString } = nextProps
      fetchWithQueryString(queryString)
    }
  }

  render () {
    const { lostDataSet, inactiveDataSet, loading, labels } = this.props
    const lostIsEmpty = Object.keys(lostDataSet).length === 0
    const inactiveIsEmpty = Object.keys(inactiveDataSet).length === 0
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div>
        <div className='col-md-6'>
          <div className={blockStyle} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <div className='block-header'>
              <h3 className='block-title'>Lost At Step</h3>
            </div>
            <div className='block-content push-50' style={{ minHeight: '300px', maxHeight: '600px' }}>
              {lostIsEmpty &&
                <div style={{ paddingTop: '60px' }}>
                  <EmptyView
                    colorThemeClass='primary'
                    iconClass={'fa fa-bar-chart'}
                    title='No data for these settings'
                    subtitle='Update your filters or come back later.' />
                </div>
              }
              {!lostIsEmpty &&
                <div className='block-content push-50' style={{ minHeight: '300px', maxHeight: '600px' }}>
                  <ChartView data={lostDataSet} labels={labels} />
                </div>
              }
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className={blockStyle} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <div className='block-header'>
              <h3 className='block-title'>Inactive At Step</h3>
            </div>
            <div className='block-content push-50' style={{ minHeight: '300px', maxHeight: '600px' }}>
              {inactiveIsEmpty &&
                <div style={{ paddingTop: '60px' }}>
                  <EmptyView
                    colorThemeClass='primary'
                    iconClass={'fa fa-bar-chart'}
                    title='No data for these settings'
                    subtitle='Update your filters or come back later.' />
                </div>
              }
              {!inactiveIsEmpty &&
                <div className='block-content push-50' style={{ minHeight: '300px', maxHeight: '600px' }}>
                  <ChartView data={inactiveDataSet} labels={labels} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

StepsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  lostDataSet: PropTypes.arrayOf(PropTypes.object).isRequired,
  inactiveDataSet: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string
}

export default StepsView
