import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import filters from '../modules/filters'
import leadsList from '../modules/leadsList'
import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'
import userData from 'modules/Stores/UserData'

function mapStateToProps (state) {
  return {
    numberOfLeads: leadsList.selectors.getTotalLeads(state),
    user: session.selectors.getUser(state),
    showingMore: filters.selectors.getShowingMore(state),
    data: filters.selectors.getData(state),
    activeExtraFiltersCount: filters.selectors.activeExtraFiltersCount(state),
    hasPrefilledUser: filters.selectors.getHasPrefilledUser(state),
    permissions: userData.selectors.getMembershipPermissions(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateFilters: filters.actions.updateFilters,
    clearAllFilters: filters.actions.clearAllFilters,
    showFilters: filters.actions.showFilters,
    hideFilters: filters.actions.hideFilters,
    showModal: modal.actions.showModal,
    presetUserInFilters: filters.actions.presetUserInFilters
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(filters.components.FiltersView)
