import { NAME } from "./constants";

export const LOADING = NAME + "/LOADING";
export const LOAD_SUCCESS = NAME + "/LOAD_SUCCESS";
export const LOAD_FAILED = NAME + "/LOAD_FAILED";
export const SET_SELECTED_OPTION = NAME + "/SET_SELECTED_OPTION";

export const INTEGRATIONS_LIST_LOADED = NAME + "/INTEGRATIONS_LIST_LOADED";
export const CONFIGURED_INTEGRATIONS_LOADED =
  NAME + "/CONFIGURED_INTEGRATIONS_LOADED";

export const ADD_INTEGRATION = NAME + "/ADD_INTEGRATION";
export const UPDATE_INTEGRATION = NAME + "/UPDATE_INTEGRATION";
export const DELETE_INTEGRATION = NAME + "/DELETE_INTEGRATION";
