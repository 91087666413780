import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { history } from 'store/store';

export class LeadCapsule extends Component {
  viewProfileSelected = () => {
    const { lead } = this.props;
    history.push('/secure/manageLeads?lead_id=' + lead.id);
  };

  render() {
    const { lead } = this.props;

    return (
      <div className="lead-list-row block block-rounded block-bordered">
        <div className="block-content">
          <div className="row">
            <div className="col-xs-10">
              <h3>{lead.display_name}</h3>
            </div>
            <div className="col-xs-2">
              <button className="btn btn-sm btn-primary push-5-r push-10" onClick={this.viewProfileSelected} type="button">
                <i className="fa fa-user" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LeadCapsule.propTypes = {
  lead: PropTypes.func.isRequired,
};

export default LeadCapsule;
