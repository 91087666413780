import MultiLocationSelectModalContainer from './containers/MultiLocationSelectModalContainer';

export default { MultiLocationSelectModalContainer };
export type LocationIdsStructure = {
  isValid: boolean;
  [key: number]: {
    isValid: boolean;
    value: boolean;
  };
};
