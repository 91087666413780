import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const ADD_NOTIFICATIONS = NAME + '/ADD_NOTIFICATIONS'
export const SET_NOTIFICATIONS = NAME + '/SET_NOTIFICATIONS'

export const SET_NOTIFICATION_COUNT = NAME + '/SET_NOTIFICATION_COUNT'
export const INCREASE_NOTIFICATION_COUNT = NAME + '/INCREASE_NOTIFICATION_COUNT'
export const DECREASE_NOTIFICATION_COUNT = NAME + '/DECREASE_NOTIFICATION_COUNT'

export const SET_CURRENT_NOTIFICATION = NAME + '/SET_CURRENT_NOTIFICATION'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
