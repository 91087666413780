import PropTypes from "prop-types";
import React, { Component } from "react";
import { ReportFilterFormStyler } from "modules/components/FilterFormSections/components";
import { transformDataToSection } from "modules/components/SelectDateRangeButton/helpers.js";
import styled from "styled-components";

import {
  HeardAboutMethodsSectionContainer,
  LeadSourceSectionContainer,
  UsersContainer,
  LocationsSectionContainer,
  DateRangeContainer
} from "modules/components/FilterFormSections/modules/filterOptions";

export class FilterForm extends Component {
  onUpdate = (sectionData, sectionName) => {
    const sections = this.props.data;
    const { updateData } = this.props;
    const newSections = { ...sections, [sectionName]: sectionData };
    updateData(newSections);
  };

  onDateSelect = (data, sectionName) => {
    const sections = this.props.data;
    const { updateData } = this.props;
    const newSections = transformDataToSection(data, sectionName, sections);
    updateData(newSections);
  };

  clearAll = () => {
    const { fetchReportWithQueryString, clearAllFilters } = this.props;
    clearAllFilters();
    fetchReportWithQueryString(null);
  };

  updateFilter = () => {
    const { fetchReportWithQueryString, queryString } = this.props;
    fetchReportWithQueryString(queryString);
  };

  render() {
    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <h3 className="block-title">Filters</h3>
        </div>
        <div className="block-content">
          <ReportFilterFormStyler title="Date Range">
            <DateRangeContainer
              onUpdate={this.onDateSelect}
              filterData={this.props.data}
            />
          </ReportFilterFormStyler>
          <ReportFilterFormStyler title="Locations">
            <LocationsSectionContainer
              onUpdate={this.onUpdate}
              filterData={this.props.data}
            />
          </ReportFilterFormStyler>
          <ReportFilterFormStyler title="Salespeople">
            <UsersContainer
              onUpdate={this.onUpdate}
              filterData={this.props.data}
            />
          </ReportFilterFormStyler>
          <ReportFilterFormStyler title="Lead Sources">
            <LeadSourceSectionContainer
              onUpdate={this.onUpdate}
              filterData={this.props.data}
            />
          </ReportFilterFormStyler>
          <ReportFilterFormStyler title="Heard About Methods">
            <HeardAboutMethodsSectionContainer
              onUpdate={this.onUpdate}
              filterData={this.props.data}
            />
          </ReportFilterFormStyler>
          <ButtonContainer>
            <button
              className="btn btn-default"
              type="button"
              onClick={this.clearAll}
            >
              Clear All
            </button>
            <button
              className="btn btn-primary push-5-r pull-right"
              type="button"
              onClick={this.updateFilter}
            >
              <i className="fa fa-filter" /> Update Filter
            </button>
          </ButtonContainer>
        </div>
      </div>
    );
  }
}

FilterForm.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  fetchReportWithQueryString: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired
};

const ButtonContainer = styled.div`
  padding: 10px 0px 25px 0px;
`;
