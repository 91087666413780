import PropTypes from "prop-types";
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import NotificationItem from "./NotificationItem";
import { EmptyView } from "modules/components";
import "./notifications.css";

export class NotificationMenu extends Component {
  state = {
    showing: false,
  };

  UNSAFE_componentWillMount() {
    const {
      subscribeToChannelsForUser,
      getNotificationCount,
      user,
    } = this.props;
    subscribeToChannelsForUser(user);
    getNotificationCount();
  }

  handleScroll = () => {
    const {
      loading,
      currentPage,
      pageCount,
      addNotificationsForPage,
    } = this.props;
    if (loading || currentPage === pageCount) {
      return;
    }
    const element = document.querySelector(".notifications");
    if (element.scrollTop >= element.scrollHeight - element.offsetHeight) {
      addNotificationsForPage(currentPage + 1);
    }
  };

  toggle = () => {
    if (!this.state.showing) {
      this.loadNotificationsForPage(1);
    }
    this.setState({ ...this.state, showing: !this.state.showing });
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showing: false });
  };

  loadNotificationsForPage = (page) => {
    this.props.getNotificationsForPage(page);
  };

  markAllAsRead = () => {
    this.props.setAllNotificationsRead();
  };

  render() {
    const { notifications, loading, notificationCount } = this.props;
    const notificationItems = notifications.map((notification, index) => {
      return (
        <NotificationItem
          notification={notification}
          setNotificationAsRead={this.props.setNotificationAsRead}
          key={index}
        />
      );
    });

    return (
      <div>
        <div className="btn-group">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            style={{ color: "#545454" }}
            onClick={this.toggle}
          >
            {notificationCount > 0 && (
              <span
                className="badge badge-primary"
                style={{ backgroundColor: "#5c90d2", marginRight: "3px" }}
              >
                {notificationCount}
              </span>
            )}
            <i className="fa fa-bell" />
            <span className="caret" />
          </button>
        </div>
        {this.state.showing && (
          <ul
            className="dropdown-menu notifications "
            style={{ display: "block" }}
            onScroll={this.handleScroll}
          >
            <li className="dropdown-header notification-header">
              <span>Notifications</span>
              <span
                className="pull-right selectable"
                onClick={this.markAllAsRead}
              >
                <strong>Mark all as read</strong>
              </span>
            </li>
            {notifications.length > 0 && notificationItems}
            {loading && (
              <div className="text-center">
                <i className="fa fa-2x fa-cog fa-spin" />
              </div>
            )}
            {!loading && notifications.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="fa fa-bell"
                title="No notifications"
                subtitle="Important events will appear here."
              />
            )}
          </ul>
        )}
      </div>
    );
  }
}

NotificationMenu.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  getNotificationsForPage: PropTypes.func.isRequired,
  addNotificationsForPage: PropTypes.func.isRequired,
  getNotificationCount: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  subscribeToChannelsForUser: PropTypes.func.isRequired,
  setAllNotificationsRead: PropTypes.func.isRequired,
  setNotificationAsRead: PropTypes.func.isRequired,
  notificationCount: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
};

export default onClickOutside(NotificationMenu);
