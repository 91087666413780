import { NAME } from "./constants";

export const RESET = NAME + "/RESET";
export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_CALL_SCHEDULES = NAME + "/SET_CALL_SCHEDULES";
export const SET_SELECTED_CALL_SCHEDULES =
  NAME + "/SET_SELECTED_CALL_SCHEDULES";
export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const SET_SELECTED_CALL_SCHEDULE = NAME + "/SET_SELECTED_CALL_SCHEDULE";
