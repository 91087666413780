import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { transformDataToSection } from 'modules/components/SelectDateRangeButton/helpers.js';

import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers';
import * as sessionHelpers from 'modules/Stores/Session/helpers';

import SelectDateRangeContainer from '../containers/SelectDateRangeContainer';
import MultiLocationSelect from 'modules/components/MultiLocationSelect';
import MultiUserSelect from 'modules/components/MultiUserSelect';
import MultiLeadSourceSelect from 'modules/components/MultiLeadSourceSelect';
import MultiHeardAboutMethodSelect from 'modules/components/MultiHeardAboutMethodSelect';
import StagesContainer from '../containers/StagesContainer';
import { ManageLeadsFormStyler } from 'modules/components/FilterFormSections/components';

import { TagsSectionContainer } from 'modules/components/FilterFormSections/containers/';

import { LastContactedSection, MissingActionsSection, TrialsSection } from 'modules/components/FilterFormSections/components';

import TextSearchView from './TextSearchView';

import { SELECT_LOCATIONS_MODAL, SELECT_USERS_MODAL, SELECT_LEAD_SOURCE_MODAL, SELECT_HEARD_ABOUT_METHOD_MODAL } from '../constants';

export class FiltersView extends Component {
  onUpdate = (sectionData, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = { ...sections, [sectionName]: sectionData };
    updateFilters(newSections);
  };

  onDateSelect = (data, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = transformDataToSection(data, sectionName, sections);

    updateFilters(newSections);
  };

  toggleFilters = () => {
    const { showingMore, showFilters, hideFilters } = this.props;
    if (showingMore === true) {
      hideFilters();
    } else {
      showFilters();
    }
  };

  clearAllFilters = () => {
    this.props.clearAllFilters();
    this.textSearch.clear();
  };

  render() {
    const { user, permissions, activeExtraFiltersCount } = this.props;

    const createdBetween = 'statusUpdatedBetween';
    const locationIdsFieldName = 'location_ids';

    const contains = 'contains';
    const leadSources = 'lead_source_ids';
    const heardAboutMethods = 'heard_about_method_ids';
    const userIdsFieldName = 'assigned_to_ids';
    const lastContacted = 'lastContacted';
    const trials = 'trials';
    const missingActions = 'no_due_actions';

    const nameContainsData = this.props.data[contains] || { fields: {} };
    const createdBetweenData = this.props.data[createdBetween] || {
      fields: {},
    };
    const locationIdsData = this.props.data[locationIdsFieldName] || {
      fields: {},
    };
    const lastContactedData = this.props.data[lastContacted] || { fields: {} };
    const trialsData = this.props.data[trials] || { fields: {} };
    const missingActionsData = this.props.data[missingActions] || {
      fields: {},
    };

    const leadSourcesData = this.props.data[leadSources] || { fields: {} };
    const heardAboutMethodsData = this.props.data[heardAboutMethods] || {
      fields: {},
    };
    const tagData = this.props.data.tags || { fields: {} };
    const userIdData = this.props.data[userIdsFieldName] || { fields: {} };

    const locationIds = Object.keys(locationIdsData.fields).map((id) => parseInt(id));
    const userIds = Object.keys(userIdData.fields).map((id) => parseInt(id));
    const leadSourceIds = Object.keys(leadSourcesData.fields).map((id) => parseInt(id));
    const heardAboutMethodIds = Object.keys(heardAboutMethodsData.fields).map((id) => parseInt(id));
    const clubTitle = locationIds.length > 0 ? locationIds.length + ' selected' : 'All locations';
    const usersTitle = userIds.length > 0 ? userIds.length + ' selected' : 'All users';
    const leadSourceTitle = leadSourceIds.length > 0 ? leadSourceIds.length + ' selected' : 'All lead sources';
    const heardAboutMethodTitle = heardAboutMethodIds.length > 0 ? heardAboutMethodIds.length + ' selected' : 'Everywhere';
    const clubsFiltersClass = locationIds.length > 0 ? 'btn btn-info' : 'btn btn-default';
    const usersFiltersClass = userIds.length > 0 ? 'btn btn-info' : 'btn btn-default';
    const leadSourceFiltersClass = leadSourceIds.length > 0 ? 'btn btn-info' : 'btn btn-default';
    const heardAboutFiltersClass = heardAboutMethodIds.length > 0 ? 'btn btn-info' : 'btn btn-default';

    return (
      <div className="block block-rounded block-bordered" style={{ paddingBottom: '15px' }}>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="h3 font-w400 push-10-t">{pluralizationHelper('Leads', this.props.numberOfLeads)}</div>
            <div>
              {activeExtraFiltersCount > 0 && (
                <span className="label label-info selectable" onClick={this.clearAllFilters}>
                  {pluralizationHelper('filters', activeExtraFiltersCount)} active (click to clear)
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row push-10">
          <div className="col-md-12">
            <TextSearchView
              ref={(input) => {
                this.textSearch = input;
              }}
              data={nameContainsData}
              onUpdate={this.onUpdate}
              sectionName={contains}
            />
          </div>
        </div>
        <div className="row push-10">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by clubs:</h3>
            <button
              className={'btn ' + clubsFiltersClass + ' push-5-r push-10'}
              type="button"
              onClick={() => this.props.showModal(SELECT_LOCATIONS_MODAL)}
            >
              {clubTitle}
            </button>
            <MultiLocationSelect.MultiLocationSelectModalContainer
              modalName={SELECT_LOCATIONS_MODAL}
              sectionName={locationIdsFieldName}
              onUpdate={this.onUpdate}
              data={locationIdsData}
            />
          </div>
        </div>
        {sessionHelpers.userCanViewOthersLeads(user) && permissions.multi_user && (
          <div className="row push-10">
            <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
              <h3 className="block-title push-10">Filter by Salespeople:</h3>
              <button
                className={'btn ' + usersFiltersClass + ' push-5-r push-10'}
                type="button"
                onClick={() => this.props.showModal(SELECT_USERS_MODAL)}
              >
                {usersTitle}
              </button>
              <MultiUserSelect.MultiUserSelectDataModalContainer
                data={userIdData}
                modalName={SELECT_USERS_MODAL}
                sectionName={userIdsFieldName}
                onUpdate={this.onUpdate}
                locationIds={locationIds}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12" style={{ paddingLeft: '35px', paddingRight: '35px' }}>
            <StagesContainer />
          </div>
        </div>
        <div className="row push-20">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by last contacted:</h3>
            <LastContactedSection sectionName={lastContacted} data={lastContactedData} onUpdate={this.onUpdate} timezone={user.timezone} />
          </div>
        </div>
        <div className="row push-20">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by Trials:</h3>
            <TrialsSection sectionName={trials} data={trialsData} onUpdate={this.onUpdate} timezone={user.timezone} />
          </div>
        </div>
        <div className="row push-20">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by missing actions:</h3>
            <MissingActionsSection sectionName={missingActions} data={missingActionsData} onUpdate={this.onUpdate} />
          </div>
        </div>
        <div className="row push-20">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by when status changed:</h3>
            <SelectDateRangeContainer sectionName={createdBetween} onUpdate={this.onDateSelect} data={createdBetweenData} />
          </div>
        </div>
        <div className="row push-10">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by source:</h3>
            <button
              className={'btn ' + leadSourceFiltersClass + ' push-5-r push-10'}
              type="button"
              onClick={() => this.props.showModal(SELECT_LEAD_SOURCE_MODAL)}
            >
              {leadSourceTitle}
            </button>
            <MultiLeadSourceSelect.MultiLeadSourceSelectModalContainer
              modalName={SELECT_LEAD_SOURCE_MODAL}
              sectionName={leadSources}
              onUpdate={this.onUpdate}
              data={leadSourcesData}
            />
          </div>
        </div>
        <div className="row push-10">
          <div className="col-md-12" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <h3 className="block-title push-10">Filter by where they heard about you:</h3>
            <button
              className={'btn ' + heardAboutFiltersClass + ' push-5-r push-10'}
              type="button"
              onClick={() => this.props.showModal(SELECT_HEARD_ABOUT_METHOD_MODAL)}
            >
              {heardAboutMethodTitle}
            </button>
            <MultiHeardAboutMethodSelect.MultiHeardAboutMethodSelectModalContainer
              modalName={SELECT_HEARD_ABOUT_METHOD_MODAL}
              sectionName={heardAboutMethods}
              onUpdate={this.onUpdate}
              data={heardAboutMethodsData}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ManageLeadsFormStyler title="Filter by Tags">
              <TagsSectionContainer sectionName="tags" onUpdate={this.onUpdate} data={tagData} />
            </ManageLeadsFormStyler>
          </div>
        </div>
      </div>
    );
  }
}

FiltersView.propTypes = {
  numberOfLeads: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  showingMore: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  showFilters: PropTypes.func.isRequired,
  hideFilters: PropTypes.func.isRequired,
  activeExtraFiltersCount: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default FiltersView;
