import * as actionTypes from "./actionTypes";


const TRIALS_REPORT_LEAD_STATUS_TABLE_ACTION_HANDLERS = {
  [actionTypes.SET_TRIALS]: (state, action) => {
    return { ...state, trials: action.trials };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_TABLE]: (state, action) => {
    return { ...state, table: !state.table };
  },
  [actionTypes.SET_SELECTED_CELL]: (state, action) => {
    return { ...state, selectedCell: action.selectedCell };
  }
};

const initialState = {
  errors: null,
  loading: false,
  table: false,
  selectedCell: null,
  trials: null
};

export default function reducer(state = initialState, action) {
  const handler = TRIALS_REPORT_LEAD_STATUS_TABLE_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
