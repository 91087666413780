import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import modal from 'modules/Stores/Modal';
import { selectedEvent } from '../selectors';
import AppointmentModalView from '../components/AppointmentModalView';
import * as actions from '../actions';
import leadsTrials from 'modules/components/LeadDetail/modules/leadsTrials';
import classes from 'modules/Stores/Classes';
import Session from 'modules/Stores/Session';
import userSelect from 'containers/Calendar/modules/userSelect';

const { hideModal, showModal } = modal.actions;

function mapStateToProps(state, ownProps) {
  const { classIntegrationId, classIntegrationName } =
    classes.selectors.locationUsesClassesFactory(selectedEvent(state)?.lead?.location_id)(state) ?? null;

  return {
    currentModal: state.modal.currentModal,
    selectedEvent: selectedEvent(state),
    loading: modal.selectors.getModalLoading(state),
    scheduleCallModalName: `${ownProps.modalName}/RECORD_APP_COMPLETE_APPOINTMENT_MODAL`,
    rescheduleModalName: `${ownProps.modalName}/RECORD_APP_RESCHEDULE_MODAL`,
    classAppointmentModalName: 'RECORD_APP_CLASS_APPOINTMENT_MODAL',
    classIntegrationId: classIntegrationId,
    classIntegrationName: classIntegrationName,
    timezone: Session.selectors.getUserTimezone(state),
    eventTypes: userSelect.selectors.getTypes(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      showModal,
      addLeadToTrial: leadsTrials.actions.addLeadToTrial,
      toggleComplete: actions.toggleAppointmentComplete,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentModalView);
