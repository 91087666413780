/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import { ContentBlockView, EmptyView, TextSearchView, PagesView } from 'modules/components';

export class LostReasonModal extends Component {
  UNSAFE_componentWillMount() {
    const { leadLostReasons } = this.props;
    if (leadLostReasons.length > 0) {
      return;
    }
    this.getLeadLostReasonsWithPageNumber(1);
  }

  getLeadLostReasonsWithPageNumber = (pageNumber) => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadLostReasonsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(1, searchTerm);
  };

  setLeadToLost = (reason) => {
    const { updateLeadToStatus, lead, listUpdateProtocol, hideModal } = this.props;
    hideModal();
    updateLeadToStatus(lead, 'lost', lead.sales_funnel_step, reason.id, listUpdateProtocol);
  };

  render() {
    const { loading, currentModal, hideModal, modalName, leadLostReasons, pageDetails } = this.props;

    const pageCount = pageDetails.pageCount;

    const reasons = leadLostReasons.map((reason, index) => {
      return (
        <div key={index} className="col-lg-6">
          <a className="block block-link-hover2 selectable" onClick={() => this.setLeadToLost(reason)}>
            <div className="block-content block-content-full clearfix bg-danger">
              <span className="h4 text-white-op">{reason.lost_reason_title}</span>
            </div>
          </a>
        </div>
      );
    });

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-danger">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Why don't they want to join?
              </h3>
            </div>
            <ContentBlockView loading={loading} bordered>
              <div className="row" style={{ marginTop: '-20px' }}>
                <TextSearchView sectionName={'leadLostReasonSearch'} onUpdate={this.searchUpdated} />
              </div>
              {leadLostReasons.length > 0 && (
                <div>
                  <div className="row">{reasons}</div>
                </div>
              )}
              {leadLostReasons.length === 0 && (
                <EmptyView colorThemeClass="primary" iconClass="si si-magnifier" title="No results" subtitle="Check the search field" />
              )}
              {pageCount > 1 && (
                <div className="text-center push">
                  <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
                </div>
              )}
            </ContentBlockView>
          </div>
        </Modal>
      </div>
    );
  }
}

LostReasonModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  leadLostReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateLeadToStatus: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

LostReasonModal.defaultProps = {
  listUpdateProtocol: {},
};

export default LostReasonModal;
