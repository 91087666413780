import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditLeadLostReasonModal from '../components/EditLeadLostReasonModal'

import * as leadLostReasonActions from '../actions'

import modal from 'modules/Stores/Modal'
const { hideModal } = modal.actions

function mapStateToProps (state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal,
    updateLeadLostReason: leadLostReasonActions.update
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadLostReasonModal)
