import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class RegionRow extends Component {

  deleteClicked = (event) => {
    const { region, onDelete } = this.props
    onDelete(region.id)
  }

  templateClicked = (event) => {
    if (event.target.id && event.target.id === 'delete') { return }
    const { region, onSelect } = this.props
    onSelect(region.id)
  }

  render () {
    const { region, selected } = this.props

    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''

    return (
      <tr className={textClass + ' selectable ' + backgroundClass} onClick={this.templateClicked}>
        <td className='font-w600'>
          {region.name}
        </td>
        <td>
          {region.location_count}
        </td>
        <td className='text-center'>
          <div className='btn-group'>
            <button id='delete' className='btn btn-xs btn-default' type='button' onClick={this.deleteClicked}>
              <i id='delete' className='fa fa-times' />
            </button>
          </div>
        </td>
      </tr>
    )
  }
}

RegionRow.propTypes = {
  region: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default RegionRow
