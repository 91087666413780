import PropTypes from "prop-types";
import React, { Component } from "react";
import { LeadNoteItem } from "./LeadNoteItem";
import "./LeadNotes.css";
import { EmptyView } from "modules/components";
import NewNoteModal from "./NewNoteModal";
import EditNoteModal from "./EditNoteModal";

import { NEW_NOTE_MODAL, EDIT_NOTE_MODAL } from "../constants";

export class LeadNotesView extends Component {
  state = {
    selectedNote: null,
  };

  addNote = () => {
    this.props.showModal(NEW_NOTE_MODAL);
  };

  editNote = (note) => {
    this.setState({ ...this.state, selectedNote: note });
    this.props.showModal(EDIT_NOTE_MODAL);
  };

  render() {
    const { notes, lead, modalLoading, loading, user } = this.props;
    const { selectedNote } = this.state;

    const notesList = notes.map((note, index) => {
      const canEdit = note.user ? user.id === note.user.id : true;
      return (
        <LeadNoteItem
          note={note}
          editClicked={this.editNote}
          noteSelected={this.viewNote}
          canEdit={canEdit}
          key={index}
        />
      );
    });

    return (
      <div className="push-20">
        {loading && (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h3 className="push-10">Loading Notes</h3>
              <i className="fa fa-2x fa-sun-o fa-spin" />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            {notes.length > 0 && (
              <div>
                <button
                  className="btn btn-success push-5-r push-10"
                  type="button"
                  onClick={this.addNote}
                >
                  <i className="fa fa-plus" /> Add note
                </button>
                <ul className="list list-simple">{notesList}</ul>
              </div>
            )}
            {notes.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="si si-note"
                title="No notes yet"
                subtitle="Add a note for others to read about this lead"
                buttonText="Add a note"
                buttonSelected={this.addNote}
              />
            )}
            <NewNoteModal
              ref="newNoteModal"
              lead={lead}
              modalName={NEW_NOTE_MODAL}
              currentModal={this.props.currentModal}
              createNoteForLead={this.props.createNoteForLead}
              hideModal={this.props.hideModal}
              loading={modalLoading}
            />
            {selectedNote && (
              <EditNoteModal
                lead={lead}
                note={selectedNote}
                modalName={EDIT_NOTE_MODAL}
                currentModal={this.props.currentModal}
                updateNoteForLead={this.props.updateNoteForLead}
                hideModal={this.props.hideModal}
                loading={modalLoading}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

LeadNotesView.propTypes = {
  user: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  createNoteForLead: PropTypes.func.isRequired,
  updateNoteForLead: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};
