import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { InvoiceLineItemRow } from './InvoiceLineItemRow';

export class InvoiceModalView extends Component {
  cleanUpAndHideModal = () => {
    const { selectInvoiceId, hideModal } = this.props;
    selectInvoiceId(null);
    hideModal();
  };

  downloadPdf = () => {
    const { invoice, getInvoicePdfForId } = this.props;
    getInvoicePdfForId(invoice.id);
  };

  render() {
    const { invoice, currentModal, modalName } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={this.cleanUpAndHideModal} dialogClassName="modal-lg">
        <div className="block">
          <div className="block-header">
            <h3 className="block-title">#{invoice.invoice_number}</h3>
          </div>
          <div className="block-content block-content-narrow">
            <div className="h1 text-center push-30-t push-30 hidden-print">INVOICE</div>
            <hr className="hidden-print" />
            <div className="row items-push-2x">
              <div className="col-xs-6 col-sm-4 col-lg-3">
                <p className="h2 font-w400 push-5">Company</p>
                <address>
                  {invoice.company_details.name}
                  <br />
                  {invoice.company_details.address}
                  <br />
                  ACN: {invoice.company_details.acn}
                  <br />
                  <i className="si si-call-end" /> {invoice.company_details.number}
                </address>
              </div>

              <div className="col-xs-6 col-sm-4 col-sm-offset-4 col-lg-3 col-lg-offset-6 text-right">
                <p className="h2 font-w400 push-5">Customer</p>
                <address>
                  {invoice.client_details.name}
                  <br />
                </address>
              </div>
            </div>

            <div className="table-responsive push-50">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: '50px' }} />
                    <th>Charge</th>
                    <th className="text-center" style={{ width: '100px' }}>
                      Quantity
                    </th>
                    <th className="text-right" style={{ width: '120px' }}>
                      Unit
                    </th>
                    <th className="text-right" style={{ width: '120px' }}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.invoice_line_items.map((lineItem, index) => {
                    return <InvoiceLineItemRow invoiceLineItem={lineItem} index={index + 1} key={index} />;
                  })}
                  <tr>
                    <td colSpan="4" className="font-w600 text-right">
                      Subtotal
                    </td>
                    <td className="text-right">$ {invoice.subtotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="font-w600 text-right">
                      Tax
                    </td>
                    <td className="text-right">{(invoice.tax_rate * 100).toFixed(0)}%</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="font-w600 text-right">
                      Tax Due
                    </td>
                    <td className="text-right">$ {invoice.tax_subtotal.toFixed(2)}</td>
                  </tr>
                  <tr className="active">
                    <td colSpan="4" className="font-w700 text-uppercase text-right">
                      Total Due
                    </td>
                    <td className="font-w700 text-right">$ {invoice.total.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="hidden-print" />
            <p>
              The above amount has been charged to your {invoice.payment_type} ending in {invoice.card_ending}. Thank you for your business.
              Please contact us with any questions regarding this invoice.
            </p>
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.downloadPdf}>
            Download as PDF <i className="si si-cloud-download" />
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndHideModal}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

InvoiceModalView.propTypes = {
  invoice: PropTypes.object.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  selectInvoiceId: PropTypes.func.isRequired,
  getInvoicePdfForId: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default InvoiceModalView;
