import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class ButtonsView extends Component {

  cancel = () => {
    this.props.hideModal()
  }

  nextClicked = () => {
    const { location, data, addOrUpdateOptionWithData } = this.props
    addOrUpdateOptionWithData(data, location, 1)
  }

  render () {
    const { nextIsReady } = this.props
    const nextDisabledStyle = (nextIsReady) ? '' : 'disabled'

    return (
      <div className='row'>
        <div className='col-xs-6 text-left'>
          <button className='wizard-prev btn btn-default' onClick={this.cancel}>
            Cancel
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          <button className={'wizard-next btn btn-default ' + nextDisabledStyle}
            disabled={!nextIsReady}
            onClick={this.nextClicked}>
            Next <i className='fa fa-arrow-right' />
          </button>
        </div>
      </div>
    )
  }
}

ButtonsView.propTypes = {
  addOrUpdateOptionWithData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
  nextIsReady: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
}

export default ButtonsView
