import React, { FC, useCallback, useState } from 'react';
import { NAME as LEAD_DETAILS_MODAL_NAME } from 'containers/Dashboard/modules/LeadDetailsModal/constants';
import { ILocation, IMembershipPermissions, IUser } from 'types';
import * as sessionHelpers from 'modules/Stores/Session/helpers';
import HeaderImage from 'images/dashboard@2x.jpg';
import { PageHeader } from 'modules/components';

import CallListContainer from '../containers/ComponentContainers/CallListContainer';
import AppointmentListContainer from '../containers/ComponentContainers/AppointmentListContainer';
import TaskListContainer from '../containers/ComponentContainers/TaskListContainer';

import UserSalesTargetsContainer from '../containers/ComponentContainers/UserSalesTargetsContainer';
import UpdatesContainer from '../containers/ComponentContainers/UpdatesContainer';
import UserSalesTeamContainer from '../containers/ComponentContainers/UserSalesTeamContainer';
import SetDashboardTypeButtonContainer from '../containers/ComponentContainers/SetDashboardTypeButtonContainer';
import SelectDateRangeContainer from '../containers/ComponentContainers/SelectDateRangeContainer';

import QueuedLeads from '../modules/QueuedLeads';
import SharedInbox from '../modules/SharedInbox';

import { LeadDetailsModalContainer } from 'containers/Dashboard/modules/LeadDetailsModal/containers';

interface ISalespersonDashboardViewProps {
  showModal: (modalName: string) => void;
  clearCurrentLocation: () => void;
  selectDateRange: (startDate: Date, endDate: Date) => void;
  isMultiLocationAccount: boolean;
  location: ILocation;
  user: IUser;
  permissions: IMembershipPermissions;
}

const SalespersonDashboardView: FC<ISalespersonDashboardViewProps> = (props) => {
  const { user, permissions, isMultiLocationAccount, location, clearCurrentLocation } = props;
  const [selectedLeadId, setSelectedLeadId] = useState<number | undefined>(undefined);

  function onDateChange(data: any) {
    const keys = Object.keys(data);
    const afterKey = keys[0];
    const startDate = data[afterKey];

    const beforeKey = keys[1];
    const endDate = data[beforeKey];

    props.selectDateRange(startDate, endDate);
  }

  function openLeadModal(leadId: number) {
    setSelectedLeadId(leadId);

    props.showModal(LEAD_DETAILS_MODAL_NAME);
  }

  const getPageHeaderChildren = useCallback(() => {
    function backSelected() {
      clearCurrentLocation();
    }

    if (!isMultiLocationAccount) return <div />;
    return <i className="si si-arrow-left fa-2x pull-left text-white selectable" onClick={backSelected} />;
  }, [isMultiLocationAccount, clearCurrentLocation]);

  return (
    <div className="content content-boxed">
      <PageHeader
        title={`Hey ${user.given_name}`}
        subtitle="Here's your action plan for today"
        image={HeaderImage}
        headerChildren={getPageHeaderChildren()}
        headerLowerChildren={<HeaderLowerChildren user={user} permissions={permissions} onDateChange={onDateChange} />}
      />
      {location && (
        <div>
          <div className="row">
            <div className="col-md-4">
              <CallListContainer openLeadModal={openLeadModal} location={location} />
            </div>
            <div className="col-md-4">
              <AppointmentListContainer openLeadModal={openLeadModal} location={location} />
            </div>
            <div className="col-md-4">
              <TaskListContainer location={location} openLeadModal={openLeadModal} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <UserSalesTargetsContainer location={location} />
            </div>
            <div className="col-lg-8">
              {sessionHelpers.userCanAssign(user) && (
                <div className="row">
                  <div className="col-lg-12">
                    <QueuedLeads.Container />
                  </div>
                </div>
              )}
              {sessionHelpers.userCanViewOthersLeads(user) && (
                <div className="row">
                  <div className="col-lg-12">
                    <SharedInbox.Container />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-12">
                  <UserSalesTeamContainer />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <UpdatesContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <LeadDetailsModalContainer leadId={selectedLeadId} />
    </div>
  );
};

interface IHeaderLowerChildrenProps {
  user: IUser;
  permissions: IMembershipPermissions;
  onDateChange: (data: any) => void;
}

const HeaderLowerChildren: FC<IHeaderLowerChildrenProps> = (props) => {
  return (
    <div className="row">
      <div className="col-sm-4 col-sm-offset-8 push-10">
        {sessionHelpers.canSwitchDashboard(props.user, props.permissions) && (
          <span style={{ float: 'left' }}>
            <SetDashboardTypeButtonContainer />
          </span>
        )}
        <span style={{ float: 'right', width: '50%' }}>
          <SelectDateRangeContainer
            buttonTitlePrefix={'Schedule: '}
            highlightOnChange={false}
            sectionName={'selectDate'}
            onUpdate={props.onDateChange}
          />
        </span>
      </div>
    </div>
  );
};

export { SalespersonDashboardView };
