import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { HorizontalBar } from 'react-chartjs-2'

export default class ChartView extends Component {

  generateOptions () {
    return {
      tooltips: {
        enabled: true
      },
      hover :{
        animationDuration:0
      },
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero:true,
            fontSize:11
          }
        }],
        yAxes: [{
          barThickness: 25,
          ticks: {
            fontSize:11
          }
        }]
      },
      legend:{
        display: false
      }
    }
  }

  render () {
    const { labels, dataSets } = this.props
    const chartData = {
      type: 'horizontalBar',
      labels: labels,
      datasets: dataSets
    }

    return (
      <HorizontalBar
        data={chartData}
        redraw={false}
        options={this.generateOptions()} />
    )
  }
}

ChartView.propTypes = {
  dataSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}
