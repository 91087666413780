import { FormFields } from 'modules/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateNewFormState } from 'utils/formHelpers';

import { EditWaiverValidator } from '../validators';

import TagContainer from '../containers/TagContainer';

import HeardAboutMethodSelect from 'modules/components/HeardAboutMethodSelect';
import LeadSourceSelect from 'modules/components/LeadSourceSelect';
import LocationSelect from 'modules/components/LocationSelect';
import SelectUserModal from 'modules/components/SelectUserModal';
import { SELECT_HEARD_ABOUT_METHOD_MODAL, SELECT_LEAD_SOURCE_MODAL, SELECT_LOCATION_MODAL, SELECT_USER_MODAL } from '../constants';
const { TextField, Switch } = FormFields;

export class EditEmailMapForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid);
    this.props.onInput(newData);
  };

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true);
    this.props.onInput(newData);
  };

  locationSelected = (location) => {
    let newData = generateNewFormState(this.props.data, 'locationId', location.id, true);
    newData = generateNewFormState(this.props.data, 'locationName', location.admin_display_name, true);
    this.props.onInput(newData);
    this.props.hideModal();
  };

  userSelected = (user) => {
    if (user) {
      let newData = generateNewFormState(this.props.data, 'userId', user.id, true);
      newData = generateNewFormState(this.props.data, 'userName', user.display_name, true);
      this.props.onInput(newData);
    } else {
      let newData = generateNewFormState(this.props.data, 'userId', null, true);
      newData = generateNewFormState(this.props.data, 'userName', null, true);
      this.props.onInput(newData);
    }
    this.props.hideModal();
  };

  leadSourceSelected = (leadSource) => {
    let newData = generateNewFormState(this.props.data, 'leadSourceId', leadSource.id, true);
    newData = generateNewFormState(this.props.data, 'leadSourceName', leadSource.source_name, true);
    this.props.onInput(newData);
    this.props.hideModal();
  };

  heardAboutMethodSelected = (heardAboutMethod) => {
    if (heardAboutMethod) {
      let newData = generateNewFormState(this.props.data, 'heardAboutMethodId', heardAboutMethod.id, true);
      newData = generateNewFormState(this.props.data, 'heardAboutMethodName', heardAboutMethod.heard_about_title, true);
      this.props.onInput(newData);
    } else {
      let newData = generateNewFormState(this.props.data, 'heardAboutMethodId', null, true);
      newData = generateNewFormState(this.props.data, 'heardAboutMethodName', null, true);
      this.props.onInput(newData);
    }
    this.props.hideModal();
  };

  render() {
    const {
      name,
      urlString,
      leadSourceId,
      heardAboutMethodId,
      locationId,
      locationName,
      userName,
      userId,
      leadSourceName,
      ignoreReopens,
      ignoreReopenForwardAddress,
      heardAboutMethodName,
    } = this.props.data.fields;
    const locationButtonString = locationName.value || 'Select a location';
    const userButtonString = userName.value || 'Select a default user';
    const leadSourceButtonString = leadSourceName.value || 'Select a lead source';
    const heardAboutMethodButtonString = heardAboutMethodName.value || 'Select a heard about method';
    const locationIdValue = locationId.value ? parseInt(locationId.value) : null;
    const userIdValue = userId.value ? parseInt(userId.value) : null;

    const leadSourceIdValue = leadSourceId.value ? parseInt(leadSourceId.value) : null;

    const heardAboutMethodIdValue = heardAboutMethodId.value ? parseInt(heardAboutMethodId.value) : null;
    return (
      <div>
        <div
          className="row"
          style={{
            marginLeft: '-30px',
            marginRight: '-30px',
            marginTop: '-15px',
          }}
        >
          <div className="col-sm-12">
            <TextField
              name="name"
              label="Email Map name *"
              placeholder="Landing page email map"
              formFieldStyle=""
              onChange={this.handleInput}
              value={name.value}
              validator={EditWaiverValidator}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: '15px' }}>
          <div className="col-sm-12">
            <h3 style={{ display: 'inline-block', fontSize: '13px' }}>Don't reopen existing leads</h3>
            <div
              style={{
                marginLeft: '-15px',
                marginRight: '-15px',
                marginTop: '-15px',
              }}
            >
              <Switch
                name="ignoreReopens"
                label="If enabled, we won't reopen any existing leads in the system. The email will be sent directly to the owners address (or specify a different one below). Useful if you're getting general enquiries from members."
                onChange={this.handleToggle}
                switchStyle="switch-sm switch-primary"
                value={ignoreReopens.value}
              />
            </div>
            {ignoreReopens.value === true && (
              <div
                className="col-sm-12"
                style={{
                  marginLeft: '-30px',
                  marginRight: '-30px',
                  marginTop: '-15px',
                }}
              >
                <TextField
                  name="ignoreReopenForwardAddress"
                  label="Ignored emails forward address"
                  placeholder="support@mygym.com"
                  formFieldStyle=""
                  onChange={this.handleInput}
                  value={ignoreReopenForwardAddress.value}
                  validator={EditWaiverValidator}
                />
              </div>
            )}
          </div>
          <div className="col-sm-12" style={{ marginTop: '15px' }}>
            <h3 className="push-15" style={{ display: 'inline-block', fontSize: '13px' }}>
              Default club *
            </h3>
            <br />
            <button
              className="btn btn-default push-5-r push-10"
              style={{ display: 'inline-block' }}
              type="button"
              onClick={() => this.props.showModal(SELECT_LOCATION_MODAL)}
            >
              {locationButtonString}
            </button>
          </div>
        </div>
        {locationIdValue && (
          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-12">
              <h3 className="push-15" style={{ display: 'inline-block', fontSize: '13px' }}>
                Automatically assign to user
              </h3>
              <br />
              <button
                className="btn btn-default push-5-r push-10"
                style={{ display: 'inline-block' }}
                type="button"
                onClick={() => this.props.showModal(SELECT_USER_MODAL)}
              >
                {userButtonString}
              </button>
            </div>
          </div>
        )}
        <div className="row" style={{ marginTop: '15px' }}>
          <div className="col-sm-12">
            <h3 className="push-15" style={{ display: 'inline-block', fontSize: '13px' }}>
              Default lead source
            </h3>
            <br />
            <button
              className="btn btn-default push-5-r push-10"
              style={{ display: 'inline-block' }}
              type="button"
              onClick={() => this.props.showModal(SELECT_LEAD_SOURCE_MODAL)}
            >
              {leadSourceButtonString}
            </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: '15px' }}>
          <div className="col-sm-12">
            <h3 className="push-15" style={{ display: 'inline-block', fontSize: '13px' }}>
              Default heard about method
            </h3>
            <br />
            <button
              className="btn btn-default push-5-r push-10"
              style={{ display: 'inline-block' }}
              type="button"
              onClick={() => this.props.showModal(SELECT_HEARD_ABOUT_METHOD_MODAL)}
            >
              {heardAboutMethodButtonString}
            </button>
          </div>
        </div>
        <div
          className="row"
          style={{
            marginLeft: '-30px',
            marginRight: '-30px',
            marginBottom: '15px',
          }}
        >
          <div className="col-xs-12">
            <TextField
              name="urlString"
              label="Web form URL"
              placeholder="www.yourawesomegym.com/contact-us"
              formFieldStyle=""
              onChange={this.handleInput}
              value={urlString.value}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '15px' }}>
          <div className="col-sm-12">
            <TagContainer />
          </div>
        </div>
        <LocationSelect.LocationSelectModalContainer
          modalName={SELECT_LOCATION_MODAL}
          selectedLocationId={locationIdValue}
          onLocationSelect={this.locationSelected}
        />
        {locationIdValue && (
          <SelectUserModal.SelectUserModalModalContainer
            modalName={SELECT_USER_MODAL}
            onLocationSelect={this.locationSelected}
            locationId={locationIdValue}
            selectedUserId={userIdValue}
            onUserSelect={this.userSelected}
          />
        )}
        <LeadSourceSelect.LeadSourceSelectModalContainer
          modalName={SELECT_LEAD_SOURCE_MODAL}
          selectedLeadSourceId={leadSourceIdValue}
          onLeadSourceSelect={this.leadSourceSelected}
        />
        <HeardAboutMethodSelect.HeardAboutMethodSelectModalContainer
          modalName={SELECT_HEARD_ABOUT_METHOD_MODAL}
          selectedHeardAboutMethodId={heardAboutMethodIdValue}
          onHeardAboutMethodSelect={this.heardAboutMethodSelected}
        />
      </div>
    );
  }
}

EditEmailMapForm.propTypes = {
  data: PropTypes.object.isRequired,
  emailMap: PropTypes.object,
  onInput: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
};

export default EditEmailMapForm;
