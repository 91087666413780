import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import selectCSV from '../modules/selectCSV'
import { getTabIndex } from '../selectors'
import { getProductName } from 'modules/Stores/UserData/selectors'


function mapStateToProps (state) {
  const activeTab = getTabIndex(state)
  return {
    isActive: (activeTab === 0),
    csvFile: selectCSV.selectors.getCSV(state),
    errorString: selectCSV.selectors.getErrorString(state),
    enhancedPrivacy: selectCSV.selectors.getEnhancedPrivacy(state),
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    csvWasSelected: selectCSV.actions.csvWasSelected
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(selectCSV.components.SelectCSVView)
