import PropTypes from "prop-types";
import React, { Component } from "react";

export class UpdatesView extends Component {
  UNSAFE_componentWillMount() {
    this.fetchHistoryitems();
  }

  fetchHistoryitems = () => {
    // fetch();
  };

  render() {
    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <ul className="block-options">
            <li>
              <button type="button" onClick={this.fetchHistoryitems}>
                <i className="si si-refresh" />
              </button>
            </li>
          </ul>
          <h3 className="block-title"> Updates</h3>
        </div>
      </div>
    );
  }
}

UpdatesView.propTypes = {
  historyItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default UpdatesView;
