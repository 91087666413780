export function idForSalesFunnelStepLink(salesFunnelStepLink) {
  if (salesFunnelStepLink.fromStep) {
    return (
      salesFunnelStepLink.fromStep.id + "-" + salesFunnelStepLink.toStep.id
    );
  } else {
    return String(salesFunnelStepLink.toStep.id);
  }
}

export function displayNameForSalesFunnelStepLink(salesFunnelStepLink) {
  if (salesFunnelStepLink.fromStep) {
    return (
      salesFunnelStepLink.fromStep.name +
      " to " +
      salesFunnelStepLink.toStep.name
    );
  } else {
    return "To " + salesFunnelStepLink.toStep.name;
  }
}

export function salesFunnelStepLinkJSONForId(salesFunnelStepLinkId, type) {
  const array = salesFunnelStepLinkId.split("-");
  if (array.length === 1) {
    return {
      to_step_id: array[0],
      type: type
    };
  } else {
    return {
      from_step_id: array[0],
      to_step_id: array[1],
      type: type
    };
  }
}

export function salesFunnelStepLinkWithType(salesFunnelStepLink, type) {
  return { ...salesFunnelStepLink, type: type };
}

export function filterDataFromQueryBody(queryBody) {
  let filterData = {};
  const allKeys = Object.keys(queryBody);
  allKeys.forEach((key, index) => {
    const data = queryBody[key];
    let fields = {};
    let sectionKey = key;
    switch (key) {
      case "statuses":
        fields = arrayOfStringsIntoFieldsForStatuses(data);
        break;
      case "current_funnel_step_ids":
      case "assigned_to_ids":
      case "lead_source_ids":
      case "heard_about_method_ids":
      case "genders":
      case "record_types":
      case "user_ids":
      case "location_ids":
      case "sales_funnel_step_ids":
      case "region_ids":
      case "lead_lost_reason_ids":
      case "deal_ids":
      case "country_ids":
      case "trial_ids":
        fields = arrayOfStringsIntoFields(data);
        break;
      case "tags":
        fields = arrayOfStringsIntoFieldsForTags(data);
        break;
      case "created_after":
      case "created_before":
        sectionKey = "createdBetween";
        fields = createdBetweenIntoFields(key, data, filterData);
        break;
      case "status_updated_after":
      case "status_updated_before":
        sectionKey = "statusUpdatedBetween";
        fields = statusUpdatedBetweenFields(key, data, filterData);
        break;
      case "sales_funnel_step_links":
        fields = arrayOfLinksIntoFieldsForSalesFunnelStepLinks(data);
        break;
      default:
        break;
    }
    let sectionData = { isValid: true, fields: fields };
    filterData = { ...filterData, [sectionKey]: sectionData };
  });
  return filterData;
}

function arrayOfStringsIntoFields(array) {
  let fields = {};
  array.forEach((string, index) => {
    fields = { ...fields, [string]: { value: true, isValid: true, id: index } };
  });
  return fields;
}

function arrayOfStringsIntoFieldsForTags(array) {
  let fields = {};
  array.forEach((string, index) => {
    fields = {
      ...fields,
      [string]: { value: true, isValid: true, id: string }
    };
  });
  return fields;
}

function arrayOfStringsIntoFieldsForStatuses(array) {
  let fields = {};
  array.forEach((key, index) => {
    fields = {
      ...fields,
      [key]: { value: true, isValid: true, label: statuses[key] }
    };
  });
  return fields;
}

function createdBetweenIntoFields(fieldName, fieldValue, filterData) {
  let fields = filterData.createdBetween
    ? filterData.createdBetween.fields
    : {};
  fields = { ...fields, [fieldName]: { value: fieldValue, isValid: true } };

  return fields;
}

function statusUpdatedBetweenFields(fieldName, fieldValue, filterData) {
  let fields = filterData.statusUpdatedBetween
    ? filterData.statusUpdatedBetween.fields
    : {};
  fields = { ...fields, [fieldName]: { value: fieldValue, isValid: true } };

  return fields;
}

function arrayOfLinksIntoFieldsForSalesFunnelStepLinks(array) {
  let fields = {};
  array.forEach((key, index) => {
    const id = key.from_step_id
      ? `${key.from_step_id}-${key.to_step_id}`
      : `${key.to_step_id}`;
    fields = { ...fields, [id]: { value: id, isValid: true } };
  });
  return fields;
}

const statuses = {
  active: "Assigned",
  not_assigned: "Not Assigned",
  inactive: "Inactive",
  lost: "Lost",
  closed: "Closed"
};
