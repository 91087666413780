import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getPotentialUsers = state => state.importLeads[NAME].potentialUsers
export const getSalespeopleMaps = state => state.importLeads[NAME].salespeopleMaps
export const getStatusMaps = state => state.importLeads[NAME].statusMaps
export const getLawfulProcessingBasesMaps = state => state.importLeads[NAME].lawfulProcessingBasesMaps

export const getLoading = state => state.importLeads[NAME].loading
export const getStatuses = state => state.importLeads[NAME].statuses
export const getSalespeople = state => state.importLeads[NAME].salespeople
export const getLawfulProcessingBases = state => state.importLeads[NAME].lawfulProcessingBases

export const isReady = createSelector(
  [ getStatusMaps, getSalespeopleMaps,
    getStatuses, getSalespeople,
    getLawfulProcessingBasesMaps, getLawfulProcessingBases
  ],
  (statusMaps, salespeopleMaps,
    statuses, salespeople,
    lawfulProcessingBasesMaps, lawfulProcessingBases) => {
    if (statusMaps.length !== statuses.length) return false
    if (salespeopleMaps.length !== salespeople.length) return false
    if (lawfulProcessingBasesMaps.length !== lawfulProcessingBases.length) return false
    return true
  }
)
