import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FacebookPageRow from './FacebookPageRow';
import { errorPanelForErrors } from 'utils/formHelpers';
import { ImageEmptyView } from 'modules/components';
import { TextSearchView } from 'modules/components';

import Empty from 'images/Moved.jpg';

const FormView = (props) => {
  const { fetchFacebookPages, facebookPages, selectedPageId, errors, facebookUserToken } = props;

  useEffect(() => {
    if (!facebookUserToken || facebookPages.length > 0) {
      return;
    }
    fetchFacebookPages(facebookUserToken);
  }, [fetchFacebookPages, facebookUserToken, facebookPages]);

  const pageSelected = (page) => {
    props.setSelectedPageId(page.id);
  };

  const searchSelected = (newSearchValue) => {
    fetchFacebookPages(facebookUserToken, newSearchValue);
  };

  const rows = facebookPages.map((facebookPage, index) => {
    const selected = facebookPage.id === selectedPageId;
    return <FacebookPageRow key={index} facebookPage={facebookPage} selectPage={pageSelected} selected={selected} />;
  });

  return (
    <div>
      {!facebookUserToken && (
        <ImageEmptyView
          image={Empty}
          title={"We couldn't load your Facebook user"}
          subtitle={'Something went wrong when trying to access your facebook pages.'}
        />
      )}
      {facebookUserToken && (
        <div>
          {errorPanelForErrors(errors, false)}
          <div>
            <div className="row push">
              <div className="col-md-12">
                <h2 className="h4 push-10">Select the facebook page that represents your club</h2>
              </div>
            </div>
            <TextSearchView sectionName={'locationSearch'} onUpdate={searchSelected} />
            <div className="row">
              <div className="col-sm-12">
                <table className="table table-vcenter table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: '60px' }} />
                      <th>Page Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FormView.propTypes = {
  facebookUserToken: PropTypes.string,
  facebookPages: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFacebookPages: PropTypes.func.isRequired,
  setSelectedPageId: PropTypes.func.isRequired,
  fetchFacebookPages: PropTypes.func.isRequired,
  errors: PropTypes.object,
  selectedPageId: PropTypes.string,
};

export default FormView;
