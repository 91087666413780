import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CsvLeadColumnHeader from '../components/CsvLeadColumnHeader'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    availableFields:  selectors.getAvailableFields(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    replaceHeaderValue: actions.replaceHeaderValue
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CsvLeadColumnHeader)
