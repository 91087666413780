import { combineReducers } from 'redux';
import selectCSV from './modules/selectCSV';
import configureImport from './modules/configureImport';
import fieldMapping from './modules/fieldMapping';
import uploadCSV from './modules/uploadCSV';
import previousImports from './modules/previousImports';

import * as actionTypes from './actionTypes';

const TAB_INDEX_ACTION_HANDLERS = {
  [actionTypes.INCREMENT_TAB_INDEX]: (state, action) => {
    const tabIndex = state.tabIndex;
    return { ...state, tabIndex: tabIndex + 1 };
  },
  [actionTypes.DECREMENT_TAB_INDEX]: (state, action) => {
    const tabIndex = state.tabIndex;
    return { ...state, tabIndex: tabIndex - 1 };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState();
  },
};

function initialState() {
  return {
    tabIndex: 0,
  };
}

function tabIndex(state = initialState(), action) {
  const handler = TAB_INDEX_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export default combineReducers({
  tabIndex,
  [selectCSV.constants.NAME]: selectCSV.reducer,
  [configureImport.constants.NAME]: configureImport.reducer,
  [fieldMapping.constants.NAME]: fieldMapping.reducer,
  [uploadCSV.constants.NAME]: uploadCSV.reducer,
  [previousImports.constants.NAME]: previousImports.reducer,
});
