import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DealModal from "../components/DealModal/DealModal";
import { updateLeadToStatus } from "../actions";

import modal from "modules/Stores/Modal";

import deals from "modules/Stores/Deals";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    deals: deals.selectors.getDeals(state),
    pageDetails: deals.selectors.getPageDetails(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDeals: deals.actions.getDeals,
      hideModal,
      updateLeadToStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DealModal);
