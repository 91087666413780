import { Box } from 'modules/components';
import { FormFields } from 'modules/components';
import React, { useEffect, useState } from 'react';
import { ILocation, ILocationMergeOverrideTag, ICustomMailMergeTag } from 'types';
import notify from 'modules/Notifications';

import { getCustomMailMergeTags } from 'modules/api/customMailMergeTags';
import {
  getLocationMergeOverrideTags,
  createLocationMergeOverrideTag,
  updateLocationMergeOverrideTag,
  deleteLocationMergeOverrideTag,
} from 'modules/api/locationMergeOverrideTags';

const { TextField, SelectField } = FormFields;

const LocationMergeOverrideTags = ({ location }: { location: ILocation }) => {
  const [customMergeFields, setCustomMergeFields] = useState<ICustomMailMergeTag[]>([]);
  const [selectedCustomMergeFieldId, setSelectedCustomMergeFieldId] = useState<number | undefined>();
  const [locationMergeOverrideTags, setLocationMergeOverrideTags] = useState<ILocationMergeOverrideTag[]>([]);
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const cleanUp = () => {
    setLocationMergeOverrideTags([]);
    setSelectedCustomMergeFieldId(undefined);
    setValue('');
  };

  const currentLocationMergeOverrideTagIndex = () => {
    return locationMergeOverrideTags.findIndex((object) => {
      return object.custom_mail_merge_tag_id === selectedCustomMergeFieldId;
    });
  };

  useEffect(() => {
    const loadInitialData = async () => {
      loadCompanyMergeFields();
      loadLocationMergeOverrides();
    };

    const loadCompanyMergeFields = async () => {
      if (customMergeFields.length > 0) {
        return;
      }
      const data = await getCustomMailMergeTags();
      setCustomMergeFields(data.custom_mail_merge_tags);
    };

    const loadLocationMergeOverrides = async () => {
      const data = await getLocationMergeOverrideTags(location.id);
      setLocationMergeOverrideTags(data.location_merge_override_tags);
    };

    loadInitialData();

    return () => {
      cleanUp();
    };
  }, [location, customMergeFields]);

  useEffect(() => {
    const locationMergeOverrideIndex = locationMergeOverrideTags.findIndex((object) => {
      return object.custom_mail_merge_tag_id === selectedCustomMergeFieldId;
    });
    const locationMergeField = locationMergeOverrideTags[locationMergeOverrideIndex];
    setValue(locationMergeField?.value || '');
  }, [selectedCustomMergeFieldId, locationMergeOverrideTags]);

  const handleSave = async () => {
    if (!selectedCustomMergeFieldId || value.length === 0) return;
    const locationMergeOverrideIndex = currentLocationMergeOverrideTagIndex();
    const locationMergeField = locationMergeOverrideTags[locationMergeOverrideIndex];
    setLoading(true);
    if (locationMergeField) {
      try {
        const response = await updateLocationMergeOverrideTag(location.id, locationMergeField.id, value);
        setLocationMergeOverrideTags([
          ...locationMergeOverrideTags.slice(0, locationMergeOverrideIndex),
          response.location_merge_override_tag,
          ...locationMergeOverrideTags.slice(locationMergeOverrideIndex + 1),
        ]);
        notify.success('Location Override Saved');
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await createLocationMergeOverrideTag(location.id, selectedCustomMergeFieldId, value);
        setLocationMergeOverrideTags([...locationMergeOverrideTags, response.location_merge_override_tag]);
        notify.success('Location Override Saved');
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    if (!selectedCustomMergeFieldId || value.length === 0) return;
    const locationMergeOverrideIndex = currentLocationMergeOverrideTagIndex();
    const locationMergeField = locationMergeOverrideTags[locationMergeOverrideIndex];
    if (!locationMergeField) return;

    setLoading(true);
    try {
      await deleteLocationMergeOverrideTag(locationMergeField.id);
      setValue('');
      setLocationMergeOverrideTags([
        ...locationMergeOverrideTags.slice(0, locationMergeOverrideIndex),
        ...locationMergeOverrideTags.slice(locationMergeOverrideIndex + 1),
      ]);
      notify.success('Location Override Deleted');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const blockStyle = loading ? 'block-opt-refresh' : '';

  return (
    <Box className={`block block-rounded block-bordered ${blockStyle}`}>
      <Box className="block-header">
        <h3 className="block-title">{`Custom Merge tags for ${location.display_name}`}</h3>
      </Box>
      <Box className="block-content" style={{ paddingBottom: '12px' }}>
        <div className="row">
          <SelectField
            name="customMergeFieldId"
            label="Company Custom Merge Field"
            possibleOptions={customMergeFields}
            placeHolderText="Select a global merge field"
            formFieldStyle=""
            onChange={(label: string, value: number) => {
              setSelectedCustomMergeFieldId(value);
            }}
            displayFieldName={'label'}
          />
        </div>
        {selectedCustomMergeFieldId && (
          <>
            <div className="row">
              <TextField
                name="value"
                label="Location Value"
                placeholder="Location specific value"
                formFieldStyle=""
                onChange={(labelName: string, value: string) => {
                  setValue(value);
                }}
                value={value}
              />
            </div>
            <div className="row" style={{ marginTop: '12px' }}>
              <div className="col-md-8">
                <button className="btn btn-primary push-5-r push-10" type="button" onClick={handleSave}>
                  Save
                </button>
                <button className="btn btn-danger push-5-r push-10" type="button" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default LocationMergeOverrideTags;
