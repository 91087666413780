import { fetchActivityRecords, fetchLeadDetails } from 'modules/api/leads';
import notify from 'modules/Notifications';
import { Dispatch } from 'react';
import { ILead, ISetLead, IClear, ILoad, ISetActivityRecords } from 'types';
import * as actionTypes from './actionTypes';
import modal from 'modules/Stores/Modal';
import { NAME } from './constants';
import { ActivityRecord } from 'types/interfaces/models';

function setLead(lead: ILead) {
  return {
    type: actionTypes.SET_LEAD,
    lead,
  };
}

function setActivityRecords(activityRecords: ActivityRecord[]) {
  return {
    type: actionTypes.SET_ACTIVITY_RECORDS,
    activityRecords,
  };
}

export function getLeadDetails(leadId: number) {
  return async (dispatch: Dispatch<ISetLead | ILoad>) => {
    try {
      dispatch(modal.actions.startLoading(NAME));
      const { lead } = await fetchLeadDetails(leadId);
      dispatch(setLead(lead));
      dispatch(modal.actions.stopLoading());
    } catch (error) {
      dispatch(modal.actions.stopLoading());
      notify.error('can not fetch selected lead!');
    }
  };
}

export function getActivityRecords(leadId: number) {
  return async (dispatch: Dispatch<ISetActivityRecords>) => {
    try {
      const { lead_activity_records } = await fetchActivityRecords(leadId);
      dispatch(setActivityRecords(lead_activity_records));
    } catch (error) {
      notify.error('can not fetch history records');
    }
  };
}

export function resetState() {
  return (dispatch: Dispatch<IClear>) => {
    dispatch({
      type: actionTypes.RESET_STATE,
    });
  };
}
