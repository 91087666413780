import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CreateSubscriptionView from '../components/CreateSubscriptionView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    errors: selectors.getErrors(state),
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createSubscription: actions.createSubscription,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionView)
