import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PagesView from 'modules/components/PageSelection/PagesView';
import { FormFields, TextSearchView } from 'modules/components';

import { EDIT_HEARD_ABOUT_MODAL, MIGRATE_HEARD_ABOUT_METHOD_MODAL } from '../constants';
import EditHeardAboutMethodModalContainer from '../containers/EditHeardAboutMethodModalContainer';
import MigrateHeardAboutMethodModalContainer from '../containers/MigrateHeardAboutMethodModalContainer';
const { InlineTextField } = FormFields;

export class HeardAboutMethods extends Component {
  state = {
    heardAboutToDelete: null,
    newHeardAboutText: null,
    selectedHeardAboutMethod: null,
  };

  UNSAFE_componentWillMount() {
    const { heardAboutMethods } = this.props;
    if (heardAboutMethods.length > 0) {
      return;
    }
    this.getHeardAboutMethodsWithPageNumber(1);
  }

  getHeardAboutMethodsWithPageNumber = (pageNumber) => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getHeardAboutMethodsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(1, searchTerm);
  };

  handleInput = (fieldName, textValue) => {
    this.setState({ ...this.state, newHeardAboutText: textValue });
  };

  addHeardAboutMethodSelected = () => {
    const newHeardAboutText = this.state.newHeardAboutText;
    this.props.createHeardAboutMethod(newHeardAboutText);
    this.setState({ ...this.state, newHeardAboutText: null });
  };

  methodClicked = (method) => {
    this.setState({ ...this.state, selectedHeardAboutMethod: method });
    this.props.showModal(EDIT_HEARD_ABOUT_MODAL);
  };

  migrateMethodClicked = (method) => {
    this.setState({ ...this.state, heardAboutToDelete: method });
    this.props.showModal(MIGRATE_HEARD_ABOUT_METHOD_MODAL);
  };

  render() {
    const { canEdit } = this.props;
    const methods = this.props.heardAboutMethods.map((heardAboutMethod, index) => {
      return (
        <div key={index} className="col-lg-4">
          <div
            className="block block-bordered selectable"
            style={{
              paddingTop: '5px',
              paddingLeft: '5px',
              paddingBottom: '5px',
              paddingRight: '5px',
            }}
          >
            <span onClick={() => this.methodClicked(heardAboutMethod)}>{heardAboutMethod.heard_about_title}</span>
            {canEdit && (
              <button
                className="btn btn-xs btn-default pull-right"
                type="button"
                onClick={() => this.migrateMethodClicked(heardAboutMethod)}
              >
                <i className="fa fa-times" />
              </button>
            )}
          </div>
        </div>
      );
    });

    const blockStyle = this.props.loading ? 'block-opt-refresh' : '';
    const pageCount = this.props.pageDetails.pageCount;

    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">How did you hear about us?</h3>
          </div>
          <div className="block-content bg-gray-lighter">
            <div className="row" style={{ marginTop: '-30px' }}>
              <TextSearchView sectionName={'heardAboutMethodSearch'} onUpdate={this.searchUpdated} />
            </div>
          </div>
          <div className="block-content">
            <div className="row">{methods}</div>
            <div className="row">
              {canEdit && (
                <>
                  <hr />
                  <div className="col-md-8">
                    <InlineTextField
                      name="newHeardAboutText"
                      label="New method"
                      placeholder="Facebook add"
                      disabled={false}
                      value={this.state.newHeardAboutText}
                      formFieldStyle=""
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-success push-5-r push-10"
                      type="button"
                      disabled={!this.state.newHeardAboutText || this.state.newHeardAboutText.length === 0}
                      onClick={this.addHeardAboutMethodSelected}
                    >
                      <i className="fa fa-plus" /> Add method
                    </button>
                  </div>
                </>
              )}
            </div>
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageClicked={this.pageClicked} pageDetails={this.props.pageDetails} />
              </div>
            )}
          </div>
        </div>
        {this.state.selectedHeardAboutMethod && (
          <EditHeardAboutMethodModalContainer modalName={EDIT_HEARD_ABOUT_MODAL} heardAboutMethod={this.state.selectedHeardAboutMethod} />
        )}
        {this.state.heardAboutToDelete && (
          <MigrateHeardAboutMethodModalContainer
            modalName={MIGRATE_HEARD_ABOUT_METHOD_MODAL}
            heardAboutMethod={this.state.heardAboutToDelete}
          />
        )}
      </div>
    );
  }
}

HeardAboutMethods.propTypes = {
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  createHeardAboutMethod: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default HeardAboutMethods;
