import { containsString } from 'utils/validation'

export function validatorForOption (option) {
  switch (option) {
    case 'perfect_gym':
      return Validator
    default:
      {}
  }
}

const Validator = {
  customUrl: [{
    rule: containsString('perfectgym.com'),
    errorMessage: 'What is your perfect gym domain? (Make sure to include perfectgym.com )'
  }]
}
