import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CsvLeadsTableView from './CsvLeadsTableView';
import { InfoView, EmptyView, FormFields, ToolTip } from 'modules/components';
const { Switch } = FormFields;

export class ConfigureLeadsView extends Component {
  UNSAFE_componentWillMount() {
    const { csvFile, parseCSVFileIntoLeads } = this.props;
    parseCSVFileIntoLeads(csvFile);
  }

  handleToggle = (fieldName, value) => {
    this.props.setImportOptionsValue(fieldName, value);
  };

  render() {
    const {
      isActive,
      leads,
      loading,
      headers,
      numberOfRecords,
      updateLeadsValue,
      enterAutomationFunnelValue,
      requiredFieldsMissing,
      recomendedFieldsMissing,
      selectedLocationName,
      errors,
      enterMatchingCallScheduleValue,
    } = this.props;

    const updateLeadsTooltipText = 'Updates leads who match either the email or mobile number.';
    const updateLeadsLabel = (
      <span>
        Update existing leads details <ToolTip text={updateLeadsTooltipText} id="updateLeads" />{' '}
        {updateLeadsValue && <span style={{ color: 'red' }}>Imports which update leads can not be reversed.</span>}
      </span>
    );

    const enterAutomationFunnelTooltipText = 'Enter lead into matching automation funnel.';
    const enterAutomationFunnelLabel = (
      <span>
        Enter into matching automation funnel <ToolTip text={enterAutomationFunnelTooltipText} id="enterAutomationFunnel" />{' '}
        {enterAutomationFunnelValue && (
          <span style={{ color: 'red' }}>Imports which enter lead into the matched automation funnel can not be reversed.</span>
        )}
      </span>
    );

    const enterMatchingCallScheduleTooltipText =
      'If enabled, any active leads that are imported will be allocated to a matching call schedule.';
    const enterMatchingCallScheduleLabel = (
      <span>
        Enter lead into matching call schedule <ToolTip text={enterMatchingCallScheduleTooltipText} id="enterMatchingCallSchedule" />{' '}
        {enterMatchingCallScheduleValue && (
          <span style={{ color: 'red' }}>Imports that add leads to call schedules cannot be reversed.</span>
        )}
      </span>
    );

    const activeClassName = isActive ? 'active in' : '';
    const requiredFieldsString = 'The following fields are required: ' + requiredFieldsMissing.map((f) => f.displayName).join(', ');
    const recomendedFieldsString = 'The following fields are recomended: ' + recomendedFieldsMissing.map((f) => f.displayName).join(', ');

    return (
      <div className={'tab-pane fade fade-up ' + activeClassName}>
        {!loading && !errors && (
          <div>
            <h4>
              {numberOfRecords} leads will be imported into {selectedLocationName}
            </h4>
            {numberOfRecords > leads.length && <p>Previewing first 200 leads</p>}
            <p>
              Select the correct field type in each column you want to import.
              <br />
              We've tried to guess which columns are which, but you should double check and confirm any columns we missed or got wrong.
              <br />
              Columns highlighed in <span className="text-success">green</span> will be processed.
              <br />
              Columns in <span className="text-muted">gray</span> are not selected yet and will be ignored.
              <br />
            </p>

            <Switch
              name="updateLeads"
              label={updateLeadsLabel}
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={updateLeadsValue}
            />
            <Switch
              name="enterAutomationFunnel"
              label={enterAutomationFunnelLabel}
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={enterAutomationFunnelValue}
            />

            <Switch
              name="enterMatchingCallSchedule"
              label={enterMatchingCallScheduleLabel}
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={enterMatchingCallScheduleValue}
            />

            {requiredFieldsMissing.length > 0 && <InfoView colorThemeClass={'danger'} detail={requiredFieldsString} />}
            {requiredFieldsMissing.length > 0 && <InfoView colorThemeClass={'danger'} detail={requiredFieldsString} />}
            {requiredFieldsMissing.length === 0 && recomendedFieldsMissing.length > 0 && (
              <InfoView colorThemeClass={'warning'} detail={recomendedFieldsString} />
            )}
            <CsvLeadsTableView leads={leads} headers={headers} />
          </div>
        )}
        {!loading && errors && (
          <div className="text-center">
            <EmptyView colorThemeClass="warning" iconClass="fa fa-warning" title="There is an issue with this CSV" subtitle={errors} />
          </div>
        )}
        {loading && (
          <div className="text-center">
            <h3>Processing file</h3>
            <i className="fa fa-3x fa-cog fa-spin" />
          </div>
        )}
      </div>
    );
  }
}

ConfigureLeadsView.propTypes = {
  updateLeadsValue: PropTypes.bool.isRequired,
  enterAutomationFunnelValue: PropTypes.bool.isRequired,
  enterMatchingCallScheduleValue: PropTypes.bool.isRequired,
  setImportOptionsValue: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  selectedLocationName: PropTypes.string.isRequired,
  parseCSVFileIntoLeads: PropTypes.func.isRequired,
  leads: PropTypes.arrayOf(PropTypes.array).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  numberOfRecords: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  csvFile: PropTypes.object.isRequired,
  requiredFieldsMissing: PropTypes.arrayOf(PropTypes.object).isRequired,
  recomendedFieldsMissing: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.string,
};

export default ConfigureLeadsView;
