import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getProductName } from 'modules/Stores/UserData/selectors'

import modal from 'modules/Stores/Modal'
import FormView from '../components/EnterDetails/FormView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    data: selectors.getData(state),
    tabIndex: selectors.getTabIndex(state),
    productName: getProductName(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: actions.onInput
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
