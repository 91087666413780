import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "react-select/dist/react-select.css";
import styled from "styled-components";

const TrialsSelectSection = ({ trials, data, onUpdate, sectionName }) => {
  function findTrialByName(name) {
    const index = trials.findIndex((trial) => trial.name === name);
    return trials[index];
  }

  function findTrialById(id) {
    const index = trials.findIndex(
      (trial) => parseInt(trial.id) === parseInt(id)
    );
    return trials[index];
  }

  function handleUpdate(event) {
    const newValues = event.map((tagValue) => {
      return {
        value: true,
        display: tagValue.label,
        isValid: true,
      };
    });

    var newTags = {};
    newValues.forEach((trial) => {
      const selectedTrial = findTrialByName(trial.display);
      newTags[selectedTrial.id] = trial;
    });

    const newData = { fields: newTags };
    onUpdate(newData, sectionName);
  }

  function tagsFromData() {
    const trialIds = data.fields;
    const ids = Object.keys(trialIds);
    return ids.map((id) => ({
      label: findTrialById(id).name,
      value: parseInt(id),
    }));
  }

  const trialOptions = trials.map((trial) => ({
    value: trial.id,
    label: String(trial.name),
  }));

  return (
    <Container>
      <Select
        multi={true}
        options={trialOptions}
        value={tagsFromData()}
        onChange={(newValue) => handleUpdate(newValue)}
      />
    </Container>
  );
};

export default TrialsSelectSection;

TrialsSelectSection.propTypes = {
  trials: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
};

const Container = styled.div`
  text-align: left;
`;
