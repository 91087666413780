import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SelectTrialTableView from './TableView/SelectTrialTableView'

import { ContentBlockView, EmptyView, PagesView } from 'modules/components'

export class TrialSelectView extends Component {

  UNSAFE_componentWillMount () {
    const { trials } = this.props
    if (trials.length > 0) { return }
    this.getTrialsWithPageNumber(1)
  }

  getTrialsWithPageNumber = (pageNumber) => {
    const { getTrials } = this.props
    getTrials(pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getTrialsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getTrials } = this.props
    getTrials(1, searchTerm)
  }

  render () {
    const { trials, pageDetails, loading, selectedTrialId, trialStartsAt, onTrialSelect } = this.props
    const pageCount = pageDetails.pageCount

    return (
      <ContentBlockView
        loading={loading}
        bordered>
        {trials.length > 0 &&
          <div>
            <SelectTrialTableView
              trials={trials}
              selectedTrialId={selectedTrialId}
              onTrialSelect={onTrialSelect}
              trialStartsAt={trialStartsAt} />
          </div>
        }
        {trials.length === 0 &&
          <EmptyView
            colorThemeClass='primary'
            iconClass='si si-magnifier'
            title='No results'
            subtitle='Check the search field' />
        }
        {pageCount > 1 &&
          <div className='text-center push'>
            <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
          </div>
        }
      </ContentBlockView>
    )
  }
}

TrialSelectView.propTypes = {
  loading: PropTypes.bool.isRequired,
  getTrials: PropTypes.func.isRequired,
  onTrialSelect: PropTypes.func.isRequired,
  selectedTrialId: PropTypes.number,
  trials: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired
}

export default TrialSelectView
