import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MigrateTrialModal from '../components/MigrateTrialModal'

import trials from 'modules/Stores/Trials'

import modal from 'modules/Stores/Modal'

import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    trials: trials.selectors.getTrials(state),
    errors: modal.selectors.getModalErrors(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    deleteTrial: actions.deleteTrial
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MigrateTrialModal)
