/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { ContentBlockView, EmptyView, TextSearchView, PagesView } from 'modules/components';

export class SelectLeadSourceModal extends Component {
  UNSAFE_componentWillMount() {
    const { leadSources } = this.props;
    if (leadSources.length > 0) {
      return;
    }
    this.getLeadSourcesWithPageNumber(1);
  }

  getLeadSourcesWithPageNumber = (pageNumber) => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  sourceClicked = (selectedLeadSource) => {
    const { lead, updateLead, listUpdateProtocol } = this.props;
    const source = { lead_source_id: selectedLeadSource.id };
    updateLead(lead, source, listUpdateProtocol.updateLeadDetails);
  };

  render() {
    const { lead, loading, currentModal, hideModal, modalName, leadSources, pageDetails } = this.props;

    const pageCount = pageDetails.pageCount;

    const leadSourceOptions = leadSources.map((leadSource, index) => {
      const bgStyle = leadSource === lead.source ? 'bg-primary-light' : 'bg-primary';
      return (
        <div key={index} className="col-lg-6">
          <a className="block block-link-hover2 selectable" onClick={() => this.sourceClicked(leadSource)}>
            <div className={'block-content block-content-full clearfix ' + bgStyle}>
              <span className="h4 text-white-op">{leadSource.source_name}</span>
            </div>
          </a>
        </div>
      );
    });

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className={'block'}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Where did {lead.given_name} come from?
            </h3>
          </div>
          <ContentBlockView loading={loading} bordered>
            <div className="row" style={{ marginTop: '-20px' }}>
              <TextSearchView sectionName={'leadSourceSearch'} onUpdate={this.searchUpdated} />
            </div>
            {leadSources.length > 0 && (
              <div>
                <div className="row">{leadSourceOptions}</div>
              </div>
            )}
            {leadSources.length === 0 && (
              <EmptyView colorThemeClass="primary" iconClass="si si-magnifier" title="No results" subtitle="Check the search field" />
            )}
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
              </div>
            )}
          </ContentBlockView>
        </div>
      </Modal>
    );
  }
}

SelectLeadSourceModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object),
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

SelectLeadSourceModal.defaultProps = {
  leadSources: [],
};

export default SelectLeadSourceModal;
