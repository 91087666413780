import * as actionTypes from './actionTypes'

const EDIT_ARTICLE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return ({ ...state, loading: action.loading })
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return ({ ...state, errors: action.errors })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  errors: null
}

export default function reducer (state = initialState, action) {
  const handler = EDIT_ARTICLE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
