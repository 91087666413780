import config from 'config';
import store from 'store/store';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import session from 'modules/Stores/Session';
import userData from 'modules/Stores/UserData';

export function registerBugsnag() {
  const state = store.getState();
  const user = session.selectors.getUser(state);
  const company = userData.selectors.getCompany(state);

  const bugsnagClient = Bugsnag.start({
    apiKey: config.bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
    appType: 'sales',
  });

  if (user && user.id) {
    bugsnagClient.user = { id: user.id, name: user.display_name };
  } else {
    bugsnagClient.user = null;
  }

  if (company) {
    bugsnagClient.metaData = {
      company: { id: company.id, name: company.title },
    };
  } else {
    bugsnagClient.metaData = null;
  }
  return bugsnagClient;
}
