import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FilterForm } from "../components/FilterForm";

import modal from "modules/Stores/Modal";

import {
  fetchReportWithQueryString,
  updateData,
  clearAllFilters,
} from "../actions";
import FilterFormSections from "modules/components/FilterFormSections";

import { getFilterData } from "../selectors";

function mapStateToProps(state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    getFilterData
  );
  return {
    data: getFilterData(state),
    queryString: queryStringSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchReportWithQueryString,
      updateData,
      clearAllFilters,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
