import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_EVENTS = NAME + '/SET_EVENTS'

export const EVENT_SELECTED = NAME + '/EVENT_SELECTED'

export const ADD_EVENTS = NAME + '/ADD_EVENT'
export const UPDATE_EVENT = NAME + '/UPDATE_EVENT'
export const DELETE_EVENT = NAME + '/DELETE_EVENT'

export const SET_DATE = NAME + '/SET_DATE'
export const SET_VIEW = NAME + '/SET_VIEW'
