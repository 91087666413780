import React, { FC } from 'react';

interface TRProps {
  chiildren?: React.ReactNode;
  selectable?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export const TR: FC<TRProps> = (props) => {
  const { children, selected, selectable, onClick } = props;

  const backgroundClass = selected ? 'bg-primary' : 'undefined';
  const textClass = selected ? 'text-white' : '';
  const selectableClass = selectable ? 'selectable' : '';
  const className = `${selectableClass} ${backgroundClass} ${textClass}`;

  return (
    <tr className={className} onClick={onClick}>
      {children}
    </tr>
  );
};
