import PropTypes from "prop-types";
import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

import CsvLeadColumnHeaderContainer from "../containers/CsvLeadColumnHeaderContainer";
import CsvValueCell from "./CsvValueCell";

import "./csvTable.css";

export class CsvLeadsTableView extends Component {
  render() {
    const { headers, leads } = this.props;
    const data = leads.map((lead) => {
      var object = {};
      headers.forEach(function (header, index) {
        object[header.key] = lead[index];
      });
      return object;
    });

    return (
      <div>
        <ReactTable
          data={data}
          sortable={false}
          columns={headers.map((header, index) => {
            return {
              Header: (
                <CsvLeadColumnHeaderContainer header={header} index={index} />
              ),
              accessor: header.key,
              Cell: (row) => (
                <CsvValueCell row={row} isValid={header.displayName !== null} />
              ),
            };
          })}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

CsvLeadsTableView.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.array).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CsvLeadsTableView;
