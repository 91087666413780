import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class ReportView extends Component {

  UNSAFE_componentWillMount () {
    const { fetchReportWithQueryString, queryString } = this.props
    fetchReportWithQueryString(queryString)
  }

  render () {
    return (
      <div className='content content-boxed push-100'>

        {this.props.headerContainer}

        {this.props.quickInfoBlockContainer}

        <div className='row'>
          <div className='col-md-4'>
            {this.props.filterFormContainer}
          </div>
          <div className='col-md-8'>
            {this.props.chartContainer}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            {this.props.tableContainer}
          </div>
        </div>
      </div>
    )
  }
}

ReportView.propTypes = {
  headerContainer: PropTypes.element.isRequired,
  tableContainer: PropTypes.element.isRequired,
  chartContainer: PropTypes.element.isRequired,
  filterFormContainer: PropTypes.element.isRequired,
  quickInfoBlockContainer: PropTypes.element.isRequired,
  fetchReportWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired
}
