import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Pie } from 'react-chartjs-2'
import { EmptyView } from 'modules/components'

export class GraphSummaryView extends Component {

  render () {
    const { dataSet, loading, isEmpty } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <div className='block-header'>
          <h3 className='block-title'>Lost Reasons Totals</h3>
        </div>
        {isEmpty &&
          <div style={{ paddingTop: '60px' }}>
            <EmptyView
              colorThemeClass='primary'
              iconClass={'fa fa-pie-chart'}
              title='No data for these settings'
              subtitle='Update your filters or come back later.' />
          </div>
        }
        {!isEmpty &&
          <div className='block-content push-50' style={{ minHeight: '300px' }}>
            <Pie data={dataSet} />
          </div>
        }
      </div>
    )
  }
}

GraphSummaryView.propTypes = {
  loading: PropTypes.bool.isRequired,
  dataSet: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool.isRequired
}

export default GraphSummaryView
