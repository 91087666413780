import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getShowingMore = (state) => state.lostLeadsReport[NAME].showingMore
export const getData = (state) => state.lostLeadsReport[NAME].data
export const getCompareOption = (state) => state.lostLeadsReport[NAME].compareOption

export const activeExtraFiltersCount = createSelector(
  [ getData ],
  (data) => {
    const leadSourcesData = (data['lead_source_ids']) || { fields: {} }
    const heardAboutMethodsData = (data['heard_about_methods_id']) || { fields: {} }
    const tagData = (data.tags) || { fields: {} }
    const userIdData = (data['user_ids']) || { fields: {} }

    const activeTagKeys = Object.keys(tagData.fields)
      .filter((key) => { return tagData.fields[key].value === true })

    const activeUerIds = Object.keys(userIdData.fields)
      .filter((key) => { return userIdData.fields[key].value === true })

    const activeLeadSourceFields = Object.keys(leadSourcesData.fields)
      .filter((key) => { return leadSourcesData.fields[key].value === true })

    const activeHeardAboutMethodsFields = Object.keys(heardAboutMethodsData.fields)
      .filter((key) => { return heardAboutMethodsData.fields[key].value === true })

    return [
      ...activeHeardAboutMethodsFields,
      ...activeLeadSourceFields,
      ...activeTagKeys,
      ...activeUerIds
    ].length
  }
)
