import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectHeardAboutMethodTableRow extends Component {
  rowSelected = event => {
    const {
      heardAboutMethod,
      heardAboutMethodSelected,
      selectHeardAboutMethod,
      deselectHeardAboutMethod
    } = this.props;
    if (heardAboutMethodSelected) {
      deselectHeardAboutMethod(heardAboutMethod);
    } else {
      selectHeardAboutMethod(heardAboutMethod);
    }
  };

  render() {
    const { heardAboutMethod, heardAboutMethodSelected } = this.props;
    return (
      <tr
        className="selectable"
        onClick={() => this.rowSelected(heardAboutMethod)}
      >
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input
              type="checkbox"
              disabled
              checked={heardAboutMethodSelected}
              readOnly
            />
            <span />
          </label>
        </td>
        <td>{heardAboutMethod.heard_about_title}</td>
      </tr>
    );
  }
}

SelectHeardAboutMethodTableRow.propTypes = {
  heardAboutMethod: PropTypes.object.isRequired,
  heardAboutMethodSelected: PropTypes.bool.isRequired,
  selectHeardAboutMethod: PropTypes.func.isRequired,
  deselectHeardAboutMethod: PropTypes.func.isRequired
};
