import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormFields } from 'modules/components';
import { generateNewFormState } from 'utils/formHelpers';

const { Switch } = FormFields;

export class ActionAutomationRuleForm extends Component {
  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true);
    this.props.onInput(newData);
  };

  render() {
    const { data } = this.props;
    const { createCallForWebLeads, moveLeadOnCallSuccess, moveLeadOnAppointmentSuccess, closeLeadOnClosedAppointment } = data.fields;

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <Switch
              name="createCallForWebLeads"
              label="Create call for web leads automatically"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={createCallForWebLeads.value}
            />
          </div>
          <div className="col-md-8">
            <Switch
              name="moveLeadOnCallSuccess"
              label="Move lead to next step after successfully completing a call"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={moveLeadOnCallSuccess.value}
            />
          </div>
          <div className="col-md-8">
            <Switch
              name="moveLeadOnAppointmentSuccess"
              label="Automate steps after successfully completing an appointment"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={moveLeadOnAppointmentSuccess.value}
            />
          </div>
          <div className="col-md-8">
            <Switch
              name="closeLeadOnClosedAppointment"
              label="Close lead after selecting `closed` appointment outcome"
              onChange={this.handleToggle}
              switchStyle="switch-sm switch-primary"
              value={closeLeadOnClosedAppointment.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <button className="btn btn-primary push-5-r push-10" type="button" onClick={this.props.onSaveSelected}>
              <i className="si si-target" /> Update Rules
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ActionAutomationRuleForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSaveSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default ActionAutomationRuleForm;
