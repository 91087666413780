import PropTypes from 'prop-types'
import React, { Component } from 'react'

import moment from 'moment'
import 'moment-timezone'

export class ChargeItemRow extends Component {

  render () {
    const { charge, timezone } = this.props
    return (
      <tr>
        <td>
          { moment(charge.created_at).tz(timezone).format('MMMM Do YYYY, h:mm:ss a') }
        </td>
        <td>
          { charge.amount }
        </td>
        <td>
          { charge.description }
        </td>
      </tr>
    )
  }
}

ChargeItemRow.propTypes = {
  timezone: PropTypes.string.isRequired,
  charge: PropTypes.object.isRequired
}

export default ChargeItemRow
