import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import userData from 'modules/Stores/UserData'
import billing from 'modules/Stores/Billing'
import createLocation from '../modules/createLocation'

function mapStateToProps (state) {
  return {
    company: userData.selectors.getCompany(state),
    errors: modal.selectors.getModalErrors(state),
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: createLocation.constants.NEW_CLUB_MODAL_NAME,
    data: createLocation.selectors.getData(state),
    paymentData: createLocation.selectors.getPaymentData(state),
    paymentMethods: billing.selectors.getPaymentMethods(state),
    countries: userData.selectors.getCountries(state),
    isValid: createLocation.selectors.isValid(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    createLocationForCompany: createLocation.actions.createLocationForCompany,
    resetForm: createLocation.actions.resetForm,
    onInput: createLocation.actions.updateData,
    onPaymentInput: createLocation.actions.updatePaymentData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(createLocation.components.CreateLocationModalView)
