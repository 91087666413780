import { request, GET, PUT, POST, DELETE } from 'utils/apiUtils';
import { apiClient, buildIndexUrl } from 'utils/apiClient';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import * as appointmentHelpers from '../appointments/helpers';
import * as noteActions from '../notes/actions';
import * as leadActions from '../../actions';
import details from '../details';
import notify from 'modules/Notifications';

import moment from 'moment';

export function setCallSchedule(lead, params, updateLeadDetails) {
  // Updates lead
  return async (dispatch) => {
    dispatch(modal.actions.startLoading());
    try {
      const response = await apiClient().put('leads/' + lead.id, {
        lead: params,
      });
      dispatch(updateLeadDetails(response.data.lead));
      dispatch(details.actions.setLead(response.data.lead));
      dispatch(fetchCalls(lead));
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success('Call Schedule added to Lead');
    } catch (e) {
      dispatch(fetchFailed(e.data));
    }
  };
}

export function fetchCalls(lead, pageNumber) {
  return async (dispatch) => {
    const page = pageNumber ? pageNumber : 1;
    const url = buildIndexUrl(`leads/${lead.id}/contact_calls`, page);
    try {
      const response = await apiClient().get(url);
      dispatch(fetchSuccess(response.data.contact_calls));
      dispatch(setPageDetails(response.data.meta));
    } catch (e) {
      dispatch(fetchFailed(e.data));
    }
  };
}

export const fetch = (lead) => {
  return (dispatch) => {
    dispatch(fetching());

    function onFailure(errors) {
      dispatch(fetchFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchSuccess(payload.contact_calls));
      dispatch(setPageDetails(payload.meta));
    }

    return request('leads/' + lead.id + '/contact_calls', GET, null, onSuccess, onFailure);
  };
};

export function setPageDetails(pageDetails) {
  return {
    type: actionTypes.SET_PAGE_DETAILS,
    pageDetails,
  };
}

function fetching() {
  return {
    type: actionTypes.FETCHING,
  };
}

function fetchSuccess(calls) {
  return {
    type: actionTypes.FETCHING_SUCCESS,
    calls,
  };
}

function fetchFailed(errors) {
  return {
    type: actionTypes.FETCHING_FAILURE,
    errors,
  };
}

export const toggleComplete = (call, lead, outcome, note, ignoreSms, ignoreEmail, listUpdateProtocol) => {
  return (dispatch) => {
    const now = call.completed_on ? null : moment().format();
    const body = {
      contact_call: {
        outcome: outcome,
        completed_on: now,
        note: note,
      },
      ignore_sms: ignoreSms,
      ignore_email: ignoreEmail,
    };
    dispatch(updating(call.id));

    function onFailure(errors) {
      dispatch(updatingFailed(errors, call.id));
    }

    function onSuccess(payload) {
      dispatch(completingSuccess(payload.contact_call));
      dispatch(details.actions.setSalesFunnelStep(payload.contact_call.lead.sales_funnel_step));
      const meta = payload.meta;
      if (!meta) {
        notify.success('Call updated');
        if (listUpdateProtocol && listUpdateProtocol.cleanUp) {
          dispatch(listUpdateProtocol.cleanUp());
        }
        return;
      }
      if (meta.next_call) {
        dispatch(add(meta.next_call));
        notify.success('Call updated. Next call scheduled automatically');
      } else {
        dispatch(leadActions.updateLeadToStatus(lead, 'inactive', lead.sales_funnel_step, null, listUpdateProtocol));
        notify.warning('Lead could not be reached in time. They will be marked as inactive');
      }
      if (meta.next_step) {
        dispatch(details.actions.updateCallScheduleStep(meta.next_step));
      }
    }

    return request('leads/' + lead.id + '/contact_calls/' + call.id, PUT, body, onSuccess, onFailure);
  };
};

function completingSuccess(call) {
  return {
    type: actionTypes.UPDATING_SUCCESS,
    call,
  };
}

export const deleteCall = (call, lead) => {
  return (dispatch) => {
    dispatch(updating(call.id));

    function onFailure(errors) {
      dispatch(updatingFailed(errors, call.id));
    }

    function onSuccess(payload) {
      dispatch(deleted(call.id));
      notify.success('Call deleted!');
    }

    return request('leads/' + lead.id + '/contact_calls/' + call.id, DELETE, null, onSuccess, onFailure);
  };
};

function deleted(callId) {
  return {
    type: actionTypes.DELETE,
    callId,
  };
}

function updating(callId) {
  return {
    type: actionTypes.UPDATING,
    callId,
  };
}

function updatingFailed(errors, callId) {
  return {
    type: actionTypes.UPDATING_FAILURE,
    errors,
    callId,
  };
}

export const scheduleCall = (lead, dueDate, listUpdateProtocol, length) => {
  return (dispatch) => {
    const body = {
      contact_call: {
        due_date: dueDate,
        length: length,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(add(payload.contact_call));
      dispatch(details.actions.setSalesFunnelStep(payload.contact_call.lead.sales_funnel_step));
      if (listUpdateProtocol.cleanUp) {
        dispatch(listUpdateProtocol.cleanUp(lead));
      }
      notify.success('Call scheduled!');
    }

    return request('leads/' + lead.id + '/contact_calls', POST, body, onSuccess, onFailure);
  };
};

export const recordCall = (lead, outcome, note) => {
  return (dispatch) => {
    const now = moment().utc().format();
    const body = {
      contact_call: {
        due_date: now,
        outcome: outcome,
        completed_on: now,
        note: note,
      },
    };

    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(details.actions.setSalesFunnelStep(payload.contact_call.lead.sales_funnel_step));
      dispatch(add(payload.contact_call));
      notify.success('Call recorded!');
    }

    return request('leads/' + lead.id + '/contact_calls', POST, body, onSuccess, onFailure);
  };
};

function add(call) {
  return {
    type: actionTypes.ADD,
    call,
  };
}

// after actionTypes
export function handleAfterActionForAppointmentOutcome(outcome, lead, salesFunnel, listUpdateProtocol) {
  return (dispatch) => {
    switch (outcome) {
      case appointmentHelpers.WANTS_TO_THINK_ABOUT_IT:
        dispatch(setupFollowUpCallForLeadAfterTour(lead, salesFunnel, listUpdateProtocol));
        break;
      case appointmentHelpers.NO_SHOW:
        dispatch(setupNoShowCallForLeadAfterTour(lead, salesFunnel, listUpdateProtocol));
        break;
      default:
        break;
    }
  };
}
function setupNoShowCallForLeadAfterTour(lead, salesFunnel, listUpdateProtocol) {
  return (dispatch) => {
    const detail = lead.display_name + " didn't show up. Have booked a follow up call";
    dispatch(noteActions.createNoteForLead(lead, detail));
  };
}

function setupFollowUpCallForLeadAfterTour(lead, salesFunnel, listUpdateProtocol) {
  return (dispatch) => {
    const currentStep = lead.sales_funnel_step;
    const nextStep = getStepThreeForLead(lead, salesFunnel);

    const detail = "They want to have a think about it. I've scheduled a follow up call to discuss later";
    dispatch(noteActions.createNoteForLead(lead, detail));
    dispatch(leadActions.moveLeadToStep(lead, currentStep, nextStep, listUpdateProtocol));
  };
}

function getStepThreeForLead(lead, salesFunnel) {
  const salesFunnelSteps = salesFunnel.steps;
  return salesFunnelSteps[2];
}
