import { safeBooleanPropertyForObject, safePropertyForObject } from 'utils/formHelpers';
import * as actionTypes from './actionTypes';

const EDIT_EMAIL_MAP_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state) => {
    return { ...state, loading: false };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  },
  [actionTypes.RELOAD_EMAIL_MAP]: (state, action) => {
    const emailMap = action.emailMap;
    const data = formDataForEmailMap(emailMap);
    const locationIds = emailMap ? emailMap.location_ids : [];
    const tags = emailMap && emailMap.lead_tags ? emailMap.lead_tags.split(',') : [];
    return {
      ...state,
      data: data,
      selectedLocationIds: locationIds,
      tags: tags,
    };
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data };
  },
  [actionTypes.SET_SHOWING]: (state, action) => {
    return { ...state, showing: action.showing };
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForEmailMap();
  },
  [actionTypes.SET_TAGS]: (state, action) => {
    const data = state.data;
    const oldValid = data.isValid;
    let newValid = true;
    if (oldValid === false) {
      newValid = oldValid;
    }
    const newData = { ...data, isValid: newValid };
    return { ...state, tags: action.tags, data: newData };
  },
};

function initialStateForEmailMap(emailMap) {
  const tags = emailMap && emailMap.lead_tags ? emailMap.lead_tags.split(',') : [];
  return {
    loading: false,
    errors: null,
    showing: false,
    tags: tags,
    data: formDataForEmailMap(emailMap),
  };
}

function formDataForEmailMap(emailMap) {
  const idKey = 'id';
  const nameKey = 'name';
  const urlStringKey = 'url_string';
  const locationIdKey = 'location_id';
  const userIdKey = 'user_id';
  const leadSourceIdKey = 'lead_source_id';
  const heardAboutMethodIdKey = 'heard_about_method_id';
  const ignoreReopensKey = 'ignore_reopens';
  const ignoreReopenForwardAddressKey = 'ignore_reopen_forward_address';
  const location = emailMap ? emailMap.default_location : null;
  const user = emailMap ? emailMap.user : null;
  return {
    fields: {
      id: {
        value: safePropertyForObject(emailMap, idKey),
        key: idKey,
        isValid: true,
      },
      name: {
        value: safePropertyForObject(emailMap, nameKey),
        key: nameKey,
        isValid: safePropertyForObject(emailMap, nameKey) != null,
      },
      urlString: {
        value: safePropertyForObject(emailMap, urlStringKey),
        key: urlStringKey,
        isValid: true,
      },
      locationId: {
        value: safePropertyForObject(emailMap, locationIdKey),
        key: locationIdKey,
        isValid: safePropertyForObject(emailMap, locationIdKey) != null,
      },
      locationName: {
        value: safePropertyForObject(location, 'admin_display_name'),
        isValid: true,
      },
      userId: {
        value: safePropertyForObject(emailMap, userIdKey),
        key: userIdKey,
        isValid: safePropertyForObject(emailMap, userIdKey) != null,
      },
      userName: {
        value: safePropertyForObject(user, 'display_name'),
        isValid: true,
      },
      leadSourceName: {
        value: safePropertyForObject(emailMap, 'lead_source_name'),
        isValid: true,
      },
      leadSourceId: {
        value: safePropertyForObject(emailMap, leadSourceIdKey),
        key: leadSourceIdKey,
        isValid: true,
      },
      heardAboutMethodName: {
        value: safePropertyForObject(emailMap, 'heard_about_method_name'),
        isValid: true,
      },
      ignoreReopens: {
        value: safeBooleanPropertyForObject(emailMap, ignoreReopensKey),
        key: ignoreReopensKey,
        isValid: true,
      },
      ignoreReopenForwardAddress: {
        value: safePropertyForObject(emailMap, ignoreReopenForwardAddressKey),
        key: ignoreReopenForwardAddressKey,
        isValid: true,
      },
      heardAboutMethodId: {
        value: safePropertyForObject(emailMap, heardAboutMethodIdKey),
        key: heardAboutMethodIdKey,
        isValid: true,
      },
    },
  };
}

export default function reducer(state = initialStateForEmailMap(), action) {
  const handler = EDIT_EMAIL_MAP_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
