import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { PagesView } from 'modules/components'

import TeamMemberRow from './TeamMemberRow'
import SweetAlert from 'react-bootstrap-sweetalert'
import { SELECT_USERS_MODAL } from '../constants'

import MultiUserSelect from 'modules/components/MultiUserSelect'

export class TeamMembersView extends Component {

  state = {
    showWarning: false,
    userToDelete: null
  }

  componentDidMount () {
    this.getUsersWithPageNumber(1)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.location) { return }

    if (nextProps.location.id === this.props.location.id) { return }
    const { getUsers, location } = nextProps
    getUsers(location, 1)
  }

  getUsersWithPageNumber = (pageNumber) => {
    const { getUsers, location } = this.props
    getUsers(location, pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getUsersWithPageNumber(pageNumber)
  }

  deleteUserSelected = (user) => {
    this.setState({ ...this.state, userToDelete: user, showWarning: true })
  }

  cancelDelete = () => {
    this.setState({ ...this.state, showWarning: false, userToDelete: null })
  }

  showAddUserView = () => {
    const { showModal } = this.props
    showModal(SELECT_USERS_MODAL)
  }

  deleteUser = () => {
    this.cancelDelete()
    this.props.deleteTeamMember(this.state.userToDelete, this.props.location)
  }

  addUsersToLocation = (userIds) => {
    const { location, addTeamMembersToLocation } = this.props
    addTeamMembersToLocation(location, userIds)
  }

  filterExistingUsers = (users) => {
    const localUserIds = this.props.teamMembers.map(user => user.id)
    return users.filter((user) => {
      return localUserIds.indexOf(user.id) === -1
    })
  }

  render () {
    const { teamMembers, user, loading, location, selectedUser, pageDetails } = this.props
    const pageCount = pageDetails.pageCount
    const teamMembersView = teamMembers.map((teamMember, index) => {
      const isCurrentUser = (teamMember.id === user.id)
      const selected = (selectedUser && selectedUser.id === teamMember.id) || false
      return <TeamMemberRow
        key={index}
        user={user}
        isCurrentUser={isCurrentUser}
        onDelete={this.deleteUserSelected}
        updateTeamMemberUserRole={this.props.updateTeamMemberUserRole}
        teamMember={teamMember}
        onSelect={this.props.selectUserId}
        selected={selected} />
    })
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <div className='block-options-simple'>
            <button type='button' className='btn btn-xs btn-primary' onClick={this.showAddUserView}>
              <i className='fa fa-plus' />{' '}Add existing users
            </button>
          </div>
          <h3 className='block-title'>{location.admin_display_name} Team Members</h3>
        </div>
        <div className='block-content block-content-full' style={{ paddingTop: '0px' }}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='table-responsive'>
                <table className='table table-vcenter'>
                  <tbody>
                    {teamMembersView}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <MultiUserSelect.MultiUserSelectModalContainer
          buttonTitle='Add team members'
          modalName={SELECT_USERS_MODAL}
          onUpdate={this.addUsersToLocation}
          filterFunction={this.filterExistingUsers} />
        {pageCount > 1 &&
          <div className='text-center push'>
            <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
          </div>
        }
        {this.state.showWarning &&
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='warning'
            title='Are you Sure?'
            confirmBtnText={'Remove ' + this.state.userToDelete.given_name}
            onConfirm={this.deleteUser}
            onCancel={this.cancelDelete}>
            {this.state.userToDelete.given_name +
            ' will no longer be able to use ' + this.props.productName +'. Their currently assigned leads will have to be reassigend'}
          </SweetAlert>
        }
      </div>
    )
  }
}

TeamMembersView.propTypes = {
  user: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  deleteTeamMember: PropTypes.func.isRequired,
  selectUserId: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  updateTeamMemberUserRole: PropTypes.func.isRequired,
  addTeamMembersToLocation: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectedUser: PropTypes.object
}

export default TeamMembersView
