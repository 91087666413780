import PropTypes from "prop-types";
import React, { Component } from "react";

import { ManagerSalesTargetRow } from "./ManagerSalesTargetRow";

import moment from "moment";
import "moment-timezone";

export class UserTagetsViewForManager extends Component {
  UNSAFE_componentWillMount() {
    const { user, location, fetch } = this.props;
    fetch(user.id, location.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.id === this.props.user.id) {
      return;
    }
    const { fetch } = this.props;
    fetch(nextProps.user.id, nextProps.location.id);
  }

  titleString = () => {
    const { salesTargetResults, timezone } = this.props;
    switch (salesTargetResults.time_type) {
      case "monthly":
        return moment().tz(timezone).format("MMMM");
      case "weekly":
        return " this week";
      default:
        return "";
    }
  };

  render() {
    const { salesTargetResults, user } = this.props;
    const allKeys = Object.keys(salesTargetResults.data);
    const rows = allKeys.map((key, index) => {
      const targetEntry = salesTargetResults.data[key];
      if (key === "referral_count" && targetEntry.target === 0) {
        return undefined;
      }
      return (
        <ManagerSalesTargetRow key={index} title={key} target={targetEntry} />
      );
    });

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <h3 className="block-title">
            {user.display_name}'s Sales Targets for {this.titleString()}
          </h3>
        </div>
        <div className="block-content block-content-full table-responsive">
          <table className="table remove-margin-b font-s13">
            <thead>
              <tr className="text-center">
                <th>Goal</th>
                <th className="text-center">Target</th>
                <th className="text-center">Month to date</th>
                <th className="text-center">Remaining</th>
                <th className="text-center">Today's Goal</th>
                <th className="text-center">On Track</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

UserTagetsViewForManager.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  salesTargetResults: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default UserTagetsViewForManager;
