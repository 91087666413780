import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = (state) => state.callScheduleSettings[NAME].errors;
export const getLoading = (state) => state.callScheduleSettings[NAME].loading;
export const getCallSchedules = (state) =>
  state.callScheduleSettings[NAME].callSchedules;
export const getSelectedCallScheduleId = (state) =>
  state.callScheduleSettings[NAME].selectedCallScheduleId;
export const getCurrentPage = (state) =>
  state.callScheduleSettings[NAME].currentPage;
export const getPageCount = (state) =>
  state.callScheduleSettings[NAME].pageCount;
export const getTotal = (state) => state.callScheduleSettings[NAME].total;

export const selectedCallSchedule = createSelector(
  [getCallSchedules, getSelectedCallScheduleId],
  (callSchedules, selectedCallScheduleId) => {
    if (callSchedules && callSchedules.length > 0) {
      const index = callSchedules.findIndex(
        (callSchedule) =>
          parseInt(callSchedule.id) === parseInt(selectedCallScheduleId)
      );
      return callSchedules[index];
    }
  }
);
