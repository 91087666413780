import * as actionTypes from './actionTypes'
import { DASHBOARD_MANAGER_TYPE } from './constants'

const STRUCTURE_ACTION_HANDLERS = {
  [actionTypes.SET_DASHBOARD_TYPE]: (state, action) => {
    return { ...state, dashboardType: action.dashboardType }
  }
}

const initialState = {
  dashboardType: DASHBOARD_MANAGER_TYPE
}

export default function reducer (state = initialState, action) {
  const handler = STRUCTURE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
