import { request, GET, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import invite from '../invite';

import notify from 'modules/Notifications';

export const getMindbodyUsersForPage = (location, integrationId, pageNumber) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setUsers(payload.users));
      dispatch(loadPageDetails(payload.meta));
    }

    return request(
      'locations/' + location.id + '/third_party_integrations/' + integrationId + '/users?page[number]=' + pageNumber,
      GET,
      null,
      onSuccess,
      onFailure
    );
  };
};

function setUsers(users) {
  return {
    type: actionTypes.SET_USERS,
    users,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

export const inviteMindBodyUsers = (users, location, userRoleId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = { invites: bulkInviteBodyForUsers(users, location.id, userRoleId) };
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      const success = payload.success;
      if (success === true) {
        const inviteCount = payload.number_invited;
        notify.success(inviteCount + ' invites sent');
        dispatch(modal.actions.hideModal());
        dispatch(resetForm());
        dispatch(invite.actions.getPendingInvitesForLocation(location));
      } else {
        notify.error('invites failed to send. Please invite manually or contact support');
      }
    }

    return request('bulk_invite', POST, body, onSuccess, onFailure);
  };
};

export function selectUserId(userId) {
  return {
    type: actionTypes.ADD_USER_ID,
    userId,
  };
}

export function deselectUserId(userId) {
  return {
    type: actionTypes.REMOVE_USER_ID,
    userId,
  };
}

export function setUserRoleId(userRoleId) {
  return {
    type: actionTypes.SET_USER_ROLE_ID,
    userRoleId,
  };
}

export function resetForm() {
  return {
    type: actionTypes.RESET_FORM,
  };
}

function bulkInviteBodyForUsers(users, locationId, userRoleId) {
  let body = {};
  users.forEach((user, index) => {
    if (!user) {
      return;
    }
    const key = index.toString();
    body[key] = {
      invite_name: user.name,
      invite_email: user.email,
      user_role_id: userRoleId,
      location_id: locationId,
      management_service_user_id: user.id,
    };
  });
  return body;
}
