import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FilterFormSections from "modules/components/FilterFormSections";
import * as filterSelectors from "../../filters/selectors";

import TableBreakdownView from "../components/TableBreakdownView";
import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    filterSelectors.getData
  );

  return {
    data: selectors.getData(state),
    compareOption: filterSelectors.getCompareOption(state),
    queryString: queryStringSelector(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWithQueryString: actions.fetchWithQueryString
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableBreakdownView);
