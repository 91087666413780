import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectUserTableRow from './SelectUserTableRow'

export class SelectUserTableView extends Component {

  render () {
    const { users, onUserSelect, selectedUser } = this.props
    const rows = users.map((user, index) => {
      const selectedId = (selectedUser) ? selectedUser.id : null
      return <SelectUserTableRow
        key={index}
        user={user}
        selected={selectedId === user.id}
        onUserSelect={onUserSelect} />
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th style={{ width: '40px' }} />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SelectUserTableView.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUserSelect: PropTypes.func.isRequired,
  selectedUser: PropTypes.object
}

export default SelectUserTableView
