import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import StepTransitionsSections from 'modules/components/FilterFormSections/components/StepTransitionsSections'

import UserData from 'modules/Stores/UserData'

function mapStateToProps (state) {
  return {
    salesFunnelStepLinks: UserData.selectors.allSalesFunnelStepLinks(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTransitionsSections)
