import React, { FC, useState } from 'react';
import Modal from 'react-bootstrap-modal';
import ModalHeader from './ModalHeader';
import { Box } from 'modules/components/Foundation';
import * as callHelpers from 'modules/components/LeadDetail/modules/calls/helpers';
import { TextArea } from 'modules/components/FormFields';

import ScheduleCallModalContainer from '../containers/ScheduleCallModalContainer';
import DealModalContainer from 'modules/components/LeadDetail/containers/DealModalContainer';
import TrialSelectModal from 'modules/components/TrialSelectModal';
import ScheduleAppointmentModalContainer from '../containers/ScheduleAppointmentModalContainer';
import ClassAppointmentModalContainer from 'modules/components/LeadDetail/modules/appointments/containers/ClassAppointmentModalContainer';
import CommonEventModalBody from './CommonEventModalBody';
import CallOutcomeRow from 'modules/components/LeadDetail/modules/calls/components/NewCallModal/CallOutcomeRow';
import { EventModalProps } from './EventModalProps';
import { ContactCallOutcome } from 'types';
import { history } from 'store/store';

const STARTED_TRIAL_MODAL = 'CALL_DETAILS_MODAL/STARTED_TRIAL_MODAL';
const DEAL_MODAL = 'CALL_DETAILS_MODAL/DEAL_MODAL_AFTER_PRESENTATION';

const ALL_CONTACT_CALL_OUTCOMES = [
  ContactCallOutcome.BookedAppointment,
  ContactCallOutcome.LeftMessage,
  ContactCallOutcome.NoAnswer,
  ContactCallOutcome.NotInterested,
  ContactCallOutcome.Other,
  ContactCallOutcome.SignedUp,
  ContactCallOutcome.StartedTrial,
  ContactCallOutcome.StillThinking,
];

const EventModal: FC<EventModalProps> = (props) => {
  const [selectedCallOutcome, setSelectedCallOutcome] = useState<ContactCallOutcome | undefined>();
  const [note, setNote] = useState('');

  const { currentModal, modalName, hideModal, selectedEvent } = props;

  const trialName = selectedEvent?.lead?.trial?.name;

  const callOutcomeSelected = (callOutcome: ContactCallOutcome) => {
    setSelectedCallOutcome(callOutcome);
  };

  const handleInput = (_name: string, value: string, _isValid: boolean) => {
    setNote(value);
  };

  const recordCall = async () => {
    const modalName = modalForActionType(selectedCallOutcome);
    if (modalName) {
      setSelectedCallOutcome(undefined);
      props.showModal(modalName);
    } else {
      cleanUpAndHide();
    }

    const { toggleComplete } = props;
    toggleComplete(selectedEvent, selectedCallOutcome, note);
  };

  const trialSelected = (trial: any, trialStartsAt: Date) => {
    const { addLeadToTrial } = props;
    const lead = selectedEvent?.lead;
    addLeadToTrial(lead, trial, trialStartsAt);
    cleanUpAndHide();
  };

  function modalForActionType(actionType?: ContactCallOutcome) {
    const { classIntegrationId, classAppointmentModalName, rescheduleModalName, scheduleCallModalName } = props;
    switch (actionType) {
      case callHelpers.BOOKED_APPOINTMENT:
        return classIntegrationId ? classAppointmentModalName : rescheduleModalName;
      case callHelpers.NO_ANSWER:
      case callHelpers.LEFT_MESSAGE:
      case callHelpers.STILL_THINKING:
        if (selectedEvent.lead.call_schedule_step) {
          return null;
        }
        return scheduleCallModalName;
      case callHelpers.SIGNED_UP:
        return DEAL_MODAL;
      case callHelpers.STARTED_TRIAL:
        return STARTED_TRIAL_MODAL;
      default:
        return null;
    }
  }

  const cleanUpAndHide = () => {
    setSelectedCallOutcome(undefined);
    setNote('');
    hideModal();
  };

  const goToLead = () => {
    if (!selectedEvent?.lead) return;
    cleanUpAndHide();
    history.push('/secure/manageLeads?lead_id=' + selectedEvent?.lead.id);
  };

  if (!selectedEvent?.lead) {
    return null;
  }

  return (
    <>
      <Modal show={currentModal === modalName} onHide={cleanUpAndHide}>
        <div style={{ paddingBottom: '20px' }}>
          <ModalHeader hideModal={cleanUpAndHide} />
          {selectedEvent && (
            <>
              <CommonEventModalBody
                displayName={selectedEvent.lead.display_name}
                email={selectedEvent.lead.email}
                title={selectedEvent.title}
                start={selectedEvent.start}
                dueDate={selectedEvent.end}
                outcome={selectedEvent.outcome}
                userDisplayName={selectedEvent.user.display_name}
                goToLead={goToLead}
                type="call"
              />
              {!selectedEvent.completed && (
                <>
                  <Box row doesWrap>
                    {ALL_CONTACT_CALL_OUTCOMES.map((type, index) => {
                      const isSelected = type === selectedCallOutcome;
                      const disabled = type === ContactCallOutcome.StartedTrial && trialName !== null;
                      return (
                        <CallOutcomeRow
                          key={index}
                          type={type}
                          isSelected={isSelected}
                          onClick={callOutcomeSelected}
                          disabled={disabled}
                          trialName={trialName}
                        />
                      );
                    })}
                  </Box>
                  <Box row>
                    <TextArea
                      formFieldStyle="w-30"
                      name="note"
                      label="Call notes"
                      placeholder="Any notes about this call?"
                      onChange={handleInput}
                      rows={4}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            {!selectedEvent?.completed ? 'Cancel' : 'Close'}
          </button>
          {!selectedEvent?.completed && (
            <button className="btn btn-success push-5-r push-10" type="button" disabled={!selectedCallOutcome} onClick={recordCall}>
              <i className="si si-calendar" /> Record call
            </button>
          )}
        </Modal.Footer>
      </Modal>
      {selectedEvent && (
        <>
          <ScheduleCallModalContainer
            eventTypes={props.eventTypes}
            usersIds={props.usersIds}
            timezone={props.timezone}
            modalName={props.scheduleCallModalName}
            lead={selectedEvent.lead}
          />
          {props.classIntegrationId && (
            <ClassAppointmentModalContainer
              modalName={props.classAppointmentModalName}
              lead={props.selectedEvent.lead}
              classIntegrationId={props.classIntegrationId}
              classIntegrationName={props.classIntegrationName}
              rescheduleModalName={props.rescheduleModalName}
            />
          )}
          <ScheduleAppointmentModalContainer
            modalName={props.rescheduleModalName}
            lead={selectedEvent.lead}
            eventTypes={props.eventTypes}
            usersIds={props.usersIds}
            timezone={props.timezone}
          />
          <DealModalContainer modalName={DEAL_MODAL} lead={selectedEvent.lead} />
          <TrialSelectModal.TrialSelectModalContainer onTrialSelect={trialSelected} modalName={STARTED_TRIAL_MODAL} />
        </>
      )}
    </>
  );
};

export default EventModal;
