import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UserSelectModal from "../components/UserSelectModal";

import * as selectors from "../selectors";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  return {
    loading: selectors.getLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectModal);
