import { request, buildIndexUrl, GET } from "utils/apiUtils";

import regionStore from "./index";

export const getRegions = (pageNumber = 1, searchTerm = null) => {
  return dispatch => {
    dispatch(regionStore.actions.setLoading(true));
    function onFailure(payload) {
      dispatch(regionStore.actions.setLoading(false));
      dispatch(regionStore.actions.setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(regionStore.actions.setLoading(false));
      dispatch(regionStore.actions.setRegions(payload.regions));
      dispatch(regionStore.actions.setPageDetails(payload.meta));
    }

    const url = buildIndexUrl("regions", pageNumber, null, searchTerm);
    return request(url, GET, null, onSuccess, onFailure);
  };
};
