import * as actionTypes from './actionTypes'

import { safePropertyForObject } from 'utils/formHelpers'

const EDIT_LOCATION_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM]: (state, action) => {
    return initialStateForLocation(action.location)
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.UPDATING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  }
}

function initialStateForLocation (location) {
  return {
    errors: null,
    loading: false,
    data: {
      fields: {
        name: {
          value: safePropertyForObject(location, 'name'),
          isValid: true
        },
        adminName: {
          value: safePropertyForObject(location, 'admin_name'),
          isValid: true
        },
        addressLineOne: {
          value: safePropertyForObject(location, 'address_line_one'),
          isValid: true
        },
        addressLineTwo: {
          value: safePropertyForObject(location, 'address_line_two'),
          isValid: true
        },
        phoneNumber: {
          value: safePropertyForObject(location, 'phone_number'),
          isValid: true
        },
        city: {
          value: safePropertyForObject(location, 'city'),
          isValid: true
        },
        state: {
          value: safePropertyForObject(location, 'state'),
          isValid: true
        },
        countryId: {
          value: safePropertyForObject(location, 'country_id'),
          isValid: true
        },
        zipPostcode: {
          value: safePropertyForObject(location, 'zip_postcode'),
          isValid: true
        },
        timezone: {
          value: safePropertyForObject(location, 'timezone'),
          isValid: true
        }
      }
    }
  }
}

export default function reducer (state = initialStateForLocation(null), action) {
  const handler = EDIT_LOCATION_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
