import * as actionTypes from './actionTypes'

const TRIALS_ACTION_HANDLERS = {
  [actionTypes.SET_TRIALS]: (state, action) => {
    return { ...state, trials: action.trials }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.TRIAL_SAVED]: (state, action) => {
    const oldTrials = state.trials
    const index = indexForTrial(oldTrials, action.trial.id)
    let newTrials = []
    if (index < 0) {
      newTrials = [
        ...oldTrials,
        action.trial
      ]
    } else {
      newTrials = [
        ...oldTrials.slice(0, index),
        action.trial,
        ...oldTrials.slice(index + 1)
      ]
    }

    return { ...state, trials: newTrials }
  },
  [actionTypes.DELETE_TRIAL_WITH_ID]: (state, action) => {
    const oldTrials = state.trials
    const index = indexForTrial(oldTrials, action.trialId)
    const newTrials = [
      ...state.trials.slice(0, index),
      ...state.trials.slice(index + 1)
    ]
    return { ...state, trials: newTrials }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  trials: [],
  errors: null,
  loading: false,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = TRIALS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

function indexForTrial (trials, trialId) {
  return trials.findIndex(trial => parseInt(trial.id) === parseInt(trialId))
}
