import { combineReducers } from 'redux';
import leadGoals from './modules/leadGoals';
import leadLostReasons from './modules/leadLostReasons';
import heardAboutMethods from './modules/heardAboutMethods';
import leadSources from './modules/leadSources';

export default combineReducers({
  [leadGoals.constants.NAME]: leadGoals.reducer,
  [leadLostReasons.constants.NAME]: leadLostReasons.reducer,
  [heardAboutMethods.constants.NAME]: heardAboutMethods.reducer,
  [leadSources.constants.NAME]: leadSources.reducer,
});
