import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  AssignedToPanel,
  LeadSourcePanel,
  GenderPanel,
  FitnessGoalsPanel,
  DateOfBirthPanel,
  HeardAboutPanel,
  TrialPanelContainer,
  LeadCheckInsPanel,
} from './DetailPanels';
import LeadTagContainer from '../../../containers/TagContainer';
import './LeadMoreDetail.css';

export class LeadMoreDetailsView extends Component {
  flatTagsForLead = () => {
    const { lead } = this.props;
    if (!lead.lead_tags) {
      return [];
    }
    return lead.lead_tags.map((tag, index) => {
      return {
        id: index,
        text: tag.name,
      };
    });
  };

  render() {
    const { lead, listUpdateProtocol, detailsLoading } = this.props;
    return (
      <div className="push-20">
        {detailsLoading && (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h3 className="push-10">Loading Detail</h3>
              <i className="fa fa-2x fa-sun-o fa-spin" />
            </div>
          </div>
        )}
        {!detailsLoading && lead && (
          <div>
            <div className="row push">
              <div className="col-sm-3">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <AssignedToPanel {...this.props} salesperson={lead.cached_salesperson} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <LeadSourcePanel {...this.props} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <LeadCheckInsPanel lead={lead} />
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-lg-12">
                    <GenderPanel {...this.props} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                  <div className="col-lg-12">
                    <DateOfBirthPanel {...this.props} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                  <div className="col-lg-12">
                    <FitnessGoalsPanel {...this.props} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                  <div className="col-lg-12">
                    <HeardAboutPanel {...this.props} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                  <div className="col-lg-12">
                    <TrialPanelContainer {...this.props} listUpdateProtocol={listUpdateProtocol} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <LeadTagContainer lead={lead} leadTags={this.flatTagsForLead()} listUpdateProtocol={listUpdateProtocol} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

LeadMoreDetailsView.propTypes = {
  lead: PropTypes.object,
  detailsLoading: PropTypes.bool.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  assignLeadToUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  formats: PropTypes.PropTypes.object.isRequired,
  currentModal: PropTypes.string,
};
