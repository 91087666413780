import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { HorizontalBar } from 'react-chartjs-2'

export default class ChartView extends Component {

  generateOptions () {
    return {
      tooltips: {
        enabled: true
      },
      hover :{
        animationDuration:0
      },
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero:true,
            fontSize:11,
            display: false
          },
          scaleLabel:{
            display:false
          },
          gridLines: {
          },
          stacked: true
        }],
        yAxes: [{
          barThickness: 25,
          gridLines: {
            display:false,
            color: '#fff',
            zeroLineColor: '#fff',
            zeroLineWidth: 0
          },
          ticks: {
            fontSize:11
          },
          stacked: true
        }]
      },
      legend:{
        display: true
      }
    }
  }

  render () {
    const { labels, data } = this.props
    const chartData = {
      type: 'horizontalBar',
      labels: labels,
      datasets: data
    }

    return (
      <HorizontalBar
        data={chartData}
        redraw={true}
        options={this.generateOptions()} />
    )
  }
}

ChartView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}
