import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserSelectView from "../components/UserSelectView";

import modal from "modules/Stores/Modal";

import selectLocation from "../../selectLocation";
import * as actions from "../actions";
import * as selectors from "../selectors";

function mapStateToProps(state, props) {
  const locationId = selectLocation.selectors.getLocationId(state);
  const locationName = selectLocation.selectors.getLocationName(state);
  return {
    users: selectors.getPotentialUsers(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    selectedUser: selectors.getFromUser(state),
    locationId: locationId,
    locationName: locationName,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUsers: actions.getPotentialUsersForLocationId,
      setSelectedUser: actions.setFromUser,
      hideModal: modal.actions.hideModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectView);
