import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SmsAccountTableRow extends Component {

  onRowSelect = () => {
    const { smsAccount, onSmsAccountSelect } = this.props
    onSmsAccountSelect(smsAccount.id)
  }

  render () {
    const { smsAccount, selected } = this.props
    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''
    const rechargeIconWord = (smsAccount.auto_recharge) ? 'on' : 'off'
    return (
      <tr className={textClass + ' selectable ' + backgroundClass}
        onClick={this.onRowSelect}>
        <td>{smsAccount.location_name}</td>
        <td className='text-center'>
          <i className='fa fa-dollar push-5-r' />
          {parseFloat(Math.round(smsAccount.balance * 100) / 100).toFixed(2)}
        </td>
        <td className='text-center'>
          {rechargeIconWord}
        </td>
      </tr>
    )
  }
}

SmsAccountTableRow.propTypes = {
  smsAccount: PropTypes.object.isRequired,
  onSmsAccountSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool
}

SmsAccountTableRow.defaultProps = {
  showSaleDetails: true
}
