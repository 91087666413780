import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PageSelection } from 'modules/components';

import { CallRow } from './CallRow';

import CompleteCallModalContainer from '../containers/CompleteCallModalContainer';
import { FINISHED_CALL_MODAL } from 'modules/components/LeadDetail/modules/calls/constants';
import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers';

export class CallListView extends Component {
  UNSAFE_componentWillMount() {
    this.fetchCallsForPage(1);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.startDate !== this.props.startDate || nextProps.endDate !== this.props.endDate) {
      this.props.fetchInRange(nextProps.startDate, nextProps.endDate, nextProps.currentPage, nextProps.location);
    }
  }

  pageClicked = (pageNumber) => {
    this.fetchCallsForPage(pageNumber);
  };

  fetchCallsForPage = (pageNumber) => {
    const { fetchInRange, startDate, endDate, location } = this.props;
    fetchInRange(startDate, endDate, pageNumber, location);
  };

  markCompleteSelected = (call) => {
    const { selectCall, showModal } = this.props;
    showModal(FINISHED_CALL_MODAL);
    selectCall(call.id);
  };

  render() {
    const { calls, loading, totalCount, timezone, selectedCall, location } = this.props;
    const { pageCount, currentPage } = this.props;
    const blockStyle = loading ? 'block block-bordered block-opt-refresh' : 'block block-bordered';
    const callList = calls.map((call, index) => {
      return (
        <CallRow
          key={index}
          call={call}
          markCompleteSelected={this.markCompleteSelected}
          timezone={timezone}
          isMobile={this.props.isMobile}
          openLeadModal={this.props.openLeadModal}
        />
      );
    });

    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">{pluralizationHelper('Contact calls', totalCount)}</h3>
        </div>
        <div className="block-content">
          <div className="list-group">
            {calls.length > 0 && <ul className="nav-users push">{callList}</ul>}
            {calls.length === 0 && (
              <div className="alert alert-success alert-dismissable">
                <p>
                  <i className="fa fa-check" /> No calls to make
                </p>
              </div>
            )}
            {pageCount && currentPage && pageCount > 1 && (
              <div className="text-center push">
                <PageSelection pageCount={pageCount} currentPage={currentPage} pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
        {selectedCall && (
          <CompleteCallModalContainer modalName={FINISHED_CALL_MODAL} location={location} call={selectedCall} lead={selectedCall.lead} />
        )}
      </div>
    );
  }
}

CallListView.propTypes = {
  loading: PropTypes.bool.isRequired,
  calls: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  fetchInRange: PropTypes.func.isRequired,
  openLeadModal: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectCall: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  selectedCall: PropTypes.object,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
};

export default CallListView;
