import * as ACTIONS from './actionTypes'

const LEAD_ACTIONS_ACTION_HANDLERS = {
  [ACTIONS.FETCHING_TODOS]: (state, action) => {
    return { ...state, loading: true }
  },
  [ACTIONS.FETCHING_TODOS_SUCCESS]: (state, action) => {
    return { ...state, loading: false, todos: action.todos }
  },
  [ACTIONS.FETCHING_TODOS_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [ACTIONS.UPDATING_TODO]: (state, action) => {
    const index = indexForToDo(state.todos, action.todoId)
    const updatedTodo = { ...state.todos[index], loading: true }
    const updatedTodos = [
      ...state.todos.slice(0, index),
      updatedTodo,
      ...state.todos.slice(index + 1)
    ]
    return { ...state, todos: updatedTodos }
  },
  [ACTIONS.ADD_TODO]: (state, action) => {
    const updatedTodos = [
      action.todo,
      ...state.todos
    ]
    return { ...state, todos: updatedTodos }
  },
  [ACTIONS.UPDATING_TODO_SUCCESS]: (state, action) => {
    const updatedTodo = action.todo
    const index = indexForToDo(state.todos, updatedTodo.id)
    const updatedTodos = [
      ...state.todos.slice(0, index),
      updatedTodo,
      ...state.todos.slice(index + 1)
    ]
    return { ...state, loading: false, todos: updatedTodos }
  },
  [ACTIONS.UPDATING_TODO_FAILURE]: (state, action) => {
    const index = indexForToDo(state.todos, action.todoId)
    const updatedTodo = { ...state.todos[index], loading: false }
    const updatedTodos = [
      ...state.todos.slice(0, index),
      updatedTodo,
      ...state.todos.slice(index + 1)
    ]
    return { ...state, loading: false, todos: updatedTodos }
  },
  [ACTIONS.TODO_DELETED]: (state, action) => {
    const index = indexForToDo(state.todos, action.todoId)
    const updatedTodos = [
      ...state.todos.slice(0, index),
      ...state.todos.slice(index + 1)
    ]
    return { ...state, loading: false, todos: updatedTodos }
  }
}

const initialState = {
  loading: false,
  todos: []
}

function indexForToDo (todos, todoId) {
  return todos.findIndex(_todo => _todo.id === todoId)
}

export default function reducer (state = initialState, action) {
  const handler = LEAD_ACTIONS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
