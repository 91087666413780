import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import { FormFields } from "modules/components";
import { validatorForOption } from "../validators";
import { fieldsForOption } from "../helpers";

const { TextField } = FormFields;

export class ConfigureForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onInput(newData);
  };

  render() {
    const { companyName, apiKey } = this.props.data.fields;
    const validator = validatorForOption(this.props.name);
    const fields = fieldsForOption(this.props.name);
    return (
      <div className="row">
        {fields.companyName && (
          <div className="col-sm-6">
            <TextField
              name="companyName"
              label={fields.companyName}
              placeholder=""
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={companyName.value}
            />
          </div>
        )}
        {fields.apiKey && (
          <div className="col-sm-6">
            <TextField
              name="apiKey"
              label={fields.apiKey}
              placeholder=""
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              validator={validator}
              value={apiKey.value}
            />
          </div>
        )}
      </div>
    );
  }
}

ConfigureForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default ConfigureForm;
