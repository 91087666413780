import PropTypes from "prop-types";
import React, { Component } from "react";
import EventView from "modules/components/Calendar/components/EventView";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "moment-timezone";
import "./styles.css";
require("react-big-calendar/lib/css/react-big-calendar.css");
const localizer = momentLocalizer(moment);

export class ScheduleCalendarView extends Component {
  componentDidMount() {
    const endDate = moment().endOf("day").utc().format();
    const startDate = moment().startOf("day").utc().format();
    const { user, locationId } = this.props;
    if (!user) {
      return;
    }
    const users = [user];
    const locations = [locationId];
    this.props.loadAllEventsForUsersInDateRange(
      startDate,
      endDate,
      this.props.timezone,
      users,
      locations
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date) {
      if (!nextProps.date) {
        return;
      }
      const endDate = moment(nextProps.date).endOf("day").utc().format();
      const startDate = moment(nextProps.date).startOf("day").utc().format();
      if (!nextProps.user) {
        return;
      }
      const users = [nextProps.user];
      const locations = [nextProps.locationId];
      this.props.loadAllEventsForUsersInDateRange(
        startDate,
        endDate,
        this.props.timezone,
        users,
        locations
      );
    }
  }

  onSelectSlot = (slotInfo) => {
    const { date } = this.props;
    const start = moment(slotInfo.start);
    const end = moment(slotInfo.end);
    const duration = moment.duration(end.diff(start));
    const length = `${duration.asMinutes()}`;
    const time = start.format();

    this.props.setTime(time);
    this.props.setLength(length);
    this.generateAndReturnDate(date, time, length);
  };

  generateAndReturnDate = (date, time, length) => {
    if (!date || !time) {
      return;
    }
    const timeMoment = moment(time);

    let dateTime = date.clone();
    dateTime.set("hour", timeMoment.hour());
    dateTime.set("minute", timeMoment.minute());

    if (this.props.onUpdate) {
      this.props.onUpdate(dateTime.format(), length);
    }
  };

  combinedEvents = () => {
    const { user, time, date, length, events } = this.props;

    if (time && length) {
      const timeMoment = moment(time);
      const start = moment(date).set({
        hour: timeMoment.get("hour"),
        minute: timeMoment.get("minute"),
        second: timeMoment.get("second"),
      });
      const end = start.clone().add(parseInt(length), "minutes");
      const newEvent = {
        id: "TEMP",
        start: start.toDate(),
        end: end.toDate(),
        title: "Proposed Time",
        user: user,
        color: "#000",
      };

      const allEvents = [...events, newEvent];
      return allEvents;
    } else {
      return events;
    }
  };

  render() {
    const eventPropGetter = function (event) {
      return { style: { backgroundColor: event.color } };
    };

    const CustomToolbar = () => {
      return <div />;
    };

    const min = new Date();
    min.setHours(6);
    min.setMinutes(0, 0, 0);

    const max = new Date();
    max.setHours(23);
    max.setMinutes(0, 0, 0);

    const style = { paddingRight: "0px" };
    const id = "firstDayCalendar";
    const date = this.props.date ? moment(this.props.date) : moment();
    const jsDate = date.toDate();
    const blockStyle = this.props.loading ? "block block-opt-refresh" : "block";
    return (
      <div className={blockStyle}>
        <div id={id} className="dayCalendar block-content" style={style}>
          <Calendar
            min={min}
            max={max}
            localizer={localizer}
            timeslots={2}
            defaultView="day"
            onSelectSlot={this.onSelectSlot}
            events={this.combinedEvents()}
            selectable={true}
            onNavigate={this.onNavigate}
            defaultDate={jsDate}
            date={jsDate}
            eventPropGetter={eventPropGetter}
            components={{
              event: EventView,
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    );
  }
}

ScheduleCalendarView.propTypes = {
  timezone: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadAllEventsForUsersInDateRange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  user: PropTypes.object,
};

export default ScheduleCalendarView;
