export function funnelStepCountsAsDataSet (counts, salesFunnelSteps) {
  const keys = salesFunnelSteps.map((step) => step.name)
  let countValues = []
  keys.forEach((key, index) => {
    countValues.push(counts[key])
  })
  const dataSets = [{
    label: 'Lead',
    data: countValues,
    backgroundColor: '#98B9E3',
    borderColor: '#5C90D2'
  }]
  return dataSets
}

export function isEmpty (dataSet) {
  const data = dataSet[0].data
  return data.filter(value => value > 0).length === 0
}
