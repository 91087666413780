import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AccountView from '../components/AccountView'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView)
