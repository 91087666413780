import React, { FC } from 'react';
import * as appointmentHelpers from 'modules/components/LeadDetail/modules/appointments/helpers';
import { AppointmentOutcome } from 'types';
interface IAppointmentOutcomeRowProps {
  type: AppointmentOutcome;
  onClick: (appointmentOutcome: AppointmentOutcome) => void;
  isSelected: boolean;
  disabled: boolean;
  trialName?: string;
}

const AppointmentOutcomeRow: FC<IAppointmentOutcomeRowProps> = (props) => {
  const { type, isSelected, onClick, disabled, trialName } = props;
  function getBgStyle() {
    if (disabled) {
      return 'bg-disabled';
    } else if (isSelected) {
      return 'bg-success-light';
    } else {
      return 'bg-success';
    }
  }

  function tileClicked() {
    if (disabled === false) {
      onClick(type);
    }
  }

  const icon = appointmentHelpers.optionIcon(type);
  const title = appointmentHelpers.optionTitle(type);
  const bgStyle = getBgStyle();
  const disabledCursor = disabled ? ' inactive' : '';

  return (
    <div className="col-xs-12" style={{ marginBottom: '7px' }}>
      <div
        style={{
          border: '1px solid #e9e9e9',
          boxShadow: 'none',
          WebkitBoxShadow: 'none',
          overflow: 'hidden',
          marginBottom: '1px',
        }}
        className={'block block-link-hover1 text-center selectable' + disabledCursor}
        onClick={tileClicked}
      >
        <div
          className={'block-content-full col-xs-3 ' + bgStyle}
          style={{
            padding: '6px',
            width: '42px',
            height: '42px',
          }}
        >
          <i className={icon + ' fa-2x text-white'} />
        </div>
        <div className="block-content block-content-full block-content-mini col-xs-9" style={{ textAlign: 'left' }}>
          {disabled && trialName ? 'Already in ' + trialName : title}
        </div>
      </div>
    </div>
  );
};

export default AppointmentOutcomeRow;
