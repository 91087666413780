import React from 'react'

export function hourOptions () {
  let options = []
  for (let i = 1; i < 13; i ++) {
    options.push(
      <option className='timeOption' key={i} value={i}>{i.toString()}</option>
    )
  }
  return options
}

export function minuteOptions () {
  let options = []
  for (let i = 0; i < 59; i = i + 15) {
    let display = i
    if (i === 0) { display = '00' }
    options.push(
      <option className='timeOption' key={i} value={i}>{display.toString()}</option>
    )
  }
  return options
}

export function periodOptions () {
  return [
    <option className='timeOption' key='am' value='am'>AM</option>,
    <option className='timeOption' key='pm' value='pm'>PM</option>
  ]
}
