import * as constants from "./constants";

import SalesPerformanceContainer from "./containers/SalesPerformanceContainer";
import LeadsReportContainer from "./containers/LeadsReportContainer";
import LeadAllocationContainer from './containers/LeadAllocationContainer'
import TimeTakenContainer from './containers/TimeTakenContainer'
import LostLeadsContainer from './containers/LostLeadsContainer'
import DealsReportContainer from './containers/DealsReportContainer'
import TrialsReportContainer from './containers/TrialsReportContainer'
import ActivityReportContainer from './containers/ActivityReportContainer'

import {
  LeadStatusContainer,
  HeardAboutMethodsSectionContainer,
  LeadSourceSectionContainer,
  TagsSectionContainer,
  AssignedToContainer,
  LocationsSectionContainer,
  RegionsSectionContainer,
  UsersContainer,
  DateRangeContainer,
  CompareSelectContainer,
  CalculatingSelectSectionContainer,
  StatusUpdatedRangeContainer,
  FunnelStepsContainer,
  TrialsSectionContainer,
} from "modules/components/FilterFormSections/modules/filterOptions";

export function selectReportContainer(reportType) {
  switch (reportType) {
    case constants.SALES_PERFORMANCE_REPORT:
      return SalesPerformanceContainer;
    case constants.LEADS_REPORT:
      return LeadsReportContainer;
    case constants.LEAD_ALLOCATION_REPORT:
      return LeadAllocationContainer;
    case constants.TIME_TAKEN_REPORT:
      return TimeTakenContainer;
    case constants.LOST_LEADS_REPORT:
      return LostLeadsContainer
    case constants.DEALS_REPORT:
      return DealsReportContainer
    case constants.TRIALS_REPORT:
      return TrialsReportContainer
    case constants.ACTIVITY_REPORT_CALLS:
    case constants.ACTIVITY_REPORT_APPOINTMENTS:
    case constants.ACTIVITY_REPORT_TASKS:
      return ActivityReportContainer
    default:
      break;
  }
}

export function selectFilterComponent(filter) {
  switch (filter) {
    case constants.COMPARE_BY:
      return CompareSelectContainer;
    case constants.CALCULATE_BY:
      return CalculatingSelectSectionContainer;
    case constants.LEAD_SOURCES:
      return LeadSourceSectionContainer;
    case constants.HEARD_ABOUT_METHODS:
      return HeardAboutMethodsSectionContainer
    case constants.TAGS:
      return TagsSectionContainer
    case constants.TRIALS:
      return TrialsSectionContainer
    case constants.STATUS_UPDATED_RANGE:
      return StatusUpdatedRangeContainer
    case constants.DATE_RANGE:
      return DateRangeContainer
    case constants.USERS:
      return UsersContainer
    case constants.FUNNEL_STEPS:
      return FunnelStepsContainer
    case constants.ASSIGNED_TO:
      return AssignedToContainer
    case constants.LOCATIONS:
      return LocationsSectionContainer
    case constants.REGIONS:
      return RegionsSectionContainer
    case constants.LEAD_STATUS:
      return LeadStatusContainer
    default:
      return null;
  }
}

export function stringForCompareOption(option) {
  switch (option) {
    case constants.CLUBS:
      return "Clubs";
    case constants.USERS:
      return "Users";
    case constants.LEAD_SOURCES:
      return "Lead Sources";
    default:
      return "";
  }
}

export function stringForCalculatingOption(option) {
  switch (option) {
    case constants.LEAD_CREATED:
      return "Lead Created";
    case constants.EVENT_OCCURED:
      return "Event Occured";
    default:
      return "";
  }
}

export function humanReadableFilterName(filter) {
  switch (filter) {
    case constants.COMPARE_BY:
      return 'Compare By';
    case constants.CALCULATE_BY:
      return 'Calculate By';
    case constants.LEAD_SOURCES:
      return 'Lead Sources';
    case constants.HEARD_ABOUT_METHODS:
      return 'Heard About Methods'
    case constants.TAGS:
      return 'Tags'
    case constants.DATE_RANGE:
      return 'Date Range'
    case constants.STATUS_UPDATED_RANGE:
      return 'Status Updated Range'
    case constants.USERS:
      return 'Users'
    case constants.ASSIGNED_TO:
      return 'Assigned To'
    case constants.LOCATIONS:
      return 'Locations'
    case constants.REGIONS:
      return 'Regions'
    case constants.LEAD_STATUS:
      return 'Lead Status'
    case constants.FUNNEL_STEPS:
      return 'Funnel Steps'
    case constants.TRIALS:
      return 'Trials'
    default:
      return null;
  }
}

export function humanReadableReportName(report) {
  switch (report) {
    case constants.SALES_PERFORMANCE_REPORT:
      return 'Sales Performance';
    case constants.TIME_TAKEN_REPORT:
      return 'Time Taken';
    case constants.LEADS_REPORT:
      return 'Leads Report';
    case constants.LEAD_ALLOCATION_REPORT:
      return 'Lead Allocation';
    case constants.LOST_LEADS_REPORT:
      return 'Lost leads';
    case constants.DEALS_REPORT:
      return 'Deals Report';
    case constants.TRIALS_REPORT:
      return 'Trials Report';
    case constants.ACTIVITY_REPORT_CALLS:
      return 'Activity Report: Calls';
    case constants.ACTIVITY_REPORT_APPOINTMENTS:
      return 'Activity Report: Appointments';
    case constants.ACTIVITY_REPORT_TASKS:
      return 'Activity Report: Tasks';
    default:
      return null;
  }
}


export function reportTagColor(type) {
  switch (type) {
    case constants.SALES_PERFORMANCE_REPORT:
      return "#5C90D2";
    case constants.TIME_TAKEN_REPORT:
      return "#5C90D2";
    case constants.LEADS_REPORT:
      return "#FF6B6B";
    case constants.LEAD_ALLOCATION_REPORT:
      return "#14ADC4";
    case constants.LOST_LEADS_REPORT:
      return '#A48AD4';
    case constants.DEALS_REPORT:
      return '#FF6C9D';
    case constants.TRIALS_REPORT:
      return '#44B4A6';
    case constants.ACTIVITY_REPORT_CALLS:
    case constants.ACTIVITY_REPORT_APPOINTMENTS:
    case constants.ACTIVITY_REPORT_TASKS:
      return '#14ADC4';
    default:
      return null
  }
}