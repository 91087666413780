import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userData from 'modules/Stores/UserData'
import session from 'modules/Stores/Session'
import SalesTeam from '../../modules/SalesTeam'
import locationSelect from '../../modules/LocationSelect'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    location: locationSelect.selectors.getSelectedLocation(state),
    salesTeam: SalesTeam.selectors.getSalesTeam(state),
    salesFunnelSteps:userData.selectors.getSalesFunnelSteps(state),
    loading: SalesTeam.selectors.getLoading(state),
    startDate: state.dashboard.DateRange.startDate,
    endDate: state.dashboard.DateRange.endDate,
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchSalesTeamForLocation: SalesTeam.actions.fetchSalesTeamForLocation },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTeam.components.TeamLeaderboardView)
