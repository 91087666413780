import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

// FETCH New Leads
export const fetchInRange = (startDate, endDate, pageNumber, location) => {
  return dispatch => {
    dispatch(fetchingCalls());

    function onFailure(errors) {
      dispatch(fetchCallsFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchCallsSuccess(payload.contact_calls));
      dispatch(loadPageDetails(payload.meta));
    }

    let queryString =
      "contact_calls_for_user?start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&completed=false&location_id=" +
      location.id;

    if (pageNumber) {
      queryString = queryString + "&page[number]=" + pageNumber;
    }
    return request(queryString, GET, null, onSuccess, onFailure);
  };
};

function fetchingCalls() {
  return {
    type: actionTypes.FETCH
  };
}

function fetchCallsSuccess(calls) {
  return {
    type: actionTypes.FETCH_SUCCESS,
    calls
  };
}

export function fetchCallsFailed(errors, todoType) {
  return {
    type: actionTypes.FETCH_FAILED,
    errors,
    todoType
  };
}

export function selectCall(callId) {
  return {
    type: actionTypes.SELECT,
    callId
  };
}

function loadPageDetails(pageDetails, todoType) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
    todoType
  };
}

export function removeSelected() {
  return {
    type: actionTypes.REMOVE_SELECTED
  };
}
