import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FacebookComposeView from '../components/FacebookComposeView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    sending: selectors.getSending(state),
    updating: selectors.getUpdating(state),
    errors: selectors.getErrors(state),
    facebookMessengerChatRoom: selectors.getChatRoom(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createNewFacebookMessage: actions.createNewFacebookMessage,
    setFacebookUserHumanOnlyStatus: actions.setFacebookUserHumanOnlyStatus,
    createChatRoomForMessengerUser: actions.createChatRoomForMessengerUser,
    unsubscribeForMessengerChatRoom: actions.unsubscribeForMessengerChatRoom
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookComposeView)
