import { NAME } from './constants'

export const getFacebookMessages = (state) => state.manageLeads.leadDetail.messages[NAME].facebookMessages
export const getLoading = (state) => state.manageLeads.leadDetail.messages[NAME].loading
export const getSending = (state) => state.manageLeads.leadDetail.messages[NAME].sending
export const getUpdating = (state) => state.manageLeads.leadDetail.messages[NAME].updating
export const getChatRoom = (state) => state.manageLeads.leadDetail.messages[NAME].chatRoom
export const getErrors = (state) => state.manageLeads.leadDetail.messages[NAME].errors
export const getPageCount = (state) => state.manageLeads.leadDetail.messages[NAME].pageCount
export const getCurrentPage = (state) => state.manageLeads.leadDetail.messages[NAME].currentPage
