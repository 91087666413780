import PropTypes from "prop-types";
import React, { Component } from "react";

import * as FormFields from "modules/components/FormFields";
import "./editorStyles.css";
const { TextArea, SelectField } = FormFields;

export class TextInputView extends Component {
  state = {
    cursorPosition: 0,
  };

  onChange = (name, value) => {
    this.props.setText(value);
  };

  onSelect = (name, value) => {
    const { text, setText } = this.props;
    const { cursorPosition } = this.state;
    if (text) {
      const newText =
        text.slice(0, cursorPosition) + value + text.slice(cursorPosition);
      setText(newText);
    } else {
      setText(value);
    }
  };

  updateCusorPosition = (name, cursorPosition) => {
    this.setState({ ...this.state, cursorPosition: cursorPosition });
  };

  render() {
    const { text, mergeTags, readOnly } = this.props;
    return (
      <div>
        <div className="row push-10">
          <SelectField
            id="personalization"
            placeHolderText="Insert merge tag"
            onChange={this.onSelect}
            name="personalization"
            initialValue={null}
            formFieldStyle={"input-group"}
            icon={"fa fa-user"}
            possibleOptions={mergeTags}
            disabled={readOnly}
            displayFieldName="title"
          />
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="email-text-editor" onClick={this.focus}>
              <TextArea
                name={"text"}
                onChange={this.onChange}
                cursorUpdated={this.updateCusorPosition}
                needsFormGroup={false}
                autoResize
                disabled={readOnly}
                value={text}
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TextInputView.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TextInputView;
