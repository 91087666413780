import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class PlanDetailsPanel extends Component {

  usersString = () => {
    const { membershipPermissions } = this.props
    if (membershipPermissions.multi_user) {
      return (
        <span><strong>Unlimited</strong> Users</span>
      )
    } else {
      return (
        <span><strong>Single</strong> User</span>
      )
    }
  }

  locationString = () => {
    const { membershipPermissions, membership } = this.props
    if (membershipPermissions.multi_user) {
      return (
        <span><strong>${membership.amount}</strong> per club</span>
      )
    } else {
      return (
        <span><strong>${membership.amount}</strong> Total</span>
      )
    }
  }

  membershipName = () => {
    const { membership } = this.props
    return membership.name.replace(/_/g, ' ')
  }

  render () {
    const { totalCost } = this.props
    return (
      <div className='block block-bordered block-link-hover3 text-center'>
        <div className='block-header'>
          <h3 className='block-title'>{this.membershipName()}</h3>
        </div>
        <div className='block-content block-content-full bg-primary'>
          <div className='h1 font-w700 text-white push-10'>${totalCost}</div>
          <div className='h5 font-w300 text-white'>per month</div>
        </div>
        <div className='block-content'>
          <table className='table table-borderless table-condensed'>
            <tbody>
              <tr>
                <td>
                  {this.usersString()}
                </td>
              </tr>
              <tr>
                <td><strong>Unlimited</strong> Leads</td>
              </tr>
              <tr>
                <td>
                  {this.locationString()}
                </td>
              </tr>
              <tr>
                <td><strong>Unlimited</strong> Chat & Email Support</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

PlanDetailsPanel.propTypes = {
  membership: PropTypes.object.isRequired,
  membershipPermissions: PropTypes.object.isRequired,
  totalCost: PropTypes.number.isRequired
}

export default PlanDetailsPanel
