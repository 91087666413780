import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SharedInboxView from '../components/SharedInboxView'

import * as actions from '../actions'
import * as selectors from '../selectors'
import locationSelect from '../../LocationSelect'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  return {
    location: locationSelect.selectors.getSelectedLocation(state),
    notifications: selectors.getNotifications(state),
    selectedUserIds: selectors.getSelectedUserIds(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch, props) {
  return bindActionCreators({
    getSharedInboxNotifications: actions.getSharedInboxNotifications,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedInboxView)
