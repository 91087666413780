import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

import userData from 'modules/Stores/UserData';
import billing from 'modules/Stores/Billing';
import modal from 'modules/Stores/Modal';

export const createLocationForCompany = (formData, paymentData) => {
  return (dispatch) => {
    const body = {
      location: seralizeLocationFromFormData(formData, paymentData),
    };

    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(resetForm());
      dispatch(userData.actions.addLocation(payload.location));
      notify.success('Location Created');
      const smsAccount = payload.meta.sms_account;
      dispatch(billing.actions.addSmsAccount(smsAccount));
      if (payload.meta.newPaymentMethod) {
        const newPaymentMethod = payload.meta.new_payment_method;
        dispatch(billing.actions.addPaymentMethod(newPaymentMethod));
      }
    }

    return request('locations', POST, body, onSuccess, onFailure);
  };
};

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function updatePaymentData(paymentData) {
  return {
    type: actionTypes.UPDATE_FORM_PAYMENT_DATA,
    paymentData,
  };
}

export function resetForm() {
  return {
    type: actionTypes.RESET_FORM,
  };
}

function seralizeLocationFromFormData(formData, paymentData) {
  const {
    name,
    addressLineOne,
    addressLineTwo,
    city,
    timezone,
    state,
    countryId,
    zipPostcode,
    phoneNumber,
    paychoicePaymentMethodId,
  } = formData.fields;

  let payload = {};

  payload = newPayloadForFormValue(payload, name, 'name');
  payload = newPayloadForFormValue(payload, addressLineOne, 'address_line_one');
  payload = newPayloadForFormValue(payload, addressLineTwo, 'address_line_two');
  payload = newPayloadForFormValue(payload, phoneNumber, 'phone_number');
  payload = newPayloadForFormValue(payload, city, 'city');
  payload = newPayloadForFormValue(payload, timezone, 'timezone');
  payload = newPayloadForFormValue(payload, state, 'state');
  payload = newPayloadForFormValue(payload, countryId, 'country_id');
  payload = newPayloadForFormValue(payload, zipPostcode, 'zip_postcode');
  payload = newPayloadForFormValue(payload, paychoicePaymentMethodId, 'paychoice_payment_method_id');
  if (paymentData.fields.number.value) {
    payload['paychoice_payment_methods_attributes'] = seralizePaymentMethodData(paymentData);
  }

  return payload;
}

function seralizePaymentMethodData(paymentData) {
  const { number, name, expiry, cvc } = paymentData.fields;

  let payload = {};

  payload = newPayloadForFormValue(payload, number, 'number');
  payload = newPayloadForFormValue(payload, name, 'name');
  payload = newPayloadForFormValue(payload, expiry, 'expire');
  payload = newPayloadForFormValue(payload, cvc, 'cvc');

  return payload;
}
