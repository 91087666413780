import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as callHelpers from "../../helpers";
import { isToday, colorForDate, isThisYear } from "modules/Helpers/DateHelpers";
import moment from "moment";
import "moment-timezone";

export default class CallItem extends Component {
  onSelect = (event) => {
    event.preventDefault();
    const { call, callClicked } = this.props;
    callClicked(call);
  };

  onDelete = () => {
    const { call, callDeleted } = this.props;
    callDeleted(call);
  };

  render() {
    const { call, timezone, loading } = this.props;
    const creatingUserName = call.creating_user
      ? call.creating_user.display_name
      : "Deleted User";
    const completingUserName = call.completing_user
      ? call.completing_user.display_name
      : "Deleted User";

    return (
      <BorderedContainer
        className={`block block-link-hover3 ${loading && "block-opt-refresh"}`}
      >
        <div className="block-content block-content-full clearfix">
          <div className="row">
            <div className="col-xs-1">
              <label className="css-input css-checkbox css-checkbox-lg css-checkbox-primary">
                <input
                  type="checkbox"
                  checked={call.completed}
                  onChange={this.onSelect}
                />
                <span />{" "}
              </label>
            </div>
            <div className="col-xs-9">
              {call.outcome && (
                <h4>
                  {completingUserName} {callHelpers.titleForType(call.outcome)}
                </h4>
              )}
              {!call.outcome && <h4>Call scheduled by {creatingUserName}</h4>}
              {call.note && (
                <p className="text-muted">
                  <i className="si si-note" /> {call.note}
                </p>
              )}
            </div>
            <div className="col-xs-2">
              <i
                className="fa fa-remove pull-right fa-2x text-muted selectable"
                onClick={this.onDelete}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-11 col-xs-offset-1">
              <CallItemTime call={call} timezone={timezone} />
            </div>
          </div>
        </div>
      </BorderedContainer>
    );
  }
}

CallItem.propTypes = {
  call: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  callClicked: PropTypes.func.isRequired,
  callDeleted: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const BorderedContainer = styled.a`
  border-color: #eaeaea;
  border-width: 0.3px;
  border-style: solid;
`;

function getDateTimeFormat(momentDate) {
  if (isToday(momentDate)) return "h:mm a";
  return isThisYear(momentDate)
    ? "dddd MMMM Do, h:mm a"
    : "dddd MMMM Do YYYY, h:mm a";
}

const CallItemTime = ({ call, timezone }) => {
  const dueMoment = moment(call.due_date).tz(timezone);
  const dueDateString = dueMoment.format(getDateTimeFormat(dueMoment));
  const timeString = isToday(dueMoment) ? "today by" : "by";

  const completedMoment = moment(call.completed_on).tz(timezone);
  const completedMomentRelative = completedMoment.fromNow();
  const completedString = completedMoment.format(
    getDateTimeFormat(completedMoment)
  );

  let completedAtOn;
  if (call.completed_on) completedAtOn = isToday(completedMoment) ? "at" : "on";
  const timeColorClass = colorForDate(dueMoment, timezone) || "text-muted";

  return (
    <div>
      {call.completed && call.completed_on && (
        <div>
          <span className="text-muted">
            Called {completedMomentRelative} {completedAtOn} {completedString}{" "}
          </span>
        </div>
      )}
      {!call.completed && call.due_date && (
        <div>
          <span className={timeColorClass}>
            Call {timeString} {dueDateString}
          </span>
        </div>
      )}
    </div>
  );
};

CallItemTime.propTypes = {
  call: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
};
