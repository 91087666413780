import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components';
import StepNumberView from 'modules/components/StepNumberView/StepNumberView'
import { toolTipSelector } from "../tooltips";

export class ContactsView extends Component {

  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, calculatingOption } = this.props;
    fetchWithQueryString(queryString, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.queryString !== this.props.queryString) ||
    (nextProps.calculatingOption !== this.props.calculatingOption)) {
      const { fetchWithQueryString, queryString, calculatingOption } = nextProps;
      fetchWithQueryString(queryString, calculatingOption);
    }
  }

  render () {
    const { data, loading, calculatingOption } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    const tooltips = toolTipSelector(calculatingOption)
    return (
      <Container className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Contact calls</h3>
        </div>
        <BlockContent className='block-content'>
          <div className='row'>
            <BorderedColumn className='col-sm-2'>
              <StepNumberView title='Calls scheduled'
                number={data.scheduled_contact_calls.current}
                percentage={data.scheduled_contact_calls.change}
                tooltipId={'scheduledCallsDescription'}
                tooltipText={tooltips.scheduledCallsDescription} />
            </BorderedColumn>

            <Column className='col-sm-2'>
              <StepNumberView title='Call attempts'
                number={data.contact_attempts.current}
                percentage={data.contact_attempts.change}
                tooltipId={'callAttemptsDescription'}
                tooltipText={tooltips.callAttemptsDescription} />
            </Column>
            <Column className='col-sm-2'>
              <StepNumberView title='Successful calls'
                number={data.successful_contact_calls.current}
                percentage={data.successful_contact_calls.change}
                tooltipId={'successCallsDescription'}
                tooltipText={tooltips.successCallsDescription} />
            </Column>
            <BorderedColumn className='col-sm-2'>
              <StepNumberView title='Success ratio'
                number={data.success_ratio.current * 100}
                suffix='%'
                percentage={data.successful_contact_calls.change}
                tooltipId={'contactSuccessRatio'}
                tooltipText={tooltips.contactSuccessRatio}  />
            </BorderedColumn>

            <Column className='col-sm-2'>
              <StepNumberView title='Successful call to appointment'
                number={data.contact_to_appointment_count.current}
                percentage={data.contact_to_appointment_count.change}
                tooltipId={'toAppointmentDescription'}
                tooltipText={tooltips.toAppointmentDescription} />
            </Column>
            <Column className='col-sm-2'>
              <StepNumberView title='Call to appointment ratio'
                number={data.contact_to_appointment_ratio.current * 100}
                suffix={'%'}
                percentage={data.contact_to_appointment_ratio.change}
                tooltipId={'toAppointmentRatioDescription'}
                tooltipText={tooltips.toAppointmentRatioDescription} />
            </Column>
          </div>
        </BlockContent>
      </Container>
    )
  }
}

ContactsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string
}

export default ContactsView

const Container = styled.div``;
const Column = styled.div``;
const BorderedColumn = styled.div`
  border-right: 1px solid #e9e9e9;
`;

const BlockContent = styled.div`
  padding-bottom: 40px;
`;