/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

export class EmailDomainRow extends Component {
  rowSelected = () => {
    const { domain, onSelect } = this.props;
    onSelect(domain);
  };

  domainType = () => {
    const { domain } = this.props;
    return (
      domain.domain_type.charAt(0).toUpperCase() + domain.domain_type.slice(1)
    );
  };

  render() {
    const { domain, selected } = this.props;
    const style = selected ? "selected" : "selectable";
    const domainStyle = selected ? "text-white" : "text-muted";
    return (
      <a onClick={this.rowSelected} className={"list-group-item " + style}>
        <span>{this.domainType()}:</span>{" "}
        <span className={domainStyle}>{domain.name}</span>
      </a>
    );
  }
}

EmailDomainRow.propTypes = {
  domain: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default EmailDomainRow;
