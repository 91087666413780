import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SELECT_HEARD_ABOUT_METHODS_MODAL } from "../constants";
import HeardAboutMethodsSection from "../components/HeardAboutMethodsSection";
import modal from "modules/Stores/Modal";

function mapStateToProps(state, props) {
  const heardAboutMethodIdsName = "heard_about_method_ids";
  const heardAboutMethodIdsData =
    props.filterData && props.filterData[heardAboutMethodIdsName]
      ? props.filterData[heardAboutMethodIdsName]
      : { fields: {} };
  const heardAboutMethodsCount = Object.keys(heardAboutMethodIdsData.fields)
    .length;
  const clubsFiltersClass =
    heardAboutMethodsCount > 0 ? "btn btn-info" : "btn btn-default";
  const clubTitle =
    heardAboutMethodsCount > 0
      ? `${heardAboutMethodsCount} selected`
      : "Everywhere";

  return {
    SELECT_HEARD_ABOUT_METHODS_MODAL: SELECT_HEARD_ABOUT_METHODS_MODAL,
    heardAboutMethodIdsName: heardAboutMethodIdsName,
    clubTitle: clubTitle,
    clubsFiltersClass: clubsFiltersClass,
    heardAboutMethodIdsData: heardAboutMethodIdsData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeardAboutMethodsSection);
