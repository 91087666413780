import { NAME } from "./constants";
import { createSelector } from 'reselect'

export const getLoading = state => state.timeTakenReport[NAME].loading;
export const getErrors = state => state.timeTakenReport[NAME].errors;
export const getData = state => state.timeTakenReport[NAME].data;

export function isEmpty () {
  return createSelector(
    [ getData ],
    (data) => {
      const keys = Object.keys(data)
      const mappedValues = keys.map(key => data[key].value)
      return mappedValues.filter(value => value > 0).length === 0
    }
  )
}