import { getSafeUTCDateAsStringFromDate } from 'modules/Helpers/DateHelpers'

const isEmpty = value => value === undefined || value === null || value === ''

export function isEmail (value) {
  if (!isEmpty(value) && !/.*@.*/i.test(value)) {
    return false
  }
  return true
}

export function isURL (value) {
  var pattern = new RegExp('^(https?:\\/\\/)?' +
 '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
 '((\\d{1,3}\\.){3}\\d{1,3}))' +
 '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
 '(\\?[;&a-z\\d%_.~+=-]*)?' +
 '(\\#[-a-z\\d_]*)?$', 'i')
  return pattern.test(value)
}

export function isPresent (value) {
  if (isEmpty(value)) {
    return false
  }
  return true
}

export function isInteger (value) {
  if (!Number.isInteger(Number(value))) {
    return false
  }
  return true
}

export function isMinLength (min) {
  return value => {
    if ((isEmpty(value)) || (!isEmpty(value) && value.length < min)) {
      return false
    }
    return true
  }
}

export function isMaxLength (max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return false
    }
    return true
  }
}

export function oneOf (enumeration) {
  return value => {
    if (!~enumeration.indexOf(value)) {
      return false
    }
    return true
  }
}

export function isValidDate (value) {
  if (getSafeUTCDateAsStringFromDate(value) === null) {
    return false
  }
  return true
}

export function hasWhiteSpace (value) {
  const hasWhiteSpace = value.indexOf(' ') >= 0
  return !hasWhiteSpace
}

export function containsString (string) {
  return value => {
    if (!isEmpty(value) && value.indexOf(string) !== -1) {
      return true
    }
    return false
  }
}
