import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export function add(lead) {
  return {
    type: actionTypes.QUEUED_INQUIRIES_ADD_LEAD,
    lead,
  };
}

export function remove(lead) {
  return {
    type: actionTypes.REMOVE_LEAD,
    lead,
  };
}

export function selectLead(lead) {
  return {
    type: actionTypes.SELECT_LEAD,
    lead,
  };
}

// FETCH QUEUED LEADS
export const fetchLeadsWithPageNumber = (location, pageNumber) => {
  return (dispatch) => {
    dispatch(fetchingLeads());

    function onFailure(errors) {
      dispatch(fetchingLeadsFailed(errors));
    }

    function onSuccess(payload) {
      if (payload.leads) {
        dispatch(fetchingLeadsSucceeded(payload.leads));
        dispatch(loadPageDetails(payload.meta));
      }
    }
    const url =
      "leads_to_assign/" + location.id + "?page[number]=" + pageNumber;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function fetchingLeads() {
  return {
    type: actionTypes.QUEUED_INQUIRIES_FETCHING_LEADS,
  };
}

function fetchingLeadsSucceeded(leads) {
  return {
    type: actionTypes.QUEUED_INQUIRIES_FETCHING_LEADS_SUCCEEDED,
    leads,
  };
}

export function fetchingLeadsFailed(errors) {
  return {
    type: actionTypes.QUEUED_INQUIRIES_FETCHING_LEADS_FAILED,
    errors,
  };
}

export function setLocationId(locationId) {
  return {
    type: actionTypes.SET_LOCATION_ID,
    locationId,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
