import PropTypes from "prop-types";
import React, { Component } from "react";

import CreateAutoAssignRuleContainer from "../containers/CreateAutoAssignRuleContainer";
import CurrentAutoAssignRuleContainer from "../containers/CurrentAutoAssignRuleContainer";

export class AutoAssignRuleView extends Component {
  UNSAFE_componentWillMount() {
    const { getAutoAssignRuleForLocation, location } = this.props;
    getAutoAssignRuleForLocation(location);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.id !== this.props.location.id) {
      nextProps.getAutoAssignRuleForLocation(nextProps.location);
    }
  }

  render() {
    const { location, autoAssignRule } = this.props;
    return (
      <div>
        <div>
          {!autoAssignRule && (
            <CreateAutoAssignRuleContainer location={location} />
          )}
          {autoAssignRule && (
            <CurrentAutoAssignRuleContainer
              autoAssignRule={autoAssignRule}
              location={location}
            />
          )}
        </div>
      </div>
    );
  }
}

AutoAssignRuleView.propTypes = {
  location: PropTypes.object.isRequired,
  getAutoAssignRuleForLocation: PropTypes.func.isRequired,
  autoAssignRule: PropTypes.object,
};

export default AutoAssignRuleView;
