import * as actionTypes from "./actionTypes";
import { filterDataFromQueryBody } from 'modules/components/FilterFormSections/helpers'

const AUTOMATIC_REPORT_TABLE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_AUTOMATIC_REPORTS]: (state, action) => {
    const formattedReports = automaticReportsFormatForReducer(action.automaticReports)
    return { ...state, automaticReports: formattedReports };
  },
  [actionTypes.DELETE_AUTOMATIC_REPORT]: (state, action) => {
    const reportToRemoveIndex = state.automaticReports.findIndex(report => report.id === action.automaticReportId)
    const deletedNewAutomaticReports = [
      ...state.automaticReports.slice(0, reportToRemoveIndex),
      ...state.automaticReports.slice(reportToRemoveIndex + 1)
    ]
    return { ...state, automaticReports: deletedNewAutomaticReports };
  },
  [actionTypes.UPDATE_AUTOMATIC_REPORT]: (state, action) => {
    const rawUpdatedAutomaticReport = action.automaticReport
    const updatedAutomaticReport = automaticReportFormatForReducer(rawUpdatedAutomaticReport)

    const indexToUpdate = state.automaticReports.findIndex(report => report.id === rawUpdatedAutomaticReport.id)
    
    const updatedNewAutomaticReports = [
      ...state.automaticReports.slice(0, indexToUpdate),
      updatedAutomaticReport,
      ...state.automaticReports.slice(indexToUpdate + 1)
    ]

    return { ...state, automaticReports: updatedNewAutomaticReports };
  },
  [actionTypes.UPDATE_AUTOMATIC_REPORT_SCHEDULE]: (state, action) => {
    const rawReportSchedule = action.automaticReportSchedule
    const indexToUpdate = state.automaticReports.findIndex(report => report.automaticReportSchedule.id === rawReportSchedule.id)
    const reportToUpdate = state.automaticReports[indexToUpdate]
    const updatedReport = {...reportToUpdate, automaticReportSchedule: {
      id: rawReportSchedule.id,
      daysToSend: rawReportSchedule.days_to_send.split(','),
      sendTime: rawReportSchedule.send_time,
    }}
    
    const scheduleNewAutomaticReports = [
      ...state.automaticReports.slice(0, indexToUpdate),
      updatedReport,
      ...state.automaticReports.slice(indexToUpdate + 1)
    ]
    return { ...state, automaticReports: scheduleNewAutomaticReports };
  },
  [actionTypes.SELECT_AUTOMATIC_REPORT]: (state, action) => {
    return { ...state, selectedAutomaticReportId: action.automaticReport.id };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState;
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  loading: false,
  errors: null,
  automaticReports: [],
  selectedAutomaticReportId: null,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
};

export default function reducer(state = initialState, action) {
  const handler = AUTOMATIC_REPORT_TABLE_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

function automaticReportsFormatForReducer(rawAutomaticReports) {
  return rawAutomaticReports.map(automaticReport => automaticReportFormatForReducer(automaticReport))
}

function automaticReportFormatForReducer(automaticReport) {
  return {
    id: automaticReport.id,
    name: automaticReport.name,
    type: automaticReport.report_type,
    compareOption: automaticReport.comparing,
    calculatingOption: automaticReport.calculating,
    userIdsToEmail: automaticReport.user_ids_to_email,
    automaticReportSchedule: {
      id: automaticReport.automatic_report_schedule.id,
      daysToSend: automaticReport.automatic_report_schedule.days_to_send.split(','),
      sendTime: automaticReport.automatic_report_schedule.send_time,
    },
    data: filterDataFromQueryBody(automaticReport.filter)
  }
}