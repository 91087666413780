import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import session from "modules/Stores/Session";
import personalization from "../modules/personalization";

function mapStateToProps(state) {
  return {
    user: session.selectors.getUser(state),
    loading: personalization.selectors.getLoading(state),
    data: personalization.selectors.getData(state),
    errors: personalization.selectors.getErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prefillFormForUser: personalization.actions.prefillFormForUser,
      updateUser: personalization.actions.updateUser,
      onInput: personalization.actions.onInput,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(personalization.components.PersonalizationForm);
