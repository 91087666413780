import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as listingActions from "../../../../listing/actions";

import modal from "modules/Stores/Modal";
import FormView from "../components/EnterDetails/FormView";

import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    tabIndex: selectors.getTabIndex(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateIntegration: listingActions.updateIntegration,
      prefillFormForIntegration: actions.prefillFormForIntegration
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormView);
