import PropTypes from "prop-types";
import React, { Component } from "react";

import TrialRow from "./TrialRow";
import { ContentBlockView, EmptyView, InfoView } from "modules/components";
import NewTrialModalContainer from "../containers/NewTrialModalContainer";
import TrialDetailContainer from "../containers/TrialDetailContainer";

import { NEW_TRIAL_MODAL } from "../constants";

export class TrialsView extends Component {
  UNSAFE_componentWillMount() {
    const { trials, getTrials } = this.props;
    if (trials && trials.length > 0) {
      return;
    }
    getTrials();
  }

  trialSelected = (emailTrial) => {
    this.props.prefillDataForTrial(emailTrial);
  };

  showCreateModal = () => {
    const { showModal } = this.props;
    showModal(NEW_TRIAL_MODAL);
  };

  render() {
    const { trials, loading, selectedTrialId, selectedTrial } = this.props;
    const trialRows = trials.map((trial, index) => {
      const selected = trial.id === selectedTrialId;
      return (
        <TrialRow
          trial={trial}
          key={index}
          selected={selected}
          onSelect={this.trialSelected}
        />
      );
    });

    const addButton = (
      <button
        type="button"
        className="pull-right btn btn-xs btn-success"
        onClick={this.showCreateModal}
      >
        <i className="fa fa-plus" /> Create New Trial
      </button>
    );

    return (
      <div>
        <InfoView colorThemeClass={"info"} title={"Trials"}>
          <p>
            Use trials to keep track of when leads start, and will end, a trial.
            You can then filter by people in a trial and when their trial ends.
            In marketing, you can setup automations for when leads are added to
            a trial, or a certain amount of days before they end.{" "}
            <strong>
              <a
                href="http://support.gymleads.net/how-it-works/trials"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check out the Knowledge Base
              </a>
            </strong>{" "}
            for more details.
          </p>
        </InfoView>
        <ContentBlockView
          title={"Trials"}
          loading={loading}
          blockOptions={addButton}
          bordered
        >
          <div className="list-group selectable push">
            {trialRows}
            {trials.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="fa fa-clock-o"
                title="No Trials"
                buttonText="Create first trial"
                buttonSelected={this.showCreateModal}
                subtitle="Add a new trial to get started."
              />
            )}
          </div>
        </ContentBlockView>
        {selectedTrialId && selectedTrial && <TrialDetailContainer />}
        <NewTrialModalContainer />
      </div>
    );
  }
}

TrialsView.propTypes = {
  prefillDataForTrial: PropTypes.func.isRequired,
  trials: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getTrials: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setSelectedTrialId: PropTypes.func.isRequired,
  selectedTrial: PropTypes.object,
  selectedTrialId: PropTypes.number,
};

export default TrialsView;
