import * as actionTypes from './actionTypes'

const USER_DATA_ACTION_HANDLERS = {
  [actionTypes.ADD_USER_ID]: (state, action) => {
    const newSelectedIds = [
      ...state.selectedUserIds,
      action.userId
    ]
    return { ...state, selectedUserIds: newSelectedIds }
  },
  [actionTypes.REMOVE_USER_ID]: (state, action) => {
    const index = indexForId(state.selectedUserIds, action.userId)
    const newSelectedIds = [
      ...state.selectedUserIds.slice(0, index),
      ...state.selectedUserIds.slice(index + 1)
    ]
    return { ...state, selectedUserIds: newSelectedIds }
  },
  [actionTypes.SET_USERS]: (state, action) => {
    return { ...state, users: action.users }
  },
  [actionTypes.RESET_FORM]: (state, action) => {
    return initialState
  },
  [actionTypes.SET_USER_ROLE_ID]: (state, action) => {
    return { ...state, selectedUserRoleId: action.userRoleId }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      total: action.pageDetails.total_count
    }
  }
}

function indexForId (ids, userId) {
  return ids.findIndex(id => id === userId)
}

const initialState = {
  selectedUserIds: [],
  selectedUserRoleId: null,
  users: [],
  pageCount: 1,
  currentPage: 1,
  total: 0
}

export default function reducer (state = initialState, action) {
  const handler = USER_DATA_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
