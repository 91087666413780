import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CreateLeadView from '../components/CreateLeadView';
import modal from 'modules/Stores/Modal';
import session from 'modules/Stores/Session';
import leadFilters from 'containers/ManageLeads/modules/filters';
import FilterFormSections from 'modules/components/FilterFormSections';

import * as selectors from '../selectors';
import * as actions from '../actions';

function mapStateToProps(state) {
  var queryBody = null;
  if (state.manageLeads) {
    const queryBodySelector = FilterFormSections.selectors.queryBodyFactory(leadFilters.selectors.getData);
    queryBody = queryBodySelector(state);
  }
  return {
    user: session.selectors.getUser(state),
    data: selectors.getData(state),
    selectedUser: selectors.getSelectedUser(state),
    errors: modal.selectors.getModalErrors(state),
    existingLead: selectors.getExistingLead(state),
    selectedLocation: selectors.getSelectedLocation(state),
    selectedLeadSource: selectors.getSelectedLeadSource(state),
    selectedHeardAboutMethod: selectors.getSelectedHeardAboutMethod(state),
    selectedLeadGoal: selectors.getSelectedLeadGoal(state),
    queryBody: queryBody,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      resetForm: actions.reset,
      create: actions.create,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLeadView);
