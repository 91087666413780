import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './styles.css'

import { hourOptions, minuteOptions, periodOptions } from './optionGenerators'

export class TimeSelectView extends Component {

  state = {
    hour: (this.props.time) ? this.props.time.hour : '1',
    adjustedHour: (this.props.time) ? this.props.time.adjustedHour : '13',
    minute: (this.props.time) ? this.props.time.minute : '00',
    period: (this.props.time) ? this.props.time.period : 'pm',
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.time !== nextProps.time) {
      const newTime = nextProps.time
      this.setState({
        hour: newTime ? newTime.hour : '1',
        adjustedHour: newTime ? newTime.adjustedHour : '13',
        minute: newTime ? newTime.minute : '00',
        period: newTime ? newTime.period : 'pm',
      })
    }
  }

  handleHourChange = (event) => {
    let textValue = event.target.value
    let name = event.target.id
    let period = this.state.period
  
    let hour = parseInt(textValue, 10)
    let adjustedHour

    if (period === 'pm' && hour !== 12) {
      adjustedHour = (hour + 12).toString()
    } else if (period === 'pm' && hour === 12) {
      adjustedHour = 12
    } else if (period === 'am' && hour === 12) {
      adjustedHour = 0
    } else if (period === 'am' && hour !== 12) {
      adjustedHour = hour
    }

    const newState = { ...this.state, [name]: textValue, adjustedHour: adjustedHour }

    this.setState(newState)
    this.props.onTimeChange(newState)
  }

  handleMinuteChange = (event) => {
    const textValue = event.target.value
    const name = event.target.id

    const newState = { ...this.state, [name]: textValue }

    this.setState(newState)
    this.props.onTimeChange(newState)
  }

  handlePeriodChange = (event) => {
    let textValue = event.target.value
    let name = event.target.id

    let hour = parseInt(this.state.hour, 10)
    let adjustedHour

    if (textValue === 'pm' && hour !== 12) {
      adjustedHour = (parseInt(hour, 10) + 12).toString()
    } else if (textValue === 'pm' && hour === 12) {
      adjustedHour = 12
    } else if (textValue === 'am' && hour === 12) {
      adjustedHour = 0
    } else if (textValue === 'am' && hour !== 12) {
      adjustedHour = hour
    }

    const newState = { ...this.state, [name]: textValue, adjustedHour: adjustedHour }

    this.setState(newState)
    this.props.onTimeChange(newState)
  }

  render () {
    const { hour, minute, period, } = this.state

    const hourSelections = hourOptions()
    const minuteSelections = minuteOptions()
    const periodSelections = periodOptions()
    return (
      <div className={'form-group'}>
        <select
          className='time-select'
          style={{ marginRight: '10px' }}
          value={hour}
          id={'hour'}
          name={'hour'}
          onChange={this.handleHourChange}>
          {hourSelections}
        </select>
        :
        <select
          className='time-select'
          style={{ marginRight: '10px', marginLeft: '10px' }}
          value={minute}
          id={'minute'}
          name={'minute'}
          onChange={this.handleMinuteChange}>
          {minuteSelections}
        </select>
        :
        <select
          className='time-select'
          style={{ marginLeft: '10px' }}
          value={period}
          id={'period'}
          name={'period'}
          onChange={this.handlePeriodChange}>
          {periodSelections}
        </select>
      </div>
    )
  }
}

TimeSelectView.propTypes = {
  time: PropTypes.object,
  onTimeChange: PropTypes.func.isRequired
}

export default TimeSelectView
