import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SmsAccountTableView from './TableView/SmsAccountTableView'

import { ContentBlockView, EmptyView, TextSearchView, PagesView } from 'modules/components'

export class SelectSmsView extends Component {

  UNSAFE_componentWillMount () {
    const { smsAccounts } = this.props
    if (smsAccounts.length > 0) { return }
    this.getSmsAccountsWithPageNumber(1)
  }

  getSmsAccountsWithPageNumber = (pageNumber) => {
    const { getSmsAccounts } = this.props
    getSmsAccounts(pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getSmsAccountsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getSmsAccounts } = this.props
    getSmsAccounts(1, searchTerm)
  }

  render () {
    const { smsAccounts, onSmsAccountSelect,
      selectedSmsAccountId, pageDetails, loading } = this.props
    const showPageSelect = (pageDetails.pageCount > 1)

    return (
      <ContentBlockView
        title='Select an account'
        loading={loading}
        bordered>
        <div className='row' style={{ marginTop: '-20px' }}>
          <TextSearchView
            sectionName={'smsAccountSearch'}
            onUpdate={this.searchUpdated} />
        </div>
        <div>
          {smsAccounts.length > 0 &&
            <SmsAccountTableView
              smsAccounts={smsAccounts}
              onSmsAccountSelect={onSmsAccountSelect}
              selectedSmsAccountId={selectedSmsAccountId} />
          }
          {smsAccounts.length === 0 &&
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-magnifier'
              title='No results'
              subtitle='Check the search field' />
          }
          {showPageSelect &&
            <div className='text-center push'>
              <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
            </div>
          }
        </div>
      </ContentBlockView>
    )
  }
}

SelectSmsView.propTypes = {
  getSmsAccounts: PropTypes.func.isRequired,
  smsAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSmsAccountSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  selectedSmsAccountId: PropTypes.number
}

export default SelectSmsView
