import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { FormFields } from 'modules/components'
const { SelectField } = FormFields

export class MapLawfulProcessingBasesView extends Component {

  addMap = (fieldName, value) => {
    const map = { old: fieldName, new: value }
    this.props.addLawfulProcessingBasesMap(map)
  }

  updateMap = (fieldName, value) => {
    const map = { old: fieldName, new: value }
    this.props.updateLawfulProcessingBasesMap(map)
  }

  render () {
    const { lawfulProcessingBases, lawfulProcessingBasesToMap, lawfulProcessingBasesMaps } = this.props

    const rows = lawfulProcessingBasesToMap.map((lawfulProcessingBasis) => {
      const selected = lawfulProcessingBasesMaps.findIndex(map => map.old === lawfulProcessingBasis) > -1
      const tickElement = (selected)
      ? <i className='fa fa-3x fa-check-circle text-success' style={{ marginTop: '10px', marginLeft: '13px' }} />
      : ''
      const onChange = (selected) ? this.updateMap : this.addMap
      return (
        <tr key={lawfulProcessingBasis}>
          <td>{lawfulProcessingBasis}</td>
          <td>
            <SelectField
              name={lawfulProcessingBasis}
              possibleOptions={lawfulProcessingBases}
              displayFieldName={'title'}
              placeHolderText='Select a lawful processing basis'
              formFieldStyle=''
              styles={{ marginBottom: '0px' }}
              onChange={onChange} />
          </td>
          <td>{tickElement}</td>
        </tr>
      )
    })

    return (
      <div style={{ marginTop: '5px' }}>
        <h5 style={{ marginBottom: '5px' }}>Lawful Processing Bases</h5>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>CSV Value</th>
              <th style={{ width: '40%' }}>Import Value</th>
              <th style={{ width: '10%' }} />
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

MapLawfulProcessingBasesView.propTypes = {
  lawfulProcessingBases: PropTypes.arrayOf(PropTypes.object).isRequired,
  lawfulProcessingBasesToMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  lawfulProcessingBasesMaps: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLawfulProcessingBasesMap: PropTypes.func.isRequired,
  updateLawfulProcessingBasesMap: PropTypes.func.isRequired
}

export default MapLawfulProcessingBasesView
