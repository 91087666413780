import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'

const ACTION_AUTOMATION_RULE_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM_DATA]: (state, action) => {
    return initialStateForActionAutomationRule(action.actionAutomationRule)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

const payloadKeys = {
  createCallForWebLeads: 'create_call_for_web_leads',
  moveLeadOnCallSuccess: 'move_lead_on_call_success',
  moveLeadOnAppointmentSuccess: 'move_lead_on_appointment_success',
  closeLeadOnClosedAppointment: 'close_lead_on_closed_appointment'
}

function initialStateForActionAutomationRule (actionAutomationRule) {
  const fields = generateFieldsForKeys(actionAutomationRule, fieldNames, payloadKeys)
  return {
    loading: false,
    errors: null,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function reducer (state = initialStateForActionAutomationRule(null), action) {
  const handler = ACTION_AUTOMATION_RULE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
