import * as actionTypes from './actionTypes'

import { safePropertyForObject, safeRawPropertyForObject } from 'utils/formHelpers'

const SALES_TARGETS_ACTION_HANDLERS = {
  [actionTypes.PREFIL_TARGET_METRICS]: (state, action) => {
    return initialStateForSalesTargets(action.salesTargets)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    const newData = action.data
    return { ...state, data: newData }
  },
  [actionTypes.UPDATING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

function initialStateForSalesTargets (salesTargets) {
  return {
    loading: false,
    errors: null,
    data: {
      isValid: true,
      fields: {
        timeType: {
          value: safePropertyForObject(salesTargets, 'time_type'),
          isValid: true,
          possibleOptions: possibleTimeOptions()
        },
        workingDaysOverride: {
          value: safePropertyForObject(salesTargets, 'working_days_override_value'),
          isValid: true
        },
        closeRate: {
          value: safePropertyForObject(salesTargets, 'close_rate'),
          isValid: true
        },
        initialCallTime: {
          value: safeRawPropertyForObject(salesTargets, 'initial_call_time', (value) => value / 60),
          isValid: true
        },
        newLeadsCount: {
          value: safePropertyForObject(salesTargets, 'new_leads_count'),
          isValid: true
        },
        initialCallCount: {
          value: safePropertyForObject(salesTargets, 'initial_call_count'),
          isValid: true
        },
        appointmentTime: {
          value: safeRawPropertyForObject(salesTargets, 'appointment_time', (value) => value / 60 / 60 / 24),
          isValid: true
        },
        appointmentCount: {
          value: safePropertyForObject(salesTargets, 'appointment_count'),
          isValid: true
        },
        presentationCount: {
          value: safePropertyForObject(salesTargets, 'presentation_count'),
          isValid: true
        },
        followUpCallTime: {
          value: safePropertyForObject(salesTargets, 'follow_up_call_time'),
          isValid: true
        },
        followUpCallCount: {
          value: safePropertyForObject(salesTargets, 'follow_up_call_count'),
          isValid: true
        },
        totalSales: {
          value: safePropertyForObject(salesTargets, 'total_sales'),
          isValid: true
        },
        referralCount: {
          value: safePropertyForObject(salesTargets, 'referral_count'),
          isValid: true
        }
      }
    }
  }
}

export default function reducer (state = initialStateForSalesTargets(null), action) {
  const handler = SALES_TARGETS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

function possibleTimeOptions () {
  return [
    { id:'weekly', name:'Weekly' },
    { id:'monthly', name:'Monthly' }
  ]
}
