import { connect } from 'react-redux';
import { SsoView } from '../components/SsoView';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import * as actions from '../actions';
import session from 'modules/Stores/Session';

function mapStateToProps(state) {
  return {
    token: queryParams.token,
    user: session.selectors.getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadSsoSession: actions.loadSsoSession,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SsoView));

const queryParams = window.location.search
  .substr(1)
  .split('&')
  .reduce(function (q, query) {
    const chunks = query.split('=');
    const key = chunks[0];
    const value = chunks[1];
    // eslint-disable-next-line
    return (q[key] = value), q;
  }, {});
