import * as actionTypes from './actionTypes'

const LEAD_EMAILS_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.FETCH_SUCCESS]: (state, action) => {
    return { ...state, loading: false, emails: action.emails }
  },
  [actionTypes.FETCH_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.SET_SHOW_HISTORY_EMAIL_ID]: (state, action) => {
    return { ...state, showHistoryEmailId: action.showHistoryEmailId }
  },
  [actionTypes.ADD]: (state, action) => {
    const emails = [
      action.email,
      ...state.emails]
    return { ...state, emails: emails }
  },
  [actionTypes.EMAILS_PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  loading: false,
  emails: [],
  showHistoryEmailId: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = LEAD_EMAILS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
