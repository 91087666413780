import * as actionTypes from './actionTypes'

const CONTACT_CALLS_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.SET_PAGE_DETAILS]: (state, action) => {
    const newMeta = action.pageDetails
    return { ...state, loading: false, pageDetails: {
      currentPage: newMeta.current_page,
      pageCount: newMeta.total_pages,
      total: newMeta.total_count
    }}
  },
  [actionTypes.FETCHING_SUCCESS]: (state, action) => {
    return { ...state, loading: false, appointments: action.appointments }
  },
  [actionTypes.FETCHING_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATING]: (state, action) => {
    const index = indexForAppointment(state.appointments, action.appointmentId)
    if (index < 0) { return state }
    const updatedAppointment = { ...state.appointments[index], loading: true }
    const updatedAppointments = [
      ...state.appointments.slice(0, index),
      updatedAppointment,
      ...state.appointments.slice(index + 1)
    ]
    return { ...state, appointments: updatedAppointments }
  },
  [actionTypes.ADD]: (state, action) => {
    const updatedAppointments = [
      action.appointment,
      ...state.appointments
    ]
    return { ...state, appointments: updatedAppointments }
  },
  [actionTypes.UPDATING_SUCCESS]: (state, action) => {
    const updatedAppointment = action.appointment
    const index = indexForAppointment(state.appointments, updatedAppointment.id)
    if (index < 0) { return state }
    const updatedAppointments = [
      ...state.appointments.slice(0, index),
      updatedAppointment,
      ...state.appointments.slice(index + 1)
    ]
    return { ...state, loading: false, appointments: updatedAppointments }
  },
  [actionTypes.SET_APPOINTMENT_SYNCING]: (state, action) => {
    const index = indexForAppointment(state.appointments, action.appointmentId)
    if (index < 0) { return state }
    const updatedAppointment = { ...state.appointments[index], syncing: action.syncing }
    const updatedAppointments = [
      ...state.appointments.slice(0, index),
      updatedAppointment,
      ...state.appointments.slice(index + 1)
    ]
    return { ...state, appointments: updatedAppointments }
  },
  [actionTypes.UPDATING_FAILURE]: (state, action) => {
    const index = indexForAppointment(state.appointments, action.appointmentId)
    const updatedAppointment = { ...state.appointments[index], loading: false }
    const updatedAppointments = [
      ...state.appointments.slice(0, index),
      updatedAppointment,
      ...state.appointments.slice(index + 1)
    ]
    return { ...state, loading: false, appointments: updatedAppointments }
  },
  [actionTypes.DELETE]: (state, action) => {
    const index = indexForAppointment(state.appointments, action.appointmentId)
    if (index < 0) { return state }
    const updatedAppointments = [
      ...state.appointments.slice(0, index),
      ...state.appointments.slice(index + 1)
    ]
    return { ...state, loading: false, appointments: updatedAppointments }
  }
}

const initialState = {
  loading: false,
  appointments: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

function indexForAppointment (appointments, appointmentId) {
  return appointments.findIndex(_appointment => _appointment.id === appointmentId)
}

export default function reducer (state = initialState, action) {
  const handler = CONTACT_CALLS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
