export function humanReadableStatus (status) {
  switch (status) {
    case 'not_sent':
      return 'Never sent'
    case 'pending':
    case 'gymleads_pending':
    case 'processed':
      return 'Pending'
    case 'delivered':
      return 'Delivered'
    case 'sent':
      return 'Sent'
    case 'opened':
      return 'Opened'
    case 'bounced':
    case 'dropped':
    case 'soft_bounced':
    case 'hard_bounced':
      return 'Bounced'
    case 'link_clicked':
      return 'Link clicked'
    case 'opt_out':
      return 'Opted out'
    default:
      return 'Unkown'
  }
}

export function humanReadableNoSendReason (noSendReason) {
  switch (noSendReason) {
    case 'missing_phone_number':
      return 'Lead is missing their mobile number'
    case 'sms_bounced':
      return 'Mobile number has bounced'
    case 'opted_out':
      return 'Lead has opted out of marketing text messages'
    case 'not_opted_in':
      return 'Lead has not opted into text messages'
    case 'transactional_outside_implied_consent':
      return 'Outside of the implied consent since the last communication (General Data Protection Regulation)'
    case 'transactional_incorrect_status':
      return 'Cannot send transactional text messages to lost or inactive leads'
    case 'insufficient_balance':
      return 'Insufficient balance at the time of sending message'
    default:
      return 'Unknown'
  }
}
