import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ConfirmEmailView } from '../components/ConfirmEmailView'

import { confirmEmail } from '../actions'

import * as selectors from '../selectors'

const mapStateToProps = state => ({
  token: selectors.getToken(state),
  loading: selectors.getLoading(state),
  errors: selectors.getErrors(state),
  user: selectors.getUser(state)
})

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    confirmEmail
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailView)
