import React, { Component } from 'react';
import styled from 'styled-components';

import * as integrationHelpers from 'modules/Helpers/IntegrationHelpers';
import CalendlySetupContainer from '../containers/CalendlySetupContainer';
import FooterButtonsContainer from '../containers/FooterButtonsContainer';
import CreateSubscriptionContainer from '../containers/CreateSubscriptionContainer';
import HeaderView from './HeaderView';

import Modal from 'react-bootstrap-modal';

export class CalendlyConfigurationModalView extends Component {
  state = {
    showCreateSubscription: false,
  };

  hideModal = () => {
    const { hideModal, resetSubscriptionModal } = this.props;
    hideModal();
    resetSubscriptionModal();
  };

  toggleShowSubscription = () => {
    this.setState({
      showCreateSubscription: !this.state.showCreateSubscription,
    });
  };

  render() {
    const { currentModal, modalName, name, selectedLocation, selectedIntegration, selectedSubscription, loading } = this.props;
    const { showCreateSubscription } = this.state;
    const logo = integrationHelpers.logoForOption(name);
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    return (
      <Modal show={currentModal === modalName} onHide={this.hideModal}>
        <div className={blockStyle}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <DissmissButton data-dismiss="modal" type="button" onClick={this.hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </DissmissButton>
              </li>
            </ul>
            <Headline className="block-title">Let's setup {integrationHelpers.titleForOption(name)}</Headline>
          </div>
          <ContentContainer className="block-content">
            <HeaderView
              toggleShowSubscription={this.toggleShowSubscription}
              selectedSubscription={selectedSubscription}
              selectedIntegration={selectedIntegration}
            />
            {!selectedSubscription && showCreateSubscription && (
              <CreateSubscriptionContainer
                toggleShowSubscription={this.toggleShowSubscription}
                selectedIntegration={selectedIntegration}
                selectedLocation={selectedLocation}
              />
            )}
            {selectedIntegration && <CalendlySetupContainer {...this.props} />}
          </ContentContainer>
          {logo && (
            <LogoContainer className="block-content">
              <LogoImage src={logo} />
            </LogoContainer>
          )}
        </div>
        <Modal.Footer>
          <FooterButtonsContainer selectedIntegration={selectedIntegration} selectedLocation={selectedLocation} />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CalendlyConfigurationModalView;

const DissmissButton = styled.button``;
const LogoContainer = styled.div``;
const ContentContainer = styled.div``;

const LogoImage = styled.img`
  width: 150px;
`;
const Headline = styled.h3`
  color: white;
`;
