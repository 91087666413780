import * as actionTypes from "./actionTypes";

const REGIONS_ACTION_HANDLERS = {
  [actionTypes.SELECT_REGION_ID]: (state, action) => {
    return { ...state, selectedRegionId: action.regionId };
  }
};

function initialStateForUser(user) {
  return {
    selectedRegionId: null
  };
}

export default function reducer(state = initialStateForUser(null), action) {
  const handler = REGIONS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
