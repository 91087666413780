import { isPresent, isEmail } from 'utils/validation'

export const ArticlesFormValidator = {
  email: [{
    rule: isPresent,
    errorMessage: 'What is your email?'
  }, {
    rule: isEmail,
    errorMessage: 'What is your email?'
  }],
  password: [{
    rule: isPresent,
    errorMessage: 'Looks like the password is missing'
  }],
  totp: [{
    rule: isPresent,
    errorMessage: 'Looks like the totp is missing'
  }],
  userId: [{
    rule: isPresent,
    errorMessage: 'Looks like the user id is missing'
  }]
}
