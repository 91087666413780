import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.dashboard[NAME].errors
export const getLoading = state => state.dashboard[NAME].loading
export const getTasks = state => state.dashboard[NAME].tasks
export const getSelectedTaskId = state => state.dashboard[NAME].selectedTaskId
export const getPageCount = state => state.dashboard[NAME].pageCount
export const getCurrentPage = state => state.dashboard[NAME].currentPage
export const getTotalCount = state => state.dashboard[NAME].totalCount

export const selectedTask = createSelector(
  [ getTasks, getSelectedTaskId ],
  (tasks, taskId) => {
    const index = tasks.findIndex(task => parseInt((task.id), 10) === parseInt((taskId), 10))
    return tasks[index]
  }
)
