import { request, PUT, POST, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

import { formDataFromData } from 'utils/formHelpers';
import { fieldNames } from './constants';
import lawfulProcessingBasisSelect from '../lawfulProcessingBasisSelect';
import lawfulProcessingBases from 'modules/Stores/LawfulProcessingBases';

export const saveLawfulProcessingBasis = (lawfulProcessingBasisId, formData) => {
  return (dispatch) => {
    const body = {
      lawful_processing_basis: formDataFromData(formData, fieldNames, false),
    };

    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      notify.success('Lawful Processing Basis Saved');
      const newBasis = payload.lawful_processing_basis;
      dispatch(lawfulProcessingBases.actions.saveLawfulProcessingBasis(newBasis));
      dispatch(lawfulProcessingBasisSelect.actions.selectLawfulProcessingBasisId(newBasis.id));
    }

    const url = 'lawful_processing_bases';

    if (lawfulProcessingBasisId) {
      return request(url + '/' + lawfulProcessingBasisId, PUT, body, onSuccess, onFailure);
    } else {
      return request(url, POST, body, onSuccess, onFailure);
    }
  };
};

export const deleteLawfulProcessingBasis = (lawfulProcessingBasisId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      notify.success('Lawful Processing Basis Deleted');
      dispatch(lawfulProcessingBasisSelect.actions.selectLawfulProcessingBasisId(null));
      dispatch(lawfulProcessingBases.actions.deleteLawfulProcessingBasisWithId(lawfulProcessingBasisId));
    }

    return request('lawful_processing_bases/' + lawfulProcessingBasisId, DELETE, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function changeShowing(showing) {
  return {
    type: actionTypes.SET_SHOWING,
    showing,
  };
}

export function prefilFormData(lawfulProcessingBasis) {
  return {
    type: actionTypes.PREFILL_FORM_DATA,
    lawfulProcessingBasis,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
