import { combineReducers } from 'redux';
import countsLoader from './modules/countsLoader';
import filters from './modules/filters';
import sourceCount from './modules/sourceCount';
import leadsTable from './modules/leadsTable';
import header from './modules/header';

export default combineReducers({
  [filters.constants.NAME]: filters.reducer,
  [countsLoader.constants.NAME]: countsLoader.reducer,
  [sourceCount.constants.NAME]: sourceCount.reducer,
  [leadsTable.constants.NAME]: leadsTable.reducer,
  [header.constants.NAME]: header.reducer,
});
