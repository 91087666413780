import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TagsView from '../components/TagsView'

import tags from 'modules/components/Tags'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    tags: tags.selectors.getTags(state),
    loading: tags.selectors.getLoading(state),
    pageDetails: tags.selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getTags: tags.actions.getTags,
    showModal: modal.actions.showModal
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TagsView)
