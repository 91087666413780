import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

export const getLeadsWithQueryAndPageNumber = (queryString, pageNumber) => {
  return (dispatch) => {
    dispatch(startFetchingLeads());
    function onFailure(errors) {
      dispatch(fetchLeadsFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchLeadsSuccess(payload.leads));
      dispatch(loadPageDetails(payload.meta));
    }

    const encodedQueryString = encodeURI(queryString);

    return request('leads?page[number]=' + pageNumber + '&' + encodedQueryString, GET, null, onSuccess, onFailure);
  };
};

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function startFetchingLeads() {
  return {
    type: actionTypes.FETCHING_LEADS,
  };
}

function fetchLeadsSuccess(leads) {
  return {
    type: actionTypes.FETCH_LEADS_SUCCESS,
    leads,
  };
}

function fetchLeadsFailed(errors) {
  return {
    type: actionTypes.FETCH_LEADS_FAILED,
    errors,
  };
}

// Actions
export function select(leadId) {
  return {
    type: actionTypes.SELECT_LEAD,
    leadId,
  };
}

export function deselect() {
  return {
    type: actionTypes.DESELECT_LEAD,
  };
}

export function add(lead) {
  return {
    type: actionTypes.ADD_LEAD,
    lead,
  };
}

export function remove(lead) {
  return {
    type: actionTypes.REMOVE_LEAD,
    lead,
  };
}

export function updateLeadDetails(lead) {
  return {
    type: actionTypes.UPDATE_LEAD,
    lead,
  };
}

export function updateLeadAssignentForLead(lead, salesperson) {
  return {
    type: actionTypes.UPDATE_SALESPERSON_FOR_LEAD,
    lead,
    salesperson,
  };
}

export function createTagForLead(lead, tag) {
  return {
    type: actionTypes.CREATE_TAG,
    lead,
    tag,
  };
}

export function deleteTagForLead(lead, tag) {
  return {
    type: actionTypes.DELETE_TAG,
    lead,
    tag,
  };
}

export function deleteLead(lead) {
  return {
    type: actionTypes.DELETE,
    lead,
  };
}

export function updateSalesFunnelStep(lead, salesFunnelStep) {
  return {
    type: actionTypes.UPDATE_LEAD_SALES_FUNNEL_STEP,
    lead,
    salesFunnelStep,
  };
}

export function showingQuickLeadView(showingQuickLeadView) {
  return {
    type: actionTypes.SET_SHOWING_QUICK_LEAD_VIEW,
    showingQuickLeadView,
  };
}
