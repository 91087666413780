import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditCallScheduleForm from "../components/EditCallScheduleForm";
import modal from "modules/Stores/Modal";
import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    data: selectors.getData(state),
    locationIdsData: selectors.locationIdsData(state),
    leadSourceIdsData: selectors.leadSourceIdsData(state),
    locationIds: selectors.getSelectedLocationIds(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onInput: actions.onInput,
      setLeadSourceIds: actions.setLeadSourceIds,
      setLocationIds: actions.setLocationIds,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCallScheduleForm);
