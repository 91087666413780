import PropTypes from 'prop-types'
import React, { Component } from 'react'

import EditActionAutomationRuleContainer from '../containers/EditActionAutomationRuleContainer'

export class ActionAutomationRulesView extends Component {

  render () {
    const { location } = this.props
    return (
      <EditActionAutomationRuleContainer location={location} />
    )
  }
}

ActionAutomationRulesView.propTypes = {
  location: PropTypes.object.isRequired
}

export default ActionAutomationRulesView
