import { combineReducers } from 'redux';
import salesTeam from './modules/SalesTeam';
import locationSelect from './modules/LocationSelect';
import QueuedLeads from './modules/QueuedLeads';
import WeeklyLeadSummary from './modules/WeeklyLeadSummary';
import SalesTargets from './modules/SalesTargets';
import Updates from './modules/Updates';
import callList from './modules/CallList';
import appointmentList from './modules/AppointmentList';
import taskList from './modules/TaskList';
import sharedInbox from './modules/SharedInbox';
import structure from './modules/Structure';
import LeadDetailsModal from './modules/LeadDetailsModal';

import dateRangeSelect from './modules/DateRangeSelect';

export default combineReducers({
  [dateRangeSelect.constants.NAME]: dateRangeSelect.reducer,
  [salesTeam.constants.NAME]: salesTeam.reducer,
  [locationSelect.constants.NAME]: locationSelect.reducer,
  [QueuedLeads.constants.NAME]: QueuedLeads.reducer,
  [WeeklyLeadSummary.constants.NAME]: WeeklyLeadSummary.reducer,
  [SalesTargets.constants.INDIVIDUAL_TYPE]: SalesTargets.reducers.inidivudalSalesTargetReducer,
  [SalesTargets.constants.LOCATION_TYPE]: SalesTargets.reducers.locationSalesTargetReducer,
  [callList.constants.NAME]: callList.reducer,
  [appointmentList.constants.NAME]: appointmentList.reducer,
  [taskList.constants.NAME]: taskList.reducer,
  [Updates.constants.NAME]: Updates.reducer,
  [sharedInbox.constants.NAME]: sharedInbox.reducer,
  [structure.constants.NAME]: structure.reducer,
  [LeadDetailsModal.constants.NAME]: LeadDetailsModal.reducer,
});
