import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Modal from "react-bootstrap-modal";
import DaySelect from "./DaySelect";
import TimeSelectContainer from "../containers/TimeSelectContainer";
import { TextField } from "modules/components/FormFields";

export default class SetupScheduleModal extends Component {
  state = {
    selectedDays: [],
    sendTime: "2019-05-29T04:00:35Z",
    reportName: ""
  };

  handleDayChange = event => {
    const { selectedDays } = this.state;
    const value = event.target.checked;
    const dayName = event.target.id;
    let newSelectedDays = [...selectedDays];

    if (value === true) {
      if (selectedDays.indexOf(dayName) >= 0) {
        return;
      }
      newSelectedDays = [...newSelectedDays, dayName];
    } else if (value === false) {
      if (selectedDays.indexOf(dayName) === -1) {
        return;
      }
      newSelectedDays = newSelectedDays.filter(day => day !== dayName);
    }
    this.setState({ ...this.state, selectedDays: newSelectedDays });
  };

  handleTimeChange = newTimeMoment => {
    this.setState({ ...this.state, sendTime: newTimeMoment.format() });
  };

  handleInput = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  handleCreate = () => {
    const { selectedDays, sendTime, reportName } = this.state;
    const {
      queryString,
      compareOption,
      calculatingOption,
      createAutomaticSchedule,
      reportType
    } = this.props;
    createAutomaticSchedule(
      queryString,
      compareOption,
      calculatingOption,
      sendTime,
      selectedDays,
      reportName,
      reportType
    );
    this.closeModal()
  };

  disableCreateButton = () => {
    const { selectedDays, sendTime, reportName } = this.state;
    if (!selectedDays || selectedDays.length === 0) {
      return true;
    } else if (!sendTime) {
      return true;
    } else if (!reportName || reportName.length === 0) {
      return true;
    }
  };

  closeModal = () => {
    const { hideModal } = this.props;
    this.setState({
      selectedDays: [],
      sendTime: "2019-05-29T04:00:35Z",
      reportName: ""
    });
    hideModal();
  };

  render() {
    const { modalName, currentModal } = this.props;
    const { selectedDays, sendTime, reportName } = this.state;
    const disableButton = this.disableCreateButton();

    return (
      <Modal show={currentModal === modalName} onHide={this.closeModal}>
        <Container className="block">
          <Header className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button
                  data-dismiss="modal"
                  type="button"
                  onClick={this.closeModal}
                >
                  <CloseIcon className="si si-close" />
                </button>
              </li>
            </ul>
            <BlockTitle className="block-title">
              Setup Automatic Send Schedule
            </BlockTitle>
          </Header>
          <Content className="block-content">
            <div className="col-md-12">
              <Description>
                This will send a report using the currently selected filters
                (Description to be added)
              </Description>
              <hr />
              <NameContainer className="row">
                <TextField
                  name="reportName"
                  label="Name"
                  placeholder="New Report"
                  formFieldStyle=""
                  onChange={this.handleInput}
                  value={reportName}
                />
              </NameContainer>
              <DateContainer>
                <Title>Time to send report</Title>
                <TimeSelectContainer
                  sendTime={sendTime}
                  handleTimeChange={this.handleTimeChange}
                />
              </DateContainer>
              <DateContainer>
                <Title>Days to send report</Title>
                <DaySelect
                  selectedDays={selectedDays}
                  handleDayChange={this.handleDayChange}
                />
              </DateContainer>
            </div>
          </Content>
        </Container>
        <Modal.Footer>
          <CloseButton
            className="btn btn-danger"
            type="button"
            onClick={this.closeModal}
          >
            Close
          </CloseButton>
          <CreateButton
            className="btn btn-primary"
            onClick={this.handleCreate}
            disabled={disableButton}
          >
            Create
          </CreateButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

SetupScheduleModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  title: PropTypes.string,
  queryString: PropTypes.string,
  compareOption: PropTypes.string,
  calculatingOption: PropTypes.string
};

const BlockTitle = styled.div`
  color: white;
`;

const CloseIcon = styled.i`
  color: white;
`;

const Container = styled.div`
  margin: 0px 0px 15px 0px;
`;

const CloseButton = styled.button`
  margin: 0px 10px 0px 0px;
`;

const Content = styled.div`
  display: flex;
`;

const Description = styled.div`
  margin: 0px 0px 20px 0px;
`;

const DateContainer = styled.div`
  padding: 15px 0px 0px 0px;
`;

const NameContainer = styled.div`
  margin: -20px -15px 5px -15px;
`;

const Title = styled.div`
  margin: 0px 0px 5px 0px;
  font-size: 13px;
  font-weight: 700;
`;

const Header = styled.div``;
const CreateButton = styled.button``;
