/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import * as callHelpers from 'modules/components/LeadDetail/modules/calls/helpers';
import { ContactCallOutcome } from 'types';

interface ICallOutcomeRowProps {
  type: ContactCallOutcome;
  onClick: (contactCallOutcome: ContactCallOutcome) => void;
  isSelected: boolean;
  disabled: boolean;
  trialName?: string;
}

const CallOutcomeRow: FC<ICallOutcomeRowProps> = (props) => {
  const { type, onClick, isSelected, disabled, trialName } = props;
  function getBgStyle() {
    if (disabled) {
      return 'bg-disabled';
    } else if (isSelected) {
      return 'bg-success-light';
    } else {
      return 'bg-success';
    }
  }

  function tileClicked() {
    if (disabled === false) {
      onClick(type);
    }
  }

  const icon = callHelpers.optionIcon(type);
  const title = callHelpers.optionTitle(type);
  const bgStyle = getBgStyle();
  const disabledCursor = disabled ? ' inactive' : '';

  return (
    <div className={'col-xs-12'} style={{ marginBottom: '7px' }}>
      <div
        style={{
          border: '1px solid #e9e9e9',
          boxShadow: 'none',
          WebkitBoxShadow: 'none',
          overflow: 'hidden',
          marginBottom: '1px',
        }}
        className={'block  block-link-hover1 text-center selectable' + disabledCursor}
        onClick={tileClicked}
      >
        <div
          className={' block-content-full col-xs-3 ' + bgStyle}
          style={{
            padding: '6px',
            width: '42px',
            height: '42px',
          }}
        >
          <i className={icon + ' fa-2x text-white'} />
        </div>
        <div className="block-content block-content-full block-content-mini block-text-row col-xs-9" style={{ textAlign: 'left' }}>
          {disabled && trialName ? 'Already in ' + trialName : title}
        </div>
      </div>
    </div>
  );
};

export default CallOutcomeRow;
