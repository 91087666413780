import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
const { SelectField } = FormFields;

export class MigrateTagModal extends Component {
  state = {
    toId: null,
  };

  handleSelect = (name, value) => {
    this.setState({ ...this.state, [name]: value });
  };

  deleteClicked = () => {
    const { tag, deleteTag, migrateAndDeleteTag } = this.props;
    const { toId } = this.state;
    if (toId) {
      migrateAndDeleteTag(tag, toId, this.cleanUpAndHide);
    } else {
      deleteTag(tag, this.cleanUpAndHide);
    }
  };

  cleanUpAndHide = () => {
    this.setState({ toId: null });
    this.props.hideModal();
  };

  render() {
    const { loading, currentModal, modalName, tag, tags, errors } = this.props;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const buttonTitle = this.state.toId ? 'Migrate & Delete Tag' : 'Delete Tag';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanUpAndHide}>
          <div className={blockStyle}>
            <div className="block-header bg-danger">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanUpAndHide}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Delete Tag {tag.name}
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <div className="row">
                  <div className="col-xs-12">
                    {errorPanelForErrors(errors)}
                    <p>
                      This will delete the tag {tag.name}.<br />
                      You can optionally select a tag to migrate leads to.
                    </p>
                  </div>
                  <div className="col-xs-12" style={{ marginLeft: '-20px', marginRight: '20px' }}>
                    <SelectField
                      name="toId"
                      label="Which tag should this migrate to?"
                      possibleOptions={tags}
                      displayFieldName={'name'}
                      placeHolderText="Delete without migrating"
                      formFieldStyle="form-material form-material-danger"
                      onChange={this.handleSelect}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndHide}>
              Cancel
            </button>
            <button className="btn btn-danger push-5-r push-10" type="button" onClick={this.deleteClicked}>
              <i className="fa fa-cross" /> {buttonTitle}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

MigrateTagModal.propTypes = {
  tag: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  deleteTag: PropTypes.func.isRequired,
  migrateAndDeleteTag: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default MigrateTagModal;
