import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'

import { OwnerElements } from './OwnerElements'
import { SalesmanagerElements } from './SalesmanagerElements'
import { SalespersonElements } from './SalespersonElements'

export class CommunicationPreferencesForm extends Component {

  saveSelected = () => {
    const { updateCommunicationPreferences, data } = this.props
    updateCommunicationPreferences(data)
  }

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true)
    this.props.onInput(newData)
  }

  elementsForCurrentUser = () => {
    const { data, userRole } = this.props
    switch (userRole.name) {
      case 'Owner':
      case 'Admin':
      case 'Franchise Owner':
        return <OwnerElements handleToggle={this.handleToggle} data={data} />
      case 'Salesmanager':
      case 'Reception':
        return <SalesmanagerElements handleToggle={this.handleToggle} data={data} />
      case 'Salesperson':
        return <SalespersonElements handleToggle={this.handleToggle} data={data} />
      default:
        return <div />
    }
  }

  render () {
    return (
      <div>
        {this.elementsForCurrentUser()}
        <div className='row'>
          <div className='col-md-8'>
            <button className='btn btn-primary push-5-r push-10'
              type='button'
              onClick={this.saveSelected}>
              <i className='si si-envelope-letter' /> Update Communication Preferences
            </button>
          </div>
        </div>
      </div>
    )
  }
}

CommunicationPreferencesForm.propTypes = {
  updateCommunicationPreferences: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  userRole: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}
