import { NAME } from './constants'

export const SET_ERRORS = NAME + '/SET_ERRORS'

export const ADD_TAG = NAME + '/ADD_TAG'
export const REMOVE_TAG = NAME + '/REMOVE_TAG'
export const EDIT_TAG = NAME + '/EDIT_TAG'

export const SET_TAGS = NAME + '/SET_TAGS'
export const SET_LOADING = NAME + '/SET_LOADING'

export const FETCHING_POPULAR_TAGS_SUCCESS = NAME + '/FETCHING_POPULAR_TAGS_SUCCESS'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
