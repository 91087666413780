import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import * as callActions from 'modules/components/LeadDetail/modules/calls/actions'
import calls from '../modules/calls'

function mapStateToProps (state, ownProps) {
  const { pathToLeadDetail } = ownProps
  return {
    loading: calls.selectors.getLoading(state, pathToLeadDetail),
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleCompleteForCall: calls.actions.toggleComplete,
    deleteCallForLead: calls.actions.deleteCall,
    showModal: modal.actions.showModal,
    setCallSchedule: callActions.setCallSchedule
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(calls.components.ContactCallsView)
