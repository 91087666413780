import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import * as reportActions from '../reportsTable/actions';
import notify from 'modules/Notifications';

export const updateAutomaticReportFilters = (queryString, automaticReportId, calculatingOption, compareByOption) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      notify.error('Error updating filter');
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(reportActions.updateAutomaticReport(payload.automatic_report));
      notify.success('Successfully updated report filters');
      dispatch(setLoading(false));
    }

    let url = `automatic_reports/${automaticReportId}`;
    if (queryString) {
      url = `${url}?${queryString}`;
    }
    if (calculatingOption) {
      url = `${url}&calculating=${calculatingOption}`;
    }
    if (compareByOption) {
      url = `${url}&comparing=${compareByOption}`;
    }
    return request(url, PUT, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function updateFilters(filterData) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    filterData,
  };
}

export function setFilters(automaticReport) {
  return {
    type: actionTypes.SET_FILTER_DATA,
    automaticReport,
  };
}

export function setCompareOption(compareOption) {
  return {
    type: actionTypes.SET_COMPARE_VALUE,
    compareOption,
  };
}

export function changeCompareOption(compareOption) {
  return (dispatch) => {
    dispatch(setCompareOption(compareOption));
  };
}

export function setCalculatingOption(calculatingOption) {
  return {
    type: actionTypes.SET_CALCULATING_VALUE,
    calculatingOption,
  };
}
