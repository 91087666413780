import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class InlineTextField extends Component {

  state = {
    errorMessage: null,
    'style': '',
    'valid': (this.props.validator !== null)
  }

  handleChange = (event) => {
    const textValue = event.target.value
    let style = ''
    const errorMessage = this.errorMessageForValue(textValue)
    const newIsValidValue = (errorMessage === null)

    if (!newIsValidValue) {
      style = 'has-error'
    }

    this.setState({ ...this.state, errorMessage: errorMessage, 'style': style, 'valid': newIsValidValue })
    this.props.onChange(this.props.name, textValue, newIsValidValue)
  }

  errorMessageForValue = (value) => {
    const { name, validator } = this.props
    if (!validator) return null

    let errorMessage = null
    const validations = validator[name]
    validations.forEach(function (validation) {
      const isValid = validation.rule(value)
      if (!isValid) {
        errorMessage = validation.errorMessage
      }
    })
    return errorMessage
  }

  render () {
    const { name, disabled, formFieldStyle, label, placeholder, value } = this.props
    const { style, errorMessage } = this.state
    const input = (value !== null) ? value : ''
    const isDisabled = disabled === true
    return (
      <div className={'form-group ' + style}>
        <div className={formFieldStyle}>
          <label className='col-sm-3 control-label' htmlFor={name}
            style={{ paddingTop: '8px' }}>
            {label}
          </label>
          <div className='col-sm-9'>
            <input type='text'
              className='form-control'
              id={name}
              placeholder={placeholder}
              onChange={this.handleChange}
              disabled={isDisabled}
              value={input} />
            <span id={name + '-error-message'} className='help-block'>{errorMessage}</span>
          </div>
        </div>
      </div>
    )
  }
}

InlineTextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  formFieldStyle: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validator: PropTypes.object,
  value: PropTypes.string,
  inline: PropTypes.bool
}

export default InlineTextField
