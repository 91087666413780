import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import configureImport from '../modules/configureImport';
import selectCSV from '../modules/selectCSV';
import { getTabIndex } from '../selectors';

function mapStateToProps(state) {
  const activeTab = getTabIndex(state);
  const selectedFields = configureImport.selectors.getSelectedFields(state);
  const numberOfRecords = configureImport.selectors.getNumberOfRecords(state);
  const enhancedPrivacy = selectCSV.selectors.getEnhancedPrivacy(state);
  return {
    updateLeadsValue: configureImport.selectors.getUpdateLeadsValue(state),
    enterAutomationFunnelValue: configureImport.selectors.getEnterAutomationFunnelValue(state),
    enterMatchingCallScheduleValue: configureImport.selectors.getEnterMatchingCallScheduleValue(state),
    isActive: activeTab === 1,
    csvFile: selectCSV.selectors.getCSV(state),
    selectedLocationId: selectCSV.selectors.getLocationId(state),
    selectedLocationName: selectCSV.selectors.getLocationName(state),
    leads: configureImport.selectors.getLeads(state),
    headers: configureImport.selectors.generateHeaderStates(state),
    loading: configureImport.selectors.getLoading(state),
    numberOfRecords: numberOfRecords,
    requiredFieldsMissing: configureImport.selectors.requiredFieldsMissing(selectedFields, numberOfRecords, enhancedPrivacy),
    recomendedFieldsMissing: configureImport.selectors.recomendedFieldsMissing(selectedFields),
    errors: configureImport.selectors.getErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      parseCSVFileIntoLeads: configureImport.actions.parseCSVFileIntoLeads,
      setImportOptionsValue: configureImport.actions.setImportOptionsValue,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(configureImport.components.ConfigureLeadsView);
