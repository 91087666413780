import * as actionTypes from './actionTypes'

const CURRENT_TARGET_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_SALES_TARGET]: (state, action) => {
    return { ...state, salesTarget: action.salesTarget }
  }
}

function initialStateForSalesTargets (salesTargets) {
  return {
    loading: false,
    salesTarget: null
  }
}

export default function reducer (state = initialStateForSalesTargets(null), action) {
  const handler = CURRENT_TARGET_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
