/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-bootstrap-modal";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView,
} from "modules/components";

export class SelectHeardAboutModal extends Component {
  UNSAFE_componentWillMount() {
    const { heardAboutMethods } = this.props;
    if (heardAboutMethods.length > 0) {
      return;
    }
    this.getHeardAboutMethodsWithPageNumber(1);
  }

  getHeardAboutMethodsWithPageNumber = (pageNumber) => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getHeardAboutMethodsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(1, searchTerm);
  };

  methodSelected = (selectedMethod) => {
    const { lead, updateLead, listUpdateProtocol } = this.props;
    const goal = { heard_about_method_id: selectedMethod.id };
    updateLead(lead, goal, listUpdateProtocol.updateLeadDetails);
  };

  render() {
    const {
      lead,
      loading,
      currentModal,
      hideModal,
      modalName,
      heardAboutMethods,
      pageDetails,
    } = this.props;

    const pageCount = pageDetails.pageCount;

    const methods = heardAboutMethods.map((method, index) => {
      const bgStyle =
        lead.heard_about_method &&
        method.heard_about_title === lead.heard_about_method.heard_about_title
          ? "bg-primary-light"
          : "bg-primary";
      return (
        <div key={index} className="col-lg-6">
          <a
            className="block block-link-hover2 selectable"
            onClick={() => this.methodSelected(method)}
          >
            <div
              className={"block-content block-content-full clearfix " + bgStyle}
            >
              <span className="h4 text-white-op">
                {method.heard_about_title}
              </span>
            </div>
          </a>
        </div>
      );
    });

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={"block"}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                Where did {lead.given_name} hear about us?
              </h3>
            </div>
            <ContentBlockView loading={loading} bordered>
              <div className="row" style={{ marginTop: "-20px" }}>
                <TextSearchView
                  sectionName={"heardAboutMethodSearch"}
                  onUpdate={this.searchUpdated}
                />
              </div>
              {heardAboutMethods.length > 0 && (
                <div>
                  <div className="row">{methods}</div>
                </div>
              )}
              {heardAboutMethods.length === 0 && (
                <EmptyView
                  colorThemeClass="primary"
                  iconClass="si si-magnifier"
                  title="No results"
                  subtitle="Check the search field"
                />
              )}
              {pageCount > 1 && (
                <div className="text-center push">
                  <PagesView
                    pageDetails={pageDetails}
                    pageClicked={this.pageClicked}
                  />
                </div>
              )}
            </ContentBlockView>
          </div>
        </Modal>
      </div>
    );
  }
}

SelectHeardAboutModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default SelectHeardAboutModal;
