import { request, PUT, DELETE, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import modal from 'modules/Stores/Modal';

import notify from 'modules/Notifications';

const { startLoading, stopLoading, hideModal } = modal.actions;

// Update Lead
export const fetch = (lead) => {
  return (dispatch) => {
    dispatch(fetching());

    function onFailure(errors) {
      dispatch(fetchFailure(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchSuccess());
      dispatch(setLead(payload.lead));
    }

    return request('leads/' + lead.id, GET, null, onSuccess, onFailure);
  };
};

export const updateLead = (lead, updatedParams, updateLeadDetails, leadSyncId, cleanUp) => {
  return (dispatch) => {
    let body = {
      lead: updatedParams,
    };
    if (leadSyncId) {
      body = {
        ...body,
        lead_sync_status: {
          service_id: leadSyncId,
        },
      };
    }
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(hideModal());
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      if (updateLeadDetails) {
        dispatch(updateLeadDetails(payload.lead));
      }
      if (cleanUp) {
        dispatch(cleanUp(payload.lead));
      }
      dispatch(setLead(payload.lead));
      dispatch(hideModal());
      notify.success('Lead updated');
    }

    return request('leads/' + lead.id, PUT, body, onSuccess, onFailure);
  };
};

export const deleteLead = (lead, remove) => {
  return (dispatch) => {
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(hideModal());
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      if (remove) {
        dispatch(remove(lead));
      }
      dispatch(hideModal());
      notify.success('Lead deleted');
    }

    return request('leads/' + lead.id, DELETE, null, onSuccess, onFailure);
  };
};

function fetching() {
  return {
    type: actionTypes.FETCHING,
  };
}

function fetchSuccess() {
  return {
    type: actionTypes.FETCH_SUCCESS,
  };
}

function fetchFailure(errors) {
  return {
    type: actionTypes.FETCH_FAILURE,
    errors,
  };
}

export function setLead(lead) {
  return {
    type: actionTypes.SET_LEAD,
    lead,
  };
}

export function setFacebookMessengerUser(facebookMessengerUser) {
  return {
    type: actionTypes.SET_FACEBOOK_MESSENGER_USER,
    facebookMessengerUser,
  };
}

export function updateLeadAssignent(salesperson) {
  return {
    type: actionTypes.UPDATE_SALESPERSON_FOR_LEAD,
    salesperson,
  };
}

export function addTag(tag) {
  return {
    type: actionTypes.CREATE_TAG,
    tag,
  };
}

export function removeTag(tag) {
  return {
    type: actionTypes.DELETE_TAG,
    tag,
  };
}

export function updateSalesFunnelStep(salesFunnelStep) {
  return {
    type: actionTypes.UPDATE_LEAD_SALES_FUNNEL_STEP,
    salesFunnelStep,
  };
}

export function setLocation(location) {
  return {
    type: actionTypes.SET_LOCATION,
    location,
  };
}

export function updateCallScheduleStep(callScheduleStep) {
  return {
    type: actionTypes.UPDATE_CALL_SCHEDULE_STEP,
    callScheduleStep,
  };
}

export function setLeadSyncStatus(leadSyncStatus) {
  return {
    type: actionTypes.SET_LEAD_SYNC_STATUS,
    leadSyncStatus,
  };
}

export function setSalesFunnelStep(salesFunnelStep) {
  return {
    type: actionTypes.UPDATE_SALES_FUNNEL_STEP,
    salesFunnelStep,
  };
}
