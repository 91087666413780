import React from "react";
import MultiHeardAboutMethodSelect from "modules/components/MultiHeardAboutMethodSelect";

const HeardAboutMethodsSection = ({
  SELECT_HEARD_ABOUT_METHODS_MODAL,
  clubsFiltersClass,
  heardAboutMethodIdsName,
  heardAboutMethodIdsData,
  showModal,
  clubTitle,
  onUpdate
}) => {
  function showSelectHeardAboutMethodsModal() {
    showModal(SELECT_HEARD_ABOUT_METHODS_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${clubsFiltersClass}`}
        type="button"
        onClick={showSelectHeardAboutMethodsModal}
      >
        {clubTitle}
      </div>
      <MultiHeardAboutMethodSelect.MultiHeardAboutMethodSelectModalContainer
        modalName={SELECT_HEARD_ABOUT_METHODS_MODAL}
        sectionName={heardAboutMethodIdsName}
        onUpdate={onUpdate}
        data={heardAboutMethodIdsData}
      />
    </div>
  );
};

export default HeardAboutMethodsSection;
