import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const SET_CALL_SCHEDULES = NAME + '/SET_CALL_SCHEDULES'
export const SET_SELECTED_CALL_SCHEDULE_ID = NAME + '/SET_SELECTED_CALL_SCHEDULE_ID'

export const ADD_CALL_SCHEDULE = NAME + '/ADD_CALL_SCHEDULE'
export const UPDATE_CALL_SCHEDULE = NAME + '/UPDATE_CALL_SCHEDULE'
export const DELETE_CALL_SCHEDULE = NAME + '/DELETE_CALL_SCHEDULE'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
