import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import Login from 'containers/Login';
import ResetPassword from 'containers/ResetPassword';
import Articles from 'containers/Articles';
import EditArticle from 'containers/EditArticle';
import ForgotPassword from 'containers/ForgotPassword';
import ConfirmEmail from 'containers/ConfirmEmail';
import Redeem from 'containers/Redeem';

import Dashboard from 'containers/Dashboard';
import Calendar from 'containers/Calendar';
import ManageLeads from 'containers/ManageLeads';

import * as settings from 'containers/Settings/routes';
import * as reports from 'containers/Reports/Reports';

import { componentStores } from 'modules/components';

import stores from 'modules/Stores';

const reducers = {
  [Login.constants.NAME]: Login.reducer,
  [Articles.constants.NAME]: Articles.reducer,
  [EditArticle.constants.NAME]: EditArticle.reducer,
  [ResetPassword.constants.NAME]: ResetPassword.reducer,
  [ForgotPassword.constants.NAME]: ForgotPassword.reducer,
  [ConfirmEmail.constants.NAME]: ConfirmEmail.reducer,
  [Redeem.constants.NAME]: Redeem.reducer,
  [Dashboard.constants.NAME]: Dashboard.reducer,
  [Calendar.constants.NAME]: Calendar.reducer,
  [ManageLeads.constants.NAME]: ManageLeads.reducer,
};
Object.keys(stores).forEach((key) => {
  const store = stores[key];
  reducers[store.constants.NAME] = store.reducer;
});

Object.keys(componentStores).forEach((key) => {
  const store = componentStores[key];
  reducers[store.constants.NAME] = store.reducer;
});

Object.keys(settings).forEach((key) => {
  const store = settings[key];
  if (!store.reducer) return;
  reducers[store.constants.NAME] = store.reducer;
});

Object.keys(reports).forEach((key) => {
  const store = reports[key];
  reducers[store.constants.NAME] = store.reducer;
});

export default function createReducer(asyncReducers) {
  return combineReducers({
    routing: routerReducer,
    ...reducers,
    ...asyncReducers,
  });
}
