import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HtmlEditor from './HtmlEditor';
import { errorPanelForErrors } from 'utils/formHelpers';

export class EditHtmlView extends Component {
  state = {
    subject: this.props.template.subject || '',
  };

  sendEmail = () => {
    const { lead, sendHtmlEmailToLead } = this.props;
    const { subject } = this.state;
    window.unlayer.exportHtml((data) => {
      const { html } = data;
      sendHtmlEmailToLead(lead, subject, html);
    });
  };

  handleSubjectChange = (event) => {
    const textValue = event.target.value;
    this.setState({ ...this.state, subject: textValue });
  };

  isFormInvalid = () => {
    const { subject } = this.state;

    if (!subject || subject.trim().length === 0) {
      return true;
    }
    return false;
  };

  render() {
    const { mergeTags, template, backSelected, cancelSelected, readOnly, errors } = this.props;
    const { subject } = this.state;

    return (
      <div>
        <div className="block-content">
          {readOnly && (
            <p className="lead">You don't have permission to edit templates. Preview the email and click send when you're ready.</p>
          )}
          {errorPanelForErrors(errors)}
          <div className="html-email-editor-subject">
            <span className="pull-left" style={{ paddingTop: '5px', marginRight: '5px' }}>
              Subject:
            </span>
            <input
              type="text"
              style={{ width: '80%' }}
              id="emailSubject"
              placeholder="Enter your email subject"
              disabled={readOnly}
              onChange={this.handleSubjectChange}
              autoComplete="off"
              value={subject}
            />
          </div>
          <HtmlEditor mergeTags={mergeTags} template={template} readOnly={readOnly} />
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary push-10 pull-left" onClick={backSelected}>
            <i className="fa fa-angle-left" /> Change Template
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={cancelSelected}>
            Cancel
          </button>
          <button className="btn btn-success push-5-r push-10" type="button" disabled={this.isFormInvalid()} onClick={this.sendEmail}>
            <i className="si si-envelope" /> Send Email
          </button>
        </div>
      </div>
    );
  }
}

EditHtmlView.propTypes = {
  template: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  backSelected: PropTypes.func.isRequired,
  cancelSelected: PropTypes.func.isRequired,
  sendHtmlEmailToLead: PropTypes.func.isRequired,
  cleanUpParent: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EditHtmlView;
