import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import { FormFields } from "modules/components";

import { UpdatePasswordFormValidator } from "../validators";
const { PasswordField } = FormFields;

export class UpdatePasswordForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onUpdate(newData);
  };

  updatePasswordClicked = () => {
    const { sendData, data } = this.props;
    sendData(data);
  };

  render() {
    const {
      currentPassword,
      newPassword,
      newPasswordConfirmation,
    } = this.props.data.fields;
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <PasswordField
              name="currentPassword"
              label="Current Password"
              placeholder="Can we check your current password?"
              formFieldStyle=""
              onChange={this.handleInput}
              value={currentPassword.value}
              validator={UpdatePasswordFormValidator}
              disabled={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <PasswordField
              name="newPassword"
              label="New Password"
              placeholder="What is your new password?"
              formFieldStyle=""
              onChange={this.handleInput}
              value={newPassword.value}
              validator={UpdatePasswordFormValidator}
              disabled={false}
            />
          </div>
          <div className="col-sm-6">
            <PasswordField
              name="newPasswordConfirmation"
              label="Confirm Password"
              placeholder="Can you confirm it?"
              onChange={this.handleInput}
              formFieldStyle=""
              value={newPasswordConfirmation.value}
              validator={UpdatePasswordFormValidator}
              disabled={false}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-9">
            <button
              className="btn btn-primary push-5-r push-10"
              type="button"
              disabled={this.props.data.isValid !== true}
              onClick={this.updatePasswordClicked}
            >
              <i className="fa fa-key" /> Update Password
            </button>
          </div>
        </div>
      </div>
    );
  }
}

UpdatePasswordForm.propTypes = {
  sendData: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default UpdatePasswordForm;
