import moment from 'moment';
import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getLoading = (state) => state[NAME].loading;
export const getEvents = (state) => state[NAME].events;
const getSelectedLeadId = (state) => state[NAME].selectedEventId;
export const getDate = (state) => state[NAME].date;
export const getView = (state) => state[NAME].view;

export const selectedEvent = createSelector([getEvents, getSelectedLeadId], (events, selectedEventId) => {
  if (selectedEventId !== null) {
    const index = events.map((event) => event.id).indexOf(selectedEventId);
    return events[index];
  }
  return null;
});

export const calculatedDateRange = createSelector([getDate, getView], (date, view) => {
  if (view === 'day') {
    return {
      startDate: moment(date).startOf('day').subtract(1, 'days').utc().format(),
      endDate: moment(date).endOf('day').add(1, 'days').utc().format(),
    };
  }
  if (view === 'week') {
    return {
      startDate: moment(date).startOf('week').subtract(1, 'day').format(),
      endDate: moment(date).endOf('week').add(1, 'day').format(),
    };
  }
  if (view === 'month') {
    return {
      startDate: moment(date).startOf('month').subtract(7, 'days').utc().format(),
      endDate: moment(date).endOf('month').add(7, 'days').utc().format(),
    };
  }
  if (view === 'agenda') {
    return {
      startDate: moment(date).startOf('day').utc().format(),
      endDate: moment(date).endOf('day').add(1, 'month').utc().format(),
    };
  }
});
