import * as actionTypes from './actionTypes'

import { safePropertyForObject, safeRawPropertyForObject } from 'utils/formHelpers'

const SALES_FUNNEL_STEPS_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM_FOR_SALES_FUNNEL_STEPS]: (state, action) => {
    return initialStateForSalesFunnel(action.salesFunnelSteps)
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    const newData = action.data
    return { ...state, data: newData }
  },
  [actionTypes.UPDATING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  }
}

function initialStateForSalesFunnel (salesFunnelSteps) {
  const stepOne = salesFunnelSteps[0]
  const stepTwo = salesFunnelSteps[1]
  const stepThree = salesFunnelSteps[2]
  const stepFour = salesFunnelSteps[3]
  return {
    errors: null,
    loading: false,
    data: {
      fields: {
        stepOne: {
          id: safePropertyForObject(stepOne, 'id'),
          value: safePropertyForObject(stepOne, 'name'),
          stepNumber: 1,
          allowsClose: safeRawPropertyForObject(stepOne, 'allows_close'),
          isValid: true
        },
        stepTwo: {
          id: safePropertyForObject(stepTwo, 'id'),
          value: safePropertyForObject(stepTwo, 'name'),
          stepNumber: 2,
          allowsClose: safeRawPropertyForObject(stepTwo, 'allows_close'),
          isValid: true
        },
        stepThree: {
          id: safePropertyForObject(stepThree, 'id'),
          value: safePropertyForObject(stepThree, 'name'),
          stepNumber: 3,
          allowsClose: safeRawPropertyForObject(stepThree, 'allows_close'),
          isValid: true
        },
        stepFour: {
          id: safePropertyForObject(stepFour, 'id'),
          value: safePropertyForObject(stepFour, 'name'),
          stepNumber: 4,
          allowsClose: safeRawPropertyForObject(stepFour, 'allows_close'),
          isValid: true
        }
      }
    }
  }
}

export default function reducer (state = initialStateForSalesFunnel([]), action) {
  const handler = SALES_FUNNEL_STEPS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
