import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

export class FacebookPageRow extends Component {
  render() {
    const { facebookPage, selectPage, selected } = this.props;
    const backgroundClass = selected ? "bg-primary" : "";
    const textClass = selected ? "text-white" : "";
    const imgUrl =
      "https://graph.facebook.com/v3.2/" + facebookPage.id + "/picture";

    return (
      <Row
        className={textClass + " selectable " + backgroundClass}
        onClick={() => selectPage(facebookPage)}
      >
        <td>
          <img alt="" src={imgUrl} style={{ width: "50px" }} />
        </td>
        <td>{facebookPage.name}</td>
        <td>
          <a
            href={`https://www.facebook.com/${facebookPage.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button className="btn btn-success">Visit page</Button>
          </a>
        </td>
      </Row>
    );
  }
}

FacebookPageRow.propTypes = {
  facebookPage: PropTypes.object.isRequired,
  selectPage: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default FacebookPageRow;

const Row = styled.tr``;
const Button = styled.div``;
