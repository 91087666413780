import * as actionTypes from './actionTypes'

const CLASSES_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_CACHED]: (state, action) => {
    return { ...state, cached: action.cached }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_CLASSES]: (state, action) => {
    return { ...state, classes: action.classes }
  },
  [actionTypes.SET_CLASS_LOCATION_IDS]: (state, action) => {
    return { ...state, classLocationIds: action.classLocationIds }
  }
}

const initialState = {
  classes: [],
  classLocationIds: [],
  cached: false,
  loading: false,
  errors: null
}

export default function reducer (state = initialState, action) {
  const handler = CLASSES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
