import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  overflow,
  OverflowProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';

export interface ImageProps extends ColorProps, LayoutProps, SpaceProps, OverflowProps, BorderProps, ShadowProps, FlexboxProps {}

export const Image = styled.img<ImageProps>`
  ${color}
  ${layout}
  ${space}
  ${overflow}
  ${flexbox}
  ${border}
  ${shadow}
`;

Image.displayName = 'Image';
