import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectLeadSourceTableRow extends Component {
  rowSelected = event => {
    const {
      leadSource,
      leadSourceSelected,
      selectLeadSource,
      deselectLeadSource
    } = this.props;
    if (leadSourceSelected) {
      deselectLeadSource(leadSource);
    } else {
      selectLeadSource(leadSource);
    }
  };

  render() {
    const { leadSource, leadSourceSelected } = this.props;
    return (
      <tr className="selectable" onClick={() => this.rowSelected(leadSource)}>
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input
              type="checkbox"
              disabled
              checked={leadSourceSelected}
              readOnly
            />
            <span />
          </label>
        </td>
        <td>{leadSource.source_name}</td>
      </tr>
    );
  }
}

SelectLeadSourceTableRow.propTypes = {
  leadSource: PropTypes.object.isRequired,
  leadSourceSelected: PropTypes.bool.isRequired,
  selectLeadSource: PropTypes.func.isRequired,
  deselectLeadSource: PropTypes.func.isRequired
};
