import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'
import { LeadTimeOutValidator } from '../validators'

const { TextField, Switch } = FormFields

export class LeadTimeForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true)
    this.props.onInput(newData)
  }

  render () {
    const {
      leadTimeOutEnabled,
      leadTimeOutMinutes
     } = this.props.data.fields
    return (
      <div>
        <div className='row'>
          <div className='col-md-8'>
            <h3 className='block-title'>Use lead time out</h3>
            <Switch
              name='leadTimeOutEnabled'
              label='When switched on, if a lead is not actioned within a timelimit by the salesperson,
               they will be unassigned and notified. Does not apply to CSV imports.'
              onChange={this.handleToggle}
              switchStyle='switch-sm switch-primary'
              value={leadTimeOutEnabled.value} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <TextField name='leadTimeOutMinutes'
              label='How many minutes should pass before the lead times out?'
              placeholder='eg. 15'
              onChange={this.handleInput}
              validator={LeadTimeOutValidator}
              value={leadTimeOutMinutes.value}
              formGroupIconElement={'Minutes'}
              disabled={!leadTimeOutEnabled.value} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-8'>
            <button className='btn btn-primary push-5-r push-10'
              type='button'
              onClick={this.props.onSaveSelected}>
              <i className='si si-clock' /> Update Lead Time Out
            </button>
          </div>
        </div>
      </div>
    )
  }
}

LeadTimeForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSaveSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default LeadTimeForm
