import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.manageLeads[NAME].errors
export const getTotalLeads = state => state.manageLeads[NAME].totalLeads
export const getLoading = state => state.manageLeads[NAME].loading
export const getPageCount = state => state.manageLeads[NAME].pageCount
export const getCurrentPage = state => state.manageLeads[NAME].currentPage
export const getLeads = state => state.manageLeads[NAME].leads
export const getShowingQuickLeadView = state => state.manageLeads[NAME].showingQuickLeadView
const getSelectedLeadId = state => state.manageLeads[NAME].selectedLeadId

export const selectedLead = createSelector(
  [ getLeads, getSelectedLeadId ],
  (leads, selectedLeadId) => {
    if (selectedLeadId !== null) {
      const index = leads.map((lead) => lead.id).indexOf(parseInt(selectedLeadId))
      return leads[index]
    }
    return null
  }
)
