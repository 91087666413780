import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LeadTodoItem } from './LeadTodoItem';
import './LeadTodo.css';
import { EmptyView } from 'modules/components';
import CustomTodoModalContainer from '../containers/CustomTodoModalContainer';

import SweetAlert from 'react-bootstrap-sweetalert';

import { NEW_TODO_MODAL } from '../constants';

export class LeadTodosView extends Component {
  state = {
    showWarning: false,
    leadActionToDelete: null,
  };

  leadTodoClicked = (leadAction) => {
    const { lead, user, toggleCompleteTodoForLead } = this.props;
    toggleCompleteTodoForLead(leadAction, lead, user);
  };

  addTodo = () => {
    this.props.showModal(NEW_TODO_MODAL);
  };

  deleteTodo = (leadAction) => {
    const { lead, deleteTodoForLead } = this.props;
    const { leadActionToDelete } = this.state;
    deleteTodoForLead(leadActionToDelete, lead);
    this.dismissPopups();
  };

  showWarningPopup = (leadActionToDelete) => {
    this.setState({
      ...this.state,
      showWarning: true,
      leadActionToDelete: leadActionToDelete,
    });
  };

  dismissPopups = () => {
    this.setState({
      ...this.state,
      showWarning: false,
      leadActionToDelete: null,
    });
  };

  render() {
    const { todoItems, loading } = this.props;

    const todoList = todoItems.map((actionItem, index) => {
      return (
        <LeadTodoItem
          key={index}
          leadTodoItem={actionItem}
          leadTodoClicked={this.leadTodoClicked}
          leadTodoDeleted={this.showWarningPopup}
        />
      );
    });

    return (
      <div className="push-20">
        {loading && (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h3 className="push-10">Loading Tasks</h3>
              <i className="fa fa-2x fa-sun-o fa-spin" />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            {todoItems.length > 0 && (
              <div>
                <button className="btn btn-success push-5-r push-10" type="button" onClick={this.addTodo}>
                  <i className="fa fa-plus" /> Schedule task
                </button>
                {todoList}
              </div>
            )}
            {todoItems.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="fa fa-tasks"
                title="No Actions for this lead"
                buttonText="Add A To Do"
                buttonSelected={this.addTodo}
              />
            )}
            <CustomTodoModalContainer modalName={NEW_TODO_MODAL} lead={this.props.lead} />
            {this.state.showWarning && (
              <SweetAlert
                warning
                showCancel
                cancelBtnBsStyle="default"
                confirmBtnBsStyle="warning"
                confirmBtnText="Delete Task"
                title="Are you Sure?"
                onConfirm={this.deleteTodo}
                onCancel={this.dismissPopups}
              >
                Are you sure you want to delete this task?
              </SweetAlert>
            )}
          </div>
        )}
      </div>
    );
  }
}

LeadTodosView.propTypes = {
  todoItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  toggleCompleteTodoForLead: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  deleteTodoForLead: PropTypes.func.isRequired,
};
