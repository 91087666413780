import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AutomaticReportsView from "../components/AutomaticReportsView";
import * as reportTableSelectors from '../modules/reportsTable/selectors'
import * as reportTableActions from '../modules/reportsTable/actions'

function mapStateToProps(state) {
  return {
    selectedAutomaticReport: reportTableSelectors.getSelectedAutomaticReport(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    reset: reportTableActions.reset
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomaticReportsView);
