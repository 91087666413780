import { request, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import billing from 'modules/Stores/Billing';

import notify from 'modules/Notifications';

export const createCreditCardRecord = (data, id) => {
  return (dispatch) => {
    const { number, name, expiry, cvc } = data.fields;

    dispatch(modal.actions.startLoading());

    const body = {
      paychoice_payment_method: {
        number: number.value,
        name: name.value,
        expire: expiry.value,
        cvc: cvc.value,
      },
    };

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.hideModal());
      dispatch(resetForm());
      if (id) {
        dispatch(billing.actions.addPaymentMethod(payload.paychoice_payment_method));
        dispatch(billing.actions.removePaymentMethodId(id));
        notify.success('Payment Method Replaced');
      } else {
        dispatch(billing.actions.addPaymentMethod(payload.paychoice_payment_method));
        notify.success('Payment Method Added');
        dispatch(modal.actions.stopLoading());
      }
    }
    if (id) {
      return request('replace_paychoice_payment_method/' + id, PUT, body, onSuccess, onFailure);
    } else {
      return request('paychoice_payment_methods', POST, body, onSuccess, onFailure);
    }
  };
};

export function onInput(data) {
  return {
    type: actionTypes.FORM_UPDATED,
    data,
  };
}

export function resetForm() {
  return {
    type: actionTypes.RESET_FORM,
  };
}
