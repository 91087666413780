import PropTypes from "prop-types";
import React, { Component } from "react";

import TextField from "modules/components/FormFields/TextField";
import { CreditCardValidator } from "../validators";
import { generateNewFormState } from "utils/formHelpers";

import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

export class CreditCardForm extends Component {
  state = {
    currentFieldName: null,
  };

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onInput(newData);
  };

  onFocus = (fieldName) => {
    this.setState({ ...this.state, currentFieldName: fieldName });
  };

  render() {
    const { number, name, expiry, cvc } = this.props.data.fields;
    return (
      <div>
        <div className="row">
          <div className="col-sm-6 hidden-xs">
            <Cards
              number={number.value}
              expiry={expiry.value}
              cvc={cvc.value}
              name={name.value}
              focused={this.state.currentFieldName}
            />
          </div>
          {this.props.showInstructions && (
            <div className="col-sm-6 col-xs-12 nice-copy">
              <p style={{ paddingLeft: "10px" }}>
                Enter your credit card details. Your card will be charged for
                your {this.props.productName} subscription and any SMS credit
                you wish to use.
              </p>
            </div>
          )}
        </div>
        <br />
        <div className="row">
          <div className="col-xs-12">
            <TextField
              name="number"
              label="Card Number"
              placeholder="**** **** **** ****"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={CreditCardValidator}
              initialValue={number.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextField
              name="name"
              label="Full Name"
              placeholder="Full Name"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={CreditCardValidator}
              initialValue={name.value}
            />
          </div>
        </div>
        <div className="row push-30">
          <div className="col-xs-6">
            <TextField
              name="expiry"
              label="Expiry"
              placeholder="MM/YY"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={CreditCardValidator}
              initialValue={expiry.value}
            />
          </div>
          <div className="col-xs-6">
            <TextField
              name="cvc"
              label="CVC"
              placeholder="***"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={CreditCardValidator}
              initialValue={cvc.value}
            />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <p className="text-muted">
                <i className="si si-lock" /> All payment details are sent on a
                256 bit encrypted connection. No credit card details are stored
                on our servers and are managed by a trusted, PCI compliant 3rd
                party.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreditCardForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  showInstructions: PropTypes.bool.isRequired,
};

export default CreditCardForm;
