import { NAME } from "./constants";

export const RESET = NAME + "/RESET";
export const SET_LOADING = NAME + "/SET_LOADING";

export const UPDATE_FILTER_DATA = NAME + "/UPDATE_FILTER_DATA";
export const SET_FILTER_DATA = NAME + "/SET_FILTER_DATA";
export const SET_COMPARE_VALUE = NAME + "/SET_COMPARE_VALUE";
export const SET_CALCULATING_VALUE = NAME + "/SET_CALCULATING_VALUE";
export const SET_ERRORS = NAME + "/SET_ERRORS";
