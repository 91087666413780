import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'
import locations from 'modules/Stores/Locations'
import userSelect from '../modules/userSelect'
import locationSelect from '../modules/locationSelect'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    selectedLocationId: locationSelect.selectors.getSelectedLocationId(state),
    selectedLocationName: locationSelect.selectors.getSelectedLocationName(state),
    showingSelection: locationSelect.selectors.getShowCompletedOption(state),
    defaultLocation: locations.selectors.getDefaultLocation(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal,
    selectLocation: locationSelect.actions.selectLocation,
    selectUsers: userSelect.actions.selectUsers,
    setShowCompletedOption: locationSelect.actions.setShowCompletedOption
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(locationSelect.components.LocationSelectView)
