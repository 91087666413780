import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ThirdPartyIntegrationTableRow } from './ThirdPartyIntegrationTableRow'

export class ThirdPartyIntegrationTableView extends Component {

  render () {
    const { thirdPartyIntegrations, thirdPartyIntegrationSelected, selectedThirdPartyIntegrationId, tableHead,
      renderRow } = this.props
    const thirdPartyIntegrationsWithCsv = [{ id: -1, name: 'csv' }, ...thirdPartyIntegrations]
    const rows = thirdPartyIntegrationsWithCsv.map((thirdPartyIntegration, index) => {
      const selected = (thirdPartyIntegration.id === selectedThirdPartyIntegrationId)
      if (renderRow) {
        return renderRow(thirdPartyIntegration, selected)
      }
      return <ThirdPartyIntegrationTableRow
        key={index}
        thirdPartyIntegration={thirdPartyIntegration}
        thirdPartyIntegrationSelected={thirdPartyIntegrationSelected}
        selected={selected} />
    })

    return (
      <div style={{ marginTop: '-20px' }}>
        <table className='table table-vcenter table-hover'>
          {tableHead &&
            tableHead
          }
          {!tableHead &&
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
          }
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

ThirdPartyIntegrationTableView.propTypes = {
  thirdPartyIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRow: PropTypes.func,
  tableHead: PropTypes.element,
  thirdPartyIntegrationSelected: PropTypes.func.isRequired,
  selectedThirdPartyIntegrationId: PropTypes.number
}

export default ThirdPartyIntegrationTableView
