import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SelectQuickSendTemplateView from "./SelectQuickSendTemplateView";
import emailTemplates from "modules/Stores/EmailTemplates";
import * as apiActions from "modules/Stores/EmailTemplates/apiActions";

function mapStateToProps(state) {
  return {
    emailTemplates: emailTemplates.selectors.getEmailTemplates(state),
    loading: emailTemplates.selectors.getLoading(state),
    errors: emailTemplates.selectors.getErrors(state),
    pageDetails: emailTemplates.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmailTemplates: apiActions.getEmailTemplates
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectQuickSendTemplateView);
