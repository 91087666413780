export const NAME = 'filters'

export const CLUBS = 'locations'
export const USERS = 'users'
export const LEAD_SOURCES = 'lead_sources'

export const ADDED_TO_TRIAL = 'added_to_trial'
export const LEAD_STATUS_CHANGE = 'status_change'
export const IN_TRIAL = 'in_trial'

export const SELECT_LOCATIONS_MODAL = NAME + '/SELECT_LOCATIONS_MODAL'
export const SELECT_USERS_MODAL = NAME + '/SELECT_USERS_MODAL'
