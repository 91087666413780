import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';
import { FormFields } from 'modules/components';

const { TextField } = FormFields;

export class NewCustomMailMergeTagModal extends Component {
  state = {
    label: null,
    value: null,
  };

  handleChange = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  createCustomMailMergeTagSelected = () => {
    const { createCustomMailMergeTag } = this.props;
    const { label, value } = this.state;
    createCustomMailMergeTag(label, value, this.onSubmitSuccess);
  };

  onSubmitSuccess = () => {
    this.setState({ label: null, value: null });
    this.props.hideModal();
  };

  formIsValid = () => {
    const { label, value } = this.state;
    if (!label || label.length < 1) {
      return false;
    }
    if (!value || value.length < 1) {
      return false;
    }
    return true;
  };

  render() {
    const { currentModal, hideModal, modalName, loading } = this.props;
    const { callName } = this.state;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Let's create a Custom Mail Merge Tag
              </h3>
            </div>
            <div className="block-content">
              <div className="push-30">
                <div className="row">
                  <div className="col-sm-12">
                    <TextField
                      name="label"
                      label="Mail Merge Tag Label *"
                      placeholder="Our Instagram"
                      formFieldStyle=""
                      onChange={this.handleChange}
                      value={callName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextField
                      name="value"
                      label="Mail Merge Tag Default Value *"
                      placeholder="@yourInstagramHandle"
                      formFieldStyle=""
                      onChange={this.handleChange}
                      value={callName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default pull-left" onClick={hideModal}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r"
              type="button"
              disabled={!this.formIsValid()}
              onClick={this.createCustomMailMergeTagSelected}
            >
              <i className="fa fa-plus" /> Create Mail Merge Tag
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

NewCustomMailMergeTagModal.propTypes = {
  createCustomMailMergeTag: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  currentModal: PropTypes.string,
};

export default NewCustomMailMergeTagModal;
