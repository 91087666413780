import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import { ConnectedRouter } from 'react-router-redux';
import store, { history } from './store/store';
import './styles/bootstrap.css';
import './styles/font-awesome.css';
import App from 'containers/app';
import './icons/font-awesome/css/font-awesome.min.css';
import './fonts/open-sans/open-sans.css';
import session from 'modules/Stores/Session';
import { registerBugsnag } from 'modules/Helpers/BugsnagClient';

import 'react-select/dist/react-select.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toast.css';
import './styles/override.css';
import 'styles/modalStyles.css';

store.dispatch(session.actions.restoreSession());
//    "react-router": "^4.1.1",

registerBugsnag();
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <>
          <App.AppContainer />
          <ToastContainer />
        </>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
