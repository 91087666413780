import PropTypes from 'prop-types'
import React, { Component } from 'react'

import moment from 'moment'
import 'moment-timezone'

export class NextPaymentDueSection extends Component {

  removeSelected = () => {

  }

  replaceSelected = () => {

  }

  render () {
    const { dueDate, timezone } = this.props
    const currentDate = moment(dueDate)
    const iscurrentDate = currentDate.isSame(new Date(), 'day')
    const dateString = (iscurrentDate) ? 'today' : 'on ' + currentDate.tz(timezone).format('MMMM Do YYYY')
    return (
      <div>
        <h3 className='font-w300 push'>Next Payment</h3>
        <p>
          Your next payment will be processed {dateString}.
        </p>
        <hr />
      </div>
    )
  }
}

NextPaymentDueSection.propTypes = {
  dueDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
}

export default NextPaymentDueSection
