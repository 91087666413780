import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import SelectLeadSourceTableView from "./TableView/SelectLeadSourceTableView";

import { CREATE_LEAD_VIEW } from "../constants";
import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class LeadSourceSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { leadSources } = this.props;
    if (leadSources.length > 0) {
      return;
    }
    this.getLeadSourcesWithPageNumber(1);
  }

  getLeadSourcesWithPageNumber = pageNumber => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  showCreateLead = () => {
    this.props.setCurrentView(CREATE_LEAD_VIEW);
  };

  onLeadSourceSelect = leadSource => {
    const { setCurrentView, selectLeadSource } = this.props;
    selectLeadSource(leadSource);
    setCurrentView(CREATE_LEAD_VIEW);
  };

  render() {
    const {
      leadSources,
      pageDetails,
      loading,
      selectedLeadSource
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <div>
        <ContentBlockView loading={loading} bordered>
          <div className="row" style={{ marginTop: "-20px" }}>
            <TextSearchView
              sectionName={"leadSourceSearch"}
              onUpdate={this.searchUpdated}
            />
          </div>
          {leadSources.length > 0 && (
            <div>
              <SelectLeadSourceTableView
                leadSources={leadSources}
                selectedLeadSource={selectedLeadSource}
                onLeadSourceSelect={this.onLeadSourceSelect}
              />
            </div>
          )}
          {leadSources.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
        </ContentBlockView>
        <Modal.Footer>
          <button
            className="btn btn-default push-5-r push-10"
            type="button"
            onClick={this.showCreateLead}
          >
            Go Back
          </button>
        </Modal.Footer>
      </div>
    );
  }
}

LeadSourceSelectView.propTypes = {
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectedLeadSource: PropTypes.object,
  getLeadSources: PropTypes.func.isRequired,
  selectLeadSource: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
};

export default LeadSourceSelectView;
