import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getLoading = (state) => state.smsRecharge[NAME].loading
export const getErrors = state => state.smsRecharge[NAME].errors
export const getSmsAccounts = state => state.smsRecharge[NAME].smsAccounts
export const getSelectedSmsAccountId = state => state.smsRecharge[NAME].selectedSmsAccountId
export const getPageDetails = state => state.smsRecharge[NAME].pageDetails

export const getSelectedSmsAccount = createSelector(
  [ getSmsAccounts, getSelectedSmsAccountId ],
  (smsAccounts, selectedSmsAccount) => {
    const index = smsAccounts
      .findIndex(smsAccount => parseInt(smsAccount.id) === parseInt(selectedSmsAccount))
    return smsAccounts[index]
  }
)
