import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import onClickOutside from "react-onclickoutside";
import NumericInput from "react-numeric-input";

import moment from "moment";
import "moment-timezone";

const NONE = "No filter";
const NEVER_CONTACTED = "Never contacted";
const SET_VALUE = " Days ago";

export class LastContactedSection extends Component {
  state = {
    showingOptions: false,
    selectedIndex: 0,
    showingDayEntry: false,
    options: [NONE, NEVER_CONTACTED, SET_VALUE],
  };

  indexClicked = (index) => {
    const option = this.state.options[index];
    switch (option) {
      case NONE:
        this.hideDayEntry(index);
        this.resetData();
        break;
      case NEVER_CONTACTED:
        this.hideDayEntry(index);
        this.setNeverContacted();
        break;
      case SET_VALUE:
        this.showDayEntry(index);
        break;
      default:
        break;
    }
  };

  showDayEntry = (index) => {
    this.setState({
      ...this.state,
      showingDayEntry: true,
      showingOptions: false,
      selectedIndex: index,
    });
  };

  hideDayEntry = (index) => {
    this.setState({
      ...this.state,
      showingDayEntry: false,
      showingOptions: false,
      selectedIndex: index,
    });
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  selectDate(date, fieldName) {
    const utcDate = date.format();
    this.handleInput(fieldName, utcDate, true);
  }

  clearData = () => {
    this.handleInput("last_contacted", null, true);
  };

  resetData = () => {
    const data = {
      fields: {
        last_contacted: { value: null, isValid: true },
        never_contacted: { value: false, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  setNeverContacted = () => {
    const data = {
      fields: {
        last_contacted: { value: null, isValid: true },
        never_contacted: { value: true, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  setNeverContactedDateForDays = (days) => {
    const date = moment()
      .subtract(days + 1, "day")
      .format();
    const data = {
      fields: {
        last_contacted: { value: date, isValid: true },
        never_contacted: { value: false, isValid: true },
      },
    };
    this.props.onUpdate(data, this.props.sectionName);
  };

  currentSelection = () => {
    const { options, selectedIndex } = this.state;
    return options[selectedIndex];
  };

  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onUpdate(newSectionData, this.props.sectionName);
  };

  render() {
    const options = this.state.options.map((option, index) => {
      const className =
        index === this.state.selectedIndex ? "selected" : "selectable";
      return (
        <li key={index} className={className}>
          <div className="DropDown" onClick={() => this.indexClicked(index)}>
            {option}
          </div>
        </li>
      );
    });
    let selectedOption = this.currentSelection();
    const btnColorStyle = selectedOption === NONE ? "btn-default" : "btn-info";
    return (
      <div>
        <button
          className={"btn " + btnColorStyle + " push-5-r push-10"}
          type="button"
          onClick={this.toggleDropdown}
        >
          {selectedOption} <i className="fa fa-caret-down" />
        </button>
        {this.state.showingDayEntry && (
          <NumericInput
            min={0}
            max={100}
            onChange={this.setNeverContactedDateForDays}
            style={{
              input: {
                width: "60px",
                height: "34px",
              },
            }}
          />
        )}
        {this.state.showingOptions && (
          <ul className="dropdown-menu" style={{ display: "block" }}>
            {options}
          </ul>
        )}
      </div>
    );
  }
}

LastContactedSection.propTypes = {
  timezone: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default onClickOutside(LastContactedSection);
