import React, { FC, useState, useEffect } from 'react';

import { IPaginationMeta, SubscriptionAddOnTemplate } from 'types';
import { Box, PagesView, GymLeadsLoader } from 'modules/components';
import { getLocationsWithAddOns, ILocationWithAddOns } from 'modules/api/locations';
import {
  createSubscriptionForAllLocations,
  createSubscriptionForLocationId,
  removeSubscriptionForLocationId,
} from 'modules/api/subscriptionAddOn';
import notify from 'modules/Notifications';

interface ManageSubscriptionsViewProps {}

export const ManageSubscriptionsView: FC<ManageSubscriptionsViewProps> = () => {
  const [loading, setLoading] = useState(false);
  const [pageMeta, setPageMeta] = useState<IPaginationMeta>({ current_page: 1, total_pages: 0, total_count: 0 });
  const [locations, setLocations] = useState<ILocationWithAddOns[]>([]);

  useEffect(() => {
    fetchLocationsForPage(1);
  }, []);

  const fetchLocationsForPage = async (pageNumber: number) => {
    setLoading(true);
    const response = await getLocationsWithAddOns(pageNumber);
    setPageMeta(response.meta);
    setLocations(response.locations);

    setLoading(false);
  };

  const handleAllLocationsClicked = async () => {
    setLoading(true);
    await createSubscriptionForAllLocations(SubscriptionAddOnTemplate.FrontDesk);
    notify.success('Front Desk setup for all locations');
    fetchLocationsForPage(1);
  };

  const enableForLocationId = async (locationId: number) => {
    const response = await createSubscriptionForLocationId(SubscriptionAddOnTemplate.FrontDesk, locationId);
    notify.success('Front Desk setup for location');
    const index = locations.findIndex((location) => location.id === locationId);
    const location = {
      ...locations[index],
      subscription_add_ons: [response.subscription_add_on],
    };

    setLocations([...locations.slice(0, index), location, ...locations.slice(index + 1)]);
  };

  const disableForLocationId = async (locationId: number, subscriptionId: number) => {
    await removeSubscriptionForLocationId(subscriptionId);
    notify.success('Front Desk removed for location');
    const index = locations.findIndex((location) => location.id === locationId);
    const location = {
      ...locations[index],
      subscription_add_ons: [],
    };

    setLocations([...locations.slice(0, index), location, ...locations.slice(index + 1)]);
  };

  if (loading) return <GymLeadsLoader active />;

  return (
    <Box isFlex column w100>
      <p>Add new profiles and manage which locations can access them.</p>
      <button
        className={'btn btn-minw btn-rounded btn-noborder btn-success push-10'}
        onClick={handleAllLocationsClicked}
        style={{ width: 240 }}
      >
        Setup for all locations
      </button>
      <table className="table table-vcenter table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-center">Front Desk enabled?</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((location) => {
            const frontDeskSubscription = location.subscription_add_ons.filter(
              (addOn) => addOn.template === SubscriptionAddOnTemplate.FrontDesk
            )?.[0];
            const enabledString = frontDeskSubscription ? 'Enabled' : 'Not enabled';
            return (
              <tr key={`location-${location.id}`}>
                <td>{location.admin_display_name}</td>
                <td className="text-center">
                  <span>{enabledString}</span>
                </td>
                <td>
                  {frontDeskSubscription && (
                    <button
                      type="button"
                      className="btn btn-xs btn-danger"
                      onClick={() => disableForLocationId(location.id, frontDeskSubscription.id)}
                    >
                      <i className="fa fa-times"></i> Disable
                    </button>
                  )}
                  {!frontDeskSubscription && (
                    <button type="button" className="btn btn-xs btn-success" onClick={() => enableForLocationId(location.id)}>
                      <i className="fa fa-plus"></i> Enable
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <PagesView pageDetails={pageMeta} pageClicked={fetchLocationsForPage} />
    </Box>
  );
};
