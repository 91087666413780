import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import sales from '../modules/sales'
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    loading: sales.selectors.getLoading(state),
    data: sales.selectors.getData(state),
    errors: sales.selectors.getErrors(state),
    queryString: queryStringSelector(state),
    calculatingOption: filters.selectors.getCalculatingOption(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchWithQueryString: sales.actions.fetchWithQueryString
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(sales.components.SalesView)
