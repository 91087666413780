import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

import modal from 'modules/Stores/Modal'
import FooterButtonsView from '../components/FooterButtonsView'

function mapStateToProps (state) {
  return {
    selectedSubscription: selectors.getSelectedSubscription(state),
    subscriptions: selectors.getSubscriptions(state),
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    deleteIntegration: actions.deleteIntegration,
    createIntegration: actions.createIntegration,
    resetSubscriptionModal: actions.resetSubscriptionModal,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterButtonsView)
