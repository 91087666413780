import { apiClient } from 'utils/apiClient';
import { IPaginationMeta, ISubscriptionAddOn, ILocation } from 'types';

export interface ILocationWithAddOns extends ILocation {
  subscription_add_ons: ISubscriptionAddOn[];
}

type GetLocationsWithAddOnsResponse = {
  locations: ILocationWithAddOns[];
  meta: IPaginationMeta;
};

export const getLocationsWithAddOns = async (pageNumber = 1) => {
  const url = `locations_with_add_ons?page[number]=${pageNumber}`;
  const response = await apiClient().get(url);
  return response.data as GetLocationsWithAddOnsResponse;
};
