import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectDealTableRow } from "./SelectDealTableRow";

export class SelectDealTableView extends Component {
  render() {
    const { deals, selectedDealIds, selectDeal, deselectDeal } = this.props;
    const rows = deals.map((deal, index) => {
      const selectedIndex = selectedDealIds.findIndex(
        selectedDealId => deal.id === selectedDealId
      );
      const selected = selectedIndex >= 0;
      return (
        <SelectDealTableRow
          key={index}
          deal={deal}
          dealSelected={selected}
          selectDeal={selectDeal}
          deselectDeal={deselectDeal}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectDealTableView.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDealIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectDeal: PropTypes.func.isRequired,
  deselectDeal: PropTypes.func.isRequired
};

export default SelectDealTableView;
