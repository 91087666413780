import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import leadSourcesStore from 'modules/Stores/LeadSources';
import * as leadSourceApiActions from 'modules/Stores/LeadSources/apiActions';
import modal from 'modules/Stores/Modal';
import leadSources from '../modules/leadSources';
import session from 'modules/Stores/Session';

function mapStateToProps(state) {
  const permissionSet = session.selectors.getUserPermissions(state);
  return {
    leadSources: leadSourcesStore.selectors.getLeadSources(state),
    loading: leadSourcesStore.selectors.getLoading(state),
    pageDetails: leadSourcesStore.selectors.getPageDetails(state),
    canEdit: permissionSet.allow_lead_settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadSources: leadSourceApiActions.getLeadSources,
      createLeadSource: leadSources.actions.create,
      deleteLeadSource: leadSources.actions.deleteLeadSource,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(leadSources.components.LeadSourcesView);
