import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';
import 'moment-timezone';

import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import ClassesContainer from '../../../containers/ClassesContainer';
import * as actions from 'modules/Helpers/IntegrationHelpers';

import { SCHEDULE_APPOINTMENT_MODAL } from '../../../constants';

export default class ClassAppointmentModal extends Component {
  state = {
    selectedClass: null,
  };

  resetState = () => {
    this.setState({ selectedClass: null });
  };

  hideModalAndCleanUp = () => {
    this.resetState();
    this.props.hideModal();
  };

  selectClass = (selectedClass) => {
    this.setState({ ...this.state, selectedClass: selectedClass });
  };

  selectClassPassId = (selectedClassPassId) => {
    this.setState({ ...this.state, selectedClassPassId: selectedClassPassId });
  };

  openAppointmentModal = () => {
    const { rescheduleModalName } = this.props;
    const scheduleAppointmentModalName = rescheduleModalName ?? SCHEDULE_APPOINTMENT_MODAL;
    this.props.showModal(scheduleAppointmentModalName);
  };

  scheduleAppointment = () => {
    const { scheduleAppointment, lead, listUpdateProtocol, classIntegrationName } = this.props;
    const { selectedClass } = this.state;

    let utcDateTimeAsString;
    if (classIntegrationName === 'glofox') {
      utcDateTimeAsString = selectedClass.start_date_time;
    } else {
      // mindbody
      const nonFormatString = moment(selectedClass.start_date_time).utc().format('YYYY-MM-DDTHH:mm:ss');
      const dateTimeMoment = moment(nonFormatString).tz(this.props.timezone);

      utcDateTimeAsString = dateTimeMoment.utc().format();
    }

    const serviceString = 'Trial ' + selectedClass.name;

    const start = moment(selectedClass.start_date_time);
    const end = moment(selectedClass.end_date_time);
    const length = moment.duration(end.diff(start)).asMinutes();

    scheduleAppointment(lead, utcDateTimeAsString, listUpdateProtocol, selectedClass.id, serviceString, null, length, classIntegrationName);
  };

  render() {
    const { currentModal, modalName, loading, classIntegrationId, classIntegrationName } = this.props;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const logoName = classIntegrationName ? classIntegrationName : 'mindbody';
    const logo = actions.logoForOption(logoName);

    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp} dialogClassName="modal-lg">
        <div className={blockStyle} style={{ marginBottom: '0px' }}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Book a trial class
            </h3>
          </div>
          <div className="block-content">
            <ClassesContainer
              selectedClass={this.state.selectedClass}
              classIntegrationId={classIntegrationId}
              classIntegrationName={classIntegrationName}
              selectClass={this.selectClass}
            />
          </div>
        </div>
        <Modal.Footer>
          <img alt="" src={logo} className="pull-left" style={{ width: '100px' }} />
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
            Cancel
          </button>
          <button className="btn btn-default push-5-r push-10" onClick={this.openAppointmentModal}>
            Set your own date/time
          </button>
          <button
            className="btn btn-success push-5-r push-10"
            type="button"
            disabled={!this.state.selectedClass}
            onClick={this.scheduleAppointment}
          >
            <i className="si si-calendar" /> Book appointment
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ClassAppointmentModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  // openAppointmentModal: PropTypes.func,
  modalName: PropTypes.string,
  rescheduleModalName: PropTypes.string,
  timezone: PropTypes.string,
  scheduleAppointment: PropTypes.func,
  listUpdateProtocol: PropTypes.object.isRequired,
  classIntegrationId: PropTypes.number.isRequired,
  classIntegrationName: PropTypes.string,
  currentModal: PropTypes.string,
};

ClassAppointmentModal.defaultProps = {
  listUpdateProtocol: {},
};
