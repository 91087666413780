import { request, GET } from "utils/apiUtils";

import searchStore from "./index";

export const getSearchResults = searchTerm => {
  return dispatch => {
    if (searchTerm.length === 0) {
      dispatch(searchStore.actions.setSearchResults([]));
      return;
    }
    dispatch(searchStore.actions.setLoading(true));
    function onFailure(payload) {
      dispatch(searchStore.actions.setLoading(false));
      dispatch(searchStore.actions.setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(searchStore.actions.setLoading(false));
      dispatch(searchStore.actions.setSearchResults(payload.leads));
      dispatch(searchStore.actions.setPageDetails(payload.meta));
    }

    const url = `leads?filter[full_text_search]=${searchTerm}&search_list=true`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};
