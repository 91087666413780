import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getData = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].data
// export const getIntegrationPermissionSet = (state) => state
// .settingsThirdPartyIntegrations.configuration[NAME].permissionSet
export const getTabIndex = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].tabIndex
export const getSiteLocations = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].siteLocations
export const getPageCount = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].pageCount
export const getCurrentPage = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].currentPage
export const getTotal = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].total

export const currentIndexIsReady = createSelector(
  [ getData, getTabIndex ],
  (data, tabIndex) => {
    if (!data) { return false }
    switch (tabIndex) {
      case 0:
        const customUrl = data.fields.customUrl.value
        return (customUrl !== null && customUrl !== undefined) || false
      case 1:
        const { username, password } = data.fields
        return (username.value.length > 0 && password.value.length > 0)
      case 2:
        const locationId = data.fields.integrationLocationId.value
        return (locationId !== null && locationId !== undefined)
      default:
        return true
    }
  }
)
