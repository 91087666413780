import axios from 'axios';
import config from 'config'

export function apiClient() {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Client-ID": config.appName
  };

  return axios.create({
    baseURL: config.apiHost,
    responseType: "json",
    withCredentials: true,
    headers: headers
  });
}

export function buildIndexUrl(base, page, sort, search) {
  let url = base + '?page[number]=' + page;
  if (sort) { url = url + '&sort=' + sort };
  if (search) { url = url + '&search=' + search };
  return url;
}
