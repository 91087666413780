import { request, POST, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export const sendEmail = (username) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = {
      password_reset_request: {
        username: username,
      },
    };

    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccess());
    }

    return request("password_reset_requests", POST, body, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

// Whitelabel profile
export const fetchWhitelabelProfileLogo = (productName) => {
  return (dispatch) => {
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(setWhitelabelProfileLogo(payload));
      dispatch(loadSuccess());
    }

    const url = `whitelabel_profile_logo?client=${productName}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setWhitelabelProfileLogo(whitelabelProfileLogo) {
  return {
    type: actionTypes.WHITELABEL_PROFILE,
    whitelabelProfileLogo,
  };
}
