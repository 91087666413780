export function filterQuickSendTemplates (templates) {
  return templates.filter(template => template.template_type === 'quick_send')
}

export function filterHtmlTemplates (templates) {
  return templates.filter(template => template.template_type === 'html' &&
  template.system_template_name !== 'QuickEmail' && (template.compliance && template.compliance.is_valid))
}

export function mergeTagsForSelect (mergeTags) {
  return mergeTags.map((mergeTag) => {
    return {
      id: mergeTag.value,
      title: mergeTag.name
    }
  })
}
