export function titleForType(type) {
  switch (type) {
    case "no_answer":
      return "Called but couldn't get through";
    case "left_message":
      return "Called and left message";
    case "booked_appointment":
      return "Called and booked an appointment";
    case "not_interested":
      return "Called but they are not interested";
    case "still_thinking":
      return "Called and they are still thinking";
    case "other_success":
      return "Called and moved the sale along";
    case "signed_up":
      return "Called and signed them up";
    case "started_trial":
      return "Called and started them on a trial";
    case "other":
      return "Called";
    default:
      return "Called";
  }
}

export function optionTitle(type) {
  switch (type) {
    case "no_answer":
      return "No answer";
    case "left_message":
      return "Left a message";
    case "not_interested":
      return "Not interested";
    case "booked_appointment":
      return "Booked an appointment";
    case "still_thinking":
      return "Said they are still thinking";
    case "other":
      return "Something else";
    case "signed_up":
      return "They signed up";
    case "started_trial":
      return "Started a trial";
    default:
      return "Called";
  }
}

export function optionIcon(type) {
  switch (type) {
    case "no_answer":
      return "si si-call-end";
    case "left_message":
      return "si si-note";
    case "not_interested":
      return "si si-close";
    case "booked_appointment":
      return "si si-calendar";
    case "still_thinking":
      return "si si-directions";
    case "other":
      return "si si-question";
    case "signed_up":
      return "si si-check";
    case "started_trial":
      return "si si-clock";
    default:
      return "";
  }
}

export const allTypes = [
  "no_answer",
  "left_message",
  "not_interested",
  "booked_appointment",
  "still_thinking",
  "other",
  "started_trial",
  "signed_up",
];

export const NO_ANSWER = "no_answer";
export const LEFT_MESSAGE = "left_message";
export const BOOKED_APPOINTMENT = "booked_appointment";
export const STILL_THINKING = "still_thinking";
export const OTHER = "other";
export const SIGNED_UP = "signed_up";
export const STARTED_TRIAL = "started_trial";
