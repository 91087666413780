import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'

import SalesFunnelForm from './SalesFunnelForm'

export class SalesFunnelView extends Component {

  UNSAFE_componentWillMount () {
    const { salesFunnel, prefillFormForSalesFunnelSteps } = this.props
    prefillFormForSalesFunnelSteps(salesFunnel.steps)
  }

  saveClicked = () => {
    const { updateSalesFunnelWithData, salesFunnel, data } = this.props
    updateSalesFunnelWithData(salesFunnel, data)
  }

  render () {
    const { loading, data, errors, updateData } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Your Sales Funnel</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <SalesFunnelForm
                data={data}
                onSaveSelected={this.saveClicked}
                updateData={updateData} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

SalesFunnelView.propTypes = {
  updateSalesFunnelWithData: PropTypes.func.isRequired,
  prefillFormForSalesFunnelSteps: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  salesFunnel: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object
}
