import { NAME } from './constants'

export const getLoading = state => state[NAME].loading

export const getFilterData = state => state[NAME].data

export const getReport = state => state[NAME].report
export const getCSVDownloadLink = state => state[NAME].report.csv_report_url

export const getReportSummaryData = state => getReport(state).summary_data
export const getReportData = state => getReport(state).data
