import { request, GET, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import billing from 'modules/Stores/Billing';
import notify from 'modules/Notifications';

export const getSubscriptionDetails = () => {
  return (dispatch) => {
    dispatch(startUpdating());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(updateBillingPlan(payload.billing_plan));
      dispatch(loadSuccessful());
    }

    return request('current_billing_plan', GET, null, onSuccess, onFailure);
  };
};

export const deletePaymentMethodId = (paymentMethodId) => {
  return (dispatch) => {
    dispatch(startUpdating());
    function onFailure(payload) {
      dispatch(loadFailed(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(billing.actions.removePaymentMethodId(paymentMethodId));
      dispatch(loadSuccessful());
      notify.success('Payment Method Deleted');
    }

    return request('paychoice_payment_methods/' + paymentMethodId, DELETE, null, onSuccess, onFailure);
  };
};

function updateBillingPlan(billingPlan) {
  return {
    type: actionTypes.BILLING_PLAN_UPDATED,
    billingPlan,
  };
}

function startUpdating() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccessful() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}
