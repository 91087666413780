import * as actionTypes from './actionTypes'

export function selectLocation (selectedLocation) {
  return {
    type: actionTypes.SET_SELECTED_LOCATION,
    selectedLocation
  }
}

export function clearLocation () {
  return {
    type: actionTypes.SET_SELECTED_LOCATION,
    selectLocation: null
  }
}
