import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ClassesView from '../components/NewAppointmentModal/ClassAppointmentModal/ClassesView'

import session from 'modules/Stores/Session'
import classes from 'modules/Stores/Classes'

function mapStateToProps (state, ownProps) {
  return {
    timezone: session.selectors.getUserTimezone(state),
    loading: classes.selectors.getLoading(state),
    classes: classes.selectors.getClasses(state),
    errors: classes.selectors.getErrors(state),
    cached: classes.selectors.getCached(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getClassesForIntegration: classes.actions.getClassesForIntegration,
    setClasses: classes.actions.setClasses
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassesView)
