import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const UPDATE_DATA = NAME + '/UPDATE_DATA'
export const PREFILL_FORM_DATA = NAME + '/PREFILL_FORM_DATA'

// Set User Container
export const SET_POTENTIAL_USERS = NAME + '/SET_POTENTIAL_USERS'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'