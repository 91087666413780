import * as actionTypes from './actionTypes'

import selectLocation from './modules/selectLocation'
import selectUsers from './modules/selectUsers'
import confirm from './modules/confirm'

export function incrementTabIndex () {
  return {
    type: actionTypes.INCREMENT_TAB_INDEX
  }
}

export function decrementTabIndex () {
  return {
    type: actionTypes.DECREMENT_TAB_INDEX
  }
}

function resetTabIndex () {
  return {
    type: actionTypes.RESET
  }
}

export function reset () {
  return dispatch => {
    dispatch(resetTabIndex())
    dispatch(selectLocation.actions.reset())
    dispatch(selectUsers.actions.reset())
    dispatch(confirm.actions.reset())
  }
}
