import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// Fetch Notes
export const fetchForLead = (lead, pageNumber = 1) => {
  return (dispatch) => {
    dispatch(fetching())

    function onFailure (errors) {
      dispatch(fetchFailure(errors))
    }

    function onSuccess (payload) {
      dispatch(loadPageDetails(payload.meta))
      dispatch(fetchSuccess(payload.lead_emails))
    }

    const url = 'leads/' + lead.id + '/lead_emails?page[number]=' + pageNumber
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function fetching () {
  return {
    type: actionTypes.FETCHING
  }
}

function fetchSuccess (emails) {
  return {
    type: actionTypes.FETCH_SUCCESS,
    emails
  }
}

function fetchFailure (errors) {
  return {
    type: actionTypes.FETCH_FAILURE,
    errors
  }
}

export function add (email) {
  return {
    type: actionTypes.ADD,
    email
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.EMAILS_PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function setShowHistoryEmailId (showHistoryEmailId) {
  return {
    type: actionTypes.SET_SHOW_HISTORY_EMAIL_ID,
    showHistoryEmailId
  }
}
