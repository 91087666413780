import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import modal from 'modules/Stores/Modal';
import leadLostReasons from '../modules/leadLostReasons';

import leadLostReasonStore from 'modules/Stores/LeadLostReasons';
import * as leadLostReasonsApiActions from 'modules/Stores/LeadLostReasons/apiActions';
import session from 'modules/Stores/Session';

function mapStateToProps(state) {
  const permissionSet = session.selectors.getUserPermissions(state);
  return {
    leadLostReasons: leadLostReasonStore.selectors.getLeadLostReasons(state),
    loading: leadLostReasonStore.selectors.getLoading(state),
    pageDetails: leadLostReasonStore.selectors.getPageDetails(state),
    canEdit: permissionSet.allow_lead_settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadLostReasons: leadLostReasonsApiActions.getLeadLostReasons,
      createLeadLostReason: leadLostReasons.actions.create,
      deleteLeadLostReason: leadLostReasons.actions.deleteLeadLostReason,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(leadLostReasons.components.LeadLostReasons);
