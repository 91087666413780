import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SalesTargets from '../../modules/SalesTargets'
import Session from 'modules/Stores/Session'
import locationSelect from '../../modules/LocationSelect'

function mapStateToProps (state) {
  const salesTargetType = SalesTargets.constants.LOCATION_TYPE
  return {
    salesTargetResults: SalesTargets.selectors.getSalesTargetResults(state, salesTargetType),
    location: locationSelect.selectors.getSelectedLocation(state),
    timezone: Session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetch: SalesTargets.actions.fetchSalesTargetResultsForLocationId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTargets.components.SalesTargetsView)
