export const APPOINTMENT_CREATED_TOOLTOP = 'Booking classes will create appointments in GymLeads.';

export const APPOINTED_UPDATED_TOOLTIP = 'Signing clients in will be updated as ‘Other’.';

export const APPOINTMENT_CANCELLED_TOOLTIP = 'All cancellations will be marked as a ‘No show’.';

export const LEAD_MEMBERSHIP_ASSIGNED_TOOLTIP = 'Adding memberships will close the lead with the same deal.';

export const COLLECT_VISITS_TOOLTIP = "Visit counts will now display in the lead's profile.";

export const MEMBERSHIP_CANCELLATION_TOOLTIP = 'Member cancellations will mark the lead as ‘Lost’.';

export const EXPORT_TOOLTIP = 'Export a closed lead to MINDBODY as a new member';

export const SYNCLEAD_TOOLTIP = 'Keep a lead synced with MINDBODY as a member throughout the whole lifecycle';

export const APPOINTMENTS_TOOLTIP = 'When scheduling a new appointment, choose from a scheduled class in MINDBODY';

export const STAFF_TOOLTIP = 'Keep sales team members in sync with MINDBODY.';

export const TRIAL_MEMBERSHIP_MAPPING_TOOLTIP = 'Mapp Gymleads trials to MINDBODY memberships';

export const LEAD_IS_PROSPECT = 'When updating a lead in MINDBODY, mark them as a prospect';

export const IGNORE_HOME_LOCATION =
  'When capturing leads from MINDOBDY, ignore the home location ID (This solves issues for leads not coming into GymLeads for some users)';

export const MANUAL_LEAD_IMPORT_PROSPECT_CHECK = 'Tick this to only import clients that are marked as a prospect in MINDBODY.';

export const MANUAL_LEAD_IMPORT = 'Tick this to import new clients from MINDBODY as leads in GymLeads';

export const CONTACT_LOGSYNC = 'Tick this to keep contacts and notes in sync.';

export const CLASSES_REQUIRE_BOOKING = 'Tick if your club requires a booking for class attendance';

export const SYNC_LEAD_FROM_CLASS_EVENT = "If we recieve a class booking for a lead not in our system, we'll try and import them.";

export const UPDATE_LEAD_FROM_INTEGRATION = "If the lead updates their details in your member management system, we'll update them here.";
