import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getTextMessageChargesForSmsAccountAndPage = (smsAcount, pageNumber) => {
  return dispatch => {
    dispatch(startUpdating())
    function onFailure (errors) {
      dispatch(loadFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(loadSuccessful(payload.text_message_charges))
      dispatch(loadPageDetails(payload.meta))
    }

    dispatch(loadSuccessful([]))
    if (pageNumber) {
      const pageNumberQuery = '?page[number]=' + pageNumber
      return request('sms_accounts/' + smsAcount.id + '/text_message_charges/' + pageNumberQuery,
      GET, null, onSuccess, onFailure)
    } else {
      return request('sms_accounts/' + smsAcount.id + '/text_message_charges/', GET, null, onSuccess, onFailure)
    }
  }
}

function startUpdating () {
  return {
    type: actionTypes.LOADING
  }
}

function loadSuccessful (charges) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    charges
  }
}

function loadFailed (errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
