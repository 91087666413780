import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'
import { GymDetailsFormValidator } from '../validators'

import EditCompanyLogoContainer from '../containers/EditCompanyLogoContainer'

const { TextField } = FormFields

export class CompanyForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  render () {
    const { title, userDisplayName, url } = this.props.data.fields
    return (
      <div>
        <div className='row'>
          <div className='col-sm-3 text-center'>
            <EditCompanyLogoContainer />
          </div>
          {title.value &&
            <div className='col-sm-9'>
              <div className='col-md-12'>
                <TextField name='title'
                  label='Company Title'
                  placeholder='What is the name of your club?'
                  onChange={this.handleInput}
                  validator={GymDetailsFormValidator}
                  initialValue={title.value} />
              </div>
              <div className='col-md-12'>
                <TextField name='userDisplayName'
                  label='Brand name shown to leads'
                  placeholder={'Leave blank to use the title'}
                  onChange={this.handleInput}
                  initialValue={userDisplayName.value} />
              </div>
              <div className='col-md-12'>
                <TextField name='url'
                  label='Company Website'
                  placeholder='www.example.com'
                  onChange={this.handleInput}
                  validator={GymDetailsFormValidator}
                  initialValue={url.value} />
              </div>
              <div className='col-sm-9'>
                <button className='btn btn-primary push-5-r push-10'
                  type='button'
                  onClick={this.props.onSaveSelected}>
                  <i className='fa fa-institution' /> Update Company
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

CompanyForm.propTypes = {
  onSaveSelected: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default CompanyForm
