import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import modal from 'modules/Stores/Modal';

import { ContentBlockView, GymLeadsLoader } from 'modules/components';
import { ISubscriptionAddOn, SubscriptionAddOnTemplate } from 'types';
import {
  getSubscriptionAddOns,
  createSubscriptionForAllLocations,
  createSubscriptionForSpecificLocations,
} from 'modules/api/subscriptionAddOn';
import { EmptyView } from './EmptyView';
import { ManageSubscriptionsView } from './ManageSubscriptionsView';
import notify from 'modules/Notifications';

import { SELECT_LOCATIONS_MODAL } from './constants';
import MultiLocationSelect, { LocationIdsStructure } from 'modules/components/MultiLocationSelect';

interface WelcomeViewProps {
  showLocationSelect: () => void;
}

const WelcomeView: FC<WelcomeViewProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [subscriptionAddOns, setSubscriptionAddOns] = useState<ISubscriptionAddOn[]>([]);

  const { showLocationSelect } = props;

  useEffect(() => {
    fetchSubscriptionAddOns(1);
  }, []);

  const fetchSubscriptionAddOns = async (pageNumber: number) => {
    setLoading(true);
    const response = await getSubscriptionAddOns(SubscriptionAddOnTemplate.FrontDesk, pageNumber);
    setSubscriptionAddOns(response.subscription_add_ons);
    setLoading(false);
  };

  const locationsSelected = async (locationIdsData: LocationIdsStructure, fieldName: string, locationIds: number[]) => {
    setLoading(true);
    await createSubscriptionForSpecificLocations(SubscriptionAddOnTemplate.FrontDesk, locationIds);
    notify.success('Front Desk setup for locations');
    fetchSubscriptionAddOns(1);
  };

  const handleAllLocationsClicked = async () => {
    await createSubscriptionForAllLocations(SubscriptionAddOnTemplate.FrontDesk);
    fetchSubscriptionAddOns(1);
  };

  const isEmpty = subscriptionAddOns.length === 0;

  return (
    <ContentBlockView title="Manage subscriptions" bordered loading={loading}>
      <GymLeadsLoader active={loading} />
      {isEmpty && <EmptyView onSpecificLocationsClicked={showLocationSelect} onAllLocationsClicked={handleAllLocationsClicked} />}
      {!isEmpty && <ManageSubscriptionsView />}

      <MultiLocationSelect.MultiLocationSelectModalContainer
        modalName={SELECT_LOCATIONS_MODAL}
        sectionName={'Select locations to add Front Desk to'}
        onUpdate={locationsSelected}
        buttonTitle="Add Front Desk to locations"
        data={{ fields: {}, isValid: true }}
        showAllLocationsButton={false}
      />
    </ContentBlockView>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      showLocationSelect: () => modal.actions.showModal(SELECT_LOCATIONS_MODAL),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeView);
