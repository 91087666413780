import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const createLeadReallocation = (locationId, fromUserId, toUserIds) => {
  return dispatch => {
    const body = {
      bulk_lead_reallocation: {
        lead_donator_id: fromUserId,
        location_id: locationId,
        user_ids: toUserIds
      }
    }

    function onFailure (payload) {
      dispatch(setSuccess(false))
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (data) {
      dispatch(setLoading(false))
      dispatch(setSuccess(true))
    }

    return request('bulk_lead_reallocations/', POST, body, onSuccess, onFailure)
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setSuccess (success) {
  return {
    type: actionTypes.SET_SUCCESS,
    success
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}
