import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import structure from '../../modules/Structure'

const mapStateToProps = (state, ownProps) => {
  return {
    currentType: structure.selectors.getDashoardType(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setDashboardType: structure.actions.setDashboardType
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(structure.components.SetDashboardTypeButton)
