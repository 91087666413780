/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap-modal';

import { EmptyView, TextSearchView, PagesView, GymLeadsLoader } from 'modules/components';

export const SelectSMSTemplateView = (props) => {
  const [search, setSearch] = useState(undefined);

  const { getTextMessageTemplates, hideModal, textMessageTemplates, onTemplateSelect, pageDetails, loading, goBackView } = props;

  useEffect(() => {
    getTextMessageTemplates(1);
  }, [getTextMessageTemplates]);

  const updateSearch = (newSearchTerm) => {
    setSearch(newSearchTerm);
    getTextMessageTemplates(1, newSearchTerm);
  };

  const handlePageClicked = (newPageNumber) => {
    getTextMessageTemplates(newPageNumber, search);
  };

  const pageCount = pageDetails.pageCount;

  const rows = textMessageTemplates.map((template) => {
    return <SMSTemplateRow textMessageTemplate={template} key={template.id} onSelect={onTemplateSelect} />;
  });

  return (
    <div style={{ marginLeft: '12px', marginRight: '12px' }}>
      <div className="row">
        <div className="col-xs-12">
          <TextSearchView sectionName={'textMessageTemplateSearch'} onUpdate={updateSearch} />
        </div>
      </div>
      <div className="list-group selectable push">
        <GymLeadsLoader active={loading} text={'Getting SMS Templates'} />
        {!loading && rows}
        {!loading && textMessageTemplates.length === 0 && (
          <EmptyView colorThemeClass="primary" iconClass="fa fa-mobile" title="No SMS Templates" />
        )}
      </div>
      {pageCount > 1 && (
        <div className="text-center push">
          <PagesView pageClicked={handlePageClicked} pageDetails={props.pageDetails} />
        </div>
      )}
      <Modal.Footer>
        <button className="btn btn-primary push-10 pull-left" type="button" onClick={() => props.setSelectedView(goBackView)}>
          <i className="fa fa-angle-left" /> Back
        </button>
        <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
          Cancel
        </button>
      </Modal.Footer>
    </div>
  );
};

SelectSMSTemplateView.defaultProps = {
  templates: [],
};

export default SelectSMSTemplateView;

const SMSTemplateRow = (props) => {
  const { textMessageTemplate, onSelect } = props;
  const rowSelected = () => {
    onSelect(textMessageTemplate);
  };

  return (
    <a onClick={rowSelected} className="list-group-item selectable">
      <span>{textMessageTemplate.title}</span>
    </a>
  );
};
