import * as actionTypes from './actionTypes';
import { request, GET, POST, PUT, DELETE } from 'utils/apiUtils';
import * as listingActions from '../../../listing/actions';

import modal from 'modules/Stores/Modal';
import notify from 'modules/Notifications';

export const fetchFacebookPages = (accessToken, searchTerm) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.showErrors(errors));
      dispatch(modal.actions.stopLoading());
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      if (payload.facebook_pages) {
        dispatch(setFacebookPages(payload.facebook_pages));
      }
    }

    let url = `facebook_pages/fetch_pages?access_token=${accessToken}`;
    if (searchTerm) {
      url = `${url}&search=${searchTerm}`;
    }

    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const createFacebookIntegration = (location, facebookPage, toTabIndex) => {
  return (dispatch) => {
    const body = {
      third_party_integration: {
        name: 'facebook',
        direction: 'incoming',
        username: facebookPage.id,
        company_name: facebookPage.name,
        api_key: facebookPage.access_token,
      },
    };
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(setIntegration(payload.third_party_integration));
      dispatch(listingActions.addIntegration(payload.third_party_integration));
      dispatch(modal.actions.stopLoading());
      dispatch(setPermissionSet(payload.third_party_integration.integration_permission_set));
      dispatch(setTabIndex(toTabIndex));
    }

    return request('locations/' + location.id + '/third_party_integrations', POST, body, onSuccess, onFailure);
  };
};

export const deleteIntegration = (integration, location) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      dispatch(listingActions.deleteIntegration(integration.id));
      dispatch(reset());
      notify.success('Integration Removed');
    }

    let path = 'locations/' + location.id + '/third_party_integrations/' + integration.id;
    return request(path, DELETE, null, onSuccess, onFailure);
  };
};

function setIntegration(integration) {
  return {
    type: actionTypes.SET_INTEGRATION,
    integration,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setTabIndex(tabIndex) {
  return {
    type: actionTypes.SET_TAB_INDEX,
    tabIndex,
  };
}

export function setFacebookUser(facebookUser) {
  return {
    type: actionTypes.SET_FACEBOOK_USER,
    facebookUser,
  };
}

export function setFacebookPages(facebookPages) {
  return {
    type: actionTypes.SET_FACEBOOK_PAGES,
    facebookPages,
  };
}

export function setSelectedPageId(selectedPageId) {
  return {
    type: actionTypes.SET_SELECTED_PAGE_ID,
    selectedPageId,
  };
}

export function prefillFormForIntegration(integration) {
  return {
    type: actionTypes.PREFIL_FORM,
    integration,
  };
}

export function setVerified(verified) {
  return {
    type: actionTypes.SET_VERIFIED,
    verified,
  };
}

// Row actions
export const createFacebookPageForToken = (integrationId, shortTermAccessToken, facebookPageId, pageName) => {
  return (dispatch) => {
    const body = {
      third_party_integration_id: integrationId,
      short_term_access_token: shortTermAccessToken,
      facebook_page_id: facebookPageId,
      facebook_page_name: pageName,
    };

    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(addLinkedPage(payload.third_party_integrations_facebook_page));
      dispatch(setTabIndex(2));
    }

    return request('facebook_pages', POST, body, onSuccess, onFailure);
  };
};

export const updateFacebookPageIntegration = (id, updateBody) => {
  return (dispatch) => {
    const body = {
      third_party_integrations_facebook_page: updateBody,
    };
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(updateLinkedPage(payload.third_party_integrations_facebook_page));
    }

    return request('third_party_integrations_facebook_pages/' + id, PUT, body, onSuccess, onFailure);
  };
};

export const deleteFacebookPageIntegration = (id) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(removeLinkedPageWithId(id));
    }

    return request('third_party_integrations_facebook_pages/' + id, DELETE, null, onSuccess, onFailure);
  };
};

function addLinkedPage(linkedPage) {
  return {
    type: actionTypes.ADD_LINKED_FACEBOOK_PAGE,
    linkedPage,
  };
}

function removeLinkedPageWithId(linkedPageId) {
  return {
    type: actionTypes.DELETE_LINKED_FACEBOOK_PAGE,
    linkedPageId,
  };
}

function updateLinkedPage(linkedPage) {
  return {
    type: actionTypes.UPDATE_LINKED_FACEBOOK_PAGE,
    linkedPage,
  };
}

export const setPermissionForSet = (locationId, integrationId, permissionSetId, field, value) => {
  return (dispatch) => {
    const body = {
      integration_permission_set: {
        [field]: value,
      },
    };

    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setPermissionSet(payload.integration_permission_set));
    }
    const url =
      'locations/' + locationId + '/third_party_integrations/' + integrationId + '/integration_permission_sets/' + permissionSetId;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setPermissionSet(permissionSet) {
  return {
    type: actionTypes.SET_PERMISSION_SET,
    permissionSet,
  };
}
