import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import { humanReadableStatus, humanReadableNoSendReason } from "../helpers";

export class TextMessageItem extends Component {
  render() {
    const { textMessage, lead } = this.props;
    const createdAtMoment = moment(textMessage.created_at).fromNow();
    const fromSystem =
      textMessage.text_message_message_direction === "from_system" ||
      textMessage.text_message_message_direction === "to_user";
    return (
      <div className="block block-transparent pull-r-l">
        {fromSystem && (
          <div>
            <div className="block-header bg-gray-lighter">
              <ul className="block-options">
                <li>
                  <span>
                    <em className="text-muted">{createdAtMoment}</em>
                  </span>
                </li>
              </ul>
              {textMessage.text_message_user && (
                <h3 className="block-title">
                  {textMessage.text_message_user.given_name}
                </h3>
              )}
              {!textMessage.text_message_user && (
                <h3 className="block-title">Deleted User</h3>
              )}
            </div>
            <div className="block-content">
              <div style={{ paddingBottom: "6px" }}>
                <span className="font-w600">Status:</span>{" "}
                {humanReadableStatus(textMessage.text_message_status)}
                {textMessage.no_send_reason && (
                  <div>
                    <span className="font-w600">Because: </span>
                    {humanReadableNoSendReason(textMessage.no_send_reason)}
                  </div>
                )}
              </div>
              <p className="font-s13">{textMessage.text_message_body}</p>
            </div>
          </div>
        )}
        {textMessage.text_message_message_direction === "from_lead" && (
          <div>
            <div className="block-header bg-gray-lighter">
              <ul className="block-options">
                <li>
                  <span>
                    <em className="text-muted">{createdAtMoment}</em>
                  </span>
                </li>
              </ul>
              <h3 className="block-title">{lead.given_name} (Lead)</h3>
            </div>
            <div className="block-content">
              <p className="font-s13">{textMessage.text_message_body}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TextMessageItem.propTypes = {
  textMessage: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
};
