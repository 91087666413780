import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

import { formDataFromData } from 'utils/formHelpers';
import { fieldNames } from './constants';
import securityPolicy from 'modules/Stores/SecurityPolicy';

export const saveSecurityPolicy = (securityPolicyId, formData) => {
  return (dispatch) => {
    const body = {
      security_policy: formDataFromData(formData, fieldNames, false),
    };

    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(null));
      notify.success('Security Policy Updated. Changes will take affect in the next session.');
      dispatch(securityPolicy.actions.setSecurityPolicy(payload.security_policy));
    }

    return request('security_policies/' + securityPolicyId, PUT, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function prefilFormData(securityPolicy) {
  return {
    type: actionTypes.PREFILL_FORM_DATA,
    securityPolicy,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
