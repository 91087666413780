import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getProductName } from 'modules/Stores/UserData/selectors'

import modal from 'modules/Stores/Modal'
import FormView from '../components/EnterClubId/FormView'

import * as mindbodyActions from '../actions'
import * as mindbodySelectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    data: mindbodySelectors.getData(state),
    tabIndex: mindbodySelectors.getTabIndex(state),
    productName: getProductName(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: mindbodyActions.onInput
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
