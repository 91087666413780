import * as actionTypes from './actionTypes';

const USER_DATA_ACTION_HANDLERS = {
  [actionTypes.RESET]: () => {
    return initialState;
  },
  [actionTypes.LOADING_STARTED]: (state) => {
    return { ...state, loading: true };
  },
  [actionTypes.LOADING_STOPPED]: (state) => {
    return { ...state, loading: false };
  },
  [actionTypes.COMPANY_DATA_FETCH_SUCESS]: (state, action) => {
    return { ...state, company: action.company };
  },
  [actionTypes.SET_COUNTRIES]: (state, action) => {
    return { ...state, countries: action.countries };
  },
  [actionTypes.SALES_FUNNEL_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      salesFunnel: salesFunnelWithSortedSteps(action.salesFunnel),
    };
  },
  [actionTypes.USER_ROLES_FETCH_SUCCESS]: (state, action) => {
    return { ...state, userRoles: action.userRoles };
  },
  [actionTypes.FETCH_LOCATION_METRICS_SUCCESS]: (state, action) => {
    return { ...state, locationMetrics: action.metrics, loading: false };
  },
  [actionTypes.FETCH_LOCATION_METRICS_FAILED]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true };
  },
  [actionTypes.SET_SALES_TARGETS]: (state, action) => {
    const salesTargets = action.salesTargets;

    const company = { ...state.company, default_target_rule_set: salesTargets };
    return { ...state, company: company };
  },
  [actionTypes.SET_REGIONS]: (state, action) => {
    return { ...state, regions: action.regions };
  },
  [actionTypes.ADD_REGION]: (state, action) => {
    const oldRegions = state.regions;
    const newRegions = [action.region, ...oldRegions];
    return { ...state, regions: newRegions };
  },
  [actionTypes.UPDATE_REGION]: (state, action) => {
    const oldRegions = state.regions;
    const updatedRegion = action.region;
    const index = indexForRegion(oldRegions, updatedRegion.id);
    const newRegions = [...oldRegions.slice(0, index), updatedRegion, ...oldRegions.slice(index + 1)];
    return { ...state, regions: newRegions };
  },
  [actionTypes.REMOVE_REGION]: (state, action) => {
    const oldRegions = state.regions;
    const removedRegionId = action.regionId;
    const index = indexForRegion(oldRegions, removedRegionId);
    const newRegions = [...oldRegions.slice(0, index), ...oldRegions.slice(index + 1)];
    return { ...state, regions: newRegions };
  },
  [actionTypes.SET_SCHEDULES]: (state, action) => {
    return { ...state, schedules: action.schedules };
  },
  [actionTypes.ADD_SCHEDULE]: (state, action) => {
    const oldSchedules = state.schedules;
    const newSchedules = [action.schedule, ...oldSchedules];
    return { ...state, schedules: newSchedules };
  },
  [actionTypes.UPDATE_SCHEDULE]: (state, action) => {
    const oldSchedules = state.schedules;
    const updatedSchedule = action.schedule;
    const index = indexForSchedule(oldSchedules, updatedSchedule.id);
    const newSchedules = [...oldSchedules.slice(0, index), updatedSchedule, ...oldSchedules.slice(index + 1)];
    return { ...state, schedules: newSchedules };
  },
  [actionTypes.REMOVE_SCHEDULE]: (state, action) => {
    const oldSchedules = state.schedules;
    const removedScheduleId = action.scheduleId;
    const index = indexForSchedule(oldSchedules, removedScheduleId);
    const newSchedules = [...oldSchedules.slice(0, index), ...oldSchedules.slice(index + 1)];
    return { ...state, schedules: newSchedules };
  },
  [actionTypes.ADD_THIRD_PARTY_INTEGRATION]: (state, action) => {
    const oldLocations = state.locations;
    const locationIndex = indexForLocation(oldLocations, action.locationId);
    const oldLocation = oldLocations[locationIndex];
    const oldThirdPartyIntegrations = oldLocation.third_party_integrations;
    const newThirdPartyIntegrations = [...oldThirdPartyIntegrations, action.thirdPartyIntegration];
    const newLocation = {
      ...oldLocation,
      third_party_integrations: newThirdPartyIntegrations,
    };
    const newLocations = [...oldLocations.slice(0, locationIndex), newLocation, ...oldLocations.slice(locationIndex + 1)];
    return { ...state, locations: newLocations };
  },
  [actionTypes.DELETE_THIRD_PARTY_INTEGRATION_WITH_ID]: (state, action) => {
    const oldLocations = state.locations;
    const locationIndex = indexForLocation(oldLocations, action.locationId);
    const oldLocation = oldLocations[locationIndex];
    const oldThirdPartyIntegrations = oldLocation.third_party_integrations;
    const index = oldThirdPartyIntegrations.findIndex((int) => parseInt(int.id) === parseInt(action.thirdPartyIntegrationId));
    const newThirdPartyIntegrations = [...oldThirdPartyIntegrations.slice(0, index), ...oldThirdPartyIntegrations.slice(index + 1)];
    const newLocation = {
      ...oldLocation,
      third_party_integrations: newThirdPartyIntegrations,
    };
    const newLocations = [...oldLocations.slice(0, locationIndex), newLocation, ...oldLocations.slice(locationIndex + 1)];
    return { ...state, locations: newLocations };
  },
  [actionTypes.UPDATE_THIRD_PARTY_INTEGRATION]: (state, action) => {
    const oldLocations = state.locations;
    const locationIndex = indexForLocation(oldLocations, action.locationId);
    const oldLocation = oldLocations[locationIndex];
    const oldThirdPartyIntegrations = oldLocation.third_party_integrations;
    const index = oldThirdPartyIntegrations.findIndex((int) => int.id === action.thirdPartyIntegration.id);
    const newThirdPartyIntegrations = [
      ...oldThirdPartyIntegrations.slice(0, index),
      action.thirdPartyIntegration,
      ...oldThirdPartyIntegrations.slice(index + 1),
    ];
    const newLocation = {
      ...oldLocation,
      third_party_integrations: newThirdPartyIntegrations,
    };
    const newLocations = [...oldLocations.slice(0, locationIndex), newLocation, ...oldLocations.slice(locationIndex + 1)];
    return { ...state, locations: newLocations };
  },
};

function indexForLocation(locations, locationId) {
  return locations.findIndex((location) => location.id === locationId);
}

function indexForRegion(regions, regionId) {
  return regions.findIndex((region) => region.id === regionId);
}

function indexForSchedule(schedules, scheduleId) {
  return schedules.findIndex((schedule) => schedule.id === scheduleId);
}

const initialState = {
  loading: false,
  countries: [],
  currentLocationId: null,
  regions: [],
  company: {},
  salesFunnel: {},
  userRoles: [],
  locationMetrics: {},
  schedules: [],
};

export default function reducer(state = initialState, action) {
  const handler = USER_DATA_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

function salesFunnelWithSortedSteps(salesFunnelSteps) {
  return {
    ...salesFunnelSteps,
    steps: salesFunnelSteps.steps.sort(function (a, b) {
      return a.step_number - b.step_number;
    }),
  };
}
