import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { history } from 'store/store';

import { EmptyView } from 'modules/components';
import { UserAvatar } from 'modules/components/Avatars';

import moment from 'moment';
import 'moment-timezone';

export class TeamMetricsView extends Component {
  inviteTeamMembersClicked = () => {
    history.push('/Settings/salesTeams');
  };

  UNSAFE_componentWillMount() {
    const { fetchSalesTeamForLocation, location, timezone } = this.props;

    const startDate = moment().tz(timezone).startOf('month').utc().format();
    const endDate = moment().tz(timezone).endOf('month').utc().format();

    fetchSalesTeamForLocation(location, startDate, endDate);
  }

  componentWillUnmount() {
    this.props.selectUserId(null);
  }

  render() {
    const { salesTeam, user, salesFunnelSteps, timezone } = this.props;
    const salesTeamView = salesTeam.map((salesPerson, index) => {
      const salesPersonUser = salesPerson.user;
      const leadCounts = salesPerson.sales_metrics.lead_counts;
      const closeRate = salesPerson.sales_metrics.close_rate;
      const isCurrentuser = salesPersonUser.id === user.id;
      const lastRowStyle = isCurrentuser ? 'ribbon ribbon-modern ribbon-primary' : '';
      const isSelected = parseInt(this.props.selectedUserId) === parseInt(salesPersonUser.id);
      const rowStyle = isSelected ? { backgroundColor: '#f5f5f5' } : {};

      const { given_name: givenName, surname } = salesPerson.user;
      let userNameToDisplay = givenName;
      if (surname) userNameToDisplay = `${givenName} ${surname}`;

      return (
        <tr
          className="selectable"
          key={salesPersonUser.id}
          id={index}
          onClick={() => this.props.selectUserId(salesPersonUser.id)}
          style={rowStyle}
        >
          <td className="text-center">{parseInt(index + 1)}</td>
          <td id={index} className="text-center">
            <UserAvatar avatarImage={salesPerson.user.user_avatar} className="img-avatar48" />
          </td>
          <td id={index} className="font-w600">
            {userNameToDisplay}
          </td>
          {salesFunnelSteps.map(function (step, index) {
            return (
              <td key={salesPersonUser.id + '-' + index} id={index} className="font-w600">
                {leadCounts && leadCounts[step.id]}
                {!leadCounts && '0'}
              </td>
            );
          })}
          <td id={index} className={'font-w600 ' + lastRowStyle}>
            {isCurrentuser && <div className="ribbon-box font-w200">You</div>}
            {closeRate.toFixed(2)}%
          </td>
        </tr>
      );
    });

    const teamHeaderView = () => (
      <thead>
        <tr>
          <th className="text-center" style={{ width: '12px' }}>
            #
          </th>
          <th className="text-center" style={{ width: '12px' }}>
            <i className="si si-user" />
          </th>
          <th>Name</th>
          {salesFunnelSteps &&
            salesFunnelSteps.map(function (step, index) {
              return (
                <th key={index} style={{ width: '15%' }}>
                  {step.name}
                </th>
              );
            })}
          <th>Close Rate</th>
        </tr>
      </thead>
    );

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <h3 className="block-title">Team Metrics For {moment().tz(timezone).format('MMMM')}</h3>
        </div>
        <div className="block-content block-content-full">
          {salesFunnelSteps && salesTeam.length > 0 && (
            <div className="table-responsive">
              <table className="table table-vcenter table-hover">
                {teamHeaderView()}
                <tbody>{salesTeamView}</tbody>
              </table>
            </div>
          )}
          {salesTeam.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-user-unfollow"
              title="You have no team members yet"
              subtitle={'You should set up your team to make the most of ' + this.props.productName + '.'}
              buttonText="Invite Team Members"
              buttonSelected={this.inviteTeamMembersClicked}
            />
          )}
        </div>
      </div>
    );
  }
}

TeamMetricsView.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object),
  salesTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchSalesTeamForLocation: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectUserId: PropTypes.func.isRequired,
  selectedUserId: PropTypes.number,
  timezone: PropTypes.string.isRequired,
};

export default TeamMetricsView;
