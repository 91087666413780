export enum Gender {
  Male = 'male',
  Female = 'Female',
}

export enum LeadStatus {
  unassigned = 'not_assigned',
  active = 'active',
  inactive = 'inactive',
  lost = 'lost',
  closed = 'closed',
}

export enum VisitTypes {
  firstVisit = 'first_visit',
  noSaleReturn = 'no_sale_return',
}

export enum SalesFunnelStep {
  assigned = 1,
  interviewed = 2,
  toured = 3,
  commited = 4,
}

export enum ActivityRecordType {
  Appointment = 'appointment',
  ContactCall = 'contact_call',
  Email = 'email',
  Sms = 'sms',
  FacebookMessage = 'facebook_message',
  Task = 'tasks',
}

export enum UserRole {
  OWNER = 'Owner',
  ADMIN = 'Admin',
  FRANCHISE_OWNER = 'Franchise Owner',
  SALESMANAGER = 'Salesmanager',
  SALESPERSON = 'Salesperson',
  RECEPTION = 'Reception',
}

export enum GymLeadsApp {
  Sales = 'gymleads-sales',
  Settings = 'gymleads-settings',
  Marketing = 'Marketing',
  Signup = 'GymLeads Signup',
}

export enum ReportTypes {
  SALES_PERFORMANCE_REPORT = 'sales_performance',
  TIME_TAKEN_REPORT = 'time_taken',
  LEADS_REPORT = 'leads_report',
  LEAD_ALLOCATION_REPORT = 'lead_allocation',
  LOST_LEADS_REPORT = 'lost_leads',
  DEALS_REPORT = 'deals_report',
  TRIALS_REPORT = 'trials_report',
  ACTIVITY_REPORT_CALLS = 'activity_report_calls',
  ACTIVITY_REPORT_APPOINTMENTS = 'activity_report_appointments',
  ACTIVITY_REPORT_TASKS = 'activity_report_tasks',
}

export enum LeadTextMessageStatus {
  GymleadsPending = 'gymleads_pending',
  Pending = 'pending',
  Delivered = 'delivered',
  Bounced = 'bounced',
  Sent = 'sent',
  NotSent = 'not_sent',
}

export enum CalendarEventType {
  Appointment = 'appointment',
  ContactCall = 'contact call',
  PersonalTask = 'personal task',
  LeadAction = 'lead action',
}

export enum ActivityFeedItemType {
  DataChange = 'data_change',
  Appointment = 'appointment',
  ContactCall = 'contact_call',
  Email = 'email',
  LeadTextMessage = 'lead_text_message',
  Note = 'note',
  Trial = 'trial',
}

export enum EmailTemplateType {
  HTML = 'html',
  QuickSend = 'quick_send',
}

export enum TextMessageNoSendReasons {
  MissingPhoneNumber = 'missing_phone_number',
  SmsBounced = 'sms_bounced',
  OptedOut = 'opted_out',
  NotOptedIn = 'not_opted_in',
  TransactionalOutsideImpliedConsent = 'transactional_outside_implied_consent',
  TransactionalIncorrectStatus = 'transactional_incorrect_status',
  InsufficientBalance = 'insufficient_balance',
}

export enum EmailStatus {
  GymLeadsPending = 'gymleads_pending',
  Pending = 'pending',
  Delivered = 'delivered',
  Dropped = 'dropped',
  HardBounced = 'hard_bounced',
  Opened = 'opened',
  LinkClicked = 'link_clicked',
  Complained = 'complained',
  Bounced = 'bounced',
  Unsubscribed = 'unsubscribed',
}

export enum EmailNoSendReason {
  MissingEmail = 'missing_email',
  EmailBounced = 'email_bounced',
  OptedOut = 'opted_out',
  NotOptedIn = 'not_opted_in',
  TransactionalOutsideImpliedConsent = 'transactional_outside_implied_consent',
  TransactionalIncorrectStatus = 'transactional_incorrect_status',
  BadEmail = 'bad_email',
}

export enum CommunicationType {
  Transactional = 'transactional',
  Marketing = 'marketing',
  FromLead = 'from_lead',
}

export enum AppointmentOutcome {
  SignedUp = 'signed_up',
  WantsToThinkAboutIt = 'wants_to_think_about_it',
  NoShow = 'no_show',
  NotInterested = 'not_interested',
  Other = 'other',
  StartedTrial = 'started_trial',
  Rescheduled = 'rescheduled',
}

export enum ContactCallOutcome {
  NoAnswer = 'no_answer',
  LeftMessage = 'left_message',
  BookedAppointment = 'booked_appointment',
  StillThinking = 'still_thinking',
  Other = 'other',
  NotInterested = 'not_interested',
  SignedUp = 'signed_up',
  StartedTrial = 'started_trial',
}

export enum MailEvent {
  Accept = 'accept',
  Deliver = 'deliver',
  Bounce = 'bounce',
  Dropped = 'dropped',
  Open = 'open',
  Click = 'click',
  Complaint = 'complaint',
  Unsubscribe = 'unsubscribe',
  Filtered = 'filtered',
}

export enum SubscriptionAddOnTemplate {
  MboPremium = 'mbo_premium',
  FrontDesk = 'frontdesk',
}
