import { apiClient } from "utils/apiClient";

export async function deleteIntegration(locationId, thirdPartyIntegrationId) {
  try {
    const response = await apiClient().delete(
      `locations/${locationId}/third_party_integrations/${thirdPartyIntegrationId}`
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response.data.errors);
    throw error;
  }
}

export async function createIntegration(locationId, body) {
  try {
    const response = await apiClient().post(
      `locations/${locationId}/third_party_integrations`,
      body
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response);
    throw error;
  }
}

export async function updateIntegration(
  locationId,
  thirdPartyIntegrationId,
  body
) {
  try {
    const response = await apiClient().put(
      `locations/${locationId}/third_party_integrations/${thirdPartyIntegrationId}`,
      body
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response.data.errors);
    throw error;
  }
}

export async function getLocationsForPage(
  locationId,
  thirdPartyIntegrationId,
  pageNumber
) {
  try {
    const response = await apiClient().get(
      `locations/${locationId}/third_party_integrations/${thirdPartyIntegrationId}/locations?page[number]=${pageNumber}`
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response.data.errors);
    throw error;
  }
}

export async function getLocation(locationId) {
  try {
    const response = await apiClient().get(`locations/${locationId}`);
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response.data.errors);
    throw error;
  }
}

export async function authenticateLatestBookerIntegration(code) {
  const body = {
    booker_integration: {
      code: code,
    },
  };
  try {
    const response = await apiClient().put(
      `booker_integrations/authenticate_latest`,
      body
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response);
    throw error;
  }
}

export async function deleteTrialToMembershipMapping(integrationId, mappingId) {
  try {
    const response = await apiClient().delete(
      `third_party_integrations/${integrationId}/trials_memberships_mappings/${mappingId}`
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response);
    throw error;
  }
}

export async function mapTrialToMembership(
  integrationId,
  membershipId,
  trialId
) {
  const body = {
    membership_id: membershipId,
    trial_id: trialId,
  };
  try {
    const response = await apiClient().post(
      `third_party_integrations/${integrationId}/trials_memberships_mappings`,
      body
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response);
    throw error;
  }
}

export async function fetchTrialsMembershipsMappings(integrationId) {
  try {
    const response = await apiClient().get(
      `third_party_integrations/${integrationId}/trials_memberships_mappings`
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response);
    throw error;
  }
}

export async function fetchThirdPartyIntegrationMemberships(integrationId) {
  try {
    const response = await apiClient().get(
      `third_party_integrations/${integrationId}/memberships`
    );
    return response.data;
  } catch (e) {
    const error = createThirdPartyIntegrationError(e.response);
    throw error;
  }
}

function createThirdPartyIntegrationError(error) {
  const thirdPartyIntegrationError = new Error("thirdPartyIntegrationError");
  thirdPartyIntegrationError.message = {
    status: error.status,
    type: error.statusText,
    message: error.data,
  };
  return thirdPartyIntegrationError;
}
