import PropTypes from "prop-types";
import React, { Component } from "react";
export class CsvLeadColumnHeader extends Component {
  fieldSelected = (event) => {
    const { header, replaceHeaderValue } = this.props;
    const newKey = event.target.value;
    replaceHeaderValue(header.key, newKey);
  };

  render() {
    const { header, availableFields, index } = this.props;

    const options = availableFields.map((option, index) => {
      return (
        <option key={index} value={option.key} hidden={option.inUse}>
          {option.displayName}
        </option>
      );
    });

    return (
      <div>
        <select
          className="form-control"
          value={header.key}
          size={options.count}
          onChange={this.fieldSelected}
        >
          <option value={index}>Don't use</option>
          {!header.displayName && (
            <option value={header.key}>{header.key}</option>
          )}
          {options}
        </select>
      </div>
    );
  }
}

CsvLeadColumnHeader.propTypes = {
  header: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  replaceHeaderValue: PropTypes.func.isRequired,
};

export default CsvLeadColumnHeader;
