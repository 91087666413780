import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ArticlesFormValidator } from '../validators'
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers'

import { FormFields } from 'modules/components'
const { TextField, PasswordField } = FormFields

export class ArticlesForm extends Component {
  state = {
    isValid: false,
    fields: {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      },
      totp: {
        value: false,
        isValid: false
      },
      userId: {
        value: '',
        isValid: false
      }
    }
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { fields } = this.state
    const email = fields.email.value
    const password = fields.password.value
    const totp = fields.totp.value
    const userId = fields.userId.value
    this.props.onSubmit(email, password, totp, userId)
  }

  handleInput = (name, value, isValid) => {
    this.setState(generateNewFormState(this.state, name, value, isValid))
  }

  render () {
    const { loading, errors } = this.props
    const { isValid } = this.state
    const buttonTitle = loading ? 'Logging in' : 'Log in'
    const loginSymbol = loading ? 'fa fa-circle-o-notch fa-spin pull-right' : 'si si-login pull-right'
    const buttonEnabled = isValid && !loading

    const renderButton = () =>
      <div className='form-group'>
        <div className='col-xs-12 col-sm-6 col-sm-offset-3'>
          <button type='submit'
            className='btn btn-block btn-primary'
            disabled={!buttonEnabled}
            onClick={this.handleSubmit}>
            <i className={loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>
    return (
      <div>
        {errorPanelForErrors(errors)}
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}>
          <TextField name='email'
            label='Email'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={ArticlesFormValidator}
            value={this.state.fields.email.value} />
          <PasswordField name='password'
            label='Password'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={ArticlesFormValidator} />
          <TextField name='totp'
            label='totp'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={ArticlesFormValidator} />
          <TextField name='userId'
            label='User Id'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={ArticlesFormValidator} />
          {renderButton()}
        </form>
      </div>
    )
  }
}

ArticlesForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ArticlesForm
