import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DealDetailView from '../components/DealDetailView'

import deals from 'modules/Stores/Deals'
import modal from 'modules/Stores/Modal'

import * as localSelectors from '../selectors'
import * as localActions from '../actions'

function mapStateToProps (state) {
  return {
    selectedDeal: localSelectors.selectedDeal(state),
    loading: deals.selectors.getLoading(state),
    data: localSelectors.getData(state),
    errors: deals.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: localActions.updateData,
    deleteDeal: deals.actions.deleteDeal,
    updateDeal: deals.actions.updateDeal,
    prefillDataForDeal: localActions.prefillDataForDeal,
    showModal: modal.actions.showModal
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DealDetailView)
