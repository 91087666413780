import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LeadSourceSelectView from "../components/LeadSourceSelectView";
import leadSources from "modules/Stores/LeadSources";
import * as leadSourceApiActions from "modules/Stores/LeadSources/apiActions";

import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    leadSources: leadSources.selectors.getLeadSources(state),
    loading: leadSources.selectors.getLoading(state),
    errors: leadSources.selectors.getErrors(state),
    pageDetails: leadSources.selectors.getPageDetails(state),
    selectedLeadSource: selectors.getSelectedLeadSource(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentView: actions.setCurrentView,
      selectLeadSource: actions.selectLeadSource,
      getLeadSources: leadSourceApiActions.getLeadSources
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadSourceSelectView);
