/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './floatingButton.css';

import createLead from 'modules/components/CreateLead';

export class FloatingAddButton extends Component {
  addLeadClicked = () => {
    this.props.showModal(createLead.constants.CREATE_LEAD_MODAL_NAME);
  };

  render() {
    return (
      <div>
        <div id="FloatingAddButton">
          <a
            className="float selectable"
            onClick={this.addLeadClicked}
            style={{
              fontSize: 20,
            }}
          >
            <i className="fa fa-user-plus my-float" />
          </a>
          <createLead.CreateLeadModalContainer modalName={createLead.constants.CREATE_LEAD_MODAL_NAME} />
        </div>
      </div>
    );
  }
}

FloatingAddButton.propTypes = {
  showModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default FloatingAddButton;
