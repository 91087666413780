import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class AnotherButtonsView extends Component {

  cancel = () => {
    this.props.setTabIndex(2)
  }

  nextClicked = () => {
    const { createFacebookPageForToken, selectedFacebookPage, integration } = this.props
    createFacebookPageForToken(integration.id, selectedFacebookPage.access_token,
      selectedFacebookPage.id, selectedFacebookPage.name)
  }

  render () {
    const { nextIsReady } = this.props

    const nextDisabledStyle = (nextIsReady) ? '' : 'disabled'
    return (
      <div className='row'>
        <div className='col-xs-6 text-left'>
          <button className='wizard-prev btn btn-default' onClick={this.cancel}>
            Cancel
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          <button className={'wizard-next btn btn-success ' + nextDisabledStyle}
            disabled={!nextIsReady}
            onClick={this.nextClicked}>
            Add page <i className='fa fa-plus' />
          </button>
        </div>
      </div>
    )
  }
}

AnotherButtonsView.propTypes = {
  createFacebookPageForToken: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired,
  nextIsReady: PropTypes.bool.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  selectedFacebookPage: PropTypes.object
}

export default AnotherButtonsView
