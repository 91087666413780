import * as actionTypes from './actionTypes'
import { safePropertyForObject } from 'utils/formHelpers'

const GYMMASTER_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.ON_INPUT]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    return initialStateForIntegration(action.integration)
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForIntegration({})
  },
  [actionTypes.SET_TAB_INDEX]: (state, action) => {
    return { ...state, tabIndex: action.tabIndex }
  },
  [actionTypes.SET_SITE_LOCATIONS]: (state, action) => {
    return { ...state, siteLocations: action.siteLocations }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      total: action.pageDetails.total_count
    }
  }
}

function initialStateForIntegration (option) {
  const id = safePropertyForObject(option, 'id')
  const locationId = safePropertyForObject(option, 'integration_location_id')
  let tabIndex = 0
  tabIndex = (id && !locationId) ? 1 : tabIndex
  tabIndex = (id && locationId) ? 2 : tabIndex
  return {
    tabIndex: tabIndex,
    permissionSet: option.integration_permission_set,
    siteLocations: [],
    selectedLocationId: null,
    data: {
      fields: {
        id: {
          value: id,
          isValid: true
        },
        customUrl: {
          value: safePropertyForObject(option, 'custom_url', ''),
          isValid: true
        },
        apiKey: {
          value: '',
          isValid: true
        },
        integrationLocationId: {
          value: locationId,
          isValid: true
        }
      }
    },
    pageCount: 1,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialStateForIntegration({}), action) {
  const handler = GYMMASTER_PREFERENCES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
