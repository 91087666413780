import PropTypes from "prop-types";
import React, { Component } from "react";

import TrialSelectModal from "modules/components/TrialSelectModal";

export class DashboardTrialSelectModal extends Component {
  trialSelected = (trial, trialStartsAt) => {
    const { lead, hideModal, addLeadToTrial } = this.props
    addLeadToTrial(lead, trial, trialStartsAt)
    hideModal()
  }

  render() {
    const { modalName, lead } = this.props;
    const trialId = (lead.trial) ? lead.trial.trial_id : null

    return (
      <TrialSelectModal.TrialSelectModalContainer
        modalName={modalName}
        onTrialSelect={this.trialSelected}
        selectedTrialId={trialId}
      />
    );
  }
}

DashboardTrialSelectModal.propTypes = {
  lead: PropTypes.object.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  salesFunnel: PropTypes.object.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired
};

export default DashboardTrialSelectModal;
