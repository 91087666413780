import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScheduleCallModal from 'modules/components/LeadDetail/modules/calls/components/NewCallModal/ScheduleCallModal';

import * as actions from '../actions';
import modal from 'modules/Stores/Modal';

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      scheduleCall: (...args) => actions.scheduleCall(ownProps.eventTypes, ownProps.usersIds, ownProps.timezone, ...args),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCallModal);
