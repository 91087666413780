export function replaceSpacesWithHyphens(text: string) {
  return text.replace(/ /g, '-').toLowerCase();
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function pluralizationHelper(text: string, count = 0) {
  const isOne = count === 1;
  if (!isOne) {
    return text;
  }
  return text.slice(0, -1);
}

export function pluralizationHelperWithOptions(count = 0, singularString: string, pluralString: string) {
  const isOne = count === 1;
  if (!isOne) {
    return pluralString;
  }
  return singularString;
}

export function objectToCamelCaseKeys(dataObject: any) {
  if (!dataObject) return {};
  const keys = Object.keys(dataObject);
  if (!keys) return {};
  let payload = {};
  keys.forEach((key) => {
    const camelCase = stringToCamelCase(key);
    payload = { ...payload, [camelCase]: dataObject[key] };
  });
  return payload;
}

export function objectToSnakeCaseKeys(dataObject: any) {
  if (!dataObject) return {};
  const keys = Object.keys(dataObject);
  if (!keys) return {};
  let payload = {};
  keys.forEach((key) => {
    const snakeCase = stringToSnakeCase(key);
    payload = { ...payload, [snakeCase]: dataObject[key] };
  });
  return payload;
}

// Courtesy of https://vladimir-ivanov.net/camelcase-to-snake_case-and-vice-versa-with-javascript/
export function stringToSnakeCase(fieldName: string) {
  return fieldName
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
}

export function stringToCamelCase(fieldName: string) {
  return fieldName.replace(/(_\w)/g, function (m) {
    return m[1].toUpperCase();
  });
}
