import * as actionTypes from './actionTypes'

const LAWFUL_PROCESSING_BASES_ACTION_HANDLERS = {
  [actionTypes.SET_LAWFUL_PROCESSING_BASES]: (state, action) => {
    return { ...state, lawfulProcessingBases: action.lawfulProcessingBases }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.LAWFUL_PROCESSING_BASIS_SAVED]: (state, action) => {
    const oldLawfulProcessingBases = state.lawfulProcessingBases
    const index = indexForLawfulProcessingBasis(oldLawfulProcessingBases, action.lawfulProcessingBasis.id)
    let newLawfulProcessingBases = []
    if (index < 0) {
      newLawfulProcessingBases = [
        ...oldLawfulProcessingBases,
        action.lawfulProcessingBasis
      ]
    } else {
      newLawfulProcessingBases = [
        ...oldLawfulProcessingBases.slice(0, index),
        action.lawfulProcessingBasis,
        ...oldLawfulProcessingBases.slice(index + 1)
      ]
    }

    return { ...state, lawfulProcessingBases: newLawfulProcessingBases }
  },
  [actionTypes.DELETE_LAWFUL_PROCESSING_BASIS_WITH_ID]: (state, action) => {
    const oldLawfulProcessingBases = state.lawfulProcessingBases
    const index = indexForLawfulProcessingBasis(oldLawfulProcessingBases, action.lawfulProcessingBasisId)
    const newLawfulProcessingBases = [
      ...state.lawfulProcessingBases.slice(0, index),
      ...state.lawfulProcessingBases.slice(index + 1)
    ]
    return { ...state, lawfulProcessingBases: newLawfulProcessingBases }
  }
}

const initialState = {
  lawfulProcessingBases: [],
  errors: null,
  loading: false
}

export default function reducer (state = initialState, action) {
  const handler = LAWFUL_PROCESSING_BASES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

function indexForLawfulProcessingBasis (lawfulProcessingBases, lawfulProcessingBasisId) {
  return lawfulProcessingBases
  .findIndex(lawfulProcessingBasis => parseInt(lawfulProcessingBasis.id) === parseInt(lawfulProcessingBasisId))
}
