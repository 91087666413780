import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'

import AvatarUploadContainer from '../containers/AvatarUploadContainer'

import { ProfileFormValidator } from '../validators'

import moment from 'moment'
import 'moment-timezone'

const { TextField, SelectField } = FormFields

export class EditProfileForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onUpdate(newData)
  }

  onUpdate = () => {
    const { updateProfileWithData, data, user } = this.props
    updateProfileWithData(data, user)
  }

  render () {
    const { username, givenName, surname, email, positionTitle,
      mobileNumber, timeZone, signatureEmail, signatureMobile } = this.props.data.fields
    return (
      <div>
        <div className='row'>
          <div className='col-sm-3 text-center'>
            <AvatarUploadContainer />
          </div>
          {givenName.value &&
            <div className='col-sm-9'>
              <div className='col-sm-12'>
                <TextField
                  name='username'
                  label='Username'
                  placeholder='What do you want your username to be?'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={username.value}
                  validator={ProfileFormValidator} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='givenName'
                  label='Given Name'
                  placeholder='What is your name?'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={givenName.value}
                  validator={ProfileFormValidator} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='surname'
                  label='Surname'
                  placeholder='And your last name?'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  value={surname.value} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='email'
                  label='Email'
                  placeholder='joe.blogs@fitzone.com'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={email.value}
                  validator={ProfileFormValidator} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='mobileNumber'
                  label='Mobile Number'
                  placeholder='+61 221 221 332'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={mobileNumber.value} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='positionTitle'
                  label='Position title'
                  placeholder='Customer service team'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={positionTitle.value} />
              </div>
              <div className='col-sm-6'>
                <SelectField
                  name='timeZone'
                  label='Time Zone'
                  possibleOptions={moment.tz.names()}
                  placeHolderText='Select your timezone'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={timeZone.value} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='signatureEmail'
                  label='Signature email'
                  placeholder='hello@gym.com'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  validator={ProfileFormValidator}
                  initialValue={signatureEmail.value} />
              </div>
              <div className='col-sm-6'>
                <TextField
                  name='signatureMobile'
                  label='Signature Phone Number'
                  placeholder='#### ####'
                  formFieldStyle=''
                  onChange={this.handleInput}
                  initialValue={signatureMobile.value} />
              </div>
              <div className='col-sm-9'>
                <button className='btn btn-primary push-5-r push-10'
                  type='button'
                  onClick={this.onUpdate}>
                  <i className='si si-user' /> Update Profile
                </button>
              </div>
            </div>
        }
        </div>
      </div>
    )
  }
}

EditProfileForm.propTypes = {
  updateProfileWithData: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  currentModal: PropTypes.string,
  staffIntegrationLocation: PropTypes.object,
  mindbodyUserId: PropTypes.number,
  staffIntegration: PropTypes.object
}

export default EditProfileForm
