import * as actionTypes from './actionTypes'

import { safePropertyForObject } from 'utils/formHelpers'

const COMMUNICATION_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.ON_INPUT]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    return initialStateForIntegration(action.integration)
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForIntegration({})
  }
}

function initialStateForIntegration (option) {
  return {
    data: {
      fields: {
        id: {
          value: safePropertyForObject(option, 'id'),
          isValid: true
        },
        username: {
          value: safePropertyForObject(option, 'username'),
          isValid: true
        },
        companyName: {
          value: safePropertyForObject(option, 'company_name'),
          isValid: true
        },
        password: {
          value: safePropertyForObject(option, 'password'),
          isValid: true
        },
        customUrl: {
          value: safePropertyForObject(option, 'custom_url'),
          isValid: true
        },
        integrationLocationId: {
          value: safePropertyForObject(option, 'integration_location_id'),
          isValid: true
        },
        apiKey: {
          value: safePropertyForObject(option, 'api_key'),
          isValid: true
        }
      }
    }
  }
}

export default function reducer (state = initialStateForIntegration({}), action) {
  const handler = COMMUNICATION_PREFERENCES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
