import * as actionTypes from './actionTypes'

const MULTI_SELECT_USER_ACTION_HANDLERS = {
  [actionTypes.SET_SELECTED_USER_IDS]: (state, action) => {
    return { ...state, selectedUserIds: action.selectedUserIds }
  },
  [actionTypes.ADD_USER_ID]: (state, action) => {
    const updatedUserIds = [
      ...state.selectedUserIds,
      action.userId
    ]
    return { ...state, selectedUserIds: updatedUserIds }
  },
  [actionTypes.REMOVE_USER_ID]: (state, action) => {
    const index = state.selectedUserIds
    .findIndex(selectedUserId => parseInt((action.userId), 10) === parseInt((selectedUserId), 10))
    const newSelectedIds = [
      ...state.selectedUserIds.slice(0, index),
      ...state.selectedUserIds.slice(index + 1)
    ]
    return { ...state, selectedUserIds: newSelectedIds }
  },
  [actionTypes.SET_USERS]: (state, action) => {
    return { ...state, users: action.users }
  },
  [actionTypes.SET_LAST_USED_LOCATION_IDS]: (state, action) => {
    return { ...state, lastUsedLocationIds: action.lastUsedLocationIds }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  users: [],
  selectedUserIds: [],
  lastUsedLocationIds: null,
  loading: false,
  singleSelect: false,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = MULTI_SELECT_USER_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
