import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InviteTeamMemberModal } from "../components/InviteTeamMemberModal";
import { getProductName } from "modules/Stores/UserData/selectors";

import modal from "modules/Stores/Modal";

import invite from "../modules/invite";
import locations from "modules/Stores/Locations";

import * as selectors from "../selectors";
const { hideModal } = modal.actions;
const { sendInvite, updateInviteData, resetForm } = invite.actions;

function mapStateToProps(state) {
  const selectedLocationId = selectors.selectedLocationId(state);
  const selectedLocation = locations.selectors.locationForIdFactory(
    selectedLocationId
  )(state);
  return {
    userRoles: selectors.availableUserRole(state),
    productName: getProductName(state),
    data: state.salesTeams.invite.data,
    location: selectedLocation,
    currentModal: state.modal.currentModal,
    loading: state.modal.loading,
    errors: state.modal.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendInvite,
      updateInviteData,
      resetForm,
      hideModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteTeamMemberModal);
