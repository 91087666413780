import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validators'

const EDIT_LAWFUL_PROCESSING_BASIS_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM_DATA]: (state, action) => {
    const fields = generateFieldsForKeys(action.lawfulProcessingBasis, fieldNames, payloadKeys, validator)
    return { ...state,
      data: {
        fields: fields,
        isValid: fieldsAreValid(fields)
      }
    }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_SHOWING]: (state, action) => {
    return { ...state, showing: action.showing }
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForLawfulProcessingBasis()
  }
}

const payloadKeys = {
  title: 'title',
  leadFacingDescription: 'lead_facing_description',
  newDefault: 'new_default',
  closedLeadDefault: 'closed_lead_default'
}

function initialStateForLawfulProcessingBasis (lawfulProcessingBasis) {
  const fields = generateFieldsForKeys(lawfulProcessingBasis, fieldNames, payloadKeys, validator)
  return {
    loading: false,
    errors: null,
    showing: false,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function reducer (state = initialStateForLawfulProcessingBasis(), action) {
  const handler = EDIT_LAWFUL_PROCESSING_BASIS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
