import * as actionTypes from './actionTypes'

const EMAIL_MAP_RESULTS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, actions) => {
    return { ...state, loading: actions.loading }
  },
  [actionTypes.SET_ERRORS]: (state, actions) => {
    return { ...state, errors: actions.errors }
  },
  [actionTypes.SET_DOMAINS]: (state, action) => {
    return { ...state, domains: action.domains }
  },
  [actionTypes.SET_SELECTED_DOMAIN_ID]: (state, action) => {
    return { ...state, selectedDomainId: action.selectedDomainId }
  },
  [actionTypes.SET_RATES]: (state, action) => {
    return { ...state, rates: action.rates }
  },
  [actionTypes.SET_STATISTICS]: (state, action) => {
    return { ...state, statistics: action.statistics }
  }
}

const initialState = {
  loading: false,
  errors: null,
  domains: [],
  selectedDomainId: null,
  rates: null,
  statistics: null
}

export default function reducer (state = initialState, action) {
  const handler = EMAIL_MAP_RESULTS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
