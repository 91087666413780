import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoggedInHeader from './LoggedInHeader';

export class Header extends Component {
  setDocumentTitle() {
    const { whitelabelProfile } = this.props;
    if (whitelabelProfile && whitelabelProfile.name) {
      document.title = `${whitelabelProfile.name} | Sales & Reporting`;
    } else {
      document.title = 'Gymleads | Sales & Reporting';
    }
  }

  render() {
    const { user, logout, whitelabelProfile } = this.props;

    this.setDocumentTitle();

    return <LoggedInHeader user={user} logout={logout} whitelabelProfile={whitelabelProfile} />;
  }
}

Header.propTypes = {
  user: PropTypes.object,
  whitelabelProfile: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

export default Header;
