import * as actionTypes from './actionTypes'
import moment from 'moment'

import { safeRawPropertyForObject } from 'utils/formHelpers'

const AUTO_ASSIGN_RULE_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM_DATA]: (state, action) => {
    return initialStateForAutoAssignRule(action.autoAssignRule)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },

  // Set User Container

  [actionTypes.SET_POTENTIAL_USERS]: (state, action) => {
    return { ...state, potentialUsers: action.potentialUsers }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count
      }
    }
  }
}

function initialStateForAutoAssignRule (autoAssignRule) {
  const endValue = (autoAssignRule && autoAssignRule.end_time) ? moment(autoAssignRule.end_time) : null
  const startValue = (autoAssignRule && autoAssignRule.start_time) ? moment(autoAssignRule.start_time) : null
  const userValue = (autoAssignRule && autoAssignRule.user) ? autoAssignRule.user : null
  return {
    loading: false,
    errors: null,
    potentialUsers: [],
    pageDetails: {
      pageCount: 0,
      currentPage: 1,
      total: 0
    },
    data: {
      isValid: true,
      fields: {
        enabled: {
          value: safeRawPropertyForObject(autoAssignRule, 'enabled'),
          isValid: true
        },
        endTime: {
          value: endValue,
          isValid: true
        },
        startTime: {
          value: startValue,
          isValid: true
        },
        user: {
          value: userValue,
          isValid: false
        }
      }
    }
  }
}

export default function reducer (state = initialStateForAutoAssignRule(null), action) {
  const handler = AUTO_ASSIGN_RULE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
