import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = (state) => state.lostLeadsReport[NAME].errors
export const getLoading = (state) => state.lostLeadsReport[NAME].loading
export const getData = (state) => state.lostLeadsReport[NAME].data

export const dataLabels = createSelector(
  [ getData ],
  (data) => {
    return data.map((dataSet) => dataSet.label)
  }
)

export const datasets = createSelector(
  [ getData ],
  (data) => {
    const allLostReasons = getAllLostReasonsFromData(data)
    if (allLostReasons.length === 0) {
      return []
    }

    const dataSets = []
    allLostReasons.forEach((lostReason, index) => {
      const label = lostReason['label']
      const id = lostReason['id']
      let values = []
      data.forEach((entity) => {
        const entityData = entity.data
        const index = entityData.findIndex(entityDataItem => entityDataItem.id === id)
        const value = (index > -1) ? entityData[index].count : 0
        values.push(value)
      })
      const color = colors[index]
      dataSets.push({
        label: label,
        data: values,
        backgroundColor: color,
        hoverBackgroundColor: color
      })
    })
    return dataSets
  }
)

function getAllLostReasonsFromData (data) {
  let allLostReasons = data.map(entity => entity.data)
  allLostReasons = [].concat.apply([], allLostReasons)
  allLostReasons = allLostReasons.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) === pos
  })
  return allLostReasons.sort((a, b) => b.id - a.id)
}

const colors = [
  '#CCDCF1', '#98B9E3', '#5C90D2', '#3E4A59', '#2C343F', '#E4DCF2',
  '#C7B7E4', '#A48AD4', '#FFB8B8', '#FF8F8F', '#FF6B6B',
  '#A8DED8', '#83D0C7', '#44B4A6', '#C4F2F9', '#7FE3F2', '#14ADC4',
  '#FFB9D0', '#FF90B5', '#FF6C9D', '#CCDCF1', '#98B9E3', '#5C90D2',
  '#3E4A59', '#2C343F', '#E4DCF2',
  '#C7B7E4', '#A48AD4', '#FFB8B8', '#FF8F8F', '#FF6B6B',
  '#A8DED8', '#83D0C7', '#44B4A6', '#C4F2F9', '#7FE3F2', '#14ADC4',
  '#FFB9D0', '#FF90B5', '#FF6C9D'
]
