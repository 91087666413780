import { NAME } from './constants';

export const getToken = () => queryParams.token;
export const getProductName = () => queryParams.client;
export const getLoading = (state) => state[NAME].loading;
export const getErrors = (state) => state[NAME].errors;
export const getValidateToken = (state) => state[NAME].validateToken;
export const getSuccess = (state) => state[NAME].success;
export const getWhitelabelProfile = (state) => state[NAME].whitelabelProfile;

// Creates a hash with the URL Query params
var queryParams = window.location.search
  .substr(1)
  .split('&')
  .reduce(function (q, query) {
    var chunks = query.split('=');
    var key = chunks[0];
    var value = chunks[1];
    // eslint-disable-next-line
    return (q[key] = value), q;
  }, {});
