import PropTypes from "prop-types";
import React, { Component } from "react";

import ScheduleStepRow from "./ScheduleStepRow";
import NewScheduleStepContainer from "../containers/NewScheduleStepContainer";
import EditScheduleStepContainer from "../containers/EditScheduleStepContainer";
import "./EditCallScheduleStepsStyles.css";

import {
  NEW_CALL_SCHEDULE_STEP_MODAL,
  UPDATE_CALL_SCHEDULE_STEP_MODAL,
} from "../constants";

export class CallScheduleStepsView extends Component {
  componentDidMount() {
    const { callScheduleId, fetchCallScheduleSteps } = this.props;
    fetchCallScheduleSteps(callScheduleId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.callScheduleId !== nextProps.callScheduleId) {
      nextProps.fetchCallScheduleSteps(nextProps.callScheduleId);
    }
  }

  selectStep = (id) => {
    this.props.selectCallScheduleStepId(id);
    this.props.showModal(UPDATE_CALL_SCHEDULE_STEP_MODAL);
  };

  stepComponents = () => {
    return this.props.steps.map((step, index) => {
      return (
        <ScheduleStepRow
          key={index}
          callScheduleStep={step}
          onSelect={this.selectStep}
        />
      );
    });
  };

  addNewStepSelected = () => {
    this.props.showModal(NEW_CALL_SCHEDULE_STEP_MODAL);
  };

  render() {
    const { loading, callScheduleId, selectedCallScheduleStep } = this.props;
    const blockStyle = loading ? "block-opt-refresh" : "";

    return (
      <div className={"block block-rounded block-bordered " + blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Schedule steps</h3>
        </div>
        <div className="block-content">
          <ul className="list list-timeline-no-border pull-t">
            {this.stepComponents()}
          </ul>
          <div className="row push-20">
            <div className="col-lg-12">
              <div
                className="selectable"
                onClick={this.addNewStepSelected}
                style={{
                  marginLeft: "40px",
                  color: "#999",
                  border: "1px dashed #e6e6e6",
                  padding: "20px",
                }}
              >
                <i
                  className="si si-plus fa-2x pull-left"
                  style={{ marginRight: "10px" }}
                />
                <span className="h4 font-w700">Add new step</span>
              </div>
            </div>
          </div>
        </div>
        <NewScheduleStepContainer callScheduleId={callScheduleId} />
        {selectedCallScheduleStep && (
          <EditScheduleStepContainer callScheduleId={callScheduleId} />
        )}
      </div>
    );
  }
}

CallScheduleStepsView.propTypes = {
  fetchCallScheduleSteps: PropTypes.func.isRequired,
  selectCallScheduleStepId: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  callScheduleId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  selectedCallScheduleStep: PropTypes.object,
  errors: PropTypes.object,
};

export default CallScheduleStepsView;
