import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class FormView extends Component {

  render () {
    const { data } = this.props
    const { customUrl, integrationLocationId } = data.fields
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <h2 className='h4 push-10'>This club is now integrated with Gymmaster</h2>
            <p className='text-gray-dark'>
              Your URL is: {customUrl.value}<br />
              Gymmaster Club ID: {integrationLocationId.value}<br />
            </p>
          </div>
        </div>
      </div>
    )
  }
}

FormView.propTypes = {
  data: PropTypes.object.isRequired
}

export default FormView
