import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'
import EditCallScheduleFormContainer from '../containers/EditCallScheduleFormContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

export class EditCallScheduleView extends Component {

  state = {
    showWarning: false
  }

  componentDidMount () {
    const { callSchedule, prefilFormForCallSchedule } = this.props
    if (callSchedule) {
      prefilFormForCallSchedule(callSchedule)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentCallScheduleId = (this.props.callSchedule) ? this.props.callSchedule.id : null
    const nextCallScheduleId = (nextProps.callSchedule) ? nextProps.callSchedule.id : null
    if (nextCallScheduleId !== currentCallScheduleId) {
      nextProps.prefilFormForCallSchedule(nextProps.callSchedule)
    }
  }

  saveCallSchedule = () => {
    const { updateCallSchedule, data, listUpdateProtocol, selectedLeadSourcesIds, selectedLocationIds } = this.props
    updateCallSchedule(data, selectedLeadSourcesIds, selectedLocationIds, listUpdateProtocol)
  }

  deleteSelected = () => {
    this.setState({ ...this.state, showWarning: true })
  }

  dismissWarning = () => {
    this.setState({ ...this.state, showWarning: false })
  }

  deleteCallSchedule = () => {
    const { deleteCallSchedule, callSchedule, listUpdateProtocol, reset } = this.props

    deleteCallSchedule(callSchedule.id, listUpdateProtocol)
    reset()
    this.setState({ ...this.state, showWarning: false })
  }

  formIsInvalid = () => {
    const { selectedLeadSourcesIds, data } = this.props
    if (data.isValid === false) { return true }
    if (selectedLeadSourcesIds.length === 0 && data.fields.isDefault.value === false) {
      return true
    }

    return false
  }

  render () {
    const { loading } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''

    return (
      <div className={'block block-rounded block-bordered ' + blockStyle} >
        <div className='block-header'>
          <div className='block-options-simple'>
            <button type='button' className='btn btn-xs btn-warning' onClick={this.deleteSelected}>
              <i className='fa fa-times' />{' '}Delete call schedule
            </button>
          </div>
          <h3 className='block-title'>Update call schedule</h3>
        </div>
        <div className='block-content'>
          <div className='row push-20'>
            <div className='col-sm-12'>
              {errorPanelForErrors(this.props.errors, false)}
              <EditCallScheduleFormContainer />
            </div>
          </div>
          <div className='push-20 row'>
            <div className='col-md-12'>
              <button type='button'
                className='btn btn-primary'
                onClick={this.saveCallSchedule}
                disabled={this.formIsInvalid()}>
                <i className='fa fa-save' /> Save call schedule
              </button>
            </div>
          </div>
        </div>
        {this.state.showWarning &&
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='warning'
            title='Are you Sure?'
            confirmBtnText='Delete this call schedule'
            onConfirm={this.deleteCallSchedule}
            onCancel={this.dismissWarning}>
            This call schedule will be deleted. Leads with this call schedule will no longer be animated.
          </SweetAlert>
        }
      </div>
    )
  }
}

EditCallScheduleView.propTypes = {
  updateCallSchedule: PropTypes.func.isRequired,
  prefilFormForCallSchedule: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  callSchedule: PropTypes.object.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  deleteCallSchedule: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  selectedLeadSourcesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  reset: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default EditCallScheduleView
