import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// GET Sales Team
export const fetchSalesTeamForLocation = (location, startDate, endDate) => {
  return dispatch => {
    dispatch(loading())

    function onFailure (errors) {
      dispatch(loadFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(loadSuccess(payload.sales_team))
    }
    if (startDate && endDate) {
      return request('locations/' + location.id + ' /sales_team?start_date=' +
       startDate + '&end_date=' + endDate,
       GET, null, onSuccess, onFailure)
    } else {
      return request('locations/' + location.id + ' /sales_team', GET, null, onSuccess, onFailure)
    }
  }
}

function loading () {
  return {
    type: actionTypes.LOADING
  }
}

function loadSuccess (salesTeam) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    salesTeam
  }
}

export function loadFailed (errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors
  }
}

export function incrementSalesTeamMemberLeadCount (salesTeamMemberId) {
  return {
    type: actionTypes.INCREMENT_SALES_TEAM_MEMBER_LEAD_COUNT,
    salesTeamMemberId
  }
}

export function selectUserId (userId) {
  return {
    type: actionTypes.USER_SELECTED,
    userId
  }
}
