import PropTypes from "prop-types";
import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import { EmptyView } from "modules/components";
import { NO_CHANGE } from "../../../constants";
import * as tooltips from "../tooltips";
import { RichToolTip } from "modules/components";

export class TimeTakenTableView extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {

    if (!nextProps.selectedCell) {
      return;
    } else if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.selectedCell !== this.props.selectedCell
    ) {
      const { getTimeTaken, queryString, selectedCell } = nextProps;
      getTimeTaken(queryString, selectedCell);
    }
  }

  render() {
    const { loading, selectedCell, formattedTitle, dataSet } = this.props;
    const blockStyle = loading ? "block-opt-refresh" : "";
    const statusNoChange = selectedCell && selectedCell.status === NO_CHANGE;

    return (
      <div className={`block block-rounded block-bordered ${blockStyle}`}>
        <div className="block-header">
          <h3 className="block-title">
            {formattedTitle}
            {selectedCell && !statusNoChange && (
              <TooltipContainer>
                <RichToolTip
                  text={tooltips.timeTakenTooltip(selectedCell.status)}
                  toptipId={`trial-time-taken-tooltip`}
                />
              </TooltipContainer>
            )}
          </h3>
        </div>
        <div className="block-content">
          {!selectedCell && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="fa fa-users"
              title="Select table cell to display the time taken"
            />
          )}
          {selectedCell && statusNoChange && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="fa fa-users"
              title="No lead data for this period."
            />
          )}
          {selectedCell && !dataSet && !statusNoChange && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="fa fa-users"
              title="No lead data for this period."
            />
          )}
          {dataSet && selectedCell && selectedCell.status !== NO_CHANGE && (
            <ChartContainer>
              <Line
                data={dataSet}
                options={{
                  responsive: true,
                  maintainAspectRatio: false
                }}
              />
            </ChartContainer>
          )}
        </div>
      </div>
    );
  }
}

TimeTakenTableView.propTypes = {
  queryString: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TimeTakenTableView;

const ChartContainer = styled.div`
  padding: 0px 0px 40px 0px;
`;

const TooltipContainer = styled.span`
  position: relative;
  top: -4px !important;
  font-size: 10px;
  margin-left: 2px;
`;
