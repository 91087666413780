import React, { Component } from "react";
import styled from "styled-components";
import { calculateTotalSum } from '../helpers'
import { RichToolTip } from 'modules/components'
import * as tooltips from '../tooltips'


export default class TrialListView extends Component {
  render() {
    const { trialsData, loading } = this.props;
    const blockLoading = loading ? "block-opt-refresh" : "";
    

    return (
      <div className={`block block-rounded block-bordered ${blockLoading}`}>
        <div className="block-header">
          <h3 className="block-title">Leads in trial</h3>
        </div>
        <div className="block-content push-50">
          {trialsData && trialsData.length > 0 && (
            <Container>
              <div className="table-responsive">
                <HeaderSpacing />
                <table className="table table-vcenter">
                  <thead>
                    <tr>
                      <TableHeader>Trial</TableHeader>
                      <TableHeader>Count
                        <TooltipContainer>
                          <RichToolTip text={tooltips.trialLeadsCount()} toptipId={`trial-count-tooltip`} />
                        </TooltipContainer>
                      </TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {trialsData.map((trial, index) => {
                      return (
                        <TrialRow trial={trial} key={`trialRow-${index}`} />
                      );
                    })}
                    <tr>
                      <td>
                        <TotalSubheader>Total</TotalSubheader>
                      </td>
                      <td>
                        <TotalSubheader>{calculateTotalSum(trialsData, 'totals')}</TotalSubheader>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Container>
          )}
        </div>
      </div>
    );
  }
}

const TrialRow = ({ trial }) => {
  return (
    <tr>
      <td>{trial.label}</td>
      <td>{trial.data.totals.value}</td>
    </tr>
  );
};

const TotalSubheader = styled.span`
  font-weight: 700;
  font-size: 14px;
`;

const Container = styled.div`
  /* margin: 0px 25px 25px 0px; */
`;

const TableHeader = styled.th`
  /* text-align: center; */
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
`;

const TooltipContainer = styled.span`
  position: relative;
  top: -4px !important;
  font-size: 10px;
  margin-left: 2px;
`;

const HeaderSpacing = styled.div`
  padding-top: 20px; 
`;
