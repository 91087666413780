import { isPresent } from 'utils/validation';

export const validator = {
  label: [
    {
      rule: isPresent,
      errorMessage: 'The custom merge tag name needs a label',
    },
  ],
  value: [
    {
      rule: isPresent,
      errorMessage: 'The custom merge tag name needs a value',
    },
  ],
};
