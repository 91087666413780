import * as CSS from 'csstype';

export interface ThemeColors {
  blue: CSS.Property.Color[];
  darkGreen: CSS.Property.Color[];
  lightGreen: CSS.Property.Color[];
  darkYellow: CSS.Property.Color[];
  lightYellow: CSS.Property.Color[];
  red: CSS.Property.Color[];
  black: CSS.Property.Color[];
  grey: CSS.Property.Color[];
}

export const PrimaryColors = {
  blue: ['#3D7CC9', '#618ED2', '#DBE2F4'],
  darkGreen: ['rgba(0,133,86,1)', 'rgba(0,133,86,0.85)', 'rgba(0,133,86,0.20)'],
};

export const SecondaryColors = {
  lightGreen: ['rgba(0,188,112,1)', 'rgba(0,188,112,0.85)', 'rgba(0,188,112,0.2)'],
  darkYellow: ['rgba(247,172,2,1)', 'rgba(247,172,2,0.85)', 'rgba(247,172,2,0.2)'],
  lightYellow: ['rgba(255,222,0,1)', 'rgba(255,222,0,0.85)', 'rgba(255,222,0,0.2)'],
  red: ['#f4d8d4', '#c54736', '#d26a5c'],
};

export const NeutralColors = {
  black: ['rgba(50,62,72,1)', 'rgba(50,62,72,0.85)', 'rgba(50,62,72,0.2)'],
  grey: ['#979797', 'rgba(50, 62, 72, 0.5)', 'rgba(151, 151, 151, 0.12)', '#F7F7F7'],
};

export const colors: ThemeColors = {
  ...PrimaryColors,
  ...SecondaryColors,
  ...NeutralColors,
};
