import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CreditCardFormContainer from 'modules/components/CreditCardForm/containers/CreditCardFormContainer';

import { errorPanelForErrors } from 'utils/formHelpers';

import Modal from 'react-bootstrap-modal';

export class CreditCardModalView extends Component {
  createCreditCardMethod = () => {
    const { createCreditCardRecord, data, cardIdToReplace } = this.props;
    createCreditCardRecord(data, cardIdToReplace);
  };

  hideModal = () => {
    const { hideModal, resetForm } = this.props;
    resetForm();
    hideModal();
  };

  render() {
    const { loading, currentModal, modalName, errors, cardIdToReplace } = this.props;
    const title = cardIdToReplace ? 'Replace credit card' : 'Add new credit card';
    const buttonTitle = cardIdToReplace ? 'Replace Payment Method' : 'Add Payment Method';
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const backgroundColor = cardIdToReplace ? 'primary' : 'success';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideModal}>
          <div className={blockStyle}>
            <div className={'block-header bg-' + backgroundColor}>
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                {title}
              </h3>
            </div>
            <div className="block-content">
              <form className="form-horizontal push-5-t">
                {errorPanelForErrors(errors)}
                <CreditCardFormContainer showInstructions={false} onInput={this.props.onInput} data={this.props.data} />
              </form>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModal}>
              Cancel
            </button>
            <button
              className={'btn btn-' + backgroundColor + ' push-5-r push-10'}
              type="button"
              disabled={this.props.data.isValid !== true}
              onClick={this.createCreditCardMethod}
            >
              <i className="fa fa-plus" /> {buttonTitle}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

CreditCardModalView.propTypes = {
  loading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  createCreditCardRecord: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  cardIdToReplace: PropTypes.number,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentModal: PropTypes.string,
};

export default CreditCardModalView;
