import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getProductName } from 'modules/Stores/UserData/selectors'

import FormView from '../components/Authenticate/FormView'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state, props) {
  return {
    data: selectors.getData(state),
    productName: getProductName(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: actions.onInput
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
