import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import graphBreakdown from '../modules/graphBreakdown'
import graphSummary from '../modules/graphSummary'

function mapStateToProps (state) {
  const data = graphBreakdown.selectors.getData(state)
  const dataSet = graphSummary.selectors.chartDataForSummaryData(data)
  return {
    loading: graphBreakdown.selectors.getLoading(state),
    errors: graphBreakdown.selectors.getErrors(state),
    dataSet: dataSet,
    isEmpty: Object.keys(dataSet).length === 0
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(graphSummary.components.GraphSummaryView)
