import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { UserAvatar } from 'modules/components/Avatars'

export default class TableView extends Component {

  render () {
    const { data, loading, headers, title } = this.props
    const rows = data.map((userRow, index) => {
      const rowResults = headers.map((header, index) => {
        return (
          <td key={userRow.display_name + header.key}>{userRow[header.key]}</td>
        )
      })

      return (
        <tr key={index} >
          <td className='text-center'>
            <UserAvatar avatarImage={userRow.avatar} className={'img-avatar48'} type={'medium'} />
          </td>
          <td className='font-w600'>
            {userRow.display_name}
          </td>
          {rowResults}
        </tr>
      )
    })

    const headerRows = headers.map((header, index) => {
      return (
        <th key={index}>{header.display_name}</th>
      )
    })

    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>{title}</h3>
        </div>
        <div className='block-content block-content-full' style={{ paddingTop: '0px' }}>
          <div className='table-responsive'>
            <table className='table table-vcenter'>
              <thead>
                <tr>
                  <th className='text-center' style={{ width: '120px' }}>
                    <i className='si si-user' />
                  </th>
                  <th>Name</th>
                  {headerRows}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

TableView.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}
