import { NAME } from './constants'

export const UPDATING = NAME + '/UPDATING'
export const UPDATE_SUCCESS = NAME + '/UPDATE_SUCCESS'
export const UPDATE_FAILED = NAME + '/UPDATE_FAILED'

export const UPDATE_FORM_DATA = NAME + '/UPDATE_DATA'
export const PREFILL_FORM = NAME + '/PREFILL_FORM'

export const LOGO_WAS_SELECTED = NAME + '/LOGO_WAS_SELECTED'
