import { NAME } from './constants'

export const SET_CURRENT_VIEW = NAME + '/SET_CURRENT_VIEW'
export const SET_SELECTED_LOCATION = NAME + '/SET_SELECTED_LOCATION'
export const SET_SELECTED_USER = NAME + '/SET_SELECTED_USER'
export const RESET = NAME + '/RESET'
export const SET_USERS = NAME + '/SET_USERS'
export const SET_LAST_USED_LOCATION_ID = NAME + '/SET_LAST_USED_LOCATION_ID'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_PAGE_DETAILS = NAME + '/SET_PAGE_DETAILS'
