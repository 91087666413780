import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { readableMobileNumber } from 'modules/Helpers/FormatHelpers'

export class AccountView extends Component {

  numberTypeStringForNumber (number) {
    switch (number.number_type) {
      case 'shared_longcode':
        return 'Longcode'
      case 'dedicated_longcode':
        return 'Dedicated longcode'
      case 'shared_shortcode':
        return 'Shortcode'
      case 'dedicated_shortcode':
        return 'Dedicated Shortcode'
      case 'dedicated_toll_free':
        return 'Toll Free'
      default:
        return 'Unkown number type'
    }
  }

  render () {
    const { loading, smsAccount } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''

    const p2pNumber = smsAccount.p2p_number
    const p2pFormattedNumber = (p2pNumber) ? '+' + p2pNumber.number.replace('+', '') : ''

    const marketingNumber = smsAccount.marketing_number
    const marketingFormattedNumber = (marketingNumber) ? '+' + marketingNumber.number.replace('+', '') : ''

    return (
      <div className='row'>
        {p2pNumber &&
          <div className='col-xs-6'>
            <div className={'block block-rounded block-bordered ' + blockStyle} >
              <div className='block-header'>
                <h3 className='block-title'>Direct SMS number</h3>
              </div>
              <div className='block-content'>
                <div className='block block-bordered text-center' >
                  <div className='block-content block-content-full bg-gray-lighter border-b'>
                    <div className='h1 font-w700'>
                      {readableMobileNumber(p2pFormattedNumber)}
                    </div>
                    <div className='h5 text-muted text-uppercase push-5-t'>
                      {this.numberTypeStringForNumber(p2pNumber)}
                    </div>
                  </div>
                  <div className='block-content block-content-full block-content-mini'>
                    <p>Outgoing: ${smsAccount.p2p_outbound_cost} per message</p>
                    <p>Incoming: ${smsAccount.p2p_inbound_cost} per message</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {marketingNumber &&
          <div className='col-xs-6'>
            <div className={'block block-rounded block-bordered ' + blockStyle} >
              <div className='block-header'>
                <h3 className='block-title'>Marketing number</h3>
              </div>
              <div className='block-content'>
                <div className='block block-bordered text-center' >
                  <div className='block-content block-content-full bg-gray-lighter border-b'>
                    <div className='h1 font-w700'>
                      {readableMobileNumber(marketingFormattedNumber)}
                    </div>
                    <div className='h5 text-muted text-uppercase push-5-t'>
                      {this.numberTypeStringForNumber(marketingNumber)}
                    </div>
                  </div>
                  <div className='block-content block-content-full block-content-mini'>
                    <p>Outgoing: ${smsAccount.marketing_outbound_cost} per message</p>
                    <p>Incoming: ${smsAccount.marketing_inbound_cost} per message</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

AccountView.propTypes = {
  loading: PropTypes.bool.isRequired,
  smsAccount: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default AccountView
