import PropTypes from 'prop-types'
import React, { Component } from 'react'

import EditProfileForm from './EditProfileForm'

import { errorPanelForErrors } from 'utils/formHelpers'

export class ProfileView extends Component {

  UNSAFE_componentWillMount () {
    const { user, prefillFormForUser } = this.props
    prefillFormForUser(user)
  }

  render () {
    const { updateProfileWithData, updateData, loading, data, user, errors } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Your Profile</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors, true)}
              <EditProfileForm
                onUpdate={updateData}
                updateProfileWithData={updateProfileWithData}
                showModal={this.props.showModal}
                currentModal={this.props.currentModal}
                staffIntegrationLocation={this.props.staffIntegrationLocation}
                staffIntegration={this.props.staffIntegration}
                data={data}
                user={user} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

ProfileView.propTypes = {
  user: PropTypes.object.isRequired,
  updateProfileWithData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  prefillFormForUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  errors: PropTypes.object,
  staffIntegrationLocation: PropTypes.object,
  mindbodyUserId: PropTypes.number,
  staffIntegration: PropTypes.object
}

export default ProfileView
