import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Doughnut } from 'react-chartjs-2'

export default class PieChart extends Component {

  generateOptions () {
    return {
      legend:{
        display: this.props.displayLegend
      }
    }
  }

  render () {
    return (
      <Doughnut
        data={this.props.data}
        redraw={false}
        options={this.generateOptions()} />
    )
  }
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  displayLegend: PropTypes.bool.isRequired
}

PieChart.defaultProps = {
  displayLegend: true
}
