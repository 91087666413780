import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapLawfulProcessingBasesView from '../components/MapLawfulProcessingBasesView'
import * as actions from '../actions'
import * as selectors from '../selectors'
import lawfulProcessingBases from 'modules/Stores/LawfulProcessingBases'

function mapStateToProps (state) {
  return {
    lawfulProcessingBasesToMap: selectors.getLawfulProcessingBases(state),
    lawfulProcessingBases: lawfulProcessingBases.selectors.getLawfulProcessingBases(state),
    lawfulProcessingBasesMaps: selectors.getLawfulProcessingBasesMaps(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addLawfulProcessingBasesMap: actions.addLawfulProcessingBasesMap,
    updateLawfulProcessingBasesMap: actions.updateLawfulProcessingBasesMap
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MapLawfulProcessingBasesView)
