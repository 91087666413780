import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { errorPanelForErrors } from 'utils/formHelpers'

import Modal from 'react-bootstrap-modal'
import { FormFields } from 'modules/components'

const { TextField } = FormFields

export class NewTagModal extends Component {
  state = {
    name: null
  }

  handleChange = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value })
  }

  createTagSelected = () => {
    const { createTag } = this.props
    const { name } = this.state
    createTag(name, this.onSubmitSuccess)
  }

  onSubmitSuccess = () => {
    this.setState({ name: null })
    this.props.hideModal()
  }

  formIsValid = () => {
    const { name } = this.state
    if (!name || name.length < 1) { return false }
    return true
  }

  render () {
    const { currentModal, hideModal, modalName, loading, errors } = this.props
    const { callName } = this.state
    const blockStyle = loading ? 'block block-opt-refresh' : 'block'

    return (
      <div>
        <Modal
          show={currentModal === modalName}
          onHide={hideModal} >
          <div className={blockStyle}>
            <div className='block-header bg-success'>
              <ul className='block-options'>
                <li>
                  <button data-dismiss='modal' type='button' onClick={hideModal} >
                    <i className='si si-close' style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className='block-title' style={{ color: 'white' }}>Let's create a tag</h3>
            </div>
            <div className='block-content'>
              <div className='push-30'>
                <div className='row'>
                  <div className='col-sm-12'>
                    {errorPanelForErrors(errors, true)}
                    <TextField
                      name='name'
                      label='Tag name *'
                      placeholder='Boxing'
                      formFieldStyle=''
                      onChange={this.handleChange}
                      value={callName} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className='btn btn-default pull-left' onClick={hideModal}>
              Cancel
            </button>
            <button className='btn btn-success push-5-r'
              type='button'
              disabled={!this.formIsValid()}
              onClick={this.createTagSelected}>
              <i className='fa fa-plus' />{' '}
              Create tag
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

NewTagModal.propTypes = {
  createTag: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  currentModal: PropTypes.string
}

export default NewTagModal
