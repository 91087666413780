import { request, buildIndexUrl, GET } from "utils/apiUtils";

import store from "./index";

export const getEmailTemplates = (
  designType,
  pageNumber,
  searchTerm = null
) => {
  return dispatch => {
    dispatch(store.actions.setLoading(true));
    function onFailure(payload) {
      dispatch(store.actions.setLoading(false));
      dispatch(store.actions.setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(store.actions.setLoading(false));
      dispatch(store.actions.setEmailTemplates(payload.email_templates));
      dispatch(store.actions.setPageDetails(payload.meta));
    }

    let url = buildIndexUrl(
      "email_template/templates_to_use",
      pageNumber,
      null,
      searchTerm
    );
    url = url + "&design_type=user&detail=true&template_type=" + designType;
    return request(url, GET, null, onSuccess, onFailure);
  };
};
