import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MigrateDealModal from '../components/MigrateDealModal'

import deals from 'modules/Stores/Deals'

import modal from 'modules/Stores/Modal'

import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    deals: deals.selectors.getDeals(state),
    errors: modal.selectors.getModalErrors(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    deleteDeal: actions.deleteDeal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MigrateDealModal)
