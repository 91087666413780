import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import permissions from '../modules/permissions';
import teamMembers from '../modules/teamMembers';

import session from 'modules/Stores/Session';

function mapStateToProps(state) {
  const currentUserRole = session.selectors.getUserRole(state);
  return {
    user: teamMembers.selectors.selectedUser(state),
    loading: permissions.selectors.getLoading(state),
    errors: permissions.selectors.getErrors(state),
    data: permissions.selectors.getData(state),
    isAdmin: session.helpers.userRoleHasOwnerPermissions(currentUserRole),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prefilForm: permissions.actions.prefilForm,
      onInput: permissions.actions.onInput,
      updateUserPermissions: permissions.actions.updateUserPermissions,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(permissions.components.PermissionsView);
