import React, { Component } from "react";
import styled from "styled-components";
import SweetAlert from "react-bootstrap-sweetalert";

export class FooterButtonsView extends Component {
  state = {
    showDanger: false
  };

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true });
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false });
  };

  createIntegration = () => {
    const { selectedLocation, createIntegration } = this.props;
    createIntegration(selectedLocation.id);
  };

  deleteIntegration = () => {
    const {
      deleteIntegration,
      selectedIntegration,
      selectedLocation
    } = this.props;
    deleteIntegration(selectedLocation.id, selectedIntegration.id);
  };

  resetModal = () => {
    const { hideModal, resetSubscriptionModal } = this.props;
    hideModal();
    resetSubscriptionModal(null);
  };

  disableDeleteButton(subscriptions) {
    if (subscriptions === null || subscriptions.length === 0) {
      return false;
    } else if (subscriptions.length > 0) {
      return true;
    }
  }

  render() {
    const {
      selectedIntegration,
      selectedSubscription,
      resetSubscriptionModal,
      subscriptions
    } = this.props;
    const disabledDeleteButton = this.disableDeleteButton(subscriptions);
    return (
      <div className="row">
        {!selectedSubscription && selectedIntegration && (
          <ButtonContainer className="col-xs-6 text-left">
            <button
              className="wizard-prev btn btn-danger"
              onClick={this.showDangerPopup}
              disabled={disabledDeleteButton}
            >
              Delete
            </button>
          </ButtonContainer>
        )}
        {!selectedIntegration && (
          <ButtonContainer className="col-xs-12 text-right">
            <CreateIntegrationButton
              className={"btn btn-default"}
              onClick={this.createIntegration}
            >
              Create
            </CreateIntegrationButton>
          </ButtonContainer>
        )}
        {selectedIntegration && !selectedSubscription && (
          <ButtonContainer className="col-xs-6 text-right">
            <button
              className="wizard-next btn btn-default"
              onClick={this.resetModal}
            >
              Done
            </button>
          </ButtonContainer>
        )}
        {selectedIntegration && selectedSubscription && (
          <ButtonContainer className="col-xs-12 text-right">
            <button
              className="wizard-next btn btn-default"
              onClick={() => resetSubscriptionModal(null)}
            >
              Go back
            </button>
          </ButtonContainer>
        )}
        {this.state.showDanger && (
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="danger"
            confirmBtnText="Delete Integration"
            title="Are you Sure?"
            onConfirm={this.deleteIntegration}
            onCancel={this.dismissPopups}
          >
            Your Calendly integration will no longer work.
          </SweetAlert>
        )}
      </div>
    );
  }
}

const ButtonContainer = styled.div``;
const CreateIntegrationButton = styled.button``;

export default FooterButtonsView;
