import React, { Component } from "react";
import PropTypes from "prop-types";
import ChartContainer from "../containers/ChartContainer";
import { EmptyView } from 'modules/components'
import styled from 'styled-components';

class ActivityBreakdownView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString } = this.props;
    fetchWithQueryString(queryString);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      const { fetchWithQueryString, queryString } = nextProps;
      fetchWithQueryString(queryString);
    }
  }

  render() {
    const { loading, isEmpty } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    
    return (
      <div className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">ACTIVITY BREAKDOWN</h3>
        </div>
        <div className="block-content push-50" >
          {isEmpty && (
            <div className="row">
              <EmprtViewContainer className="col-sm-10 col-sm-offset-1" >
                <EmptyView
                  colorThemeClass="primary"
                  iconClass="fa fa-bar-chart fa-3x"
                  title="No data for these filters"
                  subtitle="Try selecting a different time period."
                />
              </EmprtViewContainer>
            </div>
          )}
          {!isEmpty && <ChartContainer />}
        </div>
      </div>
    );
  }
}

ActivityBreakdownView.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  queryString: PropTypes.string.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired
};

export default ActivityBreakdownView;

const EmprtViewContainer = styled.div`
  margin-top: 25px;  
`;