import * as actionTypes from './actionTypes'

import { safePropertyForObject } from 'utils/formHelpers'

const BILLING_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM]: (state, action) => {
    return initialStateForCompany(action.company)
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    const newData = action.data
    return { ...state, data: newData }
  },
  [actionTypes.UPDATING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  },
  [actionTypes.LOGO_WAS_SELECTED]: (state, action) => {
    const updatedLogo = { ...state.logo, file: action.file }
    return { ...state, logo: updatedLogo }
  }
}

function initialStateForCompany (company) {
  return {
    errors: null,
    loading: false,
    data: {
      fields: {
        title: {
          value: safePropertyForObject(company, 'title'),
          isValid: true
        },
        userDisplayName: {
          value: safePropertyForObject(company, 'user_display_name'),
          isValid: true
        },
        url: {
          value: safePropertyForObject(company, 'url'),
          isValid: true
        }
      }
    },
    logo: {
      loading: false,
      file: null,
      progress: null
    }
  }
}

export default function reducer (state = initialStateForCompany(null), action) {
  const handler = BILLING_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
