import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CallScheduleStepsModal from '../components/CallSchedule/CallScheduleStepsModal'

import * as actions from '../callScheduleActions'
import * as selectors from '../selectors'
import modal from 'modules/Stores/Modal'

import { CALL_SCHEDULE_MODAL } from '../constants'

function mapStateToProps (state, ownProps) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    callScheduleSteps: selectors.getCallScheduleSteps(state, ownProps.pathToLeadDetail),
    modalName: CALL_SCHEDULE_MODAL
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    fetchCallScheduleSteps: actions.getStepsForCallSchedule,
    removeFromSchedule: actions.removeFromSchedule
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CallScheduleStepsModal)
