import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CalendarSettingsView from '../components/CalendarSettingsView'

function mapStateToProps (state) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSettingsView)
