import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserData from 'modules/Stores/UserData'
import ChartView from '../components/ChartView'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const flatSortedData = generateSortedHeadersAndCounts(selectors.getData(state))
  const timeData = timeAndSuffixFromSecconds(flatSortedData.counts)
  return {
    title: 'Activity Breakdown',
    data: timeData.data,
    loading: selectors.getLoading(state),
    labels: flatSortedData.headers,
    isEmpty: selectors.isEmpty()(state),
    dataSetTitle: UserData.selectors.companyName(state),
    redraw: false,
    yScaleTitle: timeData.suffix
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

function timeAndSuffixFromSecconds (secondsData) {
  let data = secondsData
  const dividableThreshold = Math.floor(secondsData.length / 2)
  let suffix = 'seconds'

  function isOverThreshold (value) {
    return value >= 1
  }

  const minutesData = data.map((m) => m / 60)
  let numberOfDividables = minutesData.filter(isOverThreshold).length
  if (dividableThreshold < numberOfDividables) {
    data = minutesData
    suffix = 'Minutes'
  } else {
    return { data: data, suffix: suffix }
  }

  const hoursData = data.map((m) => m / 60)
  numberOfDividables = hoursData.filter(isOverThreshold).length
  if (dividableThreshold < numberOfDividables) {
    data = hoursData
    suffix = 'Hours'
  } else {
    return { data: data, suffix: suffix }
  }

  const daysData = data.map((m) => m / 24)
  numberOfDividables = daysData.filter(isOverThreshold).length
  if (dividableThreshold < numberOfDividables) {
    data = daysData
    suffix = 'Days'
  } else {
    return { data: data, suffix: suffix }
  }

  const weeksData = data.map((m) => m / 7)
  numberOfDividables = weeksData.filter(isOverThreshold).length
  if (dividableThreshold < numberOfDividables) {
    data = weeksData
    suffix = 'Weeks'
  } else {
    return { data: data, suffix: suffix }
  }

  return { data: data, suffix: suffix }
}

function generateSortedHeadersAndCounts (summaryData) {
  let headers = []
  let counts = []
  Object.keys(summaryData).forEach((summaryDataKey) => {
    const summaryDataEntry = summaryData[summaryDataKey]
    if (summaryDataEntry.label) {
      headers.push(summaryDataEntry.label)
      counts.push(summaryDataEntry.value)
    }
  })
  return {
    headers: headers,
    counts: counts
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartView)
