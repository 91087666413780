import React, { FC } from 'react';
import styled from 'styled-components';

import { pluralizationHelper } from 'modules/Helpers/PluralizationHelpers';
import { ILead } from 'types';

import STRINGS from 'strings';

interface LeadChekInsPanelProps {
  lead: ILead;
}

const LeadCheckInsPanel: FC<LeadChekInsPanelProps> = (props) => {
  const Strings = STRINGS.LeadDetail;
  const { lead } = props;

  return (
    <Panel>
      <Icon>
        <i className={'si si-pointer'} />
      </Icon>
      <BodyDescription>
        <strong>{pluralizationHelper(Strings.checkIns, lead.visit_count)}</strong>
      </BodyDescription>
    </Panel>
  );
};

export default LeadCheckInsPanel;

const Panel = styled.div`
  text-align: center;
  margin: 0px 0px 20px 0px;
  height: 68px;
  max-width: 100%;
  overflow-x: visible;
  transition: opacity 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #e9e9e9;
  box-shadow: none;
`;

const Icon = styled.div`
  font-size: 2.1em;
  color: #999;
  margin-right: 6px;
`;

const BodyDescription = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;
