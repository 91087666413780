import { NAME, LEAD_HEARD_ABOUT_METHOD, LEAD_SOURCE_TYPE } from "./constants";
import { createSelector } from "reselect";

export const getErrors = (state) => state.leadsListReport[NAME].errors;
export const getLoading = (state) => state.leadsListReport[NAME].loading;
export const getData = (state) => state.leadsListReport[NAME].data;
export const getReportType = (state) => state.leadsListReport[NAME].reportType;

export const reportUrl = createSelector([getReportType], (reportType) => {
  switch (reportType) {
    case LEAD_HEARD_ABOUT_METHOD:
      return "leads_reports/lead_heard_about_method_numbers";
    case LEAD_SOURCE_TYPE:
      return "leads_reports/lead_source_numbers";
    default:
      return "";
  }
});

export const sortedData = createSelector([getData], (data) => {
  const sortedData = data.sort(function (a, b) {
    return b.count - a.count;
  });
  if (sortedData.length === 0) {
    return sortedData;
  }
  const total = sortedData
    .map((dataItem) => dataItem.count)
    .reduce((prev, next) => prev + next);
  return [...sortedData, { title: "Total", count: total }];
});

export const reportTitle = createSelector([getReportType], (reportType) => {
  switch (reportType) {
    case LEAD_HEARD_ABOUT_METHOD:
      return "Number of leads by heard about methods";
    case LEAD_SOURCE_TYPE:
      return "Number of leads by sources";
    default:
      return "";
  }
});

export const chartData = createSelector([getData], (data) => {
  return {
    datasets: [
      {
        data: data.map((row) => row.count),
        backgroundColor: data.map((row, index) =>
          backgroundColorForRow(row, index)
        ),
      },
    ],
    labels: data.map((row) => row.title),
  };
});

function backgroundColorForRow(row, index) {
  const colors = [
    "#CCDCF1",
    "#98B9E3",
    "#5C90D2",
    "#3E4A59",
    "#2C343F",
    "#E4DCF2",
    "#C7B7E4",
    "#A48AD4",
    "#FFB8B8",
    "#FF8F8F",
    "#FF6B6B",
    "#A8DED8",
    "#83D0C7",
    "#44B4A6",
    "#C4F2F9",
    "#7FE3F2",
    "#14ADC4",
    "#FFB9D0",
    "#FF90B5",
    "#FF6C9D",
  ];
  if (index > colors.length - 1) {
    return "#98B9E3";
  } else {
    return colors[index];
  }
}

export function convertUpdatedQueryStringToCreated(queryString) {
  return queryString
    .replace(/status_updated_before/g, "created_before")
    .replace(/status_updated_after/g, "created_after");
}
