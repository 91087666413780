import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { UserAvatar } from 'modules/components/Avatars'

import moment from 'moment'
import 'moment-timezone'

export class TeamLeaderboardView extends Component {

  UNSAFE_componentWillMount () {
    const { fetchSalesTeamForLocation, location } = this.props

    fetchSalesTeamForLocation(location)
  }

  render () {
    const { salesTeam, user, salesFunnelSteps, timezone } = this.props
    const salesTeamView = salesTeam.map((salesPerson, index) => {
      const salesPersonUser = salesPerson.user
      const closeRate = salesPerson.sales_metrics.close_rate
      const assignedCount = salesPerson.sales_metrics.assigned_count
      const isCurrentuser = (salesPersonUser.id === user.id)
      const lastRowStyle = isCurrentuser ? 'ribbon ribbon-modern ribbon-primary' : ''

      const { given_name: givenName, surname } = salesPerson.user;
      let userNameToDisplay = givenName;
      if (surname) userNameToDisplay = `${givenName} ${surname}`;

      return (
        <tr key={salesPersonUser.id} id={index} onClick={this.salesPersonClicked}>
          <td className='text-center'>
            {parseInt(index + 1)}
          </td>
          <td id={index} className='text-center'>
            <UserAvatar avatarImage={salesPerson.user.user_avatar} className='img-avatar48' />
          </td>
          <td id={index} className='font-w600'>{userNameToDisplay}</td>
          <td id={index} className='font-w600'>{assignedCount}</td>
          <td id={index} className={'font-w600 ' + lastRowStyle}>
            {isCurrentuser &&
              <div className='ribbon-box font-w200'>
                You
              </div>
            }
            {closeRate.toFixed(2)}%
          </td>
        </tr>
      )
    })

    const teamHeaderView = () =>
      <thead>
        <tr>
          <th className='text-center' style={{ width: '12px' }}>
            #
          </th>
          <th className='text-center' style={{ width: '12px' }}>
            <i className='si si-user' />
          </th>
          <th>Name</th>
          <th>Leads Assigned</th>
          <th>Close Rate</th>
        </tr>
      </thead>

    return (
      <div className='block block-rounded block-bordered'>
        <div className='block-header'>
          <h3 className='block-title'>Team Leaderboard For {moment().tz(timezone).format('MMMM')}</h3>
        </div>
        <div className='block-content block-content-full'>
          {salesFunnelSteps && salesTeam.length > 0 &&
            <div className='table-responsive'>
              <table className='table table-vcenter'>
                {teamHeaderView()}
                <tbody>
                  {salesTeamView}
                </tbody>
              </table>
            </div>
           }
        </div>
      </div>
    )
  }
}

TeamLeaderboardView.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object),
  salesTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchSalesTeamForLocation: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
}

export default TeamLeaderboardView
