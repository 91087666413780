import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectHeardAboutMethodTableRow } from "./SelectHeardAboutMethodTableRow";

export class SelectHeardAboutMethodTableView extends Component {
  render() {
    const {
      heardAboutMethods,
      selectedHeardAboutMethod,
      onHeardAboutMethodSelect
    } = this.props;
    const rows = heardAboutMethods.map((heardAboutMethod, index) => {
      const selectedId = selectedHeardAboutMethod
        ? selectedHeardAboutMethod.id
        : null;
      const selected = selectedId === heardAboutMethod.id;
      return (
        <SelectHeardAboutMethodTableRow
          key={index}
          heardAboutMethod={heardAboutMethod}
          heardAboutMethodSelected={selected}
          onHeardAboutMethodSelect={onHeardAboutMethodSelect}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectHeardAboutMethodTableView.propTypes = {
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedHeardAboutMethod: PropTypes.object,
  onHeardAboutMethodSelect: PropTypes.func.isRequired
};

export default SelectHeardAboutMethodTableView;
