import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = state => state.billingSettings[NAME].loading
export const getErrors = state => state.billingSettings[NAME].errors
export const getInvoices = state => state.billingSettings[NAME].invoices
export const getSelectedInvoiceId = state => state.billingSettings[NAME].selectedInvoiceId
export const getPageCount = state => state.billingSettings[NAME].pageCount
export const getCurrentPage = state => state.billingSettings[NAME].currentPage

export const selectedInvoice = createSelector(
  [ getInvoices, getSelectedInvoiceId ],
  (invoices, selectedInvoiceId) => {
    if (selectedInvoiceId && invoices.length > 0) {
      const index = invoices.findIndex(invoice => parseInt(invoice.id) === parseInt(selectedInvoiceId))
      return invoices[index]
    }
  }
)
