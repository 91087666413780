import { NAME } from './constants'

export const UPDATING_PROFILE = NAME + '/UPDATING_PROFILE'
export const UPDATE_PROFILE_SUCCESS = NAME + '/UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILED = NAME + '/UPDATE_PROFILE_FAILED'

export const UPDATE_FORM_DATA = NAME + '/UPDATE_FORM_DATA'

export const PREFIL_FORM_FOR_USER = NAME + '/PREFIL_FORM_FOR_USER'

export const AVATAR_WAS_SELECTED = NAME + '/AVATAR_WAS_SELECTED'

export const SET_AVAILABLE_SITE_IDS = NAME + '/SET_AVAILABLE_SITE_IDS'
