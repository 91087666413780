import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class RegionTableRow extends Component {

  rowSelected = (event) => {
    const { region, regionSelected, selectRegion, deselectRegion } = this.props
    if (regionSelected) {
      deselectRegion(region)
    } else {
      selectRegion(region)
    }
  }

  render () {
    const { region, regionSelected } = this.props
    return (
      <tr className='selectable' onClick={() => this.rowSelected(region)}>
        <td className='text-center'>
          <label className='css-input css-checkbox css-checkbox-primary'>
            <input type='checkbox' disabled checked={regionSelected} readOnly /><span />
          </label>
        </td>
        <td>{region.name}</td>
        <td className='text-center'>
          {region.location_count}
        </td>
      </tr>
    )
  }
}

RegionTableRow.propTypes = {
  region: PropTypes.object.isRequired,
  regionSelected: PropTypes.bool.isRequired,
  selectRegion: PropTypes.func.isRequired,
  deselectRegion: PropTypes.func.isRequired
}
