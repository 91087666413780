import React from "react";
import { Link } from "react-router-dom";

export const NotFoundView = () => (
  <div>
    <div className="content bg-white text-center pulldown overflow-hidden">
      <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          <h1 className="font-s128 font-w300 text-city animated flipInX">
            404
          </h1>
          <h2 className="h3 font-w300 push-50 animated fadeInUp">
            We are sorry but the page you are looking for was not found..
          </h2>
        </div>
      </div>
    </div>
    <div className="content pulldown text-muted text-center">
      <Link to="/secure/dashboard" className="link-effect">
        Back to Dashboard
      </Link>
    </div>
  </div>
);

export default NotFoundView;
