import { request, GET } from 'utils/apiUtils'

export const getExportUrlWithString = (urlString) => {
  return dispatch => {
    const windowRef = window.open()

    function onFailure (errors) {}

    function onSuccess (payload) {
      windowRef.location = payload.url
    }

    return request(urlString, GET, null, onSuccess, onFailure)
  }
}
