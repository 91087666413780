import { NAME } from './constants'

export const RESET = NAME + '/RESET'
export const SET_TAB_INDEX = NAME + '/SET_TAB_INDEX'
export const PREFIL_FORM = NAME + '/PREFIL_FORM'
export const SET_FACEBOOK_USER = NAME + '/SET_FACEBOOK_USER'
export const SET_FACEBOOK_PAGES = NAME + '/SET_FACEBOOK_PAGES'
export const SET_VERIFIED = NAME + '/SET_VERIFIED'
export const SET_SELECTED_PAGE_ID = NAME + '/SET_SELECTED_PAGE_ID'
export const SET_INTEGRATION = NAME + '/SET_INTEGRATION'
export const SET_PERMISSION_SET = NAME + '/SET_PERMISSION_SET'

export const ADD_LINKED_FACEBOOK_PAGE = NAME + '/ADD_LINKED_FACEBOOK_PAGE'
export const DELETE_LINKED_FACEBOOK_PAGE = NAME + '/DELETE_LINKED_FACEBOOK_PAGE'
export const UPDATE_LINKED_FACEBOOK_PAGE = NAME + '/UPDATE_LINKED_FACEBOOK_PAGE'
