import PropTypes from "prop-types";
import React, { Component } from "react";
import RegionTableView from "./RegionTableView/RegionTableView";

import { PageSelection } from "modules/components";

export class RegionSelectView extends Component {
  pageClicked = (pageNumber) => {
    const { updateCurrentPage } = this.props;
    updateCurrentPage(pageNumber);
  };

  render() {
    const { regions, selectedRegionIds } = this.props;

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-content">
          <div>
            <RegionTableView
              regions={regions}
              selectedRegionIds={selectedRegionIds}
              selectRegion={this.props.selectRegion}
              deselectRegion={this.props.deselectRegion}
            />
          </div>
          {this.props.pageCount > 1 && (
            <div className="text-center push">
              <PageSelection
                pageCount={this.props.pageCount}
                currentPage={this.props.currentPage}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

RegionSelectView.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRegionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectRegion: PropTypes.func.isRequired,
  deselectRegion: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
};

export default RegionSelectView;
