import PropTypes from "prop-types";
import React, { Component } from "react";
import { QuickInfoBlock } from "modules/components";

export default class QuickInfoBlocks extends Component {
  render() {
    const { blockData, loading } = this.props;

    const valueOne = blockData[0].value ? blockData[0].value : 0;
    const valueTwo = blockData[1].value ? blockData[1].value : 0;
    const valueThree = blockData[2].value ? blockData[2].value : 0;
    const valueFour = blockData[3].value ? blockData[3].value : 0;

    return (
      <div className="row">
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={blockData[0].title}
            number={valueOne}
            loading={loading}
            suffix={blockData[0].suffix}
            tooltipText={blockData[0].tooltipText}
            tooltipId={blockData[0].tooltipId}
          />
        </div>
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={blockData[1].title}
            number={valueTwo}
            loading={loading}
            suffix={blockData[1].suffix}
            tooltip={blockData[1].tooltip}
            tooltipText={blockData[1].tooltipText}
            tooltipId={blockData[1].tooltipId}
          />
        </div>
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={blockData[2].title}
            number={valueThree}
            loading={loading}
            suffix={blockData[2].suffix}
            tooltip={blockData[2].tooltip}
            tooltipText={blockData[2].tooltipText}
            tooltipId={blockData[2].tooltipId}
          />
        </div>
        <div className="col-xs-6 col-sm-3">
          <QuickInfoBlock
            title={blockData[3].title}
            number={valueFour}
            loading={loading}
            suffix={blockData[3].suffix}
            tooltip={blockData[3].tooltip}
            tooltipText={blockData[3].tooltipText}
            tooltipId={blockData[3].tooltipId}
          />
        </div>
      </div>
    );
  }
}

QuickInfoBlocks.propTypes = {
  blockData: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
};
