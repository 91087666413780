import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActivityReportView } from '../components/ActivityReportView'
import header from '../modules/header'

import contactCalls from '../modules/contactCalls'
import appointments from '../modules/appointments'
import tasks from '../modules/tasks'

function mapStateToProps (state) {
  return {
    selectedReport: header.selectors.getSelectedReport(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onReportChange: header.actions.setSelectedReport,
    exportContactCalls: contactCalls.actions.generateFullReport,
    exportAppointment: appointments.actions.generateFullReport,
    exportTasks: tasks.actions.generateFullReport
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReportView)
