import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectHeardAboutMethodTableView from "./TableView/SelectHeardAboutMethodTableView";
import PagesContainer from "../containers/PagesContainer";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView
} from "modules/components";

export class MultiHeardAboutMethodSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { heardAboutMethods } = this.props;
    if (heardAboutMethods.length > 0) {
      return;
    }
    this.getHeardAboutMethodsWithPageNumber(1);
  }

  getHeardAboutMethodsWithPageNumber = pageNumber => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getHeardAboutMethodsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getHeardAboutMethods } = this.props;
    getHeardAboutMethods(1, searchTerm);
  };

  render() {
    const {
      heardAboutMethods,
      pageDetails,
      loading,
      selectedHeardAboutMethodIds
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading} bordered>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"heardAboutMethodSearch"}
            onUpdate={this.searchUpdated}
          />
        </div>
        {heardAboutMethods.length > 0 && (
          <div>
            <SelectHeardAboutMethodTableView
              heardAboutMethods={heardAboutMethods}
              selectedHeardAboutMethodIds={selectedHeardAboutMethodIds}
              selectHeardAboutMethod={this.props.selectHeardAboutMethod}
              deselectHeardAboutMethod={this.props.deselectHeardAboutMethod}
            />
          </div>
        )}
        {heardAboutMethods.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesContainer pageClicked={this.pageClicked} />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

MultiHeardAboutMethodSelectView.propTypes = {
  getHeardAboutMethods: PropTypes.func.isRequired,
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedHeardAboutMethodIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectHeardAboutMethod: PropTypes.func.isRequired,
  deselectHeardAboutMethod: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired
};

export default MultiHeardAboutMethodSelectView;
