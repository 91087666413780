import React from "react";
import MultiRegionSelectContainer from "modules/components/MultiRegionSelect/containers/MultiRegionSelectContainer";

const RegionsSection = ({
  showModal,
  SELECT_REGIONS_MODAL,
  regionFiltersClass,
  regionsTitle,
  regionIdsName,
  onUpdate,
  locationIdsData
}) => {
  
  function showSelectRegionsModal() {
    showModal(SELECT_REGIONS_MODAL);
  }
  
  return (
    <div>
      <div
        className={`btn ${regionFiltersClass}`}
        type="button"
        onClick={showSelectRegionsModal}
      >
        {regionsTitle}
      </div>

      <MultiRegionSelectContainer
        modalName={SELECT_REGIONS_MODAL}
        sectionName={regionIdsName}
        onUpdate={onUpdate}
        data={locationIdsData}
      />
    </div>
  );
};

export default RegionsSection;
