import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getCSV = state => state.importLeads[NAME].csv.file
export const getLocationId = state => state.importLeads[NAME].locationId
export const getLocationName = state => state.importLeads[NAME].locationName
export const getErrorString = state => state.importLeads[NAME].errorString
export const getEnhancedPrivacy = state => state.importLeads[NAME].enhancedPrivacy

export const isReady = createSelector(
  [ getCSV, getLocationId ],
  (csv, locationId) => {
    if (!csv) return false
    if (!locationId) return false
    return true
  }
)
