import { connect } from 'react-redux'
import actionAutomationRules from '../modules/actionAutomationRules'

import locations from 'modules/Stores/Locations'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    location: selectedLocation
  }
}

export default connect(mapStateToProps)(actionAutomationRules.components.ActionAutomationRulesView)
