import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Bar } from 'react-chartjs-2'

export default class ChartView extends Component {

  generateOptions () {
    return {
      tooltips: {
        enabled: true
      },
      hover :{
        animationDuration:0
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero:true,
            fontSize:11,
            display: true
          },
          scaleLabel:{
            display:true
          }
        }]
      },
      legend:{
        display: false
      }
    }
  }

  render () {
    const { labels, data } = this.props
    const chartData = {
      labels: labels,
      datasets: data
    }

    return (
      <Bar
        data={chartData}
        redraw={true}
        options={this.generateOptions()} />
    )
  }
}

ChartView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}
