import {
  isInteger,
  isMinLength,
  isMaxLength,
  isPresent,
} from "utils/validation";

export const SalesTargetsValidator = {
  timeType: [
    {
      rule: isPresent,
      errorMessage: "How do you want to measure the below metrics?",
    },
  ],
  workingDaysOverride: [
    {
      rule: isInteger,
      errorMessage: "it must be a whole number",
    },
  ],
  referralCount: [
    {
      rule: isInteger,
      errorMessage: "it must be a whole number",
    },
  ],
  closeRate: [
    {
      rule: isInteger,
      errorMessage: "It should be a value between 0 and 100",
    },
    {
      rule: isMinLength(0),
      errorMessage: "It should be a value between 0 and 100",
    },
    {
      rule: isMaxLength(100),
      errorMessage: "It should be a value between 0 and 100",
    },
  ],
  initialCallTime: [
    {
      rule: isInteger,
      errorMessage: "It should be a numeric value above 0",
    },
    {
      rule: isMinLength(0),
      errorMessage: "It should be a value above 0",
    },
  ],
  initialCallCount: [
    {
      rule: isInteger,
      errorMessage: "It should be a value between 0 and 100",
    },
    {
      rule: isMinLength(0),
      errorMessage: "It should be a value between 0 and 100",
    },
    {
      rule: isMaxLength(100),
      errorMessage: "It should be a value between 0 and 100",
    },
  ],
  presentationCount: [
    {
      rule: isInteger,
      errorMessage: "It should be a value above 0",
    },
    {
      rule: isMinLength(0),
      errorMessage: "It should be a value above 0",
    },
  ],
  appointmentCount: [
    {
      rule: isInteger,
      errorMessage: "It should be a value above 0",
    },
    {
      rule: isMinLength(0),
      errorMessage: "It should be a value above 0",
    },
  ],
  newLeadsCount: [
    {
      rule: isInteger,
      errorMessage: "It should be a whole numerical value",
    },
    {
      rule: isPresent,
      errorMessage: "How many new leads should sales people get?",
    },
  ],
  totalSales: [
    {
      rule: isInteger,
      errorMessage: "It should be a whole numerical value",
    },
    {
      rule: isMinLength(0),
      errorMessage: "It should be a value above 0",
    },
  ],
};
