import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SelectColorValue extends Component {

  remove = () => {
    const { value, onRemove } = this.props
    onRemove(value)
  }

  colorLuminance (hex, lum) {
    hex = String(hex).replace(/[^0-9a-f]/gi, '')
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    lum = lum || 0

    var rgb = '#'
    var i
    var c
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16)
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
      rgb += ('00' + c).substr(c.length)
    }

    return rgb
  }

  render () {
    const { children, value, color } = this.props
    const darkerColor = this.colorLuminance(color, -0.5)
    return (
      <div className='Select-value' title={value.title}
        style={{
          color: '#fff',
          borderColor: darkerColor,
          backgroundColor: color
        }}>
        <span className='Select-value-icon'
          aria-hidden='true'
          onMouseDown={this.remove}>
          &times;
          </span>
        <span className='Select-value-label'>
          {children}
        </span>
      </div>
    )
  }
}

SelectColorValue.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  placeholder: PropTypes.string
}

export default SelectColorValue
