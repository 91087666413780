import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EmptyView } from 'modules/components'

export default class ChartBox extends Component {

  render () {
    const { title, children } = this.props
    const loading = (this.props.loading) || false
    const isEmpty = (this.props.isEmpty) || false
    const chartIcon = (this.props.chartIcon) || 'fa fa-bar-chart'
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'
    return (
      <div className={blockStyle} >
        <div className='block-header'>
          <h3 className='block-title'>{title}</h3>
        </div>
        <div className='block-content block-content-full text-center' style={{ height: '316px' }}>
          {!isEmpty &&
            children
          }
          {isEmpty &&
            <div style={{ paddingTop: '60px' }}>
              <EmptyView
                colorThemeClass='primary'
                iconClass={chartIcon}
                title='No data for these settings'
                subtitle="Perhaps you're new or need more time to enter data into the system." />
            </div>
          }
        </div>
      </div>
    )
  }
}

ChartBox.propTypes = {
  title: PropTypes.string.isRequired,
  children : PropTypes.element.isRequired,
  loading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  chartIcon: PropTypes.string
}
