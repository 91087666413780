export function generateStore(name, initialState) {
  const ACTION_HANDLERS = generateActionHandlers(name, initialState);
  return {
    name: name,
    constants: { NAME: name },
    reducer: generateReducer(initialState, ACTION_HANDLERS),
    selectors: generateSelectors(name, initialState),
    actions: generateDefaultActions(name, initialState),
  };
}

export function generateActionHandlers(name, initialState) {
  const ACTION_HANDLERS = {};
  const stateKeys = Object.keys(initialState);
  stateKeys.forEach((key) => {
    const actionName = generateActionHandlerKey(
      SIMPLE_ACTION_TYPES.SET.key,
      key
    );
    const actionValue = generateActionHandlerValue(name, actionName);
    ACTION_HANDLERS[actionValue] = (state, action) => {
      return { ...state, [key]: action[key] };
    };

    const value = initialState[key];
    if (Array.isArray(value)) {
      const singleKey = unPluralizeWord(key);

      Object.keys(ARRAY_ACTION_TYPES).forEach((actionKey) => {
        const reducerAction = ARRAY_ACTION_TYPES[actionKey];
        const actionName = reducerAction.actionHandlerFunction(
          reducerAction.key,
          singleKey
        );
        const actionValue = generateActionHandlerValue(name, actionName);
        ACTION_HANDLERS[actionValue] = (state, action) => {
          const oldArray = state[key];
          const newArray = reducerAction.arrayGenerator(
            oldArray,
            action,
            singleKey
          );
          return { ...state, [key]: newArray };
        };
      });
    }
    if (key === PAGE_DETAILS_KEY) {
      ACTION_HANDLERS[actionValue] = (state, action) => {
        return {
          ...state,
          pageDetails: {
            pageCount: action.pageDetails.total_pages,
            currentPage: action.pageDetails.current_page,
            totalCount: action.pageDetails.total_count,
          },
        };
      };
    }
  });
  const resetName = `${name}/RESET`;
  const resetValue = generateActionHandlerValue(name, resetName);
  ACTION_HANDLERS[resetValue] = () => {
    return initialState;
  };

  return ACTION_HANDLERS;
}

export function generateSelectors(name, initialState) {
  const selectors = {};

  const stateKeys = Object.keys(initialState);
  stateKeys.forEach((key) => {
    const stateKey = `get${capitalizeFirstLetter(key)}`;
    selectors[stateKey] = (state) => state[name][key];
  });

  if (hasPageDetails(initialState)) {
    Object.keys(defaultPageObject).forEach((pageKey) => {
      const stateKey = `get${capitalizeFirstLetter(pageKey)}`;
      selectors[stateKey] = (state) => state[name].pageDetails[pageKey];
    });
  }

  return selectors;
}

export function generateReducer(initialState, actionHandlers) {
  let updatedInitialState = initialState;
  if (hasPageDetails(initialState)) {
    updatedInitialState[PAGE_DETAILS_KEY] = defaultPageObject;
  }
  const reducer = function (state = updatedInitialState, action) {
    const handler = actionHandlers[action.type];

    return handler ? handler(state, action) : state;
  };
  return reducer;
}

export function generateDefaultActions(name, initialState) {
  const actions = {};
  const stateKeys = Object.keys(initialState);

  stateKeys.forEach((key) => {
    const functionName = `${SIMPLE_ACTION_TYPES.SET.key}${capitalizeFirstLetter(
      key
    )}`;

    const actionName = generateActionHandlerKey(
      SIMPLE_ACTION_TYPES.SET.key,
      key
    );
    const actionValue = generateActionHandlerValue(name, actionName);
    actions[functionName] = (value) => {
      return {
        type: actionValue,
        [key]: value,
      };
    };

    const value = initialState[key];
    if (Array.isArray(value)) {
      const singleKey = unPluralizeWord(key);

      Object.keys(ARRAY_ACTION_TYPES).forEach((actionKey) => {
        const action = ARRAY_ACTION_TYPES[actionKey];
        const actionName = action.actionHandlerFunction(action.key, singleKey);
        const actionValue = generateActionHandlerValue(name, actionName);
        const functionName = generateFunctionName(action, singleKey);
        actions[functionName] = (v1, v2) => {
          const index = action.useIndex ? v1 : undefined;
          const value = action.useIndex ? v2 : v1;
          return {
            type: actionValue,
            [singleKey]: value,
            index: index,
          };
        };
      });
    }
  });

  const resetName = `${name}/RESET`;
  const resetValue = generateActionHandlerValue(name, resetName);

  actions["reset"] = () => {
    return {
      type: resetValue,
    };
  };

  return actions;
}

function generateFunctionName(action, singleKey) {
  if (action.useIndex) {
    return `${action.key}${capitalizeFirstLetter(singleKey)}AtIndex`;
  }
  if (action.useId) {
    return `${action.key}${capitalizeFirstLetter(singleKey)}WithId`;
  }
  return `${action.key}${capitalizeFirstLetter(singleKey)}`;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function generateActionHandlerKey(actionName, key) {
  return `${actionName.toUpperCase()}_${key.toUpperCase()}`;
}

function generateIndexActionHandlerKey(actionName, key) {
  return `${actionName.toUpperCase()}_${key.toUpperCase()}_AT_INDEX`;
}

function generateIdHandlerKey(actionName, key) {
  return `${actionName.toUpperCase()}_${key.toUpperCase()}_WITH_ID`;
}

function unPluralizeWord(word) {
  return word.slice(0, -1);
}

function generateActionHandlerValue(name, actionName) {
  return `${name}/${actionName}`;
}

function hasPageDetails(initialState) {
  return Object.keys(initialState).indexOf(PAGE_DETAILS_KEY) > -1;
}

const PAGE_DETAILS_KEY = "pageDetails";
const defaultPageObject = {
  currentPage: 1,
  totalPages: 0,
  totalCount: 0,
};

const SIMPLE_ACTION_TYPES = {
  SET: {
    key: "set",
  },
};

const ARRAY_ACTION_TYPES = {
  ADD: {
    key: "add",
    actionHandlerFunction: generateActionHandlerKey,
    useIndex: false,
    useId: false,
    arrayGenerator: (oldArray, action, singleKey) => {
      return [action[singleKey], ...oldArray.slice(action.index + 1)];
    },
  },
  REMOVE: {
    key: "remove",
    actionHandlerFunction: generateActionHandlerKey,
    useIndex: false,
    useId: false,
    arrayGenerator: (oldArray, action, singleKey) => {
      const value = action[singleKey];
      const index = oldArray.indexOf(value);
      return [...oldArray.slice(0, index), ...oldArray.slice(index + 1)];
    },
  },
  INSERT_AT_INDEX: {
    key: "insert",
    actionHandlerFunction: generateIndexActionHandlerKey,
    useIndex: true,
    useId: false,
    arrayGenerator: (oldArray, action, singleKey) => {
      return [
        ...oldArray.slice(0, action.index),
        action[singleKey],
        ...oldArray.slice(action.index),
      ];
    },
  },
  REMOVE_AT_INDEX: {
    key: "remove",
    actionHandlerFunction: generateIndexActionHandlerKey,
    useIndex: true,
    useId: false,
    arrayGenerator: (oldArray, action) => {
      return [
        ...oldArray.slice(0, action.index),
        ...oldArray.slice(action.index + 1),
      ];
    },
  },
  REPLACE_AT_INDEX: {
    key: "replace",
    actionHandlerFunction: generateIndexActionHandlerKey,
    useIndex: true,
    useId: false,
    arrayGenerator: (oldArray, action, singleKey) => {
      return [
        ...oldArray.slice(0, action.index),
        action[singleKey],
        ...oldArray.slice(action.index + 1),
      ];
    },
  },
  REMOVE_WITH_ID: {
    key: "remove",
    actionHandlerFunction: generateIdHandlerKey,
    useIndex: false,
    useId: true,
    arrayGenerator: (oldArray, action, singleKey) => {
      const value = action[singleKey];
      const index = indexForCollection(oldArray, value);
      return [...oldArray.slice(0, index), ...oldArray.slice(index + 1)];
    },
  },
  REPLACE_WITH_ID: {
    key: "replace",
    actionHandlerFunction: generateIdHandlerKey,
    useIndex: false,
    useId: true,
    arrayGenerator: (oldArray, action, singleKey) => {
      const value = action[singleKey];
      const index = indexForCollection(oldArray, value.id);

      return [...oldArray.slice(0, index), value, ...oldArray.slice(index + 1)];
    },
  },
};

function indexForCollection(collection, itemId) {
  return collection.findIndex(
    (item) => parseInt(item.id, 10) === parseInt(itemId, 10)
  );
}
