import * as actionTypes from './actionTypes'
import moment from 'moment'

const TRIALS_REPORT_FILTER_ACTION_HANDLERS = {
  [actionTypes.CLEAR_ALL_FILTERS]: (state) => {
    return { ...state, data: defaultLeadFormState() }
  },
  [actionTypes.UPDATE_FILTER_DATA]: (state, action) => {
    return { ...state, data: action.filterData }
  },
  [actionTypes.HIDE_FILTERS]: (state, action) => {
    return { ...state, showingMore: false }
  },
  [actionTypes.SHOW_FILTERS]: (state, action) => {
    return { ...state, showingMore: true }
  },
}

const initialState = {
  showingMore: false,
  data: defaultLeadFormState()
}

function defaultLeadFormState () {
  return {
    createdBetween: {
      fields: {
        created_after: { value: moment().startOf('day').utc().format(), isValid: true },
        created_before: { value: moment().endOf('day').utc().format(), isValid: true }
      }
    }
  }
}

export default function reducer (state = initialState, action) {
  const handler = TRIALS_REPORT_FILTER_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
