import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { FormFields } from 'modules/components'
const { SelectField } = FormFields

export class MapStatusesView extends Component {

  addMap = (fieldName, value) => {
    const map = { old: fieldName, new: value }
    this.props.addStatusMap(map)
  }

  updateMap = (fieldName, value) => {
    const map = { old: fieldName, new: value }
    this.props.updateStatusMap(map)
  }

  render () {
    const { gymleadsStatuses, statusesToMap, statusMaps } = this.props

    const rows = statusesToMap.map((status) => {
      const selected = statusMaps.findIndex(map => map.old === status) > -1
      const tickElement = (selected)
      ? <i className='fa fa-3x fa-check-circle text-success' style={{ marginTop: '10px', marginLeft: '13px' }} />
      : ''
      const onChange = (selected) ? this.updateMap : this.addMap
      return (
        <tr key={status}>
          <td>{status}</td>
          <td>
            <SelectField
              name={status}
              possibleOptions={gymleadsStatuses}
              placeHolderText='Select a status'
              formFieldStyle=''
              styles={{ marginBottom: '0px' }}
              onChange={onChange} />
          </td>
          <td>{tickElement}</td>
        </tr>
      )
    })

    return (
      <div style={{ marginTop: '5px' }}>
        <h5 style={{ marginBottom: '5px' }}>Statuses</h5>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>CSV Value</th>
              <th style={{ width: '40%' }}>Import Value</th>
              <th style={{ width: '10%' }} />
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

MapStatusesView.propTypes = {
  gymleadsStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  statusesToMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  statusMaps: PropTypes.arrayOf(PropTypes.object).isRequired,
  addStatusMap: PropTypes.func.isRequired,
  updateStatusMap: PropTypes.func.isRequired
}

export default MapStatusesView
