import * as actionTypes from './actionTypes'

const PROFILE_ACTION_HANDLERS = {
  [actionTypes.RESET_FORM_DATA]: (state) => {
    return initialState()
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

function initialState () {
  return {
    errors: null,
    loading: false,
    data: {
      fields: {
        currentPassword: {
          value: null,
          isValid: false
        },
        newPassword: {
          value: null,
          isValid: false
        },
        newPasswordConfirmation: {
          value: null,
          isValid: false
        }
      }
    }
  }
}

export default function reducer (state = initialState(), action) {
  const handler = PROFILE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
