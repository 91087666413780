import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TrialsTableView from "../components/TrialsTableView";

import FilterFormSections from "modules/components/FilterFormSections";
import filters from "../../filters";
import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    filters.selectors.getData
  );
  return {
    loading: selectors.getLoading(state),
    trialsData: selectors.getTrials(state),
    errors: selectors.getErrors(state),
    queryString: queryStringSelector(state),
    selectedCell: selectors.getSelectedCell(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWithQueryString: actions.fetchWithQueryString,
      setSelectedCell: actions.setSelectedCell,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialsTableView);
