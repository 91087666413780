import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import WeeklyLeadSummary from '../../modules/WeeklyLeadSummary'
import locationSelect from '../../modules/LocationSelect'

function mapStateToProps (state) {
  const seperatedReportData = WeeklyLeadSummary.selectors.getSortedReportData(state)
  return {
    title: 'New Leads',
    data: seperatedReportData.counts,
    labels: seperatedReportData.headers,
    location: locationSelect.selectors.getSelectedLocation(state),
    loading: WeeklyLeadSummary.selectors.getLoading(state),
    isEmpty: WeeklyLeadSummary.selectors.getIsEmpty(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchData: WeeklyLeadSummary.actions.fetchData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyLeadSummary.components.WeeklyLeadSummaryView)
