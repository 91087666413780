import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Dropzone from 'react-dropzone'

import { SELECT_LOCATION_MODAL } from '../constants'
import LocationSelect from 'modules/components/LocationSelect'

export class SelectCSVForm extends Component {

  onDrop = (files) => {
    if (files.length > 0) {
      this.props.csvWasSelected(files[0])
    }
  }

  onLocationSelect = (location) => {
    const { selectLocation, setEnhancedPrivacy } = this.props
    setEnhancedPrivacy(location.uses_opt_in)
    selectLocation(location)
    this.props.hideModal()
  }

  showSelectLocationsModal = () => {
    this.props.showModal(SELECT_LOCATION_MODAL)
  }

  render () {
    const { csvFile, selectedLocationName, selectedLocationId } = this.props
    const buttonString = selectedLocationName || 'Select Location'

    const imageStyle = {
      width: '150px',
      height: '150px',
      paddingLeft: '15px',
      paddingRight: '15px',
      border: '2px dashed black'
    }

    const dropZoneStyle = {}

    return (
      <div>
        <div className='row'>
          <div className='col-md-3'>
            <Dropzone
              onDrop={this.onDrop}
              style={dropZoneStyle}
              className='selectable push-10'>
                <div style={imageStyle}>
                {csvFile &&
                  <p className='text-center' style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', wordBreak: 'break-all' }}>{csvFile.name}</p>
                }
                {!csvFile &&
                  <p className='text-center' style={{ marginTop: '40px' }}>Click or drag and drop a CSV file here.</p>
                }
                </div>
            </Dropzone>
          </div>
          <div className='col-md-2'>
            {csvFile &&
              <i className='fa fa-check-circle fa-5x text-success'
                style={{
                  paddingTop: '40px'
                }} />
            }
          </div>
        </div>
        {csvFile &&
          <div className='row'>
            <div className='col-sm-12'>
              <h3 className='push-15'
                style={{ display: 'inline-block', fontSize: '13px' }}>
                Which location are you importing into?</h3><br />
              <button className='btn btn-default push-5-r push-10'
                style={{ display: 'inline-block' }}
                type='button'
                onClick={this.showSelectLocationsModal}>
                {buttonString}
              </button>
              <LocationSelect.LocationSelectModalContainer
                modalName={SELECT_LOCATION_MODAL}
                selectedLocationId={selectedLocationId}
                onLocationSelect={this.onLocationSelect} />
            </div>
          </div>
        }
      </div>
    )
  }
}

SelectCSVForm.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  csvWasSelected: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
  setEnhancedPrivacy: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
  selectedLocationName: PropTypes.string,
  csvFile: PropTypes.object
}

export default SelectCSVForm
