import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";

import editRegion from "../modules/editRegion";
import regionsList from "../modules/regions";
import regions from "modules/Stores/Regions";

const getRegions = regions.selectors.getRegions;

function mapStateToProps(state) {
  const selectedRegionId = regionsList.selectors.getSelectedRegionId(state);
  const regions = getRegions(state);
  const index = regions.findIndex(
    region => parseInt(region.id) === parseInt(selectedRegionId)
  );
  const selectedRegion = regions[index];
  return {
    loading: editRegion.selectors.getLoading(state),
    region: selectedRegion,
    locations: editRegion.selectors.getLocations(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal,
      getLocationsForRegionId: editRegion.actions.getLocationsForRegionId
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(editRegion.components.EditRegionView);
