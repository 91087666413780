import * as actionTypes from './actionTypes'

import { INDIVIDUAL_TYPE, LOCATION_TYPE } from './constants'

const WEEKLY_REPORT_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.FETCH_SUCCESS]: (state, action) => {
    return { ...state, loading: false, errors: null, salesTargetResults: action.salesTargetResults }
  },
  [actionTypes.FETCH_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  }
}

const initialState = {
  salesTargetResults: {
    data: {}
  },
  loading: false,
  errors: null
}

export function locationSalesTargetReducer (state = initialState, action) {
  return generateStateForType(state, action, LOCATION_TYPE)
}

export function inidivudalSalesTargetReducer (state = initialState, action) {
  return generateStateForType(state, action, INDIVIDUAL_TYPE)
}

function generateStateForType (state, action, type) {
  if (action.targetType !== type) return state

  const handler = WEEKLY_REPORT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
