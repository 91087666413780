/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { ContentBlockView, TextSearchView, PagesView } from 'modules/components';

export class DealModal extends Component {
  UNSAFE_componentWillMount() {
    const { deals } = this.props;
    if (deals.length > 0) {
      return;
    }
    this.getDealsWithPageNumber(1);
  }

  getDealsWithPageNumber = (pageNumber) => {
    const { getDeals } = this.props;
    getDeals(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getDealsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getDeals } = this.props;
    getDeals(1, searchTerm);
  };

  setLeadToClosedWithDeal = (deal) => {
    const { updateLeadToStatus, lead, listUpdateProtocol, hideModal } = this.props;
    hideModal();
    const dealId = deal ? deal.id : null;
    updateLeadToStatus(lead, 'closed', lead.sales_funnel_step, null, listUpdateProtocol, dealId);
  };

  render() {
    const { loading, currentModal, hideModal, modalName, deals, pageDetails } = this.props;
    const pageCount = pageDetails.pageCount;

    const dealRows = deals.map((deal, index) => {
      return (
        <div key={index} className="col-lg-6">
          <a className="block block-link-hover2 selectable" onClick={() => this.setLeadToClosedWithDeal(deal)}>
            <div className="block-content block-content-full clearfix bg-success">
              <span className="h4 text-white-op">{deal.title}</span>
            </div>
          </a>
        </div>
      );
    });

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={'block'}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Are they being offered a deal?
              </h3>
            </div>
            <ContentBlockView loading={loading} bordered>
              <div className="row" style={{ marginTop: '-20px' }}>
                <TextSearchView sectionName={'dealSearch'} onUpdate={this.searchUpdated} />
              </div>
              <div>
                <div className="col-lg-6">
                  <a className="block block-link-hover2 selectable" onClick={() => this.setLeadToClosedWithDeal(null)}>
                    <div className="block-content block-content-full clearfix bg-success">
                      <span className="h4 text-white-op">None</span>
                    </div>
                  </a>
                </div>
                {deals.length > 0 && dealRows}
              </div>
              {pageCount > 1 && (
                <div className="text-center push">
                  <PagesView pageDetails={pageDetails} pageClicked={this.pageClicked} />
                </div>
              )}
            </ContentBlockView>
          </div>
        </Modal>
      </div>
    );
  }
}

DealModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateLeadToStatus: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

DealModal.defaultProps = {
  listUpdateProtocol: {},
};

export default DealModal;
