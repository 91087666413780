import { request, GET } from 'utils/apiUtils';
import notify from 'modules/Notifications';

export const generateFullReport = (queryString) => {
  return (dispatch) => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      notify.success("Your report is being generated. We'll email it to you when it's ready.");
    }

    return request(`trial_reports/full_report?${queryString}`, GET, null, onSuccess, onFailure);
  };
};
