import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class SelectTrialTableRow extends Component {
  rowSelected = () => {
    const { trial, onTrialSelect, trialStartsAt } = this.props;
    console.log(trialStartsAt.startOf('day').format());
    onTrialSelect(trial, trialStartsAt.startOf('day').format());
  };

  render() {
    const { trial, isSelected } = this.props;
    const style = isSelected ? 'selected' : 'selectable';
    return (
      <tr className={style} onClick={this.rowSelected}>
        <td>{trial.name}</td>
        <td className="text-center">{trial.day_length}</td>
      </tr>
    );
  }
}

SelectTrialTableRow.propTypes = {
  trial: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onTrialSelect: PropTypes.func.isRequired,
};
