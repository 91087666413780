export default {
  Global: {
    ok: "D'accord",
    cancel: 'Annuler',
    edit: 'Éditer',
    yes: 'Oui',
    no: 'Non',
    done: 'Terminé',
    or: 'Ou',
    OR: 'OU',
    SMS: 'SMS',
    and: 'et',
    email: 'Email',
    loading: 'Chargement',
    create: 'Créer',
    update: 'Update',
    delete: 'Delete',
    logOut: 'Log Out',
    clearSelection: 'Clear selection',
    goBackButton: 'Go Back',
    name: 'name',
    Name: 'Name',
    status: 'Status',
    location: 'Location',
    number: 'Mobile number',
    leads: 'leads',
    search: 'Search',
    clearSeach: 'Clear search',
    copyrightDate: '2016-2022',
    copyrightText: '© GymLeads',
    poweredByGymleads: '© Powered by GymLeads',
    documentTitle: 'The CRM for Gyms and fitness clubs',
    username: 'Username',
    password: 'Password',
    drafts: 'Drafts',
    draft: 'Draft',
    active: 'Active',
    from: 'From',
    to: 'To',
    date: 'Date',
    replied: 'Replied',
    scheduled: 'Scheduled',
    sent: 'Sent',
    templates: 'Templates',
    lastUpdated: 'Last updated',
    Sort: {
      nameAscending: 'Name (Ascending)',
      nameDescending: 'Name (Descending)',
      dateAscending: 'Date (Ascending)',
      dateDescending: 'Date (Descending)',
    },
    SmsHelper: {
      characterLimitsHeading: 'Character limits',
      textMessagePreviewHeading: 'Text Message Preview',
      textMessageContentHeading: 'Text Message Content',
      textfieldPlaceholder: 'Insert Merge tag',
      selectTemplateButton: 'Select Template',
      updateContentButton: 'Update Content',
      charactersRemaining: "characters remaining until it's a new message",
      totalCharactersRemaining: 'total characters remaining',
      chargedAt: 'each SMS is charged at',
      estimatedPrice: 'this bulk sms is estimated to cost',
      usingEmoji:
        "It looks like you're using emojis! {0}Emoijs are a great way to make your message stand out, but they do make the message more expensive because of the way carriers need to enocde the message. See our {1} for more details",
      supportArticle: 'support article',
      disclaimer:
        '*The above is an estimate only. Actual numbers may differ based on the actual value of merge fields. {0} also adds an opt out message and link at the end of every message to identify you as the sender and help them opt out if they wish. This is required by law in all countries {0} operates in.',
    },
  },
  Login: {
    welcomeBack: 'Bienvenue',
    bookADemo: 'Réservez une démo',
    resetYourPassword: 'Réinitialiser le mot de passe',
    logIn: "S'identifier",
    loggingIn: 'Se connecter',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    rememberMe: 'Souviens-toi de moi',
  },
  Menu: {
    dashboard: 'Tableau de bord',
    reports: 'Rapports',
    calendar: 'Calendrier',
    manageLeads: 'Gérer les prospects',
    settings: 'Paramètres',
    support: 'Soutien',
    menu: 'Menu',
  },
  Settings: {
    title: 'Réglages',
    Profile: {
      title: 'Profil',
    },
    CommunicationPreferences: {
      title: 'Communication Preferences',
    },
    CompanyAccount: {
      title: 'Company Account',
    },
    Calendar: {
      title: 'Calendar',
    },
    SecurityPolicy: {
      title: 'Security Policy',
    },
    SalesTargets: {
      title: 'Sales Targets',
    },
    Locations: {
      title: 'Locations',
    },
    SalesTeams: {
      title: 'Sales Teams',
    },
    FrontDesk: {
      title: 'FrontDesk',
    },
    EmailDomain: {
      title: 'Domaine de messagerie',
    },
    Tags: {
      title: 'Mots clés',
    },
    CallSchedules: {
      title: 'Horaires des appels',
    },
    Regions: {
      title: 'Régions',
    },
  },
  LeadDetail: {
    details: 'Détails',
    calls: 'Appels',
    appointments: 'Rendez-vous',
    notes: 'Remarques',
    messages: 'Messages',
    emails: 'E-mails',
    tasks: 'Tâches',
    history: "L'histoire",
    checkIns: 'Enregistrement',
  },
};
