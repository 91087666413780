import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import session from 'modules/Stores/Session'
import LeadsTableView from "../components/LeadsTableView";
import * as selectors from "../selectors";
import * as actions from "../actions";
import * as trialsTableSelectors from "../../trialsTable/selectors";
import filters from "../../filters";
import { humanReadableTitleLeadsTable } from '../helpers'


import FilterFormSections from "modules/components/FilterFormSections";

function mapStateToProps(state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    filters.selectors.getData
  );
  const selectedCell = trialsTableSelectors.getSelectedCell(state)
  const data = trialsTableSelectors.getTrials(state)
  const formattedTitle = humanReadableTitleLeadsTable(data, selectedCell)
  return {
    leads: selectors.getLeads(state),
    loading: selectors.getLoading(state),
    pageCount: selectors.getPageCount(state),
    currentPage: selectors.getCurrentPage(state),
    queryString: queryStringSelector(state),
    formattedTitle: formattedTitle,
    selectedCell: selectedCell,
    timezone: session.selectors.getUserTimezone(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadsWithPages: actions.getLeadsWithPages
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsTableView);
