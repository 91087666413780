import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SalesTargets from '../../modules/SalesTargets'
import SalesTeam from '../../modules/SalesTeam'
import session from 'modules/Stores/Session'

function mapStateToProps (state) {
  const salesTargetType = SalesTargets.constants.INDIVIDUAL_TYPE
  return {
    salesTargetResults: SalesTargets.selectors.getSalesTargetResults(state, salesTargetType),
    user: SalesTeam.selectors.selectedUser(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetch: SalesTargets.actions.fetchSalesTargetResultsForUserId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTargets.components.UserTargetsViewForManager)
