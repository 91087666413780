import { NAME } from "./constants";

export const ON_INPUT = NAME + "/ON_INPUT";
export const PREFIL_FORM = NAME + "/PREFIL_FORM";
export const RESET = NAME + "/RESET";
export const SET_TAB_INDEX = NAME + "/SET_TAB_INDEX";
export const SET_PERMISSION_SET = NAME + "/SET_PERMISSION_SET";

export const SET_SITE_LOCATIONS = NAME + "/SET_SITE_LOCATIONS";
export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const SET_TRIALS = NAME + "/SET_TRIALS";
export const SET_MEMBERSHIPS = NAME + "/SET_MEMBERSHIPS";
export const SET_TRIALS_MEMBERSHIPS_MAPPINGS =
  NAME + "/SET_TRIALS_MEMBERSHIPS_MAPPINGS";

export const ADD_TRIAL_MEMBERSHIP_MAPPING =
  NAME + "/ADD_TRIAL_MEMBERSHIP_MAPPING";

export const REMOVE_TRIAL_MEMBERSHIP_MAPPING =
  NAME + "/REMOVE_TRIAL_MEMBERSHIP_MAPPING";
