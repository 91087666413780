import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getData = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].data;
export const getIntegrationPermissionSet = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].permissionSet;
export const getTabIndex = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].tabIndex;
export const getSiteLocations = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].siteLocations;
export const getPageCount = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].pageCount;
export const getCurrentPage = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].currentPage;
export const getTotal = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].total;
export const getTrials = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].trials;
export const getMemberships = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].memberships;
export const getTrialsMembershipsMappings = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].trialsMembershipsMappings;

export const currentIndexIsReady = createSelector([getData, getTabIndex], (data, tabIndex) => {
  if (!data) {
    return false;
  }
  switch (tabIndex) {
    case 0:
      return data.isValid || false;
    case 2:
      const locationId = data.fields.username.value;
      return locationId !== null && locationId !== undefined;
    default:
      return true;
  }
});
