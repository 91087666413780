import * as actionTypes from "./actionTypes";
import { request, GET } from "utils/apiUtils";

export const getThirdPartyIntegrations = (locationId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setLastFetchedLocationId(locationId));
      dispatch(setThirdPartyIntegrations(payload.third_party_integrations));
    }

    let url =
      "locations/" +
      locationId +
      "/third_party_integrations?direction=" +
      "outgoing";
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setThirdPartyIntegrations(thirdPartyIntegrations) {
  return {
    type: actionTypes.SET_THIRD_PARTY_INTEGRATIONS,
    thirdPartyIntegrations,
  };
}

function setLastFetchedLocationId(lastFetchedLocationId) {
  return {
    type: actionTypes.SET_LAST_FETCHED_LOCATION_ID,
    lastFetchedLocationId,
  };
}
