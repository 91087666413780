import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import modal from 'modules/Stores/Modal';
import LeadDetailsModalView from '../components/LeadDetailsModalView';
import * as actions from '../actions';
import * as selectors from '../selectors';
import session from 'modules/Stores/Session';

const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    currentModal: state.modal.currentModal,
    loading: modal.selectors.getModalLoading(state),
    lead: selectors.getLead(state),
    activityRecords: selectors.getActivityRecords(state),
    timezone: session.selectors.getUserTimezone(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      getLeadDetails: actions.getLeadDetails,
      getActivityRecords: actions.getActivityRecords,
      resetState: actions.resetState,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadDetailsModalView);
