import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';
import { EditLeadFormValidator } from './validators';
import { placeholdersForCountry } from 'modules/Helpers/FormatHelpers';

import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import 'moment-timezone';

import { generateNewFormState, newPayloadForFormValue } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
const { TextField, Switch, SelectField } = FormFields;

export class EditLeadModal extends Component {
  state = this.generateStateForLead(this.props.lead);

  generateStateForLead(lead) {
    return {
      showDeleteWarning: false,
      isValid: false,
      fields: {
        givenName: {
          value: lead.given_name,
          isValid: true,
        },
        surname: {
          value: lead.surname,
          isValid: true,
        },
        phoneNumber: {
          value: lead.mobile_number,
          isValid: true,
        },
        email: {
          value: lead.email,
          isValid: true,
        },
        workplaceName: {
          value: lead.workplace_name,
          isValid: true,
        },
        city: {
          value: lead.city,
          isValid: true,
        },
        postcode: {
          value: lead.zip_postcode,
          isValid: true,
        },
        address: {
          value: lead.address_line_one,
          isValid: true,
        },
        state: {
          value: lead.state,
          isValid: true,
        },
        transactionalEmailOptOut: {
          value: lead.transactional_email_opt_out,
          isValid: true,
        },
        marketingEmailOptOut: {
          value: lead.marketing_email_opt_out,
          isValid: true,
        },
        marketingSmsOptOut: {
          value: lead.marketing_sms_opt_out,
          isValid: true,
        },
        transactionalSmsOptOut: {
          value: lead.transactional_sms_opt_out,
          isValid: true,
        },
        leadSyncId: {
          value: lead.lead_sync_status ? lead.lead_sync_status.service_id : null,
          isValid: true,
        },
        lawfulProcessingBasisId: {
          value: lead.lawful_processing_basis_id,
          isValid: true,
        },
      },
    };
  }

  showDeleteWarningPopup = () => {
    this.setState({ ...this.state, showDeleteWarning: true });
  };

  deleteLead = () => {
    const { lead, deleteLead, listUpdateProtocol } = this.props;
    deleteLead(lead, listUpdateProtocol.remove);
    this.dismissPopups();
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showDeleteWarning: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lead.id !== this.props.lead.id) {
      this.setState(this.generateStateForLead(nextProps.lead));
    }
    if (nextProps.fullDetail === true && this.props.fullDetail === false) {
      this.setState(this.generateStateForLead(nextProps.lead));
    }
  }

  saveClicked = (event) => {
    event && event.preventDefault();
    const { lead, updateLead, listUpdateProtocol } = this.props;
    const {
      givenName,
      surname,
      phoneNumber,
      email,
      city,
      address,
      postcode,
      state,
      leadSyncId,
      transactionalEmailOptOut,
      marketingEmailOptOut,
      marketingSmsOptOut,
      transactionalSmsOptOut,
      lawfulProcessingBasisId,
      workplaceName,
    } = this.state.fields;
    let body = {};

    body = newPayloadForFormValue(body, givenName, 'given_name');
    body = newPayloadForFormValue(body, surname, 'surname');
    body = newPayloadForFormValue(body, phoneNumber, 'mobile_number');
    body = newPayloadForFormValue(body, email, 'email');
    body = newPayloadForFormValue(body, city, 'city');
    body = newPayloadForFormValue(body, workplaceName, 'workplace_name');
    body = newPayloadForFormValue(body, address, 'address_line_one');
    body = newPayloadForFormValue(body, postcode, 'zip_postcode');
    body = newPayloadForFormValue(body, state, 'state');
    body = newPayloadForFormValue(body, transactionalEmailOptOut, 'transactional_email_opt_out');
    body = newPayloadForFormValue(body, marketingEmailOptOut, 'marketing_email_opt_out');
    body = newPayloadForFormValue(body, marketingSmsOptOut, 'marketing_sms_opt_out');
    body = newPayloadForFormValue(body, transactionalSmsOptOut, 'transactional_sms_opt_out');
    body = newPayloadForFormValue(body, lawfulProcessingBasisId, 'lawful_processing_basis_id');

    updateLead(lead, body, listUpdateProtocol.updateLeadDetails, leadSyncId.value);
  };

  handleInput = (name, value, isValid) => {
    this.setState(generateNewFormState(this.state, name, value, isValid));
  };

  handlePhoneInput = (value) => {
    this.setState(generateNewFormState(this.state, 'mobileNumber', value, true));
  };

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.state, fieldName, value, true);
    this.setState(newData);
  };

  handleSelect = (fieldName, value) => {
    const newData = generateNewFormState(this.state, fieldName, value, true);
    this.setState(newData);
  };

  phonePlaceholder = () => {
    const { lead, countries } = this.props;
    const countryId = lead.country_id || 1;
    return placeholdersForCountry(countryId, countries).phone;
  };

  startDownload = () => {
    const { lead, dataPortabilityExport } = this.props;
    dataPortabilityExport(lead);
  };

  render() {
    const { lead, loading, currentModal, hideModal, modalName, lawfulProcessingBases, timezone } = this.props;
    const {
      givenName,
      surname,
      phoneNumber,
      email,
      city,
      address,
      workplaceName,
      postcode,
      state,
      leadSyncId,
      transactionalEmailOptOut,
      marketingEmailOptOut,
      marketingSmsOptOut,
      transactionalSmsOptOut,
      lawfulProcessingBasisId,
    } = this.state.fields;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal} backdrop="static">
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Update {lead.given_name}'s contact details
              </h3>
            </div>
            <div className="block-content">
              <form className="form-horizontal push-30-t" onSubmit={this.saveClicked}>
                <div className="row push-30">
                  <div className="col-sm-6">
                    <TextField
                      name="givenName"
                      label="First name"
                      placeholder="eg. Janet"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      needsFormGroup={false}
                      initialValue={givenName.value}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      name="surname"
                      label="Surname"
                      placeholder="eg. Langley"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      needsFormGroup={false}
                      initialValue={surname.value}
                    />
                  </div>
                </div>
                <div className="row push-30">
                  <div className="col-sm-6">
                    <TextField
                      name="phoneNumber"
                      label="Phone number"
                      placeholder={this.phonePlaceholder()}
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      needsFormGroup={false}
                      initialValue={phoneNumber.value}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      name="email"
                      label="Email"
                      placeholder="hello@email.com"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      initialValue={email.value}
                      needsFormGroup={false}
                      validator={EditLeadFormValidator}
                    />
                  </div>
                </div>
                <div className="row push-30">
                  <div className="col-sm-12">
                    <TextField
                      name="address"
                      label="Street address"
                      placeholder="What is their street name and number?"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      initialValue={address.value}
                      needsFormGroup={false}
                    />
                  </div>
                </div>
                <div className="row push-30">
                  <div className="col-sm-6">
                    <TextField
                      name="city"
                      label="City"
                      placeholder="Which city do they live in? (eg. Glen Iris)"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      initialValue={city.value}
                      needsFormGroup={false}
                    />
                  </div>
                  <div className="col-sm-3">
                    <TextField
                      name="state"
                      label="State"
                      placeholder="Eg: VIC"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      initialValue={state.value}
                      needsFormGroup={false}
                    />
                  </div>
                  <div className="col-sm-3">
                    <TextField
                      name="postcode"
                      label="Postcode"
                      placeholder="Eg: 3000"
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      initialValue={postcode.value}
                      needsFormGroup={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <TextField
                      name="workplaceName"
                      label="Name of workplace"
                      placeholder=""
                      formFieldStyle="form-material form-material-primary"
                      onChange={this.handleInput}
                      initialValue={workplaceName.value}
                      needsFormGroup={false}
                    />
                  </div>
                </div>
                <div className="row push-30-t">
                  <div className="col-sm-6">
                    <SelectField
                      name="lawfulProcessingBasisId"
                      label="Lawful basis to process their data"
                      placeHolderText="None"
                      possibleOptions={lawfulProcessingBases}
                      displayFieldName={'title'}
                      formFieldStyle="form-material form-material-primary"
                      initialValue={lawfulProcessingBasisId.value}
                      onChange={this.handleSelect}
                    />
                  </div>
                  {lead.lead_sync_status && (
                    <div className="col-sm-6">
                      <TextField
                        name="leadSyncId"
                        label="Sync Id"
                        placeholder="Eg: 1000111111"
                        formFieldStyle="form-material form-material-primary"
                        onChange={this.handleInput}
                        initialValue={leadSyncId.value}
                        needsFormGroup={false}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-6">
                      <Switch
                        name="transactionalEmailOptOut"
                        label="Opted out of transactional email"
                        onChange={this.handleToggle}
                        switchStyle="switch-sm switch-primary"
                        value={transactionalEmailOptOut.value}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <Switch
                        name="marketingEmailOptOut"
                        label="Opted out of marketing email"
                        onChange={this.handleToggle}
                        switchStyle="switch-sm switch-primary"
                        value={marketingEmailOptOut.value}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <Switch
                        name="transactionalSmsOptOut"
                        label="Opted out of transactional SMS"
                        onChange={this.handleToggle}
                        switchStyle="switch-sm switch-primary"
                        value={transactionalSmsOptOut.value}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <Switch
                        name="marketingSmsOptOut"
                        label="Opted out of marketing SMS"
                        onChange={this.handleToggle}
                        switchStyle="switch-sm switch-primary"
                        value={marketingSmsOptOut.value}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {lead.opted_in_at && (
                      <p>
                        {lead.display_name} opted in at {moment(lead.opted_in_at).tz(timezone).format('dddd MMMM Do, h:mm a')}
                      </p>
                    )}
                    {!lead.opted_in_at && <p>{lead.display_name} has not opted into marketing communication</p>}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-danger pull-left" onClick={this.showDeleteWarningPopup}>
              <i className="fa fa-times" /> Delete
            </button>
            <button className="btn btn-info push-5-r push-10" onClick={this.startDownload}>
              Data request export
            </button>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn btn-primary push-5-r push-10" type="button" disabled={!this.state.isValid} onClick={this.saveClicked}>
              <i className="fa fa-save" /> Update
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showDeleteWarning && (
          <div>
            <SweetAlert
              danger
              showCancel
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="danger"
              confirmBtnText="Delete Lead"
              title="Are you Sure?"
              onConfirm={this.deleteLead}
              onCancel={this.dismissPopups}
            >
              This lead will be completely removed from all communication channels and will not show in the system.
            </SweetAlert>
          </div>
        )}
      </div>
    );
  }
}

EditLeadModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  lawfulProcessingBases: PropTypes.arrayOf(PropTypes.object).isRequired,
  fullDetail: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  dataPortabilityExport: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default EditLeadModal;
