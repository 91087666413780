import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import session from 'modules/Stores/Session'

import { updateUserAvatar, avatarWasSelected } from '../actions'
import { getAvatarLoading, getAvatarFile, getAvatarProgress } from '../selectors'
import { EditAvatar } from 'modules/components/Avatars'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    loading: getAvatarLoading(state),
    progress: getAvatarProgress(state),
    file: getAvatarFile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    startFileUpload: updateUserAvatar,
    fileWasSelected: avatarWasSelected
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAvatar)
