import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import React, { Component } from "react";
import FacebookMessageItem from "./FacebookMessageItem";
import FacebookComposeContainer from "../containers/FacebookComposeContainer";
import styled from "styled-components";
import "./message.css";

export class FacebookMessagesView extends Component {
  componentDidMount() {
    const { fetchForFacebookMessengerUser, facebookMessengerUser } = this.props;
    if (!facebookMessengerUser) {
      return;
    }
    fetchForFacebookMessengerUser(facebookMessengerUser);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { facebookMessengerUser } = this.props;
    if (facebookMessengerUser.id !== nextProps.facebookMessengerUser.id) {
      clearTimeout(this.timeout);
    }
  }

  componentDidUpdate() {
    // Scroll to bottom of messages
    const { currentPage, pageCount, facebookMessages } = this.props;
    if (currentPage === pageCount) {
      const len = facebookMessages.length - 1;
      const node = ReactDOM.findDOMNode(this["_div" + len]);
      if (node) {
        node.scrollIntoView();
      }
    } else {
      const node = ReactDOM.findDOMNode(this["_div" + 1]);
      if (node) {
        node.scrollIntoView();
      }
    }
  }

  handleScroll = () => {
    const {
      loading,
      currentPage,
      addFacebookMessengesForPage,
      facebookMessengerUser,
    } = this.props;
    if (loading || currentPage === 1) {
      return;
    }
    const element = document.querySelector(".facebook-messages");
    if (element.scrollTop === 0) {
      addFacebookMessengesForPage(facebookMessengerUser, currentPage - 1);
    }
  };

  render() {
    const { facebookMessages, lead, currentPage } = this.props;

    const messagesList = facebookMessages.map((facebookMessage, index) => {
      return (
        <FacebookMessageItem
          key={index}
          facebookMessage={facebookMessage}
          lead={lead}
          ref={(element) => {
            this["_div" + index] = element;
          }}
        />
      );
    });

    const morePages = currentPage > 1;

    return (
      <div>
        <div
          className="facebook-messages"
          onScroll={this.handleScroll}
          ref={(element) => {
            this.messages = element;
          }}
        >
          {morePages && <MorePagesIndicator> ... </MorePagesIndicator>}
          {messagesList}
        </div>
        <FacebookComposeContainer
          facebookMessengerUser={this.props.facebookMessengerUser}
          setFacebookMessengerUser={this.props.setFacebookMessengerUser}
        />
      </div>
    );
  }
}

FacebookMessagesView.propTypes = {
  facebookMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  facebookMessengerUser: PropTypes.object.isRequired,
  fetchForFacebookMessengerUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setFacebookMessengerUser: PropTypes.func.isRequired,
};

const MorePagesIndicator = styled.div`
  width: 100%;
  text-align: center;
  padding: 0px 0px 15px 0px;
`;
