import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userData from 'modules/Stores/UserData'

import { updateCompanyLogo, avatarWasSelected } from '../actions'
import { getLogoLoading, getLogoFile, getLogoProgress } from '../selectors'
import { EditCompanyLogo } from '../components/EditCompanyLogo'

function mapStateToProps (state) {
  return {
    company: userData.selectors.getCompany(state),
    loading: getLogoLoading(state),
    progress: getLogoProgress(state),
    file: getLogoFile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    startFileUpload: updateCompanyLogo,
    fileWasSelected: avatarWasSelected
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyLogo)
