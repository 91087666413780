import { NAME } from './constants'

export const PREFILL_FORM_DATA = NAME + '/PREFILL_FORM_DATA'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const RESET = NAME + '/RESET'

export const SET_SHOWING = NAME + '/SET_SHOWING'
export const UPDATE_FORM_DATA = NAME + '/UPDATE_FORM_DATA'
