import { FormFields } from 'modules/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const { Switch } = FormFields;

export class SalespersonElements extends Component {
  render() {
    const { leadAssigned, leadTimedOut, newLeadTextMessage, newLead } = this.props.data.fields;
    return (
      <div className="row">
        <div className="col-md-8">
          <h3 className="block-title">New Lead</h3>
          <Switch
            name="newLead"
            label="Get an email when a new lead inquires to the club."
            onChange={this.props.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={newLead.value}
          />
          <h3 className="block-title">Lead Assigned</h3>
          <Switch
            name="leadAssigned"
            label="Get an email when a lead has been assigned to you."
            onChange={this.props.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={leadAssigned.value}
          />
          <h3 className="block-title">Lead Time Out</h3>
          <Switch
            name="leadTimedOut"
            label="Get notified when a lead is not actioned within the goal time limit so you can reassign."
            onChange={this.props.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={leadTimedOut.value}
          />
          <h3 className="block-title">Communication Received</h3>
          <Switch
            name="newLeadTextMessage"
            label="Get notified when a lead assigned to you sends you text message or email."
            onChange={this.props.handleToggle}
            switchStyle="switch-sm switch-primary"
            value={newLeadTextMessage.value}
          />
        </div>
      </div>
    );
  }
}

SalespersonElements.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default SalespersonElements;
