import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ThirdPartyIntegrationSelectModal from '../components/ThirdPartyIntegrationSelectModal'
import thirdPartyIntegrations from 'modules/Stores/ThirdPartyIntegrations'

import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    thirdPartyIntegrations: thirdPartyIntegrations.selectors.getThirdPartyIntegrations(state),
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    currentModal: modal.selectors.getCurrentModal(state),
    lastSelectedLocationId: thirdPartyIntegrations.selectors.getLastFetchedLocationId(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    getThirdPartyIntegrations: thirdPartyIntegrations.actions.getThirdPartyIntegrations
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyIntegrationSelectModal)
