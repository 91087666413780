import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CallScheduleRow from "../components/CallScheduleRow";
import * as actions from "../actions";
import * as selectors from "../selectors";

function mapStateToProps(state, ownProps) {
  const selectedCallScheduleId = selectors.getSelectedCallScheduleId(state);
  const selected =
    parseInt(ownProps.callSchedule.id) === parseInt(selectedCallScheduleId);
  return {
    selected: selected
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectCallScheduleId: actions.selectCallScheduleId
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallScheduleRow);
