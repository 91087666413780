import PropTypes from "prop-types";
import React, { Component } from "react";
import { humanReadableStatus, humanReadableNoSendReason } from "../helpers";
import moment from "moment";
import "./EmailItem.css";

export class EmailItem extends Component {
  convertNewlinesToBreaks = (str) => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.replace(/\n/g, '<br>');
  }

  showHistoryClicked = () => {
    const { email, onShowHistory } = this.props;
    onShowHistory(email);
  };

  render() {
    const { email, lead } = this.props;
    let name = "";
    if (email.email_message_direction === "from_system") {
      name = email.email_user ? email.email_user.given_name : "Deleted user";
    } else {
      name = lead.given_name;
    }
    const createdAtMoment = moment(email.created_at).fromNow();
    return (
      <div className="block block-transparent pull-r-l">
        <div className="block-header bg-gray-lighter">
          <ul className="block-options">
            <li>
              <span>
                <em className="text-muted">{createdAtMoment}</em>
              </span>
            </li>
          </ul>
          <h3 className="block-title">{name}</h3>
        </div>
        <div className="block-content">
          <div className="row">
            <div className="col-xs-8">
              {email.email_message_direction === "from_system" && (
                <p>
                  <span className="font-w600">Subject:</span>{" "}
                  {email.email_subject}
                </p>
              )}
              {email.email_message_direction === "from_lead" && (
                <>
                  <h3 className="block-title">{lead.given_name} (Lead)</h3>
                  <div className="email-preview" style={{ pointerEvents: "none" }}>
                    <div dangerouslySetInnerHTML={{ __html: email.safe_html }} />
                  </div>
                </>
              )}
              <div>
                {email.email_message_direction === "from_system" && (
                  <div style={{ paddingBottom: "6px" }}>
                    <span className="font-w600">Status:</span>{" "}
                    {humanReadableStatus(email.email_status)}
                    {email.no_send_reason && (
                      <div>
                        <span className="font-w600">Because:</span>{" "}
                        {humanReadableNoSendReason(email.no_send_reason)}
                      </div>
                    )}
                    <button
                      className="btn btn-sm btn-default push-10-l"
                      onClick={this.showHistoryClicked}
                    >
                      View History
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {<div dangerouslySetInnerHTML={{ __html: this.convertNewlinesToBreaks(email.email_text) }} />}
          {email.safe_html && (
            <div className="email-preview" style={{ pointerEvents: "none" }}>
              <hr/>
              <div dangerouslySetInnerHTML={{ __html: email.safe_html }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

EmailItem.propTypes = {
  email: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  onShowHistory: PropTypes.func.isRequired,
};

export default EmailItem;
