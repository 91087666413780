import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { FormFields } from 'modules/components';
const { TextField } = FormFields;

export class NewRegionModalView extends Component {
  state = {
    name: null,
    postcodes: [],
  };

  hideAndReset = () => {
    this.props.hideModal();
    this.setState({ name: null, postcodes: [] });
  };

  addRegion = () => {
    const { createRegion, addRegionToList } = this.props;
    const { name, postcodes } = this.state;
    createRegion(name, postcodes, addRegionToList);
  };

  handleInput = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  updatePostcode = (event) => {
    const textValue = event.target.value;
    const index = parseInt(event.target.id);
    const oldPostcodes = this.state.postcodes;
    const newPostcodes = [...oldPostcodes.slice(0, index), textValue, ...oldPostcodes.slice(index + 1)];
    this.setState({ ...this.state, postcodes: newPostcodes });
  };

  addPostcode = () => {
    const oldPostcodes = this.state.postcodes;
    const newPostcodes = [...oldPostcodes, ''];
    this.setState({ ...this.state, postcodes: newPostcodes });
  };

  deletePostcodeIndex = (index) => {
    const oldPostcodes = this.state.postcodes;
    const newPostcodes = [...oldPostcodes.slice(0, index), ...oldPostcodes.slice(index + 1)];
    this.setState({ ...this.state, postcodes: newPostcodes });
  };

  render() {
    const { loading, currentModal, modalName, errors } = this.props;
    const { name, postcodes } = this.state;

    const addDisabled = !(name && name.length > 1 && postcodes && postcodes.length > 0);

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    const postcodesDivs = postcodes.map((postcode, index) => {
      return (
        <div className="col-xs-4 push-10" key={index}>
          <div style={{ border: '1px solid #e6e6e6', padding: '10px' }}>
            <input
              type="text"
              className="form-control h4 font-w700"
              style={{ border: 'none', padding: '0px', width: '80%' }}
              id={index}
              placeholder="postcode"
              value={postcode}
              onChange={this.updatePostcode}
            />
            <span className="pull-right selectable" style={{ marginTop: '-30px' }} onClick={() => this.deletePostcodeIndex(index)}>
              <i className="fa fa-times fa-2x" />
            </span>
          </div>
        </div>
      );
    });
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideAndReset}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.hideAndReset}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Create region
              </h3>
            </div>
            <div className="block-content">
              <div className="row push">
                {errorPanelForErrors(errors)}
                <div className="form-material">
                  <TextField name="name" label="Region name" placeholder="" formFieldStyle="" onChange={this.handleInput} />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <p style={{ fontSize: '13px', fontWeight: '600' }}>Region Postcodes</p>
                </div>
              </div>
              <div className="row">
                {postcodesDivs}
                <div className="col-xs-4">
                  <div
                    className="selectable"
                    style={{
                      color: '#999',
                      border: '1px dashed #e6e6e6',
                      padding: '10px',
                      paddingTop: '15px',
                      paddingBottom: '15px',
                    }}
                    onClick={this.addPostcode}
                  >
                    <span className="h4 font-w700">Add</span>
                    <span className="pull-right" style={{ marginTop: '-2px' }}>
                      <i className="fa fa-plus fa-2x" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideAndReset}>
              Cancel
            </button>
            <button className="btn btn-success push-5-r push-10" type="button" disabled={addDisabled} onClick={this.addRegion}>
              <i className="si si-plus" /> Add region
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

NewRegionModalView.propTypes = {
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  createRegion: PropTypes.func.isRequired,
  addRegionToList: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default NewRegionModalView;
