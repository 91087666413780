import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import PagesView from 'modules/components/PageSelection/PagesView'
import locations from 'modules/Stores/Locations'

function mapStateToProps (state) {
  return {
    pageDetails: locations.selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagesView))
