import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import fieldMapping from '../modules/fieldMapping'
import configureImport from '../modules/configureImport'
import selectCSV from '../modules/selectCSV'
import { getTabIndex } from '../selectors'
import { getProductName } from 'modules/Stores/UserData/selectors'

function mapStateToProps (state) {
  const activeTab = getTabIndex(state)
  return {
    currentLocationId: selectCSV.selectors.getLocationId(state),
    loading: fieldMapping.selectors.getLoading(state),
    csvFile: selectCSV.selectors.getCSV(state),
    isActive: (activeTab === 2),
    statusColumnIndex: configureImport.selectors.statusColumnIndex(state),
    salespersonColumnIndex: configureImport.selectors.salespersonColumnIndex(state),
    lawfulProcessingBasesColumnIndex: configureImport.selectors.lawfulProcessingBasesColumnIndex(state),
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getPotentialUsersForLocationId: fieldMapping.actions.getPotentialUsersForLocationId,
    findSalespeopleAndStatusStrings: fieldMapping.actions.findSalespeopleAndStatusStrings
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(fieldMapping.components.FieldMappingView)
