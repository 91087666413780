import React from 'react'
import MultiLocationSelect from "modules/components/MultiLocationSelect";

const LocationsSection = ({
  SELECT_LOCATIONS_MODAL,
  clubsFiltersClass,
  locationIdsName,
  locationIdsData,
  showModal,
  clubTitle,
  onUpdate
}) => {

  function showSelectLocationsModal() {
    showModal(SELECT_LOCATIONS_MODAL);
  };

  return (
    <div>
      <div
      className={`btn ${clubsFiltersClass}`}
      type="button"
      onClick={showSelectLocationsModal}
    >
      {clubTitle}
    </div>
    <MultiLocationSelect.MultiLocationSelectModalContainer
      modalName={SELECT_LOCATIONS_MODAL}
      sectionName={locationIdsName}
      onUpdate={onUpdate}
      data={locationIdsData}
    />
    </div>
  )
}

export default LocationsSection
