import FileSaver from 'file-saver';
import { GET, POST, request } from 'utils/apiUtils';
import appointments from './modules/appointments';
import details from './modules/details';
import history from './modules/history';
import facebookMessages from './modules/messages/modules/facebookMessages';
import { add } from './modules/messages/modules/textMessages/actions';

import modal from 'modules/Stores/Modal';

import notify from 'modules/Notifications';

const { startLoading, stopLoading, hideModal, showErrors } = modal.actions;

export const recordCallForLead = (lead, detailText) => {
  return (dispatch) => {
    const body = {
      lead_activity_record: {
        record_type: 'called',
        title: 'Called ' + lead.given_name,
        detail: detailText,
      },
    };
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(history.actions.addHistoryItem(payload.lead_activity_record));
      dispatch(hideModal());
      notify.success('Call Recorded');
    }

    return request('leads/' + lead.id + '/lead_activity_records', POST, body, onSuccess, onFailure);
  };
};

export const sendTextMessageToLead = (lead, textMessageBodyTemplate, text) => {
  return (dispatch) => {
    let leadTextMessage = {};
    if (textMessageBodyTemplate) {
      leadTextMessage = {
        ...leadTextMessage,
        body_template: textMessageBodyTemplate,
      };
    }
    if (text) {
      leadTextMessage = { ...leadTextMessage, text: text };
    }
    const body = {
      lead_text_message: leadTextMessage,
    };
    dispatch(startLoading());

    function onFailure(errors) {
      dispatch(showErrors(errors));
      dispatch(stopLoading());
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(add(payload.lead_text_message));
      dispatch(hideModal());
      notify.success('Text Message sent');
    }

    return request('leads/' + lead.id + '/lead_text_messages', POST, body, onSuccess, onFailure);
  };
};

// POST Move Lead
export const moveLeadToStep = (lead, fromStep, toStep, listUpdateProtocol) => {
  const fromStepId = fromStep ? fromStep.id : null;
  return (dispatch) => {
    const body = {
      funnel_step_tranistion: {
        lead_id: lead.id,
        from_step_id: fromStepId,
        to_step_id: toStep.id,
      },
    };
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(stopLoading());
      dispatch(showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(stopLoading());
      dispatch(hideModal());
      dispatch(details.actions.updateSalesFunnelStep(toStep));
      dispatch(hideModal());
      if (listUpdateProtocol.updateSalesFunnelStep) {
        const newStep = payload.funnel_step_transition.to_step;
        dispatch(listUpdateProtocol.updateSalesFunnelStep(lead, newStep));
      }
      notify.success('Lead Moved to ' + toStep.name);
    }

    return request('funnel_step_transitions', POST, body, onSuccess, onFailure);
  };
};

// POST UPDATE LEAD STATUS
export const updateLeadToStatus = (lead, statusName, currentFunnelStep, reasonId = null, listUpdateProtocol, dealId = null) => {
  return (dispatch) => {
    const funnelStepId = currentFunnelStep ? currentFunnelStep.id : null;
    const body = {
      lead_status_update: {
        lead_id: lead.id,
        from_step_id: funnelStepId,
        deal_id: dealId,
        lead_lost_reason_id: reasonId,
        status: statusName,
      },
    };

    function onFailure(errors) {}

    function onSuccess(payload) {
      const updatedLead = payload.lead_status_update.lead;
      if (listUpdateProtocol.cleanUp) {
        dispatch(listUpdateProtocol.cleanUp(lead));
      }
      if (listUpdateProtocol.updateLeadDetails) {
        dispatch(listUpdateProtocol.updateLeadDetails(updatedLead));
      }

      dispatch(details.actions.setLead(updatedLead));
      notify.success('Lead changed to ' + statusName);
    }

    return request('lead_status_updates', POST, body, onSuccess, onFailure);
  };
};

export const reopenLead = (lead, salesFunnelSteps, listUpdateProtocol) => {
  const toStepId = salesFunnelSteps[0].id;
  return (dispatch) => {
    const body = {
      lead_reopen: {
        lead_id: lead.id,
        sales_funnel_step_id: toStepId,
        status: 'active',
      },
    };

    function onFailure(errors) {}

    function onSuccess(payload) {
      const lead = payload.lead_reopen.lead;
      if (payload.lead_reopen) {
        dispatch(listUpdateProtocol.updateLeadDetails(lead));
      }

      dispatch(details.actions.setLead(lead));

      notify.success('Lead reopened');
    }

    return request('lead_reopens', POST, body, onSuccess, onFailure);
  };
};

export function handleAsyncLeadUpdateEvent(event) {
  return (dispatch) => {
    switch (event.type) {
      case 'mbo_client':
      case 'glofox_client':
      case 'journey_client':
        dispatch(details.actions.setLeadSyncStatus(event.body));
        break;
      case 'mbo_appointment':
      case 'glofox_appointment':
      case 'journey_appointment':
        dispatch(appointments.actions.handleAsyncAppointmentUpdate(event.body, event.type));
        break;
      default:
        break;
    }
  };
}

export function handleAsyncFacebookMessengerEvent(event) {
  return (dispatch) => {
    const newMessage = event.facebook_message;
    dispatch(facebookMessages.actions.add(newMessage));
  };
}

export const dataPortabilityExport = (lead) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const blob = new Blob([JSON.stringify(payload)], {
        type: 'application/json',
      });
      FileSaver.saveAs(blob, lead.display_name + '_portable_export.json');
    }

    return request('leads/' + lead.id + '/portable_export', GET, null, onSuccess, onFailure);
  };
};
