import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectHeardAboutMethodModal from "./SelectHeardAboutMethodModal";
import * as heardAboutMethodApiActions from "modules/Stores/HeardAboutMethods/apiActions";

import heardAboutMethods from "modules/Stores/HeardAboutMethods";

function mapStateToProps(state) {
  return {
    heardAboutMethods: heardAboutMethods.selectors.getHeardAboutMethods(state),
    loading: heardAboutMethods.selectors.getLoading(state),
    errors: heardAboutMethods.selectors.getErrors(state),
    pageDetails: heardAboutMethods.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getHeardAboutMethods: heardAboutMethodApiActions.getHeardAboutMethods
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectHeardAboutMethodModal);
