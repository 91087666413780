import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FiltersForm from '../components/FiltersForm';
import { CLUBS, USERS } from 'containers/Reports/Reports/SalesPerformance/modules/filters/constants';
import { lostLeadsFilters } from '../constants';

function mapStateToProps(state) {
  return {
    compareOptions: [CLUBS, USERS],
    availableFilters: lostLeadsFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersForm);
