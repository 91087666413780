import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectCSVForm from '../components/SelectCSVForm'
import modal from 'modules/Stores/Modal'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    csvFile: selectors.getCSV(state),
    selectedLocationId: selectors.getLocationId(state),
    selectedLocationName: selectors.getLocationName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal,
    csvWasSelected: actions.csvWasSelected,
    selectLocation: actions.selectLocation,
    setEnhancedPrivacy: actions.setEnhancedPrivacy
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCSVForm)
