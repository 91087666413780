import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getData = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].data
export const getTabIndex = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].tabIndex
export const getSiteLocations = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].siteLocations
export const getPageCount = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].pageCount
export const getCurrentPage = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].currentPage
export const getTotal = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].total

export const currentIndexIsReady = createSelector(
  [ getData, getTabIndex ],
  (data, tabIndex) => {
    if (!data) { return false }
    switch (tabIndex) {
      case 0:
        const companyName = data.fields.companyName.value // Client ID
        const apiKey = data.fields.apiKey.value // Secret Key
        return (companyName.length > 0 && apiKey.length > 0) || false
      case 1:
        const { integrationLocationId } = data.fields
        return (integrationLocationId.value !== null)
      default:
        return true
    }
  }
)
