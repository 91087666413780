import * as actionTypes from "./actionTypes";

const TIME_TAKEN_COMPARING_TABLE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_DATA]: (state, action) => {
    return { ...state, data: action.data };
  }
};

const initialState = {
  loading: true,
  errors: null,
  data: {}
};

export default function reducer(state = initialState, action) {
  const handler = TIME_TAKEN_COMPARING_TABLE_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
