import * as actionTypes from './actionTypes'

const WEEKLY_REPORT_ACTION_HANDLERS = {
  [actionTypes.FETCHING]: (state, action) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.FETCH_SUCCESS]: (state, action) => {
    return { ...state, loading: false, errors: null, report: action.report }
  },
  [actionTypes.FETCH_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  }
}

const initialState = {
  report: {
    summaryData: {},
    data: []
  },
  loading: false,
  errors: null
}

export default function reducer (state = initialState, action) {
  const handler = WEEKLY_REPORT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
