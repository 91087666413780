import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Updates from "../../modules/Updates";
import session from "modules/Stores/Session";

function mapStateToProps(state) {
  return {
    historyItems: Updates.selectors.getHistoryItems(state),
    loading: Updates.selectors.getLoading(state),
    timezone: session.selectors.getUserTimezone(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetch: Updates.actions.fetchUpdates
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Updates.components.UpdatesView);
