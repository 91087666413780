import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Reports from 'modules/components/Reports'

import { getReportSummaryData, getLoading } from '../selectors'

function mapStateToProps (state) {
  const summaryData = getReportSummaryData(state)

  const leadsIn = (summaryData.leads_in) ? summaryData.leads_in.count : 0
  const leadsAllocated = (summaryData.leads_allocated) ? summaryData.leads_allocated.count : 0
  const allocationRatio = (summaryData.lead_allocation_ratio) ? summaryData.lead_allocation_ratio * 100 : 0
  const closeRate = (summaryData.close_ratio) ? summaryData.close_ratio * 100 : 0

  const blockData = [{
    title: 'Leads in',
    value: leadsIn
  }, {
    title: 'Leads Allocated',
    value: leadsAllocated
  }, {
    title: 'Lead Allocation Ratio',
    value: allocationRatio,
    suffix: '%'
  }, {
    title: 'Close Ratio',
    value: closeRate,
    suffix: '%'
  }]

  return {
    blockData: blockData,
    loading: getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports.components.QuickInfoBlocks)
