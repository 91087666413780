import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import LeadSourceSelectView from 'modules/components/LeadSourceSelect/components/LeadSourceSelectView';

export class LeadSourceSelectModal extends Component {
  render() {
    const { currentModal, modalName, hideModal } = this.props;

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select lead source
            </h3>
          </div>
          <div className="block-content">
            <LeadSourceSelectView {...this.props} />
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

LeadSourceSelectModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  title: PropTypes.string,
  getLeadSources: PropTypes.func.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLeadSourceSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedLeadSourceId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
};

export default LeadSourceSelectModal;
