import * as actionTypes from './actionTypes';

import { safeRawPropertyForObject, safeBooleanPropertyForObject } from 'utils/formHelpers';

const PERMISSION_ACTION_HANDLERS = {
  [actionTypes.PREFILL_FORM]: (state, action) => {
    return initialStateForPermissions(action.userPermissions);
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    const newData = action.data;
    return { ...state, data: newData };
  },
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  },
};

function initialStateForPermissions(userPermissions) {
  return {
    errors: null,
    loading: false,
    data: {
      id: safeRawPropertyForObject(userPermissions, 'id'),
      fields: {
        allowLeadAssign: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_lead_assign'),
          isValid: true,
        },
        allowLeadReassign: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_lead_reassign'),
          isValid: true,
        },
        allowOtherCalendar: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_other_calendar'),
          isValid: true,
        },
        allowOpenComms: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_open_comms'),
          isValid: true,
        },
        showSharedInbox: {
          value: safeBooleanPropertyForObject(userPermissions, 'show_shared_inbox'),
          isValid: true,
        },
        allowMarketing: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_marketing'),
          isValid: true,
        },
        allowViewOthersLeads: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_view_others_leads'),
          isValid: true,
        },
        viewAllMarketingAssets: {
          value: safeBooleanPropertyForObject(userPermissions, 'view_all_marketing_assets'),
          isValid: true,
        },
        allowViewReports: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_view_reports'),
          isValid: true,
        },
        allowLeadSettings: {
          value: safeBooleanPropertyForObject(userPermissions, 'allow_lead_settings'),
          isValid: true,
        },
      },
    },
  };
}

export default function reducer(state = initialStateForPermissions({}), action) {
  const handler = PERMISSION_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
