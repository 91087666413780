import { connect } from 'react-redux'
import LostLeadsReportView from '../components/LostLeadsReportView'

function mapStateToProps (state) {
  return {
    queryString: null
  }
}

export default connect(mapStateToProps)(LostLeadsReportView)
