import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectHeardAboutMethodTableRow } from "./SelectHeardAboutMethodTableRow";

export class SelectHeardAboutMethodTableView extends Component {
  render() {
    const {
      heardAboutMethods,
      selectedHeardAboutMethodIds,
      selectHeardAboutMethod,
      deselectHeardAboutMethod
    } = this.props;
    const rows = heardAboutMethods.map((heardAboutMethod, index) => {
      const selectedIndex = selectedHeardAboutMethodIds.findIndex(
        selectedHeardAboutMethodId =>
          heardAboutMethod.id === selectedHeardAboutMethodId
      );
      const selected = selectedIndex >= 0;
      return (
        <SelectHeardAboutMethodTableRow
          key={index}
          heardAboutMethod={heardAboutMethod}
          heardAboutMethodSelected={selected}
          selectHeardAboutMethod={selectHeardAboutMethod}
          deselectHeardAboutMethod={deselectHeardAboutMethod}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectHeardAboutMethodTableView.propTypes = {
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedHeardAboutMethodIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectHeardAboutMethod: PropTypes.func.isRequired,
  deselectHeardAboutMethod: PropTypes.func.isRequired
};

export default SelectHeardAboutMethodTableView;
