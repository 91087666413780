import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { BulkLeadReallocationsView } from "../components/BulkLeadReallocationsView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import selectLocation from "../modules/selectLocation";
import selectUsers from "../modules/selectUsers";
import confirm from "../modules/confirm";

function mapStateToProps(state) {
  const tabIndex = selectors.getTabIndex(state);
  let nextIsReady = false;
  switch (tabIndex) {
    case 0:
      nextIsReady = selectLocation.selectors.isReady(state);
      break;
    case 1:
      nextIsReady = selectUsers.selectors.isReady(state);
      break;
    case 2:
      nextIsReady = confirm.selectors.isReady(state);
      break;
    default:
      break;
  }

  return {
    tabIndex: tabIndex,
    nextIsReady: nextIsReady,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      incrementTabIndex: actions.incrementTabIndex,
      decrementTabIndex: actions.decrementTabIndex,
      reset: actions.reset,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkLeadReallocationsView);
