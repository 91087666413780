import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LeadGoalSelectView from "../components/LeadGoalSelectView";
import leadGoals from "modules/Stores/LeadGoals";
import * as leadGoalApiActions from "modules/Stores/LeadGoals/apiActions";

function mapStateToProps(state) {
  return {
    leadGoals: leadGoals.selectors.getLeadGoals(state),
    loading: leadGoals.selectors.getLoading(state),
    errors: leadGoals.selectors.getErrors(state),
    pageDetails: leadGoals.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadGoals: leadGoalApiActions.getLeadGoals
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadGoalSelectView);
