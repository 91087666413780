import { connect } from 'react-redux'
import ArticlesView from '../components/ArticlesView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  }
}

const mapActionCreators = {
  getArticles: actions.getArticles
}

export default connect(mapStateToProps, mapActionCreators)(ArticlesView)
