/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { errorPanelForErrors } from "utils/formHelpers";

import Modal from "react-bootstrap-modal";
import {
  FormFields,
  InfoView,
  TextSearchView,
  PagesView,
} from "modules/components";

const { TextField, Switch } = FormFields;

export class NewCallScheduleModal extends Component {
  state = {
    name: null,
    selectedLeadSourceIds: [],
    isDefault: false,
  };

  UNSAFE_componentWillMount() {
    const { leadSources } = this.props;
    if (leadSources.length > 0) {
      return;
    }
    this.getLeadSourcesWithPageNumber(1);
  }

  getLeadSourcesWithPageNumber = (pageNumber) => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  handleChange = (fieldName, value) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  handleSelect = (value) => {
    const { selectedLeadSourceIds } = this.state;
    const existingIndex = indexForLeadSource(selectedLeadSourceIds, value);
    if (existingIndex >= 0) {
      this.setState({
        ...this.state,
        selectedLeadSourceIds: [
          ...selectedLeadSourceIds.slice(0, existingIndex),
          ...selectedLeadSourceIds.slice(existingIndex + 1),
        ],
      });
    } else {
      this.setState({
        ...this.state,
        selectedLeadSourceIds: [...selectedLeadSourceIds, value],
      });
    }
  };

  createScheduleSelected = () => {
    const { createCallSchedule } = this.props;
    const { name, selectedLeadSourceIds, isDefault } = this.state;
    createCallSchedule(
      name,
      selectedLeadSourceIds,
      isDefault,
      this.onSubmitSuccess
    );
  };

  onSubmitSuccess = () => {
    this.setState({ name: null, selectedLeadSourceIds: [], isDefault: false });
    this.props.hideModal();
  };

  leadSourcesFormattedForSelectField = () => {
    const { leadSources } = this.props;
    return leadSources.map((leadSource) => {
      return { value: leadSource.id, label: leadSource.source_name };
    });
  };

  formIsValid = () => {
    const { name, selectedLeadSourceIds, isDefault } = this.state;
    if (!name || name.length < 1) {
      return false;
    }
    if (selectedLeadSourceIds.length < 1 && isDefault === false) {
      return false;
    }
    return true;
  };

  render() {
    const {
      currentModal,
      hideModal,
      modalName,
      loading,
      errors,
      pageDetails,
      leadSources,
    } = this.props;

    const { callScheduleName, selectedLeadSourceIds, isDefault } = this.state;
    const blockStyle = loading ? "block block-opt-refresh" : "block";

    const switchLabel = "Make this the default schedule.";
    const warningString =
      "All leads that don't match another schedule will be placed into this one.";
    const pageCount = pageDetails.pageCount;
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                Let's create a call schedule
              </h3>
            </div>
            <div className="block-content">
              <div className="push-30">
                <div
                  className="row"
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                >
                  <div className="col-sm-12">
                    <p>
                      Create a call schedule by giving it a name and selecting
                      the lead sources it should use. You'll be able to add
                      steps after you've created it.
                    </p>
                    {errorPanelForErrors(errors, false)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <TextField
                      name="name"
                      label="Call schedule name *"
                      placeholder="Online leads call schedule"
                      formFieldStyle=""
                      onChange={this.handleChange}
                      value={callScheduleName}
                    />
                  </div>
                  <div className="col-sm-12">
                    <Switch
                      name="isDefault"
                      label={switchLabel}
                      onChange={this.handleChange}
                      closeOnSelect={false}
                      switchStyle="switch-sm switch-primary"
                      value={isDefault}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                >
                  <div className="col-sm-12">
                    <label htmlFor="leadSourceSelect">Lead sources</label>
                  </div>
                  <TextSearchView
                    sectionName={"leadSourceSearch"}
                    onUpdate={this.searchUpdated}
                  />
                </div>
                {isDefault === true && (
                  <div
                    className="row"
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                  >
                    <div className="col-sm-12">
                      <InfoView
                        colorThemeClass={"warning"}
                        detail={warningString}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  {isDefault === false &&
                    leadSourceOptions(
                      leadSources,
                      this.handleSelect,
                      selectedLeadSourceIds
                    )}
                </div>
              </div>
            </div>
            {pageCount > 1 && (
              <div className="text-center push">
                <PagesView
                  pageClicked={this.pageClicked}
                  pageDetails={this.props.pageDetails}
                />
              </div>
            )}
          </div>
          <Modal.Footer>
            <button className="btn btn-default pull-left" onClick={hideModal}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r"
              type="button"
              disabled={!this.formIsValid()}
              onClick={this.createScheduleSelected}
            >
              <i className="fa fa-plus" /> Create call Schedule
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

NewCallScheduleModal.propTypes = {
  createCallSchedule: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  currentModal: PropTypes.string,
};

NewCallScheduleModal.defaultProps = {
  leadSources: [],
};

export default NewCallScheduleModal;

const leadSourceOptions = (leadSources, onPress, selectedLeadSourceIds) => {
  return leadSources.map((leadSource, index) => {
    const existingIndex = indexForLeadSource(
      selectedLeadSourceIds,
      leadSource.id
    );
    const bgStyle = existingIndex >= 0 ? "bg-success-light" : "bg-success";
    return (
      <div key={index} className="col-lg-6">
        <a
          className="block block-link-hover2 selectable"
          onClick={() => onPress(leadSource.id)}
        >
          <div
            className={"block-content block-content-full clearfix " + bgStyle}
          >
            <span className="h4 text-white-op">{leadSource.source_name}</span>
          </div>
        </a>
      </div>
    );
  });
};

function indexForLeadSource(leadSourceIds, value) {
  return leadSourceIds.findIndex((leadSourceId) => leadSourceId === value);
}
