import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateNewFormState } from 'utils/formHelpers';

import { FormFields, ContentBlockView } from 'modules/components';
import { validator } from '../validator';

const { TextField } = FormFields;

export class CustomMailMergeTagDetailView extends Component {
  state = {
    customMailMergeTagToDelete: null,
  };

  saveCustomMailMergeTagClicked = () => {
    const { selectedCustomMailMergeTag, updateCustomMailMergeTag, data } = this.props;
    updateCustomMailMergeTag(selectedCustomMailMergeTag, data);
  };

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid);
    this.props.updateData(newData);
  };

  deleteCustomMailMergeTagClicked = () => {
    this.props.deleteCustomMailMergeTag(this.props.selectedCustomMailMergeTag.id);
  };

  render() {
    const { selectedCustomMailMergeTag, loading, data } = this.props;
    const { label, value } = data.fields;

    const deleteButton = (
      <button type="button" className="pull-right btn btn-xs btn-danger" onClick={this.deleteCustomMailMergeTagClicked}>
        <i className="fa fa-cross" /> Delete Custom Mail Merge Tag
      </button>
    );

    return (
      <div>
        <ContentBlockView title={selectedCustomMailMergeTag.label} loading={loading} blockOptions={deleteButton} bordered>
          <div className="row">
            <TextField
              name="label"
              label="Mail Merge Tag Label *"
              placeholder="Our Instagram"
              formFieldStyle=""
              onChange={this.handleInput}
              value={label.value}
              validator={validator}
            />
          </div>
          <div className="row push-20">
            <TextField
              name="value"
              label="Mail Merge Tag Default Value *"
              placeholder="@OurInstagramHandle"
              formFieldStyle=""
              onChange={this.handleInput}
              value={value.value}
              validator={validator}
            />
          </div>
          <div className="row">
            <div className="col-xs-4 push-20">
              <button className="btn btn-primary" onClick={this.saveCustomMailMergeTagClicked}>
                <i className="fa fa-save" /> Save
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    );
  }
}

CustomMailMergeTagDetailView.propTypes = {
  selectedCustomMailMergeTag: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  deleteCustomMailMergeTag: PropTypes.func.isRequired,
  updateCustomMailMergeTag: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default CustomMailMergeTagDetailView;
