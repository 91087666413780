/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import "./LeadTodo.css";

export class LeadTodoItem extends Component {
  onSelect = (event) => {
    event.preventDefault();
    const { leadTodoItem, leadTodoClicked } = this.props;
    leadTodoClicked(leadTodoItem);
  };

  onDelete = () => {
    const { leadTodoItem, leadTodoDeleted } = this.props;
    leadTodoDeleted(leadTodoItem);
  };

  render() {
    const { leadTodoItem } = this.props;

    const blockStyle = leadTodoItem.loading
      ? "block block-link-hover3 borderd block-opt-refresh "
      : "block block-link-hover3 borderd";

    return (
      <div>
        <a className={blockStyle}>
          <div className="block-content block-content-full clearfix">
            <div className="row">
              <div className="col-xs-1">
                <label className="css-input css-checkbox css-checkbox-lg css-checkbox-primary">
                  <input
                    type="checkbox"
                    checked={leadTodoItem.completed}
                    onChange={this.onSelect}
                  />
                  <span />{" "}
                </label>
              </div>
              <div className="col-xs-9">
                <h4>{leadTodoItem.title}</h4>
              </div>
              <div className="col-xs-2">
                <i
                  className="fa fa-remove pull-right fa-2x text-muted selectable"
                  onClick={this.onDelete}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-11 col-xs-offset-1">
                <p>{leadTodoItem.detail}</p>
                <LeadTodoItemTime leadTodoItem={leadTodoItem} />
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

LeadTodoItem.propTypes = {
  leadTodoItem: PropTypes.object.isRequired,
  leadTodoClicked: PropTypes.func.isRequired,
  leadTodoDeleted: PropTypes.func.isRequired,
};

class LeadTodoItemTime extends Component {
  render() {
    const { leadTodoItem } = this.props;
    const dueDateMoment = moment(leadTodoItem.due_date)
      .startOf("minute")
      .fromNow();
    const completedMoment = moment(leadTodoItem.completed_on).fromNow();
    return (
      <div>
        {leadTodoItem.completed && leadTodoItem.completed_on && (
          <div>
            <span className="text-muted">Completed {completedMoment}</span>
          </div>
        )}
        {!leadTodoItem.completed && leadTodoItem.due_date && (
          <div>
            <span className="text-muted">Due {dueDateMoment}</span>
          </div>
        )}
      </div>
    );
  }
}

LeadTodoItemTime.propTypes = {
  leadTodoItem: PropTypes.object.isRequired,
};
