import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = (state) => state[NAME].loading
export const getErrors = (state) => state[NAME].errors
export const getDomains = (state) => state[NAME].domains
export const getSelectedDomainId = (state) => state[NAME].selectedDomainId
export const getRates = (state) => state[NAME].rates
export const getStatistics = (state) => state[NAME].statistics

export const selectedDomain = createSelector(
  [ getDomains, getSelectedDomainId ],
  (domains, selectedDomainId) => {
    if (domains && domains.length > 0) {
      const index = domains.findIndex(domain => parseInt(domain.id) === parseInt(selectedDomainId))
      return domains[index]
    } else {
      return null
    }
  }
)
