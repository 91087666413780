import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getStartTime = state => state.calendar[NAME].startTime
export const getLength = state => state.calendar[NAME].length
export const getData = state => state.calendar[NAME].data
export const getLeads = state => state.calendar[NAME].leads
export const getSelectedLeadId = state => state.calendar[NAME].selectedLeadId

export const selectedLead = createSelector(
  [ getLeads, getSelectedLeadId ],
  (leads, selectedLeadId) => {
    if (selectedLeadId !== null) {
      const index = leads.map((lead) => lead.id).indexOf(parseInt(selectedLeadId))
      return leads[index]
    }
    return null
  }
)
