import React from "react";
import PropTypes from "prop-types";
import "./LeadListProgress.css";

export default function FunnelStepsWithoutLabel({
  salesFunnelSteps,
  currentStepNumber,
}) {
  const steps = salesFunnelSteps.map((step, index) => {
    let completeClass =
      currentStepNumber >= step.step_number ? "complete" : "disabled";
    completeClass =
      currentStepNumber === step.step_number ? "active" : completeClass;
    return (
      <div
        className={"col-xs-3 sales-funnel-step " + completeClass}
        key={index}
      >
        <div className="progress">
          <div className="progress-bar" />
        </div>
        <span className="sales-funnel-dot" />
      </div>
    );
  });

  return (
    <div
      className="row sales-funnel"
      style={{
        borderBottom: "0px",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {steps}
    </div>
  );
}

FunnelStepsWithoutLabel.propTypes = {
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStepNumber: PropTypes.number.isRequired,
};
