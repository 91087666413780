import PropTypes from 'prop-types'
import React, { Component } from 'react'

import EditEmailMapFormContainer from '../containers/EditEmailMapFormContainer'
import SweetAlert from 'react-bootstrap-sweetalert'

export class EditEmailMapView extends Component {

  state = {
    showWarning: false
  }

  componentDidMount () {
    const { emailMap, prefilFormForEmailMap } = this.props
    if (emailMap) {
      prefilFormForEmailMap(emailMap)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentEmailMapId = (this.props.emailMap) ? this.props.emailMap.id : null
    const nextEmailMapId = (nextProps.emailMap) ? nextProps.emailMap.id : null
    if (nextEmailMapId !== currentEmailMapId) {
      nextProps.prefilFormForEmailMap(nextProps.emailMap)
    }
  }

  saveEmailMap = () => {
    const { saveEmailMap, data, tags, listUpdateProtocol } = this.props
    saveEmailMap(data, tags, listUpdateProtocol)
  }

  deleteSelected = () => {
    this.setState({ ...this.state, showWarning: true })
  }

  dismissWarning = () => {
    this.setState({ ...this.state, showWarning: false })
  }

  deleteEmailMap = () => {
    const { deleteEmailMap, emailMap, listUpdateProtocol, changeShowing, reset } = this.props

    deleteEmailMap(emailMap.id, listUpdateProtocol)
    changeShowing(false)
    reset()
    this.setState({ ...this.state, showWarning: false })
  }

  render () {
    const { loading, emailMap } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const title = (emailMap) ? 'Update existing email map' : 'Create new email map'

    return (
      <div className={'block block-rounded block-bordered ' + blockStyle} >
        <div className='block-header'>
          {this.props.emailMap &&
            <div className='block-options-simple'>
              <button type='button' className='btn btn-xs btn-warning' onClick={this.deleteSelected}>
                <i className='fa fa-times' />{' '}Delete email map
              </button>
            </div>
          }
          <h3 className='block-title'>{title}</h3>
        </div>
        <div className='block-content'>
          {emailMap &&
            <div className='row'>
              <div className='col-sm-12'>
                <p><span className={'font-w600'}>Email address</span>: {emailMap.email}</p>
              </div>
            </div>
          }
          <div className='row'>
            <div className='col-sm-12'>
              <EditEmailMapFormContainer
                emailMap={emailMap} />
            </div>
          </div>
          <div className='push-20 row'>
            <div className='col-md-12'>
              <button type='button'
                className='btn btn-primary'
                onClick={this.saveEmailMap}
                disabled={(this.props.data.isValid !== true)}>
                <i className='fa fa-save' /> Save email map
              </button>
            </div>
          </div>
        </div>
        {this.state.showWarning &&
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='warning'
            title='Are you Sure?'
            confirmBtnText='Delete this email map'
            onConfirm={this.deleteEmailMap}
            onCancel={this.dismissWarning}>
            This email map will be deleted and won't be able to capture emails anymore.
          </SweetAlert>
        }
      </div>
    )
  }
}

EditEmailMapView.propTypes = {
  saveEmailMap: PropTypes.func.isRequired,
  prefilFormForEmailMap: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  emailMap: PropTypes.object,
  listUpdateProtocol: PropTypes.object.isRequired,
  deleteEmailMap: PropTypes.func.isRequired,
  changeShowing: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  reset: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default EditEmailMapView
