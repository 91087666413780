import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BreakdownContainer from '../containers/BreakdownContainer';
import RatesContainer from '../containers/RatesContainer';

import { ImageEmptyView } from 'modules/components';

import ChartsEmpty from 'images/ChartsEmpty.jpg';

export class ContactCallsView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, lastQueryString, compareOption, isEmpty } = this.props;
    if (isEmpty === false && queryString === lastQueryString) {
      return;
    }
    fetchWithQueryString(queryString, compareOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString || nextProps.compareOption !== this.props.compareOption) {
      const { fetchWithQueryString, queryString, compareOption } = nextProps;
      fetchWithQueryString(queryString, compareOption);
    }
  }

  render() {
    const { isEmpty, loading } = this.props;
    return (
      <div>
        {isEmpty && <ImageEmptyView loading={loading} title="No Results" subtitle="Try changing your filters." image={ChartsEmpty} />}
        {!isEmpty && (
          <div>
            <BreakdownContainer />
            <RatesContainer />
          </div>
        )}
      </div>
    );
  }
}

ContactCallsView.propTypes = {
  fetchWithQueryString: PropTypes.func.isRequired,
  compareOption: PropTypes.string.isRequired,
  queryString: PropTypes.string,
  lastQueryString: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default ContactCallsView;
