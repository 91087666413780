import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class TableView extends Component {

  render () {
    const { data } = this.props
    const rows = data.map((dataRow, index) => {
      return (
        <tr key={index} >
          <td className='font-w600'>
            {dataRow.title}
          </td>
          <td>
            {dataRow.count}
          </td>
        </tr>
      )
    })

    return (
      <div className='table-responsive'>
        <table className='table table-vcenter'>
          <thead>
            <tr>
              <th>Title</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

TableView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}
