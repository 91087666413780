import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'
import callList from '../../modules/CallList'
import dateRangeSelect from '../../modules/DateRangeSelect'

const mapStateToProps = (state) => {
  return {
    loading: callList.selectors.getLoading(state),
    calls: callList.selectors.getCalls(state),
    timezone: session.selectors.getUserTimezone(state),
    pageCount: callList.selectors.getPageCount(state),
    selectedCall: callList.selectors.selectedCall(state),
    currentPage: callList.selectors.getCurrentPage(state),
    totalCount: callList.selectors.getTotalCount(state),
    startDate: dateRangeSelect.selectors.getStartDate(state),
    endDate: dateRangeSelect.selectors.getEndDate(state),
    isMobile: session.selectors.getIsMobile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchInRange: callList.actions.fetchInRange,
    selectCall: callList.actions.selectCall,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(callList.components.CallListView)
