import * as actionTypes from './actionTypes'

const EMAIL_FORM_SELECT_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.LOAD_SUCCESS]: (state) => {
    return { ...state, loading: false }
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  },
  [actionTypes.SET_EMAIL_FORMS]: (state, action) => {
    return { ...state, emailMaps: action.emailMaps }
  },
  [actionTypes.SET_SELECTED_EMAIL_FORM_ID]: (state, action) => {
    return { ...state, selectedEmailMapId: action.selectedEmailMapId }
  },
  [actionTypes.ADD_EMAIL_FORM]: (state, action) => {
    const newEmailMap = action.emailMap
    const oldEmailMaps = state.emailMaps

    const newEmailMaps = [
      ...oldEmailMaps,
      newEmailMap
    ]
    return { ...state, emailMaps: newEmailMaps }
  },
  [actionTypes.UPDATE_EMAIL_FORM]: (state, action) => {
    const newEmailMap = action.emailMap
    const oldEmailMaps = state.emailMaps
    const index = indexForEmailMap(oldEmailMaps, newEmailMap.id)
    const newEmailMaps = [
      ...oldEmailMaps.slice(0, index),
      newEmailMap,
      ...oldEmailMaps.slice(index + 1)
    ]
    return { ...state, emailMaps: newEmailMaps }
  },
  [actionTypes.DELETE_EMAIL_FORM]: (state, action) => {
    const deletedEmailMapId = action.emailMapId
    const oldEmailMaps = state.emailMaps
    const index = indexForEmailMap(oldEmailMaps, deletedEmailMapId)
    const newEmailMaps = [
      ...oldEmailMaps.slice(0, index),
      ...oldEmailMaps.slice(index + 1)
    ]
    return { ...state, emailMaps: newEmailMaps }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      total: action.pageDetails.total_count
    }
  },
  [actionTypes.SET_SEARCH_TERM]: (state, action) => {
    return { ...state, searchTerm: action.searchTerm }
  }
}

const initialState = {
  loading: false,
  searchTerm: '',
  errors: null,
  selectedEmailMapId: null,
  pageCount: 1,
  currentPage: 1,
  total: 0,
  emailMaps: []
}

function indexForEmailMap (emailMaps, emailMapId) {
  return emailMaps.findIndex(emailMap => emailMap.id === emailMapId)
}

export default function reducer (state = initialState, action) {
  const handler = EMAIL_FORM_SELECT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
