import React from 'react';
import { push } from 'react-router-redux';
import { request, buildIndexUrl, POST, GET } from 'utils/apiUtils';

import * as actionTypes from './actionTypes';

import modal from 'modules/Stores/Modal';
import { trackEvent } from 'modules/Helpers/Analytics';
import * as detailActions from 'modules/components/LeadDetail/modules/details/actions';
import notify, { ToastButtonView } from 'modules/Notifications';

export const getUsers = (page, search, locationId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setUsers(payload.users));
      dispatch(setPageDetails(payload.meta));
      dispatch(setLoading(false));
    }
    let url = buildIndexUrl('users', page, null, search);
    url = url + '&location_ids[]=' + locationId;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

// Update Lead Assignment
export const assignLeadToUser = (lead, salesperson, listUpdateProtocol, locationId, isFirstAssignment) => {
  return (dispatch) => {
    let leadAssignment = { lead_id: lead.id, salesperson_id: salesperson.id };
    if (locationId) {
      leadAssignment = { ...leadAssignment, location_id: locationId };
    }
    const body = {
      lead_assignment: leadAssignment,
      is_first_assignment: isFirstAssignment,
    };
    dispatch(setLoading(true));

    function onFailure(errors) {
      dispatch(setLoading(false));
      dispatch(modal.actions.hideModal());
    }

    function onSuccess(payload) {
      const updatedLead = payload.lead_assignment.lead;
      dispatch(setLoading(false));
      if (listUpdateProtocol.updateLeadAssignentForLead) {
        dispatch(listUpdateProtocol.updateLeadAssignentForLead(lead, payload.lead_assignment.salesperson));
      }
      if (listUpdateProtocol.incrementSalespersonLeadCount) {
        dispatch(listUpdateProtocol.incrementSalespersonLeadCount(salesperson.id));
      }
      if (listUpdateProtocol.updateLeadDetails) {
        dispatch(listUpdateProtocol.updateLeadDetails(updatedLead));
      }

      const handleOnClick = () => {
        dispatch(push(`/secure/manageLeads?lead_id=${lead.id}`));
      };

      if (payload.meta) {
        notify.info(<ToastButtonView title={payload.meta} buttonTitle={'View lead'} onClick={handleOnClick} />);
      } else {
        notify.info(<ToastButtonView title="Lead assigned." buttonTitle={'View lead'} onClick={handleOnClick} />);
      }
      dispatch(detailActions.updateLeadAssignent(payload.lead_assignment.salesperson));
      dispatch(detailActions.setLead(updatedLead));
      dispatch(modal.actions.hideModal());
      dispatch(setSelectedUser(null));
    }

    trackEvent('Assigned lead');
    return request('lead_assignments', POST, body, onSuccess, onFailure);
  };
};

export function setCurrentView(currentView) {
  return {
    type: actionTypes.SET_CURRENT_VIEW,
    currentView,
  };
}

export function selectLocation(selectedLocation) {
  return {
    type: actionTypes.SET_SELECTED_LOCATION,
    selectedLocation,
  };
}

export function setSelectedUser(selectedUser) {
  return {
    type: actionTypes.SET_SELECTED_USER,
    selectedUser,
  };
}

export function setUsers(users) {
  return {
    type: actionTypes.SET_USERS,
    users,
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

export function setPageDetails(pageDetails) {
  return {
    type: actionTypes.SET_PAGE_DETAILS,
    pageDetails,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setLastUsedLocationId(lastUsedLocationId) {
  return {
    type: actionTypes.SET_LAST_USED_LOCATION_ID,
    lastUsedLocationId,
  };
}
