import PropTypes from "prop-types";
import React, { Component } from "react";

export class TextField extends Component {
  state = {
    errorMessage: null,
    style: "",
    valid: this.props.validator !== null,
    value: this.props.initialValue || "",
  };

  handleChange = (event) => {
    const textValue = event.target.value;
    let style = "";
    const errorMessage = this.errorMessageForValue(textValue);
    const newIsValidValue = errorMessage === null;

    if (!newIsValidValue) {
      style = "has-error";
    }

    this.setState({
      errorMessage: errorMessage,
      style: style,
      valid: newIsValidValue,
      value: textValue,
    });
    this.props.onChange(this.props.name, textValue, newIsValidValue);
  };

  handleOnFocus = (event) => {
    const { onFocus, name } = this.props;
    if (onFocus) {
      onFocus(name);
    }
  };

  handleOnBlur = (event) => {
    const { onBlur, name } = this.props;
    if (onBlur) {
      onBlur(name);
    }
  };

  errorMessageForValue = (value) => {
    const { name, validator } = this.props;
    if (!validator) return null;

    let errorMessage = null;
    const validations = validator[name];
    validations.forEach(function (validation) {
      const isValid = validation.rule(value);
      if (!isValid) {
        errorMessage = validation.errorMessage;
      }
    });
    return errorMessage;
  };

  render() {
    const {
      name,
      disabled,
      formFieldStyle,
      label,
      placeholder,
      needsFormGroup,
      formGroupIconElement,
    } = this.props;
    const { style, errorMessage } = this.state;
    const showFormGroup = needsFormGroup !== false;
    const isDisabled = disabled === true;
    const formStyle = formFieldStyle || "";
    const propsValue = this.props.value === null ? "" : this.props.value;
    const displayValue =
      propsValue !== undefined ? propsValue : this.state.value;
    const formGroupIconElementClass =
      this.props.formGroupIconElementClass || "input-group-addon";

    const textField = () => {
      return (
        <div className={formStyle}>
          {label && <label htmlFor={name}>{label}</label>}
          {formGroupIconElement && (
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id={name}
                name={name}
                placeholder={placeholder}
                onChange={this.handleChange}
                disabled={isDisabled}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
                value={displayValue}
              />
              <span className={formGroupIconElementClass}>
                {formGroupIconElement}
              </span>
            </div>
          )}
          {!formGroupIconElement && (
            <input
              type="text"
              className="form-control"
              id={name}
              name={name}
              placeholder={placeholder}
              onChange={this.handleChange}
              disabled={isDisabled}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
              value={displayValue}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
            />
          )}
          <span id={name + "-error-message"} className="help-block">
            {errorMessage}
          </span>
        </div>
      );
    };
    return (
      <div>
        {showFormGroup && (
          <div className={"form-group " + style}>
            <div className="col-xs-12">{textField()}</div>
          </div>
        )}
        {showFormGroup === false && textField()}
      </div>
    );
  }
}

TextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  formFieldStyle: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  validator: PropTypes.object,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  needsFormGroup: PropTypes.bool,
  onFocus: PropTypes.func,
  formGroupIconElementClass: PropTypes.string,
  formGroupIconElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  onBlur: PropTypes.func,
};

export default TextField;
