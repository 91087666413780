import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import salesTargets from '../modules/salesTargets'

function mapStateToProps (state, nextProps) {
  return {
    salesTargets: nextProps.location.target_rule_set,
    loading: salesTargets.selectors.getLoading(state),
    data: salesTargets.selectors.getData(state),
    errors: salesTargets.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: salesTargets.actions.updateData,
    deleteSalesTarget: salesTargets.actions.deleteSalesTarget,
    updateSalesTargetWithData: salesTargets.actions.updateSalesTargetWithData,
    prefilSalesTargetsData: salesTargets.actions.prefilSalesTargetsData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(salesTargets.components.SalesTargetsView)
