import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components';
import { StepNumberView, ConversionChevronView } from 'modules/components/'
import { toolTipSelector } from "../tooltips";

export class SalesPerformanceFunnelView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, calculatingOption } = this.props;
    fetchWithQueryString(queryString, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.queryString !== this.props.queryString) ||
    (nextProps.calculatingOption !== this.props.calculatingOption)) {
      const { fetchWithQueryString, queryString, calculatingOption } = nextProps;
      fetchWithQueryString(queryString, calculatingOption);
    }
  }

  render () {
    const { data, loading, calculatingOption } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'

    const tooltips = toolTipSelector(calculatingOption)

    const leadsCreated = data.leads_created.current
    const leadsAssigned = data.leads_assigned.current
    const leadAssignments = data.lead_assignments.current - data.leads_assigned.current
    const leadsContacted = data.leads_contacted.current
    const appointmentsBooked = data.appointments_booked.current
    const presentationsMade = data.presentations_made.current
    const salesMade = data.leads_closed.current

    const leadsAssignedConversion = (leadsCreated > 0) ? leadsAssigned / leadsCreated : 0
    const leadsContactedConversion = (leadsAssigned > 0) ? leadsContacted / leadsAssigned : 0
    const appointmentsBookedConversion = (leadsContacted > 0) ? appointmentsBooked / leadsContacted : 0
    const presentationsMadeConversion = (appointmentsBooked > 0) ? presentationsMade / appointmentsBooked : 0
    const salesConversion = (presentationsMade > 0) ? salesMade / presentationsMade : 0

    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Funnel Performance</h3>
        </div>
        <BlockContent className='block-content'>
          <div className='row'>
            <div className='col-xs-12' style={{ marginLeft: '50px' }}>
              <div className='row'>
                <div className='col-xs-2'>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <StepNumberView title='Leads created'
                        number={leadsCreated}
                        percentage={data.leads_created.change}
                        tooltipId={'funnelLeadsCreated'}
                        tooltipText={tooltips.funnelLeadsCreated} />
                    </div>
                    <div className='col-xs-6 hidden-xs hidden-sm'>
                      <ConversionChevronView
                        value={leadsAssignedConversion} />
                    </div>
                  </div>
                </div>
                <div className='col-xs-2'>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <StepNumberView title='Leads assigned'
                        number={leadsAssigned}
                        percentage={data.leads_assigned.change}
                        tooltipId={'funnelLeadsAssigned'}
                        tooltipText={tooltips.funnelLeadsAssigned} />
                        <SubValue>Reassigns: {leadAssignments}</SubValue>
                    </div>
                    <div className='col-xs-6 hidden-xs hidden-sm'>
                      <ConversionChevronView
                        value={leadsContactedConversion} />
                    </div>
                  </div>
                </div>
                <div className='col-xs-2'>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <StepNumberView title='Leads contacted'
                        number={leadsContacted}
                        percentage={data.leads_contacted.change}
                        tooltipId={'funnelLeadsContacted'}
                        tooltipText={tooltips.funnelLeadsContacted} />
                    </div>
                    <div className='col-xs-6 hidden-xs hidden-sm'>
                      <ConversionChevronView
                        value={appointmentsBookedConversion} />
                    </div>
                  </div>
                </div>
                <div className='col-xs-2'>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <StepNumberView title='Appointments booked'
                        number={appointmentsBooked}
                        percentage={data.appointments_booked.change}
                        tooltipId={'funnelAppointmentsBooked'}
                        tooltipText={tooltips.funnelAppointmentsBooked} />
                    </div>
                    <div className='col-xs-6 hidden-xs hidden-sm'>
                      <ConversionChevronView
                        value={presentationsMadeConversion} />
                    </div>
                  </div>
                </div>
                <div className='col-xs-2'>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <StepNumberView title='Presentations made'
                        number={presentationsMade}
                        percentage={data.presentations_made.change}
                        tooltipId={'funnelPresentationsmade'}
                        tooltipText={tooltips.funnelPresentationsmade} />
                    </div>
                    <div className='col-xs-6 hidden-xs hidden-sm'>
                      <ConversionChevronView
                        value={salesConversion} />
                    </div>
                  </div>
                </div>
                <div className='col-xs-2'>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <StepNumberView title='Total sales'
                        number={salesMade}
                        percentage={data.leads_closed.change}
                        tooltipId={'funnelLeadsClosed'}
                        tooltipText={tooltips.funnelLeadsClosed} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockContent>
      </div>
    )
  }
}

SalesPerformanceFunnelView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string
}

export default SalesPerformanceFunnelView

const BlockContent = styled.div`
  padding-bottom: 25px !important;
`;

const SubValue = styled.div`
  margin: 5px 0px 0px 0px;
  font-size: 11.2px;
  font-weight: 600;
  text-align: center;
`;