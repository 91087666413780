import PropTypes from "prop-types";
import React, { Component } from "react";

import FormConfigurationIntegration from "./FormConfigureIntegration";
import FormSelectLeadSourceView from "./FormSelectLeadSourceView";
import FormSelectLeadGoalView from "./FormSelectLeadGoalView";

export const SELECT_LEAD_SOURCE_VIEW = "FACEBOOK_SELECT_LEAD_SOURCE_VIEW";
export const SELECT_LEAD_GOAL_VIEW = "SELECT_LEAD_GOAL_VIEW";
export const CONFIGURE_INTEGRATION_VIEW = "FACEBOOK_CONFIGURE_INTEGRATION_VIEW";

export class FormView extends Component {
  state = {
    currentView: CONFIGURE_INTEGRATION_VIEW,
    linkedFacebookPage: null
  };

  currentView = () => {
    switch (this.state.currentView) {
      case CONFIGURE_INTEGRATION_VIEW:
        return (
          <FormConfigurationIntegration
            {...this.props}
            setCurrentView={this.setCurrentView}
          />
        );
      case SELECT_LEAD_SOURCE_VIEW:
        return (
          <FormSelectLeadSourceView
            {...this.props}
            setCurrentView={this.setCurrentView}
            linkedFacebookPage={this.state.linkedFacebookPage}
          />
        );
      case SELECT_LEAD_GOAL_VIEW:
        return (
          <FormSelectLeadGoalView
            {...this.props}
            setCurrentView={this.setCurrentView}
            linkedFacebookPage={this.state.linkedFacebookPage}
          />
        );
      default:
        break;
    }
  };

  setCurrentView = (currentView, linkedFacebookPage) => {
    this.setState({ currentView, linkedFacebookPage });
  };

  render() {
    return this.currentView();
  }
}

FormView.propTypes = {
  integration: PropTypes.object.isRequired,
  setPermissionForSet: PropTypes.func.isRequired,
  permissionSet: PropTypes.object,
  location: PropTypes.object.isRequired,
  updateFacebookPageIntegration: PropTypes.func.isRequired
};

export default FormView;
