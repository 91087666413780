/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import AssignLead from "modules/components/AssignLead";
import { UserAvatar } from "modules/components/Avatars";

import { userCanReAssign } from "modules/Stores/Session/helpers";
import "../../LeadMoreDetail.css";

export class AssignedToPanel extends Component {
  showAssignModal = () => {
    const { showModal } = this.props;
    showModal(AssignLead.constants.ASSIGN_LEAD_MODAL_NAME);
  };

  userCanEdit = () => {
    const { user } = this.props;
    if (userCanReAssign(user)) {
      return true;
    }
    return false;
  };

  render() {
    const { salesperson, listUpdateProtocol, lead } = this.props;
    const selectable = this.userCanEdit() ? "selectable" : "";
    return (
      <div>
        <a
          className={
            "block block-rounded block-bordered block-link-hover3 " + selectable
          }
          onClick={this.showAssignModal}
        >
          <div className="block-content block-content-full clearfix text-center">
            {salesperson && (
              <UserAvatar
                avatarImage={salesperson.user_avatar}
                type={"medium"}
              />
            )}
            {!salesperson && <UserAvatar avatarImage={null} type={"medium"} />}
            <div className="push-10-t">
              <div className="font-w600 push-5">Assigned to</div>
              {salesperson && (
                <div className="text-muted">{salesperson.display_name}</div>
              )}
              {!salesperson && <div className="text-muted">No one</div>}
            </div>
          </div>
        </a>
        {this.userCanEdit() && (
          <AssignLead.AssignLeadModalContainer
            lead={lead}
            modalName={AssignLead.constants.ASSIGN_LEAD_MODAL_NAME}
            listUpdateProtocol={listUpdateProtocol}
          />
        )}
      </div>
    );
  }
}

AssignedToPanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  assignLeadToUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  salesperson: PropTypes.object,
  currentModal: PropTypes.string,
};
