import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';
import { errorPanelForErrors } from 'utils/formHelpers';

import moment from 'moment';

import Datetime from 'react-datetime';
import 'styles/react-datetime.css';

export class EditTrialModal extends Component {
  state = {
    endDate: null,
    startDate: null,
    errors: null,
  };

  resetTrialState = () => {
    this.setState({ endDate: null, startDate: null, errors: null });
  };

  hideModalAndCleanUp = () => {
    this.resetTrialState();
    this.props.hideModal();
  };

  SetLeadTrialDate = (event) => {
    event && event.preventDefault();
    const { lead, currentTrial, updateLeadsTrial } = this.props;
    const { endDate, startDate } = this.state;

    updateLeadsTrial(lead, currentTrial, startDate.startOf('day').format(), endDate.endOf('day').format());
    this.hideModalAndCleanUp();
  };

  changeStartDate = (startDate) => {
    const { timezone, currentTrial } = this.props;
    var newStartDate = moment(startDate).tz(timezone).startOf('day');
    var newExpireDate = moment(startDate)
      .add(currentTrial.day_length - 1, 'days')
      .endOf('day')
      .tz(timezone);

    this.setState({
      ...this.state,
      startDate: newStartDate,
      endDate: newExpireDate,
    });
  };

  changeExpireDate = (expireDate) => {
    const { timezone, currentTrial } = this.props;
    var newExpireDate = moment(expireDate).tz(timezone).startOf('day');
    var newStartDate = moment(expireDate)
      .subtract(currentTrial.day_length - 1, 'days')
      .endOf('day')
      .tz(timezone);

    this.setState({
      ...this.state,
      startDate: newStartDate,
      endDate: newExpireDate,
    });
  };

  removeFromTrial = () => {
    const { lead, removeLeadFromTrial, currentTrial } = this.props;
    removeLeadFromTrial(lead, currentTrial);
    this.resetTrialState();
  };

  render() {
    const { currentModal, modalName, formats, timezone, currentTrial } = this.props;
    const trialStartDate = currentTrial.start_date;
    const trialEndDate = currentTrial.end_date;

    let selectedExpiresDate = trialEndDate ? moment(trialEndDate).tz(timezone) : '';
    selectedExpiresDate = this.state.endDate ? this.state.endDate : selectedExpiresDate;

    let selectedStartDate = trialStartDate ? moment(trialStartDate).tz(timezone) : '';
    selectedStartDate = this.state.startDate ? this.state.startDate : selectedStartDate;

    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              When does the trial start?
            </h3>
          </div>
          <div className="block-content">
            <div className="text-center">
              <h4 style={{ paddingBottom: '10px' }}>{currentTrial.name}</h4>
              <p>
                Editing the trials start or end date will automatically adjust the start or end date so the dates always matches the trials
                full duration.
              </p>
              <hr />
            </div>
            {errorPanelForErrors(this.state.errors)}
            <form className="form-horizontal" onSubmit={this.SetLeadTrialDate}>
              <div className="form-group">
                <div className="row" style={{ paddingBottom: '15px' }}>
                  <label className="col-md-4 control-label">Trial start date</label>
                  <div className="col-md-6">
                    <Datetime
                      value={selectedStartDate}
                      timeFormat={false}
                      locale={formats.locale}
                      dateFormat={formats.date}
                      inputProps={{
                        placeholder: formats.date,
                      }}
                      timezone={this.props.timezone}
                      utc={false}
                      viewMode={'days'}
                      onChange={(startDate) => this.changeStartDate(startDate)}
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-4 control-label">Trial end date</label>
                  <div className="col-md-6">
                    <Datetime
                      value={selectedExpiresDate}
                      timeFormat={false}
                      locale={formats.locale}
                      dateFormat={formats.date}
                      inputProps={{
                        placeholder: formats.date,
                      }}
                      timezone={this.props.timezone}
                      utc={false}
                      viewMode={'days'}
                      onChange={(expireDate) => this.changeExpireDate(expireDate)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-danger pull-left push-10" type="button" onClick={this.removeFromTrial}>
            Remove from Trial
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
            Cancel
          </button>
          <button
            className="btn btn-primary push-5-r push-10"
            type="button"
            disabled={!this.state.endDate || !this.state.startDate}
            onClick={this.SetLeadTrialDate}
          >
            <i className="fa fa-clock-o" /> Change Trial dates
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditTrialModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  formats: PropTypes.PropTypes.object.isRequired,
  currentModal: PropTypes.string,
};

export default EditTrialModal;
