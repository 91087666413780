import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from 'react-bootstrap-modal';

import { FormFields } from 'modules/components';
const { TextField } = FormFields;

export class EditHeardAboutMethodModal extends Component {
  state = {
    title: this.props.heardAboutMethod.heard_about_title,
  };

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, title: value });
  };

  updateClicked = () => {
    const { heardAboutMethod, updateHeardAboutMethod } = this.props;
    const { title } = this.state;
    updateHeardAboutMethod(heardAboutMethod, title);
  };

  render() {
    const { loading, currentModal, hideModal, modalName } = this.props;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Update method
              </h3>
            </div>
            <div className="block-content">
              <div className="form-material">
                <TextField
                  name="title"
                  label="Heard about name"
                  placeholder="eg. Facebook add"
                  formFieldStyle="form-material form-material-primary"
                  onChange={this.handleInput}
                  needsFormGroup={false}
                  initialValue={this.props.heardAboutMethod.heard_about_title}
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn btn-primary push-5-r push-10" type="button" onClick={this.updateClicked}>
              <i className="fa fa-save" /> Update heard about method
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

EditHeardAboutMethodModal.propTypes = {
  heardAboutMethod: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  updateHeardAboutMethod: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default EditHeardAboutMethodModal;
