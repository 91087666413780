import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";

import { FormFields } from "modules/components";

import { columnWidthClass } from "./FilterCheckboxSection";

import FilterSection from "./FilterSection";

const { Checkbox } = FormFields;
export default class SalesFunnelSection extends Component {
  fieldPrefix() {
    return this.props.sectionName + "/";
  }

  handleToggle = (fieldName, value) => {
    const truncatedFieldName = fieldName.replace(this.fieldPrefix(), "");
    const newSectionData = generateNewFormState(
      this.props.data,
      truncatedFieldName,
      value,
      true
    );
    this.props.onUpdate(newSectionData, this.props.sectionName);
  };

  render() {
    const userStates = this.props.salesFunnelSteps.map(
      (salesFunnelStep, index) => {
        const field = this.props.data.fields[salesFunnelStep.id];
        const checked = (field && field.value === true) || false;
        return (
          <div key={index} className={columnWidthClass}>
            <Checkbox
              label={salesFunnelStep.name}
              name={this.fieldPrefix() + salesFunnelStep.id.toString()}
              onChange={this.handleToggle}
              switchStyle={"primary"}
              disabled={false}
              checked={checked}
            />
          </div>
        );
      }
    );

    return (
      <FilterSection
        title={this.props.title}
        showBottomBorder={this.props.showBottomBorder}
      >
        <div> {userStates} </div>
      </FilterSection>
    );
  }
}

SalesFunnelSection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  showBottomBorder: PropTypes.bool,
};
