import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import CreateLeadContainer from '../containers/CreateLeadContainer';
import LocationSelectContainer from '../containers/LocationSelectContainer';
import UserSelectContainer from '../containers/UserSelectContainer';
import LeadSourceSelectContainer from '../containers/LeadSourceSelectContainer';
import HeardAboutMethodSelectContainer from '../containers/HeardAboutMethodSelectContainer';
import LeadGoalSelectContainer from '../containers/LeadGoalSelectContainer';

import {
  SELECT_LOCATION_VIEW,
  CREATE_LEAD_VIEW,
  SELECT_USER_VIEW,
  SELECT_LEAD_SOURCE_VIEW,
  SELECT_HEARD_ABOUT_METHOD_VIEW,
  SELECT_LEAD_GOAL_VIEW,
} from '../constants';

export class CreateLeadModal extends Component {
  componentDidMount() {
    const { selectLocation, defaultLocation, isMultiLocationAccount } = this.props;
    if (isMultiLocationAccount) {
      return;
    }
    selectLocation(defaultLocation);
  }

  autoAssignToCurentUser = () => {
    const { setSelectedUser, user, selectedUser } = this.props;
    if (!selectedUser) {
      setSelectedUser({ id: user.id, displayName: user.display_name });
    }
  };

  modalTitle = () => {
    const { currentView } = this.props;
    switch (currentView) {
      case CREATE_LEAD_VIEW:
        return 'Create Lead';
      case SELECT_LOCATION_VIEW:
        return 'Create Lead: Select Location';
      case SELECT_USER_VIEW:
        return 'Create Lead: Assign to User';
      case SELECT_LEAD_SOURCE_VIEW:
        return 'Create Lead: Select Lead Source';
      case SELECT_HEARD_ABOUT_METHOD_VIEW:
        return 'Create Lead: Where did they hear about you?';
      case SELECT_LEAD_GOAL_VIEW:
        return 'Create Lead: What is their goal?';
      default:
        return '';
    }
  };

  dismissModal = () => {
    const { resetForm, hideModal } = this.props;
    hideModal();
    resetForm();
  };

  render() {
    const { loading, currentModal, modalName, currentView, selectedLocation } = this.props;
    const blockStyle = loading ? 'block block-opt-refresh' : 'block';

    return (
      <Modal
        show={currentModal === modalName}
        onEnter={this.autoAssignToCurentUser}
        onHide={this.dismissModal}
        dialogClassName="modal-lg"
        backdrop="static"
      >
        <div className={blockStyle}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.dismissModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              {this.modalTitle()}
            </h3>
          </div>
          {currentView === CREATE_LEAD_VIEW && <CreateLeadContainer />}
          {currentView === SELECT_LOCATION_VIEW && <LocationSelectContainer />}
          {selectedLocation && currentView === SELECT_USER_VIEW && <UserSelectContainer />}
          {currentView === SELECT_LEAD_SOURCE_VIEW && <LeadSourceSelectContainer />}
          {currentView === SELECT_HEARD_ABOUT_METHOD_VIEW && <HeardAboutMethodSelectContainer />}
          {currentView === SELECT_LEAD_GOAL_VIEW && <LeadGoalSelectContainer />}
        </div>
      </Modal>
    );
  }
}

CreateLeadModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  currentView: PropTypes.string.isRequired,
  defaultLocation: PropTypes.object.isRequired,
  isMultiLocationAccount: PropTypes.bool.isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
};

export default CreateLeadModal;
