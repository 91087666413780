/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import EnterClubIdContainer from "../containers/EnterClubIdContainer";
import VerifyLinkBodyContainer from "../containers/VerifyLinkBodyContainer";
import SelectLocationBodyContainer from "../containers/SelectLocationBodyContainer";
import ConfirmBodyContainer from "../containers/ConfirmBodyContainer";

export class MindBodyWizardView extends Component {
  state = {
    titles: [
      "1. Enter Club ID",
      "2. Authorize access",
      "3. Select location",
      "4. Confirm & options",
    ],
  };

  render() {
    const { tabIndex, selectedIntegration } = this.props;

    const tabs = this.state.titles.map((title, index) => {
      const activeClassName = tabIndex === index ? "active" : "";
      return (
        <li className={activeClassName} key={index}>
          <a style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
            {" "}
            {title}
          </a>
        </li>
      );
    });

    const width = (((tabIndex + 1) * 1.0) / 4) * 100;

    const currentContainer = () => {
      switch (tabIndex) {
        case 0:
          return <EnterClubIdContainer />;
        case 1:
          return <VerifyLinkBodyContainer />;
        case 2:
          return <SelectLocationBodyContainer location={this.props.location} />;
        case 3:
          return (
            <ConfirmBodyContainer
              location={this.props.location}
              selectedIntegration={selectedIntegration}
            />
          );
        default:
          return <div />;
      }
    };

    return (
      <div
        className="js-wizard-simple block"
        style={{
          marginTop: "-20px",
          marginLeft: "-20px",
          marginRight: "-20px",
        }}
      >
        <ul className="nav nav-tabs nav-justified">{tabs}</ul>

        <div className="form-horizontal" action="base_forms_wizard.html">
          <div className="block-content block-content-mini block-content-full border-b">
            <div className="wizard-progress progress progress-mini remove-margin-b">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                style={{ width: width + "%" }}
              />
            </div>
          </div>

          <div className="block-content tab-content">{currentContainer()}</div>
        </div>
      </div>
    );
  }
}

MindBodyWizardView.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  selectedIntegration: PropTypes.object,
};

export default MindBodyWizardView;
