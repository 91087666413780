import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class GymmasterLocationRow extends Component {

  render () {
    const { gymmasterLocation, locationSelected, selected } = this.props
    const backgroundClass = (selected) ? 'bg-primary' : ''
    const textClass = (selected) ? 'text-white' : ''
    return (
      <tr className={textClass + ' selectable ' + backgroundClass}
        onClick={() => locationSelected(gymmasterLocation)}>
        <td>{gymmasterLocation.id}</td>
        <td>{gymmasterLocation.name}</td>
      </tr>
    )
  }
}

GymmasterLocationRow.propTypes = {
  gymmasterLocation: PropTypes.object.isRequired,
  locationSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool
}
