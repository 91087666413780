import PropTypes from 'prop-types'
import React, { Component } from 'react'

import moment from 'moment'

import { ContentBlockView } from 'modules/components'
import StatisticNumber from './StatisticNumber'

export class EmailDomainStatsView extends Component {

  UNSAFE_componentWillMount () {
    const { domain, getDomainStatistics } = this.props
    const start = moment().startOf('Day').subtract(30, 'days').utc().format()
    const end = moment().endOf('Day').utc().format()
    getDomainStatistics(domain, start, end)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.domain.id === nextProps.domain.id) { return }
    const { domain, getDomainStatistics } = nextProps
    const start = moment().startOf('Day').subtract(30, 'days').utc().format()
    const end = moment().endOf('Day').utc().format()
    getDomainStatistics(domain, start, end)
  }

  render () {
    const { loading, rates } = this.props
    return (
      <ContentBlockView title='Domain statistics for last 30 days' loading={loading} bordered>
        {rates &&
          <div className='row'>
            <div className='col-sm-6 col-lg-3'>
              <StatisticNumber number={rates.delivery_rate} title='delivered' type='bg-primary' />
            </div>
            <div className='col-sm-6 col-lg-3'>
              <StatisticNumber number={rates.unique_open_rate} title='opened' type='bg-success' />
            </div>
            <div className='col-sm-6 col-lg-3'>
              <StatisticNumber number={rates.bounce_rate} title='bounced' type='bg-warning' />
            </div>
            <div className='col-sm-6 col-lg-3'>
              <StatisticNumber number={rates.complaint_rate} title='complained' type='bg-danger' />
            </div>
          </div>
        }
      </ContentBlockView>
    )
  }
}

EmailDomainStatsView.propTypes = {
  domain: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getDomainStatistics: PropTypes.func.isRequired,
  statistics: PropTypes.object,
  rates: PropTypes.object
}

export default EmailDomainStatsView
