/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from 'modules/components';
import '../../LeadMoreDetail.css';
import SelectLeadSourceModalContainer from './SelectLeadSourceModalContainer';

import * as leadSourceHelpers from 'modules/Helpers/leadSourceHelpers';

import moment from 'moment';
import 'moment-timezone';

const LEAD_SOURCE_MODAL = 'LEAD_SOURCE_MODAL';

export class LeadSourcePanel extends Component {
  sourceSelected = () => {
    this.props.showModal(LEAD_SOURCE_MODAL);
  };

  render() {
    const { lead, timezone } = this.props;
    const leadSource = this.props.lead.source;
    const icon = <i className={leadSourceHelpers.icon(leadSource) + ' fa-2x'} />;
    return (
      <Box>
        <a className="block block-link-hover2 block-bordered selectable" onClick={this.sourceSelected}>
          <div className="block-content block-content-full clearfix text-center" style={{ height: 158 }}>
            <div className="text-muted push-10">{icon}</div>
            <div className="text-muted">Inquired via</div>
            <div className="font-w600 push-5">{leadSource}</div>
            <div className="text-muted push-5">{moment(lead.created_at).tz(timezone).format('Do MMM YYYY')}</div>
          </div>
        </a>
        <SelectLeadSourceModalContainer {...this.props} modalName={LEAD_SOURCE_MODAL} />
      </Box>
    );
  }
}

LeadSourcePanel.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateLead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
};
