import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UpdateScheduleModalView } from "../components/UpdateScheduleModalView";

import session from "modules/Stores/Session";
import modal from "modules/Stores/Modal";
import userData from "modules/Stores/UserData";

import * as actions from "../actions";
import * as selectors from "../selectors";

const { hideModal } = modal.actions;
function mapStateToProps(state, ownProps) {
  const userId = ownProps.user.id;
  const locationId = ownProps.location.id;
  const getSchedule = userData.selectors.scheduleForUserLocationFactory(
    userId,
    locationId
  );
  return {
    currentModal: state.modal.currentModal,
    loading: selectors.getLoading(state),
    schedule: getSchedule(state),
    scheduleSlots: selectors.getScheduleSlots(state),
    timezone: session.selectors.getUserTimezone(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      setScheduleSlots: actions.setScheduleSlots,
      updateSchedule: actions.updateSchedule,
      deleteSchedule: actions.deleteSchedule,
      addSlotToSchedule: actions.addSlotToSchedule,
      removeSlotFromSchedule: actions.removeSlotFromSchedule,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateScheduleModalView);
