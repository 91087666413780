import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomMailMergeTagRow from './CustomMailMergeTagRow';
import PagesView from 'modules/components/PageSelection/PagesView';
import { ContentBlockView, EmptyView, TextSearchView } from 'modules/components';
import NewCustomMailMergeTagModalContainer from '../containers/NewCustomMailMergeTagModalContainer';
import CustomMailMergeTagDetailContainer from '../containers/CustomMailMergeTagDetailContainer';

import { NEW_CUSTOM_MAIL_MERGE_TAG_MODAL } from '../constants';

export class CustomMailMergeTagsView extends Component {
  UNSAFE_componentWillMount() {
    const { customMailMergeTags } = this.props;
    if (customMailMergeTags.length > 0) {
      return;
    }
    this.getCustomMailMergeTagsWithPageNumber(1);
  }

  getCustomMailMergeTagsWithPageNumber = (pageNumber) => {
    const { getCustomMailMergeTags } = this.props;
    getCustomMailMergeTags(pageNumber);
  };

  pageClicked = (pageNumber) => {
    this.props.setSelectedCustomMailMergeTagId(null);
    this.getCustomMailMergeTagsWithPageNumber(pageNumber);
  };

  searchUpdated = (searchTerm) => {
    const { getCustomMailMergeTags } = this.props;
    getCustomMailMergeTags(1, searchTerm);
  };

  customMailMergeTagSelected = (emailCustomMailMergeTag) => {
    this.props.prefillDataForCustomMailMergeTag(emailCustomMailMergeTag);
  };

  showCreateModal = () => {
    const { showModal } = this.props;
    showModal(NEW_CUSTOM_MAIL_MERGE_TAG_MODAL);
  };

  render() {
    const { customMailMergeTags, loading, selectedCustomMailMergeTagId, selectedCustomMailMergeTag } = this.props;
    const customMailMergeTagRows = customMailMergeTags.map((customMailMergeTag, index) => {
      const selected = customMailMergeTag.id === selectedCustomMailMergeTagId;
      return (
        <CustomMailMergeTagRow
          customMailMergeTag={customMailMergeTag}
          key={index}
          selected={selected}
          onSelect={this.customMailMergeTagSelected}
        />
      );
    });

    const addButton = (
      <button type="button" className="pull-right btn btn-xs btn-success" onClick={this.showCreateModal}>
        <i className="fa fa-plus" /> Create New Mail Merge Tag
      </button>
    );

    const pageCount = this.props.pageDetails.pageCount;
    return (
      <div>
        <ContentBlockView title={'Custom Mail Merge Tags'} loading={loading} blockOptions={addButton} bordered>
          <div className="row" style={{ marginTop: '-30px' }}>
            <TextSearchView sectionName={'customMailMergeTagSearch'} onUpdate={this.searchUpdated} />
          </div>
          <div className="list-group selectable push" style={{ marginTop: '8px' }}>
            {customMailMergeTagRows}
            {customMailMergeTags.length === 0 && (
              <EmptyView
                colorThemeClass="primary"
                iconClass="si si-tag"
                title="No Custom mail merge tags"
                buttonText="Create first mail merge tag"
                buttonSelected={this.showCreateModal}
                subtitle="Add custom mail merge tags to add new fields to your communications"
              />
            )}
          </div>
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView pageClicked={this.pageClicked} pageDetails={this.props.pageDetails} />
            </div>
          )}
        </ContentBlockView>
        {selectedCustomMailMergeTagId && selectedCustomMailMergeTag && <CustomMailMergeTagDetailContainer />}
        <NewCustomMailMergeTagModalContainer />
      </div>
    );
  }
}

CustomMailMergeTagsView.propTypes = {
  prefillDataForCustomMailMergeTag: PropTypes.func.isRequired,
  customMailMergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getCustomMailMergeTags: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setSelectedCustomMailMergeTagId: PropTypes.func.isRequired,
  selectedCustomMailMergeTag: PropTypes.object,
  selectedCustomMailMergeTagId: PropTypes.number,
  pageDetails: PropTypes.object,
};

export default CustomMailMergeTagsView;
