import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ImportRow from '../components/ImportRow'

import session from 'modules/Stores/Session'

function mapStateToProps (state, props) {
  return {
    user: session.selectors.getUser(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportRow)
