import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';
import 'moment-timezone';

import Modal from 'react-bootstrap-modal';

import DateTimeSelect from 'modules/components/DateTimeSelect/DateTimeSelect';

import { FormFields } from 'modules/components';

import { NEW_TODO_MODAL } from '../../constants';

const { TextField } = FormFields;
const TITLE_PANEL = 'TITLE_PANEL';
const DATE_TIME_PANEL = 'DATE_TIME_PANEL';

export default class CustomTodoModal extends Component {
  state = {
    dateTimeString: null,
    todoTitle: null,
    currentlyShowing: TITLE_PANEL,
  };

  resetState = () => {
    this.setState({
      dateTimeString: null,
      todoTitle: null,
      currentlyShowing: TITLE_PANEL,
    });
  };

  hideModalAndCleanUp = () => {
    this.resetState();
    this.props.hideModal();
  };

  scheduleToDo = () => {
    const { createTodoForLead, lead, modalName } = this.props;
    const { dateTimeString } = this.state;
    const dateTimeMoment = moment(dateTimeString);

    const utcDateTimeAsString = dateTimeMoment.utc().format();

    const title = this.state.todoTitle;

    createTodoForLead(lead, title, null, modalName, utcDateTimeAsString);
    this.hideModalAndCleanUp();
  };

  backToTitlePanel = () => {
    this.setState({ ...this.state, currentlyShowing: TITLE_PANEL });
  };

  showDateTimePanel = (event) => {
    event && event.preventDefault();
    this.setState({ ...this.state, currentlyShowing: DATE_TIME_PANEL });
  };

  backToTodoOptionsSelected = () => {
    this.resetState();
    this.props.showModal(NEW_TODO_MODAL);
  };

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, todoTitle: value });
  };

  generateFooterButtons = () => {
    switch (this.state.currentlyShowing) {
      case TITLE_PANEL:
        return (
          <div>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
              Cancel
            </button>
            <button
              className="btn btn-success push-5-r push-10"
              type="button"
              disabled={!this.state.todoTitle}
              onClick={this.showDateTimePanel}
            >
              <i className="si si-calendar" /> Set date
            </button>
          </div>
        );
      case DATE_TIME_PANEL:
        return (
          <div>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.backToTitlePanel}>
              Back
            </button>
            <button
              className="btn btn-success push-5-r push-10"
              type="button"
              disabled={!this.state.dateTimeString || !this.state.todoTitle}
              onClick={this.scheduleToDo}
            >
              <i className="si si-check" /> Schedule task
            </button>
          </div>
        );
      default:
        return;
    }
  };

  generateModalBody = () => {
    switch (this.state.currentlyShowing) {
      case TITLE_PANEL:
        return (
          <div className="block-content">
            <form className="form-horizontal push-30-t push-50" onSubmit={this.showDateTimePanel}>
              <TextField
                name="todoTitle"
                label="What needs to happen?"
                placeholder="eg. Find out pricing for their current gym"
                formFieldStyle="form-material form-material-success"
                onChange={this.handleInput}
                disabled={false}
                initialValue={this.state.todoTitle}
              />
            </form>
          </div>
        );
      case DATE_TIME_PANEL:
        return (
          <div>
            <DateTimeSelect onUpdate={(dateTimeString) => this.setState({ ...this.state, dateTimeString: dateTimeString })} />
            <br />
          </div>
        );
      default:
        return;
    }
  };

  generateModalTitle = () => {
    switch (this.state.currentlyShowing) {
      case TITLE_PANEL:
        return 'What should we name the task?';
      case DATE_TIME_PANEL:
        return 'When should it be completed by?';
      default:
        return;
    }
  };

  render() {
    const { currentModal, modalName } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              {this.generateModalTitle()}
            </h3>
          </div>
          {this.generateModalBody()}
        </div>
        <Modal.Footer>{this.generateFooterButtons()}</Modal.Footer>
      </Modal>
    );
  }
}

CustomTodoModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  createTodoForLead: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};
