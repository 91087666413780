import PropTypes from "prop-types";
import React, { Component } from "react";

export class FormView extends Component {
  render() {
    const { data } = this.props;
    const { integrationLocationId } = data.fields;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h2 className="h4 push-10">
              This club is now integrated with Booker
            </h2>
            <p>
              <strong>Booker Location ID:</strong> {integrationLocationId.value}
              <br />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

FormView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FormView;
