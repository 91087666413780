import PropTypes from 'prop-types'
import React, { Component } from 'react'

import CreateSalesTargetsContainer from '../containers/createSalesTargetsContainer'
import TargetsContainer from '../containers/TargetsContainer'
import LeadTimeOutContainer from '../containers/LeadTimeOutContainer'

export class SalesTargetsView extends Component {

  render () {
    const { location } = this.props
    return (
      <div>
        {!location.target_rule_set &&
          <CreateSalesTargetsContainer location={location} />
        }
        {location.target_rule_set &&
          <div>
            <TargetsContainer location={location} />
            <LeadTimeOutContainer location={location} />
          </div>
        }
      </div>
    )
  }
}

SalesTargetsView.propTypes = {
  location: PropTypes.object.isRequired
}

export default SalesTargetsView
