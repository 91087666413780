/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment-timezone";

import DateSelectionModalContainer from "./DateSelectionModalContainer";

const ALL_TIME = "All time";
const TODAY = "Today";
const YESTERDAY = "Yesterday";
const THIS_WEEK = "This Week";
const LAST_WEEK = "Last Week";
const THIS_MONTH = "This Month";
const LAST_MONTH = "Last Month";
const LAST_7_DAYS = "Last 7 days";
const LAST_30_DAYS = "Last 30 Days";
const CUSTOM = "Custom";

const SET_CUSTOM_DATE_RANGE_MODAL = "SET_CUSTOM_DATE_RANGE_MODAL";

export class SelectDateRangeButton extends Component {
  state = {
    showingOptions: false,
    selectedIndex: 0,
    dateRanges: [
      ALL_TIME,
      TODAY,
      YESTERDAY,
      THIS_WEEK,
      LAST_WEEK,
      THIS_MONTH,
      LAST_MONTH,
      LAST_7_DAYS,
      LAST_30_DAYS,
      CUSTOM,
    ],
  };

  indexClicked = (index) => {
    this.setState({
      ...this.state,
      showingOptions: false,
      selectedIndex: index,
    });
    const option = this.state.dateRanges[index];
    if (option === CUSTOM) {
      this.showDateSelectionModal();
    } else {
      this.generateDateAndReturnForIndex(index);
    }
  };

  showDateSelectionModal = () => {
    this.props.showModal(SET_CUSTOM_DATE_RANGE_MODAL);
  };

  handleClickOutside = (event) => {
    this.setState({ ...this.state, showingOptions: false });
  };

  generateDateAndReturnForIndex = (index) => {
    const { updatedAfterTitle, updatedBeforeTitle } = this.props;
    const option = this.state.dateRanges[index];
    let startDate = moment();
    let endDate = moment();
    switch (option) {
      case ALL_TIME:
        const data = {
          [updatedAfterTitle]: null,
          [updatedBeforeTitle]: null,
        };
        this.props.onUpdate(data, this.props.sectionName);
        return;
      case TODAY:
        startDate = moment().startOf("day").utc();
        endDate = moment().endOf("day").utc();
        break;
      case YESTERDAY:
        startDate = moment().subtract(1, "day").startOf("day").utc();
        endDate = moment().subtract(1, "day").endOf("day").utc();
        break;
      case THIS_WEEK:
        startDate = moment().startOf("week").utc();
        endDate = moment().endOf("week").utc();
        break;
      case LAST_WEEK:
        startDate = moment().subtract(1, "week").startOf("week").utc();
        endDate = moment().subtract(1, "week").endOf("week").utc();
        break;
      case THIS_MONTH:
        startDate = moment().startOf("month").utc();
        endDate = moment().endOf("month").utc();
        break;
      case LAST_MONTH:
        startDate = moment().subtract(1, "month").startOf("month").utc();
        endDate = moment().subtract(1, "month").endOf("month").utc();
        break;
      case LAST_7_DAYS:
        startDate = moment().subtract(7, "days").startOf("day").utc();
        endDate = moment().endOf("day").utc();
        break;
      case LAST_30_DAYS:
        startDate = moment().subtract(30, "days").startOf("day").utc();
        endDate = moment().endOf("day").utc();
        break;
      default:
        break;
    }

    const data = {
      [updatedAfterTitle]: startDate.format(),
      [updatedBeforeTitle]: endDate.format(),
    };

    this.props.onUpdate(data, this.props.sectionName);
  };

  toggleDropdown = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  selectedDateRange = () => {
    const { updatedAfterTitle, updatedBeforeTitle } = this.props;
    const updatedAfter = this.props.data.fields[updatedAfterTitle];
    const updatedBefore = this.props.data.fields[updatedBeforeTitle];
    if (!updatedAfter || !updatedAfter.value) {
      return ALL_TIME;
    }

    const startDate = moment(updatedAfter.value);
    const endDate = moment(updatedBefore.value);

    if (
      startDate.isSame(moment().startOf("day").utc(), "day") &&
      endDate.isSame(moment().endOf("day").utc(), "day")
    ) {
      return TODAY;
    } else if (
      startDate.isSame(
        moment().subtract(1, "day").startOf("day").utc(),
        "day"
      ) &&
      endDate.isSame(moment().subtract(1, "day").endOf("day").utc(), "day")
    ) {
      return YESTERDAY;
    } else if (
      startDate.isSame(moment().startOf("week").utc(), "day") &&
      endDate.isSame(moment().endOf("week").utc(), "day")
    ) {
      return THIS_WEEK;
    } else if (
      startDate.isSame(
        moment().subtract(1, "week").startOf("week").utc(),
        "day"
      ) &&
      endDate.isSame(moment().subtract(1, "week").endOf("week").utc(), "day")
    ) {
      return LAST_WEEK;
    } else if (
      startDate.isSame(moment().startOf("month").utc(), "day") &&
      endDate.isSame(moment().endOf("month").utc(), "day")
    ) {
      return THIS_MONTH;
    } else if (
      startDate.isSame(
        moment().subtract(1, "month").startOf("month").utc(),
        "day"
      ) &&
      endDate.isSame(moment().subtract(1, "month").endOf("month").utc(), "day")
    ) {
      return LAST_MONTH;
    } else if (
      startDate.isSame(
        moment().subtract(7, "days").startOf("day").utc(),
        "day"
      ) &&
      endDate.isSame(moment().endOf("day").utc(), "day")
    ) {
      return LAST_7_DAYS;
    } else if (
      startDate.isSame(
        moment().subtract(30, "days").startOf("day").utc(),
        "day"
      ) &&
      endDate.isSame(moment().endOf("day").utc(), "day")
    ) {
      return LAST_30_DAYS;
    }
    return CUSTOM;
  };

  render() {
    const { highlightOnChange } = this.props;
    const options = this.state.dateRanges.map((dateRange, index) => {
      const className =
        index === this.state.selectedIndex ? "selected" : "selectable";
      return (
        <li key={index} className={className}>
          <a onClick={() => this.indexClicked(index)}>{dateRange}</a>
        </li>
      );
    });
    let selectedOption = this.selectedDateRange();
    const btnColorStyle =
      selectedOption === ALL_TIME || highlightOnChange === false
        ? "btn-default"
        : "btn-info";
    return (
      <div>
        <button
          className={"btn " + btnColorStyle}
          type="button"
          onClick={this.toggleDropdown}
        >
          {this.props.buttonTitlePrefix}
          {selectedOption} <i className="fa fa-caret-down" />
        </button>
        {this.state.showingOptions && (
          <DropdownList className="dropdown-menu">{options}</DropdownList>
        )}
        <DateSelectionModalContainer
          hideModal={this.props.hideModal}
          modalName={SET_CUSTOM_DATE_RANGE_MODAL}
          currentModal={this.props.currentModal}
          onUpdate={this.props.onUpdate}
          data={this.props.data}
          sectionName={this.props.sectionName}
          updatedAfterTitle={this.props.updatedAfterTitle}
          updatedBeforeTitle={this.props.updatedBeforeTitle}
        />
      </div>
    );
  }
}

SelectDateRangeButton.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updatedAfterTitle: PropTypes.string.isRequired,
  updatedBeforeTitle: PropTypes.string.isRequired,
  buttonTitlePrefix: PropTypes.string,
  sectionName: PropTypes.string,
  currentModal: PropTypes.string,
  highlightOnChange: PropTypes.bool,
};

SelectDateRangeButton.defaultProps = {
  highlightOnChange: true,
};

export default SelectDateRangeButton;

const DropdownList = styled.ul`
  display: block;
  top: unset !important;
  left: unset !important;
`;
