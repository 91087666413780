import { connect } from 'react-redux'
import { ForgotPasswordView } from '../components/ForgotPasswordView'

import { sendEmail, reset, fetchWhitelabelProfileLogo } from '../actions'
import { getLoading, getErrors, getSuccess, getWhitelabelProfile, getProductName } from '../selectors'


const mapStateToProps = state => ({
  loading: getLoading(state),
  errors: getErrors(state),
  success: getSuccess(state),
  productName: getProductName(state),
  whitelabelProfile: getWhitelabelProfile(state)
})

const mapActionCreators = {
  fetchWhitelabelProfileLogo,
  sendEmail,
  reset
}

export default connect(mapStateToProps, mapActionCreators)(ForgotPasswordView)
