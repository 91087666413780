import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { history } from 'store/store';

import ReedemInviteForm from './RedeemInviteForm';
import { EmptyView } from 'modules/components';

export class RedeemView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchInvite, token } = this.props;
    fetchInvite(token);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      history.push('/secure/dashboard');
    }
  }

  onFormSubmit = (givenName, password, username) => {
    const { redeemInvite, token } = this.props;
    redeemInvite(token, givenName, username, password);
  };

  render() {
    const { loading, errors, isInvalid } = this.props;
    const { invite } = this.props;
    const blockStyle = loading ? 'block block-themed animated fadeIn block-opt-refresh' : 'block block-themed animated fadeIn';
    return (
      <div className="content overflow-hidden">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
            <div className={blockStyle}>
              <div className="block-header bg-success">
                <h3 className="block-title">Redeem invite</h3>
              </div>
              <div className="block-content block-content-full block-content-narrow">
                {invite && (
                  <div>
                    <h1 className="h2 font-w600 push-30-t push-5">Hey {invite.invite_name}!</h1>
                    <p>
                      You've been invited to manage leads for {invite.company.title} at the {invite.location.admin_display_name} club.
                    </p>
                    <p>Fill in the below details to get started.</p>
                    <ReedemInviteForm
                      loading={loading}
                      errors={errors}
                      onSubmit={this.onFormSubmit}
                      name={invite.invite_name}
                      showModal={this.props.showModal}
                    />
                  </div>
                )}
                {isInvalid && (
                  <EmptyView
                    colorThemeClass="danger"
                    iconClass="si si-paper-plane"
                    title="This invite has flown away!"
                    subtitle="That invitation link seems to be missing or invalid.
                     Please check with your sales manager or account owner"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RedeemView.propTypes = {
  token: PropTypes.string.isRequired,
  fetchInvite: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  redeemInvite: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  invite: PropTypes.object,
  user: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showModal: PropTypes.func.isRequired,
};
