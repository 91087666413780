import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import { FormFields } from 'modules/components'
import { MainLocationValidator } from '../validators'
import { placeholdersForCountry } from 'modules/Helpers/FormatHelpers'

import moment from 'moment'
import 'moment-timezone'

const { TextField, SelectField } = FormFields

export class EditLocationForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  phonePlaceholder = () => {
    const { data, countries } = this.props
    const countryId = data.fields.countryId
    return placeholdersForCountry(countryId.value, countries).phone
  }

  render () {
    const { addressLineOne, addressLineTwo, city, countryId,
      state, zipPostcode, name, adminName, phoneNumber, timezone } = this.props.data.fields
    const { countries } = this.props
    return (
      <div>
        <div>
          <TextField name='name'
            label='Club Name (viewable by leads and general public)'
            placeholder='Melbourne Central'
            formFieldStyle=''
            onChange={this.handleInput}
            value={name.value} />
          <TextField name='adminName'
            label={'Club Admin Name (Only viewable by ' + this.props.productName + ' users)'}
            placeholder='MC001'
            formFieldStyle=''
            onChange={this.handleInput}
            value={adminName.value} />
          <TextField name='addressLineOne'
            label='Address Line One'
            placeholder='Level 5'
            formFieldStyle=''
            onChange={this.handleInput}
            validator={MainLocationValidator}
            value={addressLineOne.value} />
          <TextField name='addressLineTwo'
            label='Address Line Two'
            placeholder='12 Fitness Cresent'
            formFieldStyle=''
            onChange={this.handleInput}
            value={addressLineTwo.value} />
          <TextField name='phoneNumber'
            label='Phone Number'
            placeholder={this.phonePlaceholder()}
            formFieldStyle=''
            onChange={this.handleInput}
            value={phoneNumber.value} />
          <div className='row'>
            <div className='col-sm-6'>
              <TextField name='city'
                label='City'
                placeholder='Glen Iris'
                formFieldStyle=''
                onChange={this.handleInput}
                validator={MainLocationValidator}
                value={city.value} />
            </div>
            <div className='col-sm-6'>
              <TextField name='state'
                label='State'
                placeholder='VIC'
                formFieldStyle=''
                onChange={this.handleInput}
                validator={MainLocationValidator}
                value={state.value} />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <SelectField
                name='countryId'
                label='Country'
                possibleOptions={countries}
                placeHolderText='Select a country'
                formFieldStyle=''
                onChange={this.handleInput}
                validator={MainLocationValidator}
                displayFieldName={'name'}
                initialValue={countryId.value} />
            </div>
            <div className='col-sm-6'>
              <TextField name='zipPostcode'
                label='ZIP/Postcode'
                placeholder='3000'
                formFieldStyle=''
                onChange={this.handleInput}
                validator={MainLocationValidator}
                value={zipPostcode.value} />
            </div>
            <div className='col-sm-6'>
              <SelectField
                name='timezone'
                label='Time Zone'
                possibleOptions={moment.tz.names()}
                placeHolderText='Select the timezone this location operates in'
                formFieldStyle=''
                onChange={this.handleInput}
                validator={MainLocationValidator}
                initialValue={timezone.value} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <button className='btn btn-primary push-5-r push-10'
                type='button'
                onClick={this.props.onSaveSelected}>
                <i className='fa fa-building-o' /> Update Location
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditLocationForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSaveSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default EditLocationForm
