import React from "react";
import styled from "styled-components";

const HeaderView = ({ toggleShowSubscription, selectedSubscription, selectedIntegration }) => {
  function integrationMessage() {
    if (!selectedIntegration) {
      return "Calendly integration allows leads to book in appointments with you and it will automatically schedule this in GymLeads. It will also automatically track whether they have cancelled or rescheduled. For any new leads making an appointment, it will also create them a profile."
    } else if (selectedIntegration && !selectedSubscription) {
      return "If you have multiple Calendly accounts, you can sync them all individually with GymLeads. Your Calendly integrations will appear below. Please click on your Calendly integration to alter your permissions."
    } else if (selectedIntegration && selectedSubscription) {
      return "For your Calendly integration, please select the below permissions you would like activated."
    }
  }
  
  const headerMessage = integrationMessage()

  return (
    <Container>
      <div className="row">
        <div className="col-sm-9">
        {headerMessage}
        </div>
        <div className="col-sm-3">
          {!selectedSubscription && selectedIntegration && (
            <button className="btn btn-success" onClick={() => toggleShowSubscription()}>Add new</button>
          )}
        </div>
      </div>
      <Divider />
    </Container>
  );
};

export default HeaderView;

const Container = styled.div``;
const Divider = styled.hr``;