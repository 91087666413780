import PropTypes from "prop-types";
import React, { Component } from "react";

import { SalesTargetRow } from "./SalesTargetRow";

import moment from "moment";
import "moment-timezone";

export class UserTagetsView extends Component {
  state = {
    showingPercentage: false,
  };

  UNSAFE_componentWillMount() {
    const { idToFetch, location, fetch } = this.props;
    fetch(idToFetch, location.id);
  }

  titleString = () => {
    const { salesTargetResults, timezone } = this.props;
    switch (salesTargetResults.time_type) {
      case "monthly":
        return moment().tz(timezone).format("MMMM");
      case "weekly":
        return " this week";
      default:
        return "";
    }
  };

  render() {
    const { salesTargetResults } = this.props;
    const allKeys = Object.keys(salesTargetResults.data);
    const rows = allKeys.map((key, index) => {
      const targetEntry = salesTargetResults.data[key];
      if (key === "referral_count" && targetEntry.target === 0) {
        return undefined;
      }
      return (
        <SalesTargetRow
          key={index}
          title={key}
          target={targetEntry}
          showingPercentage={this.state.showingPercentage}
        />
      );
    });

    function titleForDataKey(dataKey) {
      switch (dataKey) {
        case "new_leads_count":
          return "New Leads";
        case "initial_call_count":
          return "Contact Calls";
        case "appointment_count":
          return "Appointments";
        case "presentation_count":
          return "Presentations";
        case "total_sales":
          return "Sales";
        case "referral_count":
          return "Referrals";
        default:
          return "";
      }
    }

    const todaysGoals = Object.keys(salesTargetResults.data).map(
      (dataKey, index) => {
        const data = salesTargetResults.data[dataKey];
        if (dataKey === "referral_count" && data.target === 0) {
          return undefined;
        }
        const goalToday = data.accumulated_goal_count;
        const bgColor = goalToday <= 0 ? "bg-success" : "bg-white";
        const textColor = goalToday <= 0 ? "text-white" : "text-default-darker";
        return (
          <div className="col-xs-6" key={index}>
            <table className="block block-table text-center block-bordered">
              <tbody>
                <tr>
                  <td
                    className={bgColor + " " + textColor}
                    style={{ width: "50%" }}
                  >
                    {goalToday <= 0 && (
                      <div className="h1 font-w700">
                        <i className="si si-check" />
                      </div>
                    )}
                    {goalToday > 0 && (
                      <div className="h1 font-w700">{goalToday}</div>
                    )}
                    <div
                      className={
                        "h5 text-muted text-uppercase push-5-t " + textColor
                      }
                    >
                      {titleForDataKey(dataKey)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    );

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <div className="block-options-simple btn-group btn-group-xs">
            <button
              className="btn btn-default"
              type="button"
              onClick={() =>
                this.setState({
                  ...this.state,
                  showingPercentage: !this.state.showingPercentage,
                })
              }
            >
              {!this.state.showingPercentage ? "%" : "Values"}
            </button>
          </div>
          <h3 className="block-title">
            Sales Targets for {this.titleString()}
          </h3>
        </div>
        <div className="block-content block-content-full table-responsive">
          <table
            className="table remove-margin-b font-s13"
            style={{ tableLayout: "fixed", width: "100%" }}
          >
            <thead>
              <tr className="text-center">
                <th style={{ fontSize: "12px" }}>Goal</th>
                <th className="text-center" style={{ fontSize: "12px" }}>
                  Target
                </th>
                <th className="text-center" style={{ fontSize: "12px" }}>
                  MTD
                </th>
                <th className="text-center" style={{ fontSize: "12px" }}>
                  Expected MTD
                </th>
                <th className="text-center" style={{ fontSize: "12px" }}>
                  MTD Diff
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          {salesTargetResults.working_days && (
            <div className="push-10-t">
              <p>
                <strong>Elapsed working days:</strong>{" "}
                {salesTargetResults.working_days.elapsed}
              </p>
              <p>
                <strong>Remaining working days:</strong>{" "}
                {salesTargetResults.working_days.remaining}
              </p>
              <p>
                <strong>Month complete:</strong>{" "}
                {(
                  salesTargetResults.working_days.percentage_of_target * 100
                ).toFixed(0)}
                %
              </p>
            </div>
          )}
        </div>
        <div className="block-content bg-gray-lighter">
          <p className="nice-copy push-20" style={{ fontSize: "13px" }}>
            To make your targets this month, <strong>today</strong> you should
            complete:
          </p>
          <div className="row">{todaysGoals}</div>
        </div>
      </div>
    );
  }
}

UserTagetsView.propTypes = {
  salesTargetResults: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  idToFetch: PropTypes.number.isRequired,
  fetch: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default UserTagetsView;
