import { Theme } from 'styled-system';
import { colors } from './colors';
import { spaceArray } from './space';

export const breakpoints = ['40em', '52em', '64em'];

export const defaultTheme: Theme = {
  space: spaceArray,
  breakpoints,
  colors: {
    ...colors,
  },
};
