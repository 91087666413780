import * as actionTypes from './actionTypes'

const MULTI_REGION_SELECT_ACTION_HANDLERS = {
  [actionTypes.SET_SELECTED_REGION_IDS]: (state, action) => {
    return { ...state, selectedRegionIds: action.locationIds }
  },
  [actionTypes.REGION_SEARCH_TERM_UPDATED]: (state, action) => {
    return { ...state, regionSearchTerm: action.searchTerm, regionCurrentPage: 1 }
  },
  [actionTypes.REGION_CURRENT_PAGE_UPDATED]: (state, action) => {
    return { ...state, regionCurrentPage: action.pageNumer }
  }
}

const initialState = {
  regionSearchTerm: '',
  regionCurrentPage: 1
}

export default function reducer (state = initialState, action) {
  const handler = MULTI_REGION_SELECT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
