import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CountTo from 'react-count-to'
import { RichToolTip } from 'modules/components'

export class StepNumberView extends Component {

  render () {
    const { title, number, percentage, tooltipText, tooltipId } = this.props
    const countSpeed = 1000
    let arrowClass = (percentage < 0) ? 'fa fa-caret-down text-danger' : 'fa fa-caret-up text-success'
    arrowClass = (percentage === 0) ? 'fa fa-minus text-warning' : arrowClass
    return (
      <div className='text-center'>
        <div className='font-w600 push-15-t size-to-fit' style={{ height: '40px' }}>
          {title}{' '}
          {tooltipId &&
            <span><RichToolTip text={tooltipText} toptipId={tooltipId} /></span>
          }
        </div>
        <div className='h1 font-w700'>
          <CountTo to={number} speed={countSpeed} />{this.props.suffix}
        </div>
        {percentage !== null && percentage !== undefined &&
          <div>
            <i className={arrowClass} />{' '}
            {(percentage * 100).toFixed(0)}%
          </div>
        }
      </div>
    )
  }
}

StepNumberView.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  suffix: PropTypes.string,
  percentage: PropTypes.number,
  tooltipText: PropTypes.object,
  tooltipId: PropTypes.string
}

export default StepNumberView
