import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const fetchMergeTags = () => {
  return dispatch => {
    function onFailure (payload) {}

    function onSuccess (payload) {
      dispatch(setMergeTags(payload.personalization_fields))
    }

    return request('personalization_fields?type=lead', GET, null, onSuccess, onFailure)
  }
}

function setMergeTags (mergeTags) {
  return {
    type: actionTypes.SET_MERGE_TAGS,
    mergeTags
  }
}
