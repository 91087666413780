import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-bootstrap-modal'
import SelectLocationTableView from './TableView/SelectLocationTableView'

import { CREATE_LEAD_VIEW } from '../constants'
import { ContentBlockView, EmptyView, TextSearchView, PagesView } from 'modules/components'

export class LocationSelectView extends Component {

  UNSAFE_componentWillMount () {
    const { locations } = this.props
    if (locations.length > 0) { return }
    this.getLocationsWithPageNumber(1)
  }

  getLocationsWithPageNumber = (pageNumber) => {
    const { getLocations } = this.props
    getLocations(pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getLocationsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm) => {
    const { getLocations } = this.props
    getLocations(1, searchTerm)
  }

  showCreateLead = () => {
    this.props.setCurrentView(CREATE_LEAD_VIEW)
  }

  onLocationSelect = (location) => {
    const { setCurrentView, selectLocation } = this.props
    selectLocation(location)
    setCurrentView(CREATE_LEAD_VIEW)
  }

  render () {
    const { locations, pageDetails, loading, selectedLocation } = this.props
    const pageCount = pageDetails.pageCount

    return (
      <div>
        <ContentBlockView
          loading={loading}
          bordered>
          <div className='row' style={{ marginTop: '-20px' }}>
            <TextSearchView
              sectionName={'locationSearch'}
              onUpdate={this.searchUpdated} />
          </div>
          {locations.length > 0 &&
            <div>
              <SelectLocationTableView
                locations={locations}
                selectedLocation={selectedLocation}
                onLocationSelect={this.onLocationSelect} />
            </div>
          }
          {locations.length === 0 &&
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-magnifier'
              title='No results'
              subtitle='Check the search field' />
          }
          {pageCount > 1 &&
            <div className='text-center push'>
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked} />
            </div>
          }
        </ContentBlockView>
        <Modal.Footer>
          <button
            className='btn btn-default push-5-r push-10'
            type='button'
            onClick={this.showCreateLead}>
            Go Back
          </button>
        </Modal.Footer>
      </div>
    )
  }
}

LocationSelectView.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object,
  getLocations: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
}

export default LocationSelectView
