import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = state =>
  state.settingsThirdPartyIntegrations[NAME].errors;
export const getLoading = state =>
  state.settingsThirdPartyIntegrations[NAME].loading;
export const getOptions = state =>
  state.settingsThirdPartyIntegrations[NAME].options;
export const getConfiguredIntegrations = state =>
  state.settingsThirdPartyIntegrations[NAME].configuredIntegrations;
export const getSelectedOption = state =>
  state.settingsThirdPartyIntegrations[NAME].selectedOption;

export const validConfiguredIntegrations = createSelector(
  [getConfiguredIntegrations],
  configuredIntegrations => {
    if (configuredIntegrations && configuredIntegrations.length > 0) {
      return configuredIntegrations.filter(function(i) {
        return i.name;
      });
    }
    return [];
  }
);
