import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../../filters'
import * as selectors from '../selectors'
import * as actions from '../actions'
import ExportButtonContainer from "modules/components/ExportButton/ExportButtonContainer";

function mapStateToProps(state, props) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    title: 'Deals Report',
    loading: selectors.getLoading(state),
    queryString: queryStringSelector(state),
    compareOption: filters.selectors.getCompareOption(state),
    reportType: 'deals_report'
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      generateFullReport: actions.generateFullReport,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportButtonContainer);
