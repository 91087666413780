import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LeadGoalSelectView from "../components/LeadGoalSelectView";
import leadGoals from "modules/Stores/LeadGoals";
import * as leadGoalApiActions from "modules/Stores/LeadGoals/apiActions";

import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    leadGoals: leadGoals.selectors.getLeadGoals(state),
    loading: leadGoals.selectors.getLoading(state),
    errors: leadGoals.selectors.getErrors(state),
    pageDetails: leadGoals.selectors.getPageDetails(state),
    selectedLeadGoal: selectors.getSelectedLeadGoal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentView: actions.setCurrentView,
      selectLeadGoal: actions.selectLeadGoal,
      getLeadGoals: leadGoalApiActions.getLeadGoals
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadGoalSelectView);
