import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ResetPasswordFormValidator } from '../validator'
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers'

import { FormFields } from 'modules/components'
const { PasswordField } = FormFields

export class ResetPasswordForm extends Component {
  state = {
    isValid: false,
    fields: {
      newPassword: {
        value: null,
        isValid: false
      },
      newPasswordConfirmation: {
        value: null,
        isValid: false
      }
    }
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { newPassword, newPasswordConfirmation } = this.state.fields
    const { validateToken, onSubmit } = this.props
    onSubmit(validateToken, newPassword.value, newPasswordConfirmation.value)
  }

  handleInput = (name, value, isValid) => {
    this.setState(generateNewFormState(this.state, name, value, isValid))
  }

  render () {
    const { loading, errors } = this.props
    const { isValid } = this.state
    const buttonTitle = loading ? 'Resetting Password' : 'Reset Password'
    const loginSymbol = loading ? 'fa fa-circle-o-notch fa-spin pull-right' : 'fa fa-key pull-right'
    const buttonEnabled = isValid && !loading

    const renderButton = () =>
      <div className='form-group'>
        <div className='col-xs-12 col-sm-6 col-sm-offset-3'>
          <button type='submit'
            className='btn btn-block btn-primary'
            disabled={!buttonEnabled}
            onClick={this.handleSubmit}>
            <i className={loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>

    return (
      <div>
        {errorPanelForErrors(errors)}
        <form className='form-horizontal push-30-t'>
          <PasswordField
            name='newPassword'
            label='New Password'
            placeholder='Your new password'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={ResetPasswordFormValidator} />
          <PasswordField
            name='newPasswordConfirmation'
            label='Confirm New Password'
            placeholder='Confirm your new password'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput}
            disabled={loading}
            validator={ResetPasswordFormValidator} />
          {renderButton()}
        </form>
      </div>
    )
  }
}

ResetPasswordForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validateToken: PropTypes.string.isRequired,
  errors: PropTypes.object
}

export default ResetPasswordForm
