import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class TagRow extends Component {

  rowSelected = () => {
    const { tag, onSelect } = this.props
    onSelect(tag)
  }

  deleteSelected = () => {
    const { tag, onDelete } = this.props
    onDelete(tag)
  }

  render () {
    const { tag } = this.props
    return (
      <div className={'list-group-item'}>
        <span onClick={this.rowSelected}>{tag.name}</span>
        <i className='push-5-l text-primary fa fa-pencil selectable' onClick={this.rowSelected} />
        <i className='text-danger fa fa-times pull-right selectable' onClick={this.deleteSelected} />
      </div>
    )
  }
}

TagRow.propTypes = {
  tag: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default TagRow
