import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert'

export class WizardButtonsView extends Component {

  state = {
    showDanger: false
  }

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false })
  }

  deleteIntegration = () => {
    this.dismissPopups()
    const { deleteIntegration, data, location } = this.props
    deleteIntegration(data, location)
  }

  render () {
    return (
      <div className='row'>
        <div className='col-xs-6 text-left'>
          <button className='wizard-prev btn btn-danger' onClick={this.showDangerPopup}>
            Delete
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          <button className='wizard-next btn btn-default'
            onClick={this.props.hideModal}>
            Done
          </button>
        </div>
        {this.state.showDanger &&
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='danger'
            confirmBtnText='Delete Integration'
            title='Are you Sure?'
            onConfirm={this.deleteIntegration}
            onCancel={this.dismissPopups}>
            MINDBODY integration will no longer work for this location. Are you sure you want to delete it?
          </SweetAlert>
        }
      </div>
    )
  }
}

WizardButtonsView.propTypes = {
  deleteIntegration: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
}

export default WizardButtonsView
