import * as trialsTableActions from "./modules/trialsTable/actions";
import * as timeTakenTableActions from "./modules/timeTakenTable/actions";
import sockets from "modules/Stores/Sockets";

const trialsReportChannel = userId => `trials-report-${userId}`;

export const subscribeToTrialsReport = user => {
  return dispatch => {
    const channel = trialsReportChannel(user.id);
    dispatch(subscribeToTrialsTable(channel));
    dispatch(subscribeToTimeTakenTable(channel));
  };
};

const subscribeToTrialsTable = channel => {
  return dispatch => {
    function recieved(response) {
      dispatch(trialsTableActions.websocketSetTrials(response.data));
    }
    dispatch(
      sockets.actions.subscribeToChannelName(channel, "trialsTable", recieved)
    );
  };
};

const subscribeToTimeTakenTable = channel => {
  return dispatch => {
    function recieved(response) {
      dispatch(timeTakenTableActions.websocketSetData(response.data));
    }
    dispatch(
      sockets.actions.subscribeToChannelName(channel, "timeTakenTable", recieved)
    );
  };
};