import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TurnOnOptInContainer from '../containers/TurnOnOptInContainer'
import TurnOffOptInContainer from '../containers/TurnOffOptInContainer'

export class OptInView extends Component {

  render () {
    const { location } = this.props
    return (
      <div>
        {!location.uses_opt_in &&
          <TurnOnOptInContainer location={location} />
        }
        {location.uses_opt_in &&
          <TurnOffOptInContainer location={location} />
        }
      </div>
    )
  }
}

OptInView.propTypes = {
  location: PropTypes.object.isRequired
}

export default OptInView
