import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import { FormFields } from 'modules/components'
import { validatorForOption } from '../../validators'
import { fieldsForOption } from '../../helpers'

const { TextField } = FormFields

export class FormView extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  render () {
    const { customUrl } = this.props.data.fields
    const validator = validatorForOption('perfect_gym')
    const fields = fieldsForOption('perfect_gym')
    return (
      <div>
        <div className='row push'>
          <div className='col-md-12'>
            <h2 className='h4 push-10'>Integrate Perfect Gym</h2>
            <p>Connect {this.props.productName} with your Perfect Gym account. Start by entering your Perfect Gym domain</p>
            <ul className='text-gray-dark'>
              <li>You will need to request a special API username and password from Perfect Gym support.{' '}
              Make sure you have this before setting up the integration.</li>
              <li>You can find your domain in your perfect gym URL.For eg,{' '}
                for the domain https://happygym.perfectgym.com, you would enter "happygym.perfect.com"</li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-1'
            style={{ top: '5px' }}>
            https://
          </div>
          <div className='col-xs-11'>
            <TextField
              name='customUrl'
              label={fields.customUrl}
              placeholder=''
              formFieldStyle=''
              onChange={this.handleInput}
              validator={validator}
              value={customUrl.value} />
          </div>
        </div>
      </div>
    )
  }
}

FormView.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default FormView
