/* @flow */
import { connect } from "react-redux";
import { login, fetchWhitelabelProfileLogo } from "../modules/login";
import { LoginView } from "../components/LoginView";
import * as selectors from "../selectors";

import session from "modules/Stores/Session";

const mapStateToProps = (state) => ({
  loading: state.login.loading,
  errors: state.login.errors,
  logoutReason: session.selectors.getSessionEndReason(state),
  whitelabelProfile: state.login.whitelabelProfile,
  productName: selectors.getProductName(),
});

const mapActionCreators = {
  fetchWhitelabelProfileLogo,
  login,
};

export default connect(mapStateToProps, mapActionCreators)(LoginView);
