import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

import EditSubscriptionView from '../components/EditSubscriptionView'

function mapStateToProps (state) {
  return {
    selectedSubscription: selectors.getSelectedSubscription(state),
    errors: selectors.getErrors(state),
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSubscription: actions.updateSubscription,
    deleteSubscription: actions.deleteSubscription
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscriptionView)
