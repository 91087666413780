import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TimeTakenTableView from "../components/TimeTakenTableView";
import FilterFormSections from "modules/components/FilterFormSections";
import * as selectors from "../selectors";
import * as actions from "../actions";
import * as trialsTableSelectors from "../../trialsTable/selectors";
import filters from "../../filters";
import { humanReadableTitleTimeTaken } from '../helpers'
import { humanReadableStatus } from '../../trialsTable/helpers'

function mapStateToProps(state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(
    filters.selectors.getData
    );
    
  const selectedCell = trialsTableSelectors.getSelectedCell(state)
  const timeTakenData = selectors.getData(state)
  const formattedTitle = humanReadableTitleTimeTaken(timeTakenData, selectedCell)
  const dataSetLabel = selectedCell ? `Leads marked as ${humanReadableStatus(selectedCell.status)}` : ''
  const dataSet = timeTakenData && timeTakenData.length > 0 ? selectors.chartDataForSummaryData(timeTakenData, dataSetLabel) : null
  
  return {
    dataSet: dataSet,
    timeTakenData: timeTakenData,
    loading: selectors.getLoading(state),
    queryString: queryStringSelector(state),
    selectedCell: selectedCell,
    formattedTitle: formattedTitle,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTimeTaken: actions.getTimeTaken
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeTakenTableView);
