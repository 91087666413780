import * as actionTypes from './actionTypes'

const LEAD_TEXT_MESSAGES_ACTION_HANDLERS = {
  [actionTypes.FETCHING_TEXT_MESSAGES]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.FETCH_TEXT_MESSAGE_SUCCESS]: (state, action) => {
    return { ...state, loading: false, textMessages: action.textMessages }
  },
  [actionTypes.FETCH_TEXT_MESSAGE_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.ADD_TEXT_MESSAGE]: (state, action) => {
    const textMessages = [
      action.textMessage,
      ...state.textMessages]
    return { ...state, textMessages: textMessages }
  },
  [actionTypes.TEXT_MESSAGE_PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  loading: false,
  textMessages: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = LEAD_TEXT_MESSAGES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
