import * as actionTypes from './actionTypes'

import selectCSV from './modules/selectCSV'
import configureImport from './modules/configureImport'
import fieldMapping from './modules/fieldMapping'
import uploadCSV from './modules/uploadCSV'

export function incrementTabIndex () {
  return {
    type: actionTypes.INCREMENT_TAB_INDEX
  }
}

export function decrementTabIndex () {
  return {
    type: actionTypes.DECREMENT_TAB_INDEX
  }
}

function resetTabIndex () {
  return {
    type: actionTypes.RESET
  }
}

export function reset () {
  return dispatch => {
    dispatch(resetTabIndex())
    dispatch(selectCSV.actions.reset())
    dispatch(fieldMapping.actions.reset())
    dispatch(configureImport.actions.reset())
    dispatch(uploadCSV.actions.reset())
  }
}
