import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LocationsView } from '../components/LocationsView'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'
import locations from 'modules/Stores/Locations'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  const userRole = session.selectors.getUserRole(state)
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    canCreateNewLocations: session.helpers.userRoleHasOwnerPermissions(userRole),
    currentLocation: selectedLocation,
    selectedLocationId: selectedLocationId
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    selectLocation: actions.selectLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsView)
