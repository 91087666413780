import * as actionTypes from './actionTypes'
import { safePropertyForObject, safeBooleanPropertyForObject } from 'utils/formHelpers'

const PERFECT_GYM_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.ON_INPUT]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    return initialStateForIntegration(action.integration)
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForIntegration({})
  },
  [actionTypes.SET_TAB_INDEX]: (state, action) => {
    return { ...state, tabIndex: action.tabIndex }
  },
  // [actionTypes.SET_PERMISSION_SET]: (state, action) => {
  //   return { ...state, permissionSet: action.permissionSet }
  // },
  [actionTypes.SET_SITE_LOCATIONS]: (state, action) => {
    return { ...state, siteLocations: action.siteLocations }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      total: action.pageDetails.total_count
    }
  }
}

function initialStateForIntegration (option) {
  const id = safePropertyForObject(option, 'id')
  const locationId = safePropertyForObject(option, 'integration_location_id')
  const verified = safeBooleanPropertyForObject(option, 'verified')
  let tabIndex = (id && verified === false) ? 1 : 0
  tabIndex = (id && verified === true && !locationId) ? 2 : tabIndex
  tabIndex = (id && verified === true && locationId) ? 3 : tabIndex
  return {
    tabIndex: tabIndex,
    permissionSet: option.integration_permission_set,
    siteLocations: [],
    selectedLocationId: null,
    data: {
      fields: {
        id: {
          value: id,
          isValid: true
        },
        customUrl: {
          value: safePropertyForObject(option, 'custom_url', ''),
          isValid: true
        },
        username: {
          value: safePropertyForObject(option, 'username', ''),
          isValid: true
        },
        password: {
          value: '',
          isValid: true
        },
        verified: {
          value: verified,
          isValid: true
        },
        integrationLocationId: {
          value: locationId,
          isValid: true
        },
        expiresAt: {
          value: safePropertyForObject(option, 'expires_at', ''),
          isValid: true
        }
      }
    },
    pageCount: 1,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialStateForIntegration({}), action) {
  const handler = PERFECT_GYM_PREFERENCES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
