import * as actionTypes from './actionTypes';

const CUSTOM_MAIL_MERGE_TAGS_ACTION_HANDLERS = {
  [actionTypes.SET_CUSTOM_MAIL_MERGE_TAGS]: (state, action) => {
    return { ...state, customMailMergeTags: action.customMailMergeTags };
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.CUSTOM_MAIL_MERGE_TAG_SAVED]: (state, action) => {
    const oldMergeTags = state.customMailMergeTags;
    const index = indexForMergeTag(oldMergeTags, action.customMailMergeTag.id);
    console.log(index);
    let newMergeTags = [];
    if (index < 0) {
      newMergeTags = [...oldMergeTags, action.customMailMergeTag];
    } else {
      newMergeTags = [...oldMergeTags.slice(0, index), action.customMailMergeTag, ...oldMergeTags.slice(index + 1)];
    }

    return { ...state, customMailMergeTags: newMergeTags };
  },
  [actionTypes.DELETE_CUSTOM_MAIL_MERGE_TAG_WITH_ID]: (state, action) => {
    const oldMergeTags = state.customMailMergeTags;
    const index = indexForMergeTag(oldMergeTags, action.customMailMergeTagId);
    const newMergeTags = [...state.customMailMergeTags.slice(0, index), ...state.customMailMergeTags.slice(index + 1)];
    return { ...state, customMailMergeTags: newMergeTags };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count,
      },
    };
  },
};

const initialState = {
  customMailMergeTags: [],
  errors: null,
  loading: false,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0,
  },
};

export default function reducer(state = initialState, action) {
  const handler = CUSTOM_MAIL_MERGE_TAGS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

function indexForMergeTag(customMailMergeTags, customMailMergeTagId) {
  return customMailMergeTags.findIndex((customMailMergeTag) => parseInt(customMailMergeTag.id) === parseInt(customMailMergeTagId));
}
