import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import filters from "../modules/filters";
import modal from "modules/Stores/Modal";
import userData from "modules/Stores/UserData";

function mapStateToProps(state) {
  return {
    showingMore: filters.selectors.getShowingMore(state),
    data: filters.selectors.getData(state),
    compareOption: filters.selectors.getCompareOption(state),
    activeExtraFiltersCount: filters.selectors.activeExtraFiltersCount(state),
    permissions: userData.selectors.getMembershipPermissions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFilters: filters.actions.updateFilters,
      clearAllFilters: filters.actions.clearAllFilters,
      showFilters: filters.actions.showFilters,
      hideFilters: filters.actions.hideFilters,
      setCompareOption: filters.actions.setCompareOption,
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(filters.components.FiltersView);
