import PropTypes from "prop-types";
import React, { Component } from "react";
import "./chevron.css";

export class ConversionChevronView extends Component {
  render() {
    const { value, size } = this.props;
    const style = size ? { width: size + "px", height: size + "px" } : {};
    return (
      <div className="conversion-container">
        <div className="conversion">
          <div className="chevron" style={style}>
            {value && (
              <small className="conversion-value">
                {(value * 100).toFixed(0)}%
              </small>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ConversionChevronView.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
};

export default ConversionChevronView;
