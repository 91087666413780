import React, { Component } from "react";

export class TableView extends Component {
  render() {
    const { data } = this.props;
    const rows = data.map((dataRow, index) => {
      return (
        <tr key={index}>
          <td className="font-w600">{dataRow.title}</td>
          <td>{dataRow.count}</td>
          <td>
            {dataRow.close_rate && `${(dataRow.close_rate * 100).toFixed(2)}%`}
          </td>
        </tr>
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter">
          <thead>
            <tr>
              <th>Title</th>
              <th>Count</th>
              <th>Close rate</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}
