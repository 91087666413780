import { combineReducers } from 'redux';
import locationSelect from './modules/locationSelect';
import userSelect from './modules/userSelect';
import createTask from './modules/createTask';

const calendarReducer = combineReducers({
  [locationSelect.constants.NAME]: locationSelect.reducer,
  [userSelect.constants.NAME]: userSelect.reducer,
  [createTask.constants.NAME]: createTask.reducer,
});

export default calendarReducer;
