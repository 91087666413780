import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { FormFields } from 'modules/components'
const { SelectField } = FormFields

export class MapSalespersonView extends Component {

  addMap = (fieldName, value) => {
    const map = { old: fieldName, new: value }
    this.props.addSalespersonMap(map)
  }

  updateMap = (fieldName, value) => {
    const map = { old: fieldName, new: value }
    this.props.updateSalespersonMap(map)
  }

  render () {
    const { gymleadsSalespeople, salespeopleToMap, salespeopleMaps } = this.props

    const rows = salespeopleToMap.map((salesperson) => {
      const selected = salespeopleMaps.findIndex(map => map.old === salesperson) > -1
      const tickElement = (selected)
      ? <i className='fa fa-3x fa-check-circle text-success' style={{ marginTop: '10px', marginLeft: '13px' }} />
      : ''
      const onChange = (selected) ? this.updateMap : this.addMap
      return (
        <tr key={salesperson}>
          <td>{salesperson}</td>
          <td>
            <SelectField
              name={salesperson}
              possibleOptions={gymleadsSalespeople}
              placeHolderText='Select a salesperson'
              displayFieldName='display_name'
              formFieldStyle=''
              styles={{ marginBottom: '0px' }}
              onChange={onChange} />
          </td>
          <td>{tickElement}</td>
        </tr>
      )
    })

    return (
      <div style={{ marginTop: '5px' }}>
        <h5 style={{ marginBottom: '5px' }}>Salespeople</h5>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>CSV Value</th>
              <th style={{ width: '40%' }}>Import Value</th>
              <th style={{ width: '10%' }} />
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

MapSalespersonView.propTypes = {
  gymleadsSalespeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  salespeopleToMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  salespeopleMaps: PropTypes.arrayOf(PropTypes.object).isRequired,
  addSalespersonMap: PropTypes.func.isRequired,
  updateSalespersonMap: PropTypes.func.isRequired
}

export default MapSalespersonView
