import PropTypes from "prop-types";
import React, { Component } from "react";

import Modal from "react-bootstrap-modal";
import SweetAlert from "react-bootstrap-sweetalert";

import NewScheduleEditStepView from "./NewScheduleEditStepView";
import SelectSMSTemplateContainer from "modules/components/SendTextMessageModal/SelectSMSTemplateContainer";
import SelectQuickSendTemplateContainer from "modules/components/SendEmailModal/SelectQuickSendTemplateContainer";
import {
  DAYS,
  secondsFormattedForTimeType,
  secondsToTimeObject,
  formIsInvalid,
} from "./CallScheduleStepHelpers";

export const STEP_VIEW = "NEW_CALL_SCHEDULE/STEP_VIEW";
export const SELECT_EMAIL_TEMPLATE_VIEW =
  "NEW_CALL_SCHEDULE/SELECT_EMAIL_TEMPLATE_VIEW";
export const SELECT_SMS_TEMPLATE_VIEW =
  "NEW_CALL_SCHEDULE/SELECT_SMS_TEMPLATE_VIEW";

const defaultState = {
  selectedTimeType: DAYS,
  timeToWait: 1,
  selectedEmailTemplate: null,
  selectedTextMessageTemplate: null,
  selectedView: STEP_VIEW,
};

export class EditCallScheduleStepModal extends Component {
  state = defaultState;
  componentDidMount() {
    const { callScheduleStep } = this.props;
    const timeObject = secondsToTimeObject(
      callScheduleStep.seconds_until_next_call
    );
    this.setState({
      selectedTimeType: timeObject.selectedTimeType,
      timeToWait: timeObject.timeToWait,
      selectedEmailTemplate: callScheduleStep.email_template,
      selectedTextMessageTemplate: callScheduleStep.text_message_template,
    });
  }

  handleChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  showDeleteWarningPopup = () => {
    this.setState({ showDeleteWarning: true });
  };

  deleteCallScheduleStep = () => {
    const {
      callScheduleId,
      callScheduleStep,
      deleteCallScheduleStep,
    } = this.props;
    deleteCallScheduleStep(
      callScheduleId,
      callScheduleStep.id,
      this.hideAndReset
    );
    this.dismissPopups();
  };

  dismissPopups = () => {
    this.setState({ showDeleteWarning: false });
  };

  hideAndReset = () => {
    this.setState(defaultState);
    this.props.hideModal();
  };

  titleForView = () => {
    switch (this.state.selectedView) {
      case STEP_VIEW:
        return "Update an existing step";
      case SELECT_EMAIL_TEMPLATE_VIEW:
        return "Select quick send email template";
      case SELECT_SMS_TEMPLATE_VIEW:
        return "Select SMS template";
      default:
        return "";
    }
  };

  setSelectedView = (view) => {
    this.setState({ selectedView: view });
  };

  updateScheduleStepSelected = () => {
    const {
      updateCallScheduleStep,
      callScheduleId,
      callScheduleStep,
    } = this.props;
    const { selectedEmailTemplate, selectedTextMessageTemplate } = this.state;
    const selectedEmailTemplateId = selectedEmailTemplate
      ? selectedEmailTemplate.id
      : null;
    const selectedTextMessageTemplateId = selectedTextMessageTemplate
      ? selectedTextMessageTemplate.id
      : null;
    updateCallScheduleStep(
      callScheduleId,
      callScheduleStep.id,
      secondsFormattedForTimeType(this.state),
      selectedEmailTemplateId,
      selectedTextMessageTemplateId,
      this.hideAndReset
    );
  };

  hideAndReset = () => {
    this.setState(defaultState);
    this.props.hideModal();
    this.props.selectCallScheduleStepId(null);
  };

  currentView = () => {
    switch (this.state.selectedView) {
      case STEP_VIEW:
        return (
          <NewScheduleEditStepView
            errors={this.props.errors}
            updateValue={this.handleChange}
            numberOfSteps={this.props.numberOfSteps}
            timeToWait={this.state.timeToWait}
            selectedTimeType={this.state.selectedTimeType}
            setSelectedView={this.setSelectedView}
            selectedEmailTemplate={this.state.selectedEmailTemplate}
            selectedTextMessageTemplate={this.state.selectedTextMessageTemplate}
            footerComponent={
              <UpdateFooter
                showDeleteWarningPopup={this.showDeleteWarningPopup}
                updateScheduleStepSelected={this.updateScheduleStepSelected}
                hideAndReset={this.hideAndReset}
                actionDisabled={() => formIsInvalid(this.state.timeToWait)}
              />
            }
          />
        );
      case SELECT_EMAIL_TEMPLATE_VIEW:
        return (
          <SelectQuickSendTemplateContainer
            {...this.props}
            backSelected={() => this.setState({ selectedView: STEP_VIEW })}
            setSelectedView={this.setSelectedView}
            onTemplateSelect={(template) =>
              this.setState({
                selectedEmailTemplate: template,
                selectedView: STEP_VIEW,
              })
            }
          />
        );
      case SELECT_SMS_TEMPLATE_VIEW:
        return (
          <SelectSMSTemplateContainer
            {...this.props}
            goBackView={STEP_VIEW}
            setSelectedView={this.setSelectedView}
            onTemplateSelect={(template) =>
              this.setState({
                selectedTextMessageTemplate: template,
                selectedView: STEP_VIEW,
              })
            }
          />
        );
      default:
        return "";
    }
  };

  render() {
    const { currentModal, modalName, loading } = this.props;
    const blockStyle = loading ? "block block-opt-refresh" : "block";

    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideAndReset}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button
                    data-dismiss="modal"
                    type="button"
                    onClick={this.hideAndReset}
                  >
                    <i className="si si-close" style={{ color: "white" }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: "white" }}>
                {this.titleForView()}
              </h3>
            </div>
            {this.currentView()}
          </div>
        </Modal>
        {this.state.showDeleteWarning && (
          <div>
            <SweetAlert
              danger
              showCancel
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="danger"
              confirmBtnText="Delete call schedule step"
              title="Are you Sure?"
              onConfirm={this.deleteCallScheduleStep}
              onCancel={this.dismissPopups}
            >
              This step will be deleted. All leads on this step of the schedule
              will be moved to the next one.
            </SweetAlert>
          </div>
        )}
      </div>
    );
  }
}

EditCallScheduleStepModal.propTypes = {
  updateCallScheduleStep: PropTypes.func.isRequired,
  selectCallScheduleStepId: PropTypes.func.isRequired,
  deleteCallScheduleStep: PropTypes.func.isRequired,
  callScheduleId: PropTypes.number.isRequired,
  callScheduleStep: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  currentModal: PropTypes.string,
};

export default EditCallScheduleStepModal;

const UpdateFooter = ({
  showDeleteWarningPopup,
  updateScheduleStepSelected,
  hideAndReset,
  actionDisabled,
}) => {
  return (
    <Modal.Footer>
      <button
        className="btn btn-danger pull-left"
        onClick={showDeleteWarningPopup}
      >
        <i className="fa fa-times" /> Delete
      </button>
      <button
        className="btn btn-default  push-5-r pull-left"
        onClick={hideAndReset}
      >
        Cancel
      </button>
      <button
        className="btn btn-primary push-5-r"
        type="button"
        disabled={actionDisabled()}
        onClick={updateScheduleStepSelected}
      >
        <i className="fa fa-save" /> Update step
      </button>
    </Modal.Footer>
  );
};
