import PropTypes from "prop-types";
import React, { Component } from "react";
import CallScheduleView from "./CallSchedule/CallScheduleView";
import CallItemListContainer from "../containers/CallItemListContainer";

import ScheduleCallModalContainer from "../containers/ScheduleCallModalContainer";
import RecordCallModalContainer from "../containers/RecordCallModalContainer";
import AfterCallModalContainer from "../containers/AfterCallModalContainer";
import CallScheduleSelect from "modules/components/CallScheduleSelect";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  NEW_CALL_MODAL,
  RECORD_CALL_MODAL,
  FINISHED_CALL_MODAL
} from "../constants";
import { CALL_SCHEDULE_SELECT_MODAL } from "modules/components/CallScheduleSelect/constants";

export class ContactCallsView extends Component {
  state = {
    showWarning: false,
    callToAction: null
  };

  callClicked = call => {
    const { lead, toggleCompleteForCall } = this.props;
    if (!call.completed) {
      this.setState({ ...this.state, callToAction: call });
      this.props.showModal(FINISHED_CALL_MODAL);
    } else {
      toggleCompleteForCall(call, lead, null);
    }
  };

  scheduleCall = () => {
    this.props.showModal(NEW_CALL_MODAL);
  };

  recordCall = () => {
    this.props.showModal(RECORD_CALL_MODAL);
  };

  addToCallScheduleModal = () => {
    this.props.showModal(CALL_SCHEDULE_SELECT_MODAL);
  };

  addLeadToCallSchedule = callScheduleId => {
    const { lead, setCallSchedule, listUpdateProtocol } = this.props;
    let body = { call_schedule_id: callScheduleId };
    setCallSchedule(lead, body, listUpdateProtocol.updateLeadDetails);
  };

  deleteCall = () => {
    const { lead, deleteCallForLead } = this.props;
    const { callToAction } = this.state;
    deleteCallForLead(callToAction, lead);
    this.dismissPopups();
  };

  showWarningPopup = callToAction => {
    this.setState({
      ...this.state,
      showWarning: true,
      callToAction: callToAction
    });
  };

  dismissPopups = () => {
    this.setState({ ...this.state, showWarning: false, callToAction: null });
  };

  render() {
    const { loading, lead, pathToLeadDetail } = this.props;
    const callScheduleStep = lead.call_schedule_step;

    return (
      <div className="push-20">
        {loading ? (
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 text-center">
              <h3 className="push-10">Loading Calls</h3>
              <i className="fa fa-2x fa-sun-o fa-spin" />
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-xs-8">
                <button
                  className="btn btn-success push-5-r push-10"
                  type="button"
                  onClick={this.scheduleCall}
                >
                  <i className="fa fa-plus" /> Schedule Call
                </button>
                <button
                  className="btn btn-success push-5-r push-10"
                  type="button"
                  onClick={this.recordCall}
                >
                  <i className="fa fa-plus" /> Record Call
                </button>
                {!callScheduleStep && ( // hide button if lead is on call schedule
                  <button
                    className="btn btn-success push-5-r push-10"
                    type="button"
                    onClick={this.addToCallScheduleModal}
                  >
                    <i className="fa fa-plus" /> Add to Schedule
                  </button>
                )}
              </div>
              <div className="col-xs-4">
                {callScheduleStep && (
                  <div style={{ float: "right" }}>
                    <CallScheduleView
                      lead={lead}
                      showModal={this.props.showModal}
                      pathToLeadDetail={pathToLeadDetail}
                      callScheduleStep={callScheduleStep}
                    />
                  </div>
                )}
              </div>

              <CallItemListContainer
                pathToLeadDetail={pathToLeadDetail}
                callClicked={this.callClicked}
                callDeleted={this.showWarningPopup}
                lead={lead}
              />

              <CallScheduleSelect.CallScheduleSelectModalContainer
                modalName={CALL_SCHEDULE_SELECT_MODAL}
                onUpdate={this.addLeadToCallSchedule}
                leadId={lead.id}
              />
              <ScheduleCallModalContainer
                modalName={NEW_CALL_MODAL}
                lead={this.props.lead}
                listUpdateProtocol={this.props.listUpdateProtocol}
              />
              <RecordCallModalContainer
                modalName={RECORD_CALL_MODAL}
                lead={this.props.lead}
                listUpdateProtocol={this.props.listUpdateProtocol}
                pathToLeadDetail={this.props.pathToLeadDetail}
              />
              <AfterCallModalContainer
                modalName={FINISHED_CALL_MODAL}
                lead={this.props.lead}
                call={this.state.callToAction}
                listUpdateProtocol={this.props.listUpdateProtocol}
                pathToLeadDetail={this.props.pathToLeadDetail}
              />
              {this.state.showWarning && (
                <SweetAlert
                  warning
                  showCancel
                  cancelBtnBsStyle="default"
                  confirmBtnBsStyle="warning"
                  confirmBtnText="Delete Call"
                  title="Are you Sure?"
                  onConfirm={this.deleteCall}
                  onCancel={this.dismissPopups}
                >
                  Are you sure you want to delete this call?
                </SweetAlert>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ContactCallsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  toggleCompleteForCall: PropTypes.func.isRequired,
  pathToLeadDetail: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  setCallSchedule: PropTypes.func.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  deleteCallForLead: PropTypes.func.isRequired
};
