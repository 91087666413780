import React, { Component } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import styled from "styled-components";

export default class DeleteAutomaticReportButton extends Component {
  state = {
    showDeleteConfirmModal: false
  };

  triggerDeleteAutomaticReportModal = () => {
    this.setState({ ...this.state, showDeleteConfirmModal: true });
  };

  handleDeleteAutomaticReport = () => {
    const { selectedReport, deleteAutomaticReport } = this.props;
    deleteAutomaticReport(selectedReport.id);
  };

  dismissModal = () => {
    this.setState({ ...this.state, showDeleteConfirmModal: false });
  };

  handleConfirm = () => {
    this.handleDeleteAutomaticReport();
    this.dismissModal();
  };

  render() {
    const { showDeleteConfirmModal } = this.state;
    return (
      <div>
        <DeleteButton
          className="btn btn-danger"
          type="button"
          onClick={this.triggerDeleteAutomaticReportModal}
        >
          Delete Report
        </DeleteButton>
        {showDeleteConfirmModal && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="default"
            confirmBtnBsStyle="warning"
            confirmBtnText="Delete Automatic Report"
            title="Are you Sure?"
            onConfirm={this.handleConfirm}
            onCancel={this.dismissModal}
          >
            This report will be deleted and future emails will not occur.
          </SweetAlert>
        )}
      </div>
    );
  }
}

DeleteAutomaticReportButton.propTypes = {
  deleteAutomaticReport: PropTypes.func.isRequired,
  selectedReport: PropTypes.object.isRequired
};

const DeleteButton = styled.button`
  width: 125px;
  margin: 15px 0px 0px 0px;
`;
