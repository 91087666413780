import { request, buildIndexUrl, GET, DELETE, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

import { formDataFromData } from 'utils/formHelpers';

export const getTrials = (pageNumber, searchTerm = null) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setTrials(payload.trials));
      dispatch(loadPageDetails(payload.meta));
    }

    const url = buildIndexUrl('trials', pageNumber, null, searchTerm);
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const deleteTrial = (trialId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(deleteTrialWithId(trialId));
      notify.success('Trial deleted');
    }

    const deleteString = 'trials/' + trialId;
    return request(deleteString, DELETE, null, onSuccess, onFailure);
  };
};

export const createTrial = (name, dayLength, afterSuccess) => {
  return (dispatch) => {
    const body = {
      trial: {
        name: name,
        day_length: dayLength,
      },
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(saveTrial(payload.trial));
      if (afterSuccess) {
        afterSuccess();
      }
      notify.success('Trial Created');
    }

    return request('trials/', POST, body, onSuccess, onFailure);
  };
};

export const fieldNames = ['name', 'dayLength'];
export const updateTrial = (trial, formData) => {
  return (dispatch) => {
    const body = {
      trial: formDataFromData(formData, fieldNames),
    };

    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(saveTrial(payload.trial));
      notify.info('Trial Updated');
    }
    const url = 'trials/' + trial.id;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setTrials(trials) {
  return {
    type: actionTypes.SET_TRIALS,
    trials,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function saveTrial(trial) {
  return {
    type: actionTypes.TRIAL_SAVED,
    trial,
  };
}

export function deleteTrialWithId(trialId) {
  return {
    type: actionTypes.DELETE_TRIAL_WITH_ID,
    trialId,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
