import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { CompareSelectSection } from "modules/components/FilterFormSections/components";
import { stringForCompareOption } from '../helpers'

function mapStateToProps (state, props) {
  return {
    stringForCompareOption: stringForCompareOption,
    compareOptions: props.compareOptions,
    compareOption: props.compareOption,
    setCompareOption: props.setCompareOption
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareSelectSection)