import moment from 'moment';
import { request, GET, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { handleAsyncLeadUpdateEvent, handleAsyncFacebookMessengerEvent } from 'modules/components/LeadDetail/actions';

import sockets from 'modules/Stores/Sockets';

export const getNotificationsForPage = (page) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
      dispatch(setLoading(errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setNotifications(payload.notifications));
      dispatch(loadPageDetails(payload.meta));
    }

    return request('notifications?page[number]=' + page, GET, null, onSuccess, onFailure);
  };
};

function setNotifications(notifications) {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    notifications,
  };
}

export const addNotificationsForPage = (page) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(errors) {
      dispatch(setLoading(false));
      dispatch(setLoading(errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(addNotifications(payload.notifications));
      dispatch(loadPageDetails(payload.meta));
    }

    return request('notifications?page[number]=' + page, GET, null, onSuccess, onFailure);
  };
};

function addNotifications(notifications) {
  return {
    type: actionTypes.ADD_NOTIFICATIONS,
    notifications,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

export const subscribeToChannelsForUser = (user) => {
  return (dispatch) => {
    dispatch(subscribeToNotificationsForUser(user));
    dispatch(subscribeToLeadUpdateNotificationsForUser(user));
    dispatch(subscribeToFacebookMessengerUpdatesNotificationsForUser(user));
  };
};

export const subscribeToNotificationsForUser = (user) => {
  return (dispatch) => {
    const channelName = 'private-notifications-' + user.id;
    const actionName = 'notification';
    function recieved(response) {
      dispatch(increaseNotificationCount());
      dispatch(setCurrentNotification(response));
      dispatch(waitAndClearCurrentNotification());
    }
    dispatch(sockets.actions.subscribeToChannelName(channelName, actionName, recieved));
  };
};

export const subscribeToLeadUpdateNotificationsForUser = (user) => {
  return (dispatch) => {
    const channelName = 'private-lead-update-' + user.id;
    const actionName = 'notification';
    function recieved(response) {
      dispatch(handleAsyncLeadUpdateEvent(response));
    }
    dispatch(sockets.actions.subscribeToChannelName(channelName, actionName, recieved));
  };
};

export const subscribeToFacebookMessengerUpdatesNotificationsForUser = (user) => {
  return (dispatch) => {
    const channelName = 'private-facebook-messages-' + user.id;
    const actionName = 'message_recieved';
    function recieved(response) {
      dispatch(handleAsyncFacebookMessengerEvent(response));
    }
    dispatch(sockets.actions.subscribeToChannelName(channelName, actionName, recieved));
  };
};

function setCurrentNotification(currentNotification) {
  return {
    type: actionTypes.SET_CURRENT_NOTIFICATION,
    currentNotification,
  };
}

function waitAndClearCurrentNotification() {
  return (dispatch) => {
    setTimeout(function () {
      dispatch(setCurrentNotification(null));
    }, 3500);
  };
}

function increaseNotificationCount() {
  return {
    type: actionTypes.INCREASE_NOTIFICATION_COUNT,
  };
}

function decreaseNotificationCount() {
  return {
    type: actionTypes.DECREASE_NOTIFICATION_COUNT,
  };
}

export const getNotificationCount = () => {
  return (dispatch) => {
    function onFailure(errors) {}
    function onSuccess(payload) {
      dispatch(setNotificationCount(payload.unread_notification_count));
    }

    return request('unread_notification_count', GET, null, onSuccess, onFailure);
  };
};

export const setAllNotificationsRead = () => {
  return (dispatch) => {
    dispatch(setNotificationCount(0));
    function onFailure(errors) {}
    function onSuccess(payload) {}

    return request('read_all_notifications', PUT, null, onSuccess, onFailure);
  };
};

export const setNotificationAsRead = (notification) => {
  return (dispatch) => {
    dispatch(decreaseNotificationCount());
    const body = {
      read_at: moment().format(),
    };
    function onFailure(errors) {}
    function onSuccess(payload) {}

    return request('notifications/' + notification.id, PUT, body, onSuccess, onFailure);
  };
};

function setNotificationCount(notificationCount) {
  return {
    type: actionTypes.SET_NOTIFICATION_COUNT,
    notificationCount,
  };
}
