import * as openingHoursApi from 'modules/api/openingHours';
import * as actionTypes from './actionTypes';
import * as locationActions from 'modules/Stores/Locations/actions';
import notify from 'modules/Notifications';

export function updateOpeningHours(location, openingHoursId, state) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await openingHoursApi.updateOpeningHours(location.id, openingHoursId, state);
      if (response) {
        const updatedLocation = { ...location, opening_hours: response.opening_hours };
        dispatch(locationActions.editLocation(updatedLocation));
        notify.success('Opening Hours updated');
        dispatch(setLoading(false));
        return true;
      }
    } catch (e) {
      dispatch(setErrors(e.message));
      dispatch(setLoading(false));
      return false;
    }
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
