import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_POTENTIAL_USERS = NAME + '/SET_POTENTIAL_USERS'

export const UPDATE_SALESPERSON_MAP = NAME + '/UPDATE_SALESPERSON_MAP'
export const UPDATE_STATUS_MAP = NAME + '/UPDATE_STATUS_MAP'
export const UPDATE_LAWFUL_PROCESSING_BASES_MAP = NAME + '/UPDATE_LAWFUL_PROCESSING_BASES_MAP'

export const ADD_SALESPERSON_MAP = NAME + '/ADD_SALESPERSON_MAP'
export const ADD_STATUS_MAP = NAME + '/ADD_STATUS_MAP'
export const ADD_LAWFUL_PROCESSING_BASES_MAP = NAME + '/ADD_LAWFUL_PROCESSING_BASES_MAP'

export const SET_STATUSES = NAME + '/SET_STATUSES'
export const SET_SALESPEOPLE = NAME + '/SET_SALESPEOPLE'
export const SET_LAWFUL_PROCESSING_BASES = NAME + '/SET_LAWFUL_PROCESSING_BASES'

export const RESET = NAME + '/RESET'
