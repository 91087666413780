import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AutoAssignRuleForm from '../components/AutoAssignRuleForm'

import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAssignRuleForm)
