import PropTypes from "prop-types";
import React, { Component } from "react";

export class LinkedFacebookPageRow extends Component {
  handleDelete = () => {
    const { linkedFacebookPage, deleteFacebookPageIntegration } = this.props;
    deleteFacebookPageIntegration(linkedFacebookPage.id);
  };

  render() {
    const {
      linkedFacebookPage,
      showSelectLeadSourceView,
      showSelectLeadGoalView,
    } = this.props;
    const leadSourceButtonString = linkedFacebookPage
      ? linkedFacebookPage.lead_source.source_name
      : "Select a lead source";
    const leadGoalButtonString =
      linkedFacebookPage && linkedFacebookPage.lead_goal
        ? linkedFacebookPage.lead_goal.goal_name
        : "Select a lead goal";
    const imgUrl =
      "https://graph.facebook.com/v3.2/" +
      linkedFacebookPage.facebook_page.facebook_id +
      "/picture";
    return (
      <tr>
        <td>
          <img alt="" src={imgUrl} style={{ width: "50px" }} />
        </td>
        <td>{linkedFacebookPage.facebook_page.page_name}</td>
        <td>
          <button
            className="btn btn-default push-5-r push-10"
            style={{ display: "inline-block" }}
            type="button"
            onClick={() => showSelectLeadSourceView(linkedFacebookPage)}
          >
            {leadSourceButtonString}
          </button>
        </td>
        <td>
          <button
            className="btn btn-default push-5-r push-10"
            style={{ display: "inline-block" }}
            type="button"
            onClick={() => showSelectLeadGoalView(linkedFacebookPage)}
          >
            {leadGoalButtonString}
          </button>
        </td>
        <td>
          {this.props.canDelete && (
            <button
              className="btn btn-xs btn-default"
              type="button"
              onClick={this.handleDelete}
            >
              <i className="fa fa-times" />
            </button>
          )}
        </td>
      </tr>
    );
  }
}

LinkedFacebookPageRow.propTypes = {
  linkedFacebookPage: PropTypes.object.isRequired,
  canDelete: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  updateFacebookPageIntegration: PropTypes.func.isRequired,
  deleteFacebookPageIntegration: PropTypes.func.isRequired,
  showSelectLeadSourceView: PropTypes.func.isRequired,
};

export default LinkedFacebookPageRow;
