/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";

import { secondsToTimeObject } from "./CallScheduleStepHelpers";
import { pluralizationHelper } from "modules/Helpers/PluralizationHelpers";

export class ScheduleStepRow extends Component {
  afterString = () => {
    const { callScheduleStep } = this.props;
    return callScheduleStep.step_number === 0 ? "enquiring" : "after last call";
  };

  onSelect = () => {
    const { onSelect, callScheduleStep } = this.props;
    onSelect(callScheduleStep.id);
  };

  actionString = () => {
    const { callScheduleStep } = this.props;
    const smsId = callScheduleStep.text_message_template_id;
    const emailId = callScheduleStep.email_template_id;
    if (!smsId && !emailId) {
      return "Call";
    }

    if (smsId && !emailId) {
      return "Call & SMS";
    }

    if (!smsId && emailId) {
      return "Call & email";
    }

    if (smsId && emailId) {
      return "Call, SMS and email";
    }
  };

  render() {
    const { callScheduleStep } = this.props;
    const timeObject = secondsToTimeObject(
      callScheduleStep.seconds_until_next_call
    );
    return (
      <li>
        <i className="list-timeline-no-border-icon si si-call-end bg-default-light" />
        <div className="list-timeline-no-border-content">
          <p style={{ marginLeft: "40px" }}>
            {pluralizationHelper(
              timeObject.selectedTimeType,
              timeObject.timeToWait
            )}{" "}
            after {this.afterString()}
          </p>
          <a
            className="block block-rounded block-bordered block-link-hover1 selectable"
            onClick={this.onSelect}
            style={{ marginLeft: "40px" }}
          >
            <div
              className="block-content block-content-full clearfix"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <span className="push-5">
                <span
                  className="badge badge-primary"
                  style={{ marginTop: "-5px" }}
                >
                  Step {callScheduleStep.step_number + 1}
                </span>
              </span>
              <p>{this.actionString()}</p>
            </div>
          </a>
        </div>
      </li>
    );
  }
}

ScheduleStepRow.propTypes = {
  callScheduleStep: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ScheduleStepRow;
