import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HeaderView from "../components/HeaderView";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderView);
