import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import { LeadStatusTag } from 'modules/components';

import moment from 'moment';
import 'moment-timezone';

export default class LeadTableRow extends Component {
  render() {
    const { lead, timezone } = this.props;
    const headAbout = lead.heard_about_method ? lead.heard_about_method.heard_about_title : '';

    const format = 'DD/MM/YYYY, h:mm a';
    const funnel_step = lead.sales_funnel_step ? lead.sales_funnel_step.name : null;

    return (
      <tr style={{ height: '40px' }}>
        <td className="text-center">
          <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={22} round={true} />
        </td>
        <td>{lead.given_name}</td>
        <td>{lead.surname}</td>
        <td>{lead.gender}</td>
        <td>
          <LeadStatusTag leadStatus={lead.status} />
        </td>
        <td>{funnel_step}</td>
        <td>{lead.location_name}</td>
        <td>{lead.salesperson_name}</td>
        <td>{lead.source}</td>
        <td>{headAbout}</td>
        <td>{lead.last_contacted && moment(lead.last_contacted).tz(timezone).format(format)}</td>
        <td>{lead.appointment_count}</td>
        <td>{lead.presentation_count}</td>
        <td>{lead.contact_call_count}</td>
        <td>{lead.successful_contact_call_count}</td>
        <td>{lead.mobile_number}</td>
        <td>{lead.email}</td>
        <td>{lead.date_of_birth}</td>
        <td>{lead.address_line_one}</td>
        <td>{lead.address_line_two}</td>
        <td>{lead.city}</td>
        <td>{lead.state}</td>
        <td>{lead.country}</td>
        <td>{lead.zip_postcode}</td>
        <td>{lead.workplace_name}</td>
        <td>{stringForBoolean(lead.transactional_sms_opt_out)}</td>
        <td>{stringForBoolean(lead.transactional_email_opt_out)}</td>
        <td>{stringForBoolean(lead.marketing_sms_opt_out)}</td>
        <td>{stringForBoolean(lead.marketing_email_opt_out)}</td>
        <td>{moment(lead.created_at).tz(timezone).format(format)}</td>
      </tr>
    );
  }
}

LeadTableRow.propTypes = {
  lead: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
};

function stringForBoolean(boolValue) {
  return boolValue ? 'Yes' : 'No';
}
