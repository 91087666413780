import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClassAppointmentModal from "../components/NewAppointmentModal/ClassAppointmentModal/ClassAppointmentModal";

import modal from "modules/Stores/Modal";
import classes from "modules/Stores/Classes";
import session from "modules/Stores/Session";
import * as appointmentActions from "../actions";
const { hideModal, showModal } = modal.actions;

function mapStateToProps(state, ownProps) {
  return {
    loading: classes.selectors.getLoading(state),
    timezone: session.selectors.getUserTimezone(state),
    currentModal: modal.selectors.getCurrentModal(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      showModal,
      scheduleAppointment: appointmentActions.scheduleAppointment,
      handleAfterActionForOutcome:
        appointmentActions.handleAfterActionForOutcome,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassAppointmentModal);
