import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'

import ButtonsView from '../components/SelectLocation/ButtonsView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    nextIsReady: selectors.currentIndexIsReady(state),
    data: selectors.getData(state),
    tabIndex: selectors.getTabIndex(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    deleteIntegration: actions.deleteIntegration,
    addOrUpdateOptionWithData: actions.addOrUpdateOptionWithData,
    reset: actions.reset,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsView)
