import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditEmailMapForm from "../components/EditEmailMapForm";
import modal from "modules/Stores/Modal";
import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    data: selectors.getData(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onInput: actions.onInput,
      showModal: modal.actions.showModal,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailMapForm);
