import React from "react";
import MultiCountrySelect from "modules/components/MultiCountrySelect";

const CountriesSection = ({
  SELECT_COUNTRIES_MODAL,
  clubsFiltersClass,
  countryIdsName,
  countryIdsData,
  showModal,
  clubTitle,
  onUpdate
}) => {
  function showSelectCountriesModal() {
    showModal(SELECT_COUNTRIES_MODAL);
  }

  return (
    <div>
      <div
        className={`btn ${clubsFiltersClass}`}
        type="button"
        onClick={showSelectCountriesModal}
      >
        {clubTitle}
      </div>
      <MultiCountrySelect.MultiCountrySelectModalContainer
        modalName={SELECT_COUNTRIES_MODAL}
        sectionName={countryIdsName}
        onUpdate={onUpdate}
        data={countryIdsData}
      />
    </div>
  );
};

export default CountriesSection;
