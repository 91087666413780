import * as actionTypes from "./actionTypes";
import {
  safePropertyForObject,
  safeBooleanPropertyForObject,
} from "utils/formHelpers";

const MINDBODY_PREFERENCES_ACTION_HANDLERS = {
  [actionTypes.ON_INPUT]: (state, action) => {
    return { ...state, data: action.data };
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    return initialStateForIntegration(action.integration);
  },
  [actionTypes.RESET]: (state) => {
    return initialStateForIntegration({});
  },
  [actionTypes.SET_TAB_INDEX]: (state, action) => {
    return { ...state, tabIndex: action.tabIndex };
  },
  [actionTypes.SET_PERMISSION_SET]: (state, action) => {
    return { ...state, permissionSet: action.permissionSet };
  },
  [actionTypes.SET_SITE_LOCATIONS]: (state, action) => {
    return { ...state, siteLocations: action.siteLocations };
  },
  [actionTypes.SET_TRIALS]: (state, action) => {
    return { ...state, trials: action.trials };
  },
  [actionTypes.SET_MEMBERSHIPS]: (state, action) => {
    return { ...state, memberships: action.memberships };
  },
  [actionTypes.SET_TRIALS_MEMBERSHIPS_MAPPINGS]: (state, action) => {
    return {
      ...state,
      trialsMembershipsMappings: action.trialsMembershipsMappings,
    };
  },
  [actionTypes.ADD_TRIAL_MEMBERSHIP_MAPPING]: (state, action) => {
    return {
      ...state,
      trialsMembershipsMappings: [
        ...state.trialsMembershipsMappings,
        action.mapping,
      ],
    };
  },
  [actionTypes.REMOVE_TRIAL_MEMBERSHIP_MAPPING]: (state, action) => {
    return {
      ...state,
      trialsMembershipsMappings: state.trialsMembershipsMappings.filter(
        (mapping) => `${mapping.id}` !== `${action.mappingId}`
      ),
    };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      total: action.pageDetails.total_count,
    };
  },
};

function initialStateForIntegration(option) {
  const id = safePropertyForObject(option, "id");
  const locationId = safePropertyForObject(option, "username");
  const verified = safeBooleanPropertyForObject(option, "verified");
  let tabIndex = id && verified === false ? 1 : 0;
  tabIndex = id && verified === true && !locationId ? 2 : tabIndex;
  tabIndex = id && verified === true && locationId ? 3 : tabIndex;
  return {
    tabIndex: tabIndex,
    permissionSet: option.integration_permission_set,
    siteLocations: [],
    selectedLocationId: null,
    data: {
      fields: {
        id: {
          value: id,
          isValid: true,
        },
        authUrl: {
          value: safePropertyForObject(option, "auth_url"),
          isValid: true,
        },
        verified: {
          value: verified,
          isValid: true,
        },
        companyName: {
          value: safePropertyForObject(option, "company_name"),
          isValid: true,
        },
        username: {
          value: locationId,
          isValid: true,
        },
      },
    },
    pageCount: 1,
    currentPage: 1,
    total: 0,
    trials: [],
    memberships: [],
    trialsMembershipsMappings: [],
  };
}

export default function reducer(
  state = initialStateForIntegration({}),
  action
) {
  const handler = MINDBODY_PREFERENCES_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
