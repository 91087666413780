export const NAME = 'calendarEvents';

export const LEAD_EVENT_DETAIL_MODAL = NAME + '/LEAD_EVENT_DETAIL_MODAL';
export const APPOINTMENT_EVENT_DETAIL_MODAL = NAME + '/APPOINTMENT_EVENT_DETAIL_MODAL';
export const CALL_EVENT_DETAIL_MODAL = NAME + '/CALL_EVENT_DETAIL_MODAL';
export const PERSONAL_EVENT_DETAIL_MODAL = NAME + '/PERSONAL_EVENT_DETAIL_MODAL';

export const EVENT_COLORS = {
  SUCCESSFUL: '#46c37b',
  UNSUCCESSFUL: '#d26a5c',
  MISSED: '#f3b760',
  NOT_COMPLETED: '#70b9eb',
};
