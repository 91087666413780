export enum ERuleSetTimeType {
  weekly = "weekly",
  monthly = "monthly"
}

export interface ITargetRuleSetData {
  target: number;
  target_to_date: number;
  expected_to_date: number;
  balance: number;
  target_percentage: number;
  completed_today: number;
  daily_goal_count: number;
  accumulated_goal_count: number;
  difference: number;
  percentage_on_track: number;
  percentage_of_target: number;
}

export interface ITargetRuleSetResult {
  time_type: ERuleSetTimeType;
  working_days: {
    total_working_days: number;
    elapsed: number;
    remaining: number;
    percentage_of_target: number;
  };
  data: {
    new_leads_count: ITargetRuleSetData;
    initial_call_count: ITargetRuleSetData;
    appointment_count: ITargetRuleSetData;
    presentation_count: ITargetRuleSetData;
    total_sales: ITargetRuleSetData;
    referral_count: ITargetRuleSetData;
  };
}
