import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getSelectedUserIds = state => state.salesTeams[NAME].selectedUserIds
export const getUsers = state => state.salesTeams[NAME].users
export const getPageCount = state => state.salesTeams[NAME].pageCount
export const getCurrentPage = state => state.salesTeams[NAME].currentPage
export const getTotal = state => state.salesTeams[NAME].total
export const getSelectedUserRoleId = state => state.salesTeams[NAME].selectedUserRoleId

export const selectedUsers = createSelector(
  [ getUsers, getSelectedUserIds ],
  (users, selectedUserIds) => {
    if (selectedUserIds && selectedUserIds.length > 0) {
      return selectedUserIds.map((id) => {
        const index = users.findIndex(user => parseInt(user.id) === parseInt(id))
        return users[index]
      })
    }
    return []
  }
)
