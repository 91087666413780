import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ManagerLocationDashboardView } from '../../components/ManagerLocationDashboardView'

import userData from 'modules/Stores/UserData'
import SalesTeam from '../../modules/SalesTeam'
import locations from 'modules/Stores/Locations'
import locationSelect from '../../modules/LocationSelect'

function mapStateToProps (state, ownProps) {
  return {
    user: state.session.user,
    loading: state.userData.loading,
    location: locationSelect.selectors.getSelectedLocation(state),
    company: state.userData.company,
    isMultiLocationAccount: locations.selectors.getIsMultiLocationAccount(state),
    locationMetrics: userData.selectors.getLocationMetrics(state),
    selectedUserId: SalesTeam.selectors.getSelectedUserId(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchMetricsForLocation: userData.actions.fetchMetricsForLocation,
    clearCurrentLocation: locationSelect.actions.clearLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerLocationDashboardView)
