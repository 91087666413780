import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import session from 'modules/Stores/Session'
import leadsTable from '../modules/leadsTable'
import filters from '../modules/filters'
import FilterFormSections from 'modules/components/FilterFormSections'

function mapStateToProps (state) {
  const useStatusDate = filters.selectors.getUseStatusDate(state)
  let queryString = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)(state)
  if (useStatusDate === false) {
    queryString = filters.selectors.convertUpdatedQueryStringToCreated(queryString)
  }
  return {
    queryString: queryString,
    leads: leadsTable.selectors.getLeads(state),
    loading: leadsTable.selectors.getLoading(state),
    pageCount: leadsTable.selectors.getPageCount(state),
    currentPage: leadsTable.selectors.getCurrentPage(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getLeadsWithQueryAndPageNumber: leadsTable.actions.getLeadsWithQueryAndPageNumber
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(leadsTable.components.LeadsListView)
