import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";

export class EditCompanyLogo extends Component {
  onDrop = (files) => {
    if (files.length > 0) {
      this.props.fileWasSelected(files[0]);
      this.props.startFileUpload(files[0], this.props.company);
    }
  };

  render() {
    const { company, file } = this.props;

    const logoString =
      company.company_logo && company.company_logo.medium_url
        ? company.company_logo.medium_url
        : null;

    const imageStyle = {
      width: "150px",
      height: "150px",
      border: "2px dashed black",
    };

    const dropZoneStyle = {};

    return (
      <Dropzone
        onDrop={this.onDrop}
        style={dropZoneStyle}
        className="selectable"
      >
        {file && <img alt="" src={file.preview} style={imageStyle} />}
        {!file && <img alt="" src={logoString} style={imageStyle} />}
      </Dropzone>
    );
  }
}

EditCompanyLogo.propTypes = {
  startFileUpload: PropTypes.func.isRequired,
  fileWasSelected: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  file: PropTypes.object,
  progress: PropTypes.number,
};

export default EditCompanyLogo;
