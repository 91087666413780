import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectLeadLostReasonTableView from "./TableView/SelectLeadLostReasonTableView";
import PagesContainer from "../containers/PagesContainer";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView
} from "modules/components";

export class MultiLeadLostReasonSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { leadLostReasons } = this.props;
    if (leadLostReasons.length > 0) {
      return;
    }
    this.getLeadLostReasonsWithPageNumber(1);
  }

  getLeadLostReasonsWithPageNumber = pageNumber => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadLostReasonsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(1, searchTerm);
  };

  render() {
    const {
      leadLostReasons,
      pageDetails,
      loading,
      selectedLeadLostReasonIds
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading} bordered>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"leadLostReasonSearch"}
            onUpdate={this.searchUpdated}
          />
        </div>
        {leadLostReasons.length > 0 && (
          <div>
            <SelectLeadLostReasonTableView
              leadLostReasons={leadLostReasons}
              selectedLeadLostReasonIds={selectedLeadLostReasonIds}
              selectLeadLostReason={this.props.selectLeadLostReason}
              deselectLeadLostReason={this.props.deselectLeadLostReason}
            />
          </div>
        )}
        {leadLostReasons.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesContainer pageClicked={this.pageClicked} />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

MultiLeadLostReasonSelectView.propTypes = {
  getLeadLostReasons: PropTypes.func.isRequired,
  leadLostReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedLeadLostReasonIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLeadLostReason: PropTypes.func.isRequired,
  deselectLeadLostReason: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired
};

export default MultiLeadLostReasonSelectView;
