import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GymmasterLocationRow } from './GymmasterLocationRow'
import { generateNewFormState } from 'utils/formHelpers'
import { PageSelection } from 'modules/components'

export class FormView extends Component {

  componentDidMount () {
    this.pageClicked(1)
  }

  locationSelected = (location) => {
    const newData = generateNewFormState(this.props.data, 'integrationLocationId', location.id, true)
    this.props.onInput(newData)
  }

  pageClicked = (pageNumber) => {
    const { getLocationsForPage, location, data } = this.props
    const id = data.fields.id.value
    getLocationsForPage(location, id, pageNumber)
  }

  render () {
    const { gymmasterLocations, data, pageCount } = this.props
    const integrationLocationId = data.fields.integrationLocationId.value
    const rows = gymmasterLocations.map((gymmasterLocation, index) => {
      const selected = (gymmasterLocation.id === integrationLocationId)
      return <GymmasterLocationRow
        key={index}
        gymmasterLocation={gymmasterLocation}
        locationSelected={this.locationSelected}
        selected={selected} />
    })
    return (
      <div>
        <div className='row push'>
          <div className='col-md-12'>
            <h2 className='h4 push-10'>Select Location</h2>
            <p className='text-gray-dark'>
              Select which Gymmaster location/company this location should be linked to and click next.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='table-responsive'>
            <table className='table table-vcenter table-hover'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
          {pageCount > 1 &&
            <div className='text-center push'>
              <PageSelection
                pageCount={this.props.pageCount}
                currentPage={this.props.currentPage}
                pageClicked={this.pageClicked} />
            </div>
          }
        </div>
      </div>
    )
  }
}

FormView.propTypes = {
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  gymmasterLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLocationsForPage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired
}

export default FormView
