import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import session from 'modules/Stores/Session'
import locationSelect from '../modules/locationSelect'
import createTask from '../modules/createTask'
import userSelect from '../modules/userSelect'

import FilterFormSections from 'modules/components/FilterFormSections'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(createTask.selectors.getData)
  const selectedUsers = userSelect.selectors.getSelectedUsers(state)
  const usersToUse = (selectedUsers.length > 0) ? selectedUsers : [session.selectors.getUser(state)]
  return {
    modalName: createTask.constants.CREATE_TASK_MODAL,
    currentModal: modal.selectors.getCurrentModal(state),
    users: usersToUse,
    loading: modal.selectors.getModalLoading(state),
    locationId: locationSelect.selectors.getSelectedLocationId(state),
    data: createTask.selectors.getData(state),
    length: createTask.selectors.getLength(state),
    startTime: createTask.selectors.getStartTime(state),
    queryString: queryStringSelector(state),
    timezone: session.selectors.getUserTimezone(state),
    leads: createTask.selectors.getLeads(state),
    selectedLead: createTask.selectors.selectedLead(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateFilters: createTask.actions.updateFilters,
    hideModal: modal.actions.hideModal,
    scheduleTask: createTask.actions.scheduleTask,
    searchForLeadsWithQueryString: createTask.actions.searchForLeadsWithQueryString,
    presetUserIdsInFilters: createTask.actions.presetUserIdsInFilters,
    setSelectedLeadId: createTask.actions.setSelectedLeadId,
    scheduleAppointment: createTask.actions.scheduleAppointment,
    scheduleCall: createTask.actions.scheduleCall
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(createTask.components.CreateTaskModal)
