import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import modal from 'modules/Stores/Modal';
import heardAboutMethodsStore from 'modules/Stores/HeardAboutMethods';
import * as heardAboutMethodApiActions from 'modules/Stores/HeardAboutMethods/apiActions';
import heardAboutMethods from '../modules/heardAboutMethods';
import session from 'modules/Stores/Session';

function mapStateToProps(state) {
  const permissionSet = session.selectors.getUserPermissions(state);
  return {
    heardAboutMethods: heardAboutMethodsStore.selectors.getHeardAboutMethods(state),
    loading: heardAboutMethods.selectors.getLoading(state),
    pageDetails: heardAboutMethodsStore.selectors.getPageDetails(state),
    canEdit: permissionSet.allow_lead_settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getHeardAboutMethods: heardAboutMethodApiActions.getHeardAboutMethods,
      createHeardAboutMethod: heardAboutMethods.actions.create,
      deleteHeardAboutMethod: heardAboutMethods.actions.deleteHeardAboutMethod,
      showModal: modal.actions.showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(heardAboutMethods.components.HeardAboutMethods);
