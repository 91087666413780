import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CompanyView } from '../components/CompanyView'

import { saveCompanyWithFormData, updateData, prefillForm } from '../actions'

import userData from 'modules/Stores/UserData'

import { getLoading, getData, getErrors } from '../selectors'

function mapStateToProps (state) {
  return {
    company: userData.selectors.getCompany(state),
    loading: getLoading(state),
    data: getData(state),
    errors: getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    saveCompanyWithFormData,
    onInput: updateData,
    prefillForm
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyView)
