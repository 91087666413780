import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PermissionSet from "../components/PermissionSet";

import * as glofoxSelectors from "../selectors";
import * as glofoxActions from "../actions";
import * as userDataSelectors from "modules/Stores/UserData/selectors";

function mapStateToProps(state, props) {
  return {
    data: glofoxSelectors.getData(state),
    permissionSet: glofoxSelectors.getIntegrationPermissionSet(state),
    company: userDataSelectors.getCompany(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPermissionForSet: glofoxActions.setPermissionForSet
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionSet);
