import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Welcome from './modules/Welcome';
import Profiles from './modules/Profiles';

const FrontDeskView = () => {
  return (
    <>
      <Welcome />
      <Profiles />
    </>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontDeskView);
