import React, { FC, useState } from 'react';
import Modal from 'react-bootstrap-modal';
import ModalHeader from './ModalHeader';
import { Box } from 'modules/components/Foundation';
import AppointmentOutcomeRow from 'modules/components/LeadDetail/modules/appointments/components/NewAppointmentModal/AppointmentOutcomeRow';
import { Switch, TextArea } from 'modules/components/FormFields';

import ScheduleCallModalContainer from '../containers/ScheduleCallModalContainer';
import LeadLostReasonModalContainer from 'modules/components/LeadDetail/containers/LeadLostReasonModalContainer';
import DealModalContainer from 'modules/components/LeadDetail/containers/DealModalContainer';
import TrialSelectModal from 'modules/components/TrialSelectModal';
import ScheduleAppointmentModalContainer from '../containers/ScheduleAppointmentModalContainer';
import ClassAppointmentModalContainer from 'modules/components/LeadDetail/modules/appointments/containers/ClassAppointmentModalContainer';
import CommonEventModalBody from './CommonEventModalBody';
import { EventModalProps } from './EventModalProps';
import { VisitTypes, AppointmentOutcome } from 'types';
import { history } from 'store/store';

const STARTED_TRIAL_MODAL = 'APPOINTMENT_DETAILS_MODAL/STARTED_TRIAL_MODAL';
const DEAL_MODAL = 'APPOINTMENT_DETAILS_MODAL/DEAL_MODAL_AFTER_PRESENTATION';
const LOST_REASON_MODAL = 'APPOINTMENT_DETAILS_MODAL/LOST_REASON_MODAL_AFTER_PRESENTATION';

const ALL_APPOINTMENT_OUTCOMES = [
  AppointmentOutcome.NoShow,
  AppointmentOutcome.NotInterested,
  AppointmentOutcome.Other,
  AppointmentOutcome.Rescheduled,
  AppointmentOutcome.SignedUp,
  AppointmentOutcome.StartedTrial,
  AppointmentOutcome.WantsToThinkAboutIt,
];

const EventModal: FC<EventModalProps> = (props) => {
  const [selectedAppointmentOutcome, setSelectedAppointmentOutcome] = useState<AppointmentOutcome | undefined>();
  const [visitType, setVisitType] = useState(VisitTypes.firstVisit);
  const [note, setNote] = useState('');

  const { currentModal, modalName, hideModal, selectedEvent } = props;

  const trialName = selectedEvent?.lead?.trial?.name;

  const appointmentOutcomeSelected = (appointmentOutcome: AppointmentOutcome) => {
    setSelectedAppointmentOutcome(appointmentOutcome);
  };

  const handleToggle = (_fieldName: string, value: boolean) => {
    const visitType = value === true ? VisitTypes.noSaleReturn : VisitTypes.firstVisit;
    setVisitType(visitType);
  };

  const handleInput = (_name: string, value: string, _isValid: boolean) => {
    setNote(value);
  };

  const recordAppointment = async () => {
    const modalName = modalForAppointmentOutcome(selectedAppointmentOutcome);
    if (modalName) {
      setSelectedAppointmentOutcome(undefined);
      props.showModal(modalName);
    } else {
      cleanUpAndHide();
    }

    const { toggleComplete } = props;
    toggleComplete(selectedEvent, selectedAppointmentOutcome, note, visitType);
  };

  const trialSelected = (trial: any, trialStartsAt: Date) => {
    const { addLeadToTrial } = props;
    const lead = selectedEvent?.lead;
    addLeadToTrial(lead, trial, trialStartsAt);
    cleanUpAndHide();
  };

  function modalForAppointmentOutcome(actionType?: AppointmentOutcome) {
    const { classIntegrationId, classAppointmentModalName, rescheduleModalName } = props;
    switch (actionType) {
      case AppointmentOutcome.Rescheduled:
        return classIntegrationId ? classAppointmentModalName : rescheduleModalName;
      case AppointmentOutcome.WantsToThinkAboutIt:
      case AppointmentOutcome.NoShow:
        return props.scheduleCallModalName;
      case AppointmentOutcome.NotInterested:
        return LOST_REASON_MODAL;
      case AppointmentOutcome.SignedUp:
        return DEAL_MODAL;
      case AppointmentOutcome.StartedTrial:
        return STARTED_TRIAL_MODAL;
      default:
        return null;
    }
  }

  const cleanUpAndHide = () => {
    setSelectedAppointmentOutcome(undefined);
    setVisitType(VisitTypes.firstVisit);
    setNote('');
    hideModal();
  };

  const goToLead = () => {
    if (!selectedEvent?.lead) return;
    cleanUpAndHide();
    history.push('/secure/manageLeads?lead_id=' + selectedEvent?.lead.id);
  };

  if (!selectedEvent?.lead) {
    return null;
  }

  return (
    <>
      <Modal show={currentModal === modalName} onHide={cleanUpAndHide}>
        <div style={{ paddingBottom: '20px' }}>
          <ModalHeader hideModal={cleanUpAndHide} />
          {selectedEvent && (
            <>
              <CommonEventModalBody
                displayName={selectedEvent.lead.display_name}
                email={selectedEvent.lead.email}
                title={selectedEvent.title}
                start={selectedEvent.start}
                dueDate={selectedEvent.end}
                outcome={selectedEvent.outcome}
                userDisplayName={selectedEvent.user.display_name}
                goToLead={goToLead}
                type="appointment"
              />
              {!selectedEvent.completed && (
                <>
                  <Box row doesWrap>
                    {ALL_APPOINTMENT_OUTCOMES.map((type, index) => {
                      const isSelected = type === selectedAppointmentOutcome;
                      const disabled = type === AppointmentOutcome.StartedTrial && trialName != null;
                      return (
                        <AppointmentOutcomeRow
                          key={index}
                          type={type}
                          isSelected={isSelected}
                          onClick={appointmentOutcomeSelected}
                          disabled={disabled}
                          trialName={trialName}
                        />
                      );
                    })}
                  </Box>
                  <Box>
                    <Box row>
                      <Switch
                        name="isNoSaleReturn"
                        label={'Is ' + selectedEvent?.lead.given_name + ' returning after a non sale appointment? (NSR)'}
                        onChange={handleToggle}
                        switchStyle="switch-sm switch-primary"
                        value={visitType === 'no_sale_return'}
                      />
                    </Box>
                    <Box row>
                      <TextArea
                        formFieldStyle="w-30"
                        name="note"
                        label="Appointment notes"
                        placeholder="Any notes about this appointment?"
                        onChange={handleInput}
                        rows={4}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            {!selectedEvent?.completed ? 'Cancel' : 'Close'}
          </button>
          {!selectedEvent?.completed && (
            <button
              className="btn btn-success push-5-r push-10"
              type="button"
              disabled={!selectedAppointmentOutcome}
              onClick={recordAppointment}
            >
              <i className="si si-calendar" /> Record Appointment
            </button>
          )}
        </Modal.Footer>
      </Modal>
      {selectedEvent && (
        <>
          <ScheduleCallModalContainer
            eventTypes={props.eventTypes}
            usersIds={props.usersIds}
            timezone={props.timezone}
            modalName={props.scheduleCallModalName}
            lead={selectedEvent.lead}
          />
          {props.classIntegrationId && (
            <ClassAppointmentModalContainer
              modalName={props.classAppointmentModalName}
              lead={props.selectedEvent.lead}
              classIntegrationId={props.classIntegrationId}
              classIntegrationName={props.classIntegrationName}
              rescheduleModalName={props.rescheduleModalName}
            />
          )}
          <ScheduleAppointmentModalContainer
            modalName={props.rescheduleModalName}
            lead={selectedEvent.lead}
            usersIds={props.usersIds}
            eventTypes={props.eventTypes}
            timezone={props.timezone}
          />
          <LeadLostReasonModalContainer modalName={LOST_REASON_MODAL} lead={selectedEvent.lead} />
          <DealModalContainer modalName={DEAL_MODAL} lead={selectedEvent.lead} />
          <TrialSelectModal.TrialSelectModalContainer onTrialSelect={trialSelected} modalName={STARTED_TRIAL_MODAL} />
        </>
      )}
    </>
  );
};

export default EventModal;
