export function humanReadableStatus (status) {
  switch (status) {
    case 'not_sent':
      return 'Never Sent'
    case 'pending':
    case 'gymleads_pending':
      return 'Pending'
    case 'delivered':
      return 'Delivered'
    case 'opened':
      return 'Opened'
    case 'bounced':
    case 'dropped':
    case 'hard_bounced':
      return 'Bounced'
    case 'link_clicked':
      return 'Link clicked'
    case 'complained':
      return 'Spam complaint'
    case 'unsubscribed':
      return 'Unsubscribed'
    default:
      return 'Unknown'
  }
}

export function humanReadableNoSendReason (noSendReason) {
  switch (noSendReason) {
    case 'missing_email':
      return 'Lead is missing an email address'
    case 'email_bounced':
      return 'Email address has bounced'
    case 'opted_out':
      return 'Lead has opted out of marketing emails'
    case 'not_opted_in':
      return 'Lead has not opted into emails'
    case 'transactional_outside_implied_consent':
      return 'Outside of the implied consent since the last communication (General Data Protection Regulation)'
    case 'transactional_incorrect_status':
      return 'Cannot send transactional emails to lost or inactive leads'
    case 'bad_email':
      return "Lead's email is not valid"
    default:
      return 'Unknown'
  }
}
