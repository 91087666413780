import { createSelector } from 'reselect'
import { NAME } from './constants'

import trials from 'modules/Stores/Trials'

export const getSelectedTrialId = (state) => state[NAME].selectedTrialId
export const getData = (state) => state[NAME].data

export const selectedTrial = createSelector(
  [ trials.selectors.getTrials, getSelectedTrialId ],
  (trials, selectedTrialId) => {
    if (trials && trials.length > 0) {
      const index = trials.findIndex(trial => parseInt(trial.id) === parseInt(selectedTrialId))
      return trials[index]
    } else {
      return null
    }
  }
)
