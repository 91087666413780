import { combineReducers } from 'redux';
import profile from './modules/profile';
import password from './modules/password';
import personalization from './modules/personalization';

export default combineReducers({
  [profile.constants.NAME]: profile.reducer,
  [password.constants.NAME]: password.reducer,
  [personalization.constants.NAME]: personalization.reducer,
});
