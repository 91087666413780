import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'

import CompanyForm from './CompanyForm'

export class CompanyView extends Component {

  UNSAFE_componentWillMount () {
    const { company, prefillForm } = this.props
    prefillForm(company)
  }

  saveClicked = () => {
    const { saveCompanyWithFormData, company, data } = this.props
    saveCompanyWithFormData(company, data)
  }

  render () {
    const { loading, data, errors, onInput } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Your Company</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <CompanyForm
                data={data}
                onSaveSelected={this.saveClicked}
                onInput={onInput} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

CompanyView.propTypes = {
  saveCompanyWithFormData: PropTypes.func.isRequired,
  prefillForm: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object
}
