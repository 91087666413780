import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import locations from 'modules/Stores/Locations'

import calendly from '../modules/configuration/modules/calendly'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const selectedLocationId = selectors.getSelectedLocationId(state)
  const selectedLocation = locations.selectors.locationForIdFactory(selectedLocationId)(state)
  return {
    currentModal: modal.selectors.getCurrentModal(state),
    selectedSubscription: calendly.selectors.getSelectedSubscription(state),
    selectedLocation: selectedLocation,
    loading: calendly.selectors.getLoading(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    resetSubscriptionModal: calendly.actions.resetSubscriptionModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(calendly.components.CalendlyConfigurationModalView)
