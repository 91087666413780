import PropTypes from 'prop-types';
import moment from 'moment';
import React, { Component } from 'react';

import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';

export default class DateSelect extends Component {
  state = {
    date: null,
  };

  resetState = () => {
    this.setState({ date: null });
  };

  generateAndReturnDate = (date) => {
    if (!date) {
      return;
    }

    if (this.props.onUpdate) {
      this.props.onUpdate(date);
    }
  };

  handleDateSelect = (date) => {
    this.setState({ ...this.state, date: date });
    const momentDate = moment(date);
    this.generateAndReturnDate(momentDate);
  };

  generateTheme = () => {
    switch (this.props.style) {
      case 'primary':
        return {
          selectionColor: '#5c90d2',
          textColor: {
            default: '#333',
            active: '#FFF',
          },
          weekdayColor: '#5c90d2',
          headerColor: '#5c90d2',
          floatingNav: {
            background: 'rgba(81, 67, 138, 0.96)',
            color: '#FFF',
            chevron: '#FFA726',
          },
        };
      default:
        return {
          selectionColor: '#46c37b',
          textColor: {
            default: '#333',
            active: '#FFF',
          },
          weekdayColor: '#46c37b',
          headerColor: '#46c37b',
          floatingNav: {
            background: 'rgba(81, 67, 138, 0.96)',
            color: '#FFF',
            chevron: '#FFA726',
          },
        };
    }
  };

  render() {
    const today = new Date();
    let selectedDate = this.props.date ? this.props.date : today;
    selectedDate = this.state.date ? this.state.date : selectedDate;
    const display = this.props.showYears === true ? 'years' : 'days';
    const showTodayHelper = this.props.showTodayHelper || true;
    const minDate = this.props.minDate || new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());

    return (
      <InfiniteCalendar
        theme={this.generateTheme()}
        width={window.innerWidth <= 766 ? window.innerWidth - 22 : 598}
        min={minDate}
        minDate={minDate}
        height={400}
        onSelect={this.handleDateSelect}
        onNavigate={this.handleDateSelect}
        defaultDate={selectedDate}
        keyboardSupport={true}
        display={display}
        showTodayHelper={showTodayHelper}
        hideYearsOnSelect={true}
      />
    );
  }
}

DateSelect.propTypes = {
  style: PropTypes.string,
  onUpdate: PropTypes.func,
  date: PropTypes.object,
  showYears: PropTypes.bool,
  showTodayHelper: PropTypes.bool,
  minDate: PropTypes.object,
};
