import { NAME } from './constants'

export const RESET = NAME + '/RESET'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const SET_FROM_USER = NAME + '/SET_FROM_USER'
export const SET_POTENTIAL_USERS = NAME + '/SET_POTENTIAL_USERS'

export const ADD_TO_USER_ID = NAME + '/ADD_TO_USER_ID'
export const REMOVE_TO_USER_ID = NAME + '/REMOVE_TO_USER_ID'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
