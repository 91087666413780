export function countsAsDataSet (counts) {
  const keys = ['active', 'inactive', 'lost', 'closed', 'not_assigned']
  let countValues = []
  keys.forEach((key, index) => {
    countValues.push(counts[key])
  })
  const dataSets = [{
    label: 'Status',
    data: keys.map((key) => counts[key]),
    backgroundColor: keys.map((key) => backgroundColorForStatus(key))
  }]
  return dataSets
}

export function labels () {
  const keys = ['active', 'inactive', 'lost', 'closed', 'not_assigned']
  return keys.map((key) => titleForStatus(key))
}

export function titleForStatus (status) {
  switch (status) {
    case 'active':
      return 'Active'
    case 'inactive':
      return 'Inactive'
    case 'lost':
      return 'Lost'
    case 'closed':
      return 'Closed'
    case 'not_assigned':
      return 'Unassigned'
    default:
      return ''
  }
}

function backgroundColorForStatus (status) {
  switch (status) {
    case 'active':
      return '#70b9eb'
    case 'inactive':
      return '#f3b760'
    case 'lost':
      return '#d26a5c'
    case 'closed':
      return '#46c37b'
    case 'not_assigned':
      return '#ff8f8f'
    default:
      return ''
  }
}

export function isEmpty (dataSet) {
  const data = dataSet[0].data
  return data.filter(value => value > 0).length === 0
}
