import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import RegionSelectView from './regionSelect/RegionSelectView';

export class RegionSelectModal extends Component {
  state = {
    selectedRegionIds: Object.keys(this.props.data.fields).map((string) => parseInt(string)),
  };

  UNSAFE_componentWillMount() {
    const { regions, getRegions } = this.props;
    if (regions && regions.length > 0) {
      return;
    }
    getRegions();
  }

  selectRegion = (region) => {
    const newSelectedRegionIds = [...this.state.selectedRegionIds, region.id];
    this.setState({ ...this.state, selectedRegionIds: newSelectedRegionIds });
  };

  deselectRegion = (region) => {
    const index = this.state.selectedRegionIds.findIndex((regionId) => parseInt(regionId) === parseInt(region.id));
    const newSelectedRegionIds = [...this.state.selectedRegionIds.slice(0, index), ...this.state.selectedRegionIds.slice(index + 1)];
    this.setState({ ...this.state, selectedRegionIds: newSelectedRegionIds });
  };

  useAllRegions = () => {
    this.setState({ ...this.state, selectedRegionIds: [] });
    const newSectionData = { fields: {} };
    this.props.onUpdate(newSectionData, this.props.sectionName);
    this.props.hideModal();
  };

  updateClubIds = () => {
    let fields = {};
    this.state.selectedRegionIds.forEach((regionId) => {
      const object = { value: true, isValid: true };
      fields = { ...fields, [regionId]: object };
    });
    const newSectionData = { fields: fields, isValid: true };
    this.props.onUpdate(newSectionData, this.props.sectionName);
    this.props.hideModal();
  };

  render() {
    const { currentModal, modalName, hideModal } = this.props;
    const disabled = this.state.selectedRegionIds.length === 0;
    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select regions to report on
            </h3>
          </div>
          <div className="block-content">
            <RegionSelectView
              {...this.props}
              selectedRegionIds={this.state.selectedRegionIds}
              selectRegion={this.selectRegion}
              deselectRegion={this.deselectRegion}
            />
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-primary pull-left" onClick={this.useAllRegions}>
            All Regions
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={disabled} onClick={this.updateClubIds}>
            <i className="fa fa-building-o" /> Filter by selected
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RegionSelectModal.propTypes = {
  getRegions: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
};

export default RegionSelectModal;
