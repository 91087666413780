import { apiClient } from 'utils/apiClient';
import { AppointmentOutcome, IAppointment, ICalenderableResponse, VisitTypes } from 'types';

type AppointmentResponse = {
  appointment: IAppointment;
};

type AppointmentMeta = {
  meta: {
    syncing_class: boolean;
  };
};

async function createAppointment(
  leadId: number,
  dueDate: Date,
  serviceClassId: number,
  serviceString: string,
  visitType: string,
  length: number,
  respondAsCalendarEvent: boolean
) {
  const url = `leads/${leadId}/appointments`;
  const body = {
    as_calendar_event: respondAsCalendarEvent,
    appointment: {
      due_date: dueDate,
      service_class_id: serviceClassId,
      service_class_detail: serviceString,
      visit_type: visitType,
      length: length,
    },
  };

  const response = await apiClient().post(url, body);

  return response.data as Promise<ICalenderableResponse<AppointmentMeta> | AppointmentResponse>;
}

  async function completeAppointment(leadId: number, serverId: number, outcome: AppointmentOutcome, visitType: VisitTypes, note?: string, respondAsCalendarEvent?: boolean) {
  const url = `leads/${leadId}/appointments/${serverId}`;

  const body = {
    as_calendar_event: respondAsCalendarEvent,
    appointment: {
      outcome: outcome,
      note: note,
      visit_type: visitType,
    },
  };

  const response = await apiClient().put(url, body);

  return response.data as Promise<ICalenderableResponse<AppointmentMeta> | AppointmentResponse>;
}

export { createAppointment, completeAppointment };
