import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'

import * as billingHistoryActions from '../actions'
import * as billingHistorySelectors from '../selectors'
import InvoiceModalView from '../components/InvoiceModalView'

function mapStateToProps (state) {
  return {
    invoice: billingHistorySelectors.selectedInvoice(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    selectInvoiceId: billingHistoryActions.selectInvoiceId,
    getInvoicePdfForId: billingHistoryActions.getInvoicePdfForId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModalView)
