import PropTypes from "prop-types";
import React, { Component } from "react";
import ClassRow from "./ClassRow";

export class ClassDayRow extends Component {
  render() {
    const { classes, selectClass, selectedClass, showSpots, timezone, classIntegrationName } = this.props;
    const rows = classes.map(record => {
      const selected =
        (selectedClass && selectedClass.id === record.id) || false;
      return (
        <ClassRow
          key={record.id}
          classRecord={record}
          selectClass={selectClass}
          selected={selected}
          showSpots={showSpots}
          timezone={timezone}
          classIntegrationName={classIntegrationName}
        />
      );
    });
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th style={{ width: "30%" }} />
            <th style={{ width: "20%" }} />
            {this.props.showSpots && <th style={{ width: "20%" }} />}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

ClassDayRow.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectClass: PropTypes.func.isRequired,
  showSpots: PropTypes.bool,
  selectedClass: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  classIntegrationName: PropTypes.string.isRequired
};

export default ClassDayRow;
