import React, { Component } from "react";
import styled from "styled-components";
import { FormFields, InfoView } from "modules/components";
import * as helpers from "../helpers";
import { validatorForOption } from "../validators";
const { Switch, TextField } = FormFields;

export default class EditSubscriptionView extends Component {
  state = {
    name: "",
    updateLead: undefined,
    createLead: undefined,
    cancelAppointment: undefined,
    createAppointment: undefined,
    enabled: undefined,
  };

  componentDidMount = () => {
    const { selectedSubscription } = this.props;
    const {
      enabled,
      name,
      create_appointment,
      cancel_appointment,
      create_lead,
      update_lead,
    } = selectedSubscription;
    this.setState({
      name: name,
      updateLead: update_lead,
      createLead: create_lead,
      cancelAppointment: cancel_appointment,
      createAppointment: create_appointment,
      enabled: enabled,
    });
  };

  handleInput = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  handleUpdateSubscription = () => {
    const {
      name,
      updateLead,
      createLead,
      cancelAppointment,
      createAppointment,
      enabled,
    } = this.state;
    const subscriptionParams = {
      name: name,
      update_lead: updateLead,
      create_lead: createLead,
      cancel_appointment: cancelAppointment,
      create_appointment: createAppointment,
      enabled: enabled,
    };
    const {
      updateSubscription,
      selectedSubscription,
      locationId,
      integrationId,
    } = this.props;
    updateSubscription(
      locationId,
      integrationId,
      selectedSubscription.id,
      subscriptionParams
    );
  };

  handleDeleteSubscription = () => {
    const {
      deleteSubscription,
      locationId,
      integrationId,
      selectedSubscription,
    } = this.props;
    deleteSubscription(locationId, integrationId, selectedSubscription.id);
  };

  render() {
    const { selectedSubscription, errors } = this.props;
    const {
      name,
      updateLead,
      createLead,
      cancelAppointment,
      createAppointment,
      enabled,
    } = this.state;

    const validator = validatorForOption("calendly");
    const saveButtonDisabled = name.length === 0;

    return (
      <Container className="row">
        <div className="col-md-12">
          {errors && (
            <InfoView colorThemeClass={"danger"} detail={errors.message} />
          )}
        </div>
        <Container className="col-md-6">
          <div className="col-xs-12">
            <Headline>
              Integration Settings:{" "}
              {selectedSubscription.verified ? (
                <Label className={"label label-success"}>Verified</Label>
              ) : (
                <Label className={"label label-danger"}>Invalid</Label>
              )}
            </Headline>
          </div>
          <TextField
            name="name"
            label={"Name"}
            placeholder=""
            formFieldStyle=""
            onChange={this.handleInput}
            disabled={false}
            validator={validator}
            value={name}
          />
          <EnabledSwitchContainer>
            <Switch
              name="enabled"
              label={helpers.enabledLabel}
              onChange={this.handleInput}
              switchStyle="switch-sm switch-primary"
              value={enabled}
            />
          </EnabledSwitchContainer>
        </Container>
        <Container className="col-md-6">
          <div className="col-xs-12">
            <Headline>Permissions: </Headline>
          </div>
          <Switch
            name="createAppointment"
            label={helpers.createAppointmentLabel}
            onChange={this.handleInput}
            switchStyle="switch-sm switch-primary"
            value={createAppointment}
          />
          <Switch
            name="cancelAppointment"
            label={helpers.cancelAppointmentLabel}
            onChange={this.handleInput}
            switchStyle="switch-sm switch-primary"
            value={cancelAppointment}
          />
          <Switch
            name="createLead"
            label={helpers.createLeadLabel}
            onChange={this.handleInput}
            switchStyle="switch-sm switch-primary"
            value={createLead}
          />
          <Switch
            name="updateLead"
            label={helpers.updateLeadLabel}
            onChange={this.handleInput}
            switchStyle="switch-sm switch-primary"
            value={updateLead}
          />
          <ButtonContainer className="col-xs-12">
            <DeleteButton
              className="btn btn-danger"
              onClick={this.handleDeleteSubscription}
            >
              Delete
            </DeleteButton>
            <SaveButton
              className="text-right btn btn-success"
              disabled={saveButtonDisabled}
              onClick={this.handleUpdateSubscription}
            >
              Save
            </SaveButton>
          </ButtonContainer>
        </Container>
      </Container>
    );
  }
}

const Container = styled.div``;
const Label = styled.span``;
const ButtonContainer = styled.div``;

const EnabledSwitchContainer = styled.div`
  padding: 10px 0px 0px 0px;
`;

const Headline = styled.h5`
  margin-bottom: 15px;
`;

const SaveButton = styled.button`
  margin-left: 2%;
  margin-top: 10px;
  width: 48%;
`;

const DeleteButton = styled.button`
  margin-top: 10px;
  width: 48%;
`;
