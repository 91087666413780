import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';

export function updateFilters(filterData) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    filterData,
  };
}

export function clearAllFilters() {
  return {
    type: actionTypes.CLEAR_ALL_FILTERS,
  };
}

export function showFilters() {
  return {
    type: actionTypes.SHOW_FILTERS,
  };
}

export function hideFilters() {
  return {
    type: actionTypes.HIDE_FILTERS,
  };
}

export function showNoUserError(displayName) {
  return (dispatch) => {
    notify.error(displayName + ' is not a user in the selected clubs');
  };
}
