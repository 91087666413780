import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';
import 'moment-timezone';

export class ReallocationRow extends Component {
  render() {
    const { reallocation, timezone } = this.props;
    const location = reallocation.location;

    let statusColor = 'text-info';
    switch (reallocation.status) {
      case 'pending':
        statusColor = 'text-info';
        break;
      case 'processing':
        statusColor = 'text-primary';
        break;
      case 'complete':
        statusColor = 'text-success';
        break;
      default:
        break;
    }

    return (
      <tr>
        <td>{moment(reallocation.created_at).tz(timezone).format('h:mm a, Do MMM YYYY')}</td>
        <td>
          {location && <span>{location.admin_display_name}</span>}
          {!location && <span />}
        </td>
        <td>{reallocation?.lead_donator?.display_name || 'Deleted user'}</td>
        <td>
          <i className={'fa fa-circle ' + statusColor} /> {reallocation.status}
        </td>
      </tr>
    );
  }
}

ReallocationRow.propTypes = {
  reallocation: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ReallocationRow;
