import React, { FC } from 'react';
import { IUser } from 'types';
import { space } from 'styleguide';
import { GymLeadsLoader, Box } from 'modules/components';

import CalendarContainer from '../containers/CalendarContainer';
import LocationSelectContainer from '../containers/LocationSelectContainer';
import UserSelectContainer from '../containers/UserSelectContainer';
import CreateTaskModalContainer from '../containers/CreateTaskModalContainer';

import { CalenderLegend } from './CalenderLegend';

interface CalendarLayoutViewProps {
  user: IUser;
  loading: boolean;
  selectedLocationId: number;
}

export const CalendarLayoutView: FC<CalendarLayoutViewProps> = (props) => {
  const { user, loading, selectedLocationId } = props;
  return (
    <div className="content content-boxed">
      <Box row s100>
        <Box column width="20%" mr={space.S}>
          <LocationSelectContainer />
          <Box mb={space.S} />
          {selectedLocationId && <UserSelectContainer user={user} />}
          <Box height={space.M} />
          <CalenderLegend />
          <GymLeadsLoader active={loading} />
        </Box>
        <Box width="80%">
          <CalendarContainer />
        </Box>
      </Box>
      {selectedLocationId && <CreateTaskModalContainer />}
    </div>
  );
};
