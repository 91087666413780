import locations from './Locations';
import session from './Session';
import userData from './UserData';
import modal from './Modal';
import trials from './Trials';
import mergeTags from './MergeTags';
import emailTemplates from './EmailTemplates';
import thirdPartyIntegrations from './ThirdPartyIntegrations';
import textMessageTemplates from './TextMessageTemplates';
import billing from './Billing';
import sockets from './Sockets';
import classes from './Classes';
import deals from './Deals';
import lawfulProcessingBases from './LawfulProcessingBases';
import securityPolicy from './SecurityPolicy';
import leadSources from './LeadSources';
import heardAboutMethods from './HeardAboutMethods';
import leadGoals from './LeadGoals';
import leadLostReasons from './LeadLostReasons';
import searchTerm from './Search';
import dateTimeSelect from './DateTimeSelect';
import customMailMergeTags from './CustomMailMergeTags';
import regions from './Regions';

export default {
  mergeTags,
  userData,
  modal,
  session,
  deals,
  emailTemplates,
  thirdPartyIntegrations,
  textMessageTemplates,
  billing,
  sockets,
  classes,
  lawfulProcessingBases,
  securityPolicy,
  trials,
  locations,
  leadSources,
  heardAboutMethods,
  leadGoals,
  leadLostReasons,
  searchTerm,
  dateTimeSelect,
  customMailMergeTags,
  regions,
};
