import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getLoading = state => state[NAME].loading;
export const getCached = state => state[NAME].cached;
export const getClasses = state => state[NAME].classes;
export const getErrors = state => state[NAME].errors;
export const getClassLocationIds = state => state[NAME].classLocationIds;

// This method relies on an assumed data structure passed from the API
// ClassLocationIds is an array of arrays. Each array contains the following values:
// index 0: location id
// index 1: third party_integration id
// index 2: third party_integration name
// The API will only return records for locations that have classes. This allows us to
// Know in advance if a location uses classes and pass the integraiton ID to it, without loading all locations
// into the store. (A semi healthy middleground)
export function locationUsesClassesFactory(locationId) {
  return createSelector(
    [getClassLocationIds],
    classLocationIds => {
      const index = classLocationIds.findIndex(
        classLocationIndex =>
          parseInt(classLocationIndex[0]) === parseInt(locationId)
      );
      if (index > -1) {
        return {
          classIntegrationId: classLocationIds[index][1],
          classIntegrationName: classLocationIds[index][2]
        };
      } else {
        return {
          classIntegrationId: null,
          classIntegrationName: null
        };
      }
    }
  );
}
