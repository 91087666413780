import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

import { ContentBlockView, Text, Box } from 'modules/components';

import { IWaverSubmission, ILead } from 'types';
import Modal from 'react-bootstrap-modal';

import { fetchWaiverSubmission, getDownloadUrl } from 'modules/api/waiverSubmissions';

interface ViewSignedWaiverModalViewProps {
  lead: ILead;
  waiverSubmission: any;
  hideModal: () => void;
  selectWaiverSubmissionId: () => void;
  modalName: string;
  currentModal?: string;
  timezone: string;
}

export const ViewSignedWaiverModalView: FC<ViewSignedWaiverModalViewProps> = (props) => {
  const { hideModal, selectWaiverSubmissionId, currentModal, modalName, waiverSubmission, lead, timezone } = props;

  const [loading, setLoading] = useState(false);
  const [detailedWaiverSubmission, setDetailedWaiverSubmission] = useState<IWaverSubmission | undefined>();

  useEffect(() => {
    loadSignedDetails();
    // eslint-disable-next-line
  }, [waiverSubmission]);

  const loadSignedDetails = async () => {
    setLoading(true);
    const response = await fetchWaiverSubmission(lead.id, waiverSubmission.id);
    setDetailedWaiverSubmission(response.waiver_submission_details);

    setLoading(false);
  };

  const cleanUpAndHideModal = () => {
    selectWaiverSubmissionId();
    hideModal();
  };

  const downloadPdf = async () => {
    const response = await getDownloadUrl(waiverSubmission.id);
    window.open(response.url, '_blank');
  };

  const signedDate = moment(waiverSubmission.due_date).tz(timezone).format('MMMM Do YYYY');

  return (
    <Modal show={currentModal === modalName} onHide={cleanUpAndHideModal} dialogClassName="modal-lg">
      <ContentBlockView title="Waiver submission" loading={loading} colorStyle="bg-primary">
        {detailedWaiverSubmission && (
          <Box>
            <Text.H3>Disclaimer text</Text.H3>
            <Text.P2>{detailedWaiverSubmission.waiver_text}</Text.P2>

            <Text.H3>Signature</Text.H3>
            <img alt="customer signature" src={`data:image/jpeg;base64,${detailedWaiverSubmission.waiver_signature}`} width={240} />
            <Text.P2>Signed on {signedDate}</Text.P2>
          </Box>
        )}
      </ContentBlockView>
      <Modal.Footer>
        <button className="btn btn-default push-5-r push-10" type="button" onClick={downloadPdf}>
          Download as PDF <i className="si si-cloud-download" />
        </button>
        <button className="btn btn-default push-5-r push-10" type="button" onClick={cleanUpAndHideModal}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};
