import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';

import { PERSONAL_EVENT_DETAIL_MODAL, APPOINTMENT_EVENT_DETAIL_MODAL, CALL_EVENT_DETAIL_MODAL, EVENT_COLORS } from '../constants';

import AppointmentModalContainer from '../containers/AppointmentModalContainer';
import CallModalContainer from '../containers/CallModalContainer';
import EventView from './EventView';

import PersonalTaskModalContainer from '../containers/PersonalTaskModalContainer';

require('react-big-calendar/lib/css/react-big-calendar.css');
require('react-big-calendar/lib/addons/dragAndDrop/styles.css');

const localizer = momentLocalizer(moment);

class CalendarView extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.locationId) {
      return;
    }

    if (
      nextProps.users.length !== this.props.users.length ||
      nextProps.locationId !== this.props.locationId ||
      nextProps.calculatedDateRange !== this.props.calculatedDateRange ||
      nextProps.showCompletedOnly !== this.props.showCompletedOnly ||
      nextProps.eventTypes !== this.props.eventTypes
    ) {
      const { locationId, users, calculatedDateRange, showCompletedOnly, eventTypes } = nextProps;
      this.fetchEvents(calculatedDateRange, users, locationId, showCompletedOnly, eventTypes);
    }
  }

  componentDidMount = () => {
    const { calculatedDateRange, users, locationId, showCompletedOnly, eventTypes } = this.props;
    if (locationId && users) {
      this.fetchEvents(calculatedDateRange, users, locationId, showCompletedOnly, eventTypes);
    }
  };

  dateRangeUpdated = (date, view) => {
    this.props.setView(view);
    this.props.updateDate(date);
  };

  viewUpdated = (view) => {
    this.props.setView(view);
  };

  fetchEvents = (dateRange, users, locationId, showCompletedOnly, eventTypes) => {
    const { timezone, loadAllEventsForUsersInDateRange } = this.props;
    loadAllEventsForUsersInDateRange(dateRange.startDate, dateRange.endDate, timezone, users, [locationId], showCompletedOnly, eventTypes);
  };

  moveEvent = (event) => {
    const { updateEventTime, timezone } = this.props;
    const newStartDate = moment(event.start).utc().format();
    updateEventTime(event.event, newStartDate, timezone);
  };

  eventSelected = (event) => {
    this.props.selectEventId(event.id);

    switch (event.type) {
      case 'personal task':
        this.props.showModal(PERSONAL_EVENT_DETAIL_MODAL);
        break;
      case 'contact call':
        this.props.showModal(CALL_EVENT_DETAIL_MODAL);
        break;
      default:
        this.props.showModal(APPOINTMENT_EVENT_DETAIL_MODAL);
        break;
    }
  };

  onSelectSlot = (slotInfo) => {
    const start = moment(slotInfo.start).utc();
    const end = moment(slotInfo.end).utc();
    const length = end.diff(start, 'minutes');
    const dateString = start.format();
    this.props.setTaskDetails(dateString, length.toString());
  };

  getEventColor = (isCompleted, isSuccessful, isOverdue) => {
    if (isCompleted) {
      return isSuccessful ? EVENT_COLORS.SUCCESSFUL : EVENT_COLORS.UNSUCCESSFUL;
    } else {
      return isOverdue ? EVENT_COLORS.MISSED : EVENT_COLORS.NOT_COMPLETED;
    }
  };

  render() {
    const { timezone, users } = this.props;
    const eventPropGetter = (event) => {
      let color = '#3D7CC9';
      const isCompleted = event.completed;
      const endMoment = moment(event.end).tz(timezone);
      const now = moment().tz(timezone);
      const isOverdue = now.isAfter(endMoment);

      const isSuccessful = event.successful;

      switch (event.type) {
        case 'contact call':
          color = this.getEventColor(isCompleted, isSuccessful, isOverdue);
          break;
        case 'appointment':
          color = this.getEventColor(isCompleted, isSuccessful, isOverdue);
          break;
        case 'personal task':
          color = EVENT_COLORS.NOT_COMPLETED;
          break;
        default:
          break;
      }

      return { style: { background: color } };
    };

    const startTime = moment().tz(timezone).subtract(2, 'hours');
    const min = new Date();
    min.setHours(5);
    min.setMinutes(0, 0, 0);

    const max = new Date();
    max.setHours(22);
    max.setMinutes(0, 0, 0);

    let formats = {
      eventTimeRangeStartFormat: '',
      eventTimeRangeEndFormat: '',
      agendaHeaderFormat: ({ start, end }) => {
        return `${moment(start).format('DD/MM/YY')} - ${moment(end).format('DD/MM/YY')}`;
      },
    };

    return (
      <div style={{ height: '768px', width: '100%' }}>
        <Calendar
          localizer={localizer}
          min={min}
          max={max}
          timeslots={2}
          step={15}
          formats={formats}
          defaultView={this.props.view}
          events={this.props.events}
          onNavigate={this.dateRangeUpdated}
          onView={this.viewUpdated}
          selectable={true}
          onEventDrop={this.moveEvent}
          onSelectEvent={this.eventSelected}
          eventPropGetter={eventPropGetter}
          onSelectSlot={this.onSelectSlot}
          scrollToTime={startTime.toDate()}
          components={{
            event: EventView,
          }}
        />
        <AppointmentModalContainer modalName={APPOINTMENT_EVENT_DETAIL_MODAL} usersIds={users.map((user) => user.id)} />
        <CallModalContainer modalName={CALL_EVENT_DETAIL_MODAL} usersIds={users.map((user) => user.id)} />
        <PersonalTaskModalContainer modalName={PERSONAL_EVENT_DETAIL_MODAL} />
      </div>
    );
  }
}

CalendarView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  loadAllEventsForUsersInDateRange: PropTypes.func.isRequired,
  locationId: PropTypes.number,
  calculatedDateRange: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  updateDate: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectEventId: PropTypes.func,
  setTaskDetails: PropTypes.func.isRequired,
  updateEventTime: PropTypes.func.isRequired,
  showCompletedOnly: PropTypes.bool,
  eventTypes: PropTypes.arrayOf(PropTypes.string),
};

export default CalendarView;
