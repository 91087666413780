import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'

import { InfoView } from 'modules/components'

import ActionAutomationRuleForm from './ActionAutomationRuleForm'

export class CurrentActionAutomationRuleView extends Component {

  UNSAFE_componentWillMount () {
    const { location, prefilFormData } = this.props
    prefilFormData(location.action_automation_rule)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.id !== this.props.location.id) {
      nextProps.prefilFormData(nextProps.location.action_automation_rule)
    }
  }

  saveClicked = () => {
    const { update, location, data } = this.props
    update(location, location.action_automation_rule.id, data)
  }

  render () {
    const { loading, data, errors, onInput } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    const infoString = 'Select how ' + this.props.productName + ' automates specific user actions in the sales process.'
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Action Automation Rules for { this.props.location.admin_display_name }</h3>
          </div>
          <div className='block-content'>
            <InfoView colorThemeClass={'info'} detail={infoString} />
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <ActionAutomationRuleForm
                data={data}
                onSaveSelected={this.saveClicked}
                onInput={onInput} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

CurrentActionAutomationRuleView.propTypes = {
  location: PropTypes.object.isRequired,
  prefilFormData: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default CurrentActionAutomationRuleView
