import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomMailMergeTagsView from '../components/CustomMailMergeTagsView';

import customMailMergeTags from 'modules/Stores/CustomMailMergeTags';
import modal from 'modules/Stores/Modal';

import * as localActions from '../actions';
import * as localSelectors from '../selectors';

function mapStateToProps(state) {
  return {
    customMailMergeTags: customMailMergeTags.selectors.getCustomMailMergeTags(state),
    selectedCustomMailMergeTag: localSelectors.selectedCustomMailMergeTag(state),
    selectedCustomMailMergeTagId: localSelectors.getSelectedCustomMailMergeTagId(state),
    loading: customMailMergeTags.selectors.getLoading(state),
    errors: customMailMergeTags.selectors.getErrors(state),
    pageDetails: customMailMergeTags.selectors.getPageDetails(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prefillDataForCustomMailMergeTag: localActions.prefillDataForCustomMailMergeTag,
      getCustomMailMergeTags: customMailMergeTags.actions.getCustomMailMergeTags,
      showModal: modal.actions.showModal,
      setSelectedCustomMailMergeTagId: localActions.setSelectedCustomMailMergeTagId,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomMailMergeTagsView);
