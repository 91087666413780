import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import locations from "modules/Stores/Locations";
import modal from "modules/Stores/Modal";
import listing from "../modules/listing";

import * as selectors from "../selectors";

function mapStateToProps(state) {
  const selectedLocationId = selectors.getSelectedLocationId(state);
  const selectedLocation = locations.selectors.locationForIdFactory(
    selectedLocationId
  )(state);
  return {
    location: selectedLocation,
    options: listing.selectors.getOptions(state),
    configuredIntegrations: listing.selectors.validConfiguredIntegrations(
      state
    ),
    loading: listing.selectors.getLoading(state),
    errors: listing.selectors.getErrors(state),
    selectedOption: listing.selectors.getSelectedOption(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal,
      getAllOptions: listing.actions.getAllOptions,
      getIntegrationsForLocation: listing.actions.getIntegrationsForLocation,
      setSelectedOption: listing.actions.setSelectedOption
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(listing.components.ListingView);
