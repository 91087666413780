import * as actionTypes from './actionTypes'

const TASK_LIST_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading, errors: null }
  },
  [actionTypes.SET_TASKS]: (state, action) => {
    return { ...state, tasks: action.tasks }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalCount: action.pageDetails.total_count
    }
  },
  [actionTypes.REMOVE_TASK_WITH_ID]: (state, action) => {
    const index = state.tasks
    .findIndex(task => parseInt((task.id), 10) === parseInt((action.taskId), 10))
    const newTasks = [
      ...state.tasks.slice(0, index),
      ...state.tasks.slice(index + 1)
    ]
    return { ...state, tasks: newTasks, selectedTaskId: null }
  }
}

const initialState = {
  loading: false,
  tasks: [],
  pageCount: 1,
  currentPage: 1,
  totalCount: 0
}

export default function reducer (state = initialState, action) {
  const handler = TASK_LIST_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
