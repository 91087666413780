import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import FormView from '../components/SelectLocation/FormView'

import * as mindbodyActions from '../actions'
import * as mindbodySelectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    data: mindbodySelectors.getData(state),
    tabIndex: mindbodySelectors.getTabIndex(state),
    mindbodyLocations: mindbodySelectors.getSiteLocations(state),
    pageCount: mindbodySelectors.getPageCount(state),
    currentPage: mindbodySelectors.getCurrentPage(state),
    total: mindbodySelectors.getTotal(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: mindbodyActions.onInput,
    getMindbodyLocationsForPage: mindbodyActions.getMindbodyLocationsForPage
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
