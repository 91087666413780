import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import currentAutoAssignRule from '../modules/currentAutoAssignRule'

function mapStateToProps (state, nextProps) {
  return {
    loading: currentAutoAssignRule.selectors.getLoading(state),
    data: currentAutoAssignRule.selectors.getData(state),
    errors: currentAutoAssignRule.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: currentAutoAssignRule.actions.updateData,
    deleteAutoAssignRule: currentAutoAssignRule.actions.deleteAutoAssignRule,
    updateAutoAssignRuleWithData: currentAutoAssignRule.actions.updateAutoAssignRuleWithData,
    prefilFormData: currentAutoAssignRule.actions.prefilFormData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(currentAutoAssignRule.components.CurrentAutoAssignRuleView)
