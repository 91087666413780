import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { EmptyView, PagesView } from "modules/components";
import { AppointmentItem } from "./AppointmentItem";

const AppointmentItemList = ({
  lead,
  user,
  loading,
  pageDetails,
  appointments,
  fetchAppointments,
  appointmentClicked,
  appointmentDeleted,
  classIntegrationName
}) => {
  const appointmentList = appointments.map((appointment, index) => {
    return (
      <AppointmentItem
        key={index}
        appointment={appointment}
        timezone={user.timezone}
        appointmentClicked={appointmentClicked}
        appointmentDeleted={appointmentDeleted}
        loading={loading}
        classIntegrationName={classIntegrationName}
      />
    );
  });

  function pageClicked(pageNumber) {
    fetchAppointments(lead, pageNumber);
  }

  return (
    <Container>
      {appointments.length > 0 ? (
        <AppointmentsDiv>{appointmentList}</AppointmentsDiv>
      ) : (
        <EmptyView
          colorThemeClass="primary"
          iconClass="fa fa-tasks"
          title="No appointments for this lead"
        />
      )}
      {pageDetails.pageCount > 1 && (
        <div className="col-xs-12">
          <div className="text-center push">
            <PagesView pageDetails={pageDetails} pageClicked={pageClicked} />
          </div>
        </div>
      )}
    </Container>
  );
};

export default AppointmentItemList;

AppointmentItemList.propTypes = {
  appointmentClicked: PropTypes.func.isRequired,
  appointmentDeleted: PropTypes.func.isRequired,
  fetchAppointments: PropTypes.func.isRequired,
  appointments: PropTypes.arrayOf(PropTypes.object).isRequired,
  lead: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const Container = styled.div``;
const AppointmentsDiv = styled.div``;
