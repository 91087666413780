import { request, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

// Fetch Notes
export const fetchForLead = (lead, pageNumber = 1) => {
  return (dispatch) => {
    dispatch(fetchingtextMessages());

    function onFailure(errors) {
      dispatch(fetchTextMessagesFailure(errors));
    }

    function onSuccess(payload) {
      dispatch(loadPageDetails(payload.meta));
      dispatch(fetchTextMessagesSuccess(payload.lead_text_messages));
    }

    const url = 'leads/' + lead.id + '/lead_text_messages?page[number]=' + pageNumber;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function fetchingtextMessages() {
  return {
    type: actionTypes.FETCHING_TEXT_MESSAGES,
  };
}

function fetchTextMessagesSuccess(textMessages) {
  return {
    type: actionTypes.FETCH_TEXT_MESSAGE_SUCCESS,
    textMessages,
  };
}

function fetchTextMessagesFailure(errors) {
  return {
    type: actionTypes.FETCH_TEXT_MESSAGE_FAILURE,
    errors,
  };
}

export function add(textMessage) {
  return {
    type: actionTypes.ADD_TEXT_MESSAGE,
    textMessage,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.TEXT_MESSAGE_PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
