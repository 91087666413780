import { apiClient } from 'utils/apiClient';
import { IWaverSubmission } from 'types';

export const fetchWaiverSubmission = async (leadId: number, waiverSubmissionId: number) => {
  const url = `waiver_submission_for_lead/${leadId}/${waiverSubmissionId}`;
  const response = await apiClient().get(url);
  return response.data as { waiver_submission_details: IWaverSubmission };
};

export const getDownloadUrl = async (id: number) => {
  const url = `export_waiver_submission_url/${id}`;
  const response = await apiClient().get(url);
  return response.data as { url: string };
};
