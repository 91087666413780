import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields } from 'modules/components'
import { generateNewFormState } from 'utils/formHelpers'

import { validator } from '../validators'

const { TextField, Checkbox } = FormFields

export class EditLawfulProcessingBasisForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.onInput(newData)
  }

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true)
    this.props.onInput(newData)
  }

  render () {
    const { data, lawfulProcessingBasis } = this.props
    const { title, leadFacingDescription, newDefault, closedLeadDefault } = data.fields

    return (
      <div>
        <div className='row' style={{ marginLeft: '-30px', marginRight: '-30px', marginTop: '-15px' }}>
          <div className='col-sm-12 col-md-6'>
            <TextField
              name='title'
              label='Title *'
              placeholder='Pricing enquiry'
              formFieldStyle=''
              onChange={this.handleInput}
              value={title.value}
              validator={validator}
              disabled={lawfulProcessingBasis && lawfulProcessingBasis.locked} />
          </div>
        </div>
        <div className='row' style={{ marginLeft: '-30px', marginRight: '-30px', marginBottom: '15px' }}>
          <div className='col-xs-12'>
            <TextField
              name='leadFacingDescription'
              label='Lead Facing Description *'
              placeholder='You asked us about our pricing'
              formFieldStyle=''
              onChange={this.handleInput}
              value={leadFacingDescription.value} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <Checkbox
              name='newDefault'
              label='Use as default?'
              onChange={this.handleToggle}
              switchStyle='primary'
              checked={newDefault.value} />
          </div>
          <div className='col-xs-12'>
            <Checkbox
              name='closedLeadDefault'
              label='Change to when lead closed?'
              onChange={this.handleToggle}
              switchStyle='primary'
              checked={closedLeadDefault.value} />
          </div>
        </div>
      </div>
    )
  }
}

EditLawfulProcessingBasisForm.propTypes = {
  lawfulProcessingBasis: PropTypes.object,
  data: PropTypes.object.isRequired,
  onInput: PropTypes.func.isRequired
}

export default EditLawfulProcessingBasisForm
