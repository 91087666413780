import { connect } from 'react-redux'
import EditLocationForm from '../components/EditLocationForm'
import { getProductName } from 'modules/Stores/UserData/selectors'

function mapStateToProps (state) {
  return {
    productName: getProductName(state)
  }
}

export default connect(mapStateToProps)(EditLocationForm) // Insert View name
