import { Box, EmptyView, HistoryView, Text } from "modules/components";
import { history } from "store/store";
import React, { FC, useEffect } from "react";
import Modal from "react-bootstrap-modal";
import { space } from "styleguide";
import { ActivityRecord, ILead } from "types";
import { NAME } from "../constants";
import * as leadSourceHelpers from "modules/Helpers/leadSourceHelpers";
import STRINGS from "strings";
import { pluralizationHelper } from "modules/Helpers/PluralizationHelpers";

const Strings = STRINGS.LeadDetail;

interface LeadDetailsModalProps {
  currentModal?: string;
  loading: boolean;
  hideModal: () => void;
  resetState: () => void;
  lead?: ILead;
  activityRecords: ActivityRecord[];
  getLeadDetails: Function;
  getActivityRecords: Function;
  leadId?: number;
  timezone: string;
}

const LeadDetailsModal: FC<LeadDetailsModalProps> = (props) => {
  const {
    currentModal,
    lead,
    leadId,
    activityRecords,
    timezone,
    getLeadDetails,
    getActivityRecords,
  } = props;
  function cleanUpAndHide() {
    // Order of firing those functions matter to make sure of not executing the useEffect body twice.
    props.hideModal();
    props.resetState();
  }

  useEffect(() => {
    if (currentModal === NAME && !lead && leadId) {
      getLeadDetails(leadId);
      getActivityRecords(leadId);
    }
  }, [currentModal, lead, leadId, getLeadDetails, getActivityRecords]);

  const goToLead = () => {
    cleanUpAndHide();
    history.push("/secure/manageLeads?lead_id=" + leadId);
  };

  return (
    <Modal show={currentModal === NAME} onHide={cleanUpAndHide}>
      <div className={"block"}>
        <div className="block-header bg-primary">
          <ul className="block-options">
            <li>
              <button
                data-dismiss="modal"
                type="button"
                onClick={cleanUpAndHide}
              >
                <i className="si si-close" style={{ color: "white" }} />
              </button>
            </li>
          </ul>
          <h3 className="block-title" style={{ color: "white" }}>
            {lead?.display_name}
          </h3>
        </div>
        {props.loading && (
          <Box row>
            <div
              className="col-sm-10 col-sm-offset-1"
              style={{ marginTop: "125px" }}
            >
              <EmptyView
                colorThemeClass="primary"
                iconClass="fa fa-circle-o-notch fa-spin fa-3x"
                title="Loading lead ..."
              />
            </div>
          </Box>
        )}
        {!props.loading && lead && (
          <Box padding={space.L}>
            <Text.P2>
              Assigned to <strong> {lead.salesperson_name} </strong>
            </Text.P2>
            <br />
            <Box isFlex flexDirection="row" justifyContent="center">
              <Text.P2 size="50%">
                <i className="fa fa-phone" />{" "}
                {lead.mobile_number ? lead.mobile_number : "N/A"}
              </Text.P2>
              <Text.P2 size="50%">
                <i className="fa fa-envelope" />{" "}
                {lead.email ? lead.email : "N/A"}
              </Text.P2>
            </Box>
            <Box isFlex flexDirection="row" justifyContent="center">
              <Text.P2 size="50%">
                <i className={leadSourceHelpers.icon(lead?.source)} /> Inquired
                via <strong> {lead.source} </strong>
              </Text.P2>
              <Text.P2 size="50%">
                <i className="si si-pointer" />{" "}
                <strong>
                  {pluralizationHelper(Strings.checkIns, lead.visit_count ?? 0)}
                </strong>
              </Text.P2>
            </Box>
            <HistoryView
              historyItems={activityRecords.slice(0, 5)}
              timezone={timezone}
            />
          </Box>
        )}
      </div>
      <Modal.Footer>
        <button className="btn btn-primary pull-right" onClick={goToLead}>
          Go to lead
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeadDetailsModal;
