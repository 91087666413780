import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { errorPanelForErrors } from "utils/formHelpers";
import PagesContainer from "../containers/PagesContainer";
import TableContainer from "../containers/TableContainer";
import {
  ContentBlockView,
  EmptyView,
  TextSearchView
} from "modules/components";

export class AutomaticReportsTableView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchAutomaticReportsWithPage } = this.props;
    fetchAutomaticReportsWithPage(1);
  }

  pageClicked = pageNumber => {
    const { fetchAutomaticReportsWithPage } = this.props;
    fetchAutomaticReportsWithPage(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { fetchAutomaticReportsWithPage } = this.props;
    fetchAutomaticReportsWithPage(1, searchTerm);
  };

  render() {
    const { loading, errors, automaticReports, pageCount } = this.props;
    return (
      <ContentBlockView title="Automatic Reports" loading={loading} bordered>
        {errorPanelForErrors(errors, true)}
        <SearchSection className="row">
          <TextSearchView
            sectionName={"locationSearch"}
            onUpdate={this.searchUpdated}
          />
        </SearchSection>
        <div>
          {automaticReports.length > 0 && (
            <TableContainer automaticReports={automaticReports} />
          )}
          {automaticReports.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesContainer pageClicked={this.pageClicked} />
            </div>
          )}
        </div>
      </ContentBlockView>
    );
  }
}

AutomaticReportsTableView.propTypes = {
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  automaticReports: PropTypes.arrayOf(PropTypes.object)
};

export default AutomaticReportsTableView;

const SearchSection = styled.div`
  margin: -20px 0px 0px 0px;
`;
