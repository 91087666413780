import PropTypes from "prop-types";
import React, { Component } from "react";
import { EmptyView, PagesView } from "modules/components";
import SubscriptionAddOnTableRow from "./SubscriptionAddOnTableRow";

export class SubscriptionAddOnView extends Component {
  componentDidMount() {
    this.getSubscriptionAddOnsWithPageNumber(1);
  }

  getSubscriptionAddOnsWithPageNumber = pageNumber => {
    const { fetchSubscriptionAddOns } = this.props;
    fetchSubscriptionAddOns(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getSubscriptionAddOnsWithPageNumber(pageNumber);
  };

  render() {
    const { loading, subscriptionAddOns, pageDetails } = this.props;
    const blockStyle = loading ? "block-opt-refresh" : "";
    return (
      <div className={"block block-rounded block-bordered " + blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Subscription Add Ons</h3>
        </div>
        <div className="block-content">
          {subscriptionAddOns && subscriptionAddOns.length > 0 && (
            <table className="table table-borderless table-striped table-vcenter table-hover">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionAddOns.map(subscriptionAddOn => {
                  return (
                    <SubscriptionAddOnTableRow
                      subscriptionAddOn={subscriptionAddOn}
                      key={`subAddOn-${subscriptionAddOn.id}`}
                    />
                  );
                })}
              </tbody>
            </table>
          )}
          {pageDetails.pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
          {(!subscriptionAddOns || subscriptionAddOns.length === 0) && (
            <EmptyView
              colorThemeClass={"primary"}
              iconClass={"fa fa-history"}
              title={"No Subscription Add Ons enabled"}
              subtitle={"Optional add ons that are enabled will appear here."}
            />
          )}
        </div>
      </div>
    );
  }
}

SubscriptionAddOnView.propTypes = {
  fetchSubscriptionAddOns: PropTypes.func.isRequired,
  subscriptionAddOns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  errors: PropTypes.object
};

export default SubscriptionAddOnView;
