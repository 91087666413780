import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import turnOffOptIn from '../modules/turnOffOptIn'

function mapStateToProps (state) {
  return {
    loading: turnOffOptIn.selectors.getLoading(state),
    errors: turnOffOptIn.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    turnOffOptInForLocation: turnOffOptIn.actions.turnOffOptInForLocation
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(turnOffOptIn.components.TurnOffOptInView)
