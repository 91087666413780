import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled, { keyframes } from "styled-components";
import search from "modules/Stores/Search";
import * as searchApiActions from "modules/Stores/Search/apiActions";

export class SearchBar extends React.Component {
  handleInput = (event) => {
    event && event.preventDefault();
    const textValue = event.target.value;
    this.props.updateSearchTerm(textValue);
  };

  handleSubmit = (event) => {
    event && event.preventDefault();
    const { searchTerm } = this.props;
    const showingSearch = searchTerm.length > 0 ? true : false;
    this.props.setShowingSearch(showingSearch);
    this.props.searchForTerm(searchTerm);
  };

  render() {
    return (
      <InputContainer>
        <SearchIcon className="fa fa-search" />
        <form onSubmit={this.handleSubmit}>
          <div
            className="form-material form-material-primary input-group"
            style={{ margin: 0, marginTop: -2, width: 300, marginBottom: 5 }}
          >
            <input
              style={{ width: 300 }}
              type="text"
              className="form-control"
              placeholder="Search for a lead"
              onChange={this.handleInput}
              value={this.props.searchTerm}
            />
          </div>
        </form>
        <SearchButton
          className="btn btn-primary"
          onClick={this.handleSubmit}
          isHiding={this.props.searchTerm.length === 0}
        >
          Search
        </SearchButton>
      </InputContainer>
    );
  }
}
function mapStateToProps(state) {
  return { searchTerm: search.selectors.getSearchTerm(state) };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSearchTerm: search.actions.setSearchTerm,
      searchForTerm: searchApiActions.getSearchResults,
      setShowingSearch: search.actions.setShowingSearch,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

const SearchIcon = styled.i`
  margin-right: 4px;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const SearchButton = styled.button`
  margin-left: 4px;
  color: #fff;
  visibility: ${(props) => (props.isHiding ? "hidden" : "visible")};
  animation: ${(props) => (props.isHiding ? fadeOut : fadeIn)} 0.25s linear;
  transition: visibility 0.25s linear;
`;
