import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class LeadStatusTag extends Component {

  humanReadableStatus (status) {
    if (!status) { return }
    const updatedStatus = status.replace(/_/g, ' ')
    return updatedStatus.charAt(0).toUpperCase() + updatedStatus.slice(1)
  }

  render () {
    const { leadStatus } = this.props
    let color = ''
    switch (leadStatus) {
      case 'active':
        color = '#70b9eb'
        break
      case 'inactive':
        color = '#f3b760'
        break
      case 'closed':
        color = '#46c37b'
        break
      case 'not_assigned':
        color = '#ff8f8f'
        break
      case 'lost':
        color = '#d26a5c'
        break
      default:
        break
    }

    return (
      <span className={'label'} style={{ backgroundColor: color }}>{this.humanReadableStatus(leadStatus)}</span>
    )
  }
}

LeadStatusTag.propTypes = {
  leadStatus: PropTypes.string.isRequired
}
