import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ExportLeadView from '../components/ExportLeadView'
import * as exportActions from '../actions'
import * as selectors from '../selectors'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  return {
    locationId: props.lead.location_id,
    formPostData: selectors.getFormPostData(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    exportLead: exportActions.exportLead,
    exportLeadAsCSV: exportActions.exportLeadAsCSV,
    getPostUrlAndData: exportActions.getPostUrlAndData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportLeadView)
