import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SmsAccountTableRow } from './SmsAccountTableRow'

export class SmsAccountTableView extends Component {

  render () {
    const { smsAccounts, onSmsAccountSelect, selectedSmsAccountId } = this.props
    const rows = smsAccounts.map((smsAccount, index) => {
      const selected = (smsAccount.id === selectedSmsAccountId)
      return <SmsAccountTableRow
        key={index}
        smsAccount={smsAccount}
        onSmsAccountSelect={onSmsAccountSelect}
        selected={selected} />
    })

    return (
      <div style={{ marginTop: '-20px' }}>
        <table className='table table-vcenter table-hover'>
          <thead>
            <tr>
              <th>Name</th>
              <th className='text-center'>Balance</th>
              <th className='text-center'>Auto recharge</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

SmsAccountTableView.propTypes = {
  smsAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSmsAccountSelect: PropTypes.func.isRequired,
  selectedSmsAccountId: PropTypes.number
}

SmsAccountTableRow.defaultProps = {
  showSaleDetails: true
}

export default SmsAccountTableView
