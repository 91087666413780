import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import HeardAboutMethodSelectView from 'modules/components/HeardAboutMethodSelect/components/HeardAboutMethodSelectView';

export class HeardAboutMethodSelectModal extends Component {
  render() {
    const { currentModal, modalName, hideModal, onHeardAboutMethodSelect } = this.props;

    const onClear = () => {
      onHeardAboutMethodSelect(null);
    };

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select heard about method
            </h3>
          </div>
          <div className="block-content">
            <HeardAboutMethodSelectView {...this.props} />
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={onClear}>
            Clear
          </button>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

HeardAboutMethodSelectModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  currentModal: PropTypes.string,
  title: PropTypes.string,
  getHeardAboutMethods: PropTypes.func.isRequired,
  heardAboutMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHeardAboutMethodSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedHeardAboutMethodId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element,
};

export default HeardAboutMethodSelectModal;
