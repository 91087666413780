import { request, POST } from 'utils/apiUtils';
import { history } from 'store/store';
import * as actionTypes from './actionTypes';

export const updateArticle = (articleId, name, newName, newNameAgain, totp) => {
  return (dispatch) => {
    const body = {
      articles: {
        id: articleId,
        name: name,
        new_name: newName,
        new_name_again: newNameAgain,
        totp: totp,
      },
    };

    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      history.push('/');
    }

    return request('update_article', POST, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}
