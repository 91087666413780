import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import todos from "../modules/todos";

import modal from "modules/Stores/Modal";
const { toggleCompleteTodoForLead, deleteTodoForLead } = todos.actions;
const { showModal } = modal.actions;

function mapStateToProps(state, ownProps) {
  const { pathToLeadDetail } = ownProps;
  return {
    todoItems: todos.selectors.getTodos(state, pathToLeadDetail),
    loading: todos.selectors.getLoading(state, pathToLeadDetail),
    user: state.session.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleCompleteTodoForLead,
      deleteTodoForLead,
      showModal,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(todos.components.LeadTodosView);
