import { FormFields } from 'modules/components';
import * as sessionHelpers from 'modules/Stores/Session/helpers';
import React, { Component } from 'react';
import { generateNewFormState } from 'utils/formHelpers';

const { Switch } = FormFields;

export class PersonalizationForm extends Component {
  UNSAFE_componentWillMount() {
    const { user, prefillFormForUser } = this.props;
    prefillFormForUser(user);
  }

  handleToggle = (fieldName, value) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, true);
    this.props.onInput(newData);
  };

  saveSelected = () => {
    const { updateUser, data } = this.props;
    updateUser(data);
  };

  render() {
    const { loading, user } = this.props;
    const { showOwnDataByDefault } = this.props.data.fields;
    const blockStyle = loading ? 'block-opt-refresh' : '';
    const canViewOtherLeads = sessionHelpers.userCanViewOthersLeads(user);
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle}>
          <div className="block-header">
            <h3 className="block-title">Personalization</h3>
          </div>
          <div className="block-content">
            <form className="form-horizontal push-5-t">
              <div>
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="block-title">Show only your leads by default</h3>
                    <Switch
                      name="showOwnDataByDefault"
                      label="View only your leads in Gymleads by default."
                      onChange={this.handleToggle}
                      switchStyle="switch-sm switch-primary"
                      value={showOwnDataByDefault.value}
                      disabled={!canViewOtherLeads}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <button className="btn btn-primary push-5-r push-10" type="button" onClick={this.saveSelected}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalizationForm;
