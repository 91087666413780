import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import '../Header.css';
import logo from 'images/logo.png';

import session from 'modules/Stores/Session';
import search from 'modules/Stores/Search';
import userData from 'modules/Stores/UserData';
import SearchBar from 'modules/components/Search';
import ResultsView from 'modules/components/Search/ResultsView';

import AppSelectContainer from '../containers/AppSelectContainer';
import NotificationsContainer from 'modules/components/HeaderNotifications/containers/NotificationsContainer';
import STRINGS from 'strings';

class LoggedInHeader extends Component {
  updateClicked = () => {
    window.location.reload();
  };

  setLogo() {
    const { whitelabelProfile } = this.props;
    if (whitelabelProfile && whitelabelProfile.whitelabel_profile_logo) {
      return whitelabelProfile.logo_url;
    } else {
      return logo;
    }
  }

  handleLogoutClick = () => {
    const { logout, whitelabelProfile } = this.props;
    const safeName = whitelabelProfile && whitelabelProfile.safe_name ? whitelabelProfile.safe_name : null;
    logout(safeName);
  };

  render() {
    const { user, company, isSearching, isMobile } = this.props;
    const userCanUseMarketing = session.helpers.userCanUseMarketing(user);
    const height = isSearching ? '100%' : 'auto';
    return (
      <header id="header-navbar" style={{ height: height }}>
        <div className="content-mini content-mini-full content-boxed">
          {user && (
            <ul className="nav-header pull-right">
              <li>
                <div className="btn-group">{<NotificationsContainer />}</div>
              </li>
              <li>
                <button
                  id="log-out-button"
                  className="linkButton"
                  onClick={this.handleLogoutClick}
                  style={{
                    padding: '0 12px',
                    display: 'block',
                    height: '34px',
                    lineHeight: '34px',
                    fontWeight: '600',
                    color: '#5c90d2',
                  }}
                >
                  {STRINGS.Global.logOut}
                </button>
              </li>
            </ul>
          )}
          <ul className="nav-header pull-left">
            <li>
              <img src={this.setLogo()} alt="logo" style={{ width: '120px' }} />
            </li>
            <li className="push-5-t">{company?.use_new_marketing && userCanUseMarketing && <AppSelectContainer />}</li>
            {user && <li className="push-5-t">Logged in as {user.display_name}</li>}
            {!isMobile && (
              <li>
                <SearchBar />
              </li>
            )}
          </ul>
        </div>
        {isSearching && <ResultsView />}
      </header>
    );
  }
}

LoggedInHeader.propTypes = {
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isSearching: search.selectors.getShowingSearch(state),
    company: userData.selectors.getCompany(state),
    isMobile: state.session.isMobile,
  };
}

export default connect(mapStateToProps)(LoggedInHeader);
