import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import calendar from "modules/components/Calendar";
import locationSelect from "../modules/locationSelect";
import userSelect from "../modules/userSelect";
import createTask from "../modules/createTask";
import modal from "modules/Stores/Modal";
import session from "modules/Stores/Session";

function mapStateToProps(state) {
  const selectedLocationId = locationSelect.selectors.getSelectedLocationId(
    state
  );
  const selectedUsers = userSelect.selectors.getSelectedUsers(state);
  const usersToUse =
    selectedUsers.length > 0
      ? selectedUsers
      : [session.selectors.getUser(state)];
  return {
    events: calendar.selectors.getEvents(state),
    timezone: session.selectors.getUserTimezone(state),
    locationId: selectedLocationId,
    users: usersToUse,
    view: calendar.selectors.getView(state),
    calculatedDateRange: calendar.selectors.calculatedDateRange(state),
    showCompletedOnly: locationSelect.selectors.showCompletedOnly(state),
    eventTypes: userSelect.selectors.getTypes(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadAllEventsForUsersInDateRange:
        calendar.actions.loadAllEventsForUsersInDateRange,
      selectEventId: calendar.actions.selectEventId,
      showModal: modal.actions.showModal,
      updateDate: calendar.actions.setDate,
      setView: calendar.actions.setView,
      setTaskDetails: createTask.actions.setTaskDetails,
      updateEventTime: calendar.actions.updateEventTime,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(calendar.components.CalendarView);
