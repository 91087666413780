import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import session from 'modules/Stores/Session'
import subscribe from '../modules/subscribe'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    isMobile: session.selectors.getIsMobile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(subscribe.components.SubscribeView)
