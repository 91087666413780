import React from "react";
import styled from "styled-components";
import { FormFields } from "modules/components";
import { OpeningHoursValidator } from "../validators";

const { TextField } = FormFields;

const FormView = ({ handleChange, updateOpeningHours, state }) => {
  const { weekday, weekend } = state;

  return (
    <Container>
      <FormContainer className="row">
        <FormColumn className="col-sm-12">
          <FormColumn className="col-sm-12">
            <TextFieldContainer>
              <TextField
                name="weekday"
                label="Weekday: Monday - Friday Hours"
                placeholder=""
                validator={OpeningHoursValidator}
                onChange={handleChange}
                value={weekday}
              />
            </TextFieldContainer>
          </FormColumn>
          <FormColumn className="col-sm-12">
            <TextFieldContainer>
              <TextField
                name="weekend"
                label="Weekend: Saturday and Sunday Hours"
                placeholder=""
                validator={OpeningHoursValidator}
                onChange={handleChange}
                value={weekend}
              />
            </TextFieldContainer>
          </FormColumn>
        </FormColumn>
      </FormContainer>
      <FooterContainer className="row">
        <ButtonContainer className="col-sm-12">
          <button
            className="btn btn-primary"
            type="button"
            onClick={updateOpeningHours}
          >
            <i className="fa fa-building-o" /> Update Opening Hours
          </button>
        </ButtonContainer>
      </FooterContainer>
    </Container>
  );
};

export default FormView;

const Container = styled.div``;
const FormContainer = styled.div``;
const TextFieldContainer = styled.div``;
const ButtonContainer = styled.div``;

const FooterContainer = styled.div`
  padding: 20px 0px 20px 0px;
`;

const FormColumn = styled.div`
  padding-left: 0px !important;
  margin-top: -5px !important;
`;
