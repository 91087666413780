import * as actionTypes from './actionTypes'

const MERGE_TAGS_ACTION_HANDLERS = {
  [actionTypes.SET_MERGE_TAGS]: (state, action) => {
    return { ...state, mergeTags: action.mergeTags }
  }
}

const initialState = {
  mergeTags: []
}

export default function reducer (state = initialState, action) {
  const handler = MERGE_TAGS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
