import * as actionTypes from "./actionTypes";

const SALES_PERFORMANCE_PRESENTATIONS_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return { ...state, loading: true, errors: null };
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return { ...state, loading: false, data: action.data };
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors };
  },
};

const initialState = {
  errors: null,
  loading: false,
  data: {
    presentations_due_count: { current: 0, change: 0 },
    presentations_count: { current: 0, change: 0 },
    appointment_to_presentation_ratio: { current: 0, change: 0 },
    presentation_sales_count: { current: 0, change: 0 },
    presentation_close_ratio: { current: 0, change: 0 },
    a1_close_rate: { current: 0, change: 0 },
    a2_close_rate: { current: 0, change: 0 },
    a3_close_rate: { current: 0, change: 0 },
  },
};

export default function reducer(state = initialState, action) {
  const handler = SALES_PERFORMANCE_PRESENTATIONS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
