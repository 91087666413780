import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView } from 'modules/components'

export class CreateSalesTargetView extends Component {

  createClicked = () => {
    const { createSalesTargetForLocationUser, location, user, setSalesTarget } = this.props
    createSalesTargetForLocationUser(location, user, setSalesTarget)
  }

  title = () => {
    const { user } = this.props
    switch (user.user_role.name) {
      case 'Salesperson':
        return 'Using Defaults'
      default:
        return 'No sales targets'
    }
  }

  subtitle = () => {
    const { user, location } = this.props
    switch (user.user_role.name) {
      case 'Salesperson':
        return user.given_name + ' is using the default sales target for ' + location.admin_display_name +
        '. Click below to create specific rules for them'
      default:
        return user.given_name + ' is not a salesperson, so we don\'t track their sales performance by default. ' +
        'If you want to set specific targets for them, click the button below.'
    }
  }

  render () {
    const { loading, user, location } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Sales Targets for { user.display_name } at {location.admin_display_name}</h3>
          </div>
          <div className='block-content push'>
            <EmptyView
              colorThemeClass='primary'
              iconClass='si si-target'
              title={this.title()}
              subtitle={this.subtitle()}
              buttonText='Create Unique Sales Targets'
              buttonSelected={this.createClicked} />
          </div>
        </div>
      </div>
    )
  }
}

CreateSalesTargetView.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  createSalesTargetForLocationUser: PropTypes.func.isRequired,
  setSalesTarget: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default CreateSalesTargetView
