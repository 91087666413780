import React, { useState, useEffect } from 'react';

import { ImageEmptyView, TextSearchView, PagesView, GymLeadsLoader } from 'modules/components';
import EmptyImage from 'images/HtmlTemplatesEmpty.png';

import SelectTemplateCell from './SelectTemplateCell';
import './styles.css';

export const QuickSendView = (props) => {
  const [search, setSearch] = useState(undefined);

  const { emailTemplates, backSelected, cancelSelected, pageDetails, loading, getEmailTemplates, onTemplateSelect } = props;

  useEffect(() => {
    getEmailTemplates('html', 1);
  }, [getEmailTemplates]);

  const updateSearch = (newSearchTerm) => {
    setSearch(newSearchTerm);
    getEmailTemplates('html', 1, newSearchTerm);
  };

  const handlePageClicked = (newPageNumber) => {
    getEmailTemplates('html', newPageNumber, search);
  };

  const templateSelected = (template) => {
    onTemplateSelect(template);
  };

  const pageCount = pageDetails.pageCount;
  const cells = emailTemplates.map((template, index) => {
    return <SelectTemplateCell key={index} template={template} onSelect={templateSelected} />;
  });

  return (
    <div>
      <div className="block-content">
        <h3 className="push-10">Choose a Template To Get Started</h3>
        <div className="row push">
          <div className="col-xs-12">
            <TextSearchView sectionName={'emailTemplateSearch'} onUpdate={updateSearch} />
          </div>
        </div>
        {!loading && emailTemplates.length === 0 && (
          <ImageEmptyView
            title={'You Have No Templates Yet'}
            subtitle={'HTML templates created in the marketing app will appear here.'}
            image={EmptyImage}
          />
        )}
        <GymLeadsLoader active={loading} text="Getting HTML Templates" />
        {emailTemplates.length > 0 && !loading && <div className="designs-list">{cells}</div>}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView pageClicked={handlePageClicked} pageDetails={props.pageDetails} />
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button className="btn btn-primary push-10 pull-left" onClick={backSelected}>
          <i className="fa fa-angle-left" /> Back to Quick Send
        </button>
        <button className="btn btn-default push-5-r push-10" type="button" onClick={cancelSelected}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default QuickSendView;
