import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

export const updateUser = (formData) => {
  return (dispatch) => {
    const body = {
      user: seralizeFormData(formData),
    };
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess() {
      dispatch(loadSuccessful());
      notify.success('Personalization updated');
    }

    return request('users/' + formData.id, PUT, body, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccessful(salesTarget) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    salesTarget,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

export function prefillFormForUser(user) {
  return {
    type: actionTypes.PREFILL_FORM,
    user,
  };
}

function seralizeFormData(formData) {
  const { showOwnDataByDefault } = formData.fields;

  let payload = { id: formData.id };
  payload = newPayloadForFormValue(payload, showOwnDataByDefault, showOwnDataByDefault.id);
  return payload;
}
