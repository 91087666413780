import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SELECT_REGIONS_MODAL } from '../constants'
import RegionsSection from '../components/RegionsSection'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, props) {
  const regionIdsName = "region_ids";
  const regionIdsData = props.filterData && props.filterData[regionIdsName] ? props.filterData[regionIdsName] : { fields: {} }
  const regionsCount = Object.keys(regionIdsData.fields).length;
  const regionsTitle = regionsCount > 0 ? `${regionsCount} selected` : "All regions";
  const regionFiltersClass = regionsCount > 0 ? "btn btn-info" : "btn btn-default";

  const locationIdsName = "location_ids";
  const locationIdsData = props.filterData && props.filterData[locationIdsName] ? props.filterData[locationIdsName] : { fields: {} }

  return {
    regionIdsName: regionIdsName,
    SELECT_REGIONS_MODAL: SELECT_REGIONS_MODAL,
    regionIdsData: regionIdsData,
    regionsTitle: regionsTitle,
    regionFiltersClass: regionFiltersClass,
    locationIdsData: locationIdsData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionsSection)
