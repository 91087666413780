import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormFields } from "modules/components";
import { generateNewFormState, errorPanelForErrors } from "utils/formHelpers";
import { InviteTeamMemberValidator } from "./validators";
import "./inviteTeamMember.css";

const { TextField, SelectField } = FormFields;

export class InviteTeamMemberForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newSectionData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.updateInviteData(newSectionData);
  };

  render() {
    const { email, givenName, role, errors } = this.props.data.fields;
    const { userRoles } = this.props;

    return (
      <div>
        {errorPanelForErrors(errors)}
        <form className="form-horizontal push-5-t">
          <TextField
            name="givenName"
            label="First name"
            placeholder="Janet"
            onChange={this.handleInput}
            validator={InviteTeamMemberValidator}
            initialValue={givenName.value}
          />
          <TextField
            name="email"
            label="Email"
            placeholder="their.email@example.com"
            onChange={this.handleInput}
            validator={InviteTeamMemberValidator}
            initialValue={email.value}
          />
          <SelectField
            name="role"
            label="Role"
            possibleOptions={userRoles}
            displayFieldName={"name"}
            formFieldStyle=""
            placeHolderText="Select a role"
            onChange={this.handleInput}
            validator={InviteTeamMemberValidator}
            value={role.value}
          />
        </form>
      </div>
    );
  }
}

InviteTeamMemberForm.propTypes = {
  data: PropTypes.object.isRequired,
  updateInviteData: PropTypes.func.isRequired,
  sendInvite: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
