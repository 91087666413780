import * as actionTypes from './actionTypes'
import teamMembers from './modules/teamMembers'

import invites from './modules/invite'
const { getPendingInvitesForLocation } = invites.actions

export function selectLocation (location) {
  return dispatch => Promise.all([
    dispatch(locationSelected(location)),
    dispatch(teamMembers.actions.selectUserId(null)),
    dispatch(getPendingInvitesForLocation(location))
  ])
}

export function locationSelected (location) {
  return {
    type: actionTypes.LOCATION_SELECTED,
    location
  }
}
