import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { InfoView } from 'modules/components'

import { errorPanelForErrors } from 'utils/formHelpers'

import SecurityPolicyFormView from './SecurityPolicyFormView'

export class SecurityPolicyView extends Component {

  UNSAFE_componentWillMount () {
    const { securityPolicy, prefillForm } = this.props
    prefillForm(securityPolicy)
  }

  saveClicked = () => {
    const { securityPolicy, saveSecurityPolicy, data } = this.props
    saveSecurityPolicy(securityPolicy.id, data)
  }

  render () {
    const { loading, errors, data, onInput } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <InfoView
          title='Security Policy'
          colorThemeClass='info'>
          <p>
            Set up security rules that dictate how users can keep their accounts secure.
          </p>
        </InfoView>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Your Account Security Policy</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <SecurityPolicyFormView
                data={data}
                onInput={onInput}
                onSaveSelected={this.saveClicked} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

SecurityPolicyView.propTypes = {
  saveSecurityPolicy: PropTypes.func.isRequired,
  prefillForm: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  securityPolicy: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default SecurityPolicyView
