import logo from 'images/logo.png';
import bookerLogo from './partnerlogos/booker-logo.png';
import calendlyLogo from './partnerlogos/calendly_logo.png';
import clubmanagerLogo from './partnerlogos/clubmanager-logo.svg';
import fblogo from './partnerlogos/fblogo.png';
import glofoxLogo from './partnerlogos/glofox-logo.svg';
import GymMasterLogo from './partnerlogos/gymmasterlogo.png';
import journeyLogo from './partnerlogos/journeyLogoFull.png';
import myMALogo from './partnerlogos/myma@2x.png';
import ofsLogo from './partnerlogos/ofsLogo.png';
import pgLogo from './partnerlogos/perfect_gym.png';
import MindBodyLogo from './partnerlogos/powered_by_mindbody.png';
import snappLogo from './partnerlogos/snap-logo.png';

export function titleForOption(option) {
  switch (option) {
    case 'csv':
      return 'CSV';
    case 'portabilityExport':
      return 'JSON Portability Export';
    case 'myma':
      return 'myMa';
    case 'gymmaster':
      return 'Gym Master';
    case 'booker':
      return 'Booker';
    case 'glofox':
      return 'Glofox';
    case 'mindbody':
      return 'MINDBODY';
    case 'facebook':
      return 'Facebook';
    case 'facebook_lead_adds':
      return 'Facebook Lead Adds';
    case 'gymleads_hosted_inqury_form':
      return 'Hosted Inquiry Form';
    case 'email':
      return 'Email to Lead';
    case 'one_fit_stop':
      return 'OneFitStop';
    case 'perfect_gym':
      return 'PerfectGym';
    case 'snapp_dd':
      return 'SnapDD';
    case 'clubmanager':
      return 'ClubManager';
    case 'gymvue':
      return 'GymVue';
    case 'calendly':
      return 'Calendly';
    case 'journey':
      return 'Journey';
    default:
      return '';
  }
}

export function detailForOption(option, location) {
  switch (option) {
    case 'csv':
      return 'Directly download leads as a CSV for upload to another system';
    case 'portabilityExport':
      return 'Directly download a GDPR compliant JSON object';
    case 'myma':
      return (
        'Export your closed leads to the myMA member management system. You can get an API token from ' +
        'from myMA support. your client ID can be found in your member management portal. Let us know if you need a hand!'
      );
    case 'gymmaster':
      return (
        'Export your closed leads to the Gym Master member management system. You can get an API token from ' +
        'from GymMaster support. Your GymMaster URL will be something ' +
        'like https://yourgymname.gymmasteronline.com/. Let us know if you need a hand!'
      );
    case 'booker':
      return 'Export your closed leads to the Booker system. Your Client ID and Client Secret tokens can be found in your member management portal. Let us know if you need a hand!';
    case 'mindbody':
      return (
        'Export your closed leads to the MINDBODY member management system as new clients. Your Club ID will ' +
        'be used by GymLeads to link the lead to the right club.'
      );
    case 'facebook_lead_adds':
      return 'Capture new leads from your Facebook Lead adds campaign';
    case 'gymleads_hosted_inqury_form':
      return (
        'GymLeads hosts an inqury form for your website customers to directly enter details into. ' +
        'Your clubs unique url to link to is: /inqury?location_id=' +
        location.public_uuid
      );
    case 'email':
      return (
        'If you have forms that send you new leads by email, we can provide you with a specilized email addres ' +
        'to send the leads to. We then create a lead based on the content of the email.\n\n We need to set this up for ' +
        "you manually at the moment. If you want to use this feature, get in touch with us and we'll set it up for you."
      );
    case 'facebook':
      return 'Connect to your faceboook business page and capture users that send you messages as new leads in GymLeads.';
    case 'one_fit_stop':
      return (
        'Export your closed leads to the OneFitStop member mamangement system. ' +
        'Find your locations API key in your settings or contact OneFitStop support.'
      );
    case 'perfect_gym':
      return (
        'Export your closed leads to the PerfectGym member management system. ' +
        "You'll need to request a special API user from Perfect Gym before continuing."
      );
    case 'glofox':
      return (
        'Connect Gymleads to the Glofox member management system. ' +
        "You'll need to request Glofox to enable your integration access, who will also supply a Branch ID and Namespace to enter during your GymLeads setup."
      );
    case 'snapp_dd':
      return (
        'Prefill your Snapp DD sign up form with a leads details. You will need your snapp URL (it will be' +
        ' something) like https://www.snapdda.co.uk/clients/happygym/HappyGymInClub_prepopulate.aspx. If you know' +
        ' your location id value you can enter that in as well.'
      );
    case 'calendly':
      return 'Connenct your Calendly account to Gymleads and have appointments automatically created/cancelled.';
    case 'clubmanager':
      return 'Connenct your ClubManager account to Gymleads and export closed leads to the ClubManager system.';
    case 'gymvue':
      return 'Connenct your GymVue account to Gymleads and export closed leads to the GymVue system.';
    case 'journey':
      return 'Enable powerful, seemless data sharing between your Journey and GymLeads accounts. You can connect your GymLeads account in your Journey clubs settings.';
    default:
      return '';
  }
}

export function iconForOption(option) {
  switch (option) {
    case 'csv':
    case 'portabilityExport':
      return 'glyphicon glyphicon-export';
    case 'calendly':
      return 'si si-calendar';
    case 'myma':
    case 'mindbody':
    case 'gymmaster':
    case 'booker':
    case 'glofox':
    case 'one_fit_stop':
    case 'perfect_gym':
    case 'snapp_dd':
    case 'clubmanager':
    case 'gymvue':
      return 'si si-home';
    case 'facebook_lead_adds':
      return 'si si-social-facebook';
    case 'gymleads_hosted_inqury_form':
      return 'fa fa-wpforms';
    case 'email':
      return 'si si-envelope';
    case 'facebook':
      return 'fa fa-facebook';
    default:
      return '';
  }
}

export function logoForOption(option) {
  switch (option) {
    case 'mindbody':
      return MindBodyLogo;
    case 'myma':
      return myMALogo;
    case 'gymmaster':
      return GymMasterLogo;
    case 'gymleads_hosted_inqury_form':
      return logo;
    case 'booker':
      return bookerLogo;
    case 'glofox':
      return glofoxLogo;
    case 'email':
      return logo;
    case 'facebook':
      return fblogo;
    case 'one_fit_stop':
      return ofsLogo;
    case 'perfect_gym':
      return pgLogo;
    case 'snapp_dd':
      return snappLogo;
    case 'calendly':
      return calendlyLogo;
    case 'clubmanager':
      return clubmanagerLogo;
    case 'journey':
      return journeyLogo;
    default:
      return null;
  }
}
