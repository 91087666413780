import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import selectCSV from '../modules/selectCSV'
import uploadCSV from '../modules/uploadCSV'
import configureImport from '../modules/configureImport'
import fieldMapping from '../modules/fieldMapping'
import { getTabIndex } from '../selectors'

function mapStateToProps (state) {
  const activeTab = getTabIndex(state)
  return {
    isActive: (activeTab === 3),
    importOptions: configureImport.selectors.getImportOptions(state),
    csvFile: selectCSV.selectors.getCSV(state),
    locationId: selectCSV.selectors.getLocationId(state),
    loading: uploadCSV.selectors.getLoading(state),
    headers: configureImport.selectors.getHeaders(state),
    salespeopleMaps: fieldMapping.selectors.getSalespeopleMaps(state),
    statusMaps: fieldMapping.selectors.getStatusMaps(state),
    lawfulProcessingBasesMap: fieldMapping.selectors.getLawfulProcessingBasesMaps(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    uploadCsvFile: uploadCSV.actions.uploadCsvFile
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(uploadCSV.components.UploadCsvView)
