import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Reports from 'modules/components/Reports'

import { getReportSummaryData, getReportData, getLoading } from '../selectors'

function mapStateToProps (state) {
  const headerNamesAndKeys = Reports.selectors.headerNamesAndKeysFactory(getReportSummaryData)
  return {
    data: getReportData(state),
    loading: getLoading(state),
    headers: headerNamesAndKeys(state),
    title: 'Lead Allocation Breakdown'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports.components.TableView)
