import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert'

export class ButtonsView extends Component {

  state = {
    showDanger: false
  }

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true })
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false })
  }

  deleteIntegration = () => {
    this.dismissPopups()
    const { deleteIntegration, data, location } = this.props
    deleteIntegration(data, location)
  }

  cancel = () => {
    this.props.hideModal()
  }

  nextClicked = () => {
    const { location, data, addOrUpdateOptionWithData } = this.props
    addOrUpdateOptionWithData(data, location, 3)
  }

  render () {
    const { nextIsReady } = this.props

    const nextDisabledStyle = (nextIsReady) ? '' : 'disabled'

    return (
      <div className='row'>
        <div className='col-xs-3 text-left'>
          <button className='wizard-prev btn btn-danger' onClick={this.showDangerPopup}>
            Delete
          </button>
        </div>
        <div className='col-xs-3 text-left'>
          <button className='wizard-prev btn btn-default' onClick={this.cancel}>
            Cancel
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          <button className={'wizard-next btn btn-default ' + nextDisabledStyle}
            disabled={!nextIsReady}
            onClick={this.nextClicked}>
            Next <i className='fa fa-arrow-right' />
          </button>
        </div>
        {this.state.showDanger &&
          <SweetAlert
            danger
            showCancel
            cancelBtnBsStyle='default'
            confirmBtnBsStyle='danger'
            confirmBtnText='Delete Integration'
            title='Are you Sure?'
            onConfirm={this.deleteIntegration}
            onCancel={this.dismissPopups}>
            Are you sure you want to cancel integrating Perfect Gym?
          </SweetAlert>
        }
      </div>
    )
  }
}

ButtonsView.propTypes = {
  deleteIntegration: PropTypes.func.isRequired,
  addOrUpdateOptionWithData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  nextIsReady: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
}

export default ButtonsView
