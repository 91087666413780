import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import BreakdownView from '../components/BreakdownView'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    breakdownDataSets: selectors.breakdownDataSets(state),
    breakdownPieChartData: selectors.breakdownPieChartData(state),
    tableData: selectors.breakdownTableData(state),
    labels: selectors.dataLabels(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakdownView)
