import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { App } from '../components/AppView';
import session from 'modules/Stores/Session';
import * as selectors from '../selectors';

function mapStateToProps(state, props) {
  return {
    user: session.selectors.getUser(state),
    shouldShowMenu: selectors.shouldShowMenu(state),
  };
}

export default withRouter(connect(mapStateToProps)(App));
