import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import StepNumberView from "modules/components/StepNumberView/StepNumberView";
import { toolTipSelector } from "../tooltips";

export class SalesView extends Component {
  UNSAFE_componentWillMount() {
    const { fetchWithQueryString, queryString, calculatingOption } = this.props;
    fetchWithQueryString(queryString, calculatingOption);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.queryString !== this.props.queryString ||
      nextProps.calculatingOption !== this.props.calculatingOption
    ) {
      const {
        fetchWithQueryString,
        queryString,
        calculatingOption,
      } = nextProps;
      fetchWithQueryString(queryString, calculatingOption);
    }
  }

  render() {
    const { data, loading, calculatingOption } = this.props;
    const blockStyle = loading
      ? "block block-rounded block-bordered block-opt-refresh"
      : "block block-rounded block-bordered";
    const tooltips = toolTipSelector(calculatingOption);

    return (
      <Container className={blockStyle}>
        <div className="block-header">
          <h3 className="block-title">Sales</h3>
        </div>
        <BlockContent className="block-content">
          <div className="row">
            <Column className="col-sm-2 col-sm-offset-1">
              <StepNumberView
                title="Total new leads"
                number={data.total_new_leads.current}
                percentage={data.total_new_leads.change}
                tooltipId={"totalNewLeadsDescription"}
                tooltipText={tooltips.totalNewLeadsDescription}
              />
            </Column>
            <Column className="col-sm-2">
              <StepNumberView
                title="Total sales"
                number={data.total_sales.current}
                percentage={data.total_sales.change}
                tooltipId={"totalSalesDescription"}
                tooltipText={tooltips.totalSalesDescription}
              />
            </Column>
            <BorderedColumn className="col-sm-2">
              <StepNumberView
                title="Close ratio"
                number={data.close_ratio.current * 100}
                suffix={"%"}
                percentage={data.close_ratio.change}
                tooltipId={"closeRatioDescription"}
                tooltipText={tooltips.closeRatioDescription}
              />
            </BorderedColumn>

            <Column className="col-sm-2">
              <StepNumberView
                title="Referrals from POS"
                number={data.referals_from_pos.current}
                percentage={data.referals_from_pos.change}
                tooltipId={"refferalsFromPOSDescription"}
                tooltipText={tooltips.refferalsFromPOSDescription}
              />
            </Column>
            <Column className="col-sm-2">
              <StepNumberView
                title="Referrals per sale"
                number={data.referals_per_sale.current}
                percentage={data.referals_per_sale.change}
                tooltipId={"refferalsPerSaleDescription"}
                tooltipText={tooltips.refferalsPerSaleDescription}
              />
            </Column>
          </div>
        </BlockContent>
      </Container>
    );
  }
}

SalesView.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  fetchWithQueryString: PropTypes.func.isRequired,
  queryString: PropTypes.string,
};

export default SalesView;

const Container = styled.div``;

const Column = styled.div``;
const BorderedColumn = styled.div`
  border-right: 1px solid #e9e9e9;
`;

const BlockContent = styled.div`
  padding-bottom: 40px;
`;
