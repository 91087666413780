import { apiClient } from 'utils/apiClient';
import { ContactCallOutcome, IContactCall, ICalenderableResponse } from 'types';

type CallResponse = {
  contact_call: IContactCall;
};

async function completeCall(
  leadId: number,
  contactCallId: number,
  outcome: ContactCallOutcome,
  completedOn?: Date,
  note?: string,
  asFeedItem?: boolean,
  ignoreEmail?: boolean,
  ignoreSms?: boolean,
  respondAsCalendarEvent?: boolean
) {
  const url = `leads/${leadId}/contact_calls/${contactCallId}`;

  const body = {
    contact_call: {
      outcome: outcome,
      completed_on: completedOn,
      note: note,
    },
    ignore_sms: ignoreSms,
    ignore_email: ignoreEmail,
    as_feed_item: asFeedItem,
    as_calendar_event: respondAsCalendarEvent,
  };

  const response = await apiClient().put(url, body);

  return response.data as Promise<ICalenderableResponse<null> | CallResponse>;;
}

async function createContactCallForLead(leadId: number, dueDate: Date, length: number, respondAsCalendarEvent: boolean) {
  const url = `leads/${leadId}/contact_calls`;

  const body = {
    contact_call: {
      due_date: dueDate,
      length: length,
    },
    as_calendar_event: respondAsCalendarEvent,
  };
  const response = await apiClient().post(url, body);

  return response.data as Promise<ICalenderableResponse<null> | CallResponse>;
}

export { completeCall, createContactCallForLead };
