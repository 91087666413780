import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EmailDomainView from '../components/EmailDomainView'

import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    domains: selectors.getDomains(state),
    selectedDomain: selectors.selectedDomain(state),
    selectedDomainId: selectors.getSelectedDomainId(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getMailDomains: actions.getMailDomains,
    setSelectedDomainId: actions.setSelectedDomainId
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailDomainView)
