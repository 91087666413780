import * as actionTypes from './actionTypes'

const LEAD_NOTES_ACTION_HANDLERS = {
  [actionTypes.FETCHING_NOTES]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.FETCHING_NOTES_SUCCESS]: (state, action) => {
    return { ...state, loading: false, notes: action.notes }
  },
  [actionTypes.FETCHING_NOTES_FAILURE]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.SAVE_NOTE_SUCCESS]: (state, action) => {
    const notes = [
      action.note,
      ...state.notes]
    return { ...state, notes: notes }
  },
  [actionTypes.UPDATE_NOTE_SUCCESS]: (state, action) => {
    const newNote = action.note
    const oldNotes = state.notes
    const index = indexForNote(oldNotes, newNote.lead_note_id)
    const newNotes = [
      ...oldNotes.slice(0, index),
      newNote,
      ...oldNotes.slice(index + 1)
    ]
    return { ...state, notes: newNotes }
  }
}

const initialState = {
  loading: false,
  notes: []
}

function indexForNote (notes, noteId) {
  return notes.findIndex(note => note.lead_note_id === noteId)
}

export default function reducer (state = initialState, action) {
  const handler = LEAD_NOTES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
