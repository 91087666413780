import { NAME } from './constants'

export const RESET = NAME + '/RESET'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const SET_LOADING = NAME + '/SET_LOADING'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_AUTOMATIC_REPORTS = NAME + '/SET_AUTOMATIC_REPORTS'
export const SELECT_AUTOMATIC_REPORT = NAME + '/SELECT_AUTOMATIC_REPORT'
export const DELETE_AUTOMATIC_REPORT = NAME + '/DELETE_AUTOMATIC_REPORT'
export const UPDATE_AUTOMATIC_REPORT = NAME + '/UPDATE_AUTOMATIC_REPORT'
export const UPDATE_AUTOMATIC_REPORT_SCHEDULE = NAME + '/UPDATE_AUTOMATIC_REPORT_SCHEDULE'
