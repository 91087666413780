import { request, GET, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import { newPayloadForFormValue } from 'utils/formHelpers';

import notify from 'modules/Notifications';

export const getCommunicationPreferences = () => {
  return (dispatch) => {
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      dispatch(prefilForm(payload.user_communication_preferences[0]));
    }

    return request('user_communication_preferences/', GET, null, onSuccess, onFailure);
  };
};

export const updateCommunicationPreferences = (formData) => {
  return (dispatch) => {
    const communicationPreferencesJSON = seralizeFormData(formData);
    const body = {
      user_communication_preference: communicationPreferencesJSON,
    };
    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccessful());
      notify.success('Communication Preferences updated');
    }

    return request('user_communication_preferences/' + formData.id, PUT, body, onSuccess, onFailure);
  };
};

function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

function loadSuccessful(salesTarget) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    salesTarget,
  };
}

function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors,
  };
}

export function onInput(data) {
  return {
    type: actionTypes.UPDATE_FORM_DATA,
    data,
  };
}

function prefilForm(communicationPreferences) {
  return {
    type: actionTypes.PREFILL_FORM,
    communicationPreferences,
  };
}

function seralizeFormData(formData) {
  const {
    dailyOwnerSummaryEmail,
    dailyManagerSummaryEmail,
    newLead,
    leadAssigned,
    leadTimedOut,
    newLeadTextMessage,
    paymentNotifications,
  } = formData.fields;

  let payload = { id: formData.id };
  payload = newPayloadForFormValue(payload, dailyOwnerSummaryEmail, dailyOwnerSummaryEmail.id);
  payload = newPayloadForFormValue(payload, dailyManagerSummaryEmail, dailyManagerSummaryEmail.id);
  payload = newPayloadForFormValue(payload, newLead, newLead.id);
  payload = newPayloadForFormValue(payload, leadAssigned, leadAssigned.id);
  payload = newPayloadForFormValue(payload, leadTimedOut, leadTimedOut.id);
  payload = newPayloadForFormValue(payload, newLeadTextMessage, newLeadTextMessage.id);
  payload = newPayloadForFormValue(payload, paymentNotifications, paymentNotifications.id);

  return payload;
}
