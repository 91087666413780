import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LeadTableRow from './LeadTableRow';

export default class TableView extends Component {
  render() {
    const { leads, timezone } = this.props;
    const rows = leads.map((lead, index) => {
      return <LeadTableRow lead={lead} key={index} timezone={timezone} />;
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter">
          <thead>
            <tr>
              <th className="text-center">
                <i className="si si-user" />
              </th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Gender</th>
              <th>Status</th>
              <th>Sales Funnel Step</th>
              <th>Location</th>
              <th>Salesperson</th>
              <th>Source</th>
              <th>Heard About Method</th>
              <th>Last Contact</th>
              <th>Appointment count</th>
              <th>Presentation count</th>
              <th>Call count</th>
              <th>Successful call count</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Date Of Birth</th>
              <th>Address Line One</th>
              <th>Address Line Two</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
              <th>Postcode</th>
              <th>Workplace</th>
              <th>Opted out of transactional SMS</th>
              <th>Opted out of transactional Email</th>
              <th>Opted out of marketing SMS</th>
              <th>Opted out of marketing Email</th>
              <th>Created On</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

TableView.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
};
