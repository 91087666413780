import PropTypes from "prop-types";
import React, { Component } from "react";

export class FormView extends Component {
  render() {
    const { authUrl } = this.props.data.fields;
    return (
      <div>
        <div className="row push">
          <div className="col-md-12">
            <h2 className="h4 push-10">Authorize {this.props.productName}</h2>
            <p className="text-gray-dark">
              To send data to your MINDBODY account, an account owner needs to
              authorize us. If you're an owner, click 'authorize now'. If you're
              not the owner, click the 'send to owner' button to send them an
              email with a link to visit.
            </p>
            <p className="text-gray-dark push">
              When we have been authorized, click next
            </p>
            <a
              href={authUrl.value}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-minw btn-success push-10"
            >
              <i className="si si-link" /> Authorize club
            </a>
            <br />
            <a
              href={
                "mailto:?subject=" +
                this.props.productName +
                "authorization for MINDBODY&body=Could you open this link " +
                "to authorize " +
                this.props.productName +
                " to send data to our MINDBODY account? " +
                authUrl.value
              }
              className="btn btn-minw btn-primary"
            >
              <i className="fa fa-envelope" /> Send email to manager
            </a>
          </div>
        </div>
      </div>
    );
  }
}

FormView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FormView;
