import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';
import { InfoView } from 'modules/components';
import { InviteTeamMemberForm } from './InviteTeamMemberForm/InviteTeamMemberForm';

export class InviteTeamMemberModal extends Component {
  cleanUpAndHideModal = () => {
    this.props.resetForm();
    this.props.hideModal();
  };

  saveClicked = () => {
    const { data, sendInvite, location } = this.props;
    sendInvite(data, location);
  };

  render() {
    const { loading, currentModal, modalName } = this.props;
    const { isValid } = this.props.data;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    const infoString =
      'This creates a brand new user for your ' +
      this.props.productName +
      ' account. ' +
      'If you want to add an existing user to a club, close this window and select add existing user';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.cleanUpAndHideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.cleanUpAndHideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Invite new user to the {this.props.location.admin_display_name} club
              </h3>
            </div>
            <div className="block-content">
              <InfoView colorThemeClass={'info'} title={'Invite a new user'} detail={infoString} />
              {errorPanelForErrors(this.props.errors)}
              <InviteTeamMemberForm {...this.props} />
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-default push-5-r push-10" type="button" onClick={this.cleanUpAndHideModal}>
              Cancel
            </button>
            <button className="btn btn-success push-5-r push-10" type="button" disabled={!isValid} onClick={this.saveClicked}>
              <i className="fa fa-envelope-o" /> Send Invite
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

InviteTeamMemberModal.propTypes = {
  data: PropTypes.object.isRequired,
  updateInviteData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.object),
  sendInvite: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  errors: PropTypes.object,
};

export default InviteTeamMemberModal;
