import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RecordAppointmentModal from "../components/RecordAppointmentModal";

import modal from "modules/Stores/Modal";
import UserData from "modules/Stores/UserData";
import * as appointmentActions from "modules/components/LeadDetail/modules/appointments/actions";
import * as appointmentListActions from "../actions";
import leadsTrials from "modules/components/LeadDetail/modules/leadsTrials";
import details from "modules/components/LeadDetail/modules/details";
import classes from "modules/Stores/Classes";

const { updateLead } = details.actions;
const { hideModal, showModal } = modal.actions;

function mapStateToProps(state, ownProps) {
  const {
    classIntegrationId,
    classIntegrationName
  } = classes.selectors.locationUsesClassesFactory(ownProps.lead.location_id)(
    state
  );
  return {
    loading: modal.selectors.getModalLoading(state),
    classIntegrationId: classIntegrationId,
    classIntegrationName: classIntegrationName,
    currentModal: modal.selectors.getCurrentModal(state),
    salesFunnel: UserData.selectors.getSalesFunnel(state),
    listUpdateProtocol: {
      cleanUp: appointmentListActions.removeSelected
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addLeadToTrial: leadsTrials.actions.addLeadToTrial,
      hideModal,
      showModal,
      updateLead,
      toggleComplete: appointmentActions.toggleComplete,
      moveToSecondStep: appointmentActions.moveToSecondStep,
      handleAfterActionForOutcome:
        appointmentActions.handleAfterActionForOutcome,
      onSelect: appointmentListActions.removeSelected
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordAppointmentModal);
