import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EmptyView, PageSelection } from 'modules/components'

import LeadListRow from './LeadListRow'

export class LeadsListView extends Component {

  componentDidMount () {
    const { getLeadsWithQueryAndPageNumber, queryString, loading } = this.props
    if (loading) { return }
    getLeadsWithQueryAndPageNumber(queryString, 1)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.queryString !== this.props.queryString)) {
      const { getLeadsWithQueryAndPageNumber, queryString } = nextProps
      getLeadsWithQueryAndPageNumber(queryString, 1)
    }

    if (this.props.showingQuickLeadView === true && nextProps.showingQuickLeadView === false) {
      const { getLeadsWithQueryAndPageNumber, queryString } = nextProps
      getLeadsWithQueryAndPageNumber(queryString, 1)
    }
  }

  pageClicked = (pageNumber) => {
    const { getLeadsWithQueryAndPageNumber, queryString } = this.props
    getLeadsWithQueryAndPageNumber(queryString, pageNumber)
  }

  render () {
    const { leads, pageCount, selectedLead, loading } = this.props
    let pageLimit = (selectedLead) ? 10 : 20

    const leadList = leads.map((lead, index) => {
      const selected = (selectedLead && selectedLead.id === lead.id) || false
      return (
        <div className='row' key={index}>
          <div className='col-md-12'>
            <LeadListRow
              lead={lead}
              timezone={this.props.timezone}
              onSelect={this.props.selectLead}
              salesFunnelSteps={this.props.salesFunnelSteps}
              selected={selected}
              shortMode={(selectedLead !== null)} />
          </div>
        </div>
      )
    })
    return (
      <div>
        <div key='lead-list'>
          {leads.length === 0 &&
            <EmptyView
              colorThemeClass='primary'
              iconClass='fa fa-users'
              title='No Leads!'
              subtitle='Check your filters or add a new lead.' />
          }
          {leads.length > 0 &&
            <div>
              {loading &&
                <div className='row text-center'>
                  <div className='col-xs-12'>
                    <i className='fa fa-3x fa-cog fa-spin' />
                    <div className='h3 font-w400 push-10-t push-20'>
                      Updating leads list
                    </div>
                  </div>
                </div>
              }
              {leadList}
            </div>
           }
          {pageCount > 1 &&
            <div className='text-center push'>
              <div className='block block-rounded block-bordered'>
                <PageSelection
                  pageCount={this.props.pageCount}
                  currentPage={this.props.currentPage}
                  pageClicked={this.pageClicked}
                  pageLimit={pageLimit} />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

LeadsListView.propTypes = {
  getLeadsWithQueryAndPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  selectLead: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  showingQuickLeadView: PropTypes.bool.isRequired,
  selectedLead: PropTypes.object
}

export default LeadsListView
