import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EmailMapsView from '../components/EmailMapsView'

import editEmailMap from '../modules/editEmailMap'
import emailMapSelect from '../modules/emailMapSelect'

function mapStateToProps (state) {
  return {
    editEmailMapShowing: editEmailMap.selectors.getShowing(state),
    selectedEmailMap:  emailMapSelect.selectors.selectedEmailMap(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailMapsView)
