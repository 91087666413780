import { request, GET, POST, PUT, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// Fetch Notes
export const fetchForFacebookMessengerUser = (facebookMessengerUser) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (errors) {
      dispatch(setLoading(false))
      dispatch(setErrors(errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setFacebookMessages(payload.facebook_messages))
      dispatch(loadPageDetails(payload.meta))
    }

    let url = 'facebook_messenger_users/' + facebookMessengerUser.id + '/facebook_messages'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const addFacebookMessengesForPage = (facebookMessengerUser, page = 1) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (errors) {
      dispatch(setLoading(false))
      dispatch(setErrors(errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(addMessages(payload.facebook_messages))
      dispatch(loadPageDetails(payload.meta))
    }

    let url = 'facebook_messenger_users/' + facebookMessengerUser.id + '/facebook_messages?page[number]=' + page
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const createChatRoomForMessengerUser = (facebookMessengerUser) => {
  return dispatch => {
    const body = {
      facebook_messenger_chat_room: {
        facebook_messenger_user_id: facebookMessengerUser.id
      }
    }

    function onFailure (errors) {
      dispatch(setErrors(errors))
    }

    function onSuccess (payload) {
      dispatch(setChatRoom(payload.facebook_messenger_chat_room))
    }

    let url = 'facebook_messenger_chat_rooms/'
    return request(url, POST, body, onSuccess, onFailure)
  }
}

export const unsubscribeForMessengerChatRoom = (facebookMessengerChatRoom) => {
  if (!facebookMessengerChatRoom) { return }
  return dispatch => {
    function onFailure (errors) {
      dispatch(setChatRoom(null))
    }

    function onSuccess (payload) {
      dispatch(setChatRoom(null))
    }

    let url = 'facebook_messenger_chat_rooms/' + facebookMessengerChatRoom.id
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}

export const createNewFacebookMessage = (facebookMessengerUser, text) => {
  return (dispatch) => {
    dispatch(setSending(true))

    const body = {
      facebook_message: {
        text: text
      }
    }

    function onFailure (errors) {
      dispatch(setSending(false))
      dispatch(setErrors(errors))
    }

    function onSuccess (payload) {
      dispatch(setSending(false))
      dispatch(add(payload.facebook_message))
    }

    let url = 'facebook_messenger_users/' + facebookMessengerUser.id + '/facebook_messages'
    return request(url, POST, body, onSuccess, onFailure)
  }
}

export const setFacebookUserHumanOnlyStatus = (facebookMessengerUser, botDisabled, setFacebookMessengerUser) => {
  return (dispatch) => {
    dispatch(setUpdating(true))

    const body = {
      facebook_messenger_user: {
        talk_to_human_only: botDisabled
      }
    }

    function onFailure (errors) {
      dispatch(setUpdating(false))
      dispatch(setErrors(errors))
    }

    function onSuccess (payload) {
      dispatch(setUpdating(false))
      dispatch(setFacebookMessengerUser(payload.facebook_messenger_user))
    }

    let url = 'facebook_messenger_users/' + facebookMessengerUser.id
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setSending (sending) {
  return {
    type: actionTypes.SET_SENDING,
    sending
  }
}

function setUpdating (updating) {
  return {
    type: actionTypes.SET_UPDATING,
    updating
  }
}

function setFacebookMessages (facebookMessages) {
  return {
    type: actionTypes.SET_FACEBOOK_MESSAGES,
    facebookMessages
  }
}

function addMessages (facebookMessages) {
  return {
    type: actionTypes.ADD_FACEBOOK_MESSAGES,
    facebookMessages
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function add (facebookMessage) {
  return {
    type: actionTypes.ADD_FACEBOOK_MESSAGE,
    facebookMessage
  }
}

export function setChatRoom (chatRoom) {
  return {
    type: actionTypes.SET_CHAT_ROOM,
    chatRoom
  }
}
