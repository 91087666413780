import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as dashboardTypes from '../constants'

export class SetDashboardTypeButton extends Component {

  onClick = () => {
    const { currentType, setDashboardType } = this.props
    switch (currentType) {
      case dashboardTypes.DASHBOARD_MANAGER_TYPE:
        setDashboardType(dashboardTypes.DASHBOARD_SALES_TYPE)
        break
      case dashboardTypes.DASHBOARD_SALES_TYPE:
        setDashboardType(dashboardTypes.DASHBOARD_MANAGER_TYPE)
        break
      default:
        break
    }
  }

  buttonText = () => {
    const { currentType } = this.props
    switch (currentType) {
      case dashboardTypes.DASHBOARD_MANAGER_TYPE:
        return 'Sales Dashboard'
      case dashboardTypes.DASHBOARD_SALES_TYPE:
        return 'Manage Dashboard'
      default:
        return ''
    }
  }

  render () {
    return (
      <button type='button' className='btn btn-default'
        style={{ float: 'right', marginRight: '10px' }}
        onClick={this.onClick}>
        <i className='fa fa-dashboard' />{' '}
        {this.buttonText()}
      </button>
    )
  }
}

SetDashboardTypeButton.propTypes = {
  currentType: PropTypes.string,
  setDashboardType: PropTypes.func
}

export default SetDashboardTypeButton
