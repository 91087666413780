import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getEmails = state => state.manageLeads.leadDetail[NAME].emails
export const getLoading = state => state.manageLeads.leadDetail[NAME].loading
export const getPageDetails = state => state.manageLeads.leadDetail[NAME].pageDetails
export const getShowHistoryEmailId = state => state.manageLeads.leadDetail[NAME].showHistoryEmailId

export const selectedEmailForHistory = createSelector(
  [ getEmails, getShowHistoryEmailId ],
  (emails, showHistoryEmailId) => {
    if (emails.length < 0) { return null }
    if (!showHistoryEmailId) { return null }
    const index = emails.findIndex(email => parseInt(email.id) === parseInt(showHistoryEmailId))
    return emails[index]
  }
)
