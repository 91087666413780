import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getAutoAssignRuleForLocation = (location) => {
  return dispatch => {
    dispatch(setLoading(true))
    dispatch(setErrors(null))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      if (payload) {
        dispatch(setAutoAssignRule(payload.auto_assign_rule))
      } else {
        dispatch(setAutoAssignRule(null))
      }
    }

    const url = 'locations/' + location.id + '/auto_assign_rules'

    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setAutoAssignRule (autoAssignRule) {
  return {
    type: actionTypes.SET_AUTO_ASSIGN_RULE,
    autoAssignRule
  }
}
