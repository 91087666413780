import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import leadTimeOut from '../modules/leadTimeOut'

function mapStateToProps (state, nextProps) {
  return {
    salesTargets: nextProps.location.target_rule_set,
    loading: leadTimeOut.selectors.getLoading(state),
    data: leadTimeOut.selectors.getData(state),
    errors: leadTimeOut.selectors.getErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    onInput: leadTimeOut.actions.updateData,
    updateSalesTargetWithData: leadTimeOut.actions.updateSalesTargetWithData,
    prefilSalesTargetsData: leadTimeOut.actions.prefilSalesTargetsData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(leadTimeOut.components.LeadTimeOutView)
