import { createSelector } from 'reselect'
import session from 'modules/Stores/Session'
import { NAME } from './constants'

export const getLoading = state => state.profileSettings[NAME].loading
export const getData = state => state.profileSettings[NAME].data
export const getErrors = state => state.profileSettings[NAME].errors

export const getAvatarLoading = state => state.profileSettings[NAME].avatar.loading
export const getAvatarFile = state => state.profileSettings[NAME].avatar.file
export const getAvatarProgress = state => state.profileSettings[NAME].avatar.progress

export const getAvailableSiteIds = state => state.profileSettings[NAME].availableSiteIds

export const remainingSiteIds = createSelector(
  [ getAvailableSiteIds, session.selectors.getUser ],
  (availableSiteIds, user) => {
    const currentMindbodyUsers = user.mindbody_users.map(mboUser => mboUser.site_id)
    return availableSiteIds.filter(e => !currentMindbodyUsers.includes(e))
  }
)
