import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SendTextMessageModal from "modules/components/SendTextMessageModal/SendTextMessageModal";

import modal from "modules/Stores/Modal";
import session from "modules/Stores/Session";
import billing from "modules/Stores/Billing";
import mergeTags from "modules/Stores/MergeTags";

import { sendTextMessageToLead } from "../actions";
const { showModal, hideModal } = modal.actions;

function mapStateToProps(state) {
  const getDefaultSmsCost = billing.selectors.getDefaultSmsCost(state);
  const defaultCost = getDefaultSmsCost
    ? getDefaultSmsCost.p2p_outbound_cost
    : 0.1;
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    isMobile: state.session.isMobile,
    errors: modal.selectors.getModalErrors(state),
    user: session.selectors.getUser(state),
    mergeTags: mergeTags.selectors.mergeTagsForSelect(state),
    smsCost: defaultCost,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      sendTextMessageToLead,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendTextMessageModal);
