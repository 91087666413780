import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'

import SalesTargetsForm from './SalesTargetsForm'

import SweetAlert from 'react-bootstrap-sweetalert'

export class SalesTargetsView extends Component {

  state = {
    showDanger: false
  }

  showDangerPopup = () => {
    this.setState({ ...this.state, showDanger: true })
  }

  UNSAFE_componentWillMount () {
    const { salesTargets, prefilSalesTargetsData } = this.props
    prefilSalesTargetsData(salesTargets)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.id !== this.props.location.id) {
      nextProps.prefilSalesTargetsData(nextProps.salesTargets)
    }
  }

  saveClicked = () => {
    const { updateSalesTargetWithData, salesTargets, data, location } = this.props
    updateSalesTargetWithData(location, salesTargets, data)
  }

  dismissPopups = () => {
    this.setState({ ...this.state, showDanger: false })
  }

  deleteSalesTargets = () => {
    const { deleteSalesTarget, salesTargets, location } = this.props
    deleteSalesTarget(location, salesTargets)
    this.dismissPopups()
  }

  render () {
    const { loading, data, errors, onInput } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Sales Targets for { this.props.location.admin_display_name }</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <SalesTargetsForm
                data={data}
                onSaveSelected={this.saveClicked}
                onDeleteSelected={this.showDangerPopup}
                onInput={onInput} />
            </form>
          </div>
          {this.state.showDanger &&
            <SweetAlert
              danger
              showCancel
              cancelBtnBsStyle='default'
              confirmBtnBsStyle='danger'
              confirmBtnText='Delete sales targets'
              title='Are you sure?'
              onConfirm={this.deleteSalesTargets}
              onCancel={this.dismissPopups}>
              This will delete your clubs specific sales targets and revert back to using the companies default.
            </SweetAlert>
          }
        </div>
      </div>
    )
  }
}

SalesTargetsView.propTypes = {
  location: PropTypes.object.isRequired,
  prefilSalesTargetsData: PropTypes.func.isRequired,
  updateSalesTargetWithData: PropTypes.func.isRequired,
  deleteSalesTarget: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  salesTargets: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default SalesTargetsView
