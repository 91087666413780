import { generateStore } from "utils/reduxHelpers";

const name = "search";

const initialState = {
  searchResults: [],
  showingSearch: false,
  searchTerm: "",
  loading: false,
  errors: null,
  pageDetails: {}
};

export default generateStore(name, initialState);
