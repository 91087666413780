import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalendlySetupView from '../components/CalendlySetupView';

import * as actions from '../actions';
import * as selectors from '../selectors';

function mapStateToProps(state, props) {
  return {
    selectedSubscription: selectors.getSelectedSubscription(state),
    subscriptions: selectors.getSubscriptions(state),
    loading: selectors.getLoading(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSubscriptions: actions.fetchSubscriptions,
      setSelectedSubscription: actions.setSelectedSubscription,
      setSubscriptions: actions.setSubscriptions,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendlySetupView);
