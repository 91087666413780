import { request, GET } from 'utils/apiUtils';

import session from 'modules/Stores/Session';

export const getUserEmail = () => {
  return (dispatch) => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      if (payload) {
        dispatch(session.actions.setUserEmail(payload.transactional_email));
      }
    }

    return request('my_email/', GET, null, onSuccess, onFailure);
  };
};
