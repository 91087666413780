import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as actions from 'modules/Helpers/IntegrationHelpers';

import EnterClubIdButtonsContainer from '../containers/EnterClubIdButtonsContainer';
import VerifyLinkButtonsContainer from '../containers/VerifyLinkButtonsContainer';
import SelectLocationButtonsContainer from '../containers/SelectLocationButtonsContainer';
import ConfirmButtonsContainer from '../containers/ConfirmButtonsContainer';

import MindBodyWizardView from './MindBodyWizardView';

import Modal from 'react-bootstrap-modal';

export class ConfigureModalView extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.selectedIntegration) {
      this.props.prefillFormForIntegration(this.props.selectedIntegration);
    } else {
      this.props.resetForm();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentId = this.props.selectedIntegration ? this.props.selectedIntegration.id : null;
    const nextId = nextProps.selectedIntegration ? nextProps.selectedIntegration.id : null;
    if (currentId === nextId) {
      return;
    }
    if (nextProps.selectedIntegration) {
      nextProps.prefillFormForIntegration(nextProps.selectedIntegration);
    } else {
      nextProps.resetForm();
    }
  }

  hideModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const { loading, currentModal, modalName, name, location, tabIndex } = this.props;

    const buttonsContainer = () => {
      switch (tabIndex) {
        case 0:
          return <EnterClubIdButtonsContainer location={location} />;
        case 1:
          return <VerifyLinkButtonsContainer location={location} />;
        case 2:
          return <SelectLocationButtonsContainer location={location} />;
        case 3:
          return <ConfirmButtonsContainer location={location} />;
        default:
          return <div />;
      }
    };

    const logo = actions.logoForOption(name);

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={this.hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-success">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={this.hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                Let's setup {actions.titleForOption(name)}
              </h3>
            </div>
            <div className="block-content">
              <MindBodyWizardView {...this.props} />
              {logo && <img alt="" src={logo} style={{ width: '100px' }} />}
            </div>
          </div>
          <Modal.Footer>{buttonsContainer()}</Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ConfigureModalView.propTypes = {
  loading: PropTypes.bool.isRequired,
  prefillFormForIntegration: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  selectedIntegration: PropTypes.object,
  name: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

export default ConfigureModalView;
