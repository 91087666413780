import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import textMessages from '../modules/textMessages'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state, ownProps) {
  return {
    textMessages: textMessages.selectors.getTextMessages(state),
    loading: textMessages.selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    fetchForLead: textMessages.actions.fetchForLead
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(textMessages.components.TextMessagesView)
