import PropTypes from "prop-types";
import React, { Component } from "react";
import { UserAvatar } from "modules/components/Avatars";

import Dropzone from "react-dropzone";

export class EditAvatar extends Component {
  onDrop = (files) => {
    if (files.length > 0) {
      this.props.fileWasSelected(files[0]);
      this.props.startFileUpload(files[0], this.props.user);
    }
  };

  render() {
    const { user, file } = this.props;

    const imageStyle = {
      width: "150px",
      height: "150px",
      borderRadius: "75px",
      border: "2px dashed black",
    };

    const dropZoneStyle = {};

    return (
      <Dropzone
        onDrop={this.onDrop}
        style={dropZoneStyle}
        className="selectable"
      >
        {file && <img alt="" src={file.preview} style={imageStyle} />}
        {!file && (
          <UserAvatar
            avatarImage={user.user_avatar}
            className="img-avatar img-avatar32"
            style={imageStyle}
          />
        )}
      </Dropzone>
    );
  }
}

EditAvatar.propTypes = {
  startFileUpload: PropTypes.func.isRequired,
  fileWasSelected: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  file: PropTypes.object,
  progress: PropTypes.number,
};

export default EditAvatar;
