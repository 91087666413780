import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'images/logo.png';
import LoginForm from './LoginForm';
import { InfoView } from 'modules/components';
import STRINGS from 'strings';

export class LoginView extends Component {
  componentDidMount() {
    this.lookForWhitelabelProfile(this.props.productName);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.productName !== nextProps.productName) {
      this.lookForWhitelabelProfile(nextProps.productName);
    }
  }

  lookForWhitelabelProfile(productName) {
    const { fetchWhitelabelProfileLogo } = this.props;
    if (productName) {
      fetchWhitelabelProfileLogo(productName);
    }
  }

  setLogo() {
    const { whitelabelProfile, productName } = this.props;

    if (!productName) {
      this.setDocumentTitle('Gymleads');
      return logo;
    } else if (whitelabelProfile && whitelabelProfile.logo_url) {
      this.setDocumentTitle(whitelabelProfile.name);
      return whitelabelProfile.logo_url;
    } else if (whitelabelProfile && whitelabelProfile.name === 'Gymleads') {
      this.setDocumentTitle('Gymleads');
      return logo;
    }
  }

  setDocumentTitle(name) {
    document.title = `${name} | The CRM for Gyms and fitness clubs`;
  }

  setCopyright() {
    if (this.props.productName) {
      return (
        <div>
          <span className="js-year-copy">2016-2022</span> © Powered by Gymleads
        </div>
      );
    } else {
      return (
        <div>
          <span className="js-year-copy">2016-2022</span> © Gymleads
        </div>
      );
    }
  }

  getResetPasswordUrl() {
    const { productName } = this.props;
    if (productName) {
      return '/forgotPassword?client=' + productName;
    } else {
      return '/forgotPassword';
    }
  }

  render() {
    const Strings = STRINGS.Login;
    const { login, loading, errors, logoutReason } = this.props;
    const logoImage = this.setLogo();
    return (
      <div>
        <div className="bg-white pulldown">
          <div className="content context-boxed overflow-hidden">
            <div className="row">
              {logoutReason && (
                <div className="row">
                  <div
                    className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4"
                    style={{ textAlign: 'center' }}
                  >
                    <InfoView colorThemeClass={'info'} detail={logoutReason} />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12 text-center push-30-t">
                  {logoImage && <img src={logoImage} alt="logo" style={{ height: '60px', paddingBottom: '10px' }} />}
                  <p className="animated fadeIn" style={{ margin: '10px' }}>
                    {Strings.welcomeBack}
                  </p>
                </div>
              </div>
              <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <div className="push-30-t push-50 animated fadeIn">
                  <LoginForm onSubmit={login} loading={loading} errors={errors} />
                  <p className="push-50 text-center">
                    <a href="https://www.gymleads.net/bookADemo.html" rel="noopener noreferrer" target="_blank">
                      {' '}
                      {Strings.bookADemo}
                    </a>{' '}
                    {STRINGS.Global.or}
                    <Link to={this.getResetPasswordUrl()}> {Strings.resetYourPassword}</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pulldown push-30-t text-center animated fadeInUp">
          <small className="text-muted">{this.setCopyright()}</small>
        </div>
      </div>
    );
  }
}

LoginView.propTypes = {
  loading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  fetchWhitelabelProfileLogo: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  logoutReason: PropTypes.string,
};
