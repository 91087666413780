import { isPresent } from 'utils/validation'

export const SalesFunnelValidator = {
  'stepOne': [{
    rule: isPresent,
    errorMessage: 'What do you want to call step One?'
  }],
  'stepTwo': [{
    rule: isPresent,
    errorMessage: 'What do you want to call step Two?'
  }],
  'stepThree': [{
    rule: isPresent,
    errorMessage: 'What do you want to call step Three?'
  }],
  'stepFour': [{
    rule: isPresent,
    errorMessage: 'What do you want to call step Four?'
  }]
}
