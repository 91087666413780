import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getMergeTags = (state) => state[NAME].mergeTags

export const mergeTagsForSelect = createSelector(
  [ getMergeTags ],
  (mergeTags) => {
    return mergeTags.map((mergeTag) => {
      return {
        id: mergeTag.value,
        title: mergeTag.name
      }
    })
  }
)
