import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getInvoicesForBillingPlan = (billingPlanId, pageNumber) => {
  return dispatch => {
    dispatch(startUpdating())
    function onFailure (errors) {
      dispatch(loadFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(loadSuccessful(payload.invoices))
      dispatch(loadPageDetails(payload.meta))
    }

    return request('billing_plans/' + billingPlanId + '/invoices?page[number]=' + pageNumber,
    GET, null, onSuccess, onFailure)
  }
}

function startUpdating () {
  return {
    type: actionTypes.LOADING
  }
}

function loadSuccessful (invoices) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    invoices
  }
}

function loadFailed (errors) {
  return {
    type: actionTypes.LOAD_FAILED,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function selectInvoiceId (invoiceId) {
  return {
    type: actionTypes.INVOICE_SELECTED,
    invoiceId
  }
}

export const getInvoicePdfForId = (id) => {
  return dispatch => {
    const windowRef = window.open()

    function onFailure (errors) {}

    function onSuccess (payload) {
      windowRef.location = payload.url
    }

    const urlString = '/invoices/export_invoice_url?id=' + id
    return request(urlString, GET, null, onSuccess, onFailure)
  }
}
