const MINUTES = 'Minutes'
const HOURS = 'Hours'
const DAYS = 'Days'
const WEEKS = 'Weeks'

export function secondsToTimeObject (seconds) {
  if (seconds % 604800 === 0) {
    return { timeToWait: seconds / 604800, selectedTimeType: WEEKS }
  } else if (seconds % 86400 === 0) {
    return { timeToWait: seconds / 86400, selectedTimeType: DAYS }
  } else if (seconds % 3600 === 0) {
    return { timeToWait: seconds / 3600, selectedTimeType: HOURS }
  } else if (seconds % 60 === 0) {
    return { timeToWait: seconds / 60, selectedTimeType: MINUTES }
  }
}
