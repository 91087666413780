import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

const statuses = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'not_assigned',
    label: 'Not Assigned',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
  {
    value: 'lost',
    label: 'Lost',
  },
  {
    value: 'closed',
    label: 'Closed',
  },
];

export default class LeadStatusSection extends Component {
  findSourceByLabel = (label) => {
    const index = statuses.findIndex((status) => status.label === label);
    return statuses[index];
  };

  handleUpdate = (event) => {
    const newValues = event.map((status) => {
      return {
        value: true,
        label: status.label,
        isValid: true,
      };
    });

    var newStatuses = {};
    newValues.forEach((source) => {
      const status = this.findSourceByLabel(source.label);
      const id = status.value;
      newStatuses[id] = source;
    });

    const newData = {
      fields: newStatuses,
    };

    this.props.onUpdate(newData, this.props.sectionName);
  };

  statusesFromData = () => {
    const statuses = this.props.data.fields;
    const ids = Object.keys(statuses);
    return ids.map((key) => {
      const statusData = statuses[key];
      return {
        label: statusData.label,
        value: key,
      };
    });
  };

  generateSuggestions = () => {
    return statuses.map((status) => {
      return {
        value: status.value,
        label: status.label.toString(),
      };
    });
  };

  render() {
    return (
      <Select
        multi={true}
        options={this.generateSuggestions()}
        value={this.statusesFromData()}
        onChange={(newValue) => this.handleUpdate(newValue)}
      />
    );
  }
}

LeadStatusSection.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  showBottomBorder: PropTypes.bool,
  title: PropTypes.string,
  blockStyle: PropTypes.string,
};
