import PropTypes from 'prop-types'
import React, { Component } from 'react'

import MapStatusesContainer from '../containers/MapStatusesContainer'
import MapSalespeopleContainer from '../containers/MapSalespeopleContainer'
import MapLawfulProcessingBasesContainer from '../containers/MapLawfulProcessingBasesContainer'

export class FieldMappingView extends Component {

  UNSAFE_componentWillMount () {
    const { csvFile, findSalespeopleAndStatusStrings, getPotentialUsersForLocationId, currentLocationId,
      statusColumnIndex, salespersonColumnIndex, lawfulProcessingBasesColumnIndex } = this.props
    getPotentialUsersForLocationId(currentLocationId, 1)
    findSalespeopleAndStatusStrings(csvFile, statusColumnIndex,
      salespersonColumnIndex, lawfulProcessingBasesColumnIndex)
  }

  render () {
    const { isActive, loading, statusColumnIndex,
      salespersonColumnIndex, lawfulProcessingBasesColumnIndex } = this.props
    const activeClassName = (isActive) ? 'active in' : ''
    const matchValuesNeeded = (statusColumnIndex || salespersonColumnIndex)
    return (
      <div className={'tab-pane fade fade-up ' + activeClassName}>
        {!loading && !matchValuesNeeded &&
          <div>
            <h4>Map values</h4>
            <p>
              You have no columns that need to be mapped.
            </p>
          </div>
        }
        {!loading && matchValuesNeeded &&
          <div>
            <h4>Map values</h4>
            <p>We need some more details on some of the values in your CSV.<br />
            Look through the values below and tell us how they map to your {this.props.productName} account.<br />
            You must match all values before you can continue.
            </p>
            {statusColumnIndex !== null && statusColumnIndex !== undefined &&
              <MapStatusesContainer />
            }
            {salespersonColumnIndex !== null && salespersonColumnIndex !== undefined &&
              <MapSalespeopleContainer />
            }
            {lawfulProcessingBasesColumnIndex !== null && lawfulProcessingBasesColumnIndex !== undefined &&
              <MapLawfulProcessingBasesContainer />
            }
          </div>
        }
        {loading &&
          <div className='text-center'>
            <h3>Checking for values</h3>
            <i className='fa fa-3x fa-cog fa-spin' />
          </div>
        }
      </div>
    )
  }
}

FieldMappingView.propTypes = {
  loading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  csvFile: PropTypes.object.isRequired,
  statusColumnIndex: PropTypes.number,
  salespersonColumnIndex: PropTypes.number,
  lawfulProcessingBasesColumnIndex: PropTypes.number,
  findSalespeopleAndStatusStrings: PropTypes.func.isRequired,
  getPotentialUsersForLocationId: PropTypes.func.isRequired,
  currentLocationId: PropTypes.number.isRequired
}

export default FieldMappingView
