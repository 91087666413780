import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectLeadGoalTableRow } from "./SelectLeadGoalTableRow";

export class SelectLeadGoalTableView extends Component {
  render() {
    const { leadGoals, selectedLeadGoal, onLeadGoalSelect } = this.props;
    const rows = leadGoals.map((leadGoal, index) => {
      const selectedId = selectedLeadGoal ? selectedLeadGoal.id : null;
      const selected = selectedId === leadGoal.id;
      return (
        <SelectLeadGoalTableRow
          key={index}
          leadGoal={leadGoal}
          leadGoalSelected={selected}
          onLeadGoalSelect={onLeadGoalSelect}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectLeadGoalTableView.propTypes = {
  leadGoals: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLeadGoal: PropTypes.object,
  onLeadGoalSelect: PropTypes.func.isRequired
};

export default SelectLeadGoalTableView;
