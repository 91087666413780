import * as actionTypes from './actionTypes'

const LAWFUL_PROCESSING_BASIS_SELECT_ACTION_HANDLERS = {

  [actionTypes.SET_SELECTED_LAWFUL_PROCESSING_BASIS_ID]: (state, action) => {
    return { ...state, selectedLawfulProcessingBasisId: action.selectedLawfulProcessingBasisId }
  },
  [actionTypes.SET_SEARCH_TERM]: (state, action) => {
    return { ...state, searchTerm: action.searchTerm }
  }
}

const initialState = {
  searchTerm: '',
  selectedLawfulProcessingBasisId: null
}

export default function reducer (state = initialState, action) {
  const handler = LAWFUL_PROCESSING_BASIS_SELECT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
