export function icon (leadSource) {
  switch (leadSource) {
    case 'Facebook':
      return 'si si-social-facebook'
    case 'Website':
      return 'fa fa-globe'
    case 'Walk In':
      return 'fa fa-wpforms'
    case 'Phone Call':
      return 'fa fa-phone'
    case 'Import':
      return 'glyphicon glyphicon-import'
    case 'pos_referral':
    case 'sale_referral':
      return 'fa fa-hand-o-right'
    default:
      return 'fa fa-wpforms'
  }
}

export const leadSources = ['facebook', 'web_form', 'walk_in',
  'phone_in', 'pos_referral', 'sale_referral', 'bulk_import']

// [:walk_in, :phone_in, :web_form, :facebook, :csv_import, :pos_referral, :sale_referral, :other ]
