import PropTypes from "prop-types";
import React, { Component } from "react";

import { FormFields } from "modules/components";

import { errorPanelForErrors } from "utils/formHelpers";

import { ForgotPasswordValidator } from "../validator";
const { TextField } = FormFields;

export class ForgotPasswordForm extends Component {
  state = {
    isValid: false,
    fields: {
      username: {
        value: null,
        isValid: false,
      },
    },
  };

  handleSubmit = (event) => {
    event && event.preventDefault();
    const { fields } = this.state;
    const username = fields.username.value;
    this.props.onSubmit(username);
  };

  handleInput = (name, value, isValid) => {
    const fields = this.state.fields;
    fields[name] = { value: value, isValid: isValid };
    const newIsValidValue = this.fieldsAreValid(fields);
    this.setState({ ...this.state, fields: fields, isValid: newIsValidValue });
  };

  fieldsAreValid(fields) {
    let newIsValidValue = true;
    Object.keys(fields).forEach(function (key) {
      const fieldIsValid = fields[key].isValid;
      if (fieldIsValid === false) {
        newIsValidValue = false;
      }
    });
    return newIsValidValue;
  }

  render() {
    const { loading, errors } = this.props;
    const { isValid } = this.state;
    const loginSymbol = loading
      ? "fa fa-circle-o-notch fa-spin pull-right"
      : "si si-envelope-open pull-right";
    const buttonTitle = loading ? "Reseting Password" : "Reset Password";
    const buttonEnabled = isValid && !loading;

    const renderButton = () => (
      <div className="form-group">
        <div className="col-xs-12 col-sm-6 col-sm-offset-3">
          <button
            type="submit"
            className="btn btn-block btn-primary"
            disabled={!buttonEnabled}
            onClick={this.handleSubmit}
          >
            <i className={loginSymbol} /> {buttonTitle}
          </button>
        </div>
      </div>
    );

    return (
      <div>
        {errorPanelForErrors(errors)}
        <form
          className="form-horizontal push-30-t push-50"
          onSubmit={this.handleSubmit}
        >
          <TextField
            name="username"
            label="Username"
            placeholder="jblogs"
            formFieldStyle="form-material form-material-primary"
            onChange={this.handleInput}
            validator={ForgotPasswordValidator}
            disabled={loading}
          />
          {renderButton()}
        </form>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default ForgotPasswordForm;
