import { isPresent, isMinLength, hasWhiteSpace } from 'utils/validation'

const passwordMinLength = 4
export const RedeemFormValidator = {
  username: [{
    rule: isMinLength(passwordMinLength),
    errorMessage: 'Your username needs to be longer than ' + passwordMinLength + ' characters'
  }, {
    rule: hasWhiteSpace,
    errorMessage: 'Usernames should not have any spaces'
  }, {
    rule: isPresent,
    errorMessage: 'Looks like your username is missing'
  }],
  givenName: [{
    rule: isPresent,
    errorMessage: 'We\'re missing your given name'
  }],
  password: [{
    rule: isMinLength(passwordMinLength),
    errorMessage: 'Your password needs to be longer than ' + passwordMinLength + ' characters'
  }, {
    rule: isPresent,
    errorMessage: 'Looks like the password is missing'
  }]
}
