import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import MultiUserSelectViewContainer from '../containers/MultiUserSelectViewContainer';

export class MultiUserSelectModal extends Component {
  selectUser = (user) => {
    const { singleSelect, addUserId, setSelectedUserIds } = this.props;
    if (singleSelect) {
      setSelectedUserIds([user.id]);
    } else {
      addUserId(user.id);
    }
  };

  useAllUsers = () => {
    this.props.setSelectedUserIds([]);
  };

  deselectUser = (user) => {
    this.props.removeUserId(user.id);
  };

  updateUserIds = () => {
    const { selectedUserIds, onUpdate, hideModal } = this.props;
    if (onUpdate) {
      onUpdate(selectedUserIds);
    }
    hideModal();
  };

  filteredUsers = () => {
    const { filterFunction, users } = this.props;
    if (filterFunction) {
      return filterFunction(users);
    }
    return users;
  };

  render() {
    const { currentModal, hideModal, selectedUserIds, buttonTitle, modalName, allUsersButton } = this.props;
    const disabled = selectedUserIds.length === 0;

    return (
      <Modal show={currentModal === modalName} onHide={hideModal}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={hideModal}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              Select users
            </h3>
          </div>
          <div className="block-content">
            <MultiUserSelectViewContainer
              {...this.props}
              users={this.filteredUsers()}
              selectUser={this.selectUser}
              deselectUser={this.deselectUser}
            />
          </div>
        </div>

        <Modal.Footer>
          {allUsersButton && (
            <button className="btn btn-primary pull-left" onClick={this.useAllUsers}>
              All Users
            </button>
          )}
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.props.hideModal}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={disabled} onClick={this.updateUserIds}>
            <i className="fa fa-building-o" /> {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MultiUserSelectModal.propTypes = {
  selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  get: PropTypes.func.isRequired,
  setSelectedUserIds: PropTypes.func.isRequired,
  addUserId: PropTypes.func.isRequired,
  removeUserId: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  searchTerm: PropTypes.string,
  buttonTitle: PropTypes.string,
  currentModal: PropTypes.string,
  singleSelect: PropTypes.bool.isRequired,
  filterFunction: PropTypes.func,
  identifer: PropTypes.string,
  locationIds: PropTypes.arrayOf(PropTypes.number),
  modalName: PropTypes.string.isRequired,
  allUsersButton: PropTypes.bool,
};

MultiUserSelectModal.defaultProps = {
  buttonTitle: 'Filter by selected',
  singleSelect: false,
  allUsersButton: true,
};

export default MultiUserSelectModal;
