import { Box, Text } from 'modules/components/Foundation';
import moment from 'moment';
import React, { FC } from 'react';
import Avatar from 'react-avatar';
import { space } from 'styleguide';

type CommonEventModalBodyProps = {
  displayName: string;
  email?: string;
  title: string;
  start: Date;
  dueDate: Date;
  outcome: string;
  userDisplayName: string;
  goToLead: () => void;
  type: 'appointment' | 'call';
};

const CommonEventModalBody: FC<CommonEventModalBodyProps> = (props) => {
  return (
    <Box row m={space.L}>
      <Avatar name={props.displayName} email={props.email} size="80" round={true} />
      <Box ml={space.L}>
        <Text.H3 fontWeight={300} m={0}>
          {props.title}
        </Text.H3>
        <Text.H5>{props.userDisplayName}</Text.H5>
        <p>
          On <strong>{moment(props.start || props.dueDate).format('dddd, MMMM Do YYYY')}</strong> at
          <strong> {moment(props.start || props.dueDate).format('h:mm a')}</strong>
        </p>
        {props.outcome && (
          <p>
            outcome: <strong> {props.outcome.replace(/_/g, ' ')} </strong>
          </p>
        )}
        <button className="btn btn-primary" onClick={props.goToLead}>
          Go to lead
        </button>
      </Box>

      <hr />
    </Box>
  );
};

export default CommonEventModalBody;
