import { connect } from 'react-redux'
import DateSelectionModal from './DateSelectionModal'

import userData from 'modules/Stores/UserData'

function mapStateToProps (state) {
  return {
    formats: userData.selectors.defaultFormats(state)
  }
}

export default connect(mapStateToProps)(DateSelectionModal)
