import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import modal from "modules/Stores/Modal";
import SetupScheduleModal from "../components/SetupScheduleModal";
import * as actions from "../actions";
import { SETUP_AUTOMATIC_REPORT_MODAL } from "../../../constants";

function mapStateToProps(state) {
  return {
    modalName: SETUP_AUTOMATIC_REPORT_MODAL,
    currentModal: modal.selectors.getCurrentModal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAutomaticSchedule: actions.createAutomaticSchedule,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupScheduleModal);
