import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export const days = [
  { id: "mon", display: "Monday" },
  { id: "tue", display: "Tuesday" },
  { id: "wed", display: "Wednesday" },
  { id: "thu", display: "Thursday" },
  { id: "fri", display: "Friday" },
  { id: "sat", display: "Saturday" },
  { id: "sun", display: "Sunday" }
];

const DaySelect = ({ selectedDays, handleDayChange }) => {
  function buildTableHeader() {
    return days
      .map(d => d.display)
      .map((dayName, index) => {
        return <TableHeader key={`h-${index}`}>{dayName}</TableHeader>;
      });
  }

  function buildTableCells() {
    return days
      .map(d => d.id)
      .map((dayId, index) => {
        return (
          <td className="text-center" key={"c-" + index}>
            <Label
              className={"css-input css-checkbox css-checkbox-primary"}
              htmlFor={dayId}
            >
              <input
                type="checkbox"
                id={dayId}
                name={dayId}
                onChange={handleDayChange}
                checked={selectedDays.indexOf(dayId) >= 0}
                style={{ display: "table-cell" }}
              />
              <span />
            </Label>
          </td>
        );
      });
  }

  const dayNames = buildTableHeader();
  const checkBoxes = buildTableCells();

  return (
    <Table>
      <tbody>
        <tr>{dayNames}</tr>
        <tr>{checkBoxes}</tr>
      </tbody>
    </Table>
  );
};

export default DaySelect;

DaySelect.propTypes = {
  selectedDays: PropTypes.arrayOf(PropTypes.string),
  handleDayChange: PropTypes.func.isRequired
};

const Label = styled.label`
  font-size: 13px;
`;

const TableHeader = styled.td`
  padding-left: 10px;
  padding-right: 10px;
`;

const Table = styled.table`
  margin: 15px 0px 0px 0px;
`;
