import { apiClient } from 'utils/apiClient';
import { ICustomMailMergeTag } from 'types';

async function getCustomMailMergeTags() {
  const url = `custom_mail_merge_tags`;

  const response = await apiClient().get(url);

  return response.data as {
    custom_mail_merge_tags: ICustomMailMergeTag[];
  };
}

export { getCustomMailMergeTags };
