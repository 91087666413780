import { request, POST, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import { FORM_POST_MODAL_NAME } from './constants';

import modal from 'modules/Stores/Modal';
import Papa from 'papaparse';
import FileSaver from 'file-saver';

import { titleForOption } from 'modules/Helpers/IntegrationHelpers';

import { trackEvent } from 'modules/Helpers/Analytics';

import notify from 'modules/Notifications';

export const exportLeadAsCSV = (lead) => {
  const csvContent = Papa.unparse({
    fields: [
      'given_name',
      'surname',
      'email',
      'date_of_birth',
      'gender',
      'address_line_one',
      'address_line_two',
      'city',
      'state',
      'zip_postcode',
      'country',
      'created_at',
      'mobile_number',
      'lead_source_id',
      'opted_out',
      'reason_for_interest',
    ],
    data: [
      [
        lead.given_name,
        lead.surname,
        lead.email,
        lead.date_of_birth,
        lead.gender,
        lead.address_line_one,
        lead.address_line_two,
        lead.city,
        lead.state,
        lead.zip_postcode,
        lead.country,
        lead.created_at,
        lead.mobile_number,
        lead.lead_source_id,
        lead.opted_out,
        lead.reason_for_interest,
      ],
    ],
  });

  const blob = new Blob([csvContent], { type: 'text/csv' });
  FileSaver.saveAs(blob, lead.display_name + '_export.csv');

  trackEvent('Exported Lead', {
    type: 'csv',
  });

  return (dispatch) => {
    dispatch(modal.actions.hideModal());
  };
};

export const exportLead = (username, password, lead, listUpdateProtocol, integrationOption) => {
  return (dispatch) => {
    const body = {
      id: lead.id,
      third_party_integration_id: integrationOption.id,
      username: username,
      password: password,
    };

    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));

      if (integrationOption.name === 'booker') {
        notify.error(errors.errors.error);
      }

      if (integrationOption.name === 'glofox') {
        notify.error(errors.errors.join(', '));
      }

      if (integrationOption.name === 'perfect_gym') {
        notify.error(errors.errors.missing_details);
      }
    }

    function onSuccess() {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success(lead.display_name + ' has been exported to ' + titleForOption(integrationOption.name));
    }

    return request('leads/export', POST, body, onSuccess, onFailure);
  };
};

export const getPostUrlAndData = (lead, integrationOption) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(setFormPostData(payload));
      dispatch(modal.actions.showModal(FORM_POST_MODAL_NAME));
    }
    const url = 'third_party_integrations/' + integrationOption.id + '/post_url_data/' + lead.id;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setFormPostData(formPostData) {
  return {
    type: actionTypes.SET_FORM_POST_DATA,
    formPostData,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}
