import * as actionTypes from './actionTypes'

const QUEUED_INQUIRIES_ACTION_HANDLERS = {
  [actionTypes.QUEUED_INQUIRIES_FETCHING_LEADS]: (state, action) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.QUEUED_INQUIRIES_FETCHING_LEADS_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors }
  },
  [actionTypes.QUEUED_INQUIRIES_FETCHING_LEADS_SUCCEEDED]: (state, action) => {
    return { ...state, loading: false, errors: null, leads: action.leads }
  },
  [actionTypes.REMOVE_LEAD]: (state, action) => {
    const index = indexForLead(state.leads, action.lead.id)
    const updatedLeads = [
      ...state.leads.slice(0, index),
      ...state.leads.slice(index + 1)
    ]
    return { ...state, loading: false, errors: null, leads: updatedLeads }
  },
  [actionTypes.QUEUED_INQUIRIES_ADD_LEAD]: (state, action) => {
    const lead = action.lead
    const shouldAdd = leadShouldAddForLocation(state.locationId, lead)
    if (shouldAdd) {
      const updatedLeads = [
        action.lead,
        ...state.leads]
      return { ...state, loading: false, errors: null, leads: updatedLeads }
    } else {
      return state
    }
  },
  [actionTypes.SELECT_LEAD]: (state, action) => {
    return { ...state, selectedLeadId: action.lead.id }
  },
  [actionTypes.SET_LOCATION_ID]: (state, action) => {
    return { ...state, locationId: action.locationId }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page,
      totalCount: action.pageDetails.total_count
    }
  }
}

function indexForLead (leads, leadId) {
  return leads.findIndex(lead => lead.id === leadId)
}

function leadShouldAddForLocation (locationId, lead) {
  return locationId === lead.location_id
}

const initialState = {
  loading: false,
  locationId: null,
  leads: [],
  selectedLeadId: null,
  errors: null,
  pageCount: 1,
  currentPage: 1,
  totalCount: 0
}

export default function reducer (state = initialState, action) {
  const handler = QUEUED_INQUIRIES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
