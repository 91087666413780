import PropTypes from "prop-types";
import React, { Component } from "react";
import LeadSourceTableView from "./LeadSourceTableView/LeadSourceTableView";
import PagesContainer from "../containers/PagesContainer";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView
} from "modules/components";

export class LeadSourceSelectView extends Component {
  UNSAFE_componentWillMount() {
    const { leadSources } = this.props;
    if (leadSources.length > 0) {
      return;
    }
    this.getLeadSourcesWithPageNumber(1);
  }

  getLeadSourcesWithPageNumber = pageNumber => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  render() {
    const {
      leadSources,
      onLeadSourceSelect,
      renderRow,
      tableHead,
      selectedLeadSourceId,
      minHeight,
      pageDetails,
      loading,
      title
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView
        minHeight={minHeight}
        title={title}
        loading={loading}
        bordered
      >
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"leadSourceSearch"}
            onUpdate={this.searchUpdated}
          />
        </div>
        <div>
          {leadSources.length > 0 && (
            <LeadSourceTableView
              renderRow={renderRow}
              tableHead={tableHead}
              leadSources={leadSources}
              leadSourceSelected={onLeadSourceSelect}
              selectedLeadSourceId={selectedLeadSourceId}
            />
          )}
          {leadSources.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesContainer pageClicked={this.pageClicked} />
            </div>
          )}
        </div>
      </ContentBlockView>
    );
  }
}

LeadSourceSelectView.propTypes = {
  title: PropTypes.string,
  getLeadSources: PropTypes.func.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLeadSourceSelect: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func,
  errors: PropTypes.object,
  selectedLeadSourceId: PropTypes.number,
  minHeight: PropTypes.number,
  tableHead: PropTypes.element
};

LeadSourceSelectView.defaultProps = {
  showSaleDetails: false,
  title: "Lead Sources"
};

export default LeadSourceSelectView;
