import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { TrialsReportView } from "../components/TrialsReportView";
import session from "modules/Stores/Session";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    user: session.selectors.getUser(state),
    queryString: null
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      subscribeToTrialsReport: actions.subscribeToTrialsReport
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrialsReportView);
