import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';

import { history } from 'store/store';

export class QueuedLeadRow extends Component {
  buttonClicked = () => {
    const { lead } = this.props;
    history.push('/secure/manageLeads?lead_id=' + lead.id);
  };

  rowSelected = () => {
    const { lead, leadSelected } = this.props;
    leadSelected(lead);
  };

  leadTypeTag = () => {
    const { lead } = this.props;
    let className = null;
    let value = null;
    if (lead.is_new) {
      className = 'label label-success';
      value = 'New';
    } else {
      className = 'label label-warning';
      value = 'Existing';
    }
    return <span className={className}>{value}</span>;
  };

  render() {
    const { lead } = this.props;
    const createdAtMoment = moment(lead.created_at).fromNow();

    return (
      <tr className="selectable">
        <td className="text-center" onClick={this.rowSelected}>
          <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={48} round={true} />
        </td>
        <td className="font-w600" onClick={this.rowSelected}>
          {lead.display_name}
        </td>
        <td className="font-w600" onClick={this.rowSelected}>
          {createdAtMoment}
        </td>
        <td className="font-w600" onClick={this.rowSelected}>
          {lead.source}
        </td>
        <td onClick={this.rowSelected}>{this.leadTypeTag()}</td>
        <td>
          <button className="btn btn-sm btn-primary" onClick={this.buttonClicked} type="button">
            <i className="fa fa-user" />
          </button>
        </td>
      </tr>
    );
  }
}

QueuedLeadRow.propTypes = {
  lead: PropTypes.object.isRequired,
  leadSelected: PropTypes.func.isRequired,
};
