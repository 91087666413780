import { connect } from 'react-redux'

import PaymentMethodRow from '../components/PaymentMethodRow'

function mapStateToProps (state, ownProps) {
  return {

  }
}

export default connect(mapStateToProps)(PaymentMethodRow)
