import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import leadsList from '../modules/leadsList'
import session from 'modules/Stores/Session'
import userData from 'modules/Stores/UserData'

import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    leads: leadsList.selectors.getLeads(state),
    loading: leadsList.selectors.getLoading(state),
    pageCount: leadsList.selectors.getPageCount(state),
    currentPage: leadsList.selectors.getCurrentPage(state),
    timezone: session.selectors.getUserTimezone(state),
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state),
    selectedLead: leadsList.selectors.selectedLead(state),
    queryString: queryStringSelector(state),
    showingQuickLeadView: leadsList.selectors.getShowingQuickLeadView(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getLeadsWithQueryAndPageNumber: leadsList.actions.getLeadsWithQueryAndPageNumber,
    selectLead: leadsList.actions.select
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(leadsList.components.LeadsListView)
