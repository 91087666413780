export const NAME = 'calls'

export const RECORD_CALL_MODAL = 'RECORD_CALL_MODAL'
export const FINISHED_CALL_MODAL = 'FINISHED_CALL_MODAL'
export const NEW_CALL_MODAL = 'NEW_CALL_MODAL'

export const CALL_SCHEDULE_MODAL = NAME + '/CALL_SCHEDULE_MODAL'

export const STARTED_TRIAL_MODAL = 'STARTED_TRIAL_MODAL'
export const DEAL_MODAL = 'DEAL_MODAL_AFTER_CALL'
