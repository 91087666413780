import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FormPostExportView from '../components/FormPostExportView'
import modal from 'modules/Stores/Modal'
import { FORM_POST_MODAL_NAME } from '../constants'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    title: 'Export To Snapp DD',
    modalName: FORM_POST_MODAL_NAME,
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    currentModal: modal.selectors.getCurrentModal(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    reset: actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPostExportView)
