import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const FilterWrapper = ({ children, title }) => {
  return (
    <Container>
      {title && (
        <Header>
          {title}
        </Header>
      )}
      <Content>
      <ChildContainer>{children}</ChildContainer>
      </Content>
    </Container>
  );
};

export default FilterWrapper;

FilterWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ChildContainer = styled.div`
  margin: 10px 0px 10px 0px;
`;

const Header = styled.div`
  width: 150px;
  font-size: 14px;
  font-weight: "700";
  text-transform: "uppercase";
  text-align: "left";
`;

const Content = styled.div`
  width: 80%;
`;
