import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectToUsersFormContainer from '../containers/SelectToUsersFormContainer'
import UserSelectModalContainer from '../containers/UserSelectModalContainer'

import { SELECT_FROM_USER_MODAL } from '../constants'

export class SelectUsersView extends Component {

  onSelectUser = () => {
    this.props.showModal(SELECT_FROM_USER_MODAL)
  }

  render () {
    const { isActive, fromUser, currentLocationName } = this.props
    const activeClassName = (isActive) ? 'active in' : ''
    const buttonTitle = (fromUser) ? 'From: ' + fromUser.display_name : 'Select a from user'
    return (
      <div className={'tab-pane fade fade-up ' + activeClassName}>
        <h4>Configure users for reassignment at {currentLocationName}</h4>
        <button className='btn btn-primary push-20-t push-20'
          type='button'
          onClick={this.onSelectUser}>
          {buttonTitle}
        </button>
        {fromUser &&
          <div>
            <hr />
            <p>Who should recieve the leads?</p>
            <SelectToUsersFormContainer fromUser={fromUser} />
          </div>
        }
        <UserSelectModalContainer modalName={SELECT_FROM_USER_MODAL} />
      </div>
    )
  }
}

SelectUsersView.propTypes = {
  isActive: PropTypes.bool.isRequired,
  fromUser: PropTypes.object,
  currentLocationName: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired
}

export default SelectUsersView
