import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

export class RawToolTip extends Component {

  render () {
    const { id, text } = this.props
    const place = (this.props.place) || 'top'
    const effect = (this.props.effect) || 'solid'
    const type = (this.props.type) || 'dark'
    return (
      <ReactTooltip id={id} place={place} effect={effect} type={type}>
        {text}
      </ReactTooltip>
    )
  }
}

RawToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  place: PropTypes.string, // [Top, Bottom, Left, Right]
  effect: PropTypes.string, // [Float, Solid]
  type: PropTypes.string // [Dark, Success, Warning, Error, Info, Light]
}

export default RawToolTip
