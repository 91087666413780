import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PageSelection from './PageSelection'

export class PagesView extends Component {

  render () {
    const { pageDetails, pageClicked } = this.props
    return (
      <div>
        {pageDetails.pageCount > 1 &&
          <div className='text-center push'>
            <div className='block block-rounded block-bordered'>
              <PageSelection
                pageCount={pageDetails.pageCount}
                currentPage={pageDetails.currentPage}
                pageClicked={pageClicked}
                pageLimit={10} />
            </div>
          </div>
        }
      </div>
    )
  }
}

PagesView.propTypes = {
  pageDetails: PropTypes.object.isRequired,
  pageClicked: PropTypes.func.isRequired
}

export default PagesView
