import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ChartView from './ChartView'

import { EmptyView } from 'modules/components'

export class StatusCountView extends Component {

  render () {
    const { loading, dataSets, labels, isEmpty } = this.props
    const blockStyle = loading ? 'block block-rounded block-bordered block-opt-refresh'
    : 'block block-rounded block-bordered'

    return (
      <div className={blockStyle}>
        <div className='block-header'>
          <h3 className='block-title'>Number of leads changed to status</h3>
        </div>
        <div className='block-content push-50' style={{ height: '369px', minHeight: '409px' }}>
          {isEmpty &&
            <div className='row'>
              <div className='col-sm-10 col-sm-offset-1' style={{ marginTop: '125px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='fa fa-bar-chart fa-3x'
                  title='No data for these filters'
                  subtitle='Try selecting a different time period.' />
              </div>
            </div>
          }
          {!isEmpty &&
            <ChartView dataSets={dataSets} labels={labels} />
          }
        </div>
      </div>
    )
  }
}

StatusCountView.propTypes = {
  dataSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEmpty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
}

export default StatusCountView
