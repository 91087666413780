import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import billingHistory from '../modules/billingHistory'
import subscription from '../modules/subscription'
import session from 'modules/Stores/Session'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    billingPlan: subscription.selectors.getBillingPlan(state),
    invoices: billingHistory.selectors.getInvoices(state),
    loading: billingHistory.selectors.getLoading(state),
    errors: billingHistory.selectors.getErrors(state),
    pageCount: billingHistory.selectors.getPageCount(state),
    currentPage: billingHistory.selectors.getCurrentPage(state),
    selectedInvoice: billingHistory.selectors.selectedInvoice(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetch: billingHistory.actions.getInvoicesForBillingPlan,
    selectInvoiceId: billingHistory.actions.selectInvoiceId,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(billingHistory.components.BillingHistoryView)
