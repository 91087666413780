import { isInteger, isPresent } from 'utils/validation'

export const TopUpFormValidator = {
  amount: [{
    rule: isInteger,
    errorMessage: 'You need to recharge with a whole number'
  }, {
    rule: isPresent,
    errorMessage: 'You need to recharge with a whole number'
  }]
}
