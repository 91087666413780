import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FiltersForm from '../components/FiltersForm';
import {
  CLUBS,
  USERS,
  LEAD_SOURCES,
  LEAD_CREATED,
  EVENT_OCCURED,
} from 'containers/Reports/Reports/SalesPerformance/modules/filters/constants';
import { salesPerformanceFilters } from '../constants';

function mapStateToProps(state) {
  return {
    calculatingOptions: [LEAD_CREATED, EVENT_OCCURED],
    compareOptions: [CLUBS, USERS, LEAD_SOURCES],
    availableFilters: salesPerformanceFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersForm);
