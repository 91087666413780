import * as actionTypes from './actionTypes'

const FORGOT_PASSWORD_ACTION_HANDLERS = {
  [actionTypes.LOADING]: (state) => {
    return ({ ...state, loading: true, errors: null })
  },
  [actionTypes.LOAD_SUCCESS]: (state, action) => {
    return ({ ...state, loading: false, success: true, errors: null })
  },
  [actionTypes.LOAD_FAILED]: (state, action) => {
    return ({ ...state, loading: false, success: false, errors: action.errors.errors })
  },
  [actionTypes.WHITELABEL_PROFILE]: (state, action) => {
    return ({ ...state, whitelabelProfile: action.whitelabelProfileLogo.whitelabel_profile })
  },
  [actionTypes.RESET]: (state) => {
    return initialState
  }
}

const initialState = {
  loading: false,
  success: null,
  errors: null,
  whitelabelProfile: null
}
export default function reducer (state = initialState, action) {
  const handler = FORGOT_PASSWORD_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
