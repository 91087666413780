import * as actionTypes from './actionTypes'

import { safePropertyForObject } from 'utils/formHelpers'

const PROFILE_ACTION_HANDLERS = {
  [actionTypes.PREFIL_FORM_FOR_USER]: (state, action) => {
    return initialStateForUser(action.user)
  },
  [actionTypes.UPDATE_FORM_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.UPDATING_PROFILE]: (state) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.UPDATE_PROFILE_SUCCESS]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.UPDATE_PROFILE_FAILED]: (state, action) => {
    return { ...state, loading: false, errors: action.errors.errors }
  },
  [actionTypes.AVATAR_WAS_SELECTED]: (state, action) => {
    const updatedAvatar = { ...state.avatar, file: action.file }
    return { ...state, avatar: updatedAvatar }
  },
  [actionTypes.SET_AVAILABLE_SITE_IDS]: (state, action) => {
    return { ...state, availableSiteIds: action.availableSiteIds }
  }
}

function initialStateForUser (user) {
  return {
    errors: null,
    loading: false,
    availableSiteIds: [],
    data: {
      fields: {
        username: {
          value: safePropertyForObject(user, 'username'),
          isValid: false
        },
        givenName: {
          value: safePropertyForObject(user, 'given_name'),
          isValid: false
        },
        surname: {
          value: safePropertyForObject(user, 'surname'),
          isValid: false
        },
        email: {
          value: safePropertyForObject(user, 'email'),
          isValid: false
        },
        positionTitle: {
          value: safePropertyForObject(user, 'position_title'),
          isValid: false
        },
        mobileNumber: {
          value: safePropertyForObject(user, 'mobile_number'),
          isValid: false
        },
        signatureEmail: {
          value: safePropertyForObject(user, 'signature_email'),
          isValid: true
        },
        signatureMobile: {
          value: safePropertyForObject(user, 'signature_phone_number'),
          isValid: true
        },
        timeZone: {
          value: safePropertyForObject(user, 'timezone'),
          isValid: false
        }
      }
    },
    avatar: {
      loading: false,
      file: null,
      progress: null
    }
  }
}

export default function reducer (state = initialStateForUser(null), action) {
  const handler = PROFILE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
