import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, PageSelection } from 'modules/components'
import { NEW_CALL_SCHEDULE_MODAL } from '../constants'
import NewCallScheduleModalContainer from '../containers/NewCallScheduleModalContainer'

import CallScheduleRowContainer from '../containers/CallScheduleRowContainer'

export class ListCallSchedulesView extends Component {

  componentDidMount () {
    this.pageClicked(1)
  }

  pageClicked = (pageNumber) => {
    const { fetchCallSchedulesForPageNumber } = this.props
    fetchCallSchedulesForPageNumber(pageNumber)
  }

  newCallScheduleClicked = () => {
    this.props.selectCallScheduleId(null)
    this.props.showModal(NEW_CALL_SCHEDULE_MODAL)
  }

  render () {
    const { callSchedules, loading } = this.props
    const rows = callSchedules.map((callSchedule, index) => {
      return <CallScheduleRowContainer key={index} callSchedule={callSchedule} />
    })
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <div className='block-options-simple'>
              <button type='button' className='btn btn-xs btn-success' onClick={this.newCallScheduleClicked}>
                <i className='fa fa-plus' />{' '}Create new call schedule
              </button>
            </div>
            <h3 className='block-title'>Your Call Schedules</h3>
          </div>
          <div className='block-content'>
            {callSchedules.length === 0 &&
              <div style={{ paddingBottom: '30px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='si si-call-out'
                  title='No call schedules'
                  subtitle='Call schedules will show here'
                  buttonText='Create a new call schedule'
                  buttonSelected={this.newCallScheduleClicked} />
              </div>
            }
            {callSchedules.length > 0 &&
              <table className='table table-vcenter table-hover'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number of steps</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            }
            {this.props.pageCount > 1 &&
              <div className='text-center push'>
                <PageSelection
                  pageCount={this.props.pageCount}
                  currentPage={this.props.currentPage}
                  pageClicked={this.pageClicked} />
              </div>
            }
          </div>
          <NewCallScheduleModalContainer />
        </div>
      </div>
    )
  }
}

ListCallSchedulesView.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchCallSchedulesForPageNumber: PropTypes.func.isRequired,
  callSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectCallScheduleId: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ListCallSchedulesView
