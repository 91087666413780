import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableView from "../components/TableView";

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    selectedId: selectors.getSelectedAutomaticReportId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectAutomaticReport: actions.selectAutomaticReport
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableView);
