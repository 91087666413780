import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { EmptyView, PageSelection } from 'modules/components'

import ReallocationRowContainer from '../containers/ReallocationRowContainer'

export class PreviousReallocationsView extends Component {

  UNSAFE_componentWillMount () {
    this.pageClicked(1)
  }

  pageClicked = (pageNumber) => {
    const { getReallocationsWithPageNumber } = this.props
    getReallocationsWithPageNumber(pageNumber)
  }

  render () {
    const { reallocations, pageCount, loading, currentPage } = this.props

    const reallocationRows = reallocations.map((reallocation, index) => {
      return (
        <ReallocationRowContainer key={index} reallocation={reallocation} />
      )
    })
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Previous reallocations</h3>
          </div>
          <div className='block-content'>
            {reallocations.length === 0 &&
              <div style={{ paddingBottom: '30px' }}>
                <EmptyView
                  colorThemeClass='primary'
                  iconClass='fa fa-mail-forward'
                  title='No reallocations'
                  subtitle='Previous reallocations will be shown here' />
              </div>
            }
            {reallocations.length > 0 &&
              <table className='table table-vcenter'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Location</th>
                    <th>From User</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {reallocationRows}
                </tbody>
              </table>
            }
            {pageCount > 1 &&
              <div className='text-center push'>
                <PageSelection
                  pageCount={pageCount}
                  currentPage={currentPage}
                  pageClicked={this.pageClicked} />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

PreviousReallocationsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  reallocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  getReallocationsWithPageNumber: PropTypes.func.isRequired
}

export default PreviousReallocationsView
