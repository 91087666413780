import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.emailMapsSettings[NAME].errors
export const getLoading = state => state.emailMapsSettings[NAME].loading
export const getEmailMaps = state => state.emailMapsSettings[NAME].emailMaps
export const getSelectedEmailMapId = state => state.emailMapsSettings[NAME].selectedEmailMapId
export const getCurrentPage = state => state.emailMapsSettings[NAME].currentPage
export const getPageCount = state => state.emailMapsSettings[NAME].pageCount
export const getTotal = state => state.emailMapsSettings[NAME].total
export const getSearchTerm = state => state.emailMapsSettings[NAME].searchTerm

export const selectedEmailMap = createSelector(
  [ getEmailMaps, getSelectedEmailMapId ],
  (emailMaps, selectedEmailMapId) => {
    if (emailMaps && emailMaps.length > 0) {
      const index = emailMaps.findIndex(emailMap => parseInt(emailMap.id) === parseInt(selectedEmailMapId))
      return emailMaps[index]
    }
  }
)
