import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import search from 'modules/Stores/Search';
import { history } from 'store/store';
import { GymLeadsLoader, LeadStatusTag, ImageEmptyView } from 'modules/components';
import Avatar from 'react-avatar';
import { daysAgoString } from 'modules/Helpers/DateHelpers';

import EmptyImage from 'images/Moved.jpg';

export class ResultsView extends React.Component {
  handleClick = (lead) => {
    history.push('/secure/manageLeads?lead_id=' + lead.id);
    this.props.setShowingSearch(false);
  };

  handleClear = () => {
    this.props.setShowingSearch(false);
  };

  render() {
    const { searchResults, loading } = this.props;
    return (
      <div
        className="content-mini content-mini-full content-boxed"
        style={{
          marginTop: 38,
        }}
      >
        <HeaderContainer>
          <h2
            style={{
              marginBottom: 14,
            }}
          >
            Search Results
          </h2>
          <SearchButton
            className="btn btn-default"
            onClick={this.handleClear}
            isHiding={loading}
            style={{
              color: '#646464',
            }}
          >
            <GymLeadsLoader active={loading} center={false} />
            Clear
          </SearchButton>
        </HeaderContainer>
        {searchResults.map((result) => {
          return <LeadListRow key={result.id} lead={result} onSelect={this.handleClick} />;
        })}
        {!loading && searchResults.length === 0 && (
          <ImageEmptyView
            title={'No search results'}
            subtitle={'Try another search term. You can search by name, email and phone number'}
            image={EmptyImage}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    searchResults: search.selectors.getSearchResults(state),
    loading: search.selectors.getLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setShowingSearch: search.actions.setShowingSearch,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);

const LeadListRow = ({ lead, onSelect }) => {
  const rowSelected = function () {
    onSelect(lead);
  };
  return (
    <div className={'lead-list-row block block-rounded block-bordered selectable '} style={{ marginBottom: '4px' }} onClick={rowSelected}>
      <div className="block-content">
        <h3 className="h4" style={{ marginBottom: '8px' }}>
          <span style={{ paddingTop: '2px', paddingRight: '4px' }}>{lead.display_name}</span>
          <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={32} round={true} />
          <span
            style={{
              fontSize: '14px',
              marginLeft: '4px',
            }}
          >
            <LeadStatusTag leadStatus={lead.status} />
          </span>
        </h3>
        <p className={'text-muted'} style={{ fontSize: '12px' }}>
          <span className="label-outline" style={{ marginLeft: '5px', fontSize: '10px' }}>
            {lead.location_name}
          </span>
          <i className="push-5-l fa fa-calendar" /> {daysAgoString(lead, true)}
          {lead.trial_id && (
            <span>
              <i className="push-5-l fa fa-clock-o" />
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchButton = styled.button`
  margin-left: 4px;
  margin-bottom: 12px;
  visibility: ${(props) => (props.isHiding ? 'hidden' : 'visible')};
`;
