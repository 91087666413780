import React from 'react';
import Avatar from 'react-avatar';

const EventView = (props) => {
  const { event } = props;

  const textDecoration = event.completed ? 'line-through' : 'none';
  const icon = iconForEvent(event);

  const displayString = event?.lead?.display_name || 'Proposed time';

  return (
    <span
      style={{
        textDecoration: textDecoration,
      }}
    >
      <Avatar name={event?.user?.display_name} size={20} round={true} color={event?.userColor} />
      {icon} {displayString}
      {event.desc && ':  ' + event.desc}
    </span>
  );
};

export default EventView;

const iconForEvent = (event) => {
  switch (event.type) {
    case 'contact call':
      return <i style={{ marginLeft: 6, marginRight: 6 }} className="si si-call-end" />;
    case 'appointment':
      return <i style={{ marginLeft: 6, marginRight: 6 }} className="si si-calendar" />;
    case 'personal task':
      return <i style={{ marginLeft: 6, marginRight: 6 }} className="si si-close" />;
    default:
      return undefined;
  }
};
