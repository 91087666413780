import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';
import { NAME } from './constants';

import editLocation from './modules/editLocation';
import autoAssign from './modules/autoAssign';
import createLocation from './modules/createLocation';
import createSalesTarget from './modules/salesTargets';
import creditCardModal from './modules/creditCardModal';
import actionAutomationRules from './modules/actionAutomationRules';
import openingHours from './modules/openingHours';
import optIn from './modules/optIn';

const LOCATIONS_ACTION_HANDELRS = {
  [actionTypes.LOCATION_SELECTED]: (state, action) => {
    return { ...state, selectedLocationId: action.location.id };
  },
};

const initialState = {
  selectedLocationId: null,
};

function reducer(state = initialState, action) {
  const handler = LOCATIONS_ACTION_HANDELRS[action.type];

  return handler ? handler(state, action) : state;
}

export default combineReducers({
  [NAME]: reducer,
  [autoAssign.constants.NAME]: autoAssign.reducer,
  [editLocation.constants.NAME]: editLocation.reducer,
  [createLocation.constants.NAME]: createLocation.reducer,
  [createSalesTarget.constants.NAME]: createSalesTarget.reducer,
  [creditCardModal.constants.NAME]: creditCardModal.reducer,
  [actionAutomationRules.constants.NAME]: actionAutomationRules.reducer,
  [optIn.constants.NAME]: optIn.reducer,
  [openingHours.constants.NAME]: openingHours.reducer,
});
