import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormFields } from "modules/components";
import { generateNewFormState } from "utils/formHelpers";
import { SalesTargetsValidator } from "../validators";

const { TextField, SelectField } = FormFields;

export class SalesTargetsForm extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.onInput(newData);
  };

  render() {
    const {
      timeType,
      workingDaysOverride,
      initialCallCount,
      appointmentCount,
      presentationCount,
      referralCount,
      totalSales,
      newLeadsCount,
      initialCallTime,
    } = this.props.data.fields;
    return (
      <div>
        {timeType.value && (
          <div>
            <div className="row">
              <div className="col-md-6">
                <SelectField
                  name="timeType"
                  label="What time period do you want to track by?"
                  possibleOptions={timeType.possibleOptions}
                  displayFieldName={"name"}
                  formFieldStyle=""
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  initialValue={timeType.value}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  name="workingDaysOverride"
                  label="How many working days do you want ?"
                  placeholder="eg. 5"
                  formFieldStyle=""
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  initialValue={workingDaysOverride.value}
                  formGroupIconElement={timeType.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="initialCallTime"
                  label="How soon should a salesperson call a lead after it is assigned to them?"
                  placeholder="eg. 5"
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  initialValue={initialCallTime.value}
                  formGroupIconElement={"Minutes"}
                />
              </div>
              <div className="col-md-6">
                <div style={{ paddingTop: "18px" }}>
                  <TextField
                    name="referralCount"
                    label="How many referral leads should salespeople manage?"
                    placeholder="eg. 5"
                    formFieldStyle=""
                    onChange={this.handleInput}
                    validator={SalesTargetsValidator}
                    initialValue={referralCount.value}
                    formGroupIconElement={timeType.value}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="newLeadsCount"
                  label="How many new leads should salespeople get?"
                  placeholder="eg. 25"
                  formFieldStyle=""
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  value={newLeadsCount.value}
                  formGroupIconElement={timeType.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="initialCallCount"
                  label="How many contacts should a salesperson make ?"
                  placeholder="eg. 20"
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  value={initialCallCount.value}
                  formGroupIconElement={timeType.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="appointmentCount"
                  label="How many appointments should a salesperson make ?"
                  placeholder="eg. 6"
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  value={appointmentCount.value}
                  formGroupIconElement={timeType.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="presentationCount"
                  label="How many presentations should a salesperson make ?"
                  placeholder="eg. 5"
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  value={presentationCount.value}
                  formGroupIconElement={timeType.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="totalSales"
                  label="How many sales should a salesperson make ?"
                  placeholder="eg. 25"
                  onChange={this.handleInput}
                  validator={SalesTargetsValidator}
                  value={totalSales.value}
                  formGroupIconElement={timeType.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <button
                  className="btn btn-primary push-5-r push-10"
                  type="button"
                  onClick={this.props.onSaveSelected}
                >
                  <i className="si si-target" /> Update Sales Targets
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <button
                  className="btn btn-danger push-5-r push-10"
                  type="button"
                  onClick={this.props.onDeleteSelected}
                >
                  <i className="fa fa-times" /> Delete Sales Targets
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SalesTargetsForm.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSaveSelected: PropTypes.func.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default SalesTargetsForm;
