import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import modal from "modules/Stores/Modal";
import LinkedFacebookPageRow from "../components/Confirm/LinkedFacebookPageRow";

import * as actions from "../actions";

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal,
      updateFacebookPageIntegration: actions.updateFacebookPageIntegration,
      deleteFacebookPageIntegration: actions.deleteFacebookPageIntegration
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedFacebookPageRow);
