import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TrialsSelectSectionContainer from 'modules/components/FilterFormSections/containers/TrialsSelectSectionContainer'

function mapStateToProps(state, props) {
  const trialsName = 'trial_ids';
  const trialsData = props.filterData && props.filterData[trialsName] ? props.filterData[trialsName] : { fields: {} };
  return {
    sectionName: trialsName,
    data: trialsData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrialsSelectSectionContainer);
