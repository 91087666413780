import { request, DELETE, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import * as reportsTableActions from '../reportsTable/actions';
import notify from 'modules/Notifications';

export const updateAutomaticReport = (automaticReportId, reportName, userIdsToEmail) => {
  const body = {
    name: reportName,
    user_ids_to_email: userIdsToEmail,
  };

  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(reportsTableActions.updateAutomaticReport(payload.automatic_report));
      dispatch(setLoading(false));
      notify.success('Successfully updated report');
    }

    const url = `automatic_reports/${automaticReportId}`;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

export const deleteAutomaticReport = (automaticReportId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(reportsTableActions.deleteAutomaticReport(automaticReportId));
      dispatch(setLoading(false));
      dispatch(reportsTableActions.selectAutomaticReport(null));
      notify.success('Successfully deleted report');
    }

    const url = `automatic_reports/${automaticReportId}`;
    return request(url, DELETE, null, onSuccess, onFailure);
  };
};

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}
