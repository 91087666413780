import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = state => state.activityReport[NAME].errors;
export const getLoading = state => state.activityReport[NAME].loading;
export const getData = state => state.activityReport[NAME].data;
export const getQueued = state => state.activityReport[NAME].queued;
export const getLastQueryString = state =>
  state.activityReport[NAME].lastQueryString;

export const dataLabels = createSelector(
  [getData],
  data => {
    return data.map(dataSet => dataSet.label);
  }
);

export const isEmpty = createSelector(
  [getData],
  data => {
    if (data.length === 0) {
      return true;
    }
    const firstDataSet = data[0];
    const totals = [];
    if (!firstDataSet) return true;
    const dataSetKeys = Object.keys(firstDataSet.rates);
    dataSetKeys.forEach((dataSetKey, index) => {
      const values = data.map(entryData => {
        return entryData.rates[dataSetKey].count;
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      totals.push(values.reduce(reducer));
    });
    const totalNumberReducer = (accumulator, currentValue) =>
      accumulator + currentValue;
    return totals.reduce(totalNumberReducer) === 0;
  }
);

export const breakdownDataSets = createSelector(
  [getData],
  data => {
    const firstDataSet = data[0];
    const dataSets = [];
    if (!firstDataSet) return dataSets;
    const dataSetKeys = Object.keys(firstDataSet.breakdown);
    dataSetKeys.forEach((dataSetKey, index) => {
      const label = firstDataSet.breakdown[dataSetKey].label;
      const values = data.map(entryData => {
        return entryData.breakdown[dataSetKey].value;
      });
      const colorSet = colorSets[index] || defaultColor;
      dataSets.push({
        label: label,
        data: values,
        backgroundColor: colorSet.backgroundColor,
        hoverBackgroundColor: colorSet.hoverBackgroundColor
      });
    });
    return dataSets;
  }
);

export const ratesDataSets = createSelector(
  [getData],
  data => {
    const firstDataSet = data[0];
    const dataSets = [];
    if (!firstDataSet) return dataSets;
    const dataSetKeys = ["completion", "miss"];
    dataSetKeys.forEach((dataSetKey, index) => {
      const label = firstDataSet.rates[dataSetKey].label;
      const values = data.map(entryData => {
        return entryData.rates[dataSetKey].count;
      });
      const colorSet = percentageColorSets[index] || defaultColor;
      dataSets.push({
        label: label,
        data: values,
        backgroundColor: colorSet.backgroundColor,
        hoverBackgroundColor: colorSet.hoverBackgroundColor
      });
    });
    return dataSets;
  }
);

export const breakdownPieChartData = createSelector(
  [getData],
  data => {
    const firstDataSet = data[0];
    const totals = [];
    if (!firstDataSet) return { datasets: [], labels: [] };
    const labels = [];
    const backgroundColors = [];
    const dataSetKeys = Object.keys(firstDataSet.breakdown);
    dataSetKeys.forEach((dataSetKey, index) => {
      const values = data.map(entryData => {
        return entryData.breakdown[dataSetKey].value;
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      labels.push(firstDataSet.breakdown[dataSetKey].label);
      totals.push(values.reduce(reducer));
      const colorSet = colorSets[index] || defaultColor;
      if (colorSet) {
        backgroundColors.push(colorSet.backgroundColor);
      } else {
        backgroundColors.push("#2222");
      }
    });
    return {
      datasets: [
        {
          data: totals,
          backgroundColor: backgroundColors
        }
      ],
      labels: labels
    };
  }
);

export const ratesPieChartData = createSelector(
  [getData],
  data => {
    const firstDataSet = data[0];
    const totals = [];
    if (!firstDataSet) return { datasets: [], labels: [] };
    const labels = [];
    const dataSetKeys = Object.keys(firstDataSet.rates);
    dataSetKeys.forEach((dataSetKey, index) => {
      const values = data.map(entryData => {
        return entryData.rates[dataSetKey].count;
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      labels.push(firstDataSet.rates[dataSetKey].label);
      totals.push(values.reduce(reducer));
    });
    return {
      datasets: [
        {
          data: totals,
          backgroundColor: ["#46c37b", "#C55448"]
        }
      ],
      labels: labels
    };
  }
);

export const breakdownTableData = createSelector(
  [getData],
  data => {
    const firstDataSet = data[0];
    const rows = [];
    if (!firstDataSet) return [];
    const dataSetKeys = Object.keys(firstDataSet.breakdown);
    dataSetKeys.forEach((dataSetKey, index) => {
      const values = data.map(entryData => {
        return entryData.breakdown[dataSetKey].value;
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      rows.push({
        title: firstDataSet.breakdown[dataSetKey].label,
        count: values.reduce(reducer)
      });
    });
    return rows;
  }
);

export const ratesTableData = createSelector(
  [getData],
  data => {
    const firstDataSet = data[0];
    const rows = [];
    if (!firstDataSet) return [];
    const dataSetKeys = Object.keys(firstDataSet.rates);
    dataSetKeys.forEach((dataSetKey, index) => {
      const values = data.map(entryData => {
        return entryData.rates[dataSetKey].count;
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      rows.push({
        title: firstDataSet.rates[dataSetKey].label,
        count: values.reduce(reducer)
      });
    });
    return rows;
  }
);

const percentageColorSets = [
  {
    backgroundColor: "#46c37b",
    hoverBackgroundColor: "#46c37b"
  },
  {
    backgroundColor: "#C55448",
    hoverBackgroundColor: "#C55448"
  }
];

const colorSets = [
  {
    backgroundColor: "#7fe3f2",
    hoverBackgroundColor: "#14adc4"
  },
  {
    backgroundColor: "#98b9e3",
    hoverBackgroundColor: "#5c90d2"
  },
  {
    backgroundColor: "#c7b7e4",
    hoverBackgroundColor: "#a48ad4"
  },
  {
    backgroundColor: "#83d0c7",
    hoverBackgroundColor: "#44b4a6"
  },

  {
    backgroundColor: "#FDC12D",
    hoverBackgroundColor: "#E9B22A"
  },
  {
    backgroundColor: "#83d0c7",
    hoverBackgroundColor: "#44b4a6"
  },
  {
    backgroundColor: "#3e4a59",
    hoverBackgroundColor: "#999999"
  },
  {
    backgroundColor: "#ff8f8f",
    hoverBackgroundColor: "#ffb8b8"
  },
  {
    backgroundColor: "#7fe3f2",
    hoverBackgroundColor: "#14adc4"
  },
  {
    backgroundColor: "#98b9e3",
    hoverBackgroundColor: "#5c90d2"
  }
];

const defaultColor = {
  backgroundColor: "#ff8f8f",
  hoverBackgroundColor: "#ffb8b8"
};
