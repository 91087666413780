import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';
import 'moment-timezone';

import Modal from 'react-bootstrap-modal';
import 'styles/modalStyles.css';

import DateTimeSelect from 'modules/components/DateTimeSelect/DateTimeSelect';
import ScheduleCalendarContainer from 'modules/components/DateTimeSelect/containers/ScheduleCalendarContainer';
import { FormFields } from 'modules/components';
const { Switch } = FormFields;

export default class ScheduleAppointmentModal extends Component {
  state = {
    dateTimeString: null,
    day: null,
    visitType: 'first_visit',
    length: '30',
  };

  resetState = () => {
    this.setState({ dateTimeString: null, day: null, length: '30' });
  };

  hideModalAndCleanUp = () => {
    this.resetState();
    this.props.hideModal();
  };

  dateUpdated = (newDateTimeString, length) => {
    this.setState({
      ...this.state,
      dateTimeString: newDateTimeString,
      length: length,
    });
  };

  scheduleAppointment = () => {
    const { scheduleAppointment, lead, listUpdateProtocol } = this.props;
    const { dateTimeString, visitType } = this.state;
    const dateTimeMoment = moment(dateTimeString);

    const utcDateTimeAsString = dateTimeMoment.utc().format();

    scheduleAppointment(lead, utcDateTimeAsString, listUpdateProtocol, null, null, visitType, this.state.length);
  };

  onDaySelect = (date) => {
    this.setState({ ...this.state, day: date.format() });
  };

  handleToggle = (fieldName, value) => {
    const visitType = value === true ? 'no_sale_return' : 'first_visit';
    this.setState({ ...this.state, visitType: visitType });
  };

  switchValue = () => {
    const { visitType } = this.state;
    return visitType === 'no_sale_return';
  };

  render() {
    const { currentModal, modalName, lead } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp} dialogClassName="modal-lg">
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-success">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              When will they be coming to the club?
            </h3>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-xs-12">
                  <DateTimeSelect onUpdate={this.dateUpdated} onDaySelect={this.onDaySelect} recomendedLength={this.state.length} />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Switch
                    name="isNoSaleReturn"
                    label={'Is ' + lead.given_name + ' returning after a non sale appointment? (NSR)'}
                    onChange={this.handleToggle}
                    switchStyle="switch-sm switch-primary"
                    value={this.switchValue()}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-md-4 hidden-xs hidden-sm"
              style={{
                padding: '0px',
                marginLeft: '-7px',
                height: '600px',
                width: '300px',
                overflow: 'scroll',
              }}
            >
              <ScheduleCalendarContainer
                onUpdate={this.dateUpdated}
                locationId={lead.location_id}
                date={this.state.day}
                user={lead.cached_salesperson}
              />
            </div>
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
            Cancel
          </button>
          <button
            className="btn btn-success push-5-r push-10"
            type="button"
            disabled={!this.state.dateTimeString}
            onClick={this.scheduleAppointment}
          >
            <i className="si si-calendar" /> Schedule Appointment
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ScheduleAppointmentModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  scheduleAppointment: PropTypes.func.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  currentModal: PropTypes.string,
};

ScheduleAppointmentModal.defaultProps = {
  listUpdateProtocol: {},
};
