import PropTypes from "prop-types";
import React, { Component } from "react";

import { FormFields } from "modules/components";
import moment from "moment";
import "moment-timezone";

const { TextField, PasswordField } = FormFields;
export class FormView extends Component {
  state = {
    password: "",
    username: this.props.data.fields.username.value,
  };

  reauthenticate = (event) => {
    event && event.preventDefault();
    const { location, data, authenticateIntegration } = this.props;
    const { id } = data.fields;
    const { username, password } = this.state;
    authenticateIntegration(location, id.value, username, password);
  };

  handleInput = (fieldName, value, isValid) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  render() {
    const { timezone, data } = this.props;
    const { username, integrationLocationId, expiresAt } = data.fields;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h2 className="h4 push-10">
              This club is now integrated with Perfect Gym
            </h2>
            <p className="text-gray-dark">
              Your API username: {username.value}
              <br />
              Perfect Gym Club ID: {integrationLocationId.value}
              <br />
              API access expires on:{" "}
              {moment(expiresAt.value)
                .tz(timezone)
                .format("dddd MMMM Do, h:mm a")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TextField
              name="username"
              label={"API username"}
              placeholder=""
              formFieldStyle=""
              onChange={this.handleInput}
              value={username.value}
            />
          </div>
          <div className="col-sm-6">
            <PasswordField
              name="password"
              disabled={false}
              label={"API user password"}
              placeholder=""
              formFieldStyle=""
              onChange={this.handleInput}
              value={this.state.password}
            />
          </div>
        </div>
        <div className="row push">
          <div className="col-sm-6">
            <button
              disabled={this.state.password.length < 2}
              className="btn btn-primary"
              onClick={this.reauthenticate}
            >
              Reauthenticate
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FormView.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  authenticateIntegration: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default FormView;
