import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = state => state.importLeads[NAME].loading

export const isReady = createSelector(
  [ getLoading ],
  (loading) => {
    if (loading) return false
    return true
  }
)
