import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getReallocationsWithPageNumber = (pageNumber) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (errors) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setReallocations(payload.bulk_lead_reallocations))
      dispatch(loadPageDetails(payload.meta))
    }

    return request('bulk_lead_reallocations?page[number]=' + pageNumber, GET, null, onSuccess, onFailure)
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setReallocations (reallocations) {
  return {
    type: actionTypes.SET_REALLOCATIONS,
    reallocations
  }
}
