import * as actionTypes from './actionTypes'

const GYMLEADS_NOTIFICATION_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_NOTIFICATIONS]: (state, action) => {
    return { ...state, notifications: action.notifications }
  },
  [actionTypes.ADD_NOTIFICATIONS]: (state, action) => {
    const updatedNotifications = [
      ...state.notifications,
      ...action.notifications
    ]
    return { ...state, notifications: updatedNotifications }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page
    }
  },
  [actionTypes.SET_CURRENT_NOTIFICATION]: (state, action) => {
    return { ...state, currentNotification: action.currentNotification }
  },
  [actionTypes.SET_NOTIFICATION_COUNT]: (state, action) => {
    return { ...state, notificationCount: action.notificationCount }
  },
  [actionTypes.INCREASE_NOTIFICATION_COUNT]: (state, action) => {
    return { ...state, notificationCount: state.notificationCount + 1 }
  },
  [actionTypes.DECREASE_NOTIFICATION_COUNT]: (state, action) => {
    return { ...state, notificationCount: state.notificationCount - 1 }
  }
}

const initialState = {
  notifications: [],
  currentNotification: null,
  notificationCount: 0,
  loading: false,
  pageCount: 1,
  currentPage: 1
}

export default function reducer (state = initialState, action) {
  const handler = GYMLEADS_NOTIFICATION_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
