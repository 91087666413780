import PropTypes from 'prop-types'
import React, { Component } from 'react'

import EmailDomainRow from './EmailDomainRow'
import EmailDomainStatsContainer from '../containers/EmailDomainStatsContainer'
import { InfoView, ContentBlockView } from 'modules/components'

export class EmailDomainView extends Component {

  UNSAFE_componentWillMount () {
    const { domains, getMailDomains } = this.props
    if (domains && domains.length > 0) { return }
    getMailDomains()
  }

  domainSelected = (emailDomain) => {
    this.props.setSelectedDomainId(emailDomain.id)
  }

  render () {
    const { domains, loading, selectedDomainId, selectedDomain } = this.props
    const domainRows = domains.map((domain, index) => {
      const selected = (domain.id === selectedDomainId)
      return <EmailDomainRow domain={domain} key={index} selected={selected} onSelect={this.domainSelected} />
    })
    return (
      <div>
        <InfoView colorThemeClass='info' bordered>
          <p>
            These are your email domains. We set these up so you can send and receive emails to your leads.{' '}
            We seperate transactional and marketing emails to help you manage your reputation. If your bounce{' '}
            or complaint rate gets too high, we may need to disable your marketing or all email functionality, so{' '}
            it's a good idea to keep an eye on these numbers.
          </p>
        </InfoView>
        <ContentBlockView title={'Your email domains'} loading={loading}>
          <div className='list-group selectable push'>
            {domainRows}
          </div>
        </ContentBlockView>
        {selectedDomain &&
          <EmailDomainStatsContainer />
        }
      </div>
    )
  }
}

EmailDomainView.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getMailDomains: PropTypes.func.isRequired,
  setSelectedDomainId: PropTypes.func.isRequired,
  selectedDomain: PropTypes.object,
  selectedDomainId: PropTypes.number
}

export default EmailDomainView
