import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getTabIndex = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].tabIndex
export const getFacebookUser = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].facebookUser
export const getFacebookPages = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].facebookPages
export const getSelectedPageId = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].selectedPageId
export const getVerified = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].verified
export const getIntegration = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].integration
export const getIntegrationPermissionSet = (state) => state.settingsThirdPartyIntegrations.configuration[NAME].permissionSet

export const selectedFacebookPage = createSelector(
  [ getFacebookPages, getSelectedPageId ],
  (facebookPages, selectedPageId) => {
    if (facebookPages && facebookPages.length > 0) {
      const index = facebookPages.findIndex(facebookPage => parseInt(facebookPage.id) === parseInt(selectedPageId))
      return facebookPages[index]
    }
  }
)

export const currentIndexIsReady = createSelector(
  [ getTabIndex, getFacebookUser, getFacebookPages, getSelectedPageId, getVerified ],
  (tabIndex, facebookUser, facebookPages, selectedPageId, verified) => {
    switch (tabIndex) {
      case 0:
        return (facebookUser !== null && facebookUser !== undefined)
      case 1:
        return (selectedPageId !== null && selectedPageId !== undefined)
      case 2:
        return (verified === true)
      default:
        return true
    }
  }
)
