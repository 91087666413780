import * as actionTypes from './actionTypes'

const REPORT_ACTION_HANDLERS = {
  [actionTypes.CLEAR_ALL_FILTERS]: (state) => {
    return { ...state, data: defaultFormState() }
  },
  [actionTypes.UPDATE_FILTER_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.FETCHING_REPORT]: (state) => {
    return { ...state, loading: true }
  },
  [actionTypes.FETCH_REPORT_SUCCESS]: (state, action) => {
    return { ...state, loading: false, report: action.report }
  },
  [actionTypes.FETCH_REPORT_FAILED]: (state) => {
    return { ...state, loading: false }
  }
}

const initialState = {
  leadsIn: 0,
  leadsAllocated: 0,
  percentAllocated: 0,
  closeRate: 0,
  loading: true,
  data: defaultFormState(),
  report: {
    summary_data: {},
    data: []
  }
}

function defaultFormState () {
  return {
  }
}

export default function reducer (state = initialState, action) {
  const handler = REPORT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
