import * as actionTypes from './actionTypes'

const FACEBOOK_MESSAGES_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_SENDING]: (state, action) => {
    return { ...state, sending: action.sending }
  },
  [actionTypes.SET_UPDATING]: (state, action) => {
    return { ...state, updating: action.updating }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_CHAT_ROOM]: (state, action) => {
    return { ...state, chatRoom: action.chatRoom }
  },
  [actionTypes.SET_FACEBOOK_MESSAGES]: (state, action) => {
    return { ...state, facebookMessages: action.facebookMessages }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageCount: action.pageDetails.total_pages,
      currentPage: action.pageDetails.current_page
    }
  },
  [actionTypes.ADD_FACEBOOK_MESSAGE]: (state, action) => {
    const facebookMessages = [
      ...state.facebookMessages,
      action.facebookMessage ]
    return { ...state, facebookMessages: facebookMessages }
  },
  [actionTypes.ADD_FACEBOOK_MESSAGES]: (state, action) => {
    const updatedMessages = action.facebookMessages.concat(state.facebookMessages)
    return { ...state, facebookMessages: updatedMessages }
  }
}

const initialState = {
  loading: false,
  sending: false,
  updating: false,
  chatRoom: null,
  facebookMessages: [],
  errors: null,
  pageCount: 1,
  currentPage: 1
}

export default function reducer (state = initialState, action) {
  const handler = FACEBOOK_MESSAGES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
