import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLoading = state => state.reallocations[NAME].loading
export const getErrors = state => state.reallocations[NAME].errors
export const getSuccess = state => state.reallocations[NAME].success

export const isReady = createSelector(
  [ getSuccess ],
  (success) => {
    return success
  }
)
