/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
import header from "../modules/header";

import ExportButtonContainer from "../containers/ExportButtonContainer";
const {
  CONTACT_CALL_REPORT,
  APPOINTMENT_REPORT,
  TASK_REPORT,
} = header.constants;

export class ReportSelectView extends Component {
  title() {
    const { selectedReport } = this.props;
    switch (selectedReport) {
      case CONTACT_CALL_REPORT:
        return "Contact Calls";
      case APPOINTMENT_REPORT:
        return "Appointments";
      case TASK_REPORT:
        return "Tasks";
      default:
        return "";
    }
  }

  exportAction() {
    const { selectedReport } = this.props;
    switch (selectedReport) {
      case CONTACT_CALL_REPORT:
        return this.props.exportContactCalls;
      case APPOINTMENT_REPORT:
        return this.props.exportAppointment;
      case TASK_REPORT:
        return this.props.exportTasks;
      default:
        return "";
    }
  }

  reportType() {
    const { selectedReport } = this.props;
    switch (selectedReport) {
      case CONTACT_CALL_REPORT:
        return "activity_report_calls";
      case TASK_REPORT:
        return "activity_report_tasks";
      case APPOINTMENT_REPORT:
        return "activity_report_appointments";
      default:
        return "";
    }
  }

  setSelectedReport = (selectedReport) => {
    const { onReportChange } = this.props;
    if (onReportChange) {
      onReportChange(selectedReport);
    }
  };

  render() {
    const { selectedReport } = this.props;
    const exportAction = this.exportAction();
    const reportType = this.reportType();

    return (
      <div className="block">
        <ul className="nav nav-tabs nav-tabs-alt" data-toggle="tabs">
          <li
            className={
              selectedReport === CONTACT_CALL_REPORT ? "active" : "selectable"
            }
            onClick={() => this.setSelectedReport(CONTACT_CALL_REPORT)}
          >
            <a>Contact Calls</a>
          </li>
          <li
            className={
              selectedReport === APPOINTMENT_REPORT ? "active" : "selectable"
            }
            onClick={() => this.setSelectedReport(APPOINTMENT_REPORT)}
          >
            <a>Appointments</a>
          </li>
          <li
            className={selectedReport === TASK_REPORT ? "active" : "selectable"}
            onClick={() => this.setSelectedReport(TASK_REPORT)}
          >
            <a>Tasks</a>
          </li>
          <li
            className="pull-right"
            style={{ marginRight: "6px", marginTop: "6px" }}
          >
            <ExportButtonContainer
              generateFullReport={exportAction}
              reportType={reportType}
            />
          </li>
        </ul>
        <div className="block-content">
          <h2 className="page-heading push-10">{this.title()}</h2>
        </div>
      </div>
    );
  }
}

ReportSelectView.propTypes = {
  selectedReport: PropTypes.string.isRequired,
  onReportChange: PropTypes.func,
  exportContactCalls: PropTypes.func.isRequired,
  exportAppointment: PropTypes.func.isRequired,
  exportTasks: PropTypes.func.isRequired,
};

ReportSelectView.defaultProps = {
  selectedReport: CONTACT_CALL_REPORT,
};
