import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FilterFormSections from 'modules/components/FilterFormSections'
import filters from '../modules/filters'
import header from '../modules/header'
import table from '../modules/table'
import modal from "modules/Stores/Modal";

function mapStateToProps (state) {
  const queryStringSelector = FilterFormSections.selectors.queryStringFactory(filters.selectors.getData)
  return {
    title: 'Sales Performance',
    queued: table.selectors.getQueued(state),
    queryString: queryStringSelector(state),
    compareOption: filters.selectors.getCompareOption(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    generateFullReport: table.actions.generateFullReport,
    showModal: modal.actions.showModal,    
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(header.components.ReportHeaderView)
