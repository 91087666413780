import { request, POST } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { trackEvent } from 'modules/Helpers/Analytics';

import notify from 'modules/Notifications';

import leadGoals from 'modules/Stores/LeadGoals';
import modal from 'modules/Stores/Modal';

export const createLeadGoal = (goalName) => {
  return (dispatch) => {
    const body = {
      lead_goal: {
        goal_name: goalName,
      },
    };

    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccess());
      dispatch(leadGoals.actions.addLeadGoal(payload.lead_goal));
      notify.success('Lead Goal Created');
    }

    trackEvent('Added Lead Goal', {
      goalName: goalName,
    });

    return request('lead_goals', POST, body, onSuccess, onFailure);
  };
};

export const deleteLeadGoal = (leadGoalId, toLeadGoalId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toLeadGoalId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess() {
      dispatch(modal.actions.stopLoading());
      dispatch(leadGoals.actions.removeLeadGoalWithId(leadGoalId));
      notify.success('Lead Goal Migrated');
      dispatch(modal.actions.hideModal());
    }
    const url = 'lead_goals/' + leadGoalId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

export function loadSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

export function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
  };
}
