import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SalesTargets from '../../modules/SalesTargets'
import Session from 'modules/Stores/Session'

function mapStateToProps (state) {
  const salesTargetType = SalesTargets.constants.INDIVIDUAL_TYPE
  return {
    salesTargetResults: SalesTargets.selectors.getSalesTargetResults(state, salesTargetType),
    idToFetch: Session.selectors.getUser(state).id,
    timezone: Session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetch: SalesTargets.actions.fetchSalesTargetResultsForUserId
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTargets.components.UserTargetsView)
