import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class SourceItem extends Component {
  sourceClicked = () => {
    this.props.onSelect(this.props.leadSource);
  };

  deleteClicked = () => {
    if (this.props.leadSource.locked) {
      return;
    }
    this.props.onDelete(this.props.leadSource);
  };

  render() {
    const { leadSource, canEdit } = this.props;
    return (
      <div className="col-lg-4">
        <div
          className="block block-bordered selectable"
          style={{ paddingTop: '5px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}
        >
          <span onClick={this.sourceClicked}>{leadSource.source_name}</span>
          {canEdit && (
            <>
              {leadSource.locked && <i className="pull-right fa fa-lock" />}
              {!leadSource.locked && (
                <button className="btn btn-xs btn-default pull-right" type="button" onClick={this.deleteClicked}>
                  <i className="fa fa-times" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

SourceItem.propTypes = {
  leadSource: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default SourceItem;
