import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import emailMapSelect from '../modules/emailMapSelect'
import editEmailMap from '../modules/editEmailMap'
import modal from 'modules/Stores/Modal'

function mapStateToProps (state) {
  return {
    emailMap: emailMapSelect.selectors.selectedEmailMap(state),
    loading: editEmailMap.selectors.getLoading(state),
    data: editEmailMap.selectors.getData(state),
    errors:  emailMapSelect.selectors.getErrors(state),
    listUpdateProtocol: emailMapSelect.listUpdateProtocol,
    tags: editEmailMap.selectors.getTags(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    saveEmailMap: editEmailMap.actions.saveEmailMap,
    prefilFormForEmailMap: editEmailMap.actions.prefilFormForEmailMap,
    onInput: editEmailMap.actions.onInput,
    deleteEmailMap: editEmailMap.actions.deleteEmailMap,
    changeShowing: editEmailMap.actions.changeShowing,
    showModal: modal.actions.showModal,
    reset: editEmailMap.actions.reset
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(editEmailMap.components.EditEmailMapView)
