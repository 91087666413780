import PropTypes from "prop-types";
import React, { Component } from "react";

import EmailMapSelectContainer from "../containers/EmailMapSelectContainer";
import EditEmailMapContainer from "../containers/EditEmailMapContainer";
import EmailMapResultsContainer from "../containers/EmailMapResultsContainer";
import { InfoView } from "modules/components";

export class EmailMapsView extends Component {
  render() {
    const { editEmailMapShowing, selectedEmailMap } = this.props;
    const infoString = (
      <span>
        Email maps allow you to automatically create leads from an incoming
        email. This is useful for contact forms or landing pages that send an
        email whenever it is completed. Create and manage your email maps here.
        Visit{" "}
        <strong>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://support.gymleads.net/integrations/incoming-integrations/email-to-lead-mapping"
          >
            the knowledge base
          </a>
        </strong>{" "}
        For more details.
      </span>
    );
    return (
      <div>
        <InfoView
          colorThemeClass={"info"}
          title={"Email Maps"}
          detail={infoString}
        />
        <div className="row">
          <div className="col-md-12">
            <EmailMapSelectContainer />
          </div>
        </div>
        {editEmailMapShowing && (
          <div className="row">
            <div className="col-md-12">
              <EditEmailMapContainer />
            </div>
          </div>
        )}
        {selectedEmailMap && <EmailMapResultsContainer />}
      </div>
    );
  }
}

EmailMapsView.propTypes = {
  editEmailMapShowing: PropTypes.bool.isRequired,
  selectedEmailMap: PropTypes.object,
};

export default EmailMapsView;
