import { request, POST } from 'utils/apiUtils';

export const goToSignup = () => {
  return (dispatch) => {
    const body = { to: 'GymLeads Signup' };

    function onFailure(payload) {}

    function onSuccess(payload) {
      localStorage.clear();
      window.open(payload.url, '_self');
    }

    return request('sso_tokens/', POST, body, onSuccess, onFailure);
  };
};
