import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { history } from 'store/store';

import { iconForNotification } from '../helpers';

export class NotificationItem extends Component {
  notificationClicked = () => {
    const { notification, setNotificationAsRead } = this.props;
    if (!notification) {
      return;
    }
    const owner = notification.owner;
    if (!owner) {
      return;
    }

    const lead = owner.notification_lead;
    if (!lead) {
      return;
    }

    setNotificationAsRead(notification);
    history.push('/secure/manageLeads?lead_id=' + lead.id);
  };

  render() {
    const { notification } = this.props;
    const completedMoment = moment(notification.created_at).fromNow();
    const bgColor = notification.read === false ? '#edf2fa' : '#fff';
    return (
      <li className="notification selectable" onClick={this.notificationClicked} style={{ backgroundColor: bgColor }}>
        <i className={'notification-icon ' + iconForNotification(notification)} />
        <p className="notification-detail">{notification.title}</p>
        <p className="notification-time">{completedMoment}</p>
      </li>
    );
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  setNotificationAsRead: PropTypes.func.isRequired,
};

export default NotificationItem;
