import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from 'modules/components';

import LocationSelect from 'modules/components/LocationSelect';

import { SELECT_LOCATION_MODAL, SHOW_ALL, SHOW_COMPLETED, SHOW_INCOMPLETED } from '../constants';

export class LocationSelectView extends Component {
  componentDidMount() {
    const { selectedLocationId, defaultLocation, selectLocation } = this.props;
    if (selectedLocationId) {
      return;
    }
    selectLocation(defaultLocation);
  }

  handleSelection = (event) => {
    this.props.setShowCompletedOption(event.target.id);
  };

  locationSelected = (location) => {
    const { selectUsers, selectLocation, hideModal, user } = this.props;
    selectUsers([user]);
    selectLocation(location);
    hideModal();
  };

  showSelectLocationsModal = () => {
    this.props.showModal(SELECT_LOCATION_MODAL);
  };

  render() {
    const { showingSelection, selectedLocationId, selectedLocationName } = this.props;
    const buttonString = selectedLocationName ? 'Location: ' + selectedLocationName : 'Select Location';
    return (
      <Box isFlex column>
        <Box>
          <button className="btn btn-primary" type="button" onClick={this.showSelectLocationsModal}>
            {buttonString}
          </button>
          <LocationSelect.LocationSelectModalContainer
            modalName={SELECT_LOCATION_MODAL}
            selectedLocationId={selectedLocationId}
            onLocationSelect={this.locationSelected}
          />
        </Box>
        {selectedLocationId && (
          <Box column>
            <Box>
              <label className="css-input css-radio css-radio-primary">
                <input type="radio" id={SHOW_ALL} onChange={this.handleSelection} checked={showingSelection === SHOW_ALL} />
                <span /> All
              </label>
            </Box>
            <Box>
              <label className="css-input css-radio css-radio-primary">
                <input type="radio" id={SHOW_COMPLETED} onChange={this.handleSelection} checked={showingSelection === SHOW_COMPLETED} />
                <span /> Completed
              </label>
            </Box>
            <Box>
              <label className="css-input css-radio css-radio-primary">
                <input type="radio" id={SHOW_INCOMPLETED} onChange={this.handleSelection} checked={showingSelection === SHOW_INCOMPLETED} />
                <span /> Incomplete
              </label>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

LocationSelectView.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  selectedLocationId: PropTypes.number,
  selectedLocationName: PropTypes.string,
  selectLocation: PropTypes.func.isRequired,
  selectUsers: PropTypes.func.isRequired,
  showingSelection: PropTypes.string.isRequired,
  setShowCompletedOption: PropTypes.func.isRequired,
  defaultLocation: PropTypes.object.isRequired,
};
