import PropTypes from 'prop-types'
import React, { Component } from 'react'

import BillingHistoryContainer from '../containers/BillingHistoryContainer'
import SubscriptionContainer from '../containers/SubscriptionContainer'
import SubscriptionAddOnContainer from '../modules/subscriptionAddOn/containers/SubscriptionAddOnContainer'

export class BillingView extends Component {

  render () {
    const { billingPlan } = this.props
    return (
      <div>
        <SubscriptionContainer />
        <SubscriptionAddOnContainer />
        {billingPlan.id &&
          <BillingHistoryContainer />
        }
      </div>
    )
  }
}

BillingView.propTypes = {
  billingPlan: PropTypes.object
}

export default BillingView
