import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectSmsView from '../components/SelectSmsView'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    smsAccounts: selectors.getSmsAccounts(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    pageDetails: selectors.getPageDetails(state),
    selectedSmsAccountId: selectors.getSelectedSmsAccountId(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getSmsAccounts: actions.getSmsAccounts,
    onSmsAccountSelect: actions.setSelectedSmsAccount
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSmsView)
