import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorPanelForErrors } from 'utils/formHelpers'

import SalesTargetsForm from './SalesTargetsForm'

export class SalesTargetsView extends Component {

  UNSAFE_componentWillMount () {
    const { salesTargets, prefilSalesTargetsData } = this.props
    prefilSalesTargetsData(salesTargets)
  }

  saveClicked = () => {
    const { updateSalesTargetWithData, salesTargets, data } = this.props
    updateSalesTargetWithData(salesTargets, data)
  }

  render () {
    const { loading, data, errors, onInput } = this.props
    const blockStyle = (loading) ? 'block-opt-refresh' : ''
    return (
      <div>
        <div className={'block block-rounded block-bordered ' + blockStyle} >
          <div className='block-header'>
            <h3 className='block-title'>Sales Targets</h3>
          </div>
          <div className='block-content'>
            <form className='form-horizontal push-5-t'>
              {errorPanelForErrors(errors)}
              <SalesTargetsForm
                data={data}
                onSaveSelected={this.saveClicked}
                onInput={onInput} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

SalesTargetsView.propTypes = {
  prefilSalesTargetsData: PropTypes.func.isRequired,
  updateSalesTargetWithData: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  salesTargets: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default SalesTargetsView
