import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditLeadSourceModal from '../components/EditLeadSourceModal'

import * as leadSourceActions from '../actions'

import modal from 'modules/Stores/Modal'
import lawfulProcessingBases from 'modules/Stores/LawfulProcessingBases'
const { hideModal } = modal.actions

function mapStateToProps (state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    lawfulProcessingBases: lawfulProcessingBases.selectors.getLawfulProcessingBases(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal,
    updateLeadSource: leadSourceActions.update
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadSourceModal)
