import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import modal from 'modules/Stores/Modal'
import userData from 'modules/Stores/UserData'
import newRegion from '../modules/newRegion'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    errors: modal.selectors.getModalErrors(state),
    currentModal: modal.selectors.getCurrentModal(state),
    modalName: newRegion.constants.MODAL_NAME
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal,
    createRegion: newRegion.actions.createRegion,
    addRegionToList: userData.actions.addRegion
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(newRegion.components.NewRegionModalView)
