import { combineReducers } from 'redux';

import callScheduleSelect from './modules/callScheduleSelect';
import editCallSchedule from './modules/editCallSchedule';
import callScheduleSteps from './modules/callScheduleSteps';

export default combineReducers({
  [callScheduleSelect.constants.NAME]: callScheduleSelect.reducer,
  [editCallSchedule.constants.NAME]: editCallSchedule.reducer,
  [callScheduleSteps.constants.NAME]: callScheduleSteps.reducer,
});
