import * as actionTypes from './actionTypes'

const MODAL_ACTION_HANDLERS = {
  [actionTypes.SHOW_MODAL]: (state, action) => {
    return { ...state, currentModal: action.modalName, errors: null }
  },
  [actionTypes.HIDE_MODAL]: (state, action) => {
    return { ...state, currentModal: null, errors: null }
  },
  [actionTypes.START_LOADING]: (state, action) => {
    return { ...state, loading: true, errors: null }
  },
  [actionTypes.STOP_LOADING]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.SHOW_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors.errors }
  }
}

const initialState = {
  errors: null,
  loading: false,
  currentModal: null
}

export default function reducer (state = initialState, action) {
  const handler = MODAL_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
