import { request, GET, PUT, DELETE } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';

import getAutoAssignRule from '../getAutoAssignRule';
import moment from 'moment';
import notify from 'modules/Notifications';

export const updateAutoAssignRuleWithData = (location, autoAssignRule, formData) => {
  return (dispatch) => {
    const body = {
      auto_assign_rule: seralizeForFormData(formData),
    };
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(getAutoAssignRule.actions.setAutoAssignRule(payload.auto_assign_rule));
      notify.success('auto-assign rule updated');
      dispatch(setErrors(null));
    }
    const url = 'locations/' + location.id + '/auto_assign_rules/' + autoAssignRule.id;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function prefilFormData(autoAssignRule) {
  return {
    type: actionTypes.PREFILL_FORM_DATA,
    autoAssignRule,
  };
}

export const deleteAutoAssignRule = (location, autoAssignRule) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(getAutoAssignRule.actions.setAutoAssignRule(null));
      dispatch(setLoading(false));
      notify.success('auto-assign rule deleted');
    }
    const url = 'locations/' + location.id + '/auto_assign_rules/' + autoAssignRule.id;
    return request(url, DELETE, null, onSuccess, onFailure);
  };
};

function seralizeForFormData(formData) {
  const { enabled, endTime, startTime, user } = formData.fields;

  let payload = {};
  const enabledValue = enabled.value === true ? moment().format() : null;

  payload = { ...payload, enabled_at: enabledValue };
  payload = { ...payload, end_time: endTime.value };
  payload = { ...payload, start_time: startTime.value };

  if (user.value) {
    payload = { ...payload, user_id: user.value.id };
  } else {
    payload = { ...payload, user_id: null };
  }

  return payload;
}

// Set User Container

export const getPotentialUsersForLocationId = (locationId, pageNumber, searchText) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setPotentialUsers(payload.users));
      dispatch(setPageDetails(payload.meta));
    }

    let url = 'users?location_ids[]=' + locationId + '&page[number]=' + pageNumber + '&detail=true';
    if (searchText) {
      url = url + '&search=' + searchText;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setPotentialUsers(potentialUsers) {
  return {
    type: actionTypes.SET_POTENTIAL_USERS,
    potentialUsers,
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
