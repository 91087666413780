import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

import { transformDataToSection } from "modules/components/SelectDateRangeButton/helpers.js";
import { pluralizationHelper } from "modules/Helpers/PluralizationHelpers";
import { FormFields, ToolTip } from "modules/components";
import { ReportFilterFormStyler } from "modules/components/FilterFormSections/components";
import {
  LeadStatusContainer,
  HeardAboutMethodsSectionContainer,
  LeadSourceSectionContainer,
  TagsSectionContainer,
  AssignedToContainer,
  LocationsSectionContainer,
  RegionsSectionContainer,
  StatusUpdatedRangeContainer,
  LeadLostReasonsContainer,
  DealsContainer,
  CountriesSectionContainer
} from "modules/components/FilterFormSections/modules/filterOptions";

const { Switch } = FormFields;

export class FiltersView extends Component {
  componentDidMount() {
    const { getLeadsCountWithQuery, queryString } = this.props;
    getLeadsCountWithQuery(queryString);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      const { getLeadsCountWithQuery, queryString } = nextProps;
      getLeadsCountWithQuery(queryString);
    }
  }

  onUpdate = (sectionData, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = { ...sections, [sectionName]: sectionData };
    updateFilters(newSections);
  };

  onDateSelect = (data, sectionName) => {
    const sections = this.props.data;
    const { updateFilters } = this.props;
    const newSections = transformDataToSection(data, sectionName, sections);
    updateFilters(newSections);
  };

  toggleFilters = () => {
    const { showingMore, showFilters, hideFilters } = this.props;
    showingMore === true ? hideFilters() : showFilters();
  };

  handleStatusDateToggle = (name, value) => {
    this.props.setUseStatusDate(value);
  };

  render() {
    const { showingMore, activeExtraFiltersCount, useStatusDate } = this.props;
    const leadStatuses = "statuses";

    const leadStatusesData = this.props.data[leadStatuses] || { fields: {} };
    const showMoreFiltersTitle =
      activeExtraFiltersCount > 0
        ? pluralizationHelper("active filters", activeExtraFiltersCount)
        : "Show more filters";
    const moreFiltersTitle = showingMore
      ? "Hide filters"
      : showMoreFiltersTitle;
    const moreFiltersClass =
      activeExtraFiltersCount > 0 ? "btn btn-info" : "btn btn-default";
    const statusCounts = Object.keys(leadStatusesData.fields).length;

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-content">
          <div className="row push-20">
            <div className="col-sm-2 text-center">
              <ReportFilterFormStyler title="Date Range" primary={true}>
                <StatusUpdatedRangeContainer
                  onUpdate={this.onDateSelect}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-sm-2 text-center">
              <ReportFilterFormStyler title="Locations" primary={true}>
                <LocationsSectionContainer
                  onUpdate={this.onUpdate}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-sm-2 text-center">
              <ReportFilterFormStyler title="Salespeople" primary={true}>
                <AssignedToContainer
                  onUpdate={this.onUpdate}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-md-2">
              <ReportFilterFormStyler title="Lead Sources" primary>
                <LeadSourceSectionContainer
                  onUpdate={this.onUpdate}
                  filterData={this.props.data}
                />
              </ReportFilterFormStyler>
            </div>

            <div className="col-sm-4">
              <br />
              <MoreFiltersButton
                className={moreFiltersClass}
                type="button"
                onClick={this.toggleFilters}
              >
                <i className="fa fa-filter pull-left" />
                {moreFiltersTitle}{" "}
              </MoreFiltersButton>
            </div>
          </div>

          {showingMore === true && (
            <div>
              <div className="row push-20">
                <div className="col-md-2">
                  <ReportFilterFormStyler title="Heard About Methods" primary>
                    <HeardAboutMethodsSectionContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
                <div className="col-md-2">
                  <ReportFilterFormStyler title="Regions" primary>
                    <RegionsSectionContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
                <div className="col-md-2">
                  <ReportFilterFormStyler title="Countries" primary>
                    <CountriesSectionContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
                <div className="col-md-2">
                  <ReportFilterFormStyler title="Lost Reasons" primary>
                    <LeadLostReasonsContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
                <div className="col-md-2">
                  <ReportFilterFormStyler title="Deals" primary>
                    <DealsContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
                <div className="col-md-4">
                  <ReportFilterFormStyler title="Statuses">
                    <LeadStatusContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
                <div className="col-md-4">
                  <ReportFilterFormStyler title="Tags">
                    <TagsSectionContainer
                      onUpdate={this.onUpdate}
                      filterData={this.props.data}
                    />
                  </ReportFilterFormStyler>
                </div>
              </div>
              <div className="row">
                {statusCounts > 0 && (
                  <div
                    className="col-md-4"
                    style={{ marginTop: "25px", display: "inline" }}
                  >
                    <span style={{ display: "inline-block" }}>
                      <Switch
                        name="useEventDate"
                        label={
                          <span>
                            Date changed to status.{" "}
                            <ToolTip
                              text={
                                "If this is on, we will filter the results based on " +
                                "the date the lead was closed/lost/etc. If it is off, we will use " +
                                "the date the lead was created"
                              }
                              id={"lead-event-date"}
                            />
                          </span>
                        }
                        onChange={this.handleStatusDateToggle}
                        switchStyle="switch-sm switch-primary"
                        value={useStatusDate}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

FiltersView.propTypes = {
  useStatusDate: PropTypes.bool.isRequired,
  setUseStatusDate: PropTypes.func.isRequired,
  showingMore: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  showFilters: PropTypes.func.isRequired,
  hideFilters: PropTypes.func.isRequired,
  activeExtraFiltersCount: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  queryString: PropTypes.string.isRequired,
  getLeadsCountWithQuery: PropTypes.func.isRequired
};

export default FiltersView;

const MoreFiltersButton = styled.button`
  margin: 5px 0px 0px 0px;
`;
