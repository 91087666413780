import { NAME, SHOW_ALL, SHOW_COMPLETED, SHOW_INCOMPLETED } from './constants'
import { createSelector } from 'reselect'

export const getSelectedLocationId = state => state.calendar[NAME].selectedLocationId
export const getSelectedLocationName = state => state.calendar[NAME].selectedLocationName
export const getShowCompletedOption = state => state.calendar[NAME].showCompletedOption

export const showCompletedOnly = createSelector(
  [ getShowCompletedOption ],
  (showCompletedOption) => {
    switch (showCompletedOption) {
      case SHOW_ALL:
        return null
      case SHOW_COMPLETED:
        return true
      case SHOW_INCOMPLETED:
        return false
      default:
        return null
    }
  }
)
