export function deDuplicateByProperty (arr, property) {
  const hashTable = {}

  return arr.filter((object) => {
    const match = Boolean(hashTable[property])
    return (match ? false : hashTable[property] === true)
  })
}

export function arraysEqual (a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}
