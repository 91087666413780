export function chartDataForSummaryData (data) {
  const allDeals = getAllDealsFromData(data)
  if (allDeals.length === 0) {
    return {}
  }

  const values = []
  allDeals.forEach((deal, index) => {
    const id = deal['id']
    let total = 0
    data.forEach((entity) => {
      const entityData = entity.data
      const index = entityData.findIndex(entityDataItem => entityDataItem.id === id)
      total = (index > -1) ? total + entityData[index].count : total
    })
    values.push(total)
  })

  return {
    labels: allDeals.map(reason => reason.label),
    datasets: [
      {
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        data: values
      }
    ]
  }
}

function getAllDealsFromData (data) {
  let allDeals = data.map(entity => entity.data)
  allDeals = [].concat.apply([], allDeals)
  allDeals = allDeals.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) === pos
  })
  return allDeals.sort((a, b) => b.id - a.id)
}

const colors = [
  '#CCDCF1', '#98B9E3', '#5C90D2', '#3E4A59', '#2C343F', '#E4DCF2',
  '#C7B7E4', '#A48AD4', '#FFB8B8', '#FF8F8F', '#FF6B6B',
  '#A8DED8', '#83D0C7', '#44B4A6', '#C4F2F9', '#7FE3F2', '#14ADC4',
  '#FFB9D0', '#FF90B5', '#FF6C9D', '#CCDCF1', '#98B9E3', '#5C90D2',
  '#3E4A59', '#2C343F', '#E4DCF2',
  '#C7B7E4', '#A48AD4', '#FFB8B8', '#FF8F8F', '#FF6B6B',
  '#A8DED8', '#83D0C7', '#44B4A6', '#C4F2F9', '#7FE3F2', '#14ADC4',
  '#FFB9D0', '#FF90B5', '#FF6C9D'
]
