import { request, POST, GET } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import notify from 'modules/Notifications';
import modal from 'modules/Stores/Modal';

import calendar from 'modules/components/Calendar';
import moment from 'moment';
import 'moment-timezone';

import { CREATE_TASK_MODAL } from './constants';

export function updateFilters(filterData) {
  return {
    type: actionTypes.UPDATE_FILTER_DATA,
    filterData,
  };
}

export function setTaskDetails(startTime, length) {
  return (dispatch) => {
    dispatch(setStartTime(startTime));
    dispatch(setLength(length));
    dispatch(modal.actions.showModal(CREATE_TASK_MODAL));
  };
}

function setStartTime(startTime) {
  return {
    type: actionTypes.SET_TASK_DATE,
    startTime,
  };
}

function setLength(length) {
  return {
    type: actionTypes.SET_TASK_LENGTH,
    length,
  };
}

function setLeads(leads) {
  return {
    type: actionTypes.SET_LEADS,
    leads,
  };
}

export function setSelectedLeadId(selectedLeadId) {
  return {
    type: actionTypes.SET_SELECTED_LEAD_ID,
    selectedLeadId,
  };
}

export function presetUserIdsInFilters(userId, locationId, data) {
  return (dispatch) => {
    const currentUserHash = { value: true, display: userId, isValid: true };
    const currentLocationHash = {
      value: true,
      display: locationId,
      isValid: true,
    };
    const userIdData = { fields: { [userId]: currentUserHash } };
    const locationIdData = { fields: { [locationId]: currentLocationHash } };
    const newSections = {
      ...data,
      assigned_to_ids: userIdData,
      location_ids: locationIdData,
    };
    dispatch(updateFilters(newSections));
  };
}

export const searchForLeadsWithQueryString = (queryString) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(setLeads(payload.leads));
      dispatch(modal.actions.stopLoading());
    }

    return request('leads?' + queryString, GET, null, onSuccess, onFailure);
  };
};

export const scheduleTask = (startTime, length, userId, locationId, title, timezone, user, color) => {
  return (dispatch) => {
    const body = {
      personal_task: {
        task_name: title,
        start_time: startTime,
        length: length,
        user_id: userId,
        location_id: locationId,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success('Schedule updated');
      const convertedEvents = convertPersonalTasksToEvents([payload.personal_task], timezone, user, color);
      dispatch(calendar.actions.addEvents(convertedEvents));
    }

    return request('personal_tasks', POST, body, onSuccess, onFailure);
  };
};

export const scheduleAppointment = (lead, dueDate, length, timezone, user, color) => {
  return (dispatch) => {
    const body = {
      appointment: {
        due_date: dueDate,
        length: length,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success('Appointment scheduled');
      const convertedEvents = convertAppointmentsToEvents([payload.appointment], timezone, user, color);
      dispatch(calendar.actions.addEvents(convertedEvents));
    }

    return request('leads/' + lead.id + '/appointments', POST, body, onSuccess, onFailure);
  };
};

export const scheduleCall = (lead, dueDate, length, timezone, user, color) => {
  return (dispatch) => {
    const body = {
      contact_call: {
        due_date: dueDate,
        length: length,
      },
    };
    dispatch(modal.actions.startLoading());

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      notify.success('Call scheduled');

      const convertedEvents = convertCallsToEvents([payload.contact_call], timezone, user, color);
      dispatch(calendar.actions.addEvents(convertedEvents));
    }

    return request('leads/' + lead.id + '/contact_calls', POST, body, onSuccess, onFailure);
  };
};

function convertAppointmentsToEvents(appointments, timezone, user, color) {
  return appointments.map((appointment) => {
    const startDate = moment(appointment.due_date).tz(timezone);
    const endDate = startDate.clone();
    endDate.add(appointment.length, 'minutes');
    const type = 'appointment';
    const id = type + appointment.id;

    return {
      id: id,
      lead: appointment.lead,
      serverid: appointment.id,
      start: startDate.toDate(),
      end: endDate.toDate(),
      title: `Appointment with ${appointment.lead.display_name}`,
      completed: appointment.completed,
      user: user,
      color: '#3D7CC9',
      type: type,
    };
  });
}

function convertCallsToEvents(calls, timezone, user, color) {
  return calls.map((call) => {
    const startDate = moment(call.due_date).tz(timezone);
    const endDate = startDate.clone();
    endDate.add(call.length, 'minutes');
    const type = 'contact call';
    const id = type + call.id;

    return {
      id: id,
      lead: call.lead,
      serverid: call.id,
      start: startDate.toDate(),
      end: endDate.toDate(),
      title: `Call ${call.lead.display_name}`,
      completed: call.completed,
      user: user,
      color: '#00BC70',
      type: type,
    };
  });
}

export function convertPersonalTasksToEvents(personalTasks, timezone, user, color) {
  return personalTasks.map((personalTask) => {
    const startDate = moment(personalTask.start_time).tz(timezone);
    const endDate = startDate.clone();
    endDate.add(personalTask.length, 'minutes');
    const type = 'personal task';
    const id = type + personalTask.id;

    return {
      id: id,
      serverid: personalTask.id,
      lead: null,
      start: startDate.toDate(),
      end: endDate.toDate(),
      title: personalTask.task_name,
      completed: false,
      user: user,
      color: '#F7AC02',
      type: type,
    };
  });
}
