import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import LocationSelectContainer from '../containers/LocationSelectContainer';
import UserSelectContainer from '../containers/UserSelectContainer';

import { SELECT_LOCATION_VIEW, SELECT_USER_VIEW } from '../constants';

export class AssignLeadModal extends Component {
  cleanAndHideModal = () => {
    const { reset, hideModal } = this.props;
    reset();
    hideModal();
  };

  render() {
    const { lead, loading, currentModal, modalName, currentView, listUpdateProtocol, locationName } = this.props;

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        {lead && (
          <Modal show={currentModal === modalName} onHide={this.cleanAndHideModal}>
            <div className={blockStyle}>
              <div className="block-header bg-primary">
                <ul className="block-options">
                  <li>
                    <button data-dismiss="modal" type="button" onClick={this.cleanAndHideModal}>
                      <i className="si si-close" style={{ color: 'white' }} />
                    </button>
                  </li>
                </ul>
                <h3 className="block-title" style={{ color: 'white' }}>
                  Assign {lead.given_name} at {locationName}
                </h3>
              </div>
              {currentView === SELECT_USER_VIEW && <UserSelectContainer lead={lead} listUpdateProtocol={listUpdateProtocol} />}
              {currentView === SELECT_LOCATION_VIEW && <LocationSelectContainer />}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

AssignLeadModal.propTypes = {
  currentView: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  listUpdateProtocol: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  lead: PropTypes.object,
  currentModal: PropTypes.string,
};

export default AssignLeadModal;
