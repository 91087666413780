import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MigrateLeadGoalModal from "../components/MigrateLeadGoalModal";

import * as actions from "../actions";
import leadGoals from "modules/Stores/LeadGoals";
import * as leadGoalApiActions from "modules/Stores/LeadGoals/apiActions";

import modal from "modules/Stores/Modal";
const { hideModal } = modal.actions;

function mapStateToProps(state) {
  return {
    loading: state.modal.loading,
    currentModal: state.modal.currentModal,
    leadGoals: leadGoals.selectors.getLeadGoals(state),
    errors: modal.selectors.getModalErrors(state),
    pageDetails: leadGoals.selectors.getPageDetails(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      getLeadGoals: leadGoalApiActions.getLeadGoals,
      deleteLeadGoal: actions.deleteLeadGoal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MigrateLeadGoalModal);
