import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { errorPanelForErrors } from 'utils/formHelpers';

import { getSafeUTCDateAsStringFromDate } from 'modules/Helpers/DateHelpers';

import moment from 'moment';

import Datetime from 'react-datetime';
import 'styles/react-datetime.css';

export class SelectDOBModal extends Component {
  state = {
    date: null,
    errors: null,
  };

  hideModalAndCleanUp = () => {
    this.setState({ date: null });
    this.props.hideModal();
  };

  setLeadDOB = (event) => {
    event && event.preventDefault();
    const { lead, updateLead, listUpdateProtocol } = this.props;
    const { date } = this.state;

    const UTCDateAsStringFromDate = getSafeUTCDateAsStringFromDate(date);
    if (UTCDateAsStringFromDate == null) {
      this.setState({
        ...this.state,
        errors: { invalidDate: 'That does not look like a valid date' },
      });
      return;
    }

    this.setState({ ...this.state, errors: null });

    const dobPayload = { date_of_birth: UTCDateAsStringFromDate };
    updateLead(lead, dobPayload, listUpdateProtocol.updateLeadDetails);
  };

  render() {
    const dateOfBirth = this.props.lead.date_of_birth;
    let selectedDate = dateOfBirth ? moment(dateOfBirth) : '';
    selectedDate = this.state.date ? this.state.date : selectedDate;

    const { currentModal, modalName, lead, formats } = this.props;
    return (
      <Modal show={currentModal === modalName} onHide={this.hideModalAndCleanUp}>
        <div className="block" style={{ marginBottom: '0px' }}>
          <div className="block-header bg-primary">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={this.hideModalAndCleanUp}>
                  <i className="si si-close" style={{ color: 'white' }} />
                </button>
              </li>
            </ul>
            <h3 className="block-title" style={{ color: 'white' }}>
              What is {lead.given_name} 's date of birth?
            </h3>
          </div>
          <div className="block-content">
            {errorPanelForErrors(this.state.errors)}
            <form className="form-horizontal" onSubmit={this.setLeadDOB}>
              <div className="form-group">
                <label className="col-md-4 control-label">Date Of Birth</label>
                <div className="col-md-6">
                  <Datetime
                    value={selectedDate}
                    timeFormat={false}
                    locale={formats.locale}
                    dateFormat={formats.date}
                    inputProps={{
                      placeholder: formats.date,
                    }}
                    utc={true}
                    viewMode={'days'}
                    onChange={(date) => this.setState({ ...this.state, date: date })}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal.Footer>
          <button className="btn btn-default push-5-r push-10" type="button" onClick={this.hideModalAndCleanUp}>
            Cancel
          </button>
          <button className="btn btn-primary push-5-r push-10" type="button" disabled={!this.state.date} onClick={this.setLeadDOB}>
            <i className="fa fa-birthday-cake" /> Set DOB
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

SelectDOBModal.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLead: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  formats: PropTypes.PropTypes.object.isRequired,
  currentModal: PropTypes.string,
};

export default SelectDOBModal;
