import { request, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { fieldNames } from './constants';
import { formDataFromData } from 'utils/formHelpers';
import locations from 'modules/Stores/Locations';

import notify from 'modules/Notifications';

export const update = (location, actionAutomationRuleId, formData) => {
  return (dispatch) => {
    const body = {
      action_automation_rule: formDataFromData(formData, fieldNames, false),
    };

    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      notify.success('Action Automation rules updated');
      const newLocation = {
        ...location,
        action_automation_rule: payload.action_automation_rules,
      };
      dispatch(locations.actions.editLocation(newLocation));
    }
    const url = 'locations/' + location.id + '/action_automation_rules/' + actionAutomationRuleId;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data,
  };
}

export function prefilFormData(actionAutomationRule) {
  return {
    type: actionTypes.PREFILL_FORM_DATA,
    actionAutomationRule,
  };
}
