import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditCallScheduleStepModal from "../components/EditCallScheduleStepModal";
import modal from "modules/Stores/Modal";
import * as selectors from "../selectors";
import * as actions from "../actions";
import { UPDATE_CALL_SCHEDULE_STEP_MODAL } from "../constants";

function mapStateToProps(state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    callScheduleStep: selectors.getSelectedStep(state),
    modalName: UPDATE_CALL_SCHEDULE_STEP_MODAL,
    errors: modal.selectors.getModalErrors(state)
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal: modal.actions.hideModal,
      updateCallScheduleStep: actions.updateCallScheduleStep,
      selectCallScheduleStepId: actions.selectCallScheduleStepId,
      deleteCallScheduleStep: actions.deleteCallScheduleStep
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCallScheduleStepModal);
