import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';

import { RECORD_CALL_MODAL, SEND_TEXT_MODAL } from '../../constants';

import SendTextMessageModalContainer from 'modules/components/LeadDetail/containers/SendTextMessageModalContainer';

export class PhoneOptionsModal extends Component {
  state = {
    options: [
      {
        requiresMobile: false,
        name: 'Send a txt message',
        icon: 'si si-speech',
      },
    ],
  };

  callFromDeviceSelected = () => {
    this.props.hideModal();
  };

  indexClicked = (index) => {
    switch (index) {
      case 0:
        this.sendTextMessage();
        break;
      case 1:
        this.recordCall();
        break;
      default:
        break;
    }
  };

  sendTextMessage = () => {
    this.props.showModal(SEND_TEXT_MODAL);
  };

  recordCall = () => {
    this.props.showModal(RECORD_CALL_MODAL);
  };

  render() {
    const { lead, isMobile, loading, currentModal, hideModal, modalName } = this.props;

    const options = this.state.options.map((option, index) => {
      if (option.requiresMobile && isMobile === false) {
        return undefined;
      }
      return (
        <div key={index} className="col-xs-6 col-sm-4">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="block block-bordered block-link-hover1 text-center selectable" onClick={() => this.indexClicked(index)}>
            <div className="block-content block-content-full bg-primary">
              <i className={option.icon + ' fa-5x text-white'} />
            </div>
            <div className="block-content block-content-full block-content-mini">{option.name}</div>
          </a>
        </div>
      );
    });

    const blockStyle = loading ? 'block block-opt-refresh' : 'block';
    return (
      <div>
        <Modal show={currentModal === modalName} onHide={hideModal}>
          <div className={blockStyle}>
            <div className="block-header bg-primary">
              <ul className="block-options">
                <li>
                  <button data-dismiss="modal" type="button" onClick={hideModal}>
                    <i className="si si-close" style={{ color: 'white' }} />
                  </button>
                </li>
              </ul>
              <h3 className="block-title" style={{ color: 'white' }}>
                What would you like to do?
              </h3>
            </div>
            <div className="block-content">
              <div className="row">
                {isMobile && (
                  <div className="col-xs-12 col-sm-4">
                    <a
                      className="block block-bordered block-link-hover1 text-center selectable"
                      onClick={this.callFromDeviceSelected}
                      href={'tel:' + lead.mobile_number}
                    >
                      <div className="block-content block-content-full bg-primary">
                        <i className="si si-call-out fa-5x text-white" />
                      </div>
                      <div className="block-content block-content-full block-content-mini">Call from this device</div>
                    </a>
                  </div>
                )}
                {options}
              </div>
            </div>
          </div>
        </Modal>
        <SendTextMessageModalContainer lead={lead} currentModal={currentModal} modalName={SEND_TEXT_MODAL} />
      </div>
    );
  }
}

PhoneOptionsModal.propTypes = {
  lead: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  recordCallForLead: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentModal: PropTypes.string,
};

export default PhoneOptionsModal;
