import React from "react";
import styled from "styled-components";
import ExportButtonContainer from "../containers/ExportButtonContainer";

function HeaderView() {
  return (
    <Container>
      <HeaderContainer className="row items-push">
        <div className="col-md-12">
          <div className="btn-group pull-right">
            <div className="btn-group">
              <ExportButtonContainer />
            </div>
          </div>
          <h1 className="page-heading push-10">Deals Report</h1>
          <BottomBorder />
        </div>
      </HeaderContainer>
    </Container>
  );
}

HeaderView.propTypes = {};

export default HeaderView;

const HeaderContainer = styled.div``;
const Container = styled.div``;
const BottomBorder = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 2px;
`;
