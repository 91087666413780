import { request, buildIndexUrl, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const get = (page, search, locationIds) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setUsers(payload.users))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setLoading(false))
    }
    let url = buildIndexUrl('users', page, null, search)
    if (locationIds && locationIds.length > 0) {
      url = url + '&location_ids[]=' + locationIds.join('&location_ids[]=')
    }
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setUsers (users) {
  return {
    type: actionTypes.SET_USERS,
    users
  }
}

export function setSelectedUserIds (selectedUserIds) {
  return {
    type: actionTypes.SET_SELECTED_USER_IDS,
    selectedUserIds
  }
}

export function addUserId (userId) {
  return {
    type: actionTypes.ADD_USER_ID,
    userId
  }
}

export function removeUserId (userId) {
  return {
    type: actionTypes.REMOVE_USER_ID,
    userId
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function setLastUsedLocationIds (lastUsedLocationIds) {
  return {
    type: actionTypes.SET_LAST_USED_LOCATION_IDS,
    lastUsedLocationIds
  }
}
