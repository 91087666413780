import { combineReducers } from 'redux';
import previousReallocations from './modules/previousReallocations';
import selectLocation from './modules/selectLocation';
import selectUsers from './modules/selectUsers';
import confirm from './modules/confirm';

import * as actionTypes from './actionTypes';

const TAB_INDEX_ACTION_HANDLERS = {
  [actionTypes.INCREMENT_TAB_INDEX]: (state, action) => {
    const tabIndex = state.tabIndex;
    return { ...state, tabIndex: tabIndex + 1 };
  },
  [actionTypes.DECREMENT_TAB_INDEX]: (state, action) => {
    const tabIndex = state.tabIndex;
    return { ...state, tabIndex: tabIndex - 1 };
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState();
  },
};

function initialState() {
  return {
    tabIndex: 0,
  };
}

function tabIndex(state = initialState(), action) {
  const handler = TAB_INDEX_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export default combineReducers({
  tabIndex,
  [previousReallocations.constants.NAME]: previousReallocations.reducer,
  [selectLocation.constants.NAME]: selectLocation.reducer,
  [selectUsers.constants.NAME]: selectUsers.reducer,
  [confirm.constants.NAME]: confirm.reducer,
});
