import * as actionTypes from './actionTypes'
import { guessedHeaderMappings } from './importFields'

import Papa from 'papaparse'

export function parseCSVFileIntoLeads (file) {
  return dispatch => {
    dispatch(setLoading(true))
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        const errors = errorsForResults(results)
        if (errors) {
          dispatch(setErrors(errors))
          dispatch(setLoading(false))
          return
        }
        const headerRow = results.data[0]
        dispatch(setNumberOfRecords(results.data.length - 1))
        dispatch(setLoading(false))
        const guessedHeaders = guessedHeaderMappings(headerRow)
        dispatch(setHeaders(guessedHeaders))
        dispatch(setLeads(results.data.slice(1, 201)))
      }
    })
  }
}

function errorsForResults (results) {
  const headerRow = results.data[0]
  if (headerRow.includes('')) {
    return 'Imports cannot have empty columns or rows. Please remove these and try again.'
  }
  const lastRow = results.data[results.data.length - 1]
  const uniqueLastRowFirstValue = [ ...new Set(lastRow) ][0]
  if (uniqueLastRowFirstValue === '') {
    return 'Imports cannot end with empty rows. Please remove these and try again.'
  }
  return null
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setHeaders (headers) {
  return {
    type: actionTypes.SET_HEADERS,
    headers
  }
}

function setLeads (leads) {
  return {
    type: actionTypes.SET_LEADS,
    leads
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setImportOptionsValue (fieldName, value) {
  return {
    type: actionTypes.SET_IMPORT_OPTIONS_VALUE,
    fieldName: fieldName,
    value: value
  }
}

function setNumberOfRecords (numberOfRecords) {
  return {
    type: actionTypes.SET_NUMBER_OF_RECORDS,
    numberOfRecords
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function replaceHeaderValue (oldValue, newValue) {
  return {
    type: actionTypes.REPLACE_HEADER_VALUE,
    oldValue,
    newValue
  }
}
