import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ViewSignedWaiverModalView } from '../components/ViewSignedWaiverModalView';

import * as actions from '../actions';
import * as selectors from '../selectors';
import session from 'modules/Stores/Session';
import modal from 'modules/Stores/Modal';

function mapStateToProps(state: any) {
  return {
    waiverSubmission: selectors.selectedWaiverSubmission(state),
    currentModal: modal.selectors.getCurrentModal(state),
    timezone: session.selectors.getUserTimezone(state),
  };
}
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      selectWaiverSubmissionId: actions.selectWaiverSubmissionId,
      hideModal: modal.actions.hideModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSignedWaiverModalView);
