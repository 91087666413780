import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// FETCH New Leads
export const fetchInRange = (startDate, endDate, pageNumber, location) => {
  return dispatch => {
    dispatch(fetchingAppointments())

    function onFailure (errors) {
      dispatch(fetchAppointmentsFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(fetchAppointmentsSuccess(payload.appointments))
      dispatch(loadPageDetails(payload.meta))
    }

    let queryString = 'appointments_for_user?start_date=' + startDate +
    '&end_date=' + endDate +
    '&completed=false&location_id=' + location.id

    if (pageNumber) {
      queryString = queryString + '&page[number]=' + pageNumber
    }
    return request(queryString, GET, null, onSuccess, onFailure)
  }
}

function fetchingAppointments () {
  return {
    type: actionTypes.FETCH
  }
}

function fetchAppointmentsSuccess (appointments) {
  return {
    type: actionTypes.FETCH_SUCCESS,
    appointments
  }
}

export function fetchAppointmentsFailed (errors, todoType) {
  return {
    type: actionTypes.FETCH_FAILED,
    errors,
    todoType
  }
}

export function selectAppointment (appointmentId) {
  return {
    type: actionTypes.SELECT,
    appointmentId
  }
}

function loadPageDetails (pageDetails, todoType) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
    todoType
  }
}

export function removeSelected (lead) {
  return {
    type: actionTypes.REMOVE_SELECTED
  }
}
