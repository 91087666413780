import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Select from 'react-select';
import SelectColorValueContainer from '../containers/SelectColorValueContainer';
import { userCanSeeAllCalenders } from 'modules/Stores/Session/helpers';

import { space } from 'styleguide';
import { FormFields, Box } from 'modules/components';
const { Checkbox } = FormFields;

export class UserSelectView extends Component {
  handleInput = (values) => {
    this.props.selectUsers(values);
  };

  handleSelectionForType = (type) => {
    const { eventTypes, setTypes } = this.props;
    const index = eventTypes.indexOf(type);
    if (index > -1) {
      setTypes([...eventTypes.slice(0, index), ...eventTypes.slice(index + 1)]);
    } else {
      setTypes([type, ...eventTypes]);
    }
  };

  render() {
    const { users, selectedUsers, eventTypes, user } = this.props;
    const showUserSelect = userCanSeeAllCalenders(user);
    return (
      <Box>
        {showUserSelect && (
          <Box w100 mb={space.M}>
            <Select
              multi
              placeholder={'Select a user'}
              labelKey={'display_name'}
              valueKey={'id'}
              options={users}
              value={selectedUsers}
              valueComponent={SelectColorValueContainer}
              onChange={this.handleInput}
            />
          </Box>
        )}
        <Box>
          <Checkbox
            name="contactCall"
            label="Contact calls"
            onChange={() => this.handleSelectionForType('contact_calls')}
            switchStyle="primary"
            checked={eventTypes.includes('contact_calls')}
          />
          <Box height={space.XS} />
          <Checkbox
            name="appointment"
            label="Appointments"
            onChange={() => this.handleSelectionForType('appointments')}
            switchStyle="primary"
            checked={eventTypes.includes('appointments')}
          />
          <Box height={space.XS} />
          <Checkbox
            name="tasks"
            label="Tasks"
            onChange={() => this.handleSelectionForType('tasks')}
            switchStyle="primary"
            checked={eventTypes.includes('tasks')}
          />
        </Box>
      </Box>
    );
  }
}

UserSelectView.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectUsers: PropTypes.func.isRequired,
  allowMultiSelect: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTypes: PropTypes.func.isRequired,
};
