import PropTypes from "prop-types";
import React from "react";

export const CallScheduleRow = ({
  callSchedule,
  selected,
  selectCallScheduleId
}) => {
  
  const backgroundClass = selected ? "bg-primary" : "";
  const textClass = selected ? "text-white" : "";

  return (
    <tr className={`selectable ${textClass} ${backgroundClass}`}
      onClick={() => selectCallScheduleId(callSchedule.id)}
    >
      <td className="font-w600">{callSchedule.name}</td>
      <td>{callSchedule.number_of_steps}</td>
      <td>{callSchedule.default && "Default"}</td>
    </tr>
  );
};

CallScheduleRow.propTypes = {
  callSchedule: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectCallScheduleId: PropTypes.func.isRequired
};

export default CallScheduleRow;
