export const NAME = "filters";

// Filter Types
export const COMPARE_BY = "compare_by";
export const CALCULATE_BY = "calculate_by";
export const USERS = "users";
export const LOCATIONS = "locations";
export const REGIONS = "regions";
export const LEAD_SOURCES = "lead_sources";
export const HEARD_ABOUT_METHODS = "heard_about_methods";
export const TAGS = "tags";
export const TRIALS = "trials";
export const DATE_RANGE = "date_range";
export const CLUBS = 'locations' 
export const LEAD_CREATED = 'lead_created'
export const EVENT_OCCURED = 'event_occured'
export const LEAD_STATUS = 'statuses'
export const STATUS_UPDATED_RANGE = 'statusUpdatedBetween'
export const ASSIGNED_TO = 'assigned_to'
export const FUNNEL_STEPS = 'funnel_steps'

// Lead Statuses
export const ACTIVE = 'active'
export const CLOSED = 'closed'
export const NOT_ASSIGNED = 'not_assigned'
export const INACTIVE = 'inactive'
export const LOST = 'lost'

// Modals
export const SETTINGS_SELECT_LOCATIONS_MODAL = 'SETTINGS_SELECT_LOCATIONS_MODAL'
export const SETTINGS_SELECT_USERS_MODAL = 'SETTINGS_SELECT_USERS_MODAL'
export const SETTINGS_SELECT_REGIONS_MODAL = 'SETTINGS_SELECT_REGIONS_MODAL'

// Report Types
export const SALES_PERFORMANCE_REPORT = 'sales_performance'
export const TIME_TAKEN_REPORT = 'time_taken'
export const LEADS_REPORT = 'leads_report'
export const LEAD_ALLOCATION_REPORT = 'lead_allocation'
export const LOST_LEADS_REPORT = 'lost_leads'
export const DEALS_REPORT = 'deals_report'
export const TRIALS_REPORT = 'trials_report'
export const ACTIVITY_REPORT_CALLS = 'activity_report_calls'
export const ACTIVITY_REPORT_APPOINTMENTS = 'activity_report_appointments'
export const ACTIVITY_REPORT_TASKS = 'activity_report_tasks'

// Filters
export const salesPerformanceFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  REGIONS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
  COMPARE_BY,
  CALCULATE_BY
];

export const leadsReportFilters = [
  STATUS_UPDATED_RANGE,
  ASSIGNED_TO,
  LOCATIONS,
  REGIONS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
  LEAD_STATUS
]

export const leadAllocationFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS
]

export const timeTakenFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  REGIONS,
  FUNNEL_STEPS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
  COMPARE_BY
];

export const lostLeadsFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  REGIONS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
  COMPARE_BY
];

export const dealsReportFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  REGIONS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
  COMPARE_BY
]

export const trialsReportFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  REGIONS,
  TRIALS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
]

export const activityReportFilters = [
  DATE_RANGE,
  USERS,
  LOCATIONS,
  REGIONS,
  LEAD_SOURCES,
  HEARD_ABOUT_METHODS,
  TAGS,
  COMPARE_BY
]