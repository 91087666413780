import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import email from '../modules/email'
import session from 'modules/Stores/Session'
import { getProductName } from 'modules/Stores/UserData/selectors'

function mapStateToProps (state) {
  const user = session.selectors.getUser(state)
  return {
    userEmail: user.transactional_email,
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getUserEmail: email.actions.getUserEmail
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(email.components.UserEmailView)
