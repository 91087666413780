import PropTypes from 'prop-types';
import config from 'config';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { PrivateRouteContainer } from 'modules/components';

import * as settings from '../routes';
import STRINGS from 'strings';

const settingsKeys = Object.keys(settings);

export class SettingsView extends Component {
  render() {
    const Strings = STRINGS.Settings;
    const { links, currentPath, company } = this.props;
    const menuItems = links.map((link, index) => {
      if (link.preAccess === true && company.can_access_pre_release_features === false) {
        return null;
      }

      const isActive = link.to === currentPath;

      const activeClassName = isActive ? 'active' : '';
      return (
        <li className={activeClassName} key={index}>
          <Link to={link.to}>
            <i className={link.style + ' push-5-r'} />
            {link.title}
          </Link>
        </li>
      );
    });

    return (
      <div className="content content-boxed">
        <div className="row">
          <div className="col-md-4">
            <div className="block">
              <div className="block-header">
                <h3 className="block-title">{Strings.title}</h3>
              </div>
              <div className="block-content">
                <ul className="nav nav-pills nav-stacked push">{menuItems}</ul>
                <p style={{ marginLeft: '15px' }} className="text-muted">
                  GymLeads Sales: {config.version}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            {settingsKeys.map((key) => {
              const settingRoute = settings[key];
              return (
                <PrivateRouteContainer
                  key={key}
                  path={`/secure/settings/${settingRoute.constants.NAME}`}
                  component={settingRoute.Container}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

SettingsView.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPath: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
};
