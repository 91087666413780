import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'

export const SET_LEADS = NAME + '/SET_LEADS'
export const SET_IMPORT_OPTIONS_VALUE = NAME + '/SET_IMPORT_OPTIONS_VALUE'
export const SET_NUMBER_OF_RECORDS = NAME + '/SET_NUMBER_OF_RECORDS'
export const SET_HEADERS = NAME + '/SET_HEADERS'
export const INCREMENT_LEAD_COUNT = NAME + '/INCREMENT_LEAD_COUNT'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const RESET = NAME + '/RESET'

export const REPLACE_HEADER_VALUE = NAME + '/REPLACE_HEADER_VALUE'
