import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LeadContactPanel from './LeadContactPanel/LeadContactPanel';
import LeadOptionToggleView from './LeadOptionToggleView';
import EditLeadModalContainer from '../containers/EditLeadModalContainer';
import LeadSalesFunnelStepsContainer from '../containers/LeadSalesFunnelStepsContainer';
import ExportLeadContainer from '../containers/ExportLeadContainer';
import ActiveLeadButtons from './ActiveLeadButtons';
import LeadSyncStatusView from './LeadSyncStatusView';
import { MOVE_LEAD_MODAL, EDIT_LEAD_MODAL } from '../constants';

import { LeadStatusTag, EmptyView } from 'modules/components';
import { daysAgoString } from 'modules/Helpers/DateHelpers';

import Avatar from 'react-avatar';

import './LeadDetail.css';

export class LeadDetailView extends Component {
  UNSAFE_componentWillMount() {
    const {
      fetchDetailForLead,
      fetchHistoryForLeadAndPageNumber,
      fetchToDosForLead,
      fetchAppointmentsForLead,
      fetchCallsForLead,
      fetchNotesForLead,
      fetchCurrentTrialForLead,
      lead,
    } = this.props;

    if (lead) {
      fetchDetailForLead(lead);
      fetchNotesForLead(lead);
      fetchAppointmentsForLead(lead);
      fetchCallsForLead(lead);
      fetchHistoryForLeadAndPageNumber(lead, 1);
      fetchToDosForLead(lead);
      fetchCurrentTrialForLead(lead);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      fetchDetailForLead,
      fetchHistoryForLeadAndPageNumber,
      fetchToDosForLead,
      fetchAppointmentsForLead,
      fetchCallsForLead,
      fetchNotesForLead,
      fetchCurrentTrialForLead,
      lead,
    } = this.props;
    const nextLead = nextProps.lead;
    if ((nextLead && !lead) || (nextLead && lead.id !== nextProps.lead.id)) {
      fetchDetailForLead(nextLead);
      fetchNotesForLead(nextLead);
      fetchAppointmentsForLead(nextLead);
      fetchCallsForLead(nextLead);
      fetchHistoryForLeadAndPageNumber(nextLead, 1);
      fetchToDosForLead(nextLead);
      fetchCurrentTrialForLead(nextLead);
    }
  }

  editLeadSelected = () => {
    this.props.showModal(EDIT_LEAD_MODAL);
  };

  buttonOptions = () => {
    const { lead, listUpdateProtocol } = this.props;
    if (lead.status === 'active') {
      return <ActiveLeadButtons lead={lead} {...this.props} />;
    }
    return <ExportLeadContainer lead={lead} listUpdateProtocol={listUpdateProtocol} moveLeadModalName={MOVE_LEAD_MODAL} />;
  };

  render() {
    const { lead, showModal, listUpdateProtocol, dismissLead, company } = this.props;

    return (
      <div className="block block-rounded block-bordered">
        <div className="block-content" style={{ paddingBottom: '0px' }}>
          {lead && (
            <div>
              {dismissLead && (
                <ul
                  className="block-options"
                  style={{
                    paddingTop: '10px',
                    paddingRight: '10px',
                    zIndex: '9',
                    position: 'relative',
                  }}
                >
                  <li>
                    <button data-dismiss="modal" type="button" onClick={dismissLead}>
                      <i className="si si-close fa-2x" />
                    </button>
                  </li>
                </ul>
              )}
              <EditLeadModalContainer modalName={EDIT_LEAD_MODAL} lead={lead} listUpdateProtocol={listUpdateProtocol} />
              <div className="row">
                <div className="col-sm-3 text-center">
                  <Avatar name={lead.display_name} src={lead.image_url} email={lead.email} size={128} round={true} />
                  <br />
                  <button className="btn btn-minw btn-default editLeadButton" onClick={this.editLeadSelected} type="button">
                    Edit
                  </button>
                </div>
                <div className="col-sm-8">
                  <h3 className="push-10">
                    {lead.display_name}{' '}
                    <span
                      style={{
                        fontSize: '16px',
                        position: 'absolute',
                        marginLeft: '10px',
                      }}
                    >
                      <LeadStatusTag leadStatus={lead.status} />
                    </span>
                  </h3>
                  <span className="label-outline">{lead.location_name}</span>
                  <span className="text-muted">
                    <i className="push-5-l fa fa-calendar" /> {daysAgoString(lead)}
                  </span>
                  {lead.lead_sync_status && (
                    <span style={{ marginLeft: '10px' }}>
                      <span style={{ position: 'absolute', top: '35px' }}>
                        <LeadSyncStatusView syncing={lead.syncing} leadSyncStatus={lead.lead_sync_status} />
                      </span>
                    </span>
                  )}
                  {lead.sales_funnel_step && (
                    <div className="push-20-t">
                      <LeadSalesFunnelStepsContainer lead={lead} showTitles listUpdateProtocol={listUpdateProtocol} />
                    </div>
                  )}
                  <div className="push-20-t">
                    <LeadContactPanel lead={lead} showModal={showModal} company={company} />
                  </div>
                  {this.buttonOptions()}
                </div>
              </div>
              <div className="row">
                <LeadOptionToggleView lead={lead} listUpdateProtocol={listUpdateProtocol} pathToLeadDetail={this.props.pathToLeadDetail} />
              </div>
            </div>
          )}
          {!lead && <EmptyView colorThemeClass="primary" iconClass="si si-user" title="Select a lead to see more details" />}
        </div>
      </div>
    );
  }
}

LeadDetailView.propTypes = {
  listUpdateProtocol: PropTypes.object.isRequired,
  fetchDetailForLead: PropTypes.func.isRequired,
  fetchHistoryForLeadAndPageNumber: PropTypes.func.isRequired,
  fetchCallsForLead: PropTypes.func.isRequired,
  fetchAppointmentsForLead: PropTypes.func.isRequired,
  fetchToDosForLead: PropTypes.func.isRequired,
  fetchNotesForLead: PropTypes.func.isRequired,
  pathToLeadDetail: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  updateLeadToStatus: PropTypes.func.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  lead: PropTypes.object,
  company: PropTypes.object.isRequired,
  dismissLead: PropTypes.func,
};
