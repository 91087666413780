import { NAME } from "./constants";

export const getErrors = (state) => state.trialsReport[NAME].errors;
export const getLoading = (state) => state.trialsReport[NAME].loading;
export const getData = (state) => state.trialsReport[NAME].data;

export function chartDataForSummaryData(data, dataSetLabel) {
  const days = data.map((obj) => `Day ${obj.day}`);
  const values = data.map((obj) => obj.count);

  if (days.length === 0 || values === 0) {
    return {};
  }

  return {
    labels: days,
    datasets: [
      {
        label: dataSetLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: values,
      },
    ],
  };
}
