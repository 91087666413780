import { request, POST, PUT } from 'utils/apiUtils';
import * as actionTypes from './actionTypes';
import { trackEvent } from 'modules/Helpers/Analytics';

import notify from 'modules/Notifications';

import leadSources from 'modules/Stores/LeadSources';
import modal from 'modules/Stores/Modal';

export const create = (leadSourceTitle) => {
  return (dispatch) => {
    const body = {
      lead_source: {
        source_name: leadSourceTitle,
      },
    };

    dispatch(startLoading());
    function onFailure(errors) {
      dispatch(loadFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(loadSuccess());
      dispatch(leadSources.actions.addLeadSource(payload.lead_source));
      notify.success('Lead source created');
      trackEvent('Added Lead source', {
        title: leadSourceTitle,
      });
    }

    return request('lead_sources', POST, body, onSuccess, onFailure);
  };
};

export const deleteLeadSource = (leadSourceId, toLeadSourceId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());
    const body = {
      to_id: toLeadSourceId,
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload));
    }

    function onSuccess() {
      dispatch(modal.actions.stopLoading());
      dispatch(leadSources.actions.removeLeadSourceWithId(leadSourceId));
      notify.success('Lead source migrated');
      trackEvent('Deleted Lead Source ', {
        id: leadSourceId,
      });
      dispatch(modal.actions.hideModal());
    }
    const url = 'lead_sources/' + leadSourceId + '/migrate';
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const update = (leadSource, title, lawfulProcessingBasisId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading());

    const body = {
      lead_source: {
        source_name: title,
        lawful_processing_basis_id: lawfulProcessingBasisId,
      },
    };

    function onFailure(errors) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(leadSources.actions.replaceLeadSourceWithId(payload.lead_source));
      dispatch(modal.actions.hideModal());
      notify.success('Lead source updated');
    }

    return request('lead_sources/' + leadSource.id, PUT, body, onSuccess, onFailure);
  };
};

export function startLoading() {
  return {
    type: actionTypes.LOADING,
  };
}

export function loadSuccess() {
  return {
    type: actionTypes.LOAD_SUCCESS,
  };
}

export function loadFailed(errors) {
  return {
    type: actionTypes.LOAD_FAILED,
  };
}
