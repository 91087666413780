import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLead = (state) => state.manageLeads.leadDetail[NAME].lead
export const getLoading = (state) => state.manageLeads.leadDetail[NAME].loading
export const getFullDetail = (state) => state.manageLeads.leadDetail[NAME].fullDetail

export function getLatestLead (shallowLead) {
  return createSelector(
    [ getLead, getLoading ],
    (lead, loading) => {
      if (!shallowLead) {
        return null
      }

      if (shallowLead && !lead) {
        return shallowLead
      }

      if (shallowLead && lead && shallowLead.id !== lead.id) {
        return shallowLead
      }

      if (shallowLead && lead && shallowLead.id === lead.id) {
        return lead
      }

      return null
    }
  )
}
