import { isInteger, isMinLength, isMaxLength } from 'utils/validation'

export const LeadTimeOutValidator = {
  leadTimeOutMinutes: [{
    rule: isInteger,
    errorMessage: 'It should be a value between 0 and 100'
  }, {
    rule: isMinLength(0),
    errorMessage: 'It should be a value between 0 and 100'
  }, {
    rule: isMaxLength(100),
    errorMessage: 'It should be a value between 0 and 100'
  }]
}
