import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LeadSalesFunnelSteps } from 'modules/components'
import { moveLeadToStep } from '../actions'

import userData from 'modules/Stores/UserData'

function mapStateToProps (state) {
  return {
    salesFunnelSteps: userData.selectors.getSalesFunnelSteps(state)
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    moveLeadToStep
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadSalesFunnelSteps)
