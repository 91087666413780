import * as actionTypes from "./actionTypes";

import { CREATE_LEAD_VIEW } from "./constants";

const CREATE_LEAD_ACTION_HANDLERS = {
  [actionTypes.UPDATE_LEAD_DATA]: (state, action) => {
    return { ...state, data: action.data };
  },
  [actionTypes.SELECT_USER]: (state, action) => {
    return { ...state, assignToUser: action.user };
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_USERS]: (state, action) => {
    return { ...state, users: action.users };
  },
  [actionTypes.RESET]: (state, action) => {
    const newData = defaultLeadFormState();
    return {
      ...state,
      data: newData,
      loading: false,
      currentView: CREATE_LEAD_VIEW,
      showingUsers: false,
      selectedLeadSource: null,
      selectedLeadGoal: null,
      selectedHeardAboutMethod: null,
      existingLead: null,
      assignToUser: null,
      pageDetails: {
        currentPage: 1,
        pageCount: 0,
        total: 0
      }
    };
  },
  [actionTypes.SET_EXISTING_LEAD]: (state, action) => {
    return { ...state, existingLead: action.lead };
  },
  [actionTypes.ADD_TAG]: (state, action) => {
    return {
      ...state,
      data: defaultLeadFormState(),
      showingUsers: false,
      assignToUser: null
    };
  },
  [actionTypes.REMOVE_TAG]: (state, action) => {
    return {
      ...state,
      data: defaultLeadFormState(),
      showingUsers: false,
      assignToUser: null
    };
  },
  [actionTypes.SET_CURRENT_VIEW]: (state, action) => {
    return { ...state, currentView: action.currentView };
  },
  [actionTypes.SET_LAST_USED_LOCATION_ID]: (state, action) => {
    return { ...state, lastUsedLocationId: action.lastUsedLocationId };
  },
  [actionTypes.SET_SELECTED_LOCATION]: (state, action) => {
    return {
      ...state,
      selectedLocation: action.selectedLocation,
      assignToUser: null
    };
  },
  [actionTypes.SET_SELECTED_LEAD_SOURCE]: (state, action) => {
    return {
      ...state,
      selectedLeadSource: action.selectedLeadSource
    };
  },
  [actionTypes.SET_SELECTED_LEAD_GOAL]: (state, action) => {
    return {
      ...state,
      selectedLeadGoal: action.selectedLeadGoal
    };
  },
  [actionTypes.SET_SELECTED_HEARD_ABOUT_METHOD]: (state, action) => {
    return {
      ...state,
      selectedHeardAboutMethod: action.selectedHeardAboutMethod
    };
  },
  [actionTypes.SET_TAGS]: (state, action) => {
    const newFields = { ...state.data.fields, tags: action.tags };
    const newData = { ...state.data, fields: newFields };
    return { ...state, data: newData };
  },
  [actionTypes.SET_PAGE_DETAILS]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  data: defaultLeadFormState(),
  selectedLocation: null,
  lastUsedLocationId: null,
  selectedLeadSource: null,
  selectedLeadGoal: null,
  selectedHeardAboutMethod: null,
  loading: false,
  users: [],
  currentView: CREATE_LEAD_VIEW,
  showingUsers: false,
  existingLead: null,
  assignToUser: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

function defaultLeadFormState() {
  return {
    isValid: false,
    fields: {
      givenName: {
        value: "",
        isValid: false
      },
      surname: {
        value: "",
        isValid: true
      },
      email: {
        value: "",
        isValid: true
      },
      mobileNumber: {
        value: "",
        isValid: true
      },
      dateOfBirth: {
        value: "",
        isValid: true
      },
      gender: {
        value: "",
        isValid: true
      },
      workplaceName: {
        value: "",
        isValid: true
      },
      zipPostcode: {
        value: "",
        isValid: true
      },
      notes: {
        value: "",
        isValid: true
      },
      tags: []
    }
  };
}

export default function reducer(state = initialState, action) {
  const handler = CREATE_LEAD_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
